export const TYPE_ELEMENTS_CAR_ACCIDENT = {
  SIGNATURE: 1,
  ACCIDENT: 2,
  DAMAGED: 3,
  AMOUNT_PRICE: 4,
  COST: 5,
  GARA_NOTE: 6,
  PROVIDER_NOTE: 7,
  BILL: 8,
  ACCEPTANCE: 9,
  APPROVAL_PROVIDER: 10
}
