import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";

import noti from "../../../../../assets/loyalty/noti.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogNoti(props) {
  const { dispatch, open, loading } = props;
  const classes = makeStyle();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => dispatch("closeDialogNoti")}
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialog}
    >
      <DialogContent style={{ padding: "20px 0" }}>
        <div className={classes.divContent}>
          <img
            src={noti}
            alt="wait"
            width={"35%"}
            style={{ maxWidth: "140px" }}
          />
          <div className="title">Thông báo</div>
          <div className="text">
            {loading
              ? "Đang tạo yêu cầu bồi thường"
              : "Bạn có chắn chắn thông tin đã đúng?"}
          </div>
        </div>
        {loading ? (
          <div className={classes.action}>
            <CircularProgress size={24} sx={{ m: 1, color: "#284078" }} />
            <div> Quá trình này sẽ mất khoảng vài phút...</div>
          </div>
        ) : (
          <div className={classes.button}>
            <Button
              style={{
                ...styleButton,
                color: "#0B3C8A",
                background: "white",
                borderRadius: 5,
                marginRight: "12px",
                border: "1px solid #0B3C8A",
              }}
              onClick={() => dispatch("closeDialogNoti")}
            >
              Quay lại
            </Button>
            <Button style={styleButton} onClick={() => dispatch("onSubmit")}>
              Có
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

const styleButton = {
  width: "40%",
  color: "white",
  textTransform: "none",
  background: "#0B3C8A",
  fontWeight: "600",
  borderRadius: 5,
};

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: 5,
      width: "75%",
      maxWidth: "300px",
      margin: 0,
    },
  },

  divContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .title": {
      textAlign: "center",
      marginTop: 10,
      fontWeight: 600,
      fontSize: 17,
    },
    "& .text": {
      textAlign: "center",
      margin: "5px 0 15px",
    },
  },

  action: {
    textAlign: "center",
    fontSize: "13px",
    color: "#FB7800",
    fontStyle: "italic",
  },

  button: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));
