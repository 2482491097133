import moment from "moment";

export default function TableExportExcelBVPK(props) {
  const { dataSearch } = props;

  const fromDate = !dataSearch?.from
    ? "..............."
    : moment(dataSearch?.from).format("DD/MM/YYYY");
  const toDate = !dataSearch?.to
    ? "..............."
    : moment(dataSearch?.to).format("DD/MM/YYYY");

  return (
    <>
      <div style={{ display: "none" }}>
        <table
          className="collapse-border"
          id={"export-data-table"}
          data-cols-width="7, 35, 30, 20, 35, 30, 20, 20, 20, 30"
        >
          <thead style={{ fontSize: "13" }}>
            <tr />
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"16"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                BÁO CÁO BẢO LÃNH VIỆN PHÍ
              </th>
            </tr>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >{`Từ ngày: ${fromDate} Đến Ngày: ${toDate} `}</th>
            </tr>
            <tr />
            <tr />
            <tr />
            <tr />
            <tr style={{ fontSize: "13" }}>
              <Th>STT</Th>
              <Th>Tên người được bảo hiểm</Th>
              <Th>Mã HS Bồi thường</Th>
              <Th>CCCD/CMND</Th>
              <Th>Công ty BH</Th>
              <Th>Đơn vị xử lý</Th>
              <Th>Số tiền yêu cầu</Th>
              <Th>Số tiền bồi thường </Th>
              <Th>Ngày cập nhật</Th>
              <Th>Trạng thái</Th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13" }}>
            {props.data.map((item, index) => {
              return (
                <>
                  <tr key={index} style={{ fontSize: "13" }}>
                    <Td>{index + 1}</Td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const Th = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-h={"center"}
      data-a-wrap={true}
      data-f-bold={true}
    >
      {props.children}
    </th>
  );
};

const Td = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-wrap={true}
      data-t={props.type || ""}
    >
      {props.children}
    </th>
  );
};
