import { makeStyles } from "@mui/styles";

export const styledStatusClaim = makeStyles(() => ({
  container: {
    background: "#F8F8F8",
    "& .title-head": {
      color: "#686868",
    },
  },

  titleDiv: {
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .name-insurance": {
      fontSize: "16px",
      color: "#0B3C8A",
      fontWeight: "700",
      marginTop: "22px",
    },

    "& .name-claim": {
      fontSize: "15px",
      color: "#4B4B4B",
    },
  },

  box: {
    width: "100%",
    padding: "10px 16px",
    position: "relative",
    background: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .icon-status": {
      position: "absolute",
      top: "-20px",
      margin: "auto",
    },

    "& .notification": {
      fontSize: "15px",
      fontWeight: "700",
      marginTop: "16px",
    },
  },

  statusText: {
    fontSize: "14px",
    textAlign: "center",
    color: "#0B3C8A",

    "& .weight-700": {
      fontWeight: "700",
    },

    "& .w-100": {
      width: "100%",
    },

    "& .my-16": {
      margin: "16px 0",
    },

    "& .btn": {
      width: "45%",
      textTransform: "none",
      borderRadius: "9px",
      border: "1px solid #0B3C8A",
      boxShadow: "0px 10px 12px rgba(0, 0, 0, 0.2)",
    },

    "& .btn-refuse": {
      color: "#0B3C8A",
    },
    "& .btn-refuse.Mui-disabled": {
      color: "#A0A0A0 !important",
      border: "1px solid #A0A0A0 !important",
    },

    "& .btn-agree": {
      color: "#fff",
      backgroundColor: "#0B3C8A",
    },
    "& .btn-agree.Mui-disabled": {
      background: "#A0A0A0 !important",
      border: "1px solid #A0A0A0 !important",
    },

    "& .btn-agree:hover": {
      backgroundColor: "#0B3C8A",
    },
  },

  infor: {
    marginTop: "12px",
    background: "white",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 25px",
    color: "#2B2B2B",
    fontSize: "15px",
    "&:hover": {
      cursor: "pointer",
    },
  },

  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "center",

    "& .button": {
      textTransform: "none",
      padding: "6px 24px",
      background: "#3961A0",
      color: "white",
      borderRadius: "8px",
      boxShadow: "0px 10px 12px rgba(0, 0, 0, 0.2)",
    },

    "& .button:hover": {
      color: "white",
      background: "#3961A0",
    },
  },

  //
}));
