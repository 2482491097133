import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { Autocomplete, Box, Button, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import _, { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";

import useReducer from "../../../../library/hooks/useReducer";
import CommonTable from "../../../../library/layout/commonTable";
import { columns } from "../components/columnsAddUers";
import DialogAddUserCreate from "../components/dialogAddUser";
import FormCreateGCN from "../components/formCreactGCN";
import { Loading } from "../components/Loading";
import LoadingMui from "../../../../ui/LoadingMui";
import { TextField } from "../overrideMui";
import createGCNReducer from "../reducers/createGCNReducer";
import ImportExcel from "../components/ImportExcel";

import Icon_empty from "../../../../assets/icons/icon_empty.svg";
import Icon_upload from "../../../../assets/icons/Icon_upFile.svg";
import { createGCNStyles } from "../styles/styleCreateGCN";

const typeInsu = [{ name: "BH con người" }];
const options = [
  { title: "Thêm mới", id: 1 },
  // { title: "Cập nhật", id: 2 },
];

const initialState = {
  addInfo: {},
  addUserData: {},
  listOfInsuredPeople: [],
  dialogAddUser: {},
  merchants: [],
  merchant: {},
  loadingAutocomplete: {},
  payloadListAutocomplete: {},
  organizes: [],
  products: [],
  packages: [],
  loading: false,
  errAddUser: [],
  errInfo: [],
  importList: [],
};

export default function CreateGCN() {
  const classes = createGCNStyles();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const userTypeFromProfile = user?.extra_info?.userType || "";
  const [state, dispatch] = useReducer({
    reducer: createGCNReducer,
    name: "creatGCNReducer",
    initialState,
    history,
  });

  const [scrollPosition, setScrollPosition] = useState({});

  useEffect(() => {
    dispatch("getDataList");
  }, []);

  const {
    listOfInsuredPeople,
    loading,
    organizes,
    addInfo,
    loadingAutocomplete,
    payloadListAutocomplete,
  } = state;

  const handleDownload = () => {
    const filePath = "/template/template-import-gcn.xlsx";
    const link = document.createElement("a");
    link.href = filePath;
    link.download = "template-import-gcn.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onScrollListOrg = () => {
    const elment = document.getElementsByClassName("div-scroll");
    if (
      elment.length > 0 &&
      +payloadListAutocomplete?.org?.page * 10 <
        +payloadListAutocomplete?.org?.total
    ) {
      const scrollDiv = elment[0];
      const listItems = document.querySelectorAll(".scroll-item");
      const lastItem = listItems[listItems.length - 1] || null;

      if (
        Math.abs(lastItem?.offsetTop - scrollDiv.scrollTop + 70) <
          lastItem?.offsetHeight + scrollDiv.offsetHeight &&
        !loadingAutocomplete?.org
      ) {
        dispatch("getMoveListOrg");
        setScrollPosition({ ...scrollPosition, org: scrollDiv.scrollHeight });
      }
    }
  };

  useEffect(() => {
    const element = document.getElementsByClassName("div-scroll");
    if (element.length > 0) {
      const element_scroll = element[0];
      element_scroll.scrollTop = scrollPosition?.org || 0;
    }
  }, [loadingAutocomplete?.org]);

  const getValueAutocomplete = () => {
    const temp = organizes.find((i) => i.id == addInfo?.orgId);
    return payloadListAutocomplete?.org?.param?.title ||
      payloadListAutocomplete?.org?.param?.title == ""
      ? { title: payloadListAutocomplete?.org?.param?.title }
      : temp || null;
  };

  return (
    <div className={classes.container}>
      <Box className={classes.box_heder}>
        <div className="title-creat-product">Thêm GCN</div>
        <div>
          <Button
            className="buttons-delete"
            onClick={() => dispatch("resetContract")}
          >
            Xoá hết
          </Button>
          <Button
            className="buttons-save"
            onClick={() => dispatch("createContract")}
          >
            Lưu lại
          </Button>
        </div>
      </Box>
      <div style={{ position: "relative" }}>
        <Grid container spacing={1.5}>
          <Grid xs={6} sm={5} md={3}>
            <div className={classes.box_type_gcn}>
              <div className="title">Chọn loại</div>
              <div className="box-select">
                <span className="label-select">Loại bảo hiểm</span>
                <TextField fullWidth select value="BH con người">
                  {typeInsu.map((item, index) => (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="box-select">
                <span className="label-select">Thêm mới/Cập nhật</span>
                <TextField fullWidth select value={1}>
                  {options.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="box-select">
                <span className="label-select">Quản lý bởi</span>
                <Autocomplete
                  value={getValueAutocomplete() || null}
                  options={organizes}
                  disabled={["tpa", "tpas"].includes(userTypeFromProfile)}
                  getOptionLabel={(i) => i?.title || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Nhập thông tin"
                      // error={errors.includes("org_id")}
                      size="small"
                    />
                  )}
                  onChange={(e, value, reason) => {
                    if (reason === "selectOption") {
                      dispatch({
                        type: "onChangeInfo",
                        name: "orgId",
                        value: value?.id || null,
                      });
                    }
                    if (reason === "clear") {
                      dispatch({
                        type: "resetAutocomplete",
                        name: "org",
                        value: "",
                      });
                    }
                  }}
                  noOptionsText={
                    <div>
                      {loadingAutocomplete?.org
                        ? "Đang tải thêm ... "
                        : "Không tìm thấy dữ liệu"}
                    </div>
                  }
                  onInputChange={debounce((e, value, reason) => {
                    if (reason === "input") {
                      dispatch({ type: "onInputChangeOrg", value });
                    }
                  }, 600)}
                  ListboxComponent={(paramsList) => (
                    <div
                      {...paramsList}
                      className={`${paramsList.className} div-scroll`}
                      onScroll={onScrollListOrg}
                    >
                      {paramsList?.children?.map((i) => (
                        <li
                          {...i?.props}
                          key={i?.key}
                          className={`${i?.props?.className} scroll-item`}
                        />
                      ))}
                      {loadingAutocomplete?.org && (
                        <div
                          style={{
                            fontWeight: "600",
                            color: "#0B7CE4",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                        >
                          Đang tải thêm ...{" "}
                        </div>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
          </Grid>
          <Grid xs={6} sm={7} md={9}>
            <FormCreateGCN state={state} dispatch={dispatch} />
          </Grid>
        </Grid>
        <div className={classes.box_table_beneficiary}>
          <div className="title">
            <span>Thông tin người được hưởng bảo hiểm</span>
            <div>
              <Button className="buttons btn-add-user" onClick={handleDownload}>
                {" "}
                <VerticalAlignBottomOutlinedIcon
                  fontSize="small"
                  sx={{ marginRight: "6px" }}
                />{" "}
                Tải template
              </Button>
              <Button
                className="buttons btn-add-user"
                onClick={() =>
                  dispatch({
                    type: "openDialogUserCreateGCN",
                    name: "import_file",
                  })
                }
              >
                <img
                  src={Icon_upload}
                  alt="icon_upload"
                  style={{ marginRight: "8px" }}
                />
                Import file
              </Button>
              <Button
                className="buttons btn-dow-file"
                onClick={() =>
                  dispatch({
                    type: "openDialogUserCreateGCN",
                    name: "handmade",
                  })
                }
              >
                <GroupAddOutlinedIcon sx={{ width: "15px" }} /> Thêm người
              </Button>
            </div>
          </div>

          {_.isEmpty(listOfInsuredPeople) ? (
            <div className={classes.box_empty}>
              <img src={Icon_empty} alt="empty" />
              <div>Trống</div>
            </div>
          ) : (
            <CommonTable
              columns={columns}
              rows={listOfInsuredPeople}
              classes={classes}
            />
          )}
        </div>
      </div>
      <DialogAddUserCreate {...{ state, dispatch }} />
      <ImportExcel {...{ state, dispatch }} />
      {/* <Loading loading={loading} /> */}
      <LoadingMui loading={loading} />
    </div>
  );
}
