import React, { useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import useReducer from "../reducer";
import { useLocation } from "react-router-dom";
import insuranceReducer from "./insuranceReducer";
import { useStyles } from "./styles";
import BeneficiaryList from "./components/beneficiaryList";
import VolatilityReport from "./components/volatilityReport";
import SavingsInsurance from "./components/savingsInsurance";
import { Wrapper } from "../../carAccident/styles";

const queryString = require("query-string");

const initialState = {
  loading: false,
  activePage: 0,
  beneficiaryList: [],
  orderList: [],
  insuranceData: {},
  propsList: {},
  reportData: { showDate: 0 },
  filter: {},
  openSendMail: false,
  errEmail: false,
  success: false,
  snackbar: { open: false, mes: "" },
};

const renderPage = (activePage, { state, dispatch }) => {
  switch (activePage) {
    case 0:
      return <BeneficiaryList {...{ state, dispatch }} />;
    case 1:
      return <VolatilityReport {...{ state, dispatch }} />;
    case 2:
      return <SavingsInsurance {...{ state, dispatch }} />;
  }
};

export default function InsuranceContract(props) {
  const location = useLocation();
  const classes = useStyles();
  const [state, dispatch] = useReducer({
    reducer: insuranceReducer,
    name: "insuranceReducer",
    initialState,
  });

  useEffect(() => {
    let q = queryString.parse(location?.search);
    !!q.token && localStorage.setItem("token", q.token);
  }, []);

  const { loading, activePage } = state;
  return (
    <Wrapper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {renderPage(activePage, { state, dispatch })}
    </Wrapper>
  );
}
