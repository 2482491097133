import { makeStyles } from "@mui/styles";

const styleMedical = makeStyles((theme) => ({
  div: { paddingBottom: "0px !important", marginTop: "8px" },

  table: {
    minWidth: "600px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      color: "#0B3C8A",
    },

    "& td": {
      color: "##4B4B4B",
      lineHeight: "26px",
    },

    "& th.check-box": {
      width: "40px",
    },

    "& th.medicine-code": {
      width: "130px",
    },

    "& th.tabs": {
      width: "30px",
      padding: 0,
    },

    "& th.quantity": {
      width: "80px",
      textAlign: "center",
    },

    "& th.unit-price": {
      width: "110px",
      textAlign: "center",
    },

    "& th.money": {
      width: "115px",
      textAlign: "center",
    },

    "& th.icon": {
      width: "45px",
      textAlign: "center",
      paddingLeft: "5px",
    },

    "& td.tabs": {
      padding: "0",
      textAlign: "center",
    },

    "& td.quantity": {
      textAlign: "center",
    },

    "& td.unit-price": {
      textAlign: "center",
    },

    "& td.money": {
      textAlign: "center",
    },

    "& td.icon": {
      paddingLeft: "5px",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#E3F3FF !important",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid white",
    background: "#F8F8F8 !important",
  },

  gridBottom: {
    marginBottom: "24px",

    "& .sum": {
      width: "100%",
      padding: "4px 10px",
      background: "#ECECEC",
      color: "#3961A0",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "26px",
    },

    "& .money": {
      width: "100%",
      padding: "4px 24px 4px 0",

      background: "#3961A0",
      color: "white",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "26px",
      textAlign: "end",
    },
  },

  textField: {
    "& .MuiInputBase-input": {
      padding: "6px",
      borderRadius: "8px",
    },

    "& fieldset": {
      border: "none",
      boxShadow: "inset 1px 1px 1px rgb(0 0 0 / 25%)",
      borderRadius: "8px",
    },
  },

  //
}));

export default styleMedical;
