const COLOR = {
	white: '#fff',
	black: '#000',
	red: '#d0011b',
	gray: '#4a4a4a', // text color
	blue: '#189eff',
	lightgray: '#dddddd',
	yellow: '#E16B2F',
};
export default COLOR;
