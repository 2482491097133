import React from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, Popover, TableCell, Button } from "@mui/material";

import CommonTable from "../../../../library/layout/commonTable";
import { code_rules, type_of_indemnify } from "../util";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import editIcon from "../../../../assets/health/edit.svg";
import { _formatMoney } from "../../GCN/util";

const getType = (id, name) => {
  const itemType = code_rules.find((i) => i.id === id) || {};
  const itemIndemnify = type_of_indemnify.find((i) => i.id === id);
  return name === "type" ? itemType?.title || "" : itemIndemnify?.title || "";
};

export default function InsuranceBenefitTable(props) {
  const classes = useMakeStykles();
  const { state, dispatch, name } = props;

  const { payloadProductPackage, popverEdit, productPackage } = state;
  const rows =
    name === "details"
      ? productPackage?.benefits
      : payloadProductPackage?.benefits;

  return (
    <div>
      <CommonTable
        classes={classes}
        rows={rows || []}
        columns={columns}
        data={{ dispatch, name }}
      />
      <Popover
        id={"popover-edit-benefit"}
        open={Boolean(popverEdit?.open)}
        anchorEl={popverEdit?.ref || null}
        onClose={() => {
          dispatch("closePopverEdit");
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: 6 }}>
          <div className={classes.divButton}>
            <Button
              className={classes.buttons}
              onClick={() =>
                dispatch({ type: "openDialogAddBenefit", isEdit: true })
              }
            >
              <img src={editIcon} style={{ marginRight: "8px" }} />
              Chỉnh sửa
            </Button>
          </div>
          <div className={classes.divButton}>
            <Button
              className={classes.buttons}
              onClick={() => dispatch("deleteBennefit")}
            >
              <DeleteOutlineOutlinedIcon
                sx={{ marginRight: "8px", fontSize: "18px" }}
              />
              Xoá
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}

const columns = [
  {
    label: "Mã quyền lợi",
    headerClass: "code",
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst code stickey"
            : item.isSecond
            ? "isSecond code stickey"
            : "code stickey"
        }
      >
        {item?.code_benefit || ""}
      </TableCell>
    ),
  },
  {
    label: "Tên quyền lợi ",
    headerClass: "name-benefits",
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst name-benefits"
            : item.isSecond
            ? "isSecond name-benefits"
            : "name-benefits"
        }
      >
        {item?.title || ""}
        <div
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#4B4B4B",
            lineHeight: "22px",
          }}
        >{`Loại: ${getType(item?.config_benefit?.code_rules, "type")}`}</div>
      </TableCell>
    ),
  },
  {
    label: "Quyền lợi",
    headerClass: "benefits",
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst benefits"
            : item.isSecond
            ? "isSecond benefits"
            : "benefits"
        }
      >
        {+item?.config_benefit?.value >= 0
          ? `${item?.config_benefit?.value || ""}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              "."
            )
          : item?.config_benefit?.value || ""}
        <div
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#4B4B4B",
            lineHeight: "22px",
          }}
        >
          {`${(item?.config_benefit?.type_of_indemnify || [])
            .map((item) => getType(item, "type_of_indemnify"))
            .join(" / ")}`}
          {item?.config_benefit?.code_rules !== 1 ? (
            <span>
              GH phí: {_formatMoney(item?.config_benefit?.limit_fees)}{" "}
              <span style={{ marginLeft: "10px" }}>
                GH {item?.config_benefit?.code_rules === 2 ? " Ngày" : " Lần"}:{" "}
              </span>
              {item?.config_benefit?.code_rules === 2
                ? item?.config_benefit?.limit_day || " -"
                : item?.config_benefit?.limit_number_of_times || " -"}
            </span>
          ) : (
            ""
          )}
        </div>
      </TableCell>
    ),
  },
  {
    label: "",
    headerClass: "edit",
    renderCell: ({ rowIdx, item, data: { dispatch, name } }) => (
      <TableCell
        className={item.isFirst ? "isFirst" : item.isSecond ? "isSecond" : " "}
      >
        {name === "details" ? null : (
          <IconButton
            onClick={(e) =>
              dispatch({ type: "openPopverEdit", item, e, index: rowIdx })
            }
          >
            <MoreHorizOutlinedIcon />
          </IconButton>
        )}
      </TableCell>
    ),
  },
];

const useMakeStykles = makeStyles(() => ({
  div: {
    paddingBottom: "20px",
  },

  table: {
    minWidth: "770px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      textAlign: "center",
      color: "white !important",
      background: "none !important",
    },

    "& td": {
      textAlign: "center",
      padding: 0,
      fontWeight: "600",
    },

    "& .stickey": {
      position: "sticky",
    },

    "& th.code": {
      width: "120px",
      textAlign: "left",
      background: "#3961A0 !important",
    },

    "& th.name-benefits": {
      textAlign: "left",
    },

    "& th.benefits": {
      textAlign: "end",
    },

    "& th.edit": {
      width: "40px",
    },

    "& td.code": {
      padding: "0 5px",
      paddingLeft: "8px",
      textAlign: "left",
      fontWeight: "500",
      background: " white",
    },

    "& td.isFirst": {
      background: "#A8D9FF",
    },

    "& td.isSecond": {
      background: "#E3F3FF",
    },

    "& td.name-benefits": {
      textAlign: "left",
      padding: "0 5px",
    },

    "& td.benefits": {
      textAlign: "end",
      padding: "0 5px",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#3961A0 !important",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #D6DEEB",
    background: "white !important",
  },

  divButton: {
    maxWidth: "350px",
    minWidth: "130px",
    borderBottom: `1px solid #ECECEC`,

    "&:last-child": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },

  buttons: {
    width: "100%",
    color: "#4B4B4B",
    fontSize: "14px",
    cursor: "pointer",
    textTransform: "none",
    display: "flex",
    justifyContent: "start",
    textAlign: "left",
    padding: "8px 0",
    fontWeight: "400",
  },
}));
