import { IconButton, LinearProgress, TextField } from "@mui/material";
import { Button, Collapse } from "antd-mobile";
import React from "react";
import { useStyles } from "../styles/infoStyles";
import { convertDateHour } from "../utils";

import backCmnd from "../../../../../assets/loyalty/cmnd-back.svg";
import frontCmnd from "../../../../../assets/loyalty/cmnd-front.svg";
import deleteIcon from "../../../../../assets/loyalty/delete-img.svg";
import downloadIcon from "../../../../../assets/loyalty/download-file.svg";
import fileIcon from "../../../../../assets/loyalty/file.svg";
import uploadIcon from "../../../../../assets/loyalty/upload.svg";

function InfoDriver({ state, dispatch }) {
  const {
    claimInfo,
    extraInfo,
    destination: { data, index },
    errors,
    loadingImg,
  } = state;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className="header">THÔNG TIN TÀI XẾ VÀ NGƯỜI THÂN</div>
      <div className="title">Thông tin tài xế</div>
      <div className={classes.divInfo}>
        <div className="label">
          CMND/CCCD 2 mặt của tài xế (png,jpg)
          <span style={{ color: "red" }}> *</span>
        </div>
        <div className="row">
          <div className="box-img">
            <input
              type="file"
              hidden
              id={`cmnd-front`}
              accept="image/*"
              onChange={(e) => {
                dispatch({
                  type: "addDriverFile",
                  e,
                  nameType: "front",
                });
              }}
            />
            <div className="frame-img">
              <img
                src={extraInfo.frontCmnd?.path || frontCmnd}
                alt="front"
                className="img"
              />
            </div>
            {!!loadingImg?.front && <LinearProgress />}
            <Button
              disabled={!!loadingImg?.front}
              className="btn"
              onClick={() => {
                window.document.getElementById(`cmnd-front`).click();
              }}
            >
              Ảnh mặt trước
            </Button>
          </div>
          <div className="box-img">
            <input
              type="file"
              hidden
              id={`cmnd-back`}
              accept="image/*"
              onChange={(e) => {
                dispatch({
                  type: "addDriverFile",
                  e,
                  nameType: "back",
                });
              }}
            />
            <div className="frame-img">
              <img
                src={extraInfo.backCmnd?.path || backCmnd}
                alt="front"
                className="img"
              />
            </div>
            {!!loadingImg?.back && <LinearProgress />}
            <Button
              disabled={!!loadingImg?.back}
              className="btn"
              onClick={() => {
                window.document.getElementById(`cmnd-back`).click();
              }}
            >
              Ảnh mặt sau
            </Button>
          </div>
        </div>
        {errors.includes("cmnd") && (
          <div className={classes.textErr}>Vui lòng tải đủ 2 mặt CMND/CCCD</div>
        )}
      </div>
      <div className={classes.divInfo}>
        <div className="label">
          Hợp đồng tài xế ahamove cấp (pdf,png,jpg)
          <span style={{ color: "red" }}> *</span>
        </div>
        <div className="box-file">
          <div className="upload">
            <input
              type="file"
              hidden
              id={`addFile-contract`}
              accept={"application/pdf,.ppt, .pptx, image/*"}
              onChange={(e) => {
                dispatch({
                  type: "addDriverFile",
                  nameType: "contractFiles",
                  e,
                });
              }}
            />
            <div className="row">
              <Button
                disabled={!!loadingImg?.contractFiles}
                className="button"
                onClick={() => {
                  window.document.getElementById(`addFile-contract`).click();
                }}
              >
                Chọn file
              </Button>
              {!!loadingImg?.contractFiles && (
                <div>
                  <img src={uploadIcon} alt="upload" /> Đang tải lên...
                </div>
              )}
            </div>
          </div>
          {(extraInfo?.contractFiles || []).map((item, index) => (
            <div className="border-item" key={index}>
              <div className="row">
                <div className="row">
                  <img
                    src={fileIcon}
                    alt="file"
                    style={{ marginRight: "6px", width: "25px" }}
                  />
                  <div>
                    <div>{item?.originalname || ""}</div>
                    <div className="size">{item?.size || ""}</div>
                  </div>
                </div>
                <div className="row">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      dispatch({
                        type: "downloadFile",
                        path: item?.path,
                        name: item?.originalname,
                      })
                    }
                  >
                    <img src={downloadIcon} alt="download" />
                  </IconButton>

                  <IconButton
                    color="primary"
                    onClick={() =>
                      dispatch({
                        type: "deleteContractFile",
                        index,
                      })
                    }
                  >
                    <img src={deleteIcon} alt="del" />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>
        {errors.includes("contractFiles") && (
          <div className={classes.textErr}>Vui lòng tải file hợp đồng</div>
        )}
      </div>

      {renderDriverInfo(data, extraInfo).map((item, index) => (
        <div key={`driver-${index}`} style={{ marginTop: 20 }}>
          <TextField
            fullWidth
            focused
            size="small"
            placeholder="Nhập thông tin"
            label={
              <div>
                {item.label}
                <span style={{ color: "red" }}> *</span>
              </div>
            }
            onChange={(e) =>
              dispatch({
                type: "onChangeValue",
                name: item.name,
                value: e.target.value,
              })
            }
            value={item.value}
            className={classes.inputText}
          />
          {errors.includes(item.name) && (
            <div className={classes.textErr}>
              Vui lòng nhập đầy đủ thông tin
            </div>
          )}
        </div>
      ))}
      <div className="rowDiv">
        <CustomInput
          label="Loại xe"
          value={data?.driver?.vehicleType}
          classes={classes.readOnly}
        />
        <CustomInput
          label="Biển số xe"
          value={data?.driver?.license}
          classes={classes.readOnly}
          otherStyle={{ margin: "20px 0 0 10px" }}
        />
      </div>
      <CustomInput
        label="Thời gian nhận hàng"
        value={convertDateHour(data?.createdDate)}
        classes={classes.readOnly}
      />
      <CustomInput
        label="Thời gian giao hàng"
        value={convertDateHour(claimInfo?.endDate)}
        classes={classes.readOnly}
      />

      <Collapse defaultActiveKey={["1"]} className={classes.collapse}>
        <Collapse.Panel key="1" title="Thông tin người thân 1">
          {renderInfoRelative1(extraInfo).map((item, index) => (
            <div key={`relate1-${index}`} style={{ marginTop: 20 }}>
              <TextField
                fullWidth
                focused
                size="small"
                placeholder="Nhập thông tin"
                label={
                  <div>
                    {item.label}
                    <span style={{ color: "red" }}> *</span>
                  </div>
                }
                onChange={(e) =>
                  dispatch({
                    type: "onChangeValue",
                    name: item.name,
                    value: e.target.value,
                  })
                }
                value={item.value}
                className={classes.inputText}
              />
              {errors.includes(item.name) && (
                <div className={classes.textErr}>
                  Vui lòng nhập đầy đủ thông tin
                </div>
              )}
            </div>
          ))}
        </Collapse.Panel>

        <Collapse.Panel key="1" title="Thông tin người thân 2">
          {renderInfoRelative2(extraInfo).map((item, index) => (
            <div key={`relate2-${index}`} style={{ marginTop: 20 }}>
              <TextField
                fullWidth
                focused
                size="small"
                placeholder="Nhập thông tin"
                label={
                  <div>
                    {item.label}
                    <span style={{ color: "red" }}> *</span>
                  </div>
                }
                onChange={(e) =>
                  dispatch({
                    type: "onChangeValue",
                    name: item.name,
                    value: e.target.value,
                  })
                }
                value={item.value}
                className={classes.inputText}
              />
              {errors.includes(item.name) && (
                <div className={classes.textErr}>
                  Vui lòng nhập đầy đủ thông tin
                </div>
              )}
            </div>
          ))}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default InfoDriver;

const renderDriverInfo = (data, extraInfo) => [
  {
    label: "Họ và tên",
    name: "driverName",
    value:
      extraInfo?.driverName === ""
        ? extraInfo?.driverName
        : extraInfo?.driverName || data?.driver?.fullName || "",
    style: { textTransform: "capitalize" },
  },
  {
    label: "Số điện thoại",
    name: "driverPhoneNumber",
    value:
      extraInfo?.driverPhoneNumber === ""
        ? extraInfo?.driverPhoneNumber
        : extraInfo?.driverPhoneNumber || data?.driver?.phoneNumber || "",
  },
  {
    label: "Địa chỉ hộ khẩu (theo CMND/CCCD)",
    name: "householdAddress",
    value:
      extraInfo?.householdAddress === ""
        ? extraInfo?.householdAddress
        : extraInfo?.householdAddress || data?.driver?.householdAddress || "",
    style: { textTransform: "capitalize" },
  },

  {
    label: "Quê quán",
    name: "driverHometown",
    value:
      extraInfo?.driverHometown === ""
        ? extraInfo?.driverHometown
        : extraInfo?.driverHometown || data?.driver?.hometown || "",
    style: { textTransform: "capitalize" },
  },
  {
    label: "Nơi ở hiện tại",
    name: "driverAddress",
    value:
      extraInfo?.driverAddress === ""
        ? extraInfo?.driverAddress
        : extraInfo?.driverAddress || data?.driver?.address || "",
    style: { textTransform: "capitalize" },
  },
];

const renderInfoRelative1 = (extraInfo) => [
  {
    label: "Họ và tên",
    name: "relativeName1",
    value: extraInfo?.relativeName1 || "",
    style: { textTransform: "capitalize" },
  },
  {
    label: "Số điện thoại",
    name: "relativePhone1",
    value: extraInfo?.relativePhone1 || "",
  },
  {
    label: "Năm sinh",
    name: "relativeDob1",
    value: extraInfo?.relativeDob1 || "",
  },
  {
    label: "Mối quan hệ với tài xế",
    name: "relationship1",
    value: extraInfo?.relationship1 || "",
  },
];

const renderInfoRelative2 = (extraInfo) => [
  {
    label: "Họ và tên",
    name: "relativeName2",
    value: extraInfo?.relativeName2 || "",
    style: { textTransform: "capitalize" },
  },
  {
    label: "Số điện thoại",
    name: "relativePhone2",
    value: extraInfo?.relativePhone2 || "",
  },
  {
    label: "Năm sinh",
    name: "relativeDob2",
    value: extraInfo?.relativeDob2 || "",
  },
  {
    label: "Mối quan hệ với tài xế",
    name: "relationship2",
    value: extraInfo?.relationship2 || "",
  },
];

const CustomInput = ({ label, value, error, classes, otherStyle }) => (
  <div style={{ marginTop: 20, ...otherStyle }}>
    <TextField
      fullWidth
      focused
      size="small"
      placeholder="Nhập thông tin"
      label={
        <div>
          {label}
          <span style={{ color: "red" }}> *</span>
        </div>
      }
      value={value || ""}
      className={classes}
      InputProps={{
        readOnly: true,
      }}
    />
    {!!error && (
      <div className={classes.textErr}>Vui lòng nhập đầy đủ thông tin</div>
    )}
  </div>
);
