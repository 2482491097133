import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    margin: "55px 16px 16px",
    padding: "20px",
    background: "#fff",
    "& .result": {
      marginTop: 20,
      color: "red",
      fontSize: 17,
    },
    "& .header": {
      color: "#2A3790",
      fontWeight: "600",
      fontSize: 18,
      marginBottom: "20px",
    },
    "& .search": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 20,
    },
    "& .button": {
      background: "#3961A0",
      borderRadius: 8,
      color: "#fff",
      padding: "8px 16px",
      height: 45,
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .icon": {
      marginRight: "8px",
    },
    "& .wrapper": {
      width: "60%",
      display: "flex",
      alignItems: "center",
    },
    "& .textfield": {
      width: "80%",
      marginRight: 16,
    },
    "& .select-type-ins": {
      width: "30%",
      minWidth: "180px",
    },
    "& .scrolling": { textAlign: "center" },
    "& .table-scroll": {
      overflowY: "auto",
      height: "calc(80vh - 20px)",
      margin: "10px 0",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      height: "45px",
    },
  },
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
    },
    "& .MuiDialogContent-root": {
      padding: "0 20px 20px",
    },
    "& .title": {
      color: "#4B4B4B",
      margin: "12px 0 8px",
    },
    "& .center": {
      textAlign: "center",
    },
    "& .button": {
      background: "#3961A0",
      color: "#fff",
      marginTop: 20,
      padding: "10px 40px",
    },
    "& .skip": {
      background: "white",
      color: "#3961A0",
      marginTop: 20,
      marginRight: 20,
      padding: "10px 40px",
      border: "1px solid #DCDCDC",
    },
    "& .checking": {
      background: "#61646D",
    },
    "& .red-text": {
      color: "red",
      fontSize: 15,
      marginTop: 5,
      fontStyle: "italic",
    },
  },
  table: {
    minWidth: "800px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& td": {
      textAlign: "left",
      color: "#4B4B4B",
    },

    "& td.code": {
      padding: "0 8px",
      fontWeight: 500,
      background: "#fff",
      width: "150px",
    },

    "& td.isFirst": {
      background: "#09BAFD",
      fontWeight: 700,
    },

    "& td.isSecond": {
      background: "#E3F3FF",
      fontWeight: 500,
    },

    "& td.name-benefits": {
      padding: "0 8px",
    },

    "& td.edit": {
      width: "60px",
    },
  },
  header: {
    display: "none",
  },
  headerTable: {
    color: "#fff",
    fontSize: 15,
    fontWeight: 600,
    display: "flex",
    flexDirection: "row",
    background: "#3961A0",
    padding: "8px",
    position: "sticky",
    top: 0,
    zIndex: 3,
    "& .code": {
      width: "150px",
    },
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #D6DEEB",
    background: "white !important",
  },
}));
