import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { ThemeProvider, createTheme, Button, IconButton } from "@mui/material";

import styleLicense from "./styleLicense";
import AccordionsLincense from "./accordions";
import BoxImg from "./boxImg";
import Additionalrecords from "./additionalRecords";
import DialogClaimInfor from "./dialogClaimInfor";

import editContained from "../../../../../../assets/health/edit-contained.svg";
import { listTypeOfRisk } from "../../utils";

export default function Lincense(props) {
  const { state, dispatch, level_id } = props;
  const classes = styleLicense();
  const { detail, insuranceTypes } = state;

  const insuranceType =
    insuranceTypes.find((i) => i.id == detail?.extraInfo?.typeOfInsurance) ||
    {};

  const typeOfIndimnity =
    (insuranceType?.typeOfIndimnity || []).find(
      (i) => i.id == detail?.extraInfo?.typeOfIndimnity
    ) || {};

  const typeOfRisk =
    detail?.extraInfo?.typeOfInsurance == 1
      ? (typeOfIndimnity?.typeOfRisk || []).find(
          (i) => i.id == detail?.extraInfo?.typeOfRisk
        )
      : listTypeOfRisk.find((i) => i.id == detail?.extraInfo?.typeOfTreatment);
  const dataRender = [
    {
      headLabel: "Loại bảo hiểm",
      value: insuranceType?.title || "",
      minWidth: 130,
    },
    {
      headLabel: "Loại bồi thường",
      value: typeOfIndimnity?.title || "",
      minWidth: 140,
    },
    {
      headLabel: "Loại rủi ro",
      value: typeOfRisk?.title || "--",
      minWidth: 100,
    },
    {
      headLabel: "Số tiền yêu cầu bồi thường",
      value: detail?.requestAmount
        ? detail?.requestAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : "-",
      minWidth: 220,
    },
  ];

  useEffect(() => {
    dispatch("initialLincense");
  }, []);

  return (
    <div>
      <div className={classes.box_table}>
        <div className={classes.title}>
          <span className="span-title">Thông tin yêu cầu bồi thường</span>
          <div>
            <IconButton onClick={() => dispatch("setOpenDialogClaimInfo")}>
              <img src={editContained} />
            </IconButton>
          </div>
        </div>
        <div style={{ width: "100%", overflow: "auto" }}>
          <div className={classes.table_info_insuran}>
            {dataRender.map((i, index) => (
              <div
                key={`key_div_render_${index}`}
                className="div-column"
                style={{ minWidth: i.minWidth ? i.minWidth : "" }}
              >
                <div className="head-table">{i.headLabel}</div>
                <div className="value-table">{i.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.container}>
        <ThemeProvider
          theme={createTheme({
            breakpoints: {
              values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                xxl: 2250,
              },
            },
          })}
        >
          <Grid container>
            <Grid item xs={5} sm={5} md={4} xl={4} xxl={3}>
              <AccordionsLincense {...{ state, dispatch, level_id }} />
            </Grid>
            <Grid item xs={7} sm={7} md={8} xl={8} xxl={9}>
              <BoxImg {...{ state, dispatch, level_id }} />
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
      <div className={classes.additionalRecords}>
        <div className="title-additional-ecords">Yêu cầu bổ sung hồ sơ</div>
        <Additionalrecords {...{ state, dispatch, level_id }} />
        <div className={classes.buttons}>
          <Button
            className={level_id === 1 ? "buttons" : "buttons disabled"}
            disabled={level_id !== 1}
            onClick={() =>
              dispatch({
                type: "openDialogNoti",
                typeDialog: "warning",
                name: "submitAdditionalRequest",
                textContent: [
                  "Bạn có chắc chắn muốn gửi yêu cầu",
                  "bổ sung chứng từ",
                ],
              })
            }
          >
            Gửi yêu cầu bổ sung
          </Button>
        </div>
      </div>
      <DialogClaimInfor {...{ state, dispatch }} />
    </div>
  );
}
