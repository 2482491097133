import { useState, useMemo } from "react";
import _ from "lodash";

const useReducer = ({ reducer, name = "reducers", initialState, ...data }) => {
  const global = window;
  const [state, setState] = useState({ ...initialState });

  useMemo(() => {
    _.set(global, `reducers.${name}`, {});
  }, []);

  const setValue = (changeState) => {
    if (changeState) {
      const newState = {
        ...state,
        ..._.get(global, `reducers.${name}`, {}),
        ...changeState,
      };
      setState(newState);
      Object.assign(_.get(global, `reducers.${name}`, {}), newState);
    }
  };

  const dispatch = async (params, newState) => {
    const action = typeof params === "string" ? { type: params } : params || {};

    if (!!action?.type && _.isFunction(reducer)) {
      const response = reducer({
        state: {
          ...state,
          ..._.get(global, `reducers.${name}`, {}),
          ...(newState || {}),
        },
        action,
        dispatch,
        ...data,
      });

      const changeState = response();
      setValue(
        changeState instanceof Promise ? await changeState : changeState
      );
    }
  };

  const clearStateGlobal = (initial = {}, isSetState = false) => {
    _.set(global, `reducers.${name}`, { ...initial });
    isSetState && setState({ ...initial });
  };

  return [state, dispatch, clearStateGlobal];
};

export default useReducer;
