import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import styleCI from "./styleCI";
import { converDate } from "../../utils";
import moment from "moment";

export default function PackageInsurance(props) {
  const classes = styleCI();
  const { state } = props;
  const { detail } = state;

  const convertMoney = (money) => {
    return `${money}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const renderData = [
    {
      title: "Số hợp đồng",
      value: detail.certNum || "",
      color: "#3961A0",
      wordBreak: true,
      name: "cerNum",
    },
    {
      title: "Tổng phí bảo hiểm",
      value: `${convertMoney(detail.fees || 0)} VNĐ`,
      color: "#3961A0",
    },
    {
      title: "Tổng quyền lợi",
      value: `${convertMoney(detail.productAmount || 0)} VNĐ`,
      color: "#3961A0",
    },
    {
      title: "Quyền lợi còn lại",
      value: `${convertMoney(detail.totalAmountRemaining || 0)} VNĐ`,
      color: "#3961A0",
    },
    {
      title: "Chủ hợp đồng",
      value: detail?.insuredInfo?.insuredName || "",
      color: "#686868",
    },
    {
      title: "Giới tính",
      value:
        detail?.insuredInfo?.insuredGender === 1
          ? "Nam"
          : detail?.insuredInfo?.insuredGender === 0
          ? "Nữ"
          : "",
      color: "#686868",
    },
    {
      title: "Ngày sinh",
      value: converDate(detail?.insuredInfo?.insuredDob)
        ? moment(converDate(detail?.insuredInfo?.insuredDob)).format(
            "DD/MM/YYYY"
          )
        : "",
      color: "#686868",
    },
    {
      title: "Số  điện thoại",
      value: detail?.insuredInfo?.insuredPhone || "",
      color: "#686868",
    },
    {
      title: "CMND/ CCCD",
      value: detail?.insuredInfo?.insuredPrivateId || "",
      color: "#686868",
    },
    {
      title: "Email",
      value: detail?.insuredInfo?.insuredEmail || "",
      color: "#686868",
    },
    {
      title: "Địa chỉ",
      value: detail?.insuredInfo?.insuredAddress,
      color: "#686868",
    },
    {
      title: "Nơi mua bảo hiểm",
      value: detail?.merchantTitle || "",
      color: "#686868",
    },
    {
      title: "Ngày tham gia đầu tiên",
      value:
        moment(converDate(detail?.beginDate)).format("HH:mm, DD/MM/YYYY ") ||
        "",
      color: "#686868",
    },
    {
      title: "Ngày hiệu lực",
      value:
        moment(converDate(detail?.beginDate)).format("HH:mm, DD/MM/YYYY ") ||
        "",
      color: "#686868",
    },
    {
      title: "Ngày hết hạn",
      value:
        moment(converDate(detail?.endDate)).format("HH:mm, DD/MM/YYYY ") || "",
      color: "#686868",
    },
  ];

  return (
    <div className={classes.container_package_insurance}>
      <Grid container spacing={2}>
        {renderData.map((item, index) => (
          <Grid item key={index} xs={6} sm={4} md={4} lg={3}>
            <div className="item-grid-package-insurance">
              <div style={{ color: "#A0A0A0" }}>{item.title}</div>
              <div
                style={{
                  color: item.color,
                  fontWeight: "600",
                  wordBreak: item.wordBreak ? "break-all" : "",
                }}
                onClick={() => {
                  if (item.name === "cerNum" && detail?.certLink) {
                    window.open(detail?.certLink, "_blank");
                  }
                }}
              >
                {item.value}
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
