import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { TableCell } from "@mui/material";

// import PackageInsurance from "./packageInsurance";
// import TableBeneficiary from "./tableBeneficiary";
// import InsuranceBenefitTable from "./insuranceBenefitsTable";
// import DialogFullWidth from "./DialogMaxWidth";
// import TimeRemaining from "./timeRemaining";
// import TableSick from "./tableSick";
// import zomIcon from "../../../../../../assets/health/zom-icon.svg";
import { detailBLVP } from "../../styles/styleDetailBLVP";
import CommonTable from "../../../../../library/layout/commonTable";
import { converDate } from "../../util";
import moment from "moment";

export default function ContractInfomationBLVP(props) {
  const classes = detailBLVP();
  const { state, dispatch } = props;
  const { detail, rowsBeneficiary } = state;

  const dataInforContract = [
    {
      title: "Chủ hợp đồng",
      value: detail?.insuredInfo?.insuredName || "",
      wordBreak: true,
    },
    {
      title: "Giới tính",
      value:
        detail?.insuredInfo?.insuredGender === 1
          ? "Nam"
          : detail?.insuredInfo?.insuredGender === 0
          ? "Nữ"
          : "",
    },
    {
      title: "Ngày sinh",
      value: converDate(detail?.insuredInfo?.insuredDob)
        ? moment(converDate(detail?.insuredInfo?.insuredDob)).format(
            "DD/MM/YYYY"
          )
        : "",
    },
    {
      title: "Số điện thoại",
      value: detail?.insuredInfo?.insuredPhone || "",
    },
    {
      title: "CMND/ CCCD/ Hộ chiếu",
      value: detail?.insuredInfo?.insuredPrivateId || "",
    },
    {
      title: "Email",
      value: detail?.insuredInfo?.insuredEmail || "",
    },
    {
      title: "Địa chỉ",
      value: detail?.insuredInfo?.insuredAddress || "",
    },
    {
      title: "Nơi mua bảo hiểm",
      value: detail?.merchantTitle || "",
    },
    {
      title: "Ngày tham gia đầu tiên",
      value:
        moment(converDate(detail?.beginDate)).format("HH:mm, DD/MM/YYYY ") ||
        "",
    },
    {
      title: "Ngày hiệu lực",
      value:
        moment(converDate(detail?.beginDate)).format("HH:mm, DD/MM/YYYY ") ||
        "",
    },
    {
      title: "Ngày hết hạn",
      value:
        moment(converDate(detail?.endDate)).format("HH:mm, DD/MM/YYYY ") || "",
    },
  ];

  return (
    <div className={classes.containerTabContractInformation}>
      <div>
        <div className="title-packege-insurance">Thông tin gói bảo hiểm</div>
        <div className={classes.container_package_insurance}>
          <Grid container spacing={2}>
            {dataInforContract.map((item, index) => (
              <Grid item key={index} xs={6} sm={4} md={4} lg={3}>
                <div className="item-grid-package-insurance">
                  <div style={{ color: "#A0A0A0" }}>{item.title}</div>
                  <div
                    style={{
                      color: "#686868",
                      fontWeight: "600",
                      wordBreak: item.wordBreak ? "break-all" : "",
                    }}
                  >
                    {item.value}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <div>
        <div className="title-packege-insurance">
          Danh sách người hưởng bảo hiểm
        </div>
        <div>
          <CommonTable
            rows={rowsBeneficiary}
            columns={columns}
            classes={classes}
          />
        </div>
      </div>
    </div>
  );
}

const columns = [
  {
    label: "STT",
    headerClass: "stt",
    renderCell: ({ rowIdx }) => (
      <TableCell className="stt">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "Họ và tên",
    headerClass: "fullname",
    renderCell: ({ rowIdx, item }) => (
      <TableCell className="">{item.insuredName}</TableCell>
    ),
  },
  {
    label: "Ngày sinh",
    renderCell: ({ rowIdx, item }) => (
      <TableCell>
        {converDate(item.insuredDob)
          ? moment(converDate(item.insuredDob)).format("DD/MM/YYYY")
          : ""}
      </TableCell>
    ),
  },
  {
    label: "Giới tính",
    renderCell: ({ rowIdx, item }) => (
      <TableCell>
        {item.insuredGender === 1
          ? "Nam"
          : item.insuredGender === 0
          ? "Nữ"
          : ""}
      </TableCell>
    ),
  },
  {
    label: "Số CMND/ CCCD/ Hộ chiếu",
    headerClass: "cmnd",
    renderCell: ({ rowIdx, item }) => (
      <TableCell>{item?.insuredPrivateId || ""}</TableCell>
    ),
  },
  {
    label: "Mối quan hệ với người mua BH",
    headerClass: "relationship",
    renderCell: ({ rowIdx, item }) => (
      <TableCell>
        {(item.relationshipWithInsuredPersonId ||
          item.relationshipWithBuyerId) == 1
          ? "Bản thân"
          : item.relationshipWithInsuredPersonName ||
            item.relationshipWithBuyerName ||
            ""}
      </TableCell>
    ),
  },
];
