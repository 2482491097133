import { saveAs } from "file-saver";
import JSZip from "jszip";
import _ from "lodash";
import {
  getDetailIndemnify,
  getFileByUrl,
  updateDetailIndemnify,
  uploadImg,
} from "../../../../../apis/claimAharideApi";
import {
  bone_temporary_out,
  dead,
  hurt_forever,
  temporary_in,
  temporary_out,
} from "../../../../claim/create/aharide/components/create/contantsUpload";
import History from "./../../../../../configs/history";

const getItemImg = ({ data, index, id }) => {
  switch (id) {
    case 300:
      return {
        name: `Hình chụp CMND - CCCD ${index + 1}`,
        path: data.path,
      };
    case 301:
      return {
        name: `Hình chụp giấy đăng ký, đăng kiểm, giấy phép lái xe (cavet xe) ${
          index + 1
        }`,
        path: data.path,
      };
    case 302:
      return {
        name: `Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và dấu - mộc bệnh viện - phòng khám - cơ sở y tế ${
          index + 1
        }`,
        path: data.path,
      };
    case 303:
      return {
        name: `Hình chụp chỉ định chụp XQ, MRI… hoặc các xét nghiệm tương tự ${
          index + 1
        }`,
        path: data.path,
      };
    case 304:
      return {
        name: `Hình chụp phiếu đọc kết quả phim XQ, MRI… hoặc các xét nghiệm tương tự ${
          index + 1
        }`,
        path: data.path,
      };
    case 305:
      return {
        name: `Hình chụp hoá đơn điều trị ${index + 1}`,
        path: data.path,
      };
    case 306:
      return {
        name: `Quan hệ với người được bảo hiểm ${index + 1}`,
        path: data.path,
      };
    case 307:
      return {
        name: `Phiếu chỉ định của bác sĩ đối với yêu cầu xét nghiệm, phiếu chụp và kết quả chiếu chụp-xét nghiệm ${
          index + 1
        }`,
        path: data.path,
      };
    case 308:
      return {
        name: `Hình chụp giấy ra viện, đơn thuốc xuất viện ${index + 1}`,
        path: data.path,
      };
    case 309:
      return {
        name: `Hình chụp bản kê chi tiết viện phí ${index + 1}`,
        path: data.path,
      };
    case 310:
      return {
        name: `Hình chụp giấy chứng nhận phẫu thuật (nếu có) ${index + 1}`,
        path: data.path,
      };
    case 311:
      return {
        name: `Hình chụp báo cáo y tế - Hồ sơ bệnh án của bệnh viện có kết luận nguyên nhân tử vong (nếu có) ${
          index + 1
        }`,
        path: data.path,
      };
    case 312:
      return {
        name: `Hình chụp giấy chứng nhận thương tật của cơ quan có thẩm quyền ${
          index + 1
        }`,
        path: data.path,
      };
    case 313:
      return {
        name: `Hình chụp CMND - CCCD mặt trước và sau của người thụ hưởng quyền lợi bảo hiểm ${
          index + 1
        }`,
        path: data.path,
      };
    case 314:
      return {
        name: `Hình chụp bản gốc biên bản công an điều tra có kết luận nguyên nhân tử vong - biên bản tai nạn có xác nhận của chính quyền địa phương hoặc cơ quan chủ quản ${
          index + 1
        }`,
        path: data.path,
      };
    // case 315:
    //   return {
    //     name: ` ${index + 1}`,
    //     path: data.path,
    //   };
    case 316:
      return {
        name: `Hình chụp giấy chứng tử ${index + 1}`,
        path: data.path,
      };
    case 317:
      return {
        name: `Hình chụp biên bản phân chia di sản thừa kế có xác nhận của cơ quan chức năng - phường xã ${
          index + 1
        }`,
        path: data.path,
      };
    case 319:
      return {
        name: `Hình ảnh bổ sung ${index + 1}`,
        path: data.path,
      };

    default:
      return null;
  }
};

const getListImgToRender = (extraInfo) => {
  const {
    levelAccident = "",
    typeOfTreatment = "",
    damageAccident = "",
  } = extraInfo;

  if (levelAccident == 1) {
    return [...dead];
  }
  if (levelAccident == 2) {
    return [...hurt_forever];
  }
  if (levelAccident == 3) {
    if (typeOfTreatment == 1 && damageAccident == 1) {
      return [...bone_temporary_out];
    }
    if (typeOfTreatment == 1 && damageAccident == 2) {
      return [...temporary_out];
    }
    if (typeOfTreatment == 2) {
      return [...temporary_in];
    }
  }
  return [];
};

export default function detailReducer({
  state,
  dispatch,
  action,
  roleType,
  id,
}) {
  const cases = {
    initial: async () => {
      dispatch("onLoading");
      const res = {};
      const dataApi = await getDetailIndemnify(id);
      if (dataApi?.data?.statusCode === 200) {
        const data = _.get(dataApi, "data.result") || {};
        const temp = (data?.elements || []).find((i) => i.type_id === 319);

        res.content = temp?.text || "";
        res.approvalAmount = temp?.value || "";
        res.row = data;
        res.initStatusId = _.get(dataApi, "data.result.statusId");
        res.statusId = _.get(dataApi, "data.result.statusId");
        res.updated = [5, 6, 7].includes(res.statusId);
        res.documentsAdditionalImg = Array.isArray(temp?.extra_info?.documents)
          ? temp?.extra_info?.documents
          : temp?.documents || [];

        res.renderListImg = getListImgToRender(data?.extraInfo) || [];
      }

      return {
        ...res,
        loading: false,
        showImg: {},
      };
    },

    back: () => {
      History.goBack();
    },

    onchangeContent: () => {
      const { e } = action;
      return {
        content: e.target.value,
        errors: { ...state.errors, errorContent: !e.target.value },
      };
    },

    onchangeApprovalAmount: () => {
      const { e } = action;
      const value = e.target.value.replace(/\./g, "");
      return {
        approvalAmount: value,
        errors: {
          ...state.errors,
          approvalAmount: !value && value !== 0,
        },
      };
    },

    changeStatusId: () => {
      const { e } = action;
      const { errors, statusId } = state;

      const res = {};
      if (+e.target.value === 7) {
        res.approvalAmount = 0;
        res.errors = { ...errors, approvalAmount: false };
      }

      if (statusId === 7) {
        res.approvalAmount = null;
      }

      if (+e.target.value === 4) {
        res.errors = { ...errors, approvalAmount: false };
      }

      return { statusId: +e.target.value, ...res };
    },

    update: () => {
      const { content, approvalAmount, statusId } = state;
      if (
        !content ||
        (!approvalAmount && +statusId !== 4 && approvalAmount !== 0)
      ) {
        return {
          errors: {
            ...state.errors,
            errorContent: !content,
            approvalAmount:
              !approvalAmount && +statusId !== 4 && approvalAmount !== 0,
          },
        };
      }
      return { confirmDialog: true };
    },

    onSubmitUpdate: async () => {
      const { content, statusId, approvalAmount, row, documentsAdditionalImg } =
        state;
      dispatch("onLoading");

      const params = {
        claimId: `${row.id}`,
        elements: [
          {
            typeId: 319,
            typeCode: "action_element",
            extraInfo: {
              documents: documentsAdditionalImg || [],
            },
            text: content,
            value: approvalAmount || null,
          },
        ],
      };

      const action =
        statusId === 4
          ? "amend"
          : statusId === 5
          ? "complete"
          : statusId === 7
          ? "reject"
          : "";

      const dataApi = await updateDetailIndemnify(roleType, action, params);

      const res = {};
      if (dataApi?.data?.statusCode === 200) {
        res.confirmDialog = false;
        res.updated = true;
        res.snackbar = { open: true, message: "Cập nhật thành công" };
        row.statusId = dataApi?.data?.result?.status_id;
      }
      if (dataApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            open: true,
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
            type: "error",
          },
        };
      }

      return { loading: false, ...res, row };
    },

    additionalImg: async () => {
      const { e } = action;
      const { documentsAdditionalImg } = state;
      const file = e.target.files[0];
      const data = new FormData();

      if (!file) {
        return;
      }

      data.append("document", file);

      dispatch("onLoading");
      const api = await uploadImg(data);

      if (api instanceof Error) {
        return { loading: false };
      }

      const res = {};
      if (api?.data?.statusCode === 200) {
        const dataApi = _.get(api, "data.result[0]") || {};
        res.documentsAdditionalImg = [
          ...documentsAdditionalImg,
          { path: dataApi?.path, size: dataApi?.size },
        ];
      }

      return { loading: false, ...res };
    },

    deleteAdditionalImg: () => {
      const { indexImg } = action;
      const { documentsAdditionalImg } = state;
      const res = {};

      documentsAdditionalImg.splice(indexImg, 1);

      return { documentsAdditionalImg, ...res };
    },

    closeConfirm: () => ({
      confirmDialog: false,
    }),

    showDialog: () => {
      const { indexImg, listImg, name } = action;
      const { showImg } = state;

      if (name === "additionalImg" || name === "relationship") {
        showImg.listImg = listImg?.map((i) => i.path);
        showImg.path = listImg[indexImg].path;
      } else {
        showImg.listImg = listImg?.map((i) => i.path);
        showImg.path = showImg?.listImg[indexImg];
      }

      showImg.indexImg = indexImg;
      showImg.open = true;

      return { showImg };
    },

    changeIndexImg: () => {
      const { showImg } = state;
      const { name } = action;
      const res = {};
      if (name === "next") {
        const index =
          showImg?.indexImg === showImg?.listImg?.length - 1
            ? 0
            : showImg?.indexImg + 1;

        res.showImg = {
          ...showImg,
          indexImg: index,
          path: showImg?.listImg[index],
        };
      } else {
        const index =
          showImg?.indexImg === 0
            ? showImg?.listImg.length - 1
            : showImg?.indexImg - 1;

        res.showImg = {
          ...showImg,
          indexImg: index,
          path: showImg?.listImg[index],
        };
      }

      return res;
    },

    closeShowImg: () => ({
      showImg: { ...state.showImg, open: false },
    }),

    onLoading: () => ({ loading: true }),

    downLoadZip: async () => {
      const { row } = state;
      const { file } = action;
      dispatch("onLoading");

      const elements = row.elements || [];
      const listPathAndNameImg = [];
      const listImgFileZip = [];
      elements.forEach((item) => {
        if (
          (Array.isArray(item?.extra_info?.documents)
            ? item?.extra_info?.documents
            : item?.documents || []
          )?.length > 0
        ) {
          (Array.isArray(item?.extra_info?.documents)
            ? item?.extra_info?.documents
            : item?.documents || []
          )?.forEach((el, index) => {
            const itemArray = getItemImg({ data: el, index, id: item.type_id });
            !_.isEmpty(itemArray) && listPathAndNameImg.push(itemArray);
          });
        }
      });

      if (listPathAndNameImg.length > 0) {
        await Promise.all(
          listPathAndNameImg.map(async (i) => {
            const dataApi = await getFileByUrl(i.path);
            listImgFileZip.push({ name: i.name, file: dataApi });
          })
        );
      }

      const zip = new JSZip();
      zip.file("Yêu cầu trả tiền bảo hiểm.pdf", file);
      const img = zip.folder("Hình ảnh");
      listImgFileZip.forEach((blob, index) => {
        img.file(`${blob.name}.png`, blob.file, {
          base64: true,
        });
      });
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "Hồ sơ yêu cầu trả tiền bảo hiểm.zip");
      });

      return { loading: false };
    },

    closeSnackbar: () => ({ snackbar: { ...state.snackbar, open: false } }),
    //
  };

  return action?.type && cases[action?.type];
}
