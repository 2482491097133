import { isEmpty } from "lodash";

import {
  // getListProductClaim,
  markReadNotification,
  getListNotification_v2,
  markReadAllNotification,
} from "../../../../../apis/notificationApis";

export default function modalReducer({
  state,
  dispatch,
  action,
  listProductClaims,
  user,
  history,
  onClose,
  notificationes,
  setNotificationes,
}) {
  const systemType = user?.extra_info?.userType;
  const product_parents = user?.role_management_claim?.product_parents || [];

  const cases = {
    initial: async () => {
      dispatch("onLoading");
      const res = {};
      if (!systemType) {
        return { notificationList: [], loading: false };
      }

      const dataAPi = await getListNotification_v2(systemType, {
        limit: 20,
        offset: 0,
      });

      if (dataAPi instanceof Error) {
        return { notificationList: [], loading: false };
      }

      if (dataAPi?.data?.statusCode == 200) {
        const temp = dataAPi?.data?.result || {};
        res.notificationList = temp?.data || [];
        res.pageNoti = {
          totalUnread: temp?.totalUnread,
          total: temp.total,
          totalPage: Math.ceil(temp.total / 20),
          pageNumber: 1,
        };
      }

      return { ...res, loading: false };
    },

    onPaging: async () => {
      const { pageNoti, notificationList } = state;

      if (pageNoti?.pageNumber + 1 > pageNoti?.totalPage) {
        return;
      }
      dispatch("onLoading");

      const params = {
        limit: 20,
        offset: pageNoti?.pageNumber * 20,
      };

      const res = {};

      const dataAPi = await getListNotification_v2(systemType, params);

      if (dataAPi instanceof Error) {
        return { notificationList: [], loading: false };
      }

      if (dataAPi?.data?.statusCode == 200) {
        const temp = dataAPi?.data?.result || {};
        res.notificationList = [...notificationList, ...(temp?.data || [])];
        res.pageNoti = {
          totalUnread: temp?.totalUnread,
          total: temp.total,
          totalPage: Math.ceil(temp.total / 20),
          pageNumber: pageNoti?.pageNumber + 1,
        };
      }

      return { ...res, loading: false };
    },

    handleClickNoti: async () => {
      const { notification } = action;
      const { pageNoti, notificationList } = state;
      let product_code = "";
      const dataMenu = listProductClaims
        ?.find((i) => {
          if (i.product_id == notification?.extra_info?.productIdParent) {
            product_code = i.product_code;
          }
          return i.product_id == notification?.extra_info?.productIdParent;
        })
        ?.childrens?.find((c) => c.role_type == "approve_claim");

      const dataSubmenu = product_parents?.find(
        (i) => i.product_id == notification?.extra_info?.productIdParent
      );

      localStorage.setItem(
        "roleAdminMenuItem",
        JSON.stringify({
          ...dataMenu,
          product_id: +notification?.extra_info?.productIdParent,
          role_management: { ...dataSubmenu },
          userType: systemType,
          product_code,
        })
      );

      switch (+notification?.extra_info?.productIdParent) {
        case 15:
          history.push(`/admin/pba-detail-review?id=${notification?.claim_id}`);
          break;

        case 19:
          history.push(
            `/admin/claim-approve/details/${notification?.claim_id}/tomato`
          );
          break;

        case 24:
          history.push(`/admin/int-detail-review?id=${notification?.claim_id}`);
          break;

        case 32:
          history.push(
            `/admin/aharide/management/detail?id=${notification?.claim_id}`
          );
          break;

        case 34:
          history.push(
            `/admin/loyalty/management/detail?id=${notification?.claim_id}`
          );
          break;

        default:
          history.push(
            `/admin/health/indemnify/detail/${notification?.claim_id}?product_id_parent=${notification?.extra_info?.productIdParent}`
          );
          break;
      }

      onClose();
      const markReadApi = await markReadNotification(notification.id);
      setNotificationes({
        data: [...notificationList],
        total: pageNoti?.total,
        totalUnread: (+pageNoti?.totalUnread > 0
          ? +pageNoti?.totalUnread - 1
          : 0
        ).toString(),
      });
    },

    markAllRead: async () => {
      const { notificationList, pageNoti } = state;
      const dataAPi = await markReadAllNotification();
      const res = {};
      if (dataAPi?.data?.statusCode === 200) {
        res.notificationList = notificationList.map((i) => ({
          ...i,
          is_read: 1,
        }));
        pageNoti.totalUnread = 0;
        setNotificationes({
          ...notificationes,
          totalUnread: 0,
        });
      }

      return { ...res, popoverNoti: false, pageNoti };
    },

    openPopoverNoti: () => ({ popoverNoti: true }),

    closePopoverNoti: () => ({ popoverNoti: false }),

    onLoading: () => ({ loading: true }),

    //
  };

  return cases[action.type] && cases[action.type];
}
