import { makeStyles } from "@mui/styles";

export const styeleds = makeStyles(() => ({
  container: {
    marginTop: "62px",
    padding: "0 12px",
    width: "100%",
  },

  selectComperny: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",

    "& span": {
      marginRight: "16px",
      fontWeight: "600",
    },
  },

  textFieldSelect: {
    width: "300px",

    "& legend": {
      display: "none",
    },

    "& fieldset": {
      top: "0",
    },
  },

  contaiFlagCard: {
    width: "100%",
    margin: "16px 0",
  },

  itemflagCard: {
    width: "100%",
    background: "white",
    border: "1px solid #D6DEEB",
    boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    padding: "12px",
  },

  itemflagCardChart: {
    display: "flex",
    justifyContent: "space-between",

    "& .infor": {
      width: "70%",
    },

    "& .chart": {
      width: "30%",
    },
  },

  containerChart: {
    width: "100%",
    padding: "8px",
    background: "#FFFFFF",
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.18)",
    borderRadius: "12px",
    marginBottom: "24px",
  },

  headerChart: {
    width: "100%",
    margin: "12px 0",

    "& .form-control > .MuiTypography-root ": {
      color: "#686868",
      fontSize: "14px",
    },

    "& .check-box": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      "& .no-1": {
        color: "#3961A0 !important",
      },
      "& .no-2": {
        color: "#FFA21F !important",
      },
      "& .no-3": {
        color: "#069D27 !important",
      },
      "& .no-4": {
        color: "#FF003D !important",
      },
    },

    "& .short-date": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "16px 0",

      "& .short": {
        display: "flex",
        alignItems: "center",
      },
    },
  },

  textFieldStandard: {
    width: "150px",
  },

  //
}));
