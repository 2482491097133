import * as React from "react";
import { Dialog, DialogContent, Slide, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import success from "../../../../../assets/health/success.svg";
import error from "../../../../../assets/health/error.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogNoti(props) {
  const { dispatch, data } = props;
  const classes = makeStyle();

  return (
    <Dialog
      open={!!data.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() =>
        data.type === "success" ? null : dispatch("closeDialogNoti")
      }
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialog}
    >
      <DialogContent style={{ padding: "20px" }}>
        <div className={classes.divContent}>
          {data.type === "success" && (
            <>
              <img
                src={success}
                alt="success"
                width={"50px"}
                style={{ maxWidth: "140px" }}
              />
              <div className="text">
                <span>{data.textContent}</span>
              </div>
            </>
          )}
          {data.type === "error" && (
            <>
              <img
                src={error}
                alt="wait"
                width={"50px"}
                style={{ maxWidth: "140px" }}
              />
              <div className="text">
                <span>Hồ sơ của bạn đã không </span>
                <span>được gửi đi thành công</span>
              </div>
            </>
          )}
        </div>
        <div className={classes.button}>
          {data.type === "success" && (
            <Button
              style={{ ...styleButton, width: "50%" }}
              onClick={() => window.location.reload()}
            >
              Đóng
            </Button>
          )}
          {data.type === "error" && (
            <Button
              style={{ ...styleButton, width: "50%" }}
              onClick={() => dispatch("closeDialogNoti")}
            >
              Đóng
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

const styleButton = {
  width: "40%",
  color: "white",
  textTransform: "none",
  background: "#0B3C8A",
  fontWeight: "600",
  borderRadius: "12px",
};

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
      width: "75%",
      maxWidth: "550px",
      margin: 0,
    },
  },

  divContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .text": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
      margin: "12px 0",
    },
  },

  button: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));
