// DEV
// const SERVER = "https://claim-api-dev.globalcare.vn";
// const CORE_SERVER = "https://core-dev.globalcare.vn";

const SERVER = process.env.REACT_APP_SERVER;
const CORE_SERVER = process.env.REACT_APP_CORE_SERVER;

// BETA
// const SERVER = "https://claim-api-beta.globalcare.vn";
// const CORE_SERVER = "https://core-beta.globalcare.vn";

// LIVE
// const SERVER = 'https://claim-api.globalcare.vn';
//const CORE_SERVER = "https://core.globalcare.vn";

// DEV`
// const SERVER = 'http://localhost:6966';
// const CORE_SERVER = "https://core-dev.globalcare.vn";

const exportedObject = {
  SERVER,
  CORE_SERVER,
};

export default exportedObject;
