import {
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import moment from "moment";
import Grid from "@mui/material/Unstable_Grid2";
import NumberFormat from "react-number-format";

import logo_defaul from "../../../../assets/health/logo_defaul.svg";
import { accountDetail } from "../styles/styleDetailAccount";
import { TextField, Switch, CheckBox } from "../overrideMui/index";
import { get, isEmpty } from "lodash";
import {
  Close as CloseIcon,
  FilterList as FilterListIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";

export default function InforAccount(props) {
  const classes = accountDetail();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const userTypeFromProfile = user?.extra_info?.userType || "";
  const { state, dispatch, userId } = props;
  const {
    roleGroupByOrg,
    payloadAcount,
    listProducts,
    roleGroup,
    errors,
    listProviderBVKP,
    oldListProviderBVKP,
    loadingAutocomplete,
    payloadListAutocomplete,
  } = state;
  //CLAIM_APPROVE

  const dataCheckBox = [
    { label: "BH con người", id: 1, code: "con_nguoi" },
    { label: "BH tài sản", id: 2, code: "tai_san" },
    { label: "BH chuyến", id: 3, code: "chuyen" },
  ];

  const isGCAdmin =
    payloadAcount?.extra_info?.userType === "globalcare" &&
    payloadAcount?.extra_info?.user_title_id === 1;

  const isGCUser =
    payloadAcount?.extra_info?.userType === "globalcare" &&
    payloadAcount?.extra_info?.user_title_id === 2;

  const isTpagAdmin =
    payloadAcount?.extra_info?.userType === "tpag" &&
    payloadAcount?.extra_info?.user_title_id === 1;

  const isTpagUser =
    payloadAcount?.extra_info?.userType === "tpag" &&
    payloadAcount?.extra_info?.user_title_id === 2;

  const isTpasAdmin =
    payloadAcount?.extra_info?.userType === "tpas" &&
    payloadAcount?.extra_info?.user_title_id === 1;

  const isTpasUser =
    payloadAcount?.extra_info?.userType === "tpas" &&
    payloadAcount?.extra_info?.user_title_id === 2;

  const isTpaAdmin =
    payloadAcount?.extra_info?.userType === "tpa" &&
    payloadAcount?.extra_info?.user_title_id === 1;

  const isTpaUser =
    payloadAcount?.extra_info?.userType === "tpa" &&
    payloadAcount?.extra_info?.user_title_id === 2;

  const isMerchant = payloadAcount?.extra_info?.userType === "merchant";

  const isBVPK = payloadAcount?.extra_info?.userType === "bvpk";

  const newListProducts = isTpagAdmin || isTpaAdmin ? [] : listProducts;

  const showOptionProduct = () => {
    switch (userTypeFromProfile) {
      case "globalcare":
      case "tpa":
      case "tpag":
      case "tpas":
        if (isTpagUser && roleGroup?.privileges?.CLAIM_APPROVE === 1) {
          return {
            listLevel: [{ label: "Cấp 5", id: 5 }],
            showLevel: true,
            disabledLevel: true,
            showApproveFrom: false,
            showApproveTo: false,
            onlyShowApproveTo: false,
          };
        }

        if (isTpagUser) {
          return {
            listLevel: [],
            showLevel: false,
            disabledLevel: false,
            showApproveFrom: false,
            showApproveTo: false,
            onlyShowApproveTo: false,
          };
        }

        if (isTpasAdmin) {
          return {
            listLevel: [],
            showLevel: false,
            disabledLevel: false,
            showApproveFrom: false,
            showApproveTo: false,
            onlyShowApproveTo: true,
          };
        }

        if (
          (isTpasUser || isTpaUser) &&
          roleGroup?.privileges?.CLAIM_APPROVE === 1 &&
          roleGroup?.privileges?.CLAIM_CHECK === 1
        ) {
          return {
            listLevel: [
              { label: "Cấp 1", id: 1 },
              { label: "Cấp 2", id: 2 },
              { label: "Cấp 3", id: 3 },
              { label: "Cấp 4", id: 4 },
            ],
            showLevel: true,
            disabledLevel: false,
            showApproveFrom: false,
            showApproveTo: false,
            onlyShowApproveTo: true,
          };
        }

        if (
          (isTpasUser || isTpaUser) &&
          roleGroup?.privileges?.CLAIM_APPROVE === 1
        ) {
          return {
            listLevel: [
              { label: "Cấp 2", id: 2 },
              { label: "Cấp 3", id: 3 },
              { label: "Cấp 4", id: 4 },
            ],
            showLevel: true,
            disabledLevel: false,
            showApproveFrom: false,
            showApproveTo: false,
            onlyShowApproveTo: true,
          };
        }

        if (
          (isTpasUser || isTpaUser) &&
          roleGroup?.privileges?.CLAIM_CHECK === 1
        ) {
          return {
            listLevel: [{ label: "Cấp 1", id: 1 }],
            showLevel: true,
            disabledLevel: true,
            showApproveFrom: false,
            showApproveTo: false,
            onlyShowApproveTo: false,
          };
        }

        if (isTpasUser || isTpaUser) {
          return {
            listLevel: [],
            showLevel: false,
            disabledLevel: false,
            showApproveFrom: false,
            showApproveTo: false,
            onlyShowApproveTo: false,
          };
        }

        if (isMerchant || isBVPK) {
          return {
            listLevel: [],
            showLevel: false,
            disabledLevel: false,
            showApproveFrom: false,
            showApproveTo: false,
            onlyShowApproveTo: false,
          };
        }

      default:
        return {
          listLevel: [],
          showLevel: false,
          showApproveFrom: false,
          showApproveTo: false,
          onlyShowApproveTo: false,
        };
    }
  };

  return (
    <div>
      <div className={classes.containerInforAccount}>
        <div className={classes.title_label}>Thông tin tài khoản</div>
        <div className="box-conten">
          <Grid container spacing={3}>
            <Grid lg={6} xs={12}>
              <div className="label">Tên tài khoản</div>
              <TextField
                fullWidth
                sx={{
                  maxWidth: "450px",
                  "& .MuiInputBase-input": { textTransform: "capitalize" },
                }}
                error={errors.includes("fullname")}
                value={payloadAcount?.fullname || ""}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeInforAccount",
                    name: "fullname",
                    value: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid lg={6} xs={12}>
              <div className="label">User đăng nhập</div>
              <TextField
                fullWidth
                sx={{ maxWidth: "450px" }}
                error={errors.includes("username")}
                value={payloadAcount?.username || ""}
                disabled={userId !== "new"}
                onChange={(e) => {
                  if (
                    /^[a-z0-9_.,@-]+$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    dispatch({
                      type: "onChangeInforAccount",
                      name: "username",
                      value: (e.target.value || "").replace(/\s/g, ""),
                    });
                  }
                }}
              />
            </Grid>
            <Grid lg={6} xs={12}>
              <div className="label">Email</div>
              <TextField
                fullWidth
                sx={{ maxWidth: "450px" }}
                error={errors.includes("email")}
                value={payloadAcount?.email || ""}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeInforAccount",
                    name: "email",
                    value: (e.target.value || "").replace(/\s/g, ""),
                  })
                }
              />
            </Grid>
            <Grid lg={6} xs={12}>
              <div className="label">Số điện thoại</div>
              <TextField
                fullWidth
                sx={{ maxWidth: "450px" }}
                value={payloadAcount?.phone_number || ""}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeInforAccount",
                    name: "phone_number",
                    value: (e.target.value || "").replace(/\s/g, ""),
                  })
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        className={classes.containerInforAccount}
        style={{ marginTop: "12px" }}
      >
        <div className={classes.title_label}>Chọn nhóm quyền</div>
        <div className="box-conten">
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={payloadAcount?.extra_info?.role_group_id || null}
            onChange={(e) => {
              dispatch({
                type: "onChangeTypeAccount",
                name: "role_group_id",
                value: e.target.value,
              });
            }}
          >
            {roleGroupByOrg.map((item, index) => (
              <FormControlLabel
                key={`form_control_label_radio_${index}`}
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    color: "#4B4B4B",
                  },
                }}
                style={{ minWidth: "20%" }}
                control={<Radio />}
                value={item?.id || ""}
                label={item.title}
              />
            ))}
          </RadioGroup>
        </div>
      </div>
      <div
        className={classes.containerInforAccount}
        style={{ marginTop: "12px" }}
      >
        <div className={classes.title_label}>Chọn loại bảo hiểm</div>
        <div className="box-conten">
          {dataCheckBox.map((item, index) => (
            <FormControlLabel
              key={`type_of_insurance_for_user_checkbox_${index}`}
              sx={{
                marginTop: "6px",
                marginLeft: "1px",
                marginRight: "66px",
                "& .MuiTypography-root": {
                  fontSize: "14px",
                  color: "#4B4B4B",
                  marginLeft: "12px",
                },
              }}
              // value="female"
              control={
                <CheckBox
                  checked={Boolean(
                    (
                      payloadAcount?.extra_info?.insurance_codes || []
                    )?.includes(item.code)
                  )}
                  disabled={Boolean(
                    payloadAcount?.extra_info?.userType === "bvpk"
                  )}
                  onChange={(e) => {
                    dispatch({
                      type: "onCheckTypeOfInsuranceForUser",
                      code: item.code,
                      value: e.target.checked,
                    });
                  }}
                />
              }
              label={item.label}
            />
          ))}
        </div>
      </div>
      {payloadAcount?.extra_info?.userType === "bvpk" && (
        <div
          className={classes.containerInforAccount}
          style={{ marginTop: "12px" }}
        >
          <div className={classes.title_label}>Nhà bảo hiểm</div>
          <div className="box-conten">
            <Grid container spacing={3}>
              {listProviderBVKP.map((item, index) => (
                <Grid xs={12} md={4} xl={3}>
                  <FormControlLabel
                    key={`item_provider_checkbox_${index}`}
                    sx={{
                      marginLeft: "1px",
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                        color: "#4B4B4B",
                        marginLeft: "12px",
                      },
                    }}
                    control={
                      <CheckBox
                        checked={Boolean(
                          get(
                            payloadAcount,
                            "role_management.claim.providers",
                            []
                          ).includes(+item.id)
                        )}
                        onChange={(e) => {
                          dispatch({
                            type: "onCheckProviderBVPK",
                            item,
                            value: e.target.checked,
                          });
                        }}
                      />
                    }
                    label={item.title}
                  />
                </Grid>
              ))}
            </Grid>
            {!loadingAutocomplete?.provider && (
              <div className={classes.box_show_move}>
                {(payloadListAutocomplete?.provider?.page * 20 <
                  +payloadListAutocomplete?.provider?.total ||
                  oldListProviderBVKP.length > listProviderBVKP.length) && (
                  <div
                    className="move"
                    onClick={() => {
                      dispatch("getMoveListProvider");
                    }}
                  >
                    Hiển thị thêm{" "}
                    <ExpandMoreIcon color="#0B7CE4" sx={{ width: "20px" }} />
                  </div>
                )}
                {listProviderBVKP.length > 20 && (
                  <div
                    className="collap"
                    onClick={() => {
                      dispatch("collapListProvider");
                    }}
                  >
                    Thu gọn{" "}
                    <ExpandLessIcon color="#0B7CE4" sx={{ width: "20px" }} />
                  </div>
                )}
              </div>
            )}
            {loadingAutocomplete?.provider && (
              <div
                style={{
                  fontWeight: "600",
                  color: "#0B7CE4",
                  width: "100%",
                  textAlign: "center",
                  fontSize: "14px",
                  marginTop: "12px",
                }}
              >
                Đang tải thêm ...{" "}
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className={classes.containerInforAccount}
        style={{ marginTop: "12px" }}
      >
        <div className={classes.title_label}>Chọn sản phẩm</div>
        <div className="box-conten">
          <Grid container spacing={3}>
            {newListProducts.map((item, index) => (
              <Grid md={6} xs={12} key={`item_grid_${index}`}>
                <div className={classes.rowProduct}>
                  <FormControlLabel
                    sx={{
                      marginLeft: "1px",
                      marginRight: "6px",
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                        color: "#4B4B4B",
                        marginLeft: "12px",
                      },
                    }}
                    control={
                      <CheckBox
                        checked={Boolean(item?.isCheck)}
                        onChange={(e) =>
                          dispatch({
                            type: "onCheckProductParent",
                            checked: e.target.checked,
                            indexProduct: index,
                            item,
                          })
                        }
                      />
                    }
                    label={
                      <div className={classes.box_img_chekBox}>
                        <img
                          src={item.url_logo ? item.url_logo : logo_defaul}
                          width={25}
                          height={25}
                          style={{ marginRight: "6px", borderRadius: "16px" }}
                          alt="url_logo"
                        />{" "}
                        <div>{item.title}</div>
                      </div>
                    }
                  />
                  {showOptionProduct().showLevel && (
                    <TextField
                      sx={{ minWidth: "80px" }}
                      className={classes.textField_select}
                      select
                      disabled={
                        !Boolean(item?.isCheck) ||
                        (item?.level_id === 5 && !isTpasUser) ||
                        showOptionProduct().disabledLevel
                      }
                      value={item?.level_id || ""}
                      onChange={(e) =>
                        dispatch({
                          type: "onChangeItemProduct",
                          name: "level_id",
                          value: e.target.value,
                          index,
                          item,
                        })
                      }
                    >
                      {(showOptionProduct().listLevel || []).map(
                        (item, index) => (
                          <MenuItem key={item.label} value={item.id}>
                            {item.label}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  )}
                </div>
                {item.level_id !== 1 &&
                  (showOptionProduct().showApproveFrom ||
                    showOptionProduct().showApproveTo ||
                    showOptionProduct().onlyShowApproveTo) && (
                    <div className={classes.approval_level}>
                      {showOptionProduct().showApproveFrom && (
                        <div className="min approval">
                          <div>Mức duyệt tối thiểu</div>
                          <NumberFormat
                            disabled={!Boolean(item?.isCheck)}
                            placeholder="Nhập số tiền"
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            thousandSeparator="."
                            customInput={TextField}
                            className={classes.textField}
                            onChange={(e) => {
                              dispatch({
                                type: "onChangeItemProduct",
                                value: (e.target.value || "").replace(
                                  /\./g,
                                  ""
                                ),
                                name: "approve_from",
                                index,
                                item,
                              });
                            }}
                            value={item.approve_from || ""}
                            fullWidth
                          />
                        </div>
                      )}
                      <div
                        className={
                          showOptionProduct().onlyShowApproveTo
                            ? "max approval fullwidth"
                            : "max approval"
                        }
                      >
                        <div>Mức duyệt tối đa dưới</div>
                        <NumberFormat
                          disabled={!Boolean(item?.isCheck)}
                          placeholder="Nhập số tiền"
                          thousandsGroupStyle="thousand"
                          decimalSeparator=","
                          thousandSeparator="."
                          customInput={TextField}
                          className={classes.textField}
                          onChange={(e) => {
                            dispatch({
                              type: "onChangeItemProduct",
                              value: (e.target.value || "").replace(/\./g, ""),
                              name: "approve_to",
                              index,
                              item,
                            });
                          }}
                          value={item.approve_to || ""}
                          fullWidth
                        />
                      </div>
                    </div>
                  )}
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}

//Boolean(roleGroup?.privileges?.CLAIM_APPROVE)
