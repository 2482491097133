import { makeStyles } from "@mui/styles";

export const listCompanyStyled = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    padding: "24px 12px 12px 12px",
    borderRight: "1px solid #C0C0C0",
  },

  title_list: {
    height: "40px",
    fontSize: "20px",
    color: "#4B4B4B",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
  },

  search_list: {
    width: "100%",
    margin: "12px 0",
  },

  item_list: {
    padding: "12px 6px",
    cursor: "pointer",

    "&:hover": {
      background: "#b0e2ff52",
    },
  },

  active_company: {
    color: "#3961A0",
  },

  //
}));
