import React from "react";
import { Button, MenuItem } from "@mui/material";

import { creatProduct } from "../styles/styleCreatProduct";
import { Switch, TextField } from "../overrideMui";
import import_logo from "../../../../assets/images/import_logo.svg";
import moment from "moment";

const typeInsurance = [{ label: "BH con người" }];

export default function LogoProduct(props) {
  const classes = creatProduct();
  const { state, dispatch, userType, id } = props;

  const { payloadCreate, listOrganization, errors, detailProduct } = state;

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.box_logo}>
        <div className="title-logo">Logo sản phẩm</div>

        <div className="box-logo-item">
          <div style={{ margin: "12px" }}>
            <img
              src={
                payloadCreate?.url_logo ? payloadCreate?.url_logo : import_logo
              }
              width={60}
              height={60}
              style={{ borderRadius: "24px" }}
            />
          </div>
          <div className="note-img">
            <span>Dung lượng file tối đa 1MB</span>
            <span>Định dạng: .JPEG, .PNG </span>
          </div>
          <Button
            className="btn-choose-img"
            onClick={() => {
              document.getElementById("input-upload-img").click();
            }}
          >
            Chọn ảnh
          </Button>
          <input
            type="file"
            hidden
            id="input-upload-img"
            accept="image/png, image/jpeg"
            onChange={(e) => {
              dispatch({ type: "upLoadImg_create", e });
              e.target.files = null;
            }}
          />
        </div>
      </div>
      <div className={classes.box_type_insu}>
        <div className="title">Loại bảo hiểm</div>
        <div className="label-select">Chọn loại bảo hiểm</div>
        <div>
          <TextField
            fullWidth
            select
            defaultValue={"BH con người"}
            disabled={true}
          >
            {typeInsurance.map((item, index) => (
              <MenuItem key={`type_insurance_${index}`} value={item.label}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <div className={classes.box_status}>
        <div className="title">Trạng thái</div>
        <div className="div-switch">
          <span>Trạng thái hoạt động</span>
          <Switch
            checked={Boolean(payloadCreate?.status)}
            onChange={(e) =>
              dispatch({
                type: "onChangePayload_create",
                name: "status",
                value: e.target.checked ? 1 : 0,
              })
            }
          />
        </div>
        <div className="date-label">Ngày tạo</div>
        <div className="date-value">
          {id === "new"
            ? moment(detailProduct?.created_date).format("DD/MM/YYYY, HH:mm")
            : moment(detailProduct?.created_date).format("DD/MM/YYYY, HH:mm")}
        </div>
        {id !== "new" && (
          <>
            <div className="date-label">Ngày cập nhật</div>
            <div className="date-value">
              {detailProduct?.updated_date
                ? moment(detailProduct?.updated_date).format(
                    "DD/MM/YYYY, HH:mm"
                  )
                : "-"}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
