import * as PATH from "../../../../../configs/routesConfig";

import boi_thuong from "../../../../../assets/lauyout/icon_boi_thuong.svg";
import tai_khoan from "../../../../../assets/lauyout/icon_tai_khoan.svg";
import phan_quyen from "../../../../../assets/lauyout/icon_phan_quyen.svg";
import san_pham from "../../../../../assets/lauyout/icon_san_pham.svg";
import gcn from "../../../../../assets/lauyout/icon_gcn.svg";
import ma_nghiep_vu from "../../../../../assets/lauyout/icon_ma_nghiep_vu.svg";
import bao_cao from "../../../../../assets/lauyout/icon_bao_cao.svg";
import dashboard from "../../../../../assets/lauyout/icon_dashboard.svg";

export const getItemSubMenus = (data) => {
  if (data?.product_code === "blvp") {
    return {
      name: "blvp",
      title: "Bảo lãnh viện phí",
      product_id: null,
      product_code: "blvp",
      feature_code: "BLVP",
      childrens: [
        {
          name: "dashboard_blvp",
          path: PATH.BLVP_DASHBOARD,
          title: "Dashboard",
          role_type: "dashboard",
          path_involve: [],
          privileges: [],
        },
        {
          name: "manage_claim_blvp",
          path: PATH.BLVP_RECORDS_MANAGE,
          title: "Quản lý hồ sơ",
          role_type: "manage_claim",
          path_involve: [],
          privileges: ["BLVP_MANAGE"],
        },
        {
          name: "claim_report_blvp",
          path: PATH.BLVP_REPORT,
          title: "Báo cáo",
          role_type: "claim_report",
          path_involve: [],
          privileges: ["BLVP_VIEW"],
        },
      ],
      systemType: ["provider", "globalcare", "department_hospital", "merchant"],
    };
  }

  switch (+data?.id) {
    case 15:
      return {
        name: "PBA",
        title: "BH Phúc Bình An",
        product_id: 15,
        product_code: "pba",
        feature_code: null,
        childrens: [
          {
            name: "review_claim_pba",
            path: PATH.PHUCBINHAN_REVIEW_CLAIM,
            title: "Hồ sơ bồi thường",
            role_type: "approve_claim",
            path_involve: [],
            privileges: [
              "CLAIM_CHECK",
              "CLAIM_APPROVE",
              "CLAIM_PAYMENT_ORDER_UPLOAD",
            ],
          },
          {
            name: "claim_report_pba",
            path: PATH.PHUCBINHAN_BUSINESS_REPORT,
            title: "Báo cáo bồi thường",
            role_type: "claim_report",
            path_involve: [],
            privileges: ["CLAIM_REPORT", "CLAIM_VIEW", "CLAIM_EXPORT"],
          },
        ],
        systemType: ["provider", "globalcare", "merchant"],
      };

    case 19:
      return {
        name: "tomato",
        title: "Tomato",
        product_id: 19,
        product_code: "tomato",
        feature_code: null,
        childrens: [
          {
            name: "dashboard_tomato",
            path: `${PATH.AD_V2_DASHBOARD}/tomato`,
            title: "Dashboard",
            role_type: "dashboard",
            privileges: [],
          },
          {
            name: "business_report_tomato",
            path: `${PATH.AD_V2_BUSINESS_REPORT}/tomato`,
            title: "Báo cáo kinh doanh",
            role_type: "business_report",
            privileges: [],
          },
          {
            name: "claim_report_tomato",
            path: `${PATH.AD_V2_CLAIM_REPORT}/tomato`,
            title: "Báo cáo bồi thường",
            role_type: "claim_report",
            privileges: ["CLAIM_REPORT", "CLAIM_VIEW", "CLAIM_EXPORT"],
          },
          {
            name: "review_claim_tomato",
            path: `${PATH.AD_V2_CLAIM_REVIEW}/tomato`,
            title: "Hồ sơ bồi thường",
            role_type: "claim_approval",
            path_involve: [],
            privileges: [],
          },
          {
            name: "approve_claim_tomato",
            path: `${PATH.AD_V2_CLAIM_APPROVE}/tomato`,
            title: "Duyệt hồ sơ",
            role_type: "approve_claim",
            privileges: [],
          },
        ],
        systemType: [
          "provider",
          "globalcare",
          "department_hospital",
          "merchant",
        ],
      };

    case 24:
      return {
        name: "intransit",
        title: "Intransit",
        product_id: 24,
        product_code: "intransit",
        feature_code: null,
        childrens: [
          {
            name: "review_claim_intransit",
            path: PATH.REVIEW_CLAIM_INTRANSIT,
            title: "Hồ sơ bồi thường",
            role_type: "approve_claim",
            path_involve: [],
            privileges: [
              "CLAIM_CHECK",
              "CLAIM_APPROVE",
              "CLAIM_PAYMENT_ORDER_UPLOAD",
            ],
          },
          {
            name: "claim_report_intransit",
            path: PATH.COMPENSATION_REPORTR_INTRANSIT,
            title: "Báo cáo bồi thường",
            role_type: "claim_report",
            path_involve: [],
            privileges: ["CLAIM_REPORT", "CLAIM_VIEW", "CLAIM_EXPORT"],
          },
        ],
        systemType: ["provider", "globalcare", "merchant"],
      };

    case 32:
      return {
        name: "aharide",
        title: "BH Aharide",
        product_id: 32,
        product_code: "aharide",
        feature_code: null,
        childrens: [
          {
            name: "management",
            path: PATH.AHARIDE_MANAGEMENT,
            title: "Hồ sơ bồi thường",
            role_type: "approve_claim",
            path_involve: [],
            privileges: [
              "CLAIM_CHECK",
              "CLAIM_APPROVE",
              "CLAIM_PAYMENT_ORDER_UPLOAD",
            ],
          },
          {
            name: "report",
            path: PATH.AHARIDE_REPORT,
            title: "Báo cáo bồi thường",
            role_type: "claim_report",
            path_involve: [],
            privileges: ["CLAIM_REPORT", "CLAIM_VIEW", "CLAIM_EXPORT"],
          },
        ],
        systemType: ["provider", "globalcare"],
      };

    case 34:
      return {
        name: "loyalty",
        title: "BH Lòng trung thành",
        product_id: 34,
        product_code: "loyalty",
        feature_code: null,
        childrens: [
          {
            name: "loyalty_management",
            path: PATH.LOYALTY_MANAGEMENT,
            title: "Kiểm tra xử lý hồ sơ",
            role_type: "approve_claim",
            path_involve: [],
            privileges: [
              "CLAIM_CHECK",
              "CLAIM_APPROVE",
              "CLAIM_PAYMENT_ORDER_UPLOAD",
            ],
          },
          {
            name: "loyalty_report",
            path: PATH.LOYALTY_REPORT,
            title: "Báo cáo bồi thường",
            role_type: "claim_report",
            path_involve: [],
            privileges: ["CLAIM_REPORT", "CLAIM_VIEW", "CLAIM_EXPORT"],
          },
        ],
        systemType: ["provider", "globalcare", "merchant"],
      };

    default:
      return {
        name: data?.code || "",
        title: data?.title || "",
        product_id: +data?.id || null,
        product_code: data?.product_code || null,
        feature_code: null,
        childrens: [
          {
            name: `approve_claim_${data?.code || ""}`,
            path: PATH.RECODRS_INDEMNIFY,
            title: "Hồ sơ bồi thường",
            role_type: "approve_claim",
            path_involve: ["/admin/health/indemnify/detail/"],
            privileges: [
              "CLAIM_CHECK",
              "CLAIM_APPROVE",
              "CLAIM_PAYMENT_ORDER_UPLOAD",
            ],
          },
          // {
          //   name: `claim_report_${data?.code || ""}`,
          //   path: `${PATH.HEALTH_REPORT}`,
          //   title: "Báo cáo bồi thường",
          //   role_type: "claim_report",
          //   path_involve: [],
          //   privileges: ["CLAIM_REPORT", "CLAIM_VIEW", "CLAIM_EXPORT"],
          // },
          // {
          //   name: `dashboard_health_${data?.code || ""}`,
          //   path: `${PATH.AD_V2_HEALTH_DASHBOARD}`,
          //   title: "Dashboard",
          //   role_type: "dashboard",
          //   path_involve: [],
          //   privileges: ["CLAIM_DASHBOARD_VIEW"],
          // },
        ],
        systemType: [
          "provider",
          "globalcare",
          "merchant",
          "department_garage",
          "merchant",
        ],
      };
  }
};

export const itemMenu = [
  {
    title: "Quản lý bồi thường",
    childrens: [],
    icon: boi_thuong,
    name: "product_parents",
    stt: 1,
    role_group: [
      "CLAIM_APPROVE",
      "CLAIM_CHECK",
      "CLAIM_DASHBOARD_VIEW",
      "CLAIM_EXPORT",
      "CLAIM_PAYMENT_ORDER_UPLOAD",
      "CLAIM_REPORT",
      "CLAIM_VIEW",
    ],
    systemType: [
      "provider",
      "globalcare",
      "merchant",
      "department_garage",
      "merchant",
      "tpag",
      "tpas",
      "tpa",
      "bvpk",
    ],
  },
  {
    title: "Dashboard",
    stt: 2,
    childrens: [],
    icon: dashboard,
    name: "dashboard",
    role_group: ["CLAIM_DASHBOARD_VIEW"],
    path: PATH.AD_V2_DASHBOARD,
    path_involve: [],
    systemType: [
      "provider",
      "globalcare",
      "department_hospital",
      "merchant",
      "tpag",
      "tpas",
      "tpa",
      "bvpk",
    ],
  },
  {
    title: "Quản lý phân quyền",
    stt: 6,
    childrens: [],
    icon: phan_quyen,
    name: "decentraliza",
    role_group: ["ROLE_GROUP_MANAGE", "ROLE_GROUP_VIEW"],
    path: PATH.AD_V2_DECENTRALIZA,
    path_involve: [],
    systemType: [
      "provider",
      "globalcare",
      "department_hospital",
      "merchant",
      "tpag",
      "tpas",
      "tpa",
      "bvpk",
    ],
  },
  {
    title: "Quản lý tài khoản",
    stt: 5,
    childrens: [],
    icon: tai_khoan,
    name: "account-manager-v2",
    role_group: ["ACCOUNT_MANAGE", "ACCOUNT_VIEW", "ACCOUNT_EXPORT"],
    path: PATH.AD_V2_ACCOUNT_v2,
    path_involve: ["/admin/account-management/detail/"],
    systemType: [
      "provider",
      "globalcare",
      "department_hospital",
      "merchant",
      "tpag",
      "tpas",
      "tpa",
      "bvpk",
    ],
  },
  {
    title: "Sản phẩm",
    stt: 3,
    childrens: [],
    icon: san_pham,
    name: "product-manager-v2",
    path: PATH.AD_V2_PRODUCT_v2,
    role_group: ["PRODUCT_EXPORT", "PRODUCT_MANAGE", "PRODUCT_VIEW"],
    path_involve: [
      "/admin/product-management/create/",
      "/admin/product-management/detail",
      "/admin/product-management/detail-update/",
    ],
    systemType: [
      "provider",
      "globalcare",
      "department_hospital",
      "merchant",
      "tpag",
      "tpas",
      "tpa",
      "bvpk",
    ],
  },
  {
    title: "Quản lý GCN",
    stt: 4,
    childrens: [],
    icon: gcn,
    name: "gcn-manager",
    role_group: ["CERT_EXPORT", "CERT_MANAGE", "CERT_VIEW"],
    path: PATH.AD_V2_GCN_MANAGER,
    path_involve: ["/admin/gcn/create", "/admin/gcn/detail"],
    systemType: [
      "provider",
      "globalcare",
      "department_hospital",
      "merchant",
      "tpag",
      "tpas",
      "tpa",
      "bvpk",
    ],
  },
  // {
  //   title: "Bảo lãnh viện phí",
  //   stt: 9,
  //   childrens: [],
  //   icon: san_pham,
  //   name: "blvp-v2",
  //   role_group: ["BLVP_MANAGE", "BLVP_VIEW"],
  //   path: PATH.AD_V2_MANAGER_BLVP,
  //   path_involve: [],
  //   systemType: [
  //     "provider",
  //     "globalcare",
  //     "department_hospital",
  //     "merchant",
  //     "tpag",
  //     "tpas",
  //     "tpa",
  //     "bvpk",
  //   ],
  // },
  {
    title: "Quản lý mã nghiệp vụ",
    stt: 7,
    childrens: [],
    icon: ma_nghiep_vu,
    name: "qlmnv-v2",
    role_group: ["BENEFIT_MANAGE", "BENEFIT_VIEW", "BLVP_MANAGE", "BLVP_VIEW"],
    path: PATH.AD_V2_BENEFIT_V2,
    path_involve: [],
    systemType: [
      "provider",
      "globalcare",
      "department_hospital",
      "merchant",
      "tpag",
      "tpas",
      "tpa",
      "bvpk",
    ],
  },
  {
    title: "Báo Cáo",
    stt: 8,
    childrens: [],
    icon: bao_cao,
    name: "bao-cao",
    role_group: ["CLAIM_REPORT"],
    path: PATH.AD_V2_REPORT,
    path_involve: [],
    systemType: [
      "provider",
      "globalcare",
      "department_hospital",
      "merchant",
      "tpag",
      "tpas",
      "tpa",
      "bvpk",
    ],
  },
  {
    title: "Tải hồ sơ claim docx",
    stt: 10,
    childrens: [],
    icon: san_pham,
    name: "tai_hs",
    role_group: [],
    path: PATH.AD_V2_DOWLOAD_DOCX,
    path_involve: [],
    systemType: ["globalcare"],
  },
];
