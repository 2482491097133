import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Slide,
  TextField,
} from "@mui/material";
import React from "react";
import { useStyles } from "../useStyles";
import { hardTimes, renderConfig } from "../utils";

const timeArr = hardTimes();
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditDialog(props) {
  const { open, dispatch, state, title } = props;
  const { dataEdit, saving } = state;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"md"}
      onClose={() => dispatch({ type: "onClose", name: title })}
      className={classes.editDialog}
    >
      <DialogTitle>
        {title === "waiting" ? "Thời gian chờ" : "Đồng chi trả"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {renderConfig.map((i) => (
            <Grid item xs={6} sm={3}>
              <div className="title">{i.title}</div>
              {title === "waiting" ? (
                <Autocomplete
                  fullWidth
                  value={dataEdit.extra_info?.config_waiting?.[i.name] || 0}
                  options={timeArr}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Nhập thông tin"
                      size="small"
                    />
                  )}
                  onChange={(e, value) =>
                    dispatch({
                      type: "selectWaitingTime",
                      name: [i.name],
                      value,
                    })
                  }
                />
              ) : (
                <TextField
                  fullWidth
                  value={
                    dataEdit.extra_info?.config_share_payment?.[i.name] || ""
                  }
                  onChange={(e) =>
                    dispatch({
                      type: "selectShareTime",
                      name: i.name,
                      value: e?.target?.value,
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
          ))}
        </Grid>
        <div className="center">
          <Button
            disabled={saving}
            className="button"
            onClick={() => dispatch({ type: "saveTime", name: title })}
          >
            {saving ? "Đang lưu.." : "Cập nhật"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
