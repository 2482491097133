import _ from "lodash";
import moment from "moment";
import { getListIndemnify } from "../../../../../apis/claimAhamoveApi";

import * as PATH from "../../../../../configs/routesConfig";
import { MAXIMUN_LINE_EXPORT_EXCEL_INTRANSIT } from "../../../../../utils/contains";

export default function intransitRrducer({
  state,
  dispatch,
  action,
  roleType,
  form,
  history,
  dataSearchIntransitList,
  setDataSearchIntransitList,
  user,
  embeded,
}) {
  const cases = {
    initial: async () => {
      const res = {};
      dispatch("onLoading");
      const params = _.isEmpty(dataSearchIntransitList?.dataSearch)
        ? {
            insuredPhone: "",
            insuredPrivateId: null,
            certNum: null,
            statusId: null,
            from: moment().subtract(1, "months").toDate(),
            to: moment().toDate(),
            limit: 10,
            offset: 0,
            productIdParent: 24,
            merchantId:
              user?.extra_info?.userType == "merchant"
                ? user?.extra_info?.merchantId
                : null,
          }
        : {
            ...dataSearchIntransitList?.dataSearch,
            merchantId:
              user?.extra_info?.userType == "merchant"
                ? user?.extra_info?.merchantId
                : dataSearchIntransitList?.dataSearch?.merchantId || null,
            limit: dataSearchIntransitList?.pageTable?.rowsPerPage || 10,
            offset:
              dataSearchIntransitList?.pageTable?.rowsPerPage *
                dataSearchIntransitList?.pageTable?.page || 0,
            productIdParent: 24,
          };
      const dataApi = await getListIndemnify(roleType, params);

      res.dataSearch = _.isEmpty(dataSearchIntransitList?.dataSearch)
        ? {
            from: moment().subtract(1, "months").toDate(),
            to: moment().toDate(),
            merchantId:
              user?.extra_info?.userType == "merchant"
                ? user?.extra_info?.merchantId
                : null,
          }
        : {
            ...dataSearchIntransitList?.dataSearch,
            merchantId:
              user?.extra_info?.userType == "merchant"
                ? user?.extra_info?.merchantId
                : dataSearchIntransitList?.dataSearch?.merchantId || null,
          };

      if (dataApi instanceof Error) {
        return { loading: false, ...res };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.rows = _.get(dataApi, "data.result.data") || [];
        res.totalRows = _.get(dataApi, "data.result.total") || 0;
        res.pageTable = {
          count: Math.ceil(dataApi.data.result.total / 10),
          page: dataSearchIntransitList?.pageTable?.page || 0,
          rowsPerPage: dataSearchIntransitList?.pageTable?.rowsPerPage || 10,
        };
      }

      return { ...res, loading: false };
    },

    onChangeSearch: () => {
      const { name, value } = action;
      const { dataSearch } = state;
      dataSearch[name] = value;

      return { dataSearch };
    },

    changePage: async () => {
      const {
        data: { item, changePage },
      } = action;

      if (form === "report-claim" && embeded) {
        history.push(`${PATH.DETAIL_REPORT_INTRANSIT_EMBEDED}?id=${item.id}`);

        return;
      }

      // form === "review-claim" &&
      //   history.push(`${PATH.DETAIL_REVIEW_INTRANSIT}?id=${item.id}`);

      form === "report-claim" &&
        history.push(`${PATH.DETAIL_REPORT_INTRANSIT}?id=${item.id}`);

      form === "review-claim" &&
        history.push(`${PATH.DETAIL_REVIEW_INTRANSIT}?id=${item.id}`);

      // form === "report-claim" &&
      //   history.push(`${PATH.DETAIL_REPORT_INTRANSIT}/intransit?id=${item.id}`);

      // form === "review-claim" &&
      //   history.push(`${PATH.DETAIL_REVIEW_INTRANSIT}/intransit?id=${item.id}`);
    },

    onPaging: async () => {
      const { page, rowsPerPage } = action;
      const { dataSearch, pageTable } = state;

      dispatch("onLoading");

      const params = {
        insuredPhone: dataSearch?.insuredPhone || "",
        insuredPrivateId: dataSearch?.insuredPrivateId || null,
        insuredName: dataSearch?.insuredName || "",
        certNum: dataSearch?.certNum || null,
        statusId: dataSearch?.statusId || null,
        from: dataSearch?.from || null,
        to: dataSearch?.to || null,
        limit: rowsPerPage,
        offset: pageTable.rowsPerPage !== rowsPerPage ? 0 : page * rowsPerPage,
        productIdParent: 24,
        merchantId: dataSearch?.merchantId || null,
      };

      const dataApi = await getListIndemnify(roleType, params);
      const res = {};

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.rows = _.get(dataApi, "data.result.data") || [];
        res.totalRows = _.get(dataApi, "data.result.total") || 0;
        res.pageTable = {
          count: Math.ceil(dataApi.data.result.total / (rowsPerPage || 10)),
          page: pageTable.rowsPerPage !== rowsPerPage ? 0 : page,
          rowsPerPage: rowsPerPage,
        };
        setDataSearchIntransitList({
          dataSearch: { ...dataSearch },
          pageTable: { ...res.pageTable },
        });
      }

      return { ...res, loading: false };
    },

    onSubmitSearch: async () => {
      const { dataSearch, pageTable } = state;
      setDataSearchIntransitList({
        dataSearch: { ...dataSearch },
        pageTable: { ...pageTable },
      });

      dispatch("onLoading");

      const params = {
        insuredPhone: dataSearch?.insuredPhone || "",
        insuredPrivateId: dataSearch?.insuredPrivateId || null,
        insuredName: dataSearch?.insuredName || "",
        code: dataSearch?.code || null,
        statusId: dataSearch?.statusId || null,
        from: dataSearch?.from || null,
        to: dataSearch?.to || null,
        limit: pageTable?.rowsPerPage || 10,
        offset: 0,
        productIdParent: 24,
        merchantId: dataSearch?.merchantId || null,
      };
      const dataApi = await getListIndemnify(roleType, params);

      const res = {};

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.rows = _.get(dataApi, "data.result.data") || [];
        res.totalRows = _.get(dataApi, "data.result.total") || 0;
        res.pageTable = {
          count: Math.ceil(
            dataApi.data.result.total / (pageTable?.rowsPerPage || 10)
          ),
          page: 0,
          rowsPerPage: pageTable?.rowsPerPage,
        };
      }

      return { ...res, loading: false };
    },

    onLoading: () => ({ loading: true }),

    onSubmitExport: () => {
      const { totalRows } = state;

      if (totalRows > +MAXIMUN_LINE_EXPORT_EXCEL_INTRANSIT) {
        return { openMaxLineDialog: true };
      } else {
        dispatch("getListExport");
      }
    },

    getListExport: async () => {
      const { count, dataSearch } = state;
      const res = {};

      dispatch("onLoading");
      const params = {
        insuredPhone: dataSearch?.insuredPhone || "",
        insuredPrivateId: dataSearch?.insuredPrivateId || null,
        insuredName: dataSearch?.insuredName || "",
        code: dataSearch?.code || null,
        statusId: dataSearch?.statusId || null,
        from: dataSearch?.from || null,
        to: dataSearch?.to || null,
        limit: +MAXIMUN_LINE_EXPORT_EXCEL_INTRANSIT,
        offset: 0,
        productIdParent: 24,
        merchantId: dataSearch?.merchantId || null,
        export: 1,
      };

      const dataApi = await getListIndemnify(roleType, params);

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.count = +count + 1;
        res.dataExport = _.get(dataApi, "data.result.data") || [];
      }

      return { ...res, loading: false, openMaxLineDialog: false };
    },

    closeMaxLineDialog: () => ({ openMaxLineDialog: false }),

    //
  };

  return action?.type && cases[action?.type];
}
