import * as React from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CloseIcon from "@mui/icons-material/Close";

import { TextField } from "../../overrideMui";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogClaimInfor(props) {
  const { state, dispatch } = props;
  const classes = makeStyle();

  const { dialogClaimInfo, insuranceTypes, detail, errors } = state;

  return (
    <Dialog
      open={!!dialogClaimInfo.open}
      TransitionComponent={Transition}
      maxWidth={"md"}
      onClose={() => dispatch("setOpenDialogClaimInfo")}
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <span>Thông tin yêu cầu bồi thường</span>
        <IconButton onClick={() => dispatch("setOpenDialogClaimInfo")}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "0 18px 16px 18px" }}>
        <div className={classes.clontainerContent}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  color: "#4B4B4B",
                  fontSize: "14px",
                  paddingBottom: "6px",
                }}
              >
                Loại bảo hiểm
              </div>
              <TextField
                autoComplete="new-password"
                fullWidth
                select
                error={!!errors.insuranceType}
                value={dialogClaimInfo?.insuranceType}
                onChange={(e) =>
                  dispatch({
                    type: "onChangDialogClaimInfo",
                    name: "insuranceType",
                    value: e.target.value,
                  })
                }
              >
                {(insuranceTypes || []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  color: "#4B4B4B",
                  fontSize: "14px",
                  paddingBottom: "6px",
                }}
              >
                Loại bồi thường
              </div>
              <TextField
                autoComplete="new-password"
                fullWidth
                select
                error={!!errors.typeOfIndimnity}
                value={dialogClaimInfo?.typeOfIndimnity}
                onChange={(e) =>
                  dispatch({
                    type: "onChangDialogClaimInfo",
                    name: "typeOfIndimnity",
                    value: e.target.value,
                  })
                }
              >
                {(dialogClaimInfo.typeOfIndimnitys || []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  color: "#4B4B4B",
                  fontSize: "14px",
                  paddingBottom: "6px",
                }}
              >
                Loại điều trị
              </div>
              <TextField
                disabled={dialogClaimInfo.typeOfRisks?.length == 0}
                autoComplete="new-password"
                fullWidth
                select
                error={!!errors.typeOfRisk}
                value={
                  dialogClaimInfo.insuranceType == 1
                    ? dialogClaimInfo?.typeOfRisk
                    : dialogClaimInfo.typeOfTreatment
                }
                onChange={(e) =>
                  dispatch({
                    type: "onChangDialogClaimInfo",
                    name: "typeOfRisk",
                    value: e.target.value,
                  })
                }
              >
                {(dialogClaimInfo.typeOfRisks || []).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div
                style={{
                  color: "#4B4B4B",
                  fontSize: "14px",
                  paddingBottom: "6px",
                }}
              >
                Số tiền yêu cầu bồi thường
              </div>
              <TextField
                disabled
                autoComplete="new-password"
                fullWidth
                value={`${detail?.requestAmount || "-"}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  "."
                )}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">VNĐ</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.buttons}>
          <Button className="buttons" onClick={() => dispatch("updateDetail")}>
            Cập nhật
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
      margin: "24px",
      width: "100%",
    },
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 18px",

    "& .content-title": {
      color: "#4B4B4B",
      fontWeight: "600",
      fontSize: "14px",
    },
  },

  clontainerContent: {
    width: "100%",
    minWidth: "500px",
    // maxHeight: "75vh",
    // overflow: "auto",
  },

  buttons: {
    width: "100%",
    paddingTop: "12px",
    display: "flex",
    justifyContent: "end",

    "& .buttons": {
      textTransform: "none",
      background: "#3961A0",
      color: "white",
      borderRadius: "12px",
      padding: "8px 24px",
      fontWeight: "600",
    },
  },
}));
