import moment from "moment";
import { code_rules } from "../util";

export default function TemplateBenefitExcel(props) {
  const { listBenefits } = props;

  return (
    <>
      <div style={{ display: "none" }}>
        <table
          className="collapse-border"
          id={"template-benefits-table"}
          data-cols-width="7, 25, 25, 37, 25, 25, 25, 25"
        >
          <thead style={{ fontSize: "13" }}>
            <tr />
            <tr />
            <tr>
              <th />
              <th />
              <th>
                Chọn loại rủi ro bao gồm "Tai nạn", "Sức khoẻ", "Tử vong", "Thai
                sản", "TTVV", phân cách nhau bằng "/" nếu nhiều loại.
                {"VD: [Tai nạn] / [Sức khoẻ]  "}
              </th>
              <Th>Không đổi</Th>
              <Th>Không đổi</Th>
              <th>nhập quyền lợi</th>
              <th>nhập giới hạn ngày trên lần</th>
              <th>nhập giới hạn phí</th>
            </tr>
            <tr style={{ fontSize: "13" }}>
              <Th>STT</Th>
              <Th>Mã NV</Th>
              <Th>Loại rủi ro</Th>
              <Th>Tên nghiệp vụ</Th>
              <Th>Loại</Th>
              <Th>Quyền lợi</Th>
              <Th>Số ngày/lần</Th>
              <Th>Giới hạn phí</Th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13" }}>
            {listBenefits?.map((item, index) => {
              return (
                <>
                  <tr key={index} style={{ fontSize: "13" }}>
                    <Td>{index + 1}</Td>
                    <Td bold={item?.config_benefit?.benefit_level === 1}>
                      {item?.code_benefit || ""}
                    </Td>
                    <Td></Td>
                    <Td>{item?.title || ""}</Td>
                    <Td>
                      {code_rules.find(
                        (i) => i.id === item?.config_benefit?.code_rules
                      )?.title || ""}
                    </Td>
                    <Td>{item?.number_of_packages || ""}</Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                    <Td>
                      {item?.status === 1
                        ? "Đang hoạt động"
                        : item?.status === 0
                        ? "Ngưng hoạt động"
                        : ""}
                    </Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                  </tr>
                  {(item?.packages || [])?.map((p, index) => (
                    <tr key={index} style={{ fontSize: "13" }}>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{p?.title}</Td>
                      <Td>{p?.id || ""}</Td>
                      <Td type="n">{p?.fees || ""}</Td>
                      <Td type="n">{p?.product_amount || ""}</Td>
                      <Td type="n">{p?.participants || ""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>
                        {p?.status === 1
                          ? "Đang hoạt động"
                          : p?.status === 0
                          ? "Ngưng hoạt động"
                          : ""}
                      </Td>
                    </tr>
                  ))}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const Th = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-h={"center"}
      data-a-wrap={true}
      data-f-bold={true}
    >
      {props.children}
    </th>
  );
};

const Td = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-wrap={true}
      data-t={props.type || ""}
      data-f-bold={Boolean(props.bold)}
    >
      {props.children}
    </th>
  );
};
