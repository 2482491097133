

import React, { PureComponent } from 'react';
import { BarChart, Bar, Label, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const data = [
  {
    name: 'Vinmart',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Coopmart',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Minitstop',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: '7-Eleven',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Circle K',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Big C',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const renderLegend = (props) => {
  const { payload } = props;
  return (
    <div style={{ display: 'flex', margin: 'auto', width: '50%',  }}>
      {
        payload.map((entry, index) => {
          return (
            <div key={index} style={{ width: '50%' }}>
              <div style={{ display: 'inline-block', height: 20, width: 25, background: entry.color, borderRadius: 6 }}></div>
              <span style={{ verticalAlign: 'text-bottom' }}>  {entry.value === 'uv' ? 'Tiền bồi thường' : 'Doanh thu'}</span>
            </div>
          )
        }
        )
      }
    </div>
  );
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ background: 'white', opacity: 0.8 }}>
        <b>{`${label}`}</b>
        <p>{`Doanh thu: ${payload[0].value} VNĐ`}</p>
        <p>{`Tiền bồi thường: ${payload[1].value} VNĐ`}</p>
      </div>
    );
  }

  return null;
}

export default function Example() {
  return (
    <ResponsiveContainer width='100%' height={400}>
      <BarChart
      className='total-by-merchant'
        data={data}
        margin={{
          top: 20,

          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" orientation="left" stroke="#2A3790" type='number'
          tick={{ fontSize: '16px' }}
          width={100}>
          <Label
            dy={-10}
            position={'top'}
            style={{
              // textAnchor: "start",
              fontSize: "100%",
              fill: "black",
              fontStyle: 'italic',
            }}
            angle={0}
            value={"Triệu đồng"} /></YAxis>
        <YAxis yAxisId="right" orientation="right" stroke="black">
          <Label
          tick={{ fontSize: '16px' }}
          dy={-10}
          position={'top'}
          style={{
            textAnchor: "end",
            fontSize: "90%",
            fill: "black",
            fontStyle: 'italic',
          }}
          angle={0}
          value={"Triệu đồng"} /></YAxis>
        <Tooltip content={CustomTooltip} />
        <Legend iconSize={18} content={renderLegend} align={'center'} />
        <Bar yAxisId="left" dataKey="pv" fill="#2A3790" />
        <Bar yAxisId="right" dataKey="uv" fill="#EBEDFF" />
      </BarChart>
    </ResponsiveContainer>
  );
}