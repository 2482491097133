import React, { useCallback } from "react";
import { DatePicker, Space } from "antd-mobile";

import closeIcon from "../../../../../../assets/loyalty/symbols_close.svg";
import checkIcon from "../../../../../../assets/loyalty/symbols_check.svg";
import "./style.css";

const RenderDatePicker = ({
  open = false,
  min = undefined,
  max = undefined,
  outputFormat = null,
  close,
  onConfirm,
  value,
}) => {
  const now = value ? new Date(value) : new Date();

  // moment(value).format(outputFormat || "DD/MM/YYYY")
  const labelRenderer = useCallback((type, data) => {
    switch (type) {
      case "month":
        return "tháng " + data;
      default:
        return data;
    }
  }, []);

  return (
    <Space align="center">
      <DatePicker
        className="datePicker-loyalty"
        min={min}
        max={max}
        visible={open}
        title="Ngày xảy ra sự cố"
        cancelText={<img src={closeIcon} alt="close" width={16} />}
        confirmText={<img src={checkIcon} alt="check" width={20} />}
        renderLabel={labelRenderer}
        onClose={() => close()}
        onConfirm={(value) => {
          onConfirm(value);
        }}
        defaultValue={now}
      ></DatePicker>
    </Space>
  );
};

export default RenderDatePicker;
