import moment from "moment";

const getParamUrlByKey = (key) => {
  let search = window.location.search;
  let arr = search.split("?");
  let params = new URLSearchParams(arr[1]);
  let platform = params.get(key) || "";
  return platform;
};

const _formatMoney = (str) => {
  if (str) {
    return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return 0;
};

const convertDateHour = (date) => {
  const data = moment(date).format("DD/MM/YYYY HH:mm");
  return data.replace(/,/g, "");
};

const formatDate = (date) => {
  if (!date) return "";
  const newDate = moment(date).format("DD/MM/YYYY");
  return newDate !== "Invalid date" ? newDate : "";
};

const getExtension = (path) => {
  const extension = path?.split(".")?.pop();
  return extension;
};

export {
  getParamUrlByKey,
  _formatMoney,
  convertDateHour,
  formatDate,
  getExtension,
};
