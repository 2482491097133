import React from "react";
import { useHistory } from "react-router-dom";
import useReducer from "../../../../../library/hooks/useReducer";
import loyaltyReducer from "../reducers/loyaltyReducer";
import { getParamUrlByKey } from "../utils";

import BottomButton from "../components/BottomButton";
import HeaderBar from "../components/HeaderBar";
import ChooseDestination from "./ChooseDestination";
import HistoryClaim from "./HistoryClaim";
import HistoryDetail from "./HistoryDetail";
import InfoBooker from "./InfoBooker";
import InfoDriver from "./InfoDriver";
import SearchStep from "./SearchStep";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Success from "./Success";

const initialState = {
  loading: false,
  currentStep: 1,
  codeOrder: "",
  codeOrderList: [],
  claimInfo: {},
  destination: [],
  extraInfo: {},
  errors: [],
  picker: {},
  elements: [],
  disabledBtn: false,
  checkedCommit: false,
  openCertificate: false,
  openDialogNoCerLink: false,
  showImg: false,
  confirmCreate: false,
  loadingImg: {},
  showFileEvidence: {},
  tabsStatus: 2,
  audioList: [],
  statusNotify: null,
};

function Loyalty() {
  const History = useHistory();
  const isApp = getParamUrlByKey("platform") === "app";

  const [state, dispatch] = useReducer({
    reducer: loyaltyReducer,
    name: "loyaltyReducer",
    initialState,
    History,
    resetState: initialState,
  });

  const { disabledBtn, currentStep, extraInfo, checkedCommit } = state;

  const titleBottom = getTitleBottom(currentStep);
  const onGoBack = () => {
    window.ReactNativeWebView.postMessage("onGoBack");
  };

  return (
    <div style={{ maxWidth: "600px", margin: "auto" }}>
      <HeaderBar
        title="Khai báo bồi thường"
        onPress={() =>
          currentStep === 1
            ? onGoBack()
            : dispatch({ type: "onBack", currentStep })
        }
        isApp={isApp}
      />

      <div
        style={{
          minHeight: isApp ? "calc(100vh - 165px)" : "calc(100vh - 125px)",
          padding: 10,
          fontSize: 15,
          color: "#343A40",
        }}
      >
        {getStep({ currentStep, state, dispatch, isApp })}
      </div>
      {currentStep !== 1 && currentStep !== 7 && (
        <BottomButton
          {...{
            disabled: disabledBtn,
            title: titleBottom,
            onClick: () => dispatch("changePageNext"),
          }}
        />
      )}

      {currentStep === 7 && (
        <BottomButton
          {...{
            disabled: !extraInfo.beneficiaryEmail || !checkedCommit,
            title: titleBottom,
            onClick: () => dispatch("changePageNext"),
          }}
        />
      )}
    </div>
  );
}

export default Loyalty;

const getStep = ({ currentStep, state, dispatch, isApp }) => {
  switch (currentStep) {
    case 1:
      return <SearchStep {...{ state, dispatch, isApp }} />;
    case 2:
      return <ChooseDestination {...{ state, dispatch, isApp }} />;
    case 3:
      return <InfoDriver {...{ state, dispatch, isApp }} />;
    case 4:
      return <InfoBooker {...{ state, dispatch, isApp }} />;
    case 5:
      return <Step1 {...{ state, dispatch, isApp }} />;
    case 6:
      return <Step2 {...{ state, dispatch, isApp }} />;
    case 7:
      return <Step3 {...{ state, dispatch, isApp }} />;
    case 8:
      return <Success {...{ state, dispatch, isApp }} />;
    case 9:
      return <HistoryClaim {...{ state, dispatch, isApp }} />;
    case 10:
      return <HistoryDetail {...{ state, dispatch, isApp }} />;
    default:
      return null;
  }
};

const getTitleBottom = (step) => {
  switch (step) {
    case 2:
    case 5:
    case 6:
      return "Tiếp tục";
    case 3:
      return "Xác nhận";
    case 4:
      return "Báo cáo sự cố";
    case 7:
      return "Xác nhận";
    case 8:
      return "Lịch sử khiếu nại";
    case 9:
    case 10:
      return "Quay lại";
    default:
      return null;
  }
};
