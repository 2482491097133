import { makeStyles } from "@mui/styles";

export const dashboardStyle = makeStyles(() => ({
  container: {
    marginTop: "60px",
    padding: "0 12px",
    width: "100%",
  },

  //head_filter

  head_filter: {
    width: "100%",
    fontSize: "15px",

    "& .first-header": {
      display: "flex",
      background: "white",
      padding: "12px",
      alignItems: "center",
      boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.1)",
      justifyContent: "space-between",

      "& .box_select": {
        display: "flex",
        alignItems: "center",
      },

      "& .box_refect": {
        display: "flex",
        alignItems: "center",
      },
    },

    "& .secont-header": {
      marginTop: "8px",
      display: "flex",
      background: "white",
      padding: "12px",
      alignItems: "center",
      boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.1)",
    },
  },

  //head_filter

  //contaiFlagCard -->

  contaiFlagCard: {
    width: "100%",
    margin: "16px 0",
  },

  itemflagCard: {
    width: "100%",
    background: "white",
    // border: "1px solid #D6DEEB",
    boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.1)",
    // borderRadius: "12px",
    padding: "12px",
    minHeight: "150px",

    "& .header-icon": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    "& .icon-right": {
      padding: "8px",
      boxShadow: "1px 2px 6px rgb(141 149 161 / 78%)",
      borderRadius: "10px",
    },

    "& .header-number": {
      marginTop: "8px",
      display: "flex",
      alignItems: "start",
      color: "#0B7CE4",
    },

    "& .header-compare": {
      marginTop: "8px",
      display: "flex",
      alignItems: "center",
      color: "#0B7CE4",
    },
  },

  itemflagCardChart: {
    display: "flex",
    justifyContent: "space-between",

    "& .infor": {
      width: "70%",
    },

    "& .chart": {
      width: "30%",
    },
  },

  //contaiFlagCard <----

  containerChart: {
    background: "white",
    padding: "12px",
    boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.1)",
  },

  headerChart: {
    width: "100%",
    margin: "12px 0",

    "& .form-control > .MuiTypography-root ": {
      color: "#686868",
      fontSize: "14px",
    },

    "& .check-box": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      "& .no-1": {
        color: "#3961A0 !important",
      },
      "& .no-2": {
        color: "#FFA21F !important",
      },
      "& .no-3": {
        color: "#069D27 !important",
      },
      "& .no-4": {
        color: "#FF003D !important",
      },
    },

    "& .short-date": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "16px 0",

      "& .short": {
        display: "flex",
        alignItems: "center",
      },
    },
  },

  textFieldStandard: {
    width: "150px",
  },

  max_height_autocomplete: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      margin: 0,
      marginTop: "12px",
      maxHeight: "300px",

      "& .MuiAutocomplete-listbox": {
        maxHeight: "300px",
      },
    },
  },

  //
}));
