import React from "react";
import { Dialog, IconButton, TableCell, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

import CommonTable from "../../../../library/layout/commonTable";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogListProductAndMerchant(props) {
  const { state, dispatch } = props;
  const classes = useStyle();

  const { dataFiter, dialogListP_M, overviewListProduct, pageListP_M } = state;

  const pageTable = pageListP_M[dialogListP_M?.nameDialog] || {};
  const rows =
    dialogListP_M?.nameDialog === "product"
      ? dialogListP_M?.listProduct || []
      : dialogListP_M?.listMerchant || [];
  return (
    <Dialog
      open={Boolean(dialogListP_M.open)}
      TransitionComponent={Transition}
      maxWidth="90%"
      fullWidth
      className={classes.containerDialog}
      onClose={() => dispatch("cloaseDialogListP_M")}
    >
      <div className={classes.title}>
        <div>
          <span style={{ fontWeight: "600", fontSize: "20px" }}>
            {dialogListP_M?.nameDialog === "product"
              ? "Sản phẩm"
              : "Doanh Nghiệp"}
          </span>
          <span
            style={{
              fontSize: "14px",
              color: "#6685B6",
              marginLeft: "6px",
            }}
          >{` ${dataFiter?.valueTextDate || ""}`}</span>
        </div>
        <IconButton onClick={() => dispatch("cloaseDialogListP_M")}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <CommonTable
        columns={columns}
        rows={rows}
        classes={classes}
        data={{ nameTable: dialogListP_M?.nameDialog || "" }}
        page={{
          count: pageTable?.count || 1,
          page: pageTable?.page || 0,
          onChange: (event, page, rowsPerPage) => {
            dispatch({
              type: "onchangePage",
              page,
              rowsPerPage,
            });
          },
        }}
        event={(type, data) =>
          (ele, ...rest) => {
            dispatch({ type, data, ele, rest });
          }}
      />
    </Dialog>
  );
}

const columns = [
  {
    label: "TH",
    headerClass: "stt",
    renderCell: ({ rowIdx, colIdx }) => (
      <TableCell className={``} key={colIdx}>
        {rowIdx + 1}
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, item, colIdx }) => (
      <TableCell className={``} key={colIdx}>
        {data?.nameTable === "product" ? "Tên sản phẩm" : "Doanh nghiệp"}
      </TableCell>
    ),
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {item.title}
      </TableCell>
    ),
  },
  {
    label: "Tỉ lệ BT",
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {+item?.fees
          ? ((+item?.amount / (+item?.fees || 1)) * 100).toFixed(1)
          : 0}
      </TableCell>
    ),
  },
  {
    label: "YCBT",
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {item?.total_claim}
      </TableCell>
    ),
  },
  {
    label: "Tổng mức BT",
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {`${item.amount || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      </TableCell>
    ),
  },
];

const useStyle = makeStyles(() => ({
  containerDialog: {
    "& .MuiPaper-root": {
      padding: "12px",
    },
  },

  title: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },

  div: {
    paddingBottom: "12px",
    overflow: "auto",
  },

  table: {
    minWidth: "500px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      textAlign: "center",
      color: "#4B4B4B !important",
      background: "#F8F8F8 !important",
      fontWeight: "600",
    },

    "& th.stt": {
      width: "40px",
    },

    "& td": {
      textAlign: "center",
      padding: 0,
      fontWeight: "500",
      background: "white !important",
      borderBottom: "1px solid #ECECEC",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#F8F8F8 !important",
    padding: "0 8px",
    top: 0,
    position: "sticky",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1px solid #D6DEEB",
    background: "#ECECEC !important",
  },

  footer: {
    marginTop: "8px",
  },
}));
