import "../styles/index.css";
import TableToExcel from "@linways/table-to-excel";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Button, DatePicker, Form, Input, message, Select } from "antd";
import {
  BarStatIcon,
  CalendarIcon,
  ExportIcon,
  MerchantIcon,
  MobilePhoneIcon,
  PrivateIdIcon,
  StatusIcon,
  UserIcon,
} from "../components/icon/icon";

import ExportBusinessTable from "../components/table/export/business-table";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import BusinessReportTable from "../components/table/BusinessReport";
import { getUsers } from "../api/account";
import { getInsuredPersons, getOrders } from "../api/business";
import moment from "moment";
import { STATUS } from "../utils/data";
import { getListMerchant } from "../../../../../apis/adminManagerAccountApis";

const { Option } = Select;

export default function BusinessReport() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [params, setParams] = useState({});
  const [exportedData, setExportedData] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [exporting, setExporting] = useState(false);

  const [employees, setEmployees] = useState([]);

  const [render, setRender] = useState(false);
  const [forbidden, setForbidden] = useState(true);

  const getMerchantsApi = async () => {
    const response = await getListMerchant().catch((e) => e);
    if (response instanceof Error) return message.error("Lỗi lấy dữ liệu", 2);

    setMerchants(response.data.result?.data);
  };

  useEffect(() => {
    if (!user) window.location.reload();

    if (
      +user.role_id === 2 ||
      user.extra_info?.userType === "globalcare" ||
      user?.role_management_claim?.submenu?.business_report?.view !== 1
    ) {
      setForbidden(false);
    }
  }, []);

  useEffect(() => {
    if (forbidden) return;

    const values = form.getFieldsValue();
    setParams(values);

    async function callApi() {
      const result = await getUsers({});
      if (result instanceof Error) {
        return message.error("Lỗi lấy danh sách nhân viên", 2);
      }

      setEmployees(result.data.result?.data);

      await getMerchantsApi();

      setRender(true);
    }

    callApi();
  }, [forbidden]);

  useEffect(() => {
    if (!render) return;

    const values = form.getFieldsValue();
    delete values.fromDate;
    delete values.toDate;
    setParams(values);
  }, [render]);

  useEffect(() => {
    if (!render) return;

    async function callApi() {
      const response = await getInsuredPersons({
        ...params,
        page: currentPage,
        limit: pageSize,
        source: "admin",
      });
      if (response instanceof Error) {
        return message.error("Có lỗi xảy ra", 2);
      }
      setData(response.data.result?.data);
      setTotal(response.data.result?.total);
    }

    callApi();
  }, [currentPage]);

  useEffect(() => {
    if (!render) return;

    async function callApi() {
      const response = await getInsuredPersons({
        ...params,
        page: 1,
        limit: pageSize,
      });
      if (response instanceof Error) {
        return message.error("Có lỗi xảy ra", 2);
      }
      setData(response.data.result?.data);
      setTotal(response.data.result?.total);
      setCurrentPage(1);
    }

    callApi();
  }, [pageSize, params]);

  const exportData = async () => {
    if (!params?.fromDate || !params?.toDate) {
      return message.error("Vui lòng chọn ngày", 2);
    }

    if (params?.fromDate?.isAfter(params?.toDate)) {
      return message.error("Ngày bắt đầu phải nhỏ hơn ngày kết thúc", 2);
    }

    if (params?.toDate?.diff(params?.fromDate, "month") > 1) {
      return message.error("Vui lòng chọn thời gian trong 1 tháng", 2);
    }
    setExporting(true);
    let data = [];

    let response = await getOrders({
      ...params,
      page: 1,
      limit: 100,
    }).catch((e) => e);
    if (response instanceof Error) {
      setExporting(false);
      return message.error("Có lỗi xảy ra", 2);
    }
    const total = +response.data?.result?.total;
    data = [...response.data?.result?.data];
    for (let i = 2; i <= Math.ceil(total / 100); i++) {
      response = await getOrders({
        ...params,
        page: i,
        limit: 100,
      }).catch((e) => e);
      if (response instanceof Error) {
        setExporting(false);
        return message.error("Có lỗi xảy ra", 2);
      }

      data = [...data, ...response.data?.result?.data];
    }
    setExportedData(data);
    setTimeout(() => {
      TableToExcel.convert(document.getElementById("business-excel"), {
        name: `bao-cao-kinh-doanh-tu-${params.fromDate?.format(
          "DD/MM/YYYY"
        )}-den-${params.toDate?.format("DD/MM/YYYY")}.xlsx`,
        sheet: {
          name: "Sheet 1",
        },
      });
      setExporting(false);
    }, 1500);
  };

  return (
    <Fragment>
      <Container
        maxWidth={false}
        style={{ marginTop: "55px", marginBottom: "12px" }}
      >
        {forbidden && <>Bạn không có quyền truy cập trang</>}
        {render && (
          <Form form={form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography fontWeight={500} fontSize={16} color="#2A3790">
                  Báo cáo kinh doanh
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={{
                  marginTop: 10,
                }}
              >
                <Grid
                  item
                  container
                  spacing={1}
                  style={{
                    background: "#E9FBFF",
                    borderRadius: "8px",
                    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
                    padding: 25,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      fontWeight={700}
                      textTransform={"uppercase"}
                      color={"#2A3790"}
                      fontSize={20}
                    >
                      Tra cứu báo cáo kinh doanh
                    </Typography>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Typography fontSize={18} color="#2A3790">
                      <UserIcon style={{ verticalAlign: "top" }} />
                      <span> Họ và tên</span>
                    </Typography>
                    <Form.Item name="fullName">
                      <Input
                        allowClear={true}
                        className="rounded clearable"
                        size="large"
                        style={{
                          width: "100%",
                          marginTop: 5,
                          background: "transparent",
                        }}
                        placeholder="Nhập thông tin"
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Typography fontSize={18} color="#2A3790">
                      <PrivateIdIcon style={{ verticalAlign: "top" }} />
                      <span> Theo CMND/CCCD</span>
                    </Typography>
                    <Form.Item name="privateId">
                      <Input
                        allowClear={true}
                        className="rounded clearable"
                        size="large"
                        style={{
                          width: "100%",
                          marginTop: 5,
                          background: "transparent",
                        }}
                        placeholder="Nhập thông tin"
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Typography fontSize={18} color="#2A3790">
                      <MobilePhoneIcon style={{ verticalAlign: "top" }} />
                      <span> Theo số điện thoại</span>
                    </Typography>
                    <Form.Item name="phone">
                      <Input
                        allowClear={true}
                        className="rounded clearable"
                        size="large"
                        style={{
                          width: "100%",
                          marginTop: 5,
                          background: "transparent",
                        }}
                        placeholder="Nhập thông tin"
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Typography fontSize={18} color="#2A3790">
                      <MerchantIcon style={{ verticalAlign: "top" }} />
                      <span> Kênh bán</span>
                    </Typography>
                    <Form.Item name="merchantId" initialValue={null}>
                      <Select
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        className="rounded transparent-background"
                        size="large"
                        style={{ width: "100%", marginTop: 5 }}
                        placeholder="Chọn kênh bán"
                      >
                        <Option value={null}>Tất Cả</Option>
                        {merchants.map((merchant, index) => {
                          return (
                            <Option key={index} value={merchant.id}>
                              {merchant.title}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Typography fontSize={18} color="#2A3790">
                      <StatusIcon style={{ verticalAlign: "top" }} />
                      <span> Theo trạng thái</span>
                    </Typography>
                    <Form.Item name={"statusId"} initialValue={null}>
                      <Select
                        className="rounded transparent-background"
                        size="large"
                        style={{ width: "100%", marginTop: 5 }}
                        placeholder="Chọn trạng thái"
                      >
                        <Option value={null}>Tất Cả</Option>
                        {STATUS.map((item, index) => {
                          return (
                            <Option
                              key={index}
                              value={item.id}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item.title}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Typography fontSize={18} color="#2A3790">
                      <CalendarIcon style={{ verticalAlign: "top" }} />
                      <span> Từ ngày</span>
                    </Typography>
                    <Form.Item name={"fromDate"} initialValue={moment()}>
                      <DatePicker
                        disabledDate={(current) =>
                          current && current.isAfter(moment())
                        }
                        format={"DD/MM/YYYY"}
                        className="rounded"
                        suffixIcon={
                          <CalendarOutlined style={{ color: "#2A3790" }} />
                        }
                        size="large"
                        style={{
                          width: "100%",
                          marginTop: 5,
                          background: "transparent",
                        }}
                        placeholder="Nhập thông tin"
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Typography fontSize={18} color="#2A3790">
                      <CalendarIcon style={{ verticalAlign: "top" }} />
                      <span> Đến ngày</span>
                    </Typography>
                    <Form.Item name={"toDate"} initialValue={moment()}>
                      <DatePicker
                        disabledDate={(current) =>
                          current && current.isAfter(moment())
                        }
                        format={"DD/MM/YYYY"}
                        className="rounded"
                        suffixIcon={
                          <CalendarOutlined style={{ color: "#2A3790" }} />
                        }
                        size="large"
                        style={{
                          width: "100%",
                          marginTop: 5,
                          background: "transparent",
                        }}
                        placeholder="Nhập thông tin"
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <Typography fontSize={18} color="#2A3790">
                      <BarStatIcon style={{ verticalAlign: "top" }} />
                      <span> Nhân sự phê duyệt</span>
                    </Typography>
                    <Select
                      className="rounded transparent-background"
                      size="large"
                      style={{ width: "100%", marginTop: 5 }}
                      placeholder="Nhập thông tin"
                    >
                      {employees.map((employee, index) => (
                        <Option key={index} value={employee.id}>
                          {employee.fullname}
                        </Option>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={4}></Grid>

                  <Grid
                    item
                    xs={4}
                    sm={4}
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      block
                      loading={loading}
                      onClick={() => {
                        setParams(form.getFieldsValue());
                      }}
                      icon={<SearchOutlined style={{ color: "white" }} />}
                      style={{
                        background: "#2A3790",
                        borderRadius: 32,
                        color: "white",
                        height: 42,
                      }}
                      size="large"
                    >
                      Tìm kiếm
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={4}></Grid>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  fontSize={18}
                  color="#2A3790"
                  textTransform={"uppercase"}
                  fontWeight={"bold"}
                >
                  kết quả tra cứu
                </Typography>
              </Grid>

              <Grid
                xs={6}
                style={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <div style={{ marginRight: 5 }}>
                  <Select
                    defaultValue={10}
                    style={{ width: 120, textAlign: "left", color: "#2A3790" }}
                    onChange={(e) => {
                      setPageSize(e);
                    }}
                  >
                    <Option value={10}>Hiển thị 10</Option>
                    <Option value={20}>Hiển thị 20</Option>
                    <Option value={50}>Hiển thị 50</Option>
                  </Select>
                </div>
                <div style={{ width: 160 }}>
                  <Button
                    icon={<ExportIcon style={{ verticalAlign: "top" }} />}
                    style={{
                      border: "1px solid #2A3790",
                      color: "#2A3790",
                      background: "#E8EBFF",
                      borderRadius: 8,
                      height: 40,
                    }}
                    block
                    onClick={() => {
                      exportData();
                    }}
                    disabled={exporting}
                    size="large"
                  >
                    Xuất báo cáo
                  </Button>
                </div>
              </Grid>

              <Grid item xs={12}>
                <BusinessReportTable
                  data={data}
                  total={total}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Container>

      <>
        <ExportBusinessTable
          data={exportedData}
          fromDate={params?.fromDate}
          toDate={params?.toDate}
          totalFees={exportedData.reduce((total, item) => {
            return total + +item.fees;
          }, 0)}
        />
      </>
    </Fragment>
  );
}
