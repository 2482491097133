import _, { set } from "lodash";
import React, { useEffect, useState } from "react";
import { Box, Grid, ImageList, ImageListItem, Typography } from "@mui/material";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  addDocs,
  getDetail,
  getListOrderForClaim,
} from "../../../../../../apis/claimApis";
import {
  Wrapper,
  ContentWrapper,
  Content,
} from "../../.../../../carAccident/styles";
import HeaderBar from "../../components/layout/HeaderBar";
import AdjustIcon from "@mui/icons-material/Adjust";
import TomatoArccodion from "../../components/TomatoArccodion";

import { getTypeInsu } from "../../components/createDetailClaimFile/until";
import Loading from "../../../../../../ui/Loading";
import { formatCurrency, formatDateDMY } from "../../../../../../utils/utils";
import {
  accidentLevel,
  accidentTypes,
  treatmentTypes,
} from "../../components/createDetailClaimFile/until";
import BoxImage from "../../components/createDetailClaimFile/BoxImage";
import CTCheckbox from "../../components/layout/CTCheckBox";
import moment from "moment";
import { Button, message } from "antd";
import { uploadImage } from "../../../../../../apis/uploadApis";

const getNameValue = (array, value) => {
  const temp = array.find((item) => item.value === value);
  return temp?.name;
};

const DOC_TYPES = {
  id: "Hình chụp CMND/CCCD/Passport mặt trước và sau",
  license: "Hình chụp giấy phép lái xe và giấy tờ xe (cavet xe)",
  report: "Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản",
  prescriptionHospitalCheckout: "Hình chụp giấy ra viện, đơn thuốc xuất viện",
  prescription:
    "Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh viện/ phòng khám",
  test: "Phiếu chỉ định của bác sĩ đối với yêu cầu xét nghiệm, chiếu chụp và kết quả chiếu chụp-xét nghiệm",
  xq: "Hình chụp phim XQ, chỉ định chụp XQ; hình chụp phiếu đọc kết quả phim XQ",
  diagnose:
    "Hình chụp toa thuốc/ giấy ra viện có ghi chẩn đoán “bỏng”, độ bỏng, diện tích bỏng (nếu có), chữ ký bác sỹ và mộc tròn bệnh viện/ phòng khám",
  governmentConfirmation:
    "Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận nguyên nhân",
  certificateInjury: "Giấy chứng nhận thương tật của cơ quan có thẩm quyền",
  deadConclusion:
    "Biên bản công an điều tra có kết luận nguyên nhân tử vong/xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận nguyên nhân tử vong",
  deathCertificate: "Giấy chứng tử",
  inheritanceText:
    "Biên bản phân chia di sản thừa kế có xác nhận của cơ quan chức năng/phường xã",
  vaccinationCard: "Hình chụp phiếu chích ngừa/ chỉ định chích ngừa",
  prescriptionCheckout: "Hình chụp đơn thuốc xuất viện",
  detailedListHospitalFees: "Hình chụp bảng kê chi tiết viện phí",
  surgery: "Hình chụp giấy chứng nhận phẫu thuật",
  vaccinationBill: "Hóa đơn chích ngừa",
  bill: "Hóa đơn điều trị",
};

const InsuTomatoClaimUpdate = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [claimDetail, setClaimDetail] = useState({});
  const [customer, setCustomer] = useState(undefined);
  const { insuredInfo = {}, extraInfo = {} } = claimDetail;

  const [images, setImages] = useState({});
  const [loadings, setLoadings] = useState({});

  const upLoadFile = async ({ name, e }) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    if (file?.size > 150 * 1024) {
    }
    let tempLoading = { ...loadings };

    setLoadings({ ...tempLoading, [name]: true });

    const data = new FormData();
    data.append("document", file);

    const dataApi = await uploadImage(data).catch((e) => e);
    if (e instanceof Error)
      return message.error("Có lỗi xảy ra, vui lòng thử lại", 1.5);

    let temp = { ...images };
    if (!temp[name]) {
      temp[name] = {
        comment: null,
        createdDate: moment().format("DD/MM/YYYY"),
        id: `${name}${moment().unix()}`,
        links: [],
        name: name,
      };
    }

    let tempData = [...temp[name].links];
    tempData.push(dataApi?.data?.result[0]?.path);

    temp[name].links = [...tempData];
    setImages(temp);
    setLoadings({ ...tempLoading, [name]: false });
  };

  const onDelete = async (name, index) => {
    let temp = { ...images };
    let tempData = [...temp[name].links];
    tempData.splice(index, 1);
    temp[name].links = [...tempData];
    if (temp[name].links.length < 1) delete temp[name];

    setImages(temp);
  };

  const processAddDocs = async () => {
    setProcessing(true);
    let temp = { ...images };

    let response = await addDocs(claimDetail?.id, temp).catch((e) => e);
    if (response instanceof Error) {
      return message.error("Có lỗi xảy ra, vui lòng thử lại", 1.5);
    }

    message.success("Thêm tài liệu thành công", 1.5);
    setProcessing(false);
    history.goBack();
  };

  const renderStatusBlock = (status) => {
    if (status) {
      const STATUS = [
        { value: 1, label: "Chờ xử lý", color: "#26A9E0" },
        { value: 2, label: "Đang xử lý", color: "#DBAC06" },
        { value: 3, label: "Chờ phê duyệt", color: "#6372DA" },
        { value: 4, label: "Bổ sung hồ sơ", color: "#FB7800" },
        { value: 5, label: "Đã phê duyệt", color: "#39B44A" },
        { value: 6, label: "Hoàn tất", color: "#39B44A" },
        { value: 7, label: "Từ chối", color: "#BF272D" },
      ];

      return (
        <span
          style={{
            color: STATUS.filter((item) => +item.value === +status)[0].color,
          }}
        >
          <AdjustIcon />{" "}
          {STATUS.filter((item) => +item.value === +status)[0].label}
        </span>
      );
    } else return;
  };

  const getData = async () => {
    const dataApi = await getDetail(id);
    if (dataApi?.data?.statusCode === 200) {
      const tmp = _.get(dataApi, "data.result");

      const dataCustomer = await getListOrderForClaim({
        limit: 10000,
        offset: 0,
        productIdParent: tmp?.productIdParent,
      });
      const customerData =
        dataCustomer?.data?.result?.filter(
          (item) => item.customerId === tmp?.insuredInfo?.customerId
        )?.[0] ?? undefined;
      setCustomer(customerData);
      setClaimDetail(tmp);
    }
    setIsLoading(false);
  };

  const bankInfo = claimDetail?.extraInfo?.beneficiary?.bank || {};

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getData();
  }, [id]);

  const { lifePermanentDisability, trafficDead } = getTypeInsu(
    insuredInfo,
    extraInfo
  );

  return (
    <Wrapper>
      <HeaderBar onPress={() => history.goBack()}>
        <div
          style={{
            fontWeight: "700",
            textAlign: "center",
            fontSize: "16px",
            lineHeight: "1em",
          }}
        >
          <div style={{ marginBottom: 10 }}>Hồ Sơ Bồi Thường Mã:</div>
          <div style={{ color: "#0B7CE4" }}>{claimDetail.code}</div>
        </div>
      </HeaderBar>
      {isLoading && <Loading />}
      <ContentWrapper style={{ backgroundColor: "#FFFFFF", marginTop: "45px" }}>
        <Content>
          <Box sx={contentBox}>
            <Typography sx={statTitle}>TÌNH TRẠNG HỒ SƠ</Typography>
            <Typography sx={stat}>
              {renderStatusBlock(claimDetail?.statusId)}
            </Typography>
          </Box>
          <Box sx={contentBox}>
            <TomatoArccodion
              title="Thông tin hợp đồng"
              expanded={true}
              halfShow={true}
            >
              <Box sx={arcdContent}>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Tổng quyền lợi bảo hiểm
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {" "}
                    {customer?.total_insurance_benefits
                      ? formatCurrency(customer?.total_insurance_benefits)
                      : "0"}{" "}
                    VNĐ
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Tổng phí bảo hiểm
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {" "}
                    {claimDetail?.insuredInfo?.fees
                      ? formatCurrency(claimDetail?.insuredInfo?.fees)
                      : "0"}{" "}
                    VNĐ
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>Họ Tên</Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {customer?.name ?? ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Năm sinh
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {moment(
                      claimDetail?.insuredInfo?.insuredDob,
                      "DD/MM/YYYY"
                    ).isValid()
                      ? moment(
                          claimDetail?.insuredInfo?.insuredDob,
                          "DD/MM/YYYY"
                        ).format("YYYY")
                      : ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    CMND/CCCD
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {insuredInfo?.insuredPrivateId ?? ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Số điện thoại
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {customer?.phone ?? ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>Email</Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {customer?.email ?? ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Địa chỉ
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {customer?.address ?? ""}
                  </Typography>
                </Grid>
              </Box>
            </TomatoArccodion>
          </Box>
          <Box sx={contentBox}>
            <TomatoArccodion title="Thông báo bồi thường" expanded>
              <Box sx={arcdContent}>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography className="section-header">
                    THÔNG BÁO BỒI THƯỜNG
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Số tiền yêu cầu bồi thường
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {" "}
                    {claimDetail?.insuredInfo?.requestMoney
                      ? formatCurrency(claimDetail?.insuredInfo?.requestMoney)
                      : "N/A"}{" "}
                    VNĐ
                  </Typography>
                </Grid>

                {/* {claimDetail?.statusId === 6 && ( */}
                {true && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Số tiền không thanh toán
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {" "}
                      {claimDetail?.amount
                        ? formatCurrency(
                            +claimDetail?.insuredInfo?.requestMoney -
                              +claimDetail?.amount
                          )
                        : "N/A"}{" "}
                      VNĐ
                    </Typography>
                  </Grid>
                )}

                {/* {claimDetail?.statusId === 6 && ( */}
                {true && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Số tiền được bồi thường
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {" "}
                      {claimDetail?.amount
                        ? formatCurrency(+claimDetail?.amount)
                        : "N/A"}{" "}
                      VNĐ
                    </Typography>
                  </Grid>
                )}
                {/* <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>Lý do</Typography>
                  <Typography
                    className="textarea"
                    style={{ fontWeight: "bold" }}
                  >
                    {claimDetail?.insuredInfo?.note}
                  </Typography>
                </Grid> */}
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Loại tai nạn
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {getNameValue(
                      accidentTypes,
                      _.get(extraInfo, "accident.type")
                    ) || ""}
                  </Typography>
                </Grid>
                {_.get(extraInfo, "accident.type") === "traffic accident" &&
                  _.get(extraInfo, "accident.level") !== "dead" && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        Điều khiển xe trên 50cc
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {_.get(extraInfo, "accident.bikeType") ? "Có" : "Không"}
                      </Typography>
                    </Grid>
                  )}
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Mức độ tai nạn
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {getNameValue(
                      accidentLevel,
                      _.get(extraInfo, "accident.level")
                    ) || ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Loại hình điều trị
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {getNameValue(
                      treatmentTypes,
                      _.get(insuredInfo, "treatmentType")
                    ) || ""}
                  </Typography>
                </Grid>
                {"boarding" === claimDetail?.insuredInfo?.treatmentTyp && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Ngày nhập viện
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {formatDateDMY(claimDetail?.insuredInfo?.hospitalizeDate)}
                    </Typography>
                  </Grid>
                )}
                {"boarding" === claimDetail?.insuredInfo?.treatmentType && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Ngày xuất viện
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {formatDateDMY(claimDetail?.insuredInfo?.dischargeDate)}
                    </Typography>
                  </Grid>
                )}
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Ngày tai nạn
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {formatDateDMY(claimDetail?.insuredInfo?.accidentDate)}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Nơi xảy ra tai nạn
                  </Typography>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    maxHeight={150}
                    overflow={"auto"}
                  >
                    {claimDetail?.insuredInfo?.accidentAt}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Diễn biến tai nạn
                  </Typography>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className="textarea"
                    maxHeight={150}
                    overflow={"auto"}
                  >
                    {claimDetail?.insuredInfo?.discription}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Nơi điều trị
                  </Typography>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    maxHeight={150}
                    overflow={"auto"}
                  >
                    {claimDetail?.insuredInfo?.text}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Tổng chi phí điều trị
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {formatCurrency(claimDetail?.insuredInfo?.treatmentPrice)}
                    VNĐ
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Chẩn đoán bệnh
                  </Typography>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className="textarea"
                    maxHeight={150}
                    overflow={"auto"}
                  >
                    {claimDetail?.insuredInfo?.diagnostic}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography className="section-header">CHỨNG TỪ</Typography>
                </Grid>

                {claimDetail?.insuredInfo?.requiredUpdate?.map(
                  (item, index) => (
                    <div key={index}>
                      <Typography fontSize={14} color="black">
                        {DOC_TYPES[item]}
                      </Typography>
                      <Grid container style={{ marginTop: 0 }}>
                        <BoxImage
                          isNew
                          isMargin
                          onClick={(e) => upLoadFile({ e, name: item })}
                          onLoading={loadings[item]}
                        />
                        {images[item]?.links?.map((image, i) => (
                          <BoxImage
                            key={i}
                            isMargin={index !== 2}
                            url={image || null}
                            deleteImg={() => onDelete(item, image)}
                          />
                        ))}
                      </Grid>
                      {/* {errors.includes("report") && (
                        <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                      )} */}
                    </div>
                  )
                )}

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  style={{ marginTop: 15 }}
                >
                  <Typography style={{ fontWeight: "bold" }}>
                    Số tài khoản
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {bankInfo?.id || ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Ngân hàng
                  </Typography>
                  <Typography style={{ fontWeight: "bold", maxWidth: "50%" }}>
                    {bankInfo?.name || ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Chi nhánh
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {bankInfo?.branch || ""}
                  </Typography>
                </Grid>
              </Box>
            </TomatoArccodion>
          </Box>
          <Box sx={{ ...contentBox, ...agreementBox }}>
            <CTCheckbox checked={true} style={{ alignItems: "flex-start" }} />
            <Typography
              style={{
                fontWeight: "500",
                fontFamily: "Helvetica",
                textAlign: "justify",
              }}
            >
              Tôi đã đọc kỹ và chấp nhận tất cả
              <span
                style={{
                  color: "#1890ff",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {" "}
                các điều khoản trong Quy tắc Bảo hiểm
              </span>{" "}
              của Công ty bảo hiểm. Tôi cam đoan tất cả các thông tin khai báo ở
              trên là đúng sự thật và hiểu rằng bất kỳ thông tin nào khai báo
              không đúng sự thật có thể sẽ dẫn đến việc Giấy chứng nhận bảo
              hiểm/ Hợp đồng bảo hiểm mất hiệu lực và/hoặc Bảo hiểm Công ty bảo
              hiểm có quyền từ chối một phần hoặc toàn bộ trách nhiệm bồi
              thường. Tôi đồng ý sẽ thông báo cho Công ty bảo hiểm về bất kỳ sự
              thay đổi thông tin nào (nếu có). Tôi đồng ý để cho bác sỹ, bệnh
              viện hoặc cơ sở y tế đã điều trị hoặc tư vấn sức khoẻ cho tôi được
              cung cấp cho Công ty bảo hiểm mọi thông tin liên quan đến sức khoẻ
              của tôi nếu có sự nghi vấn, nhằm đáp ứng các yêu cầu về giải quyết
              quyền lợi bảo hiểm.
            </Typography>
          </Box>
          <Box sx={contentBox}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "19px",
                  letterSpacing: "-0.24px",
                  marginBottom: "0.75rem",
                  color: "#0B3C8A",
                }}
              >
                Chữ ký của bạn<span style={{ color: "red" }}>*</span>
              </Typography>
              {/* <a style={{ textDecoration: "underline", fontSize: "15px" }}>
                Ký lại
              </a> */}
            </div>
            <Typography
              className="textarea"
              sx={{
                padding: "1rem",
                width: "100%",
                borderRadius: "12px",
                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.17)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                src={claimDetail?.insuredInfo?.signature}
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </Typography>
          </Box>
          <Box
            style={{
              marginTop: 20,
            }}
          >
            <Button
              onClick={processAddDocs}
              loading={processing}
              block
              size="large"
              style={{
                backgroundColor: "#0B3C8A",
                color: "white",
                borderRadius: 12,
              }}
            >
              Bổ sung chứng từ
            </Button>
          </Box>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default InsuTomatoClaimUpdate;

const Titiel400 = ({ title, unRequier, unMarginTop }) => {
  return (
    <Box sx={{ marginBottom: "16px", marginTop: unMarginTop ? 0 : "16px" }}>
      <Typography style={styleTitle400}>
        {title}
        <span style={{ color: "red", display: unRequier ? "none" : "" }}>
          *
        </span>
      </Typography>
    </Box>
  );
};

const styleTitle400 = {
  fontWeight: "bold",
  fonStize: "15px",
  lineHeight: "19px",
  letterSpacing: "-0.408px",
  color: "#000000",
};

const contentBox = {
  marginTop: "25px",
};
const agreementBox = {
  display: "flex",
  "& > p": {
    fontSize: "13px",
    lineHeight: "22px",
    marginLeft: "0.5rem",
  },
  "& .MuiSvgIcon-root path": {
    color: "#0B3C8A",
  },
};
const stat = {
  fontSize: "15px",
  lineHeight: "19px",
  letterSpacing: "-0.24px",
  "& svg": {
    width: "16px",
    height: "16px",
    marginRight: "0.25rem",
    display: "inline-flex",
    alignItems: "center",
  },
  "& span": {
    display: "inline-flex",
    alignItems: "center",
  },
};

const statTitle = {
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.32)",
  marginBottom: "0.25rem",
  fontWeight: "600",
  letterSpacing: "-0.24px",
};

const arcdContent = {
  paddingTop: "8px",
  "& p": {
    // fontFamily: "Google Sans",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19px",
    letterSpacing: "-0.24px",
    marginBottom: "0.75rem",

    "&:first-of-type": {
      color: "#0B3C8A",
    },
    "&.section-header": {
      fontSize: "17px",
      fontWeight: "700",
      lineHeight: "21px",
      color: "#000",
      marginBottom: "1rem",
    },
    "&.textarea": {
      padding: "0.75rem",
      width: "100%",
      borderRadius: "12px",
      boxSizing: "border-box",
      border: "1px solid rgba(0, 0, 0, 0.17)",
    },
    "span.required": {
      color: "red",
    },
  },
  "& .image-list": {
    marginBottom: "0.75rem",
    "& .MuiImageListItem-root": {
      borderRadius: "13px",
      overflow: "hidden",
    },
  },
  "& .old-file": {
    marginBottom: "0.2rem",
    "& > div": {
      backgroundColor: "#D2E4FF",
      borderRadius: "10px",
      padding: "0.75rem",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "0.5rem",
      "& p": {
        color: "#000",
        marginBottom: "0",
      },
      "& a": {
        color: "#0B3C8A",
        display: "block",
        position: "relative",
        "&::after": {
          content: "' '",
          width: "100%",
          height: "1px",
          backgroundColor: "#0B3C8A",
          position: "absolute",
          top: "92%",
          left: "50%",
          transform: "translateX(-50%)",
        },
      },
    },
    "& > p": {
      fontSize: "13px",
      marginBottom: "0.5rem",
      color: "#000000",
      opacity: "0.6",
      lineHeight: "16px",
    },
  },
};
