import React, { useEffect, useLayoutEffect } from "react";
import { TableCell } from "@mui/material";

import CommonTable from "../../../../../../library/layout/commonTable";
import styleLicense from "./styleClaim";

export default function Diagnosis(props) {
  const { state, dispatch } = props;
  const classes = styleLicense();

  const { diagnosisExpan, rowsDiagnosis } = state;

  useEffect(() => {
    document.getElementById("diagnosis-table").style.height = diagnosisExpan
      ? 0
      : `${
          document?.getElementById("diagnosis")?.getBoundingClientRect()
            ?.height + 40
        }px`;
  }, [rowsDiagnosis.length, diagnosisExpan]);

  useLayoutEffect(() => {
    function updateSize() {
      document.getElementById("diagnosis-table").style.height = diagnosisExpan
        ? 0
        : `${
            document?.getElementById("diagnosis")?.getBoundingClientRect()
              ?.height + 40
          }px`;
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div
      className={classes.container_diagnosis}
      id="diagnosis-table"
      style={{
        // height: diagnosisExpan ? 0 : height,
        opacity: diagnosisExpan ? 0 : 1,
        padding: diagnosisExpan ? 0 : "",
        margin: diagnosisExpan ? "8px 0" : "",
        MozTransition: "all 0.7s ease",
        WebkitTransition: "all 0.7s ease",
        OTransition: "all 0.7s ease",
        transition: "all 0.7s ease",
      }}
    >
      <CommonTable
        classes={classes}
        rows={rowsDiagnosis}
        columns={columns}
        id="diagnosis"
      />
    </div>
  );
}

const columns = [
  {
    label: "Mã bệnh",
    headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">
        <div style={{ minHeight: "28px", width: "100%" }}>
          {item.diseaseCode || ""}
        </div>
      </TableCell>
    ),
  },
  {
    label: "Tên bệnh",
    headerClass: "name",
    renderCell: ({ item }) => (
      <TableCell className="name">{item.diseaseName || ""}</TableCell>
    ),
  },
];
