import React, { useRef, useEffect, useState } from "react";
import { Button } from "@mui/material";
// import Webcam from "react-webcam";

import { styledCCCD } from "../components/styeleds/styleCCCD";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import camera_icon from "../../../../../assets/health/camera_icon.svg";
import DialogInstruction from "./dialogInstruction";

const videoConstraints = {
  facingMode: { exact: "environment" },
};

export default function Camera(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledCCCD();
  const { dialogInstruction, face = "" } = state;
  const videoRef = useRef(null);

  const [dataimg, setDataImg] = useState(null);

  const captureImage = async () => {
    const videoElement = videoRef.current;
    videoElement.click();

    // if (videoElement) {
    //   // const canvas = videoRef.current;
    //   // canvas.width = videoElement.videoWidth;
    //   // canvas.height = videoElement.videoHeight;

    //   const context = videoElement.getContext("2d");
    //   context.drawImage(
    //     videoElement,
    //     0,
    //     0,
    //     videoElement.width,
    //     videoElement.height
    //   );

    //   const dataURL = await videoElement.toDataURL("image/png");
    //   setDataImg(dataURL);
    //   dispatch({ type: "uploadImgCCCD", dataURL });
    // }
  };

  // useEffect(() => {
  //   if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
  //     navigator.mediaDevices
  //       // .getUserMedia({ video: true })
  //       .getUserMedia({
  //         video: {
  //           facingMode: "environment", // Sử dụng camera sau
  //           // facingMode: "user", // Sử dụng camera trước
  //         },
  //       })
  //       .then((stream) => {
  //         const canvas = videoRef.current;
  //         const ctx = canvas.getContext("2d");
  //         const video = document.createElement("video");
  //         video.srcObject = stream;
  //         video.play();
  //         video.addEventListener("play", () => {
  //           function drawFrame() {
  //             ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  //             requestAnimationFrame(drawFrame);
  //           }
  //           drawFrame();
  //         });

  //         // if (videoRef.current) {
  //         //   videoRef.current.srcObject = stream;
  //         //   videoRef.current.play();
  //         // }
  //       })
  //       .catch((error) => {
  //         console.error("Error accessing camera:", error);
  //       });
  //   }
  //   return () => setDataImg(null);
  // }, []);

  // useEffect(() => {
  //   if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
  //     navigator.mediaDevices
  //       // .getUserMedia({ video: true })
  //       .getUserMedia({
  //         video: {
  //           facingMode: "environment", // Sử dụng camera sau
  //           // facingMode: "user", // Sử dụng camera trước
  //         },
  //       })
  //       .then((stream) => {
  //         if (videoRef.current) {
  //           videoRef.current.srcObject = stream;
  //           videoRef.current.play();
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error accessing camera:", error);
  //       });
  //   }
  //   return () => setDataImg(null);
  // }, []);

  return (
    <div
      className={classes.containerCamera}
      style={{
        minHeight: isApp ? "calc(100vh - 95px)" : "calc(100vh - 55px)",
        position: "relative",
      }}
    >
      <div className="title-face-private">
        {face === "before" ? "CHỤP MẶT TRƯỚC" : "CHỤP MẶT SAU"}
      </div>
      <div className={classes.box_title_camera}>
        <div>Đặt chứng từ trên một mặt phẳng, không</div>
        <div>cầm trên tay </div>
      </div>
      {!dataimg && (
        // <video
        //   ref={videoRef}
        //   style={{
        //     width: "100%",
        //     borderBottomLeftRadius: "12px",
        //     borderBottomRightRadius: "12px",
        //     minHeight: "250px",
        //   }}
        // />
        // <Webcam
        //   audio={false}
        //   width={"100%"}
        //   height={250}
        //   screenshotFormat="image/jpeg"
        //   videoConstraints={videoConstraints}
        // >
        //   {({ getScreenshot }) => (
        //     <button
        //       onClick={() => {
        //         const imageSrc = getScreenshot();
        //       }}
        //     >
        //       Capture photo
        //     </button>
        //   )}
        // </Webcam>
        <input
          ref={videoRef}
          capture="camera"
          type="file"
          accept="image/*"
          onClick={(e) => {
            e.target.value = "";
          }}
          onChange={(e) => {}}
          style={
            {
              // display: "none",
            }
          }
        />
      )}
      {dataimg && (
        <img
          src={dataimg}
          style={{
            width: "100%",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
        />
      )}
      <div className="div-icon-camera" onClick={captureImage}>
        <img src={camera_icon} style={{ marginBottom: "12px" }} />
        Chụp ảnh
      </div>
      <div className="div-instruction">
        <Button
          onClick={() => dispatch("setDialogInstruction")}
          style={{
            color: "white",
            textTransform: "none",
            background: "#000000",
            padding: "8px 16px",
            borderRadius: "16px",
            bottom: "25px",
          }}
        >
          Xem hướng dẫn <NavigateNextIcon />{" "}
        </Button>
      </div>
      <DialogInstruction
        open={!!dialogInstruction}
        onClose={() => dispatch("setDialogInstruction")}
      />
    </div>
  );
}
