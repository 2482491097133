import { message } from "antd";
import _, { cloneDeep, get, set } from "lodash";
import { SSF, read, utils } from "xlsx";
import {
  getOverview,
  getOverviewList,
  getOverviewListProduct,
  getOverviewListMerchant,
  getListOrg,
  getListProductParent,
  getListMerchant,
} from "../../../apis/dashboardApi";

import moment from "moment";

const getDataChartOneDate = (array) => {
  if (array.length === 0) return null;
  if (array.length === 1) return array[0];

  const temp = {};
  array.forEach((i) => {
    temp.count_status_id =
      +(temp?.count_status_id || 0) + +(i?.count_status_id || 0);
    temp.total_amount = +(temp?.total_amount || 0) + +(i?.total_amount || 0);
  });
  temp.serial_date = array[0].serial_date;

  return temp;
};

const delay = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 800);
  });

export default function dashboardReducer(props) {
  const { state, action, dispatch, history } = props;
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const userTypeFromProfile = user?.extra_info?.userType || "";
  const isGlobalcare = userTypeFromProfile === "globalcare";
  const orgIdFromProfile = user?.extra_info?.org_id || "";

  const cases = {
    initail: async () => {
      const { shortDashboard } = state;
      const modalDate = { option: "byMonth" };
      const dataFiter = {
        optionModalDate: "byMonth",
        valueTextDate: `Theo tháng: ${moment().format("MM/YYYY")} `,
        typeAccount: "all",
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().endOf("month").format("YYYY-MM-DD"),
      };

      dispatch("onLoading");

      const res = {};

      res.listOrg = [{ title: "Tất Cả", id: 0 }];
      res.listProductParent = [{ title: "Tất Cả", id: 0 }];
      res.listMerchant = [{ title: "Tất Cả", id: 0 }];

      const params = {
        // providerId: 6,
        merchantId: null,
        productIdParents: null,
        orgId: isGlobalcare ? null : orgIdFromProfile,
        // orgIds: [82, 70],
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().endOf("month").format("YYYY-MM-DD"),
      };

      const [
        overViewApi,
        overviewListApi,
        overViewProductApi,
        overViewMerchantApi,
      ] = await Promise.all([
        getOverview(userTypeFromProfile, params),
        getOverviewList(userTypeFromProfile, params),
        getOverviewListProduct(userTypeFromProfile, {
          ...params,
          limit: 10,
          offset: 0,
        }),
        getOverviewListMerchant(userTypeFromProfile, {
          ...params,
          limit: 10,
          offset: 0,
        }),
      ]);

      dispatch("getDataFilter", { ...res });

      res.overview = get(overViewApi, "data.result") || {};
      res.overviewList = get(overviewListApi, "data.result") || [];
      res.overviewListProduct =
        get(overViewProductApi, "data.result.data") || [];
      res.overviewListMerchant =
        get(overViewMerchantApi, "data.result.data") || [];
      res.pageListP_M = {
        product: {
          page: 0,
          count: Math.ceil(get(overViewProductApi, "data.result.total") / 10),
        },
        merchant: {
          page: 0,
          count: Math.ceil(get(overViewMerchantApi, "data.result.total") / 10),
        },
      };
      res.dialogListP_M = {
        listProduct: get(overViewProductApi, "data.result.data") || [],
        listMerchant: get(overViewMerchantApi, "data.result.data") || [],
      };

      const labelsChart = [];
      const dataResultListOvervew = get(overviewListApi, "data.result") || [];
      dataResultListOvervew.forEach((item) => {
        const dateChart = moment(item?.serial_date).format("DD/MM/YYYY");
        if (labelsChart.includes(dateChart)) return;
        labelsChart.push(dateChart);
      });

      const tempDataChart = labelsChart.reduce((array, item) => {
        const dataOfDate = (dataResultListOvervew || []).filter(
          (i) => moment(i?.serial_date).format("DD/MM/YYYY") === item
        );

        const insuredPerson = getDataChartOneDate(
          dataOfDate?.filter((i) => i.category === "cert")
        );
        const claimsChart = getDataChartOneDate(
          dataOfDate?.filter((i) => i.category === "claim_request")
        );
        const claimsApproveChart = getDataChartOneDate(
          dataOfDate?.filter((i) => i.category === "claim_approve")
        );
        const claimsRejectChart = getDataChartOneDate(
          dataOfDate?.filter((i) => i.category === "claim_reject")
        );

        array = [
          ...array,
          {
            insuredPerson,
            claimsChart,
            claimsApproveChart,
            claimsRejectChart,
          },
        ];

        return array;
      }, []);

      res.dataChart = [...tempDataChart];
      res.xChart = [...labelsChart];
      res.yChart = {
        insuredPerson: tempDataChart.map(
          (i) => get(i, `insuredPerson.${shortDashboard}`) || 0
        ),
        claimsChart: tempDataChart.map(
          (i) => get(i, `claimsChart.${shortDashboard}`) || 0
        ),
        claimsApproveChart: tempDataChart.map(
          (i) => get(i, `claimsApproveChart.${shortDashboard}`) || 0
        ),
        claimsRejectChart: tempDataChart.map(
          (i) => get(i, `claimsRejectChart.${shortDashboard}`) || 0
        ),
      };

      const payloadApi = {
        orgId: 0,
        merchantId: 0,
        productIdParents: [0],
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().endOf("month").format("YYYY-MM-DD"),
      };

      return {
        loading: false,
        modalDate,
        dataFiter,
        payloadApi,
        ...res,
      };
    },

    getDataFilter: async () => {
      const res = {};
      const [listOrgApi, listProductParentApi, listMerchantApi] =
        await Promise.all([
          getListOrg({ limit: 10, offset: 0, org_type: null }),
          getListProductParent({
            limit: 10,
            offset: 0,
            statusId: 1,
            orgNotNull: isGlobalcare ? 1 : 0,
          }),
          getListMerchant({ limit: 10, offset: 0 }),
        ]);

      res.listOrg = [
        { title: "Tất Cả", id: 0 },
        ...(get(listOrgApi, "data.result.data") || []),
      ];
      res.listProductParent = [
        { title: "Tất Cả", id: 0 },
        ...(get(listProductParentApi, "data.result.data") || []),
      ];
      res.listMerchant = [
        { title: "Tất Cả", id: 0 },
        ...(get(listMerchantApi, "data.result.data") || []),
      ];
      res.payloadAutocomplete = {
        org: {
          page: 1,
          total: get(listOrgApi, "data.result.total") || 0,
        },
        merchant: {
          page: 1,
          total: get(listMerchantApi, "data.result.total") || 0,
        },
        product: {
          page: 1,
          total: get(listProductParentApi, "data.result.total") || 0,
        },
      };

      return res;
    },

    getDataOverview: async () => {
      const { payloadApi, shortDashboard, dataFiter, dialogListP_M } = state;

      dispatch("onLoading");
      const res = {};
      const paramsProduct = payloadApi?.productIdParents.includes(0);
      const params = {
        // providerId: 6,
        merchantId: payloadApi?.merchantId || null,
        productIdParents:
          paramsProduct || payloadApi?.productIdParents?.length == 0
            ? null
            : payloadApi?.productIdParents,
        orgId: isGlobalcare ? payloadApi?.orgId || null : orgIdFromProfile,
        // orgIds: [82, 70],
        from: payloadApi?.from || null,
        to: payloadApi?.to || null,
      };

      const [
        overViewApi,
        overviewListApi,
        overViewProductApi,
        overViewMerchantApi,
      ] = await Promise.all([
        getOverview(userTypeFromProfile, params),
        getOverviewList(userTypeFromProfile, params),
        getOverviewListProduct(userTypeFromProfile, {
          ...params,
          limit: 10,
          offset: 0,
        }),
        getOverviewListMerchant(userTypeFromProfile, {
          ...params,
          limit: 10,
          offset: 0,
        }),
      ]);

      res.overview = get(overViewApi, "data.result") || {};
      res.overviewList = get(overviewListApi, "data.result") || [];
      res.overviewListProduct =
        get(overViewProductApi, "data.result.data") || [];
      res.overviewListMerchant =
        get(overViewMerchantApi, "data.result.data") || [];
      res.pageListP_M = {
        product: {
          page: 0,
          count: Math.ceil(get(overViewProductApi, "data.result.total") / 10),
        },
        merchant: {
          page: 0,
          count: Math.ceil(get(overViewMerchantApi, "data.result.total") / 10),
        },
      };
      res.dialogListP_M = {
        ...dialogListP_M,
        listProduct: get(overViewProductApi, "data.result.data") || [],
        listMerchant: get(overViewMerchantApi, "data.result.data") || [],
      };

      const labelsChart = [];
      const dataResultListOvervew = get(overviewListApi, "data.result") || [];
      dataResultListOvervew.forEach((item) => {
        const dateChart =
          dataFiter?.optionModalDate === "byYear"
            ? moment(item?.serial_date).format("MM/YYYY")
            : moment(item?.serial_date).format("DD/MM/YYYY");
        if (labelsChart.includes(dateChart)) return;
        labelsChart.push(dateChart);
      });

      const tempDataChart = labelsChart.reduce((array, item) => {
        const dataOfDate = (dataResultListOvervew || []).filter((i) => {
          return dataFiter?.optionModalDate === "byYear"
            ? moment(i?.serial_date).format("MM/YYYY") === item
            : moment(i?.serial_date).format("DD/MM/YYYY") === item;
        });

        const insuredPerson = getDataChartOneDate(
          dataOfDate?.filter((i) => i.category === "cert")
        );
        const claimsChart = getDataChartOneDate(
          dataOfDate?.filter((i) => i.category === "claim_request")
        );
        const claimsApproveChart = getDataChartOneDate(
          dataOfDate?.filter((i) => i.category === "claim_approve")
        );
        const claimsRejectChart = getDataChartOneDate(
          dataOfDate?.filter((i) => i.category === "claim_reject")
        );

        array = [
          ...array,
          {
            insuredPerson,
            claimsChart,
            claimsApproveChart,
            claimsRejectChart,
          },
        ];

        return array;
      }, []);

      res.dataChart = [...tempDataChart];
      res.xChart = [...labelsChart];
      res.yChart = {
        insuredPerson: tempDataChart.map(
          (i) => get(i, `insuredPerson.${shortDashboard}`) || 0
        ),
        claimsChart: tempDataChart.map(
          (i) => get(i, `claimsChart.${shortDashboard}`) || 0
        ),
        claimsApproveChart: tempDataChart.map(
          (i) => get(i, `claimsApproveChart.${shortDashboard}`) || 0
        ),
        claimsRejectChart: tempDataChart.map(
          (i) => get(i, `claimsRejectChart.${shortDashboard}`) || 0
        ),
      };

      return { loading: false, ...res };
    },

    selectOption: () => {
      const { option } = action;
      const { modalDate } = state;

      const getDataDate = () => {
        switch (option) {
          case "byDate":
            return {
              from: moment().format("YYYY-MM-DD"),
              to: moment().format("YYYY-MM-DD"),
            };
          case "byWeek":
            return {
              from: moment().startOf("week").format("YYYY-MM-DD"),
              to: moment().endOf("week").format("YYYY-MM-DD"),
            };
          case "byMonth":
            return {
              from: moment().startOf("month").format("YYYY-MM-DD"),
              to: moment().endOf("month").format("YYYY-MM-DD"),
            };
          case "byYear":
            return {
              from: moment().startOf("year").format("YYYY-MM-DD"),
              to: moment().endOf("year").format("YYYY-MM-DD"),
            };
          case "option":
            return {
              from: moment().format("YYYY-MM-DD"),
              to: moment().format("YYYY-MM-DD"),
            };
          default:
            return {};
        }
      };

      if (option === "option") {
        modalDate.dataDateOptionFrom = moment();
        modalDate.dataDateOptionTo = moment();
      }

      modalDate.option = option;
      modalDate.from = getDataDate().from || null;
      modalDate.to = getDataDate().to || null;
      modalDate.dataDate = moment();

      return { modalDate };
    },

    onChangeDate: () => {
      const { date, dateString, actionDate } = action;
      const { modalDate } = state;

      modalDate.dataDate = date;

      if (actionDate == "day") {
        modalDate.from = moment(date).format("YYYY-MM-DD");
        modalDate.to = moment(date).format("YYYY-MM-DD");

        return { modalDate };
      }

      if (actionDate === "byWeek") {
        modalDate.from = moment(date).startOf("week").format("YYYY-MM-DD");
        modalDate.to = moment(date).endOf("week").format("YYYY-MM-DD");
      }

      if (actionDate === "byMonth") {
        modalDate.from = moment(date).startOf("month").format("YYYY-MM-DD");
        modalDate.to = moment(date).endOf("month").format("YYYY-MM-DD");
      }

      if (actionDate === "byYear") {
        modalDate.from = moment(date).startOf("year").format("YYYY-MM-DD");
        modalDate.to = moment(date).endOf("year").format("YYYY-MM-DD");
      }

      if (actionDate === "option-from") {
        modalDate.from = moment(date).format("YYYY-MM-DD");
        modalDate.dataDateOptionFrom = date;
        modalDate.dataDate = null;
      }

      if (actionDate === "option-to") {
        modalDate.to = moment(date).format("YYYY-MM-DD");
        modalDate.dataDateOptionTo = date;
        modalDate.dataDate = null;
      }
      return { modalDate };
    },

    onSubmitModalDate: async () => {
      const { modalDate, dataFiter, payloadApi } = state;

      const setDate = () => {
        switch (modalDate?.option) {
          case "today":
            return {
              from: moment().format("YYYY-MM-DD"),
              to: moment().format("YYYY-MM-DD"),
              valueTextDate: `Hôm nay: ${moment().format("DD/MM/YYYY")}`,
            };
          case "yesterday":
            return {
              from: moment().subtract(1, "days").format("YYYY-MM-DD"),
              to: moment().subtract(1, "days").format("YYYY-MM-DD"),
              valueTextDate: `Hôm qua: ${moment()
                .subtract(1, "days")
                .format("DD/MM/YYYY")}`,
            };
          case "in7Day":
            return {
              from: moment().subtract(7, "days").format("YYYY-MM-DD"),
              to: moment().format("YYYY-MM-DD"),
              valueTextDate: `Trong 7 ngày`,
            };
          case "in30Day":
            return {
              from: moment().subtract(30, "days").format("YYYY-MM-DD"),
              to: moment().format("YYYY-MM-DD"),
              valueTextDate: `Trong 30 ngày`,
            };
          case "byDate":
            return {
              from: modalDate?.from || null,
              to: modalDate?.to || null,
              valueTextDate: ` Theo ngày: ${moment(modalDate?.dataDate).format(
                "DD/MM/YYYY"
              )}`,
            };
          case "byWeek":
            return {
              from: modalDate?.from || null,
              to: modalDate?.to || null,
              valueTextDate: `Theo tuần `,
            };
          case "byMonth":
            return {
              from: modalDate?.from || null,
              to: modalDate?.to || null,
              valueTextDate: `Theo tháng: ${moment(modalDate.dataDate).format(
                "MM/YYYY"
              )} `,
            };
          case "byYear":
            return {
              from: modalDate?.from || null,
              to: modalDate?.to || null,
              valueTextDate: `Theo năm: ${moment(modalDate.dataDate).format(
                "YYYY"
              )} `,
            };
          case "option":
            return {
              from: modalDate?.from || null,
              to: modalDate?.to || null,
              valueTextDate: `${moment(modalDate.dataDateOptionFrom).format(
                "DD/MM/YYYY"
              )} - ${moment(modalDate.dataDateOptionTo).format("DD/MM/YYYY")} `,
            };

          default:
            return {};
        }
      };

      dataFiter.optionModalDate = cloneDeep(modalDate.option);
      dataFiter.from = setDate().from || null;
      dataFiter.to = setDate().to || null;
      dataFiter.valueTextDate = setDate().valueTextDate || "";
      dataFiter.dataDate = cloneDeep(modalDate.dataDate) || null;
      dataFiter.dataDateOptionFrom =
        modalDate?.option === "option"
          ? cloneDeep(modalDate.dataDateOptionFrom) || null
          : null;
      dataFiter.dataDateOptionTo =
        modalDate?.option === "option"
          ? cloneDeep(modalDate.dataDateOptionTo) || null
          : null;

      payloadApi.from = cloneDeep(dataFiter.from);
      payloadApi.to = cloneDeep(dataFiter.to);

      dispatch({ type: "getDataOverview" }, { payloadApi });

      return { dataFiter, modalDate: { ...modalDate, open: false } };
    },

    openModalDate: () => {
      const { dataFiter } = state;
      return {
        modalDate: {
          ...state?.modalDate,
          open: true,
          option: dataFiter.optionModalDate || "in30Day",
          dataDate: cloneDeep(dataFiter.dataDate) || null,
          dataDateOptionFrom: cloneDeep(dataFiter.dataDateOptionFrom) || null,
          dataDateOptionTo: cloneDeep(dataFiter.dataDateOptionTo) || null,
        },
      };
    },

    closeModalDate: () => ({
      modalDate: {
        ...state?.modalDate,
        open: false,
        option: state?.dataFiter?.optionModalDate || "in30Day",
      },
    }),

    onSelectTypeAccount: async () => {
      const { value } = action;
      const {
        dataFiter,
        payloadAutocomplete,
        loadingAutocomplete,
        payloadApi,
      } = state;

      dataFiter.typeAccount = value;
      dispatch({ type: "onLoadingAutocomplete", name: "orgBr" });
      const listOrgApi = await getListOrg({
        limit: 10,
        offset: 0,
        org_type: value === "all" ? null : value,
      });
      const listOrg = [
        { title: "Tất Cả", id: 0 },
        ...(get(listOrgApi, "data.result.data") || []),
      ];
      payloadAutocomplete.org = {
        page: 1,
        total: get(listOrgApi, "data.result.total") || 0,
      };
      payloadApi.orgId = 0;
      loadingAutocomplete.orgBr = false;

      return {
        dataFiter,
        payloadAutocomplete,
        listOrg,
        loadingAutocomplete,
        payloadApi,
      };
    },

    onChangPayloadApi: () => {
      const { name, value, e, test } = action;
      const { payloadApi } = state;
      if (name === "org") {
        payloadApi.orgId = value;
        dispatch({ type: "setValueAutocomplete", name: "org", value: null });
      }

      if (name === "merchant") {
        payloadApi.merchantId = value;
        dispatch({
          type: "setValueAutocomplete",
          name: "merchant",
          value: null,
        });
      }

      if (name === "product") {
        if (value.length > payloadApi.productIdParents.length) {
          const newlySelectedOption = value[value.length - 1];

          if (newlySelectedOption === 0) {
            payloadApi.productIdParents = [0];
          } else {
            payloadApi.productIdParents = value.filter((i) => i != 0);
          }
        } else {
          payloadApi.productIdParents = value;
        }
      }

      dispatch("getDataOverview", { payloadApi });

      return { payloadApi };
    },

    setValueAutocomplete: () => {
      const { value, name } = action;
      const { payloadAutocomplete } = state;
      if (name === "org") {
        payloadAutocomplete.org = { ...payloadAutocomplete.org, title: value };
      }

      if (name === "merchant") {
        payloadAutocomplete.merchant = {
          ...payloadAutocomplete.merchant,
          title: value,
        };
      }

      return { payloadAutocomplete };
    },

    getMoveListAutocomplete: async () => {
      const { name } = action;
      const {
        payloadAutocomplete,
        loadingAutocomplete,
        listOrg,
        listMerchant,
        dataFiter,
        listProductParent,
      } = state;
      const res = {};
      dispatch({ type: "onLoadingAutocomplete", name });
      const offset = get(payloadAutocomplete, `${name}.page`, 0) * 10;
      if (name === "org") {
        const listOrgApi = await getListOrg({
          limit: 10,
          offset: offset,
          org_type:
            dataFiter?.typeAccount === "all"
              ? null
              : dataFiter?.typeAccount || null,
          title: get(payloadAutocomplete, `org.title`) || null,
        });
        res.listOrg = [
          ...listOrg,
          ...(get(listOrgApi, "data.result.data") || []),
        ];
        payloadAutocomplete.org = {
          ...payloadAutocomplete.org,
          page: (payloadAutocomplete?.org?.page || 0) + 1,
          total: get(listOrgApi, "data.result.total"),
        };
      }

      if (name === "merchant") {
        const listMerchantApi = await getListMerchant({
          limit: 10,
          offset: offset,
          keyword: get(payloadAutocomplete, `merchant.title`) || null,
        });
        res.listMerchant = [
          ...listMerchant,
          ...(get(listMerchantApi, "data.result.data") || []),
        ];
        payloadAutocomplete.merchant = {
          ...payloadAutocomplete.merchant,
          page: (payloadAutocomplete?.merchant?.page || 0) + 1,
          total: get(listMerchantApi, "data.result.total"),
        };
      }

      if (name === "product") {
        const listProductParentApi = await getListProductParent({
          limit: 10,
          offset: offset,
          statusId: 1,
          keyword: payloadAutocomplete?.product?.keyword || null,
          orgNotNull: isGlobalcare ? 1 : 0,
        });
        res.listProductParent = [
          ...listProductParent,
          ...(get(listProductParentApi, "data.result.data") || []),
        ];
        payloadAutocomplete.product = {
          ...payloadAutocomplete.product,
          page: (payloadAutocomplete?.product?.page || 0) + 1,
          total: get(listProductParentApi, "data.result.total"),
        };
      }

      loadingAutocomplete[name] = false;
      return { loadingAutocomplete, ...res };
    },

    onInputChangeOrg: async () => {
      const { value } = action;
      const {
        dataFiter,
        payloadAutocomplete,
        loadingAutocomplete,
        payloadApi,
      } = state;

      await dispatch({ type: "setValueAutocomplete", name: "org", value });
      dispatch({ type: "onLoadingAutocomplete", name: "org" });
      const listOrgApi = await getListOrg({
        limit: 10,
        offset: 0,
        org_type:
          dataFiter?.typeAccount === "all"
            ? null
            : dataFiter?.typeAccount || null,
        title: value || null,
      });

      const res = {};
      const setTime = await delay();
      if (get(state, "payloadAutocomplete.org.title") !== value) {
        return;
      }

      res.listOrg = [
        { title: "Tất Cả", id: 0 },
        ...(get(listOrgApi, "data.result.data") || []),
      ];
      payloadAutocomplete.org = {
        page: 1,
        total: get(listOrgApi, "data.result.total"),
        title: value,
      };
      payloadApi.orgId = null;
      loadingAutocomplete.org = false;

      return { ...res, payloadAutocomplete, loadingAutocomplete, payloadApi };
    },

    onInputChangeMerchant: async () => {
      const { value } = action;
      const { payloadAutocomplete, loadingAutocomplete } = state;

      dispatch({ type: "onLoadingAutocomplete", name: "merchant" });
      await dispatch({ type: "setValueAutocomplete", name: "merchant", value });
      const listMerchantApi = await getListMerchant({
        limit: 10,
        offset: 0,
        keyword: value || null,
      });
      const res = {};
      const setTime = await delay();
      if (get(state, "payloadAutocomplete.merchant.title") !== value) {
        return;
      }

      res.listMerchant = [
        { title: "Tất Cả", id: 0 },
        ...(get(listMerchantApi, "data.result.data") || []),
      ];
      payloadAutocomplete.org = {
        page: 1,
        total: get(listMerchantApi, "data.result.total"),
        title: value,
      };
      loadingAutocomplete.merchant = false;

      return { ...res, payloadAutocomplete, loadingAutocomplete };
    },

    onInputChangeProduct: async () => {
      const { value } = action;
      const { payloadApi, listProductParent, payloadAutocomplete } = state;
      const itemChosse = listProductParent.filter((i) =>
        (payloadApi?.productIdParents || [])?.includes(i.id)
      );

      const res = {};
      const listProductParentApi = await getListProductParent({
        limit: 10,
        offset: 0,
        statusId: 1,
        keyword: value,
        orgNotNull: isGlobalcare ? 1 : 0,
      });

      res.listProductParent = [
        { title: "Tất Cả", id: 0 },
        ...itemChosse,
        ...(get(listProductParentApi, "data.result.data") || []).filter(
          (i) => !(payloadApi?.productIdParents || [])?.includes(i.id)
        ),
      ];
      payloadAutocomplete.product = {
        ...payloadAutocomplete.product,
        page: 1,
        total: get(listProductParentApi, "data.result.total"),
        keyword: value,
      };

      return { ...res, payloadAutocomplete };
    },

    onChangeShowChart: () => {
      const { name, value } = action;
      const { showChart } = state;
      showChart[name] = value;
      return { showChart };
    },

    selectShortChart: () => {
      const { value } = action;
      const { dataChart } = state;

      const res = {};

      res.yChart = {
        insuredPerson: dataChart.map(
          (i) => get(i, `insuredPerson.${value}`) || 0
        ),
        claimsChart: dataChart.map((i) => get(i, `claimsChart.${value}`) || 0),
        claimsApproveChart: dataChart.map(
          (i) => get(i, `claimsApproveChart.${value}`) || 0
        ),
        claimsRejectChart: dataChart.map(
          (i) => get(i, `claimsRejectChart.${value}`) || 0
        ),
      };

      return { shortDashboard: value, ...res };
    },

    openDialogListP_M: () => {
      const { name } = action;
      const { dialogListP_M } = state;
      return {
        dialogListP_M: { ...dialogListP_M, open: true, nameDialog: name },
      };
    },

    cloaseDialogListP_M: () => {
      const { dialogListP_M } = state;
      return {
        dialogListP_M: { ...dialogListP_M, open: false },
      };
    },

    onchangePage: async () => {
      const { page } = action;
      const { dialogListP_M, pageListP_M, payloadApi } = state;

      const res = {};
      const paramsProduct = payloadApi?.productIdParents.includes(0);
      const params = {
        merchantId: payloadApi?.merchantId || null,
        productIdParents:
          paramsProduct || payloadApi?.productIdParents?.length == 0
            ? null
            : payloadApi?.productIdParents,
        orgId: isGlobalcare ? payloadApi?.orgId || null : orgIdFromProfile,
        from: payloadApi?.from || null,
        to: payloadApi?.to || null,
        limit: 10,
        offset: page * 10 || 0,
      };

      dispatch("onLoading");

      if (dialogListP_M?.nameDialog === "product") {
        const dataApi = await getOverviewListProduct(
          userTypeFromProfile,
          params
        );

        res.pageListP_M = {
          ...pageListP_M,
          product: {
            page: page || 0,
            count: Math.ceil(get(dataApi, "data.result.total") / 10),
          },
        };
        res.dialogListP_M = {
          ...dialogListP_M,
          listProduct: get(dataApi, "data.result.data") || [],
        };
      }

      if (dialogListP_M?.nameDialog === "merchant") {
        const dataApi = await getOverviewListMerchant(
          userTypeFromProfile,
          params
        );

        res.pageListP_M = {
          ...pageListP_M,
          merchant: {
            page: page || 0,
            count: Math.ceil(get(dataApi, "data.result.total") / 10),
          },
        };
        res.dialogListP_M = {
          ...dialogListP_M,
          listMerchant: get(dataApi, "data.result.data") || [],
        };
      }

      return { ...res, loading: false };
    },

    refresh_data: async () => {
      const { payloadApi, shortDashboard, dataFiter, dialogListP_M } = state;

      const res = {};
      const paramsProduct = payloadApi?.productIdParents.includes(0);
      const params = {
        // providerId: 6,
        merchantId: payloadApi?.merchantId || null,
        productIdParents:
          paramsProduct || payloadApi?.productIdParents?.length == 0
            ? null
            : payloadApi?.productIdParents,
        orgId: isGlobalcare ? payloadApi?.orgId || null : orgIdFromProfile,
        // orgIds: [82, 70],
        from: payloadApi?.from || null,
        to: payloadApi?.to || null,
      };

      const [
        overViewApi,
        overviewListApi,
        overViewProductApi,
        overViewMerchantApi,
      ] = await Promise.all([
        getOverview(userTypeFromProfile, params),
        getOverviewList(userTypeFromProfile, params),
        getOverviewListProduct(userTypeFromProfile, {
          ...params,
          limit: 10,
          offset: 0,
        }),
        getOverviewListMerchant(userTypeFromProfile, {
          ...params,
          limit: 10,
          offset: 0,
        }),
      ]);

      res.overview = get(overViewApi, "data.result") || {};
      res.overviewList = get(overviewListApi, "data.result") || [];
      res.overviewListProduct =
        get(overViewProductApi, "data.result.data") || [];
      res.overviewListMerchant =
        get(overViewMerchantApi, "data.result.data") || [];
      res.pageListP_M = {
        product: {
          page: 0,
          count: Math.ceil(get(overViewProductApi, "data.result.total") / 10),
        },
        merchant: {
          page: 0,
          count: Math.ceil(get(overViewMerchantApi, "data.result.total") / 10),
        },
      };
      res.dialogListP_M = {
        ...dialogListP_M,
        listProduct: get(overViewProductApi, "data.result.data") || [],
        listMerchant: get(overViewMerchantApi, "data.result.data") || [],
      };

      const labelsChart = [];
      const dataResultListOvervew = get(overviewListApi, "data.result") || [];
      dataResultListOvervew.forEach((item) => {
        const dateChart =
          dataFiter?.optionModalDate === "byYear"
            ? moment(item?.serial_date).format("MM/YYYY")
            : moment(item?.serial_date).format("DD/MM/YYYY");
        if (labelsChart.includes(dateChart)) return;
        labelsChart.push(dateChart);
      });

      const tempDataChart = labelsChart.reduce((array, item) => {
        const dataOfDate = (dataResultListOvervew || []).filter((i) => {
          return dataFiter?.optionModalDate === "byYear"
            ? moment(i?.serial_date).format("MM/YYYY") === item
            : moment(i?.serial_date).format("DD/MM/YYYY") === item;
        });

        const insuredPerson = getDataChartOneDate(
          dataOfDate?.filter((i) => i.category === "cert")
        );
        const claimsChart = getDataChartOneDate(
          dataOfDate?.filter((i) => i.category === "claim_request")
        );
        const claimsApproveChart = getDataChartOneDate(
          dataOfDate?.filter((i) => i.category === "claim_approve")
        );
        const claimsRejectChart = getDataChartOneDate(
          dataOfDate?.filter((i) => i.category === "claim_reject")
        );

        array = [
          ...array,
          {
            insuredPerson,
            claimsChart,
            claimsApproveChart,
            claimsRejectChart,
          },
        ];

        return array;
      }, []);

      res.dataChart = [...tempDataChart];
      res.xChart = [...labelsChart];
      res.yChart = {
        insuredPerson: tempDataChart.map(
          (i) => get(i, `insuredPerson.${shortDashboard}`) || 0
        ),
        claimsChart: tempDataChart.map(
          (i) => get(i, `claimsChart.${shortDashboard}`) || 0
        ),
        claimsApproveChart: tempDataChart.map(
          (i) => get(i, `claimsApproveChart.${shortDashboard}`) || 0
        ),
        claimsRejectChart: tempDataChart.map(
          (i) => get(i, `claimsRejectChart.${shortDashboard}`) || 0
        ),
      };

      return { ...res };
    },

    setDataRefresh: () => ({ refresh_interval: action.value }),
    //

    onLoadingAutocomplete: () => ({
      loadingAutocomplete: {
        ...state.loadingAutocomplete,
        [action.name]: true,
      },
    }),

    onLoading: () => ({ loading: true }),

    //
  };

  return _.isString(action.type) && cases[action.type];
}
