import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { IconButton, MenuItem } from "@mui/material";
import TableToExcel from "@linways/table-to-excel";

import LoadingMui from "../../../../../ui/LoadingMui";
import useReducer from "../../../../../library/hooks/useReducer";
import CommonTable from "../../../../../library/layout/commonTable";
import Snackbars from "../../../../../ui/snackbar";
import recordsReducer from "../reducer/recordsReducer";
import { initialState } from "../utils";
import {
  HeaderSearchRecords,
  columns,
  TableExportExcel,
  DialogUpdateReview,
  DialogDetailPDF,
} from "../components";
import styleRecords from "../style/records-css";
import { TextField } from "../overrideMui";
import downloadIcon from "../../../../../assets/health/download-icon.svg";

import { dataSearchList } from "../atomHealth";
import { useRecoilState } from "recoil";
import { getParamUrlByKey } from "../../../../../utils/utils";

export default function RecodrsIndemnify({ role_type = null }) {
  const classes = styleRecords();
  const history = useHistory();
  const location = useLocation();
  const users = JSON.parse(localStorage.getItem("user")) || {};
  const user_id = _.isEmpty(users) ? "" : users.id;
  const systemType = _.isEmpty(users) ? "" : users?.extra_info?.userType;
  const { query } = location;

  const product_id_parent = getParamUrlByKey("product_id_parent") || "";
  const getRoleFromProfileUser =
    (_.get(users, "role_management_claim.product_parents", []) || []).find(
      (i) => i.product_id_parent == product_id_parent
    ) || {};

  !!query?.product_id &&
    localStorage.setItem(
      "product_id",
      JSON.stringify({
        product_id: query?.product_id,
        level_id: query?.role_management?.level_id,
      })
    );
  const product_id = getRoleFromProfileUser?.product_id_parent || null;
  const level_id = getRoleFromProfileUser?.level_id || null;

  const [dataSearchHealthList, setDataSearchHealthList] =
    useRecoilState(dataSearchList);
  const [isFirstRender, setIsFirstRender] = useState(false);

  const [state, dispatch] = useReducer({
    initialState,
    name: "recordsReducer",
    reducer: recordsReducer,
    history,
    location,
    product_id,
    systemType,
    role_type,
    dataSearchHealthList,
    setDataSearchHealthList,
  });

  const {
    pageTable,
    loading,
    rows,
    snackbar,
    dataSearch,
    count,
    dataExportExcel,
  } = state;

  useEffect(() => {
    setIsFirstRender(true);
    return () => {
      const fetchData = async () => {
        if (
          !window.location?.pathname
            .toString()
            .includes("/admin/health/indemnify/detail")
        ) {
          setDataSearchHealthList({});
        }
      };

      fetchData();
    };
  }, [window.location.pathname]);

  useEffect(() => {
    if (isFirstRender) {
      dispatch("initial");
    }
  }, [isFirstRender]);

  useEffect(async () => {
    if (count !== 0) {
      const table = document.getElementById("export-data-table");
      TableToExcel.convert(table, {
        name: `bao-cao-boi-thuong.xlsx`,
        sheet: {
          name: "Sheet 1",
        },
      });
    }
  }, [count]);

  return _.isEmpty(getRoleFromProfileUser) ? (
    <div className={classes.container}>
      <div>Xin lỗi bạn chưa có quyền truy cập nội dung này</div>
    </div>
  ) : (
    <div className={classes.container}>
      <div className="title">Hồ sơ bồi thường</div>
      <HeaderSearchRecords {...{ dataSearch, dispatch, classes, role_type }} />
      <div className={classes.result}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{ marginRight: "12px", color: "#3961A0", fontWeight: "600" }}
          >
            Kết Quả Tra Cứu
          </span>
          <TextField
            select
            value={pageTable.rowsPerPage || 20}
            className="select-view"
            onChange={(e) =>
              dispatch({
                type: "onchangePage",
                page: pageTable.page,
                rowsPerPage: e.target.value,
                name: "rowsPerPage",
              })
            }
          >
            {[10, 20, 50, 100].map((i) => (
              <MenuItem key={i} value={i}>{`Hiển thị ${i}`}</MenuItem>
            ))}
          </TextField>
        </div>
        <div
          style={{ color: "#3961A0", fontWeight: "600", cursor: "pointer" }}
          onClick={() => dispatch("onSubmitExport")}
        >
          <IconButton>
            <img src={downloadIcon} />
          </IconButton>
          Xuất báo cáo
        </div>
      </div>
      {rows.length === 0 ? (
        <div className={classes.noData}>
          Hiện không có không có hồ sơ bồi thường nào{" "}
        </div>
      ) : (
        <CommonTable
          rows={rows}
          columns={columns}
          classes={classes}
          data={{ user_id, level_id, role_type, users }}
          page={{
            ...pageTable,
            onChange: (event, page, rowsPerPage) => {
              dispatch({
                type: "onchangePage",
                page,
                rowsPerPage,
              });
            },
          }}
          event={(type, data) =>
            (ele, ...rest) => {
              dispatch({ type, data, ele, rest });
            }}
        />
      )}
      <LoadingMui loading={loading} />
      <Snackbars
        open={snackbar.open}
        type={snackbar.type}
        onClose={() => dispatch("closeSnackbar")}
        message={snackbar.message}
      />
      <DialogUpdateReview {...{ state, dispatch }} />
      <DialogDetailPDF {...{ state, dispatch }} />
      <TableExportExcel dataSearch={dataSearch} data={dataExportExcel} />
    </div>
  );
}
