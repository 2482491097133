import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { useHistory, useLocation } from "react-router-dom";

import useReducer from "../../reducer";
import { Wrapper, ContentWrapper, Content } from "../../../carAccident/styles";
import HeaderBar from "../../components/layout/HeaderBar";
import OverriderAccordion from "../../components/layout/OverriderAccordion";
import RowData from "../../components/layout/RowData";
import ClaimFile from "../../components/ClaimFile";
import NextButton from "../../components/layout/NextButton";
import createClaimFileReducer from "../../reducer/createClaimFileReducer";
import { CREATE_REQUEST_CLAIM_TOMATO } from "../../../../../../configs/routesConfig";

const initialState = { infoContracts: [], paramsData: {}, errors: [] };

const CreateClaimFileTomato = () => {
  const history = useHistory();
  const location = useLocation();
  const { itemContract, create } = location.state;

  const [state, dispatch] = useReducer({
    reducer: createClaimFileReducer,
    name: "createClaimFileReducer",
    initialState,
    history,
    itemContract,
    location,
  });

  const { infoContracts, sum, expense } = state;

  useEffect(() => {
    dispatch({ type: "initial" });
    window.scrollTo(0, 0);
    create === "new" && dispatch("resetData");
  }, []);

  return (
    <Wrapper>
      <HeaderBar
        title="Tạo yêu cầu bồi thường"
        background="#F2F2F2"
        onPress={() => {
          history.push(CREATE_REQUEST_CLAIM_TOMATO + location.search, {
            itemContract: itemContract,
            paramsData: { insuredInfo: {}, extraInfo: {} },
          });
        }}
      />
      <ContentWrapper style={{ backgroundColor: "white", marginTop: 10 }}>
        <Content>
          <Row justify="center">
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <div style={formInfo}>
                <OverriderAccordion
                  title="Thông tin hợp đồng"
                  isInforContract={true}
                  sum={sum}
                  expense={expense}
                >
                  <div
                    style={{
                      boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.08)",
                      borderRadius: "10px",
                    }}
                  >
                    {(infoContracts || []).map((item, index) => (
                      <RowData
                        key={index}
                        title={item?.title || ""}
                        value={item?.value || ""}
                      />
                    ))}
                  </div>
                </OverriderAccordion>
                <div style={claimFile}>
                  <span>Hồ sơ bồi thường</span>
                </div>
                <ClaimFile dispatch={dispatch} state={state} />
                <NextButton onChangNext={() => dispatch("onSubmit")} />
              </div>
            </Col>
          </Row>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default CreateClaimFileTomato;

const formInfo = {
  margin: "43px 0 8px 0",
};

const claimFile = {
  margin: "28px 0 8px 0",
  fontSize: 20,
  fontWeight: "700",
};
