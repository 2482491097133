import api from "../configs/api";

export function getListYourClaim_v2(data) {
  return api.apiForm("POST", "/api/v2/claim/client/list", data);
}

export function getListClaim_v2(data) {
  return api.apiForm("POST", "/api/v1/order/claim/list", data);
}

//khi xoá các url đã lưu trên S3 gọi thêm API này data: {
// "action_code": "document_delete",
// "extra_info": {"url":"links"}
// }
export function todoRemovedLinkS3(data) {
  return api
    .apiForm("POST", `/api/v2/todo`, data)
    .then((res) => res)
    .catch((err) => err.response);
}
