import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
  Slide,
  Alert,
} from "@mui/material";

import { Button as AntButton, Modal } from "antd";

import HeaderBar from "../../components/layout/HeaderBar";
import { useStyles } from "../styles";
import { isEmail, TextRender } from "../utils";
import { sendEmail } from "../../../../../../apis/claimApis";
import EmailIcon from "../../../../../../assets/icons/email.svg";
import DownloadIcon from "../../../../../../assets/icons/download.svg";
import CheckIcon from "../../../../../../assets/icons/check.svg";

const infoRender = [
  { name: "Số hợp đồng", value: "code" },
  { name: "Họ tên", value: "buyer_name" },
  { name: "Năm sinh", value: "buyer_dob" },
  { name: "CMND/CCCD", value: "buyer_private_id" },
  { name: "Số điện thoại", value: "buyer_phone" },
  { name: "Email", value: "buyer_email" },
  { name: "Địa chỉ", value: "buyer_address" },
  { name: "Quyền lợi bảo hiểm", value: "product_amount" },
  { name: "Phí bảo hiểm", value: "fees" },
  { name: "Nơi mua", value: "insuredAddress" },
];

export default function SavingsInsurance({ state, dispatch }) {
  const {
    insuranceData,
    openSendMail,
    email,
    success,
    loading,
    errEmail,
    snackbar,
  } = state;
  const classes = useStyles();

  const [showCert, setShowCert] = useState(false);
  const [os, setOs] = useState(null);

  const getMobileOS = () => {
    const ua = navigator.userAgent
    if (/iPad|iPhone|iPod/.test(ua)
      || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      return "iOS"
    }

    return 'Other'
  }

  useEffect(() => {
    setOs(getMobileOS())
  }, [])

  const onClickBtn = async () => {
    if (isEmail(email)) {
      const { data } = await sendEmail(insuranceData.code, { email });
      data?.statusCode === 200
        ? dispatch("sendEmail")
        : dispatch({ type: "openSnackbar", name: "email" });
    } else dispatch("setError");
  };
  const downloadData = async (link) => {
    if (!link)
      return;

    window.ReactNativeWebView.postMessage(`${link}?type=browser`);
  };

  return (
    <>
      <HeaderBar title={"Bảo hiểm TOMATO"} onPress={() => dispatch("onBack")} />
      <div className={classes.wrapperInfo}>
        <Typography className="title">THÔNG TIN BẢO HIỂM</Typography>
        {(infoRender || []).map((item) => (
          <TextRender {...{ insuranceData, item, classes }} />
        ))}
        <Box className={classes.bottomNav}>
          <BottomNavigation showLabels value={3}>
            <BottomNavigationAction
              label="Gửi Email"
              icon={<img src={EmailIcon} alt="email" />}
              onClick={() => dispatch("openSendMail")}
              className={classes.bottomAction}
            />
            <BottomNavigationAction
              label="Xem GCN"
              icon={<img src={DownloadIcon} alt="down" />}
              onClick={() => downloadData(insuranceData.cert_link)}
              style={{ fontWeight: 700, color: "black" }}
            />
          </BottomNavigation>
        </Box>
        <Dialog
          fullWidth
          open={openSendMail}
          onClose={() => dispatch("closeSendMail")}
          className={classes.dialog}
        >
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogTitle className="titleDialog">Gửi Email</DialogTitle>
          <DialogContent>
            <Typography className="txtDialog">
              Bạn muốn gửi hợp đồng bảo hiểm đến email
            </Typography>
            <TextField
              fullWidth
              value={email || ""}
              placeholder={"Nhập email"}
              size="small"
              className="txtField"
              error={errEmail}
              helperText={errEmail ? "Email không đúng định dạng." : ""}
              onChange={(e) =>
                dispatch({
                  type: "onChange",
                  value: e.target.value,
                  name: "email",
                })
              }
            />
            <AntButton
              block
              size="large"
              style={{
                marginTop: 10,
                background: "#0B3C8A",
                color: "white",
                borderRadius: 16,
                outline: "none",
              }}
              onClick={onClickBtn}
            >
              Gửi
            </AntButton>
            {/* <Button
              key="email"
              fullWidth
              className={classes.button}
              onClick={onClickBtn}
            >
              Gửi
            </Button> */}
          </DialogContent>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth={"xl"}
          open={success}
          onClose={() => dispatch("closeSuccess")}
          className={classes.dialog}
        >
          <DialogTitle className="titleDialog">
            <img src={CheckIcon} alt="checked" className="img" />
            Hoàn tất
          </DialogTitle>
          <DialogContent>
            <Typography className="txtSuccess">Gửi Email thành công</Typography>
            <Button
              key="close"
              fullWidth
              className={classes.button}
              onClick={() => dispatch("closeSuccess")}
            >
              Đóng
            </Button>
          </DialogContent>
        </Dialog>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbar?.open || false}
        onClose={() => dispatch("closeSnackbar")}
        message={snackbar?.mes || ""}
        key={Math.random()}
        autoHideDuration={3000}
        TransitionComponent={Slide}
      >
        <Alert severity={!!snackbar?.success ? "success" : "error"}>
          <strong>{!!snackbar?.success ? "Thông báo: " : "Lỗi: "}</strong>
          {snackbar?.mes || ""}
        </Alert>
      </Snackbar>
    </>
  );
}
