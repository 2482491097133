import "./App.css";

import AppRouter from "./routes";
import NotConnected from "./views/error-page/not-connected";
import StoreProvider from "./utils/store/storestate";
import { ModalLoading, ModalListImage } from "./ui/modal";
import { RecoilRoot } from "recoil";
import { useEffect, useState } from "react";
import DialogSetReload from "./components/dialogSetReload";

function App() {
  const connect = window.navigator.onLine;
  const [dialog, setDialog] = useState({});

  const getmeta = async () => {
    const timestamp = new Date().getTime();
    const response = await fetch(`/meta.json?_=${timestamp}`);
    const data = await response.json();
    const newVersion = data.version.split(" ")[0];

    const metaClient = JSON.parse(localStorage.getItem("meta_version")) || null;
    if (metaClient == null) {
      localStorage.setItem("meta_version", JSON.stringify(newVersion));
    } else if (newVersion != metaClient) {
      if (data.popup_confirm == "1" && data.force_reload != "1") {
        setDialog({ open: true, version: newVersion || null });
      } else {
        newVersion &&
          localStorage.setItem("meta_version", JSON.stringify(newVersion));
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getmeta();
    }, 20000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <StoreProvider>
      <ModalLoading />
      <ModalListImage />
      <RecoilRoot>{connect ? <AppRouter /> : <NotConnected />}</RecoilRoot>
      <DialogSetReload {...{ dialog, setDialog }} />
    </StoreProvider>
  );
}

export default App;
