import _, { get, set } from "lodash";
import moment from "moment";

import {
  OCR_cccd,
  createClaimHealth,
  createClaimHealthWithPartner,
  getListBank,
  getListConfig,
  getListPackeInsurance,
  getListPackeInsuranceWithPartner,
  uploadImg,
  uploadImgWithPartner,
  uploadImg_base64,
  uploadImg_base64_with_artner,
  deleteS3Document,
} from "../../../../../apis/health";
import { removeUnicode } from "../../../../../utils/utils";
import { converDate, getListLicenseById, relationships } from "../utils";
import { saveAs } from "file-saver";

const validate = (state) => {
  const {
    listImgLicese,
    curenPage,
    insuredInfo,
    beneficiary,
    listImgRelationships,
    checkConditions,
    dataSignature,
    listInsuranceesCheck,
    insuredGender,
    riskType,
    insuranceType,
    typeOfIndimnity,
    extraInfo,
    privateId,
    requestAmount,
  } = state;
  const errors = [];

  const insuredInforUser = listInsuranceesCheck[0] || {};

  const getInfor = () => {
    // !privateId && errors.push("privateId");
    insuranceType?.insuranceCode === "tai_nan" &&
      _.isEmpty(riskType) &&
      errors.push("riskType");
    _.isEmpty(typeOfIndimnity) && errors.push("typeOfIndimnity");
    return errors;
  };

  const chooseInsurance = () => {
    listInsuranceesCheck.length === 0 && errors.push("checkInsurances");
    return errors;
  };

  const cccd = () => {
    !insuredInfo?.insuredName && errors.push("insuredName");
    !insuredInfo?.insuredDob && errors.push("insuredDob");
    !insuredInfo?.insuredPrivateId && errors.push("insuredPrivateId");
    return errors;
  };

  const reportedAccident = () => {
    !insuredInfo?.insuredName && errors.push("insuredName");
    !insuredInfo?.insuredAddress && errors.push("insuredAddress");
    !extraInfo?.placeOfAccident && errors.push("placeOfAccident");
    !extraInfo?.reason && errors.push("reason");
    !extraInfo?.accidentSituation && errors.push("accidentSituation");
    !extraInfo?.result && errors.push("result");

    return errors;
  };

  const license = () => {
    listImgLicese.forEach((item) => {
      if (item.optional) {
        return;
      }

      (item?.documents || []).length === 0 && errors.push(item.typeId);
      item.typeId === 500 &&
        (item?.documents || []).length < 2 &&
        errors.push(item.typeId);
    });

    // [4, 5, 6].includes(riskType.id) &&
    //   !extraInfo.typeOfTreatment &&
    [4, 5, 6].includes(typeOfIndimnity.id) &&
      insuranceType?.insuranceCode == "suc_khoe" &&
      !extraInfo.typeOfTreatment &&
      errors.push("typeOfTreatment");
    +requestAmount <= 0 && errors.push("requestAmount");

    return errors;
  };

  const infor = () => {
    beneficiary === "relative" &&
      !insuredInfo.beneficiaryName &&
      errors.push("beneficiaryName");
    !insuredInfo.relationshipWithBuyerId &&
      beneficiary === "relative" &&
      errors.push("relationshipWithBuyerId");
    !insuredInfo.beneficiaryBankAcountNumber &&
      errors.push("beneficiaryBankAcountNumber");
    !insuredInfo.beneficiaryBankTitle && errors.push("bank");
    !insuredInfo.beneficiaryBankBranchName &&
      errors.push("beneficiaryBankBranchName");
    beneficiary === "relative" &&
      (listImgRelationships || []).length === 0 &&
      errors.push(517);
    ![0, 1].includes(insuredInforUser?.insuredInfo?.insuredGender) &&
      ![0, 1].includes(insuredGender) &&
      errors.push("insuredGender");

    !insuredInfo?.insuredName &&
      !insuredInforUser?.insuredInfo?.insuredName &&
      errors.push("insuredName");

    !insuredInfo?.insuredPrivateId &&
      !insuredInforUser?.insuredInfo?.insuredPrivateId &&
      errors.push("insuredPrivateId");

    !insuredInfo?.insuredPhone &&
      !insuredInforUser?.insuredInfo?.insuredPhone &&
      errors.push("insuredPhone");

    !insuredInfo?.insuredDob &&
      !insuredInforUser?.insuredInfo?.insuredDob &&
      errors.push("insuredDob");

    // !insuredInfo?.insuredEmail & !insuredInforUser?.insuredInfo?.insuredEmail &&
    //   errors.push("insuredPrivateId");

    return errors;
  };

  const reviewInfo = () => {
    !checkConditions && errors.push("checkConditions");
    (dataSignature?.extraInfo?.documents || []).length < 1 &&
      errors.push("signature");

    return errors;
  };

  switch (curenPage) {
    case "get-infor":
      return getInfor();

    case "license":
      return license();

    case "choose-insurance":
      return chooseInsurance();

    case "reported-accident":
      return reportedAccident();

    case "cccd":
      return cccd();

    case "infor":
      return infor();

    case "review-infor":
      return reviewInfo();

    default:
      return [];
  }
};

const filterPackageInsurance = (data = []) => {
  const duplicateTomato = [];

  const dataReturn = data.reduce((arr, item, index) => {
    if (item.productIdParent !== 19) return [...arr, item];

    const findIndexDup = duplicateTomato.findIndex(
      (i) =>
        i?.providerId === item.providerId &&
        i.buyerPrivateId === item.buyerPrivateId &&
        i.certNum === item.certNum
    );

    if (findIndexDup > -1) {
      const checkEndDate = !duplicateTomato[findIndexDup]?.endDate
        ? false
        : moment(duplicateTomato[findIndexDup]?.endDate).diff(
          moment(item?.endDate),
          "seconds"
        ) >= 0;

      duplicateTomato[findIndexDup] = checkEndDate
        ? duplicateTomato[findIndexDup]
        : item;

      if (!checkEndDate) {
        const indexArray = arr.findIndex(
          (i) =>
            i?.providerId === item.providerId &&
            i.buyerPrivateId === item.buyerPrivateId
        );
        arr[indexArray] = item;
      }
    } else {
      duplicateTomato.push(item);
      arr = [...arr, item];
    }

    return arr;
  }, []);

  return dataReturn;
};

export default function healthReducer({
  action,
  state,
  dispatch,
  phone,
  partner,
  declarationApp,
}) {
  const had_partner_phone =
    _.isEmpty(phone) || _.isEmpty(partner) ? {} : { phone, partner };

  const cases = {
    initial: async () => {
      dispatch("onLoading");

      const listConfigApi = await getListConfig();

      if (listConfigApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...state.snackbar,
            open: true,
            type: "error",
            message: "Tải thông tin bị lỗi. Xin vui lòng thử lại",
          },
        };
      }

      const insuranceTypes = _.get(listConfigApi, "data.result") || [];
      const riskTypes = [];
      const insuranceType = insuranceTypes.find(
        (i) => i.insuranceCode === "suc_khoe"
      );
      const typeOfIndimnities = insuranceType?.typeOfIndimnity || [];

      return {
        loading: false,
        riskTypes,
        insuranceTypes,
        insuranceType,
        typeOfIndimnities,
      };
    },

    inittalReportedAccident: () => {
      const { listInsuranceesCheck, insuredInfo, extraInfo } = state;
      const insuredInforUser = listInsuranceesCheck[0] || {};
      insuredInfo.insuredName =
        insuredInfo.insuredName || insuredInforUser?.insuredInfo?.insuredName;

      if (!extraInfo.dateOfAccident) {
        extraInfo.dateOfAccident = moment().format();
      }

      return { insuredInfo, extraInfo };
    },

    getListBanks: async () => {
      dispatch("onLoading");
      const listBankApi = await getListBank();

      if (listBankApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...state.snackbar,
            open: true,
            type: "error",
            message: "Tải thông tin bị lỗi. Xin vui lòng thử lại",
          },
        };
      }

      const listBanks = _.get(listBankApi, "data.result.data");

      return { listBanks, loading: false };
    },

    submitNext: () => {
      const {
        curenPage,
        errors,
        listInsuranceesCheck,
        curenStep,
        insuranceType,
        insuredInfo,
      } = state;

      const isAccidentType = insuranceType?.insuranceCode === "tai_nan";
      const maxCurenSteps = isAccidentType ? 3 : 2;
      if (curenPage === "cccd") {
        const insuredData = listInsuranceesCheck[0] || {};
        let insuredDob =
          converDate(insuredData?.insuredInfo?.insuredDob).split("T").length > 0
            ? moment(
              get(
                converDate(insuredData?.insuredInfo?.insuredDob)?.split("T"),
                "[0]",
                null
              ) || null
            ).format()
            : converDate(insuredData?.insuredInfo?.insuredDob);

        const check =
          removeUnicode(insuredData?.insuredInfo?.insuredName || "")
            .toLowerCase()
            .replace(/\s/g, "") ==
          removeUnicode(insuredInfo?.insuredName || "")
            .toLowerCase()
            .replace(/\s/g, "") &&
          moment(insuredDob).diff(
            moment(insuredInfo?.insuredDob || null),
            "days"
          ) == 0 &&
          `${removeUnicode(
            insuredData?.insuredInfo?.insuredPrivateId ||
            insuredData?.insuredInfo?.insuredPrivateID
          ) || ""
            }`
            .toLowerCase()
            .replace(/\s/g, "") ===
          (`${removeUnicode(insuredInfo?.insuredPrivateId) || ""}`
            .toLowerCase()
            .replace(/\s/g, "") || null);

        if (!check) {
          dispatch({
            type: "openDialogNoti",
            name: "error",
            content: [
              "Thông tin người được bảo",
              "hiểm không có trong hợp",
              "đồng bảo hiểm.",
            ],
          });
          return;
        }
      }

      const error = validate(state);
      if (error.length > 0) {
        error.forEach((key) => (errors[key] = true));
        return { errors };
      }

      switch (curenPage) {
        case "get-infor":
          return {
            curenPage: "choose-insurance",
            disableNextButton: listInsuranceesCheck.length === 0,
            headerTitle: "Chọn gói bảo hiểm",
            disableNextButton:
              validate({
                ...state,
                curenPage: "choose-insurance",
              }).length > 0,
          };

        case "choose-insurance":
          return isAccidentType
            ? {
              curenPage: "reported-accident",
              curenStep: 0,
              headerTitle: "Tường trình tai nạn",
              disableNextButton:
                validate({
                  ...state,
                  curenPage: "reported-accident",
                }).length > 0,
            }
            : {
              curenPage: "license",
              curenStep: 0,
              headerTitle: "Tạo yêu cầu bồi thường",
              disableNextButton:
                validate({
                  ...state,
                  curenPage: "license",
                }).length > 0,
            };

        // case "cccd":
        //   return isAccidentType
        //     ? {
        //         curenPage: "reported-accident",
        //         curenStep:
        //           curenStep === maxCurenSteps ? curenStep : curenStep + 1,
        //         headerTitle: "Tường trình tai nạn",
        //         disableNextButton:
        //           validate({
        //             ...state,
        //             curenPage: "reported-accident",
        //           }).length > 0,
        //       }
        //     : {
        //         curenPage: "license",
        //         curenStep:
        //           curenStep === maxCurenSteps ? curenStep : curenStep + 1,
        //         headerTitle: "Tạo yêu cầu bồi thường",
        //         disableNextButton:
        //           validate({
        //             ...state,
        //             curenPage: "license",
        //           }).length > 0,
        //       };

        case "reported-accident":
          return {
            curenPage: "license",
            curenStep: curenStep === maxCurenSteps ? curenStep : curenStep + 1,
            headerTitle: "Tạo yêu cầu bồi thường",
            disableNextButton:
              validate({
                ...state,
                curenPage: "license",
              }).length > 0,
          };

        case "license":
          return {
            curenPage: "infor",
            curenStep: curenStep === maxCurenSteps ? curenStep : curenStep + 1,
            headerTitle: "Thông tin người được BH",
            disableNextButton:
              validate({
                ...state,
                curenPage: "infor",
              }).length > 0,
          };

        case "infor":
          return {
            curenPage: "review-infor",
            curenStep: curenStep === maxCurenSteps ? curenStep : curenStep + 1,
            headerTitle: "Xác nhận yêu cầu",
            titleButtonBT: "Gửi yêu cầu",
            disableNextButton:
              validate({
                ...state,
                curenPage: "review-infor",
              }).length > 0,
          };

        case "review-infor":
          dispatch({ type: "openDialogNoti", name: "warning" });
          return;

        default:
          break;
      }
    },

    submitPress: () => {
      const { curenPage, curenStep, arrayLinks, insuranceType } = state;

      const isAccidentType = insuranceType?.insuranceCode === "tai_nan";

      switch (curenPage) {
        case "get-infor":
          window.ReactNativeWebView.postMessage("onGoBack");
          break;

        case "choose-insurance":
          return {
            curenPage: "get-infor",
            disableNextButton: false,
            headerTitle: "Yêu cầu bồi thường",
            disableNextButton:
              validate({
                ...state,
                curenPage: "get-infor",
              }).length > 0,
          };

        // case "cccd":
        //   return {
        //     curenPage: "choose-insurance",
        //     curenStep: curenStep === 0 ? curenStep : curenStep - 1,
        //     headerTitle: "Chọn gói bảo hiểm",
        //     disableNextButton:
        //       validate({
        //         ...state,
        //         curenPage: "choose-insurance",
        //       }).length > 0,
        //   };

        // case "camera":
        case "reported-accident":
          return {
            curenPage: "choose-insurance",
            curenStep: curenStep === 0 ? curenStep : curenStep - 1,
            headerTitle: "Chọn gói bảo hiểm",
            disableNextButton:
              validate({
                ...state,
                curenPage: "choose-insurance",
              }).length > 0,
          };

        case "license":
          return isAccidentType
            ? {
              curenPage: "reported-accident",
              curenStep: curenStep === 0 ? curenStep : curenStep - 1,
              headerTitle: "Tường trình tai nạn",
              disableNextButton:
                validate({
                  ...state,
                  curenPage: "reported-accident",
                }).length > 0,
            }
            : {
              curenPage: "choose-insurance",
              curenStep: curenStep === 0 ? curenStep : curenStep - 1,
              headerTitle: "Chọn gói bảo hiểm",
              disableNextButton:
                validate({
                  ...state,
                  curenPage: "choose-insurance",
                }).length > 0,
            };

        case "infor":
          return {
            curenPage: "license",
            curenStep: curenStep === 0 ? curenStep : curenStep - 1,
            headerTitle: "Tạo yêu cầu bồi thường",
            disableNextButton:
              validate({
                ...state,
                curenPage: "license",
              }).length > 0,
          };

        case "review-infor":
          return {
            curenPage: "infor",
            curenStep: curenStep === 0 ? curenStep : curenStep - 1,
            headerTitle: "Thông tin người được BH",
            titleButtonBT: "Tiếp tục",
            disableNextButton:
              validate({
                ...state,
                curenPage: "infor",
              }).length > 0,
          };

        case "show-license":
          return {
            curenPage: "review-infor",
            headerTitle: "Xác nhận yêu cầu",
            disableNextButton:
              validate({
                ...state,
                curenPage: "review-infor",
              }).length > 0,
          };

        case "show-link":
          return {
            curenPage: arrayLinks.length > 1 ? "show-license" : "review-infor",
            headerTitle:
              arrayLinks.length > 1 ? "Hóa đơn điện tử" : "Xác nhận yêu cầu",
          };

        default:
          break;
      }
    },

    openPageShowImg: () => ({
      curenPage: "show-license",
      nameShowImgPage: action.name,
      headerTitle:
        action.name === "img" ? "Hình ảnh chứng từ" : "Hóa đơn điện tử",
    }),

    openPageShowLink: () => ({
      curenPage: "show-link",
      indexLink: action.index,
      headerTitle: "Hóa đơn điện tử",
    }),

    onChangeGetInfor: () => {
      const { name, value } = action;
      const {
        errors,
        insuranceTypes,
        insuranceType,
        riskTypes,
        riskType,
        typeOfIndimnities,
        typeOfIndimnity,
        beneficiary,
      } = state;

      const res = {};
      res[name] = value;
      errors[name] = false;

      if (name === "insuranceType") {
        res.insuranceType = insuranceTypes.find((i) => i.id === value) || {};
        res.typeOfIndimnities =
          insuranceTypes.find((i) => i.id === value)?.typeOfIndimnity || [];
        res.riskType = "";
        res.riskTypes = [];
        res.typeOfIndimnity = "";
      }
      if (name === "typeOfIndimnity") {
        const temp = typeOfIndimnities.find((i) => i.id === value) || {};
        res.beneficiary = value == 6 ? "relative" : beneficiary;
        res.typeOfIndimnity = { ...temp };
        res.riskTypes = temp?.typeOfRisk || [];
        res.riskType = "";
        res.typeOfTreatment = null;

        errors[name] = false;
      }
      if (name === "riskType") {
        res.riskType = riskTypes.find((i) => i.id === value);
        res.typeOfTreatment = null;
      }

      res.listImgLicese = getListLicenseById({
        insuranceCode:
          res?.insuranceType?.insuranceCode ||
          insuranceType?.insuranceCode ||
          null,
        typeOfIndimnity:
          +res?.typeOfIndimnity?.id || +typeOfIndimnity?.id || null,
        typeOfRisk: +res?.riskType?.id || riskType?.id || null,
      });

      const disableNextButton = validate({ ...state, ...res }).length > 0;
      return { ...res, errors, disableNextButton };
    },

    getListPackeInsurance: async () => {
      const { privateId, insuranceType } = state;

      const oldState = JSON.parse(localStorage?.getItem("data-infor"));
      if (
        oldState?.privateId === privateId &&
        oldState?.insuranceCode === insuranceType.insuranceCode
      ) {
        return;
      }

      dispatch("onLoading");
      const res = {};
      const params = {
        keyword: privateId,
        // insuranceCode: insuranceType?.insuranceCode || "",
        insuranceCode: "con_nguoi",
        limit: 10,
        offset: 0,
      };
      const dataApi = _.isEmpty(had_partner_phone)
        ? await getListPackeInsurance(params)
        : await getListPackeInsuranceWithPartner({
          phone: had_partner_phone.phone,
          partner: had_partner_phone.partner,
          data: params,
        });
      if (dataApi instanceof Error) {
        return {
          loading: false,
          listInsurances: [],
          listInsuranceesCheck: [],
        };
      }

      if (dataApi?.data?.statusCode === 200) {
        localStorage.setItem(
          "data-infor",
          JSON.stringify({
            privateId: privateId,
            insuranceCode: insuranceType?.insuranceCode || "",
          })
        );
        const temp = dataApi?.data?.result?.data || [];
        res.listInsurances = filterPackageInsurance(temp);
        res.listInsuranceesCheck = [];

        res.page = {
          total: dataApi?.data?.result?.total,
          offset: 10,
        };
      }

      const disableNextButton =
        validate({
          ...state,
          ...res,
        }).length > 0;

      return { loading: false, ...res, disableNextButton };
    },

    getItems: async () => {
      const { privateId, riskType, insuranceType, page, listInsurances } =
        state;
      if (page.total <= page.offset || page.total <= 10) {
        return;
      }
      const res = {};
      dispatch("onLoading");

      const params = {
        keyword: privateId,
        typeOfInsurance: insuranceType.id,
        // insuranceCode: insuranceType?.insuranceCode || "",
        insuranceCode: "con_nguoi",
        typeOfRisk: riskType.id,
        limit: 10,
        offset: page.offset,
        ...had_partner_phone,
      };
      const dataApi = _.isEmpty(had_partner_phone)
        ? await getListPackeInsurance(params)
        : await getListPackeInsuranceWithPartner({
          phone: had_partner_phone.phone,
          partner: had_partner_phone.partner,
          data: params,
        });

      if (dataApi instanceof Error) {
        return {
          loading: false,
          listInsurances: [],
          listInsuranceesCheck: [],
        };
      }

      if (dataApi?.data?.statusCode === 200) {
        const temp = [
          ...listInsurances,
          ...(dataApi?.data?.result?.data || []),
        ];
        res.listInsurances = filterPackageInsurance(temp);

        res.page = {
          total: dataApi?.data?.result?.total,
          offset: page.offset + 10,
        };
      }

      const disableNextButton = !listInsurances.some((i) => i.isCheck);

      return { loading: false, ...res, disableNextButton };
    },

    oncheckInsurancePackage: () => {
      const { value, index } = action;
      const { listInsurances, insuredInfo } = state;
      const res = {};

      const temp = listInsurances.map((item, indexMap) => {
        return indexMap == index
          ? { ...item, isCheck: value }
          : { ...item, isCheck: false };
      });

      const disableNextButton = !temp.some((i) => i.isCheck);
      const listInsuranceesCheck = [temp.find((i) => i.isCheck)];
      const dataCheck = listInsuranceesCheck[0] || {};
      insuredInfo.insuredEmail = dataCheck?.insuredInfo?.insuredEmail;
      insuredInfo.insuredPhone = dataCheck?.insuredInfo?.insuredPhone;
      return {
        listInsurances: [...temp],
        ...res,
        disableNextButton,
        listInsuranceesCheck,
        insuredInfo,
      };
    },

    addImg: async () => {
      const { e, typeId, index } = action;
      const { listImgLicese, errors, listImgRelationships } = state;
      const files = e.target.files;
      if (files.length === 0) {
        return;
      }
      const res = {};

      dispatch("onLoading");
      const tempDocumentLicese =
        _.get(listImgLicese, `[${index}].documents`) || [];
      const promises = [];

      for (let i = 0; i < files.length; i++) {
        const data = new FormData();
        data.append("document", files[i]);

        const api = _.isEmpty(had_partner_phone)
          ? uploadImg(data)
          : uploadImgWithPartner({
            phone: had_partner_phone.phone,
            partner: had_partner_phone.partner,
            data,
          });
        promises.push(api);
      }

      const resultsApi = await Promise.all(promises);
      resultsApi.forEach((api) => {
        if (api?.data?.statusCode === 200) {
          const dataApi = _.get(api, "data.result[0]") || {};

          const dataImg = {
            path: dataApi?.path,
            size: dataApi?.size,
          };
          if (typeId === 517) {
            listImgRelationships.push(dataImg);
          } else {
            tempDocumentLicese.push(dataImg);
          }
        }
      });

      listImgLicese[index] = {
        ...listImgLicese[index],
        documents: tempDocumentLicese,
      };

      const err = validate({ ...state, listImgLicese, listImgRelationships });
      errors[typeId] = err.includes(typeId);
      const disableNextButton = err.length > 0;

      return {
        loading: false,
        listImgLicese,
        errors,
        listImgRelationships,
        disableNextButton,
      };
    },

    deleteImg: () => {
      const { indexList, typeId, index, url } = action;
      const { listImgLicese } = state;
      const itemImgByindex = listImgLicese[index];
      if (url) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: url },
        });
      }
      itemImgByindex.documents.splice(indexList, 1);
      listImgLicese[index] = { ...itemImgByindex };
      return { listImgLicese };
    },

    deleteImgRelationships: () => {
      const { index, url } = action;
      const { listImgRelationships } = state;
      if (url) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: url },
        });
      }

      listImgRelationships.splice(index, 1);
      return { listImgRelationships };
    },

    onChangeLink: () => {
      const { value, clear } = action;
      const arrayLinks = value.replace(/\r?\n?\s/g, "").split(",");

      const res = {};
      if (clear) {
        res.textLink = value;
      } else {
        res.textLink = arrayLinks.join(",\n");
      }
      return { arrayLinks, ...res };
    },

    onChangeRequestAmount: () => {
      const disableNextButton =
        validate({ ...state, requestAmount: action.value }).length > 0;
      return {
        requestAmount: action.value,
        disableNextButton,
      };
    },

    oncheckBeneficiary: () => {
      const { name, value } = action;
      if (!value) {
        return;
      }
      const disableNextButton =
        validate({ ...state, beneficiary: name }).length > 0;

      return { beneficiary: name, disableNextButton };
    },

    onChangeBeneficiary: () => {
      const { name, value } = action;
      const { insuredInfo, errors } = state;
      if (name === "relationshipWithBuyerId") {
        insuredInfo.relationshipWithBuyerName = relationships.find(
          (i) => i.value === value
        )?.title;
      }

      if (name === "bank") {
        insuredInfo.beneficiaryBankId = value?.id || "";
        insuredInfo.beneficiaryBankTitle = value?.short_name || "";
      }

      insuredInfo[name] = value;
      errors[name] = false;

      const disableNextButton = validate({ ...state, insuredInfo }).length > 0;

      return { insuredInfo, disableNextButton };
    },

    onLoading: () => ({ loading: true }),

    openSnacbars: () => ({
      snackbar: {
        ...state.snackbar,
        open: true,
        type: action.typeSnackbar,
        message: action.message,
      },
    }),

    closeSnackbar: () => ({ snackbar: { ...state.snackbar, open: false } }),

    openDaShowImg: () => {
      const { item, name } = action;
      const { listImgRelationships } = state;
      const res = {};

      if (name === "relationship") {
        res.listImgShow = listImgRelationships.map((i) => i.path);
        res.indexImgShow = { index: 1, path: res.listImgShow[0] };
      } else {
        res.listImgShow = item.documents.map((i) => i.path);
        res.indexImgShow = { index: 1, path: res.listImgShow[0] };
      }

      return { showDialogImg: true, ...res };
    },

    openShowImgDialogInline: () => {
      const { data, documents } = action;
      const listImgShow = documents.map((i) => i.path);
      const indexImgShow = { index: data.index + 1, path: data?.itemImg?.path };

      return { showDialogImg: true, listImgShow, indexImgShow };
    },

    closeDaShowImg: () => ({ showDialogImg: false }),

    changeIndexImg: () => {
      const { listImgShow, indexImgShow } = state;
      const { name } = action;
      const res = {};
      if (listImgShow.length === 1) {
        return;
      }

      if (name === "next") {
        res.indexImgShow = {
          index:
            indexImgShow.index === listImgShow.length
              ? 1
              : indexImgShow.index + 1,
          path:
            indexImgShow.index === listImgShow.length
              ? listImgShow[0]
              : listImgShow[indexImgShow.index],
        };
      } else {
        res.indexImgShow = {
          index:
            indexImgShow.index === 1
              ? listImgShow.length
              : indexImgShow.index - 1,
          path:
            indexImgShow.index === 1
              ? listImgShow[listImgShow.length - 1]
              : listImgShow[indexImgShow.index - 2],
        };
      }

      return res;
    },

    handlSignature: async () => {
      const { ref } = action;
      const { errors } = state;
      const url = ref.current.getTrimmedCanvas().toDataURL("image/png");

      dispatch("onLoadingSignature");

      const dataApi = _.isEmpty(had_partner_phone)
        ? await uploadImg_base64([
          {
            document: url,
          },
        ])
        : await uploadImg_base64_with_artner({
          phone: had_partner_phone.phone,
          partner: had_partner_phone.partner,
          data: [
            {
              document: url,
            },
          ],
        });
      const res = {};

      if (dataApi instanceof Error) {
        dispatch({ type: "clearSignature", ref: ref });
        return { loadingSignature: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        const resuilt = dataApi?.data?.result[0];
        res.dataSignature = {
          extraInfo: {
            documents: [{ path: resuilt?.path, size: resuilt?.size }],
          },
          typeId: 518,
          typeCode: "sign",
        };

        errors.signature = false;
      }

      const disableNextButton = validate({ ...state, ...res }).length > 0;

      return { loadingSignature: false, errors, ...res, disableNextButton };
    },

    clearSignature: () => {
      const { ref } = action;
      const { dataSignature } = state;
      const urlImg = _.get(dataSignature, "extraInfo.documents[0].path");

      if (urlImg) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: urlImg },
        });
      }

      ref?.current?.clear();
      dataSignature.extraInfo = {};

      const disableNextButton =
        validate({ ...state, dataSignature }).length > 0;

      return { dataSignature, disableNextButton };
    },

    onLoadingSignature: () => ({ loadingSignature: true }),

    checkConditions: () => {
      const { value } = action;
      const disableNextButton =
        validate({ ...state, checkConditions: value }).length > 0;

      return {
        checkConditions: value,
        errors: { ...state.errors, checkConditions: false },
        disableNextButton,
      };
    },

    onChangeAccodior: () => {
      const { name } = action;
      return { [name]: !state[name] };
    },

    onchangeInsuredGender: () => {
      const disableNextButton =
        validate({ ...state, insuredGender: action.value }).length > 0;
      return {
        insuredGender: action.value,
        errors: { ...state.errors, insuredGender: false },
        disableNextButton,
      };
    },

    uploadImgCCCD: async () => {
      const { files, face } = action;
      const { snackbar, dataPrivateId, errors } = state;

      const data = new FormData();
      const file = files[0];
      if (!file) {
        return;
      }
      data.append("document", file);

      dispatch("onLoading");
      // const dataApi = _.isEmpty(had_partner_phone)
      //   ? await uploadImg_base64([
      //       {
      //         document: files,
      //       },
      //     ])
      //   : await uploadImg_base64_with_artner({
      //       phone: had_partner_phone.phone,
      //       partner: had_partner_phone.partner,
      //       data: [
      //         {
      //           document: files,
      //         },
      //       ],
      //     });
      const dataApi = _.isEmpty(had_partner_phone)
        ? await uploadImg(data)
        : await uploadImgWithPartner({
          phone: had_partner_phone.phone,
          partner: had_partner_phone.partner,
          data,
        });

      const res = {};

      if (dataApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
        };
      }

      const dataUrl = _.get(dataApi, "data.result[0].path");
      const documents = dataPrivateId?.extraInfo?.documents || [{}, {}];

      if (face === "before") {
        documents[0] = { path: dataUrl, face };
      } else {
        documents[1] = { path: dataUrl, face };
      }

      res.dataPrivateId = {
        ...dataPrivateId,
        extraInfo: {
          documents: [...documents],
        },
      };
      const checkOcr = documents.find((i) => i.face === "before")?.ocr;

      if (!_.isEmpty(documents[0]) && !_.isEmpty(documents[1]) && !checkOcr) {
        const params = {
          extraInfo: {
            documents: [...documents].map((i) => ({
              path: i.path,
            })),
          },
          typeId: 500,
          typeCode: "private_id",
          text: null,
          value: null,
        };

        const dataApiOcr = await OCR_cccd(params);

        if (dataApiOcr?.data?.statusCode == 200) {
          const dataOcr = _.get(dataApiOcr, "data.result.extraInfo.ocr") || {};
          documents[0].ocr = true;

          res.dataPrivateId = {
            ...dataPrivateId,
            extraInfo: {
              documents: [...documents],
              ocr: _.get(dataApiOcr, "data.result.extraInfo.ocr") || {},
            },
          };

          res.insuredInfo = {
            insuredName: dataOcr?.idCardInfo?.name || "",
            insuredPrivateId: dataOcr?.idCardInfo?.id || "",
            insuredDob: converDate(dataOcr?.idCardInfo?.dob)
              ? moment(converDate(dataOcr?.idCardInfo?.dob)).format()
              : null,
          };

          const error = validate({ ...state, ...res, curenPage: "cccd" });
          ["insuredName", "insuredDob", "insuredPrivateId"].forEach((i) => {
            errors[i] = error.includes(i);
          });
          res.disableNextButton = error.length > 0;
        }
      }

      return { loading: false, ...res, curenPage: "cccd", errors };
    },

    formReportAccidnet: async () => {
      const { canvas } = action;
      const { listImgLicese } = state;
      // const canvat = await html2canvas(elements);
      dispatch("onLoading");
      const img = canvas?.toDataURL("image/png");
      const res = {};

      if (img) {
        const dataApi = await uploadImg_base64([
          {
            document: img,
          },
        ]);

        if (dataApi?.data?.statusCode === 200) {
          const resuilt = dataApi?.data?.result[0];
          const indexEl = listImgLicese.findIndex(
            (i) => i.typeCode === "report"
          );
          indexEl > -1 &&
            set(listImgLicese, `[${indexEl}].documents`, [
              ...(listImgLicese[indexEl]?.documents || []),
              { path: resuilt?.path, size: resuilt?.size, isCanvas: true },
            ]);
          // res.dataSignature = {
          //   extraInfo: {
          //     documents: [{ path: resuilt?.path, size: resuilt?.size }],
          //   },
          //   typeId: 518,
          //   typeCode: "sign",
          // };

          res.alreadyAccidentReportImg = true;
        }
      }

      return { ...res, listImgLicese, loading: false };
    },

    // movePageCamera: () => {
    //   const { face } = action;
    //   return { curenPage: "camera", headerTitle: "", face };
    // },

    onSubmit: async () => {
      const {
        listInsuranceesCheck,
        insuredGender,
        insuredInfo,
        beneficiary,
        insuranceType,
        riskType,
        listImgLicese,
        dataSignature,
        arrayLinks,
        listImgRelationships,
        typeOfIndimnity,
        extraInfo,
        requestAmount,
        dataPrivateId,
      } = state;

      const certs = listInsuranceesCheck?.map((i) => ({
        ...i,
        requestAmount: +requestAmount,
      }));
      const insuredInforUser = certs[0] || {};
      const elements = [];
      listImgLicese.forEach((i) => {
        (i?.documents || []).length > 0 &&
          elements.push({
            extraInfo: { documents: i.documents },
            typeId: i.typeId,
            typeCode: i.typeCode,
            text: null,
            value: null,
          });
      });

      const links =
        arrayLinks.length === 0
          ? []
          : [
            {
              extraInfo: {
                documents: arrayLinks
                  .filter((i) => !!i)
                  .map((l) => ({ path: l, size: null })),
              },
              typeId: 516,
              typeCode: "e-invoice",
              text: null,
              value: null,
            },
          ];

      const relationship =
        beneficiary === "selt"
          ? []
          : [
            {
              extraInfo: { documents: listImgRelationships },
              typeId: 517,
              typeCode: "relationship",
              text: null,
              value: null,
            },
          ];

      const newDocumentsPrivateId = dataPrivateId?.extraInfo?.documents.map(
        (i) => ({ path: i.path })
      );

      const params = {
        // certs: certs,
        ...(_.get(certs, "[0]", {}) || {}),
        insuredInfo: {
          insuredGender: [0, 1].includes(
            +insuredInforUser?.insuredInfo?.insuredGender
          )
            ? insuredInforUser?.insuredInfo?.insuredGender
            : insuredGender,
          insuredName:
            insuredInfo?.insuredName ||
            insuredInforUser?.insuredInfo?.insuredName ||
            null,
          insuredAddress:
            insuredInfo?.insuredAddress ||
            insuredInforUser?.insuredInfo?.insuredAddress ||
            null,
          insuredPrivateId:
            insuredInfo?.insuredPrivateId ||
            insuredInforUser?.insuredInfo?.insuredPrivateId ||
            "",
          insuredDob:
            insuredInfo?.insuredDob ||
            insuredInforUser?.insuredInfo?.insuredDob ||
            "",
          insuredPhone:
            insuredInfo?.insuredPhone ||
            insuredInforUser?.insuredInfo?.insuredPhone ||
            "",
          insuredEmail:
            insuredInfo?.insuredEmail ||
            insuredInforUser?.insuredInfo?.insuredEmail ||
            "",
          relationshipWithInsuredPersonId:
            beneficiary === "self" ? 1 : beneficiary === "relative" ? 2 : null,
        },
        extraInfo: {
          typeOfInsurance: insuranceType.id || null,
          typeOfRisk: riskType.id || null,
          typeOfTreatment: extraInfo.typeOfTreatment || null,
          declarationApp: declarationApp || null,
          accidentSituation: extraInfo?.accidentSituation || null,
          dateOfAccident: extraInfo?.dateOfAccident || null,
          placeOfAccident: extraInfo?.placeOfAccident || null,
          reason: extraInfo?.reason || null,
          result: extraInfo?.result || null,
          typeOfIndimnity: typeOfIndimnity?.id | null,
          treatmentPlace: "",
          departmentTreatment: "",
          dayOfTreatment: "",
          doctorName: "",
          beneficiaryBankId: insuredInfo?.beneficiaryBankId,
          beneficiaryBank: insuredInfo?.beneficiaryBankTitle,
          beneficiaryBankAcountNumber: insuredInfo?.beneficiaryBankAcountNumber,
          beneficiaryBankBranchName: insuredInfo?.beneficiaryBankBranchName,
          beneficiaryId:
            beneficiary === "self"
              ? 1
              : insuredInfo?.relationshipWithBuyerId || null,
          beneficiaryName:
            beneficiary === "self"
              ? insuredInforUser?.insuredInfo?.insuredName
              : insuredInfo.beneficiaryName || "",
        },
        elements: [
          {
            ...dataPrivateId,
            extraInfo: {
              documents: newDocumentsPrivateId,
              ocr: dataPrivateId?.extraInfo?.ocr || null,
            },
          },
          ...elements,
          {
            ...dataSignature,
            text: null,
            value: null,
          },
          ...links,
          ...relationship,
        ],
      };

      dispatch("onLoading");
      const dataApi = _.isEmpty(had_partner_phone)
        ? await createClaimHealth(params)
        : await createClaimHealthWithPartner({
          data: params,
          phone: had_partner_phone?.phone || "",
          partner: had_partner_phone?.partner || "",
        });

      if (dataApi?.data?.messageCode == "cert.debt.fee.error") {
        dispatch({
          type: "openDialogNoti",
          name: "error",
          content: [dataApi?.data?.message],
        });
        return { loading: false };
      }

      if (dataApi?.data?.statusCode != 200) {
        dispatch({ type: "openDialogNoti", name: "error" });
      }

      if (dataApi?.data?.statusCode === 200) {
        dispatch({
          type: "openDialogNoti",
          name: "success",
          id: _.get(dataApi, "data.result.id") || "",
        });
      }

      return { loading: false };
    },

    openDialogNoti: () => ({
      dialogNoti: {
        type: action.name,
        open: true,
        id: action?.id || "",
        content: action?.content || null,
      },
    }),

    closeDialogNoti: () => ({
      dialogNoti: { ...state.dialogNoti, open: false },
    }),

    onchangeExtraInfo: () => {
      const { name, value } = action;
      const { extraInfo, errors, typeOfIndimnity, insuranceType } = state;
      const res = {};
      extraInfo[name] = value;
      errors[name] = false;
      if (name === "typeOfTreatment") {
        res.listImgLicese = getListLicenseById({
          insuranceCode: insuranceType.insuranceCode,
          typeOfIndimnity: typeOfIndimnity?.id,
          typeOfRisk: +value || null,
        });
      }

      return { extraInfo, errors, ...res };
    },

    setOpenPicker: () => {
      const { name } = action;
      const { picker } = state;

      picker[name] = !picker[name];

      return { picker };
    },

    onChangeReportedAccident: () => {
      const { value, name } = action;
      const { extraInfo, insuredInfo } = state;

      if (["riskTime", "riskDate"].includes(name)) {
        const hours = moment(extraInfo.dateOfAccident).format("HH") || "00";
        const minutes = moment(extraInfo.dateOfAccident).format("mm") || "00";

        const newValue =
          name === "riskTime"
            ? moment(extraInfo.dateOfAccident).set({
              hours: value[0],
              minutes: value[1],
            })
            : moment(value).set({
              hours: hours,
              minutes: minutes,
            });

        extraInfo.dateOfAccident = newValue;
        return { extraInfo };
      }

      if (["insuredName", "insuredAddress"].includes(name)) {
        insuredInfo[name] = value;
      } else {
        extraInfo[name] = value;
      }
      const alreadyAccidentReportImg = false;
      const disableNextButton =
        validate({ ...state, insuredInfo, extraInfo }).length > 0;

      return {
        insuredInfo,
        extraInfo,
        disableNextButton,
        alreadyAccidentReportImg,
      };
    },

    setDialogInstruction: () => {
      const { dialogInstruction } = state;
      return { dialogInstruction: !dialogInstruction };
    },

    openModalRule: () => {
      const { listInsuranceesCheck, snackbar } = state;
      let url = null;
      const documentsProduct =
        _.get(listInsuranceesCheck, "[0].productExtraInfo.documents", []) || [];
      if (documentsProduct.length) {
        url =
          documentsProduct.find((i) => i.type_code === "terms_and_conditions")
            ?.path || null;
      }

      if (!url) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Xin lỗi hiện chưa có các điều khoản cho Bảo Hiểm này",
          },
        };
      }

      return { modalRule: { open: true, url } };
    },

    closeModalRule: () => {
      return { modalRule: { open: false, url: null } };
    },

    onLoadingCDNAccident: () => ({ loadingSignature: true }),

    //
  };

  return action.type && cases[action.type];
}
