import React from "react";
import _ from "lodash";
import { Box, Typography } from "@mui/material";

export default function RowData(props) {
  const { title, value, classes } = props;
  const style = _.isEmpty(classes) ? styleBox : classes;
  return (
    <Box sx={style}>
      <Typography className="string title">{title}</Typography>
      <Typography className="string value">{value}</Typography>
    </Box>
  );
}

const styleBox = {
  display: "flex",
  justifyContent: "space-between",
  padding: "0 16px 16px 16px",
  "& .string": {
    fontSize: "15px",
    lineHeight: "19px",
    fontWeight: "bold",
  },
  "& .title": {
    color: "#0B3C8A",
  },
};
