import { Form } from 'antd';
import styled, { css } from 'styled-components';

const largeSize = css`
	height: 53px;
	padding-right: 13px;
	font-size: 17px;
`;
const defaultSize = css`
	height: 42px;
	font-size: 17px;
`;

export const FieldStyle = styled(Form.Item)`
	width: 100%;
	.ant-input {
		${props => (props.size === 'large' ? largeSize : defaultSize)};
    border: 1px solid rgba(151, 151, 151, 0.24)
	}
	.ant-input-number-input {
		${props => (props.size === 'large' ? largeSize : defaultSize)};
	}
	.ant-calendar-picker {
		width: 100%;
	}
`;
