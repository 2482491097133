import { Button, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Loading } from "./../components/Loading";
import { useRecoilState } from "recoil";

import TableToExcel from "@linways/table-to-excel";
import useReducer from "../../../../../library/hooks/useReducer";
import CommonTable from "../../../../../library/layout/commonTable";
import CarSearch from "../components/CarSearch";
import DialogNote from "../components/DialogNote";
import TableExportExcelReport from "../components/TableExportExcel";
import { columns } from "../components/columns";
import managementReducer from "../reducer/managementReducer";
import { dataSearchList } from "../atomAharide";

import exportIcon from "../assets/exportIcon.svg";
import { useStyles } from "../styles/useStyles";
import { get } from "lodash";

const initialState = {
  rows: [],
  dataExport: [],
  row: {},
  showImg: {},
  dataSearch: {},
  errors: {},
  pageTable: {
    count: 0,
    page: 0,
    rowsPerPage: 10,
  },
  count: 0,
  totalRows: 0,
  approvalAmount: "",
  content: "",
  setPage: "compensation",
  loading: false,
  confirmDialog: false,
  openMaxLineDialog: false,
};

export default function Management({ form }) {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const roleType = user?.extra_info?.userType;
  const privilegesFromUser =
    get(user, "role_management_claim.privileges") || {};
  const history = useHistory();

  const [dataSearchAharideList, setdataSearchAharideList] =
    useRecoilState(dataSearchList);

  const [state, dispatch] = useReducer({
    reducer: managementReducer,
    name: "managementReducer",
    initialState,
    history,
    form,
    roleType,
    user,
    dataSearchAharideList,
    setdataSearchAharideList,
  });

  const {
    loading,
    rows,
    pageTable,
    count,
    dataExport,
    dataSearch,
    openMaxLineDialog,
  } = state;

  useEffect(() => {
    dispatch("initial");

    return () => {
      if (
        ![
          "/admin/aharide/report/detail",
          "/admin/aharide/management/detail",
        ].includes(window.location?.pathname)
      ) {
        setdataSearchAharideList({});
      }
    };
  }, []);

  useEffect(async () => {
    if (count !== 0) {
      const table = document.getElementById("export-data-table");
      const name = "Bao-cao-boi-thuong-aharide.xlsx";
      TableToExcel.convert(table, {
        name,
        sheet: {
          name: "Sheet 1",
        },
      });
    }
  }, [count]);

  return (
    <div className={classes.container}>
      <div className="head-title">
        {form === "approve-claim"
          ? "Kiểm Tra Xử Lý Hồ Sơ"
          : "Báo Cáo Bồi Thường"}
      </div>
      <CarSearch {...{ state, dispatch, user }} />
      <div className={classes.boxTable}>
        <div className="head">
          <div className="head-title">KẾT QUẢ TRA CỨU</div>
          <div className="button-table">
            <div className={classes.selectPage}>
              <Select
                className="select-number"
                fullWidth
                size="small"
                value={pageTable?.rowsPerPage || 10}
                style={{ color: "#2A3790" }}
                onChange={(e) => {
                  dispatch({
                    type: "onPaging",
                    page: pageTable?.page,
                    rowsPerPage: e?.target?.value,
                  });
                }}
              >
                <MenuItem value={10}>Hiển thị 10</MenuItem>
                <MenuItem value={20}>Hiển thị 20</MenuItem>
                <MenuItem value={50}>Hiển thị 50</MenuItem>
              </Select>
            </div>
            {form === "report-claim" &&
              privilegesFromUser?.CLAIM_REPORT == 1 && (
                <div className="button">
                  <Button onClick={() => dispatch("onSubmitExport")}>
                    <img src={exportIcon} className="icon" alt="exportIcon" />
                    Xuất báo cáo
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
      {rows.length > 0 ? (
        <CommonTable
          columns={columns}
          rows={rows}
          classes={classes}
          page={{
            ...pageTable,
            onChange: (event, page, rowsPerPage) => {
              dispatch({
                type: "onPaging",
                page,
                rowsPerPage,
              });
            },
          }}
          event={(type, data) =>
            (ele, ...rest) => {
              dispatch({ type, data, ele, rest });
            }}
        />
      ) : (
        <div className="result-text">
          Không có kết quả giống với từ khoá bạn tìm. Vui lòng kiểm tra lại
        </div>
      )}
      <DialogNote
        open={!!openMaxLineDialog}
        dispatch={dispatch}
        loading={loading}
      />
      <TableExportExcelReport
        data={dataExport}
        dataSearch={dataSearch}
        roleType={roleType}
      />
      <Loading loading={loading} />
    </div>
  );
}
