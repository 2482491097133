import { makeStyles } from "@mui/styles";

export const GCNManager = makeStyles(() => ({
  container: {
    width: "100%",
    marginTop: "45px",
    padding: "0 12px",
  },

  box_heder: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 0",
    alignItems: "center",

    "& .title-decentraliza": {
      fontSize: "20px",
      fontWeight: "700",
    },

    "& .buttons-add-decentraliza": {
      background: "#0B7CE4",
      color: "white",
      fontWeight: "500",

      "& .icon-button": { marginRight: "12px" },
    },
  },

  box_button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .text-field-selecter": {
      width: "150px",
      marginRight: "16px",
    },

    "& .text-field-search": {
      minWidth: "260px",
    },

    "& .div-buttons": {
      "& .button": {
        color: "#4B4B4B",
        background: "white",
        border: "1px solid #ECECEC",
        minWidth: "110px",
      },

      "& .dowload": {
        marginRight: "8px",
      },
    },

    "& .search-box": {
      display: "flex",
      alignItems: "center",
    },
  },

  div: {
    overflow: "auto",
    marginTop: "24px",
    paddingBottom: "0",
  },

  table: {
    minWidth: "1340px",
    paddingLeft: "12px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      fontWeight: "700",
      fontSize: "13px",
    },

    "& td": {
      fontSize: "13px",
    },

    "& .sticky": {
      position: "sticky",
    },

    "& .STT": {
      width: "45px",
      paddingLeft: "12px",
    },

    "& .BH": {
      paddingLeft: "16px",
    },

    "& .show-icon": {
      width: "55px",
      right: 0,
      background: "white",
    },

    "& .logo": {
      width: "40px",
      height: "40px",
      objectFit: "contain",
      padding: "2px",
      borderRadius: "50%",
      border: "0.5px solid rgba(0, 0, 0, 0.05)",
    },

    "& .status": {
      background: "white",
      width: "45px",
      right: "55px",
      textAlign: "center",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "2px solid #C0C0C0",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #ECECEC",
    background: "white !important",
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "white",
    padding: "8px 12px",

    "& .MuiTablePagination-root": {
      borderBottom: "none",
    },

    "& .MuiToolbar-root": {
      "& .MuiTablePagination-selectLabel": {
        display: "none",
      },

      "& .MuiInputBase-root": {
        display: "none",
      },

      "& .MuiTablePagination-displayedRows": {
        margin: "auto",
      },
    },

    "& .rows-per-page": {
      color: "#3961A0",
      fontSize: "14px",
    },
  },

  divButton: {
    maxWidth: "350px",
    minWidth: "130px",
    borderBottom: `1px solid #ECECEC`,

    "&:last-child": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },

  buttons: {
    width: "100%",
    color: "#4B4B4B",
    fontSize: "14px",
    cursor: "pointer",
    textTransform: "none",
    display: "flex",
    justifyContent: "start",
    textAlign: "left",
    padding: "8px 0",
    fontWeight: "400",
  },

  date: {
    margin: "0 4px",
    maxWidth: "140px",

    "& .MuiInputBase-root": {
      paddingLeft: "4px",
    },
    "& .MuiInputBase-input": {
      fontSize: "15px",
      padding: "9px 6px",
    },
    "& .MuiButtonBase-root": {
      padding: "6px",
      "& .MuiSvgIcon-root": {
        width: "20px",
      },
    },
  },

  //
}));
