import api from "../configs/api";

export function getListProducts(data) {
  return api
    .apiForm("POST", "/api/v2/product/list", data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function uploadImg(data) {
  return api
    .apiForm("POST", "/api/v2/document/upload/cdn", data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListOrganization(data) {
  return api
    .apiForm("POST", "/api/v2/organization/list", data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListProvider(data) {
  return api
    .apiForm("POST", "/api/v2/provider/list", data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListProductParent(data) {
  return api
    .apiForm("POST", "/api/v2/product-parent/list", data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function createProduct(data) {
  return api
    .apiForm("POST", "/api/v2/product", data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function updateProduct(data) {
  return api
    .apiForm("PUT", "/api/v2/product", data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getDetailProduct(id) {
  return api
    .apiForm("GET", `/api/v2/product/${id}/detail`)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListProductPackage(data) {
  return api
    .apiForm("POST", `/api/v2/product-package/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getDetailProductPackage(id) {
  return api
    .apiForm("GET", `/api/v2/product-package/${id}/detail`)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListBenefits(data) {
  return api
    .apiForm("POST", `/api/v2/benefit/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function createProductPackage(data) {
  return api
    .apiForm("POST", `/api/v2/product-package`, data)
    .then((res) => res)
    .catch((err) => err.response);
}
export function updateProductPackage(data) {
  return api
    .apiForm("PUT", `/api/v2/product-package`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListProviderParent(data) {
  return api
    .apiForm("POST", `/api/v2/provider-parent/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}
