import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export default function LoadingMui(props) {
  return (
    <Backdrop
      style={{
        zIndex: 99999,
        color: "#fff",
        borderRadius: "5px",
      }}
      open={!!props?.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
