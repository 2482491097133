import React, { useEffect, useState } from "react";
import { Wrapper, ContentWrapper, Content } from "./styles";
import { Col, Row, Checkbox, Modal } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";

import ItemDropDown from "../../../../ui/ItemDropDown";
import Button from "../../../../ui/buttons/Button";
import * as PATH from "../../../../configs/routesConfig";
import useWindowDimensions from "../../../../utils/WindowDimensions";
import { getDetailsClaimClient } from "../../../../apis/carAccidentApis";
import parseCurrency from "../../../../utils/parseCurrency";
import HeaderBar from "../../../../ui/HeaderBar";
import "./CarAccident.css";

const RowItem = ({ title = "", value = "", styleTitle }) => {
  return (
    <div style={styleRowItem}>
      <div style={styleTitle || styleTitleItem}>{title}</div>
      <div>{value}</div>
    </div>
  );
};

const DetailsClaimCarAccident = () => {
  const { width } = useWindowDimensions();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [details, setDetails] = useState({});

  const [listImage, setListImage] = useState([]);
  const [isShowData, setIsShowData] = useState(true);
  const [isShowDataClaim, setIsShowDataClaim] = useState(true);
  const [amount, setAmount] = useState(0);
  const [priceApprove, setPriceApprove] = useState(0);
  const [signature, setSignature] = useState(null);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    document.body.style.backgroundColor = "#F7F7F7";
    window.scrollTo({ top: 0, behavior: "smooth" });
    fetchDetail();
  }, [location]);

  const fetchDetail = () => {
    getDetailsClaimClient(location?.state?.claimId)
      .then((res) => {
        const data = res?.data?.result;
        let arrayImage = data?.elements?.filter((item) => {
          return item.typeId === 2;
        });
        let arraySign = data?.elements?.filter((item) => {
          return item.typeId === 1;
        });
        let arrayAmount = data?.elements?.filter((item) => {
          return item.typeId === 4;
        });
        arrayAmount.sort((a, b) => {
          return new Date(b.createdDate) - new Date(a.createdDate);
        });
        let arrayPrice = data?.elements?.filter((item) => {
          return item.typeId === 10;
        });
        arrayPrice.sort((a, b) => {
          return new Date(b.createdDate) - new Date(a.createdDate);
        });
        setListImage(arrayImage[0]?.documents);
        setAmount(arrayAmount[0]?.value);
        setPriceApprove(arrayPrice[0]?.value);
        setSignature(arraySign[0]?.documents[0]?.path);
        setDetails(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleShowData = () => {
    setIsShowData(!isShowData);
  };

  const handleShowDataClaim = () => {
    setIsShowDataClaim(!isShowDataClaim);
  };

  const renderImage = (item, index) => {
    if (index <= 3) {
      return (
        <Col align="center" key={index} span={6}>
          <img
            alt="image_accident"
            style={{
              width: width / 4 - 25,
              height: width / 4 - 25,
              maxWidth: 100,
              maxHeight: 100,
            }}
            src={item?.path}
          />
        </Col>
      );
    }
    return;
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <HeaderBar
            title="Chi tiết hồ sơ bồi thường"
            background="#F2F2F2"
            onPress={() => history.goBack()}
          />
          <Row justify="center" style={{ marginTop: 30 }}>
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <div style={formInfo}>
                <div style={claimCode}>Mã hồ sơ : {details?.code}</div>
                <ItemDropDown
                  title="Thông tin hợp đồng"
                  onPress={handleShowData}
                  isShowData={!isShowData}
                >
                  <div style={{ padding: "10px 0 10px 0" }}>
                    <RowItem
                      title="Giá trị xe"
                      value={parseCurrency(
                        parseInt(details?.insuredInfo?.price)
                      )}
                    />
                    <RowItem
                      title="Ngày hiệu lực"
                      value={moment(details?.insuredInfo?.beginDate).format(
                        "hh:mm:ss; DD/MM/YYYY"
                      )}
                    />
                    <RowItem
                      title="Ngày hết hạn"
                      value={moment(details?.insuredInfo?.endDate).format(
                        "hh:mm:ss; DD/MM/YYYY"
                      )}
                    />
                    <RowItem
                      title="Họ tên"
                      value={(
                        details?.insuredInfo?.insuredName || ""
                      ).toUpperCase()}
                    />
                    <RowItem
                      title="Ngày sinh"
                      value={(
                        details?.insuredInfo?.insuredDob || ""
                      )?.toUpperCase()}
                    />
                    <RowItem
                      title="CMND/CCCD/Hộ chiếu"
                      value={(
                        details?.insuredInfo?.insuredPrivateId || ""
                      )?.toUpperCase()}
                    />
                    <RowItem
                      title="Biển số xe"
                      value={(
                        details?.insuredInfo?.licenseNumber || ""
                      )?.toUpperCase()}
                    />
                    <RowItem
                      title="Năm sản xuất"
                      value={details?.insuredInfo?.yearOfManufacture || ""}
                    />
                    <RowItem
                      title="Hiệu xe"
                      value={(
                        details?.insuredInfo?.carTitle || ""
                      )?.toUpperCase()}
                    />
                    <RowItem
                      title="Số chỗ ngồi"
                      value={details?.insuredInfo?.seats || ""}
                    />
                    <RowItem
                      title="Số điện thoại"
                      value={details?.insuredInfo?.insuredPhone || ""}
                    />
                    <RowItem
                      title="Email"
                      value={details?.insuredInfo?.insuredEmail || ""}
                    />
                    <RowItem
                      title="Địa chỉ"
                      value={details?.insuredInfo?.insuredAddress || ""}
                    />
                  </div>
                </ItemDropDown>
                <div style={{ height: 24 }} />
                <ItemDropDown
                  title="Hồ sơ bồi thường"
                  onPress={handleShowDataClaim}
                  isShowData={!isShowDataClaim}
                >
                  <div style={{ padding: "10px 0 10px 0" }}>
                    <RowItem
                      title="Mã hồ sơ"
                      value={details?.code?.toUpperCase() || ""}
                    />
                    <RowItem
                      title="Ngày tai nạn"
                      value={moment(details?.extraInfo?.accidentDate).format(
                        "hh:mm:ss; DD/MM/YYYY"
                      )}
                    />
                    <RowItem
                      title="Địa điểm tai nạn"
                      value={details?.extraInfo?.accidentAddress || ""}
                    />
                    <RowItem
                      title="Gara"
                      value={details?.departmentName || ""}
                    />
                    {amount && (
                      <RowItem
                        title="Tổng chi phí sửa chữa"
                        value={parseCurrency(parseInt(amount))}
                      />
                    )}
                    {priceApprove && (
                      <RowItem
                        title="Phí phê duyệt bồi thường"
                        value={parseCurrency(parseInt(priceApprove))}
                      />
                    )}
                    <RowItem title="Nguyên nhân/ diễn biến tai nạn" />
                    <div style={txtNoteAccident}>
                      {details?.extraInfo?.accidentDescription}
                    </div>
                    <RowItem title="Hình ảnh hiện trường" />
                    <Row style={{ margin: "5px 10px 10px 10px" }}>
                      {listImage?.map((item, index) =>
                        renderImage(item, index)
                      )}
                    </Row>

                    <RowItem title="Thông tin tài khoản ngân hàng" />
                    <div style={{ padding: 5 }}>
                      <RowItem
                        title="Chủ tài khoản"
                        value={details?.bankAccountName || ""}
                      />
                      <RowItem
                        title="Số tài khoản"
                        value={details?.bankAccountNumber || ""}
                      />
                      <RowItem
                        title="Ngân hàng"
                        value={details?.bankName || ""}
                      />
                      <RowItem
                        title="Chi nhánh"
                        value={details?.bankBranchName || ""}
                      />
                    </div>
                  </div>
                </ItemDropDown>
                <div style={{ marginTop: 24 }}>
                  <Checkbox style={{ textAlign: "justify" }} checked>
                    Tôi đã đọc kỹ và chấp nhận tất cả các điều khoản trong Quy
                    tắc Bảo hiểm của Công ty bảo hiểm. Tôi cam đoan tất cả các
                    thông tin khai báo ở trên là đúng sự thật và hiểu rằng bất
                    kỳ thông tin nào khai báo không đúng sự thật có thể sẽ dẫn
                    đến việc Giấy chứng nhận bảo hiểm/ Hợp đồng bảo hiểm mất
                    hiệu lực và/hoặc Bảo hiểm Công ty bảo hiểm có quyền từ chối
                    một phần hoặc toàn bộ trách nhiệm bồi thường. Tôi đồng ý sẽ
                    thông báo cho Công ty bảo hiểm về bất kỳ sự thay đổi thông
                    tin nào (nếu có).
                  </Checkbox>
                </div>
                <div style={{ marginTop: 10, marginLeft: -10 }}>
                  <RowItem title="Chữ ký của bạn" />
                </div>
                <div style={styleSignature}>
                  <img
                    alt="image_sign"
                    src={signature}
                    style={{ maxWidth: 300 }}
                  />
                </div>
                <Modal
                  visible={previewVisible}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%", marginTop: 30 }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            </Col>
          </Row>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

const styleTitleItem = {
  flex: 1,
  fontSize: 15,
  color: "#024281",
  fontWeight: "600",
  marginRight: 10,
};

const styleTitleItemCustom = {
  flex: 1,
  fontSize: 15,
  color: "#000",
  fontWeight: "600",
};

const formInfo = {
  margin: "0px 0 12px 0",
};

const styleRowItem = {
  display: "flex",
  padding: "6px 12px 6px 12px",
};

const claimCode = {
  fontSize: 17,
  color: "#0B3C8A",
  fontWeight: "700",
  padding: "12px 0 24px 0",
};
const txtNoteAccident = {
  fontSize: 15,
  padding: "0 15px 0 10px",
};

const styleSignature = {
  width: "100%",
  height: 198,
  border: "1px solid rgba(151, 151, 151, 0.24)",
  borderRadius: 10,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

export default DetailsClaimCarAccident;
