import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getDatailsClaimCarFrom } from "../api/claim";
import { ModalLoading } from "../../../../../ui/modal";
import ApproveClaimDetail from "./approve-detail";

const QuotationApproveDetail = () => {
  const roleAdminMenuItem = JSON.parse(
    localStorage.getItem("roleAdminMenuItem")
  );
  const { id } = useParams();
  const [details, setDetails] = useState({});

  useEffect(() => {
    ModalLoading.show();
    getDatailsClaimCarFrom(roleAdminMenuItem?.userType, id)
      .then((res) => {
        ModalLoading.hide();
        setDetails(res?.data?.result);
      })
      .catch((err) => {
        ModalLoading.hide();
      });
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <ApproveClaimDetail details={details} />
      </Box>
    </>
  );
};

export default QuotationApproveDetail;
