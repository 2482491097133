import axios from "axios";
import config from "./config";

function apiForm(method, url, data = {}) {
  let token = localStorage.getItem("token");
  switch (method) {
    case "POST":
      return axios({
        method: "POST",
        url: config.SERVER + url,
        data,
        headers: {
          Authorization: `Bearer ${token ? `${token}` : ""}`,
        },
      });
    case "GET":
      return axios({
        method: "GET",
        url: config.SERVER + url,
        headers: {
          Authorization: `bearer ${token ? `${token}` : ""}`,
        },
      });
    case "PUT":
      return axios({
        method: "PUT",
        url: config.SERVER + url,
        data,
        headers: {
          Authorization: `bearer ${token ? `${token}` : ""}`,
        },
      });
    case "DELETE":
      return axios({
        method: "DELETE",
        url: config.SERVER + url,
        data,
        headers: {
          Authorization: `Bearer ${token ? `${token}` : ""}`,
        },
      });
    default:
      return;
  }
}

function apiForm_formData(method, url, data) {
  let token = localStorage.getItem("token");
  return axios({
    method,
    url: config.SERVER + url,
    data,
    headers: {
      Authorization: `Bearer ${token ? `${token}` : ""}`,
    },
  });
}

function apiForm1(url, data) {
  let token = localStorage.getItem("token");
  return axios({
    method: "POST",
    url: config.CORE_SERVER + url,
    data,
    headers: {
      Authorization: `Bearer ${token ? `${token}` : ""}`,
    },
  });
}

function apiGetFileByUrl(url) {
  return axios({
    method: "GET",
    url: url,
    responseType: "blob",
  });
}

export default {
  apiForm,
  apiForm_formData,
  apiForm1,
  apiGetFileByUrl,
};
