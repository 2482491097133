import { makeStyles } from "@mui/styles";

export const styleDialogShowPDF = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      margin: 0,
      width: "90%",
      maxWidth: "500px",
    },
  },

  dialogTitle: {
    padding: "0 !important",
    display: "flex",
    justifyContent: "end",
  },

  //
}));
