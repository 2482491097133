import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  useTheme,
  FormControlLabel,
  Radio,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { DatePicker } from "antd";

import { styeleds } from "./styled";
import { TextField } from "../../overrideMui";
import moment from "moment";
import { converDate } from "../../utils";

export default function ChartCard(props) {
  const { state, dispatch } = props;
  const classes = styeleds();
  const theme = useTheme();
  const {
    from,
    to,
    shortDashboard,
    showChart,
    xChart,
    dataClaimsChart,
    dataClaimsApproveChart,
    dataClaimsRejectChart,
  } = state;

  const options = {
    animation: true,
    layout: { padding: 0, margin: 0 },
    legend: { display: false },
    responsive: true,
    maintainAspectRatio: false,
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
        },
        gridLines: {
          display: true,
          drawBorder: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider,
        },
      },
    ],
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: false,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const dataSet = [
    // {
    //   label: "Người được bảo hiểm",
    //   data: [10, 20, 30, 40, 50, 55, 60, 70, 80, 90, 1],
    //   tension: 0.3,
    //   backgroundColor: "rgb(57, 97, 160, 0.2)",
    //   borderColor: "rgb(57, 97, 160, 1)",
    //   borderWidth: 2,
    // },
    {
      label: "HS yêu cầu bồi thường",
      data: [...dataClaimsChart],
      tension: 0.3,
      backgroundColor: "rgb(255 162 31 / 20%)",
      borderColor: "rgb(255, 162, 31, 1)",
      borderWidth: 2,
      name: "claims",
    },
    {
      label: "Chi trả bồi thường",
      data: [...dataClaimsApproveChart],
      tension: 0.3,
      backgroundColor: "rgb(6,157,39, 0.2)",
      borderColor: "rgb(6,157,39,1)",
      borderWidth: 2,
      name: "claimsApprove",
    },
    {
      label: "Từ chối chi trả",
      data: [...dataClaimsRejectChart],
      tension: 0.3,
      backgroundColor: "rgb(255,0,61,0.2)",
      borderColor: "rgb(255,0,61,1)",
      borderWidth: 2,
      name: "claimsReject",
    },
  ];

  const data = {
    labels: [...(xChart || [])],
    datasets: dataSet.filter((i) => !!showChart[i.name]),
  };

  return (
    <div className={classes.containerChart}>
      <div className={classes.headerChart}>
        <div className="check-box">
          <div
            style={{
              marginRight: "24px",
              color: "#0B3C8A",
              fontWeight: "600",
              minWidth: "100px",
            }}
          >
            Biểu đồ
          </div>
          <div style={{ width: "85%" }}>
            {/* <FormControlLabel
              className="form-control"
              control={<Radio className="no-1" checked={true} />}
              label="Người được bảo hiểm"
            /> */}
            <FormControlLabel
              className="form-control"
              id="claims"
              onChange={(e) => {
                dispatch({
                  type: "onChangeShowChart",
                  name: "claims",
                });
              }}
              checked={!!showChart?.claims}
              control={<Checkbox className="no-2" />}
              label="HS yêu cầu bồi thường"
            />
            <FormControlLabel
              className="form-control"
              id="onChangeShowChart"
              onChange={(e) => {
                dispatch({
                  type: "onChangeShowChart",
                  value: e.target.checked,
                  name: "claimsApprove",
                });
              }}
              checked={!!showChart?.claimsApprove}
              control={<Checkbox className="no-3" />}
              label="Chi trả bồi thường"
            />
            <FormControlLabel
              className="form-control"
              id="onChangeShowChart"
              onChange={(e) => {
                dispatch({
                  type: "onChangeShowChart",
                  value: e.target.checked,
                  name: "claimsReject",
                });
              }}
              control={
                <Checkbox
                  className="no-4"
                  checked={!!showChart?.claimsReject}
                />
              }
              label="Từ chối chi trả"
            />
          </div>
        </div>
        <div className="short-date">
          <div className="short">
            <span style={{ fontSize: "14px", marginRight: "12px" }}>
              Sắp xếp:{" "}
            </span>{" "}
            <TextField
              value={shortDashboard || ""}
              onChange={(e) =>
                dispatch({ type: "onChangeShort", value: e.target.value })
              }
              className={`${classes.textFieldSelect}  ${classes.textFieldStandard}`}
              variant="standard"
              select
              size="small"
            >
              {[
                { name: "Số hồ sơ", value: "count_status_id" },
                { name: "Số tiền", value: "total_amount" },
              ].map((item, key) => (
                <MenuItem value={item.value} key={key}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="date">
            <span style={{ marginRight: "12px" }}> Từ </span>
            <DatePicker
              disabledDate={(e) => {
                const date =
                  moment(e.format("YYYY/MM/DD")).diff(
                    moment(converDate(to)).format("YYYY/MM/DD"),
                    "days"
                  ) - 1;
                return date > -1 || date < -30;
              }}
              // disabledDate={(e) => {}}
              value={from ? moment(from) : null}
              onChange={(e) =>
                dispatch({
                  type: "onChangeDate",
                  value: e?.format() || null,
                  name: "from",
                })
              }
              allowClear={true}
              style={{
                ...Styles.input,
                paddingTop: 11,
                paddingBottom: 8,
              }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
            />
            <span style={{ margin: "0 12px" }}> Đến </span>
            <DatePicker
              disabledDate={(e) => {
                const date =
                  moment(e.format("YYYY/MM/DD")).diff(
                    moment(converDate(from)).format("YYYY/MM/DD"),
                    "days"
                  ) + 1;
                return date < 1 || date > 30;
              }}
              value={to ? moment(to) : null}
              onChange={(e) =>
                dispatch({
                  type: "onChangeDate",
                  value: e?.format() || null,
                  name: "to",
                })
              }
              allowClear={true}
              style={{
                ...Styles.input,
                paddingTop: true ? 11 : 13,
                paddingBottom: true ? 8 : 10,
              }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
            />
          </div>
        </div>
      </div>
      <div
        class="chart-container"
        style={{
          position: "relative",
          width: "100%",
          height: "70vh",
          maxHeight: "700px",
        }}
      >
        <Line options={options} data={data} />
      </div>
    </div>
  );
}

const Styles = {
  titleInput: {
    zIndex: 4,
    top: "-12px",
    left: 3,
    position: "absolute",
    background: "#ffffff",
    marginLeft: 8,
    width: "fit-content",
    fontWeight: 500,
    padding: "0px 5px",
    fontSize: 15,
    color: "#3961A0",
  },
  input: {
    fontSize: 16,
    boxShadow: "none",
    borderRadius: 16,
    width: "170px",
    border: "1px solid #A0A0A0",
  },
};
