import { TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import CommonTable from "../../../../library/layout/commonTable";
import emptyIcon from "../assets/empty.svg";
import { formatDate } from "../util";

const columns = [
  {
    label: "STT",
    headerClass: "STT",
    renderCell: ({ rowIdx }) => (
      <TableCell className="stt">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "Họ và tên ",
    key: "insuredName",
  },
  {
    label: "Số thẻ BH",
    key: "certNum",
  },
  {
    label: "CCCD/CMND",
    key: "insuredPrivateId",
  },
  {
    label: "NV/NT",
    key: "insuredType",
  },
  // {
  //   label: "Số diện thoại",
  //   key: "insuredPhone",
  // },
  {
    label: "Ngày bắt đầu",
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {formatDate(item?.beginDate)}
      </TableCell>
    ),
  },
  {
    label: "Ngày hết hạn",
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {formatDate(item?.endDate)}
      </TableCell>
    ),
  },
];

function ImportTable({ list }) {
  const classes = useStyles();
  return (
    <CommonTable
      classes={classes}
      rows={list}
      columns={columns}
      empty={
        <div className="empty">
          <img src={emptyIcon} alt="empty" />
        </div>
      }
    />
  );
}

export default ImportTable;

const useStyles = makeStyles(() => ({
  table: {
    marginTop: "20px",
    minWidth: "1300px",
    "& thead .STT": {
      textAlign: "center",
      minWidth: "50px",
    },
    "& tbody .stt": {
      textAlign: "center",
      padding: "4px 12px",
    },
  },
  div: {
    paddingBottom: 0,
  },
}));
