import React, { useEffect, useState, useRef } from 'react';
import { Wrapper, ContentWrapper, Content } from './styles';
import { Col, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';

import IconLocation from '../../../../assets/icons/ic_location.png'
import HeaderBar from '../../../../ui/HeaderBar';
import { getGaraList } from '../../../../apis/carAccidentApis';
import { InputField } from '../../../../ui/fields';
import { compare } from '../../../../utils/utils';
import './CarAccident.css'

const SelectGara = () => {
  const history = useHistory();
  const [ totalData, setTotalData ] = useState(0)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ listGara, setListGara ] = useState([])
  const [listDataFilter, setListDataFilter] = useState([])
  const [scrollTop, setScrollTop] = useState(0);
  const location = useLocation();

  useEffect(() => {
    document.body.style.backgroundColor = '#F7F7F7'
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  useEffect(() => {
    if(location?.state?.providerId) {
      if(listGara?.length <= totalData) {
        fetchData({providerId: location?.state?.providerId})
      }
    }
  }, [scrollTop])

  const fetchData = (data) => {
    setIsLoading(true)
    const params = {
      providerId: data.providerId,
      limit: 100000,
      offset: 0,
    }

    getGaraList(params).then(async res => {
      const total = res?.data?.result?.total
      const list = res?.data?.result?.data
      const newList = listGara.concat(list)
      setListGara(newList)
      setListDataFilter(newList)
      setTotalData(total)
    })
    .catch(err => {
      console.error('err', err)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  const selectGara = (item) => () => {
    global.dataGara = item
    history.goBack()
  }

  const renderListGara = (item, index) => {
    return(
      <Row
        style={itemGara}
        key={'gara' + index}
        onClick={selectGara(item)}
      >
        <div style={iconAdd}>
          <img alt="example" style={{ width: 28, height: 28 }} src={IconLocation} />
        </div>
        <div style={{flex: 1}}>
          <div style={txtNameGara}>
            {item.name}
          </div>
          <div style={txtAddressGara}>
            {item.address}
          </div>
        </div>
      </Row>
    )
  }

  const listInnerRef = useRef();

  const onScroll = (e) => {
    const element = e.target.documentElement;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setScrollTop(element.scrollTop)
    }
  } 

  const handleSearch = (e) => {
    let newList = []
    newList = listGara.filter(item =>
      !e.target.value ? item.name : compare(e.target.value, item.name) || compare(e.target.value, item.address)
    );
   
    setTimeout(() => setListDataFilter(newList), 300)
  }

	return (
		<Wrapper onScroll={onScroll} ref={listInnerRef}> 
			<ContentWrapper>
        <HeaderBar
          title='Chọn Gara sửa chữa'
          onPress={() => history.goBack()}
          background='#F2F2F2'
        />
				<Content style={{marginTop: 55}}>
          <Row justify='center'>
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <InputField
                name="search"
                autoComplete="search"
                placeholder="Tìm kiếm"
                size="largeSize"
                inputStyle={inputStyle}
                hasIconLeft
                iconStart={<SearchOutlined style={{paddingRight: 5, color: 'rgba(0, 0, 0, 0.54)'}}/>}
                onChange={handleSearch}
              />
              <div style={titleGaraNearest}>
                Danh sách Gara
              </div>
              {isLoading && <div style={styleLoading}>
                <LoadingOutlined style={{
                  fontSize: 30,
                  color: '#E16B2F',
                }}/>
              </div>}
              {listDataFilter?.map((item, index) => renderListGara(item, index))}
            </Col>
          </Row>
				</Content>
			</ContentWrapper>
		</Wrapper>
	);
}


const inputStyle = {
  borderRadius: 9,
  height: 53,
  fontSize: 17,
}

const iconAdd = {
  display: 'flex',
  width: 50,
  height: 50,
  borderRadius: 25,
  border: '1px solid rgba(0, 0, 0, 0.08)',
  marginRight: 12,
  alignItems: 'center',
  justifyContent: 'center'
}

const itemGara = {
  margin: '20px 0 20px 0',
  alignItems: 'center'
}

const styleLoading = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  marginTop: 20
}

const titleGaraNearest = {
  color: 'rgba(0, 0, 0, 1)',
  fontSize: 17,
  fontWeight: '600',
  marginTop: 20
}

const txtNameGara = {
  color: 'rgba(0, 0, 0, 1)',
  fontSize: 15,
}

const txtAddressGara = {
  color: 'rgba(0, 0, 0, 0.46)',
  fontSize: 13,
}

export default SelectGara;
