import React, { useEffect, useState } from "react";
import { Wrapper, ContentWrapper, Content } from "./styles";
import { Col, Row, Checkbox, message } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";

import ItemDropDown from "../../../../ui/ItemDropDown";
import Button from "../../../../ui/buttons/Button";
import * as PATH from "../../../../configs/routesConfig";
import useWindowDimensions from "../../../../utils/WindowDimensions";
import parseCurrency from "../../../../utils/parseCurrency";
import { createClaimCarAccident } from "../../../../apis/carAccidentApis";
import "./CarAccident.css";
import { TYPE_ELEMENTS_CAR_ACCIDENT } from "../../../../configs/constants";
import { messages } from "../../../../utils/messages";
import { ModalLoading } from "../../../../ui/modal";
import HeaderBar from "../../../../ui/HeaderBar";

const RowItem = ({ title = "", value = "", styleTitle }) => {
  return (
    <div style={styleRowItem}>
      <div style={styleTitle || styleTitleItem}>{title}</div>
      <div style={{ textAlign: "right", marginLeft: 15 }}>{value}</div>
    </div>
  );
};

const SummaryClaimCarAccident = () => {
  const { width } = useWindowDimensions();
  const [isShowData, setIsShowData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDataClaim, setIsShowDataClaim] = useState(false);
  const [dataClaim, setDataClaim] = useState({});
  const [insuranceInfo, setInsuranceInfo] = useState({});
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    document.body.style.backgroundColor = "#F7F7F7";
    window.scrollTo({ top: 0, behavior: "smooth" });
    setDataClaim(location?.state?.dataClaim);
    setInsuranceInfo(location?.state?.insuranceInfo);
  }, [location]);

  const onSubmitForm = () => {
    setIsLoading(true);
    const params = {
      buyerPhone: location?.state?.dataUser?.buyerPhone,
      buyerPrivateId: location?.state?.dataUser?.buyerPrivateId,
      buyerEmail: location?.state?.dataUser?.buyerEmail,
      productId: location?.state?.dataUser?.productId,
      providerId: location?.state?.dataUser?.providerId,
      productIdParent: location?.state?.dataUser?.productIdParent,
      certNum: insuranceInfo?.certNum,
      departmentId: dataClaim?.gara_data?.id,
      insuredInfo: insuranceInfo,
      extraInfo: {
        accidentDate: dataClaim?.accident_date,
        accidentAddress: dataClaim?.name,
        accidentDescription: dataClaim?.note_accident,
        beneficiaryName: dataClaim?.account_holder,
        beneficiaryBankId: dataClaim?.bank_name,
        beneficiaryBank: dataClaim?.banksInfo?.short_name,
        beneficiaryBankAcountNumber: dataClaim?.account_number,
        beneficiaryBankBranchName: dataClaim?.branch,
      },
      elements: [
        {
          typeId: TYPE_ELEMENTS_CAR_ACCIDENT.SIGNATURE,
          documents: [{ path: dataClaim?.signature }],
        },
        {
          typeId: TYPE_ELEMENTS_CAR_ACCIDENT.ACCIDENT,
          documents: dataClaim?.list_image,
        },
      ],
    };
    createClaimCarAccident(params)
      .then((res) => {
        createSuccess();
      })
      .catch((err) => {
        console.error("errr", err);
        createFail(err?.response?.data);
      });
  };

  const createSuccess = async () => {
    setIsLoading(false);
    localStorage.removeItem("listClaim");
    localStorage.removeItem("dataUser");
    history.push(PATH.LIST_CLAIM_PATH + location?.search + "&showheader");
    message.success({
      content: messages.alert.create_claim_success,
      duration: 5,
    });
  };
  const createFail = async (error) => {
    ModalLoading.hide();
    setIsLoading(false);
    message.error({
      content: error?.message || messages.alert.create_claim_error,
      duration: 5,
    });
  };

  const handleShowData = () => {
    setIsShowData(!isShowData);
  };

  const handleShowDataClaim = () => {
    setIsShowDataClaim(!isShowDataClaim);
  };

  const renderImage = (item, index) => {
    if (index <= 3) {
      return (
        <Col align="center" key={index} span={6}>
          <img
            alt="image_accident"
            style={{
              width: width / 4 - 25,
              height: width / 4 - 25,
              maxWidth: 100,
              maxHeight: 100,
            }}
            src={item?.thumbUrl}
          />
        </Col>
      );
    }
    return;
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <HeaderBar
          title="Tạo hồ sơ bồi thường"
          background="#F2F2F2"
          onPress={() => history.goBack()}
        />
        <Content style={{ marginTop: 60 }}>
          <Row justify="center">
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <div style={formInfo}>
                <ItemDropDown
                  title="Thông tin hợp đồng"
                  onPress={handleShowData}
                  isShowData={isShowData}
                >
                  <div style={{ padding: "10px 0 10px 0" }}>
                    <RowItem
                      title="Phí bảo hiểm"
                      value={parseCurrency(parseInt(insuranceInfo?.price))}
                    />
                    <RowItem
                      title="Ngày hiệu lực"
                      value={
                        insuranceInfo?.beginDate
                          ? `${moment(insuranceInfo?.beginDate).format(
                              "hh:mm:ss;DD/MM/YYYY"
                            )}`
                          : ""
                      }
                    />
                    <RowItem
                      title="Ngày hết hạn"
                      value={
                        insuranceInfo?.endDate
                          ? `${moment(insuranceInfo?.endDate).format(
                              "hh:mm:ss;DD/MM/YYYY"
                            )}`
                          : ""
                      }
                    />
                    <RowItem
                      title="Họ tên"
                      value={insuranceInfo?.insuredName?.toUpperCase() || ""}
                    />
                  </div>
                </ItemDropDown>
                <div style={{ height: 24 }} />
                <ItemDropDown
                  title="Hồ sơ bồi thường"
                  onPress={handleShowDataClaim}
                  isShowData={isShowDataClaim}
                >
                  <div style={{ padding: "10px 0 10px 0" }}>
                    <RowItem
                      title="Ngày tai nạn"
                      value={
                        dataClaim?.accident_date
                          ? `${moment(dataClaim?.accident_date).format(
                              "DD/MM/YYYY"
                            )}`
                          : ""
                      }
                    />
                    <RowItem title="Địa điểm tai nạn" value={dataClaim?.name} />
                    <RowItem title="Gara" value={dataClaim?.gara_data?.name} />
                    <RowItem title="Nguyên nhân/ diễn biến tai nạn" />
                    <div style={txtNoteAccident}>
                      {dataClaim?.note_accident}
                    </div>
                    <RowItem title="Hình ảnh hiện trường" />
                    <Row style={{ margin: "5px 10px 10px 10px" }}>
                      {dataClaim?.list_image?.map((item, index) =>
                        renderImage(item, index)
                      )}
                    </Row>
                    <RowItem title="Thông tin tài khoản ngân hàng" />
                    <div style={{ padding: 5 }}>
                      <RowItem
                        title="Chủ tài khoản"
                        value={dataClaim?.account_holder}
                        styleTitle={styleTitleItemCustom}
                      />
                      <RowItem
                        title="Số tài khoản"
                        value={dataClaim?.account_number}
                        styleTitle={styleTitleItemCustom}
                      />
                      <RowItem
                        title="Ngân hàng"
                        value={dataClaim?.banksInfo?.name}
                        styleTitle={{
                          ...styleTitleItemCustom,
                          minWidth: "fit-content",
                        }}
                      />
                      <RowItem
                        title="Chi nhánh"
                        value={dataClaim?.branch}
                        styleTitle={styleTitleItemCustom}
                      />
                    </div>
                  </div>
                </ItemDropDown>
                <div style={{ marginTop: 24 }}>
                  <Checkbox style={{ textAlign: "justify" }} checked>
                    Tôi đã đọc kỹ và chấp nhận tất cả các điều khoản trong Quy
                    tắc Bảo hiểm của Công ty bảo hiểm. Tôi cam đoan tất cả các
                    thông tin khai báo ở trên là đúng sự thật và hiểu rằng bất
                    kỳ thông tin nào khai báo không đúng sự thật có thể sẽ dẫn
                    đến việc Giấy chứng nhận bảo hiểm/ Hợp đồng bảo hiểm mất
                    hiệu lực và/hoặc Bảo hiểm Công ty bảo hiểm có quyền từ chối
                    một phần hoặc toàn bộ trách nhiệm bồi thường. Tôi đồng ý sẽ
                    thông báo cho Công ty bảo hiểm về bất kỳ sự thay đổi thông
                    tin nào (nếu có).
                  </Checkbox>
                </div>
                <div style={{ marginTop: 10, marginLeft: -10 }}>
                  <RowItem title="Chữ ký của bạn" />
                </div>
                <div style={signature}>
                  <img alt="image_sign" src={dataClaim?.signature} />
                </div>
                <div style={{ padding: "40px 10px 0 10px" }}>
                  <Button
                    type="primary"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={onSubmitForm}
                    border="14"
                  >
                    Gửi
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

const styleTitleItem = {
  flex: 1,
  fontSize: 15,
  color: "#024281",
  fontWeight: "600",
};

const styleTitleItemCustom = {
  flex: 1,
  fontSize: 15,
  color: "#000",
  fontWeight: "600",
};

const formInfo = {
  margin: "0 0 12px 0",
};

const styleRowItem = {
  display: "flex",
  padding: "6px 12px 6px 12px",
};

const claimCode = {
  fontSize: 17,
  color: "#0B3C8A",
  fontWeight: "700",
  padding: "12px 0 24px 0",
};
const txtNoteAccident = {
  fontSize: 15,
  padding: "0 15px 0px 20px",
};

const signature = {
  width: "100%",
  height: 168,
  border: "1px solid rgba(151, 151, 151, 0.24)",
  borderRadius: 10,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

export default SummaryClaimCarAccident;
