import React from "react";
import { Button } from "@mui/material";

import { TextField } from "../../../overrideMui";
import styledSendApproval from "./styledSendApproval";
import gim from "../../../../../../../assets/health/gim.svg";
import moment from "moment";

export default function Approval(props) {
  const { state, dispatch } = props;
  const classes = styledSendApproval();

  const { detail, dialogSendApproval, currentFilePdf } = state;

  const render = [
    { title: "Người gửi", value: detail?.userFullnameReview || "" },
    {
      title: "Gửi đến",
      value:
        currentFilePdf?.userAssign?.fullname ||
        detail?.userFullnameAssign ||
        "",
    },
    { title: "Mã hồ sơ", value: detail?.code || "" },
    { title: "Ngày gửi", value: moment().format("HH:mm DD/MM/YYYY") },
  ];

  return (
    <div>
      <div className={classes.clontainerContent}>
        <div className="apprival-title">Trình phê duyệt</div>
        {render.map((item, index) => (
          <div className="box-infor" key={index}>
            <div className="title">{item.title}</div>
            <div className="value">{item.value}</div>
          </div>
        ))}
        <div className={classes.boxNote}>
          <div className="note">Ghi chú</div>
          <TextField
            fullWidth
            multiline
            rows={3}
            className={classes.multilineTextField}
            value={dialogSendApproval?.note || ""}
            onChange={(e) =>
              dispatch({
                type: "onchangeNoteSendApproval",
                value: e?.target?.value,
              })
            }
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <span>
          <img src={gim} /> 1
        </span>
        <Button
          className="buttons"
          onClick={() =>
            dispatch({
              type: "openDialogNoti",
              typeDialog: "warning",
              name: "sendRequestApprpve",
              textContent: [
                "Bạn có chắc chắn muốn gửi yêu cầu",
                "phê duyệt lên cấp trên",
              ],
            })
          }
        >
          Gửi trình duyệt
        </Button>
      </div>
    </div>
  );
}
