import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useReducer from "../../../../library/hooks/useReducer";
import LoadingMui from "../../../../ui/LoadingMui";
import AddMerchant from "../components/AddMerchant";
import AddProduct from "../components/AddProduct";
import detailReducer from "../useReducer/detailReducer";
import { useStyles } from "../useStyles";
import { formatDate, getParamUrlByKey } from "../utils";

import Addcircle from "../assets/add-circle.svg";
import PencilEditIcon from "../assets/pencil-edit.svg";
import DefaultIcon from "../assets/logo-default.svg";

const initialState = {
  data: {},
  editData: {},
  product_parent: [],
  products: [],
  open: { merchant: false, product: false },
  loading: false,
  errors: [],
};

function Detail() {
  const history = useHistory();
  const id = getParamUrlByKey("id");
  const classes = useStyles();

  const [state, dispatch] = useReducer({
    reducer: detailReducer,
    name: "detailReducer",
    initialState,
    history,
    id,
  });

  useEffect(() => {
    dispatch("getDetail");
    dispatch("getListProductParent");
  }, []);

  const { data, editData, loading, open } = state;

  return (
    <>
      <div className={classes.headerBar}>Quản lý khách hàng</div>
      <LoadingMui loading={loading} />
      <Grid container className={classes.create}>
        <Grid item xs={12} sm={2.6} className="info">
          <div className="center">
            {data?.url_logo ? (
              <img src={data?.url_logo} alt="upload" className="logo-product" />
            ) : (
              <img src={DefaultIcon} alt="upload" className="logo-product" />
            )}
            <div className="name-product">{data?.title || ""}</div>
            <div className="tax-code">MST: {data?.taxCode || ""}</div>

            <Button
              className="button"
              onClick={() => dispatch({ type: "onOpen", name: "merchant" })}
              startIcon={<img src={PencilEditIcon} alt="ic" className="icon" />}
            >
              Chỉnh sửa
            </Button>
          </div>
          <div className="row data">
            <div className="nowrap">Số lượng sản phẩm</div>
            <div className="value">{data?.totalProduct || 0}</div>
          </div>
          <div className="row data">
            <div className="nowrap">Tổng người tham gia</div>
            <div className="value">{data?.totalParticipants || 0}</div>
          </div>
          <div className="row data">
            <div className="nowrap">Yêu cầu bồi thường</div>
            <div className="value">{data?.totalNumberClaim || 0} đơn</div>
          </div>
          <div className="row data">
            <div className="nowrap">Số điện thoại</div>
            <div className="value">{data?.phone || ""}</div>
          </div>
          <div className="row data">
            <div className="nowrap">Địa chỉ</div>
            <div className="value">{data?.address || ""}</div>
          </div>
          <div className="row data">
            <div className="nowrap">Email</div>
            <div className="value break-word">{data?.email || ""}</div>
          </div>
          <div className="create-date">
            Ngày tham gia:{" "}
            <span className="value">{formatDate(data?.createdDate)}</span>
          </div>
        </Grid>
        <Grid container item xs={12} sm={9} spacing={2}>
          <Grid item xs={12} className="bold">
            Sản phẩm đang tham gia
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            onClick={() => dispatch({ type: "onOpen", name: "product" })}
          >
            <div className="center box">
              <img src={Addcircle} alt={`icon`} className="logo" />
              <div className="add-product">Thêm sản phẩm</div>
            </div>
          </Grid>
          {(data?.productMerchantList || []).map((item, index) => (
            <Grid
              item
              xs={12}
              md={4}
              onClick={() => dispatch({ type: "changePage", item })}
            >
              <div className="row box">
                <img
                  src={item?.urlLogo || DefaultIcon}
                  alt={`ic${index}`}
                  className="logo border"
                />
                <div>
                  <div className="title">{`Bảo hiểm ${
                    item?.titleProduct || ""
                  }`}</div>
                  <div className="date">
                    Ngày tạo: {formatDate(item?.createdDate)}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <AddMerchant
        open={open?.merchant}
        id={id}
        data={editData}
        state={state}
        dispatch={dispatch}
      />
      <AddProduct open={open?.product} state={state} dispatch={dispatch} />
    </>
  );
}

export default Detail;
