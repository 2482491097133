import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function HeaderBar(props) {
  const { title = "", onPress, isApp } = props;
  const classes = styles();

  return (
    <Box className={classes.header}>
      <div
        className="wrapper"
        style={{ margin: isApp ? "48px 0 10px" : "10px 0" }}
      >
        <div
          style={{ top: isApp ? "44px" : "3px" }}
          className="button-back"
          onClick={onPress}
        >
          <ChevronLeftIcon style={{ fontSize: "35px" }} />
        </div>
        <div className="title">{title}</div>
      </div>
    </Box>
  );
}

const styles = makeStyles(() => ({
  header: {
    position: "sticky",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    background: "#284078",
    top: 0,
    left: 0,
    zIndex: 11,

    "& .wrapper": {
      width: "100%",
      display: "flex",
    },
    "& .button-back": {
      color: "white",
      display: "flex",
      alignItems: "center",
      padding: "0 13px",
      left: 0,
      top: 0,
    },
    "& .title": {
      color: "white",
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "35px",
      width: "80%",
      paddingRight: "16px",
      textAlign: "center",
    },
  },
}));
