import { get, isString } from "lodash";
import {
  getListbvpk,
  getListbvpk_createRequest,
} from "../../../../apis/blvp_api";

export default function managerBLVPReducer(props) {
  const { state, action, dispatch } = props;
  const userFromProfile = JSON.parse(localStorage.getItem("user")) || {};
  const userTypeFromProfile = get(userFromProfile, "extra_info.userType") || "";

  const cases = {
    initail: async () => {
      dispatch("onLoading");
      const listBVPKApi = await getListbvpk(userTypeFromProfile, {
        featureCode: "BLVP",
        limit: 20,
        offset: 0,
      });

      if (listBVPKApi?.data?.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lội khi lấy thông tin, xin vui lòng thử lại",
          },
        };
      }

      const listBVPK = get(listBVPKApi, "data.result.data", []) || [];
      const pageTable = {
        total: get(listBVPKApi, "data.result.total", 0),
        page: 1,
        count: Math.ceil(get(listBVPKApi, "data.result.total", 0) / 20),
      };

      return { loading: false, listBVPK, pageTable };
    },

    onChangePage: async () => {
      const { pageNumber } = action;
      const { dataSearch } = state;

      const params = {
        featureCode: "BLVP",
        limit: 20,
        offset: (pageNumber - 1) * 20 || 0,
      };

      dispatch("onLoading");
      const dataApi = await getListbvpk(params);

      if (dataApi?.data?.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lội khi lấy thông tin, xin vui lòng thử lại",
          },
        };
      }

      const listBVPK = get(dataApi, "data.result.data", []) || [];
      const pageTable = {
        total: get(dataApi, "data.result.total", 0),
        page: pageNumber,
        count: Math.ceil(get(dataApi, "data.result.total", 0) / 20),
      };

      return {
        loading: false,
        listBVPK,
        pageTable,
      };
    },

    openFilter: () => {
      const { filter } = state;

      return { filter: { ...filter, open: true } };
    },

    closeFilter: () => {
      const { filter } = state;
      return { filter: { ...filter, open: false } };
    },

    applyFilter: async () => {
      const { keyword = null } = action;
      const { dataSearch } = state;

      if (keyword || keyword === "") {
        dataSearch.keyword = keyword;
      }

      // if (["from", "to"].includes(name)) {
      //   dataSearch[name] = value;
      // }

      const params = {
        keyword: dataSearch.keyword || null,
        from: dataSearch?.from || null,
        to: dataSearch?.to || null,
        statusId: dataSearch?.statusId,
        limit: 20,
        offset: 0,
        featureCode: "BLVP",
      };

      dispatch("onLoading");
      const dataApi = await getListbvpk(params);
      const response = get(dataApi, "data", {});

      if (response.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi khi lấy thông tin, xin vui lòng thử lại",
          },
        };
      }
      const listBVPKApi = get(response, "result.data", []) || [];
      const pageTable = {
        total: get(response, "result.total", 0),
        page: 1,
        count: Math.ceil(get(response, "result.total", 0) / 20),
      };

      return {
        loading: false,
        listBVPKApi,
        pageTable,
      };
    },

    exportFileExcelBVPK: async () => {
      const { dataSearch } = state;
      dispatch("onLoading");

      const dataApi = await getListbvpk({
        limit: 500,
        offset: 0,
        featureCode: "BLVP",
        ...dataSearch,
      });

      if (dataApi?.data?.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lội khi lấy thông tin, xin vui lòng thử lại",
          },
        };
      }
      const dataExportExcel = get(dataApi, "data.result.data", []) || [];

      return { loading: false, count: state.count + 1, dataExportExcel };
    },

    // create request

    initail_createRequest: async () => {
      dispatch("onLoading");

      const dataApi = await getListbvpk_createRequest({ limit: 20, offset: 0 });

      if (dataApi?.data?.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lội khi lấy thông tin, xin vui lòng thử lại",
          },
        };
      }

      const listBVPK_createRequest = get(dataApi, "data.result.data", []) || [];
      const pageTable_createRequest = {
        total: get(dataApi, "data.result.total", 0),
        page: 1,
        count: Math.ceil(get(dataApi, "data.result.total", 0) / 20) || 1,
      };

      return {
        loading: false,
        listBVPK_createRequest,
        pageTable_createRequest,
      };
    },

    search_create_request: async () => {
      const { insuredPrivateId } = action;

      dispatch("onLoading");
      const dataApi = await getListbvpk_createRequest({
        limit: 20,
        offset: 0,
        insuredPrivateId,
      });

      if (dataApi?.data?.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lội khi lấy thông tin, xin vui lòng thử lại",
          },
        };
      }

      const listBVPK_createRequest = get(dataApi, "data.result.data", []) || [];
      const pageTable_createRequest = {
        total: get(dataApi, "data.result.total", 0),
        page: 1,
        count: Math.ceil(get(dataApi, "data.result.total", 0) / 20) || 1,
      };

      return {
        loading: false,
        listBVPK_createRequest,
        insuredPrivateId,
        pageTable_createRequest,
      };
    },

    onChangePage_createRequest: async () => {
      const { pageNumber } = action;
      const { insuredPrivateId } = state;

      dispatch("onLoading");
      const dataApi = await getListbvpk_createRequest({
        limit: 20,
        offset: (pageNumber - 1) * 20 || 0,
        insuredPrivateId: insuredPrivateId || null,
      });

      if (dataApi?.data?.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lội khi lấy thông tin, xin vui lòng thử lại",
          },
        };
      }

      const listBVPK_createRequest = get(dataApi, "data.result.data", []) || [];
      const pageTable_createRequest = {
        total: get(dataApi, "data.result.total", 0),
        page: pageNumber,
        count: Math.ceil(get(dataApi, "data.result.total", 0) / 20),
      };

      return {
        loading: false,
        listBVPK_createRequest,
        pageTable_createRequest,
      };
    },

    //

    onLoading: () => ({ loading: true }),

    //
  };

  return isString(action.type) && cases[action.type];
}
