import "../styles/index.css";
import { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import {
  BlueBagMoneyIcon,
  CalendarIcon,
  ColorDocumentIcon,
  GreenMoneyBagIcon,
  MerchantIcon,
  SmallBlueMoneyBag,
  SmallTotalIcon,
  ThreeDotsIcon,
  TotalIcon,
} from "../components/icon/icon";

import PercentTotal from "../components/chart/PercentTotal";
import TotalByMerchant from "../components/chart/TotalByMerchant";
import { Button, DatePicker, Form, message, Select } from "antd";
import {
  getDashboardClaim,
  getRevenueToday,
  getTop5,
  getTotalDashboard,
} from "../api/home";
import { formatMoney } from "../utils/money";
import { getListMerchant } from "../../../../../apis/merchantManageApis";
import moment from "moment";

const Dashboard = () => {
  const { Option } = Select;
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [form] = Form.useForm();
  const [anchorEl, setAnchorEl] = useState(null);
  const [totalData, setTotalData] = useState({});
  const [revenueToday, setRevenueToday] = useState({});
  const [top5, setTop5] = useState([]);
  const [orderBy, setOrderBy] = useState("desc");
  const [merchants, setMerchants] = useState([]);
  const [render, setRender] = useState(false);
  const [forbidden, setForbidden] = useState(true);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getClaim = async () => {
    const values = form.getFieldsValue();

    const result = await getDashboardClaim(
      values.merchant,
      values.start.startOf("date").format("YYYY-MM-DD HH:mm:ss"),
      values.end.endOf("date").format("YYYY-MM-DD HH:mm:ss")
    );
    // setTotalData(result.data)
  };

  useEffect(() => {
    async function callApi() {
      const result = await getTotalDashboard();
      if (result instanceof Error) return message.error("Có lỗi xảy ra");

      setTotalData(result.data?.result);
    }

    callApi();
  }, []);

  useEffect(() => {
    async function callApi() {
      const result = await getTop5(orderBy);
      if (result instanceof Error) return message.error("Có lỗi xảy ra");

      setTop5(result.data?.result);
    }

    callApi();
  }, [orderBy]);

  useEffect(() => {
    async function callApi() {
      const result = await getRevenueToday();
      if (result instanceof Error) return message.error("Có lỗi xảy ra");

      let temp = result.data?.result;
      temp = [...temp]
        .filter((item) => +item.total > 0)
        .map((item) => {
          return {
            name: item.title,
            value: +item.total,
            id: item.id,
          };
        });
      setRevenueToday(temp);
    }

    callApi();
  }, []);

  useEffect(() => {
    async function callApi() {
      const result = await getListMerchant();
      if (result instanceof Error) return message.error("Có lỗi xảy ra");

      setMerchants(result.data?.result?.data);
    }

    callApi();
  }, []);

  useEffect(() => {
    getClaim();
  }, []);

  useEffect(() => {
    if (!forbidden) return;

    setRender(true);
  }, [forbidden]);

  useEffect(() => {
    if (!currentUser) window.location.reload();

    if (
      +currentUser.role_id === 2 ||
      currentUser.extra_info?.userType === "globalcare" ||
      currentUser.role_management_claim?.submenu?.dashboard?.full_access !== 1
    )
      return setForbidden(false);
  }, []);

  return (
    <>
      {render && (
        <Container style={{ marginTop: "55px" }} maxWidth={false}>
          {forbidden && <>Bạn không có quyền truy cập trang này</>}
          {!forbidden && (
            <>
              <Typography
                fontWeight="fontWeightBold"
                color="transparent"
                variant="h7"
              >
                Bảo hiểm vật chất ô tô
              </Typography>
              <Form form={form}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={1}
                      style={{
                        padding: 10,
                        background: "#2A3790",
                        borderRadius: 16,
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{ display: "flex", padding: "10px 0" }}
                      >
                        <TotalIcon />
                        <div
                          style={{
                            color: "white",
                            marginLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          <div>Tổng đơn</div>
                          <div>
                            {formatMoney(totalData?.cert?.total)} <sub>Đơn</sub>
                          </div>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{ display: "flex", padding: "10px 0" }}
                      >
                        <BlueBagMoneyIcon />
                        <div
                          style={{
                            color: "white",
                            marginLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          <div>Tổng tiền</div>
                          <div>
                            {formatMoney(totalData?.cert?.total_fees)}
                            <sup>VNĐ</sup>
                          </div>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{ display: "flex", padding: "10px 0" }}
                      >
                        <ColorDocumentIcon />
                        <div
                          style={{
                            color: "white",
                            marginLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          <div>Tổng hồ sơ bồi thường</div>
                          <div>
                            {formatMoney(totalData?.claim?.total)}{" "}
                            <sub>Đơn</sub>
                          </div>
                        </div>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{ display: "flex", padding: "10px 0" }}
                      >
                        <GreenMoneyBagIcon />
                        <div
                          style={{
                            color: "white",
                            marginLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          <div>Tổng số tiền bồi thường</div>
                          <div>
                            {formatMoney(totalData?.claim?.total_amount)}{" "}
                            <sup>VNĐ</sup>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sm={12} md={7}>
                    <Grid
                      container
                      spacing={1}
                      style={{
                        padding: 10,
                        background: "white",
                        borderRadius: 16,
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25",
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          color={"#2A3790"}
                          fontWeight={700}
                          fontSize={18}
                          textTransform="uppercase"
                        >
                          Tỷ lệ cơ cấu doanh thu theo kênh bán (hôm nay)
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <PercentTotal data={revenueToday} />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          color={"#2A3790"}
                          fontWeight={700}
                          fontSize={18}
                          textTransform="uppercase"
                        >
                          Hồ sơ bồi thường
                        </Typography>
                      </Grid>

                      <Grid item xs={4} style={{ marginTop: 10 }}>
                        <Typography fontSize={18} color="#2A3790">
                          <MerchantIcon style={{ verticalAlign: "top" }} />
                          <span> Kênh bán</span>
                        </Typography>
                        <Form.Item name={"merchant"} initialValue={null}>
                          <Select
                            style={{ width: "100%", borderRadius: 32 }}
                            size="large"
                            allowSearch={true}
                            placeholder="Nhập thông tin"
                            className="rounded transparent-background"
                          >
                            <Option value={null}>Tất cả</Option>
                            {merchants.map((item, index) => {
                              return (
                                <Option key={index} value={item.id}>
                                  {item.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Grid>

                      <Grid item xs={4} style={{ marginTop: 10 }}>
                        <Typography fontSize={18} color="#2A3790">
                          <CalendarIcon style={{ verticalAlign: "top" }} />
                          <span> Từ ngày</span>
                        </Typography>
                        <Form.Item name={"start"} initialValue={moment()}>
                          <DatePicker
                            allowClear={false}
                            size="large"
                            style={{ width: "100%" }}
                            className="rounded"
                            placeholder="Chọn ngày"
                            format={"DD/MM/YYYY"}
                            disabledDate={(current) => {
                              return current.isAfter(moment());
                            }}
                          />
                        </Form.Item>
                      </Grid>

                      <Grid item xs={4} style={{ marginTop: 10 }}>
                        <Typography fontSize={18} color="#2A3790">
                          <CalendarIcon style={{ verticalAlign: "top" }} />
                          <span> Đến ngày</span>
                        </Typography>
                        <Form.Item name={"end"} initialValue={moment()}>
                          <DatePicker
                            allowClear={false}
                            size="large"
                            style={{ width: "100%" }}
                            className="rounded"
                            placeholder="Chọn ngày"
                            format={"DD/MM/YYYY"}
                            disabledDate={(current) => {
                              return current.isAfter(moment());
                            }}
                          />
                        </Form.Item>
                      </Grid>

                      <Grid xs={4}></Grid>

                      <Grid xs={4} style={{ paddingTop: 10 }}>
                        <Button
                          onClick={() => {
                            getClaim();
                          }}
                          style={{
                            color: "white",
                            background: "#2A3790",
                            borderRadius: 16,
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                          }}
                          size="large"
                          block
                        >
                          Lọc
                        </Button>
                      </Grid>

                      <Grid xs={4}></Grid>

                      <Grid item xs={12}>
                        <TotalByMerchant />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sm={12} md={5}>
                    <Grid
                      container
                      spacing={1}
                      style={{
                        padding: 10,
                        background: "white",
                        borderRadius: 16,
                        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25",
                      }}
                    >
                      <Grid item xs={11} md={10}>
                        <Typography
                          color={"#2A3790"}
                          fontWeight={700}
                          fontSize={18}
                          textTransform="uppercase"
                        >
                          Top doanh số dẫn đầu
                        </Typography>
                      </Grid>

                      <Grid item xs={1} md={2}>
                        {" "}
                        <Tooltip title="Top Doanh Số">
                          <IconButton
                            onClick={(e) => {
                              setAnchorEl(e.currentTarget);
                            }}
                            sx={{ ml: 1 }}
                          >
                            <ThreeDotsIcon />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setOrderBy("desc");
                            }}
                          >
                            Top 5 doanh số cao nhất
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setOrderBy("asc");
                            }}
                          >
                            Top 5 doanh số thấp nhất
                          </MenuItem>
                        </Menu>
                      </Grid>

                      {top5.map((item, index) => (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              background: "#FDF0F1",
                              boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
                              padding: 10,
                              borderRadius: 8,
                              minHeight: 130,
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={1}>
                                <div
                                  style={{
                                    background: "white",
                                    padding: 5,
                                    fontSize: 11,
                                    textAlign: "center",
                                    borderRadius: 4,
                                  }}
                                >
                                  {`0${index + 1}`}
                                </div>
                              </Grid>

                              <Grid item xs={3}>
                                <div
                                  style={{
                                    background: "white",
                                    borderRadius: 6,
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    src={
                                      item?.extra_info?.image ||
                                      "https://cdn.globalcare.vn/private/images/merchant/default-branch.png"
                                    }
                                    style={{ objectFit: "scale-down" }}
                                  />
                                </div>
                              </Grid>

                              <Grid
                                item
                                xs={7}
                                style={{
                                  padding: "20px 0 20px 10px",
                                  borderRadius: 6,
                                }}
                              >
                                <div>
                                  <Typography fontWeight={600} fontSize={18}>
                                    {item.title}
                                  </Typography>
                                  <div style={{ margin: "10px 0" }}>
                                    <SmallTotalIcon
                                      style={{ verticalAlign: "bottom" }}
                                    />
                                    <span style={{ marginLeft: 5 }}>
                                      Tổng đơn
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        color: "#DBAC06",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {formatMoney(item.total)} <sub>Đơn</sub>
                                    </span>
                                  </div>

                                  <div>
                                    <SmallBlueMoneyBag
                                      style={{ verticalAlign: "bottom" }}
                                    />
                                    <span style={{ marginLeft: 5 }}>
                                      Tổng tiền
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        color: "#26A9E0",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {formatMoney(item.total_fees)}{" "}
                                      <sub>VNĐ</sub>
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default Dashboard;
