import React from "react";
import { MenuItem } from "@mui/material";

import { TextField } from "../overrideMui";
import { styledGetInfor } from "../components/styeleds/styledGetInfor";
import { healthRiskTypes } from "../utils";

export default function GetInfor(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledGetInfor();
  const {
    insuranceType,
    privateId,
    riskType,
    errors,
    insuranceTypes,
    riskTypes,
    typeOfIndimnities,
    typeOfIndimnity,
  } = state;

  return (
    <div
      className={classes.container}
      style={{
        minHeight: isApp ? "calc(100vh - 175px)" : "calc(100vh - 133px)",
      }}
    >
      {/* <div className="title">
        Nhập họ tên/CCCD hoặc Sđt <span style={{ color: "red" }}>*</span>
      </div>
      <TextField
        fullWidth
        className={classes.textFields}
        value={privateId || ""}
        error={errors.privateId}
        helperText={errors.privateId ? "Vui lòng nhập đầy đủ thông tin" : false}
        onChange={(e) =>
          dispatch({
            type: "onChangeGetInfor",
            name: "privateId",
            value: e?.target?.value,
          })
        }
      /> */}
      <div className="title">Chọn loại bảo hiểm</div>
      <TextField
        fullWidth
        className={classes.textFields}
        select
        value={insuranceType?.id || ""}
        onChange={(e) =>
          dispatch({
            type: "onChangeGetInfor",
            name: "insuranceType",
            value: e?.target?.value,
          })
        }
      >
        {insuranceTypes.map((item, index) => (
          <MenuItem value={item.id} key={index}>
            {item.title}
          </MenuItem>
        ))}
      </TextField>
      <div className="title">
        Chọn loại bồi thường <span style={{ color: "red" }}>*</span>
      </div>
      <TextField
        fullWidth
        className={classes.textFields}
        select
        error={!!errors.compensationType}
        helperText={errors.riskType ? "Vui lòng chọn loại bồi thường" : false}
        value={typeOfIndimnity.id || ""}
        onChange={(e) =>
          dispatch({
            type: "onChangeGetInfor",
            name: "typeOfIndimnity",
            value: e?.target?.value,
          })
        }
      >
        {typeOfIndimnities?.map((item, index) => (
          <MenuItem value={item.id} key={index}>
            {item.title}
          </MenuItem>
        ))}
      </TextField>
      {insuranceType?.insuranceCode === "tai_nan" && (
        <>
          <div className="title">
            Chọn loại rủi ro <span style={{ color: "red" }}>*</span>
          </div>
          <TextField
            fullWidth
            className={classes.textFields}
            select
            error={errors?.riskType}
            helperText={errors?.riskType ? "Vui lòng chọn loại rủi ro" : false}
            value={riskType?.id || ""}
            onChange={(e) =>
              dispatch({
                type: "onChangeGetInfor",
                name: "riskType",
                value: e?.target?.value,
              })
            }
          >
            {riskTypes.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
    </div>
  );
}
