import { IconButton, TableCell } from "@mui/material";
import moment from "moment";

import showIcon from "../../../../assets/health/show-icon.svg";
// import editReviewer from "../../../../../assets/health/edit-reviewer.svg";
import dontShowIcon from "../../../../assets/health/dontShowIcon.svg";
import { getStatusById } from "../utils";
import { get, isEmpty } from "lodash";

export const columnsClaim = [
  {
    label: "STT",
    headerClass: "stt",
    key: "name",
    renderCell: ({ rowIdx }) => (
      <TableCell className="stt">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "Tên",
    headerClass: "full-name",
    renderCell: ({ item }) => (
      <TableCell className="full-name">
        {item?.insuredInfo?.insuredName || ""}
      </TableCell>
    ),
  },
  {
    label: "Mã hồ sơ",
    headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">{item.code}</TableCell>
    ),
  },
  {
    label: "Tên sản phẩm",
    // headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">{item.productTitle}</TableCell>
    ),
  },
  {
    label: "Doanh nghiệp",
    // headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">{item.merchantTitle}</TableCell>
    ),
  },
  {
    label: "Ngày",
    // headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">
        {item.createdDate ? moment(item.createdDate).format("DD/MM/YYYY") : ""}
      </TableCell>
    ),
  },
  {
    label: "Số tiền yêu cầu",
    headerClass: "amount",
    renderCell: ({ item }) => (
      <TableCell>
        {`${item.requestAmount || "0"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          " VNĐ"}
      </TableCell>
    ),
  },
  {
    label: "Số tiền bồi thường",
    headerClass: "amount",
    renderCell: ({ item }) => (
      <TableCell>
        {[5, 6, 8, 9].includes(item?.statusId)
          ? `${item.amount || "-"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            " VNĐ"
          : "- VNĐ"}
      </TableCell>
    ),
  },
  {
    label: "NS xử lý HS",
    renderCell: ({ item, data, event, rowIdx }) => (
      <TableCell className="reviewer">
        <div>
          <span style={{ marginRight: "12px" }}>
            {item.userFullnameReview || ""}
          </span>
        </div>
      </TableCell>
    ),
  },
  {
    label: "NS phê duyệt",
    renderCell: ({ item, data, rowIdx, event }) => (
      // <TableCell>{item.userFullnameAssign || ""}</TableCell>
      <TableCell className="reviewer">
        <div>
          <span style={{ marginRight: "12px" }}>
            {item.userFullnameAssign || ""}
          </span>
        </div>
      </TableCell>
    ),
  },
  {
    label: "Trạng thái",
    headerClass: "status",
    renderCell: ({ item, data }) => (
      <TableCell
        style={{
          fontWeight: "500",
          color: getStatusById(item.statusId).colorReport,
        }}
      >
        {getStatusById(item.statusId).labelReport}
      </TableCell>
    ),
  },
  {
    label: "Quản lý bởi",
    renderCell: ({ item, data }) => (
      <TableCell
        style={{
          fontWeight: "500",
        }}
      >
        {item?.organizationTitle}
      </TableCell>
    ),
  },
  {
    label: "Xem hồ sơ",
    headerClass: "view-records stickey",
    renderCell: ({ item, data, event }) => {
      return (
        <TableCell className="view-records stickey">
          <IconButton
            disabled={[2, 3, 4].includes(item.statusId)}
            onClick={event("onClickShowDetail", { item })}
          >
            <img
              src={[2, 3, 4].includes(item.statusId) ? dontShowIcon : showIcon}
            />
          </IconButton>
        </TableCell>
      );
    },
  },
];

export const columnsProduct = [
  {
    label: "STT",
    headerClass: "stt",
    key: "name",
    renderCell: ({ rowIdx }) => (
      <TableCell className="stt">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "Tên sản phẩm",
    headerClass: "full-name",
    renderCell: ({ item }) => (
      <TableCell className="full-name">{item?.product_title || ""}</TableCell>
    ),
  },
  {
    label: "Mã sản phẩm",
    // headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">{item.product_id_parent}</TableCell>
    ),
  },
  {
    label: "Nhà bảo hiểm",
    // headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">{item.provider_title}</TableCell>
    ),
  },
  {
    label: "Quản lý bởi",
    // headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">{item.organization_title}</TableCell>
    ),
  },
  {
    label: "Tỉ lệ BT",
    headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">
        {((+item?.total_amount || 0) / (+item?.total_fees || 1)) * 100}
      </TableCell>
    ),
  },
  {
    label: "Tổng đơn BH",
    renderCell: ({ item }) => (
      <TableCell>
        {item.participants}
        {/* {`${item.total_claim || "0"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          " VNĐ"} */}
      </TableCell>
    ),
  },
  {
    label: "Doanh số (VNĐ)",
    renderCell: ({ item }) => (
      <TableCell>
        {`${item.total_fees || "0"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </TableCell>
    ),
  },
  {
    label: "YCBT",
    renderCell: ({ item, data, event, rowIdx }) => (
      <TableCell className="reviewer">
        {`${item.total_claim || "0"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </TableCell>
    ),
  },
  {
    label: "Tổng bồi thường",
    renderCell: ({ item, data, rowIdx, event }) => (
      // <TableCell>{item.userFullnameAssign || ""}</TableCell>
      <TableCell className="reviewer">
        {`${item.total_amount || "0"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </TableCell>
    ),
  },
];

export const columnsMerchant = [
  {
    label: "STT",
    headerClass: "stt",
    key: "name",
    renderCell: ({ rowIdx }) => (
      <TableCell className="stt">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "Doanh nghiệp",
    headerClass: "full-name",
    renderCell: ({ item }) => (
      <TableCell className="full-name">{item?.title || ""}</TableCell>
    ),
  },
  {
    label: "Mã số thuế",
    // headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="">{item?.tax_code}</TableCell>
    ),
  },
  {
    label: "Tên sản phẩm",
    // headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="">{item.product_title}</TableCell>
    ),
  },
  {
    label: "Nhà bảo hiểm",
    headerClass: "provider_title",
    renderCell: ({ item }) => (
      <TableCell className="">{item.provider_title}</TableCell>
    ),
  },
  {
    label: "Quản lý bởi",
    // headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="">{item.organization_title}</TableCell>
    ),
  },
  {
    label: "Tỉ lệ BT",
    headerClass: "amount",
    renderCell: ({ item }) => (
      <TableCell className="">
        {((+item?.total_amount || 0) / (+item?.total_fees || 1)) * 100}
      </TableCell>
    ),
  },
  {
    label: "Tổng đơn BH",
    headerClass: "amount",
    renderCell: ({ item }) => <TableCell>{item?.participants}</TableCell>,
  },
  {
    label: "Doanh số (VNĐ)",
    headerClass: "amount",
    renderCell: ({ item }) => (
      <TableCell>
        {`${item.total_fees || "0"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </TableCell>
    ),
  },
  {
    label: "YCBT",
    headerClass: "YCBT",
    renderCell: ({ item, data, event, rowIdx }) => (
      <TableCell className="reviewer">{item?.total_claim || ""}</TableCell>
    ),
  },
  {
    label: "Tổng bồi thường",
    headerClass: "amount",
    renderCell: ({ item, data, rowIdx, event }) => (
      // <TableCell>{item.userFullnameAssign || ""}</TableCell>
      <TableCell className="reviewer">
        {" "}
        {`${item.total_amount || "0"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </TableCell>
    ),
  },
];
