import React, { useEffect } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  text
} from 'recharts';
import { formatMoney } from '../../utils/money';
const data = [
  { name: 'Vinmart', value: 400 },
  { name: 'Coopmart', value: 300 },
  { name: 'Ministop', value: 300 },
  { name: '7-Eleven', value: 200 },
];

const COLORS = ['#2A3790', '#3C4DBF', '#6372DA', '#7082FF'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc' }}>
        <label>{payload[0].name === 'Không có dữ liệu' ? 'Không có dữ liệu' : `${payload[0].name} : ${formatMoney(payload[0].value || 0)} đơn`}</label>
      </div>
    );
  }

  return null;
}

function PieChartShow(props) {
  return (
    <>
      <ResponsiveContainer width='100%' height={400}>
        <PieChart width={600} height={600}>
          <Pie
            data={props.data}
            color="#000000"
            dataKey="value"
            nameKey="name"
            cx="50%" cy="50%"
            fill="#8884d8"
            labelLine={false}
            label={renderCustomizedLabel}
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend align='right' layout='vertical' verticalAlign='middle' height={36} />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
}

export default React.memo(PieChartShow)


