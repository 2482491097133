import { Typography } from "@mui/material";
import moment from "moment";

function convertCurrency(str) {
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function formatDate(value) {
  return moment(value).format("DD/MM/YYYY");
}

function formatFilterDate(name, value) {
  switch (name) {
    case "fromDate":
      return `${moment(value).format("YYYY/MM/DD")} 00:00`;
    case "toDate":
      return `${moment(value).format("YYYY/MM/DD")} 23:59`;
  }
}

function expiredDate(today, date) {
  return today > date;
}

function isEmail(mail) {
  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return (mail || "").match(mailFormat);
}

function getText(insuranceData, name) {
  switch (name) {
    case "buyer_dob":
      return formatDate(insuranceData[name]);
    case "product_amount":
    case "fees":
      return convertCurrency(`${insuranceData[name]}`) + " VNĐ";
    case "insuredAddress":
      return insuranceData?.insured_info?.insuredAddress;
    default:
      return insuranceData[name];
  }
}

const TextRender = ({ insuranceData, item, classes }) => {
  return (
    <div className={classes.info} key={item.value}>
      <Typography className="key">{item.name}</Typography>
      <Typography className="value">
        {getText(insuranceData, item.value) || "--"}
      </Typography>
    </div>
  );
};

export {
  convertCurrency,
  formatDate,
  expiredDate,
  isEmail,
  TextRender,
  formatFilterDate,
};
