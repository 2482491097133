import React, { useEffect, useLayoutEffect } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { CheckBox, TextField } from "../../overrideMui";
import CommonTable from "../../../../../../library/layout/commonTable";
import styleOtherTable from "./styleOtherTableApproval";
import NumberFormat from "react-number-format";

export default function OtherTableApproval(props) {
  const { state, dispatch, level_id } = props;
  const classes = styleOtherTable();

  const { detail, rowsOtherTablesApprove, approveOtherTablesTotal } = state;

  const getDataByTypeOfIndimnity = () => {
    switch (+detail?.extraInfo?.typeOfIndimnity) {
      case 3:
        return {
          name: "injuries",
          thName: "Loại thương tật",
          title: "Tỉ lệ thương tật",
        };
      case 5:
        return {
          name: "canceOrFatalDiseaseOrStroke",
          thName: "Loại bệnh",
          title: "Ung thư/ bệnh hiểm nghèo/ đột quỵ",
        };
      case 6:
        return {
          name: "deaths",
          thName: "Nguyên nhân tử vong",
          title: "Tử vong",
        };
      default:
        return {};
    }
  };

  return (
    <div className={classes.div}>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <Table className={classes.table}>
          <TableHead className={classes.header}>
            <TableRow className={classes.headerRow}>
              <TableCell>{getDataByTypeOfIndimnity()?.thName}</TableCell>
              <TableCell className="refuse">Từ chối</TableCell>
              <TableCell className="reason-expense-cell">Lý do </TableCell>
              <TableCell className="fees">Tổng quyền lợi</TableCell>
              {getDataByTypeOfIndimnity()?.name == "injuries" && (
                <TableCell className="approved">{"Tỉ lệ (%) "}</TableCell>
              )}
              <TableCell className="approved">Thành tiền</TableCell>
              <TableCell className="approved">Được duyệt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {rowsOtherTablesApprove?.map((item, rowIdx) => {
              return item?.isParent ? (
                <TableRow
                  key={rowIdx}
                  className={`${classes.cellRow} ${classes.rowColap}`}
                >
                  <TableCell colSpan={3} className="cell-colap">
                    <div
                      style={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                    >
                      <IconButton>
                        <KeyboardArrowDownIcon />
                      </IconButton>
                      <span style={{ marginRight: "12px" }}>
                        {item?.codeBenefit || ""}
                      </span>
                      <span>{item?.titleBenefit || ""}</span>
                    </div>
                  </TableCell>
                  {/* <TableCell className="cell-colap"></TableCell> */}
                  <TableCell className="fees cell-colap">
                    {`${item?.benefitAmount || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    )}
                  </TableCell>
                  {getDataByTypeOfIndimnity().name == "injuries" && (
                    <TableCell className="approve-fees cell-colap"></TableCell>
                  )}
                  <TableCell className="approve-fees cell-colap">
                    {`${item?.totalFee || "0"}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    )}
                  </TableCell>
                  <TableCell className="approve-fees cell-colap">
                    {`${item?.totalFeeCodeBenefitApprove || "0"}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={rowIdx} className={classes.cellRow}>
                  <TableCell className="name">
                    {item?.otherText || ""}
                  </TableCell>

                  <TableCell className="accept">
                    <Radio
                      disabled={level_id !== 1}
                      className={classes.radio}
                      checked={item?.approveStatus === 0}
                      onClick={() =>
                        dispatch({
                          type: `otherTablesChangTableApprove`,
                          index: rowIdx,
                          name: "approveStatus",
                          item,
                          value: item?.approveStatus === 0 ? 1 : 0,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell className="note">
                    <TextField
                      fullWidth
                      disabled={level_id !== 1}
                      className={classes.textField}
                      multiline
                      value={item?.note || ""}
                      onChange={(e) => {
                        dispatch({
                          type: `otherTablesChangTableApprove`,
                          name: "note",
                          index: rowIdx,
                          item,
                          value: e.target.value,
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell className="fees"></TableCell>
                  {getDataByTypeOfIndimnity()?.name == "injuries" && (
                    <TableCell className="fees">
                      {item?.percent || ""}
                    </TableCell>
                  )}
                  <TableCell className="fees">
                    {`${item?.fee == 0 ? item?.fee : item?.fee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    )}
                  </TableCell>
                  <TableCell className="fees">
                    {
                      <NumberFormat
                        disabled={level_id !== 1 || item?.approveStatus === 0}
                        thousandsGroupStyle="thousand"
                        decimalSeparator=","
                        thousandSeparator="."
                        customInput={TextField}
                        className={`textMoney ${classes.textField}`}
                        onChange={(e) => {
                          dispatch({
                            type: `otherTablesChangTableApprove`,
                            name: "approveFee",
                            index: rowIdx,
                            item,
                            value: +e.target.value.replace(/\./g, ""),
                          });
                        }}
                        value={
                          item?.approveFee == 0
                            ? item?.approveFee
                            : item?.approveFee || ""
                        }
                        fullWidth
                      />
                    }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className={classes.totalFees}>
        <span className="conten">Tổng phí được duyệt</span>
        <span className="fees">
          {`${approveOtherTablesTotal || "0"}`.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            "."
          )}
        </span>
      </div>
    </div>
  );
}
