import { get, isString, set } from "lodash";
import {
  getListProducts,
  uploadImg,
  getListOrganization,
  getListProvider,
  getListProviderParent,
  getDetailProduct,
  createProduct,
  updateProduct,
  getListProductPackage,
} from "../../../../apis/productApi_v2";
import { todoRemovedLinkS3 } from "../../../../apis/apis-v2";
import moment from "moment";

const delay = () => {
  return new Promise((resole) => {
    setTimeout(() => {
      resole("done");
    }, 800);
  });
};

export default function productManagerReducer(props) {
  const { state, action, dispatch, userType, history, id } = props;
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const userTypeFromProfile = get(user, "extra_info.userType") || "";

  const cases = {
    initial: async () => {
      dispatch("onLoading");
      const dataSearch = {
        from: moment().subtract(30, "days").format(),
        to: moment().format(),
      };

      const dataApi = await getListProducts({
        limit: 20,
        offset: 0,
        ...dataSearch,
      });
      const response = get(dataApi, "data", {});
      dispatch("getListDataFilter");

      if (response.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lội khi lấy thông tin, xin vui lòng thử lại",
          },
        };
      }
      const listProducts = get(response, "result.data", []) || [];
      const pageTable = {
        total: get(response, "result.total", 0),
        page: 1,
        count: Math.ceil(get(response, "result.total", 0) / 20),
      };

      return {
        loading: false,
        listProducts,
        pageTable,
        dataSearch,
      };
    },

    getListDataFilter: async () => {
      const [orgApi, merchantApi] = await Promise.all([
        getListOrganization({ limit: 20, offset: 0 }),
        getListProvider({ limit: 20, offset: 0 }),
      ]);

      const listOrganization = [
        ...(get(orgApi, "data.result.data") || []),
      ].slice(0, 5);
      const listMerchants = [
        ...(get(merchantApi, "data.result.data") || []),
      ].slice(0, 5);

      const oldListOrganization = [...(get(orgApi, "data.result.data") || [])];
      const oldListMerchants = [
        ...(get(merchantApi, "data.result.data") || []),
      ];

      const pageFilter = {
        providerIds: {
          page: 1,
          total: get(merchantApi, "data.result.total"),
        },
        orgIds: { page: 1, total: get(orgApi, "data.result.total") },
      };

      return {
        listOrganization,
        listMerchants,
        oldListOrganization,
        oldListMerchants,
        pageFilter,
      };
    },

    onChangePage: async () => {
      const { pageNumber } = action;
      const { dataSearch } = state;
      const params = {
        ...dataSearch,
        // from: dataSearch?.from || null,
        // to: dataSearch?.to || null,
        statusIds:
          !!dataSearch?.statusId || dataSearch?.statusId === 0
            ? [dataSearch?.statusId]
            : null,
        limit: 20,
        offset: (pageNumber - 1) * 20 || 0,
      };

      dispatch("onLoading");
      const dataApi = await getListProducts(params);
      const response = get(dataApi, "data", {});

      if (response.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lội khi lấy thông tin, xin vui lòng thử lại",
          },
        };
      }
      const listProducts = get(response, "result.data", []) || [];
      const pageTable = {
        total: get(response, "result.total", 0),
        page: pageNumber,
        count: Math.ceil(get(response, "result.total", 0) / 20),
      };

      return {
        loading: false,
        listProducts,
        pageTable,
      };
    },

    exPanRows: async () => {
      const { data } = action;
      const { listProducts } = state;
      listProducts[data.index] = { ...data.item, expan: !data.item.expan };
      dispatch("onLoading");

      if (!data.item.expan) {
        const productPackageApi = await getListProductPackage({
          productId: data.item?.id,
        });
        const packages = get(productPackageApi, "data.result", []) || [];

        if ((packages || []).length > 0) {
          const newPackages = packages.map((i) => ({
            ...i,
            parentId: data.item.id,
            child: true,
          }));
          !data.item.expan &&
            listProducts.splice(
              data.index + 1,
              0,
              {
                title: "Gói bảo hiểm",
                id: "Mã gói",
                fees: "Phí bảo hiểm",
                product_amount: "Quyền lợi",
                created_date: "Ngày tạo",
                participants: "Người tham gia",
                status: "Trạng thái",
                parentId: data.item.id,
                headChild: true,
                child: true,
              },
              ...newPackages
            );
        }
      } else {
        const listPackage = listProducts.filter(
          (i) => i.parentId === data.item?.id
        );
        data.item.expan &&
          listProducts.splice(data.index + 1, listPackage.length);
      }

      return { listProducts, loading: false };
    },

    openFilter: () => {
      const { filter } = state;

      return { filter: { ...filter, open: true } };
    },

    changeDataFilter: () => {
      const { value, name, typeAction } = action;
      const { dataSearch } = state;
      if (typeAction === "delete") {
        dataSearch[name] = null;
        return dataSearch;
      }

      if (["providerIds", "orgIds"].includes(name)) {
        const dataValue = dataSearch[name] || [];
        const checkDataMenu = dataValue.includes(value);

        dataSearch[name] = checkDataMenu
          ? dataValue.filter((i) => i !== value)
          : [...dataValue, value];
      }

      if (name === "statusId") {
        dataSearch[name] = value;
      }

      return dataSearch;
    },

    showMoveFilter: async () => {
      const { name } = action;
      const {
        pageFilter,
        oldListOrganization,
        oldListMerchants,
        listMerchants,
        listOrganization,
        loadingFilter,
      } = state;

      const res = {};
      if (name === "orgIds") {
        if (oldListOrganization.length > listOrganization.length) {
          return { listOrganization: [...oldListOrganization] };
        }

        if (pageFilter?.orgIds.page * 20 >= pageFilter?.orgIds?.total) {
          return;
        }

        dispatch({ type: "onLoadingFilter", name: "orgIds" });
        const orgApi = await getListOrganization({
          limit: 20,
          offset: pageFilter?.orgIds.page * 20,
        });

        if (orgApi?.data?.statusCode !== 200) {
          return {
            loading: false,
            snackbar: {
              open: true,
              type: "error",
              message: "Xin lỗi, đã xảy ra lỗi trong quá trình lấy dữ liệu",
            },
          };
        }

        res.listOrganization = [
          ...listOrganization,
          ...(get(orgApi, "data.result.data") || []),
        ];
        res.oldListOrganization = [
          ...oldListOrganization,
          ...(get(orgApi, "data.result.data") || []),
        ];
        res.pageFilter = {
          ...pageFilter,
          orgIds: {
            page: pageFilter?.orgIds?.page + 1,
            total: get(orgApi, "data.result.total", 0),
          },
        };
      }

      if (name === "providerIds") {
        if (oldListMerchants.length > listMerchants.length) {
          return { listMerchants: [...oldListMerchants] };
        }

        if (
          pageFilter?.providerIds.page * 20 >=
          pageFilter?.providerIds?.total
        ) {
          return;
        }

        dispatch({ type: "onLoadingFilter", name: "providerIds" });
        const merchantApi = await getListProvider({
          limit: 20,
          offset: pageFilter?.providerIds.page * 20,
        });

        if (merchantApi?.data?.statusCode !== 200) {
          return {
            loading: false,
            snackbar: {
              open: true,
              type: "error",
              message: "Xin lỗi, đã xảy ra lỗi trong quá trình lấy dữ liệu",
            },
          };
        }

        res.listMerchants = [
          ...listMerchants,
          ...(get(merchantApi, "data.result.data") || []),
        ];
        res.oldListMerchants = [
          ...oldListMerchants,
          ...(get(merchantApi, "data.result.data") || []),
        ];
        res.pageFilter = {
          ...pageFilter,
          providerIds: {
            page: pageFilter?.providerIds?.page + 1,
            total: get(merchantApi, "data.result.total", 0),
          },
        };
      }

      return { ...res, loadingFilter: { ...loadingFilter, [name]: false } };
    },

    collapseFilter: () => {
      const { name } = action;
      const { listMerchants, listOrganization } = state;

      const res = {};
      if (name === "orgIds") {
        res.listOrganization = [...listOrganization].splice(0, 5);
      }

      if (name === "providerIds") {
        res.listMerchants = [...listMerchants].splice(0, 5);
      }

      return { ...res };
    },

    applyFilter: async () => {
      const { keyword = null, name, value } = action;
      const { dataSearch } = state;

      if (keyword || keyword === "") {
        dataSearch.keyword = keyword;
      }

      if (["from", "to"].includes(name)) {
        dataSearch[name] = value;
      }

      const params = {
        ...dataSearch,
        keyword: dataSearch.keyword || null,
        // from: dataSearch?.from || null,
        // to: dataSearch?.to || null,
        statusIds:
          !!dataSearch?.statusId || dataSearch?.statusId === 0
            ? [dataSearch?.statusId]
            : null,
        limit: 20,
        offset: 0,
      };

      dispatch("onLoading");
      const dataApi = await getListProducts(params);
      const response = get(dataApi, "data", {});

      if (response.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi khi lấy thông tin, xin vui lòng thử lại",
          },
        };
      }
      const listProducts = get(response, "result.data", []) || [];
      const pageTable = {
        total: get(response, "result.total", 0),
        page: 1,
        count: Math.ceil(get(response, "result.total", 0) / 20),
      };

      return {
        loading: false,
        listProducts,
        pageTable,
        filter: { open: false },
      };
    },

    closeFilter: () => {
      const { filter } = state;
      return { filter: { ...filter, open: false } };
    },

    initial_creat: async () => {
      const payloadCreate = {};
      const payloadListAutocomplete = {};

      dispatch("onLoading");
      const res = {};

      if (id !== "new") {
        const detailApi = await getDetailProduct(id);
        const responseDetails = get(detailApi, "data", {});
        res.detailProduct = get(responseDetails, "result");

        payloadCreate.status = get(responseDetails, "result.status", null);
        payloadCreate.provider_id_parent = get(
          responseDetails,
          "result.provider_id_parent",
          null
        );
        payloadCreate.title = get(responseDetails, "result.title") || null;
        payloadCreate.org_id = +get(responseDetails, "result.org_id") || null;
        payloadCreate.url_logo =
          get(responseDetails, "result.url_logo") || null;
        payloadCreate.provider_id =
          +get(responseDetails, "result.provider_id") || null;
        set(
          payloadCreate,
          "extra_info",
          get(responseDetails, "result.extra_info", null)
        );
        set(
          payloadCreate,
          "extra_info.insurance_code",
          get(responseDetails, "result.extra_info.insurance_code", null) ||
            "con_nguoi"
        );

        if (
          get(
            responseDetails,
            "result.extra_info.typeOfInsuranceContract",
            null
          )
        ) {
          set(
            payloadCreate,
            "extra_info.benefit_type",
            get(
              responseDetails,
              "result.extra_info.typeOfInsuranceContract",
              null
            ) === 1
              ? "quyen_loi_rieng"
              : get(
                  responseDetails,
                  "result.extra_info.typeOfInsuranceContract",
                  null
                ) === 2
              ? "quyen_loi_chung"
              : null
          );
        }
      }

      const api = [
        getListProviderParent({
          limit: 10,
          offset: 0,
          id: id !== "new" ? res.detailProduct?.provider_id_parent : undefined,
        }),
        getListOrganization({
          limit: 10,
          offset: 0,
          org_types: ["tpag", "tpa"],
          id: id !== "new" ? res.detailProduct?.org_id : "",
        }),
      ];

      const [providerparentApi, listOrgApi] = await Promise.all(api);
      // const listOrgApi = await ;

      const responseProviderParent = get(providerparentApi, "data", {});
      // const responseProvider = get(providerApi, "data", {});
      const listOrganization = get(listOrgApi, "data.result.data", []) || [];
      const listProviderParent =
        get(responseProviderParent, "result.data", []) || [];
      // const listProviders = get(responseProvider, "result.data", []) || [];
      payloadListAutocomplete.provider_parent = {
        page: 1,
        total: responseProviderParent?.result?.total,
        params: {},
      };
      payloadListAutocomplete.org = {
        page: 1,
        total: get(listOrgApi, "data.result.total", []),
        params: { org_types: ["tpag", "tpa"] },
      };

      if (id === "new") {
        payloadCreate.provider_id_parent =
          get(responseProviderParent, "result.data[0].id", null) || null;
        // payloadCreate.provider_id =
        //   get(responseProvider, "result.data[0].id", null) || null;
        set(payloadCreate, "extra_info.benefit_type", "quyen_loi_rieng");
        set(payloadCreate, "extra_info.insurance_code", "con_nguoi");
        payloadCreate.status = 1;
        payloadCreate.org_id =
          userTypeFromProfile === "globalcare"
            ? get(listOrganization, "[0].id")
            : ["tpag", "tpa"].includes(userTypeFromProfile)
            ? get(user, "extra_info.org_id") || null
            : null;
      }

      if (listProviderParent.length > 0) {
        dispatch({
          type: "getListProvider",
          listProviderParent,
          payloadCreate,
          payloadListAutocomplete,
          provider_id_parent:
            id !== "new" ? res.detailProduct?.provider_id_parent : null,
          ...res,
        });
      }

      return {
        loading: false,
        // listProviders,
        listProviderParent,
        payloadCreate,
        listOrganization,
        payloadListAutocomplete,
        errors: [],
        ...res,
      };
    },

    getListProvider: async () => {
      const {
        listProviderParent,
        payloadCreate,
        detailProduct,
        payloadListAutocomplete,
        provider_id_parent,
      } = action;
      const { loadingAutocomplete } = state;

      dispatch({ type: "onLoadingAutocomplete", name: "loadListProvider" });
      const res = {};

      const dataApi = await getListProvider({
        limit: 200,
        offset: 0,
        provider_id_parent: provider_id_parent
          ? provider_id_parent
          : listProviderParent[0]?.id,
        id: id !== "new" ? detailProduct?.provider_id : null,
      });

      const listProviders = get(dataApi, "data.result.data", []) || [];
      payloadListAutocomplete.provider = {
        page: 1,
        total: get(dataApi, "data.result.total", []),
        params: { provider_id_parent: listProviderParent[0]?.id },
      };
      if (id !== "new") {
        payloadCreate.provider_id = detailProduct?.provider_id;
      } else {
        payloadCreate.provider_id = get(
          dataApi,
          "data.result.data[0].id",
          null
        );
      }

      return {
        listProviders,
        payloadListAutocomplete,
        loadingAutocomplete: {
          ...loadingAutocomplete,
          loadListProvider: false,
        },
      };
    },

    upLoadImg_create: async () => {
      const { e, typeCode, typeId } = action;
      const { payloadCreate } = state;
      const file = e.target.files[0];
      const data = new FormData();
      data.append("document", file);

      dispatch("onLoading");

      const dataApi = await uploadImg(data);
      const response = get(dataApi, "data", {});
      if (response.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
        };
      }

      if (typeCode && typeId) {
        const name = file?.name || "";
        const size = file?.size || "";
        const documents = get(payloadCreate, "extra_info.documents", []) || [];
        documents.push({
          type_id: typeId,
          type_code: typeCode,
          path: get(response, "result[0].path"),
          size: size,
          original_name: name,
        });
        set(payloadCreate, `extra_info.documents`, documents);
        return { loading: false, payloadCreate };
      }

      payloadCreate.url_logo = get(response, "result[0].path");

      return { loading: false, payloadCreate };
    },

    onChangePayload_create: () => {
      const { value, name, is_extra_info = false } = action;
      const { payloadCreate, errors } = state;
      const res = {};

      res.errors = errors.filter((i) => i !== name);
      if (is_extra_info) {
        set(payloadCreate, `extra_info.${name}`, value);
        return { payloadCreate, ...res };
      }
      payloadCreate[name] = value;

      if (name === "provider_id_parent") {
        dispatch({ type: "getListProviderById", id: value });
      }

      return { payloadCreate, ...res };
    },

    getListProviderById: async () => {
      const { id } = action;
      const { payloadListAutocomplete, loadingAutocomplete, payloadCreate } =
        state;

      dispatch({ type: "onLoadingAutocomplete", name: "loadListProvider" });
      const res = {};

      const dataApi = await getListProvider({
        limit: 200,
        offset: 0,
        provider_id_parent: id,
      });

      const listProviders = get(dataApi, "data.result.data", []) || [];
      payloadListAutocomplete.provider = {
        page: 1,
        total: get(dataApi, "data.result.total", []),
        params: { provider_id_parent: id },
      };

      payloadCreate.provider_id = get(dataApi, "data.result.data[0].id", null);

      return {
        listProviders,
        payloadListAutocomplete,
        loadingAutocomplete: {
          ...loadingAutocomplete,
          loadListProvider: false,
        },
      };
    },

    getMoveListAutocomplete: async () => {
      const { name } = action;
      const {
        loadingAutocomplete,
        payloadListAutocomplete,
        listProviderParent,
        listOrganization,
      } = state;

      dispatch({ type: "onLoadingAutocomplete", name });

      const api = [];
      name === "provider_parent" &&
        api.push(
          getListProviderParent({
            limit: 10,
            offset: payloadListAutocomplete?.provider_parent?.page * 10 || 0,
            ...payloadListAutocomplete?.provider_parent?.params,
          })
        );

      name === "org" &&
        api.push(
          getListOrganization({
            limit: 10,
            offset: payloadListAutocomplete?.org?.page * 10 || 0,
            ...payloadListAutocomplete?.org?.params,
          })
        );

      const [dataApi] = await Promise.all(api);
      const res = {};
      if (name === "provider_parent") {
        const listData = get(dataApi, "data.result.data", []) || [];
        res.listProviderParent = [...listProviderParent, ...listData];
        payloadListAutocomplete.provider_parent = {
          ...payloadListAutocomplete.provider_parent,
          total: get(dataApi, "data.result.total"),
          page: (payloadListAutocomplete.provider_parent?.page || 0) + 1,
        };
      }

      if (name === "org") {
        const listData = get(dataApi, "data.result.data", []) || [];
        res.listOrganization = [...listOrganization, ...listData];
        payloadListAutocomplete.org = {
          ...payloadListAutocomplete.org,
          total: get(dataApi, "data.result.total"),
          page: (payloadListAutocomplete.org?.page || 0) + 1,
        };
      }

      return {
        loadingAutocomplete: { ...loadingAutocomplete, [name]: false },
        ...res,
        payloadListAutocomplete,
      };
    },

    deleteAttachedFiel_create: () => {
      const { item, index } = action;
      const { payloadCreate } = state;
      const documents = get(payloadCreate, "extra_info.documents", []) || [];
      dispatch({ type: "todo", url: item.path });
      documents.splice(index, 1);

      set(payloadCreate, `extra_info.documents`, documents);
      return { payloadCreate };
    },

    deleteAll_create: () => {
      return { payloadCreate: {} };
    },

    validate: () => {
      const { payloadCreate, errors } = state;
      // userType == "globalcare" && !payloadCreate?.orgId && errors.push("orgId");
      !payloadCreate?.title && errors.push("title");
      !payloadCreate?.provider_id_parent && errors.push("provider_id_parent");
      !payloadCreate?.provider_id && errors.push("provider_id");
      !payloadCreate?.org_id &&
        userType === "globalcare" &&
        errors.push("org_id");
      !payloadCreate?.extra_info?.benefit_type && errors.push("benefit_type");

      if (errors.length === 0) {
        dispatch("submitSave");
        return;
      }
      return {
        errors,
        snackbar: {
          open: true,
          type: "error",
          message: "Vui lòng nhập đầy đủ thông tin.",
        },
      };
    },

    submitSave: async () => {
      const { payloadCreate, detailProduct } = state;
      // id !== "new" && delete payloadCreate.orgId;
      dispatch("onLoading");
      const newPayloadCreate = { ...payloadCreate };
      delete newPayloadCreate.provider_id_parent;
      const dataApi =
        id !== "new"
          ? await updateProduct({
              ...newPayloadCreate,

              product_id_parent: detailProduct.product_id_parent,
              id: detailProduct.id,
              org_id: +detailProduct.org_id,
            })
          : await createProduct(newPayloadCreate);
      const response = get(dataApi, "data", {});
      if (response.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: `Đã xảy ra lỗi trong quá trình ${
              id !== "new" ? "Update" : "tạo"
            }, xin vui lòng thử lại`,
          },
        };
      }

      if (id === "new" && get(dataApi, "data.result.id", null)) {
        const newProductOfUser = [
          ...(get(user, "role_management_claim.product_parents", []) || []),
          { product_id_parent: get(dataApi, "data.result.id") },
        ];
        set(user, "role_management_claim.product_parents", newProductOfUser);
        localStorage.setItem("user", JSON.stringify(user));
        history.push(
          `/admin/product-management/detail/${get(dataApi, "data.result.id")}`
        );
      }

      if (id !== "new") {
        history.push("/admin/product-management");
      }

      return {
        loading: false,
        snackbar: {
          open: true,
          type: "success",
          message:
            id !== "new"
              ? "Cập nhật sản phâm thành công"
              : "Tạo sản phẩm thành công",
        },
        payloadCreate: {},
      };
    },

    exportFileExcel: async () => {
      const { dataSearch } = state;
      dispatch("onLoading");

      const dataApi = await getListProducts({
        limit: 1000,
        offset: 0,
        ...dataSearch,
        statusId: undefined,
        statusIds:
          !!dataSearch?.statusId || dataSearch?.statusId === 0
            ? [dataSearch?.statusId]
            : null,
      });
      const response = get(dataApi, "data", {});

      if (response.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lội khi lấy thông tin, xin vui lòng thử lại",
          },
        };
      }
      const dataExportExcel = get(response, "result.data", []) || [];

      return { loading: false, count: state.count + 1 };
    },

    resetAutocomplete: () => {
      const { value, name } = action;
      const { payloadCreate, payloadListAutocomplete } = state;

      const res = {};

      if (name === "org_id") {
        set(payloadListAutocomplete, "org.params.title", value);
        payloadCreate.org_id = null;
      }

      if (name === "provider_id_parent") {
        set(payloadListAutocomplete, "provider_parent.params.keyword", value);
        payloadCreate.provider_id_parent = null;
      }

      return { payloadListAutocomplete, payloadCreate, ...res };
    },

    onInputChangeProviderParent: async () => {
      const { value } = action;
      const { payloadListAutocomplete, loadingAutocomplete } = state;

      dispatch({ type: "onLoadingAutocomplete", name: "provider_parent" });

      await dispatch({
        type: "resetAutocomplete",
        name: "provider_id_parent",
        value: value,
      });

      const dataApi = await getListProviderParent({
        limit: 10,
        offset: 0,
        ...payloadListAutocomplete?.provider_parent?.params,
        keyword: value,
      });

      await delay();
      if (
        get(state, "payloadListAutocomplete.provider_parent.params.keyword") !==
        value
      ) {
        return;
      }

      const listProviderParent = get(dataApi, "data.result.data", []) || [];
      payloadListAutocomplete.provider_parent = {
        ...payloadListAutocomplete.provider_parent,
        page: 1,
        total: get(dataApi, "data.result.total", 0),
        params: {
          ...payloadListAutocomplete.provider_parent?.params,
          keyword: value,
        },
      };
      loadingAutocomplete.provider_parent = false;
      return {
        listProviderParent,
        payloadListAutocomplete,
        loadingAutocomplete,
      };
    },

    onInputChangeOrg: async () => {
      const { value } = action;
      const { payloadListAutocomplete, loadingAutocomplete } = state;

      dispatch({ type: "onLoadingAutocomplete", name: "org" });

      await dispatch({
        type: "resetAutocomplete",
        name: "org_id",
        value: value,
      });

      const dataApi = await getListOrganization({
        limit: 10,
        offset: 0,
        ...payloadListAutocomplete?.org?.params,
        title: value,
      });
      await delay();
      if (get(state, "payloadListAutocomplete.org.params.title") !== value) {
        return;
      }

      const listOrganization = get(dataApi, "data.result.data", []) || [];
      payloadListAutocomplete.org = {
        ...payloadListAutocomplete.org,
        page: 1,
        total: get(dataApi, "data.result.total", 0),
        params: {
          ...payloadListAutocomplete.org?.params,
          title: value,
        },
      };

      return {
        listOrganization,
        payloadListAutocomplete,
        loadingAutocomplete: { ...loadingAutocomplete, org: false },
      };
    },

    clearAutocomplete: async () => {
      const { name } = action;
      const { payloadListAutocomplete, loadingAutocomplete, payloadCreate } =
        state;

      dispatch({ type: "onLoadingAutocomplete", name });

      const api = [];
      name === "provider_parent" &&
        api.push(
          getListProviderParent({
            limit: 20,
            offset: 0,
            ...payloadListAutocomplete?.provider_parent?.params,
            keyword: null,
          })
        );

      name === "org" &&
        api.push(
          getListOrganization({
            limit: 20,
            offset: 0,
            ...payloadListAutocomplete?.org?.params,
            title: null,
          })
        );

      const [dataApi] = await Promise.all(api);
      const res = {};
      if (name === "provider_parent") {
        const listData = get(dataApi, "data.result.data", []) || [];
        res.listProviderParent = [...listData];
        payloadListAutocomplete.provider_parent = {
          ...payloadListAutocomplete.provider_parent,
          total: get(dataApi, "data.result.total"),
          page: 1,
        };
        set(payloadListAutocomplete, "provider_parent.params.keyword", null);
        payloadCreate.provider_id_parent = null;
      }

      if (name === "org") {
        const listData = get(dataApi, "data.result.data", []) || [];
        res.listOrganization = [...listData];
        payloadListAutocomplete.org = {
          ...payloadListAutocomplete.org,
          total: get(dataApi, "data.result.total"),
          page: 1,
        };
        set(payloadListAutocomplete, "org.params.title", null);
        payloadCreate.org_id = null;
      }

      return {
        ...res,
        payloadListAutocomplete,
        payloadCreate,
        loadingAutocomplete: { ...loadingAutocomplete, [name]: false },
      };
    },

    onCloseSnackbar: () => ({ snackbar: { open: false } }),

    onLoading: () => ({ loading: true }),

    onLoadingFilter: () => ({
      loadingFilter: { ...state.loadingFilter, [action.name]: true },
    }),

    onLoadingAutocomplete: () => ({
      loadingAutocomplete: {
        ...state.loadingAutocomplete,
        [action?.name]: true,
      },
    }),

    todo: () => {
      const { url } = action;
      if (Array.isArray(url)) {
        Promise.all(
          url.map((i) =>
            todoRemovedLinkS3({
              action_code: "document_delete",
              extra_info: {
                url: i,
              },
            })
          )
        );

        return;
      }

      if (url) {
        todoRemovedLinkS3({
          action_code: "document_delete",
          extra_info: {
            url: url,
          },
        });

        return;
      }
    },

    //
  };

  return isString(action.type) && cases[action.type];
}
