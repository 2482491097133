import { makeStyles } from "@mui/styles";

const styledSendApproval = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
      margin: "24px",
      width: "100%",
    },
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    padding: "8px 18px",

    "& .content-title": {
      color: "#4B4B4B",
      fontWeight: "600",
      fontSize: "14px",
    },
  },

  clontainerContent: {
    width: "100%",

    "& .apprival-title": {
      fontSize: "18px",
      fontWeight: "600",
      color: "#3961A0",
      margin: "24px 0",
    },

    "& .box-infor": {
      fontSize: "14px",
      margin: "18px 0",
      lineHeight: "26px",

      "& .title": {
        fontWeight: "600",
      },

      "& .value": {
        color: "#686868",
      },
    },
  },

  buttons: {
    width: "100%",
    paddingTop: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #A0A0A0",
    paddingBottom: "18px",

    "& .buttons": {
      textTransform: "none",
      background: "#3961A0",
      color: "white",
      borderRadius: "12px",
      padding: "8px 24px",
      fontWeight: "600",
    },
  },

  boxNote: {
    width: "100%",

    "& .note": {
      fontSize: "14px",
      paddingBottom: "8px",
      fontWeight: "600",
    },
  },

  multilineTextField: {
    "& .MuiInputBase-multiline": {
      padding: "0px !important",

      "& textarea": {
        background: "#F8F8F8",
        boxShadow: "inset 0px 1px 1px rgba(0, 0, 0, 0.25)",
      },

      "& fieldset": {
        border: "none",
      },
    },
  },

  //
}));

export default styledSendApproval;
