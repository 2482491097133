import { makeStyles } from "@mui/styles";

export const styledInfo = makeStyles(() => ({
  container: {
    background: "#F8F8F8",
    padding: "16px",
    "& .title-head": {
      color: "#686868",
    },
  },

  accordion: {
    boxShadow: "none !important",
    border: "none !important",
    borderRadius: "12px !important",
    marginTop: "12px",

    "&:before": {
      height: "0 !important",
    },
  },

  accordiontitle: {
    minHeight: "36px !important",
    padding: "0 12px !important",
    borderRadius: "12px !important",
    background: "#0B3C8A !important",
    color: "white !important",

    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
    },
  },

  licenseImg: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 12px",
    background: "#F8F8F8",
    color: "#0B3C8A",
    marginBottom: "6px",
    cursor: "pointer",
  },

  proveRelationship: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 12px",
    cursor: "pointer",
  },

  rules: {
    display: "flex",
    alignItems: "start",
    margin: "12px 0",
  },

  signature: {
    border: "1px solid rgba(0, 0, 0, 0.18)",
    borderRadius: "6px",
  },

  signatureTitle: {
    marginBottom: "8px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },

  sigCanvas: {
    position: "relative",
    width: "100%",
    display: "flex",
    padding: "8px",
  },

  //
}));
