import { message } from "antd";
import { get } from "lodash";
import {
  addProduct,
  getDetail,
  getProductParentList,
  updateProvider,
  uploadImage,
} from "../../../../apis/providerApis";
import * as PATH from "../../../../configs/routesConfig";

const detailReducer = ({
  initialState,
  state,
  action,
  dispatch,
  history,
  id,
}) => {
  const cases = {
    getDetail: async () => {
      dispatch("onLoading");
      const result = await getDetail(id);
      if (result instanceof Error) {
        message.error(result.response?.data?.message || "Lỗi lấy chi tiết", 2);
        return { loading: false };
      }
      const data = get(result, "data.result") || {};
      data.logo = get(data, "urlLogo");
      data.title = data?.title || "";
      data.taxCode = get(data, "extraInfo.taxCode");
      data.phone = get(data, "extraInfo.phone");
      data.address = get(data, "extraInfo.address");
      data.email = get(data, "extraInfo.email");
      const open = { provider: false, product: false };
      return { data, open, loading: false };
    },
    upLoadFile: async () => {
      const { e } = action;
      const { data } = state;

      if (e.target.files.length < 1) {
        return;
      }
      const file = e.target.files[0];
      const formdata = new FormData();
      formdata.append("document", file);

      dispatch("loadImg");
      const dataApi = await uploadImage(formdata);
      const item = get(dataApi, "data.result[0]") || null;
      data.logo = item?.path || null;
      return { data, imgLoading: false };
    },
    onChange: () => {
      const { data } = state;
      const { name, value } = action;
      data[name] = value;
      return { data };
    },
    onSelect: async () => {
      const { data } = state;
      const { name, value } = action;
      data[name] = value || {};
      if (data.insurance_type?.insurance_code) {
        dispatch("getListProductParent");
      }
      return { data };
    },
    getListProductParent: async () => {
      const { data } = state;
      const { value } = action;
      const p = {
        keyword: value || "",
        insuranceCode: data.insurance_type.insurance_code,
        enableClaim: 1,
        limit: 100,
        offset: 0,
      };
      const res = await getProductParentList(p);
      if (res instanceof Error) {
        message.error(res.response?.data?.message || "Lỗi lấy danh sách", 2);
      }
      const product_parent = get(res, "data.result.data") || [];
      return { product_parent };
    },
    addProduct: async () => {
      const { data, open } = state;
      const errors = [];
      !data.insurance_type?.insurance_code && errors.push("insurance_type");
      !data.product_name && errors.push("product_name");
      if (errors.length === 0) {
        const p = {
          title: "",
          provider_id: +id,
          product_id_parent: +data?.product_name?.product_id,
          url_logo: "",
          packages: [],
        };
        const res = await addProduct(p);
        if (res instanceof Error) {
          return message.error(
            res.response?.data?.message || "Lỗi thêm sản phẩm",
            2
          );
        }
        message.success("Thêm sản phẩm thành công.!", 2);
        const product_parent = [];
        data.insurance_type = {};
        data.product_name = {};
        open.product = false;
        dispatch("getDetail");
        return { open, product_parent, data };
      }

      return { errors };
    },
    validate: () => {
      const { data } = state;
      const errors = [];
      const keys = ["title", "taxCode", "phone", "address", "email"];
      keys.forEach((i) => {
        !data[i] && errors.push(i);
      });
      if (errors.length === 0) {
        dispatch("onSave");
      }
      return { errors };
    },
    onSave: async () => {
      const { data, open } = state;
      const d = {
        id,
        title: data?.title,
        url_logo: data?.logo,
        extraInfo: {
          taxCode: data?.taxCode,
          phone: data?.phone,
          address: data?.address,
          email: data?.email,
        },
      };
      dispatch("onSaving");
      const res = await updateProvider(d);
      if (res instanceof Error) {
        message.error(res.response?.data?.message || "Lỗi cập nhật", 2);
        return { saving: false };
      }
      message.success(res.data?.message || "Cập nhật thành công", 2);
      dispatch("getDetail");
      return { data: {}, saving: false, open: { ...open, provider: false } };
    },

    onOpen: () => {
      const { open } = state;
      open[action.name] = true;
      return { open };
    },
    onClose: () => {
      const { open, data } = state;
      if (!action.name) {
        return { errors: [], open: { ...open, provider: false } };
      }
      const product_parent = [];
      open.product = false;
      data.insurance_type = {};
      data.product_name = {};
      return { open, data, product_parent, errors: [] };
    },
    onLoading: () => ({ loading: true }),
    changePage: async () => {
      const { item } = action;
      history.push(`${PATH.AD_PRODUCT_PACKAGE}?id=${item.id}`);
    },
  };
  return cases[action?.type];
};
export default detailReducer;
