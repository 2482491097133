import { Autocomplete, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextField } from "../overrideMui";
import { createGCNStyles } from "../styles/styleCreateGCN";
import { debounce, get } from "lodash";
// import { Loading } from "./Loading";

export default function FormCreateGCN({ state, dispatch }) {
  const classes = createGCNStyles();
  const {
    products,
    packages,
    addInfo,
    errInfo,
    merchants,
    merchant,
    loadingAutocomplete,
    payloadListAutocomplete,
  } = state;

  const [scrollPosition, setScrollPosition] = useState({});

  const onScrollListMerchant = (name) => {
    const elment = document.getElementsByClassName(
      name === "merchant" ? "div-scroll" : "div-scroll-product"
    );
    const payload = get(payloadListAutocomplete, `${name}`) || {};

    if (elment.length > 0 && +payload?.page * 20 < +payload?.total) {
      const scrollDiv = elment[0];
      const listItems = document.querySelectorAll(
        name === "merchant" ? ".scroll-item" : ".scroll-item-product"
      );
      const lastItem = listItems[listItems.length - 1] || null;

      if (
        Math.abs(lastItem?.offsetTop - scrollDiv.scrollTop + 70) <
          lastItem?.offsetHeight + scrollDiv.offsetHeight &&
        !loadingAutocomplete[name]
      ) {
        dispatch({ type: "getMoveListAutocomplete", name });
        setScrollPosition({
          ...scrollPosition,
          [name]: scrollDiv.scrollHeight,
        });
      }
    }
  };

  useEffect(() => {
    const element = document.getElementsByClassName("div-scroll");
    if (element.length > 0) {
      const element_scroll = element[0];
      element_scroll.scrollTop = scrollPosition?.merchant || 0;
    }
  }, [loadingAutocomplete?.merchant]);

  useEffect(() => {
    const element = document.getElementsByClassName("div-scroll-product");
    if (element.length > 0) {
      const element_scroll = element[0];
      element_scroll.scrollTop = scrollPosition?.product || 0;
    }
  }, [loadingAutocomplete?.product]);

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.box_buyer}>
        <div className="title">Thông tin bên mua bảo hiểm</div>
        <div className={"infor-buyer"}>
          <div className="div-column">
            <div className="label">Tên doanh nghiệp</div>
            <Autocomplete
              disablePortal
              sx={{
                "& .MuiInputBase-root": {
                  padding: "1px",
                  paddingLeft: "8px",
                  paddingRight: "32px",
                },
              }}
              options={merchants}
              getOptionLabel={(options) => options?.title || ""}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Nhập hoặc chọn doanh nghiệp có sẵn"
                />
              )}
              value={{ title: merchant?.title || "" }}
              onChange={(e, value, reason) => {
                if (reason === "selectOption") {
                  dispatch({
                    type: "onChangeMerchant",
                    value,
                  });
                }
                if (reason === "clear") {
                  dispatch({
                    type: "onInputChangeMerchant",
                    value: "",
                  });
                }
              }}
              onInputChange={debounce((e, value, reason) => {
                if (reason === "input" && value) {
                  dispatch({ type: "onInputChangeMerchant", value });
                }
              }, 600)}
              noOptionsText={
                <div>
                  {loadingAutocomplete?.merchant
                    ? "Đang tải thêm ... "
                    : "Không tìm thấy dữ liệu"}
                </div>
              }
              ListboxComponent={(paramsList) => (
                <div
                  {...paramsList}
                  className={`${paramsList.className} div-scroll`}
                  onScroll={() => onScrollListMerchant("merchant")}
                >
                  {paramsList?.children?.map((i) => (
                    <li
                      {...i?.props}
                      key={i?.key}
                      className={`${i?.props?.className} scroll-item`}
                    />
                  ))}
                  {loadingAutocomplete?.merchant && (
                    <div
                      style={{
                        fontWeight: "600",
                        color: "#0B7CE4",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      Đang tải thêm ...{" "}
                    </div>
                  )}
                </div>
              )}
            />

            {errInfo.includes("merchant") && (
              <div className={classes.redTxt}>
                Vui lòng nhập đầy đủ thông tin
              </div>
            )}
          </div>
          <div className="div-column">
            <div className="label">Số hợp đồng</div>
            <TextField
              fullWidth
              placeholder="Nhập số hợp đồng"
              className="text-field-search"
              value={addInfo?.certNumGroup || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChangeInfo",
                  name: "certNumGroup",
                  value: e.target.value,
                })
              }
            />
            {errInfo.includes("certNumGroup") && (
              <div className={classes.redTxt}>
                Vui lòng nhập đầy đủ thông tin
              </div>
            )}
          </div>
          <div className="div-column">
            <div className="label">Mã số thuế</div>
            <TextField
              fullWidth
              placeholder="Nhập mã số thuế"
              className="text-field-search"
              value={addInfo?.tax_code || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChangeInfo",
                  name: "tax_code",
                  value: e.target.value,
                })
              }
            />
            {errInfo.includes("tax_code") && (
              <div className={classes.redTxt}>
                Vui lòng nhập đầy đủ thông tin
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.box_insurace}>
        <div className="title">Thông tin bảo hiểm</div>
        <div className={"infor-buyer"}>
          <div className="div-column">
            <div className="label">Chọn sản phẩm</div>
            <Autocomplete
              disablePortal
              sx={{
                "& .MuiInputBase-root": {
                  padding: "1px",
                  paddingLeft: "8px",
                  paddingRight: "32px",
                },
              }}
              options={products}
              getOptionLabel={(options) => options?.title || ""}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Nhập hoặc chọn doanh nghiệp có sẵn"
                />
              )}
              // value={{ title: merchant?.title || "" }}
              onChange={(e, value, reason) => {
                if (reason === "selectOption") {
                  dispatch({
                    type: "onChangeInfo",
                    name: "productId",
                    value,
                  });
                }

                if (reason === "clear") {
                  dispatch({
                    type: "onChangeInfo",
                    name: "productId",
                    value: {},
                  });
                  dispatch({ type: "onInputChangeProduct", value: "" });
                }
              }}
              onInputChange={debounce((e, value, reason) => {
                if (reason === "input" && value) {
                  dispatch({ type: "onInputChangeProduct", value });
                }
              }, 600)}
              noOptionsText={
                <div>
                  {loadingAutocomplete?.product
                    ? "Đang tải thêm ... "
                    : "Không tìm thấy dữ liệu"}
                </div>
              }
              ListboxComponent={(paramsList) => (
                <div
                  {...paramsList}
                  className={`${paramsList.className} div-scroll-product`}
                  onScroll={() => onScrollListMerchant("product")}
                >
                  {paramsList?.children?.map((i) => (
                    <li
                      {...i?.props}
                      key={i?.key}
                      className={`${i?.props?.className} scroll-item-product`}
                    />
                  ))}
                  {loadingAutocomplete?.product && (
                    <div
                      style={{
                        fontWeight: "600",
                        color: "#0B7CE4",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      Đang tải thêm ...{" "}
                    </div>
                  )}
                </div>
              )}
            />

            {errInfo.includes("productId") && (
              <div className={classes.redTxt}>
                Vui lòng nhập đầy đủ thông tin
              </div>
            )}
          </div>
          <div className="div-column">
            <div className="label">Chọn gói sản phẩm</div>
            <TextField
              fullWidth
              select
              value={addInfo?.productPackageId || null}
              onChange={(e) =>
                dispatch({
                  type: "onChangeInfo",
                  name: "productPackageId",
                  value: e.target.value,
                })
              }
            >
              {packages.map((item, index) => (
                <MenuItem key={`item_package_${index}`} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
            </TextField>
            {errInfo.includes("productPackageId") && (
              <div className={classes.redTxt}>
                Vui lòng nhập đầy đủ thông tin
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
