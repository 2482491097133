import { message } from "antd";
import { isEmpty, set, get } from "lodash";
import {
  createBenefitCode,
  getBenefitCode,
  updateBenefitCode,
} from "../../../apis/benefitCodeApis";

const sortData = (array) => {
  return array.sort((a, b) => {
    const [aParts, bParts] = [
      a.code_benefit.split("."),
      b.code_benefit.split("."),
    ];
    const minLength = Math.min(aParts.length, bParts.length);

    for (let i = 0; i < minLength; i++) {
      const aIsNumber = /^\d+$/.test(aParts[i]);
      const bIsNumber = /^\d+$/.test(bParts[i]);

      if (aIsNumber && bIsNumber) {
        const aPartNum = parseInt(aParts[i]);
        const bPartNum = parseInt(bParts[i]);

        if (aPartNum !== bPartNum) {
          return aPartNum - bPartNum;
        }
      } else {
        const comparison = aParts[i].localeCompare(bParts[i]);
        if (comparison !== 0) {
          return comparison;
        }
      }
    }

    return aParts.length - bParts.length;
  });
};

const benefitCodeReducer = ({ state, action, dispatch }) => {
  const cases = {
    getList: async () => {
      const { data } = state;
      const { value } = action;
      const res = {};
      const p = {
        keyword: value || "",
        limit: 100,
        offset: 0,
      };
      dispatch("onLoading");
      const result = await getBenefitCode(p);
      if (result instanceof Error) {
        message.error("Lỗi lấy danh sách", 2);
        return { loading: false };
      }

      if (!value) {
        res.typeCode = get(result, "data.result.data") || [];
      }
      data.total = get(result, "data.result.total") || 0;
      res.rows = sortData(
        (get(result, "data.result.data") || [])?.map((i) => {
          const regex = /\./g;
          if (!i.code_benefit.includes(".")) {
            return { ...i, isFirst: true };
          }
          if (i.code_benefit.match(regex).length === 1) {
            return { ...i, isSecond: true };
          }
          return i;
        })
      );
      return { ...res, data, loading: false };
    },

    fetchData: async () => {
      const { rows, data } = state;
      const res = {};
      const offset = rows.length;

      if (offset < data.total) {
        dispatch("onScrolling");
        const p = {
          keyword: "",
          limit: 100,
          offset,
        };
        dispatch("onLoading");
        const result = await getBenefitCode(p);
        if (result instanceof Error) {
          message.error("Lỗi lấy danh sách", 2);
          return { scrolling: false, loading: false };
        }

        const newData = sortData(
          (get(result, "data.result.data") || []).map((i) => {
            const regex = /\./g;
            if (!i.code_benefit.includes(".")) {
              return { ...i, isFirst: true };
            }
            if (i.code_benefit.match(regex).length === 1) {
              return { ...i, isSecond: true };
            }
            return i;
          })
        );
        res.rows = [...rows, ...newData];
        res.loading = false;
      }
      return { ...res, scrolling: false };
    },

    getListCode: async () => {
      const { data } = state;
      const { value } = action;
      const res = {};
      const p = {
        keyword: value || "",
        limit: 100,
        offset: 0,
      };
      if (!state.isInput) {
        const result = await getBenefitCode(p);
        if (result instanceof Error) {
          return message.error("Lỗi lấy danh sách", 2);
        }
        res.typeCode = get(result, "data.result.data") || [];
        data.codeBenefitParent = { code_benefit: value, title: "" };
      }
      res.isInput = false;
      return res;
    },

    onChangeData: () => {
      const { value, name } = action;
      const { data, errors } = state;

      if (name === "benefit_level" || name === "code_rules") {
        data.configBenefit = { ...(data.configBenefit || {}), [name]: value };
        // data.codeBenefit = "";
        // data.title = "";
        return { data };
      }
      const res = {};
      res.errors = errors.filter((i) => i !== name);
      data[name] = value;

      if (name === "codeBenefit") {
        res.errors = res.errors.filter(
          (i) => !["check_code_benefit", "invalid_code"].includes(i)
        );
        data[name] = value.toUpperCase();
      }

      return { data, ...res };
    },

    checkCode: async () => {
      const { data, errors } = state;

      if (isEmpty(data?.codeBenefit)) {
        return;
      }

      dispatch("onChecking");
      const dataAppi = await getBenefitCode({
        code_benefit: data?.codeBenefit || "",
        limit: 1000,
        offset: 0,
      });

      if (dataAppi instanceof Error) {
        message.error(
          "Lỗi khi kiễm tra Mã trong hệ thống, vui lòng thử lại sau",
          2
        );
        dispatch("onClose");
        return { checking: false };
      }

      let check = false;
      (get(dataAppi, "data.result.data") || []).forEach((i) => {
        if (
          (i?.code_benefit || "").replace(/\s/g, "").toLowerCase() ===
          (data.codeBenefit || "").replace(/\s/g, "").toLowerCase()
        ) {
          check = true;
          return;
        }
        return;
      });

      check && errors.push("check_code_benefit");
      return { errors, checking: false };
    },

    validate: () => {
      const { data } = state;
      const errors = [];
      !data?.codeBenefit && errors.push("codeBenefit");
      !data?.title && errors.push("title");

      switch (data?.configBenefit?.benefit_level) {
        case 1:
          data.codeBenefit.split(".").length !== 1 &&
            errors.push("invalid_code");
          break;
        case 2:
          data.codeBenefit.split(".").length !== 2 &&
            errors.push("invalid_code");
          break;
        case 3:
          data.codeBenefit.split(".").length !== 3 &&
            errors.push("invalid_code");
          break;

        default:
          break;
      }

      if (errors.length === 0) {
        dispatch("onSave");
      }
      return { errors };
    },

    onSave: async () => {
      const { data, isEdit, rows } = state;

      const codeBenefitParent = () => {
        const newData = data.codeBenefit.split(".");
        switch (get(data, "configBenefit.benefit_level")) {
          case 1:
            return null;
          case 2:
            return newData.slice(0, 1).join(".");
          case 3:
            return newData.slice(0, 2).join(".");

          default:
            return null;
        }
      };

      const d = {
        codeBenefit: data.codeBenefit,
        title: data.title,
        codeBenefitParent: codeBenefitParent(),
        configBenefit: data.configBenefit,
      };

      dispatch("onLoading");

      delete data?.configBenefit?.type_code_benefit;
      const res =
        Boolean(isEdit) && (Boolean(data.rowIdx) || data.rowIdx === 0)
          ? await updateBenefitCode({
              code_benefit: data?.codeBenefit,
              config_benefit: data.configBenefit,
              title: data.title,
            })
          : await createBenefitCode(d);

      if (res instanceof Error) {
        message.error(res?.data?.message || "Lỗi tạo mã quyền lợi", 2);
        return { loading: false };
      }
      Boolean(isEdit) && (Boolean(data.rowIdx) || data.rowIdx === 0)
        ? message.success("Cập nhật thành công!", 2)
        : message.success("Tạo mã quyền lợi thành công.!", 2);

      !Boolean(isEdit) && dispatch("getList");
      rows[data.rowIdx] = {
        ...(rows[data.rowIdx] || {}),
        title: data.title,
        config_benefit: {
          ...rows[data.rowIdx]?.config_benefit,
          ...data.config_benefit,
        },
      };

      return { data: {}, loading: false, open: false };
    },

    onOpen: () => {
      const { data } = state;
      const { isEdit = false, item, rowIdx } = action;

      if (isEdit) {
        data.codeBenefit = item?.code_benefit || "";
        data.title = item?.title || "";
        data.rowIdx = rowIdx;
        data.configBenefit = {
          ...item?.config_benefit,
          benefit_level:
            item?.config_benefit?.benefit_level ||
            item?.config_benefit?.type_code_benefit,
          insurance_code: item?.config_benefit?.insurance_code || "con_nguoi",
        };

        return { open: true, data, isEdit };
      }

      data.configBenefit = {
        benefit_level: 1,
        code_rules: 1,
        insurance_code: "con_nguoi",
      };
      return { open: true, data, isEdit };
    },

    onClose: () => ({ open: false, data: {}, errors: [], isEdit: false }),

    onLoading: () => ({ loading: true }),

    onChecking: () => ({ checking: true }),

    onScrolling: () => ({ scrolling: true }),
  };

  return cases[action?.type];
};
export default benefitCodeReducer;
