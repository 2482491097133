import * as PATH from "../configs/routesConfig";
import { Route } from "react-router-dom";
import { lazy } from "react";

import RecodrsIndemnify from "../views/admin-v2/claim-management/health/page/records-indemnify";
import RecodrsIndemnifyDetail from "../views/admin-v2/claim-management/health/page/recorda-indemnify-detail";
import CompensationReport from "../views/admin-v2/claim-management/intransit/page/compensation-report";
import CompensationDetailReport from "../views/admin-v2/claim-management/intransit/page/detail-report";
import CompensationReportPBA from "../views/admin-v2/claim-management/PBA/page/compensation-report";
import CompensationDetailReportPBA from "../views/admin-v2/claim-management/PBA/page/detail-report";
import DashboardTomamto from "../views/admin-v2/claim-management/tomato/page/dashboard";
import BusinessReportTomamto from "../views/admin-v2/claim-management/tomato/page/business-report";
import BusinessReportDetailTomamto from "../views/admin-v2/claim-management/tomato/page/business-report-detail";
import ClaimReportTomamto from "../views/admin-v2/claim-management/tomato/page/claim-report";
import ClaimReportDetailTomamto from "../views/admin-v2/claim-management/tomato/page/claim-detail";
import CheckClaimReport from "../views/admin-v2/claim-management/tomato/page/check-claim";
import QuotationUpdate from "../views/admin-v2/claim-management/tomato/page/quotation-update";
import ApproveClaimTomato from "../views/admin-v2/claim-management/tomato/page/approve-claim";
import QuotationApproveDetail from "../views/admin-v2/claim-management/tomato/page/quotation-approve-detail";
import Dashboard from "../views/admin-v2/claim-management/health/page/dashboard";
import Management from "../views/admin-v2/claim-management/aharide/page/management";
import Detail from "../views/admin-v2/claim-management/aharide/page/detail";
import LoyaltyManagement from "../views/admin-v2/claim-management/loyalty/page/management";
import LoyaltyDetail from "../views/admin-v2/claim-management/loyalty/page/detail";
import DashboardAll from "../views/admin-v2/dashboard";
import ReportAll from "../views/admin-v2/report";
import DowDocX from "../views/admin-v2/dowdocx";

export default function AdminV2Router(props) {
  // const { id, productCode = "" } = props;
  return (
    <>
      <Route
        exact
        path={PATH.RECODRS_INDEMNIFY}
        component={() => <RecodrsIndemnify />}
        key={PATH.RECODRS_INDEMNIFY}
      />
      <Route
        exact
        path={PATH.RECODRS_INDEMNIFY_DETAIL}
        component={() => <RecodrsIndemnifyDetail />}
        key={PATH.RECODRS_INDEMNIFY_DETAIL}
      />
      <Route
        exact
        path={PATH.HEALTH_REPORT}
        component={() => <RecodrsIndemnify role_type="claim_report" />}
        key={PATH.HEALTH_REPORT}
      />
      <Route
        exact
        path={PATH.COMPENSATION_REPORTR_INTRANSIT}
        component={() => <CompensationReport form="report-claim" />}
        key={PATH.COMPENSATION_REPORTR_INTRANSIT}
      />
      <Route
        exact
        path={PATH.REVIEW_CLAIM_INTRANSIT}
        component={() => <CompensationReport form="review-claim" />}
        key={PATH.REVIEW_CLAIM_INTRANSIT}
      />
      <Route
        exact
        path={PATH.DETAIL_REVIEW_INTRANSIT}
        component={() => <CompensationDetailReport form="review-claim" />}
        key={PATH.DETAIL_REVIEW_INTRANSIT}
      />
      <Route
        exact
        path={PATH.DETAIL_REPORT_INTRANSIT}
        component={() => <CompensationDetailReport form="report-claim" />}
        key={PATH.DETAIL_REPORT_INTRANSIT}
      />
      <Route
        exact
        path={PATH.PHUCBINHAN_BUSINESS_REPORT}
        component={() => <CompensationReportPBA form="report-claim" />}
        key={PATH.PHUCBINHAN_BUSINESS_REPORT}
      />
      <Route
        exact
        path={PATH.PHUCBINHAN_REVIEW_CLAIM}
        component={() => <CompensationReportPBA form="review-claim" />}
        key={PATH.PHUCBINHAN_REVIEW_CLAIM}
      />
      <Route
        exact
        path={PATH.DETAIL_REVIEW_PBA}
        component={() => <CompensationDetailReportPBA form="review-claim" />}
        key={PATH.DETAIL_REVIEW_PBA}
      />
      <Route
        exact
        path={PATH.DETAIL_REPORT_PBA}
        component={() => <CompensationDetailReportPBA form="report-claim" />}
        key={PATH.DETAIL_REPORT_PBA}
      />
      <Route
        exact
        path={`${PATH.AD_V2_DASHBOARD}/tomato`}
        component={() => <DashboardTomamto />}
        key={`${PATH.AD_V2_DASHBOARD}/tomato`}
      />
      <Route
        exact
        path={`${PATH.AD_V2_BUSINESS_REPORT}/tomato`}
        component={() => <BusinessReportTomamto />}
        key={`${PATH.AD_V2_BUSINESS_REPORT}/tomato`}
      />
      <Route
        exact
        path={`${PATH.AD_V2_BUSINESS_REPORT_DETAIL}/tomato`}
        component={() => <BusinessReportDetailTomamto />}
        key={`${PATH.AD_V2_BUSINESS_REPORT_DETAIL}/tomato`}
      />
      <Route
        exact
        path={`${PATH.AD_V2_CLAIM_REPORT}/tomato`}
        component={() => <ClaimReportTomamto />}
        key={`${PATH.AD_V2_CLAIM_REPORT}/tomato`}
      />
      <Route
        exact
        path={`${PATH.AD_V2_CLAIM_REPORT_DETAIL}/tomato`}
        component={() => <ClaimReportDetailTomamto />}
        key={`${PATH.AD_V2_CLAIM_REPORT_DETAIL}/tomato`}
      />
      <Route
        exact
        path={`${PATH.AD_V2_CLAIM_REVIEW}/tomato`}
        component={() => <CheckClaimReport />}
        key={`${PATH.AD_V2_CLAIM_REVIEW}/tomato`}
      />
      <Route
        exact
        path={`${PATH.AD_V2_CLAIM_REVIEW_DETAIL}/tomato`}
        component={() => <QuotationUpdate />}
        key={`${PATH.AD_V2_CLAIM_REVIEW_DETAIL}/tomato`}
      />
      <Route
        exact
        path={`${PATH.AD_V2_CLAIM_APPROVE}/tomato`}
        component={() => <ApproveClaimTomato />}
        key={`${PATH.AD_V2_CLAIM_APPROVE}/tomato`}
      />
      <Route
        exact
        path={`${PATH.AD_V2_CLAIM_APPROVE_DETAIL}/tomato`}
        component={() => <QuotationApproveDetail />}
        key={`${PATH.AD_V2_CLAIM_APPROVE_DETAIL}/tomato`}
      />
      <Route
        exact
        path={PATH.AD_V2_HEALTH_DASHBOARD}
        component={() => <Dashboard />}
        key={PATH.AD_V2_HEALTH_DASHBOARD}
      />
      <Route
        exact
        path={PATH.AHARIDE_MANAGEMENT}
        component={() => <Management form="approve-claim" />}
        key={PATH.AHARIDE_MANAGEMENT}
      />
      <Route
        exact
        path={PATH.AHARIDE_REPORT}
        component={() => <Management form="report-claim" />}
        key={PATH.AHARIDE_REPORT}
      />
      <Route
        exact
        path={PATH.AHARIDE_MANAGEMENT_DETAIL}
        component={() => <Detail form="approve-claim" />}
        key={PATH.AHARIDE_MANAGEMENT_DETAIL}
      />
      <Route
        exact
        path={PATH.AHARIDE_REPORT_DETAIL}
        component={() => <Detail form="report-claim" />}
        key={PATH.AHARIDE_REPORT_DETAIL}
      />
      <Route
        exact
        path={PATH.LOYALTY_MANAGEMENT}
        component={() => <LoyaltyManagement form="approve-claim" />}
        key={PATH.LOYALTY_MANAGEMENT}
      />
      <Route
        exact
        path={PATH.LOYALTY_REPORT}
        component={() => <LoyaltyManagement form="report-claim" />}
        key={PATH.LOYALTY_REPORT}
      />
      <Route
        exact
        path={`${PATH.LOYALTY_MANAGEMENT}/detail`}
        component={() => <LoyaltyDetail form="approve-claim" />}
        key={`${PATH.LOYALTY_MANAGEMENT}/detail`}
      />
      <Route
        exact
        path={`${PATH.LOYALTY_REPORT}/detail`}
        component={() => <LoyaltyDetail form="report-claim" />}
        key={`${PATH.LOYALTY_REPORT}/detail`}
      />
      <Route
        exact
        path={`${PATH.AD_V2_DASHBOARD}`}
        component={() => <DashboardAll />}
        key={`${PATH.AD_V2_DASHBOARD}`}
      />
      <Route
        exact
        path={`${PATH.AD_V2_REPORT}`}
        component={() => <ReportAll />}
        key={`${PATH.AD_V2_REPORT}`}
      />
      <Route
        exact
        path={`${PATH.AD_V2_DOWLOAD_DOCX}`}
        component={() => <DowDocX />}
        key={`${PATH.AD_V2_DOWLOAD_DOCX}`}
      />
    </>
  );
}
