import _ from "lodash";
import { getTypeInsu } from "../components/createDetailClaimFile/until";
import { CREATE_DETAIL_CLAIM_FILE_TOMATO } from "../../../../../configs/routesConfig";
import moment from "moment";
const dataTemps = [
  "Họ tên",
  "Năm sinh",
  "CMND/CCCD/Hộ chiếu",
  "Số điện thoại",
  "Email",
  "Địa chỉ",
];

const fomatYYMMDD = (value) => {
  return moment(value).format("Y-MM-DD");
};

const createClaimFileReducer = ({
  state,
  action,
  history,
  itemContract,
  location,
}) => {
  const cases = {
    initial: () => {
      const infoContracts = dataTemps.map((item) => {
        switch (item) {
          case "Họ tên":
            return { title: item, value: itemContract?.name || "" };
          case "Năm sinh":
            return { title: item, value: itemContract?.birthday || "" };
          case "CMND/CCCD/Hộ chiếu":
            return {
              title: item,
              value: itemContract?.citizen_identification || "",
            };
          case "Số điện thoại":
            return { title: item, value: itemContract?.phone || "" };
          case "Email":
            return { title: item, value: itemContract?.email || "" };
          case "Địa chỉ":
            return { title: item, value: itemContract?.address || "" };
        }
      });

      const sum = _.get(itemContract, "total_insurance_benefits") | "";
      const expense = _.get(itemContract, "price") | "";

      return { infoContracts, sum, expense };
    },

    resetData: () => {
      const { paramsData } = state;
      paramsData.insuredInfo = {};
      paramsData.extraInfo = {};
      return { paramsData };
    },

    onChange: () => {
      const { name, value, isDate } = action;
      const { paramsData } = state;

      if (["type", "level", "relate", "bikeType"].includes(name)) {
        _.set(paramsData, ["extraInfo", "accident", `${name}`], value);
      } else {
        _.set(
          paramsData,
          ["insuredInfo", `${name}`],
          !isDate ? value : fomatYYMMDD(value)
        );
      }

      return { paramsData };
    },

    onSubmit: () => {
      const { paramsData } = state;
      const { insuredInfo = {}, extraInfo = {} } = paramsData;
      const bone =
        ["life", "work accident"].includes(
          _.get(paramsData, "extraInfo.accident.type")
        ) && _.get(paramsData, "insuredInfo.treatmentType") === "outpatient";

      const errors = [];

      bone &&
        _.get(extraInfo, `accident.relate`) !== null &&
        _.isEmpty(_.get(extraInfo, `accident.relate`)) &&
        errors.push("relate");

      [
        "treatmentType",
        "accidentAt",
        "discription",
        "treatmentPrice",
        "diagnostic",
        "hospitalizeDate",
        "dischargeDate",
        "accidentDate",
        "text",
      ].forEach((key) => {
        if (
          ["hospitalizeDate", "dischargeDate", "accidentDate"].includes(key)
        ) {
          if (fomatYYMMDD(insuredInfo[key]) === "Invalid date") {
            errors.push(key);
            return;
          }
        }
        if (key === "hospitalizeDate" || key === "dischargeDate") {
          _.get(insuredInfo, "treatmentType") === "boarding" &&
            _.isEmpty(insuredInfo[key]) &&
            _.get(extraInfo, "accident.level") !== "dead" &&
            errors.push(key);
          const _today = new Date().setHours(0, 0, 0);
          const _date = new Date(insuredInfo[key]).setHours(0, 0, 0);
          const _dateAccident = new Date(insuredInfo["accidentDate"]).setHours(
            0,
            0,
            0
          );
          if (_date > _today 
           // && key !== "dischargeDate"
            ) {
            errors.push(key);
          }

          if (_date < _dateAccident) {
            errors.push("hospitalizeDate_outdate");
          }

          if (
            _.get(insuredInfo, "treatmentType") === "boarding" &&
            key === "dischargeDate" &&
            _.get(extraInfo, "accident.level") !== "dead"
          ) {
            const hospitalizeDate = new Date(
              insuredInfo.hospitalizeDate
            ).setHours(0, 0, 0);
            const dischargeDate = new Date(insuredInfo.dischargeDate).setHours(
              0,
              0,
              0
            );
            let a = hospitalizeDate > dischargeDate;
            if (hospitalizeDate > dischargeDate) {
              errors.push(key);
            }
          }
        } else {
          if (
            !(
              _.get(extraInfo, "accident.level") === "dead" &&
              ["treatmentPrice", "treatmentType"].includes(key)
            )
          ) {
            if (_.isEmpty(insuredInfo[key])) {
              errors.push(key);
            }
            if (+insuredInfo.treatmentPrice < 1000) {
              errors.push("treatmentPrice2");
            }
          }
        }
        if (key === "accidentDate") {
          const _today = new Date().setHours(0, 0, 0);
          const _date = new Date(insuredInfo[key]).setHours(0, 0, 0);
          const _dateHospitalize = new Date(
            insuredInfo["hospitalizeDate"]
          ).setHours(0, 0, 0);
          if (
            _date > _today ||
            (_.get(insuredInfo, "treatmentType") === "boarding" &&
              _date > _dateHospitalize)
          ) {
            errors.push(key);
          }
        }

        if (
          _.get(insuredInfo, "treatmentType") === "boarding" &&
          !!insuredInfo.hospitalizeDate &&
          !!insuredInfo.dischargeDate &&
          moment(insuredInfo.hospitalizeDate).diff(
            moment(insuredInfo.dischargeDate),
            "days"
          ) === 0
        ) {
          errors.push("duplicateDate");
          return;
        }
      });

      if (
        insuredInfo.treatmentType === "boarding" &&
        extraInfo?.accident?.level !== "dead" &&
        new Date(insuredInfo?.hospitalizeDate) < new Date("05/06/2022")
      ) {
        errors.push("errorDate1");
      }

      if (new Date(insuredInfo?.accidentDate) < new Date("05/06/2022")) {
        errors.push("errorDate2");
      }

      ["type", "level"].forEach((key) => {
        if (_.isEmpty(_.get(extraInfo, `accident.${key}`))) {
          errors.push(key);
        }
      });

      if (errors.length === 0) {
        let a = paramsData;
        let b = itemContract;
        if (paramsData?.extraInfo?.accident?.level === "dead") {
          paramsData.extraInfo.beneficiary = {};
          paramsData.extraInfo.beneficiary.type = "relative";
          paramsData.extraInfo.beneficiary.fullname = "";
        }

        history.push(CREATE_DETAIL_CLAIM_FILE_TOMATO + location.search, {
          itemContract: itemContract,
          paramsData: paramsData,
        });
      }

      return { errors };
    },
  };

  return cases[action?.type];
};

export default createClaimFileReducer;
