import React, { useEffect } from "react";
import _ from "lodash";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Backdrop, CircularProgress, IconButton } from "@mui/material";

import { TextField } from "../../overrideMui";
import styleClaimLicense from "./styleClaimLicense";
import DiagnosisTable from "./diagnosisTable";
import ExpenseTable from "./expenseTable";
import Subsidize from "./subsidize";
import DialogSendApproval from "./dialogSendApproval";
import OtherTableApproval from "./otherTableApproval";

import { ReactComponent as IconImg } from "../../../../../../assets/health/icon_img.svg";
import BoxImgLicense from "../claim/dialogUpdate/boxImgLicense";

// import { PDFViewer } from "@react-pdf/renderer";
// import ExportClaimPDF from "./dialogSendApproval/ExportClaimPDF";

const getTitleByTypeOfIndimnity = (id) => {
  switch (id) {
    case 3:
      return "Tỉ lệ thương tật";
    case 5:
      return "Ung thư/ bệnh hiểm nghèo/ đột quỵ";
    case 6:
      return "Nguyên nhân tử vong";
    default:
      return "";
  }
};

export default function ClaimLicensse(props) {
  const { state, dispatch, level_id } = props;
  const classes = styleClaimLicense();
  const {
    finalFeesTotalApprove,
    noteUseLV1,
    detail,
    loadingClaimLicense,
    tabsDetail,
    imgLicenseShow,
  } = state;

  const handleOutsideClick = (event) => {
    if (!event.target.closest("#show-img-box") && !!imgLicenseShow.open) {
      dispatch({ type: "setShowImgLicens", name: "" });
    }
  };

  useEffect(() => {
    tabsDetail.key === "claim-license" && dispatch("initialClaimLicense");
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [!!imgLicenseShow.open]);

  const Title = ({ name, title }) => {
    return (
      <div className="header-title" key={title}>
        <span>{title}</span>
        <IconButton
          className="icon-button"
          onClick={() => dispatch({ type: "setShowImgLicens", name })}
        >
          <IconImg className="icon-svg" />
        </IconButton>
      </div>
    );
  };

  const check = _.get(detail, "extraInfo.countCheckInput");
  //+check === 1
  // return (
  //   <div>
  //     <PDFViewer
  //       style={{
  //         width: "100%",
  //         height: "85vh",
  //         minHeight: "400px",
  //         maxHeight: "750px",
  //       }}
  //     >
  //       <ExportClaimPDF detail={detail} />
  //     </PDFViewer>
  //   </div>
  // );
  return +check === 1 ? (
    <div className={classes.container}>
      {[3, 5, 6].includes(+detail?.extraInfo?.typeOfIndimnity) ? (
        <>
          <Title
            title={getTitleByTypeOfIndimnity(
              +detail?.extraInfo?.typeOfIndimnity
            )}
            name="prescription"
          />
          <OtherTableApproval {...{ state, dispatch, level_id }} />
          <Title title="Trợ cấp khác" name="prescription" />
          <Subsidize {...{ state, dispatch, level_id }} />
        </>
      ) : (
        <>
          <Title title="Chẩn đoán bệnh" name="prescription" />
          <DiagnosisTable {...{ state, dispatch, level_id }} />
          <Title title="Bảng kê chi phí khám" name="treatment_cost_checkout" />
          <ExpenseTable
            {...{ state, dispatch, name: "examination", level_id }}
          />
          <Title title="Hóa đơn" name="bill" />
          <ExpenseTable {...{ state, dispatch, name: "bill", level_id }} />
          <Title title="Toa thuốc" name="medicine" />
          <ExpenseTable {...{ state, dispatch, name: "medicine", level_id }} />
          <Title title="Trợ cấp khác" name="prescription" />
          <Subsidize {...{ state, dispatch, level_id }} />
        </>
      )}

      <div className={classes.border} />
      <div className={classes.containerFees}>
        <Grid container>
          <Grid item xs={5} display="flex" alignItems="end">
            <div className={classes.boxNote}>
              <div className="note">Ghi chú</div>
              <div
                style={{
                  fontSize: "11px",
                  fontStyle: "italic",
                  padding: "0 0 6px 6px",
                }}
              >
                Nội dung này sẽ được gửi đến khách hàng
              </div>
              <TextField
                fullWidth
                disabled={level_id !== 1}
                multiline
                rows={3}
                className={classes.multilineTextField}
                value={noteUseLV1?.note || ""}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeNoteLV1",
                    name: "note",
                    value: e.target.value,
                  })
                }
              />
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className={classes.boxFees}>
              <div className="total-fees">
                <span>Tổng chi phí trong phạm vi bảo hiểm</span>
                <span>
                  {`${finalFeesTotalApprove || "0"}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "."
                  ) + " VND"}
                </span>
              </div>
              <div className="ratio-cost">
                <div className="box">
                  <span className="lable">Nhập tỉ lệ % bồi thường </span>
                  <span className="textField-fees">
                    <TextField
                      disabled={level_id !== 1}
                      InputProps={{
                        endAdornment: <span>%</span>,
                      }}
                      fullWidth
                      className={classes.textFieldRation}
                      value={
                        noteUseLV1?.approvedPercent == 0
                          ? 0
                          : noteUseLV1?.approvedPercent || ""
                      }
                      onChange={(e) => {
                        if (
                          !"0123456789".includes(e?.nativeEvent?.data) &&
                          e?.nativeEvent?.data !== null
                        )
                          return;
                        dispatch({
                          type: "onChangeNoteLV1",
                          name: "approvedPercent",
                          value: +e.target.value,
                        });
                      }}
                    />
                  </span>
                </div>
                <div className="box">
                  <span className="lable">Tổng chi phí bồi thường</span>
                  <span className="textField-fees">
                    <TextField
                      disabled={level_id !== 1}
                      InputProps={{
                        endAdornment: <span>VNĐ</span>,
                      }}
                      fullWidth
                      className={classes.textFieldRation}
                      value={`${
                        noteUseLV1?.amount == 0 ? 0 : noteUseLV1?.amount || ""
                      }`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      onChange={(e) => {
                        if (
                          !"0123456789".includes(e?.nativeEvent?.data) &&
                          e?.nativeEvent?.data !== null
                        )
                          return;
                        dispatch({
                          type: "onChangeNoteLV1",
                          name: "amount",
                          value: +e.target.value.replace(/\./g, ""),
                        });
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button
            disabled={
              level_id !== 1 ||
              [5, 6, 7, 8, 9].includes(detail?.statusId || detail?.status_id)
            }
            className={
              level_id !== 1 ||
              [5, 6, 7, 8, 9].includes(detail?.statusId || detail?.status_id)
                ? "buttons disabled"
                : "buttons"
            }
            onClick={() => dispatch("submitExportLicense")}
          >
            Xuất Hồ sơ
          </Button>
        </div>
      </div>
      <DialogSendApproval {...{ state, dispatch }} />
      <Backdrop
        style={{
          zIndex: 9999,
          color: "#fff",
          background: "#000000",
          opacity: "0.7",
        }}
        open={!!loadingClaimLicense}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: "22px",
            fontWeight: "600",
          }}
        >
          <span style={{}}>
            Đang chạy tiến trình xuất file, có thể mất chút thời gian.
          </span>
          <span>xin vui lòng chờ giây lát.</span>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>
      <div
        className={classes.boxShowImg}
        style={{
          width: !!imgLicenseShow.open ? "500px" : 0,
          opacity: !!imgLicenseShow.open ? 1 : 0,
        }}
        id={`show-img-box`}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <BoxImgLicense {...{ dispatch, state }} />
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        color: "red",
        fontStyle: "italic",
        padding: "12px 16px",
        fontSize: "14px",
      }}
    >
      Hồ sơ bồi thường chưa được xử lý
    </div>
  );
}
