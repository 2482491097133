import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import { getIconInsurance } from "../utils";
import phone from "../../../../../assets/health/phone.svg";
import card from "../../../../../assets/health/Card.svg";

export default function BoxPackage(props) {
  const { data, onClick } = props;
  const classes = styleds();
  const dataIcon = getIconInsurance(data?.productIdParent) || {};
  return (
    <Box
      className={classes.box}
      style={{
        background: dataIcon?.color ? dataIcon.color : "#069D27",
      }}
      onClick={onClick}
    >
      <div className={classes.headTitle}>
        <img
          src={data?.productUrlLogo || dataIcon?.icon || ""}
          width={data?.productIdParent == 19 ? 27 : 22}
          height={data?.productIdParent == 19 ? 27 : 22}
          className="img"
        />
        <span className="title">{data?.productTitle}</span>
      </div>
      <div className={classes.inforBox}>
        <div className="name-user">{data?.insuredInfo?.insuredName}</div>
        <div className="row">
          <div>
            <img src={phone} width={20} height={20} />
            <span style={{ color: "#3961A0", marginLeft: "6px" }}>
              {data?.insuredInfo?.insuredPhone}
            </span>
          </div>
          <div>
            <img src={card} width={20} height={20} />
            <span style={{ color: "#3961A0", marginLeft: "6px" }}>
              {data?.insuredInfo?.insuredPrivateID ||
                data?.insuredInfo?.insuredPrivateId}
            </span>
          </div>
        </div>
        <div className="row">
          <span>Số hợp đồng</span>
          <span> {data?.certNum}</span>
        </div>
        <div className="row">
          <span>Ngày hết hạn</span>
          <span>{moment(data.endDate).format("DD/MM/YYYY")}</span>
        </div>
      </div>
    </Box>
  );
}

const styleds = makeStyles(() => {
  return {
    box: {
      borderRadius: "17px",

      width: "100%",
      cursor: "pointer",
    },

    headTitle: {
      padding: "8px",
      width: "100%",
      color: "white",
      display: "flex",
      alignItems: "center",

      "& .img": {
        marginLeft: "12px",
      },

      "& .title": {
        width: "calc(100% - 28px)",
        textAlign: "center",
        fontSize: "15px",
        fontWeight: "700",
        padding: "0 12px 0 6px",
      },
    },
    inforBox: {
      padding: "12px",
      width: "100%",
      background: "white",
      borderRadius: "16px",
      border: "1px solid white",

      "& .name-user": {
        textTransform: "capitalize",
        fontSize: "15px",
        fontWeight: "600",
      },

      "& .row": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "6px",
      },
    },

    //
  };
});
