import React from "react";
import { IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Document, Page } from "react-pdf";

import { styledReviewInfo } from "./styeleds/styledReviewInfo";
import wait from "../../../../../assets/ahamove/wait.png";

const RuleModal = ({ url, open, onClose }) => {
  const [states, setStates] = React.useState({
    numPages: "" || 1,
    pageShow: 1,
    width: 0,
    link: "",
  });

  const onDocumentLoadSuccess = (params) => {
    const { numPages } = params;
    setStates({ ...states, loading: true });
    if (numPages > 1) {
      setStates({ ...states, numPages });
    }
  };

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={!!open}
      centered={true}
    >
      <div style={styleModal}>
        <div
          className="flex"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            className="font-weight-600 font-size-16"
            style={{ width: "80%" }}
          >
            {"Điều khoản trong Quy tắc Bảo hiểm"}
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ marginTop: 10 }}>
          <div id="pdfBox">
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
              loading={<Loading />}
              // renderMode={"canvas"}
              // rotate={90}
            >
              <div
                style={{
                  height: "75vh",
                  width:
                    window?.document?.getElementById("pdfBox")?.offsetWidth,
                  overflow: "scroll",
                }}
              >
                {Array.from(new Array(states.numPages), (el, index) => (
                  <Page
                    width={
                      window?.document?.getElementById("pdfBox")?.offsetWidth
                    }
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ))}
              </div>
            </Document>
          </div>
          {/* <iframe
            src={url}
            style={{
              height: "80vh",
              width: "100%",
              overflow: "hidden",
              border: "none",
            }}
          /> */}
        </div>

        <div style={{ marginTop: 10 }} className="grid-container-1">
          <div
            style={{
              textAlign: "center",
              color: "#0B3C8A",
              border: "1px #0B3C8A solid",
              borderRadius: 8,
              padding: 12,
            }}
            className="font-weight-600"
            onClick={onClose}
          >
            Đóng
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RuleModal;

const styleModal = {
  padding: "1rem",
  position: "absolute",
  border: "none",
  borderRadius: "8px",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "auto",
  background: "white",
};

const Loading = () => {
  const classes = styledReviewInfo();
  return (
    <div className={classes.divContentRule}>
      <img src={wait} alt="wait" width={"40%"} />
      <div className="text">
        <span className="title">
          Vui lòng đợi điều khoản trong quy tắc bảo hiểm được tải về
        </span>
        <span className="action">Quá trình này sẽ mất khoảng vài phút</span>
      </div>
    </div>
  );
};
