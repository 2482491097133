import api from "../configs/api";

export function getListClaimReport(data) {
  return api
    .apiForm("POST", `/api/v2/report/claim/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListClaimReportWithType(systemType, data) {
  return api
    .apiForm("POST", `/api/v2/report/${systemType}/claim/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export const getDetailClaim = (systemType, id) =>
  api
    .apiForm("GET", `/api/v2/claim/${systemType}/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

export function getListProductReport(data) {
  return api
    .apiForm("POST", `/api/v2/report/product/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListMerchantReport(data) {
  return api
    .apiForm("POST", `/api/v2/report/merchant/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}
