import moment from "moment";

export default function ExportFileGcn(props) {
  const { data } = props;
  return (
    <>
      <div style={{ display: "none" }}>
        <table
          className="collapse-border"
          id={"export-data-gcn"}
          data-cols-width="7, 25, 30, 30, 40, 30, 30, 40, 30, 30"
        >
          <thead style={{ fontSize: "13" }}>
            <tr />
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"16"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                FILE QUẢN LÝ GCN
              </th>
            </tr>
            <tr />
            <tr />
            <tr />
            <tr style={{ fontSize: "13" }}>
              <Th>STT</Th>
              <Th>Gói sản phẩm</Th>
              <Th>Họ và tên</Th>
              <Th>Doanh nghiệp</Th>
              <Th>Số thẻ BH</Th>
              <Th>CCCD/CMND</Th>
              <Th>Số diện thoại</Th>
              <Th>Công ty BH</Th>
              <Th>Ngày bắt đầu</Th>
              <Th>Ngày hết hạn</Th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13" }}>
            {data.map((item, index) => {
              return (
                <tr key={index} style={{ fontSize: "13" }}>
                  <th
                    data-f-name={"Times new ro<Tdman"}
                    data-f-sz={"12"}
                    data-a-h={"center"}
                    data-b-a-s={"thin"}
                    data-a-wrap={true}
                  >
                    {index + 1}
                  </th>
                  <Td>
                    {item?.productPackageTitle ||
                      item?.product_package_title ||
                      ""}
                  </Td>
                  <Td>{item?.insuredName || item?.insured_name || ""}</Td>
                  <Td>
                    {item?.organizationTitle || item?.organization_title || ""}
                  </Td>
                  <Td>{item?.certNum || item?.cert_num || ""}</Td>
                  <Td>
                    {item?.insuredPrivateId || item?.insured_private_id || ""}
                  </Td>
                  <Td>{item?.insuredPhone || item?.insured_phone || ""}</Td>
                  <Td>{item?.providerTitle || item?.provider_title || ""}</Td>

                  <Td>
                    {item?.beginDate || item?.begin_date
                      ? moment(item?.beginDate || item?.begin_date).format(
                          "DD/MM/YYYY"
                        )
                      : ""}
                  </Td>
                  <Td>
                    {item?.endDate || item?.end_date
                      ? moment(item?.endDate || item?.end_date).format(
                          "DD/MM/YYYY"
                        )
                      : ""}
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const Th = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-h={"center"}
      data-a-wrap={true}
      data-f-bold={true}
    >
      {props.children}
    </th>
  );
};

const Td = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-wrap={true}
    >
      {props.children}
    </th>
  );
};
