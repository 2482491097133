import { makeStyles } from "@mui/styles";

export const stylePopoverFilter = makeStyles(() => ({
  container: {
    padding: "10px 15px 20px",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .title": {
      display: "flex",
      alignItems: "center",
    },
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    margin: "12px 0",

    "& .span-time": {
      fontSize: "15px",
      color: "#4B4B4B",
      fontWeight: "600",
    },

    "& .span-delete": {
      fontSize: "14px",
      color: "#0B7CE4",
      fontWeight: "400",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
  },

  time: {
    marginTop: "16px",
    paddingBottom: "16px",
    borderBottom: "1px solid #D4D4D4",

    "& .box-date": {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "14px",

      "& fieldset": {
        borderRadius: "8px",
      },

      "& .MuiButtonBase-root": {
        paddingLeft: 4,
      },

      "& .from-date": {
        width: "48%",
      },
      "& .to-date": {
        width: "48%",
      },
    },

    "& .span-date": {
      marginBottom: "6px",
    },
  },

  box_check: {
    marginTop: "16px",
    paddingBottom: "16px",
    borderBottom: "1px solid #D4D4D4",
    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .icon": {
      width: "30px",
      height: "30px",
      objectFit: "contain",
      marginRight: 8,
      borderRadius: "50%",
    },
    "& .color-text": {
      marginTop: "8px",
      color: "#0B7CE4",
      fontSize: 14,
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },

    "& .show-move": {
      display: "flex",
      justifyContent: "space-between",
    },
  },

  date: {
    "& .MuiInputBase-root": {
      paddingLeft: "4px",
      borderWidth: "1px",
    },

    "& .MuiInputBase-input": {
      padding: "6px 4px",
      fontSize: "13px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 4,
    },

    "& .MuiButtonBase-root": {
      padding: "6px",

      "& .MuiSvgIcon-root": {
        width: "20px",
      },
    },
  },

  checkBox: {
    marginRight: "12px",
  },

  button: {
    background: "#0B7CE4",
    color: "white",
    fontWeight: "500",
    width: "100%",
    marginTop: 15,
    borderRadius: "8px",
  },
}));
