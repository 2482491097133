import React, { useEffect } from 'react';
import { Wrapper, ContentWrapper, Content } from './styles';
import { Col, Row } from 'antd';
import { useHistory } from "react-router-dom";

import Button from '../../../../ui/buttons/Button';
import IconSuccess from '../../../../assets/icons/ic_success.png'
import * as PATH from '../../../../configs/routesConfig';
import './CarAccident.css'

const ClaimCarAccidentSuccess = () => {
  const history = useHistory();
  useEffect(() => {
    document.body.style.backgroundColor = '#F7F7F7'
  }, [])

	return (
		<Wrapper> 
			<ContentWrapper>
				<Content>
          <Row justify='center'>
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <div style={formInfo}>
                <img alt="example" style={styleIconSuccess} src={IconSuccess} />
                <div style={styleTitle}>
                  Chúc mừng bạn đã gửi hồ sơ yêu cầu bồi thường thành công
                </div>
                <div style={styleContent}>
                  Theo dõi email và thông báo trên app để biết tình trạng xử lý hồ sơ
                </div>
                
              </div>
            </Col>
          </Row>
				</Content>
        <div style={styleButton}>
          <Button
            type="primary"
            htmlType="submit"
            // loading={isSubmitting}
            // disabled={!isValid}
            border='14'
          >
            Đóng
          </Button>
        </div>
			</ContentWrapper>
		</Wrapper>
	);
}

const styleButton = {
  width: '100%',
  position: 'absolute',
  padding: '40px 20px 0 20px',
  bottom: 60,
}

const formInfo = {
  justifyContent: 'center',
  alignItems: 'center',
  margin: '100px 0 12px 0',
}

const styleIconSuccess = {
  display: 'flex',
  width: 80,
  height: 80,
  margin: 'auto'
}

const styleTitle = {
  fontWeight: 'bold',
  fontSize: 24,
  textAlign: 'center',
  color: '#024281',
  margin: 14,
  marginTop: 30,
}

const styleContent = {
  fontSize: 16,
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.51)',
  margin: 20
}

export default ClaimCarAccidentSuccess
