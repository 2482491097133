import * as React from "react";
import moment from "moment";
import { Button, Drawer, FormControlLabel, FormGroup } from "@mui/material";

import {
  FilterList as FilterListIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";

import { stylePopoverFilter } from "../styles/stylePopoverFilter";
import { TextField, CheckBox } from "../overrideMui";
import { get } from "lodash";

export default function PopoverFilter(props) {
  const classes = stylePopoverFilter();
  const { state, dispatch, user } = props;
  const isRootDev = get(user, "role_management_claim.is_root") === 1;

  const {
    filter,
    dataSearch,
    listMerchants,
    listOrganization,
    loadingFilter,
    pageFilter,
    oldListOrganization,
    oldListMerchants,
  } = state;

  const HeaTitle = ({ title, name }) => (
    <div className={classes.title}>
      <span className="span-time">{title}</span>
      <span
        className="span-delete"
        onClick={() =>
          dispatch({ type: "changeDataFilter", name, typeAction: "delete" })
        }
      >
        Xoá
      </span>
    </div>
  );

  const hadData = [
    {
      title: "Trạng thái",
      acttionName: "statusId",
      option: [
        {
          label: "Đang hoạt động",
          id: 1,
          checked: dataSearch?.statusId === 1,
        },
        {
          label: "Ngưng hoạt động",
          id: 0,
          checked: dataSearch?.statusId === 0,
        },
      ],
    },
    {
      title: "Công Ty Bảo Hiểm",
      acttionName: "providerIds",
      dontShow: !isRootDev,
      option: listMerchants.map((i) => {
        return {
          ...i,
          label: i.title,
          checked: Boolean(dataSearch?.providerIds?.includes(i.id)),
        };
      }),
    },
    {
      title: "Quản Lý Bởi",
      acttionName: "orgIds",
      dontShow: !isRootDev,
      option: listOrganization?.map((i) => {
        return {
          ...i,
          label: i.title,
          checked: Boolean(dataSearch?.orgIds?.includes(i?.id)),
        };
      }),
    },
  ];

  const checkShowmove = (name, option = []) => {
    return name === "orgIds"
      ? oldListOrganization.length > option?.length
      : oldListMerchants.length > option?.length;
  };

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={Boolean(filter?.open)}
        onClose={() => dispatch("closeFilter")}
        sx={{
          "& .MuiPaper-elevation": {
            marginTop: "24px",
            marginRight: "12px",
            maxHeight: "calc(100vh - 38px)",
          },
        }}
      >
        <div style={{ width: "280px", maxHeight: "100%", overflow: "auto" }}>
          <div className={classes.container}>
            <div className={classes.header}>
              <div>
                <FilterListIcon fontSize="small" sx={{ marginRight: "8px" }} />
                <span>Bộ lộc</span>
              </div>
              <CloseIcon
                fontSize="small"
                sx={{ marginRight: "8px", cursor: "pointer" }}
                onClick={() => dispatch("closeFilter")}
              />
            </div>
            {hadData.map((data, dataIndex) => (
              <div key={data.title} className={classes.box_check}>
                <HeaTitle title={data.title} name={data.acttionName} />
                <div>
                  <FormGroup>
                    {data.option.map((opt, otpIndex) => (
                      <div
                        key={`item_checkBox_${otpIndex}`}
                        style={{ marginBottom: "8px" }}
                      >
                        <FormControlLabel
                          sx={{
                            margin: 0,
                            "& .MuiTypography-root": {
                              fontSize: "14px",
                            },
                          }}
                          control={
                            <CheckBox
                              className={classes.checkBox}
                              checked={Boolean(opt?.checked)}
                              onChange={(e) =>
                                dispatch({
                                  type: "changeDataFilter",
                                  value: opt.id,
                                  name: data.acttionName,
                                })
                              }
                            />
                          }
                          label={opt?.label}
                        />
                      </div>
                    ))}
                  </FormGroup>
                  {["orgIds", "providerIds"].includes(data.acttionName) &&
                    loadingFilter[data.acttionName] && (
                      <div
                        style={{
                          fontWeight: "600",
                          color: "#0B7CE4",
                          width: "100%",
                          textAlign: "center",
                          fontSize: "17px",
                        }}
                      >
                        Đang tải thêm ...{" "}
                      </div>
                    )}
                  {["orgIds", "providerIds"].includes(data.acttionName) && (
                    <div className="show-move">
                      {(get(pageFilter, `${data.acttionName}.total`, 0) >
                        get(pageFilter, `${data.acttionName}.page`, 0) * 20 ||
                        checkShowmove(data.acttionName, data.option)) && (
                        <div
                          className="row color-text"
                          onClick={() => {
                            if (!loadingFilter[data.acttionName]) {
                              dispatch({
                                type: "showMoveFilter",
                                show: true,
                                name: data.acttionName,
                              });
                            }
                          }}
                        >
                          Hiển thị thêm{" "}
                          <ExpandMoreIcon
                            color="#0B7CE4"
                            sx={{ width: "20px" }}
                          />
                        </div>
                      )}
                      {data?.option?.length > 5 && (
                        <div
                          className="row color-text"
                          onClick={() =>
                            dispatch({
                              type: "collapseFilter",
                              show: false,
                              name: data.acttionName,
                            })
                          }
                        >
                          Thu gọn{" "}
                          <ExpandLessIcon
                            color="#0B7CE4"
                            sx={{ width: "20px" }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={classes.applyButtons}>
            <Button
              sx={{
                padding: "6px 12px",
                background: "#0B7CE4",
                color: "white",
                fontWeight: "500",
                width: "100%",
                "&:hover": {
                  background: "#0B7CE4",
                },
              }}
              onClick={() => dispatch("applyFilter")}
            >
              Áp dụng
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
