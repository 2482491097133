import React from "react";
import moment from "moment";
import { IconButton } from "@mui/material";

import { getInfoProductById } from "../utils";
import DialogInsuranceInfo from "./dialogInsuranceInfo";

export default function HeaderDetail(props) {
  const { state, dispatch, classes } = props;
  const { detail, loading } = state;

  return (
    <div className={classes.containerHeaderDetail}>
      <div className="box-infor">
        <span style={{ color: "#686868" }}>Mã người được BH </span>
        <span
          style={{ color: "#4B4B4B", fontWeight: "600", textAlign: "center" }}
        >
          {detail?.certNum || ""}
        </span>
      </div>
      <div className="border" />
      <div className="box-infor">
        <span style={{ color: "#686868", width: "max-content" }}>
          Ngày tạo hồ sơ{" "}
        </span>
        <span
          style={{ color: "#4B4B4B", fontWeight: "600", textAlign: "center" }}
        >
          {detail?.createdDate
            ? moment(detail?.createdDate).format("DD/MM/YYYY")
            : ""}
        </span>
      </div>
      <div className="border" />
      <div className="box-infor">
        <span style={{ color: "#686868" }}>Nhân sự xử lý </span>
        <span
          style={{ color: "#4B4B4B", fontWeight: "600", textAlign: "center" }}
        >
          {detail?.userFullnameReview || ""}
        </span>
      </div>
      <div className="border" />
      <div className="box-infor">
        <span style={{ color: "#686868" }}>Nhân sự phê duyệt </span>
        <span
          style={{ color: "#4B4B4B", fontWeight: "600", textAlign: "center" }}
        >
          {detail?.userFullnameAssign || ""}
        </span>
      </div>
      <div className="border" />
      {/* <div className="box-insurance">
        <span style={{ color: "#686868" }}>Bảo hiểm liên quan </span>
        <span
          style={{ color: "#4B4B4B", fontWeight: "600", textAlign: "center" }}
        >
          {detail?.productIdParentList?.map((item, index) => (
            <IconButton
              key={`icon-button-insurance-${index}`}
              style={{ padding: "6px" }}
              onClick={() =>
                dispatch({
                  type: "openDialogInsuranceInfo",
                  productId: +item?.productIdParent || null,
                  urlLogo: item?.urlLogo || null,
                })
              }
            >
              <img
                src={item?.urlLogo || getInfoProductById(+item).icon}
                width="20px"
              />
            </IconButton>
          ))}
        </span>
      </div> */}
      <DialogInsuranceInfo {...{ state, dispatch }} />
    </div>
  );
}
