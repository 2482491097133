import api from "../configs/api";

const getList = () => api.apiForm("GET", "/api/v1/claim/tomato/client");

const getListClaim = (data) =>
  api.apiForm("POST", "/api/v1/claim/client/list", data);

const getDetail = (id) =>
  api.apiForm("GET", `/api/v1/claim/client/${id}/detail`);

const getListOrderForClaim = (data) =>
  api.apiForm("POST", "/api/v1/order/claim/list ", data);

function createTomato(data) {
  return api.apiForm("POST", "/api/v1/claim/client", data);
}

const getBeneficiaryList = (data) =>
  api.apiForm("POST", "/api/v1/tomato/claim/insured-persons", data);

const getOrderList = (data) =>
  api.apiForm("POST", "/api/v1/tomato/claim/insured-orders", data);

const sendEmail = async (code, data) =>
  api
    .apiForm("POST", `/api/v1/tomato/orders/${code}/send-email`, data)
    .then((res) => res)
    .catch((err) => err);

const addDocs = async (id, docs) => {
  api.apiForm("POST", `/api/v1/tomato/claim/${id}/add-docs`, docs);
};

const deleteS3Document = (data) =>
  api
    .apiForm("POST", `/api/v2/todo`, data)
    .then((res) => res)
    .catch((error) => error);

export {
  addDocs,
  getList,
  getDetail,
  getListOrderForClaim,
  createTomato,
  getListClaim,
  getBeneficiaryList,
  getOrderList,
  sendEmail,
  deleteS3Document,
};
