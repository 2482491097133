import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from "@mui/material";
import React from "react";
import { useStyles } from "../useStyles";
import { types } from "../utils";
import { isEmpty } from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddProduct(props) {
  const { open, dispatch, state } = props;
  const { data, product_parent } = state;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"xs"}
      onClose={() => dispatch({ type: "onClose", name: "product" })}
      className={classes.dialog}
    >
      <DialogTitle>Thêm sản phẩm</DialogTitle>
      <DialogContent>
        <div className="title">Chọn loại bảo hiểm</div>
        <Autocomplete
          value={data?.insurance_type || {}}
          options={types}
          getOptionLabel={(i) => i?.insurance_title || ""}
          noOptionsText={"Không tìm thấy dữ liệu"}
          renderInput={(params) => (
            <TextField {...params} placeholder="Nhập thông tin" size="small" />
          )}
          onChange={(e, value) =>
            dispatch({
              type: "onSelect",
              name: "insurance_type",
              value,
            })
          }
        />
        <div className="title">Tên sản phẩm</div>
        <Autocomplete
          value={data?.product_name || {}}
          options={product_parent}
          getOptionLabel={(i) => i?.product_title || ""}
          noOptionsText={"Không tìm thấy dữ liệu"}
          renderInput={(params) => (
            <TextField {...params} placeholder="Nhập thông tin" size="small" />
          )}
          onChange={(e, value) =>
            dispatch({
              type: "onSelect",
              name: "product_name",
              value,
            })
          }
        />
        <div className="center">
          <Button
            disabled={
              isEmpty(data?.insurance_type) || isEmpty(data?.product_name)
            }
            className="button"
            onClick={() => dispatch("addProduct")}
          >
            Thêm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
