import { useState, useEffect } from "react";
import { message } from "antd";
import { Box, Button, Grid, TextField } from "@mui/material";

import { updateProfile_v2, getProfile_v2 } from "../../../../../apis/authApis";
import { messages } from "../../../../../utils/messages";
import { isEmpty } from "lodash";

const AccountProfileDetails = ({ profiles, setProfiles, setLoading }) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    setValues({ ...profiles });
  }, [profiles]);

  const handleChange = (event) => {
    if (["dob", "address"].includes(event.target.name)) {
      setValues({
        ...values,
        extra_info: {
          ...values.extra_info,
          [event.target.name]: event.target.value,
        },
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }

    setErrors([...errors.filter((t) => t !== event.target.name)]);
    if (event.target.name === "email") {
      setErrors([
        ...errors.filter((t) => !["email", "emailValidate"].includes(t)),
      ]);
    }
  };

  const handleSubmit = () => {
    const data = {
      fullname: values.fullname,
      email: values.email,
      phone_number: values.phone_number,
      private_id: values.private_id,
      dob: values.dob,
      extra_info: {
        dob: values?.extra_info?.dob,
        address: values?.extra_info?.address,
      },
    };

    const error = [];
    isEmpty(values.fullname) && error.push("fullname");
    isEmpty(values.email) && error.push("email");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    !isEmpty(values.email) &&
      !emailRegex.test(values.email) &&
      error.push("emailValidate");

    if (error.length > 0) {
      setErrors([...error]);
      return;
    }
    setErrors([]);
    setLoading(true);
    updateProfile_v2(data)
      .then((v) => {
        message.success({
          content: messages.alert.update_account_success,
          duration: 5,
        });
        setData();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const setData = async () => {
    const dataApi = await getProfile_v2();
    if (dataApi?.data?.statusCode === 200) {
      setProfiles({ ...dataApi.data.result });
      localStorage.setItem("user", JSON.stringify({ ...dataApi.data.result }));
      // window.location.reload();
    }
    setLoading(false);
  };

  return (
    <form autoComplete="off" noValidate>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Tên tài khoản"
            name="fullname"
            onChange={handleChange}
            required
            value={values?.fullname}
            error={errors.includes("fullname")}
            helperText={
              errors.includes("fullname") ? "Vui lòng nhập thông tin" : false
            }
            variant="outlined"
            focused
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            inputProps={{ readOnly: true }}
            required
            disabled
            label="User đăng nhập"
            name="phone_number"
            InputLabelProps={{ shrink: true }}
            value={profiles?.username}
            variant="outlined"
            focused
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            error={errors.includes("email") || errors.includes("emailValidate")}
            helperText={
              errors.includes("email")
                ? "Vui lòng nhập thông tin"
                : errors.includes("emailValidate")
                ? "Email chưa hợp lệ"
                : false
            }
            onChange={handleChange}
            required
            value={values?.email}
            variant="outlined"
            focused
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Số điện thoại"
            name="phone_number"
            onChange={handleChange}
            type="number"
            value={values?.phone_number}
            variant="outlined"
            focused
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Địa chỉ liên hệ"
            name="address"
            onChange={handleChange}
            value={values?.extra_info?.address}
            variant="outlined"
            focused
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Ngày sinh"
            name="dob"
            InputLabelProps={{ shrink: true }}
            type="date"
            onChange={handleChange}
            value={values.dob || values?.extra_info?.dob}
            variant="outlined"
            // focused
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="CMND/CCCD/Hộ chiếu"
            name="private_id"
            onChange={handleChange}
            value={values?.private_id}
            variant="outlined"
            focused
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          py: 2,
          mt: "calc(100vh - 650px)",
        }}
      >
        <Button
          onClick={handleSubmit}
          sx={{ padding: "10px 60px" }}
          color="gc"
          variant="contained"
        >
          Cập nhật
        </Button>
      </Box>
    </form>
  );
};

export default AccountProfileDetails;
