import moment from "moment";
import { _formatMoney, formatDate, renderStatus } from "../utils";

export default function TableExcelReportProvider(props) {
  const { data, dataSearch, roleType } = props;

  const fromDate = !dataSearch?.from
    ? "..............."
    : moment(dataSearch?.from).format("DD/MM/YYYY");
  const toDate = !dataSearch?.to
    ? "..............."
    : moment(dataSearch?.to).format("DD/MM/YYYY");

  const getRoleType = (role) => {
    switch (role) {
      case "globalcare":
        return "Global Care";
      case "provider":
        return "Nhà bảo hiểm";
      case "merchant":
        return "Ahamove";
      default:
        break;
    }
  };
  const getCompensation = (item) => {
    return item?.elements?.find((i) => i.type_id === 203) || {};
  };

  return (
    <>
      <div style={{ display: "none" }}>
        <table
          className="collapse-border"
          id={"export-data-table-provider"}
          data-cols-width="7, 15, 40, 30, 20, 20, 20, 20, 20, 20, 20, 40, 20, 20, 30, 20, 20, 40"
        >
          <thead style={{ fontSize: "13" }}>
            <tr />
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"16"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                BÁO CÁO CHI TIẾT HỒ SƠ BỒI THƯỜNG
              </th>
            </tr>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >{`Từ ngày: ${fromDate} Đến Ngày: ${toDate} `}</th>
            </tr>
            <tr />
            <tr>
              <th
                colSpan={3}
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-wrap={true}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                {`Bên xuất báo cáo: ${getRoleType(roleType)}`}
              </th>
            </tr>
            <tr />
            <tr />
            <tr style={{ fontSize: "13" }}>
              <Th>STT</Th>
              <Th>Ngày tạo HSBT</Th>
              <Th>Mã hồ sơ bồi thường</Th>
              <Th>Họ và tên người được bảo hiểm</Th>
              <Th>SĐT NĐBH</Th>
              <Th>Mã đơn hàng vận chuyển</Th>
              <Th>Mã chuyến đi</Th>
              <Th>Loại thanh toán</Th>
              <Th>Loại hàng hoá</Th>
              <Th>Trạng thái đơn hàng</Th>
              <Th>Ngày xảy ra sự cố</Th>
              <Th>Mô tả diễn biến</Th>
              <Th>{"Giá trị hàng hóa (VNĐ)"}</Th>
              <Th>Đối tượng bảo hiểm</Th>
              <Th>Rủi ro gặp phải</Th>
              <Th>Trạng thái bồi thường</Th>
              <Th>{"Số tiền phê duyệt (VNĐ)"}</Th>
              <Th>Nội dung</Th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13" }}>
            {data.map((item, index) => {
              return (
                <tr key={index} style={{ fontSize: "13" }}>
                  <th
                    data-f-name={"Times new ro<Tdman"}
                    data-f-sz={"12"}
                    data-a-h={"center"}
                    data-b-a-s={"thin"}
                    data-a-wrap={true}
                  >
                    {index + 1}
                  </th>
                  <Td>
                    {item?.createdDate
                      ? moment(item?.createdDate).format("DD/MM/YYYY")
                      : ""}
                  </Td>
                  <Td>{item?.code || ""}</Td>
                  <Td>
                    {item?.insuredInfo?.insuredName || item?.buyerName || ""}
                  </Td>
                  <Td>
                    {item?.insuredInfo?.insuredPhone || item?.buyerPhone || ""}
                  </Td>
                  <Td>{item?.extraInfo?.orderCode || ""}</Td>
                  <Td>
                    {item?.extraInfo?.codeDestination ||
                      item?.extraInfo?.destination?.codeDestination ||
                      ""}
                  </Td>
                  <Td>
                    {item?.extraInfo?.paymentInfo?.paymentId === 1
                      ? "Ứng thu hộ COD"
                      : "Không ứng COD"}
                  </Td>
                  <Td>
                    {item?.extraInfo?.productInfo?.typeNameOfProduct || ""}
                  </Td>
                  <Td>
                    {item?.extraInfo?.statusId === 0
                      ? "Thất bại"
                      : "Thành công"}
                  </Td>
                  <Td>{formatDate(item?.extraInfo?.riskDate)}</Td>
                  <Td>{item?.extraInfo?.describe || ""}</Td>
                  {/* <Td>{`${_formatMoney(
                    item?.extraInfo?.productAmount || 0
                  )} đ`}</Td> */}
                  <th
                    data-f-name={"Times new roman"}
                    data-f-sz={"12"}
                    data-b-a-s={"thin"}
                    data-a-wrap={true}
                    data-t="n"
                  >
                    {item?.extraInfo?.productAmount || ""}
                  </th>
                  <Td>{renderInsuranceObj(item?.extraInfo?.insuranceObj)}</Td>
                  <Td>
                    {+item?.extraInfo?.typeOfAccident === 3
                      ? "Shipper chiếm dụng hàng hoá"
                      : "Shipper chiếm dụng COD"}
                  </Td>
                  <Td>{renderStatus(item.statusId)}</Td>
                  {/* <Td>
                    {getCompensation(item)?.value
                      ? `${getCompensation(item)?.value}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " đ"
                      : ""}
                  </Td> */}
                  <th
                    data-f-name={"Times new roman"}
                    data-f-sz={"12"}
                    data-b-a-s={"thin"}
                    data-a-wrap={true}
                    data-t="n"
                  >
                    {item.amount ? item.amount : ""}
                  </th>
                  <Td>{getCompensation(item)?.text || ""}</Td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
const renderInsuranceObj = (id) => {
  switch (id) {
    case 1:
      return "Hàng hoá";
    case 2:
      return "Hàng hoá có hoá đơn";
    case 3:
      return "Hàng hoá không có hoá đơn";
    case 4:
      return "Tài liệu";
    default:
      return "";
  }
};

const Th = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-h={"center"}
      data-a-wrap={true}
      data-f-bold={true}
    >
      {props.children}
    </th>
  );
};

const Td = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-wrap={true}
    >
      {props.children}
    </th>
  );
};
