import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
export default function TomatoArccodion(props) {
  const [expanded, setExpanded] = React.useState(props.expanded || false);
  const handleChange = () => (event, newExpanded) => {
    setExpanded(!expanded);
  };
  return (
    <Accordion sx={arcdWrapper} className={[props.halfShow ? "half-show" : '']} expanded={expanded}>
      <AccordionSummary
        onClick={handleChange()}
        sx={arcdHeader}
        expandIcon={<ExpandMore />}
        id="panelInsurInforHeader"
      >
        <Typography>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={arcdBody}>{props.children}</AccordionDetails>
    </Accordion>
  );
}

const arcdWrapper = {
  borderRadius: "0 0 10px 10px !important",
  boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.08)",

  "&.half-show .MuiCollapse-root": {
    minHeight: '78px !important',
    visibility: "visible !important"
  }
};

const arcdHeader = {
  background: "#0B3C8A",
  color: "#ffffff",
  borderRadius: "10px",
  minHeight: "45px !important",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#FFFFFF",
    transform: "rotate(270deg)",
    "&.Mui-expanded": {
      transform: "rotate(359deg)",
    },
  },
  "& .MuiAccordionSummary-content": {
    margin: "10px 0 !important",
  },
};

const arcdBody = {
  borderRadius: "0 0 10px 10px",
};
