import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoginSidebar } from "./login-sidebar";

const LoginLayoutRoot = styled("div")(() => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
}));

export default function LoginLayout(props) {
  const { children } = props;

  return (
    <>
      <LoginLayoutRoot>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <LoginSidebar />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <ShadowCenter />
              <ShadowTop /> */}
              {children}
            </Grid>
          </Grid>
        </Box>
      </LoginLayoutRoot>
    </>
  );
}
