import {
  Autocomplete,
  Button,
  Grid,
  LinearProgress,
  Paper,
  TableCell,
  TableContainer,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import useReducer from "../../../../library/hooks/useReducer";
import CommonTable from "../../../../library/layout/commonTable";
import AddDialog from "../components/AddDialog";
import Loading from "../components/Loading";
import detailReducer from "../useReducer/detailReducer";
import { getParamUrlByKey, types } from "../utils";

import AddIcon from "../assets/add-circle.svg";
import EmptyImage from "../assets/empty-image.svg";
import ImgUpload from "../assets/upload-image.svg";
import { useStyles } from "../useStyles";

const initialState = {
  rows: [],
  data: { packages: [] },
  open: false,
  loading: false,
  scrolling: false,
  imgLoading: false,
};

function ProductDetail() {
  const history = useHistory();
  const id = getParamUrlByKey("id");
  const classes = useStyles();
  const ref = useRef();

  const [state, dispatch] = useReducer({
    reducer: detailReducer,
    name: "detailReducer",
    initialState,
    history,
    id,
  });

  useEffect(async () => {
    await dispatch("initial");
    if (id) {
      dispatch("getDetail");
    }
  }, []);

  const onClickDiv = (e) => {
    ref.current?.click();
  };

  const { data, loading, imgLoading } = state;

  return (
    <>
      <div className={classes.headerBar}>
        Gói sản phẩm / <b>Tạo sản phẩm mới</b>
      </div>
      <Loading loading={loading} />
      <Grid container className={classes.create}>
        <Grid item xs={12} sm={4.5} className="info">
          <div className="title mt-5">Khai báo sản phẩm gốc</div>
          <div className="center">
            {data?.logo ? (
              <img src={data?.logo} alt="upload" className="logo" />
            ) : (
              <img src={ImgUpload} alt="upload" className="logo" />
            )}
            <input
              id="uploadFile"
              type="file"
              onChange={(e) => dispatch({ type: "upLoadFile", e })}
              ref={ref}
              hidden
            />
            {imgLoading && (
              <div className="progress">
                <LinearProgress />
              </div>
            )}
            <Button className="button mt-15" onClick={onClickDiv}>
              Upload logo
            </Button>
          </div>
          <div className="label">Chọn loại bảo hiểm</div>
          <Autocomplete
            value={data?.type || null}
            options={types}
            getOptionLabel={(i) => i?.insurance_title || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Nhập thông tin"
                size="small"
              />
            )}
            onChange={(e, value) =>
              dispatch({
                type: "onSelect",
                value,
              })
            }
          />
          <div className="label">Tên sản phẩm</div>
          <TextField
            fullWidth
            placeholder="Nhập thông tin"
            value={data?.title || ""}
            className="textfield"
            onChange={(e) =>
              dispatch({
                type: "onChange",
                value: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={7} className="benefit">
          <div className="row">
            <div className="title">Chọn mã quyền lợi sản phẩm</div>
            <Button className="button" onClick={() => dispatch("openDialog")}>
              <img src={AddIcon} alt="add-ic" className="icon" />
              Thêm mã quyển lợi
            </Button>
          </div>
          <TableContainer className="table-scroll" component={Paper}>
            <div className={classes.headerTable}>
              <div className="code">Mã quyền lợi</div>
              <div>Tên quyền lợi</div>
            </div>
            {data?.packages.length > 0 ? (
              <CommonTable
                classes={classes}
                rows={data?.packages || []}
                columns={columns}
              />
            ) : (
              <img src={EmptyImage} alt="empty" className="empty" />
            )}
          </TableContainer>
        </Grid>
        <Grid item xs={12} className="footer">
          <Button className="button mt-15" onClick={() => dispatch("onSave")}>
            {id ? "Cập nhật" : "Lưu lại"}
          </Button>
        </Grid>
        <AddDialog state={state} dispatch={dispatch} classes={classes} />
      </Grid>
    </>
  );
}

export default ProductDetail;

const columns = [
  {
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst code"
            : item.isSecond
            ? "isSecond code"
            : "code"
        }
      >
        {item?.code_benefit || ""}
      </TableCell>
    ),
  },
  {
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst name-benefits"
            : item.isSecond
            ? "isSecond name-benefits"
            : "name-benefits"
        }
      >
        {item?.title || ""}
      </TableCell>
    ),
  },
];
