import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TableCell,
  TableContainer,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useRef } from "react";
import useReducer from "../../../library/hooks/useReducer";
import CommonTable from "../../../library/layout/commonTable";
import benefitCodeReducer from "./benefitCodeReducer";
import AddDialog from "./components/AddDialog";
import { useStyles } from "./useStyles";
import LoadingMui from "../../../ui/LoadingMui";

import AddIcon from "./assets/add-icon.svg";
import SearchIcon from "./assets/search-icon.svg";
import editIcon from "../../../assets/health/edit.svg";

const initialState = {
  typeCode: [],
  rows: [],
  errors: [],
  data: {},
  open: false,
  loading: false,
  saving: false,
  isInput: false,
  scrolling: false,
};

const code_rules = [
  {
    id: 1,
    title: "Giới hạn/ năm",
  },
  {
    id: 2,
    title: "Giới hạn/ ngày",
  },
  {
    id: 3,
    title: "Giới hạn/ lần",
  },
  // {
  //   id: 4,
  //   title: "Trợ cấp/ ngày",
  // },
  // {
  //   id: 5,
  //   title: "Trợ cấp/ lần",
  // },
  // {
  //   id: 6,
  //   title: "Trợ cấp/ năm",
  // },
  // {
  //   id: 7,
  //   title: "Trợ cấp lương/ ngày",
  // },
  // {
  //   id: 8,
  //   title: "Trợ cấp lương/ tháng",
  // },
];

const BenefitCode_v2 = () => {
  const classes = useStyles();
  const tableRef = useRef();
  let flag = false;

  const [state, dispatch] = useReducer({
    reducer: benefitCodeReducer,
    name: "benefitCodeReducer_v2",
    initialState,
  });

  useEffect(() => {
    dispatch("getList");
    return () => delete window?.reducers?.decentralizaReducer;
  }, []);

  const { rows, loading, scrolling } = state;

  const onScroll = () => {
    if (tableRef.current) {
      const scrollDiv = tableRef.current;
      const lastItem = scrollDiv.querySelector(
        "#tableScroll .scroll-item:last-child"
      );
      const { offsetTop, offsetHeight } = lastItem;
      if (
        Math.abs(offsetTop - scrollDiv.scrollTop) <
        offsetHeight + scrollDiv.offsetHeight
      ) {
        dispatch("fetchData");
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className="header">Quản lý mã nghiệp vụ</div>
      <div className="search">
        <div className="wrapper">
          <TextField
            fullWidth
            className="textfield select-type-ins"
            defaultValue={1}
            disabled
            select
          >
            {[{ title: "BH con người", id: 1 }].map((i, index) => (
              <MenuItem key={`select_type_insurance_${index}`} value={i.id}>
                {i.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            placeholder="Tìm kiếm mã quyền lợi"
            className="textfield"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="search-ic" />
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch({
                  type: "getList",
                  value: e.target.value,
                });
                flag = true;
              }
            }}
            onChange={debounce((e) => {
              if (!flag) {
                dispatch({
                  type: "getList",
                  value: e.target.value,
                });
              }
              flag = false;
            }, 1800)}
          />
        </div>
        <Button className="button" onClick={() => dispatch("onOpen")}>
          <img src={AddIcon} alt="add-ic" className="icon" />
          Thêm mã
        </Button>
      </div>
      <TableContainer
        id="tableScroll"
        ref={tableRef}
        onScroll={debounce(onScroll, 800)}
        className="table-scroll"
        component={Paper}
      >
        <div className={classes.headerTable}>
          <div className="code">Mã quyền lợi</div>
          <div>Tên quyền lợi</div>
        </div>
        <CommonTable
          classes={classes}
          rows={rows}
          columns={columns}
          empty={"Không có thông tin. Vui lòng kiểm tra lại"}
          data={{ dispatch }}
        />
        {scrolling && (
          <div className="scrolling">
            <CircularProgress size={25} sx={{ mb: 2, color: "#0ABAFD" }} />
          </div>
        )}
      </TableContainer>
      <AddDialog state={state} dispatch={dispatch} />
      <LoadingMui loading={loading} />
    </div>
  );
};

export default BenefitCode_v2;

const columns = [
  {
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst code"
            : item.isSecond
            ? "isSecond code"
            : "code"
        }
      >
        {item?.code_benefit || ""}
      </TableCell>
    ),
  },
  {
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst name-benefits"
            : item.isSecond
            ? "isSecond name-benefits"
            : "name-benefits"
        }
      >
        <>
          <div>{item?.title || ""}</div>
          <div
            style={{
              color: "#686868",
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: "400",
            }}
          >
            {"Loại: " +
              ((
                code_rules?.find(
                  (i) => i.id === item?.config_benefit?.code_rules
                ) || {}
              )?.title || "")}
          </div>
        </>
      </TableCell>
    ),
  },
  {
    renderCell: ({ rowIdx, item, data }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst name-benefits edit"
            : item.isSecond
            ? "isSecond name-benefits edit"
            : "name-benefits edit"
        }
      >
        {
          <IconButton
            onClick={() => {
              data.dispatch({ type: "onOpen", isEdit: true, item, rowIdx });
            }}
          >
            <img src={editIcon} />
          </IconButton>
        }
      </TableCell>
    ),
  },
];
