import "../styles/index.css";

import {
  Box,
  Container,
  Grid,
  Typography,
  Modal as ModalV2,
} from "@mui/material";

import { Button, message, Tooltip } from "antd";

import {
  AccidentIcon,
  AddressIcon,
  BankIcon,
  BankIdIcon,
  BirthCakeIcon,
  BlueDotIcon,
  CalendarIcon,
  CircleInnerUserIcon,
  DownloadFileIcon,
  EmailIcon,
  FileTextIcon,
  HospitalIcon,
  MobilePhoneIcon,
  PrivateIdIcon,
  RelationshipIcon,
  StatusIcon,
  TotalBenefitIcon,
  TotalInsureFeeIcon,
  UpIcon,
  UserIcon,
} from "../components/icon/icon";

import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { formatMoney } from "../utils/money";
import { useRouteMatch } from "react-router-dom";
import { addFeedBack, getClaimDetail, getListHistory } from "../api/claim";
import RenderAccidentType from "../components/views/TypeAccident";
import {
  ACCIDENT_LEVEL,
  ACCIDENT_TYPE,
  STATUS,
  TREATMENT_TYPE,
} from "../utils/type";
import Collapse from "../components/collapse";

function Step({ detail }) {
  const [open, setOpen] = useState(true);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <div style={{ display: "flex" }}>
          <BlueDotIcon style={{ marginRight: 5 }} />
          <div style={{ marginTop: -4, fontWeight: 700 }}>
            <span style={{ color: "#FB7800" }}> Mã hồ sơ: </span>
            <span style={{ color: "#2A3790" }}> {detail?.code || "N/A"} </span>
          </div>
        </div>
        <div style={{ marginTop: -15 }}>
          <UpIcon
            style={{ transform: open ? "rotate(0deg)" : "rotate(180deg)" }}
          />
        </div>
      </div>

      <div
        style={{
          transition: "0.3s",
          opacity: open ? 1 : 0,
          height: !open ? 0 : "auto",
          minHeight: !open ? 0 : 250,
          borderLeft: "2px solid #2A3790",
          marginLeft: 8,
          marginTop: -8,
          padding: 10,
        }}
      >
        <div style={{ margin: "5px 0" }}>
          <CalendarIcon style={{ marginRight: 5 }} />
          <span
            style={{
              verticalAlign: "top",
              fontSize: 14,
              fontWeight: "bold",
              color: "#2A3790",
            }}
          >
            Ngày tạo hồ sơ:
          </span>
          <span style={{ verticalAlign: "top", fontSize: 14 }}>
            {" "}
            {moment(detail?.claim_created).format("DD/MM/YYYY - hh:mm:ss")}
          </span>
        </div>

        <div style={{ margin: "5px 0" }}>
          <CalendarIcon style={{ marginRight: 5 }} />
          <span
            style={{
              verticalAlign: "top",
              fontSize: 14,
              fontWeight: "bold",
              color: "#2A3790",
            }}
          >
            Ngày cập nhật:
          </span>
          <span style={{ verticalAlign: "top", fontSize: 14 }}>
            {" "}
            {moment(detail?.claim_updated).format("DD/MM/YYYY - hh:mm:ss")}
          </span>
        </div>

        <div style={{ margin: "5px 0" }}>
          <StatusIcon style={{ marginRight: 5 }} />
          <span
            style={{
              verticalAlign: "top",
              fontSize: 14,
              fontWeight: "bold",
              color: "#2A3790",
            }}
          >
            Trạng thái bồi thường:
          </span>
          <span
            style={{
              verticalAlign: "top",
              fontSize: 14,
              color: STATUS.filter(
                (item) => item.value === +detail.status_id
              )[0]?.color,
            }}
          >
            {" "}
            {
              STATUS.filter((item) => item.value === +detail.status_id)[0]
                ?.label
            }
          </span>
        </div>

        <div style={{ margin: "5px 0" }}>
          <CircleInnerUserIcon style={{ marginRight: 5 }} />
          <span
            style={{
              verticalAlign: "top",
              fontSize: 14,
              fontWeight: "bold",
              color: "#2A3790",
            }}
          >
            Người xử lý hồ sơ:
          </span>
          <span
            style={{
              verticalAlign: "top",
              fontSize: 14,
              textTransform: "capitalize",
            }}
          >
            {" "}
            {detail.assigner}
          </span>
        </div>

        <div
          style={{
            margin: "5px 0",
            display: "grid",
            gap: 5,
            gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
          }}
        >
          <div style={{ gridColumn: "span 6 / span 6" }}>
            <FileTextIcon style={{ marginRight: 5 }} />
            <span
              style={{
                verticalAlign: "top",
                fontSize: 14,
                fontWeight: "bold",
                color: "#2A3790",
              }}
            >
              Nội dung:
            </span>
            <div
              style={{
                verticalAlign: "top",
                fontSize: 14,
                textTransform: "capitalize",
                border: "1px solid #2A3790",
                minHeight: 60,
                borderRadius: 8,
                padding: 10,
                color: "#343A40",
              }}
            >
              {detail.text}
            </div>
          </div>

          <div style={{ gridColumn: "span 6 / span 6" }}>
            <FileTextIcon style={{ marginRight: 5 }} />
            <span
              style={{
                verticalAlign: "top",
                fontSize: 14,
                fontWeight: "bold",
                color: "#2A3790",
              }}
            >
              Từ chối:
            </span>
            <div
              style={{
                verticalAlign: "top",
                fontSize: 14,
                textTransform: "capitalize",
                border: "1px solid #2A3790",
                minHeight: 60,
                borderRadius: 8,
                padding: 10,
                color: "#343A40",
              }}
            >
              {detail.note}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function RecoupDetail({ details }) {
  const link = useRouteMatch();
  const [showAccidentDetail, setShowAccidentDetail] = useState(false);
  const [showDiagnosticDetail, setShowDiagnosticDetail] = useState(false);
  const [elements, setElements] = useState([]);
  const [render, setRender] = useState(true);
  const [data, setData] = useState(null);
  const [totalBenefit, setTotalBenefit] = useState(0);
  const [deadBenefit, setDeadBenefit] = useState(0);
  const [overPerm, setOverPerm] = useState(0);
  const [underPerm, setUnderPerm] = useState(0);
  const [tempTotal, setTempTotal] = useState(0);
  const [usedTotal, setUsedTotal] = useState(0);
  const [medicalTotal, setMedicalTotal] = useState(0);
  const [deadOverTempTotal, setDeadOverTempTotal] = useState(0);

  const convertAccidentTypeAmount = () => {
    setDeadBenefit(
      details?.extraInfo?.approvedAmount?.reduce((acc, cur) => {
        if (+cur.accident_type === 1) return acc + +cur.approved_amount;

        return acc;
      }, 0)
    );

    setOverPerm(
      details?.extraInfo?.approvedAmount?.reduce((acc, cur) => {
        if (+cur.accident_type === 2) return acc + +cur.approved_amount;

        return acc;
      }, 0)
    );

    setUnderPerm(
      details?.extraInfo?.approvedAmount?.reduce((acc, cur) => {
        if (+cur.accident_type === 3) return acc + +cur.approved_amount;

        return acc;
      }, 0)
    );

    setTempTotal(
      details?.extraInfo?.approvedAmount?.reduce((acc, cur) => {
        if (+cur.accident_type === 4) return acc + +cur.approved_amount;

        return acc;
      }, 0)
    );

    setUsedTotal(
      details?.extraInfo?.approvedAmount?.reduce((acc, cur) => {
        return acc + +cur.approved_amount;
      }, 0)
    );
  };

  const getTotalBenefit = () => {
    setTotalBenefit(
      details?.extraInfo?.activeOrders?.reduce((cur, acc) => {
        return (
          cur +
          +acc.benefit -
          +details?.extraInfo?.approvedAmount
            ?.filter((item) => +item?.order_id === +acc?.id)
            ?.reduce((cur1, acc1) => {
              return cur1 + +acc1.approved_amount;
            }, 0)
        );
      }, 0)
    );

    let tempMedicalTotal = details?.extraInfo?.activeOrders?.reduce(
      (cur, acc) => {
        let tempMedical =
          +acc.medical_fees -
          +details?.extraInfo?.approvedAmount
            ?.filter((item) => +item.order_id === +acc.id)
            .reduce((current, grow) => {
              if (+grow.accident_type === 4) {
                return current + +grow.approved_amount;
              }
              return current;
            }, 0);

        let notTempMedical =
          +acc.benefit -
          +details?.extraInfo?.approvedAmount
            ?.filter((item) => +item.order_id === +acc.id)
            .reduce((current, grow) => {
              return current + +grow.approved_amount;
            }, 0);

        return cur + Math.min(tempMedical, notTempMedical);
      },
      0
    );

    setMedicalTotal(tempMedicalTotal);

    setDeadOverTempTotal(
      details?.extraInfo?.activeOrders?.reduce((cur, acc) => {
        return (
          cur +
          +acc.benefit -
          +details?.extraInfo?.approvedAmount
            ?.filter((item) => +item?.order_id === +acc?.id)
            ?.reduce((cur1, acc1) => {
              return cur1 + +acc1.approved_amount;
            }, 0)
        );
      }, 0)
    );
  };

  useEffect(() => {
    if (!details) return;

    setData(details);

    return () => {};
  }, [details]);

  useEffect(() => {
    if (!data) return;

    getTotalBenefit();
    convertAccidentTypeAmount();
  }, [data]);

  const feedback = async (type, data) => {
    await addFeedBack(data?.id, data);
  };

  const getHistory = async () => {
    const result = await getListHistory(details?.id);
    if (result instanceof Error) return message.error("Lỗi khi lấy dữ liệu");

    setElements(result.data?.result || []);
  };

  useEffect(() => {
    if (!details) return;

    async function callApi() {
      await getHistory();
    }

    callApi();
  }, [details]);

  const renderStatus = (statusId) => {
    let status = STATUS.filter((item) => +item.value === statusId)[0];

    return { colorCode: status?.color, statusText: status?.text };
  };

  return (
    <>
      {render && (
        <Container maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontWeight={500} fontSize={16} color="#2A3790">
                Kiểm tra xử lý hồ sơ /{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {data?.insuredInfo?.insuredName || "N/A"}
                </span>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={500} fontSize={18}>
                <span>Trạng thái:</span>
                <span style={{ color: renderStatus(data?.statusId).colorCode }}>
                  {" "}
                  {
                    STATUS.filter((item) => +item.value === data?.statusId)[0]
                      ?.label
                  }
                </span>
              </Typography>
            </Grid>

            <Grid item xs={4} style={{ minHeight: 950 }}>
              <div
                style={{
                  background: "#EBFFFE",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                  padding: 10,
                }}
              >
                <Collapse title="Thông tin hợp đồng">
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <TotalBenefitIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Tổng quyền lợi bảo hiểm
                      </span>
                    </div>
                    <div>
                      {formatMoney(totalBenefit)}
                      <sup>VNĐ</sup>
                    </div>
                  </div>

                  <div>
                    <ol type="number" style={{ marginLeft: 40 }}>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#2A3790",
                        }}
                      >
                        <span>Tử vong</span>
                        <span style={{ color: "initial" }}>
                          {formatMoney(deadOverTempTotal)}
                          <sup>VNĐ</sup>
                        </span>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#2A3790",
                        }}
                      >
                        <span> Thương tật vĩnh viễn</span>
                        <span style={{ color: "initial" }}>
                          {formatMoney(deadOverTempTotal)}
                          <sup>VNĐ</sup>
                        </span>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#2A3790",
                        }}
                      >
                        <span>Chi phí y tế</span>
                        <span style={{ color: "initial" }}>
                          {formatMoney(medicalTotal)}
                          <sup>VNĐ</sup>
                        </span>
                      </li>
                    </ol>
                  </div>

                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <TotalInsureFeeIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Quyền lợi đã chi trả
                      </span>
                    </div>

                    <div>
                      {formatMoney(usedTotal)}
                      <sup>VNĐ</sup>
                    </div>
                  </div>
                  <div>
                    <ol type="number" style={{ marginLeft: 40 }}>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#2A3790",
                        }}
                      >
                        <span>Tử vong:</span>
                        <span style={{ color: "initial" }}>
                          {formatMoney(deadBenefit)}
                          <sup>VNĐ</sup>
                        </span>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#2A3790",
                        }}
                      >
                        <span>TTVV từ 50%</span>
                        <span style={{ color: "initial" }}>
                          {formatMoney(overPerm)}
                          <sup>VNĐ</sup>
                        </span>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#2A3790",
                        }}
                      >
                        <span>TTVV dưới 50%</span>
                        <span style={{ color: "initial" }}>
                          {formatMoney(underPerm)}
                          <sup>VNĐ</sup>
                        </span>
                      </li>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#2A3790",
                        }}
                      >
                        <span>Thương tật tạm thời</span>
                        <span style={{ color: "initial" }}>
                          {formatMoney(tempTotal)}
                          <sup>VNĐ</sup>
                        </span>
                      </li>
                    </ol>
                  </div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <UserIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Họ và tên
                      </span>
                    </div>

                    <div>{details?.insuredInfo?.insuredName}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <BirthCakeIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Năm sinh
                      </span>
                    </div>

                    <div>{details?.insuredInfo?.insuredDob || "N/A"}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <PrivateIdIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        CMND/CCCD
                      </span>
                    </div>

                    <div>{details?.insuredInfo?.insuredPrivateId || "N/A"}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <MobilePhoneIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Số điện thoại
                      </span>
                    </div>

                    <div>{details?.insuredInfo?.insuredPhoneNumber}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <EmailIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Email
                      </span>
                    </div>

                    <div>{details?.insuredInfo?.insuredEmail || "N/A"}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <AddressIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Địa chỉ
                      </span>
                    </div>

                    <div>{details?.insuredInfo?.insuredAddress || "N/A"}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>
                </Collapse>

                <Collapse title="Hồ sơ bồi thường">
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <FileTextIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Loại tai nạn
                      </span>
                    </div>

                    <div>
                      {
                        ACCIDENT_TYPE.filter(
                          (item) =>
                            item.value === data?.extraInfo?.accident?.type
                        )[0]?.label
                      }
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <FileTextIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Mức độ tai nạn
                      </span>
                    </div>

                    <div>
                      {
                        ACCIDENT_LEVEL.filter(
                          (item) =>
                            item.value === data?.extraInfo?.accident?.level
                        )[0]?.label
                      }
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  {TREATMENT_TYPE.filter(
                    (item) => item.value === data?.insuredInfo?.treatmentType
                  )[0] && (
                    <>
                      <div
                        style={{
                          marginTop: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <FileTextIcon style={{ marginRight: 5 }} />
                          <span
                            style={{
                              verticalAlign: "top",
                              color: "#2A3790",
                              fontSize: 14,
                            }}
                          >
                            Loại hình điều trị
                          </span>
                        </div>

                        <div>
                          {TREATMENT_TYPE.filter(
                            (item) =>
                              item.value === data?.insuredInfo?.treatmentType
                          )[0]?.label || "N/A"}
                        </div>
                      </div>
                      <div style={{ background: "#D2D2D2", height: 1 }}></div>
                    </>
                  )}

                  {data?.insuredInfo?.hospitalizeDate && (
                    <>
                      <div
                        style={{
                          marginTop: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <CalendarIcon style={{ marginRight: 5 }} />
                          <span
                            style={{
                              verticalAlign: "top",
                              color: "#2A3790",
                              fontSize: 14,
                            }}
                          >
                            Ngày nhập viện
                          </span>
                        </div>

                        <div>
                          {moment(
                            data?.insuredInfo?.hospitalizeDate,
                            "YYYY-MM-DD"
                          ).isValid()
                            ? moment(
                                data?.insuredInfo?.hospitalizeDate,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : "N/A"}
                        </div>
                      </div>
                      <div style={{ background: "#D2D2D2", height: 1 }}></div>
                    </>
                  )}

                  {data?.insuredInfo?.dischargeDate && (
                    <>
                      <div
                        style={{
                          marginTop: 10,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <CalendarIcon style={{ marginRight: 5 }} />
                          <span
                            style={{
                              verticalAlign: "top",
                              color: "#2A3790",
                              fontSize: 14,
                            }}
                          >
                            Ngày xuất viện
                          </span>
                        </div>

                        <div>
                          {moment(
                            data?.insuredInfo?.dischargeDate,
                            "YYYY-MM-DD"
                          ).isValid()
                            ? moment(
                                data?.insuredInfo?.dischargeDate,
                                "YYYY-MM-DD"
                              ).format("DD/MM/YYYY")
                            : "N/A"}
                        </div>
                      </div>
                      <div style={{ background: "#D2D2D2", height: 1 }}></div>
                    </>
                  )}

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CalendarIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Ngày tai nạn
                      </span>
                    </div>

                    <div>
                      {moment(
                        data?.insuredInfo?.accidentDate,
                        "YYYY-MM-DD"
                      ).isValid()
                        ? moment(
                            data?.insuredInfo?.accidentDate,
                            "YYYY-MM-DD"
                          ).format("DD/MM/YYYY")
                        : "N/A"}
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <AccidentIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Nơi xảy ra tai nạn
                      </span>
                    </div>

                    <div>{data?.insuredInfo?.accidentAt}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>
                </Collapse>

                <Collapse title={"Diễn biến tai nạn"}>
                  <div
                    style={{
                      position: "relative",
                      border: "1px solid #F1F1F1",
                      padding: "9px 17px",
                      background: "white",
                      borderRadius: 8,
                      maxHeight: !showAccidentDetail ? "60px" : "none",
                      whiteSpace: showAccidentDetail ? "normal" : "nowrap",
                      minHeight: 60,
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 15,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {data?.insuredInfo?.discription}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        cursor: "pointer",
                        color: "#0044CC",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        setShowAccidentDetail((prev) => !prev);
                      }}
                    >
                      {showAccidentDetail ? "Ẩn bớt" : "Xem thêm"}
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <HospitalIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Nơi điều trị
                      </span>
                    </div>

                    <Tooltip
                      placement="top"
                      title={data?.insuredInfo?.text}
                      arrowPointAtCenter
                    >
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: "150px",
                          cursor: "pointer",
                        }}
                      >
                        {data?.insuredInfo?.text}
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <TotalInsureFeeIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Tổng chi phí điều trị
                      </span>
                    </div>

                    <div>
                      {formatMoney(data?.insuredInfo?.treatmentPrice)}
                      <sup>VNĐ</sup>
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>
                </Collapse>

                <Collapse title="Chẩn đoán bệnh">
                  <div
                    style={{
                      position: "relative",
                      border: "1px solid #F1F1F1",
                      padding: "9px 17px",
                      background: "white",
                      borderRadius: 8,
                      maxHeight: !showDiagnosticDetail ? "60px" : "none",
                      whiteSpace: showDiagnosticDetail ? "normal" : "nowrap",
                      minHeight: 60,
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 15,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {data?.insuredInfo?.diagnostic}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        cursor: "pointer",
                        color: "#0044CC",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        setShowDiagnosticDetail((prev) => !prev);
                      }}
                    >
                      {showDiagnosticDetail ? "Ẩn bớt" : "Xem thêm"}
                    </div>
                  </div>
                </Collapse>

                <Collapse title="Thông tin người thụ hưởng">
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CircleInnerUserIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Chủ tài khoản
                      </span>
                    </div>

                    <div
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "150px",
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.extraInfo?.beneficiary?.fullname}
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", maxWidth: 180 }}>
                      <RelationshipIcon style={{ marginRight: 5 }} />
                      <div
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                          textTransform: "capitalize",
                        }}
                      >
                        Mối quan hệ với người được bảo hiểm
                      </div>
                    </div>

                    <div style={{}}>{data?.extraInfo?.relationship}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <BankIdIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Số tài khoản
                      </span>
                    </div>

                    <Tooltip
                      placement="top"
                      title={data?.extraInfo?.beneficiary?.bank?.id}
                      arrowPointAtCenter
                    >
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: "150px",
                        }}
                      >
                        {data?.extraInfo?.beneficiary?.bank?.id}
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <BankIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Ngân hàng
                      </span>
                    </div>

                    <Tooltip
                      placement="top"
                      title={data?.extraInfo?.beneficiary?.bank?.name}
                      arrowPointAtCenter
                    >
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: "150px",
                          cursor: "pointer",
                        }}
                      >
                        {data?.extraInfo?.beneficiary?.bank?.name}
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <BankIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Chi nhánh
                      </span>
                    </div>

                    <Tooltip
                      placement="top"
                      title={data?.extraInfo?.beneficiary?.bank?.branch}
                      arrowPointAtCenter
                    >
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: "150px",
                        }}
                      >
                        {data?.extraInfo?.beneficiary?.bank?.branch}
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>
                </Collapse>
              </div>
            </Grid>

            <Grid
              container
              item
              spacing={2}
              xs={8}
              style={{
                maxHeight: 800,
              }}
            >
              <Grid item xs={6}>
                <div
                  style={{
                    background: "#FFFFE7",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    minHeight: 580,
                    maxHeight: 580,
                    overflowY: "auto",
                    padding: 10,
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography color="#2A3790" fontWeight={500} fontSize={20}>
                      Chứng từ
                    </Typography>
                    <div style={{ fontWeight: 500, fontSize: 20 }}>
                      <span style={{ color: "#2A3790" }}> {data?.code}</span>
                    </div>
                  </div>
                  {<RenderAccidentType data={data} />}
                </div>
              </Grid>

              <Grid
                item
                xs={6}
                justifyContent={"space-between"}
                display="flex"
                flexDirection={"column"}
              >
                <div
                  style={{
                    borderRadius: 10,
                    background: "#F2FDF4",
                    padding: 10,
                    minHeight: 580,
                    maxHeight: 580,
                    overflowY: "auto",
                    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Typography
                    color="#2A3790"
                    fontWeight={500}
                    fontSize={20}
                    marginBottom={1}
                  >
                    Lịch sử bồi thường
                  </Typography>
                  {elements?.map((item, index) => {
                    return <Step detail={item} key={index} />;
                  })}
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button
                    size="large"
                    icon={<DownloadFileIcon />}
                    style={{
                      width: 180,
                      borderRadius: 20,
                      background: "#D4F1D8",
                      outline: "none",
                      border: "none",
                      boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25);",
                    }}
                  >
                    <span style={{ verticalAlign: "super" }}>
                      Lưu trữ hồ sơ
                    </span>
                  </Button>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <Button
                size="large"
                block
                style={{
                  borderRadius: 16,
                  background: "#2A3790",
                  background: "white",
                  border: "1px solid #2A3790",
                }}
                onClick={() => {
                  window.history.back();
                }}
              >
                Quay lại
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
