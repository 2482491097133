import { message } from "antd";
import { get } from "lodash";
import {
  createProvider,
  getProviders,
  uploadImage,
} from "../../../../apis/providerApis";
import * as PATH from "../../../../configs/routesConfig";

const managementReducer = ({
  initialState,
  state,
  action,
  dispatch,
  history,
}) => {
  const cases = {
    getList: async () => {
      const { data } = state;
      const { value } = action;
      const res = {};
      const p = {
        keyword: value || "",
        limit: 20,
        offset: 0,
      };
      dispatch("onLoading");
      const result = await getProviders(p);
      if (result instanceof Error) {
        message.error("Lỗi lấy danh sách", 2);
        return { loading: false };
      }
      res.rows = get(result, "data.result.data") || [];
      res.totalRows = get(result, "data.result.total") || 0;
      res.pageTable = {
        count: Math.ceil(res.totalRows / 20),
        page: 0,
        rowsPerPage: 20,
      };
      data.search = value || "";
      return { ...res, data, loading: false };
    },
    upLoadFile: async () => {
      const { e } = action;
      const { data } = state;

      if (e.target.files.length < 1) {
        return;
      }
      const file = e.target.files[0];
      const formdata = new FormData();
      formdata.append("document", file);

      dispatch("loadImg");
      const dataApi = await uploadImage(formdata);
      const item = get(dataApi, "data.result[0]") || null;
      data.logo = item?.path || null;
      return { data, imgLoading: false };
    },
    onChange: () => {
      const { data } = state;
      const { name, value } = action;
      data[name] = value;
      return { data };
    },
    validate: () => {
      const { data } = state;
      const errors = [];
      const keys = ["title", "taxCode", "phone", "address", "email"];
      keys.forEach((i) => {
        !data[i] && errors.push(i);
      });
      if (errors.length === 0) {
        dispatch("onSave");
      }
      return { errors };
    },
    onSave: async () => {
      const { data } = state;
      const d = {
        title: data?.title,
        url_logo: data?.logo,
        extraInfo: {
          taxCode: data?.taxCode,
          phone: data?.phone,
          address: data?.address,
          email: data?.email,
        },
      };
      dispatch("onSaving");
      const res = await createProvider(d);
      if (res instanceof Error) {
        message.error("Lỗi tạo mã quyền lợi", 2);
        return { saving: false };
      }
      message.success("Tạo mã quyền lợi thành công.!", 2);
      dispatch("getList");
      return { data: {}, saving: false, open: false };
    },
    changePage: async () => {
      const { item } = action.data;
      history.push(`${PATH.AD_PROVIDER_DETAIL}?id=${item.id}`);
    },
    openDialog: () => ({ open: true }),
    onClose: () => ({ open: false, data: {}, errors: [] }),
    onLoading: () => ({ loading: true }),
    loadImg: () => ({ imgLoading: true }),
    onSaving: () => ({ saving: true }),
    onPaging: async () => {
      const { data } = state;
      const { page, rowsPerPage } = action;
      const res = {};
      const p = {
        keyword: data?.search || "",
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      };
      dispatch("onLoading");
      const result = await getProviders(p);
      if (result instanceof Error) {
        message.error(result.response?.data?.message || "Lỗi lấy danh sách", 2);
        return { loading: false };
      }
      res.rows = get(result, "data.result.data") || [];
      res.totalRows = get(result, "data.result.total") || 0;
      res.pageTable = {
        count: Math.ceil(res.totalRows / 20),
        page,
        rowsPerPage,
      };

      return { ...res, loading: false };
    },
  };
  return cases[action?.type];
};
export default managementReducer;
