import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";
import React, { useRef } from "react";
import uploadIcon from "../assets/upload.svg";
import { createGCNStyles } from "../styles/styleCreateGCN";
import ImportTable from "./ImportTable";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ImportExcel(props) {
  const { state, dispatch } = props;
  const { dialogAddUser, importList, dragActive, uploading, saving } = state;
  const classes = createGCNStyles();
  const inputRef = useRef(null);

  const onBtnClick = () => {
    inputRef.current.click();
  };

  return (
    <Dialog
      open={Boolean(dialogAddUser?.open == "import_file")}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"xl"}
      onClose={() => dispatch("closeDialogImportExcel")}
      className={classes.importDialog}
    >
      <DialogTitle>Thông tin người được bảo hiểm</DialogTitle>
      <DialogContent>
        <form
          className={`upload ${dragActive ? "drag-active" : ""}`}
          onDragEnter={(e) => dispatch({ type: "handleDrag", e })}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            hidden
            id="uploadFile"
            ref={inputRef}
            type="file"
            onChange={(e) => {
              dispatch({ type: "handleChange", e });
              e.target.value = null;
            }}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <img src={uploadIcon} alt="upload" className="logo" />
          <div className="bold">Kéo thả file vào đây</div>
          <div className="text-sm">Các file định dạng: Excel</div>
          <div className="txt">Hoặc</div>
          <Button
            disabled={uploading || saving}
            className="btn-upload"
            onClick={onBtnClick}
          >
            {uploading && (
              <CircularProgress size={25} sx={{ mx: 1, color: "#fff" }} />
            )}{" "}
            {uploading ? "Đang tải file...." : "Tải file lên"}
          </Button>

          {dragActive && (
            <div
              className="drag-file-element"
              onDragEnter={(e) => dispatch({ type: "handleDrag", e })}
              onDragLeave={(e) => dispatch({ type: "handleDrag", e })}
              onDragOver={(e) => dispatch({ type: "handleDrag", e })}
              onDrop={(e) => dispatch({ type: "handleDrop", e })}
            ></div>
          )}
        </form>
        <ImportTable list={importList} />
      </DialogContent>
      <DialogActions>
        <Button
          className="btn-cancel"
          onClick={() => dispatch("closeDialogImportExcel")}
        >
          Bỏ qua
        </Button>
        <Button
          disabled={importList.length === 0 || saving}
          className="btn-upload"
          onClick={() => dispatch("importExcel")}
        >
          {saving ? "Đang cập nhật..." : "Cập nhật"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImportExcel;
