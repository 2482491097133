import { saveAs } from "file-saver";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import Resizer from "react-image-file-resizer";
import check_signature_file from "../../../../../../../assets/health/check_signature_pdfFile.png";
import check from "../../../../../../../assets/health/check.png";
import checked from "../../../../../../../assets/health/checked.png";

import {
  convertCurrency,
  formatDate,
  renderRelationship,
  typeOfIndimnity,
  formatDate_Y_M_D,
  listTypeOfRisk,
} from "../../../utils";

import {
  AlignmentType,
  BorderStyle,
  Document,
  HeadingLevel,
  HorizontalPositionAlign,
  ImageRun,
  Packer,
  Paragraph,
  PositionalTab,
  PositionalTabAlignment,
  PositionalTabLeader,
  PositionalTabRelativeTo,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalPositionAlign,
  WidthType,
  convertInchesToTwip,
} from "docx";
import { get } from "lodash";
import { getFileByUrl } from "../../../../../../../apis/health";

const borderCellNone = {
  top: {
    style: BorderStyle.NONE,
    size: 0,
    color: "ff0000",
  },
  right: {
    style: BorderStyle.NONE,
    size: 0,
    color: "ff0000",
  },
  bottom: {
    style: BorderStyle.NONE,
    size: 0,
    color: "ff0000",
  },
  left: {
    style: BorderStyle.NONE,
    size: 0,
    color: "ff0000",
  },
};

const table = (detail) => {
  const typeOfIndimnitys = typeOfIndimnity.find(
    (i) => i.id == detail?.extraInfo?.typeOfIndimnity
  );

  return new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        height: {
          value: 350,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Loại bồi thường",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Số tiền YCBT",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Số tiền không thanh toán",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Số tiền được bồi thường",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 350,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${typeOfIndimnitys?.title || ""}`,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${convertCurrency(detail?.requestAmount || "")}`,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${convertCurrency(
                      detail?.requestAmount - detail?.amount > 0
                        ? detail?.requestAmount - detail?.amount
                        : 0 || ""
                    )}`,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${convertCurrency(detail?.amount || "")}`,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 350,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Tổng cộng: `,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${convertCurrency(detail?.requestAmount || "")}`,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${convertCurrency(
                      detail?.requestAmount - detail?.amount > 0
                        ? detail?.requestAmount - detail?.amount
                        : 0 || ""
                    )}`,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${convertCurrency(detail?.amount || "")}`,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      }),
    ],
    margins: {
      top: convertInchesToTwip(0.1),
      bottom: convertInchesToTwip(0.1),
      right: convertInchesToTwip(0.1),
      left: convertInchesToTwip(0.1),
    },
  });
};

const tableLicense = (detail, blobChecked, blobCheck, blob) => {
  const typeOfIndimnitys = typeOfIndimnity.find(
    (i) => i.id == detail?.extraInfo?.typeOfIndimnity
  );

  return new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 4500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Đơn thuốc",
                  }),
                  new PositionalTab({
                    alignment: PositionalTabAlignment.RIGHT,
                    relativeTo: PositionalTabRelativeTo.MARGIN,
                    leader: PositionalTabLeader.NONE,
                  }),
                  new ImageRun({
                    data: (detail?.elements || []).find((i) => i.type_id == 507)
                      ? blobChecked
                      : blobCheck,
                    transformation: {
                      width: 15,
                      height: 15,
                    },
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 4500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Bảng kê chi tiết điều trị nội trú",
                  }),
                  new PositionalTab({
                    alignment: PositionalTabAlignment.RIGHT,
                    relativeTo: PositionalTabRelativeTo.MARGIN,
                    leader: PositionalTabLeader.NONE,
                  }),
                  new ImageRun({
                    data: (detail?.elements || []).find((i) => i.type_id == 506)
                      ? blobChecked
                      : blobCheck,
                    transformation: {
                      width: 15,
                      height: 15,
                    },
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 4500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Chỉ định chiếu chụp/xét nghiệm",
                  }),
                  new PositionalTab({
                    alignment: PositionalTabAlignment.RIGHT,
                    relativeTo: PositionalTabRelativeTo.MARGIN,
                    leader: PositionalTabLeader.NONE,
                  }),
                  new ImageRun({
                    data: (detail?.elements || []).find((i) => i.type_id == 504)
                      ? blobChecked
                      : blobCheck,
                    transformation: {
                      width: 15,
                      height: 15,
                    },
                  }),
                ],
                heading: HeadingLevel.HEADING_4,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 4500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Phiếu tiêm ngừa",
                  }),
                  new PositionalTab({
                    alignment: PositionalTabAlignment.RIGHT,
                    relativeTo: PositionalTabRelativeTo.MARGIN,
                    leader: PositionalTabLeader.NONE,
                  }),
                  new ImageRun({
                    data: (detail?.elements || []).find((i) => i.type_id == 528)
                      ? blobChecked
                      : blobCheck,
                    transformation: {
                      width: 15,
                      height: 15,
                    },
                  }),
                ],
                heading: HeadingLevel.HEADING_4,
              }),
            ],
            borders: { ...borderCellNone },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 4500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Kết quả chiếu chụp/xét nghiệm",
                  }),
                  new PositionalTab({
                    alignment: PositionalTabAlignment.RIGHT,
                    relativeTo: PositionalTabRelativeTo.MARGIN,
                    leader: PositionalTabLeader.NONE,
                  }),
                  new ImageRun({
                    data: (detail?.elements || []).find(
                      (i) => i.type_id == 513 || i.type_id == 514
                    )
                      ? blobChecked
                      : blobCheck,
                    transformation: {
                      width: 15,
                      height: 15,
                    },
                  }),
                ],
                heading: HeadingLevel.HEADING_4,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 4500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Báo cáo y tế, tóm tắt bệnh án",
                  }),
                  new PositionalTab({
                    alignment: PositionalTabAlignment.RIGHT,
                    relativeTo: PositionalTabRelativeTo.MARGIN,
                    leader: PositionalTabLeader.NONE,
                  }),
                  new ImageRun({
                    data: (detail?.elements || []).find((i) => i.type_id == 531)
                      ? blobChecked
                      : blobCheck,
                    transformation: {
                      width: 15,
                      height: 15,
                    },
                  }),
                ],
                heading: HeadingLevel.HEADING_4,
              }),
            ],
            borders: { ...borderCellNone },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 4500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Giấy ra viện",
                  }),
                  new PositionalTab({
                    alignment: PositionalTabAlignment.RIGHT,
                    relativeTo: PositionalTabRelativeTo.MARGIN,
                    leader: PositionalTabLeader.NONE,
                  }),
                  new ImageRun({
                    data: (detail?.elements || []).find((i) => i.type_id == 510)
                      ? blobChecked
                      : blobCheck,
                    transformation: {
                      width: 15,
                      height: 15,
                    },
                  }),
                ],
                heading: HeadingLevel.HEADING_4,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 4500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                  }),
                ],
                heading: HeadingLevel.HEADING_4,
              }),
            ],
            borders: { ...borderCellNone },
          }),
        ],
      }),
    ],
    margins: {
      top: convertInchesToTwip(0.05),
      bottom: convertInchesToTwip(0.05),
      right: convertInchesToTwip(0.05),
      left: convertInchesToTwip(0.05),
    },
  });
};

const tableCompensationInfor = (detail) => {
  return new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Mã hồ sơ: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${detail?.code}`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Họ và tên :",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${detail?.insuredInfo?.insuredName || ""}`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Số hợp đồng BH: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${detail?.certNum || ""}`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Ngày sinh: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${
                      detail?.insuredInfo?.insuredDob
                        ? moment(
                            formatDate_Y_M_D(detail?.insuredInfo?.insuredDob)
                          ).format("DD/MM/YYYY")
                        : ""
                    }`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Thời hạn BH: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${
                      detail?.endDate
                        ? moment(formatDate_Y_M_D(detail?.endDate)).format(
                            "DD/MM/YYYY"
                          )
                        : ""
                    }`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "CMND/CCCD: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${detail?.insuredInfo?.insuredPrivateId || ""}`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: ``,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Giới tính: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${
                      detail?.insuredInfo?.insuredGender === 1
                        ? "Nam"
                        : detail?.insuredInfo?.insuredGender === 0
                        ? "Nữ"
                        : ""
                    }`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: ``,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Email: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${detail?.insuredInfo?.insuredEmail || ""}`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 250,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Loại bồi thường: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
            margins: {
              top: convertInchesToTwip(0.2),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.05),
              left: convertInchesToTwip(0.05),
            },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${
                      listTypeOfRisk.find(
                        (i) => i.id == detail?.extraInfo?.typeOfRisk
                      )?.title || ""
                    }`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
            margins: {
              top: convertInchesToTwip(0.2),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.05),
              left: convertInchesToTwip(0.05),
            },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Nơi điều trị: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
            margins: {
              top: convertInchesToTwip(0.2),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.05),
              left: convertInchesToTwip(0.05),
            },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${detail?.extraInfo?.treatmentPlace || ""}`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
            margins: {
              top: convertInchesToTwip(0.2),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.05),
              left: convertInchesToTwip(0.05),
            },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${""}`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Khoa điều trị: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${detail?.extraInfo?.departmentTreatment || ""}`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${""}`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Ngày vào viện:  ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: detail?.extraInfo?.dateOfHospital
                      ? moment(
                          formatDate_Y_M_D(detail?.extraInfo?.dateOfHospital)
                        ).format("DD/MM/YYYY")
                      : "",
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: ``,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Ngày ra viện: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: detail?.extraInfo?.dateOutOfHospital
                      ? moment(
                          formatDate_Y_M_D(detail?.extraInfo?.dateOutOfHospital)
                        ).format("DD/MM/YYYY")
                      : "",
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: ``,
                    bold: true,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Bác sỹ điều trị: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${detail?.extraInfo?.doctorName || ""}`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
        ],
      }),
    ],
    margins: {
      top: convertInchesToTwip(0.05),
      bottom: convertInchesToTwip(0.05),
      right: convertInchesToTwip(0.05),
      left: convertInchesToTwip(0.05),
    },
  });
};

const tableBeneficiaryInfor = (detail) => {
  return new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Họ và tên: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${
                      detail?.extraInfo?.beneficiaryName ||
                      detail?.insuredInfo?.beneficiaryName ||
                      ""
                    }`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Số tài khoản: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${
                      detail?.extraInfo?.beneficiaryBankAcountNumber ||
                      detail?.insuredInfo?.beneficiaryBankAcountNumber ||
                      ""
                    }`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: { ...borderCellNone },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "MQH với người được BH: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
            },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${
                      detail?.insuredInfo?.relationshipWithInsuredPersonId == 1
                        ? "Bản thân"
                        : detail?.insuredInfo
                            ?.relationshipWithInsuredPersonId == 2
                        ? "Người thân"
                        : ""
                    }`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
            },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Ngân hàng: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
            },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${
                      detail?.extraInfo?.beneficiaryBank ||
                      detail?.insuredInfo?.beneficiaryBankTitle ||
                      ""
                    }`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
            },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: ``,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Chi nhánh: ",
                    color: "#A0A0A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${
                      detail?.extraInfo?.beneficiaryBankBranchName ||
                      detail?.insuredInfo?.beneficiaryBankBranchName ||
                      ""
                    }`,
                    bold: true,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
        ],
      }),
    ],
    margins: {
      top: convertInchesToTwip(0.05),
      bottom: convertInchesToTwip(0.05),
      right: convertInchesToTwip(0.05),
      left: convertInchesToTwip(0.05),
    },
  });
};

const tableDiagnosis = (detail) => {
  const rows_diagnosis = (get(detail, "extraInfo.doctorDiagnoses") || []).map(
    (item) =>
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 1200,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.diseaseCode || "",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 4600,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.diseaseName || "",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1200,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.approveStatus === 1 ? "Chấp nhận" : "Từ chối",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.note || "",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      })
  );
  return new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 1200,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Mã bệnh",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 4600,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tên bệnh",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1200,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Kết quả",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Lý do",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      }),
      ...rows_diagnosis,
    ],
    margins: {
      top: convertInchesToTwip(0.05),
      bottom: convertInchesToTwip(0.05),
      right: convertInchesToTwip(0.05),
      left: convertInchesToTwip(0.05),
    },
  });
};

const tableListOfExaminationCosts = (detail) => {
  let fee_approve_examination = 0;
  (get(detail, "extraInfo.examinationFees") || []).forEach(
    (i) => (fee_approve_examination += +i?.approveFee)
  );

  const rows_examination = (get(detail, "extraInfo.examinationFees") || []).map(
    (item) =>
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2700,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.serviceName || "",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1100,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.approveStatus === 1 ? "Chấp nhận" : "Từ chối",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.note || "",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.fee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.paidFee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.approveFee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      })
  );
  return new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2700,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tên dịch vụ",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1100,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Kết quả",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Lý do",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tổng phí",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "BH khác",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Được duyệt",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      }),
      ...rows_examination,
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 5800,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tổng phí được duyệt",
                    color: "#3961A0",
                    bold: true,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            columnSpan: 3,
          }),
          new TableCell({
            width: {
              size: 4200,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: !!fee_approve_examination
                      ? `${fee_approve_examination}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " VNĐ"
                      : "0",
                    color: "#3961A0",
                    bold: true,
                  }),
                ],
                alignment: AlignmentType.END,
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            columnSpan: 3,
          }),
        ],
      }),
    ],
    margins: {
      top: convertInchesToTwip(0.05),
      bottom: convertInchesToTwip(0.05),
      right: convertInchesToTwip(0.05),
      left: convertInchesToTwip(0.05),
    },
  });
};

const tableBill = (detail) => {
  let fee_approve_bills = 0;
  (get(detail, "extraInfo.bills") || []).forEach(
    (i) => (fee_approve_bills += +i?.approveFee)
  );

  const rows_bills = (get(detail, "extraInfo.bills") || []).map(
    (item) =>
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2700,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.serviceName || "",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1100,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.approveStatus === 1 ? "Chấp nhận" : "Từ chối",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.note || "",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.fee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.paidFee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.approveFee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      })
  );
  return new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2700,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tên dịch vụ",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1100,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Kết quả",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Lý do",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tổng phí",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "BH khác",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Được duyệt",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      }),
      ...rows_bills,
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 5800,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tổng phí được duyệt",
                    color: "#3961A0",
                    bold: true,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            columnSpan: 3,
          }),
          new TableCell({
            width: {
              size: 4200,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: !!fee_approve_bills
                      ? `${fee_approve_bills}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " VNĐ"
                      : "0",
                    color: "#3961A0",
                    bold: true,
                  }),
                ],
                alignment: AlignmentType.END,
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            columnSpan: 3,
          }),
        ],
      }),
    ],
    margins: {
      top: convertInchesToTwip(0.05),
      bottom: convertInchesToTwip(0.05),
      right: convertInchesToTwip(0.05),
      left: convertInchesToTwip(0.05),
    },
  });
};

const tableMedication = (detail) => {
  let fee_approve_medicines = 0;
  (get(detail, "extraInfo.medicines") || []).forEach(
    (i) => (fee_approve_medicines += +i?.approveFee)
  );

  const rows_bills = (get(detail, "extraInfo.medicines") || []).map(
    (item) =>
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2700,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.serviceName || "",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1100,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.approveStatus === 1 ? "Chấp nhận" : "Từ chối",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.note || "",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.fee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.paidFee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.approveFee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      })
  );
  return new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2700,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tên dịch vụ",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1100,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Kết quả",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Lý do",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tổng phí",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "BH khác",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1400,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Được duyệt",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      }),
      ...rows_bills,
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 5800,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tổng phí được duyệt",
                    color: "#3961A0",
                    bold: true,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            columnSpan: 3,
          }),
          new TableCell({
            width: {
              size: 4200,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: !!fee_approve_medicines
                      ? `${fee_approve_medicines}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " VNĐ"
                      : "0",
                    color: "#3961A0",
                    bold: true,
                  }),
                ],
                alignment: AlignmentType.END,
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            columnSpan: 3,
          }),
        ],
      }),
    ],
    margins: {
      top: convertInchesToTwip(0.05),
      bottom: convertInchesToTwip(0.05),
      right: convertInchesToTwip(0.05),
      left: convertInchesToTwip(0.05),
    },
  });
};

const tableOtherBenefit = (detail) => {
  let fee_approve_others = 0;
  (get(detail, "extraInfo.others") || []).forEach(
    (i) => (fee_approve_others += +i?.approveFee)
  );

  const rows_others = (get(detail, "extraInfo.others") || []).map(
    (item) =>
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.serviceName || "",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.fee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.approveFee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      })
  );
  return new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Quyền lợi",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tổng phí",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Được duyệt",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      }),
      ...rows_others,
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tổng phí được duyệt",
                    color: "#3961A0",
                    bold: true,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 4000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: !!fee_approve_others
                      ? `${fee_approve_others}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " VNĐ"
                      : "0",
                    color: "#3961A0",
                    bold: true,
                  }),
                ],
                alignment: AlignmentType.END,
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            columnSpan: 2,
          }),
        ],
      }),
    ],
    margins: {
      top: convertInchesToTwip(0.05),
      bottom: convertInchesToTwip(0.05),
      right: convertInchesToTwip(0.05),
      left: convertInchesToTwip(0.05),
    },
  });
};

const tableAccident = (detail) => {
  let rowAccidnetTabale = [];

  const getTitleByTypeOfIndimnity = () => {
    switch (+detail?.extraInfo?.typeOfIndimnity) {
      case 3:
        rowAccidnetTabale = (detail?.extraInfo?.injuries || []).map((i) => ({
          ...i,
          otherText: i.injuryType || "",
        }));
        return {
          name: "injuries",
          thName: "Loại thương tật",
          title: "Tỉ lệ thương tật",
          rowAccidnetTabale,
        };
      case 5:
        rowAccidnetTabale = (
          detail?.extraInfo?.canceOrFatalDiseaseOrStroke || []
        ).map((i) => ({
          ...i,
          otherText: i.diseaseType || "",
        }));
        return {
          name: "canceOrFatalDiseaseOrStroke",
          thName: "Loại bệnh",
          title: "Ung thư/ bệnh hiểm nghèo/ đột quỵ",
          rowAccidnetTabale,
        };
      case 6:
        rowAccidnetTabale = (detail?.extraInfo?.deaths || []).map((i) => ({
          ...i,
          otherText: i.reasonOfDeath || "",
        }));
        return {
          name: "deaths",
          thName: "Nguyên nhân tử vong",
          title: "Tử vong",
          rowAccidnetTabale,
        };
      default:
        return {};
    }
  };

  const rows = getTitleByTypeOfIndimnity().rowAccidnetTabale || [];

  const colHeadRatio =
    +detail?.extraInfo?.typeOfIndimnity === 3
      ? [
          new TableCell({
            width: {
              size: 1000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tỉ lệ (%)",
                    color: "#3961A0",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ]
      : [];

  const colBodyRatio = (item) => {
    return +detail?.extraInfo?.typeOfIndimnity === 3
      ? [
          new TableCell({
            width: {
              size: 1000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.percent || ""}`,
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ]
      : [];
  };

  let colHeader = [
    new TableCell({
      width: {
        size: 2500,
        type: WidthType.DXA,
      },
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: getTitleByTypeOfIndimnity().thName,
              color: "#3961A0",
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
      ],
    }),
    new TableCell({
      width: {
        size: 1000,
        type: WidthType.DXA,
      },
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: "Kết quả",
              color: "#3961A0",
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
      ],
    }),
    new TableCell({
      width: {
        size: 2000,
        type: WidthType.DXA,
      },
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: "Lý do",
              color: "#3961A0",
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
      ],
    }),
    new TableCell({
      width: {
        size: 1300,
        type: WidthType.DXA,
      },
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: "Tổng quyền lợi",
              color: "#3961A0",
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
      ],
    }),
    ...colHeadRatio,
    new TableCell({
      width: {
        size: detail?.extraInfo?.typeOfIndimnity == 3 ? 1100 : 1600,
        type: WidthType.DXA,
      },
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: "Thành tiền",
              color: "#3961A0",
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
      ],
    }),
    new TableCell({
      width: {
        size: detail?.extraInfo?.typeOfIndimnity == 3 ? 1100 : 1600,
        type: WidthType.DXA,
        type: WidthType.DXA,
      },
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: "Được duyệt",
              color: "#3961A0",
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
      ],
    }),
  ];

  const rows_accident = (rows || []).map(
    (item) =>
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 2500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.otherText || "",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.approveStatus === 1 ? "Chấp nhận" : "Từ chối",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.note || "",
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 1300,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.benefitAmount || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          ...colBodyRatio(item),
          new TableCell({
            width: {
              size: detail?.extraInfo?.typeOfIndimnity == 3 ? 1100 : 1600,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.fee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
          new TableCell({
            width: {
              size: detail?.extraInfo?.typeOfIndimnity == 3 ? 1100 : 1600,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${item?.approveFee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ),
                    color: "#686868",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
          }),
        ],
      })
  );

  let fee_approve_accident = 0;

  (rows || []).forEach((i) => (fee_approve_accident += +i?.approveFee));

  return new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [...colHeader],
      }),
      ...rows_accident,
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 6800,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Tổng phí được duyệt",
                    color: "#3961A0",
                    bold: true,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            columnSpan: 4,
          }),
          new TableCell({
            width: {
              size: 3200,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: !!fee_approve_accident
                      ? `${fee_approve_accident}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " VNĐ"
                      : "0",
                    color: "#3961A0",
                    bold: true,
                  }),
                ],
                alignment: AlignmentType.END,
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            columnSpan: +detail?.extraInfo?.typeOfIndimnity === 3 ? 3 : 2,
          }),
        ],
      }),
    ],
    margins: {
      top: convertInchesToTwip(0.05),
      bottom: convertInchesToTwip(0.05),
      right: convertInchesToTwip(0.05),
      left: convertInchesToTwip(0.05),
    },
  });
};

const tableNote = (detail) => {
  const elementNote = (detail?.elements || []).filter((i) => i.type_id == 521);
  let itemElement = elementNote.reduce((itemElement, curentItem) => {
    if ([6, 9].includes(curentItem.status_id)) {
      return curentItem;
    }

    return curentItem.status_id > (itemElement?.status_id || 0)
      ? curentItem
      : itemElement;
  }, {});

  return new Table({
    columnWidths: [3505, 5505],
    rows: [
      new TableRow({
        height: {
          value: 200,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 4000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Ghi chú",
                    color: "#3961A0",
                    bold: true,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              top: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
            margins: {
              top: convertInchesToTwip(0.2),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.05),
              left: convertInchesToTwip(0.05),
            },
          }),
          new TableCell({
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Tổng chi phí trong phạm vi bảo hiểm`,
                    color: "#3961A0",
                    bold: true,
                  }),

                  new PositionalTab({
                    alignment: PositionalTabAlignment.RIGHT,
                    relativeTo: PositionalTabRelativeTo.MARGIN,
                    leader: PositionalTabLeader.NONE,
                  }),
                  new TextRun({
                    text:
                      `${detail?.extraInfo?.approveAmount || 0}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      ) + " VNĐ",
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              top: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
            margins: {
              top: convertInchesToTwip(0.2),
              bottom: convertInchesToTwip(0.05),
              right: convertInchesToTwip(0.05),
              left: convertInchesToTwip(0.05),
            },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 150,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 4000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${itemElement?.text || ""}`,
                    // color: "#3961A0",
                    // bold: true,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            rowSpan: 2,
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
          }),
          new TableCell({
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Tỉ lệ % bồi thưởng`,
                    bold: true,
                  }),

                  new PositionalTab({
                    alignment: PositionalTabAlignment.RIGHT,
                    relativeTo: PositionalTabRelativeTo.MARGIN,
                    leader: PositionalTabLeader.NONE,
                  }),
                  new TextRun({
                    text: `${detail?.extraInfo?.approvedPercent || 0}` + " %",
                  }),
                ],
                // alignment: AlignmentType.END,
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
            },
          }),
        ],
      }),
      new TableRow({
        height: {
          value: 200,
          rule: WidthType.DXA,
        },
        children: [
          new TableCell({
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Tổng chi phí`,
                    bold: true,
                  }),

                  new PositionalTab({
                    alignment: PositionalTabAlignment.RIGHT,
                    relativeTo: PositionalTabRelativeTo.MARGIN,
                    leader: PositionalTabLeader.NONE,
                  }),
                  new TextRun({
                    text:
                      `${detail?.extraInfo?.amount || 0}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      ) + " VNĐ",
                  }),
                ],
                // alignment: AlignmentType.END,
                heading: HeadingLevel.HEADING_1,
              }),
            ],
            borders: {
              ...borderCellNone,
              bottom: {
                style: BorderStyle.SINGLE,
                size: 1,
                color: "#A0A0A0",
              },
            },
            margins: {
              top: convertInchesToTwip(0.05),
              bottom: convertInchesToTwip(0.2),
              right: convertInchesToTwip(0.05),
              left: convertInchesToTwip(0.05),
            },
          }),
        ],
      }),
    ],
    margins: {
      top: convertInchesToTwip(0.05),
      bottom: convertInchesToTwip(0.05),
      right: convertInchesToTwip(0.05),
      left: convertInchesToTwip(0.05),
    },
  });
};

const capitalizedText = (originalText) => {
  return originalText
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// const resizeFile = (file) =>
//   new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       file,
//       650,
//       800,
//       "JPEG",
//       100,
//       0,
//       (uri) => {
//         resolve(uri);
//       },
//       "blob"
//     );
//   });

const ExportClaimRefuseDocx = ({ classes, detail, dispatch }) => {
  const elementNote = (detail?.elements || []).find(
    (i) => i.status_id != 2 && i.type_id == 521 && !!i.text
  );
  const render_pay_amount = detail?.amount
    ? [
        new Paragraph({
          children: [
            new TextRun({
              text: `Số tiền trên sẽ được chuyển vào tài khoản theo thông tin sau:`,
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: ` + Họ và tên chủ tài khoản: ${
                detail?.insuredInfo?.insuredName || ""
              }`,
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: ` + Mối quan hệ với người được BH: ${renderRelationship(
                detail?.insuredInfo?.relationshipWithInsuredPersonId
              )}`,
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: ` + Ngân hàng: ${
                detail?.extraInfo?.beneficiaryBank ||
                detail?.insuredInfo?.beneficiaryBankTitle ||
                ""
              }`,
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: ` + Chi nhánh: ${
                detail?.extraInfo?.beneficiaryBankBranchName ||
                detail?.insuredInfo?.beneficiaryBankBranchName ||
                ""
              }`,
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: ` + Số tài khoản: ${
                detail?.extraInfo?.beneficiaryBankAcountNumber ||
                detail?.insuredInfo?.beneficiaryBankAcountNumber ||
                ""
              }`,
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: "Quý khách vui lòng kiểm tra tài khoản sau 3 ngày kể từ ngày nhận được thông báo này.",
            }),
          ],
          heading: HeadingLevel.HEADING_3,
        }),
      ]
    : [];

  const TabTextRun = new TextRun({
    text: `              `,
  });

  const generateDocument = async () => {
    // const blob = await fetch(LogoGC).then((r) => r.blob());
    const signatureInsurance =
      detail.elements?.find((item) => item.type_id == 518)?.extra_info
        ?.documents?.[0]?.path || "";
    const blob = await fetch(check_signature_file).then((r) => r.blob());
    const blobChecked = await fetch(checked).then((r) => r.blob());
    const blobCheck = await fetch(check).then((r) => r.blob());
    const blobSignatureInsurred = signatureInsurance
      ? await fetch(signatureInsurance).then((r) => r.blob())
      : null;

    const listImgClaim = [];
    const listTypeId = [
      500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513, 514,
      515, 523, 524, 525, 528, 522,
    ];
    const listImg = (detail?.elements || []).reduce((array, item) => {
      if (listTypeId.includes(+item?.type_id)) {
        const get_img_in_item = (item?.extra_info?.documents || [])
          .filter((i) => !!i.path)
          .map((i) => i.path);
        return [...array, ...get_img_in_item];
      }
      return array;
    }, []);
    for (let iFor = 0; iFor < listImg.length; iFor++) {
      const dataImg = await fetch(listImg[iFor]).then((r) => r.blob());
      dataImg && listImgClaim.push(dataImg);

      // const dataApi = await getFileByUrl(listImg[iFor]);

      // if (dataApi) {
      //   const newImg = await resizeFile(dataApi);
      //   newImg && listImgClaim.push(newImg);
      // }
    }

    const renderImgClaim = (listImgClaim || []).map(
      (i) =>
        new Paragraph({
          children: [
            new ImageRun({
              data: i,
              transformation: {
                width: 600,
                height: 650,
              },
            }),
          ],
          heading: HeadingLevel.HEADING_2,
        })
    );

    const getTitleByTypeOfIndimnity = () => {
      switch (+detail?.extraInfo?.typeOfIndimnity) {
        case 3:
          return "Tỉ lệ thương tật";
        case 5:
          return "Ung thư/ bệnh hiểm nghèo/ đột quỵ";
        case 6:
          return "Tử vong";
        default:
          return "";
      }
    };

    const renderTabel = [3, 5, 6].includes(+detail?.extraInfo?.typeOfIndimnity)
      ? [
          new Paragraph({
            text: "",
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: getTitleByTypeOfIndimnity(),
                color: "#4B4B4B",
                bold: true,
              }),
            ],
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text: "",
          }),
          tableAccident(detail),
          new Paragraph({
            text: "",
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Quyền lợi khác`,
                color: "#4B4B4B",
                bold: true,
              }),
            ],
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text: "",
          }),
          tableOtherBenefit(detail),
        ]
      : [
          new Paragraph({
            children: [
              new TextRun({
                text: `Chuẩn đoán bệnh`,
                color: "#4B4B4B",
                bold: true,
              }),
            ],
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text: "",
          }),
          tableDiagnosis(detail),
          new Paragraph({
            text: "",
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Bảng kê chi phí khám`,
                color: "#4B4B4B",
                bold: true,
              }),
            ],
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text: "",
          }),
          tableListOfExaminationCosts(detail),
          new Paragraph({
            text: "",
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Hoá đơn`,
                color: "#4B4B4B",
                bold: true,
              }),
            ],
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text: "",
          }),
          tableBill(detail),
          new Paragraph({
            text: "",
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Toa thuốc`,
                color: "#4B4B4B",
                bold: true,
              }),
            ],
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text: "",
          }),
          tableMedication(detail),

          new Paragraph({
            text: "",
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Quyền lợi khác`,
                color: "#4B4B4B",
                bold: true,
              }),
            ],
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text: "",
          }),
          tableOtherBenefit(detail),
        ];

    const doc = new Document({
      styles: {
        default: {
          heading1: {
            run: {
              size: 22,
              font: "Arial",
            },
            paragraph: {
              spacing: {
                after: 100,
              },
            },
          },
          heading2: {
            run: {
              size: 28,
              font: "Arial",
            },
            paragraph: {
              spacing: {
                before: 350,
                after: 150,
              },
            },
          },
          heading3: {
            run: {
              size: 22,
              font: "Arial",
            },
            paragraph: {
              spacing: {
                before: 100,
              },
            },
          },
          heading4: {
            run: {
              size: 22,
              font: "Arial",
            },
            paragraph: {
              spacing: {
                after: 10,
              },
            },
          },
        },
      },
      sections: [
        {
          properties: {
            page: {
              margin: { top: 1435, left: 846, bottom: 1435, right: 846 },
            },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `TPHCM , ngày ${moment().format(
                    "DD"
                  )} tháng ${moment().format("MM")} năm ${moment().format(
                    "YYYY"
                  )}`,
                  italics: true,
                }),
              ],
              alignment: AlignmentType.END,
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `THÔNG BÁO ${
                    !detail?.amount ? "TỪ CHỐI" : ""
                  } BỒI THƯỜNG`,
                  bold: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
              heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Số hồ sơ bồi thường: ${detail?.code || ""}`,
                  italics: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph(""),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Kính gửi: Quý khách hàng, ${
                    detail?.insuredInfo?.insuredName || ""
                  }`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Công ty ${
                    detail?.organizationTitle || ""
                  } thông báo đến Quý khách hàng thông tin hồ sơ yêu cầu trả tiền bảo hiểm sau đây:`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: ` - Người được bảo hiểm: ${
                    detail?.insuredInfo?.insuredName || ""
                  }`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: ` - Số hợp đồng bảo hiểm: ${
                    detail?.certNum || detail?.cert_num || ""
                  }`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: ` - Thời hạn bảo hiểm: từ ${formatDate(
                    detail?.beginDate
                  )} đến ${formatDate(detail?.endDate)} `,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: ` - Đơn vị tham gia bảo hiểm/ mã đơn hàng bảo hiểm: ${
                    detail?.certNum || ""
                  }`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `- Số tiền yêu cầu chi trả: ${convertCurrency(
                    detail?.requestAmount || ""
                  )}`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: detail?.amount
                    ? "Sau khi xem xét hồ sơ yêu cầu chi trả bảo hiểm của Quý khách, chúng tôi thông báo chi trả bảo hiểm cho Quý khách như sau:"
                    : "Sau khi xem xét chi tiết hồ sơ, bộ phận Bồi thường Global Care rất tiếc gửi đến Quý khách thông báo từ chối bồi thường cho hồ sơ yêu cầu thanh toán tiền bảo hiểm của Quý khách như sau:",
                }),
              ],
              heading: HeadingLevel.HEADING_3,
            }),
            new Paragraph(""),
            table(detail),
            new Paragraph(""),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Diễn giải (nếu có): ${elementNote?.text || ""}`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph(""),
            ...render_pay_amount,
            new Paragraph({
              children: [
                new TextRun({
                  text: "Nếu có bất kỳ thắc mắc nào liên quan đến việc giải quyết chi trả bảo hiểm nêu trên hoặc cần thêm thông tin, Quý khách vui lòng liên hệ với chúng tôi để được giải đáp.",
                }),
              ],
              heading: HeadingLevel.HEADING_3,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Trân trọng.",
                }),
              ],
              heading: HeadingLevel.HEADING_3,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "",
                  break: detail?.amount ? 1 : 11,
                }),
              ],
            }),
            new Paragraph({
              spacing: { line: 300 },
              children: [
                new TextRun({
                  text: `​CÔNG TY ${detail?.organizationTitle || ""}`,
                  italics: true,
                  bold: true,
                  size: 28,
                }),
              ],
            }),
            new Paragraph({
              spacing: { line: 300 },
              children: [
                new TextRun({
                  text: "Người phê duyệt",
                  italics: true,
                  bold: true,
                  size: 22,
                  font: "Arail",
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `${detail?.userFullnameAssign || "Đỗ Tiến Phát"}`,
                  italics: true,
                  bold: true,
                  size: 22,
                  font: "Arail",
                }),
                new ImageRun({
                  data: blob,
                  transformation: {
                    width: 95,
                    height: 70,
                  },
                  floating: {
                    zIndex: 0,
                    behindDocument: true,
                    horizontalPosition: {
                      offset: 1734400,
                    },
                    verticalPosition: {
                      offset: 9044000,
                    },
                  },
                }),
              ],
            }),
          ],
        },
        {
          // properties: {
          //   page: {
          //     margin: { top: 1435, left: 846, bottom: 1435, right: 846 },
          //   },
          // },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `GIẤY YÊU CẦU`,
                  bold: true,
                  size: 28,
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: 50,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `THANH TOÁN BẢO HIỂM`,
                  bold: true,
                  size: 28,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: ``,
                  break: 1,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Thông tin người được bảo hiểm:`,
                  bold: true,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `tên người dược bảo hiểm:  ${capitalizedText(
                    detail?.insuredInfo?.insuredName || ""
                  )}`,
                }),
                TabTextRun,
                TabTextRun,
                new TextRun({
                  text: ` Ngày sinh:  ${formatDate(
                    formatDate_Y_M_D(detail?.insuredInfo?.insuredDob)
                  )}`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Số CMND/CCCD:  ${
                    detail?.insuredInfo?.insuredPrivateId || ""
                  }`,
                }),
                TabTextRun,
                new TextRun({
                  text: `Sồ điện thoại:  ${
                    detail?.insuredInfo?.insuredPhone || ""
                  }`,
                }),
                TabTextRun,
                new TextRun({
                  text: `Email:  ${detail?.insuredInfo?.insuredEmail || ""}`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: ``,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Mô tả tai nạn/Bệnh tật:`,
                  bold: true,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: ` Nguyên nhân, diễn biến, lịch sử bệnh: ${
                    detail?.extraInfo?.reason || ""
                  }`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Ngày xảy ra rủi ro:  ${formatDate(
                    formatDate_Y_M_D(detail?.extraInfo?.dateOfAccident)
                  )}`,
                }),
                TabTextRun,
                new TextRun({
                  text: `Ngày vào viện:  ${formatDate(
                    formatDate_Y_M_D(detail?.extraInfo?.dateOfHospital)
                  )}`,
                }),
                TabTextRun,
                new TextRun({
                  text: `Ngày ra viện:  ${formatDate(
                    formatDate_Y_M_D(detail?.extraInfo?.dateOutOfHospital)
                  )}`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Chẩn đoán: ${(detail?.extraInfo?.doctorDiagnoses || [])
                    .map((i) => i?.diseaseName || "")
                    .join(", ")}`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Nơi điều trị: ${
                    detail?.extraInfo?.treatmentPlace || ""
                  }`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: ``,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Thông tin hồ sơ yêu cầu chi trả:`,
                  bold: true,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Bảng liệt kê các chứng từ và hóa đơn gốc nộp kèm yêu cầu trả tiền bảo hiểm:`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            tableLicense(detail, blobChecked, blobCheck, blob),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Cam kết`,
                  bold: true,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Tôi với tư cách là người yêu cầu bồi thường, xin cam đoan những lời khai trên là đúng sự thật. Tôi cũng đồng ý rằng với Giấy yêu cầu này, tôi cho phép nhà Bảo Hiểm tiếp xúc với các bên thứ ba (từ bất kỳ bác sĩ, cơ sở y tế, nhà thuốc, Công ty và các đại lý hoặc các tổ chức khác...) để thu thập thông tin cần thiết để xem xét bồi thường cho hợp đồng bảo hiểm của tôi.`,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Ngày: ${moment().format("DD/MM/YYYY")}`,
                  bold: true,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `CHỮ KÝ NGƯỜI YÊU CẦU`,
                  bold: true,
                }),
              ],
              heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph({
              children: [
                new ImageRun({
                  data: blobSignatureInsurred || null,
                  transformation: {
                    width: 125,
                    height: 70,
                  },
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `${capitalizedText(
                    detail?.insuredInfo?.insuredName || ""
                  )}`,
                  bold: true,
                }),
              ],
              heading: HeadingLevel.HEADING_1,
            }),
          ],
        },
        {
          properties: {
            page: {
              margin: { top: 1435, left: 846, bottom: 1435, right: 846 },
            },
          },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `HỒ SƠ BỒI THƯỜNG SỐ ${detail?.code}`,
                  color: "#3961A0",
                  bold: true,
                }),
              ],

              heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `THÔNG TIN BỒI THƯỜNG`,
                  color: "#3961A0",
                  bold: true,
                }),
              ],
              heading: HeadingLevel.HEADING_2,
            }),
            tableCompensationInfor(detail),
            new Paragraph({
              children: [
                new TextRun({
                  text: `NGƯỜI THỤ HƯỞNG`,
                  color: "#3961A0",
                  bold: true,
                }),
              ],
              heading: HeadingLevel.HEADING_2,
            }),
            tableBeneficiaryInfor(detail),
            new Paragraph({
              children: [
                new TextRun({
                  text: `CÁC MỤC BỒI THƯỜNG`,
                  color: "#3961A0",
                  bold: true,
                }),
              ],
              heading: HeadingLevel.HEADING_2,
            }),
            ...renderTabel,
            new Paragraph({
              children: [
                new TextRun({
                  text: ``,
                  color: "#3961A0",
                  bold: true,
                }),
              ],
              heading: HeadingLevel.HEADING_2,
            }),
            tableNote(detail),
            ...renderImgClaim,
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "Thong-bao-boi-thuong.docx");
      // const newBlob = new Blob([blob], {
      //   type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // });
    });
  };
  return generateDocument();
  // return (
  //   <div className={classes.infor} onClick={generateDocument}>
  //     <div>Xem thông báo bồi thường</div>

  //     <NavigateNextIcon fontSize="small" />
  //   </div>
  // );
};

export default ExportClaimRefuseDocx;
