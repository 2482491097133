export const initialState = {
  listCompanys: [],
  listCompanysTPAG: [],
  merchants: [],
  merchant: {},
  parentRole: {},
  inforCompany: {},
  groupRoleChosen: {},
  dialogCreatCompany: {},
  dialogAddRole: {},
  snackbar: {},
  errors: {},
  page: {},
  usingRoleGroupBasic: false,
  loading: false,
  payloadApiAutocomplete: {},
  loadingAutocomplete: {},
};

export const defaultRole = {
  BLVP_MANAGE: 1,
  BLVP_VIEW: 1,
  PRODUCT_MANAGE: 1,
  PRODUCT_VIEW: 1,
  PRODUCT_EXPORT: 1,
  CERT_MANAGE: 1,
  CERT_VIEW: 1,
  CERT_EXPORT: 1,
  CLAIM_DASHBOARD_VIEW: 1,
  CLAIM_VIEW: 1,
  CLAIM_CHECK: 1,
  CLAIM_APPROVE: 1,
  CLAIM_PAYMENT_ORDER_UPLOAD: 1,
  CLAIM_REPORT: 1,
  CLAIM_EXPORT: 1,
  ROLE_GROUP_MANAGE: 1,
  ROLE_GROUP_VIEW: 1,
  ACCOUNT_MANAGE: 1,
  ACCOUNT_VIEW: 1,
  ACCOUNT_EXPORT: 1,
  BENEFIT_MANAGE: 1,
  BENEFIT_VIEW: 1,
};
