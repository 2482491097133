import React, { useEffect, useState } from "react";
import { IconButton, TableCell, Autocomplete, Popper } from "@mui/material";
import NumberFormat from "react-number-format";
import { createFilterOptions } from "@mui/material/Autocomplete";

import CommonTable from "../../../../../../../library/layout/commonTable";
import styleDialog from "./styleDialog";
import { TextField } from "../../../overrideMui";

import addSquare from "../../../../../../../assets/health/add-square.svg";
import minusSquare from "../../../../../../../assets/health/minus-square.svg";
import _ from "lodash";
import moment from "moment";

export default function TableDilaogUpdate(props) {
  const { state, dispatch, name } = props;
  const classes = styleDialog({
    widthTable: name === "prescription" ? 1050 : 810,
  });
  const [scrollPosition, setScrollPosition] = useState({});

  const {
    rowsDiagnosis,
    rowsExamination,
    rowsMedicines,
    listNameDisease,
    listNameMedicine,
    listBenefits,
    rowsBills,
    loadingAutocomplete,
    payloadAutocomplete,
  } = state;

  useEffect(() => {
    name === "diagnosis" &&
      listNameDisease.length == 0 &&
      dispatch("getListDisease");
    name === "prescription" &&
      listNameMedicine.length == 0 &&
      dispatch("getListMedicine");
  }, []);

  const dataRender = () => {
    switch (name) {
      case "diagnosis":
        const dataAutocomplete = "";
        return {
          columns: columnsDiagnosis,
          rows: rowsDiagnosis,
          dataAutocomplete,
        };
      case "medical":
        return { columns: columnsOther, rows: rowsExamination };
      case "bill":
        return { columns: columnsOther, rows: rowsBills };
      case "prescription":
        return { columns: columnsMedical, rows: rowsMedicines };

      default:
        return { columns: [], rows: [] };
    }
  };

  const onScrollListAutoComplete = (nameScroll) => {
    const elementDivScroll = document.getElementsByClassName(
      `div-scroll-${nameScroll}`
    );
    const payload = payloadAutocomplete[nameScroll];
    if (elementDivScroll.length > 0 && +payload?.page * 10 < +payload?.total) {
      const scrollDiv = elementDivScroll[0];
      const listItems = document.querySelectorAll(`.scroll-item-${nameScroll}`);
      const lastItem = listItems[listItems.length - 1] || null;

      if (
        Math.abs(lastItem?.offsetTop - scrollDiv.scrollTop + 70) <
          lastItem?.offsetHeight + scrollDiv.offsetHeight &&
        !loadingAutocomplete[nameScroll]
      ) {
        setScrollPosition({
          ...scrollPosition,
          [nameScroll]: scrollDiv.scrollHeight,
        });
        dispatch({ type: "getMoveListAutocomplete", name: nameScroll });
      }
    }
  };
  useEffect(() => {
    const el = document.getElementsByClassName(`div-scroll-diagnosis`);
    if (el.length > 0) {
      const element_scroll = el[0];
      element_scroll.scrollTop = scrollPosition?.diagnosis || 0;
    }
  }, [loadingAutocomplete?.diagnosis]);

  useEffect(() => {
    const el = document.getElementsByClassName(`div-scroll-prescription`);
    if (el.length > 0) {
      const element_scroll = el[0];
      element_scroll.scrollTop = scrollPosition?.prescription || 0;
    }
  }, [loadingAutocomplete?.prescription]);

  return (
    <div className={classes.container_diagnosis}>
      <CommonTable
        classes={classes}
        rows={dataRender().rows}
        columns={dataRender().columns}
        data={{
          name: name,
          listNameDisease,
          listNameMedicine,
          dispatch,
          listBenefits,
          loadingAutocomplete,
          onScrollListAutoComplete,
        }}
        event={(type, data) =>
          (ele, ...rest) => {
            dispatch({ type, data, ele, rest });
          }}
      />
    </div>
  );
}

const columnsDiagnosis = [
  {
    label: "Mã bệnh",
    headerClass: "code",
    renderCell: ({ rowIdx, classes, item, event, data }) => {
      return (
        <TableCell className="code">
          {
            <Autocomplete
              loading
              freeSolo
              disableClearable
              noOptionsText={
                <div>
                  {data?.loadingAutocomplete?.org
                    ? "Đang tải thêm ... "
                    : "Không có dữ liệu"}
                </div>
              }
              loadingText={
                data?.loadingAutocomplete?.org
                  ? "Đang tải thêm ... "
                  : "Không có dữ liệu"
              }
              PopperComponent={(props) => {
                return (
                  <div
                    {...props}
                    style={{
                      width: "480px",
                      position: "absolute",
                      display: props.open ? "" : "none",
                    }}
                    placement={"bottom-start"}
                  >
                    {props.children}
                  </div>
                );
              }}
              options={data?.listNameDisease || []}
              getOptionLabel={(option) => option?.code}
              renderOption={(props, option) => (
                <div {...props} style={{ width: "100%" }}>
                  {option?.code || ""} - {option?.name || ""}
                </div>
              )}
              className={`${classes.autocomplete}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={classes.textField}
                  placeholder="Nhập mã bệnh"
                />
              )}
              value={{ code: item?.diseaseCode || "", name: "" }}
              onChange={event(`${data.name}OnChangText`, {
                index: rowIdx,
                name: "diseasename",
              })}
              onInputChange={(e, value, reason) => {
                if (reason === "input") {
                  data.dispatch({
                    type: "getNameDisease",
                    value,
                    name: "diagnosis",
                    nameText: "code",
                    index: rowIdx,
                    reason,
                  });
                }
              }}
              // onInputChange={_.debounce((e, value, reason) => {
              //   if (reason === "input") {
              //     data.dispatch({
              //       type: "getNameDisease",
              //       value,
              //       name: "diagnosis",
              //       nameText: "code",
              //       index: rowIdx,
              //       reason,
              //     });
              //   }
              // }, 600)}
              ListboxComponent={(paramsList) => (
                <div
                  {...paramsList}
                  className={`${paramsList.className} div-scroll-diagnosis`}
                  onScroll={() => data?.onScrollListAutoComplete("diagnosis")}
                >
                  {paramsList?.children?.map((i) => (
                    <li
                      {...i?.props}
                      key={i?.key}
                      className={`${i?.props?.className} scroll-item-diagnosis`}
                    />
                  ))}
                  {data?.loadingAutocomplete?.diagnosis && (
                    <div
                      style={{
                        fontWeight: "600",
                        color: "#0B7CE4",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      Đang tải thêm ...{" "}
                    </div>
                  )}
                </div>
              )}
            />
          }
        </TableCell>
      );
    },
  },
  {
    label: "Tên bệnh",
    headerClass: "name",
    renderCell: ({ rowIdx, classes, item, event, data }) => (
      <TableCell className="name">
        <Autocomplete
          loading
          freeSolo
          disableClearable
          id="autocomplete_disease_name"
          noOptionsText={
            <div>
              {data?.loadingAutocomplete?.org
                ? "Đang tải thêm ... "
                : "Không có dữ liệu"}
            </div>
          }
          loadingText={
            data?.loadingAutocomplete?.org
              ? "Đang tải thêm ... "
              : "Không có dữ liệu"
          }
          options={data?.listNameDisease || []}
          getOptionLabel={(option) => option?.name}
          renderOption={(props, option) => (
            <div {...props} style={{ width: "100%" }}>
              {option?.code || ""} - {option?.name || ""}
            </div>
          )}
          className={`${classes.autocomplete}`}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              className={classes.textField}
              placeholder="Nhập tên bệnh"
            />
          )}
          value={{
            code: item?.diseaseCode || "",
            name: item?.diseaseName || "",
          }}
          onChange={event(`${data.name}OnChangText`, {
            index: rowIdx,
            name: "diseasename",
          })}
          onInputChange={(e, value, reason) => {
            if (reason === "input") {
              data.dispatch({
                type: "getNameDisease",
                value,
                name: "diagnosis",
                nameText: "name",
                index: rowIdx,
                reason,
              });
            }
          }}
          PopperComponent={(props) => {
            return (
              <div
                {...props}
                style={{
                  maxWidth:
                    document.getElementById("autocomplete_disease_name")
                      .offsetWidth + 30,
                  position: "absolute",
                  display: props.open ? "" : "none",
                }}
                placement={"bottom-start"}
              >
                {props.children}
              </div>
            );
          }}
          // onInputChange={_.debounce((e, value, reason) => {
          //   if (reason === "input") {
          //     data.dispatch({
          //       type: "getNameDisease",
          //       value,
          //       name: "diagnosis",
          //       nameText: "name",
          //       index: rowIdx,
          //       reason,
          //     });
          //   }
          // }, 600)}
          ListboxComponent={(paramsList) => (
            <div
              {...paramsList}
              className={`${paramsList.className} div-scroll-diagnosis`}
              onScroll={() => data?.onScrollListAutoComplete("diagnosis")}
            >
              {paramsList?.children?.map((i) => (
                <li
                  {...i?.props}
                  key={i?.key}
                  className={`${i?.props?.className} scroll-item-diagnosis`}
                />
              ))}
              {data?.loadingAutocomplete?.diagnosis && (
                <div
                  style={{
                    fontWeight: "600",
                    color: "#0B7CE4",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  Đang tải thêm ...{" "}
                </div>
              )}
            </div>
          )}
        />
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, event }) => (
      <TableCell
        className="icon"
        onClick={event("addRowsTableUpdate", { name: data.name })}
      >
        <IconButton>
          <img src={addSquare} />
        </IconButton>
      </TableCell>
    ),
    renderCell: ({ data, event, rowIdx }) => (
      <TableCell className="icon">
        <IconButton
          onClick={event("deleteRowsTableUpdate", {
            name: data.name,
            index: rowIdx,
          })}
        >
          <img src={minusSquare} />
        </IconButton>
      </TableCell>
    ),
  },
];

const columnsMedical = [
  {
    label: "Mã QL",
    headerClass: "code-QL",
    renderCell: ({ rowIdx, classes, item, event, data }) => {
      return (
        <TableCell className="code-QL">
          {
            <Autocomplete
              loading
              key={`${rowIdx}_${item.titleBenefit}`}
              noOptionsText={"Không tìm thấy dữ liệu"}
              PopperComponent={(props) => {
                return (
                  <Popper
                    {...props}
                    style={{
                      width: "480px",
                    }}
                    placement={"bottom-start"}
                  >
                    {props.children}
                  </Popper>
                );
              }}
              options={data?.listBenefits || []}
              getOptionLabel={(option) => option?.code_benefit}
              renderOption={(props, option) => (
                <div {...props} style={{ width: "100%" }}>
                  {option?.code_benefit || ""} - {option?.title || ""}
                </div>
              )}
              className={`${classes.autocomplete}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={classes.textField}
                  placeholder="Nhập mã QL"
                />
              )}
              filterOptions={(options, params) => {
                const temp = options.filter((i) => {
                  return (
                    i.code_benefit
                      ?.toLowerCase()
                      ?.includes(params?.inputValue?.toLowerCase()) ||
                    i.title
                      ?.toLowerCase()
                      ?.includes(params?.inputValue?.toLowerCase())
                  );
                });
                return [...temp];
              }}
              value={{
                code_benefit: item?.codeBenefit || "",
                title: item?.titleBenefit || "",
              }}
              onChange={event(`${data.name}OnChangText`, {
                index: rowIdx,
                name: "QL_code",
              })}
            />
          }
        </TableCell>
      );
    },
  },
  {
    label: "Mã thuốc",
    headerClass: "medicine-code",
    renderCell: ({ rowIdx, classes, data, event, item }) => (
      <TableCell className="medicine-code">
        <Autocomplete
          loading
          freeSolo
          disableClearable
          key="medicineCode"
          noOptionsText={
            <div>
              {data?.loadingAutocomplete?.org
                ? "Đang tải thêm ... "
                : "Không có dữ liệu"}
            </div>
          }
          loadingText={
            data?.loadingAutocomplete?.org
              ? "Đang tải thêm ... "
              : "Không có dữ liệu"
          }
          PopperComponent={(props) => {
            return (
              <div
                {...props}
                style={{
                  width: "400px",
                  position: "absolute",
                  display: props.open ? "" : "none",
                }}
                placement={"bottom-start"}
              >
                {props.children}
              </div>
            );
          }}
          options={data?.listNameMedicine || []}
          getOptionLabel={(option) => option?.code}
          renderOption={(props, option) => (
            <div {...props} style={{ width: "100%" }}>
              {option?.code || ""} - {option?.name || ""}
            </div>
          )}
          className={`${classes.autocomplete}`}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              className={classes.textField}
              placeholder="Nhập mã thuốc"
            />
          )}
          value={{
            code: item?.medicineCode || "",
            name: item?.medicineName || "",
          }}
          onChange={event(`${data.name}OnChangText`, {
            index: rowIdx,
            name: "medicineNameCode",
          })}
          onInputChange={(e, value, reason) => {
            if (reason === "input") {
              data.dispatch({
                type: "getNameMedicine",
                value,
                nameText: "code",
                index: rowIdx,
              });
            }
          }}
          // onInputChange={_.debounce((e, value, reason) => {
          //   if (reason === "input") {
          //     data.dispatch({
          //       type: "getNameMedicine",
          //       value,
          //       nameText: "code",
          //       index: rowIdx,
          //     });
          //   }
          // }, 700)}
          ListboxComponent={(paramsList) => (
            <div
              {...paramsList}
              className={`${paramsList.className} div-scroll-prescription`}
              onScroll={() => data?.onScrollListAutoComplete("prescription")}
            >
              {paramsList?.children?.map((i) => (
                <li
                  {...i?.props}
                  key={i?.key}
                  className={`${i?.props?.className} scroll-item-prescription`}
                />
              ))}
              {data?.loadingAutocomplete?.prescription && (
                <div
                  style={{
                    fontWeight: "600",
                    color: "#0B7CE4",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  Đang tải thêm ...{" "}
                </div>
              )}
            </div>
          )}
        />
      </TableCell>
    ),
  },
  {
    label: "Tên thuốc",
    headerClass: "service-name",
    renderCell: ({ rowIdx, classes, data, event, item }) => (
      <TableCell className="service-name">
        <Autocomplete
          loading
          freeSolo
          disableClearable
          id="autocomplete_medicine_name"
          noOptionsText={
            <div>
              {data?.loadingAutocomplete?.org
                ? "Đang tải thêm ... "
                : "Không có dữ liệu"}
            </div>
          }
          loadingText={
            data?.loadingAutocomplete?.org
              ? "Đang tải thêm ... "
              : "Không có dữ liệu"
          }
          options={data?.listNameMedicine || []}
          getOptionLabel={(option) => option?.name}
          renderOption={(props, option) => (
            <div {...props} style={{ width: "100%" }}>
              {option?.code || ""} - {option?.name || ""}
            </div>
          )}
          className={`${classes.autocomplete}`}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              className={classes.textField}
              placeholder="Nhập tên thuốc"
            />
          )}
          value={{
            code: item?.medicineCode || "",
            name: item?.medicineName || "",
          }}
          onChange={event(`${data.name}OnChangText`, {
            index: rowIdx,
            name: "medicineNameCode",
          })}
          PopperComponent={(props) => {
            return (
              <div
                {...props}
                style={{
                  maxWidth:
                    document.getElementById("autocomplete_medicine_name")
                      .offsetWidth + 30,
                  position: "absolute",
                  display: props.open ? "" : "none",
                }}
                placement={"bottom-start"}
              >
                {props.children}
              </div>
            );
          }}
          onInputChange={(e, value, reason) => {
            if (reason === "input") {
              data.dispatch({
                type: "getNameMedicine",
                value,
                nameText: "name",
                index: rowIdx,
              });
            }
          }}
          // onInputChange={_.debounce((e, value, reason) => {
          //   if (reason === "input") {
          //     data.dispatch({
          //       type: "getNameMedicine",
          //       value,
          //       nameText: "name",
          //       index: rowIdx,
          //     });
          //   }
          // }, 800)}
          ListboxComponent={(paramsList) => (
            <div
              {...paramsList}
              className={`${paramsList.className} div-scroll-prescription`}
              onScroll={() => data?.onScrollListAutoComplete("prescription")}
            >
              {paramsList?.children?.map((i) => (
                <li
                  {...i?.props}
                  key={i?.key}
                  className={`${i?.props?.className} scroll-item-prescription`}
                />
              ))}
              {data?.loadingAutocomplete?.prescription && (
                <div
                  style={{
                    fontWeight: "600",
                    color: "#0B7CE4",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  Đang tải thêm ...{" "}
                </div>
              )}
            </div>
          )}
        />
      </TableCell>
    ),
  },
  {
    label: "Số lượng",
    headerClass: "quantity",
    renderCell: ({ rowIdx, classes, item, event, data }) => (
      <TableCell className="quantity">
        <NumberFormat
          customInput={TextField}
          className={classes.textField}
          onChange={event(`${data.name}OnChangText`, {
            index: rowIdx,
            name: "amount",
          })}
          value={item.amount || ""}
          fullWidth
          placeholder="Nhập số lượng"
        />
      </TableCell>
    ),
  },
  {
    label: "Đơn giá",
    headerClass: "unit-price",
    renderCell: ({ rowIdx, classes, data, event, item }) => (
      <TableCell className="unit-price">
        <NumberFormat
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          className={classes.textField}
          placeholder="Nhập đơn giá"
          onChange={event(`${data.name}OnChangText`, {
            index: rowIdx,
            name: "price",
          })}
          value={item.price || ""}
          fullWidth
        />
      </TableCell>
    ),
  },
  {
    label: "Thành tiền",
    headerClass: "into-money",
    renderCell: ({ rowIdx, classes, data, event, item }) => (
      <TableCell className="into-money">
        <NumberFormat
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          className={classes.textField}
          placeholder="Nhập số tiền"
          onChange={event(`${data.name}OnChangText`, {
            index: rowIdx,
            name: "fee",
          })}
          value={item.fee || ""}
          fullWidth
        />
      </TableCell>
    ),
  },
  {
    label: "BH khác",
    headerClass: "paidFee",
    renderCell: ({ rowIdx, classes, data, event, item }) => (
      <TableCell className="paidFee">
        <NumberFormat
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          className={classes.textField}
          placeholder="Nhập số tiền BHK"
          onChange={event(`${data.name}OnChangText`, {
            index: rowIdx,
            name: "paidFee",
          })}
          value={item.paidFee || ""}
          fullWidth
        />
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, event }) => (
      <TableCell className="icon sticky">
        <IconButton onClick={event("addRowsTableUpdate", { name: data.name })}>
          <img src={addSquare} />
        </IconButton>
      </TableCell>
    ),
    renderCell: ({ data, event, rowIdx }) => (
      <TableCell className="icon sticky">
        <IconButton
          onClick={event("deleteRowsTableUpdate", {
            name: data.name,
            index: rowIdx,
          })}
        >
          <img src={minusSquare} />
        </IconButton>
      </TableCell>
    ),
  },
];

const columnsOther = [
  {
    label: "Mã QL",
    headerClass: "code-QL",
    renderCell: ({ rowIdx, classes, item, event, data }) => {
      return (
        <TableCell className="code-QL">
          {
            <Autocomplete
              loading
              key={`${rowIdx}_${item.titleBenefit}`}
              noOptionsText={"Không tìm thấy dữ liệu"}
              PopperComponent={(props) => {
                return (
                  <Popper
                    {...props}
                    style={{
                      width: "480px",
                    }}
                    placement={"bottom-start"}
                  >
                    {props.children}
                  </Popper>
                );
              }}
              options={data?.listBenefits || []}
              getOptionLabel={(option) => option?.code_benefit}
              renderOption={(props, option) => (
                <div {...props} style={{ width: "100%" }}>
                  {option?.code_benefit || ""} - {option?.title || ""}
                </div>
              )}
              className={`${classes.autocomplete}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={classes.textField}
                  placeholder="Nhập mã QL"
                />
              )}
              filterOptions={(options, params) => {
                const temp = options.filter((i) => {
                  return (
                    i.code_benefit
                      ?.toLowerCase()
                      ?.includes(params?.inputValue?.toLowerCase()) ||
                    i.title
                      ?.toLowerCase()
                      ?.includes(params?.inputValue?.toLowerCase())
                  );
                });
                return [...temp];
              }}
              value={{
                code_benefit: item?.codeBenefit || "",
                title: item?.titleBenefit || "",
              }}
              onChange={event(`${data.name}OnChangText`, {
                index: rowIdx,
                name: "QL_code",
              })}
            />
          }
        </TableCell>
      );
    },
  },
  {
    renderHeader: ({ data }) => (
      <TableCell className="service-name">
        {["medical", "bill"].includes(data.name) ? "Tên dịch vụ" : "Tên thuốc"}
      </TableCell>
    ),
    renderCell: ({ rowIdx, classes, data, event, item }) => (
      <TableCell className="service-name">
        {["medical", "bill"].includes(data.name) ? (
          <TextField
            fullWidth
            className={classes.textField}
            placeholder="Nhập tên dịch vụ"
            onChange={event(`${data.name}OnChangText`, {
              index: rowIdx,
              name: "serviceName",
            })}
            value={item.serviceName || ""}
          />
        ) : (
          <Autocomplete
            key={"medicineName"}
            loading
            noOptionsText={"Không tìm thấy dữ liệu"}
            freeSolo
            options={data?.listNameMedicine || []}
            inputValue={item?.medicineName}
            loadingText={
              data?.listNameMedicine.length === 0 ? "No Option" : "...Loading"
            }
            onChange={event(`${data.name}OnChangText`, {
              index: rowIdx,
              name: "medicineName",
            })}
            getOptionLabel={(option) => option?.name || ""}
            className={classes.autocomplete}
            renderInput={(params) => (
              <TextField {...params} fullWidth className={classes.textField} />
            )}
            onInputChange={_.debounce(
              event(`getNameMedicine`, {
                index: rowIdx,
              }),
              300
            )}
          />
        )}
      </TableCell>
    ),
  },
  {
    label: "Số lượng",
    headerClass: "quantity",
    renderCell: ({ rowIdx, classes, item, event, data }) => (
      <TableCell className="quantity">
        <NumberFormat
          customInput={TextField}
          className={classes.textField}
          placeholder="Nhập số lượng"
          onChange={event(`${data.name}OnChangText`, {
            index: rowIdx,
            name: "amount",
          })}
          value={item?.amount == 0 ? item?.amount : item?.amount || ""}
          fullWidth
        />
      </TableCell>
    ),
  },
  {
    label: "Đơn giá",
    headerClass: "unit-price",
    renderCell: ({ rowIdx, classes, data, event, item }) => (
      <TableCell className="unit-price">
        <NumberFormat
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          className={classes.textField}
          placeholder="Nhập đơn giá"
          onChange={event(`${data.name}OnChangText`, {
            index: rowIdx,
            name: "price",
          })}
          value={item.price || ""}
          fullWidth
        />
      </TableCell>
    ),
  },
  {
    label: "Thành tiền",
    headerClass: "into-money",
    renderCell: ({ rowIdx, classes, data, event, item }) => (
      <TableCell className="into-money">
        <NumberFormat
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          className={classes.textField}
          placeholder="Nhập số tiền"
          onChange={event(`${data.name}OnChangText`, {
            index: rowIdx,
            name: "fee",
          })}
          value={item.fee || ""}
          fullWidth
        />
      </TableCell>
    ),
  },
  {
    label: "BH khác",
    headerClass: "paidFee",
    renderCell: ({ rowIdx, classes, data, event, item }) => (
      <TableCell className="paidFee">
        <NumberFormat
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          className={classes.textField}
          placeholder="Nhập số tiền BHK"
          onChange={event(`${data.name}OnChangText`, {
            index: rowIdx,
            name: "paidFee",
          })}
          value={item.paidFee || ""}
          fullWidth
        />
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, event }) => (
      <TableCell className="icon">
        <IconButton onClick={event("addRowsTableUpdate", { name: data.name })}>
          <img src={addSquare} />
        </IconButton>
      </TableCell>
    ),
    renderCell: ({ data, event, rowIdx }) => (
      <TableCell className="icon">
        <IconButton
          onClick={event("deleteRowsTableUpdate", {
            name: data.name,
            index: rowIdx,
          })}
        >
          <img src={minusSquare} />
        </IconButton>
      </TableCell>
    ),
  },
];
