import api from "../configs/api";

const getGCNList = (data) => {
  return api
    .apiForm("POST", "/api/v2/contract/list", data)
    .then((res) => res)
    .catch((err) => err.response);
};

const getDetailGCN = (id) => {
  return api
    .apiForm("GET", `/api/v2/contract/${id}/detail`)
    .then((res) => res)
    .catch((err) => err.response);
};

const getProductList = (data) => {
  return api
    .apiForm("POST", "/api/v2/product/all-packages/list", data)
    .then((res) => res)
    .catch((err) => err.response);
};

const getProductListFilter = (data) => {
  return api
    .apiForm("POST", "/api/v2/product/filter/list", data)
    .then((res) => res)
    .catch((err) => err.response);
};

const getOrganizationList = (data) => {
  return api
    .apiForm("POST", "/api/v2/organization/list", data)
    .then((res) => res)
    .catch((err) => err.response);
};

const getProductPackageList = (data) => {
  return api
    .apiForm("POST", "/api/v2/product-package/list", data)
    .then((res) => res)
    .catch((err) => err.response);
};

const createContract = (data) => {
  return api
    .apiForm("POST", "/api/v2/contract", data)
    .then((res) => res)
    .catch((err) => err.response);
};

const updateContract = (data) => {
  return api
    .apiForm("PUT", "/api/v2/contract", data)
    .then((res) => res)
    .catch((err) => err.response);
};

const addContractByExcel = (data) => {
  return api.apiForm("POST", `/api/v2/contract/import`, data);
};

const getListMerchant = (data) => {
  return api
    .apiForm("POST", "/api/v2/merchant/list", data)
    .then((res) => res)
    .catch((err) => err.response);
};
const getListMerchantFilter = (data) => {
  return api
    .apiForm("POST", "/api/v2/merchant/filter/list", data)
    .then((res) => res)
    .catch((err) => err.response);
};

const createMerchant = (data) => {
  return api
    .apiForm("POST", "/api/v2/merchant/", data)
    .then((res) => res)
    .catch((err) => err.response);
};

const changeStatus = (data) => {
  return api
    .apiForm("PUT", "/api/v2/contract/status", data)
    .then((res) => res)
    .catch((err) => err.response);
};

export {
  getGCNList,
  getDetailGCN,
  getProductList,
  getOrganizationList,
  getProductPackageList,
  createContract,
  updateContract,
  addContractByExcel,
  getListMerchant,
  getListMerchantFilter,
  createMerchant,
  changeStatus,
  getProductListFilter,
};
