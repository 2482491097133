import { TableCell } from "@mui/material";
import DefaultIcon from "../assets/logo-default.svg";
import { formatDate } from "../utils";

export const columns = [
  {
    label: "STT",
    headerClass: "center",
    renderCell: ({ rowIdx, colIdx }) => (
      <TableCell className="center" key={colIdx}>
        {rowIdx + 1}
      </TableCell>
    ),
  },
  {
    label: "Logo",
    headerClass: "center",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell className="center" key={colIdx}>
        <img src={item?.url_logo || DefaultIcon} alt="logo" className="logo" />
      </TableCell>
    ),
  },
  {
    label: "ID",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell key={colIdx}>{item?.code || ""}</TableCell>
    ),
  },
  {
    label: "Công ty bảo hiểm",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell key={colIdx}>{item?.title || ""}</TableCell>
    ),
  },
  {
    label: "Số lượng sản phẩm",
    headerClass: "center",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell className="center" key={colIdx}>
        {item?.count_number_claim_product || "0"}
      </TableCell>
    ),
  },
  {
    label: "Ngày tham gia",
    headerClass: "center",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell className="center" key={colIdx}>
        {formatDate(item?.created_date)}
      </TableCell>
    ),
  },
];
