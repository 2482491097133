import moment from "moment";

import { cloneDeep, get, set, isString } from "lodash";
import { SSF, read, utils } from "xlsx";
import {
  getListOrg,
  getListProductParent,
  getListMerchant,
} from "../../../apis/dashboardApi";

import {
  getListClaimReport,
  getListClaimReportWithType,
  getDetailClaim,
  getListProductReport,
  getListMerchantReport,
} from "../../../apis/claimReportApi";

const delay = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 800);
  });

export default function reportReducer(props) {
  const { state, action, dispatch, history } = props;
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const userTypeFromProfile = user?.extra_info?.userType || "";
  const isGlobalcare = userTypeFromProfile === "globalcare";
  const orgIdFromProfile = user?.extra_info?.org_id || "";

  const cases = {
    initail: async () => {
      const modalDate = {
        option: "byMonth",
      };
      const dataFiter = {
        claim: {
          optionModalDate: "byMonth",
          valueTextDate: `Theo tháng: ${moment().format("MM/YYYY")} `,
          typeAccount: "all",
          statusId: 0,
          from: moment().startOf("month").format("YYYY-MM-DD"),
          to: moment().endOf("month").format("YYYY-MM-DD"),
        },
        product: {
          optionModalDate: "byMonth",
          valueTextDate: `Theo tháng: ${moment().format("MM/YYYY")} `,
          typeAccount: "all",
          from: moment().startOf("month").format("YYYY-MM-DD"),
          to: moment().endOf("month").format("YYYY-MM-DD"),
        },
        merchant: {
          optionModalDate: "byMonth",
          valueTextDate: `Theo tháng: ${moment().format("MM/YYYY")} `,
          typeAccount: "all",
          from: moment().startOf("month").format("YYYY-MM-DD"),
          to: moment().endOf("month").format("YYYY-MM-DD"),
        },
      };

      dispatch("onLoading");

      const res = {};

      res.listOrg = [{ title: "Tất Cả", id: 0 }];
      res.listProductParent = [{ title: "Tất Cả", id: 0 }];

      const params = {
        orgId: isGlobalcare ? null : orgIdFromProfile,
        productIdParent: null,
        insuranceCodes: ["con_nguoi"],
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().endOf("month").format("YYYY-MM-DD"),
        limit: 20,
        offset: 0,
      };

      const listClaimApi = ["bvpk", "provider", "merchant"].includes(
        userTypeFromProfile
      )
        ? await getListClaimReportWithType(userTypeFromProfile, params)
        : await getListClaimReport(params);

      res.listClaimReport = get(listClaimApi, "data.result.data", []) || [];
      res.pageTable = {
        claim: {
          total: get(listClaimApi, "data.result.total"),
          count: Math.ceil(get(listClaimApi, "data.result.total") / 20),
          page: 0,
        },
      };

      const payloadApi = {
        claim: {
          orgId: 0,
          productIdParent: 0,
          from: moment().startOf("month").format("YYYY-MM-DD"),
          to: moment().endOf("month").format("YYYY-MM-DD"),
        },
        product: {
          orgId: 0,
          statusId: 1,
          from: moment().startOf("month").format("YYYY-MM-DD"),
          to: moment().endOf("month").format("YYYY-MM-DD"),
        },
        merchant: {
          orgId: 0,
          from: moment().startOf("month").format("YYYY-MM-DD"),
          to: moment().endOf("month").format("YYYY-MM-DD"),
        },
      };
      dispatch("getDataFilter");
      dispatch("getDataProduct", { payloadApi });
      dispatch("getDataMerchant", { payloadApi });

      return {
        loading: false,
        modalDate,
        dataFiter,
        payloadApi,
        ...res,
      };
    },

    getDataFilter: async () => {
      const res = {};
      const [listOrgApi, listProductParentApi] = await Promise.all([
        getListOrg({ limit: 10, offset: 0, org_type: null }),
        getListProductParent({
          limit: 10,
          offset: 0,
          statusId: 1,
        }),
      ]);

      res.listOrg = [
        { title: "Tất Cả", id: 0 },
        ...(get(listOrgApi, "data.result.data") || []),
      ];
      res.listProductParent = [
        { title: "Tất Cả", id: 0 },
        ...(get(listProductParentApi, "data.result.data") || []),
      ];
      res.payloadAutocomplete = {
        org: {
          page: 1,
          total: get(listOrgApi, "data.result.total") || 0,
        },
        product: {
          page: 1,
          total: get(listProductParentApi, "data.result.total") || 0,
        },
      };

      return res;
    },

    getDataClaim: async () => {
      const { payloadApi, pageTable } = state;

      dispatch("onLoading");

      const params = {
        insuranceCodes: ["con_nguoi"],
        orgId: isGlobalcare
          ? payloadApi?.claim?.orgId || null
          : orgIdFromProfile,
        keyword: payloadApi?.claim?.keyword,
        statusIds: payloadApi?.claim?.statusIds,
        productIdParent: payloadApi?.claim?.productIdParent || null,
        from: payloadApi?.claim?.from,
        to: payloadApi?.claim?.to,
        limit: 20,
        offset: 0,
      };

      const dataApi = ["blvp", "provider", "merchant"].includes(
        userTypeFromProfile
      )
        ? await getListClaimReportWithType(userTypeFromProfile, params)
        : await getListClaimReport(params);
      const listClaimReport = get(dataApi, "data.result.data") || [];
      pageTable.claim = {
        total: get(dataApi, "data.result.total"),
        count: Math.ceil(get(dataApi, "data.result.total") / 20),
        page: 0,
      };

      return { loading: false, listClaimReport, pageTable };
    },

    getDataProduct: async () => {
      const { payloadApi, pageTable } = state;

      dispatch("onLoading");

      const params = {
        insuranceCodes: ["con_nguoi"],
        orgId: isGlobalcare
          ? payloadApi?.product?.orgId || null
          : orgIdFromProfile,
        keyword: payloadApi?.product?.keyword,
        statusId: 1,
        from: payloadApi?.product?.from,
        to: payloadApi?.product?.to,
        limit: 20,
        offset: 0,
      };

      const dataApi = await getListProductReport(params);
      const listProductReport = get(dataApi, "data.result.data") || [];
      pageTable.product = {
        total: get(dataApi, "data.result.total"),
        count: Math.ceil(get(dataApi, "data.result.total") / 20),
        page: 0,
      };

      return { loading: false, listProductReport, pageTable };
    },

    getDataMerchant: async () => {
      const { payloadApi, pageTable } = state;

      dispatch("onLoading");

      const params = {
        insuranceCodes: ["con_nguoi"],
        keyword: payloadApi?.merchant?.keyword,
        orgId: isGlobalcare
          ? payloadApi?.merchant?.orgId || null
          : orgIdFromProfile,
        from: payloadApi?.merchant?.from,
        to: payloadApi?.merchant?.to,
        limit: 20,
        offset: 0,
      };

      const dataApi = await getListMerchantReport(params);
      const listMerchantReport = get(dataApi, "data.result.data") || [];
      pageTable.merchant = {
        total: get(dataApi, "data.result.total"),
        count: Math.ceil(get(dataApi, "data.result.total") / 20),
        page: 0,
      };

      return { loading: false, listMerchantReport, pageTable };
    },

    selectOption: () => {
      const { option } = action;
      const { modalDate } = state;

      const getDataDate = () => {
        switch (option) {
          case "byDate":
            return {
              from: moment().format("YYYY-MM-DD"),
              to: moment().format("YYYY-MM-DD"),
            };
          case "byWeek":
            return {
              from: moment().startOf("week").format("YYYY-MM-DD"),
              to: moment().endOf("week").format("YYYY-MM-DD"),
            };
          case "byMonth":
            return {
              from: moment().startOf("month").format("YYYY-MM-DD"),
              to: moment().endOf("month").format("YYYY-MM-DD"),
            };
          case "byYear":
            return {
              from: moment().startOf("year").format("YYYY-MM-DD"),
              to: moment().endOf("year").format("YYYY-MM-DD"),
            };
          case "option":
            return {
              from: moment().format("YYYY-MM-DD"),
              to: moment().format("YYYY-MM-DD"),
            };

          default:
            return {};
        }
      };

      if (option === "option") {
        modalDate.dataDateOptionFrom = moment();
        modalDate.dataDateOptionTo = moment();
      }
      modalDate.option = option;
      modalDate.from = getDataDate().from || null;
      modalDate.to = getDataDate().to || null;
      modalDate.dataDate = moment();

      return { modalDate };
    },

    onChangeDate: () => {
      const { date, actionDate } = action;
      const { modalDate } = state;
      modalDate.dataDate = date;

      if (actionDate == "day") {
        modalDate.from = moment(date).format("YYYY-MM-DD");
        modalDate.to = moment(date).format("YYYY-MM-DD");

        return { modalDate };
      }

      if (actionDate === "byWeek") {
        modalDate.from = moment(date).startOf("week").format("YYYY-MM-DD");
        modalDate.to = moment(date).endOf("week").format("YYYY-MM-DD");
      }

      if (actionDate === "byMonth") {
        modalDate.from = moment(date).startOf("month").format("YYYY-MM-DD");
        modalDate.to = moment(date).endOf("month").format("YYYY-MM-DD");
      }

      if (actionDate === "byYear") {
        modalDate.from = moment(date).startOf("year").format("YYYY-MM-DD");
        modalDate.to = moment(date).endOf("year").format("YYYY-MM-DD");
      }

      if (actionDate === "option-from") {
        modalDate.from = moment(date).format("YYYY-MM-DD");
        modalDate.dataDateOptionFrom = date;
        modalDate.dataDate = null;
      }

      if (actionDate === "option-to") {
        modalDate.to = moment(date).format("YYYY-MM-DD");
        modalDate.dataDateOptionTo = date;
        modalDate.dataDate = null;
      }

      return { modalDate };
    },

    onSubmitModalDate: async () => {
      const { modalDate, dataFiter, payloadApi, tabs } = state;

      const setDate = () => {
        switch (modalDate?.option) {
          case "today":
            return {
              from: moment().format("YYYY-MM-DD"),
              to: moment().format("YYYY-MM-DD"),
              valueTextDate: `Hôm nay: ${moment().format("DD/MM/YYYY")}`,
            };
          case "yesterday":
            return {
              from: moment().subtract(1, "days").format("YYYY-MM-DD"),
              to: moment().subtract(1, "days").format("YYYY-MM-DD"),
              valueTextDate: `Hôm qua: ${moment()
                .subtract(1, "days")
                .format("DD/MM/YYYY")}`,
            };
          case "in7Day":
            return {
              from: moment().subtract(7, "days").format("YYYY-MM-DD"),
              to: moment().format("YYYY-MM-DD"),
              valueTextDate: `Trong 7 ngày`,
            };
          case "in30Day":
            return {
              from: moment().subtract(30, "days").format("YYYY-MM-DD"),
              to: moment().format("YYYY-MM-DD"),
              valueTextDate: `Trong 30 ngày`,
            };
          case "byDate":
            return {
              from: modalDate?.from || null,
              to: modalDate?.to || null,
              valueTextDate: ` Theo ngày: ${moment(modalDate?.dataDate).format(
                "DD/MM/YYYY"
              )}`,
            };
          case "byWeek":
            return {
              from: modalDate?.from || null,
              to: modalDate?.to || null,
              valueTextDate: `Theo tuần `,
            };
          case "byMonth":
            return {
              from: modalDate?.from || null,
              to: modalDate?.to || null,
              valueTextDate: `Theo tháng: ${moment(modalDate.dataDate).format(
                "MM/YYYY"
              )} `,
            };
          case "byYear":
            return {
              from: modalDate?.from || null,
              to: modalDate?.to || null,
              valueTextDate: `Theo năm: ${moment(modalDate.dataDate).format(
                "YYYY"
              )} `,
            };
          case "option":
            return {
              from: modalDate?.from || null,
              to: modalDate?.to || null,
              valueTextDate: `${moment(modalDate.dataDateOptionFrom).format(
                "DD/MM/YYYY"
              )} - ${moment(modalDate.dataDateOptionTo).format("DD/MM/YYYY")} `,
            };

          default:
            return {};
        }
      };

      set(
        dataFiter,
        `${tabs.key}.optionModalDate`,
        cloneDeep(modalDate.option)
      );
      set(dataFiter, `${tabs.key}.from`, setDate().from || null);
      set(dataFiter, `${tabs.key}.to`, setDate().to || null);
      set(dataFiter, `${tabs.key}.valueTextDate`, setDate().valueTextDate);
      set(dataFiter, `${tabs.key}.dataDate`, cloneDeep(modalDate.dataDate));
      set(
        dataFiter,
        `${tabs.key}.dataDateOptionFrom`,
        modalDate?.option === "option"
          ? cloneDeep(modalDate.dataDateOptionFrom) || null
          : null
      );
      set(
        dataFiter,
        `${tabs.key}.dataDateOptionTo`,
        modalDate?.option === "option"
          ? cloneDeep(modalDate.dataDateOptionTo) || null
          : null
      );

      set(payloadApi, `${tabs.key}.from`, cloneDeep(setDate().from));
      set(payloadApi, `${tabs.key}.to`, cloneDeep(setDate().to));

      if (tabs.key === "claim") {
        dispatch({ type: "getDataClaim" }, { payloadApi });
      }
      if (tabs.key === "product") {
        dispatch({ type: "getDataProduct" }, { payloadApi });
      }
      if (tabs.key === "merchant") {
        dispatch({ type: "getDataMerchant" }, { payloadApi });
      }

      return { dataFiter, modalDate: { ...modalDate, open: false } };
    },

    openModalDate: () => {
      const { dataFiter, tabs } = state;
      return {
        modalDate: {
          ...state?.modalDate,
          open: true,
          option: get(dataFiter, `${tabs.key}.optionModalDate`) || "in30Day",
          dataDate:
            cloneDeep(get(dataFiter, `${tabs.key}.dataDate`)) || moment(),
          dataDateOptionFrom:
            cloneDeep(get(dataFiter, `${tabs.key}.dataDateOptionFrom`)) || null,
          dataDateOptionTo:
            cloneDeep(get(dataFiter, `${tabs.key}.dataDateOptionTo`)) || null,
          from: cloneDeep(get(dataFiter, `${tabs.key}.from`)) || null,
          to: cloneDeep(get(dataFiter, `${tabs.key}.to`)) || null,
        },
      };
    },

    closeModalDate: () => ({
      modalDate: {
        ...state?.modalDate,
        open: false,
        option: state?.dataFiter?.optionModalDate || "in30Day",
      },
    }),

    onSelectTypeAccount: async () => {
      const { value } = action;
      const {
        dataFiter,
        payloadAutocomplete,
        loadingAutocomplete,
        payloadApi,
      } = state;

      dataFiter.typeAccount = value;
      dispatch({ type: "onLoadingAutocomplete", name: "orgBr" });
      const listOrgApi = await getListOrg({
        limit: 20,
        offset: 0,
        org_type: value,
      });
      const listOrg = [
        { title: "Tất Cả", id: 0 },
        ...(get(listOrgApi, "data.result.data") || []),
      ];
      payloadAutocomplete.org = {
        page: 1,
        total: get(listOrgApi, "data.result.total") || 0,
      };
      payloadApi.orgId = 0;
      loadingAutocomplete.orgBr = false;

      return {
        dataFiter,
        payloadAutocomplete,
        listOrg,
        loadingAutocomplete,
        payloadApi,
      };
    },

    onChangPayloadApi: () => {
      const { name, value } = action;
      const { payloadApi, tabs, dataFiter } = state;

      if (name === "org") {
        set(payloadApi, `${tabs.key}.orgId`, value);
        dispatch({ type: "setValueAutocomplete", name: "org", value: null });
      }
      if (name === "product") {
        set(payloadApi, `claim.productIdParent`, value);
      }

      if (name === "keyword") {
        set(payloadApi, `${tabs.key}.keyword`, value);
      }

      if (name === "statusId") {
        const getListId = () => {
          switch (value) {
            case 0:
              return null;
            case 1:
              return [1, 2, 3, 4];
            case 2:
              return [5, 6, 9];
            case 3:
              return [7, 8, 10];
            default:
              return null;
          }
        };
        set(payloadApi, `claim.statusIds`, getListId());
        set(dataFiter, "claim.statusId", value);
      }

      if (tabs.key === "claim") {
        dispatch({ type: "getDataClaim" }, { payloadApi });
      }
      if (tabs.key === "product") {
        dispatch({ type: "getDataProduct" }, { payloadApi });
      }
      if (tabs.key === "merchant") {
        dispatch({ type: "getDataMerchant" }, { payloadApi });
      }

      return { payloadApi, dataFiter };
    },

    setValueAutocomplete: () => {
      const { value, name } = action;
      const { payloadAutocomplete } = state;
      if (name === "org") {
        payloadAutocomplete.org = { ...payloadAutocomplete.org, title: value };
      }

      if (name === "merchant") {
        payloadAutocomplete.merchant = {
          ...payloadAutocomplete.merchant,
          title: value,
        };
      }

      return { payloadAutocomplete };
    },

    getMoveListAutocomplete: async () => {
      const { name } = action;
      const {
        payloadAutocomplete,
        loadingAutocomplete,
        listOrg,
        listMerchant,
        dataFiter,
        listProductParent,
      } = state;
      const res = {};
      dispatch({ type: "onLoadingAutocomplete", name });
      const offset = get(payloadAutocomplete, `${name}.page`, 0) * 10;
      if (name === "org") {
        const listOrgApi = await getListOrg({
          limit: 10,
          offset: offset,
          org_type: dataFiter?.typeAccount || null,
          title: get(payloadAutocomplete, `org.title`) || null,
        });
        res.listOrg = [
          ...listOrg,
          ...(get(listOrgApi, "data.result.data") || []),
        ];
        payloadAutocomplete.org = {
          ...payloadAutocomplete.org,
          page: (payloadAutocomplete?.org?.page || 0) + 1,
          total: get(listOrgApi, "data.result.total"),
        };
      }

      if (name === "product") {
        const listProductParentApi = await getListProductParent({
          limit: 10,
          offset: offset,
          statusId: 1,
          keyword: payloadAutocomplete?.product?.keyword || null,
        });
        res.listProductParent = [
          ...listProductParent,
          ...(get(listProductParentApi, "data.result.data") || []),
        ];
        payloadAutocomplete.product = {
          ...payloadAutocomplete.product,
          page: (payloadAutocomplete?.product?.page || 0) + 1,
          total: get(listProductParentApi, "data.result.total"),
        };
      }

      loadingAutocomplete[name] = false;
      return { loadingAutocomplete, ...res };
    },

    onInputChangeOrg: async () => {
      const { value } = action;
      const {
        dataFiter,
        payloadAutocomplete,
        loadingAutocomplete,
        payloadApi,
      } = state;

      await dispatch({ type: "setValueAutocomplete", name: "org", value });

      dispatch({ type: "onLoadingAutocomplete", name: "org" });
      const listOrgApi = await getListOrg({
        limit: 10,
        offset: 0,
        org_type: dataFiter?.typeAccount || null,
        title: value || null,
      });
      const res = {};
      const setTime = await delay();
      if (get(state, "payloadAutocomplete.org.title") !== value) {
        return;
      }
      res.listOrg = [
        { title: "Tất Cả", id: 0 },
        ...(get(listOrgApi, "data.result.data") || []),
      ];
      payloadAutocomplete.org = {
        ...state?.payloadAutocomplete?.org,
        page: 1,
        total: get(listOrgApi, "data.result.total"),
        // title: value,
      };
      payloadApi.orgId = null;
      loadingAutocomplete.org = false;

      return { ...res, payloadAutocomplete, loadingAutocomplete, payloadApi };
    },

    onInputChangeProduct: async () => {
      const { value } = action;
      const { payloadApi, payloadAutocomplete } = state;

      const res = {};
      const listProductParentApi = await getListProductParent({
        limit: 10,
        offset: 0,
        statusId: 1,
        keyword: value,
      });

      res.listProductParent = [
        { title: "Tất Cả", id: 0 },
        ...(get(listProductParentApi, "data.result.data") || []).filter(
          (i) => !(payloadApi?.productIdParents || [])?.includes(i.id)
        ),
      ];
      payloadAutocomplete.product = {
        ...payloadAutocomplete.product,
        page: 1,
        total: get(listProductParentApi, "data.result.total"),
        keyword: value,
      };

      return { ...res, payloadAutocomplete };
    },

    onChangeTabs: () => {
      const { value } = action;
      const { tabs } = state;
      const getData = () => {
        switch (value) {
          case 0:
            return { key: "product", value };
          case 1:
            return { key: "merchant", value };
          case 2:
            return { key: "claim", value };
          default:
            return {};
        }
      };

      return { tabs: { ...getData() } };
    },

    onClickShowDetail: async () => {
      const {
        data: { item },
      } = action;
      dispatch("onLoading");
      const detailClaimApi = await getDetailClaim(userTypeFromProfile, item.id);

      const res = {};
      if (detailClaimApi?.data?.statusCode === 200) {
        const detailClaim = get(detailClaimApi, "data.result") || {};

        const elementPdf = detailClaim?.elements?.find((i) => {
          const url = get(i, "extra_info.documents[0].path") || null;
          return (
            [5, 6, 7, 8, 9, 10].includes(+i.status_id) &&
            !!url &&
            i?.type_id === 521
          );
        });

        if (elementPdf) {
          const url = get(elementPdf, "extra_info.documents[0].path");
          window.open(url, "_blank");
        } else {
          res.snackbar = {
            open: true,
            type: "error",
            message:
              "Có thể bồi thường này chưa có file, vui lòng liên hệ với quản trị viện đễ được hỗ trợ !!",
          };
        }
      }

      return { loading: false, ...res };
    },

    exportFileExcel: async () => {
      const { tabs, pageTable, payloadApi, count } = state;
      dispatch("onLoading");
      const res = {};

      if (tabs.key == "claim") {
        const limit = pageTable?.claim?.total || 500;
        // const params = {
        //   insuranceCodes: ["con_nguoi"],
        //   from: payloadApi?.claim?.from,
        //   to: payloadApi?.claim?.to,
        //   limit,
        //   offset: 0,
        // };
        const params = {
          insuranceCodes: ["con_nguoi"],
          orgId: isGlobalcare
            ? payloadApi?.claim?.orgId || null
            : orgIdFromProfile,
          keyword: payloadApi?.claim?.keyword,
          statusIds: payloadApi?.claim?.statusIds,
          productIdParent: payloadApi?.claim?.productIdParent || null,
          from: payloadApi?.claim?.from,
          to: payloadApi?.claim?.to,
          limit,
          offset: 0,
        };

        const dataApi = ["blvp", "provider", "merchant"].includes(
          userTypeFromProfile
        )
          ? await getListClaimReportWithType(userTypeFromProfile, params)
          : await getListClaimReport(params);
        res.listReportExportExcel = get(dataApi, "data.result.data") || [];
      }

      if (tabs.key == "product") {
        const limit = pageTable?.product?.total || 500;

        // const params = {
        //   insuranceCodes: ["con_nguoi"],
        //   from: payloadApi?.product?.from,
        //   to: payloadApi?.product?.to,
        //   limit,
        //   offset: 0,
        // };

        const params = {
          insuranceCodes: ["con_nguoi"],
          orgId: isGlobalcare
            ? payloadApi?.product?.orgId || null
            : orgIdFromProfile,
          keyword: payloadApi?.product?.keyword,
          statusId: 1,
          from: payloadApi?.product?.from,
          to: payloadApi?.product?.to,
          limit,
          offset: 0,
        };

        const dataApi = await getListProductReport(params);
        res.listReportExportExcel = get(dataApi, "data.result.data") || [];
      }

      if (tabs.key == "merchant") {
        const limit = pageTable?.merchant?.total || 500;

        // const params = {
        //   insuranceCodes: ["con_nguoi"],
        //   from: payloadApi?.merchant?.from,
        //   to: payloadApi?.merchant?.to,
        //   limit,
        //   offset: 0,
        // };
        const params = {
          insuranceCodes: ["con_nguoi"],
          keyword: payloadApi?.merchant?.keyword,
          orgId: isGlobalcare
            ? payloadApi?.merchant?.orgId || null
            : orgIdFromProfile,
          from: payloadApi?.merchant?.from,
          to: payloadApi?.merchant?.to,
          limit: 20,
          offset: 0,
        };

        const dataApi = await getListMerchantReport(params);
        res.listReportExportExcel = get(dataApi, "data.result.data") || [];
      }

      return { loading: false, ...res, count: count + 1 };
    },

    onchangePage: async () => {
      const { page } = action;
      const { pageTable, tabs, payloadApi } = state;

      dispatch("onLoading");

      const res = {};

      if (tabs.key === "claim") {
        const params = {
          insuranceCodes: ["con_nguoi"],
          orgId: isGlobalcare
            ? payloadApi?.claim?.orgId || null
            : orgIdFromProfile,
          keyword: payloadApi?.claim?.keyword,
          statusIds: payloadApi?.claim?.statusIds,
          productIdParent: payloadApi?.claim?.productIdParent || null,
          from: payloadApi?.claim?.from,
          to: payloadApi?.claim?.to,
          limit: 20,
          offset: page * 20 || 0,
        };

        const dataApi = ["blvp", "provider", "merchant"].includes(
          userTypeFromProfile
        )
          ? await getListClaimReportWithType(userTypeFromProfile, params)
          : await getListClaimReport(params);
        res.listClaimReport = get(dataApi, "data.result.data") || [];
        pageTable.claim = {
          total: get(dataApi, "data.result.total"),
          count: Math.ceil(get(dataApi, "data.result.total") / 20),
          page: page || 0,
        };
      }

      if (tabs.key === "product") {
        const params = {
          insuranceCodes: ["con_nguoi"],
          orgId: isGlobalcare
            ? payloadApi?.product?.orgId || null
            : orgIdFromProfile,
          keyword: payloadApi?.product?.keyword,
          statusId: 1,
          from: payloadApi?.product?.from,
          to: payloadApi?.product?.to,
          limit: 20,
          offset: page * 20 || 0,
        };

        const dataApi = await getListProductReport(params);
        res.listProductReport = get(dataApi, "data.result.data") || [];
        pageTable.product = {
          total: get(dataApi, "data.result.total"),
          count: Math.ceil(get(dataApi, "data.result.total") / 20),
          page: page || 0,
        };
      }

      if (tabs.key === "merchant") {
        const params = {
          insuranceCodes: ["con_nguoi"],
          keyword: payloadApi?.merchant?.keyword,
          orgId: isGlobalcare
            ? payloadApi?.merchant?.orgId || null
            : orgIdFromProfile,
          from: payloadApi?.merchant?.from,
          to: payloadApi?.merchant?.to,
          limit: 20,
          offset: page * 20 || 0,
        };

        const dataApi = await getListMerchantReport(params);
        res.listMerchantReport = get(dataApi, "data.result.data") || [];
        pageTable.merchant = {
          total: get(dataApi, "data.result.total"),
          count: Math.ceil(get(dataApi, "data.result.total") / 20),
          page: page || 0,
        };
      }

      return { loading: false, pageTable, ...res };
    },

    //

    onLoadingAutocomplete: () => ({
      loadingAutocomplete: {
        ...state.loadingAutocomplete,
        [action.name]: true,
      },
    }),

    onLoading: () => ({ loading: true }),

    //
  };

  return isString(action.type) && cases[action.type];
}
