export const titlesList = [
  {
    id: 1,
    title: 'Cấp 1'
  }, {
    id: 2,
    title: 'Cấp 2'
  },
  {
    id: 3,
    title: 'Cấp 3'
  },
  {
    id: 4,
    title: 'Cấp 4'
  },
  {
    id: 5,
    title: 'Cấp 5'
  }
]

export const STATUS = [
  {
    id: 1,
    title: 'Chờ thanh toán'
  },
  {
    id: 2,
    title: 'Thanh toán thành công'
  },
  {
    id: 3,
    title: 'Hoàn tất'
  },
  {
    id: 4,
    title: 'Huỷ'
  },
  {
    id: 5,
    title: 'Thanh toán thất bại'
  },
]