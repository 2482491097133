import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    margin: "55px 16px 16px",
    padding: "20px",
    "& .header": {
      color: "#2A3790",
      fontWeight: "600",
      fontSize: 18,
      marginBottom: "20px",
    },
    "& .wrapper": {
      width: "40%",
      display: "flex",
      alignItems: "center",
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .search": {
      justifyContent: "space-between",
      marginBottom: 20,
    },
    "& .wrapper-btn": {
      width: "40%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    "& .button": {
      background: "#3961A0",
      borderRadius: 5,
      color: "#fff",
      padding: "4px 16px",
      height: 45,
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .textfield": {
      width: "80%",
      marginRight: 16,
    },
    "& .icon": {
      marginRight: "12px",
      width: 25,
    },
    "& .box": {
      padding: "15px 10px",
      background: "#FFF",
      border: "1px solid #DCDCDC",
      boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "6px",
      alignItems: "center",
      justifyContent: "flex-start",
      minHeight: "120px",
      "&:hover": {
        cursor: "pointer",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
      },
    },
    "& .logo": {
      width: "55px",
      height: "55px",
      objectFit: "contain",
      marginRight: 8,
      borderRadius: "50%",
      border: "0.5px dashed rgba(0, 0, 0, 0.25)",
    },
    "& .title": {
      fontSize: 17,
      fontWeight: 700,
      color: "#4B4B4B",
    },
    "& .date": {
      fontSize: 15,
      color: "#4B4B4B",
    },
    "& .MuiOutlinedInput-root": {
      height: 45,
    },
  },
  create: {
    margin: "55px 16px 16px",
    padding: 20,
    "& .info": {
      background: "#fff",
      padding: 20,
      marginRight: 20,
      height: "fit-content",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    },
    "& .benefit": {
      background: "#fff",
      padding: 20,
      height: "fit-content",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      maxWidth: "700px",
    },
    "& .title": {
      color: "#4B4B4B",
      fontWeight: 700,
      fontSize: 17,
    },
    "& .center": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .relative": {
      position: "relative",
    },
    "& .logo": {
      width: "30%",
      maxWidth: "150px",
      marginTop: 20,
      borderRadius: "50%",
      "&:hover": {
        cursor: "pointer",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
      },
    },
    "& .progress": {
      width: "30%",
      maxWidth: "150px",
      marginTop: 5,
    },
    "& .mt-5": {
      marginTop: 5,
    },
    "& .mt-15": {
      marginTop: 15,
    },
    "& .button": {
      background: "#3961A0",
      borderRadius: 5,
      color: "#fff",
      padding: "8px 16px",
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .label": {
      color: "#686868",
      margin: "10px 0 8px",
    },
    "& .icon": {
      marginRight: 8,
    },
    "& .table-scroll": {
      overflowY: "auto",
      //   height: "calc(80vh - 20px)",
      margin: "10px 0",
    },
    "& .empty": {
      width: "100%",
    },
    "& .footer": {
      display: "flex",
      justifyContent: "flex-end",
      margin: "15px 2% 0 0",
    },
    "& .MuiOutlinedInput-root": {
      height: 40,
    },
  },
  table: {
    minWidth: "800px",
    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    "& td": {
      textAlign: "left",
      color: "#4B4B4B",
    },
    "& td.code": {
      padding: "0 8px",
      fontWeight: 500,
      background: "#fff",
      width: "150px",
    },

    "& td.isFirst": {
      background: "#09BAFD",
      fontWeight: 700,
    },

    "& td.isSecond": {
      background: "#E3F3FF",
      fontWeight: 500,
    },

    "& td.name-benefits": {
      padding: "0 8px",
    },
  },
  div: {
    paddingBottom: 0,
  },

  header: {
    display: "none",
  },

  headerTable: {
    color: "#fff",
    fontSize: 15,
    fontWeight: 600,
    display: "flex",
    flexDirection: "row",
    background: "#3961A0",
    padding: "8px",
    position: "sticky",
    top: 0,
    zIndex: 3,
    "& .code": {
      width: "150px",
    },
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #D6DEEB",
    background: "white !important",
  },

  headerBar: {
    width: "100%",
    color: "#4B4B4B",
    fontSize: "15px",
    padding: "12px 0 12px 16px",
    background: "white",
    margin: "0 4px",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
    position: "sticky",
    top: "64px",
    zIndex: 2,
  },
}));
