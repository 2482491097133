import React from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField,
} from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import moment from "moment";

import { styledStepHistory } from "../components/step/styledHistory";
import picture from "../../../../../assets/ahamove/picture.svg";
import _ from "lodash";
import ShowImg from "../components/step/DialogShowImg";

export default function DetailHistory(props) {
  const { state, dispatch, isApp, name = "" } = props;
  const classes = styledStepHistory();
  const {
    historyDestination: { data = {}, index },
    elementsHistory = [],
    showImg,
    tabsStatus,
  } = state;

  const renderImg = [
    {
      title: "Hình ảnh hàng hóa/ tài liệu trước khi bị sự cố",
      id: 200,
      typeCode: "goods_document_before_risk",
      listImg: (data.elements || []).find((item) => item.type_id === 200),
    },
    {
      title: "Hình ảnh hàng hóa/ tài liệu sau khi bị sự cố",
      id: 201,
      typeCode: "goods_document_after_risk",
      dontShow: +data?.extra_info?.typeOfAccident === 2,
      listImg: (data.elements || []).find((item) => item.type_id === 201),
    },
    {
      title: "Hình ảnh hóa đơn",
      id: 202,
      typeCode: "bill",
      dontShow: +data?.paymentInfo?.paymentId === 2,
      listImg: (data.elements || []).find((item) => item.type_id === 202),
    },
  ];

  const paymentContent =
    (data.elements || []).find((item) => item.type_id === 203) || {};

  const getListImg = (id, array) => {
    const arrImgUpdate = data?.elements?.find((e) => e.typeId === id);

    const temp = Array.isArray(arrImgUpdate?.extra_info?.documents)
      ? arrImgUpdate?.extra_info?.documents
      : arrImgUpdate?.documents || [];

    const arrayImg = [...array, ...temp];
    return arrayImg;
  };

  const hadUrl = (id, items) => {
    const temp = data?.elements?.find((e) => e.typeId === id);
    const dataUrl = (
      Array.isArray(temp?.extra_info?.documents)
        ? temp?.extra_info?.documents
        : temp?.documents || []
    ).find((ei) => ei?.path === items?.path);

    return !_.isEmpty(dataUrl);
  };

  const temp_signature =
    (data.elements || []).find((item) => item.type_id === 204) || {};

  const signature = Array.isArray(temp_signature?.extra_info?.documents)
    ? temp_signature?.extra_info?.documents[0]
    : _.get(
        data?.elements?.find((i) => i.type_id === 204),
        "documents[0]"
      ) || {};

  return (
    <div
      style={{
        minHeight: isApp ? "calc(100vh - 165px)" : "calc(100vh - 125px)",
      }}
    >
      <div className={classes.contaiDetialH}>
        <div className="titleHead">THÔNG TIN SỰ CỐ</div>
        <Row
          title="Ngày xảy ra sự cố"
          value={moment(data?.extra_info?.riskDate).format("DD/MM/YYYY") || ""}
        />
        <Row
          title="Giờ xảy ra sự cố"
          value={data?.extra_info?.riskTime || ""}
        />
        <Row title="Mô tả diễn biến" value={data?.extra_info?.describe || ""} />
        <div className="titleHead">KHAI BÁO GIÁ TRỊ HÀNG HÓA</div>
        <Row
          title="Giá trị hàng hoá"
          value={
            `${data?.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " VNĐ"
          }
        />
        <div className="titleHead">ĐỐI TƯỢNG BẢO HIỂM</div>
        <div style={{ fontSize: "15px", fontWeight: "500" }}>
          {getInsuranceObj(data?.extra_info?.insuranceObj)}
        </div>
        <div className="titleHead">RỦI RO GẶP PHẢI</div>
        <div style={{ fontSize: "15px", fontWeight: "500" }}>
          {+data?.extra_info?.typeOfAccident ||
          +data?.extra_info?.riskType === 1
            ? "Hư hỏng"
            : "Mất mát"}
        </div>
      </div>
      <div style={{ width: "100%", height: "8px", background: "#FEF3ED" }} />
      <div className={classes.containImg}>
        <Accordion>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownOutlinedIcon style={{ color: "#284078" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span className="titleAccodion">HÌNH ẢNH</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.boxImg}>
              {renderImg.map((dataMap, indx) =>
                !dataMap.dontShow ? (
                  <div className="divImg" key={dataMap.title}>
                    <div
                      key={dataMap.title + `line${indx}`}
                      className="title"
                      style={{
                        position: "sticky",
                        left: 0,
                        background: "white",
                        zIndex: 5,
                        fontWeight: "500",
                      }}
                    >
                      {dataMap?.title}
                    </div>
                    <div className={classes.lineImg} key={indx}>
                      {+data?.statusId === 4 && name != "detail_365" && (
                        <div
                          key={`addImage${indx}`}
                          style={{
                            position: "sticky",
                            left: 0,
                            background: "white",
                            zIndex: 5,
                          }}
                          className={classes.divImg}
                          onClick={() => {
                            window.document
                              .getElementById(`addImage${indx}`)
                              .click();
                          }}
                        >
                          <input
                            type="file"
                            hidden
                            id={`addImage${indx}`}
                            accept="image/*"
                            onChange={(e) => {
                              dispatch({
                                type: "addImgUpdate",
                                e,
                                id: dataMap.id,
                                typeCode: dataMap.typeCode,
                              });
                            }}
                          />
                          <img src={picture} alt="picture" />
                          <span className="spanPicture">
                            Tải ảnh lên từ thiết bị
                          </span>
                        </div>
                      )}
                      {getListImg(
                        dataMap.id,
                        Array.isArray(dataMap?.listImg?.extra_info?.documents)
                          ? dataMap?.listImg?.extra_info?.documents
                          : dataMap?.listImg?.documents || []
                      ).map(
                        (items, index) => (
                          <div className={classes.divImg} key={`img${index}`}>
                            <img
                              key={index}
                              src={items?.path}
                              alt={`picture ${index}`}
                              width="98%"
                              height={75}
                              onClick={() =>
                                dispatch({
                                  type: "showImg",
                                  data: dataMap,
                                  url: items?.path,
                                  indexImg: index,
                                  name: "history",
                                  showCancle: hadUrl(dataMap.id, items),
                                })
                              }
                            />
                            {hadUrl(dataMap.id, items) && (
                              <CancelOutlinedIcon
                                className="icon"
                                fontSize="small"
                                style={{ zIndex: 11 }}
                                onClick={() =>
                                  dispatch({
                                    type: "deleteImg",
                                    name: "history",
                                    items,
                                    dataMap,
                                    id: data.id,
                                    index,
                                  })
                                }
                              />
                            )}
                          </div>
                        ),
                        this
                      )}
                    </div>
                  </div>
                ) : null
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div style={{ width: "100%", height: "8px", background: "#FEF3ED" }} />
      <div className={classes.containImg}>
        <Accordion>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownOutlinedIcon style={{ color: "#284078" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span className="titleAccodion">THÔNG TIN NGƯỜI THỤ HƯỞNG</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.boxTextRow}>
              <Row
                capitalize
                title="Họ và tên"
                value={data?.extra_info?.beneficiaryName || ""}
              />
              <Row
                title="Số điện thoại"
                value={data?.extra_info?.beneficiaryPhone || ""}
              />
              <Row
                title="Ngân hàng"
                value={data?.extra_info?.beneficiaryBank || ""}
              />
              <Row
                title="Số tài khoản"
                value={data?.extra_info?.beneficiaryBankAcountNumber || ""}
              />
              <Row
                title="Email"
                value={data?.extra_info?.beneficiaryEmail || ""}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div style={{ width: "100%", height: "8px", background: "#FEF3ED" }} />
      <div className={classes.containImg}>
        <Accordion>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownOutlinedIcon style={{ color: "#284078" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span className="titleAccodion">THÔNG TIN PHÊ DUYỆT</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.boxTextRow}>
              <Row
                title="Số tiền phê duyệt"
                value={
                  `${paymentContent?.value || ""}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "."
                  ) + " VNĐ"
                }
              />
              <div className={classes.ellipsis}>
                <span className="titleSpan">Nội dung</span>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  value={paymentContent?.text || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                  className="border-none"
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div style={{ width: "100%", height: "8px", background: "#FEF3ED" }} />
      <div className={classes.signature}>
        <div className="yourSigna">
          Chữ ký của bạn <span style={{ color: "red" }}>*</span>
        </div>
        <div className="signa">
          {signature?.path && (
            <img
              alt="signature"
              src={signature?.path || ""}
              style={{ objectFit: "contain", maxHeight: 166, maxWidth: "90%" }}
            />
          )}
        </div>
      </div>
      <ShowImg {...{ dispatch, showImg }} />
    </div>
  );
}

const Row = (props) => {
  const { title, value, capitalize } = props;

  return (
    <div className="rowDiv">
      <span className="titleSpan">{title}</span>
      <span
        className={capitalize ? "valueSpan capitalize" : "valueSpan"}
        title={value}
      >
        {value}
      </span>
    </div>
  );
};

const getInsuranceObj = (data) => {
  switch (+data) {
    case 1:
      return "Hàng hoá";
    case 2:
      return "Hàng hoá có hoá đơn";
    case 3:
      return "Hàng hoá không có hoá đơn";
    case 4:
      return "Mất mát";

    default:
      break;
  }
};
