import React from "react";
import { Button, Dialog, DialogContent, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";

import warning from "../../../../assets/images/notifica_warning.svg";
import sussecc from "../../../../assets/images/notifica_success.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogNotifica(props) {
  const classes = useStyle();
  const { data, dispatch } = props;
  const { open = false, message = "", type = "success" } = data;

  return (
    <React.Fragment>
      <Dialog
        open={Boolean(open)}
        TransitionComponent={Transition}
        onClose={() => dispatch("onCloseDialogNotifica")}
      >
        <DialogContent className={classes.content}>
          <div className="div-img">
            {type === "warning" && <img src={warning} width={80} />}
            {type === "success" && <img src={sussecc} width={80} />}
            {/* {type === "error" && (
              <img src={true ? warning : sussecc} width={80} />
            )} */}
          </div>
          <div className="message-text">
            {(message || []).map((i) => (
              <div>{i}</div>
            ))}
          </div>
          <div className="div-button">
            {type === "warning" && (
              <Button
                onClick={() => dispatch("onCloseDialogNotifica")}
                className="btn no"
              >
                Không
              </Button>
            )}
            <Button
              className="btn yes"
              onClick={() => {
                if (type === "warning") {
                  dispatch("resetPassword");
                } else {
                  dispatch("onCloseDialogNotifica");
                }
              }}
            >
              {type === "warning" ? "có" : "Đóng"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const useStyle = makeStyles(() => ({
  content: {
    minWidth: "300px",

    "& .div-img": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginBottom: "24px",
    },

    "& .message-text": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginBottom: "24px",
      fontSize: "15px",
      fontWeight: "600",
    },

    "& .div-button": {
      width: "100%",
      display: "flex",
      justifyContent: "center",

      "& .btn": { fontSize: "15px", fontWeight: "400", padding: "6px 18px" },
      "& .yes": {
        color: "white",
        border: "1px solid #3961A0",
        background: "#3961A0",
      },
      "& .no": {
        color: "#3961A0",
        border: "1px solid #3961A0",
        marginRight: "12px",
      },
    },
  },
}));
