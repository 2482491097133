import _ from "lodash";
import {
  getDetails,
  uploadImg,
  updateLicese,
  acceptByClient,
  rejectByClient,
  deleteS3Document,
} from "../../../../../apis/health";
import { getListLicenseById, listLicense } from "../utils";

export default function yourClaimReducer({
  action,
  state,
  dispatch,
  location,
  queryString,
  apptodetail,
}) {
  const q = queryString.parse(location?.search);
  const id = q.id;
  const cases = {
    initial: async () => {
      const { item } = action;
      dispatch("onLoading");
      const res = {};
      const apiDetail = await getDetails(id);

      if (apiDetail instanceof Error) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Vui lòng thử lại",
          },
        };
      }

      if (apiDetail?.data?.statusCode === 200) {
        res.detail = apiDetail?.data?.result || {};
      }

      return {
        curenPage: "status-claim",
        headerTitle: null,
        loading: false,
        ...res,
      };
    },

    openPageInfor: () => ({
      curenPage: "infor",
      headerTitle: null,
    }),

    openLinks: () => {
      const { detail, snackbar, arrayLinks } = state;
      const { elements = [] } = detail;

      const links = arrayLinks[0]?.documents || [];

      if (links.length === 0) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "warning",
            message: "Hiện không có Link hóa đơn diện tử nào",
          },
        };
      }

      if (links.length === 1) {
        dispatch({ type: "openPageShowLink", index: 0 });
        return;
      }

      dispatch({ type: "openPageShowImg", name: "link" });

      return {};
    },

    openPageShowLink: () => ({
      curenPage: "show-link",
      indexLink: action.index,
      headerTitle: "Hóa đơn điện tử",
    }),

    openLecesePage: () => ({
      curenPage: "license",
      headerTitle: "Bổ sung chứng từ",
    }),

    submitPress: () => {
      const { curenPage, nameShowImgPage, arrayLinks } = state;
      const links = arrayLinks[0]?.documents || [];
      switch (curenPage) {
        case "status-claim":
          apptodetail
            ? window.ReactNativeWebView.postMessage("onGoBack")
            : window.history.back();

          break;
        case "infor":
          return {
            curenPage: "status-claim",
            headerTitle: null,
          };
        case "show-license":
          return {
            curenPage: "infor",
            headerTitle: null,
          };
        case "show-link":
          return {
            curenPage: links.length === 1 ? "infor" : "show-license",
            headerTitle:
              links.length === 1
                ? null
                : nameShowImgPage === "img"
                ? "Hình ảnh chứng từ"
                : "Hóa đơn điện tử",
          };
        case "license":
          return {
            curenPage: "status-claim",
            headerTitle: null,
          };

        default:
          break;
      }
    },

    onChangeAccodior: () => {
      const { name } = action;
      return { [name]: !state[name] };
    },

    openDaShowImg: () => {
      const { typeId, item } = action;
      const { detail, snackbar } = state;
      const { elements = [] } = detail;
      const res = {};
      if (typeId) {
        const temp = elements.find((i) => i.type_id === typeId);
        res.listImgShow = _.isEmpty(temp)
          ? []
          : temp?.extra_info?.documents.map((i) => i.path);
        res.indexImgShow = { index: 1, path: res.listImgShow[0] || "" };
      } else {
        const temp = _.get(item, "licese[0].extra_info.documents") || [];
        res.listImgShow = _.isEmpty(temp) ? [] : temp?.map((i) => i.path);
        res.indexImgShow = { index: 1, path: res.listImgShow[0] || "" };

        if (res.listImgShow.length === 0) {
          return {
            snackbar: {
              ...snackbar,
              open: true,
              type: "warning",
              message: "Hiện không có hình ảnh nào",
            },
          };
        }
      }
      return { showDialogImg: true, ...res };
    },

    openShowImgDialogInline: () => {
      const { data, documents } = action;
      const { showDialogImg } = state;
      const listImgShow = documents.map((i) => i.path);
      const indexImgShow = {
        index: data.index + 1,
        path: data?.itemImg?.path || "",
      };

      return {
        showDialogImg: {
          ...showDialogImg,
          open: true,
        },
        listImgShow,
        indexImgShow,
      };
    },

    closeDaShowImg: () => ({ showDialogImg: false }),

    getListImgLicense: () => {
      const { detail } = state;
      const { elements = [] } = detail;

      const listLiceseContent = [
        ...getListLicenseById({
          typeOfInsurance: detail?.extraInfo?.typeOfInsurance,
          typeOfIndimnity: detail?.extraInfo?.typeOfIndimnity,
          typeOfRisk:
            detail?.extraInfo?.typeOfInsurance == 1
              ? detail?.extraInfo?.typeOfRisk
              : detail?.extraInfo?.typeOfTreatment,
        }),
      ];

      const listTypeIdCurrent = listLiceseContent.map((i) => i.typeId);
      const listAllTypeIdLicense = listLicense.map((i) => i.typeId);

      elements.forEach((el) => {
        if (
          !listTypeIdCurrent.includes(el.type_id) &&
          listAllTypeIdLicense.includes(el.type_id)
        ) {
          const temp = listLicense.find(
            (license) => license.typeId == el?.type_id
          );
          listTypeIdCurrent.push(temp.typeId);
          listLiceseContent.push({
            title: temp.title,
            typeId: temp.typeId,
            typeCode: temp.typeCode,
          });
        }
      });

      const listLicese = listLiceseContent.map((i) => {
        const temp = elements
          .filter(
            (e) =>
              e.type_id === i.typeId && e?.extra_info?.documents?.length > 0
          )
          ?.reverse();
        return { ...i, licese: [...temp] };
      });

      const listLinks = elements.filter((l) => l.type_id === 516);
      const arrayLinks = listLinks.map((i) => {
        const documents = (i?.extra_info?.documents || []).map((a) => a.path);
        return { created_date: i.created_date, documents };
      });
      return { listLicese, arrayLinks };
    },

    openPageShowImg: () => ({
      curenPage: "show-license",
      nameShowImgPage: action.name,
      headerTitle:
        action.name === "img" ? "Hình ảnh chứng từ" : "Hóa đơn điện tử",
    }),

    changeIndexImg: () => {
      const { listImgShow, indexImgShow } = state;
      const { name } = action;
      const res = {};
      if (listImgShow.length === 1) {
        return;
      }

      if (name === "next") {
        res.indexImgShow = {
          index:
            indexImgShow.index === listImgShow.length
              ? 1
              : indexImgShow.index + 1,
          path:
            indexImgShow.index === listImgShow.length
              ? listImgShow[0]
              : listImgShow[indexImgShow.index],
        };
      } else {
        res.indexImgShow = {
          index:
            indexImgShow.index === 1
              ? listImgShow.length
              : indexImgShow.index - 1,
          path:
            indexImgShow.index === 1
              ? listImgShow[listImgShow.length - 1]
              : listImgShow[indexImgShow.index - 2],
        };
      }

      return res;
    },

    getListAddLicese: () => {
      const { detail } = state;
      const { elements, extraInfo } = detail;

      const listLiceseContent = listLicense.map((i) => ({
        title: i.title,
        typeId: i.typeId,
        typeCode: i.typeCode,
      }));
      const listLiceseId = listLicense.map((i) => i.typeId);

      const temp = elements.filter((i) => {
        if (i.type_id === 520 && i?.extra_info?.elements_amend?.length > 0) {
          const listIdAmend = i?.extra_info?.elements_amend.map(
            (j) => j.typeId
          );
          const tempElemt = elements.find((e) => {
            const timeDate =
              new Date(e.created_date) - new Date(i.created_date);
            return listIdAmend.includes(e.type_id) && timeDate > 0;
          });

          return !tempElemt;
        }
        // return i.type_id === 520 && i?.extra_info?.elements_amend?.length > 0;
      });

      const additionLicesees = [];

      temp.forEach((i) => {
        (i?.extra_info?.elements_amend || []).forEach((e) => {
          const itemLiceseContent = listLiceseContent.find(
            (j) => j.typeId === e.typeId
          );
          additionLicesees.push({
            ...itemLiceseContent,
            text: e.text,
            documents: [],
          });
        });
      });

      // const additionLicesees = (temp?.extra_info?.elements_amend || []).map(
      //   (i) => {
      //     // const itemLicese = elements.find((e) => e.type_id === i.typeId);
      //     const itemLiceseContent = listLiceseContent.find(
      //       (j) => j.typeId === i.typeId
      //     );
      //     return {
      //       ...itemLiceseContent,
      //       text: i.text,
      //       documents: [],
      //       // documents: _.get(itemLicese, "extra_info.documents") || [],
      //     };
      //   }
      // );

      const elementsAmendId = temp.map((i) => i.id);

      return { additionLicesees, elementsAmendId };
    },

    addImg: async () => {
      const { e, index } = action;
      const { additionLicesees } = state;
      const files = e.target.files;
      if (files.length === 0) {
        return;
      }
      const res = {};
      dispatch("onLoading");

      const promises = [];

      for (let i = 0; i < files.length; i++) {
        const data = new FormData();
        data.append("document", files[i]);

        promises.push(uploadImg(data));
      }
      const resuiltApi = await Promise.all(promises);
      resuiltApi.forEach((api) => {
        if (api?.data?.statusCode === 200) {
          const dataApi = _.get(api, "data.result[0]") || {};
          const dataImg = {
            path: dataApi?.path,
            size: dataApi?.size,
            isNewImg: true,
          };
          additionLicesees[index]?.documents?.push(dataImg);
        }
        if (api instanceof Error) return;
      });

      return { loading: false, additionLicesees };
    },

    deleteImg: () => {
      const { indexList, index, url } = action;
      const { additionLicesees } = state;
      if (url) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: url },
        });
      }

      const itemImgByindex = additionLicesees[index];
      itemImgByindex.documents.splice(indexList, 1);
      additionLicesees[index] = itemImgByindex;
      return { additionLicesees };
    },

    expandedImg: () => {
      const { name } = action;
      return { [name]: !state[name] };
    },

    submitUpdate: async () => {
      const {
        detail,
        additionLicesees,
        dialogNoti,
        snackbar,
        elementsAmendId,
      } = state;
      let error = false;

      for (let i = 0; i < additionLicesees.length; i++) {
        if (additionLicesees[i].documents.length == 0) {
          error = true;
          break;
        }
      }

      if (error) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "warning",
            message: "Vui lòng bổ sung đầy đủ các chứng từ bên dưới",
          },
        };
      }

      const elements = additionLicesees.map((item) => {
        const imgAfter = detail?.elements.find(
          (i) => i.type_id == item?.typeId
        );

        const documentsAfter = _.get(imgAfter, "extra_info.documents") || [];

        const documents = [
          ...documentsAfter,
          ...(item?.documents || []).map((i) => ({
            path: i.path,
            size: i.size,
          })),
        ];

        return {
          claimId: detail.id,
          typeId: item.typeId,
          typeCode: item.typeCode,
          text: (elementsAmendId || []).join(", ") || "",
          extraInfo: {
            documents: [...documents],
          },
        };
      });
      const params = {
        id: detail.id,
        elements: [
          ...elements,
          {
            typeId: 520,
            typeCode: "action_element",
            extraInfo: {
              documents: [],
            },
          },
        ],
      };

      dispatch("onLoading");
      const dataApi = await updateLicese(params);
      if (dataApi instanceof Error) {
        return {
          dialogNoti: { ...dialogNoti, open: true, type: "error" },
          loading: false,
        };
      }

      if (dataApi?.data?.statusCode === 200) {
        return {
          dialogNoti: {
            ...dialogNoti,
            open: true,
            type: "success",
            textContent: "Bạn đã gửi bổ sung hồ sơ thành công",
          },
          loading: false,
        };
      }
    },

    setOpenDialogRefuse: () => {
      const { dialogRefuseClaim } = state;

      if (dialogRefuseClaim.open) {
        dialogRefuseClaim.textContent = "";
      }

      return {
        dialogRefuseClaim: {
          ...dialogRefuseClaim,
          open: !dialogRefuseClaim.open,
          text: "",
        },
      };
    },

    onChangTextNoteRefuse: () => {
      const { value } = action;
      const { dialogRefuseClaim } = state;
      return { dialogRefuseClaim: { ...dialogRefuseClaim, text: value } };
    },

    sendRequestRefuse: async () => {
      const { dialogRefuseClaim, dialogNoti, snackbar, detail } = state;
      if (!dialogRefuseClaim?.text) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Vui lòng nhập nội dung từ chối",
          },
        };
      }
      const elementTemp = detail.elements?.find(
        (i) => i.status_id == 5 && i.type_code == "note_pdf"
      );

      const params = {
        claimId: detail?.id || null,
        elements: [
          {
            typeId: 520,
            typeCode: "action_element",
            text: dialogRefuseClaim?.text || null,
            notePdfId: elementTemp?.id,
          },
          {
            claimId: detail?.id || null,
            id: elementTemp?.id || null,
            typeId: 521,
            typeCode: "note_pdf",
            // text: dialogRefuseClaim?.text || null,
          },
        ],
      };

      dispatch("onLoading");

      const dataApi = await rejectByClient(params);

      if (dataApi?.data?.statusCode == 200) {
        return {
          loading: false,
          disableBtn: true,
          dialogRefuseClaim: {
            ...dialogRefuseClaim,
            open: !dialogRefuseClaim.open,
            text: "",
          },
          dialogNoti: {
            ...dialogNoti,
            open: true,
            type: "success",
            textContent:
              "Nội dung từ chối của Quý khách đã được gửi đi thành công",
          },
        };
      }
      return {
        loading: false,
        snackbar: {
          ...snackbar,
          open: true,
          type: "error",
          message:
            dataApi?.data?.message || "Đã xảy ra lỗi, xin vui lòng thử lại",
        },
      };
    },

    sendRequestAccept: async () => {
      const { dialogRefuseClaim, dialogNoti, snackbar, detail } = state;
      const elementTemp = detail.elements?.find(
        (i) => i.status_id == 5 && i.type_id == 521
      );

      const params = {
        claimId: detail?.id || null,
        elements: [
          {
            claimId: detail?.id || null,
            id: elementTemp?.id || null,
            typeId: 521,
            typeCode: "note_pdf",
            text: "",
          },
          {
            typeId: 520,
            typeCode: "action_element",
            text: "" || null,
          },
        ],
      };

      dispatch("onLoading");

      const dataApi = await acceptByClient(params);
      if (dataApi?.data?.statusCode == 200) {
        return {
          loading: false,
          disableBtn: true,
          dialogNoti: {
            ...dialogNoti,
            open: true,
            type: "success",
            textContent:
              "Hồ sơ bồi thường của Quý khách đã được hoàn thành. Vui lòng kiểm tra tài khoản sau 3 ngày kể từ ngày nhận được thông báo này",
          },
        };
      }

      return {
        loading: false,
        snackbar: {
          ...snackbar,
          open: true,
          type: "error",
          message:
            dataApi?.data?.message || "Đã xảy ra lỗi, xin vui lòng thử lại",
        },
      };
    },

    closeDialogNoti: () => ({
      dialogNoti: { ...state.dialogNoti, open: false, textContent: "" },
    }),

    openDialogShowPDF: () => ({
      dialogShowPDF: { ...(state.dialogShowPDF || {}), open: true },
    }),

    closeDialogShowPDF: () => ({
      dialogShowPDF: { ...(state.dialogShowPDF || {}), open: false },
    }),

    closeSnackbar: () => ({ snackbar: { ...state.snackbar, open: false } }),

    onLoading: () => ({ loading: true }),
  };

  return action.type && cases[action.type];
}
