import api from "../configs/api";

export function getListCompanys(data) {
  return api
    .apiForm("POST", "/api/v2/organization/list", data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListPrivileges() {
  return api
    .apiForm("GET", "/api/v2/role/privileges/list")
    .then((res) => res)
    .catch((err) => err.response);
}

export function createCompany(typeCompany, data) {
  return api
    .apiForm("POST", `/api/v2/organization/${typeCompany}`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function updateCompany(data) {
  return api
    .apiForm("PUT", `/api/v2/organization`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function deleteCompany(id) {
  return api
    .apiForm("DELETE", `/api/v2/organization/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getDetailCompany(id) {
  return api
    .apiForm("GET", `/api/v2/organization/${id}/detail`)
    .then((res) => res)
    .catch((err) => err.response);
}

export function creatGroupRole(data) {
  return api
    .apiForm("POST", `/api/v2/role-group`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getDetailGroupRole(id) {
  return api
    .apiForm("GET", `/api/v2/role-group/${id}/detail`)
    .then((res) => res)
    .catch((err) => err.response);
}

export function updateDetailGroupRole(data) {
  return api
    .apiForm("PUT", `/api/v2/role-group`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function deleteGroupRole(id) {
  return api
    .apiForm("DELETE", `/api/v2/role-group/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListMerchant(data) {
  return api
    .apiForm("POST", "/api/v2/merchant/filter/list", data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function createMerchant(data) {
  return api
    .apiForm("POST", "/api/v2/merchant/", data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function updateMerchant(data) {
  return api
    .apiForm("PUT", "/api/v2/merchant/", data)
    .then((res) => res)
    .catch((err) => err.response);
}
