import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Slide,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { isArray, isNumber } from "lodash";
import React from "react";
import { useStyles } from "../useStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddDialog(props) {
  const { dispatch, state } = props;
  const { open, data, checking, errors, isEdit } = state;
  const classes = useStyles();

  const getLabel = () => {
    switch (data?.configBenefit?.benefit_level) {
      case 1:
        return {
          placeholder: "VD: A",
          placeholderTitile: "VD: Quyền lợi tai nạn",
        };
      case 2:
        return {
          placeholder: "VD: A.1",
          placeholderTitile: "VD: Tử vong, TTVV, Mất tích",
        };
      case 3:
        return {
          placeholder: "VD: A.1.1",
          placeholderTitile: "VD: Trợ cấp mai táng",
        };

      default:
        break;
    }
  };

  const code_rules = [
    {
      id: 1,
      title: "Giới hạn/ năm",
    },
    {
      id: 2,
      title: "Giới hạn/ ngày",
    },
    {
      id: 3,
      title: "Giới hạn/ lần",
    },
    // {
    //   id: 4,
    //   title: "Trợ cấp/ ngày",
    // },
    // {
    //   id: 5,
    //   title: "Trợ cấp/ lần",
    // },
    // {
    //   id: 6,
    //   title: "Trợ cấp/ năm",
    // },
    // {
    //   id: 7,
    //   title: "Trợ cấp lương/ ngày",
    // },
    // {
    //   id: 8,
    //   title: "Trợ cấp lương/ tháng",
    // },
  ];

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"md"}
      onClose={() => dispatch("onClose")}
      className={classes.dialog}
    >
      <DialogTitle>Tạo mã quyền lợi mới</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid sm={6} xs={12}>
            <div className="title">Chọn loại bảo hiểm</div>
            <TextField
              fullWidth
              className="textfield "
              defaultValue={1}
              disabled
              select
            >
              {[{ title: "BH con người", id: 1 }].map((i, index) => (
                <MenuItem key={`select_type_insurance_${index}`} value={i.id}>
                  {i.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid sm={6} xs={12}>
            <div className="title">Chọn loại mã</div>
            <TextField
              fullWidth
              select
              key={`selecttor`}
              disabled={
                Boolean(isEdit) &&
                (Boolean(data?.configBenefit?.type_code_benefit) ||
                  Boolean(data?.configBenefit?.benefit_level))
              }
              className="textfield"
              value={
                data?.configBenefit?.type_code_benefit ||
                data?.configBenefit?.benefit_level ||
                ""
              }
              onChange={(e) =>
                dispatch({
                  type: "onChangeData",
                  value: e.target.value,
                  name: "benefit_level",
                })
              }
            >
              {[
                { title: "Cấp 1", id: 1 },
                { title: "Cấp 2", id: 2 },
                { title: "Cấp 3", id: 3 },
              ].map((i, index) => (
                <MenuItem key={`select_type_code${index}`} value={i.id}>
                  {i.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid sm={6} xs={12}>
            {" "}
            <div className="title">Nhập mã</div>
            <TextField
              fullWidth
              disabled={Boolean(isEdit)}
              placeholder={getLabel()?.placeholder || ""}
              value={data?.codeBenefit || ""}
              onBlur={() => {
                dispatch({ type: "checkCode" });
              }}
              onChange={(e) => {
                const regex = /^[a-z0-9. ]*$/i;
                if (regex.test(e.target.value)) {
                  dispatch({
                    type: "onChangeData",
                    name: "codeBenefit",
                    value: e.target.value,
                  });
                }
              }}
              error={
                errors.includes("codeBenefit") ||
                errors.includes("check_code_benefit") ||
                errors.includes("invalid_code")
              }
              helperText={
                errors.includes("codeBenefit")
                  ? "Vui lòng nhập đầy đủ thông tin."
                  : errors.includes("check_code_benefit")
                  ? "Mã này đã có trong hệ thống"
                  : errors.includes("invalid_code")
                  ? "Mã bạn nhập không hợp lệ"
                  : false
              }
            />
            {/* {errors.includes("codeBenefit") && (
              <div className="red-text">Vui lòng nhập đầy đủ thông tin.</div>
            )}
            {errors.includes("check_code_benefit") && (
              <div className="red-text">Mã này đã có trong hệ thống</div>
            )}
            {errors.includes("invalid_code") && (
              <div className="red-text">Mã bạn nhập không hợp lệ</div>
            )} */}
          </Grid>
          <Grid sm={6} xs={12}>
            <div className="title">Nhập tên mã</div>
            <TextField
              fullWidth
              placeholder={getLabel()?.placeholderTitile || ""}
              value={data?.title || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChangeData",
                  name: "title",
                  value: e.target.value,
                })
              }
              error={errors.includes("title")}
              helperText={
                errors.includes("title")
                  ? "Vui lòng nhập đầy đủ thông tin."
                  : false
              }
            />
            {/* {errors.includes("title") && (
              <div className="red-text">Vui lòng nhập đầy đủ thông tin.</div>
            )} */}
          </Grid>
        </Grid>

        <div className="title">Định nghĩa mã</div>
        <TextField
          fullWidth
          select
          key={`selecttor`}
          className="textfield"
          value={data?.configBenefit?.code_rules || ""}
          disabled={
            Boolean(isEdit) &&
            +data?.configBenefit?.code_rules > 0 &&
            Boolean(data?.configBenefit?.code_rules)
          }
          onChange={(e) =>
            dispatch({
              type: "onChangeData",
              value: e.target.value,
              name: "code_rules",
            })
          }
        >
          {code_rules.map((i, index) => (
            <MenuItem key={`select_type_code${index}`} value={i.id}>
              {i.title}
            </MenuItem>
          ))}
        </TextField>

        <div className="center">
          <Button className="button skip" onClick={() => dispatch("onClose")}>
            Bỏ qua
          </Button>
          <Button
            disabled={Boolean(checking)}
            className={`button  ${Boolean(checking) ? "checking" : ""}`}
            onClick={() => dispatch("validate")}
          >
            {Boolean(isEdit) ? "Lưu lại" : "Thêm"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
