import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useHistory, useParams } from "react-router-dom";

import useReducer from "../../../../library/hooks/useReducer";
import { TextField } from "../overrideMui/index";
import { accountDetail } from "../styles/styleDetailAccount";
import { initialStateAccountDetail } from "../util";
import detailAccountReducer from "../reducers/detailAccountreducer";
import TypeAccountDetail from "../components/typeAccountDetail";
import InforAccount from "../components/inforAccount";
import LoadingMui from "../../../../ui/LoadingMui";
import Snackbars from "../../../../ui/snackbar";
import DialogNotifica from "../components/dialogNotification";

export default function DetailAccount() {
  const classes = accountDetail();
  const history = useHistory();
  const { id } = useParams();
  const [state, dispatch, clearStateGlobal] = useReducer({
    initialState: initialStateAccountDetail,
    reducer: detailAccountReducer,
    name: "detailAccountReducer",
    userId: id,
    history,
  });

  const { snackbar, loading, dialogNotifica } = state;

  useEffect(() => {
    dispatch(id === "new" ? "initials" : "initialsUpdate");
    return () => clearStateGlobal();
  }, []);

  return (
    <div className={classes.container}>
      <Box className={classes.box_heder}>
        <div className="title-account-detail">
          {id === "new" ? "Tạo tài khoản" : "Cập nhật tài khoản"}
        </div>
        <div>
          <Button
            className="buttons-delete"
            onClick={() =>
              dispatch(id === "new" ? "initials" : "initialsUpdate")
            }
          >
            Xoá hết
          </Button>
          <Button
            className="buttons-save"
            onClick={() => dispatch("submitSave")}
          >
            Lưu lại
          </Button>
        </div>
      </Box>
      <Grid container spacing={1}>
        <Grid xs={6} sm={5} md={3.5}>
          <TypeAccountDetail {...{ state, dispatch, userId: id }} />
        </Grid>
        <Grid xs={6} sm={7} md={8.5}>
          <div style={{ width: "100%" }}>
            <InforAccount {...{ state, dispatch, userId: id }} />
          </div>
        </Grid>
      </Grid>
      <LoadingMui loading={Boolean(loading)} />
      <DialogNotifica data={dialogNotifica} dispatch={dispatch} />

      <Snackbars
        open={Boolean(snackbar.open)}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => dispatch("closeSnackbar")}
      />
    </div>
  );
}
