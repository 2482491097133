import * as React from "react";
import { Dialog, DialogContent, Slide, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { getInfoProductById } from "../utils";
import LoadingMui from "../../../../../ui/LoadingMui";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogInsuranceInfo(props) {
  const { dispatch, state, loading } = props;
  const classes = makeStyle();
  const { dialogInsuranceInfo } = state;
  const insuranceInfor = getInfoProductById(
    dialogInsuranceInfo?.product_id || 0
  );

  return (
    <Dialog
      open={dialogInsuranceInfo.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => dispatch("closeDialogInsuranceInfo")}
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialog}
    >
      <DialogContent style={{ padding: "20px 0" }}>
        <div className={classes.divContent}>
          <div className={classes.header}>
            <div className="div-img">
              <img
                src={dialogInsuranceInfo?.urlLogo || insuranceInfor.icon || ""}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="label">
              <span>{dialogInsuranceInfo.product_title}</span>
              <span style={{ color: "#475569" }}>
                {dialogInsuranceInfo.provider_title}
              </span>
            </div>
          </div>
          <div className={classes.box_info}>
            <div className="rows">
              <div className="name"></div>
              <div className="approve" style={{ fontWeight: "600" }}>
                Đã bồi thường
              </div>
              <div className="amount-left" style={{ fontWeight: "600" }}>
                Quyền lợi còn lại
              </div>
            </div>
            {dialogInsuranceInfo?.data?.map((item, index) => (
              <div className="rows" key={`rows-infor${index}`}>
                <div className="name">
                  {`${item?.codeBenefitDetail || ""}. ${
                    item?.nameBenefitDetail || ""
                  }`}
                </div>
                <div className="approve">
                  {`- ` +
                    `${item?.totalAmountApprove || 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    ) +
                    "đ"}
                </div>
                <div className="amount-left">
                  {`${item?.limitAmountLeft || 0}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "."
                  ) + "đ"}
                </div>
              </div>
            ))}
          </div>
        </div>
        <LoadingMui loading={loading} />
      </DialogContent>
    </Dialog>
  );
}

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
      width: "100%",
      margin: 0,
    },
  },

  divContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",

    "& .text": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
      margin: "12px 0",
    },
  },

  header: {
    width: "100%",
    display: "flex",

    "& .div-img": {
      width: "70px",
      height: "70px",
    },

    "& .label": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginLeft: "12px",
    },
  },

  box_info: {
    width: "100%",

    "& .rows": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      margin: "8px 0",
    },

    "& .name": {
      width: "40%",
      padding: "0 6px",
    },

    "& .approve": {
      width: "28%",
      color: "#0B7CE4",
      textAlign: "end",
      padding: "0 6px",
    },

    "& .amount-left": {
      width: "28%",
      textAlign: "end",
      padding: "0 6px",
    },
  },

  //
}));
