import React, { Component } from 'react';
import { Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './modal.css'

export class ModalLoading extends Component {
  static _sharedModalLoading = undefined;
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
    ModalLoading._sharedModalLoading = this;
  }
  
  static show() {
    if (ModalLoading._sharedModalLoading) {
      ModalLoading._sharedModalLoading?.setState({ isLoading: true });
    }
  }

  static hide() {
    if (ModalLoading._sharedModalLoading) {
      ModalLoading._sharedModalLoading?.setState({ isLoading: false });
    }
  }

  render() {
    const {
      children = <LoadingOutlined style={{
        fontSize: 40,
        color: '#E16B2F'
      }}/>
    } = this.props;

    return (
      <Modal
        visible={this.state.isLoading && ModalLoading._sharedModalLoading}
        footer={null}
        closable={false}
        style={{
          alignItems: 'center',
          display: 'flex',
          position: 'fixed',
          inset: '0px',
          padding: '20px',
          justifyContent: 'center',
        }}
        maskStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }}
        zIndex={99999999999}
        className='modal-loading'
      >
        {children}
      </Modal>
    );
  }
}

export default ModalLoading;
