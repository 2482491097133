import { Button, message, Modal, Row, Col, Collapse, Input } from "antd";

import "../../styles/index.css";

import { Grid, Typography } from "@mui/material";

import { MessageOutlined } from "@ant-design/icons";

import { TotalInsureFeeIcon } from "../../components/icon/icon";

import { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  formatMoney,
  removeFormatFloat,
  removeFormatMoney,
} from "../../utils/money";
import { addFeedBack } from "../../api/claim";
import { Controller, useForm } from "react-hook-form";
import MyEditor from "../../components/editors";

const { Panel } = Collapse;

export default function CheckingAcceptance({
  data,
  afterSend = async () => {},
  getDetailData = async () => {},
  totalBenefit = 0,
}) {
  const user = JSON.parse(localStorage.getItem("user"));

  const [visible, setVisible] = useState(false);
  const [visibleResult, setVisibleResult] = useState(false);

  const {
    register,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
    setError,
    clearErrors,
    control,
  } = useForm();
  const [disabled, setDisabled] = useState(false);
  const [showMessages, setShowMessages] = useState(false);

  const additionContent = useRef();
  const acceptContent = useRef();
  const rejectContent = useRef();
  const deniedContent = useRef();

  const moneyWatch = watch("money");
  const percentWatch = watch("percent");
  const statusWatch = watch("status");

  useEffect(() => {
    let temp = +removeFormatMoney(moneyWatch);
    setValue("money", formatMoney(+temp));
  }, [moneyWatch]);

  useEffect(() => {
    let temp = percentWatch;
    if (isNaN(temp)) return;
    if (temp > 100) temp = 100;

    if (temp < 0) temp = 0;

    setValue("money", (+totalBenefit * temp) / 100);
    setValue("percent", temp);
  }, [percentWatch]);

  useEffect(() => {
    if (!data?.statusId) return;

    setValue("status", +data?.statusId?.toString());
    setDisabled(
      +data?.statusId === 3 ||
        +data?.statusId === 7 ||
        +data?.statusId === 5 ||
        +data?.statusId === 7
    );

    setValue("text", data?.elements[0]?.text);
    setValue("note", data?.elements[0]?.note);

    if (+data?.statusId === 3 || +data?.statusId === 5) {
      setValue(
        "money",
        formatMoney(
          data.amount ||
            data?.elements?.filter((item) => +item.statusId === 3)[0]?.value
        )
      );

      setValue("status", "3");
    }

    if (+data?.statusId === 7) {
      setValue("status", "7");
    }

    if (+data?.statusId === 4) {
      setValue("status", "4");
    }

    if (data?.extraInfo?.accident?.level === "dead") {
      setValue("percent", 100);
    }

    return () => {};
  }, [data]);

  useEffect(() => {}, [statusWatch]);

  const feedBack = async (type) => {
    let values = getValues();
    let request = {
      status: +values.status === 7 ? 3 : +values.status,
      note: values.note,
      amount:
        +values.status === 3 || +values.status === 7
          ? +removeFormatMoney(values.money || 0)
          : null,
      text: values.text,
      type: 32,
      percent: +values.percent || null,
    };
    await addFeedBack(+data?.id, request);
    setVisible(false);
    setVisibleResult(true);
    await afterSend();
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        alignItems="stretch"
      >
        <Grid item xs={6} style={{ paddingLeft: 30, marginBottom: 20 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ paddingTop: 20 }}>
              <Typography fontWeight={"bold"} color="#2A3790" fontSize={20}>
                Phần duyệt của bồi thường viên
                <Button
                  style={{ marginLeft: 20, outline: "none", border: "none" }}
                  icon={<MessageOutlined />}
                  onClick={() => {
                    setShowMessages(true);
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          {(+statusWatch === 3 || +data?.statusId === 3) &&
            (data?.extraInfo?.accident?.level === "dead" ||
              data?.extraInfo?.accident?.level === "permanent disability") && (
              <>
                <div
                  style={{
                    background: "#EBEDFF",
                    borderRadius: 8,
                    padding: 10,
                  }}
                >
                  <Typography fontWeight={"bold"} color="#2A3790" fontSize={12}>
                    <TotalInsureFeeIcon style={{ marginRight: 5 }} />
                    <span style={{ verticalAlign: "super" }}>
                      Tỷ lệ thương tật
                    </span>
                  </Typography>

                  <div style={{}}>
                    <Controller
                      name="percent"
                      defaultValue={0}
                      control={control}
                      rules={{}}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Input
                          disabled={data?.extraInfo?.accident?.level === "dead"}
                          onChange={onChange}
                          value={value}
                          suffix={"%"}
                          className="text-right"
                          ref={ref}
                        />
                      )}
                    />
                  </div>
                  <div
                    style={{
                      minHeight: 16,
                    }}
                  >
                    {errors["percent"] && (
                      <div
                        style={{
                          color: "red",
                          fontSize: 12,
                        }}
                      >
                        {errors["percent"].message}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
        </Grid>

        <Grid item xs={3}>
          {(+statusWatch === 3 || +data?.statusId === 3) && (
            <>
              <div
                style={{
                  background: "#EBEDFF",
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Typography fontWeight={"bold"} color="#2A3790" fontSize={12}>
                  <TotalInsureFeeIcon style={{ marginRight: 5 }} />
                  <span style={{ verticalAlign: "super" }}>
                    Số tiền bồi thường
                  </span>
                </Typography>

                <div style={{}}>
                  <Controller
                    name="money"
                    defaultValue={0}
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        disabled={
                          +data?.statusId === 5 ||
                          +data?.statusId === 7 ||
                          data?.extraInfo?.accident?.level === "dead" ||
                          data?.extraInfo?.accident?.level ===
                            "permanent disability"
                        }
                        onChange={onChange}
                        value={value}
                        suffix={"VNĐ"}
                        className="text-right"
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    minHeight: 16,
                  }}
                >
                  {errors["money"] && (
                    <div
                      style={{
                        color: "red",

                        fontSize: 12,
                      }}
                    >
                      {errors["money"].message}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </Grid>

        <Grid item xs={3} style={{}}>
          <label className="premium-radio" htmlFor="accepted">
            <input
              type={"radio"}
              value="3"
              id="accepted"
              {...register("status")}
              disabled={
                // (+data?.userIdReview !== +user?.id && +data?.userIdAssign !== +user?.id) ||
                disabled
              }
            />
            <span class="design"></span>
            <span class="value">Hồ sơ đủ điều kiện</span>
          </label>
        </Grid>
        <Grid item xs={2} style={{}}>
          <label className="premium-radio" htmlFor="addition">
            <input
              type={"radio"}
              value="4"
              id="addition"
              {...register("status")}
              disabled={
                // (+data?.userIdReview !== +user?.id && +data?.userIdAssign !== +user?.id) ||
                disabled
              }
            />
            <span class="design"></span>
            <span class="value">Hồ sơ bổ sung</span>
          </label>
        </Grid>
        <Grid item xs={2} style={{}}>
          <label className="premium-radio" htmlFor="denied">
            <input
              type={"radio"}
              value="7"
              id="denied"
              {...register("status")}
              disabled={
                // (+data?.userIdReview !== +user?.id && +data?.userIdAssign !== +user?.id) ||
                disabled
              }
            />
            <span class="design"></span>
            <span class="value">Từ chối</span>
          </label>
        </Grid>

        {(statusWatch === "3" || +data?.statusId === 3) && (
          <>
            <Grid item xs={6} style={{ paddingLeft: 30 }}>
              <MyEditor
                placeholder="Nội dung được chi trả"
                ref={acceptContent}
              />
              <div
                style={{
                  minHeight: 16,
                }}
              >
                {errors["acceptContent"] && (
                  <div
                    style={{
                      color: "red",
                      fontSize: 12,
                    }}
                  >
                    {errors["acceptContent"].message}
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={6} style={{ paddingLeft: 30 }}>
              <MyEditor
                placeholder="Nội dung không được chi trả"
                ref={deniedContent}
                readOnly={
                  // (+data?.userIdReview !== +user?.id && +data?.userIdAssign !== +user?.id) ||
                  disabled
                }
              />
              <div
                style={{
                  minHeight: 16,
                }}
              >
                {errors["deniedContent"] && (
                  <div
                    style={{
                      color: "red",
                      fontSize: 12,
                    }}
                  >
                    {errors["deniedContent"].message}
                  </div>
                )}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "center",
                paddingBottom: 20,
              }}
            >
              <Button
                style={{
                  width: 180,
                  height: 50,
                  background: "#2A3790",
                  borderRadius: 28,
                  color: "white",
                  boxShadow: "0px 0px 16px 8px rgba(0, 0, 0, 0.08)",
                }}
                size="large"
                onClick={async () => {
                  clearErrors();
                  let validate = true;
                  if (!acceptContent.current?.getValue().hasText) {
                    setError("acceptContent", {
                      message: "Vui lòng nhập nội dung",
                    });
                    validate = false;
                  }

                  validate = (await trigger()) && validate;
                  if (!validate) return;

                  setValue("text", acceptContent.current?.getValue().content);
                  setValue("note", deniedContent.current?.getValue().content);
                  setVisible(true);
                }}
                disabled={
                  // +data?.userIdReview !== +user?.id ||
                  disabled || removeFormatMoney(moneyWatch) <= 1000
                }
              >
                Gửi phê duyệt
              </Button>
            </Grid>
          </>
        )}

        {statusWatch === "4" && (
          <>
            {/* <Editor /> */}
            <Grid item xs={12} style={{ paddingLeft: 30 }}>
              <MyEditor
                placeholder="Nội dung yêu cầu bổ sung"
                ref={additionContent}
              />
              <div
                style={{
                  minHeight: 16,
                }}
              >
                {errors["additionContent"] && (
                  <div
                    style={{
                      color: "red",
                      fontSize: 12,
                    }}
                  >
                    {errors["additionContent"].message}
                  </div>
                )}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "center",
                paddingBottom: 20,
              }}
            >
              <Button
                style={{
                  width: 180,
                  height: 50,
                  background: "#2A3790",
                  borderRadius: 28,
                  color: "white",
                  boxShadow: "0px 0px 16px 8px rgba(0, 0, 0, 0.08)",
                }}
                size="large"
                onClick={async () => {
                  clearErrors();
                  let validate = true;
                  if (!additionContent.current?.getValue().hasText) {
                    setError("additionContent", {
                      message: "Vui lòng nhập nội dung",
                    });
                    validate = false;
                  }

                  validate = (await trigger()) && validate;
                  if (!validate) return;

                  const tempDetail = await getDetailData();
                  setValue("status", "4");
                  if (!tempDetail?.insuredInfo?.requiredUpdate?.length)
                    return message.error(
                      "Vui lòng nhập yêu cầu bổ sung hình ảnh!"
                    );

                  setValue("text", additionContent.current?.getValue().content);
                  setVisible(true);
                }}
                disabled={
                  // (+data?.userIdReview !== +user?.id &&
                  //   +data?.userIdAssign !== +user?.id) ||
                  disabled
                }
              >
                Gửi yêu cầu bổ sung
              </Button>
            </Grid>
          </>
        )}

        {statusWatch === "7" && +data?.statusId !== 7 && (
          <>
            <Grid item xs={12} style={{ paddingLeft: 30 }}>
              <MyEditor placeholder="Lý do từ chối" ref={rejectContent} />
              <div
                style={{
                  minHeight: 16,
                }}
              >
                {errors["rejectContent3"] && (
                  <div
                    style={{
                      color: "red",
                      fontSize: 12,
                    }}
                  >
                    {errors["rejectContent"].message}
                  </div>
                )}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "center",
                paddingBottom: 20,
              }}
            >
              <Button
                style={{
                  width: 180,
                  height: 50,
                  background: "#2A3790",
                  borderRadius: 28,
                  color: "white",
                  boxShadow: "0px 0px 16px 8px rgba(0, 0, 0, 0.08)",
                }}
                size="large"
                onClick={async () => {
                  clearErrors();
                  let validate = true;
                  let content = rejectContent.current?.getValue();
                  if (!content.hasText) {
                    setError("rejectContent", {
                      message: "Vui lòng nhập nội dung",
                    });
                    validate = false;
                  }

                  validate = (await trigger()) && validate;
                  if (!validate) return;

                  setValue("note", content.content);
                  setVisible(true);
                }}
                disabled={disabled}
              >
                Gửi phê duyệt
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        centered
        footer={null}
        width={400}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <svg
            width="57"
            height="51"
            viewBox="0 0 57 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M55.8159 44.8038L31.3553 2.43891C31.0383 1.86994 30.575 1.39597 30.0135 1.06601C29.4519 0.736047 28.8124 0.562073 28.1611 0.562073C27.5098 0.562073 26.8703 0.736047 26.3087 1.06601C25.7471 1.39597 25.2839 1.86994 24.9669 2.43891L0.506256 44.8038C0.182015 45.3654 0.0111315 46.0023 0.0107429 46.6508C0.0103542 47.2993 0.180474 47.9365 0.504042 48.4985C0.827609 49.0605 1.29325 49.5275 1.85427 49.8527C2.41528 50.178 3.05195 50.35 3.70043 50.3516H52.6217C53.2702 50.35 53.9069 50.178 54.4679 49.8527C55.0289 49.5275 55.4946 49.0605 55.8181 48.4985C56.1417 47.9365 56.3118 47.2993 56.3114 46.6508C56.311 46.0023 56.1402 45.3654 55.8159 44.8038ZM28.2031 15.3837C28.5765 15.3891 28.9452 15.4688 29.2875 15.618C29.6299 15.7672 29.9391 15.983 30.1973 16.2529C30.4554 16.5228 30.6573 16.8413 30.7911 17.19C30.925 17.5386 30.9882 17.9104 30.977 18.2837L30.5147 34.4227C30.4387 34.9875 30.1604 35.5055 29.7314 35.8806C29.3025 36.2557 28.752 36.4625 28.1821 36.4625C27.6122 36.4625 27.0617 36.2557 26.6328 35.8806C26.2038 35.5055 25.9255 34.9875 25.8495 34.4227L25.2191 18.3678C25.2078 17.991 25.2724 17.6157 25.4088 17.2643C25.5453 16.9129 25.7509 16.5925 26.0135 16.322C26.2761 16.0516 26.5904 15.8366 26.9376 15.6899C27.2849 15.5431 27.658 15.4676 28.035 15.4678L28.2031 15.3837ZM28.2031 44.5096C27.6296 44.5096 27.0689 44.3395 26.592 44.0208C26.1151 43.7022 25.7434 43.2493 25.5239 42.7194C25.3044 42.1895 25.247 41.6064 25.3589 41.0438C25.4708 40.4813 25.747 39.9646 26.1525 39.559C26.5581 39.1534 27.0748 38.8772 27.6374 38.7653C28.1999 38.6534 28.783 38.7109 29.3129 38.9304C29.8428 39.1499 30.2957 39.5216 30.6144 39.9985C30.933 40.4754 31.1031 41.036 31.1031 41.6096C31.1031 42.3787 30.7976 43.1163 30.2537 43.6602C29.7099 44.204 28.9722 44.5096 28.2031 44.5096Z"
              fill="#FFC107"
            />
          </svg>
        </div>

        <div style={{ margin: "10px 0" }}>
          <Typography
            color={"#2A3790"}
            fontSize={18}
            textAlign="center"
            fontWeight={600}
          >
            {(+statusWatch === 3 || +statusWatch === 7) && (
              <>Bạn đã chắc chắn mọi thông tin đã đúng để gửi đi phê duyệt?</>
            )}

            {+statusWatch === 4 && (
              <>
                Bạn đã chắc chắn mọi thông tin đã đúng để gửi cho khách hàng yêu
                cầu bổ sung hồ sơ?
              </>
            )}
          </Typography>
        </div>

        <Row>
          <Col span={12} style={{ textAlign: "center" }}>
            <Button
              size="large"
              onClick={() => setVisible(false)}
              style={{
                color: "#2A3790",
                border: "1px solid #2A3790",
                width: 150,
                borderRadius: 32,
                fontWeight: 500,
              }}
            >
              Không
            </Button>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <Button
              size="large"
              onClick={async () => {
                await feedBack();
              }}
              style={{
                background: "#2A3790",
                border: "1px solid #2A3790",
                width: 150,
                borderRadius: 32,
                fontWeight: 500,
                color: "white",
              }}
            >
              Có
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={visibleResult}
        onCancel={() => setVisible(false)}
        centered
        footer={null}
        width={400}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <svg
            width="57"
            height="57"
            viewBox="0 0 57 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.367 0.5C12.9245 0.5 0.207031 13.2175 0.207031 28.6601C0.207031 44.1026 12.9245 56.82 28.367 56.82C43.8096 56.82 56.527 44.1026 56.527 28.6601C56.527 12.9147 43.8096 0.5 28.367 0.5ZM45.3236 16.851L20.7971 41.0746H20.4944L10.8049 25.0265C16.2552 25.0265 20.4944 30.4768 21.1 31.0824C21.1 31.0824 21.0999 31.0824 21.4027 31.0824L45.3236 16.851C45.0208 16.5482 45.3236 16.5482 45.3236 16.851Z"
              fill="#39B44A"
            />
          </svg>
        </div>

        <div style={{ margin: "10px 0" }}>
          <Typography
            color={"#2A3790"}
            fontSize={18}
            textAlign="center"
            fontWeight={600}
          >
            {statusWatch === "7" ||
              (statusWatch === "3" && <>Bạn đã gửi phê duyệt thành công</>)}
            {!(statusWatch === "7" || statusWatch === "3") && (
              <>Bạn đã gửi thành công</>
            )}
          </Typography>
        </div>

        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              size="large"
              onClick={async () => {
                setVisibleResult(false);
              }}
              style={{
                background: "#2A3790",
                border: "1px solid #2A3790",
                width: 150,
                borderRadius: 32,
                fontWeight: 500,
                color: "white",
              }}
            >
              Đóng
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        onCancel={() => {
          setShowMessages(false);
        }}
        visible={showMessages}
        title={"Nội dung tin nhắn yêu cầu kiểm tra"}
        centered
        footer={<Button>Đóng</Button>}
      >
        <Collapse defaultActiveKey={["1"]} expandIconPosition="end">
          {data?.insuredInfo?.petitioners?.map((item, index) => {
            return (
              <Panel
                header={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "95%",
                    }}
                  >
                    <div>{item?.fullName}</div>

                    <div>{item?.createdAt}</div>
                  </div>
                }
                key={(index + 1).toString()}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: item?.message || "" }}
                ></div>
              </Panel>
            );
          })}
        </Collapse>
      </Modal>
    </>
  );
}
