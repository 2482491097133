import axios from "axios";
import config from "../../../../../configs/config";
import api from "../../../../../configs/api";

export async function getClaims(
  status_id = 1,
  phone = null,
  citizen_identification = null,
  merchant_id = null,
  from_date = null,
  to_date = null,
  page = 1,
  limit = 10
) {
  let object = {
    status_id,
    phone,
    citizen_identification,
    merchant_id,
    from_date,
    to_date,
  };
  let queryString = "";

  let route = `/api/v1/tomato/claim?limit=${limit}&page=${page}`;

  const result = await axios
    .get(`${config.SERVER}${route}&${queryString}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .catch((e) => e);

  return result;
}

export async function getClaimDetail(systemType, id) {
  return await axios
    .get(`${config.SERVER}/api/v1/claim/${systemType}/${id}/detail`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .catch((e) => e);
}

export async function getListHistory(id) {
  return await axios
    .get(`${config.SERVER}/api/v1/tomato/claim/${id}/element`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .catch((e) => e);
}

export async function addFeedBack(id, data) {
  return await axios
    .post(`${config.SERVER}/api/v1/tomato/claim/${id}/element`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .catch((e) => e);
}

export async function changeReviewer(userId, claimId) {
  return await axios
    .post(
      `${config.SERVER}/api/v1/claim/${claimId}/reviewer`,
      {
        userId: userId,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .catch((e) => e);
}

export async function listInsuredOrders(params) {
  return await axios
    .post(`${config.SERVER}/api/v1/tomato/claim/insured-orders`, params, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .catch((e) => e);
}

export function getListClaimFrom(systemType, data) {
  return api.apiForm("POST", `/api/v1/claim/${systemType}/list`, data);
}

export function getDatailsClaimCarFrom(systemType, claimId) {
  return api.apiForm("GET", `/api/v1/claim/${systemType}/${claimId}/detail`);
}
