import React, { useEffect } from "react";
import _ from "lodash";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import HeadSteper from "../components/step/headSteper";
import ShowImg from "../components/step/DialogShowImg";

import { styledStep4 } from "../components/step/styledStep4";
import danger from "../../../../../assets/ahamove/danger.svg";
import picture from "../../../../../assets/ahamove/picture.svg";

export default function Step4(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledStep4();
  const {
    elements,
    destination: { data, index },
    extraInfo,
    showImg,
  } = state;

  const renderImg = [
    {
      title: "Hình ảnh hàng hóa/ tài liệu trước khi bị sự cố",
      id: 200,
      listImg: (elements || []).find((item) => item.typeId === 200),
    },
    {
      title: "Hình ảnh hàng hóa/ tài liệu sau khi bị sự cố",
      id: 201,
      dontShow: +extraInfo?.typeOfAccident === 2,
      listImg: (elements || []).find((item) => item.typeId === 201),
    },
    {
      title: "Hình ảnh hóa đơn",
      id: 202,
      dontShow:
        +data?.paymentInfo?.paymentId === 2 &&
        [3, 4].includes(+extraInfo?.insuranceObj),
      listImg: (elements || []).find((item) => item.typeId === 202),
    },
  ];

  useEffect(() => dispatch("initialStep4"), []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        minHeight: isApp ? "calc(100vh - 165px)" : "calc(100vh - 125px)",
      }}
    >
      <HeadSteper activeStep={1} />
      <div className={classes.boxImg}>
        <div className={classes.titleHead}>HÌNH ẢNH</div>
        <div className="warring">
          <img src={danger} alt="danger" style={{ margin: "0 6px 3px 0" }} />
          Cung cấp ít nhất 1 hình ảnh theo các mục sau
        </div>
        {renderImg.map((data, indx) =>
          !data.dontShow ? (
            <div className="divImg" key={`boxIMg${indx}`}>
              <div className="title">
                {data.title} <span style={{ color: "red" }}>*</span>
              </div>
              <div className={classes.lineImg}>
                <div
                  className={classes.divImg}
                  onClick={() => {
                    window.document.getElementById(`addImage${indx}`).click();
                  }}
                >
                  <input
                    type="file"
                    hidden
                    id={`addImage${indx}`}
                    accept="image/*"
                    onChange={(e) => {
                      dispatch({ type: "addImg", e, id: data.id });
                      e.target.value = null;
                    }}
                  />
                  <img src={picture} alt="picture" />
                  <span className="spanPicture">Tải ảnh lên từ thiết bị</span>
                </div>
                {(_.isEmpty(data?.listImg?.documents)
                  ? [1, 2]
                  : data?.listImg?.documents
                ).map((items, index) =>
                  !items.path ? (
                    <div
                      className={classes.divImg}
                      key={`img${index}`}
                      onClick={() => {
                        window.document
                          .getElementById(`addImage${indx}`)
                          .click();
                      }}
                    >
                      <input
                        type="file"
                        hidden
                        id={`addImage${indx}`}
                        accept="image/*"
                        onChange={(e) => {
                          dispatch({ type: "addImg", e, id: data.id });
                          e.target.value = null;
                        }}
                      />
                      <img src={picture} alt="picture" />
                      <span className="spanPicture">
                        Tải ảnh lên từ thiết bị
                      </span>
                    </div>
                  ) : (
                    <div className={classes.divImg} key={`img${index}`}>
                      <img
                        key={index}
                        src={items?.path}
                        alt={`picture ${index}`}
                        width="98%"
                        height={75}
                        onClick={() => {
                          dispatch({
                            type: "showImg",
                            data,
                            url: items?.path,
                            indexImg: index,
                          });
                        }}
                      />
                      <CancelOutlinedIcon
                        className="icon"
                        fontSize="small"
                        style={{ zIndex: 11 }}
                        onClick={() => {
                          dispatch({
                            type: "deleteImg",
                            id: data.id,
                            index,
                            url: items?.path,
                          });
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null
        )}
      </div>
      <ShowImg {...{ dispatch, showImg }} />
    </div>
  );
}
