import * as React from "react";
import {
  Button,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@mui/material";

import {
  FilterList as FilterListIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";

import { stylePopoverFilter } from "../styles/stylePopoverFilter";
import { TextField, CheckBox } from "../overrideMui";
import { userTypes } from "../util";
import { get } from "lodash";
import { useRef } from "react";

export default function PopoverFilter(props) {
  const classes = stylePopoverFilter();
  const { state, dispatch, user } = props;
  const userTypeFromProfile = get(user, "extra_info.userType") || "";

  const {
    filter,
    dataFill,
    listOrg,
    selectUserType,
    roleGroups,
    pageFilter,
    loadingFilter,
    oldListOrg,
    oldRoleGroups,
  } = state;

  const HeaTitle = ({ title, name }) => (
    <div className={classes.title}>
      <span className="span-time">{title}</span>
      <IconButton
        sx={{ padding: 0 }}
        onClick={() => {
          dispatch({ type: "deleteDataFill", name });
        }}
      >
        <span className="span-delete">Xoá</span>
      </IconButton>
    </div>
  );

  const hadData = [
    {
      title: "Loại tài khoản",
      acttionName: "userTitleIds",
      option: [
        { label: "Admin", id: 1, checked: dataFill?.userTitleIds?.includes(1) },
        { label: "User", id: 2, checked: dataFill?.userTitleIds?.includes(2) },
      ],
    },
    {
      title: "Thuộc tài khoản",
      acttionName: "userTypes",
      option: selectUserType
        .filter((i) => i.value !== "all")
        .map((i) => ({
          ...i,
          id: i.value,
          checked: Boolean((dataFill?.userTypes || [])?.includes(i.value)),
        })),
    },
    {
      title: "Công ty",
      acttionName: "orgIds",
      option: listOrg.map((i) => {
        return {
          ...i,
          label: i.title,
          checked: Boolean(dataFill?.orgIds?.includes(i.id)),
        };
      }),
    },
    {
      title: "Trạng thái",
      acttionName: "statusIds",
      option: [
        {
          label: "Đang hoạt động",
          id: 1,
          checked: Boolean(dataFill?.statusIds?.includes(1)),
        },
        {
          label: "Ngưng hoạt động",
          id: 0,
          checked: Boolean(dataFill?.statusIds?.includes(0)),
        },
      ],
    },
    {
      title: "Nhóm quyền",
      acttionName: "roleGroupIds",
      dontShow: userTypeFromProfile === "globalcare",
      option: roleGroups?.map((i) => {
        return {
          ...i,
          label: i.title,
          checked: Boolean(dataFill?.roleGroupIds?.includes(i?.id)),
        };
      }),
    },
  ];

  const checkShowmove = (name, option = []) => {
    return name === "orgIds"
      ? oldListOrg.length > option?.length
      : oldRoleGroups.length > option?.length;
  };

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={Boolean(filter?.open)}
        onClose={() => dispatch("closeFilter")}
        sx={{
          "& .MuiPaper-elevation": {
            marginTop: "24px",
            marginRight: "12px",
            maxHeight: "calc(100vh - 38px)",
          },
        }}
      >
        <div
          style={{ width: "280px", height: "100%", overflow: "auto" }}
          id="divScroll"
        >
          <div className={classes.container}>
            <div className={classes.header}>
              <div>
                <FilterListIcon fontSize="small" sx={{ marginRight: "8px" }} />
                <span>Bộ lộc</span>
              </div>
              <CloseIcon
                fontSize="small"
                sx={{ marginRight: "8px", cursor: "pointer" }}
                onClick={() => dispatch("closeFilter")}
              />
            </div>
            {hadData.map((data, dataIndex) =>
              data?.dontShow ? null : (
                <div key={data.title} className={classes.box_check}>
                  <HeaTitle title={data.title} name={data.acttionName} />
                  <div>
                    <FormGroup>
                      {data.option.map((opt, otpIndex) => (
                        <div
                          key={`item_checkBox_${otpIndex}`}
                          style={{ marginBottom: "8px" }}
                        >
                          <FormControlLabel
                            sx={{
                              margin: 0,
                              "& .MuiTypography-root": {
                                fontSize: "14px",
                              },
                            }}
                            control={
                              <CheckBox
                                className={classes.checkBox}
                                checked={Boolean(opt?.checked)}
                                onChange={(e) =>
                                  dispatch({
                                    type: "onChangeFill",
                                    name: data.acttionName,
                                    checked: e.target.checked,
                                    value: opt.id,
                                  })
                                }
                              />
                            }
                            label={opt?.label}
                          />
                        </div>
                      ))}
                    </FormGroup>
                    {["orgIds", "roleGroupIds"].includes(data.acttionName) &&
                      loadingFilter[data.acttionName] && (
                        <div
                          style={{
                            fontWeight: "600",
                            color: "#0B7CE4",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "17px",
                          }}
                        >
                          Đang tải thêm ...{" "}
                        </div>
                      )}
                    {["orgIds", "roleGroupIds"].includes(data.acttionName) && (
                      <div className="show-move">
                        {(get(pageFilter, `${data.acttionName}.total`, 0) >
                          get(pageFilter, `${data.acttionName}.page`, 0) * 20 ||
                          checkShowmove(data.acttionName, data.option)) && (
                          <div
                            className="row color-text"
                            onClick={() => {
                              if (!loadingFilter[data.acttionName]) {
                                dispatch({
                                  type: "showMove",
                                  show: true,
                                  name: data.acttionName,
                                });
                              }
                            }}
                          >
                            Hiển thị thêm{" "}
                            <ExpandMoreIcon
                              color="#0B7CE4"
                              sx={{ width: "20px" }}
                            />
                          </div>
                        )}
                        {data?.option?.length > 5 && (
                          <div
                            className="row color-text"
                            onClick={() =>
                              dispatch({
                                type: "collapse",
                                show: false,
                                name: data.acttionName,
                              })
                            }
                          >
                            Thu gọn{" "}
                            <ExpandLessIcon
                              color="#0B7CE4"
                              sx={{ width: "20px" }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
          <div className={classes.apply_button}>
            <Button
              className="button-apply"
              onClick={() => {
                dispatch("applyFilter");
              }}
            >
              Áp dụng
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
