import { makeStyles } from "@mui/styles";

export const detailBLVP = makeStyles(() => ({
  container: {
    width: "100%",
    paddingRight: "12px",
    marginTop: "55px",
  },

  containerHeaderDetail: {
    width: "100%",
    padding: "8px 6px",
    background: "white",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)",

    "& .box-infor": {
      fontSize: "13px",

      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "0 6px",
    },

    "& .border": { borderRight: "1px solid #DCDCDC" },

    "& .box-insurance": {
      fontSize: "13px",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      padding: "0 6px",
    },
  },

  containerTabsDetail: {
    marginTop: "16px",

    "& .box_render_tab": {
      marginTop: "12px",
    },
  },

  tabsDetail: {
    borderBottom: "1px solid #3961A0",
    zIndex: 3,
    position: "sticky",
    top: 0,
    background: "white",
    "& .MuiTabScrollButton-root": {
      width: "20px",
    },

    "& .MuiButtonBase-root.MuiTab-root": {
      fontSize: "14px",
      textTransform: "none",
      fontWeight: "500",
      color: "#3961A0",
    },

    "& .MuiButtonBase-root.Mui-selected": {
      fontWeight: "700",
      color: "#3961A0",
      background: "white",
    },

    "& .MuiTabs-indicator": {
      background: "#3961A0",
    },
  },

  containerRightStatusTab: {
    background: "white",
    overflow: "auto",
    height: "calc(100vh - 140px)",
  },

  containerTabContractInformation: {
    marginTop: "12px",
    background: "white",
    padding: "12px",

    "& .title-packege-insurance": {
      padding: "8px 0",
      fontWeight: "600",
      fontSize: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },

  container_package_insurance: {
    background: "#F8F8F8",
    width: "100%",
    padding: "12px",
    marginBottom: "12px",

    "& .item-grid-package-insurance": {
      fontSize: "14px",
    },
  },

  div: {
    paddingBottom: "24px",
  },

  table: {
    minWidth: "800px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      fontWeight: "500",
      textAlign: "center",
      background: "none !important",
    },

    "& td": {
      textAlign: "center",
      padding: 0,
    },

    "& th.stt": {
      width: "45px",
    },

    "& th.fullname": {
      width: "150px",
    },

    "& th.cmnd": {
      width: "200px",
    },

    "& th.relationship": {
      width: "235px",
    },

    "& th.sick-code": {
      width: "150px",
      textAlign: "left",
    },

    "& th.sick-name": {
      textAlign: "left",
    },

    "& td.sick-code": {
      textAlign: "left",
      paddingLeft: "5px",
    },

    "& td.sick-name": {
      textAlign: "left",
      paddingLeft: "5px",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#ECECEC !important",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #D6DEEB",
    background: "white !important",
  },

  //
}));
