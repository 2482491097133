import _ from "lodash";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  getDetailIndemnify,
  updataDetail,
  uploadImg,
  getFileByUrl,
} from "../../../../../apis/claimAhamoveApi";
import { deleteS3Document } from "../../../../../apis/claimApis";

import History from "../../../../../configs/history";
import { message } from "antd";

const getItemImg = ({ data, index, id }) => {
  switch (id) {
    case 200:
      return {
        name: `Hình ảnh hàng hoá, tài liệu trước khi bị sự cố ${index + 1}`,
        path: data.path,
      };
    case 201:
      return {
        name: `Hình ảnh hàng hoá, tài liệu sau khi bị sự cố ${index + 1}`,
        path: data.path,
      };
    case 202:
      return {
        name: `Hình ảnh hóa đơn ${index + 1}`,
        path: data.path,
      };
    case 203:
      return {
        name: `Hình ảnh bổ sung ${index + 1}`,
        path: data.path,
      };

    default:
      return null;
  }
};

export default function detailRrducer({
  state,
  dispatch,
  action,
  roleType,
  id,
}) {
  const cases = {
    initial: async () => {
      dispatch("onLoading");
      const res = {};
      const dataApi = await getDetailIndemnify(roleType, id);
      if (dataApi?.data?.statusCode === 200) {
        const data = _.get(dataApi, "data.result") || {};
        const temp = (data?.elements || []).find((i) => i.type_id === 203);
        const files203 = temp?.extra_info?.documents || [];

        res.content = temp?.text || "";
        res.approvalAmount = temp?.value || "";
        res.row = data;
        res.initStatusId = _.get(dataApi, "data.result.statusId");
        res.statusId = _.get(dataApi, "data.result.statusId");
        res.documentsAdditionalImg = Array.isArray(temp?.extra_info?.documents)
          ? temp?.extra_info?.documents.filter((i) => i.type !== "file")
          : temp?.documents.filter((i) => i.type !== "file") || [];
        res.documentsAdditionalFile = files203.filter((i) => i.type === "file");

        const audio =
          (data?.elements || []).find((i) => i.type_id === 205)?.extra_info
            ?.documents || [];
        res.documentAudio = audio?.map((i) => ({ ...i, playAudio: true }));
        res.updated = [6, 7].includes(res.statusId);
      }

      return {
        ...res,
        loading: false,
        showImg: {},
      };
    },

    back: () => {
      History.goBack();
    },

    onchangeContent: () => {
      const { e } = action;
      return {
        content: e.target.value,
        errors: { ...state.errors, errorContent: !e.target.value },
      };
    },

    onchangeApprovalAmount: () => {
      const { e } = action;
      const value = e.target.value.replace(/\./g, "");
      return {
        approvalAmount: value,
        errors: {
          ...state.errors,
          approvalAmount: !value && value !== 0,
        },
      };
    },

    changeStatusId: () => {
      const { e } = action;
      const { errors, statusId } = state;

      const res = {};
      if (+e.target.value === 7) {
        res.approvalAmount = 0;
        res.errors = { ...errors, approvalAmount: false };
      }

      if (statusId === 7) {
        res.approvalAmount = null;
      }

      if (+e.target.value === 4) {
        res.errors = { ...errors, approvalAmount: false };
      }

      return { statusId: +e.target.value, ...res };
    },

    update: () => {
      const { content, approvalAmount, statusId } = state;
      if (
        !content ||
        (!approvalAmount && +statusId !== 4 && approvalAmount !== 0)
      ) {
        return {
          errors: {
            ...state.errors,
            errorContent: !content,
            approvalAmount:
              !approvalAmount && +statusId !== 4 && approvalAmount !== 0,
          },
        };
      }
      return { confirmDialog: true };
    },

    onSubmitUpdate: async () => {
      const {
        content,
        statusId,
        approvalAmount,
        row,
        documentsAdditionalImg,
        documentsAdditionalFile,
        documentAudio,
      } = state;

      const newDocumentAudio = documentAudio.map(
        ({ playAudio, ...item }) => item
      );

      dispatch("onLoading");
      const params = {
        claimId: row.id,
        productIdParent: 24,
        elements: [
          {
            typeId: 203,
            typeCode: "note",
            extraInfo: {
              documents: [
                ...(documentsAdditionalImg || []),
                ...(documentsAdditionalFile || []),
              ],
            },
            text: content,
            value: approvalAmount || null,
          },
          {
            typeId: 205,
            typeCode: "file_voice",
            extraInfo: { documents: newDocumentAudio },
          },
        ],
      };

      const action =
        statusId === 4
          ? "amend"
          : statusId === 6
          ? "complete"
          : statusId === 7
          ? "reject"
          : "";

      const dataApi = await updataDetail(action, params);

      const res = {};
      if (dataApi?.data?.statusCode === 200) {
        res.confirmDialog = false;
        res.updated = true;
        res.snackbar = {
          open: true,
          message: "Cập nhật thành công",
          type: "success",
        };
        row.statusId = dataApi?.data?.result?.status_id;
      }
      if (dataApi instanceof Error) {
        return {
          confirmDialog: false,
          loading: false,
          snackbar: {
            open: true,
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
            type: "error",
          },
        };
      }

      return { loading: false, ...res };
    },

    additionalImg: async () => {
      const { e } = action;
      const { documentsAdditionalImg } = state;
      const file = e.target.files[0];
      const data = new FormData();

      if (!file) {
        return;
      }

      data.append("document", file);

      dispatch("onLoading");
      const api = await uploadImg(data);

      if (api instanceof Error) {
        return { loading: false };
      }

      const res = {};
      if (api?.data?.statusCode === 200) {
        const dataApi = _.get(api, "data.result[0]") || {};
        res.documentsAdditionalImg = [
          ...documentsAdditionalImg,
          { path: dataApi?.path, size: dataApi?.size },
        ];
      }

      return { loading: false, ...res };
    },

    showEvidence: () => {
      const { showFileEvidence } = state;
      const { file } = action;
      showFileEvidence.open = true;
      showFileEvidence.link = file.path;
      return { showFileEvidence };
    },

    closeShowEvidence: () => ({ showFileEvidence: {} }),

    additionalFile: async () => {
      const { name, e } = action;
      const { documentsAdditionalFile } = state;
      const file = e.target.files[0];
      const data = new FormData();

      if (!file) {
        return;
      }

      data.append("document", file);
      dispatch("onLoading");
      const api = await uploadImg(data);

      if (api instanceof Error) {
        return { loading: false };
      }

      const res = {};
      if (api?.data?.statusCode === 200) {
        const dataApi = _.get(api, "data.result[0]") || {};
        res.loading = false;
        res.documentsAdditionalFile = [
          ...documentsAdditionalFile,
          {
            path: dataApi?.path,
            size: dataApi?.size,
            originalname: dataApi?.originalname,
            type: name,
          },
        ];
      }

      return res;
    },

    downloadFile: async () => {
      const { path, name } = action;
      const dataApi = await getFileByUrl(path);
      saveAs(dataApi, `${name}`);
    },

    deleteFile: () => {
      const { index, url, name } = action;
      const { documentsAdditionalFile } = state;
      if (url) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: url },
        });
      }
      documentsAdditionalFile.splice(index, 1);
      return { documentsAdditionalFile };
    },

    deleteAdditionalImg: () => {
      const { indexImg, itemIMG } = action;
      const { documentsAdditionalImg } = state;
      const res = {};

      if (itemIMG.path) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: itemIMG.path },
        });
      }

      documentsAdditionalImg.splice(indexImg, 1);

      return { documentsAdditionalImg, ...res };
    },

    closeConfirm: () => ({
      confirmDialog: false,
    }),

    showDialog: () => {
      const { indexImg, listImg, name } = action;
      const { showImg } = state;

      if (name === "additionalImg") {
        showImg.listImg = listImg?.map((i) => i.path);
        showImg.path = listImg[indexImg].path;
      } else {
        showImg.listImg = (
          Array.isArray(listImg?.extra_info?.documents)
            ? listImg?.extra_info?.documents
            : listImg?.documents || []
        )?.map((i) => i.path);
        showImg.path = showImg?.listImg[indexImg];
      }

      showImg.open = true;
      showImg.indexImg = indexImg;

      return { showImg };
    },

    changeIndexImg: () => {
      const { showImg } = state;
      const { name } = action;
      const res = {};
      if (name === "next") {
        const index =
          showImg?.indexImg === showImg?.listImg?.length - 1
            ? 0
            : showImg?.indexImg + 1;

        res.showImg = {
          ...showImg,
          indexImg: index,
          path: showImg?.listImg[index],
        };
      } else {
        const index =
          showImg?.indexImg === 0
            ? showImg?.listImg.length - 1
            : showImg?.indexImg - 1;

        res.showImg = {
          ...showImg,
          indexImg: index,
          path: showImg?.listImg[index],
        };
      }

      return res;
    },

    closeShowImg: () => ({
      showImg: { ...state.showImg, open: false },
    }),

    onLoading: () => ({ loading: true }),

    // onLoadPDF: () => {
    //   const { filePDF } = action;
    //   return { statusPDF: { loading: false, file: filePDF } };
    // },

    downLoadZip: async () => {
      const { row, statusPDF } = state;
      const { file } = action;
      dispatch("onLoading");

      const elements = row.elements || [];
      const listPathAndNameImg = [];
      const listImgFileZip = [];
      elements.forEach((item) => {
        if (
          (Array.isArray(item?.extra_info?.documents)
            ? item?.extra_info?.documents
            : item?.documents || []
          )?.length > 0
        ) {
          (Array.isArray(item?.extra_info?.documents)
            ? item?.extra_info?.documents
            : item?.documents || []
          )?.forEach((el, index) => {
            const itemArray = getItemImg({ data: el, index, id: item.type_id });
            !_.isEmpty(itemArray) && listPathAndNameImg.push(itemArray);
          });
        }
      });

      if (listPathAndNameImg.length > 0) {
        await Promise.all(
          listPathAndNameImg.map(async (i) => {
            const dataApi = await getFileByUrl(i.path);
            listImgFileZip.push({ name: i.name, file: dataApi });
          })
        );
      }

      const zip = new JSZip();
      zip.file("thông báo tổn thất và yêu cầu bồi thường.pdf", file);
      const img = zip.folder("Hình ảnh");
      listImgFileZip.forEach((blob, index) => {
        img.file(`${blob.name}.png`, blob.file, { base64: true });
      });
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "thông báo tổn thất và yêu cầu bồi thường.zip");
      });

      return { loading: false };
    },

    closeSnackbar: () => ({ snackbar: { ...state.snackbar, open: false } }),

    loadingAudio: () => ({ loadAudio: true }),

    addAudio: async () => {
      const { e } = action;
      const { documentAudio } = state;
      if (e.target.files.length < 1) {
        return;
      }

      const file = e.target.files[0];
      const data = new FormData();
      data.append("document", file);

      dispatch("loadingAudio");
      const resultApi = await uploadImg(data);
      if (resultApi instanceof Error) {
        message.error(resultApi?.response?.data?.message || "Xảy ra lỗi", 3);

        return { loadAudio: false };
      }

      const res = {};
      const dataApi = _.get(resultApi, "data.result[0]") || {};

      res.documentAudio = [
        ...documentAudio,
        {
          ...dataApi,
          size: `${((dataApi?.size || 0) / 1024).toFixed()} KB`,
          playAudio: true,
        },
      ];
      return { ...res, loadAudio: false };
    },

    setPlayIcon: () => {
      const { documentAudio } = state;
      const { index, all, value } = action;
      if (all) {
        const arr = documentAudio.map((i) => ({
          ...i,
          playAudio: true,
          progress: 0,
          currentTime: 0,
        }));
        return { documentAudio: arr };
      }

      documentAudio[index] = {
        ...documentAudio[index],
        playAudio: value,
      };

      return { documentAudio };
    },

    setAudioPlay: () => {
      const { documentAudio } = state;
      const { index, progress, currentTime, duration } = action;
      documentAudio[index] = {
        ...documentAudio[index],
        progress:
          progress == 0
            ? progress
            : progress || documentAudio[index]?.progress || 0,
        currentTime:
          currentTime == 0
            ? currentTime
            : currentTime || documentAudio[index]?.currentTime || 0,
        duration:
          duration == 0
            ? duration
            : duration || documentAudio[index]?.duration || 0,
      };

      return { documentAudio };
    },

    cantPlayAudio: () => {
      return {
        snackbar: {
          ...state.snackbar,
          open: true,
          type: "error",
          message:
            "Vui lòng download để nghe offline, trình duyệt chưa hỗ trợ file này.",
        },
      };
    },

    deleteAudio: () => {
      const { documentAudio } = state;
      const { index, url } = action;

      if (url) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: url },
        });
      }

      documentAudio.splice(index, 1);
      return { documentAudio };
    },

    dowloadAudio: async () => {
      const { documentAudio } = state;
      const item = documentAudio[action.index];
      const dataApi = await getFileByUrl(item.path);
      saveAs(dataApi, `${item?.originalname}`);
    },

    copyToClipboard: () => {
      const { row } = state;
      const textArea = document.createElement("textarea");
      textArea.value = row?.extraInfo?.codeDestination || "";

      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      message.success("Đã sao chép!", 3);
    },
  };

  return action?.type && cases[action?.type];
}
