import React, { useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { initialStateDetailBLVP } from "../util";
import { detailBLVP } from "../styles/styleDetailBLVP";
import detailBLVPReducer from "../reducers/detailBLVPReducer";
import HeaderDetailBLVP from "../components/detail/headerDetailBLVP";

import LoadingMui from "../../../../ui/LoadingMui";
import useReducer from "../../../../library/hooks/useReducer";
import Snackbars from "../../../../ui/snackbar";
import TabsDetailBLVP from "../components/detail/tabDetailBLVP";
import RightStatusTabsBLVP from "../components/detail/rightStatusTabsBLVP";
import { useParams } from "react-router-dom";

export default function DetailBLVP() {
  const classes = detailBLVP();
  const { id } = useParams();

  const [state, dispatch] = useReducer({
    initialState: initialStateDetailBLVP,
    reducer: detailBLVPReducer,
    name: "detailBLVPReducer",
    id,
  });

  useEffect(() => {
    dispatch("initail");
  }, []);

  const { loading, snackbar } = state;

  return (
    <ThemeProvider
      theme={createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 2250,
          },
        },
      })}
    >
      <div className={classes.container}>
        <Grid container>
          <Grid
            item
            sm={12}
            md={9}
            xl={9}
            xxl={10}
            style={{
              padding: "0 10px",
              overflow: "auto",
              height: "calc(100vh - 85px)",
            }}
          >
            <HeaderDetailBLVP {...{ state, dispatch }} />
            <TabsDetailBLVP {...{ state, dispatch }} />
          </Grid>
          <Grid
            item
            sm={12}
            md={3}
            xl={3}
            xxl={2}
            style={{ background: "white" }}
          >
            <RightStatusTabsBLVP {...{ state, dispatch }} />
          </Grid>
        </Grid>
        <LoadingMui loading={Boolean(loading)} />
        <Snackbars
          open={Boolean(snackbar.open)}
          type={snackbar.type}
          onClose={() => dispatch("closeSnackbar")}
          message={snackbar.message}
        />
      </div>
    </ThemeProvider>
  );
}
