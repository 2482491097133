import { Button, Grid, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useReducer from "../../../../library/hooks/useReducer";
import LoadingMui from "../../../../ui/LoadingMui";
import AddProduct from "../components/AddProduct";
import AddProvider from "../components/AddProvider";
import detailReducer from "../useReducer/detailReducer";
import { useStyles } from "../useStyles";
import { formatDate, getParamUrlByKey } from "../utils";

import Addcircle from "../assets/add-circle.svg";
import EditIcon from "../assets/edit-icon.svg";
import DefaultIcon from "../assets/logo-default.svg";

const initialState = {
  data: {},
  open: { provider: false, product: false },
  loading: false,
  product_parent: [],
  errors: [],
};

function Detail() {
  const history = useHistory();
  const id = getParamUrlByKey("id");
  const classes = useStyles();
  const widthLayout750 = useMediaQuery("(min-width:750px)");
  const widthLayout900 = useMediaQuery("(max-width:900px)");

  const [state, dispatch] = useReducer({
    reducer: detailReducer,
    name: "detailReducer",
    initialState,
    history,
    id,
  });

  useEffect(() => {
    dispatch("getDetail");
  }, []);

  const { data, loading, open } = state;

  return (
    <>
      <div className={classes.headerBar}>Quản lý công ty bảo hiểm</div>
      <LoadingMui loading={loading} />
      <Grid container className={classes.create} spacing={2}>
        <Grid
          item
          xs={12}
          sm={widthLayout750 && widthLayout900 ? 5 : !widthLayout750 ? 12 : 3.5}
          className="info"
        >
          <div className="center">
            {data?.logo ? (
              <img src={data?.logo} alt="upload" className="logo-product" />
            ) : (
              <img src={DefaultIcon} alt="upload" className="logo-product" />
            )}
            <div className="name-product">{data?.title || ""}</div>
            <div className="tax-code">MST: {data?.taxCode || ""}</div>

            <Button
              className="button"
              onClick={() => dispatch({ type: "onOpen", name: "provider" })}
              startIcon={<img src={EditIcon} alt="ic" className="icon" />}
            >
              Chỉnh sửa
            </Button>
          </div>
          <div className="row data">
            <div className="nowrap">Số lượng sản phẩm</div>
            <div className="value">{data?.totalNumberClaimProduct || 0}</div>
          </div>
          <div className="row data">
            <div className="nowrap">Yêu cầu bồi thường</div>
            <div className="value">{data?.totalNumberClaim || 0} đơn</div>
          </div>
          <div className="row data">
            <div className="nowrap">Số điện thoại</div>
            <div className="value">{data?.phone || ""}</div>
          </div>
          <div className="row data">
            <div className="nowrap">Địa chỉ</div>
            <div className="value">{data?.address || ""}</div>
          </div>
          <div className="row data">
            <div className="nowrap">Email</div>
            <div className="value break-word">{data?.email || ""}</div>
          </div>
          <div className="create-date">
            Ngày tham gia:{" "}
            <span className="value">{formatDate(data?.createdDate)}</span>
          </div>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={widthLayout750 && widthLayout900 ? 7 : !widthLayout750 ? 12 : 8.5}
          spacing={2}
        >
          <Grid item xs={12} className="bold">
            Sản phẩm đang bán
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            onClick={() => dispatch({ type: "onOpen", name: "product" })}
          >
            <div className="center box">
              <img src={Addcircle} alt={`icon`} className="logo" />
              <div className="add-product">Thêm sản phẩm</div>
            </div>
          </Grid>
          {(data?.productParentList || []).map((item, index) => (
            <Grid
              item
              xs={12}
              md={4}
              onClick={() => dispatch({ type: "changePage", item })}
            >
              <div className="row box">
                <img
                  src={item?.urlLogo || DefaultIcon}
                  alt={`ic${index}`}
                  className="logo border"
                />
                <div>
                  <div className="title">{`Bảo hiểm ${item?.title || ""}`}</div>
                  <div className="date">
                    Ngày tạo: {formatDate(item?.createdDate)}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
        <AddProvider
          open={open?.provider}
          id={id}
          state={state}
          dispatch={dispatch}
        />
        <AddProduct open={open?.product} state={state} dispatch={dispatch} />
      </Grid>
    </>
  );
}

export default Detail;
