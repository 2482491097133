import api from '../configs/api';

function getListMerchant() {
  return api.apiForm('POST', '/api/v1/merchant/list');
};

function getListMerchantSelected() {
  return api.apiForm('POST', '/api/v1/merchant-claim/list');
};

function updateMerchantList(data) {
  return api.apiForm('POST', '/api/v1/merchant-claim', data);
};

export {
  getListMerchant,
  updateMerchantList,
  getListMerchantSelected
};
