import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import _ from "lodash";
import moment from "moment";
import React from "react";

import check from "../../../../../../../assets/health/check.png";
import checked from "../../../../../../../assets/health/checked.png";
import sign from "../../../../../../../assets/health/sign.png";
import stamp from "../../../../../../../assets/health/stamp.png";
import LogoGC from "../../../../../../../assets/lauyout/logo-gc.png";

import check_signature_file from "../../../../../../../assets/health/check_signature_pdfFile.png";

import {
  converDate,
  convertCurrency,
  formatDate,
  listTypeOfRisk,
  renderRelationship,
  typeOfIndimnity,
} from "../../../utils";

Font.register({
  family: "times-new-roman",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman@1.0.4/Times New Roman.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-bold@1.0.4/Times New Roman Bold.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-italic@1.0.4/Times New Roman Italic.ttf",
      fontStyle: "italic",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-bold-italic@1.0.4/Times New Roman Bold Italic.ttf",
      fontStyle: "bold-italic",
    },
  ],
});

export default function ExportClaimPDF({
  detail,
  approveAmount,
  listImgBase64PDFfile = [],
  noteUseLV1,
}) {
  const section_report_tt_chuyen_khoan = [
    {
      label: "Họ và tên chủ tài khoản: ",
      value:
        detail?.extraInfo?.beneficiaryName ||
        detail?.insuredInfo?.beneficiaryName ||
        "",
    },
    {
      label: "Mối quan hệ với người được BH: ",
      value: renderRelationship(
        detail?.insuredInfo?.relationshipWithInsuredPersonId
      ),
    },
    {
      label: "Ngân hàng: ",
      value:
        detail?.extraInfo?.beneficiaryBank ||
        detail?.extraInfo?.beneficiaryBankTitle ||
        detail?.insuredInfo?.beneficiaryBankTitle ||
        "",
    },

    {
      label: "Chi nhánh: ",
      value:
        detail?.extraInfo?.beneficiaryBankBranchName ||
        detail?.insuredInfo?.beneficiaryBankBranchName ||
        "",
    },

    {
      label: "Số tài khoản: ",
      value:
        detail?.extraInfo?.beneficiaryBankAcountNumber ||
        detail?.insuredInfo?.beneficiaryBankAcountNumber ||
        "",
    },
  ];

  const thong_tin_boi_thuong_d1_o1 = [
    { label: "Mã hồ sơ: ", value: detail?.code || "" },
    { label: "Số hợp đồng BH: ", value: detail?.certNum || "" },
    { label: "Thời hạn BH: ", value: formatDate(detail?.endDate) },
  ];

  const thong_tin_boi_thuong_d1_o2 = [
    { label: "Họ và tên : ", value: detail?.insuredInfo?.insuredName || "" },
    {
      label: "Ngày sinh: ",
      value: converDate(detail?.insuredInfo?.insuredDob)
        ? moment(converDate(detail?.insuredInfo?.insuredDob)).format(
            "DD/MM/YYYY"
          )
        : "",
    },
    {
      label: "CMND/CCCD: ",
      value: detail?.insuredInfo?.insuredPrivateId || "",
    },
    {
      label: "Giới tính: ",
      value:
        detail?.insuredInfo?.insuredGender === 1
          ? "Nam"
          : detail?.insuredInfo?.insuredGender === 0
          ? "Nữ"
          : "",
    },
    { label: "Email: ", value: detail?.insuredInfo?.insuredEmail || "" },
  ];

  const thong_tin_boi_thuong_d2_o1 = [
    {
      label: "Loại bồi thường: ",
      value:
        listTypeOfRisk.find((i) => i.id == detail?.extraInfo?.typeOfRisk)
          ?.title || "",
    },
  ];

  const thong_tin_boi_thuong_d2_o2 = [
    { label: "Nơi điều trị: ", value: detail?.extraInfo?.treatmentPlace || "" },
    {
      label: "Khoa điều trị: ",
      value: detail?.extraInfo?.departmentTreatment || "",
    },
    {
      label: "Ngày vào viện: ",
      value: converDate(detail?.extraInfo?.dateOfHospital)
        ? moment(converDate(detail?.extraInfo?.dateOfHospital)).format(
            "HH:mm, DD/MM/YYYY"
          )
        : "",
    },
    {
      label: "Ngày ra viện: ",
      value: converDate(detail?.extraInfo?.dateOutOfHospital)
        ? moment(converDate(detail?.extraInfo?.dateOutOfHospital)).format(
            "HH:mm, DD/MM/YYYY"
          )
        : "",
    },
    {
      label: "Bác sỹ điều trị: ",
      value: detail?.extraInfo?.doctorName || "",
    },
  ];

  const nguoi_thu_huong_o1 = [
    {
      label: "Họ và tên: ",
      value:
        detail?.extraInfo?.beneficiaryName ||
        detail?.insuredInfo?.beneficiaryName ||
        "",
    },
    {
      label: "MQH với người được BH: ",
      value:
        detail?.insuredInfo?.relationshipWithInsuredPersonId == 1
          ? "Bản thân"
          : detail?.insuredInfo?.relationshipWithInsuredPersonId == 2
          ? "Người thân"
          : "",
    },
  ];

  const nguoi_thu_huong_o2 = [
    {
      label: "Số tài khoản: ",
      value:
        detail?.extraInfo?.beneficiaryBankAcountNumber ||
        detail?.insuredInfo?.beneficiaryBankAcountNumber ||
        "",
    },
    {
      label: "Ngân hàng: ",
      value:
        detail?.extraInfo?.beneficiaryBank ||
        detail?.extraInfo?.beneficiaryBankTitle ||
        detail?.insuredInfo?.beneficiaryBankTitle ||
        "",
    },
    {
      label: "Chi nhánh ",
      value:
        detail?.extraInfo?.beneficiaryBankBranchName ||
        detail?.insuredInfo?.beneficiaryBankBranchName ||
        "",
    },
  ];

  const rows_diagnosis = _.get(detail, "extraInfo.doctorDiagnoses") || [];

  const rows_examination = _.get(detail, "extraInfo.examinationFees") || [];

  let fee_approve_examination = 0;

  rows_examination.forEach((i) => (fee_approve_examination += +i?.approveFee));

  const rows_bills = _.get(detail, "extraInfo.bills") || [];

  let fee_approve_bills = 0;

  rows_bills.forEach((i) => (fee_approve_bills += +i?.approveFee));

  const rows_medicines = _.get(detail, "extraInfo.medicines") || [];

  let fee_approve_medicines = 0;

  rows_medicines.forEach((i) => (fee_approve_medicines += +i?.approveFee));

  const rows_others = _.get(detail, "extraInfo.others") || [];

  let fee_approve_others = 0;

  rows_others.forEach((i) => (fee_approve_others += +i?.approveFee));
  // const listTypeId = [
  //   500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513, 514,
  //   515, 516, 523, 524, 525, 528,
  // ];

  // const noteUseL1 = detail?.elements?.find((i) => i.type_id == 521) || {};

  // const listImg = (detail?.elements || []).reduce((array, item) => {
  //   if (listTypeId.includes(+item?.type_id)) {
  //     const get_img_in_item = (item?.extra_info?.documents || [])
  //       .filter((i) => !!i.path)
  //       .map((i) => i.path);
  //     return [...array, ...get_img_in_item];
  //   }
  //   return array;
  // }, []);
  let otherTables = [];
  const getTitleByTypeOfIndimnity = () => {
    switch (+detail?.extraInfo?.typeOfIndimnity) {
      case 3:
        otherTables = (detail?.extraInfo?.injuries || []).map((i) => ({
          ...i,
          otherText: i.injuryType || "",
        }));
        return {
          name: "injuries",
          thName: "Loại thương tật",
          title: "Tỉ lệ thương tật",
        };
      case 5:
        otherTables = (
          detail?.extraInfo?.canceOrFatalDiseaseOrStroke || []
        ).map((i) => ({
          ...i,
          otherText: i.diseaseType || "",
        }));
        return {
          name: "canceOrFatalDiseaseOrStroke",
          thName: "Loại bệnh",
          title: "Ung thư/ bệnh hiểm nghèo/ đột quỵ",
        };
      case 6:
        otherTables = (detail?.extraInfo?.deaths || []).map((i) => ({
          ...i,
          otherText: i.reasonOfDeath || "",
        }));
        return {
          name: "deaths",
          thName: "Nguyên nhân tử vong",
          title: "Tử vong",
        };
      default:
        return {};
    }
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section_report}>
          <View style={styles.section_row}>
            {/* <Image
              src={LogoGC}
              style={{
                width: "100%",
                maxWidth: "15%",
              }}
            /> */}
            <Text></Text>
            <Text>
              {`TPHCM, ngày ${moment().format("DD")} tháng ${moment().format(
                "MM"
              )} năm ${moment().format("YYYY")}`}
            </Text>
          </View>
          <Text
            style={{
              textAlign: "center",
              fontSize: 14,
              fontWeight: "bold",
              marginTop: "12px",
            }}
          >
            {`THÔNG BÁO ${!detail?.amount ? "TỪ CHỐI " : ""}BỒI THƯỜNG`}
          </Text>
          <Text
            style={{ textAlign: "center", marginBottom: 15 }}
          >{`Số hồ sơ bồi thường: ${detail?.code || ""}`}</Text>
          <Text style={styles.break}>
            <Text>Kính gửi: Quý khách hàng,</Text>
          </Text>
          <Text style={styles.break}>
            Công ty {`${detail?.organizationTitle || ""}`} thông báo đến Quý
            khách hàng thông tin hồ sơ yêu cầu trả tiền bảo hiểm sau đây:
          </Text>
          <View style={styles.section_list}>
            <Text style={styles.flex_row}>
              <Text> - Người được bảo hiểm: </Text>
              <Text style={{ textTransform: "capitalize" }}>
                {detail?.insuredInfo?.insuredName || ""}
              </Text>
            </Text>
            <Text>
              - Số hợp đồng bảo hiểm:{" "}
              {detail?.certNum || detail?.cert_num || ""}
            </Text>
            <Text>
              - Thời hạn bảo hiểm: từ {formatDate(detail?.beginDate)} đến{" "}
              {formatDate(detail?.endDate)}
            </Text>
            <Text>
              - Đơn vị tham gia bảo hiểm/ mã đơn hàng bảo hiểm:{" "}
              {detail?.merchantTitle || detail?.merchant_title || ""}
            </Text>
            <Text>
              - Số tiền yêu cầu chi trả:{" "}
              {convertCurrency(detail?.requestAmount)}
            </Text>
          </View>
          <Text style={styles.break}>
            {detail?.amount
              ? "Sau khi xem xét hồ sơ yêu cầu chi trả bảo hiểm của Quý khách, chúng tôi thông báo chi trả bảo hiểm cho Quý khách như sau:"
              : "Sau khi xem xét chi tiết hồ sơ, bộ phận Bồi thường Global Care rất tiếc gửi đến Quý khách thông báo từ chối bồi thường cho hồ sơ yêu cầu thanh toán tiền bảo hiểm của Quý khách như sau:"}
          </Text>
          <View style={styles.break}>
            <View style={styles.flex_row}>
              <Text style={styles.table_cell}>Loại bồi thường</Text>
              <Text style={styles.table_cell}>Số tiền YCBT</Text>
              <Text style={styles.table_cell}>Số tiền không thanh toán</Text>
              <Text style={styles.table_cell}>Số tiền được bồi thường</Text>
            </View>
            <View style={styles.flex_row}>
              <Text style={styles.table_cell}>
                {typeOfIndimnity.find(
                  (i) => i.id == detail?.extraInfo?.typeOfIndimnity
                )?.title || ""}
              </Text>
              <Text style={styles.table_cell}>
                {convertCurrency(detail?.requestAmount)}
              </Text>
              <Text style={styles.table_cell}>
                {(detail?.requestAmount || 0) -
                  (approveAmount || detail?.amount || 0) <
                0
                  ? 0
                  : convertCurrency(
                      (detail?.requestAmount || 0) -
                        (approveAmount || detail?.amount || 0)
                    )}
              </Text>
              <Text style={styles.table_cell}>
                {convertCurrency(approveAmount || detail?.amount || 0)}
              </Text>
            </View>
            <View style={styles.flex_row}>
              <Text style={styles.table_cell}>Tổng cộng</Text>
              <Text style={styles.table_cell}>
                {convertCurrency(detail?.requestAmount)}
              </Text>
              <Text style={styles.table_cell}>
                {(detail?.requestAmount || 0) -
                  (approveAmount || detail?.amount || 0) <
                0
                  ? ""
                  : convertCurrency(
                      (detail?.requestAmount || 0) -
                        (approveAmount || detail?.amount || 0)
                    )}
              </Text>
              <Text style={styles.table_cell}>
                {convertCurrency(approveAmount || detail?.amount || 0)}
              </Text>
            </View>
          </View>
          <Text style={styles.break}>
            Diễn giải (nếu có):{" "}
            {/* {detail.elements?.find((item) => item.type_id == 520)?.text || ""} */}
            {noteUseLV1?.note || ""}
          </Text>

          {detail?.amount ? (
            <>
              <Text style={styles.break}>
                Số tiền trên sẽ được chuyển vào tài khoản theo thông tin sau:
              </Text>
              <View style={styles.section_list}>
                {section_report_tt_chuyen_khoan.map((item, index) => (
                  <Text key={index}>
                    + {item.label} {item.value}
                  </Text>
                ))}
              </View>
              <Text style={styles.break}>
                Quý khách vui lòng kiểm tra tài khoản sau 3 ngày kể từ ngày nhận
                được thông báo này.
              </Text>
            </>
          ) : (
            <></>
          )}

          <Text style={styles.break}>
            Nếu có bất kỳ thắc mắc nào liên quan đến việc giải quyết chi trả bảo
            hiểm nêu trên hoặc cần thêm thông tin, Quý khách vui lòng liên hệ
            với chúng tôi để được giải đáp.
          </Text>
          <Text style={styles.break}>Trân trọng.</Text>
          <View style={{ ...styles.break, position: "relative" }}>
            <Text style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              ​CÔNG TY {`${detail?.organizationTitle || ""}`}
            </Text>
            <Text style={{ marginTop: "8px" }}>Người phê duyệt</Text>
            <Text style={{ textTransform: "capitalize" }}>
              {detail?.userFullnameAssign || ""}
            </Text>
            <Image
              style={styles.img_check}
              src={check_signature_file}
              cache={false}
            />
          </View>
          {/* <View style={styles.break}>
            <Image style={styles.sign} src={sign} cache={false} />
            <Image style={styles.stamp} src={stamp} cache={false} />
          </View>
          <View style={{ marginTop: 60 }}>
            <Text style={{ fontWeight: "bold" }}>
              ​CÔNG TY CP TƯ VẤN GLOBAL CARE
            </Text>
            <Text style={{ fontStyle: "italic" }}>
              Office: (+84 28) 7307 7878 | Hotline: 1900 29 29 87
            </Text>
            <Text style={{ fontStyle: "italic" }}>
              1/2 đường 33, P.An Khánh, TP.Thủ Đức.
            </Text>
          </View> */}
        </View>
        <View style={styles.section_report} break={true}>
          <Text
            style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}
          >
            GIẤY YÊU CẦU
          </Text>
          <Text
            style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}
          >
            THANH TOÁN BẢO HIỂM
          </Text>
          <Text style={{ marginTop: 15, fontWeight: "bold" }}>
            Thông tin Người được bảo hiểm:
          </Text>
          <View style={styles.section_row}>
            <Text style={styles.flex_row}>
              <Text>Tên người được bảo hiểm: </Text>
              <Text style={{ textTransform: "capitalize" }}>
                {detail?.insuredInfo?.insuredName || ""}
              </Text>
            </Text>
            <Text>
              Ngày sinh: {formatDate(detail?.insuredInfo?.insuredDob)}
            </Text>
          </View>
          <View style={styles.section_row}>
            <Text>
              Số CMND/CCCD: {detail?.insuredInfo?.insuredPrivateId || ""}
            </Text>
            <Text>
              Số điện thoại: {detail?.insuredInfo?.insuredPhone || ""}
            </Text>
            <Text>Email: {detail?.insuredInfo?.insuredEmail || ""}</Text>
          </View>
          <Text style={{ marginTop: 10, fontWeight: "bold" }}>
            Mô tả tai nạn/Bệnh tật:
          </Text>
          <Text>
            Nguyên nhân, diễn biến, lịch sử bệnh:{" "}
            {detail?.extraInfo?.reason || ""}
          </Text>
          <View style={styles.section_row}>
            <Text>
              Ngày xảy ra rủi ro:{" "}
              {formatDate(detail?.extraInfo?.dateOfAccident)}
            </Text>
            <Text>
              Ngày vào viện: {formatDate(detail?.extraInfo?.dateOfHospital)}
            </Text>
            <Text>
              Ngày ra viện: {formatDate(detail?.extraInfo?.dateOutOfHospital)}
            </Text>
          </View>
          <Text>
            Chẩn đoán:{" "}
            {(detail?.extraInfo?.doctorDiagnoses || [])
              .map((i) => i?.diseaseName || "")
              .join(", ")}
          </Text>
          <Text>Nơi điều trị: {detail?.extraInfo?.treatmentPlace || ""} </Text>
          <Text style={{ marginTop: 10, fontWeight: "bold" }}>
            Thông tin hồ sơ yêu cầu chi trả:
          </Text>
          <Text>
            Bảng liệt kê các chứng từ và hóa đơn gốc nộp kèm yêu cầu trả tiền
            bảo hiểm:
          </Text>
          <View style={styles.section_row}>
            <View style={styles.section_list_item}>
              <Text>Đơn thuốc</Text>
              <Text>
                <Image
                  src={
                    (detail?.elements || []).find((i) => i.type_id == 507)
                      ? checked
                      : check
                  }
                  style={{
                    width: "8px",
                  }}
                />
              </Text>
            </View>
            <View style={styles.section_list_item}>
              <Text>Bảng kê chi tiết điều trị nội trú</Text>
              <Text>
                <Image
                  src={
                    (detail?.elements || []).find((i) => i.type_id == 506)
                      ? checked
                      : check
                  }
                  style={{
                    width: "8px",
                  }}
                />
              </Text>
            </View>
          </View>
          <View style={styles.section_row}>
            <View style={styles.section_list_item}>
              <Text>Chỉ định chiếu chụp/xét nghiệm</Text>
              <Text>
                <Image
                  src={
                    (detail?.elements || []).find((i) => i.type_id == 504)
                      ? checked
                      : check
                  }
                  style={{
                    width: "8px",
                  }}
                />
              </Text>
            </View>
            <View style={styles.section_list_item}>
              <Text>Phiếu tiêm ngừa</Text>
              <Text>
                <Image
                  src={
                    (detail?.elements || []).find((i) => i.type_id == 528)
                      ? checked
                      : check
                  }
                  style={{
                    width: "8px",
                  }}
                />
              </Text>
            </View>
          </View>
          <View style={styles.section_row}>
            <View style={styles.section_list_item}>
              <Text>Kết quả chiếu chụp/xét nghiệm</Text>
              <Text>
                <Image
                  src={
                    (detail?.elements || []).find(
                      (i) => i.type_id == 513 || i.type_id == 514
                    )
                      ? checked
                      : check
                  }
                  style={{
                    width: "8px",
                  }}
                />
              </Text>
            </View>
            <View style={styles.section_list_item}>
              <Text>Báo cáo y tế, tóm tắt bệnh án</Text>
              <Text>
                <Image
                  src={
                    (detail?.elements || []).find((i) => i.type_id == 531)
                      ? checked
                      : check
                  }
                  style={{
                    width: "8px",
                  }}
                />
              </Text>
            </View>
          </View>
          <View style={styles.section_list_item}>
            <Text>Giấy ra viện</Text>
            <Text style={{ marginRight: "40px" }}>
              <Image
                src={
                  (detail?.elements || []).find((i) => i.type_id == 510)
                    ? checked
                    : check
                }
                style={{
                  width: "8px",
                }}
              />
            </Text>
          </View>
          <Text style={{ marginTop: 10, fontWeight: "bold" }}>Cam kết</Text>
          <Text>
            Tôi với tư cách là người yêu cầu bồi thường, xin cam đoan những lời
            khai trên là đúng sự thật. Tôi cũng đồng ý rằng với Giấy yêu cầu
            này, tôi cho phép nhà Bảo Hiểm tiếp xúc với các bên thứ ba (từ bất
            kỳ bác sĩ, cơ sở y tế, nhà thuốc, Công ty và các đại lý hoặc các tổ
            chức khác...) để thu thập thông tin cần thiết để xem xét bồi thường
            cho hợp đồng bảo hiểm của tôi.
          </Text>
          <Text style={{ marginTop: 10, fontWeight: "bold" }}>
            Ngày: {moment().format("DD/MM/YYYY")}
          </Text>
          <Text style={{ marginTop: 5, fontWeight: "bold" }}>
            CHỮ KÝ NGƯỜI YÊU CẦU
          </Text>
          <View style={{ height: 120 }}>
            <Image
              src={
                detail.elements?.find((item) => item.type_id == 518)?.extra_info
                  ?.documents?.[0]?.path || ""
              }
              style={{
                width: "100%",
                maxWidth: "25%",
                marginTop: 15,
              }}
            />
          </View>
          <View style={{ marginTop: 15, marginBottom: 250 }}>
            <Text style={styles.flex_row}>
              <Text>Họ tên: </Text>
              <Text style={{ textTransform: "capitalize", fontWeight: "bold" }}>
                {detail?.insuredInfo?.insuredName || ""}
              </Text>
            </Text>
          </View>
        </View>
        <View style={styles.view1}>
          <Text
            style={styles.view1T1}
          >{`Hồ sơ bồi thường số ${detail?.code} `}</Text>
        </View>
        <View style={{ ...styles.view2, paddingTop: "16px" }}>
          <Text>THÔNG TIN BỒI THƯỜNG</Text>
        </View>
        <View style={styles.view3}>
          <View style={{ width: "48%", marginRight: "5px" }}>
            {thong_tin_boi_thuong_d1_o1.map((item) => (
              <View
                style={{ ...styles.flex_row, width: "100%", marginTop: "8px" }}
                key={item?.label}
              >
                <Text style={styles.textV3_tt}>{item?.label || ""}</Text>
                <Text style={styles.textV3_vl}>{item?.value || ""}</Text>
              </View>
            ))}
          </View>
          <View style={{ width: "48%", marginLeft: "5px" }}>
            {thong_tin_boi_thuong_d1_o2.map((item) => (
              <View
                style={{ ...styles.flex_row, width: "100%", marginTop: "8px" }}
                key={item?.label}
              >
                <Text style={styles.textV3_tt}>{item?.label || ""}</Text>
                <Text
                  style={{ ...styles.textV3_vl, textTransform: "capitalize" }}
                >
                  {item?.value || ""}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.border} />
        <View style={styles.view3}>
          <View style={{ width: "48%", marginRight: "5px" }}>
            {thong_tin_boi_thuong_d2_o1.map((item) => (
              <View
                style={{ ...styles.flex_row, width: "100%", marginTop: "8px" }}
                key={item?.label}
              >
                <Text style={styles.textV3_tt}>{item?.label || ""}</Text>
                <Text style={styles.textV3_vl}>{item?.value || ""}</Text>
              </View>
            ))}
          </View>
          <View style={{ width: "48%", marginLeft: "5px" }}>
            {thong_tin_boi_thuong_d2_o2.map((item) => (
              <View
                style={{ ...styles.flex_row, width: "100%", marginTop: "8px" }}
                key={item?.label}
              >
                <Text style={styles.textV3_tt}>{item?.label || ""}</Text>
                <Text style={styles.textV3_vl}>{item?.value || ""}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.border} />
        <View style={styles.view2}>
          <Text>NGƯỜI THỤ HƯỞNG</Text>
        </View>
        <View style={styles.view3}>
          <View style={{ width: "48%", marginRight: "5px" }}>
            {nguoi_thu_huong_o1.map((item) => (
              <View
                style={{ ...styles.flex_row, width: "100%", marginTop: "8px" }}
                key={item?.label || ""}
              >
                <Text style={styles.textV3_tt}>{item?.label || ""}</Text>
                <Text
                  style={{
                    ...styles.textV3_vl,
                  }}
                >
                  {item?.value}
                </Text>
              </View>
            ))}
          </View>
          <View style={{ width: "48%", marginLeft: "5px" }}>
            {nguoi_thu_huong_o2.map((item) => (
              <View
                style={{ ...styles.flex_row, width: "100%", marginTop: "8px" }}
                key={item?.label}
              >
                <Text style={styles.textV3_tt}>{item?.label || ""}</Text>
                <Text style={styles.textV3_vl}>{item?.value || ""}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.border} />
        <View style={styles.view2}>
          <Text>CÁC MỤC BỒI THƯỜNG</Text>
        </View>

        {[3, 5, 6].includes(+detail?.extraInfo?.typeOfIndimnity) ? (
          <>
            <View style={styles.view4}>
              <Text>{getTitleByTypeOfIndimnity().title || ""}</Text>
            </View>
            <View>
              <View style={styles.head_table}>
                <Text style={{ width: "25%" }}>
                  {getTitleByTypeOfIndimnity().thName}
                </Text>
                <Text style={{ width: "10%" }}>Kết quả</Text>
                <Text style={{ width: "20%", textAlign: "center" }}>Lý do</Text>
                <Text style={{ width: "13%", textAlign: "center" }}>
                  Tổng quyền lợi{" "}
                </Text>
                {detail?.extraInfo?.typeOfIndimnity == 3 && (
                  <Text style={{ width: "10%", textAlign: "center" }}>
                    {"Tỉ lệ (%)"}
                  </Text>
                )}
                <Text
                  style={{
                    width:
                      detail?.extraInfo?.typeOfIndimnity == 3 ? "11%" : "16%",
                    textAlign: "center",
                  }}
                >
                  Thành tiền
                </Text>
                <Text
                  style={{
                    width:
                      detail?.extraInfo?.typeOfIndimnity == 3 ? "11%" : "16%",
                    textAlign: "center",
                  }}
                >
                  Được duyệt
                </Text>
              </View>
              {otherTables.map((item) => (
                <View style={styles.row_body_table} key={item?.diseaseCode}>
                  <Text style={{ width: "25%" }}>{item?.otherText || ""}</Text>
                  <Text style={{ width: "10%" }}>
                    {item?.approveStatus === 1 ? "Chấp nhận" : "Từ chối"}
                  </Text>
                  <Text style={{ width: "20%", textAlign: "center" }}>
                    {item?.note || ""}
                  </Text>
                  <Text style={{ width: "13%", textAlign: "center" }}>
                    {`${item?.benefitAmount || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    )}
                  </Text>
                  {detail?.extraInfo?.typeOfIndimnity == 3 && (
                    <Text style={{ width: "10%", textAlign: "center" }}>
                      {item?.percent || ""}
                    </Text>
                  )}
                  <Text
                    style={{
                      width:
                        detail?.extraInfo?.typeOfIndimnity == 3 ? "11%" : "16%",
                      textAlign: "center",
                    }}
                  >
                    {`${item?.fee || ""}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </Text>
                  <Text
                    style={{
                      width:
                        detail?.extraInfo?.typeOfIndimnity == 3 ? "11%" : "16%",
                      textAlign: "center",
                    }}
                  >
                    {`${item?.approveFee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    )}
                  </Text>
                </View>
              ))}
            </View>
            <View style={{ ...styles.view4, marginTop: "18px" }}>
              <Text>Quyền lợi khác</Text>
            </View>
            <View>
              <View style={styles.head_table}>
                <Text style={{ width: "60%" }}>Quyền lợi</Text>
                <Text style={{ width: "20%", textAlign: "center" }}>
                  Tổng phí
                </Text>
                <Text style={{ width: "20%", textAlign: "center" }}>
                  Được duyệt
                </Text>
              </View>
              {rows_others?.map((item) => (
                <View
                  style={styles.row_body_examination_imp}
                  key={item?.diseaseCode}
                >
                  <Text style={{ width: "60%" }}>{item?.titleBenefit}</Text>
                  <Text style={{ width: "20%", textAlign: "center" }}>
                    {`${item?.fee || ""}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </Text>
                  <Text
                    style={{
                      width: "20%",
                      textAlign: "center",
                      color: "#3961A0",
                    }}
                  >
                    {`${item?.approveFee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    )}
                  </Text>
                </View>
              ))}
              <View style={styles.row_footer}>
                <View style={styles.row_footer_tt_table}>
                  <Text>Tổng phí được duyệt</Text>
                </View>
                <View style={styles.row_footer_vl_table}>
                  <Text>
                    {fee_approve_others
                      ? `${fee_approve_others}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " VNĐ"
                      : 0}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.border} />
          </>
        ) : (
          <>
            <View style={styles.view4}>
              <Text>Chuẩn đoán bệnh</Text>
            </View>
            <View>
              <View style={styles.head_table}>
                <Text style={{ width: "15%" }}>Mã bệnh</Text>
                <Text style={{ width: "45%" }}>Tên bệnh</Text>
                <Text style={{ width: "15%", textAlign: "center" }}>
                  Kết quả
                </Text>
                <Text style={{ width: "25%", textAlign: "center" }}>Lý do</Text>
              </View>
              {rows_diagnosis.map((item) => (
                <View style={styles.row_body_table} key={item?.diseaseCode}>
                  <Text style={{ width: "15%" }}>{item?.diseaseCode}</Text>
                  <Text style={{ width: "45%" }}>{item?.diseaseName}</Text>
                  <Text style={{ width: "15%", textAlign: "center" }}>
                    {item?.approveStatus === 1 ? "Chấp nhận" : "Từ chối"}
                  </Text>
                  <Text style={{ width: "25%", textAlign: "center" }}>
                    {item?.note || ""}
                  </Text>
                </View>
              ))}
            </View>
            <View
              style={{
                ...styles.view4,
                marginTop: !(
                  rows_diagnosis?.length < 3 && rows_examination?.length < 4
                )
                  ? "18px"
                  : "",
              }}
              break={
                !(rows_diagnosis?.length < 3 && rows_examination?.length < 4)
              }
            >
              <Text>Bảng kê chi phí khám</Text>
            </View>
            <View>
              <View style={styles.head_table}>
                <Text style={{ width: "30%" }}>Tên dịch vụ</Text>
                <Text style={{ width: "12%" }}>Kết quả</Text>
                <Text style={{ width: "20%", textAlign: "center" }}>Lý do</Text>
                <Text style={{ width: "15%", textAlign: "center" }}>
                  Tổng phí
                </Text>
                <Text style={{ width: "12%", textAlign: "center" }}>
                  BH khác
                </Text>
                <Text style={{ width: "12%", textAlign: "center" }}>
                  Được duyệt
                </Text>
              </View>
              {rows_examination?.map(
                (item, index) => (
                  <View
                    style={{
                      ...styles.row_body_table,
                      marginTop: index === 27 ? "18px" : "",
                    }}
                    key={index}
                    break={index === 27}
                  >
                    <Text style={{ width: "30%" }}>
                      {item?.serviceName || ""}
                    </Text>
                    <Text style={{ width: "12%" }}>
                      {item?.approveStatus === 1 ? "Chấp nhận" : "Từ chối"}
                    </Text>
                    <Text
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#3961A0",
                      }}
                    >
                      {item?.note}
                    </Text>
                    <Text style={{ width: "12%", textAlign: "center" }}>
                      {`${item?.fee || ""}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}
                    </Text>
                    <Text style={{ width: "13%", textAlign: "center" }}>
                      {`${item?.paidFee || ""}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}
                    </Text>
                    <Text
                      style={{
                        width: "13%",
                        textAlign: "center",
                        color: "#3961A0",
                      }}
                    >
                      {`${item?.approveFee || ""}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}
                    </Text>
                  </View>
                )
                // )
              )}
              <View style={styles.row_footer}>
                <View style={styles.row_footer_tt_table}>
                  <Text>Tổng phí được duyệt</Text>
                </View>
                <View style={styles.row_footer_vl_table}>
                  <Text>
                    {" "}
                    {!!fee_approve_examination
                      ? `${fee_approve_examination}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " VNĐ"
                      : 0}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                ...styles.view4,
                marginTop: !(
                  rows_diagnosis?.length < 3 && rows_examination?.length < 4
                )
                  ? "18px"
                  : "",
              }}
              break={
                !(rows_diagnosis?.length < 3 && rows_examination?.length < 4)
              }
            >
              <Text>Hoá đơn</Text>
            </View>
            <View>
              <View style={styles.head_table}>
                <Text style={{ width: "30%" }}>Tên dịch vụ</Text>
                <Text style={{ width: "12%" }}>Kết quả</Text>
                <Text style={{ width: "20%", textAlign: "center" }}>Lý do</Text>
                <Text style={{ width: "13%", textAlign: "center" }}>
                  Tổng phí
                </Text>
                <Text style={{ width: "12%", textAlign: "center" }}>
                  BH khác
                </Text>
                <Text style={{ width: "13%", textAlign: "center" }}>
                  Được duyệt
                </Text>
              </View>
              {rows_bills?.map(
                (item, index) => (
                  <View
                    style={{
                      ...styles.row_body_table,
                      marginTop: index === 27 ? "18px" : "",
                    }}
                    key={index}
                    break={index === 27}
                  >
                    <Text style={{ width: "30%" }}>
                      {item?.serviceName || ""}
                    </Text>
                    <Text style={{ width: "12%" }}>
                      {item?.approveStatus === 1 ? "Chấp nhận" : "Từ chối"}
                    </Text>
                    <Text
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#3961A0",
                      }}
                    >
                      {item?.note}
                    </Text>
                    <Text style={{ width: "13%", textAlign: "center" }}>
                      {`${item?.fee || ""}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}
                    </Text>
                    <Text style={{ width: "12%", textAlign: "center" }}>
                      {`${item?.paidFee || ""}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}
                    </Text>
                    <Text
                      style={{
                        width: "13%",
                        textAlign: "center",
                        color: "#3961A0",
                      }}
                    >
                      {`${item?.approveFee || ""}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}
                    </Text>
                  </View>
                )
                // )
              )}
              <View style={styles.row_footer}>
                <View style={styles.row_footer_tt_table}>
                  <Text>Tổng phí được duyệt</Text>
                </View>
                <View style={styles.row_footer_vl_table}>
                  <Text>
                    {" "}
                    {!!fee_approve_bills
                      ? `${fee_approve_bills}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " VNĐ"
                      : 0}
                  </Text>
                </View>
              </View>
            </View>
            <View
              break={
                (rows_examination?.length > 18 &&
                  rows_examination?.length < 28) ||
                rows_examination?.length + rows_diagnosis?.length < 6
              }
            >
              <View
                style={{
                  ...styles.view4,
                  marginTop: "18px",
                }}
              >
                <Text>Toa thuốc</Text>
              </View>
              <View style={styles.head_table}>
                <Text style={{ width: "30%" }}>Tên thuốc</Text>
                <Text style={{ width: "12%" }}>Kết quả</Text>
                <Text style={{ width: "20%", textAlign: "center" }}>Lý do</Text>
                <Text style={{ width: "13%", textAlign: "center" }}>
                  Tổng phí
                </Text>
                <Text style={{ width: "12%", textAlign: "center" }}>
                  BH khác
                </Text>
                <Text style={{ width: "13%", textAlign: "center" }}>
                  Được duyệt
                </Text>
              </View>
              {rows_medicines.map(
                (item, index) => (
                  <View
                    style={styles.row_body_table}
                    key={index}
                    break={index === 27}
                  >
                    <Text style={{ width: "33%" }}>{` ${
                      item?.medicineCode || ""
                    } ${item?.medicineName || ""}`}</Text>
                    <Text style={{ width: "12%" }}>
                      {item?.approveStatus === 1 ? "Chấp nhận" : "Từ chối"}
                    </Text>
                    <Text
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#3961A0",
                      }}
                    >
                      {item?.note}
                    </Text>
                    <Text style={{ width: "13%", textAlign: "center" }}>
                      {`${item?.fee || ""}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}
                    </Text>
                    <Text style={{ width: "12%", textAlign: "center" }}>
                      {`${item?.paidFee || ""}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}
                    </Text>
                    <Text
                      style={{
                        width: "13%",
                        textAlign: "center",
                        color: "#3961A0",
                      }}
                    >
                      {`${item?.approveFee || ""}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        "."
                      )}
                    </Text>
                  </View>
                )
                // )
              )}
              <View style={styles.row_footer}>
                <View style={styles.row_footer_tt_table}>
                  <Text>Tổng phí được duyệt</Text>
                </View>
                <View style={styles.row_footer_vl_table}>
                  <Text>
                    {fee_approve_medicines
                      ? `${fee_approve_medicines}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " VNĐ"
                      : 0}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ ...styles.view4, marginTop: "18px" }}>
              <Text>Quyền lợi khác</Text>
            </View>
            <View>
              <View style={styles.head_table}>
                <Text style={{ width: "60%" }}>Quyền lợi</Text>
                <Text style={{ width: "20%", textAlign: "center" }}>
                  Tổng phí
                </Text>
                <Text style={{ width: "20%", textAlign: "center" }}>
                  Được duyệt
                </Text>
              </View>
              {rows_others?.map((item) => (
                <View
                  style={styles.row_body_examination_imp}
                  key={item?.diseaseCode}
                >
                  <Text style={{ width: "60%" }}>{item?.titleBenefit}</Text>
                  <Text style={{ width: "20%", textAlign: "center" }}>
                    {`${item?.fee || ""}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </Text>
                  <Text
                    style={{
                      width: "20%",
                      textAlign: "center",
                      color: "#3961A0",
                    }}
                  >
                    {`${item?.approveFee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    )}
                  </Text>
                </View>
              ))}
              <View style={styles.row_footer}>
                <View style={styles.row_footer_tt_table}>
                  <Text>Tổng phí được duyệt</Text>
                </View>
                <View style={styles.row_footer_vl_table}>
                  <Text>
                    {fee_approve_others
                      ? `${fee_approve_others}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " VNĐ"
                      : 0}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.border} />
          </>
        )}

        <View style={styles.view5}>
          <View style={styles.view5_1}>
            <Text
              style={{ color: "#3961A0", fontSize: "13px", fontWeight: "bold" }}
            >
              Ghi chú
            </Text>
            <Text style={{ color: "#4B4B4B", marginTop: "6px" }}>
              {noteUseLV1?.note || ""}
            </Text>
          </View>
          <View style={styles.view5_2}>
            <View style={{ ...styles.view5_2t1, ...styles.style_row }}>
              <Text>Tổng chi phí trong phạm vi bảo hiểm</Text>
              <Text style={{ fontSize: "12px" }}>
                {`${detail?.extraInfo?.approveAmount || 0}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  "."
                ) + " VNĐ"}
              </Text>
            </View>
            <View style={{ ...styles.view5_2t2, ...styles.style_row }}>
              <Text>Tỉ lệ % bồi thường</Text>
              <Text>{(detail?.extraInfo?.approvedPercent || 0) + " %"}</Text>
            </View>
            <View style={{ ...styles.view5_2t3, ...styles.style_row }}>
              <Text>Tổng chi phí</Text>
              <Text style={{ fontSize: "13px" }}>
                {`${detail?.extraInfo?.amount || 0}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  "."
                ) + " VNĐ"}
              </Text>
            </View>
          </View>
        </View>

        {listImgBase64PDFfile?.length > 0 &&
          listImgBase64PDFfile?.map((img, index) => (
            <View
              key={`img_base64_pdf_${index}`}
              style={{
                padding: "28px 16px",
                width: "100%",
                margin: "auto",
                maxHeight: "95%",
              }}
            >
              <Image
                src={img || ""}
                style={{
                  width: "98%",
                  maxHeight: "93%",
                }}
              />
            </View>
          ))}
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "times-new-roman",
    fontSize: "11px",
  },

  section_report: {
    margin: 30,
    lineHeight: 1.4,
  },

  section_row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  section_list: {
    marginLeft: 15,
  },

  section_list_item: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "50%",
    margin: "0 20px",
  },

  break: {
    marginTop: 8,
  },

  table_cell: {
    width: "25%",
    padding: "8px",
    color: "black",
    border: "0.5px solid #686868",
  },

  stamp: { width: "15%" },

  sign: {
    width: "40%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "10%",
  },

  img_check: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "22%",
    left: "13%",
    opacity: "0.7",
  },

  flex_row: {
    display: "flex",
    flexDirection: "row",
  },

  view1: {
    padding: "16px",
    color: "white",
    backgroundColor: "#3961A0",
    fontSize: "14px",
    textTransform: "uppercase",
  },

  view2: {
    padding: "12px 16px",
    fontSize: "12px",
    color: "#3961A0",
    fontWeight: "bold",
  },

  view3: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingLeft: "16px",
  },

  textV3_tt: {
    width: "40%",
    color: "#A0A0A0",
  },

  textV3_vl: {
    width: "59%",
    color: "#686868",
    fontWeight: "bold",
  },

  border: {
    margin: "12px 16px",
    borderTop: "1px solid #D6DEEB",
  },

  view4: {
    color: "#4B4B4B",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "12px 16px",
  },

  head_table: {
    backgroundColor: "#E3F3FF",
    padding: "6px",
    margin: "0 16px",
    display: "flex",
    flexDirection: "row",
    color: "#0B3C8A",
    borderBottom: "1px solid white",
  },

  row_body_table: {
    backgroundColor: "#F8F8F8",
    padding: "6px",
    margin: "0 16px",
    display: "flex",
    flexDirection: "row",
    color: "#4B4B4B",
    borderBottom: "1px solid white",
  },

  row_footer: {
    display: "flex",
    flexDirection: "row",
    margin: "0 16px",
  },

  row_body_examination_imp: {
    backgroundColor: "#DCDCDC",
    padding: "6px",
    margin: "0 16px",
    display: "flex",
    flexDirection: "row",
    color: "#1A1A1A",
    borderBottom: "1px solid white",
    fontWeight: "bold",
  },

  row_footer_tt_table: {
    width: "60%",
    backgroundColor: "#ECECEC",
    padding: "8px 6px",
    color: "#3961A0",
    fontWeight: "bold",
  },
  row_footer_vl_table: {
    width: "40%",
    backgroundColor: "#3961A0",
    padding: "8px 6px",
    color: "white",
    fontWeight: "bold",
    textAlign: "right",
  },

  view5: {
    margin: "12px 16px",
    marginTop: "4px",
    paddingBottom: "12px",
    borderBottom: "1px solid #3961A0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  view5_1: {
    width: "35%",
    textAlign: "left",
  },

  view5_2: {
    width: "60%",
  },

  view5_2t1: {
    width: "100%",
    justifyContent: "space-between",
    fontWeight: "bold",
    backgroundColor: "#4B4B4B",
    padding: "6px",
    alignItems: "center",
    color: "white",
  },

  view5_2t2: {
    width: "100%",
    justifyContent: "space-between",
    fontWeight: "bold",
    padding: "12px 0",
    alignItems: "center",
  },

  view5_2t3: {
    width: "100%",
    justifyContent: "space-between",
    fontWeight: "bold",
    paddingTop: "6px",
    alignItems: "center",
    color: "#3961A0",
  },

  style_row: {
    display: "flex",
    flexDirection: "row",
  },

  //
});
