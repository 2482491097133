import api from "../configs/api";

export const getListPackeInsurance = (data) =>
  api
    .apiForm("POST", "/api/v2/cert/list", data)
    .then((res) => res)
    .catch((error) => error);

export const getListPackeInsuranceWithPartner = ({ phone, partner, data }) =>
  api
    .apiForm(
      "POST",
      `/api/v2/cert/partner/general/list?phone=${phone}&partner=${partner}`,
      data
    )
    .then((res) => res)
    .catch((error) => error);

export const getListConfig = () =>
  api
    .apiForm("GET", "/api/config/health/insurance", {})
    .then((res) => res)
    .catch((error) => error);

export const getListBank = () =>
  api
    .apiForm("POST", "/api/v2/bank/list", {
      limit: 1000,
    })
    .then((res) => res)
    .catch((error) => error);

export const uploadImg = (data) =>
  api
    .apiForm("POST", "/api/v2/document/upload/cdn", data)
    .then((res) => res)
    .catch((error) => error);

export const uploadImgWithPartner = ({ phone, partner, data }) =>
  api
    .apiForm(
      "POST",
      `/api/v2/document/partner/general/upload/cdn?phone=${phone}&partner=${partner}`,
      data
    )
    .then((res) => res)
    .catch((error) => error);

export const uploadImg_base64 = (data) =>
  api
    .apiForm("POST", "/api/v2/document/upload/cdn/image-base64", data)
    .then((res) => res)
    .catch((error) => error);

export const uploadImg_base64_with_artner = ({ phone, partner, data }) =>
  api
    .apiForm(
      "POST",
      `/api/v2/document/partner/general/upload/cdn/image-base64?phone=${phone}&partner=${partner}`,
      data
    )
    .then((res) => res)
    .catch((error) => error);

export const createClaimHealth = (data) =>
  api
    .apiForm("POST", "/api/v2/claim/client", data)
    .then((res) => res)
    .catch((error) => error?.response);

export const createClaimHealthWithPartner = ({ phone, partner, data }) =>
  api
    .apiForm(
      "POST",
      `/api/v2/claim/partner/general?phone=${phone}&partner=${partner}`,
      data
    )
    .then((res) => res)
    .catch((error) => {
      return error?.response;
    });

export const getListDetails = (data) =>
  api
    .apiForm("POST", "/api/v2/claim/client/list", data)
    .then((res) => res)
    .catch((error) => error);

export const getDetails = (id) =>
  api
    .apiForm("GET", `/api/v2/claim/client/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

export const updateLicese = (data) =>
  api
    .apiForm("PUT", `/api/v2/claim/client`, data)
    .then((res) => res)
    .catch((error) => error);

// Bồi Thường Viên

export const getListClaim = (systemType, data) =>
  api
    .apiForm("POST", `/api/v2/claim/${systemType}/list`, data)
    .then((res) => res)
    .catch((error) => error);

export const getDetailClaim = (systemType, id) =>
  api
    .apiForm("GET", `/api/v2/claim/${systemType}/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

export const getPackageBenefitList = (data) =>
  api
    .apiForm("POST", `/api/v2/product-package-benefit/claimed/list`, data)
    .then((res) => res)
    .catch((error) => error);
// export const getPackageBenefitList = (data) => thay dối url API ngày 16/01/2024
//   api
//     .apiForm("POST", `/api/v2/product-package-benefit/list`, data)
//     .then((res) => res)
//     .catch((error) => error);

export const additionalRequest = (systemType, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}/add-elements/amend`, data)
    .then((res) => res)
    .catch((error) => error);

export const getListBenefits = (data) =>
  api
    .apiForm("POST", `/api/v2/benefit/list`, data)
    .then((res) => res)
    .catch((error) => error);

export const checkLicenseClaim = (systemType, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}/check`, data)
    .then((res) => res)
    .catch((error) => error);

export const fileLicenseClaim = (systemType, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}/file`, data)
    .then((res) => res)
    .catch((error) => error);

export const requestApprove = (systemType, data) =>
  api
    .apiForm(
      "PUT",
      `/api/v2/claim/${systemType}/add-elements/request-approve`,
      data
    )
    .then((res) => res)
    .catch((error) => error);

export const getHistoryClaim = (systemType, data) =>
  api
    .apiForm("POST", `/api/v2/claim/${systemType}/history/list`, data)
    .then((res) => res)
    .catch((error) => error);

export const complete = (systemType, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}/add-elements/complete`, data)
    .then((res) => res)
    .catch((error) => error);

export const reject = (systemType, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}/add-elements/reject`, data)
    .then((res) => res)
    .catch((error) => error);

export const approve = (systemType, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}/add-elements/approve`, data)
    .then((res) => res)
    .catch((error) => error);

export const getListDisease = (data) =>
  api
    .apiForm("POST", `/api/v2/disease/list`, data)
    .then((res) => res)
    .catch((error) => error);

export const getListMedicine = (data) =>
  api
    .apiForm("POST", `/api/v2/medicine/list`, data)
    .then((res) => res)
    .catch((error) => error);

export const addNote = (systemType, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}/add-elements/note`, data)
    .then((res) => res)
    .catch((error) => error);

export const getDetailInsurance = (systemType, claimId) =>
  api
    .apiForm("GET", `/api/v2/claim/${systemType}/${claimId}/insurance/detail`)
    .then((res) => res)
    .catch((error) => error);

export const getListReviewer = (userType, data) =>
  api
    .apiForm("POST", `/api/v2/claim/${userType}/reviewer/list`, data)
    .then((res) => res)
    .catch((error) => error);

export const updateReviewer = (userType, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${userType}/assign/user`, data)
    .then((res) => res)
    .catch((error) => error);

export const OCR = (systemType, data) =>
  api
    .apiForm("POST", `/api/v2/ocr/claim-by-id`, data)
    .then((res) => res)
    .catch((error) => error);

export const OCR_cccd = (data) =>
  api
    .apiForm("POST", `/api/v2/ocr/document/cccd`, data)
    .then((res) => res)
    .catch((error) => error);

export const mappingBenefit = (data) =>
  api
    .apiForm("POST", `/api/v2/mapping-benefit/suggestion`, data)
    .then((res) => res)
    .catch((error) => error);

export const getListDiseaseOCR = (data) =>
  api
    .apiForm("POST", `/api/v2/disease/list`, data)
    .then((res) => res)
    .catch((error) => error);

export const getFileByUrl = (url) =>
  api
    .apiGetFileByUrl(url)
    .then((res) => res.data)
    .catch((error) => error);

export const invalidPDF = (systemType, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}/add-elements/trash`, data)
    .then((res) => res)
    .catch((error) => error);

export const reconfirm = (systemType, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}/add-elements/reconfirm`, data)
    .then((res) => res)
    .catch((error) => error);

export const deletePDF = (data) =>
  api
    .apiForm("DELETE", `/api/v2/claim-element`, data)
    .then((res) => res)
    .catch((error) => error);

export const cancelPDF = (systemType, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}/add-elements/cancel`, data)
    .then((res) => res)
    .catch((error) => error);

export const deleteS3Document = (data) =>
  api
    .apiForm("POST", `/api/v2/todo`, data)
    .then((res) => res)
    .catch((error) => error);

//dashboard
export const getDashboard = (systemType, data) =>
  api
    .apiForm("POST", `/api/v2/dashboard/${systemType}/overview`, data)
    .then((res) => res)
    .catch((error) => error);

export const getListDashboard = (systemType, data) =>
  api
    .apiForm("POST", `/api/v2/dashboard/${systemType}/overview/list`, data)
    .then((res) => res)
    .catch((error) => error);

export const getCompany = () =>
  api
    .apiForm("POST", `/api/v2/provider/list`, { limit: "100", offset: 0 })
    .then((res) => res)
    .catch((error) => error);

export const updateDetail = (systemType, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}`, data)
    .then((res) => res)
    .catch((error) => error);

// detail

export const acceptByClient = (params) =>
  api
    .apiForm(
      "PUT",
      `/api/v2/claim/client/add-elements/accept-by-client`,
      params
    )
    .then((res) => res)
    .catch((error) => ({ ...error.response }));

export const rejectByClient = (params) =>
  api
    .apiForm(
      "PUT",
      `/api/v2/claim/client/add-elements/reject-by-client`,
      params
    )
    .then((res) => res)
    .catch((error) => ({ ...error.response }));
