import * as React from "react";
import { Snackbar, Slide, Stack, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";

//type
// success, info, warning, error

function Transition(props) {
  return <Slide {...props} direction="down" />;
}

const useMakeStyle = makeStyles(() => ({
  borderRadius: { "& .MuiPaper-root": { borderRadius: "16px" } },
}));

export default function Snackbars(props) {
  const {
    anchorOrigin = { vertical: "top", horizontal: "center" },
    open,
    message = "",
    type = "success",
    autoHideDuration = 2500,
    onClose,
  } = props;

  const classes = useMakeStyle();

  return (
    <div>
      <Snackbar
        open={open}
        anchorOrigin={anchorOrigin}
        onClose={onClose}
        autoHideDuration={autoHideDuration}
        TransitionComponent={Transition}
        key={message}
      >
        <Stack
          sx={{ width: "100%" }}
          spacing={2}
          className={classes.borderRadius}
        >
          <Alert
            severity={type}
            sx={{
              width: "100%",
              background: type === "success" ? "#2ead34" : "",
              color: "white",
            }}
            variant="filled"
          >
            {message}
          </Alert>
        </Stack>
      </Snackbar>
    </div>
  );
}
