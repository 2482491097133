import _ from "lodash";
import {
  getInforOrder,
  getListBank,
  uploadImgAhamove,
  create,
  getHistoryOrder,
  uploadUrlImg,
  uploadImg_base64,
} from "../../../../../apis/claimFinaApi";
import moment from "moment";
import { deleteS3Document } from "../../../../../apis/claimApis";

const valiDateTime = (inforOrder, extraInfo, destination) => {
  const finishDay = moment(
    inforOrder?.endDate ? inforOrder?.endDate : destination?.data?.createdDate
  )
    .set({
      second: 0,
      millisecond: 0,
    })
    .toDate();
  const riskTime = extraInfo?.riskTime?.split(":").map((i) => i);
  const riskDate = moment(extraInfo?.riskDate)
    .set({
      hour: riskTime[0] || 0,
      minute: riskTime[1] || 0,
      second: 0,
      millisecond: 0,
    })
    .toDate();

  if (
    moment(destination?.data?.createdDate).toDate() > riskDate ||
    moment(finishDay).toDate() < riskDate
  ) {
    return "validateDateTime";
  }
};

const valiDatePage = (state) => {
  const {
    currentStep,
    extraInfo,
    destination,
    elements,
    bank,
    chekedCommit,
    inforOrder,
  } = state;
  const errors = [];

  const step3 = () => {
    !extraInfo.riskDate && errors.push("riskDate");
    !extraInfo.riskTime && errors.push("riskTime");
    !extraInfo.describe && errors.push("describe");
    !extraInfo.productAmount &&
      destination?.data?.paymentInfo?.paymentId === 1 &&
      +extraInfo.productAmount !== 0 &&
      errors.push("productAmount");
    !extraInfo.insuranceObj && errors.push("insuranceObj");
    !extraInfo.typeOfAccident && errors.push("typeOfAccident");

    if (extraInfo.riskTime && extraInfo.riskDate) {
      const validateDateTime = valiDateTime(inforOrder, extraInfo, destination);
      validateDateTime === "validateDateTime" &&
        errors.push("validateDateTime");
    }

    +extraInfo?.productAmount > +destination?.data?.codValue &&
      destination?.data?.paymentInfo?.paymentId === 1 &&
      errors.push("codValue");

    return errors;
  };

  const step4 = () => {
    let check = 0;

    if (+destination?.data?.paymentInfo?.paymentId === 1) {
      check = +extraInfo?.typeOfAccident === 2 ? 2 : 3;
    } else {
      check =
        +extraInfo?.typeOfAccident === 2 &&
        [3, 4].includes(+extraInfo?.insuranceObj)
          ? 1
          : +extraInfo?.typeOfAccident === 2 ||
            [3, 4].includes(+extraInfo?.insuranceObj)
          ? 2
          : 3;
    }

    if (elements.length >= check) {
      elements.forEach((i) => {
        i?.documents?.length === 0 && errors.push("notEnoughImg");
      });
    } else errors.push("notEnoughImg");
    return errors;
  };

  const step5 = () => {
    const filterEmail =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    const signature = elements.find((i) => i.typeId === 204) || {};

    !extraInfo.beneficiaryName && errors.push("beneficiaryName");
    !extraInfo.beneficiaryBankAcountNumber &&
      errors.push("beneficiaryBankAcountNumber");
    _.isEmpty(bank) && errors.push("bank");
    !extraInfo.beneficiaryBankAcountNumber &&
      errors.push("beneficiaryBankAcountNumber");
    !extraInfo.beneficiaryEmail && errors.push("beneficiaryEmail");
    extraInfo.beneficiaryPhone &&
      extraInfo.beneficiaryPhone.length < 10 &&
      errors.push("phoneType");
    extraInfo.beneficiaryEmail &&
      !filterEmail.test(extraInfo.beneficiaryEmail) &&
      errors.push("emailType");

    !chekedCommit && errors.push("chekedCommit");
    _.isEmpty(signature) && errors.push("signature");

    return errors;
  };

  switch (currentStep) {
    case 3:
      return step3();
    case 4:
      return step4();
    case 5:
      return step5();
  }
};

export default function finaRrducer({
  state,
  dispatch,
  action,
  codeOrder,
  gc_order_code,
  phone,
  partner,
  History,
}) {
  const cases = {
    initial: async () => {
      const [dataApi, dataBanks] = await Promise.all([
        getInforOrder({
          params: {
            code: codeOrder,
            limit: 1,
            offset: 0,
            gcOrderCode: gc_order_code || null,
          },
          codeOrder,
          gc_order_code,
          phone,
          partner,
        }),
        getListBank(),
      ]);

      const inforOrder = _.get(dataApi, "data.result") || {};
      const banks = _.get(dataBanks, "data.result.data") || [];

      const res = {};

      return { loading: false, inforOrder, banks, ...res };
    },

    initHistory: async () => {
      const { inforOrder } = state;
      const buyerPhone =
        _.get(inforOrder, "destination[0].sender.phoneNumber") || null;

      dispatch("onLoading");
      const { tabsStatus } = state;

      const dataApi = await getHistoryOrder({
        params: {
          statusId: tabsStatus || 2,
          limit: 10,
          offset: 0,
          buyerPhone,
          certNum: inforOrder?.certNum,
        },
        codeOrder,
        gc_order_code,
        phone,
        partner,
      });
      const res = {};

      if (dataApi?.data?.statusCode === 200) {
        res.historyDestination = {};
        res.historyOrder = _.get(dataApi, "data.result") || {};
        res.page = {
          total: Math.ceil((_.get(dataApi, "data.result.total") || 0) / 10),
          offset: 0,
        };
      }

      return { ...res, loading: false };
    },

    initialStep4: async () => {
      const {
        elements,
        destination: { data },
      } = state;

      const index = (elements || []).findIndex((i) => i.typeId === 200);

      if (data?.imagesBefore.length > 0) {
        dispatch("onLoading");

        const params = data?.imagesBefore?.map((i) => ({ document: i }));

        const dataApi = await uploadUrlImg({
          params,
          codeOrder,
          gc_order_code,
          phone,
          partner,
        });

        if (dataApi?.data?.statusCode === 200) {
          const dataImg = _.get(dataApi, "data.result") || [];

          if (index === -1) {
            elements.push({
              typeId: 200,
              documents: dataImg?.map((i) => ({
                path: i?.path,
                size: i?.size || null,
              })),
              text: null,
              value: null,
            });
          } else {
            const items = elements[index];
            const imgUpload = items?.documents?.filter((i) => !!i.size);
            const listDataImgApi = dataImg?.map((i) => ({
              path: i?.path,
              size: i?.size || null,
            }));

            elements[index] = {
              typeId: 200,
              documents: [...listDataImgApi, ...imgUpload],
              text: null,
              value: null,
            };
          }
        }
      }

      const err = valiDatePage({ ...state, elements });

      const disabledBottomButton = err.length > 0;

      return { elements, disabledBottomButton, loading: false };
    },

    getItems: async () => {
      const { tabsStatus, page, historyOrder, inforOrder } = state;
      const buyerPhone =
        _.get(inforOrder, "destination[0].sender.phoneNumber") || null;

      if (page.total === page.offset || page.total === 1) {
        return;
      }
      dispatch("onLoading");

      const dataApi = await getHistoryOrder({
        params: {
          statusId: tabsStatus || 5,
          limit: 10,
          offset: page?.offset + 1,
          buyerPhone,
          certNum: inforOrder?.certNum,
        },
        codeOrder,
        gc_order_code,
        phone,
        partner,
      });
      const res = {};

      if (dataApi?.data?.statusCode === 200) {
        // res.historyDestination = {};
        res.historyOrder = {
          total: historyOrder.total,
          data: [
            ...(historyOrder.data || []),
            ...(_.get(dataApi, "data.result.data") || []),
          ],
        };
        res.page = {
          total: Math.ceil((_.get(dataApi, "data.result.total") || 0) / 10),
          offset: page?.offset + 1,
        };
      }

      return { ...res, loading: false };
    },

    changePageNext: () => {
      const { page = null } = action;
      const { currentStep, historyDestination, destination, inforOrder } =
        state;
      const res = {};

      res.currentStep = page ? page : currentStep + 1;
      res.disabledBottomButton = res?.currentStep !== 2;

      if (currentStep === 1) {
        // const expiry =
        //   destination?.data?.paymentInfo?.paymentId === 1 ? 10081 : 1441;
        const expiry = 4321;
        const now = moment(new Date());
        const date = moment(
          inforOrder?.endDate || destination?.data?.createdDate
        );
        // const date = moment(
        //   destination?.data?.paymentInfo?.paymentId === 1
        //     ? inforOrder?.endDate
        //     : destination?.data?.createdDate
        // );
        const durationMinutes = Math.ceil(
          moment.duration(now.diff(date)).asMinutes()
        );

        res.currentStep =
          durationMinutes >= expiry ? "notify" : res.currentStep;
        res.statusNotify = durationMinutes >= expiry ? "warring" : "null";
        res.disabledBottomButton =
          durationMinutes >= expiry ? false : res.disabledBottomButton;
      }

      if (currentStep === 5) {
        dispatch("createInsu");
      }

      if (currentStep === "history") {
        res.disabledBottomButton = false;
      }

      if ([2, 3].includes(currentStep)) {
        const err = valiDatePage({ ...state, currentStep: res.currentStep });
        res.disabledBottomButton = (err || []).length > 0;
      }

      return res;
    },

    changePagePress: () => {
      const { currentStep, statusNotify, destination } = state;
      const { page } = action;
      const res = {};

      if (currentStep === 1) {
        History.goBack();
        return;
      }

      const namePage = () => {
        switch (currentStep) {
          case "history":
          case "notify":
            return 1;
          case "detailHistory":
            return "history";
          default:
            return null;
        }
      };

      res.currentStep = namePage()
        ? namePage()
        : currentStep === 1
        ? 1
        : currentStep - 1;

      res.disabledBottomButton =
        currentStep === "detailHistory" ||
        (currentStep === "history" && _.isEmpty(destination));

      return { ...res };
    },

    changePageHistory: () => ({
      currentStep: "history",
      disabledBottomButton: true,
      historyDestination: {},
    }),

    onClickDestination: () => {
      const {
        data: { items, index },
        name,
      } = action;
      const { destination, extraInfo, historyDestination, inforOrder, errors } =
        state;

      const res = {};
      if (name === "destination") {
        res.destination =
          destination?.index === index ? {} : { data: items, index };
        const showDate =
          new Date().getTime() < new Date(inforOrder?.endDate).getTime();

        extraInfo.productAmount = res?.destination?.data?.codValue || null;
        extraInfo.riskDate = showDate
          ? moment()
          : moment(inforOrder?.endDate).toDate() || null;
        extraInfo.riskTime = showDate
          ? moment().format("HH:mm")
          : moment(inforOrder?.endDate).format("HH:mm");

        errors.validateDateTime =
          valiDateTime(inforOrder, extraInfo, res.destination) ===
          "validateDateTime";

        res.disabledBottomButton = _.isEmpty(res.destination);
      } else {
        res.historyDestination =
          historyDestination?.index === index ? {} : { data: items, index };
        res.disabledBottomButton = _.isEmpty(res.historyDestination);
      }

      return { ...res, extraInfo, errors };
    },

    setOpenPicker: () => {
      const { name } = action;
      const { picker } = state;

      return { picker: { ...picker, [name]: !picker[name] } };
    },

    confirmPicke: () => {
      const { name, value } = action;
      const { extraInfo, errors } = state;
      extraInfo[name] = value;
      const res = {};

      if (name === "riskTime") {
        const newValue = `${value[0]}` + ":" + `${value[1]}`;
        extraInfo[name] = newValue;
      }

      if (
        name === "riskDate" &&
        moment(value).format("DD") === moment().format("DD")
      ) {
        const newTime = moment().format("HH:mm");
        extraInfo.riskTime = newTime;
      }

      if (extraInfo.riskTime && extraInfo.riskDate) {
        errors.validateDateTime = (
          valiDatePage({ ...state, extraInfo }) || []
        ).includes("validateDateTime");
      }

      const err = valiDatePage({ ...state, extraInfo });
      const disabledBottomButton = err.length > 0;

      return { extraInfo, errors, disabledBottomButton };
    },

    onChangeExtraInfo: () => {
      const { name, value, key, destination } = action;
      const { extraInfo, errors } = state;
      const res = {};

      if (
        (name === "beneficiaryPhone" &&
          !"1234567890".includes(key) &&
          key !== null) ||
        (name === "beneficiaryPhone" && value.length > 10)
      ) {
        return;
      }

      if (name === "bank") {
        res.bank = value;
        extraInfo.beneficiaryBankId = +value?.id || null;
        extraInfo.beneficiaryBank = value?.short_name || null;
      } else {
        extraInfo[name] = value;
      }

      if (name === "productAmount") {
        extraInfo[name] = value.replace(/\./g, "").replace(/^0+/, "");
        errors.codValue = (
          valiDatePage({ ...state, extraInfo }) || []
        ).includes("codValue");
      }

      if (name === "describe") {
        errors.describe = !extraInfo.describe;
      }

      const err = valiDatePage({ ...state, extraInfo });
      const disabledBottomButton = err.length > 0;
      errors.emailType = err.includes("emailType")
        ? "Email chưa hợp lệ"
        : false;
      errors.phoneType = err.includes("phoneType")
        ? "Số điện thoại chưa hợp lệ"
        : false;

      return { extraInfo, ...res, errors, disabledBottomButton };
    },

    checkError: () => {
      const { errors } = state;
      errors.describe = (valiDatePage(state) || []).includes("describe");

      return { errors };
    },

    addImg: async () => {
      const { e, id } = action;
      const { elements } = state;
      const index = (elements || []).findIndex((i) => i.typeId === id);
      const file = e.target.files[0];
      const data = new FormData();

      if (!file) {
        return;
      }

      data.append("document", file);

      dispatch("onLoading");
      const api = await uploadImgAhamove({
        params: data,
        codeOrder,
        gc_order_code,
        phone,
        partner,
      });

      const res = {};
      if (api?.data?.statusCode === 200) {
        const dataApi = _.get(api, "data.result[0]") || {};

        if (index === -1) {
          elements.push({
            typeId: id,
            documents: [{ path: dataApi?.path, size: dataApi?.size }],
            text: null,
            value: null,
          });
        } else {
          const items = elements[index];
          elements[index] = {
            ...items,
            documents: [
              ...items.documents,
              {
                path: dataApi?.path,
                size: dataApi?.size,
              },
            ],
          };
        }

        const err = valiDatePage({ ...state, elements });

        res.disabledBottomButton = err.length > 0;
      }

      return { loading: false, elements, ...res };
    },

    handlSignature: async () => {
      const { ref } = action;
      const { elements, errors } = state;
      const index = elements?.findIndex((i) => i.typeId === 204);
      const url = ref.current.getTrimmedCanvas().toDataURL("image/png");

      dispatch("onLoadingSignature");

      const dataApi = await uploadImg_base64({
        params: [
          {
            document: url,
          },
        ],
        codeOrder,
        gc_order_code,
        phone,
        partner,
      });

      if (dataApi instanceof Error) {
        dispatch({ type: "clearSignature", ref: ref });
        return { loadingSignature: false };
      }
      const res = {};
      if (dataApi?.data?.statusCode === 200) {
        const url = dataApi?.data?.result[0]?.path;

        if (index === -1) {
          elements.push({
            documents: [
              {
                path: url || "",
                size: null,
              },
            ],
            text: null,
            typeId: 204,
            value: null,
          });
        } else {
          elements[index] = {
            ...elements[index],
            documents: [
              {
                path: url || "",
                size: null,
              },
            ],
          };
        }

        const err = valiDatePage({ ...state, elements });
        errors.signature = err.includes("signature");
        res.disabledBottomButton = (err || []).length > 0;
      }

      return { loadingSignature: false, elements, errors, ...res };
    },

    clearSignature: () => {
      const { ref } = action;
      const { elements, errors } = state;
      ref?.current?.clear();
      const index = elements?.findIndex((i) => i.typeId === 204);
      const url = _.get(elements, `[${index}].documents[0].path`) || null;
      if (url) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: url },
        });
      }

      if (index > -1) {
        elements.splice(index, 1);
      }

      const err = valiDatePage({ ...state, elements });
      errors.signature = err.includes("signature");
      const disabledBottomButton = (err || []).length > 0;

      return { loadingSignature: false, elements, disabledBottomButton };
    },

    addImgUpdate: async () => {
      const { e, id } = action;
      const { elementsHistory } = state;
      const index = (elementsHistory || []).findIndex((i) => i.typeId === id);
      const file = e.target.files[0];
      const data = new FormData();
      data.append("document", file);

      dispatch("onLoading");
      const api = await uploadImgAhamove({
        params: data,
        codeOrder,
        gc_order_code,
        phone,
        partner,
      });

      const res = {};
      if (api?.data?.statusCode === 200) {
        const dataApi = _.get(api, "data.result[0]") || {};

        if (index === -1) {
          elementsHistory.push({
            typeId: id,
            documents: [{ path: dataApi?.path, size: dataApi?.size }],
            text: null,
            value: null,
          });
        } else {
          const items = elementsHistory[index];
          elementsHistory[index] = {
            ...items,
            documents: [
              ...items.documents,
              {
                path: dataApi?.path,
                size: dataApi?.size,
              },
            ],
          };
        }
      }

      return { loading: false, elementsHistory, ...res };
    },

    showImg: () => {
      const { data, url, indexImg, name = "", showCancle } = action;
      const { showImg } = state;
      const res = {};

      res.showImg = {
        ...showImg,
        open: true,
        lisUrl: data?.listImg?.documents || [],
        url,
        indexImg,
        id: data?.id,
        name: null,
        showCancle: true,
      };

      if (name === "history") {
        res.showImg = {
          ...showImg,
          open: true,
          lisUrl: data?.listImg?.documents || [],
          url,
          indexImg,
          id: data?.id,
          name: "history",
          showCancle,
        };
      }

      return res;
    },

    deleteImg: () => {
      const { id, index, name, items, dataMap, url } = action;
      const { elements, elementsHistory, showImg } = state;
      const res = {};

      if (url) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: url },
        });
      }

      if (name === "history") {
        const indexElementsHistory = elementsHistory.findIndex(
          (i) => i.typeId === dataMap.id
        );
        const indexDocuments = elementsHistory[
          indexElementsHistory
        ].documents.findIndex((e) => e.path === items.path);

        elementsHistory[indexElementsHistory].documents.splice(
          indexDocuments,
          1
        );
      } else {
        const indx = elements.findIndex((i) => i.typeId === id);
        elements[indx].documents.splice(index, 1);
        const err = valiDatePage({ ...state, elements });
        res.showImg = {
          ...showImg,
          indexImg: showImg.indexImg === 0 ? 0 : showImg.indexImg - 1,
          lisUrl: elements[indx].documents,
          url: elements[indx]?.documents[
            showImg.indexImg === 0 ? 0 : showImg.indexImg - 1
          ]?.path,
          open: false,
          // open: !(elements[indx].documents.length === 0),
        };
        res.disabledBottomButton = err.length > 0;
      }

      return { elements, ...res };
    },

    changeIndexImg: () => {
      const { showImg } = state;
      const res = {};
      const { name } = action;
      if (name === "next") {
        const index =
          showImg?.indexImg === showImg?.lisUrl?.length - 1
            ? 0
            : showImg?.indexImg + 1;

        res.showImg = {
          ...showImg,
          indexImg: index,
          url: showImg?.lisUrl[index]?.path,
        };
      } else {
        const index =
          showImg?.indexImg === 0
            ? showImg?.lisUrl.length - 1
            : showImg?.indexImg - 1;

        res.showImg = {
          ...showImg,
          indexImg: index,

          url: showImg?.lisUrl[index]?.path,
        };
      }

      return res;
    },

    closeShowImg: () => ({ showImg: { ...state.showImg, open: false } }),

    checkCommit: () => {
      const { value } = action;
      const err = valiDatePage({ ...state, chekedCommit: value });
      const disabledBottomButton = err.length > 0;

      return { chekedCommit: value, disabledBottomButton };
    },

    createInsu: async () => {
      const {
        elements,
        extraInfo,
        inforOrder,
        destination: { data, index },
      } = state;

      dispatch("onLoading");
      const params = {
        productIdParent: 24,
        productId: 36,
        providerId: 10,
        certNum: inforOrder?.certNum,
        insuredInfo: {
          ...inforOrder?.insuredInfo,
          certLink: inforOrder?.certLink,
          beginDate: inforOrder?.createdDate,
          endDate: inforOrder?.endDate,
        },
        extraInfo: {
          ...data,
          ...extraInfo,
          riskDate: extraInfo.riskDate.toISOString(),
          orderCode: inforOrder?.orderCode,
          partnerOrderCode: inforOrder?.partnerOrderCode,
        },
        elements: elements.map((i) => ({
          value: i.value || null,
          text: i.text || null,
          typeId: i.typeId || null,
          typeCode: i.typeCode || null,
          extraInfo: { documents: i.documents },
        })),
      };
      const dataApi = await create({
        params,
        codeOrder,
        gc_order_code,
        phone,
        partner,
      });
      let reset = {};
      const res = {};
      if (dataApi?.data?.statusCode === 200) {
        res.currentStep = "notify";
        res.statusNotify = "success";
        res.disabledBottomButton = false;
        res.buyerPhone =
          _.get(inforOrder, "destination[0].sender.phoneNumber") || null;
        reset = {
          elements: [],
          elementsHistory: [],
          bank: {},
          historyOrder: {},
          destination: {},
          picker: {},
          extraInfo: {},
          errors: {},
          chekedCommit: false,
          ...res,
          loading: false,
        };
      } else {
      }

      return reset;
    },

    onChangeTabs: async () => {
      const { value } = action;
      const { inforOrder } = state;
      const buyerPhone =
        _.get(inforOrder, "destination[0].sender.phoneNumber") || null;

      const dataApi = await getHistoryOrder({
        params: {
          statusId: value,
          limit: 10,
          offset: 0,
          buyerPhone,
          certNum: inforOrder?.certNum,
        },
        codeOrder,
        gc_order_code,
        phone,
        partner,
      });
      const res = {};

      if (dataApi?.data?.statusCode === 200) {
        res.disabledBottomButton = true;
        res.historyOrder = _.get(dataApi, "data.result") || {};
        res.historyDestination = {};
        res.page = {
          total: Math.ceil((_.get(dataApi, "data.result.total") || 0) / 10),
          offset: 0,
        };
      }

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      return { ...res, tabsStatus: value, loading: false };
    },

    countdown: () => ({
      countdown: state.countdown === 0 ? 175 : state.countdown - 1,
    }),

    onLoading: () => ({ loading: true }),

    onLoadingSignature: () => ({ loadingSignature: true }),

    openLink: () => {
      const { inforOrder } = state;

      if (inforOrder?.certLink) {
        return { openCertificate: true };
      } else {
        return { openDialogWait: true };
      }
    },

    closeCertificate: () => ({ openCertificate: false }),

    closeDialogWait: () => ({ openDialogWait: false }),

    //
  };

  return action?.type && cases[action?.type];
}
