import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { TableCell } from "@mui/material";
import { formatDate } from "../util";
import DefaultIcon from "../assets/logo_default.svg";
import CircleIcon from "@mui/icons-material/Circle";

export const columns = [
  {
    label: "STT",
    headerClass: "STT",
    renderCell: ({ rowIdx }) => (
      <TableCell className="STT">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "BH",
    headerClass: "BH",
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={``} key={colIdx}>
        <img
          src={item?.urlLogo || item?.product_url_logo || DefaultIcon}
          alt="logo"
          className="logo"
        />
      </TableCell>
    ),
  },
  {
    label: "Gói sản phẩm",
    // key: "product_package_title",
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {item?.product_package_title || item?.productPackageTitle}
      </TableCell>
    ),
  },
  {
    label: "Họ và tên ",
    // key: "insured_name",
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={``} key={colIdx}>
        {item?.insured_name || item?.insuredName}
      </TableCell>
    ),
  },
  {
    label: "Doanh nghiệp",
    // key: "merchant_title",
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={``} key={colIdx}>
        {item?.merchant_title || item?.merchantTitle}
      </TableCell>
    ),
  },
  {
    label: "Số thẻ BH",
    // key: "cert_num",
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={``} key={colIdx}>
        {item?.cert_num || item?.certNum}
      </TableCell>
    ),
  },
  {
    label: "CCCD/CMND",
    // key: "insured_private_id",
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={``} key={colIdx}>
        {item?.insured_private_id || item?.insuredPrivateId}
      </TableCell>
    ),
  },
  // {
  //   label: "Số điện thoại",
  //   // key: "insured_phone",
  //   renderCell: ({ rowIdx, colIdx, event, item }) => (
  //     <TableCell className={``} key={colIdx}>
  //       {item?.insured_phone || item?.insuredPhone}
  //     </TableCell>
  //   ),
  // },
  {
    label: "Công ty BH",
    // key: "provider_title",
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={``} key={colIdx}>
        {item?.provider_title || item?.providerTitle}
      </TableCell>
    ),
  },
  {
    label: "Ngày bắt đầu",
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={``} key={colIdx}>
        {formatDate(item?.beginDate || item?.begin_date)}
      </TableCell>
    ),
  },
  {
    label: "Ngày hết hạn",
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={``} key={colIdx}>
        {formatDate(item?.endDate || item?.end_date)}
      </TableCell>
    ),
  },
  {
    label: "TT",
    headerClass: "status sticky",
    renderCell: ({ item }) => (
      <TableCell className={`status sticky`}>
        {item.headChild ? (
          item.status
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircleIcon
              fontSize="small"
              style={{ width: "16px" }}
              sx={{ color: !!item.status ? "#069D27" : "#CFCFCF" }}
            />
          </div>
        )}
      </TableCell>
    ),
  },
  {
    label: "Xem",
    headerClass: "show-icon sticky",
    renderCell: ({ data, item, event }) => (
      <TableCell className="show-icon sticky">
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={event("openPopverEditTableGCN", { item })}
        >
          <MoreHorizIcon fontSize="small" />
        </div>
      </TableCell>
    ),
  },
];
