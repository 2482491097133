import axios from "axios";
import config from "../../../../../configs/config";

export async function getTotalDashboard() {
  const response = await axios
    .get(`${config.SERVER}/api/v1/tomato/dashboard/total`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .catch((e) => e);
  return response;
}

export async function getRevenueToday() {
  const response = await axios
    .get(`${config.SERVER}/api/v1/tomato/dashboard/revenue-in-day`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .catch((e) => e);
  return response;
}

export async function getTop5(orderBy = "asc") {
  const response = await axios
    .get(
      `${config.SERVER}/api/v1/tomato/dashboard/top-5-revenue?orderBy=${orderBy}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .catch((e) => e);
  return response;
}

export async function getDashboardClaim(merchant, start, end) {
  return await axios
    .get(`${config.SERVER}/api/v1/tomato/dashboard/claim`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        merchant,
        start,
        end,
      },
    })
    .catch((e) => e);
}
