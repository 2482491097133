import React from "react";
import { makeStyles } from "@mui/styles";
import { TableCell } from "@mui/material";
import NumberFormat from "react-number-format";

import CommonTable from "../../../../../../library/layout/commonTable";
import { TextField } from "../../overrideMui";

export default function Subsidize(props) {
  const { state, dispatch, level_id } = props;
  const classes = useStyle();
  const { rowsOtherBenefits, approveOthersTotal } = state;

  return (
    <div className={classes.container}>
      <CommonTable
        rows={rowsOtherBenefits}
        columns={columns}
        classes={classes}
        data={{ level_id }}
        event={(type, data) =>
          (ele, ...rest) => {
            dispatch({ type, data, ele, rest });
          }}
      />
      <div className={classes.totalFees}>
        <span className="conten">Tổng phí được duyệt</span>
        <span className="fees">
          {`${approveOthersTotal || "0"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
        </span>
      </div>
    </div>
  );
}

const columns = [
  {
    label: "Quyền lợi",
    headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">
        <div
          style={{
            width: "100%",
            fontSize: "14px",
            paddingLeft: "12px",
          }}
        >
          <span style={{ marginRight: "12px" }}>{item?.codeBenefit || ""}</span>
          <span>{item?.titleBenefit || ""}</span>
        </div>
      </TableCell>
    ),
  },
  {
    label: "Tổng phí",
    headerClass: "fees",
    renderCell: ({ item }) => (
      <TableCell className="fees">
        {`${item?.fee || ""}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      </TableCell>
    ),
  },
  {
    label: "Được duyệt",
    headerClass: "approved",
    renderCell: ({ classes, item, event, rowIdx, data }) => (
      <TableCell className="approved">
        <NumberFormat
          disabled={data.level_id !== 1}
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          className={`textMoney ${classes.textField}`}
          onChange={event(`othersBenefitChangTableApprove`, {
            name: "approveFee",
            index: rowIdx,
            item,
          })}
          value={item?.approveFee || ""}
          fullWidth
        />
      </TableCell>
    ),
  },
];

const useStyle = makeStyles(() => ({
  container: {
    marginBottom: "24px",
  },

  textField: {
    "& .MuiInputBase-input": {
      padding: "6px",
      borderRadius: "8px",
    },

    "& .MuiInputBase-multiline": {
      padding: "1px 0 !important",
    },

    "& fieldset": {
      border: "none",
      boxShadow: "inset 1px 1px 1px rgb(0 0 0 / 25%)",
      borderRadius: "8px",
    },
  },

  div: { paddingBottom: 0 },

  table: {
    minWidth: "800px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      color: "#0B3C8A",
      padding: "10px 5px 10px 12px",
    },

    "& th.fees": {
      textAlign: "center",
      width: "90px",
    },

    "& th.approved": {
      textAlign: "center",
      width: "140px",
    },

    "& td": {
      color: "#4B4B4B",
      padding: "5px 5px 5px 12px",
      lineHeight: "26px",
      fontWeight: "600",
    },

    "& td.fees": {
      textAlign: "center",
    },

    "& .textMoney": {
      "& .MuiInputBase-input.MuiOutlinedInput-input": {
        textAlign: "center",
      },
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#E3F3FF !important",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid white",
    background: "#F8F8F8 !important",
  },

  totalFees: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "42px",

    "& .conten": {
      width: "65%",
      background: "#ECECEC",
      color: "#3961A0",
      paddingLeft: "10px",
    },

    "& .fees": {
      width: "35%",
      background: "#3961A0",
      color: "white",
      textAlign: "end",
      paddingRight: "10px",
    },
  },
}));
