import moment from "moment";
const MAXIMUN_LINE_EXPORT_EXCEL = "10000";

const genders = [
  { id: 1, title: "Nam" },
  { id: 0, title: "Nữ" },
];

const insured_types = [
  { id: "NV", title: "Nhân viên" },
  { id: "NT", title: "Người thân" },
];

const initialStateGCNManager = {
  list: [],
  selectType: [],
  loading: false,
  dataSearch: "",
  filter: {},
  products: [],
  organizes: [],
  dataExport: [],
  count: 0,
  total: 0,
  pageTable: {
    count: 0,
    pageNumber: 1,
    pageSize: 20,
  },
  expand: false,
  popverEditTabelGCN: {},
  loadingFilter: {},
  pageFilter: {},
};

const renderAccident = [
  { title: "Tai nạn", name: "accident" },
  { title: "Bệnh có sẵn", name: "existing_disease" },
  { title: "Bệnh đặc biệt", name: "special_disease" },
  { title: "Thai sản", name: "pregnancy" },
  { title: "Biến chứng thai sản", name: "pregnancy_complications" },
  { title: "Bệnh TT Nội trú", name: "inpatient_treatment" },
  { title: "Bệnh TT Ngoại trú", name: "outpatient_treatment" },
  { title: "Răng", name: "tooth" },
];

const code_rules = [
  {
    id: 1,
    title: "Giới hạn/ năm",
  },
  {
    id: 2,
    title: "Giới hạn/ ngày",
  },
  {
    id: 3,
    title: "Giới hạn/ lần",
  },
  {
    id: 4,
    title: "Trợ cấp/ ngày",
  },
  {
    id: 5,
    title: "Trợ cấp/ lần",
  },
  {
    id: 6,
    title: "Trợ cấp/ năm",
  },
  {
    id: 7,
    title: "Trợ cấp lương/ ngày",
  },
  {
    id: 8,
    title: "Trợ cấp lương/ tháng",
  },
];

const type_of_indemnify = [
  {
    id: 1,
    title: "Tai nạn",
  },
  {
    id: 2,
    title: "Ốm đau, bệnh tật",
  },
  {
    id: 3,
    title: "Tử vong",
  },
  {
    id: 4,
    title: "Thai sản",
  },
  {
    id: 5,
    title: "TTVV/TTTT",
  },
];

const formatDate = (date) => {
  if (!date) return "";

  const checkDate = date.split("T");
  // if (checkDate.length > 1) {
  //   return moment().format("DD/MM/YYYY");
  // }

  const newDate = moment(checkDate[0]).format("DD/MM/YYYY");
  return newDate !== "Invalid date" ? newDate : "";
};

const getParamUrlByKey = (key) => {
  let search = window.location.search;
  let arr = search.split("?");
  let params = new URLSearchParams(arr[1]);
  let result = params.get(key) || "";
  return result;
};

const _formatMoney = (str) => {
  if (str) {
    return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return 0;
};

export {
  MAXIMUN_LINE_EXPORT_EXCEL,
  genders,
  insured_types,
  initialStateGCNManager,
  renderAccident,
  code_rules,
  type_of_indemnify,
  formatDate,
  getParamUrlByKey,
  _formatMoney,
};
