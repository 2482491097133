import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const styleds = makeStyles(() => {
  return {
    rowStyle: {
      position: "sticky",
      display: "flex",
      width: "100%",
      top: 0,
      left: 0,
      justifyContent: "flex-start",
      alignItems: "center",
      zIndex: 11,
      background: "white",
    },

    divStyle: {
      width: "100%",
      display: "flex",
    },

    styleButtonBack: {
      color: "#4B4B4B",
      display: "flex",
      // padding: "0 13px",
      left: 0,
      top: 0,
      alignItems: "center",
    },

    styleTitle: {
      color: "#4B4B4B",
      fontSize: 18,
      paddingRight: "16px",
      fontWeight: "700",
      alignItems: "center",
      lineHeight: "35px",
      textAlign: "center",
      width: "90%",
    },
  };
});

export default function HeaderBar(props) {
  const { title = "", onPress, isApp } = props;
  const classes = styleds();

  return (
    <Box className={classes.rowStyle}>
      <div
        className={classes.divStyle}
        style={{ margin: isApp ? "48px 0 10px" : "10px 0" }}
      >
        <div
          style={{ top: isApp ? "44px" : "3px" }}
          className={classes.styleButtonBack}
          onClick={onPress}
        >
          <ChevronLeftIcon style={{ fontSize: "35px" }} />
        </div>
        <div className={classes.styleTitle}>{title}</div>
      </div>
    </Box>
  );
}
