import React from "react";

export default function RowInfo(props) {
  const {
    title,
    value,
    capitalize,
    styedTitle = {},
    styledValue = {},
    styledRow = {},
  } = props;

  return (
    <div style={{ ...row, ...styledRow }}>
      <span style={{ ...spanTitle, ...styedTitle }}>{title}</span>
      <span
        style={{
          ...spanValue,
          ...styledValue,
          textTransform: capitalize ? "capitalize" : "",
        }}
      >
        {value}
      </span>
    </div>
  );
}

const row = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "4px",
};

const spanTitle = {
  minWidth: "fit-content",
  marginRight: "6px",
};

const spanValue = {
  color: "#0B3C8A",
  textAlign: "end",
};
