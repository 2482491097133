import { makeStyles } from "@mui/styles";

export const styledStep4 = makeStyles(() => ({
  titleHead: {
    fontSize: "16px",
    color: "#284078",
    fontWeight: "500",
    paddingTop: "6px",
  },

  boxImg: {
    padding: "10px 16px",
    margin: "8px 0",
    background: "white",
    minHeight: "570px",

    "& .warring": {
      color: "#E16C2B",
      background: "#FFF4E4",
      borderRadius: "8px",
      fontSize: "14px",
      fontStyle: "italic",
      width: "max-content",
      padding: "8px",
      display: "flex",
      alignItems: "center",
      fontWeight: "500",
    },

    "& .divImg": {
      width: "100%",
      overflow: "auto",

      "& .title": {
        fontSize: "16px",
        lineHeight: "21px",
        padding: "6px 0",
        position: "sticky",
        left: 0,
      },
    },
  },

  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "8px",
    border: "2px solid #284078",
    borderRadius: "12px",
    width: "100px",
    marginRight: "8px",
    marginBottom: "6px",
    position: "relative",

    "& .spanPicture": {
      color: "#284078",
      textAlign: "center",
      fontWeight: "500",
      fontSize: "14px",
    },

    "& .icon": {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 11,
    },
  },

  lineImg: {
    display: "flex",
    width: "fit-content",
    margin: "6px 0",
  },

  //
}));
