import _ from 'lodash'
const parseCurrency = (value, sign = 'VNĐ', fractionDigits = 0) => {
    if(_.isNumber(value)){
        let formatedValue = value.toLocaleString(undefined, {minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits})
        return [formatedValue, sign].filter(item => (item || '').trim().length > 0).join(' ')
    }
    return ''
}

export default parseCurrency;
