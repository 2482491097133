import { makeStyles } from "@mui/styles";

export const detailStyles = makeStyles(() => ({
  container: {
    position: "relative",
    width: "100%",
    padding: "0 12px",
    marginTop: "45px",
    background: "#FFF",

    "& .title": {
      color: "#4B4B4B",
      fontSize: "15px",
      fontWeight: "700",
      margin: " 24px 0 12px 0",
    },

    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },

  box_logo: {
    "& .name-insurance": {
      color: "#4B4B4B",
      fontSize: "18px",
      fontWeight: "700",
    },
  },

  header_box: {
    width: "100%",
    background: "white",
    padding: "12px",
    border: "1px solid #ECECEC",
    borderRadius: "8px",
    marginTop: "12px",
    "& .logo": {
      width: "50px",
      height: "50px",
      objectFit: "contain",
      padding: "2px",
      borderRadius: "50%",
      border: "0.5px solid rgba(0, 0, 0, 0.05)",
    },
  },

  box_grid_item: {
    "& .justyfy-between": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      margin: "8px 0",
    },

    "& .label": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#4B4B4B",
    },

    "& .value": {
      fontSize: "14px",
      fontWeight: "700",
      color: "#4B4B4B",
    },
    "& .blue": {
      color: "#3961A0",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
  },

  box_infor_insurance: {
    width: "100%",
    marginTop: "12px",

    "& .title": {
      color: "#4B4B4B",
      fontSize: "15px",
      fontWeight: "700",
      marginBottom: "8px",
    },

    "& .box_infor": {
      background: "#F8F8F8",
      // border: "1px solid #D3D3D3",
      borderRadius: "8px",
      padding: "8px 12px",
      display: "flex",
      flexWrap: "wrap",
    },

    "& .box_item": {
      margin: "0 12px 16px 0",
      minWidth: "200px",
      maxWidth: "350px",
      width: "22%",
      fontSize: "14px",
    },

    "& .item_label": {
      color: "#A0A0A0",
      marginBottom: "4px",
    },

    "& .item_value": {
      fontWeight: "600",
    },
  },

  containeer_time_remaining: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    marginBottom: "12px",
    paddingBottom: 15,
    background: "#F8F8F8",
    flexWrap: "wrap",

    "& .div-column": {
      display: "flex",
      flexDirection: "column",
      background: "#F8F8F8",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "12px",
      width: "11%",
      maxWidth: "250px",
    },

    "& .head-table": {
      textAlign: "center",
      color: "#A0A0A0",
      padding: "6px",
      fontSize: 14,
    },

    "& .value-table": {
      textAlign: "center",
      color: "#3961A0",
      fontSize: "13px",
      fontWeight: "600",
      padding: "6px",
      paddingBottom: "24px",
    },
  },

  button: {
    margin: "25px 0",
    textAlign: "end",
    "& .btn-upload": {
      background: "#3961A0",
      color: "#fff",
      padding: "8px 24px",
    },
  },
}));
