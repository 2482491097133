import { message } from "antd";
import _ from "lodash";
import {
  createBenefitCode,
  getBenefitCode,
} from "../../../apis/benefitCodeApis";

const benefitCodeReducer = ({ initialState, state, action, dispatch }) => {
  const cases = {
    getList: async () => {
      const { data } = state;
      const { value } = action;
      const res = {};
      const p = {
        keyword: value || "",
        limit: 100,
        offset: 0,
      };
      dispatch("onLoading");
      const result = await getBenefitCode(p);
      if (result instanceof Error) {
        message.error("Lỗi lấy danh sách", 2);
        return { loading: false };
      }

      if (!value) {
        res.typeCode = _.get(result, "data.result.data") || [];
      }
      data.total = _.get(result, "data.result.total") || 0;
      res.rows = (_.get(result, "data.result.data") || []).map((i) => {
        const regex = /\./g;
        if (!i.code_benefit.includes(".")) {
          return { ...i, isFirst: true };
        }
        if (i.code_benefit.match(regex).length === 1) {
          return { ...i, isSecond: true };
        }
        return i;
      });
      return { ...res, data, loading: false };
    },
    fetchData: async () => {
      const { rows, data } = state;
      const res = {};
      const offset = rows.length + 1;

      if (offset < data.total) {
        dispatch("onScrolling");
        const p = {
          keyword: "",
          limit: 100,
          offset,
        };

        const result = await getBenefitCode(p);
        if (result instanceof Error) {
          message.error("Lỗi lấy danh sách", 2);
          return { scrolling: false };
        }

        const newData = (_.get(result, "data.result.data") || []).map((i) => {
          const regex = /\./g;
          if (!i.code_benefit.includes(".")) {
            return { ...i, isFirst: true };
          }
          if (i.code_benefit.match(regex).length === 1) {
            return { ...i, isSecond: true };
          }
          return i;
        });
        res.rows = [...rows, ...newData];
      }
      return { ...res, scrolling: false };
    },
    getListCode: async () => {
      const { data } = state;
      const { value } = action;
      const res = {};
      const p = {
        keyword: value || "",
        limit: 100,
        offset: 0,
      };
      if (!state.isInput) {
        const result = await getBenefitCode(p);
        if (result instanceof Error) {
          return message.error("Lỗi lấy danh sách", 2);
        }
        res.typeCode = _.get(result, "data.result.data") || [];
        data.codeBenefitParent = { code_benefit: value, title: "" };
      }
      res.isInput = false;
      return res;
    },

    onSelect: () => {
      const { data } = state;
      const { name, value } = action;
      data[name] = value || {};
      if (name === "codeBenefitParent") {
        data.codeBenefit = "";
      }
      return { data, isInput: true };
    },
    changeCode: () => {
      const { data } = state;
      const { name, value } = action;
      data[name] = value;
      return { data };
    },

    validate: () => {
      const { data } = state;
      const errors = [];
      // !data?.codeBenefitParent?.code_benefit && errors.push("codeParent");
      !data?.codeBenefit && errors.push("code");
      !data?.title && errors.push("title");
      // !data?.codeRules?.value && errors.push("codeRules");
      if (errors.length === 0) {
        dispatch("onSave");
      }
      return { errors };
    },
    onSave: async () => {
      const { data } = state;
      const d = {
        codeBenefitParent: data?.codeBenefitParent?.code_benefit || undefined,
        codeBenefit: data?.codeBenefit
          ? data?.codeBenefit.toUpperCase()
          : undefined,
        title: data?.title || undefined,
        configBenefit: {
          codeRules: [data?.codeRules?.value || undefined],
        },
      };
      dispatch("onSaving");
      const res = await createBenefitCode(d);
      if (res instanceof Error) {
        message.error(res?.data?.message || "Lỗi tạo mã quyền lợi", 2);
        return { saving: false };
      }
      message.success("Tạo mã quyền lợi thành công.!", 2);
      dispatch("getList");
      return { data: {}, saving: false, open: false };
    },
    onOpen: () => ({ open: true }),
    onClose: () => ({ open: false, data: {}, errors: [] }),
    onLoading: () => ({ loading: true }),
    onSaving: () => ({ saving: true }),
    onScrolling: () => ({ scrolling: true }),
  };
  return cases[action?.type];
};
export default benefitCodeReducer;
