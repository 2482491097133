import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircleIcon from "@mui/icons-material/Circle";
import { TableCell } from "@mui/material";
import moment from "moment";
import { getStatusById } from "../util";

export const columns = [
  {
    label: "STT",
    headerClass: "STT ",
    renderCell: ({ rowIdx }) => (
      <TableCell className="STT">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "Tên người được bảo hiểm",
    renderCell: ({ item }) => (
      <TableCell>{item?.insuredInfo?.insuredName || ""}</TableCell>
    ),
  },
  {
    label: "Mã HS Bồi thường",
    key: "code",
  },
  {
    label: "CCCD/CMND",
    renderCell: ({ item }) => (
      <TableCell>{item?.insuredInfo?.insuredPrivateId || ""}</TableCell>
    ),
  },
  {
    label: "Công ty BH",
    key: "merchantTitle",
  },
  {
    label: "Đơn vị xử lý",
    key: "userFullnameReview",
  },
  {
    label: "Số tiền yêu cầu",
    renderCell: ({ item }) => (
      <TableCell>
        {(item?.requestAmount || "")
          ?.toString()
          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </TableCell>
    ),
  },
  {
    label: "Số tiền bồi thường",
    renderCell: ({ item }) => (
      <TableCell>
        {(item?.amount || 0)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </TableCell>
    ),
  },
  {
    label: "Ngày cập nhật",
    renderCell: ({ item }) => (
      <TableCell>
        {item?.updatedDate ? moment(item?.updatedDate) : ""}
      </TableCell>
    ),
  },
  {
    label: "Trạng thái",
    renderCell: ({ item, data }) => (
      <TableCell
        style={{
          fontWeight: "500",
          color: getStatusById(item.statusId).color,
        }}
      >
        {getStatusById(item.statusId).label}
      </TableCell>
    ),
  },
  {
    label: "Xem",
    headerClass: "show-icon sticky",
    renderCell: ({ data, item }) => (
      <TableCell className="show-icon sticky">
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => data.history.push(`/admin/blvp/detail/${item.id}`)}
        >
          <MoreHorizIcon fontSize="small" />
        </div>
      </TableCell>
    ),
  },
];
