import React from "react";
import { TableCell, Radio } from "@mui/material";

import { TextField } from "../../overrideMui";
import CommonTable from "../../../../../../library/layout/commonTable";
import styleClaimLicense from "./styleClaimLicense";

export default function DiagnosisTable(props) {
  const { state, dispatch, level_id } = props;
  const classes = styleClaimLicense();
  const { rowsDiagnosis } = state;

  return (
    <div className={classes.container_diagnosis}>
      <CommonTable
        classes={classes}
        rows={rowsDiagnosis}
        columns={columns}
        data={{ level_id }}
        event={(type, data) =>
          (ele, ...rest) => {
            dispatch({ type, data, ele, rest });
          }}
      />
    </div>
  );
}

const columns = [
  {
    label: "Mã bệnh",
    headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">{item?.diseaseCode || ""}</TableCell>
    ),
  },
  {
    label: "Tên bệnh",
    headerClass: "diagnosis-name",
    renderCell: ({ item }) => (
      <TableCell className="diagnosis-name">
        {item?.diseaseName || ""}
      </TableCell>
    ),
  },
  {
    label: "Từ chối ",
    headerClass: "refuse",
    renderCell: ({ classes, item, event, rowIdx, data }) => (
      <TableCell className="refuse">
        <Radio
          className={classes.radio}
          disabled={data.level_id !== 1}
          key={`radio_${rowIdx}`}
          checked={+item.approveStatus === 0}
          onClick={event("diagnosisChangTableApprove", {
            index: rowIdx,
            name: "approveStatus",
            value: item.approveStatus == 1 ? 0 : 1,
          })}
        />
      </TableCell>
    ),
  },
  {
    label: "Lý do",
    headerClass: "reason",
    renderCell: ({ classes, item, event, rowIdx, data }) => (
      <TableCell className="reason">
        <TextField
          fullWidth
          disabled={data.level_id !== 1}
          multiline
          className={classes.textField}
          value={item?.note || ""}
          onChange={event("diagnosisChangTableApprove", {
            index: rowIdx,
            name: "note",
          })}
        />
      </TableCell>
    ),
  },
];
