import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    marginTop: "48px",
    padding: "0 12px",
    width: "100%",
  },

  box_heder: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 0",
    alignItems: "center",

    "& .title-decentraliza": {
      fontSize: "20px",
      fontWeight: "700",
    },

    "& .buttons-add-decentraliza": {
      background: "#0B7CE4",
      color: "white",
      fontWeight: "500",

      "& .icon-button": { marginRight: "12px" },
    },

    "& .disabled": {
      background: "#A0A0A0",
    },
  },

  grid_container: {
    background: "white",
  },

  //
}));
