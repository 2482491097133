import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import styleLicense from "./styleLicense";
import { Button } from "@mui/material";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "14px", color: "white" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#3961A0",
  color: "white",
  flexDirection: "row-reverse",
  padding: "0 8px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: "8px 0 8px  8px",

    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: "600",
    },
  },
}));

const Accordions = ({ expanded, onChange, title, children }) => {
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ background: "#F8F8F8", padding: "8px" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default function AccordionsLincense(props) {
  const classes = styleLicense();
  const { state, dispatch, level_id } = props;

  const { expandAccordions, listTypeIdLicese, typeIdLiceseButton, detail } =
    state;

  return (
    <div>
      <Accordions
        key="persionalLicensse"
        title="Chứng từ cá nhân"
        onChange={() =>
          dispatch({ type: "onChangExpand", name: "persionalLicensse" })
        }
        expanded={expandAccordions?.persionalLicensse}
      >
        {listTypeIdLicese
          .filter((i) => [500, 537, 517].includes(i.typeId))
          ?.map((i) => (
            <Button
              className={classes.divButton}
              key={`license_button_${i.typeId}`}
              style={{
                background: typeIdLiceseButton === i.typeId ? "#E3F3FF" : "",
                color:
                  (
                    (detail?.elements || []).find(
                      (el) => el.type_code === i.typeCode
                    )?.extra_info?.documents || []
                  )?.length == 0
                    ? "#999999"
                    : "",
              }}
              // disabled={
              //   (
              //     (detail?.elements || []).find(
              //       (el) => el.type_code === i.typeCode
              //     )?.extra_info?.documents || []
              //   )?.length == 0 && level_id !== 1
              // }
              onClick={() =>
                dispatch({
                  type: "onClickLicense",
                  typeId: i.typeId,
                  nameLicense: i.title,
                  typeCode: i.typeCode,
                })
              }
            >
              {i.title}
            </Button>
          ))}
      </Accordions>
      <Accordions
        key="hospitalLicensse"
        title="Chứng từ bệnh viện"
        onChange={() =>
          dispatch({ type: "onChangExpand", name: "hospitalLicensse" })
        }
        expanded={expandAccordions?.hospitalLicensse}
      >
        {listTypeIdLicese
          .filter(
            (i) =>
              ![500, 517, 523, 532, 533, 534, 535, 536, 537].includes(i.typeId)
          )
          .map((i) => (
            <Button
              className={classes.divButton}
              key={i.title}
              style={{
                background: typeIdLiceseButton === i.typeId ? "#E3F3FF" : "",
                color:
                  (
                    (detail?.elements || []).find(
                      (el) => el.type_code === i.typeCode
                    )?.extra_info?.documents || []
                  )?.length == 0
                    ? "#999999"
                    : "",
              }}
              onClick={() =>
                dispatch({
                  type: "onClickLicense",
                  typeId: i.typeId,
                  nameLicense: i.title || "",
                  typeCode: i.typeCode,
                })
              }
              // disabled={
              //   (
              //     (detail?.elements || []).find(
              //       (el) => el.type_code === i.typeCode
              //     )?.extra_info?.documents || []
              //   )?.length == 0 && level_id !== 1
              // }
            >
              {i.title}
            </Button>
          ))}
      </Accordions>
      <Accordions
        key="policeLicensse"
        title="Chứng từ cơ quan chức năng"
        onChange={() =>
          dispatch({ type: "onChangExpand", name: "policeLicensse" })
        }
        expanded={expandAccordions?.policeLicensse}
      >
        {listTypeIdLicese
          .filter((i) => [523, 532, 533, 534, 535, 536].includes(i.typeId))
          ?.map((i) => (
            <Button
              className={classes.divButton}
              key={`license_button_${i.typeCode}`}
              style={{
                background: typeIdLiceseButton === i.typeId ? "#E3F3FF" : "",
                color:
                  (
                    (detail?.elements || []).find(
                      (el) => el.type_code === i.typeCode
                    )?.extra_info?.documents || []
                  )?.length == 0
                    ? "#999999"
                    : "",
              }}
              // disabled={
              //   (
              //     (detail?.elements || []).find(
              //       (el) => el.type_code === i.typeCode
              //     )?.extra_info?.documents || []
              //   )?.length == 0 && level_id !== 1
              // }
              onClick={() =>
                dispatch({
                  type: "onClickLicense",
                  typeId: i.typeId,
                  nameLicense: i.title,
                  typeCode: i.typeCode,
                })
              }
            >
              {i.title}
            </Button>
          ))}
      </Accordions>
    </div>
  );
}
