import moment from "moment";

export default function TableExportExcel(props) {
  const { dataSearch } = props;

  const fromDate = !dataSearch?.from
    ? "..............."
    : moment(dataSearch?.from).format("DD/MM/YYYY");
  const toDate = !dataSearch?.to
    ? "..............."
    : moment(dataSearch?.to).format("DD/MM/YYYY");

  return (
    <>
      <div style={{ display: "none" }}>
        <table
          className="collapse-border"
          id={"export-data-table"}
          data-cols-width="7, 15, 25, 25, 20, 25, 15, 25, 20, 20, 20, 15"
        >
          <thead style={{ fontSize: "13" }}>
            <tr />
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"16"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                BÁO CÁO TÀI KHOẢN
              </th>
            </tr>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >{`Từ ngày: ${fromDate} Đến Ngày: ${toDate} `}</th>
            </tr>
            <tr />
            <tr />
            <tr />
            <tr />
            <tr style={{ fontSize: "13" }}>
              <Th>STT</Th>
              <Th>Loại tài khoản</Th>
              <Th>Tên tài khoản</Th>
              <Th>Tên đăng nhập</Th>
              <Th>Số điện thoại</Th>
              <Th>Email</Th>
              <Th>Nhóm quyền</Th>
              <Th>Công ty</Th>
              <Th>Quản lý bởi</Th>
              <Th>Ngày tạo</Th>
              <Th>Ngày cập nhập</Th>
              <Th>Trạng thái</Th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13" }}>
            {props.data.map((item, index) => {
              return (
                <tr key={index} style={{ fontSize: "13" }}>
                  <Td>{index + 1}</Td>
                  <Td>{item?.user_type || ""}</Td>
                  <Td>{item?.fullname || ""}</Td>
                  <Td>{item?.username || ""}</Td>
                  <Td>{item?.phone_number || ""}</Td>
                  <Td>{item?.email || ""}</Td>
                  <Td>{item?.role_group_title || ""}</Td>
                  <Td>{item?.org_title}</Td>
                  <Td>{item?.manage_by || ""}</Td>
                  <Td>
                    {item?.created_date
                      ? moment(item?.created_date).format("DD/MM/YYYY")
                      : ""}
                  </Td>
                  <Td>
                    {item?.updated_date
                      ? moment(item?.updated_date).format("DD/MM/YYYY")
                      : ""}
                  </Td>
                  <Td>
                    {item?.status === 1
                      ? "Đang hoạt động"
                      : item?.status === 0
                      ? "Ngưng hoạt động"
                      : ""}
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const Th = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-h={"center"}
      data-a-wrap={true}
      data-f-bold={true}
    >
      {props.children}
    </th>
  );
};

const Td = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-wrap={true}
    >
      {props.children}
    </th>
  );
};
