import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";

import {
  Wrapper,
  ContentWrapper,
  Content,
} from "../../.../../carAccident/styles";
import file from "../../../../../assets/icons/file.svg";
import HeaderBar from "../components/layout/HeaderBar";
import imgTomato from "../../../../../assets/images/text-Tomato.png";
import * as PATH from "../../../../../configs/routesConfig";

const steps = [
  {
    label: "Bước 1:",
    desc: ["Đăng nhập app", "Chọn yêu cầu bồi thường"],
  },
  {
    label: "Bước 2:",
    desc: [
      "Tạo yêu cầu bồi thường",
      "Điền các thông tin vào form và hình ảnh đính kèm",
    ],
  },
  {
    label: "Bước 3:",
    desc: ["Gửi hồ sơ yêu cầu bồi thường"],
  },
];

const CreateRequestClaimTomato = () => {
  const history = useHistory();

  const handalListClaim = () => {
    history.push(PATH.LIST_CLAIM_TOMATO + "?goback");
  };
  const handleBtn = () => {
    history.push(PATH.INSU_TOMATO_CLAIM_LIST + "?goback");
  };

  return (
    <Wrapper>
      <ContentWrapper
        style={{ backgroundColor: "white", fontFamily: "SVN-Avenir Next" }}
      >
        <HeaderBar
          title="Tạo yêu cầu bồi thường"
          background="#F2F2F2"
          onPress={() => history.push(PATH.CLAIM_TOMATO)}
        />
        <Content>
          <Row justify="center">
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <div style={formInfo}>
                <Box sx={bgDashboardIntro}>
                  <img src={imgTomato} />
                  <span style={title}>
                    {"Tích lũy tiền lẻ - chia sẻ yêu thương"}
                  </span>
                </Box>
                <Box key="box">
                  <div style={spanStyle}>{"Quy trình bồi thường"}</div>
                  <Stepper orientation="vertical" sx={stepper}>
                    {steps.map((step) => (
                      <Step active={true} key={step.label}>
                        <StepLabel
                          sx={stepLabel}
                          children={step.label}
                          StepIconComponent={RadioButtonCheckedOutlinedIcon}
                        ></StepLabel>
                        <StepContent sx={stepContent}>
                          <ul style={{ fontSize: 17 }}>
                            {step.desc.map((desc, index) => (
                              <li key={step.label + index}>{desc}</li>
                            ))}
                          </ul>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button onClick={handalListClaim} sx={createButton}>
                    Tạo yêu cầu bồi thường
                  </Button>
                  <Button sx={iconButton} onClick={handleBtn}>
                    <img src={file} alt="file" style={{ fontSize: "0px" }} />
                  </Button>
                </Box>
              </div>
            </Col>
          </Row>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default CreateRequestClaimTomato;

const formInfo = {
  margin: "54px 0 8px 0",
};

const spanStyle = {
  fontSize: 20,
  fontWeight: 600,
  lineHeight: "25px",
  margin: "54px 0 12px 0",
  color: "#0B3C8A",
  fontFamily: `"Averta Std CY",sans-serif`,
};

const title = {
  fontWeight: 400,
  fontSize: 19,
  lineHeight: "22px",
  color: "#0A0A0A",
  fontFamily: `"SVN-Avenir Next",sans-serif`,
};

const bgDashboardIntro = {
  borderWidth: 0,
  flexDirection: "column",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& img": {
    width: "70%",
  },
  "& span": {
    marginTop: "12px",
    fontSize: "19px",
    fontWeight: 400,
  },
};

const stepLabel = {
  "& .MuiStepContent-root": {},
  "& .MuiStepLabel-label": {
    fontSize: 17,
    fontFamily: `"Averta Std CY",sans-serif`,
  },
  "& .MuiSvgIcon-root": {
    width: 20,
    height: 20,
    color: "#0B3C8A",
    paddingLeft: "4px",
  },
};

const stepContent = {
  borderColor: "#0B3C8A",
  fontFamily: `"Averta Std CY",sans-serif`,
  fontSize: "17px",
  lineHeight: "21px",

  "& ul": {
    paddingLeft: 3,
  },
};

const stepper = {
  ".MuiStepConnector-line": {
    borderColor: "#0B3C8A",
  },
};

const iconButton = {
  color: "#0B3C8A",
  borderRadius: "20px",
  height: "58px",
  backgroundColor: "white",
  padding: 0,
  boxShadow: "0px 0px 90px rgba(0, 0, 0, 0.19)",
};

const createButton = {
  width: "75%",
  lineHeight: "22px",
  fontWeight: 700,
  borderRadius: "14px",
  fontSize: 17,
  marginRight: "6px",
  padding: "15px 24px",
  textTransform: "none",
  backgroundColor: "#0B3C8A",
  color: "white",
  "&:hover": { backgroundColor: "#0B3C8A" },
};
