import React, { useRef, useEffect } from "react";
import { Button, Card, IconButton } from "@mui/material";
import { get } from "lodash";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";

import { TextField } from "../overrideMui";
import { SteperHeader, DialogNoti } from "../components";
import { styledCCCD } from "../components/styeleds/styleCCCD";
import DatePicker from "../components/PickerMobile/DatePicker";

import CMND_MT from "../../../../../assets/health/CMND_MT.svg";
import CMND_MS from "../../../../../assets/health/CMND_MS.svg";

export default function CCCD(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledCCCD();
  const {
    curenStep,
    insuranceType,
    dataPrivateId,
    insuredInfo,
    picker,
    dialogNoti,
    errors,
  } = state;

  const before =
    get(
      (dataPrivateId?.extraInfo?.documents || []).find(
        (i) => i.face === "before"
      ),
      "path"
    ) || null;

  const after =
    get(
      (dataPrivateId?.extraInfo?.documents || []).find(
        (i) => i.face === "after"
      ),
      "path"
    ) || null;

  return (
    <div
      className={classes.container}
      style={{
        minHeight: isApp ? "calc(100vh - 175px)" : "calc(100vh - 133px)",
      }}
    >
      <SteperHeader activeStep={curenStep} insuranceType={insuranceType} />
      <div className={classes.title}>Chụp CMND/ CCCD</div>
      <div style={{ fontSize: "13px", marginBottom: "16px" }}>
        Chụp CMND/CCCD để nhập nhanh thông tin {"( không bắt buộc )"}{" "}
      </div>
      <div className={classes.boxCCCDImg}>
        <Grid container spacing={1}>
          <Grid xs={6}>
            <div className="div-img-cccd">
              <div style={{ width: "100%", marginBottom: "10px" }}>
                <img src={before || CMND_MT} width="100%" />
              </div>
              <input
                capture="camera"
                id="before_privateId"
                type="file"
                accept="image/*"
                onClick={(e) => {
                  e.target.value = "";
                }}
                onChange={(e) => {
                  dispatch({
                    type: "uploadImgCCCD",
                    files: e.target.files,
                    face: "before",
                  });
                }}
                style={{
                  display: "none",
                }}
              />
              <Button
                className={classes.buttonImg}
                onClick={
                  () => {
                    document.getElementById("before_privateId").click();
                  }
                  // dispatch({ type: "movePageCamera", face: "before" })
                }
              >
                Chụp mặt trước
              </Button>
            </div>
          </Grid>
          <Grid xs={6}>
            <div className="div-img-cccd">
              <div style={{ width: "100%", marginBottom: "10px" }}>
                <img src={after || CMND_MS} width="100%" />
              </div>
              <input
                capture="camera"
                id="after_privateId"
                type="file"
                accept="image/*"
                onClick={(e) => {
                  e.target.value = "";
                }}
                onChange={(e) => {
                  dispatch({
                    type: "uploadImgCCCD",
                    files: e.target.files,
                    face: "after",
                  });
                }}
                style={{
                  display: "none",
                }}
              />
              <Button
                className={classes.buttonImg}
                onClick={
                  () => {
                    document.getElementById("after_privateId").click();
                  }
                  // dispatch({ type: "movePageCamera", face: "after" })
                }
              >
                Chụp mặt sau
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.title}>Nhập thông tin người được bảo hiểm</div>

      <div className={classes.label}>
        Họ và tên <span style={{ color: "red" }}>*</span>{" "}
      </div>
      <TextField
        fullWidth
        placeholder="Nhập thông tin"
        value={insuredInfo?.insuredName || ""}
        error={!!errors?.insuredName}
        className={classes.inputCapitalize}
        helperText={!!errors?.insuredName ? "Vui lòng nhập họ và tên" : false}
        onChange={(e) => {
          dispatch({
            type: "onChangeBeneficiary",
            value: e?.target?.value,
            name: "insuredName",
          });
        }}
      />
      <div className={classes.label}>
        Năm sinh <span style={{ color: "red" }}>*</span>{" "}
      </div>
      <TextField
        fullWidth
        placeholder="Nhập thông tin"
        InputProps={{
          readOnly: true,
        }}
        error={!!errors?.insuredDob}
        helperText={!!errors?.insuredDob ? "Vui lòng chọn năm sinh" : false}
        value={
          insuredInfo?.insuredDob
            ? moment(insuredInfo?.insuredDob).format("DD/MM/YYYY")
            : ""
        }
        onFocus={() => dispatch({ type: "setOpenPicker", name: "dob" })}
      />
      <div className={classes.label}>
        CMND/ CCCD/ Hộ chiếu <span style={{ color: "red" }}>*</span>{" "}
      </div>
      <TextField
        fullWidth
        placeholder="Nhập thông tin"
        value={insuredInfo?.insuredPrivateId || ""}
        error={!!errors?.insuredPrivateId}
        helperText={
          !!errors?.insuredPrivateId
            ? "Vui lòng nhập CMND/ CCCD/ hộ chiếu"
            : false
        }
        onChange={(e) => {
          dispatch({
            type: "onChangeBeneficiary",
            value: e?.target?.value,
            name: "insuredPrivateId",
          });
        }}
      />
      <div
        style={{ paddingLeft: "6px", fontSize: "12px", fontStyle: "italic" }}
      >
        Người dưới 18 tuổi thì nhập số định danh
      </div>
      <DatePicker
        value={insuredInfo?.insuredDob || null}
        open={!!picker.dob}
        max={moment().toDate()}
        close={() => dispatch({ type: "setOpenPicker", name: "dob" })}
        onConfirm={(value) =>
          dispatch({
            type: "onChangeBeneficiary",
            value: moment(value).format(),
            name: "insuredDob",
          })
        }
      />
      <DialogNoti {...{ dispatch, data: dialogNoti }} />
    </div>
  );
}
