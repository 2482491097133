import { makeStyles } from "@mui/styles";

export const styledStepHistory = makeStyles(() => ({
  container: {},

  boxTabs: {
    padding: "8px",
    marginBottom: "8px",
    background: "white",

    "& .MuiTabs-indicator": {
      backgroundColor: "#E16C2B",
    },

    "& .MuiButtonBase-root.MuiTabScrollButton-root": {
      width: "20px",
    },

    "& .MuiButtonBase-root.MuiTab-root": {
      padding: "6px 8px",
      fontSize: "15px",
      textTransform: "none",
      color: "#000000",
    },

    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      color: "#E16C2B",
      fontWeight: "600",
    },
  },

  boxTrip: {
    padding: "16px",
    background: "white",
  },

  contaiDetialH: {
    padding: "0 16px 16px",
    background: "white",

    "& .titleHead": {
      fontSize: "16px",
      color: "#284078",
      fontWeight: "600",
      padding: "8px 0",
    },

    "& .rowDiv": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "8px",
      fontSize: "15px",
      lineHeight: "22px",
      fontWeight: "500",

      "& .valueSpan": {
        maxWidth: "65%",
        textAlign: "end",
      },
    },
  },

  containImg: {
    marginTop: "8px",

    "& .titleAccodion": {
      color: "#284078",
      fontSize: "16px",
      fontWeight: "600",
    },

    "& .MuiAccordionDetails-root": {
      padding: "0 16px 16px",
    },

    "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "40px",

      "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: "8px 0",
      },
    },
  },

  boxImg: {
    // padding: "10px 16px",
    margin: "8px 0",
    background: "white",

    "& .warring": {
      color: "#E16C2B",
      background: "#FFF4E4",
      borderRadius: "8px",
      fontSize: "14px",
      fontStyle: "italic",
      width: "max-content",
      padding: "8px",
      display: "flex",
      alignItems: "center",
      fontWeight: "500",
    },

    "& .divImg": {
      width: "100%",
      overflow: "auto",

      "& .title": {
        fontSize: "16px",
        lineHeight: "21px",
        padding: "6px 0",
      },
    },
  },

  lineImg: {
    display: "flex",
    width: "fit-content",
    margin: "6px 0",
  },

  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "8px",
    border: "2px solid #284078",
    borderRadius: "12px",
    width: "110px",
    marginRight: "8px",
    marginBottom: "6px",
    position: "relative",

    "& .spanPicture": {
      color: "#284078",
      textAlign: "center",
      fontWeight: "500",
      fontSize: "14px",
    },

    "& .icon": {
      position: "absolute",
      top: 0,
      right: 0,
    },
  },

  boxTextRow: {
    "& .rowDiv": {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "15px",
      fontWeight: "500",
      margin: "8px 0",
    },

    "& .capitalize": {
      textTransform: "capitalize",
    },

    "& .border-none": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiInputBase-multiline": {
        padding: "5px 0 0 10px !important",
        fontSize: 14,
      },
    },
  },

  // ellipsis: {
  //   display: "flex",
  //   justifyContent: "space-between",
  //   fontSize: "15px",
  //   fontWeight: "500",

  //   "& .valueSpan": {
  //     maxWidth: "60%",
  //     overflow: "hidden",
  //     // whiteSpace: "nowrap",
  //     // textOverflow: "ellipsis",
  //   },

  //   "& .titleSpan": {
  //     width: "220px",
  //   },
  // },

  signature: {
    padding: "16px",
    marginBottom: "20px",
    background: "white",

    "& .yourSigna": {
      fontSize: "16px",
      color: "#284078",
      fontWeight: "600",
      marginBottom: "8px",
    },

    "& .signa": {
      width: "100%",
      maxWidth: "600px",
      height: 168,
      border: "1px solid rgba(151, 151, 151, 0.24)",
      borderRadius: 10,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
  },

  //
}));
