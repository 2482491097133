import {
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  Radio,
  RadioGroup,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";

import HeadStepper from "../components/HeadStepper";
import DatePicker from "../components/PickerMobile/DatePicker";
import TimePicker from "../components/PickerMobile/TimePicker";
import { useStyles } from "../styles/stepperStyles";
import { _formatMoney } from "../utils";

import date from "../../../../../assets/loyalty/date.svg";
import hour from "../../../../../assets/loyalty/hour.svg";

function Step1({ state, dispatch }) {
  const {
    destination: { data, index },
    claimInfo,
    extraInfo,
    errors,
    picker,
  } = state;
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch("setInitialRiskDate");
  }, []);

  const maxDate =
    moment() <=
    moment(claimInfo?.endDate ? claimInfo?.endDate : data?.createdDate)
      ? moment().toDate()
      : moment(
          claimInfo?.endDate ? claimInfo?.endDate : data?.createdDate
        ).toDate();

  const maxTime =
    moment(extraInfo?.riskDate)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .format("DD") ==
    moment()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .format("DD")
      ? moment().format("HH:mm")
      : null;

  return (
    <div className={classes.container}>
      <HeadStepper activeStep={0} />
      <div className={classes.spacing}></div>
      <div className="header">THÔNG TIN SỰ CỐ</div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item xs={6} md={6}>
          <div
            onClick={() =>
              dispatch({ type: "setOpenPicker", name: "riskDate" })
            }
          >
            <span>Ngày xảy ra sự cố</span>
            <div className={classes.inputDiv}>
              <Input
                placeholder="DD/MM/YYYY"
                fullWidth
                value={moment(extraInfo?.riskDate).format("DD/MM/YYYY") || null}
              />
              <IconButton style={{ padding: "4px" }}>
                <img src={date} alt="date" />
              </IconButton>
            </div>
          </div>
        </Grid>
        <Grid item xs={6} md={6}>
          <div
            className="incident"
            onClick={() =>
              dispatch({ type: "setOpenPicker", name: "riskTime" })
            }
          >
            <span className="spanHours">Giờ xảy ra sự cố</span>
            <div className={classes.inputDiv}>
              <Input
                placeholder="HH:MM"
                fullWidth
                value={extraInfo?.riskTime || null}
                readOnly
              />
              <IconButton style={{ padding: "4px" }}>
                <img src={hour} alt="hour" />
              </IconButton>
            </div>
          </div>
          {errors.includes("validateDateTime") && (
            <div className={classes.error}>
              {`Giờ xảy ra sự cố phải sau ${moment(
                claimInfo?.createdDate
              ).format("h:mm")}`}
            </div>
          )}
        </Grid>
      </Grid>

      <div className="mt-10">
        <span>Mô tả diễn biến</span>
        <div
          className={classes.inputDiv}
          style={{ borderColor: errors.describe ? "red" : "" }}
        >
          <Input
            placeholder="Tường trình diễn biến sự việc."
            fullWidth
            // onBlur={() => dispatch("checkError")}
            value={extraInfo?.describe || ""}
            onChange={(e) =>
              dispatch({
                type: "onChangeExtraInfo",
                name: "describe",
                value: e.target.value,
              })
            }
            multiline
            minRows={3}
          />
        </div>
        {errors.includes("describe") && (
          <div className={classes.error}>Vui lòng nhập mô tả diễn biến</div>
        )}
      </div>
      <div className={classes.spacing}></div>
      <div className="header">KHAI BÁO GIÁ TRỊ ĐƠN HÀNG</div>
      <div className={classes.inputDiv}>
        <Input
          fullWidth
          placeholder="Nhập giá trị hàng hoá."
          value={
            extraInfo.productAmount ? _formatMoney(extraInfo.productAmount) : ""
          }
          onChange={(e) => {
            if (
              !"0123456789".includes(e?.nativeEvent?.data) &&
              e?.nativeEvent?.data !== null
            ) {
              return;
            }

            dispatch({
              type: "onChangeExtraInfo",
              name: "productAmount",
              value: e?.target?.value,
            });
          }}
        />
        <div style={{ padding: "4px", fontWeight: "500" }}>.VNĐ</div>
      </div>
      {errors.includes("productAmount") && (
        <div className={classes.error}>Vui lòng khai báo giá trị đơn hàng</div>
      )}
      {errors.includes("codValue") && (
        <div className={classes.error}>
          {`Giá trị hàng hóa không vượt quá giá trị COD ${_formatMoney(
            data?.codValue
          )}đ`}
        </div>
      )}
      <div className={classes.spacing}></div>
      <div className="header">RỦI RO GẶP PHẢI</div>
      <div className="risk">
        <RadioGroup
          aria-labelledby="risk"
          value={extraInfo?.typeOfAccident || null}
          onChange={(e) =>
            dispatch({
              type: "changeType",
              value: e?.target?.value,
            })
          }
        >
          {[
            { id: 3, label: "Shipper chiếm dụng hàng hoá" },
            { id: 4, label: "Shipper chiếm dụng COD" },
          ].map((i, index) => (
            <FormControlLabel
              key={index}
              value={i.id}
              control={
                <Radio
                  sx={{
                    "& .Mui-checked": {
                      color: "#E16C2B",
                    },
                    "& .MuiSvgIcon-root": {
                      color: errors.includes("typeOfAccident") ? "red" : "",
                    },
                  }}
                />
              }
              label={i.label}
            />
          ))}
        </RadioGroup>
        {errors.includes("typeOfAccident") && (
          <div className={classes.error}>Vui lòng chọn rủi ro gặp phải</div>
        )}
      </div>
      <DatePicker
        value={extraInfo?.riskDate || null}
        open={picker.riskDate}
        min={moment(claimInfo?.createdDate || null).toDate() || null}
        max={maxDate}
        close={() => dispatch({ type: "setOpenPicker", name: "riskDate" })}
        onConfirm={(value) =>
          dispatch({ type: "confirmPicker", name: "riskDate", value })
        }
      />
      <TimePicker
        value={extraInfo?.riskTime || null}
        maxTime={maxTime}
        open={picker.riskTime}
        close={() => dispatch({ type: "setOpenPicker", name: "riskTime" })}
        onConfirm={(value) =>
          dispatch({ type: "confirmPicker", name: "riskTime", value })
        }
      />
    </div>
  );
}

export default Step1;
