import _ from "lodash";
import { getDetailClaimIntransit } from "../../../../apis/claimFinaApi";

export default function detailFinaBH365({ state, dispatch, action, id }) {
  const cases = {
    initail: async () => {
      dispatch("onLoading");
      const { historyDestination } = state;

      const dataApi = await getDetailClaimIntransit(id);

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      if (_.get(dataApi, "data.statusCode") === 200) {
        historyDestination.data = _.get(dataApi, "data.result") || {};
      }

      return { loading: false };
    },

    onLoading: () => ({ loading: true }),

    changeIndexImg: () => {
      const { showImg } = state;
      const res = {};
      const { name } = action;
      if (name === "next") {
        const index =
          showImg?.indexImg === showImg?.lisUrl?.length - 1
            ? 0
            : showImg?.indexImg + 1;

        res.showImg = {
          ...showImg,
          indexImg: index,
          url: showImg?.lisUrl[index]?.path,
        };
      } else {
        const index =
          showImg?.indexImg === 0
            ? showImg?.lisUrl.length - 1
            : showImg?.indexImg - 1;

        res.showImg = {
          ...showImg,
          indexImg: index,

          url: showImg?.lisUrl[index]?.path,
        };
      }

      return res;
    },

    showImg: () => {
      const { data, url, indexImg, name = "", showCancle } = action;
      const { showImg } = state;
      const res = {};

      res.showImg = {
        ...showImg,
        open: true,
        lisUrl: data?.listImg?.documents || [],
        url,
        indexImg,
        id: data?.id,
        name: "history",
        showCancle,
      };

      return res;
    },

    closeShowImg: () => ({ showImg: { ...state.showImg, open: false } }),
    //
  };

  return action?.type && cases[action?.type];
}
