import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShowImg(props) {
  const { dispatch, state } = props;
  const classes = makeStyle();

  const { showDialogImg, listImgShow, indexImgShow } = state;

  return (
    <Dialog
      open={!!showDialogImg}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => dispatch("closeDaShowImg")}
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialog}
    >
      <DialogContent style={{ padding: "20px 0" }}>
        {!!indexImgShow.path ? (
          <div>
            <div
              className={classes.lengthImg}
            >{`${indexImgShow?.index} / ${listImgShow.length}`}</div>
            <div className={classes.divContent}>
              <ArrowBackIosIcon
                size="small"
                className={classes.icon}
                onClick={() =>
                  dispatch({ type: "changeIndexImg", name: "pre" })
                }
              />
              <div className={classes.divImg}>
                <img
                  style={{ width: "100%" }}
                  src={indexImgShow?.path || ""}
                  alt="imgs"
                />
                <CancelOutlinedIcon
                  className="icon"
                  onClick={() => dispatch("closeDaShowImg")}
                />
              </div>
              <ArrowForwardIosIcon
                size="small"
                className={classes.icon}
                onClick={() =>
                  dispatch({ type: "changeIndexImg", name: "next" })
                }
              />
            </div>
          </div>
        ) : (
          <div style={{ padding: "20px" }}>Hiện chưa có ảnh nào</div>
        )}
      </DialogContent>
    </Dialog>
  );
}

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
      margin: "0 8px",
    },
  },

  divContent: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },

  divImg: {
    width: "100%",
    maxWidth: "380px",

    "& .icon": {
      position: "absolute",
      top: "-50px",
      right: "6px",
    },
  },

  lengthImg: {
    textAlign: "center",
    padding: "6px 0",
  },

  icon: { color: "#969696" },
}));
