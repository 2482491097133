import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button } from "@mui/material";
import React, { useEffect } from "react";
import {
  DialogNoti,
  DialogRefuseClaim,
  SteperHeader,
  DialogShowPDF,
} from "../components";
import { styledStatusClaim } from "../components/styleds/styledStatusClaim";
import { convertCurrency } from "../utils";
import { get } from "lodash";
import { listLicense } from "../utils";
import ExportPDF from "../../../create/health/components/ExportPDF";

import { saveAs } from "file-saver";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";

const getNonPaid = (detail) => {
  // const bills = detail?.extraInfo?.bills?.map((i) =>
  //   i.approveStatus == 0 ? i?.note : ""
  // );
  // const examinationFees = detail?.extraInfo?.examinationFees?.map((i) =>
  //   i.approveStatus == 0 ? i?.note : ""
  // );
  // const medicines = detail?.extraInfo?.medicines?.map((i) =>
  //   i.approveStatus == 0 ? i?.note : ""
  // );

  // const rejectReason = [...bills, ...examinationFees, ...medicines]
  //   .filter((i) => !!i)
  //   .join(", ");

  const elementText = detail?.elements?.find((i) => {
    return i?.type_id === 521 && [5, 7, 8, 9].includes(+i?.status_id);
  });

  return elementText?.text || "";
};

export default function StatusClaim(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledStatusClaim();
  const { detail, dialogNoti } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contentTextId4 = (
    get(
      detail?.elements?.find(
        (i) =>
          i.type_code === "action_element" &&
          (i?.extra_info?.elements_amend || [])
      ),
      "extra_info.elements_amend"
    ) || []
  ).map((i) => {
    const temp = listLicense.find((te) => te.typeCode == i.typeCode);
    return temp.title || "";
  });

  const getContenByStatusId = () => {
    switch (detail.statusId) {
      case 1:
      case 2:
      case 3:
        return (
          <div>
            Hồ sơ yêu cầu bồi thường của Quý khách đang được xử lý. Thời gian xử
            lý dự kiến từ 24 đến 48 giờ làm việc.
          </div>
        );

      case 4:
        return (
          <div>
            Hồ sơ yêu cầu bồi thường của Quý khách đang bị thiếu các chứng từ.
            <span className="weight-700"> {contentTextId4.join(", ")}</span> Vui
            lòng bổ sung các chứng từ này.
          </div>
        );
      case 6:
        return (
          <div>
            Hồ sơ yêu cầu bồi thường của Quý khách đã được phê duyệt. Để xem chi
            tiết vào
            <span className="weight-700"> “Xem thông báo bồi thường"</span> Vui
            hoặc Email của Quý khách.
          </div>
        );
      case 7:
        return (
          <div
            className="w-100"
            style={{
              color: "#4B4B4B",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="w-100 my-16" style={{ textAlign: "center" }}>
              Hồ sơ yêu cầu bồi thường của Quý khách đã được phê duyệt với nội
              dung như sau:
            </div>
            <div style={{ color: "#000000", textAlign: "start" }}>
              <div className="w-100">
                Số tiền yêu cầu bồi thường:{" "}
                <span className="weight-700">{`${convertCurrency(
                  detail?.requestAmount
                )} đ`}</span>
              </div>
              <div className="w-100">
                Số tiền được chi trả:{" "}
                <span className="weight-700">{`${convertCurrency(
                  detail?.amount
                )} đ`}</span>{" "}
              </div>
              <div className="w-100">
                Nội dung không được chi trả:{" "}
                <span className="weight-700">{getNonPaid(detail) || ""}</span>{" "}
                {/* <span className="weight-700">
                  {(detail?.extraInfo?.examinationFees || [])
                    .map((item) => {
                      if (!item?.approveFee) {
                        return item?.serviceName;
                      }
                    })
                    .join()}
                </span> */}
              </div>
              <div className="w-100 my-16">
                Để xem chi tiết vào <b>“Xem thông báo bồi thường"</b> hoặc
                <b> Email </b>của bạn.
              </div>
            </div>
          </div>
        );

      case 5:
      case 8:
      case 9:
        if (detail?.requestAmount <= detail?.amount) {
          return (
            <div>
              Hồ sơ yêu cầu bồi thường của Quý khách đã được phê duyệt. Để xem
              chi tiết vào{" "}
              <span className="weight-700">“Xem thông báo bồi thường"</span>{" "}
              hoặc <span className="weight-700">Email </span> của Quý khách.
            </div>
          );
        }
        if (detail?.requestAmount > detail?.amount) {
          return (
            <div
              className="w-100"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                className="w-100 my-16"
                style={{ color: "#3961A0", textAlign: "center" }}
              >
                Hồ sơ yêu cầu bồi thường của Quý khách đã được phê duyệt với nội
                dung như sau:
              </div>
              <div style={{ color: "#000000", textAlign: "start" }}>
                <div className="w-100">
                  Số tiền yêu cầu bồi thường:{" "}
                  <span className="weight-700">{`${convertCurrency(
                    detail?.requestAmount
                  )} đ`}</span>{" "}
                </div>
                <div className="w-100">
                  Số tiền được chi trả:{" "}
                  <span className="weight-700">{`${convertCurrency(
                    detail?.amount
                  )} đ`}</span>{" "}
                </div>
                <div className="w-100">
                  Nội dung không được chi trả:{" "}
                  <span className="weight-700">{getNonPaid(detail) || ""}</span>{" "}
                </div>
                <div className="w-100 my-16">
                  Để xem chi tiết vào “Xem thông báo bồi thường" hoặc Email của
                  bạn.
                </div>
                <div className="w-100 my-16">
                  <i>
                    Vui lòng bấm <b>“xác nhận”</b> nếu Quý khách chấp nhận thông
                    báo này. Trong vòng <b>24 giờ</b> kể từ giờ ra thông báo,
                    nếu Quý khách không xác nhận chúng tôi sẽ mặc định Quý khách
                    đã chấp nhận thông báo này.
                  </i>
                </div>
              </div>
              <div
                className="w-100"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: 16,
                }}
              >
                <Button
                  disabled={[7, 8, 9].includes(detail.statusId)}
                  className="btn-refuse btn"
                  onClick={() => dispatch("setOpenDialogRefuse")}
                >
                  Từ chối
                </Button>
                <Button
                  disabled={[7, 8, 9].includes(detail.statusId)}
                  className="btn-agree btn"
                  onClick={() => dispatch("sendRequestAccept")}
                >
                  Xác nhận
                </Button>
              </div>
            </div>
          );
        }
      case 10:
        return (
          <div style={{ color: "red" }}>
            Hồ sơ yêu cầu bồi thường của Quý khách không hợp lệ. Lý do {"....."}
          </div>
        );
      default:
        break;
    }
  };

  const dowloadPDF = async () => {
    const blobPdf = await new pdf(ExportPDF({ detail })).toBlob();
    saveAs(blobPdf, "pdt.pdf");
  };

  return (
    <div
      className={classes.container}
      style={{
        minHeight: isApp ? "calc(100vh - 130px)" : "calc(100vh - 94px)",
      }}
    >
      <div className={classes.titleDiv}>
        <div className="name-insurance">
          Bảo hiểm {detail?.productTitle || ""}
        </div>

        <div style={{ width: "100%" }}>
          <SteperHeader detail={detail} />
        </div>
      </div>

      <div className={classes.box}>
        <div className="notification">THÔNG BÁO</div>
        <div className={classes.statusText}>{getContenByStatusId()}</div>
        {detail.statusId === 4 && (
          <div className={classes.buttons}>
            <Button
              className="button"
              onClick={() => dispatch("openLecesePage")}
            >
              Bổ sung ngay
            </Button>
          </div>
        )}
      </div>
      {[5, 6, 7, 8, 9].includes(detail.statusId) && (
        <>
          {/* <PDFDownloadLink
            document={<ExportPDF detail={detail} />}
            fileName="Thông báo bồi thường.pdf"
          >
            {({ blob, url, loading, error }) => {
              return (
                <div className={classes.infor}>
                  <div>Xem thông báo bồi thường</div>
                  <NavigateNextIcon fontSize="small" />
                </div>
              );
            }}
          </PDFDownloadLink> */}
          <div onClick={() => dispatch("openDialogShowPDF")}>
            <div className={classes.infor}>
              <div>Xem thông báo bồi thường</div>
              <NavigateNextIcon fontSize="small" />
            </div>
          </div>
        </>
      )}
      <div className={classes.infor} onClick={() => dispatch("openPageInfor")}>
        <div>Hồ sơ yêu cầu bồi thường</div>
        <NavigateNextIcon fontSize="small" />
      </div>
      <DialogRefuseClaim {...{ state, dispatch }} />
      <DialogShowPDF {...{ state, dispatch }} />
      <DialogNoti data={dialogNoti} dispatch={dispatch} />
    </div>
  );
}
