import { makeStyles } from "@mui/styles";

export const styleDocumentDetail = makeStyles(() => ({
  container: {
    marginTop: "12px",
    background: "white",
    padding: "12px",

    "& .title-packege-insurance": {
      padding: "8px 0",
      fontWeight: "600",
      fontSize: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },

  divButton: {
    width: "100%",
    color: "#4B4B4B",
    fontSize: "14px",
    cursor: "pointer",
    textTransform: "none",
    display: "flex",
    justifyContent: "start",
    textAlign: "left",
    padding: "8px 0",
    borderBottom: `1px solid white`,
    "&:last-child": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },

  containerBoxImg: {
    paddingLeft: "8px",
  },

  headerTitleBoxImg: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "12px",

    "& .titile": {
      color: "#3961A0",
      fontWeight: "600",
      maxWidth: "280px",
    },

    "& .buttons .MuiIconButton-root": {
      padding: "5px",
      marginRight: "3px",
    },
  },

  BoxImg: {
    width: "100%",
    overflow: "auto",

    "& .img": {
      width: "100%",
      minHeight: "400px",
      transition: `transform 0.5s`,
    },
  },

  BoxItemImg: {
    width: "100%",
    marginTop: "24px",

    "& .infor-img": {
      width: "100%",
      padding: "12px 0",
      display: "flex",
      justifyContent: "space-between",
    },

    "& .list-img": {
      display: "flex",
      width: "100%",
      overflowX: "auto",
      paddingBottom: "12px",

      "& .item-img": {
        width: "93px",
        height: "93px",
        marginRight: "8px",
        cursor: "pointer",
        position: "relative",

        "& .icon-closse": {
          color: "#1A1A1A",
          position: "absolute",
          top: -3,
          right: -6,
          background: "white",
          borderRadius: "12px",
        },
      },

      "& .item-additional": {
        width: "93px",
        height: "93px",
        marginRight: "8px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
      },
    },
  },

  additionalRecords: {
    marginTop: "12px",
    padding: "8px",
    background: "white",

    "& .title-additional-ecords": {
      fontSize: "14px",
      fontWeight: "600",
      marginBottom: "12px",
    },
  },

  containerAdditional: {
    border: "1px solid #A0A0A0",
    width: "100%",
    paddingTop: "32px",
    background: "#F8F8F8",

    "& .disabled": {
      background: "#686868",
    },
  },

  readTime: {
    margin: "0 16px",
    borderTop: "1px solid #A0A0A0",
    display: "flex",
    justifyContent: "center",
    position: "relative",

    "& .time": {
      background: "#DCDCDC",
      borderRadius: "24px",
      padding: "0 8px",
      position: "absolute",
      height: "24px",
      fontSize: "14px",
      color: "#686868",
      top: "-13px",
    },
  },

  comment: {
    marginTop: "16px",
    marginBottom: "16px",
    fontSize: "14px",
    width: "100%",
    padding: "0 16px",

    "& .item-comment": {
      display: "flex",
    },

    "& .icon-comment": {
      marginRight: "8px",
    },

    "& .title-license-type": {
      color: "#3961A0",
      fontWeight: "600",
    },

    "& .comment-license-type": {
      display: "flex",
      alignItems: "center",

      "& .content-comment": {
        marginRight: "6px",
      },

      "& .icons-edit-comment": {
        background: "#ECECEC",
        borderRadius: "8px",
        display: "flex",
      },
    },
  },

  inputComment: {
    padding: "8px",
    background: "white",
    display: "flex",
    width: "100%",

    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      padding: "0 !important",
    },

    "& fieldset": {
      border: "none",
    },
  },

  buttons: {
    width: "100%",
    padding: "12px 0",
    display: "flex",
    justifyContent: "end",

    "& .buttons": {
      textTransform: "none",
      background: "#3961A0",
      color: "white",
      borderRadius: "12px",
      padding: "12px 24px",
      fontWeight: "600",
    },

    "& .disabled": {
      background: "#A0A0A0",
    },
  },

  box_table: {
    marginTop: "12px",
    background: "white",
    padding: "12px",
  },

  title: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",

    "& .span-title": {
      fontWeight: "600",
    },
  },

  table_info_insuran: {
    width: "100%",
    display: "flex",
    background: "#F8F8F8",
    justifyContent: "space-between",

    "& .div-column": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      background: "#F8F8F8",
      marginBottom: "12px",
    },

    "& .head-table": {
      // textAlign: "center",
      color: "#A0A0A0",
      padding: "6px",
    },

    "& .value-table": {
      // textAlign: "center",
      color: "#3961A0",
      fontSize: "13px",
      fontWeight: "600",
      padding: "6px",
      paddingBottom: "24px",
    },
  },

  div_addition_request: {
    width: "100%",
    fontSize: "14px",
    color: "red",
    fontStyle: "italic",
    marginBottom: "12px",
  },

  //
}));
