import { get, isString } from "lodash";
import { getListClaim, getDetailClaim } from "../../../apis/health";
import ExportClaimRefuseDocx from "../claim-management/health/components/claimLicense/dialogSendApproval/ExportClaimRefuseDocx";

export default function dowDocXReducer(props) {
  const { state, action, dispatch, userType, history, id } = props;
  const users = JSON.parse(localStorage.getItem("user")) || null;
  const systemType = !users ? "" : users?.extra_info?.userType;
  const cases = {
    search: async () => {
      const { keyword } = action;

      const params = {
        code: keyword,
        limit: 20,
        offset: 0,
      };

      dispatch("onLoading");
      const res = {};

      const dataApi = await getListClaim(systemType, params);

      if (dataApi instanceof Error) {
        return {
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại",
          },
          loading: false,
        };
      }

      if (get(dataApi, "data.statusCode") === 200) {
        res.rows = get(dataApi, "data.result.data") || [];
        res.pageTable = {
          count: Math.ceil((get(dataApi, "data.result.total") || 0) / 20),
          page: 0,
          rowsPerPage: 20,
        };
      }

      return { loading: false, ...res, keyword };
    },

    onClickDowFile: async () => {
      const { data } = action;
      const { item = {} } = data;
      dispatch("onLoading");
      const detailClaimApi = await getDetailClaim(systemType, item.id);

      const res = {};
      if (detailClaimApi?.data?.statusCode === 200) {
        const detailClaim = get(detailClaimApi, "data.result") || {};
        await ExportClaimRefuseDocx({ detail: detailClaim });
      } else {
        return {
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại",
          },
          loading: false,
        };
      }

      return { loading: false, ...res };
    },

    onchangePage: async () => {
      const { page } = action;
      const { snackbar, keyword } = state;
      const params = {
        code: keyword || null,
        limit: 20,
        offset: page * 20,
      };
      const res = {};
      dispatch("onLoading");

      const dataApi = await getListClaim(systemType, params);

      if (dataApi instanceof Error) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại",
          },
          loading: false,
        };
      }

      if (get(dataApi, "data.statusCode") === 200) {
        res.rows = get(dataApi, "data.result.data") || [];

        res.pageTable = {
          count: Math.ceil((get(dataApi, "data.result.total") || 0) / 20),
          page: page,
          rowsPerPage: 20,
        };
      }

      return { loading: false, ...res };
    },

    closeSnackbar: () => ({ snackbar: { ...state.snackbar, open: false } }),

    onLoading: () => ({ loading: true }),

    //
  };

  return isString(action.type) && cases[action.type];
}
