import React from "react";
import { IconButton } from "@mui/material";

import styleCI from "./styleCI";
import PackageInsurance from "./packageInsurance";
import TableBeneficiary from "./tableBeneficiary";
import InsuranceBenefitTable from "./insuranceBenefitsTable";
import DialogFullWidth from "./DialogMaxWidth";
import TimeRemaining from "./timeRemaining";
import TableSick from "./tableSick";
import zomIcon from "../../../../../../assets/health/zom-icon.svg";

export default function ContractInfomation(props) {
  const classes = styleCI();
  const { state, dispatch } = props;
  const { openDialogFullWidth } = state;

  return (
    <div className={classes.container}>
      <div>
        <div className="title-packege-insurance">Thông tin gói bảo hiểm</div>
        <PackageInsurance {...{ state, dispatch }} />
      </div>
      <div>
        <div className="title-packege-insurance">
          Danh sách người hưởng bảo hiểm
        </div>
        <TableBeneficiary {...{ state, dispatch }} />
      </div>
      <div>
        <div className="title-packege-insurance">Thời gian chờ còn lại</div>
        <TimeRemaining {...{ state, dispatch, name: "time" }} />
      </div>
      <div>
        <div className="title-packege-insurance">Đồng chi trả</div>
        <TimeRemaining {...{ state, dispatch, name: "copay" }} />
      </div>
      <div>
        <div className="title-packege-insurance">Bệnh có sẵn</div>
        <TableSick {...{ state, dispatch, name: "sick-available" }} />
      </div>
      <div>
        <div className="title-packege-insurance">Loại trừ</div>
        <TableSick {...{ state, dispatch, name: "sick-exclude" }} />
      </div>
      <div>
        <div className="title-packege-insurance">
          Bảng quyền lợi bảo hiểm{" "}
          <IconButton onClick={() => dispatch("openDialogFullWidth")}>
            <img src={zomIcon} />
          </IconButton>
        </div>
        <InsuranceBenefitTable {...{ state, dispatch }} />
      </div>
      <DialogFullWidth
        open={openDialogFullWidth}
        onClose={() => dispatch("closeDialogFullWidth")}
        state={state}
        dispatch={dispatch}
      />
    </div>
  );
}
