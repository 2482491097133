import { makeStyles } from "@mui/styles";

export const styledGetInfor = makeStyles(() => ({
  container: {
    background: "#F8F8F8",
    padding: "16px",

    "& .title": {
      marginBottom: "6px",
      color: "#4B4B4B",
    },
  },

  textFields: {
    marginBottom: "12px !important",
  },

  //
}));
