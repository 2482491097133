import { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material';
import {
  useLocation,
  useParams
} from "react-router-dom";

import { Detail } from '../components/business-detail/detail'
import {
  getListClaimFromGlobalCare,
  getInfoCarAccident,
  getListClaimFromProvider,
} from '../../../../apis/carAccidentApis'
import { ModalLoading } from '../../../../ui/modal';
import BusinessReportDetail from '../../providers/page/business-report-detail';


const ClaimDetails = (props) => {
  const { id } = useParams();
  const location = useLocation();
  const [details, setDetails] = useState({})
  const [historyLists, setHistoryLists] = useState([]);
  const [productCode, setProductCode] = useState(null);
  const [render, setRender] = useState(false);

  useEffect(() => {
    fetchData()

    setProductCode(location.pathname?.split('/')[5]);
    setRender(true);

    return () => {
      localStorage.removeItem('desNavbar')
      localStorage.removeItem('titleNavbar')
    }
  }, [])

  const fetchData = () => {
    const user = localStorage.getItem('user') || false
    const isCheckUser = user && user !== 'undefined' && user !== 'null'
    const systemType = (isCheckUser && JSON.parse(user)?.extra_info?.userType) || 0
    if (systemType === 'provider') {
      fetchDetails()
      fetchDataFromProvider()
    }
    if (systemType === 'globalcare') {
      fetchDetails()
      fetchDataFromGlobalcare()
    }
  }

  const fetchDataFromProvider = () => {
    const [certNum, productIdParent] = id.split('_')
    const params = {
      certNum: certNum,
      productId: productIdParent,
      offset: 0,
      limit: 100,
    }
    getListClaimFromProvider(params).then(res => {
      setHistoryLists(res?.data?.result?.data || [])
    })
      .catch(err => {
        console.error(err)
      })
  }

  const fetchDataFromGlobalcare = () => {
    const [certNum, productIdParent] = id.split('_')
    const params = {
      certNum: certNum,
      productId: productIdParent,
      offset: 0,
      limit: 99999999,
    }
    getListClaimFromGlobalCare(params).then(res => {
      setHistoryLists(res?.data?.result?.data || [])
    })
      .catch(err => {
        console.error(err)
      })
  }

  const fetchDetails = () => {
    const [certNum, productIdParent] = id.split('_')
    const data = {
      "certNum": certNum,
      "productIdParent": productIdParent
    }
    ModalLoading.show()
    getInfoCarAccident(data).then(res => {
      setDetails(res?.data?.result)
      ModalLoading.hide()
    })
      .catch(err => {
        ModalLoading.hide()
      })
  }

  return (
    <>
      {
        render && <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8
          }}
        >
          {
            productCode === 'tomato' && <BusinessReportDetail />
          }
          {
            productCode !== 'tomato' &&  <Detail details={details} historyLists={historyLists} />
          }
        </Box>
      }

    </>
  )
};

export default ClaimDetails
