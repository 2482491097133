import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import wait from "../../../../../../assets/ahamove/wait.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Wait(props) {
  const { dispatch, open } = props;
  const classes = makeStyle();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth={"xs"}
      keepMounted
      onClose={() => dispatch("closeDialogWait")}
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialog}
    >
      <DialogContent style={{ padding: "20px 0" }}>
        <div className={classes.divContent}>
          <img src={wait} alt="wait" width={"40%"} />
          <div className="text">
            <span className="title">
              Vui lòng đợi giấy chứng nhận được tải về
            </span>
            <span className="action">Quá trình này sẽ mất khoảng vài phút</span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
    },
  },

  divContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .text": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
      marginTop: "12px",

      "& .title": {
        fontSize: "15px",
      },

      "& .action": {
        fontSize: "13px",
        color: "#FB7800",
        fontStyle: "italic",
      },
    },
  },
}));
