import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from "@mui/material";
import { isEmpty } from "lodash";
import React from "react";
import { useStyles } from "../useStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddProduct(props) {
  const { open, dispatch, state } = props;
  const { data, product_parent, products } = state;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"sm"}
      onClose={() => dispatch({ type: "onClose", name: "product" })}
      className={classes.addDialog}
    >
      <DialogTitle>Thêm sản phẩm</DialogTitle>
      <DialogContent>
        <div className="label">Sản phẩm</div>
        <Autocomplete
          value={data?.productParent || {}}
          options={product_parent}
          noOptionsText={"Không tìm thấy dữ liệu"}
          getOptionLabel={(i) => i?.product_title || ""}
          renderInput={(params) => (
            <TextField {...params} placeholder="Nhập thông tin" size="small" />
          )}
          onChange={(e, value) =>
            dispatch({
              type: "onSelect",
              name: "productParent",
              value,
            })
          }
        />
        <div className="label">Công ty bảo hiểm</div>
        <Autocomplete
          value={data?.provider || {}}
          options={products}
          noOptionsText={"Không tìm thấy dữ liệu"}
          getOptionLabel={(i) => i?.providerTitle || ""}
          renderInput={(params) => (
            <TextField {...params} placeholder="Nhập thông tin" size="small" />
          )}
          onChange={(e, value) =>
            dispatch({
              type: "onSelect",
              name: "provider",
              value,
            })
          }
        />
        <div className="center">
          <Button
            disabled={isEmpty(data?.productParent) || isEmpty(data?.provider)}
            className="button"
            onClick={() => dispatch("addProduct")}
          >
            Thêm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
