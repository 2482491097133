import { makeStyles } from "@mui/styles";

export const styledShowLicense = makeStyles(() => ({
  container: {
    background: "#F8F8F8",
    padding: "16px",
    "& .title-head": {
      color: "#686868",
    },
  },

  title: {
    fontSize: "18px",
    fontWeight: "700",
    color: "black",
    margin: "12px 0",
  },

  boxListImg: {
    padding: "8px",
    background: "white",
    borderRadius: "10px",
  },

  proveRelationship: {
    color: "#4B4B4B",
    display: "flex",
    padding: "12px 0",
    justifyContent: "space-between",
    cursor: "pointer",
  },

  //
}));
