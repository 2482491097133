import { IconButton, MenuItem, TextField } from "@mui/material";
import moment from "moment";
import React from "react";

import diamond from "../../../../../../assets/health/diamond.svg";
import edit from "../../../../../../assets/health/edit.svg";
import trash from "../../../../../../assets/health/trash.svg";
import { listLicense } from "../../utils";
import styleLicense from "./styleLicense";
// import imageIcons from "../../../../../../assets/health/image-icons.svg";
// import paperclip from "../../../../../../assets/health/paperclip.svg";
import pointer from "../../../../../../assets/health/pointer.svg";

export default function Additionalrecords(props) {
  const { state, dispatch, level_id } = props;
  const classes = styleLicense();
  const {
    additionalLicenseSelect,
    additionalLicenseText,
    listCommentLinceseAdd,
  } = state;

  const listLicenses = [
    ...listLicense.map((i) => {
      return {
        title: i.title,
        typeId: i.typeId,
        typeCode: i.typeCode,
      };
    }),
    {
      title: "Chứng từ chứng minh quan hệ",
      typeId: 517,
      typeCode: "relationship",
    },
  ];

  return (
    <div className={classes.containerAdditional}>
      {listCommentLinceseAdd?.map(
        (item, index) =>
          item?.comments?.length > 0 && (
            <div key={index}>
              <div className={classes.readTime}>
                <div className="time">
                  {moment(item?.created_date).format("HH:mm DD/MM/YYYY")}
                </div>
              </div>
              {item?.comments?.map((i, indx) => (
                <div className={classes.comment} key={i?.typeId}>
                  <div className="item-comment">
                    <div className="icon-comment">
                      <img src={diamond} />
                    </div>
                    <div className="lable-comment">
                      <div className="title-license-type">{i?.title || ""}</div>
                      <div className="comment-license-type">
                        <div className="content-comment">{i?.text || ""}</div>
                        {item?.isNewCmt && level_id === 1 && (
                          <div className="icons-edit-comment">
                            <IconButton
                              onClick={() =>
                                dispatch({
                                  type: "editComment",
                                  index: indx,
                                  item: i,
                                })
                              }
                            >
                              <img src={edit} />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                dispatch({ type: "deleteComment", index: indx })
                              }
                            >
                              <img src={trash} />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
      )}
      <div
        className={
          level_id === 1
            ? classes.optionsComment
            : `${classes.optionsComment} disabled`
        }
      >
        <span className="title-span">Loại chứng từ</span>
        <TextField
          size="small"
          select
          disabled={level_id !== 1}
          className={classes.selectter}
          value={additionalLicenseSelect || ""}
          onChange={(e) =>
            dispatch({
              type: "onChangeLicenseAdd",
              value: e.target.value,
              name: "additionalLicenseSelect",
            })
          }
        >
          {listLicenses.map((item, index) => (
            <MenuItem key={index} value={item.typeId}>
              {item.title}
            </MenuItem>
          ))}
        </TextField>
        {/* <IconButton disabled={level_id !== 1} style={{ padding: "5px" }}>
          <img src={imageIcons} />
        </IconButton>
        <IconButton disabled={level_id !== 1} style={{ padding: "5px" }}>
          <img src={paperclip} />
        </IconButton> */}
      </div>
      <div className={classes.inputComment}>
        <TextField
          multiline
          className="textFiled"
          fullWidth
          disabled={level_id !== 1}
          placeholder="nhập nội dung"
          value={additionalLicenseText || ""}
          onChange={(e) =>
            dispatch({
              type: "onChangeLicenseAdd",
              value: e.target.value,
              name: "additionalLicenseText",
            })
          }
          InputProps={{
            endAdornment: (
              <IconButton
                disabled={!additionalLicenseText}
                onClick={() => {
                  dispatch("addCommentLicense");
                }}
              >
                <img src={pointer} />
              </IconButton>
            ),
          }}
        />
      </div>
    </div>
  );
}
