import api from '../configs/api';

function uploadImage(data) {
  return api.apiForm_formData('POST', '/api/v1/document/upload/cdn', data);
};

function uploadBase64(data) {
  return api.apiForm('POST', '/api/v1/document/upload/cdn/image-base64', data);
};

export {
  uploadImage,
  uploadBase64
};
