import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
} from "@mui/material";
import NumberFormat from "react-number-format";

import { TextField } from "../../overrideMui";
import styleClaimLicense from "./styleClaimLicense";

export default function ExpenseTable(props) {
  const { state, dispatch, name, level_id } = props;
  const classes = styleClaimLicense();
  const {
    rowsExaminationApprove,
    rowsBillsApprove,
    rowsMedicinesApprove,
    approveExaminationFeesTotal,
    approveBillFeesTotal,
    approveMedicineFeesTotal,
  } = state;

  const getRowsandFeed = () => {
    switch (name) {
      case "examination":
        return {
          rows: rowsExaminationApprove,
          approveFeesTotal: approveExaminationFeesTotal,
        };
      case "bill":
        return {
          rows: rowsBillsApprove,
          approveFeesTotal: approveBillFeesTotal,
        };
      case "medicine":
        return {
          rows: rowsMedicinesApprove,
          approveFeesTotal: approveMedicineFeesTotal,
        };
    }
  };

  // const rows =
  //   name === "examination" ? rowsExaminationApprove : rowsMedicinesApprove;
  //medicine

  return (
    <div className={classes.div}>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <Table className={classes.table}>
          <TableHead className={classes.header}>
            <TableRow className={classes.headerRow}>
              <TableCell>
                {name === "examination" ? "Tên dịch vụ" : "Tên thuốc"}
              </TableCell>
              <TableCell className="refuse">Từ chối</TableCell>
              <TableCell className="reason-expense-cell">Lý do </TableCell>
              <TableCell className="fees">Tổng chi phí</TableCell>
              <TableCell className="approved">BH khác</TableCell>
              <TableCell className="approved">Được duyệt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {getRowsandFeed().rows?.map((item, rowIdx) => {
              return item?.isParent ? (
                <TableRow
                  key={rowIdx}
                  className={`${classes.cellRow} ${classes.rowColap}`}
                >
                  <TableCell
                    colSpan={2}
                    className={
                      item?.errorFeedApprove ? `cell-colap error` : `cell-colap`
                    }
                  >
                    <div
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingLeft: "12px",
                      }}
                    >
                      <span style={{ marginRight: "12px" }}>
                        {item?.codeBenefit || ""}
                      </span>
                      <span>{item?.titleBenefit || ""}</span>
                    </div>
                  </TableCell>
                  <TableCell className="cell-colap"></TableCell>
                  <TableCell
                    className={
                      item?.errorFeedApprove
                        ? "fees cell-colap error"
                        : "fees cell-colap"
                    }
                  >
                    {`${item?.totalFeeCodeBenefit || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    )}
                  </TableCell>
                  <TableCell
                    className={
                      item?.errorFeedApprove
                        ? "approve-fees cell-colap error"
                        : "approve-fees cell-colap"
                    }
                  >
                    {`${item?.totalPaidFee || "0"}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    )}
                  </TableCell>
                  <TableCell
                    className={
                      item?.errorFeedApprove
                        ? "approve-fees cell-colap error"
                        : "approve-fees cell-colap"
                    }
                  >
                    {`${item?.totalFeeCodeBenefitApprove || "0"}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={rowIdx} className={classes.cellRow}>
                  <TableCell className="name">
                    {["examination", "bill"].includes(name)
                      ? item?.serviceName || ""
                      : `${item?.medicineCode || ""} ${
                          item?.medicineName || ""
                        }`}
                  </TableCell>

                  <TableCell className="accept">
                    <Radio
                      disabled={level_id !== 1}
                      className={classes.radio}
                      checked={item?.approveStatus === 0}
                      onClick={() =>
                        dispatch({
                          type: `${name}ChangTableApprove`,
                          index: rowIdx,
                          name: "approveStatus",
                          item,
                          value: item?.approveStatus === 0 ? 1 : 0,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell className="note">
                    <TextField
                      fullWidth
                      disabled={level_id !== 1}
                      className={classes.textField}
                      multiline
                      value={item?.note || ""}
                      onChange={(e) => {
                        dispatch({
                          type: `${name}ChangTableApprove`,
                          name: "note",
                          index: rowIdx,
                          item,
                          value: e.target.value,
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell className="fees">
                    {`${item?.fee == 0 ? item?.fee : item?.fee || ""}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      "."
                    )}
                  </TableCell>
                  <TableCell className="fees">
                    {
                      <NumberFormat
                        disabled={level_id !== 1 || item?.approveStatus === 0}
                        thousandsGroupStyle="thousand"
                        decimalSeparator=","
                        thousandSeparator="."
                        customInput={TextField}
                        className={`textMoney ${classes.textField}`}
                        onChange={(e) => {
                          dispatch({
                            type: `${name}ChangTableApprove`,
                            name: "paidFee",
                            index: rowIdx,
                            item,
                            value: +e.target.value.replace(/\./g, ""),
                          });
                        }}
                        value={
                          item?.paidFee == 0
                            ? item?.paidFee
                            : item?.paidFee || ""
                        }
                        fullWidth
                      />
                    }
                  </TableCell>
                  <TableCell className="fees">
                    {
                      <NumberFormat
                        disabled={level_id !== 1 || item?.approveStatus === 0}
                        thousandsGroupStyle="thousand"
                        decimalSeparator=","
                        thousandSeparator="."
                        customInput={TextField}
                        className={`textMoney ${classes.textField}`}
                        onChange={(e) => {
                          dispatch({
                            type: `${name}ChangTableApprove`,
                            name: "approveFee",
                            index: rowIdx,
                            item,
                            value: +e.target.value.replace(/\./g, ""),
                          });
                        }}
                        value={
                          item?.approveFee == 0
                            ? item?.approveFee
                            : item?.approveFee || ""
                        }
                        fullWidth
                      />
                    }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className={classes.totalFees}>
        <span className="conten">Tổng phí được duyệt</span>
        <span className="fees">
          {/* {name === "examination"
            ? `${approveExaminationFeesTotal || "0"}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                "."
              )
            : `${approveMedicineFeesTotal || "0"}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                "."
              )} */}
          {`${getRowsandFeed().approveFeesTotal || "0"}`.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            "."
          )}
        </span>
      </div>
    </div>
  );
}
