import React, { useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

import useReducer from "../../../../library/hooks/useReducer";
import detailFinaBH365 from "./detailFinaReducer";
import DetailHistory from "../../create/fina//main/detailHistory";
import HeaderBar from "./components/HeaderBar";
import BottomButton from "./components/BottomButton";

const initialState = {
  loading: false,
  historyDestination: {},
  elementsHistory: [],
  showImg: false,
  tabsStatus: 6,
};

export default function DetailFinaBH365() {
  let arr = window.location.search.split("?");
  let params = new URLSearchParams(arr[1]);
  const isApp = true;
  const id = params.get("id");

  const history = useHistory();

  const [state, dispatch] = useReducer({
    reducer: detailFinaBH365,
    name: "detailFinaBH365",
    initialState,
    isApp,
    id,
  });

  const { loading } = state;

  useEffect(() => dispatch("initail"), []);

  return (
    <div style={{ maxWidth: "600px", margin: "auto" }}>
      <HeaderBar
        title={"Bảo hiểm hàng hoá vận chuyển"}
        onPress={() => {
          history.goBack();
        }}
      />
      <div
        style={{
          minHeight: isApp ? "550px" : "600px",
          background: "white",
        }}
      >
        <DetailHistory {...{ state, dispatch, isApp }} />
      </div>
      <BottomButton
        {...{
          title: "Quay lại",
          onClick: () => {
            history.goBack();
          },
        }}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
