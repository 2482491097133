import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { TextField } from "../overrideMui";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogAddRole(props) {
  const { state, dispatch } = props;
  const classes = useMakeStyles();
  const { dialogAddRole } = state;

  return (
    <Dialog
      open={Boolean(dialogAddRole.open)}
      TransitionComponent={Transition}
      onClose={() => dispatch("closeDialogAddRole")}
    >
      <DialogContent>
        <div className={classes.title}>
          {dialogAddRole?.isUpdate ? "Cập nhật nhóm quyền" : "Thêm nhóm quyền"}
        </div>
        <div className={classes.content}>
          <span className="span-title">Tên nhóm quyền</span>
          <TextField
            fullWidth
            className="textfield-input"
            value={dialogAddRole.nameRoleGroup}
            onChange={(e) =>
              dispatch({
                type: "onChangeDialogAddRole",
                name: "nameRoleGroup",
                value: e.target.value,
              })
            }
          />
        </div>
        <div className={classes.buttons}>
          <Button
            className="button-save"
            onClick={() => {
              return dialogAddRole.isUpdate
                ? dispatch("updateGroupRole")
                : dispatch("creatRoleGrounp");
            }}
          >
            Lưu lại{" "}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const useMakeStyles = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
      margin: "24px",
      width: "100%",
    },
  },

  title: {
    fontSize: "15px",
    margin: "16px 0",
    fontWeight: "600",
  },

  content: {
    fontSize: "14px",

    "& .textfield-input": {
      margin: "8px 0",
    },
  },

  buttons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",

    "& .button-save": {
      color: "white",
      background: "#3961A0",
      fontWeight: "500",
      padding: "8px 24px",
    },
  },
}));
