import axios from "axios";
import api from "../configs/api";
import config from "../configs/config";

const getMerchants = (data) =>
  api
    .apiForm("POST", "/api/v2/merchant/list", data)
    .then((res) => res)
    .catch((error) => error);

const getMerchantDetail = (id) =>
  api
    .apiForm("GET", `/api/v2/merchant/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

const getProductParentList = (data) =>
  api
    .apiForm("POST", "/api/v2/product-parent/list", data)
    .then((res) => res)
    .catch((error) => error);

const getProductList = (data) =>
  api
    .apiForm("POST", "/api/v2/product/list", data)
    .then((res) => res)
    .catch((error) => error);

const createMerchants = (data) =>
  api
    .apiForm("POST", "/api/v2/merchant", data)
    .then((res) => res)
    .catch((error) => error);

const updateMerchants = (data) =>
  api
    .apiForm("PUT", "/api/v2/merchant", data)
    .then((res) => res)
    .catch((error) => error);

const addProductMerchant = (data) =>
  api
    .apiForm("POST", "/api/v2/product-merchant", data)
    .then((res) => res)
    .catch((error) => error);

const detailProductMerchant = (data) =>
  api
    .apiForm("POST", `/api/v2/product-merchant/detail`, data)
    .then((res) => res)
    .catch((error) => error);

const getContractList = (data) =>
  api
    .apiForm("POST", `/api/v2/contract/list`, data)
    .then((res) => res)
    .catch((error) => error);

const addContract = (data) =>
  api
    .apiForm("POST", `/api/v2/contract`, data)
    .then((res) => res)
    .catch((error) => error);

const updateContract = (data) =>
  api
    .apiForm("PUT", `/api/v2/contract`, data)
    .then((res) => res)
    .catch((error) => error);

const detailContract = (id) =>
  api
    .apiForm("GET", `/api/v2/contract/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

const addContractByExcel = (data) => {
  return api.apiForm("POST", `/api/v2/contract/import`, data);
};

const uploadImage = (data) => {
  return api.apiForm("POST", "/api/v2/document/upload/cdn", data);
};
export {
  getMerchants,
  getMerchantDetail,
  getProductParentList,
  getProductList,
  createMerchants,
  updateMerchants,
  addProductMerchant,
  detailProductMerchant,
  getContractList,
  addContract,
  updateContract,
  detailContract,
  addContractByExcel,
  uploadImage,
};
