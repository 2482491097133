import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircleIcon from "@mui/icons-material/Circle";
import { IconButton, TableCell } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import logo_defaul from "../../../../assets/health/logo_defaul.svg";
import moment from "moment";

export const columns = [
  {
    label: "",
    headerClass: "STT",
    renderCell: ({ item, event, rowIdx }) => (
      <TableCell
        className={`${Boolean(item.expan) ? "STT expan" : "STT"} ${
          item.child ? "child" : ""
        } `}
      >
        {!item.parentId ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              style={{ padding: "4px" }}
              onClick={event("exPanRows", { item, index: rowIdx })}
            >
              {item.expan ? (
                <KeyboardArrowUpOutlinedIcon fontSize="small" />
              ) : (
                <KeyboardArrowDownIcon fontSize="small" />
              )}
            </IconButton>
          </div>
        ) : null}
      </TableCell>
    ),
  },
  {
    label: "Tên sản phẩm",
    renderCell: ({ item }) => (
      <TableCell
        className={`${item.child ? "child" : ""} ${
          item.headChild ? "headChild" : ""
        }`}
      >
        {item.child ? (
          item.title
        ) : (
          <div>
            <img
              src={item.url_logo ? item.url_logo : logo_defaul}
              width={25}
              height={25}
              style={{ marginRight: "6px" }}
            />{" "}
            {item.product_title || item.title}
          </div>
        )}
      </TableCell>
    ),
  },
  {
    label: "Mã sản phẩm",
    renderCell: ({ item }) => (
      <TableCell
        className={`${item.child ? "child" : ""} ${
          item.headChild ? "headChild" : ""
        }`}
      >
        {item.id}
      </TableCell>
    ),
  },
  {
    label: "Công ty bảo hiểm",
    renderCell: ({ item }) => (
      <TableCell
        className={`${item.child ? "child" : ""} ${
          item.headChild ? "headChild" : ""
        }`}
      >
        {item.child
          ? +item.fees > 0
            ? `${item.fees || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            : item.fees
          : item.provider_title}
      </TableCell>
    ),
  },
  {
    label: "Số lượng gói",
    renderCell: ({ item }) => (
      <TableCell
        className={`${item.child ? "child" : ""} ${
          item.headChild ? "headChild" : ""
        }`}
      >
        {item.child
          ? +item.product_amount > 0
            ? `${item.product_amount || 0}`?.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                "."
              )
            : item.product_amount
          : item.number_of_packages}
      </TableCell>
    ),
  },
  {
    label: "Ngày tạo",
    renderCell: ({ item }) => (
      <TableCell
        className={`${item.child ? "child" : ""} ${
          item.headChild ? "headChild" : ""
        }`}
      >
        {item.headChild
          ? item.created_date
          : item.created_date
          ? moment(item.created_date).format("DD/MM/YYYY")
          : ""}
      </TableCell>
    ),
  },
  {
    label: "Người tham gia",
    headerClass: "participant",
    renderCell: ({ item }) => (
      <TableCell
        className={`${item.child ? "child" : ""} ${
          item.headChild ? "headChild" : ""
        } participant`}
      >
        {item.participants}
      </TableCell>
    ),
  },
  {
    isGC: true,
    label: "Quản lý bởi",
    headerClass: "participant",
    renderCell: ({ item }) => (
      <TableCell
        className={`${item.child ? "child" : ""} ${
          item.headChild ? "headChild" : ""
        } participant`}
      >
        {item.headChild ? "" : item.organization_title}
      </TableCell>
    ),
  },

  {
    label: "Trạng thái",
    headerClass: "status",
    renderCell: ({ item }) => (
      <TableCell
        className={`${item.child ? "child" : ""} ${
          item.headChild ? "headChild" : ""
        } status`}
      >
        {item.headChild ? (
          item.status
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircleIcon
              fontSize="small"
              style={{ width: "16px" }}
              sx={{ color: !!item.status ? "#069D27" : "#CFCFCF" }}
            />
          </div>
        )}
      </TableCell>
    ),
  },
  {
    label: "Xem",
    headerClass: "show-icon sticky",
    renderCell: ({ item, data }) => (
      <TableCell className={`${item.child ? "child" : ""} show-icon sticky`}>
        {!item.child ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() =>
                data?.history.push(
                  `/admin/product-management/detail/${item.id}`
                )
              }
            >
              <MoreHorizIcon fontSize="small" />
            </IconButton>
          </div>
        ) : null}
      </TableCell>
    ),
  },
];
