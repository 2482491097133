import MuiTextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const TextField = styled(MuiTextField)({
  "& .MuiInputBase-input": {
    padding: "12px 13px",
    background: "white",
    borderRadius: "12px",
  },

  "& fieldset": {
    borderRadius: "16px",
    border: "1px solid #A0A0A0",

    "& legend": {
      fontSize: "14px",
    },
  },

  "& .MuiFormLabel-root": {
    fontSize: "18px",
    color: "#3961A0",
  },
});

export default TextField;
