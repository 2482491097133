import { getExtension } from "../utils";
import file from "../assets/file.svg";
import download from "../assets/download-file.svg";
import { makeStyles } from "@mui/styles";

export default function FileItem({ item, dispatch }) {
  const classes = useStyle();
  const url = item?.path || "";

  return (
    <div className={classes.container}>
      <div className="row">
        <div
          className="row"
          onClick={() =>
            getExtension(item?.path) !== "pdf"
              ? dispatch({ type: "showEvidenceImg", file: item })
              : window.open(url, "_blank")
          }
        >
          <img src={file} alt="file" style={{ marginRight: "6px" }} />
          <div className="name">
            <div>{item?.originalname || ""}</div>
            <div className="size">{item?.size || ""}</div>
          </div>
        </div>
        <img
          src={download}
          alt="download"
          className="icon"
          onClick={() =>
            dispatch({
              type: "downloadFile",
              path: item?.path,
              name: item?.originalname,
            })
          }
        />
      </div>
    </div>
  );
}
const useStyle = makeStyles(() => ({
  container: {
    background: "#fff",
    borderBottom: "1px solid #EDEDED",
    padding: "10px",
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .name:hover": {
      color: "#2A3790",
      fontWeight: 500,
      cursor: "pointer",
    },
    "& .size": {
      fontSize: 12,
    },
    "& .icon": {
      cursor: "pointer",
    },
  },
}));
