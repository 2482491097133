import React from "react";
import moment from "moment";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, MenuItem } from "@mui/material";
import { DatePicker } from "antd";

import { TextField } from "../overrideMui";
import imgSearch from "../../../../../assets/health/imgSearch.svg";

export default function HeaderSearchRecords(props) {
  const { dataSearch, dispatch, classes, role_type } = props;

  const inputsSearch = [
    {
      name: "code",
      label: "Mã hồ sơ bồi thường",
      placeholder: "Nhập thông tin",
    },
    { name: "insuredName", label: "Họ và tên", placeholder: "Nhập thông tin" },
    {
      name: "insuredPhone",
      label: "Số điện thoại",
      placeholder: "Nhập thông tin",
    },
    {
      name: "insuredPrivateId",
      label: "CMND/CCCD",
      placeholder: "Nhập thông tin",
    },
  ];
  const inputsDate = [
    { name: "from", label: "Từ ngày" },
    { name: "to", label: "Đến ngày" },
  ];

  const selectStatus =
    role_type === "claim_report"
      ? [
          { name: "Tất cả", statusId: 0, statusIds: [] },
          { name: "Đang xử lý", statusId: 1, statusIds: [1, 2, 3, 4] },
          { name: "Đồng ý bồi thường", statusId: 2, statusIds: [5, 6, 9] },
          { name: "Từ chối bồi thường", statusId: 3, statusIds: [7, 8, 10] },
        ]
      : [
          { name: "Tất cả", statusId: 0 },
          { name: "Đang xử lý", statusId: 2 },
          { name: "Chờ phê duyệt", statusId: 3 },
          { name: "Bổ sung hồ sơ", statusId: 4 },
          { name: "Đã phê duyệt", statusId: 5 },
          { name: "Chấp nhận bồi thường", statusId: 9 },
          { name: "Hoàn thành", statusId: 6 },
          { name: "Từ chối", statusId: 7 },
        ];

  return (
    <div className={classes.containerSearch}>
      <div className="header-title">Tra cứu hồ sơ bồi thường</div>
      <Grid container className={classes.cardSearch}>
        <Grid xs={12} md={2}>
          <div className="img-search">
            <img src={imgSearch} alt="imgSearch" />
          </div>
        </Grid>
        <Grid
          container
          xs={12}
          md={10}
          spacing={2}
          className={classes.gridItemContainer}
        >
          {inputsSearch.map((i, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <TextField
                autoComplete="new-password"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    dispatch("submitSearch");
                  }
                }}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeSearch",
                    value: e.target.value,
                    name: i.name,
                  })
                }
                placeholder={i.placeholder}
                value={dataSearch[i.name] || ""}
                label={i.label}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          ))}
          {inputsDate.map((i, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <div style={{ position: "relative", width: "100%" }}>
                <div style={Styles.titleInput}>{i.label}</div>
                <div>
                  <DatePicker
                    value={
                      dataSearch[i.name] ? moment(dataSearch[i.name]) : null
                    }
                    onChange={(e) =>
                      dispatch({
                        type: "onChangeSearch",
                        value: e?.format() || null,
                        name: i.name,
                      })
                    }
                    allowClear={true}
                    style={{
                      ...Styles.input,
                      paddingTop: !dataSearch[i.name] ? 11 : 13,
                      paddingBottom: !dataSearch[i.name] ? 8 : 10,
                    }}
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                  />
                </div>
              </div>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              value={dataSearch?.statusId || 0}
              select
              onChange={(e) => {
                dispatch({
                  type: "onChangeSearch",
                  value: e.target.value,
                  name: "statusId",
                });
              }}
              label="Trạng thái"
              InputLabelProps={{ shrink: true }}
            >
              {selectStatus.map((item, key) => (
                <MenuItem key={key} value={item.statusId}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              value={dataSearch?.userFullnameReview || ""}
              onChange={(e) => {
                dispatch({
                  type: "onChangeSearch",
                  value: e.target.value,
                  name: "userFullnameReview",
                });
              }}
              placeholder="Nhập thông tin"
              label="Nhân sự xử lý hồ sơ"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Button
              onClick={() => dispatch("submitSearch")}
              className={classes.buttonSearch}
            >
              Tra cứu
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const Styles = {
  titleInput: {
    zIndex: 4,
    top: "-12px",
    left: 3,
    position: "absolute",
    background: "#ffffff",
    marginLeft: 8,
    width: "fit-content",
    fontWeight: 500,
    padding: "0px 5px",
    fontSize: 15,
    color: "#3961A0",
  },
  input: {
    fontSize: 16,
    boxShadow: "none",
    borderRadius: 16,
    width: "100%",
    border: "1px solid #A0A0A0",
  },
};
