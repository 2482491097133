import { IconButton, TableCell } from "@mui/material";
import moment from "moment";

import downloadIcon from "../../../../assets/health/download-icon.svg";
import { getStatusById } from "../../claim-management/health/utils";
import { get, isEmpty } from "lodash";

const columns = [
  {
    label: "STT",
    headerClass: "stt",
    key: "name",
    renderCell: ({ rowIdx }) => (
      <TableCell className="stt">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "Họ và tên",
    headerClass: "full-name",
    renderCell: ({ item }) => (
      <TableCell className="full-name">
        {item?.insuredInfo?.insuredName || ""}
      </TableCell>
    ),
  },
  {
    label: "Mã HS bồi thường",
    headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">{item.code}</TableCell>
    ),
  },
  {
    label: "Ngày tạo hồ sơ",
    renderCell: ({ item, data }) => {
      let check = false;
      if (item?.statusId == 2) {
        const dayItem = item?.createdDate;
        check = moment().diff(moment(dayItem), "minutes") > 719;
      }
      if ([3, 4].includes(item?.statusId)) {
        const updatedDate = item?.updatedDate;
        check = moment().diff(moment(updatedDate), "minutes") > 1439;
      }

      return (
        <TableCell
          style={{ color: check ? "red" : "" }}
          title={
            item.createdDate
              ? moment(item.createdDate).format("HH:mm DD/MM/YYYY")
              : ""
          }
        >
          {item.createdDate
            ? moment(item.createdDate).format("DD/MM/YYYY")
            : ""}
        </TableCell>
      );
    },
  },
  {
    label: "Ngày cập nhật",
    renderCell: ({ item }) => (
      <TableCell
        title={
          item.updatedDate
            ? moment(item.updatedDate).format("HH:mm DD/MM/YYYY")
            : ""
        }
      >
        {item.updatedDate
          ? moment(item.updatedDate).format("DD/MM/YYYY")
          : "--"}
      </TableCell>
    ),
  },
  {
    label: "Số tiền yêu cầu",
    renderCell: ({ item }) => (
      <TableCell>
        {`${item.requestAmount || "0"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          " VNĐ"}
      </TableCell>
    ),
  },
  {
    label: "Số tiền đã duyệt",
    renderCell: ({ item }) => (
      <TableCell>
        {[5, 6, 8, 9].includes(item?.statusId)
          ? `${item.amount || "-"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            " VNĐ"
          : "- VNĐ"}
      </TableCell>
    ),
  },
  {
    label: "Trạng thái",
    renderCell: ({ item, data }) => (
      <TableCell
        style={{
          fontWeight: "500",
          color:
            data.role_type == "claim_report"
              ? getStatusById(item.statusId).colorReport
              : getStatusById(item.statusId).color,
        }}
      >
        {data.role_type == "claim_report"
          ? getStatusById(item.statusId).labelReport
          : getStatusById(item.statusId).label}
      </TableCell>
    ),
  },
  {
    label: "Nhân sự xử lý hồ sơ",
    renderCell: ({ item, data, event, rowIdx }) => (
      <TableCell className="reviewer">
        <div>
          <span style={{ marginRight: "12px" }}>
            {item.userFullnameReview || ""}
          </span>
        </div>
      </TableCell>
    ),
  },
  {
    label: "Nhân sự phê duyệt",
    renderCell: ({ item, data, rowIdx, event }) => (
      // <TableCell>{item.userFullnameAssign || ""}</TableCell>
      <TableCell className="reviewer">
        <div>
          <span style={{ marginRight: "12px" }}>
            {item.userFullnameAssign || ""}
          </span>
        </div>
      </TableCell>
    ),
  },
  {
    label: "Tải hồ sơ",
    headerClass: "view-records stickey",
    renderCell: ({ item, data, event }) => {
      return (
        <TableCell className="view-records stickey">
          <IconButton onClick={event("onClickDowFile", { item })}>
            <img src={downloadIcon} />
          </IconButton>
        </TableCell>
      );
    },
  },
];

export default columns;
