import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";
import { Col, Row, Modal, Button } from "antd";

import {
  Box,
  Grid,
  Link,
  Typography,
  Dialog,
  DialogContent,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";

import CTCheckbox from "../../components/layout/CTCheckBox";
import { Wrapper, ContentWrapper, Content } from "../../../carAccident/styles";
import HeaderBar from "../../components/layout/HeaderBar";
import useWindowDimensions from "../../../../../../utils/WindowDimensions";
import NextButton from "../../components/layout/NextButton";
import { createTomato } from "../../../../../../apis/claimApis";
import { uploadBase64 } from "../../../../../../apis/uploadApis";
import {
  CLAIM_TOMATO,
  CREATE_DETAIL_CLAIM_FILE_TOMATO,
} from "../../../../../../configs/routesConfig";
import { formatCurrency, formatDateDMY } from "../../../../../../utils/utils";
import BoxImage from "../../components/createDetailClaimFile/BoxImage";
import { getTypeInsu } from "../../components/createDetailClaimFile/until";
import {
  accidentTypes,
  accidentLevel,
  treatmentTypes,
} from "../../components/createDetailClaimFile/until";
import TomatoArccodion from "../../components/TomatoArccodion";
import moment from "moment";
import noImage from "../../../../../../assets/icons/noImage.svg";
import successIcon from "../../../../../../assets/icons/successIcon.svg";

const dataTemps = [
  { title: "Họ tên", value: "Nguyễn Văn A", name: "name" },
  { title: "Năm sinh", value: "01/01/1997", name: "birthday" },
  {
    title: "CMND/CCCD/Hộ chiếu",
    value: "123456678",
    name: "citizen_identification",
  },
  { title: "Số điện thoại", value: "0912345678", name: "phone" },
  { title: "Email", value: "Nguyenvana@gmail.com", name: "email" },
  { title: "Địa chỉ", value: "abc lý chính thắng", name: "address" },
];

const getNameValue = (array, value) => {
  const temp = array.find((item) => item.value === value);
  return temp?.name;
};

export default function ShowDetail() {
  const location = useLocation();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { paramsData, itemContract } = location.state;
  const refSignatureCanvas = useRef("");
  const [data, setData] = useState(paramsData);
  const { insuredInfo, extraInfo } = data;
  const [agree, setAgree] = useState(false);
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [onLoading, setLoading] = useState(false);

  const bone =
    ["life", "work accident", "traffic accident"].includes(
      _.get(extraInfo, "accident.type")
    ) && _.get(insuredInfo, "treatmentType") === "outpatient";

  const onSubmit = async () => {
    if (!insuredInfo?.signature) {
      setErrors({ ...errors, signature: true });
    }

    if (!agree) {
      setErrors({ ...errors, agree: true });
    }

    if (!agree || !insuredInfo?.signature) {
      return;
    }

    setDisabled(true);
    const images = { ...data?.insuredInfo?.images };
    if (!images?.converted) {
      delete data?.insuredInfo?.image;
      Object.keys(images).forEach((key) => {
        let temp = [];
        temp.push({
          createdDate: moment().format("DD/MM/YYYY"),
          name: key,
          links: images[key],
          id: key + moment().unix(),
          comment: null,
        });
        images[key] = temp;
      });
      images.converted = true;
    }

    const insured = JSON.parse(localStorage.getItem("insured"));

    data.productId = itemContract.productId;
    data.providerId = itemContract.providerId;
    data.insuredInfo.images = images;
    data.insuredInfo.insuredName = insured?.name;
    data.insuredInfo.insuredPrivateId = insured?.citizen_identification;
    data.insuredInfo.insuredPhoneNumber = insured?.phone;
    data.insuredInfo.insuredDob = insured?.birthday;
    data.insuredInfo.customerId = insured?.customerId;

    const relationShip = _.get(extraInfo, "beneficiary.relationship");

    data.extraInfo.relationship =
      relationShip === "couple"
        ? "Vợ/Chồng"
        : relationShip === "other"
        ? "Khác"
        : "Cha mẹ/Con cái";

    const dataAPi = await createTomato(data);
    localStorage.removeItem("order");
    localStorage.removeItem("insured");
    const id = _.get(dataAPi, "data.result.id");
    setDisabled(false);
    setOpen(true);
    //history.push(CLAIM_TOMATO);
  };

  const closeAlert = () => {
    setOpen(false);
    history.push(CLAIM_TOMATO);
  };

  const updateFilr = async () => {
    const url = refSignatureCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    let params = [
      {
        document: url,
      },
    ];

    setLoading(true);
    const dataImg = await uploadBase64(params)
      .then(async (res) => {
        return res.data.result[0].path;
      })
      .catch((err) => {
        console.error("err", err);
      });
    setLoading(false);
    _.set(data, ["insuredInfo", "signature"], dataImg);
  };

  const onClearSign = (e) => {
    _.set(data, ["insuredInfo", "signature"], null);
    setData({ ...data });
    refSignatureCanvas?.current?.clear();
  };

  useEffect(() => {
    _.set(data, ["insuredInfo", "signature"], null);
    window.scrollTo(0, 0);
  }, []);

  const {
    animalBitesBoarding,
    animalBitesOutpatient,
    animalBitesDead,
    animalBitesPermanentDisability,
    brunedTemporaryInjury,
    brunedDead,
    brunedPermanentDisability,
    lifeOutpatientBone,
    lifeOutpatient,
    lifeBoarding,
    lifePermanentDisability,
    lifeOutpatientPermanentDisability,
    lifeOutpatientPermanentDisabilityBone,
    lifeBoardingPermanentDisability,
    lifeDead,
    workAccidentTemporaryInjuryBone,
    workAccidentTemporaryInjury,
    workAccidentTemporaryInjuryBoarding,
    workAccidentPermanentDisabilityOutpatient,
    workAccidentPermanentDisabilityOutpatientBone,
    workAccidentPermanentDisabilityOutpatientNoBone,
    workAccidentPermanentDisabilityBoarding,
    workAccidentDead,
    trafficOutpatientTemporaryInjury,
    trafficOutpatientTemporaryInjuryBone,
    trafficOutpatientTemporaryInjuryNoBone,
    trafficBoardingTemporaryInjury,
    trafficPermanentDisabilityOutpatient,
    trafficPermanentDisabilityBoarding,
    trafficPermanentDisabilityOutpatientBone,
    trafficPermanentDisabilityOutpatientNoBone,
    trafficDead,
  } = getTypeInsu(insuredInfo, extraInfo);

  const isDead = _.get(extraInfo, "accident.level") === "dead";

  return (
    <Wrapper>
      <HeaderBar
        title="Thông tin hồ sơ bồi thường"
        background="white"
        onPress={() =>
          history.push(CREATE_DETAIL_CLAIM_FILE_TOMATO + location.search, {
            itemContract: itemContract,
            paramsData: paramsData,
          })
        }
      />
      <ContentWrapper style={{ backgroundColor: "white", marginTop: 10 }}>
        <Content>
          <Row justify="center">
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <div style={formInfo}>
                <TomatoArccodion
                  title="Thông tin hợp đồng"
                  expanded="true"
                  halfShow={true}
                >
                  <Box sx={arcdContent}>
                    <RenderRow
                      title="Tổng quyền lợi bảo hiểm"
                      value={
                        (itemContract?.price > 0
                          ? formatCurrency(
                              itemContract?.total_insurance_benefits
                            )
                          : 0) + " VNĐ"
                      }
                    />
                    <RenderRow
                      title="Tổng phí bảo hiểm"
                      value={formatCurrency(itemContract?.price) + " VNĐ"}
                    />
                    {dataTemps.map((item) => (
                      <RenderRow
                        title={item?.title || ""}
                        value={itemContract?.[item?.name] || ""}
                      />
                    ))}
                  </Box>
                </TomatoArccodion>
                <div style={claimFile}></div>

                <TomatoArccodion title="Hồ sơ bồi thường" expanded="true">
                  <Box sx={arcdContent}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography className="section-header">
                        THÔNG TIN HỒ SƠ
                      </Typography>
                    </Grid>
                    <RenderRow
                      title="Loại tai nạn"
                      value={
                        getNameValue(
                          accidentTypes,
                          _.get(extraInfo, "accident.type")
                        ) || ""
                      }
                    />
                    {_.get(extraInfo, "accident.type") === "traffic accident" &&
                      _.get(extraInfo, "accident.level") !== "dead" && (
                        <RenderRow
                          title="Điều khiển xe trên 50cc"
                          value={
                            _.get(extraInfo, "accident.bikeType") === "yes"
                              ? "Có"
                              : "Không"
                          }
                        />
                      )}

                    <RenderRow
                      title="Mức độ tai nạn"
                      value={
                        getNameValue(
                          accidentLevel,
                          _.get(extraInfo, "accident.level")
                        ) || ""
                      }
                    />

                    {!isDead && (
                      <RenderRow
                        title="Loại hình điều trị"
                        value={
                          getNameValue(
                            treatmentTypes,
                            _.get(insuredInfo, "treatmentType")
                          )
                            ? getNameValue(
                                treatmentTypes,
                                _.get(insuredInfo, "treatmentType")
                              ) +
                              (_.get(extraInfo, "accident.relate") === "bone" &&
                              bone
                                ? ", liên quan đến xương"
                                : _.get(extraInfo, "accident.relate") !==
                                    "bone" && bone
                                ? ", không liên quan đến xương"
                                : "")
                            : ""
                        }
                      />
                    )}
                    {_.get(insuredInfo, "treatmentType") === "boarding" &&
                      !isDead && (
                        <>
                          <RenderRow
                            title="Ngày nhập viện"
                            value={
                              formatDateDMY(
                                _.get(insuredInfo, "hospitalizeDate")
                              ) || ""
                            }
                          />
                          <RenderRow
                            title="Ngày xuất viện"
                            value={
                              formatDateDMY(
                                _.get(insuredInfo, "dischargeDate")
                              ) || ""
                            }
                          />
                        </>
                      )}
                    {/* <RenderRow
                      title="Bệnh viện điều trị"
                      value={_.get(insuredInfo, "dischargeDate") || ""}
                    /> */}
                    <RenderRow
                      title="Ngày tai nạn"
                      value={
                        formatDateDMY(_.get(insuredInfo, "accidentDate")) || ""
                      }
                    />
                    <RenderRow
                      title="Nơi xảy ra tai nạn"
                      value={_.get(insuredInfo, "accidentAt") || ""}
                    />
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>Diễn biến tai nạn</Typography>
                      <Typography className="textarea" maxHeight={150} overflow={'auto'}>
                        {_.get(insuredInfo, "discription") || ""}
                      </Typography>
                    </Grid>
                    <Typography>Nơi điều trị</Typography>
                    <Typography className="textarea" maxHeight={150} overflow={'auto'}>
                      {_.get(insuredInfo, "text") || ""}
                    </Typography>
                    {!isDead && (
                      <RenderRow
                        title="Tổng chi phí điều trị"
                        value={
                          _.get(insuredInfo, "treatmentPrice")
                            ? formatCurrency(
                                _.get(insuredInfo, "treatmentPrice")
                              ) + " VNĐ"
                            : ""
                        }
                      />
                    )}
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>Chẩn đoán bệnh</Typography>
                      <Typography className="textarea" maxHeight={150} overflow={'auto'}>
                        {_.get(insuredInfo, "diagnostic") || ""}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      sx={{ marginBottom: "-16px" }}
                    >
                      <Typography className="section-header">
                        CHỨNG TỪ
                      </Typography>
                    </Grid>
                    <div>
                      <Titiel400
                        blue={true}
                        title="Hình chụp CMND/CCCD/Passport mặt trước và sau"
                      />
                      <Grid container>
                        {(_.get(insuredInfo, "images.id") || [noImage]).map(
                          (item) => (
                            <BoxImage isShow isMargin url={item || null} />
                          )
                        )}
                      </Grid>
                    </div>
                    {(!!trafficOutpatientTemporaryInjury ||
                      !!trafficBoardingTemporaryInjury ||
                      !!trafficPermanentDisabilityOutpatient ||
                      !!trafficPermanentDisabilityBoarding) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Hình chụp giấy phép lái xe và giấy tờ xe (cavet xe)"
                        />
                        <Grid container>
                          {(
                            _.get(insuredInfo, "images.license") || [noImage]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!workAccidentTemporaryInjuryBone ||
                      !!workAccidentTemporaryInjury ||
                      !!workAccidentTemporaryInjuryBoarding ||
                      !!workAccidentPermanentDisabilityOutpatient ||
                      !!workAccidentPermanentDisabilityBoarding ||
                      !!trafficOutpatientTemporaryInjury ||
                      !!trafficBoardingTemporaryInjury ||
                      !!trafficPermanentDisabilityOutpatient ||
                      !!trafficPermanentDisabilityBoarding) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản"
                        />
                        <Grid container>
                          {(
                            _.get(insuredInfo, "images.report") || [noImage]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!lifeBoarding ||
                      !!lifeBoardingPermanentDisability ||
                      !!workAccidentTemporaryInjuryBoarding ||
                      !!workAccidentPermanentDisabilityBoarding ||
                      !!trafficBoardingTemporaryInjury ||
                      !!trafficPermanentDisabilityBoarding) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Hình chụp giấy ra viện, đơn thuốc xuất viện"
                        />
                        <Grid container>
                          {(
                            _.get(
                              insuredInfo,
                              "images.prescriptionHospitalCheckout"
                            ) || [noImage]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!lifeOutpatientBone ||
                      !!lifeOutpatient ||
                      !!lifeOutpatientPermanentDisability ||
                      !!workAccidentTemporaryInjuryBone ||
                      !!workAccidentTemporaryInjury ||
                      !!workAccidentPermanentDisabilityOutpatient ||
                      !!trafficOutpatientTemporaryInjury ||
                      !!trafficPermanentDisabilityOutpatient) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh viện/ phòng khám"
                        />
                        <Grid container>
                          {(
                            _.get(insuredInfo, "images.prescription") || [
                              noImage,
                            ]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!lifeOutpatient ||
                      !!workAccidentTemporaryInjury ||
                      !!workAccidentPermanentDisabilityOutpatientNoBone ||
                      !!trafficOutpatientTemporaryInjuryNoBone ||
                      !!trafficPermanentDisabilityOutpatientNoBone) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Phiếu chỉ định của bác sĩ đối với yêu cầu xét nghiệm, chiếu chụp và kết quả chiếu chụp-xét nghiệm"
                        />
                        <Grid container>
                          {(_.get(insuredInfo, "images.test") || [noImage]).map(
                            (item, index) => (
                              <BoxImage
                                isShow
                                key={index}
                                isMargin={index !== 2}
                                url={item || null}
                              />
                            )
                          )}
                        </Grid>
                      </div>
                    )}
                    {(!!lifeOutpatientBone ||
                      !!lifeOutpatientPermanentDisabilityBone ||
                      !!workAccidentTemporaryInjuryBone ||
                      !!workAccidentPermanentDisabilityOutpatientBone ||
                      !!trafficOutpatientTemporaryInjuryBone ||
                      !!trafficPermanentDisabilityOutpatientBone) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Hình chụp phim XQ, chỉ định chụp XQ; hình chụp phiếu đọc kết quả phim XQ"
                        />
                        <Grid container>
                          {(_.get(insuredInfo, "images.xq") || [noImage]).map(
                            (item, index) => (
                              <BoxImage
                                isShow
                                key={index}
                                isMargin={index !== 2}
                                url={item || null}
                              />
                            )
                          )}
                        </Grid>
                      </div>
                    )}
                    {!!brunedTemporaryInjury && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Hình chụp toa thuốc/ giấy ra viện có ghi chẩn đoán “bỏng”, độ bỏng, diện tích bỏng (nếu có), chữ ký bác sỹ và mộc tròn bệnh viện/ phòng khám"
                        />
                        <Grid container>
                          {(
                            _.get(insuredInfo, "images.diagnose") || [noImage]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!animalBitesPermanentDisability ||
                      !!brunedPermanentDisability ||
                      !!lifeBoardingPermanentDisability ||
                      !!lifePermanentDisability ||
                      !!workAccidentPermanentDisabilityOutpatient ||
                      !!workAccidentPermanentDisabilityBoarding ||
                      !!trafficPermanentDisabilityOutpatient ||
                      !!trafficPermanentDisabilityBoarding) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận nguyên nhân"
                        />
                        <Grid container>
                          {(
                            _.get(
                              insuredInfo,
                              "images.governmentConfirmation"
                            ) || [noImage]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!animalBitesPermanentDisability ||
                      !!brunedPermanentDisability ||
                      !!lifeOutpatientPermanentDisability ||
                      !!lifeBoardingPermanentDisability ||
                      !!workAccidentPermanentDisabilityOutpatient ||
                      !!workAccidentPermanentDisabilityBoarding ||
                      !!trafficPermanentDisabilityOutpatient ||
                      !!trafficPermanentDisabilityBoarding) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Giấy chứng nhận thương tật của cơ quan có thẩm quyền"
                        />
                        <Grid container>
                          {(
                            _.get(insuredInfo, "images.certificateInjury") || [
                              noImage,
                            ]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!animalBitesDead ||
                      !!brunedDead ||
                      !!lifeDead ||
                      !!workAccidentDead ||
                      !!trafficDead) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Biên bản công an điều tra có kết luận nguyên nhân tử vong/xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận nguyên nhân tử vong"
                        />
                        <Grid container>
                          {(
                            _.get(insuredInfo, "images.deadConclusion") || [
                              noImage,
                            ]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!animalBitesDead ||
                      !!brunedDead ||
                      !!lifeDead ||
                      !!workAccidentDead ||
                      !!trafficDead) && (
                      <div>
                        <Titiel400 blue={true} title="Giấy chứng tử" />
                        <Grid container>
                          {(
                            _.get(insuredInfo, "images.deathCertificate") || [
                              noImage,
                            ]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!animalBitesDead ||
                      !!brunedDead ||
                      !!lifeDead ||
                      !!workAccidentDead ||
                      !!trafficDead) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Biên bản phân chia di sản thừa kế có xác nhận của cơ quan chức năng/phường xã"
                        />
                        <Grid container>
                          {(
                            _.get(insuredInfo, "images.inheritanceText") || [
                              noImage,
                            ]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!animalBitesOutpatient || !!animalBitesBoarding) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Hình chụp phiếu chích ngừa/ chỉ định chích ngừa"
                        />
                        <Grid container>
                          {(
                            _.get(insuredInfo, "images.vaccinationCard") || [
                              noImage,
                            ]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {!!animalBitesBoarding && (
                      <div>
                        <Titiel400 blue={true} title="Hình chụp giấy ra viện" />
                        <Grid container>
                          {(
                            _.get(insuredInfo, "images.hospitalCheckout") || [
                              noImage,
                            ]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {!!animalBitesBoarding && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Hình chụp đơn thuốc xuất viện"
                        />
                        <Grid container>
                          {(
                            _.get(
                              insuredInfo,
                              "images.prescriptionCheckout"
                            ) || [noImage]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!animalBitesBoarding ||
                      !!lifeBoarding ||
                      !!lifeBoardingPermanentDisability ||
                      !!workAccidentTemporaryInjuryBoarding ||
                      !!workAccidentPermanentDisabilityBoarding ||
                      !!trafficBoardingTemporaryInjury ||
                      !!trafficPermanentDisabilityBoarding) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Hình chụp bảng kê chi tiết viện phí"
                        />
                        <Grid container>
                          {(
                            _.get(
                              insuredInfo,
                              "images.detailedListHospitalFees"
                            ) || [noImage]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!animalBitesBoarding ||
                      !!lifeBoarding ||
                      !!lifeBoardingPermanentDisability ||
                      !!workAccidentTemporaryInjuryBoarding ||
                      !!workAccidentPermanentDisabilityBoarding ||
                      !!trafficBoardingTemporaryInjury ||
                      !!trafficPermanentDisabilityBoarding) && (
                      <div>
                        <Titiel400
                          blue={true}
                          title="Hình chụp giấy chứng nhận phẫu thuật (nếu có)"
                          unRequier
                        />
                        <Grid container>
                          {((_.get(insuredInfo, "images.surgery") || [])
                            .length < 1
                            ? [noImage]
                            : _.get(insuredInfo, "images.surgery")
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!animalBitesOutpatient || !!animalBitesBoarding) && (
                      <div>
                        <Titiel400 blue={true} title="Hóa đơn chích ngừa" />
                        <Grid container>
                          {(
                            _.get(insuredInfo, "images.vaccinationBill") || [
                              noImage,
                            ]
                          ).map((item, index) => (
                            <BoxImage
                              isShow
                              key={index}
                              isMargin={index !== 2}
                              url={item || null}
                            />
                          ))}
                        </Grid>
                      </div>
                    )}
                    {(!!brunedTemporaryInjury ||
                      !!brunedPermanentDisability ||
                      !!lifeOutpatientBone ||
                      !!lifeOutpatient ||
                      !!lifeBoarding ||
                      !!lifeOutpatientPermanentDisability ||
                      !!lifeBoardingPermanentDisability ||
                      !!workAccidentTemporaryInjuryBone ||
                      !!workAccidentTemporaryInjury ||
                      !!workAccidentTemporaryInjuryBoarding ||
                      !!workAccidentPermanentDisabilityOutpatient ||
                      !!workAccidentPermanentDisabilityBoarding ||
                      !!trafficOutpatientTemporaryInjury ||
                      !!trafficBoardingTemporaryInjury ||
                      !!trafficPermanentDisabilityOutpatient ||
                      !!trafficPermanentDisabilityBoarding) && (
                      <div>
                        <Titiel400 blue={true} title="Hóa đơn điều trị" />
                        <Grid container>
                          {(_.get(insuredInfo, "images.bill") || [noImage]).map(
                            (item, index) => (
                              <BoxImage
                                isShow
                                key={index}
                                isMargin={index !== 2}
                                url={item || null}
                              />
                            )
                          )}
                        </Grid>
                      </div>
                    )}
                    {(!!animalBitesOutpatient ||
                      !!animalBitesBoarding ||
                      !!brunedTemporaryInjury ||
                      !!brunedPermanentDisability ||
                      !!lifeOutpatientBone ||
                      !!lifeBoarding ||
                      !!lifeBoardingPermanentDisability ||
                      !!lifeOutpatient ||
                      !!workAccidentTemporaryInjuryBone ||
                      !!workAccidentTemporaryInjuryBoarding ||
                      !!workAccidentTemporaryInjury ||
                      !!workAccidentPermanentDisabilityOutpatient ||
                      !!workAccidentPermanentDisabilityBoarding ||
                      !!trafficOutpatientTemporaryInjury ||
                      !!trafficBoardingTemporaryInjury ||
                      !!trafficPermanentDisabilityOutpatient ||
                      !!trafficPermanentDisabilityBoarding) && (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        sx={{ marginTop: "10px" }}
                      >
                        <Typography
                          sx={{ opacity: "0.7", color: "#000 !important" }}
                        >
                          Vui lòng đính kèm link hoá đơn điện tử (Nếu có)
                        </Typography>
                        <Typography className="textarea">
                          <a
                            style={{
                              textDecoration: "underline",
                              color: "0B3C8A",
                            }}
                          >
                            {_.get(insuredInfo, "billLink") || ""}
                          </a>
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography className="header">
                        THÔNG TIN NGƯỜI THỤ HƯỞNG
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>Chủ tài khoản</Typography>
                      <Typography>
                        {_.get(extraInfo, "beneficiary.fullname") || ""}
                      </Typography>
                    </Grid>
                    {_.get(paramsData, "extraInfo.beneficiary.type") ===
                      "relative" && (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography>
                          Mối quan hệ với người được bảo hiểm
                        </Typography>
                        <Typography>
                          {_.get(extraInfo, "beneficiary.relationship") ===
                          "couple"
                            ? "Vợ/Chồng"
                            : _.get(extraInfo, "beneficiary.relationship") ===
                              "other"
                            ? "Khác"
                            : "Cha mẹ/Con cái"}
                        </Typography>
                      </Grid>
                    )}
                    {_.get(paramsData, "extraInfo.beneficiary.type") ===
                      "relative" && (
                      <Grid container sx={{ marginBottom: "1rem" }}>
                        <div>
                          <Typography>
                            Chứng từ xác minh quan hệ
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <Grid container>
                            {(
                              _.get(
                                insuredInfo,
                                "images.verifyRelationship"
                              ) || [noImage]
                            ).map((item, index) => (
                              <BoxImage
                                key={index}
                                isShow
                                isMargin={index !== 2}
                                url={item || null}
                              />
                            ))}
                          </Grid>
                        </div>
                      </Grid>
                    )}
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>Số tài khoản</Typography>
                      <Typography>
                        {_.get(extraInfo, "beneficiary.bank.id") || ""}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>Ngân hàng</Typography>
                      <Typography>
                        {_.get(extraInfo, "beneficiary.bank.name") || ""}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>Chi nhánh</Typography>
                      <Typography>
                        {_.get(extraInfo, "beneficiary.bank.branch") || ""}
                      </Typography>
                    </Grid>
                  </Box>
                </TomatoArccodion>
                <Box sx={{ ...contentBox, ...agreementBox }}>
                  <CTCheckbox
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                    style={{ alignItems: "flex-start" }}
                  />
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      textAlign: "justify",
                    }}
                  >
                    Tôi đã đọc kỹ và chấp nhận tất cả{" "}
                    <Link
                      href="https://drive.google.com/file/d/1_5F4wdbwdxXwoauAKgNnwvTjxud5G9hf/view?usp=sharing"
                      underline="always"
                      sx={linkPolicy}
                      target="_blank"
                    >
                      <span>các điều khoản trong Quy tắc Bảo hiểm</span>
                    </Link>{" "}
                    của Công ty bảo hiểm. Tôi cam đoan tất cả các thông tin khai
                    báo ở trên là đúng sự thật và hiểu rằng bất kỳ thông tin nào
                    khai báo không đúng sự thật có thể sẽ dẫn đến việc Giấy
                    chứng nhận bảo hiểm/ Hợp đồng bảo hiểm mất hiệu lực và/hoặc
                    Bảo hiểm Công ty bảo hiểm có quyền từ chối một phần hoặc
                    toàn bộ trách nhiệm bồi thường. Tôi đồng ý sẽ thông báo cho
                    Công ty bảo hiểm về bất kỳ sự thay đổi thông tin nào (nếu
                    có).
                    <br />
                    Tôi đồng ý để cho bác sỹ, bệnh viện hoặc cơ sở y tế đã điều
                    trị hoặc tư vấn sức khoẻ cho tôi được cung cấp cho Công ty
                    bảo hiểm mọi thông tin liên quan đến sức khoẻ của tôi nếu có
                    sự nghi vấn, nhằm đáp ứng các yêu cầu về giải quyết quyền
                    lợi bảo hiểm.
                  </Typography>
                </Box>
                {errors?.agree && (
                  <Box sx={{ marginTop: "12px" }}>
                    <Typography sx={hyperText}>
                      Vui lòng xác nhận đồng ý điều khoản
                    </Typography>
                  </Box>
                )}
                <Box sx={contentBox}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "19px",
                        letterSpacing: "-0.24px",
                        marginBottom: "0.75rem",
                        color: "#0B3C8A",
                      }}
                    >
                      Chữ ký của bạn<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <a
                      style={{ textDecoration: "underline", fontSize: "15px" }}
                      onClick={onClearSign}
                    >
                      Ký lại
                    </a>
                  </div>
                  <div style={signature}>
                    {!_.get(data, "insuredInfo.signature") ? (
                      <div style={{ position: "relative", width: width - 32 }}>
                        <Backdrop
                          style={{
                            zIndex: 99,
                            color: "#fff",
                            position: "absolute",
                            borderRadius: "5px",
                          }}
                          open={onLoading}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                        <SignatureCanvas
                          ref={refSignatureCanvas}
                          penColor="black"
                          canvasProps={{ width: width - 32, height: 168 }}
                          onEnd={() => updateFilr()}
                        />
                      </div>
                    ) : (
                      <img
                        alt="signature"
                        src={_.get(data, "insuredInfo.signature")}
                        style={{ objectFit: "contain" }}
                      />
                    )}
                  </div>
                  {errors?.signature && (
                    <Box sx={{ marginTop: "12px" }}>
                      <Typography sx={hyperText}>
                        Vui lòng ký vào ô trên
                      </Typography>
                    </Box>
                  )}
                </Box>
                <NextButton
                  title="Gửi"
                  onChangNext={onSubmit}
                  disabled={disabled}
                />
              </div>
            </Col>
          </Row>

          <Dialog
            fullWidth
            maxWidth={"xl"}
            open={open}
            onClose={() => setOpen(false)}
            sx={dialog}
          >
            <DialogContent>
              <img src={successIcon} alt="success" />
              <Typography className="alertSuccess">
                Bạn đã gửi hồ sơ thành công
              </Typography>
              <Typography className="textAlert">
                Vui lòng chờ hồ sơ được duyệt
              </Typography>
              <Button
                block
                size="large"
                className="buttonAlert"
                onClick={closeAlert}
              >
                Đóng
              </Button>
            </DialogContent>
          </Dialog>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
}

const RenderRow = (props) => {
  const { title, value } = props;
  return (
    <Grid container direction="row" justifyContent="space-between">
      <Typography>{title}</Typography>
      <Typography maxHeight={150} overflow={'auto'}>{value}</Typography>
    </Grid>
  );
};

const Titiel400 = ({ title, unRequier, unMarginTop, blue = false }) => {
  let titStyle = { ...styleTitle400 };
  if (blue) {
    titStyle.color = "#0B3C8A !important";
  }
  return (
    <Box sx={{ marginBottom: "16px", marginTop: unMarginTop ? 0 : "16px" }}>
      <Typography style={titStyle}>
        {title}
        <span style={{ color: "red", display: unRequier ? "none" : "" }}>
          *
        </span>
      </Typography>
    </Box>
  );
};

const formInfo = {
  margin: "43px 0 8px 0",
};

const dialog = {
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  textAlign: "center",
  borderRadius: 16,
  "& .alertSuccess": {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#416AE1",
    paddingTop: "15px",
  },
  "& .textAlert": {
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    padding: "15px 0",
  },
  "& .buttonAlert": {
    background: "#0B3C8A",
    color: "white",
    borderRadius: 16,
    width: "50%",
  },
};

const hyperText = {
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "16px",
  letterSpacing: "-0.408px",
  color: "#FF2929",
};

const styleTitle400 = {
  fontWeight: "bold",
  fonStize: "15px",
  lineHeight: "19px",
  letterSpacing: "-0.408px",
  color: "#000000",
};

const contentBox = {
  marginTop: "25px",
};

const agreementBox = {
  display: "flex",
  "& > p": {
    fontSize: "13px",
    lineHeight: "22px",
    marginLeft: "0.5rem",
  },
  "& .MuiSvgIcon-root path": {
    color: "#0B3C8A",
  },
};

const claimFile = {
  margin: "28px 0 8px 0",
  fontSize: 20,
  fontWeight: "700",
};

const arcdContent = {
  paddingTop: "8px",
  "& p": {
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19px",
    letterSpacing: "-0.24px",
    marginBottom: "0.75rem",

    "&:first-of-type": {
      color: "#0B3C8A",
    },
    "&.section-header": {
      fontSize: "17px",
      fontWeight: "700",
      lineHeight: "21px",
      color: "#000",
      marginBottom: "1rem",
    },
    "&.header": {
      fontSize: "17px",
      fontWeight: "700",
      lineHeight: "21px",
      color: "#000",
      margin: "18px 0",
    },
    "&.textarea": {
      padding: "0.75rem",
      width: "100%",
      borderRadius: "12px",
      boxSizing: "border-box",
      border: "1px solid rgba(0, 0, 0, 0.17)",
    },
    "span.required": {
      color: "red",
    },
  },
  "& .image-list": {
    marginBottom: "0.75rem",
    "& .MuiImageListItem-root": {
      borderRadius: "13px",
      overflow: "hidden",
    },
  },
  "& .old-file": {
    marginBottom: "0.2rem",
    "& > div": {
      backgroundColor: "#D2E4FF",
      borderRadius: "10px",
      padding: "0.75rem",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "0.5rem",
      "& p": {
        color: "#000",
        marginBottom: "0",
      },
      "& a": {
        color: "#0B3C8A",
        display: "block",
        position: "relative",
        "&::after": {
          content: "' '",
          width: "100%",
          height: "1px",
          backgroundColor: "#0B3C8A",
          position: "absolute",
          top: "92%",
          left: "50%",
          transform: "translateX(-50%)",
        },
      },
    },
    "& > p": {
      fontSize: "13px",
      marginBottom: "0.5rem",
      color: "#000000",
      opacity: "0.6",
      lineHeight: "16px",
    },
  },
};

const signature = {
  width: "100%",
  maxWidth: "500px",
  height: 168,
  border: "1px solid rgba(151, 151, 151, 0.24)",
  borderRadius: 10,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

const linkPolicy = {
  textDecoration: "underline !important",
  "& span": {
    textDecoration: "underline !important",
  },
};
