import React from "react";
import moment from "moment";
import { IconButton } from "@mui/material";

import { detailBLVP } from "../../styles/styleDetailBLVP";

export default function HeaderDetailBLVP(props) {
  const classes = detailBLVP();
  const { state, dispatch } = props;
  const { detail } = state;
  return (
    <div className={classes.containerHeaderDetail}>
      <div className="box-infor">
        <span style={{ color: "#686868" }}>Mã người được BH </span>
        <span
          style={{ color: "#4B4B4B", fontWeight: "600", textAlign: "center" }}
        >
          {detail?.certNum || ""}
        </span>
      </div>
      <div className="border" />
      <div className="box-infor">
        <span style={{ color: "#686868", width: "max-content" }}>
          Ngày tạo hồ sơ{" "}
        </span>
        <span
          style={{ color: "#4B4B4B", fontWeight: "600", textAlign: "center" }}
        >
          {detail?.createdDate
            ? moment(detail?.createdDate).format("DD/MM/YYYY")
            : ""}
        </span>
      </div>
      <div className="border" />
      <div className="box-infor">
        <span style={{ color: "#686868" }}>Nhân sự xử lý </span>
        <span
          style={{ color: "#4B4B4B", fontWeight: "600", textAlign: "center" }}
        >
          {detail?.userFullnameReview || ""}
        </span>
      </div>
      <div className="border" />
      <div className="box-infor">
        <span style={{ color: "#686868" }}>Nhân sự phê duyệt </span>
        <span
          style={{ color: "#4B4B4B", fontWeight: "600", textAlign: "center" }}
        >
          {detail?.userFullnameAssign || ""}
        </span>
      </div>
      <div className="border" />
    </div>
  );
}
