import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    "& .header": {
      fontSize: 16,
      color: "#284078",
      fontWeight: 600,
      margin: "15px 0 10px",
    },
    "& .title": {
      fontSize: 16,
      fontWeight: 600,
      padding: "10px 0 5px",
    },
    "& .rowDiv": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: 4,
      "& .valueSpan": {
        maxWidth: "65%",
        textAlign: "end",
      },
      "& .capitalize": {
        textTransform: "capitalize",
      },
    },
    "& .row": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .box-img": {
      border: "1px solid #ECECEC",
      borderRadius: 8,
      width: "49%",
      paddingTop: 10,
      margin: "4px 0 12px",
      textAlign: "center",

      "& .frame-img": {
        height: "100px",
      },
      "& .img": {
        height: "100%",
        maxWidth: "100%",
      },
      "& .text": {
        marginTop: 5,
        fontSize: 13,
      },
    },
    "& .box-file": {
      border: "1px solid #ECECEC",
      borderRadius: 8,
      margin: "8px 0",
      "& .border-item": {
        borderBottom: "1px solid #E9ECEF",
        margin: "8px 4px 0",
      },
      "& .size": {
        fontSize: 13,
        fontStyle: "italic",
      },
    },
  },
  spacing: {
    height: 5,
    background: "#F8F8F8",
    width: "100vw",
    margin: "15px 0 0px -10px",
  },

  lineSpacing: {
    height: 5,
    background: "#F8F8F8",
    width: "100vw",
    margin: "10px 0 10px -10px",
  },

  loading: {
    textAlign: "center",
    marginTop: 30,
    "& .MuiCircularProgress-root": {
      color: "#284078",
    },
  },

  boxTabs: {
    margin: "-10px",
    "& .MuiTabs-indicator": {
      backgroundColor: "#E16C2B",
    },

    "& .MuiButtonBase-root.MuiTabScrollButton-root": {
      width: "20px",
    },

    "& .MuiButtonBase-root.MuiTab-root": {
      padding: "0px 8px",
      fontSize: "14px",
      textTransform: "none",
      color: "#000000",
    },

    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      color: "#E16C2B",
      fontWeight: "600",
    },
  },
  containImg: {
    margin: "0px -10px",

    "& .titleAccodion": {
      color: "#284078",
      fontSize: "16px",
      fontWeight: "600",
    },

    "& .MuiAccordionDetails-root": {
      padding: "0 16px 16px",
    },

    "& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "40px",

      "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: "8px 0",
      },
    },

    "& .border-none": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiInputBase-multiline": {
        padding: "5px 0 0 10px !important",
        fontSize: 14,
      },
    },
  },

  divImg: {
    width: "100%",
    overflow: "auto",
    "& .title": {
      padding: "6px 0",
      position: "sticky",
      left: 0,
      fontSize: 14,
    },
    "& .upload": {
      padding: 10,
      borderRadius: 8,
      border: "1px dashed #D2D2D2",
      background: "#F8F9FA",
      margin: "8px 0",
      minHeight: 40,
    },
    "& .border": {
      borderBottom: "1px solid #EDEDED",
      padding: "5px 0",
    },
  },

  lineImg: {
    display: "flex",
    width: "fit-content",
    margin: "4px 0px",
  },

  boxImg: {
    margin: "0px 8px 8px 0px",
    border: "1px solid #D2D2D2",
    width: 80,
    height: 100,
    "& .image": {
      width: "100%",
      height: "100%",
    },
  },
}));
