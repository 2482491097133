import React, { useEffect } from "react";
import {} from "@mui/material";

export default function ShowLink(props) {
  const { state, isApp } = props;
  const { indexLink = 0, arrayLinks } = state;
  return (
    <div
      style={{
        minHeight: isApp ? "calc(100vh - 175px)" : "calc(100vh - 133px)",
      }}
    >
      <iframe
        src={`${arrayLinks[indexLink]}`}
        title="url"
        width={"100%"}
        style={{
          height: isApp ? "calc(100vh - 100px)" : "calc(100vh - 60px)",
        }}
      />
    </div>
  );
}
