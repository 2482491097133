import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import TableToExcel from "@linways/table-to-excel";

import useReducer from "../../../library/hooks/useReducer";
import LoadingMui from "../../../ui/LoadingMui";
import Snackbars from "../../../ui/snackbar";
import CommonTable from "../../../library/layout/commonTable";

import HeadFilter from "./components/headFilter";
import { reportStyle } from "./style/styleReport";
import ModalFilterDate from "./components/modalFilterDate";
import { initialState } from "./utils";
import reportReducer from "./reportReducer";
import {
  columnsClaim,
  columnsMerchant,
  columnsProduct,
} from "./components/columnRecords";
import ClaimTableExportExcel from "./components/claimTableExportExcel";
import ProductTableExportExcel from "./components/productTableExportExcel";
import MerchantTableExportExcel from "./components/merchantTableExportExcel";

export default function Report() {
  const classes = reportStyle();
  const userFromProfile = JSON.parse(localStorage.getItem("user")) || {};
  const labelTabs = ["Sản Phẩm", "Doanh Nghiệp", "Bồi Thường"];
  const users = JSON.parse(localStorage.getItem("user")) || {};

  const [state, dispatch, clearStateGlobal] = useReducer({
    initialState,
    reducer: reportReducer,
    name: "reportReducer",
  });

  const {
    loading,
    snackbar,
    tabs,
    pageTable,
    listClaimReport,
    listProductReport,
    listMerchantReport,
    payloadApi,
    listReportExportExcel,
    count,
  } = state;

  useEffect(() => {
    dispatch("initail");

    return clearStateGlobal();
  }, []);

  useEffect(async () => {
    if (count !== 0) {
      const table = () => {
        switch (tabs?.key) {
          case "claim":
            return {
              el: document.getElementById("claim-report-export-data-table"),
              name: `bao-cao-boi-thuong.xlsx`,
            };
          case "product":
            return {
              el: document.getElementById("product-report-export-data-table"),
              name: `bao-cao-san-pham.xlsx`,
            };
          case "merchant":
            return {
              el: document.getElementById("merchant-report-export-data-table"),
              name: `bao-cao-doanh-nghiep.xlsx`,
            };

          default:
            break;
        }
      };
      TableToExcel.convert(table().el, {
        name: table().name,
        sheet: {
          name: "Sheet 1",
        },
      });
    }
  }, [count]);

  const getRowsForTable = () => {
    switch (tabs?.key) {
      case "claim":
        return {
          rows: listClaimReport || [],
          columns: columnsClaim,
          pageTable: pageTable?.claim || {},
        };
      case "product":
        return {
          rows: listProductReport || [],
          columns: columnsProduct,
          pageTable: pageTable?.product || {},
        };
      case "merchant":
        return {
          rows: listMerchantReport || [],
          columns: columnsMerchant,
          pageTable: pageTable?.merchant || {},
        };

      default:
        return { rows: [], columns: [], pageTable: {} };
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>Báo Cáo</div>
      <Tabs
        className={classes.tabsDetail}
        value={tabs?.value}
        onChange={(e, value) => {
          dispatch({ type: "onChangeTabs", value });
        }}
        scrollButtons="auto"
        aria-label="basic tabs example"
      >
        {labelTabs.map((item, index) => (
          <Tab
            label={
              <span style={{ display: "flex", alignItems: "center" }}>
                {item}
              </span>
            }
            key={item}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      <HeadFilter {...{ state, dispatch, userFromProfile }} />
      <CommonTable
        rows={getRowsForTable().rows}
        columns={getRowsForTable().columns}
        classes={classes}
        data={{ users }}
        page={{
          count: getRowsForTable().pageTable?.count || 1,
          page: getRowsForTable().pageTable?.page || 0,
          onChange: (event, page, rowsPerPage) => {
            dispatch({
              type: "onchangePage",
              page,
              rowsPerPage,
            });
          },
        }}
        event={(type, data) =>
          (ele, ...rest) => {
            dispatch({ type, data, ele, rest });
          }}
      />
      <ModalFilterDate {...{ state, dispatch, userFromProfile }} />
      <ClaimTableExportExcel
        from={payloadApi?.claim?.from}
        to={payloadApi?.claim?.to}
        data={listReportExportExcel}
      />
      <ProductTableExportExcel
        from={payloadApi?.product?.from}
        to={payloadApi?.product?.to}
        data={listReportExportExcel}
      />
      <MerchantTableExportExcel
        from={payloadApi?.merchant?.from}
        to={payloadApi?.merchant?.to}
        data={listReportExportExcel}
      />
      <LoadingMui loading={loading} />
      <Snackbars
        open={!!snackbar.open}
        type={snackbar.type}
        onClose={() => dispatch("closeSnackbar")}
        message={snackbar.message}
      />
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
