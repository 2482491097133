import {
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import DialogNoti from "../components/DialogNoti";
import HeadStepper from "../components/HeadStepper";
import { useStyles } from "../styles/stepperStyles";

import clear from "../../../../../assets/loyalty/clear.svg";

function Step3({ state, dispatch }) {
  const { extraInfo, checkedCommit, errors, confirmCreate, loading } = state;
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.container}>
      <HeadStepper activeStep={2} />
      <div className={classes.spacing}></div>
      <div className="header mb-10">THÔNG TIN NGƯỜI THỤ HƯỞNG</div>
      <TextField
        fullWidth
        className={classes.textField}
        label={
          <div>
            Tên tài khoản <span style={{ color: "red" }}>*</span>
          </div>
        }
        defaultValue={"CONG TY CO PHAN DICH VU TUC THOI"}
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        fullWidth
        className={classes.textField}
        label={
          <div>
            Ngân hàng<span style={{ color: "red" }}>*</span>
          </div>
        }
        defaultValue={"Ngân hàng Techcombank - CN Thắng Lợi"}
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        fullWidth
        className={classes.textField}
        label={
          <div>
            Số tài khoản<span style={{ color: "red" }}>*</span>
          </div>
        }
        defaultValue={"19131637055028"}
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        fullWidth
        className={classes.divInput}
        label={
          <div>
            Email<span style={{ color: "red" }}>*</span>
          </div>
        }
        value={extraInfo.beneficiaryEmail || ""}
        onChangeCapture={(e) =>
          dispatch({
            type: "onChangeValue",
            name: "beneficiaryEmail",
            value: e.target.value,
          })
        }
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() =>
                dispatch({
                  type: "onChangeValue",
                  name: "beneficiaryEmail",
                  value: "",
                })
              }
            >
              <img src={clear} alt="clear" width={20} />
            </IconButton>
          ),
        }}
      />
      {errors.includes("email") && (
        <div className={classes.errText}>Vui lòng nhập email</div>
      )}
      {errors.includes("emailType") && (
        <div className={classes.errText}>
          Vui lòng nhập email đúng định dạng
        </div>
      )}

      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            checked={checkedCommit}
            onChange={(e) =>
              dispatch({ type: "checkCommit", value: e.target.checked })
            }
          />
        }
        label={
          <span
            style={{
              color: errors.includes("checkedCommit") ? "red" : "#284078",
              fontSize: 15,
            }}
          >
            Tôi cam kết các thông tin trong yêu cầu bồi thường này là đúng sự
            thật.
          </span>
        }
      />
      <DialogNoti open={confirmCreate} dispatch={dispatch} loading={loading} />
    </div>
  );
}

export default Step3;

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#F5FAF7",
  backgroundImage: "#F5FAF7",
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#E16C2B",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#E16C2B",
  },
});
