import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useReducer from "../../../../library/hooks/useReducer";
import managementReducer from "../useReducer/managementReducer";
import { useStyles } from "../useStyles";
import { formatDate } from "../utils";

import AddIcon from "../assets/add-icon.svg";
import GridIcon from "../assets/grid-icon.svg";
import ListIcon from "../assets/list-icon.svg";
import DefaultIcon from "../assets/logo_default.svg";
import SearchIcon from "../assets/search-icon.svg";

const initialState = {
  data: {},
  products: [],
  page: {
    count: 0,
    pageNumber: 1,
    pageSize: 20,
  },
  total: 0,
};

function ProductManagement() {
  const history = useHistory();
  const classes = useStyles();

  const [state, dispatch] = useReducer({
    reducer: managementReducer,
    name: "managementReducer",
    initialState,
    history,
  });

  useEffect(() => {
    dispatch("getList");
  }, []);

  const { loading, products, page } = state;
  return (
    <div className={classes.container}>
      <div className="header">Gói sản phẩm</div>
      <div className="row search">
        <div className="wrapper">
          <TextField
            fullWidth
            placeholder="Tìm kiếm sản phẩm"
            className="textfield"
            // value={data?.search || ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="search-ic" />
                </InputAdornment>
              ),
            }}
            onChange={debounce(
              (e) =>
                dispatch({
                  type: "getList",
                  value: e.target.value,
                }),
              800
            )}
          />
          {loading && <CircularProgress size={25} sx={{ color: "#3961A0" }} />}
        </div>
        <div className="wrapper-btn">
          {/* <img src={ListIcon} alt="list-ic" className="icon" />
          <img src={GridIcon} alt="grid-ic" className="icon" /> */}
          <Button
            className="button"
            onClick={() => dispatch({ type: "changePage", name: "add" })}
          >
            <img src={AddIcon} alt="add-ic" className="icon" />
            Tạo sản phẩm mới
          </Button>
        </div>
      </div>
      <Grid container spacing={2}>
        {products.map((item, index) => (
          <Grid
            item
            xs={6}
            md={3}
            onClick={() =>
              dispatch({ type: "changePage", name: "detail", item })
            }
          >
            <div className="row box">
              <img
                src={item?.url_logo || DefaultIcon}
                alt={`ic${index}`}
                className="logo"
              />
              <div>
                <div className="title">{`Bảo hiểm ${item?.title || ""}`}</div>
                <div className="date">
                  Ngày tạo: {formatDate(item?.created_date)}
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      <Stack spacing={2} sx={{ alignItems: "flex-end", mt: 2 }}>
        <Pagination
          count={page.count}
          page={page.pageNumber}
          onChange={(e, value) => dispatch({ type: "onPaging", e, value })}
          variant="outlined"
          color="primary"
        />
      </Stack>
    </div>
  );
}

export default ProductManagement;
