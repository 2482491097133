import React, { useEffect, useState } from "react";
import {
  InputAdornment,
  MenuItem,
  Autocomplete,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { debounce, get } from "lodash";

import { dashboardStyle } from "../style/styleDashboard";
import { TextField } from "../overrideMui";
import { option_refresh_interval } from "../utils";

export default function HeadFilter(props) {
  const classes = dashboardStyle();
  const { state, dispatch, userFromProfile } = props;
  const [scrollPosition, setScrollPosition] = useState({});

  const {
    dataFiter,
    loadingAutocomplete,
    payloadAutocomplete,
    payloadApi,
    listOrg,
    listMerchant,
    listProductParent,
    refresh_interval,
  } = state;
  const userTypeFromProfile = userFromProfile?.extra_info?.userType;
  const is_root =
    userTypeFromProfile === "globalcare" &&
    userFromProfile?.role_management_claim?.is_root == 1;

  const listTypeAccount =
    userTypeFromProfile === "globalcare"
      ? [
          { label: "Tất Cả", value: "all" },
          { label: "TPAG", value: "tpag" },
          { label: "TPAS", value: "tpas" },
          { label: "TPA", value: "tpa" },
        ]
      : userTypeFromProfile === "tpag"
      ? [
          { label: "Tất Cả", value: "all" },
          { label: "TPAG", value: "tpag" },
          { label: "TPAS", value: "tpas" },
        ]
      : [];

  const getValueAutocomplete = (id, name) => {
    if (name === "org") {
      const title = payloadAutocomplete?.org?.title;
      const temp = title
        ? {
            title: title,
          }
        : listOrg.find((i) => i.id == id) || null;

      return temp;
    }

    if (name === "merchant") {
      const title = payloadAutocomplete?.merchant?.title;
      const temp =
        title || title == ""
          ? {
              title: title,
            }
          : listMerchant.find((i) => i.id == id) || null;

      return temp;
    }
    if (name === "product") {
      return id?.map((j) => {
        return listProductParent.find((i) => j == i.id) || null;
      });
    }
  };

  const onScrollListAutocomplete = (name) => {
    const elment = document.getElementsByClassName(`div-scroll-${name}`);
    const pageListAuto = get(payloadAutocomplete, `${name}.page`) || 0;
    const totalListAuto = get(payloadAutocomplete, `${name}.total`) || 0;

    if (elment.length > 0 && +pageListAuto * 10 < +totalListAuto) {
      const scrollDiv = elment[0];
      const listItems = document.querySelectorAll(`.scroll-item-${name}`);
      const lastItem = listItems[listItems.length - 1] || null;
      if (
        Math.abs(lastItem?.offsetTop - scrollDiv.scrollTop) <
          lastItem?.offsetHeight + scrollDiv.offsetHeight &&
        !loadingAutocomplete[name]
      ) {
        dispatch({ type: "getMoveListAutocomplete", name });
        setScrollPosition({
          ...scrollPosition,
          [name]: scrollDiv.scrollHeight,
        });
      }
    }
  };

  useEffect(() => {
    const element = document.getElementsByClassName("div-scroll-merchant");
    if (element.length > 0) {
      const element_scroll = element[0];
      element_scroll.scrollTop = scrollPosition?.merchant || 0;
    }
  }, [loadingAutocomplete?.merchant]);

  useEffect(() => {
    const element = document.getElementsByClassName("div-scroll-org");
    if (element.length > 0) {
      const element_scroll = element[0];
      element_scroll.scrollTop = scrollPosition?.org || 0;
    }
  }, [loadingAutocomplete?.org]);

  useEffect(() => {
    const element = document.getElementsByClassName("div-scroll-product");
    if (element.length > 0) {
      const element_scroll = element[0];
      element_scroll.scrollTop = scrollPosition?.product || 0;
    }
  }, [loadingAutocomplete?.product]);

  useEffect(() => {
    if (refresh_interval != "off" && is_root) {
      const interval = setInterval(() => {
        dispatch("refresh_data");
      }, refresh_interval * 1000);

      return () => clearInterval(interval);
    }
  }, [refresh_interval]);

  return (
    <div className={classes.head_filter}>
      <div className="first-header">
        <div className="box_select">
          <span
            style={{ marginRight: "12px", fontWeight: "500", color: "#61646D" }}
          >
            Chọn khung thời gian{" "}
          </span>
          <TextField
            value={dataFiter?.valueTextDate || ""}
            onClick={() => {
              dispatch("openModalDate");
            }}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon
                    fontSize="small"
                    sx={{ color: "#686868" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {["globalcare", "tpag"].includes(userTypeFromProfile) && (
            <>
              <span
                style={{
                  margin: "0 12px",
                  fontWeight: "500",
                  color: "#61646D",
                }}
              >
                Loại tài khoản{" "}
              </span>
              <TextField
                select
                value={dataFiter?.typeAccount || ""}
                sx={{ minWidth: "100px" }}
                onChange={(e) => {
                  dispatch({
                    type: "onSelectTypeAccount",
                    value: e.target.value,
                  });
                }}
              >
                {listTypeAccount.map((item, index) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
              <span
                style={{
                  margin: "0 12px",
                  fontWeight: "500",
                  color: "#61646D",
                }}
              >
                Đơn vị TPA{" "}
              </span>
              <div style={{ position: "relative" }}>
                <Autocomplete
                  disablePortal
                  id="autocomple_tpa_id"
                  sx={{
                    "& .MuiInputBase-root": {
                      padding: "1px",
                      paddingLeft: "8px",
                      paddingRight: "32px",
                      minWidth: "190px",
                    },
                  }}
                  options={listOrg}
                  getOptionLabel={(options) => options?.title || ""}
                  // fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // placeholder="Nhập hoặc chọn doanh nghiệp có sẵn"
                      // error={errors.includes("provider_id_parent")}
                    />
                  )}
                  value={getValueAutocomplete(payloadApi?.orgId, "org") || null}
                  onChange={(e, value, reason) => {
                    if (reason === "selectOption") {
                      dispatch({
                        type: "onChangPayloadApi",
                        name: "org",
                        value: value?.id,
                      });
                    }
                    if (reason === "clear") {
                      dispatch({
                        type: "onInputChangeOrg",
                        value: null,
                      });
                    }
                  }}
                  onInputChange={debounce((e, value, reason) => {
                    if (reason === "input") {
                      dispatch({ type: "onInputChangeOrg", value });
                    }
                  }, 600)}
                  noOptionsText={
                    <div>
                      {loadingAutocomplete?.org
                        ? "Đang tải thêm ... "
                        : "Không tìm thấy dữ liệu"}
                    </div>
                  }
                  ListboxComponent={(paramsList) => (
                    <div
                      {...paramsList}
                      className={`${paramsList.className} div-scroll-org`}
                      onScroll={() => {
                        onScrollListAutocomplete("org");
                      }}
                    >
                      {paramsList?.children?.map((i) => (
                        <li
                          {...i?.props}
                          key={i?.key}
                          className={`${i?.props?.className} scroll-item-org`}
                        />
                      ))}
                      {loadingAutocomplete?.org && (
                        <div
                          style={{
                            fontWeight: "600",
                            color: "#0B7CE4",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                        >
                          Đang tải thêm ...{" "}
                        </div>
                      )}
                    </div>
                  )}
                  PopperComponent={(props) => {
                    return (
                      <div
                        {...props}
                        className={`${props.className} ${classes.max_height_autocomplete}`}
                        style={{
                          width:
                            document.getElementById("autocomple_tpa_id")
                              .offsetWidth + 90,
                          position: "absolute",
                          display: props.open ? "" : "none",
                        }}
                        placement={"bottom-start"}
                      >
                        {props.children}
                      </div>
                    );
                  }}
                />
                <Backdrop
                  style={{
                    color: "#fff",
                    borderRadius: "5px",
                    position: "absolute",
                  }}
                  open={loadingAutocomplete?.orgBr}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            </>
          )}
        </div>
        {is_root && (
          <div className="box_refect">
            <IconButton onClick={() => dispatch("getDataOverview")}>
              <CachedIcon />
            </IconButton>
            <TextField
              select
              value={refresh_interval}
              onChange={(e) =>
                dispatch({ type: "setDataRefresh", value: e.target.value })
              }
            >
              {option_refresh_interval.map((i, index) => (
                <MenuItem value={i.value} key={i}>
                  {i.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
      </div>
      <div className="secont-header">
        <span
          style={{ marginRight: "12px", fontWeight: "500", color: "#61646D" }}
        >
          Sản phẩm{" "}
        </span>
        <div style={{ position: "relative" }}>
          <Autocomplete
            disablePortal
            multiple
            id="autocomple_product_id"
            limitTags={2}
            sx={{
              "& .MuiInputBase-root": {
                padding: "1px",
                paddingLeft: "8px",
                paddingRight: "32px",
                minWidth: "190px",
                // maxWidth: "350px",
              },
            }}
            options={listProductParent}
            getOptionLabel={(options) => options?.title || ""}
            // fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                // placeholder="Nhập hoặc chọn doanh nghiệp có sẵn"
                // error={errors.includes("provider_id_parent")}
              />
            )}
            value={
              getValueAutocomplete(payloadApi?.productIdParents, "product") ||
              []
            }
            onChange={(e, value, reason) => {
              dispatch({
                type: "onChangPayloadApi",
                name: "product",
                value: value?.map((i) => i.id),
                e,
                test: value,
              });
            }}
            onInputChange={debounce((e, value, reason) => {
              if (reason === "input") {
                dispatch({ type: "onInputChangeProduct", value });
              }
            }, 800)}
            noOptionsText={
              <div>
                {loadingAutocomplete?.product
                  ? "Đang tải thêm ... "
                  : "Không tìm thấy dữ liệu"}
              </div>
            }
            ListboxComponent={(paramsList) => (
              <div
                {...paramsList}
                className={`${paramsList.className} div-scroll-product`}
                onScroll={() => {
                  onScrollListAutocomplete("product");
                }}
              >
                {paramsList?.children?.map((i) => (
                  <li
                    {...i?.props}
                    key={i?.key}
                    className={`${i?.props?.className} scroll-item-product`}
                  />
                ))}
                {loadingAutocomplete?.product && (
                  <div
                    style={{
                      fontWeight: "600",
                      color: "#0B7CE4",
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    Đang tải thêm ...{" "}
                  </div>
                )}
              </div>
            )}
            PopperComponent={(props) => {
              return (
                <div
                  {...props}
                  className={`${props.className} ${classes.max_height_autocomplete}`}
                  style={{
                    width:
                      document.getElementById("autocomple_product_id")
                        .offsetWidth + 150,
                    // position: "absolute",
                    display: props.open ? "" : "none",
                  }}
                  placement={"bottom-start"}
                >
                  {props.children}
                </div>
              );
            }}
          />
        </div>
        <span style={{ margin: "0 12px", fontWeight: "500", color: "#61646D" }}>
          Doanh nghiệp{" "}
        </span>
        <div style={{ position: "relative" }}>
          <Autocomplete
            disablePortal
            id="autocomple_merchant_id_text"
            sx={{
              "& .MuiInputBase-root": {
                padding: "1px",
                paddingLeft: "8px",
                paddingRight: "32px",
                minWidth: "190px",
              },
            }}
            options={listMerchant}
            getOptionLabel={(options) => options?.title || ""}
            renderInput={(params) => <TextField {...params} />}
            value={
              getValueAutocomplete(payloadApi?.merchantId, "merchant") || null
            }
            onChange={(e, value, reason) => {
              if (reason === "selectOption") {
                dispatch({
                  type: "onChangPayloadApi",
                  name: "merchant",
                  value: value?.id,
                });
              }
              if (reason === "clear") {
                dispatch({
                  type: "onInputChangeMerchant",
                  value: "",
                });
              }
            }}
            onInputChange={debounce((e, value, reason) => {
              if (reason === "input") {
                dispatch({ type: "onInputChangeMerchant", value });
              }
            }, 600)}
            noOptionsText={
              <div>
                {loadingAutocomplete?.merchant
                  ? "Đang tải thêm ... "
                  : "Không tìm thấy dữ liệu"}
              </div>
            }
            ListboxComponent={(paramsList) => (
              <div
                {...paramsList}
                className={`${paramsList.className} div-scroll-merchant`}
                onScroll={() => {
                  onScrollListAutocomplete("merchant");
                }}
              >
                {paramsList?.children?.map((i) => (
                  <li
                    {...i?.props}
                    key={i?.key}
                    className={`${i?.props?.className} scroll-item-merchant`}
                  />
                ))}
                {loadingAutocomplete?.merchant && (
                  <div
                    style={{
                      fontWeight: "600",
                      color: "#0B7CE4",
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    Đang tải thêm ...{" "}
                  </div>
                )}
              </div>
            )}
            PopperComponent={(props) => {
              return (
                <div
                  {...props}
                  className={`${props.className} ${classes.max_height_autocomplete}`}
                  style={{
                    width:
                      document.getElementById("autocomple_merchant_id_text")
                        .offsetWidth + 90,
                    // position: "absolute",
                    display: props.open ? "" : "none",
                  }}
                  placement={"bottom-start"}
                >
                  {props.children}
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
