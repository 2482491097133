export const messages = {
  content: {
    new: 'new',
  },
  button: {
    new: 'new',
  },
  title: {
    new: 'new',
  },
  alert: {
    error: 'Đã xảy ra sự cố',
    update_status_error: 'Cập nhật trạng thái thất bại',
    update_status_success: 'Cập nhật trạng thái thành công',
    create_account_error: 'Tạo tài khoản thất bại',
    create_account_success: 'Tạo tài khoản thành công',
    update_account_error: 'Cập nhật tài khoản thất bại',
    update_account_success: 'Cập nhật tài khoản thành công',
    create_claim_error: 'Tạo hồ sơ bồi thường không thành công',
    create_claim_success: 'Tạo hồ sơ bồi thường thành công',
    login_success: 'Đăng nhập thành công',
    login_error: 'Đăng nhập thất bại',
    'user.not.found.or.incorrect.password': 'Tên đăng nhập hoặc mật khẩu không chính xác',
    update_product_success: 'Lưu danh sách sản phẩm bồi thường thành công',
    update_product_error: 'Lưu danh sách sản phẩm bồi thường thất bại',
    update_password_error: 'Cập nhật mật khẩu thất bại',
    update_password_success: 'Cập nhật mật khẩu thành công',
    new: 'new',
  }
};
