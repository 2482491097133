import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

import InsuranceBenefitTable from "./insuranceBenefitsTable";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogFullWidth(props) {
  const { open, onClose, state, dispatch } = props;
  const classes = makeStyle();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth={"2000px"}
      onClose={onClose}
      className={classes.dialog}
    >
      <DialogTitle style={{ textAlign: "end" }}>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <InsuranceBenefitTable {...{ state, dispatch }} />
      </DialogContent>
    </Dialog>
  );
}

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
      margin: "12px",
    },
  },

  divContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
