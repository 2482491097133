import { TableCell } from "@mui/material";
import { formatDate } from "../utils";

export const columns = [
  {
    label: "STT",
    headerClass: "stt",
    renderCell: ({ rowIdx, colIdx }) => (
      <TableCell className="center" key={colIdx}>
        {rowIdx + 1}
      </TableCell>
    ),
  },
  {
    label: "Họ và tên",
    headerClass: "left",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell className="fullname" key={colIdx}>
        {item?.insuredName || ""}
      </TableCell>
    ),
  },
  {
    label: "Mã nhân viên",
    headerClass: "left",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell key={colIdx}>{item?.certNum || ""}</TableCell>
    ),
  },
  {
    label: "Ngày sinh",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell key={colIdx}>{formatDate(item?.insuredDob)}</TableCell>
    ),
  },
  {
    label: "CMND/ CCCD",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell className="center" key={colIdx}>
        {item?.insuredPrivateId || ""}
      </TableCell>
    ),
  },
  {
    label: "Số điện thoại",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell className="center" key={colIdx}>
        {item?.insuredPhone}
      </TableCell>
    ),
  },
  {
    label: "Nhân viên/ người thân",
    headerClass: "nv",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell className="center" key={colIdx}>
        {item?.insuredType || ""}
      </TableCell>
    ),
  },
  {
    label: "Ngày bắt đầu",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell className="center" key={colIdx}>
        {formatDate(item?.beginDate)}
      </TableCell>
    ),
  },
  {
    label: "Ngày kết thúc",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell className="date" key={colIdx}>
        {formatDate(item?.endDate)}
      </TableCell>
    ),
  },
  {
    label: "Ngày tham gia đầu tiên",
    headerClass: "nv",
    renderCell: ({ colIdx, event, item }) => (
      <TableCell className="center" key={colIdx}>
        {formatDate(item?.beginDate)}
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, index, event }) => (
      <TableCell key={index}>Trạng thái</TableCell>
    ),
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell key={colIdx} className="center">
        <span
          className={`stt  ${
            item.status === 1
              ? "color1"
              : item.status === 2
              ? "color2"
              : item.status === 3
              ? "color3"
              : ""
          }`}
        >
          {item.status === 1
            ? "Đang hoạt động"
            : item.status === 2
            ? "Sắp hết hạn"
            : item.status === 3
            ? "Hết hạn"
            : ""}
        </span>
      </TableCell>
    ),
  },
];
