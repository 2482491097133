import { Grid, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useReducer from "../../../../library/hooks/useReducer";
import LoadingMui from "../../../../ui/LoadingMui";
import BenefitPackageTable from "../components/BenefitsPackageTable";
import contractReducer from "../useReducer/contractReducer";
import { useStyles } from "../useStyles";
import {
  convertCurrency,
  formatDate,
  genders,
  getParamUrlByKey,
  insuredType,
  renderConfig,
  types,
} from "../utils";

import editIcon from "../assets/edit-icon.svg";
import eyeOpenIcon from "../assets/eye-open-ic.png";
import defaultIcon from "../assets/logo-default.svg";
import AddInfoUser from "../components/AddInfoUser";
import EditDialog from "../components/EditDialog";

const initialState = {
  oldData: {},
  data: {},
  editData: {},
  dataInfo: {},
  benefitList: [],
  loading: false,
  saving: false,
  open: {
    user: false,
    waiting: false,
    share: false,
  },
  errors: [],
};

function User(props) {
  const history = useHistory();
  const id = getParamUrlByKey("id");
  const packageId = getParamUrlByKey("packageId");
  const classes = useStyles();

  const [state, dispatch] = useReducer({
    reducer: contractReducer,
    name: "contractReducer",
    initialState,
    history,
    id,
    packageId,
  });

  const { oldData, data, benefitList, open, loading } = state;

  useEffect(() => {
    dispatch("getOldData");
    if (id) {
      dispatch("getDetailContract");
    }
  }, []);

  return (
    <>
      <div className={classes.headerBar}>
        Khách hàng/ <b>{oldData?.providerTitle || ""} </b>
      </div>
      <LoadingMui loading={loading} />

      <div className={classes.package}>
        <div className="container">
          <div className="info">
            <Grid container spacing={4}>
              <Grid item xs={3}>
                {oldData?.urlLogo ? (
                  <img src={oldData.urlLogo} alt="logo" className="logo" />
                ) : (
                  <img src={defaultIcon} alt="logo" className="logo" />
                )}
                <div className="name">Bảo hiểm {oldData?.title || ""}</div>
              </Grid>
              <Grid item xs={5}>
                <div className="row">
                  <div>Mã sản phẩm</div>
                  <div className="color">{oldData?.productId || ""}</div>
                </div>
                <div className="row">
                  <div>Tên sản phẩm</div>
                  <div className="color">Bảo hiểm {oldData?.title || ""}</div>
                </div>
                <div className="row">
                  <div>Thuộc công ty</div>
                  <div className="color">{oldData?.providerTitle || ""}</div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="row">
                  <div>Loại bảo hiểm</div>
                  <div className="color">
                    {types.find(
                      (i) => i.insurance_code == oldData.insuranceCode
                    )?.insurance_title || ""}
                  </div>
                </div>
                <div className="row">
                  <div>Ngày tạo</div>
                  <div className="color">
                    {formatDate(oldData?.createdDate)}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="fee">
            <div className="fee-text">
              Phí BH: <b className="ml-10">{oldData?.rangeFees} </b>
            </div>
            <div className="fee-text">
              Quyền lợi:
              <b className="ml-10">
                {" "}
                {convertCurrency(oldData?.total_product_amount || 0)}
              </b>
            </div>
            <div className="row fee-text">
              Mã quyền lợi sản phẩm{" "}
              <IconButton onClick={() => dispatch("showDetailPackage")}>
                <img src={eyeOpenIcon} alt="eye" />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="detail">
          {!data?.id ? (
            <div className="title-info"> Không có thông tin.</div>
          ) : (
            <>
              <div className="row">
                <div className="bold">Thông tin gói bảo hiểm</div>
                <IconButton color="primary">
                  <img
                    src={editIcon}
                    alt="edit"
                    onClick={() => dispatch("onOpenInfo")}
                  />
                </IconButton>
              </div>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={4}
                className="bg-gray"
              >
                {renderInfoUser(data).map((item) => (
                  <Grid item xs={3} className={classes.gridItem}>
                    <div className="title-info">{item.title}</div>
                    <div className={`${item?.color ? "color" : ""}`}>
                      {item.value}
                    </div>
                  </Grid>
                ))}
              </Grid>
              <div className="row">
                <div className="bold">Thời gian chờ</div>
                <IconButton color="primary">
                  <img
                    src={editIcon}
                    alt="edit"
                    onClick={() =>
                      dispatch({ type: "onOpen", name: "waiting" })
                    }
                  />
                </IconButton>
              </div>
              <div className="bg-gray row">
                {renderConfig.map((i) => (
                  <InfoWaiting title={i.title} name={i.name} data={data} />
                ))}
              </div>
              <div className="row">
                <div className="bold">Đồng chi trả</div>
                <IconButton color="primary">
                  <img
                    src={editIcon}
                    alt="edit"
                    onClick={() => dispatch({ type: "onOpen", name: "share" })}
                  />
                </IconButton>
              </div>
              <div className="bg-gray row">
                {renderConfig.map((i) => (
                  <InfoShare title={i.title} name={i.name} data={data} />
                ))}
              </div>
              <div className="bold">Bảng quyền lợi bảo hiểm</div>
              <BenefitPackageTable list={benefitList} />
            </>
          )}{" "}
        </div>
      </div>
      <AddInfoUser open={open.user} state={state} dispatch={dispatch} />
      <EditDialog
        open={open.waiting}
        title="waiting"
        state={state}
        dispatch={dispatch}
      />
      <EditDialog
        open={open.share}
        title="share"
        state={state}
        dispatch={dispatch}
      />
    </>
  );
}

export default User;

const InfoWaiting = ({ title, name, data }) => (
  <div className="center">
    <div>{title}</div>
    <div className="color">{data?.extraInfo?.config_waiting?.[name] || 0}</div>
  </div>
);
const InfoShare = ({ title, name, data }) => (
  <div className="center">
    <div>{title}</div>
    <div className="color">
      {data?.extraInfo?.config_share_payment?.[name] || 0}%
    </div>
  </div>
);

const renderInfoUser = (data) => [
  { title: "Số hợp đồng BH", value: data?.certNumGroup || "--", color: true },
  {
    title: "Tổng phí bảo hiểm",
    value: convertCurrency(data?.fees) || 0,
    color: true,
  },
  {
    title: "Tổng quyền lợi",
    value: convertCurrency(data?.productAmount) || 0,
    color: true,
  },
  {
    title: "Quyền lợi còn lại",
    value: convertCurrency(data?.remainingBenefit) || 0,
    color: true,
  },
  { title: "Người được bảo hiểm", value: data?.insuredName || "--" },
  {
    title: "Giới tính",
    value: data?.insuredGender
      ? genders.find((i) => i.id === data?.insuredGender)?.title
      : "--",
  },
  { title: "Ngày sinh", value: formatDate(data?.insuredDob) },
  { title: "Số điện thoại", value: data?.insuredPhone || "--" },
  { title: "CMND/ CCCD", value: data?.insuredPrivateId || "--" },
  { title: "Email", value: data?.insuredEmail || "--" },
  {
    title: "Địa chỉ",
    value: data?.insuredAddress || "--",
  },
  { title: "Nơi mua bảo hiểm", value: data?.titleMerchant || "--" },
  { title: "Mã nhân viên", value: data?.certNum || "--" },
  {
    title: "Nhân viên/ người thân",
    value: insuredType.find((i) => i.value == data.insuredType)?.title || "--",
  },

  { title: "Ngày bắt đầu", value: formatDate(data?.beginDate) },
  { title: "Ngày kết thúc", value: formatDate(data?.endDate) },
];
