import _ from "lodash";
import React, { useEffect } from "react";
import { Button } from "@mui/material";

import useReducer from "../../../../../../library/hooks/useReducer";
import claimComponentReducer from "../../reducer/claimComponentReducer";
import { initialStateClaimComponent } from "../../utils";
import ProgressLoading from "../ProgressLoading";
import Title from "./Title";
import BeneficiaryInformation from "./beneficiaryInformation";
import Diagnosis from "./diagnosis";
import DialogCodeBenefit from "./dialogCodeBenefit";
import DialogUpdate from "./dialogUpdate";
import MedicalTable from "./medicalExpensesTable";
import ProfileInformation from "./profileInformation";
import OtherTable from "./otherExpensezTable";

import tag from "../../../../../../assets/health/tag.svg";
import styleClaim from "./styleClaim";

export default function Claim(props) {
  const {
    level_id,
    systemType,
    detail,
    id,
    transData,
    rowsInsuranceBenefit,
    tabsDetail,
    dialogInsuranceInfo,
  } = props;

  const [state, dispatch] = useReducer({
    initialState: initialStateClaimComponent,
    name: "claimComponentReducer",
    reducer: claimComponentReducer,
    level_id,
    id,
    systemType,
    transData,
    rowsInsuranceBenefit,
    dialogInsuranceInfo,
  });

  const classes = styleClaim();
  const {
    profileInformationExpan,
    beneficiaryInformationExpan,
    diagnosisExpan,
    medicalExpan,
    benefitsExpan,
    prescriptionExpan,
    examinationFeesTotal,
    medicineFeesTotal,
    othersTotal,
    billsTotal,
    otherTablesTotal,
    finalFeesTotal,
    errors,
    progressLoading,
    hoa_don,
  } = state;

  useEffect(
    () => dispatch("finalFeesTotal"),
    [
      examinationFeesTotal,
      billsTotal,
      medicineFeesTotal,
      othersTotal,
      otherTablesTotal,
    ]
  );

  useEffect(() => {
    dispatch({ type: "inittailClaim", detail });
    level_id == 1 &&
      detail?.statusId == 2 &&
      tabsDetail?.key == "claim" &&
      dispatch("checkDataElements");
  }, []);

  return (
    <div className={classes.container}>
      <Title
        title="Thông tin hồ sơ"
        unEdit={level_id !== 1}
        expan={profileInformationExpan}
        onEdit={() =>
          dispatch({ type: "openDialogUpdata", name: "profileInformation" })
        }
        onExpan={() =>
          dispatch({ type: "expanBox", name: "profileInformationExpan" })
        }
      />
      <ProfileInformation {...{ state, dispatch }} />
      <Title
        title="Thông tin thụ hưởng"
        unEdit={level_id !== 1 || [5, 6, 8, 9, 10].includes(detail?.statusId)}
        expan={beneficiaryInformationExpan}
        onEdit={() =>
          dispatch({ type: "openDialogUpdata", name: "beneficiaryInformation" })
        }
        onExpan={() =>
          dispatch({ type: "expanBox", name: "beneficiaryInformationExpan" })
        }
      />
      <BeneficiaryInformation {...{ state, dispatch }} />
      {[3, 5, 6].includes(+detail?.extraInfo?.typeOfIndimnity) ? (
        <>
          <OtherTable {...{ state, dispatch, level_id }} />
          <Title
            title="Quyền lợi khác"
            unEdit={true}
            expan={benefitsExpan}
            onExpan={() =>
              dispatch({ type: "expanBox", name: "benefitsExpan" })
            }
          />
          {errors?.benefits && (
            <div className={classes.divErrors}>
              Vui lòng nhập đầy đủ thông tin{" "}
            </div>
          )}
          <MedicalTable
            {...{ state, dispatch, name: "benefitsExpan", level_id }}
            // key="benefits"
          />
        </>
      ) : (
        <>
          {" "}
          <Title
            title="Chẩn đoán bệnh"
            unEdit={
              level_id !== 1 || [5, 6, 8, 9, 10].includes(detail?.statusId)
            }
            expan={diagnosisExpan}
            onEdit={() =>
              dispatch({ type: "openDialogUpdata", name: "diagnosis" })
            }
            onExpan={() =>
              dispatch({ type: "expanBox", name: "diagnosisExpan" })
            }
          />
          <Diagnosis {...{ state, dispatch }} />
          <Title
            title="Bảng kê chi phí khám"
            unEdit={
              level_id !== 1 || [5, 6, 8, 9, 10].includes(detail?.statusId)
            }
            addIcon={level_id === 1 ? tag : false}
            expan={medicalExpan}
            onClickAdd={() =>
              dispatch({ type: "openDialogCodeBenefits", name: "medical" })
            }
            onEdit={() =>
              dispatch({ type: "openDialogUpdata", name: "medical" })
            }
            onExpan={() => dispatch({ type: "expanBox", name: "medicalExpan" })}
          />
          <MedicalTable
            {...{ state, dispatch, name: "medicalExpan" }}
            key="medical"
          />
          <Title
            title="Hóa đơn"
            unEdit={
              level_id !== 1 || [5, 6, 8, 9, 10].includes(detail?.statusId)
            }
            addIcon={level_id === 1 ? tag : false}
            expan={hoa_don}
            onClickAdd={() =>
              dispatch({ type: "openDialogCodeBenefits", name: "bill" })
            }
            onEdit={() => dispatch({ type: "openDialogUpdata", name: "bill" })}
            onExpan={() => dispatch({ type: "expanBox", name: "bill" })}
          />
          <MedicalTable
            {...{ state, dispatch, name: "bill" }}
            // key="medical"
          />
          <Title
            title="Toa thuốc"
            unEdit={
              level_id !== 1 || [5, 6, 8, 9, 10].includes(detail?.statusId)
            }
            addIcon={level_id === 1 ? tag : false}
            expan={prescriptionExpan}
            onClickAdd={() =>
              dispatch({ type: "openDialogCodeBenefits", name: "prescription" })
            }
            onEdit={() =>
              dispatch({ type: "openDialogUpdata", name: "prescription" })
            }
            onExpan={() =>
              dispatch({ type: "expanBox", name: "prescriptionExpan" })
            }
          />
          <MedicalTable
            {...{ state, dispatch, name: "prescriptionExpan" }}
            key="prescription"
          />
          <Title
            title="Quyền lợi khác"
            unEdit={true}
            expan={benefitsExpan}
            onExpan={() =>
              dispatch({ type: "expanBox", name: "benefitsExpan" })
            }
          />
          {errors?.benefits && (
            <div className={classes.divErrors}>
              Vui lòng nhập đầy đủ thông tin{" "}
            </div>
          )}
          <MedicalTable
            {...{ state, dispatch, name: "benefitsExpan", level_id }}
            // key="benefits"
          />
        </>
      )}
      <div className={classes.totalCost}>
        <span>{"Tổng chi phí"}</span>
        <span>
          {`${finalFeesTotal || 0}`
            .replace(/\./g, ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " VNĐ"}
        </span>
      </div>
      <div className={classes.buttons}>
        <Button
          disabled={
            level_id !== 1 ||
            [5, 6, 7, 8, 9].includes(detail?.statusId || detail?.status_id)
          }
          className={
            level_id !== 1 ||
            [5, 6, 7, 8, 9].includes(detail?.statusId || detail?.status_id)
              ? "buttons disabled "
              : "buttons"
          }
          onClick={() => dispatch("checkClaim")}
        >
          Kiểm tra hồ sơ bồi thường
        </Button>
      </div>
      <DialogUpdate {...{ state, dispatch }} />
      <DialogCodeBenefit {...{ state, dispatch }} />
      <ProgressLoading
        {...{ open: progressLoading?.open, value: progressLoading.value }}
      />
    </div>
  );
}
