import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Typography, Modal } from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import { getDetail365PBA } from "../../../../../apis/claimPBAApi";
import HeaderBar from "../layout/HeaderBar";
import CTCheckbox from "../layout/CTCheckBox";
import ItemDropDown from "../layout/ItemDropDown";

import parseCurrency from "../../../../../utils/parseCurrency";
import { Wrapper, ContentWrapper, Content } from "../../carAccident/styles";
import {
  bone_temporary_out,
  temporary_out,
  temporary_in,
  hurt_forever,
  dead,
} from "../components/create/contantsUpload";
import { getParamUrlByKey } from "../../../../../utils/utils";
import { Details } from "@mui/icons-material";

const queryString = require("query-string");

const DetailPBA = (props) => {
  const location = useLocation();
  const history = useHistory();
  const id = getParamUrlByKey("id");

  const [detail, setDetail] = useState({});
  const [dataClaim, setDataClaim] = useState({});
  // const [propsModalSuccess, setPropsModalSuccess] = useState([]);
  const [uploadRender, setUploadRender] = useState([]);
  const [signature, setSignature] = useState({});
  const [isShowData1, setIsShowData1] = useState(false);
  const [isShowData2, setIsShowData2] = useState(false);
  const [ruleModalOpen, setRuleModalOpen] = useState(false);

  const compensNoticeRender = [
    {
      id: 11,
      key: "totalAmountTreatMent",
      title: "Số tiền yêu cầu bồi thường",
      hidden: detail?.extraInfo?.levelAccident == 1,
      value: parseCurrency(+detail?.extraInfo?.totalAmountTreatment || 0),
      price: true,
      type: "text",
      styleTitle: {
        flex: "1 1 0%",
        fontSize: "15px",
        color: "rgb(2, 66, 129)",
        fontWeight: "600",
        width: "fit-content",
        minWidth: "195px",
        marginRight: "10px",
      },
    },
    {
      id: 12,
      key: "totalAmountTreatMent",
      title: "Số tiền được bồi thường",
      hidden: detail?.extraInfo?.levelAccident == 1,
      value: parseCurrency(+dataClaim?.value || 0),
      price: true,
      type: "text",
      styleTitle: {
        flex: "1 1 0%",
        fontSize: "15px",
        color: "rgb(2, 66, 129)",
        fontWeight: "600",
        width: "fit-content",
        minWidth: "180px",
        marginRight: "10px",
      },
    },
    {
      id: 8,
      key: "text",
      title: "Nội dung",
      value: dataClaim?.text,
      wrap: true,
      type: "text",
    },
  ];

  const initRender = [
    {
      id: 1,
      key: "typeOfAccident",
      title: "Loại tai nạn",
      value: "Tai nạn giao thông",
      type: "text",
    },
    {
      id: 2,
      key: "levelAccident",
      title: "Mức độ tai nạn",
      value:
        detail?.extraInfo?.levelAccident == 1
          ? "Tử vong"
          : detail?.extraInfo?.levelAccident == 2
          ? "Thương tật vĩnh viễn"
          : detail?.extraInfo?.levelAccident == 3
          ? "Thương tật tạm thời"
          : "",
      type: "radio",
    },
    {
      id: 3,
      key: "typeOfTreatment",
      hidden: detail?.extraInfo?.levelAccident != 3,
      title: "Loại hình điều trị",
      type: "select",
      value:
        detail?.extraInfo?.typeOfTreatment == 1
          ? "Ngoại trú"
          : detail?.extraInfo?.typeOfTreatment == 2
          ? "Nội trú"
          : "",
    },
    {
      id: 5,
      key: "damageAccident",
      hidden: detail?.extraInfo?.typeOfTreatment != 1,
      title: "Loại tai nạn",
      type: "radio",
      value:
        +detail?.extraInfo?.damageAccident == 1
          ? "Liên quan đến xương"
          : +detail?.extraInfo?.damageAccident == 2
          ? "Không liên quan đến xương"
          : "",
    },
    {
      id: 6,
      key: "dateOfAccident",
      title: "Ngày tai nạn",
      value: detail?.extraInfo?.dateOfAccident,
      type: "text",
    },
    {
      id: 7,
      key: "positionAccident",
      title: "Nơi xảy ra tai nạn",
      value: detail?.extraInfo?.positionAccident,
      type: "text",
    },
    {
      id: 8,
      key: "reason",
      title: "Diễn biến tai nạn",
      value: detail?.extraInfo?.reason,
      wrap: true,
      type: "text",
    },
    {
      id: 9,
      key: "dayOfTreatment",
      title: "Ngày điều trị",
      hidden:
        detail?.extraInfo?.levelAccident == 1 ||
        detail?.extraInfo?.typeOfTreatment == 2,
      value: detail?.extraInfo?.dayOfTreatment,
      type: "text",
    },
    {
      id: 10,
      key: "treatmentPlace",
      title: "Nơi điều trị",
      value: detail?.extraInfo?.treatmentPlace,
      type: "text",
    },
    {
      id: 11,
      key: "totalAmountTreatMent",
      title: "Tổng chi phí điều trị",
      hidden: detail?.extraInfo?.levelAccident == 1,
      value: parseCurrency(+detail?.extraInfo?.totalAmountTreatment || 0),
      price: true,
      type: "text",
    },
    {
      id: 12,
      key: "diagnoses",
      title: "Chẩn đoán",
      value: detail?.extraInfo?.diagnoses,
      wrap: true,
      type: "text",
    },
    {
      id: 13,
      key: "dateOfHospital",
      title: "Ngày nhập viện",
      hidden: detail?.extraInfo?.typeOfTreatment != 2,
      type: "date",
      value: detail?.extraInfo?.dateOfHospital,
    },
    {
      id: 14,
      key: "dateOutOfHospital",
      title: "Ngày xuất viện",
      hidden: detail?.extraInfo?.typeOfTreatment != 2,
      type: "date",
      value: detail?.extraInfo?.dateOutOfHospital,
    },
  ];

  const beneficiaryRender = [
    {
      id: 1,
      key: "beneficiaryName",
      title: "Họ và tên người thụ hưởng",
      value: detail?.insuredInfo?.beneficiaryName,
      type: "text",
      styleTitle: {
        flex: "1 1 0%",
        fontSize: "15px",
        color: "rgb(2, 66, 129)",
        fontWeight: "600",
        width: "fit-content",
        minWidth: "195px",
        marginRight: "10px",
      },
    },
    {
      id: 11,
      key: "beneficiaryBankName",
      errorMsg: "Vui lòng nhập đúng thông tin",
      title: "Ngân hàng",
      type: "select",
      value: detail?.insuredInfo?.beneficiaryBankTitle,
    },
    {
      id: 12,
      key: "beneficiaryBankAcountNumber",
      value: detail?.insuredInfo?.beneficiaryBankAcountNumber,
      title: "Số tài khoản",
      type: "text",
    },
    {
      id: 13,
      key: "departmantBank",
      title: "Chi nhánh",
      value: detail?.insuredInfo?.beneficiaryBankBranchName,
      type: "text",
    },
  ];

  const handleShowData1 = () => {
    setIsShowData1(!isShowData1);
  };

  const handleShowData2 = () => {
    setIsShowData2(!isShowData2);
  };

  const initUploadRender = () => {
    if (detail?.extraInfo?.levelAccident == 1) {
      return setUploadRender([...dead]);
    }
    if (detail?.extraInfo?.levelAccident == 2) {
      return setUploadRender([...hurt_forever]);
    }
    if (detail?.extraInfo?.levelAccident == 3) {
      if (
        detail?.extraInfo?.typeOfTreatment == 1 &&
        detail?.extraInfo?.damageAccident == 1
      ) {
        return setUploadRender([...bone_temporary_out]);
      }
      if (
        detail?.extraInfo?.typeOfTreatment == 1 &&
        detail?.extraInfo?.damageAccident == 2
      ) {
        return setUploadRender([...temporary_out]);
      }
      if (detail?.extraInfo?.typeOfTreatment == 2) {
        return setUploadRender([...temporary_in]);
      }
    }
    return;
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  });

  useEffect(async () => {
    await getDetail365PBA(id)
      .then((res) => {
        setDetail(res?.data?.result);
        const dataClaimByAdmin = (res?.data?.result?.elements || []).find(
          (i) => i.type_id === 319
        );
        const dataSignature = (res?.data?.result?.elements || []).find(
          (i) => i.type_id === 318
        );
        setDataClaim(dataClaimByAdmin || {});
        setSignature(
          Array.isArray(dataSignature?.extra_info?.documents)
            ? _.get(dataSignature, "extra_info.documents[0]") || {}
            : _.get(dataSignature, "documents[0]") || {}
        );
        initUploadRender();
      })
      .catch((errors) => errors);
  }, [id]);

  useEffect(async () => {
    initUploadRender();
  }, [detail]);

  const insuredInfo = detail?.insuredInfo;

  const converStatus = (id) => {
    switch (id) {
      case 2:
        return { title: "Đang xử lý", color: "#FFA21F" };
      case 3:
        return { title: "Đang đợi phê duyệt", color: "#26A9E0" };
      case 4:
        return { title: "Bổ sung hồ sơ", color: "#3F71FF" };
      case 5:
        return { title: "Phê duyệt", color: "#39B44A" };
      case 6:
        return { title: "Hoàn tất", color: "#39B44A" };
      case 7:
        return { title: "Từ chối", color: "#BF272D" };
      case 8:
        return { title: "Khách hàng từ chối", color: "#BF272D" };

      default:
        break;
    }
  };

  return (
    <Wrapper>
      <HeaderBar
        background="white"
        onPress={() => {
          history.goBack();
        }}
      >
        <span
          style={{
            fontSize: "16px",
            fontWeight: "600",
            width: "100%",
            textAlign: "center",
            paddingLeft: "55px",
            paddingRight: "16px",
          }}
        >
          HS bồi thường mã:{" "}
          <span style={{ color: "#024281" }}>{detail?.code || ""}</span>
        </span>
      </HeaderBar>
      <br />
      <br />

      <ContentWrapper
        style={{ backgroundColor: "white", marginTop: 60, width: "100%" }}
      >
        <Content
          style={{ width: "100%", padding: "0 16px", margin: "0 0 24px 0" }}
        >
          <>
            <Box>
              <div
                style={{
                  fontWeight: "700",
                  color: "rgba(0, 0, 0, 0.32)",
                  padding: "8px 0",
                }}
              >
                TÌNH TRẠNG HỒ SƠ
              </div>
              <div
                style={{
                  color: converStatus(detail.statusId)?.color,
                  display: "flex",
                  paddingBottom: "8px",
                }}
              >
                <RadioButtonCheckedIcon />
                <span style={{ marginLeft: "6px" }}>
                  {converStatus(detail.statusId)?.title}
                </span>
              </div>
            </Box>
            <Box style={formInfo}>
              <ItemDropDown
                title="Thông tin hợp đồng"
                onPress={handleShowData1}
                isShowData={isShowData1}
              >
                <Box style={styleDropDown}>
                  <RowItem
                    id={1}
                    title="Họ tên chủ xe"
                    value={insuredInfo?.insuredName}
                  />
                  <RowItem
                    title="Biển số xe/ số khung/ số máy"
                    value={insuredInfo?.licenseNumber}
                  />
                  <RowItem
                    title="Số điện thoại"
                    value={insuredInfo?.insuredPhone}
                    hidden={isShowData1}
                  />
                  <RowItem
                    title="Loại xe"
                    value={`Loại xe nhóm ${insuredInfo?.typeId}`}
                    hidden={isShowData1}
                  />
                  <RowItem
                    title="Hiệu lực bảo hiểm"
                    value={`${insuredInfo?.beginDate} - ${
                      insuredInfo?.endDate || ""
                    }`}
                    hidden={isShowData1}
                  />
                </Box>
              </ItemDropDown>
              <Box style={{ height: 24 }} />
            </Box>

            <ItemDropDown
              title="Hồ sơ bồi thường"
              onPress={handleShowData2}
              isShowData={isShowData2}
            >
              <Box style={styleDropDown}>
                <Box hidden={isShowData2}>
                  <Typography
                    style={styleRowItem}
                    fontSize={17}
                    fontWeight={700}
                  >
                    THÔNG BÁO BỒI THƯỜNG
                  </Typography>
                  {compensNoticeRender.map((v, i) => (
                    <RowItem
                      hidden={
                        isShowData2 ? v?.hidden || v.id > 4 : v?.hidden || false
                      }
                      title={v.title}
                      value={v.value}
                      wrap={v.wrap}
                      styleTitle={_.isEmpty(v.styleTitle) ? null : v.styleTitle}
                    />
                  ))}
                </Box>
                <Typography style={styleRowItem} fontSize={17} fontWeight={700}>
                  THÔNG TIN HỒ SƠ
                </Typography>
                {initRender.map((v, i) => (
                  <RowItem
                    hidden={
                      isShowData2 ? v?.hidden || v.id > 4 : v?.hidden || false
                    }
                    title={v.title}
                    value={v.value}
                    wrap={v.wrap}
                  />
                ))}
                <Box hidden={isShowData2}>
                  <Typography
                    style={styleRowItem}
                    fontSize={17}
                    fontWeight={700}
                  >
                    CHỨNG TỪ
                  </Typography>
                  {uploadRender?.map((v, i) => {
                    const listImg = (detail?.elements || []).find(
                      (el) => v.typeId === el?.type_id
                    );

                    return (
                      <div
                        key={i}
                        style={{
                          padding: "0 12px",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            overflowX: "auto",
                            width: "100%",
                          }}
                        >
                          <Box
                            style={{
                              fontWeight: 500,
                              color: "#0B3C8A",
                              fontWeight: "600",
                              padding: "8px 0",
                              position: "sticky",
                              left: 0,
                            }}
                          >
                            {v?.title} <span style={{ color: "red" }}>*</span>
                          </Box>
                          <Box sx={{ display: "flex", width: "fit-content" }}>
                            {(Array.isArray(listImg?.extra_info?.documents)
                              ? listImg?.extra_info?.documents
                              : listImg?.documents || []
                            )?.length > 0 ? (
                              (Array.isArray(listImg?.extra_info?.documents)
                                ? listImg?.extra_info?.documents
                                : listImg?.documents || []
                              )?.map((ell) => (
                                <Box
                                  sx={{
                                    width: "90px",
                                    height: "90px",
                                    border: "1px dotted rgba(0, 0, 0, 0.19) ",
                                    borderRadius: "16px",
                                    padding: "1px",
                                    marginRight: "12px",
                                    marginBottom: "12px",
                                  }}
                                >
                                  <img
                                    src={ell.path}
                                    width={"100%"}
                                    height={"100%"}
                                    style={{ borderRadius: "16px" }}
                                  />
                                </Box>
                              ))
                            ) : (
                              <Box
                                sx={{
                                  width: "100%",
                                  color: "red",
                                  fontStyle: "italic",
                                }}
                              >
                                Không có hình ảnh
                              </Box>
                            )}
                          </Box>
                        </div>
                      </div>
                    );
                  })}
                  <Typography
                    style={styleRowItem}
                    fontSize={17}
                    fontWeight={700}
                  >
                    THÔNG TIN NGƯỜI THỤ HƯỞNG
                  </Typography>
                  {beneficiaryRender.map((v, i) => (
                    <Box key={i} hidden={Boolean(v.hidden)}>
                      <RowItem
                        id={v.id}
                        hidden={v.hidden}
                        title={v.title}
                        value={v.value}
                        styleTitle={v.styleTitle}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </ItemDropDown>

            <Box sx={{ ...contentBox, ...agreementBox }}>
              <CTCheckbox
                checked={true}
                style={{ alignItems: "flex-start" }}
                disable={true}

                // onChange={(e) => setIsChecked(!isChecked)}
              />
              <Typography
                style={{
                  fontWeight: "500",
                  textAlign: "justify",
                  color: "black",
                }}
              >
                Tôi đã đọc kỹ và chấp nhận tất cả
                <span
                  onClick={() =>
                    setRuleModalOpen({
                      title: "Quy tắc Bảo hiểm",
                      open: true,
                      url: "https://drive.google.com/file/d/1b13jAOsABri26S1El2_YbSB704i4iwAZ/preview",
                    })
                  }
                  style={{
                    color: "#1890ff",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  các điều khoản trong Quy tắc Bảo hiểm
                </span>{" "}
                của Công ty bảo hiểm. Tôi cam đoan tất cả các thông tin khai báo
                ở trên là đúng sự thật và hiểu rằng bất kỳ thông tin nào khai
                báo không đúng sự thật có thể sẽ dẫn đến việc Giấy chứng nhận
                bảo hiểm/ Hợp đồng bảo hiểm mất hiệu lực và/hoặc Bảo hiểm Công
                ty bảo hiểm có quyền từ chối một phần hoặc toàn bộ trách nhiệm
                bồi thường. Tôi đồng ý sẽ thông báo cho Công ty bảo hiểm về bất
                kỳ sự thay đổi thông tin nào (nếu có).
                <div>
                  Tôi đồng ý để cho bác sỹ, bệnh viện hoặc cơ sở y tế đã điều
                  trị hoặc tư vấn sức khoẻ cho tôi được cung cấp cho Công ty bảo
                  hiểm mọi thông tin liên quan đến sức khoẻ của tôi nếu có sự
                  nghi vấn, nhằm đáp ứng các yêu cầu về giải quyết quyền lợi bảo
                  hiểm.
                </div>
              </Typography>
            </Box>
            <Box style={{ display: "flex" }}>
              <Box style={titleSignature}>Chữ ký của bạn</Box>
            </Box>
            <Box style={signatureStyle}>
              <img
                alt="signature"
                src={signature?.path || ""}
                style={{ objectFit: "contain" }}
              />
            </Box>
          </>
        </Content>
      </ContentWrapper>
      <RuleModal
        isOpen={ruleModalOpen?.open}
        title={ruleModalOpen?.title}
        setOpen={setRuleModalOpen}
        url={ruleModalOpen?.url}
      />
      {/* <ModalSuccess {...propsModalSuccess} /> */}
    </Wrapper>
  );
};

export default DetailPBA;

const RowItem = ({
  hidden,
  id = "",
  title = "",
  value = "",
  wrap = "",
  styleTitle,
}) => {
  return (
    <>
      <Box hidden={Boolean(hidden)}>
        <Box style={styleRowItem}>
          <Box style={styleTitle || styleTitleItem}>{title}</Box>
          <Box hidden={wrap} style={{ flex: 1, textAlign: "right" }}>
            <span style={{ textTransform: id == 1 ? "capitalize" : "none" }}>
              {value}
            </span>
          </Box>
        </Box>
        <Box
          hidden={!wrap}
          style={{
            border: "0.5px solid rgba(0, 0, 0, 0.17)",
            borderRadius: "12px",
            margin: "6px 12px",
            padding: "6px 12px",
            minHeight: "70px",
          }}
        >
          {value}
        </Box>
      </Box>
    </>
  );
};

const RuleModal = ({ title, url, isOpen, setOpen }) => {
  const close = () => {
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={isOpen}
      centered={true}
    >
      <div style={styleModal}>
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <div className="font-weight-600 font-size-16">{title}</div>
          <div style={{ textAlign: "right" }} onClick={close}>
            <img src="https://product.globalcare.vn/core_tnds+vcx_oto/close-button.svg" />
          </div>
        </div>

        <div style={{ marginTop: 10 }}>
          <iframe
            src={url}
            style={{
              height: "80vh",
              width: "100%",
              overflow: "hidden",
              border: "none",
            }}
          />
        </div>

        <div style={{ marginTop: 10 }} className="grid-container-1">
          <div
            style={{
              textAlign: "center",
              color: "#0B3C8A",
              border: "1px #0B3C8A solid",
              borderRadius: 8,
              padding: 12,
            }}
            className="font-weight-600"
            onClick={close}
          >
            Huỷ
          </div>
        </div>
      </div>
    </Modal>
  );
};

const styleModal = {
  padding: "1rem",
  position: "absolute",
  border: "none",
  borderRadius: "8px",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "auto",
  background: "white",
};

const titleSignature = {
  flex: 1,
  fontSize: 17,
  color: "#0B3C8A",
  fontWeight: "700",
  margin: "14px 0 14px 0",
};

const styleTitleItem = {
  flex: 1,
  fontSize: 15,
  color: "#024281",
  fontWeight: "600",
  marginRight: 10,
};

const styleDropDown = {
  boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.08)",
  padding: "10px 0 10px 0",
  width: "100%",
};

const agreementBox = {
  display: "flex",
  "& > p": {
    fontSize: "13px",
    lineHeight: "22px",
    padding: "0 12px",
  },
  "& .MuiSvgIcon-root path": {
    color: "#0B3C8A",
  },
};

const formInfo = {
  margin: "0px 0 12px 0",
};

const contentBox = {
  marginTop: "25px",
};

const styleRowItem = {
  display: "flex",
  padding: "6px 12px 6px 12px",
};

const signatureStyle = {
  width: "100%",
  height: 168,
  border: "1px solid rgba(151, 151, 151, 0.24)",
  borderRadius: 10,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};
