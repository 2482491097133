import React from "react";
import { Tabs, Tab } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { detailBLVP } from "../../styles/styleDetailBLVP";
import ContractInfomationBLVP from "./contractInfomationBLVP";
import Documents from "./documents";
import ApprovalHistory from "./approvalHistory";

const labelTabs = ["Thông tin hợp đồng BH", "Chứng từ", "Lịch sử xử lý HS"];

export default function TabsDetailBLVP(props) {
  const classes = detailBLVP();
  const { state, dispatch } = props;
  const {
    tabsDetail,
    dialogNoti,
    detail,
    rowsInsuranceBenefit,
    dialogInsuranceInfo,
  } = state;

  const renderTab = ({ tabKey }) => {
    switch (tabKey) {
      case "contract_infomation":
        return <ContractInfomationBLVP {...{ state, dispatch }} />;
      case "documents":
        return <Documents {...{ state, dispatch }} />;
      case "approval_history":
        return <ApprovalHistory {...{ state, dispatch }} />;

      default:
        return null;
    }
  };

  return (
    <div className={classes.containerTabsDetail}>
      <Tabs
        className={classes.tabsDetail}
        value={tabsDetail?.value}
        onChange={(e, value) => {
          dispatch({ type: "onChangeTabsDetail", value });
        }}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="basic tabs example"
      >
        {labelTabs.map((item, index) => (
          <Tab
            label={
              <span style={{ display: "flex", alignItems: "center" }}>
                {item} <ArrowRightIcon />
              </span>
            }
            key={item}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      <div className="box_render_tab">
        {renderTab({
          tabKey: tabsDetail.key,
        })}
      </div>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
