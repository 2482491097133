import React from "react";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";

import { detailProduct } from "../styles/styleDetailProduct";

export default function ProductPackage(props) {
  const classes = detailProduct();
  const history = useHistory();

  const { state, dispatch, id } = props;
  const { productPackages, productPackage } = state;
  const user = JSON.parse(localStorage.getItem("user")) || {};

  return (
    <div className={classes.container_product_packake}>
      <div className={classes.title_product_package}>
        <span>Gói sản phẩm</span>
        {user?.role_management_claim?.privileges?.PRODUCT_MANAGE === 1 && (
          <IconButton
            sx={{
              border: "1px solid #0B7CE4",
              borderRadius: "6px",
              padding: "2px",
            }}
            onClick={() =>
              history.push("/admin/product-management/detail-update/new", {
                product_id: id,
              })
            }
          >
            <AddIcon fontSize="small" sx={{ color: "#0B7CE4" }} />
          </IconButton>
        )}
      </div>
      <div className={classes.box_label_product}>
        {productPackages.map((item, index) => (
          <div
            className={
              productPackage?.indexActive === index
                ? "label-product active"
                : "label-product"
            }
            key={`item_product_packeges_${item?.id}`}
            onClick={() =>
              dispatch({
                type: "onClickProductPackage",
                item,
                index,
              })
            }
          >
            {item.title || ""}
          </div>
        ))}
      </div>
    </div>
  );
}
