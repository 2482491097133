import React, { useEffect, useLayoutEffect } from "react";
import _ from "lodash";
import { IconButton, TableCell } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import NumberFormat from "react-number-format";

import { TextField } from "../../overrideMui";
import { CheckBox } from "../../overrideMui";
import CommonTable from "../../../../../../library/layout/commonTable";
import styleMedical from "./styleMedicalTable";
import chevronDown from "../../../../../../assets/health/chevron-down.svg";
import addSquare from "../../../../../../assets/health/add-square.svg";
import minusSquare from "../../../../../../assets/health/minus-square.svg";

export default function MedicalTable(props) {
  const { state, dispatch, name, level_id = false } = props;
  const classes = styleMedical();
  const {
    rowsExamination,
    rowsBills,
    rowsMedicines,
    rowsOtherBenefits,
    checkedAllMedical,
    checkedAllBill,
    checkedAllPrescription,
    examinationFeesTotal,
    billsTotal,
    medicineFeesTotal,
    othersTotal,
    errors,
    dialogCodeBenefit,
    dialogUpdate,
    detail,
  } = state;

  // const columns = name === "benefitsExpan" ? columnsbenefit : columnsMedica;
  const columns = () => {
    switch (name) {
      case "benefitsExpan":
        return columnsbenefit;

      case "medicalExpan":
      case "bill":
        return columnsMedica;

      case "prescriptionExpan":
        return columnsMedicineName;

      default:
        return [];
    }
  };
  const rows = () => {
    switch (name) {
      case "medicalExpan":
        return {
          rows: rowsExamination,
          fees: examinationFeesTotal,
          error: errors?.medical,
        };
      case "bill":
        return {
          rows: rowsBills,
          fees: billsTotal,
          error: errors?.bill,
        };
      case "prescriptionExpan":
        return {
          rows: rowsMedicines,
          fees: medicineFeesTotal,
          error: errors?.prescription,
        };
      case "benefitsExpan":
        return {
          rows: rowsOtherBenefits,
          fees: othersTotal,
          error: errors?.benefits,
        };

      default:
        return {};
    }
  };

  useEffect(() => {
    document.getElementById(`${name}-box`).style.height = state[name]
      ? 0
      : `${
          document?.getElementById(`${name}`)?.getBoundingClientRect()?.height +
          26
        }px`;
  }, [
    rowsExamination?.length,
    rowsBills?.length,
    rowsMedicines?.length,
    rowsOtherBenefits?.length,
    state[name],
    !!dialogCodeBenefit?.open,
    !!dialogUpdate?.open,
  ]);

  useLayoutEffect(() => {
    function updateSize() {
      document.getElementById(`${name}-box`).style.height = state[name]
        ? 0
        : `${
            document?.getElementById(`${name}`)?.getBoundingClientRect()
              ?.height + 26
          }px`;
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div
      className={classes.container_diagnosis}
      id={`${name}-box`}
      style={{
        opacity: state[name] ? 0 : 1,
        padding: state[name] ? 0 : "",
        margin: state[name] ? "8px 0" : "",
        MozTransition: "all 0.7s ease",
        WebkitTransition: "all 0.7s ease",
        OTransition: "all 0.7s ease",
        transition: "all 0.7s ease",
      }}
    >
      <div id={name}>
        <CommonTable
          classes={classes}
          rows={rows().rows || []}
          columns={columns()}
          data={{
            name: name,
            checkedAllMedical,
            checkedAllBill,
            checkedAllPrescription,
            error: rows().error,
            level_id,
            detail,
          }}
          event={(type, data) =>
            (ele, ...rest) => {
              dispatch({ type, data, ele, rest });
            }}
        />
        <Grid container className={classes.gridBottom}>
          <Grid item xs={7.5}>
            <div className="sum">Tổng phí</div>
          </Grid>
          <Grid item xs={4.5}>
            <div className="money">
              {`${rows().fees || 0}`
                .replace(/\./g, ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const columnsMedicineName = [
  {
    renderHeader: ({
      data: { checkedAllMedical, checkedAllPrescription, name, checkedAllBill },
      event,
    }) => (
      <TableCell className="check-box">
        <CheckBox
          key={name}
          checked={
            name === "medicalExpan"
              ? !!checkedAllMedical
              : name == "bill"
              ? !!checkedAllBill
              : !!checkedAllPrescription
          }
          onChange={event("checkAllTable", { name })}
        />
      </TableCell>
    ),
    renderCell: ({ item, event, data: { name }, rowIdx }) => (
      <TableCell className="check-box">
        <CheckBox
          key={name}
          checked={!!item.checked}
          onChange={event("checkItemTable", { name, index: rowIdx })}
        />
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data }) => (
      <TableCell className="medicine-code">Mã thuốc</TableCell>
    ),
    renderCell: ({ item, data }) => (
      <TableCell
        className="medicine-code"
        style={{
          color: data.error && _.isEmpty(item?.codeBenefit) ? "red" : "",
        }}
      >
        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          {!!item?.codeBenefit && (
            <div
              title={item?.titleBenefit}
              style={{
                marginRight: "8px",
                padding: "0 12px",
                background: "#D6DEEB",
                borderRadius: "18px",
                display: "flex",
                width: "44%",
                height: "26px",
                maxWidth: "400px",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "6px" }}>
                {item?.codeBenefit || ""}
              </span>
              <div
                style={{
                  fontWeight: "600",
                  color: "#4B4B4B",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item?.titleBenefit || ""}
              </div>
            </div>
          )}
          <div>{item?.medicineCode || ""}</div>
        </div>
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data }) => (
      <TableCell className="service-name">Tên thuốc</TableCell>
    ),
    renderCell: ({ item, data }) => (
      <TableCell
        className="name-service"
        style={{
          color: data.error && _.isEmpty(item?.codeBenefit) ? "red" : "",
        }}
      >
        {/* <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          {!!item?.codeBenefit && (
            <div
              title={item?.titleBenefit}
              style={{
                marginRight: "8px",
                padding: "0 12px",
                background: "#D6DEEB",
                borderRadius: "18px",
                display: "flex",
                width: "44%",
                height: "26px",
                maxWidth: "400px",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "6px" }}>
                {item?.codeBenefit || ""}
              </span>
              <div
                style={{
                  fontWeight: "600",
                  color: "#4B4B4B",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item?.titleBenefit || ""}
              </div>
            </div>
          )}
          <div>
            {["medicalExpan", "bill"].includes(data.name)
              ? item.serviceName || ""
              : item.medicineName || ""}
          </div>
        </div> */}
        {item.medicineName || ""}
      </TableCell>
    ),
  },
  {
    label: "Số lượng",
    headerClass: "quantity",
    renderCell: ({ rowIdx, item, data }) => (
      <TableCell
        className="quantity"
        style={{
          color: data.error && _.isEmpty(item?.codeBenefit) ? "red" : "",
        }}
      >
        {item?.amount}
      </TableCell>
    ),
  },
  {
    label: "Đơn giá",
    headerClass: "unit-price",
    renderCell: ({ rowIdx, item, data }) => (
      <TableCell
        className="unit-price"
        style={{
          color: data.error && _.isEmpty(item?.codeBenefit) ? "red" : "",
        }}
      >
        {`${item.price || ""}`
          .replace(/\./g, ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      </TableCell>
    ),
  },
  {
    label: "Thành tiền",
    headerClass: "money",
    renderCell: ({ rowIdx, item, data }) => (
      <TableCell
        className="money"
        style={{
          color: data.error && _.isEmpty(item?.codeBenefit) ? "red" : "",
        }}
      >
        {`${item.fee || ""}`
          .replace(/\./g, ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      </TableCell>
    ),
  },
  {
    label: "BH khác",
    headerClass: "money",
    renderCell: ({ rowIdx, item, data }) => (
      <TableCell
        className="money"
        style={{
          color: data.error && _.isEmpty(item?.codeBenefit) ? "red" : "",
        }}
      >
        {`${item.paidFee || "--"}`
          .replace(/\./g, ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      </TableCell>
    ),
  },
];

const columnsMedica = [
  {
    renderHeader: ({
      data: { checkedAllMedical, checkedAllPrescription, name, checkedAllBill },
      event,
    }) => (
      <TableCell className="check-box">
        <CheckBox
          key={name}
          checked={
            name === "medicalExpan"
              ? !!checkedAllMedical
              : name == "bill"
              ? !!checkedAllBill
              : !!checkedAllPrescription
          }
          onChange={event("checkAllTable", { name })}
        />
      </TableCell>
    ),
    renderCell: ({ item, event, data: { name }, rowIdx }) => (
      <TableCell className="check-box">
        <CheckBox
          key={name}
          checked={!!item.checked}
          onChange={event("checkItemTable", { name, index: rowIdx })}
        />
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data }) => (
      <TableCell className="service-name">Tên dịch vụ</TableCell>
    ),
    renderCell: ({ item, data }) => (
      <TableCell
        className="name-service"
        style={{
          color: data.error && _.isEmpty(item?.codeBenefit) ? "red" : "",
        }}
      >
        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          {!!item?.codeBenefit && (
            <div
              title={item?.titleBenefit}
              style={{
                marginRight: "8px",
                padding: "0 12px",
                background: "#D6DEEB",
                borderRadius: "18px",
                display: "flex",
                width: "44%",
                height: "26px",
                maxWidth: "400px",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "6px" }}>
                {item?.codeBenefit || ""}
              </span>
              <div
                style={{
                  fontWeight: "600",
                  color: "#4B4B4B",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item?.titleBenefit || ""}
              </div>
            </div>
          )}
          <div>
            {["medicalExpan", "bill"].includes(data.name)
              ? item.serviceName || ""
              : item.medicineName || ""}
          </div>
        </div>
      </TableCell>
    ),
  },
  {
    label: "Số lượng",
    headerClass: "quantity",
    renderCell: ({ rowIdx, item, data }) => (
      <TableCell
        className="quantity"
        style={{
          color: data.error && _.isEmpty(item?.codeBenefit) ? "red" : "",
        }}
      >
        {item?.amount}
      </TableCell>
    ),
  },
  {
    label: "Đơn giá",
    headerClass: "unit-price",
    renderCell: ({ rowIdx, item, data }) => (
      <TableCell
        className="unit-price"
        style={{
          color: data.error && _.isEmpty(item?.codeBenefit) ? "red" : "",
        }}
      >
        {`${item.price || ""}`
          .replace(/\./g, ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      </TableCell>
    ),
  },
  {
    label: "Thành tiền",
    headerClass: "money",
    renderCell: ({ rowIdx, item, data }) => (
      <TableCell
        className="money"
        style={{
          color: data.error && _.isEmpty(item?.codeBenefit) ? "red" : "",
        }}
      >
        {`${item.fee || ""}`
          .replace(/\./g, ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      </TableCell>
    ),
  },
  {
    label: "BH khác",
    headerClass: "money",
    renderCell: ({ rowIdx, item, data }) => (
      <TableCell
        className="money"
        style={{
          color: data.error && _.isEmpty(item?.codeBenefit) ? "red" : "",
        }}
      >
        {`${item.paidFee || "--"}`
          .replace(/\./g, ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      </TableCell>
    ),
  },
];

const columnsbenefit = [
  {
    renderHeader: () => <TableCell className="tabs" />,
    renderCell: ({ event, rowIdx, data }) => (
      <TableCell className="tabs">
        <IconButton
          disabled={data.level_id !== 1}
          style={{ padding: "4px" }}
          onClick={event("openDialogCodeBenefits", {
            index: rowIdx,
            name: "benefits",
          })}
        >
          <img src={chevronDown} />
        </IconButton>
      </TableCell>
    ),
  },
  {
    label: "Tên dịch vụ",
    headerClass: "name-service",
    renderCell: ({ rowIdx, item }) => (
      <TableCell className="name-service">
        <div style={{ display: "flex", width: "100%" }}>
          {!!item?.codeBenefit && (
            <div
              style={{
                marginRight: "12px",
                padding: "0 12px",
                background: "#D6DEEB",
                borderRadius: "18px",
                display: "flex",
                height: "26px",
              }}
            >
              <span style={{ marginRight: "6px" }}>
                {item?.codeBenefit || ""}
              </span>
            </div>
          )}
          {item?.titleBenefit || ""}
        </div>
      </TableCell>
    ),
  },
  {
    label: "Số lượng",
    headerClass: "quantity",
    renderCell: ({ rowIdx, classes, event, data, item }) => (
      <TableCell className="quantity">
        <NumberFormat
          disabled={data.level_id !== 1}
          customInput={TextField}
          className={classes.textField}
          onChange={event(`benefitsOnChangText`, {
            index: rowIdx,
            name: "numberOfDays",
          })}
          value={item.numberOfDays || ""}
          fullWidth
        />
      </TableCell>
    ),
  },
  {
    label: "Đơn giá",
    headerClass: "unit-price",
    renderCell: ({ rowIdx, classes, event, data, item }) => (
      <TableCell className="unit-price">
        <NumberFormat
          disabled={data.level_id !== 1}
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          className={classes.textField}
          onChange={event(`benefitsOnChangText`, {
            index: rowIdx,
            name: "price",
          })}
          value={item.price || ""}
          fullWidth
        />
      </TableCell>
    ),
  },
  {
    label: "Thành tiền",
    headerClass: "money",
    renderCell: ({ rowIdx, classes, event, data, item }) => (
      <TableCell className="money">
        <NumberFormat
          disabled={data.level_id !== 1}
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          className={classes.textField}
          onChange={event(`benefitsOnChangText`, {
            index: rowIdx,
            name: "fee",
          })}
          value={item.fee || ""}
          fullWidth
        />
      </TableCell>
    ),
  },
  {
    renderHeader: ({ event, data }) => (
      <TableCell className="icon">
        <IconButton
          disabled={
            data.level_id !== 1 ||
            [5, 6, 8, 9, 10].includes(data?.detail?.statusId)
          }
          style={{ padding: "4px" }}
          onClick={event("addRowsTableUpdate", { name: "benefits" })}
        >
          <img src={addSquare} />
        </IconButton>
      </TableCell>
    ),
    renderCell: ({ rowIdx, event, data }) => (
      <TableCell className="icon">
        <IconButton
          disabled={data.level_id !== 1}
          style={{ padding: "4px" }}
          onClick={event("deleteRowsTableUpdate", {
            name: "benefits",
            index: rowIdx,
          })}
        >
          <img src={minusSquare} />
        </IconButton>
      </TableCell>
    ),
  },
];
