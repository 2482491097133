import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogSetReload({ dialog, setDialog }) {
  const classes = useMakeStyle();

  const handleClose = () => {
    setDialog({ ...dialog, open: false });
  };

  const handleClick = () => {
    dialog.version &&
      localStorage.setItem("meta_version", JSON.stringify(dialog.version));
    setDialog({ ...dialog, open: false });
    window.location.reload();
  };

  return (
    <Dialog
      open={dialog?.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Hiện tại đã có bản cập nhật mới, hãy tải lại trang để cập nhật
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button onClick={handleClose} className="btn no">
          Không
        </Button>
        <Button className="btn ok" onClick={handleClick}>
          Tải lại
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useMakeStyle = makeStyles(() => ({
  action: {
    justifyContent: "center",

    "& .btn": {
      textTransform: "none",
      border: "1px solid",
      borderRadius: "18px",
      width: "40%",
    },

    "& .ok": {
      background: "#3961A0",
      color: "white",
    },

    "& .ok::hover": {
      background: "#3961A0",
      color: "white",
    },
  },
  //
}));
