import React from "react";
import moment from "moment";
import { STATUS } from "../../../utils/data";

const getStatusTitle = (id) => {
  let statusTitle = STATUS.filter((status) => +status?.id === +id)[0]?.title;

  return statusTitle;
};

function ExportBusinessTable(props) {
  return (
    <div style={{ display: "none" }}>
      <table
        className="collapse-border"
        id={"business-excel"}
        data-cols-width="10, 35, 30, 40,40, 25, 30, 30, 30, 30, 40, 30, 20, 25, 20,20, 25,35, 25, 30, 35"
      >
        <thead style={{ fontSize: "13" }}>
          <tr>
            <th
              colSpan={26}
              data-f-name={"Times new roman"}
              data-f-sz={"24"}
              data-a-wrap={true}
              data-a-h={"center"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              BÁO CÁO DANH SÁCH ĐƠN HÀNG TỪ NGÀY{" "}
              {props.fromDate?.format("DD/MM/YYYY")} ĐẾN NGÀY{" "}
              {props.toDate?.format("DD/MM/YYYY")}
            </th>
          </tr>
          <tr style={{ fontSize: "13" }}>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              STT
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Mã giỏ hàng
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Mã Đơn hàng
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Loại bảo hiểm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              CÔNG TY BH
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Kênh bán
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Vị trí
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              ID Sale
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Tên sale
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Số hợp đồng bảo hiểm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Tên khách hàng
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Ngày sinh
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              CMND
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Địa chỉ
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Điện thoại
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Email
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Ngày tạo đơn
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Trạng thái
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Thời gian thanh toán phí
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Hiệu lực bảo hiểm
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Tổng phí bảo hiểm (VNĐ)
            </th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "13" }}>
          {props.data.map((item, index) => {
            return (
              <tr key={index} style={{ fontSize: "13" }}>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-t="n"
                >
                  {index + 1}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.transaction_code}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.code}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.product_title}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.provider_title}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.merchant_title}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.sale_level_title}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.user_id}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.sale_name}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.cert_num}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.insured_info?.insuredName}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.insured_info?.insuredDob}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.insured_info?.insuredPrivateId}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.insured_info?.insuredAddress}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.insured_info?.insuredPhone}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.insured_info?.insuredEmail}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {moment(item.created_date).format("DD/MM/YYYY")}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {getStatusTitle(item.status_id)}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item.transaction_date
                    ? moment(item.transaction_date).format("HH:mm DD/MM/YYYY")
                    : ""}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {moment(item.begin_date).format("DD/MM/YYYY")} -{" "}
                  {moment(item.end_date).format("DD/MM/YYYY")}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-t="n"
                  data-num-fmt={+item.fees === 0 ? "0" : "#,###"}
                >
                  {item.fees}
                </td>
              </tr>
            );
          })}

          <tr>
            <td
              colSpan={20}
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-a-wrap={true}
              data-a-h={"right"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Tổng cộng
            </td>
            <td
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-t="n"
              data-num-fmt={+props.totalCivilFee === 0 ? "0" : "#,###"}
            >
              {props.totalFees}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(ExportBusinessTable);
