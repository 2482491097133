import { Button, Dialog, DialogContent, MenuItem, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import * as React from "react";
import { TextField } from "../overrideMui";
import { genders, insured_types } from "../util";
import moment from "moment";
import { isEmpty } from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogAddUserCreate(props) {
  const classes = useStyle();
  const { state, dispatch } = props;
  const { dialogAddUser, addUserData, errAddUser } = state;

  return (
    <div>
      <Dialog
        open={Boolean(dialogAddUser?.open == "handmade")}
        TransitionComponent={Transition}
        onClose={() => dispatch("closeDialogUserCreateGCN")}
      >
        <DialogContent>
          <div className={classes.heade_title}>
            Thông tin người được bảo hiểm
          </div>
          <div>
            <div className={classes.box_text}>
              <div className="label">
                Họ và tên <span style={{ color: "red" }}>*</span>
              </div>
              <TextField
                fullWidth
                placeholder="Nhập họ tên"
                value={addUserData?.insuredName || ""}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeAdd",
                    name: "insuredName",
                    value: e.target.value,
                  })
                }
              />
              {errAddUser.includes("insuredName") && (
                <div className={classes.redTxt}>
                  Vui lòng nhập đầy đủ thông tin
                </div>
              )}
            </div>
          </div>
          <div className={classes.gird}>
            <div className={`${classes.box_text} grid-box mr-12`}>
              <div className="label">
                Ngày sinh <span style={{ color: "red" }}>*</span>
              </div>
              <DatePicker
                value={
                  !isEmpty(addUserData?.insuredDob)
                    ? moment(addUserData?.insuredDob).toDate()
                    : null
                }
                inputFormat="dd/MM/yyyy"
                placeholder="Chọn ngày sinh"
                onChange={(date) => {
                  dispatch({
                    type: "onChangeAdd",
                    name: "insuredDob",
                    value: moment(date).format("YYYY/MM/DD"),
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              {errAddUser.includes("insuredDob") && (
                <div className={classes.redTxt}>
                  Vui lòng nhập đầy đủ thông tin
                </div>
              )}
            </div>
            <div className={`${classes.box_text} grid-box`}>
              <div className="label">
                Giới tính <span style={{ color: "red" }}>*</span>
              </div>
              <TextField
                fullWidth
                select
                value={addUserData?.insuredGender}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeAdd",
                    name: "insuredGender",
                    value: e.target.value,
                  })
                }
              >
                {genders.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
              {errAddUser.includes("insuredGender") && (
                <div className={classes.redTxt}>
                  Vui lòng nhập đầy đủ thông tin
                </div>
              )}
            </div>
          </div>
          {/* <div className={classes.box_text}>
            <div className="label">Số điện thoại</div>
            <TextField
              fullWidth
              placeholder="Nhập số điện thoại"
              value={addUserData?.insuredPhone || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChangeAdd",
                  name: "insuredPhone",
                  value: e.target.value,
                })
              }
            />
          </div> */}
          <div className={classes.box_text}>
            <div className="label">
              CMND/ CCCD/ Hộ chiếu <span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              placeholder="Nhập số CMND"
              value={addUserData?.insuredPrivateId || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChangeAdd",
                  name: "insuredPrivateId",
                  value: e.target.value,
                })
              }
            />
            {errAddUser.includes("insuredPrivateId") && (
              <div className={classes.redTxt}>
                Vui lòng nhập đầy đủ thông tin
              </div>
            )}
          </div>
          <div className={classes.gird}>
            <div className={`${classes.box_text} grid-box mr-12`}>
              <div className="label">
                Nhân viên/ Người thân <span style={{ color: "red" }}>*</span>
              </div>
              <TextField
                fullWidth
                select
                value={addUserData?.insuredType || null}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeAdd",
                    name: "insuredType",
                    value: e.target.value,
                  })
                }
              >
                {insured_types.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
              {errAddUser.includes("insuredType") && (
                <div className={classes.redTxt}>
                  Vui lòng nhập đầy đủ thông tin
                </div>
              )}
            </div>
            <div className={`${classes.box_text} grid-box`}>
              <div className="label">
                Số thẻ bảo hiểm <span style={{ color: "red" }}>*</span>
              </div>
              <TextField
                fullWidth
                placeholder="Nhập số thẻ bảo hiểm"
                value={addUserData?.certNum || ""}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeAdd",
                    name: "certNum",
                    value: e.target.value,
                  })
                }
              />
              {errAddUser.includes("certNum") && (
                <div className={classes.redTxt}>
                  Vui lòng nhập đầy đủ thông tin
                </div>
              )}
            </div>
          </div>
          <div className={classes.gird}>
            <div className={`${classes.box_text} grid-box mr-12`}>
              <div className="label">
                Ngày bắt đầu <span style={{ color: "red" }}>*</span>
              </div>
              <DatePicker
                value={
                  !isEmpty(addUserData?.beginDate)
                    ? moment(addUserData?.beginDate).toDate()
                    : null
                }
                inputFormat="dd/MM/yyyy"
                placeholder="Chọn ngày bắt đầu"
                onChange={(date) => {
                  dispatch({
                    type: "onChangeAdd",
                    name: "beginDate",
                    value: moment(date).format("YYYY/MM/DD"),
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              {errAddUser.includes("beginDate") && (
                <div className={classes.redTxt}>
                  Vui lòng nhập đầy đủ thông tin
                </div>
              )}
            </div>
            <div className={`${classes.box_text} grid-box`}>
              <div className="label">
                Ngày kết thúc <span style={{ color: "red" }}>*</span>
              </div>
              <DatePicker
                value={
                  !isEmpty(addUserData?.endDate)
                    ? moment(addUserData?.endDate).toDate()
                    : null
                }
                inputFormat="dd/MM/yyyy"
                placeholder="Chọn ngày kết thúc"
                onChange={(date) => {
                  dispatch({
                    type: "onChangeAdd",
                    name: "endDate",
                    value: moment(date).format("YYYY/MM/DD"),
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              {errAddUser.includes("endDate") && (
                <div className={classes.redTxt}>
                  Vui lòng nhập đầy đủ thông tin
                </div>
              )}
            </div>
          </div>
          <div className={classes.buttons}>
            <Button
              className="btn btn-cancel"
              onClick={() => dispatch("closeDialogUserCreateGCN")}
            >
              Bỏ qua
            </Button>
            <Button
              className="btn btn-update"
              onClick={() => dispatch("addUser")}
            >
              Cập nhật
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyle = makeStyles(() => ({
  container: {},

  heade_title: {
    fontSize: "18px",
    color: "#4B4B4B",
    fontWeight: "700",
    margin: "12px 0 18px 0",
  },

  box_text: {
    fontSize: "14px",
    color: "#4B4B4B",
    marginBottom: "12px",

    "& .label": {
      marginBottom: "8px",
    },
  },

  gird: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "wrap",

    "& .grid-box": {
      width: "48%",
      minWidth: "250px",
      maxWidth: "350px",
    },

    "& .mr-12": {
      marginRight: "12px",
    },
  },

  redTxt: {
    color: "red",
    fontSize: "12px",
    marginTop: "4px",
  },

  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
    marginTop: "12px",

    "& .btn": {
      padding: "6px 18px",
      fontWeight: "400",
      fontSize: "14px",
    },

    "& .btn-cancel": {
      color: "#3961A0",
      border: "1px solid #DCDCDC",
      marginRight: "12px",
    },

    "& .btn-update": {
      color: "white",
      background: "#3961A0",
      marginRight: "12px",
    },
  },

  //
}));
