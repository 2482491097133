import React, { useEffect, useState } from "react";

import HeadFilter from "./components/headFilter";

import useReducer from "../../../library/hooks/useReducer";
import { dashboardStyle } from "./style/styleDashboard";
import FlagCard from "./components/flagCard";
import ChartCard from "./components/chartCard";
import LoadingMui from "../../../ui/LoadingMui";
import Snackbars from "../../../ui/snackbar";
import TableReport from "./components/tableReport";
import ModalFilterDate from "./components/modalFilterDate";
import { initialState } from "./utils";
import dashboardReducer from "./dashboardReducer";
import DialogListProductAndMerchant from "./components/dialogListProductAndMerchant";

export default function Dashboard() {
  const classes = dashboardStyle();
  const userFromProfile = JSON.parse(localStorage.getItem("user")) || {};

  const [state, dispatch] = useReducer({
    initialState,
    reducer: dashboardReducer,
    name: "dashboardReducer",
  });

  const { loading, snackbar } = state;

  useEffect(() => {
    dispatch("initail");
  }, []);

  return (
    <div className={classes.container}>
      <HeadFilter {...{ state, dispatch, userFromProfile }} />
      <FlagCard {...{ state, dispatch }} />
      <ChartCard {...{ state, dispatch }} />
      <TableReport {...{ state, dispatch }} />
      <ModalFilterDate {...{ state, dispatch }} />
      <DialogListProductAndMerchant {...{ state, dispatch }} />
      <LoadingMui loading={loading} />
      <Snackbars
        open={!!snackbar.open}
        type={snackbar.type}
        onClose={() => dispatch("closeSnackbar")}
        message={snackbar.message}
      />
    </div>
  );
}
