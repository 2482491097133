import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { _formatMoney, convertMoneyNumberToString } from "../utils";

import signature from "../../../../../assets/loyalty/sign.png";

Font.register({
  family: "times-new-roman",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman@1.0.4/Times New Roman.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-bold@1.0.4/Times New Roman Bold.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-italic@1.0.4/Times New Roman Italic.ttf",
      fontStyle: "italic",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-bold-italic@1.0.4/Times New Roman Bold Italic.ttf",
      fontStyle: "bold-italic",
    },
  ],
});

export default function ExportAcceptClaimPDF({ state }) {
  const { row, approvalAmount } = state;
  const { extraInfo } = row;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section_report}>
          <View style={styles.header}>
            <Text>THÔNG BÁO CHẤP NHẬN BỒI THƯỜNG</Text>
            <Text>và</Text>
            <Text>MIỄN TRỪ TRÁCH NHIỆM</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ marginRight: 10 }}>Kính gửi: </Text>
            <View style={styles.col}>
              <Text style={{ fontWeight: 600 }}>
                CÔNG TY CỔ PHẦN TƯ VẤN GLOBAL CARE
              </Text>
              <Text> 1/2 đường số 33, phường An Khánh, Tp. Thủ Đức</Text>
            </View>
          </View>
          <Text style={{ fontWeight: 600, marginTop: 10 }}>
            Về việc:
            <Text style={{ fontStyle: "italic" }}>
              {" "}
              Chấp nhận số tiền bồi thường đối với tổn thất do{" "}
              {extraInfo?.describe || "……………………………………………………"} xảy ra vào thời
              điểm {extraInfo?.riskTime}, ngày{" "}
              {moment(extraInfo?.riskDate).format("DD/MM/YYYY")} thuộc HĐBH số{" "}
              {row?.certNum || "…………………"}
            </Text>
          </Text>
          <View style={{ marginTop: 10 }}>
            <Text>
              {`Theo đề nghị giải quyết bồi thường của Quý Công ty căn cứ theo những điều kiện và điều khoản của hợp đồng bảo hiểm nêu trên, đại diện cho Người được bảo hiểm, chúng tôi chấp thuận số tiền bồi thường là: ${
                extraInfo?.productAmount
                  ? _formatMoney(approvalAmount || 0)
                  : "……………………………………"
              } (bằng chữ: ${
                approvalAmount
                  ? convertMoneyNumberToString(+approvalAmount)
                  : "……………………………………"
              }) là số tiền bồi thường chung cuộc và đầy đủ cho vụ tổn thất nêu trên.`}
            </Text>
          </View>
          <View style={{ marginTop: 10 }}>
            <Text>
              Đề nghị Quý Tổng Công Ty chuyển số tiền bồi thường nêu trên vào
              tài khoản của chúng tôi theo chi tiết sau:
            </Text>
            <View style={{ ...styles.col, marginLeft: 30 }}>
              <Text>
                • Người thụ hưởng:{" "}
                <Text style={styles.bold}>
                  CONG TY CO PHAN DICH VU TUC THOI
                </Text>
              </Text>
              <Text>
                • Số tài khoản: <Text style={styles.bold}>19131637055028</Text>
              </Text>
              <Text>
                • Ngân hàng:{" "}
                <Text style={styles.bold}>Ngân hàng Techcombank</Text>
              </Text>
              <Text>
                • Địa chỉ ngân hàng:{" "}
                <Text style={styles.bold}>CN Thắng Lợi</Text>
              </Text>
            </View>
          </View>
          <Text style={{ marginTop: 10 }}>
            Chúng tôi cam kết chúng tôi không có hợp đồng bảo hiểm nào khác cho
            các tổn thất nên trên và đồng ý giải trừ tất cả các trách nhiệm mà
            Quý Công ty có thể hoặc phải có liên quan đến tổn thất nói trên theo
            hợp đồng bảo hiểm, đồng thời hoàn lại khoản chiếm dụng mà tài xế
            chiếm dụng hoàn trả {"(nếu có)"}.
          </Text>
          <Text style={{ margin: "10px 0px" }}>
            Theo đó, chúng tôi đồng ý bảo lưu yếu tố thế quyền và hỗ trợ cung
            cấp các hồ sơ liên quan cần thiết cho Quý Công ty để thực hiện các
            công tác truy đòi đối với các pháp nhân liên quan đến sự kiện bảo
            hiểm nêu trên.
          </Text>
          <View style={styles.section_sign}>
            <View style={styles.sign}>
              <Text>Ngày …… tháng …… năm …………</Text>
              <Text>ĐẠI DIỆN NGƯỜI ĐƯỢC BẢO HIỂM</Text>
              <Text>TRƯỞNG PHÒNG KẾ TOÁN TÀI CHÍNH</Text>
              {/* <Image
                src={signature || ""}
                style={{
                  width: "100%",
                  marginTop: 15,
                }}
              /> */}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontFamily: "times-new-roman",
    fontSize: 11,
  },
  section_report: {
    margin: 30,
    lineHeight: 1.4,
  },
  header: {
    margin: "5px 0 20px",
    fontWeight: 600,
    fontSize: 13,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  bold: {
    fontWeight: 600,
    marginLeft: "10px",
  },
  section_sign: {
    display: "flex",
    fontSize: "11px",
    justifyContent: "end",
    textAlign: "right",
    position: "relative",
  },

  sign: {
    display: "flex",
    flexDirection: "column",
    fontSize: "11px",
    justifyContent: "center",
    textAlign: "center",
    position: "absolute",
    width: "45%",
    right: "10px",
  },
});
