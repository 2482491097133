import { Grid, Typography, Container } from "@mui/material";
import { Button, message, Select } from "antd";

import { DownOutlined } from "@ant-design/icons";

import {
  AddressIcon,
  BirthCakeIcon,
  BlueDotIcon,
  CalendarIcon,
  DownloadFileIcon,
  EmailIcon,
  MobilePhoneIcon,
  PrivateIdIcon,
  StatusIcon,
  TotalBenefitIcon,
  TotalInsureFeeIcon,
  UserIcon,
} from "../components/icon/icon";

import { useEffect, useState } from "react";
import BusinessReportTable from "../components/table/BusinessReport";
import { formatMoney } from "../utils/money";
import { useParams } from "react-router-dom";
import { getInsuredOrders, getInsuredPersons } from "../api/business";
import moment from "moment";
import { STATUS } from "../utils/type";
import axios from "axios";

const { Option } = Select;

function NotClaimed({ insuredPerson }) {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <Grid
        item
        container
        xs={12}
        style={{
          marginTop: 10,
        }}
      >
        <Grid
          item
          container
          spacing={1}
          style={{
            background: "#E9FBFF",
            borderRadius: "8px",
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
            padding: 15,
          }}
        >
          <Grid item xs={12}>
            <Typography
              fontWeight={700}
              // textTransform={'uppercase'}
              fontSize={20}
            >
              Thông tin hợp đồng
            </Typography>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <TotalBenefitIcon style={{ verticalAlign: "top" }} />
              <span> Tổng quyền lợi bảo hiểm</span>
            </Typography>
            <span style={{ fontSize: 15 }}>
              {formatMoney(insuredPerson?.total_product_amount || 0)}VNĐ
            </span>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <BirthCakeIcon style={{ verticalAlign: "top" }} />
              <span> Năm sinh</span>
            </Typography>
            <span style={{ fontSize: 15 }}>{insuredPerson?.dob || "N/A"}</span>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <EmailIcon style={{ verticalAlign: "top" }} />
              <span> Email</span>
            </Typography>
            <span style={{ fontSize: 15 }}>
              {insuredPerson?.insuredemail || "N/A"}
            </span>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <TotalInsureFeeIcon style={{ verticalAlign: "top" }} />
              <span> Tổng phí bảo hiểm</span>
            </Typography>
            <span style={{ fontSize: 15 }}>
              {formatMoney(insuredPerson?.total_fees || 0)}VNĐ
            </span>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <PrivateIdIcon style={{ verticalAlign: "top" }} />
              <span> CMND/CCCD</span>
            </Typography>
            <span style={{ fontSize: 15 }}>
              {insuredPerson?.insuredprivateid || "N/A"}
            </span>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <AddressIcon style={{ verticalAlign: "top" }} />
              <span> Địa chỉ</span>
            </Typography>
            <span style={{ fontSize: 15 }}>{"N/A"}</span>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <UserIcon style={{ verticalAlign: "top" }} />
              <span> Họ tên</span>
            </Typography>
            <span style={{ fontSize: 15, textTransform: "capitalize" }}>
              {insuredPerson?.insuredname}
            </span>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <MobilePhoneIcon style={{ verticalAlign: "top" }} />
              <span> Số điện thoại</span>
            </Typography>
            <span style={{ fontSize: 15 }}>
              {insuredPerson?.phonenumber || "N/A"}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function OrderCertInfo({ info, selectedOrder, onSelect = () => {} }) {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [open, setOpen] = useState(true);
  const [content, setContent] = useState(null);
  const [childHeight, setChildHeight] = useState(0);
  const [render, setRender] = useState(false);
  useEffect(() => {
    if (!content) return;

    setRender(true);
  }, [content]);

  useEffect(() => {
    if (!render) return;

    setChildHeight(`${+content.clientHeight / 16}rem`);
  }, [open, render]);

  const renderStatusOrder = () => {
    if (moment().isBefore(moment(info?.begin_date)))
      return (
        <span style={{ fontWeight: 500, color: "#6372DA" }}>Đang chờ</span>
      );

    if (moment().isAfter(moment(info?.end_date)))
      return <span style={{ fontWeight: 500, color: "#BF272D" }}>Hết hạn</span>;

    return <span style={{ fontWeight: 500, color: "#39B44A" }}>Hiệu lực</span>;
  };

  return (
    <div
      style={{
        padding: 10,
        background: "white",
        marginBottom: 10,
        border: `1px solid ${
          selectedOrder?.id === info?.id ? "orange" : "white"
        } `,
        borderRadius: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => setOpen(!open)}
      >
        <div
          style={{
            fontWeight: "bold",
            margin: "5px 0px",
          }}
        >
          <span
            style={{
              color: "#FB7800",
            }}
          >
            Giấy chứng nhận số:{" "}
          </span>
          <span
            style={{
              color: "#2A3790",
            }}
          >
            {info?.cert_num || "N/A"}
          </span>
        </div>
        <div>
          <DownOutlined
            style={{
              transition: "0.4 all linear",
              transform: `rotate(${open ? 0 : 180}deg)`,
            }}
          />
        </div>
      </div>

      <div
        style={{
          maxHeight: open ? childHeight : 0,
          overflow: "hidden",
          transition: "0.4s max-height",
          cursor: "pointer",
        }}
        onClick={() => {
          onSelect();
        }}
      >
        <div
          ref={(ref) => {
            setContent(ref);
          }}
          style={{
            overflow: "auto",
          }}
        >
          <div style={{ fontWeight: "bold" }}>
            <StatusIcon style={{ verticalAlign: "bottom" }} />
            <span style={{ padding: "0px 5px", color: "#2A3790" }}>
              Trạng thái:
            </span>
            {renderStatusOrder()}
          </div>

          <div
            style={{
              height: 2,
              background: "#D2D2D2",
              margin: "10px 0px",
            }}
          ></div>

          <div style={{ fontWeight: "bold", margin: "10px 0px" }}>
            <TotalBenefitIcon style={{ verticalAlign: "bottom" }} />
            <span style={{ padding: "0px 5px", color: "#2A3790" }}>
              Quyền lợi bảo hiểm:
            </span>
            <span style={{ fontWeight: 500 }}>
              {formatMoney(info?.product_amount || 0)}VNĐ{" "}
            </span>
          </div>

          <div style={{ fontWeight: "bold", margin: "10px 0px" }}>
            <TotalInsureFeeIcon style={{ verticalAlign: "bottom" }} />
            <span style={{ padding: "0px 5px", color: "#2A3790" }}>
              Phí bảo hiểm:
            </span>
            <span style={{ fontWeight: 500 }}>
              {formatMoney(info?.fees || 0)}VNĐ{" "}
            </span>
          </div>

          <div style={{ fontWeight: "bold", margin: "10px 0px" }}>
            <CalendarIcon style={{ verticalAlign: "bottom" }} />
            <span style={{ padding: "0px 5px", color: "#2A3790" }}>
              Ngày hiệu lực:
            </span>
            <span style={{ fontWeight: 500 }}>
              {moment(info?.begin_date).format("HH:mm DD/MM/YYYY")}{" "}
            </span>
          </div>

          <div style={{ fontWeight: "bold", margin: "10px 0px" }}>
            <CalendarIcon style={{ verticalAlign: "bottom" }} />
            <span style={{ padding: "0px 5px", color: "#2A3790" }}>
              Ngày hết hạn:
            </span>
            <span style={{ fontWeight: 500 }}>
              {moment(info?.end_date).format("HH:mm DD/MM/YYYY")}{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function ClaimInfo({ info }) {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [open, setOpen] = useState(true);
  const [content, setContent] = useState(null);
  const [childHeight, setChildHeight] = useState(0);
  const [render, setRender] = useState(false);
  useEffect(() => {
    if (!content) return;

    setRender(true);
  }, [content]);

  useEffect(() => {
    if (!render) return;

    setChildHeight(`${+content.clientHeight / 16}rem`);
  }, [open, render]);

  const renderStatusOrder = () => {
    if (moment().isBefore(moment(info?.begin_date)))
      return (
        <span style={{ fontWeight: 500, color: "#6372DA" }}>Đang chờ</span>
      );

    if (moment().isAfter(moment(info?.end_date)))
      return <span style={{ fontWeight: 500, color: "#BF272D" }}>Hết hạn</span>;

    return <span style={{ fontWeight: 500, color: "#39B44A" }}>Hiệu lực</span>;
  };

  return (
    <div style={{}}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => setOpen(!open)}
      >
        <div
          style={{
            fontWeight: "bold",
            margin: "5px 0px",
          }}
        >
          <BlueDotIcon style={{ marginRight: 5 }} />
          <span
            style={{
              color: "#FB7800",
            }}
          >
            Mã hồ sơ:{" "}
          </span>
          <span
            style={{
              color: "#2A3790",
            }}
          >
            {info?.code || "N/A"}
          </span>
        </div>
        <div>
          <DownOutlined
            style={{
              transition: "0.4 all linear",
              transform: `rotate(${open ? 0 : 180}deg)`,
            }}
          />
        </div>
      </div>

      <div
        style={{
          maxHeight: open ? childHeight : 0,
          overflow: "hidden",
          transition: "0.4s max-height",
          paddingLeft: 20,
          borderLeft: "1px solid #2A3790",
          marginLeft: 8,
        }}
      >
        <div
          ref={(ref) => {
            setContent(ref);
          }}
          style={{
            overflow: "auto",
          }}
        >
          <div style={{ fontWeight: "bold", margin: "10px 0px" }}>
            <CalendarIcon style={{ verticalAlign: "bottom" }} />
            <span style={{ padding: "0px 5px", color: "#2A3790" }}>
              Ngày tạo hồ sơ:
            </span>
            <span style={{ fontWeight: 500 }}>
              {moment(info?.created_date).format("HH:mm DD/MM/YYYY")}{" "}
            </span>
          </div>

          <div style={{ fontWeight: "bold", margin: "10px 0px" }}>
            <CalendarIcon style={{ verticalAlign: "bottom" }} />
            <span style={{ padding: "0px 5px", color: "#2A3790" }}>
              Ngày cập nhật:
            </span>
            <span style={{ fontWeight: 500 }}>
              {info?.updated_date
                ? moment(info?.updated_date).format("HH:mm DD/MM/YYYY")
                : "N/A"}{" "}
            </span>
          </div>

          <div style={{ fontWeight: "bold" }}>
            <StatusIcon style={{ verticalAlign: "bottom" }} />
            <span style={{ padding: "0px 5px", color: "#2A3790" }}>
              Trạng thái bồi thường:
            </span>
            <span
              style={{
                color: STATUS.filter(
                  (status) => +status.value === +info?.status_id
                )[0]?.color,
              }}
            >
              {
                STATUS.filter((status) => +status.value === +info?.status_id)[0]
                  ?.label
              }
            </span>
          </div>

          <div style={{ fontWeight: "bold", margin: "10px 0px" }}>
            <TotalBenefitIcon style={{ verticalAlign: "bottom" }} />
            <span style={{ padding: "0px 5px", color: "#2A3790" }}>
              Người xử lý hồ sơ:
            </span>
            <span style={{ fontWeight: 500, textTransform: "capitalize" }}>
              {info?.reviewer_full_name}{" "}
            </span>
          </div>

          <div style={{ fontWeight: "bold", margin: "10px 0px" }}>
            <TotalInsureFeeIcon style={{ verticalAlign: "bottom" }} />
            <span style={{ padding: "0px 5px", color: "#2A3790" }}>
              Số tiền bồi thường:
            </span>
            <span style={{ fontWeight: 500 }}>
              {formatMoney(info?.amount || 0)}VNĐ{" "}
            </span>
          </div>

          <div style={{ fontWeight: "bold", margin: "10px 0px" }}>
            <CalendarIcon style={{ verticalAlign: "bottom" }} />
            <span style={{ padding: "0px 5px", color: "#2A3790" }}>
              Nội dung:
            </span>
            {/* <span style={{ fontWeight: 500 }}>{moment(info?.end_date).format('HH:mm DD/MM/YYYY')} </span> */}
          </div>
        </div>
      </div>
    </div>
  );
}

function Claimed({ insuredPerson, orders = [], claims = [] }) {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [selectedOrder, setSelectedOrder] = useState(null);

  const downloadCertFile = async () => {
    if (!selectedOrder)
      return message.error("Vui lòng chọn hợp đồng để tải file chứng nhận", 2);

    fetch(selectedOrder?.cert_link)
      .then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `Giấy chứng nhận đơn hàng ${selectedOrder?.code}`;
          a.click();
        });
      })
      .catch((e) => {
        setTimeout(() => {
          message.error("Có lỗi xảy ra. Vui lòng thử lại", 1);
        }, 1200);
      });
  };

  return (
    <>
      <Grid
        item
        container
        xs={4}
        style={{
          marginTop: 10,
        }}
      >
        <Grid
          item
          container
          spacing={1}
          style={{
            background: "#E9FBFF",
            borderRadius: "8px",
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
            padding: 15,
            maxHeight: 400,
            minHeight: 400,
          }}
          justifyContent="start"
        >
          <Grid item xs={12}>
            <Typography fontWeight={700} color="#2A3790" fontSize={20}>
              Thông tin hợp đồng
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <TotalBenefitIcon style={{ verticalAlign: "top" }} />
              <span> Tổng quyền lợi bảo hiểm</span>
            </Typography>
            <span style={{ fontSize: 15 }}>
              {formatMoney(insuredPerson?.total_product_amount || 0)}VNĐ
            </span>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <TotalInsureFeeIcon style={{ verticalAlign: "top" }} />
              <span> Tổng phí bảo hiểm</span>
            </Typography>
            <span style={{ fontSize: 15 }}>
              {formatMoney(insuredPerson?.total_fees || 0)}VNĐ
            </span>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <TotalInsureFeeIcon style={{ verticalAlign: "top" }} />
              <span> Số tiền bồi thường đã chi trả</span>
            </Typography>
            <span style={{ fontSize: 15 }}>
              {formatMoney(
                claims
                  .filter((claim) => +claim.status_id === 5)
                  .reduce((total, item) => {
                    return total + +item.amount;
                  }, 0) || 0
              )}
              VNĐ
            </span>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 10,
            }}
          >
            <Typography fontSize={15} color="#2A3790">
              <UserIcon style={{ verticalAlign: "top" }} />
              <span> Họ tên</span>
            </Typography>
            <span style={{ fontSize: 15, textTransform: "capitalize" }}>
              {insuredPerson?.insuredname}
            </span>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <BirthCakeIcon style={{ verticalAlign: "top" }} />
              <span> Năm sinh</span>
            </Typography>
            <span style={{ fontSize: 15 }}>{insuredPerson?.dob || "N/A"}</span>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <PrivateIdIcon style={{ verticalAlign: "top" }} />
              <span> CMND/CCCD</span>
            </Typography>
            <span style={{ fontSize: 15 }}>
              {insuredPerson?.insuredprivateid || "N/A"}
            </span>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <MobilePhoneIcon style={{ verticalAlign: "top" }} />
              <span> Số điện thoại</span>
            </Typography>
            <span style={{ fontSize: 15 }}>
              {insuredPerson?.phonenumber || "N/A"}
            </span>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <EmailIcon style={{ verticalAlign: "top" }} />
              <span> Email</span>
            </Typography>
            <span style={{ fontSize: 15 }}>
              {insuredPerson?.insuredemail || "N/A"}
            </span>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography fontSize={15} color="#2A3790">
              <AddressIcon style={{ verticalAlign: "top" }} />
              <span> Địa chỉ</span>
            </Typography>
            <span style={{ fontSize: 15 }}>{"N/A"}</span>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={4}
        style={{
          marginTop: 10,
        }}
      >
        <Grid
          item
          container
          spacing={1}
          style={{
            background: "#FFFFE7",
            borderRadius: "8px",
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
            padding: 15,
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              maxHeight: "calc(100vh - 50px)",
              overflowY: "auto",
            }}
          >
            <Typography fontWeight={700} color="#2A3790" fontSize={20}>
              Chi tiết hợp đồng
            </Typography>

            <div style={{ marginTop: 10 }}>
              {orders.map((item, index) => {
                return (
                  <OrderCertInfo
                    info={item}
                    key={index}
                    selectedOrder={selectedOrder}
                    onSelect={() => {
                      setSelectedOrder(item);
                    }}
                  />
                );
              })}
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={4}
        style={{
          marginTop: 10,
        }}
      >
        <Grid
          item
          container
          spacing={1}
          style={{
            background: "#EBFFFE",
            borderRadius: "8px",
            boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
            padding: 15,
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              maxHeight: "calc(100vh - 250px)",
              overflowY: "auto",
            }}
          >
            <Typography fontWeight={700} color="#2A3790" fontSize={20}>
              Lịch sử bồi thường
            </Typography>

            <div style={{ marginTop: 10 }}>
              {claims.map((item, index) => {
                return <ClaimInfo info={item} key={index} />;
              })}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
            }}
          >
            <Button
              size="large"
              icon={<DownloadFileIcon style={{ verticalAlign: "middle" }} />}
              style={{
                background: "#D4F1D8",
                borderRadius: 24,
                outline: "none",
                fontWeight: 500,
                fontSize: 16,
              }}
              onClick={downloadCertFile}
            >
              Tải giấy chứng nhận
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default function BusinessReportDetail() {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const { id } = useParams();
  const [render, setRender] = useState(true);
  const [data, setData] = useState([]);
  const [insuredPerson, setInsuredPerson] = useState(null);
  const [claimed, setClaimed] = useState(null);

  useEffect(() => {
    if (!currentUser) return window.location.reload();

    async function callApi() {
      const response = await getInsuredPersons({
        customerId: id,
        notExpired: true,
      });

      return setInsuredPerson(response.data.result.data[0]);
    }
    callApi();
  }, []);

  useEffect(() => {
    async function callApi() {
      const response = await getInsuredOrders({
        customerId: id,
      });
      setData(response.data.result);
      if (response.data?.result?.certs?.length) {
        return setClaimed("claimed");
      }

      if (!response.data?.result?.certs?.length) {
        return setClaimed("not");
      }
    }
    callApi();
  }, []);

  useEffect(() => {}, [claimed]);

  useEffect(() => {
    if (!claimed) return;

    setRender(true);
  }, [claimed]);
  return (
    <Container maxWidth={false} style={{ marginTop: "55px" }}>
      {render && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight={500} fontSize={16} color="#2A3790">
              Báo cáo/ Kinh doanh/{" "}
              <span
                style={{
                  textTransform: "capitalize",
                }}
              >
                {insuredPerson?.buyer_name || "N/A"}
              </span>
            </Typography>
          </Grid>

          {claimed === "not" && <NotClaimed insuredPerson={insuredPerson} />}

          {claimed === "claimed" && (
            <Claimed
              insuredPerson={insuredPerson}
              orders={data?.certs || []}
              claims={data?.claims || []}
            />
          )}
        </Grid>
      )}
    </Container>
  );
}
