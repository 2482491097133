import * as React from "react";
import moment from "moment";
import { Drawer, FormControlLabel, FormGroup } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import {
  FilterList as FilterListIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import { stylePopoverFilter } from "../styles/stylePopoverFilter";
import { TextField, CheckBox } from "../overrideMui";

const hadData = [
  {
    title: "Sản Phẩm",
    option: [
      { label: "BH sức khoẻ PTI Care", name: "", imgUrl: "" },
      { label: "BH bệnh hiểm nghèo", name: "", imgUrl: "" },
      { label: "BH Tomato", name: "", imgUrl: "" },
      { label: "BH Family Care", name: "", imgUrl: "" },
      { label: "BH Thai sản", name: "", imgUrl: "" },
    ],
  },
  {
    title: "Doanh nghiệp",
    option: [
      { label: "Global Care", name: "" },
      { label: "Công ty FPT", name: "" },
      { label: "MP logistic", name: "" },
      { label: "Sen Group", name: "" },
    ],
  },
];

export default function PopoverFilter(props) {
  const classes = stylePopoverFilter();
  const { state, dispatch } = props;

  const { filter } = state;

  const HeaTitle = ({ title, name }) => (
    <div className={classes.title}>
      <span className="span-time">{title}</span>
      <span className="span-delete">Xoá</span>
    </div>
  );

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={Boolean(filter?.open)}
        onClose={() => dispatch("closeFilter")}
        sx={{
          "& .MuiPaper-elevation": {
            marginTop: "24px",
            marginRight: "12px",
            maxHeight: "calc(100vh - 38px)",
          },
        }}
      >
        <div style={{ width: "280px", height: "100%", overflow: "auto" }}>
          <div className={classes.container}>
            <div className={classes.header}>
              <div>
                <FilterListIcon fontSize="small" sx={{ marginRight: "8px" }} />
                <span>Bộ lộc</span>
              </div>
              <CloseIcon
                fontSize="small"
                sx={{ marginRight: "8px", cursor: "pointer" }}
                onClick={() => dispatch("closeFilter")}
              />
            </div>
            <div className={classes.time}>
              <HeaTitle title="Thời gian" name="time" />
              <div className="box-date">
                <div className="from-date">
                  <div className="sapn-date">Từ ngày</div>
                  <div>
                    <DatePicker
                      onKeyDown={(e) => {}}
                      fullWidth
                      disableFuture
                      inputFormat="dd/MM/yyyy"
                      // value={dataSearch?.from || null}
                      // onChange={(e) =>
                      //   dispatch({
                      //     type: "onChangeSearch",
                      //     name: "from",
                      //     value: e,
                      //   })
                      // }
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          fullWidth
                          {...params}
                          className={classes.date}
                          focused
                          error={false}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="to-date">
                  <div className="sapn-date">Đến ngày</div>
                  <div>
                    <DatePicker
                      onKeyDown={(e) => {}}
                      fullWidth
                      disableFuture
                      inputFormat="dd/MM/yyyy"
                      // value={dataSearch?.from || null}
                      // onChange={(e) =>
                      //   dispatch({
                      //     type: "onChangeSearch",
                      //     name: "from",
                      //     value: e,
                      //   })
                      // }
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          fullWidth
                          {...params}
                          className={`${classes.date} phat dt`}
                          focused
                          error={false}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            {hadData.map((data, dataIndex) => (
              <div key={data.title} className={classes.box_check}>
                <HeaTitle title={data.title} name="" />
                <div>
                  <FormGroup>
                    {data.option.map((opt, otpIndex) => (
                      <div
                        key={`item_checkBox_${otpIndex}`}
                        style={{ marginBottom: "8px" }}
                      >
                        <FormControlLabel
                          sx={{
                            margin: 0,
                            "& .MuiTypography-root": {
                              fontSize: "14px",
                            },
                          }}
                          control={<CheckBox className={classes.checkBox} />}
                          label={opt?.label}
                        />
                      </div>
                    ))}
                  </FormGroup>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    </div>
  );
}
