import React, { useEffect } from 'react';
import { Wrapper, ContentWrapper, Content } from './styles';
import { Col, Row } from 'antd';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useHistory } from "react-router-dom";

import * as PATH from '../../../../configs/routesConfig';
import { InputField } from '../../../../ui/fields';
import {
  formValidate,
} from '../../../../utils/validation/input'
import Button from '../../../../ui/buttons/Button';
import HeaderBar from '../../../../ui/HeaderBar';
import './CarAccident.css'

const validationSchema = yup.object().shape({
  garaName: formValidate,
  garaPhone: formValidate,
  garaAddress: yup.string().trim().required('Vui lòng nhập thông tin'),
});

const initialValues = {
  garaName: '',
  garaPhone: '',
  garaAddress: '',
}

const SelectGaraOther = () => {
  const history = useHistory()
  useEffect(() => {
    document.body.style.backgroundColor = '#F7F7F7'
  }, [])

  const onSubmitForm = (values, setSubmitting) => {
    global.dataGara = values
    history.push(PATH.CREATE_CLAIM_ACCIDENT_PATH)
  }

	return (
		<Wrapper> 
			<ContentWrapper>
				<Content>
        <Row justify='center'>
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              {/* <HeaderBar
                title='Nhập thông tin Gara'
                onPress={() => history.goBack()}
                background='#F2F2F2'
              /> */}
              <div style={formInfo}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validate={values => {
                  }}
                  onSubmit={(values, { setSubmitting }) => onSubmitForm(values, setSubmitting)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <InputField
                        validateStatus={touched.garaName && errors.garaName ? 'error' : undefined}
                        help={touched.garaName && errors.garaName ? errors.garaName : ''}
                        name="garaName"
                        autoComplete="garaName"
                        value={values.garaName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Nhập tên Gara"
                        size="large"
                        inputStyle={inputStyle}
                        title='Tên Gara'
                        isRequire={true}
                        styleTitle={styleTitle}
                      />
                      <InputField
                        validateStatus={touched.garaPhone && errors.garaPhone ? 'error' : undefined}
                        help={touched.garaPhone && errors.garaPhone ? errors.garaPhone : ''}
                        name="garaPhone"
                        autoComplete="garaPhone"
                        value={values.garaPhone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Số điện thoại Gara"
                        size="large"
                        inputStyle={inputStyle}
                        title='Số điện thoại Gara'
                        isRequire={true}
                        styleTitle={styleTitle}
                      />
                      <InputField
                        validateStatus={touched.garaAddress && errors.garaAddress ? 'error' : undefined}
                        help={touched.garaAddress && errors.garaAddress ? errors.garaAddress : ''}
                        name="garaAddress"
                        autoComplete="garaAddress"
                        value={values.garaAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Địa chỉ Gara"
                        size="large"
                        inputStyle={inputStyle}
                        title='Địa chỉ Gara'
                        isRequire={true}
                        styleTitle={styleTitle}
                      />
                      <div style={txtAlert}>
                        Gara không nằm trong chuỗi liên kết bạn sẽ phải thanh toán tiền trước,
                        sau khi hoàn tất hồ sơ bồi thường bạn sẽ nhận lại chi phí sửa chữa sau.
                        Xin cảm ơn.
                      </div>
                      <div style={{padding: '40px 10px 0 10px'}}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={isSubmitting}
                          border='14'
                        >
                          Lưu
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
				</Content>
			</ContentWrapper>
		</Wrapper>
	);
}

const inputStyle = {
  borderRadius: 9,
  height: 53,
  fontSize: 17,
}

const formInfo = {
  margin: '24px 0 12px 0'
}

const styleTitle = {
  paddingBottom: 14,
  color: '#000000',
  fontWeight: 'bold',
  fontSize: 17,
}

const txtAlert = {
  paddingBottom: 14,
  color: 'red',
  fontSize: 17,
}

export default SelectGaraOther;
