import { TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import CommonTable from "../../../../library/layout/commonTable";
import { formatDate } from "../util";

export default function TableListBeneficiary({ infoData }) {
  const classes = useMakeStyles();
  return <CommonTable classes={classes} rows={[infoData]} columns={columns} />;
}

const columns = [
  {
    label: "STT",
    headerClass: "stt",
    renderCell: ({ rowIdx, colIdx }) => (
      <TableCell key={colIdx}>{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "Họ và tên",
    headerClass: "code",
    renderCell: ({ colIdx, item }) => (
      <TableCell key={colIdx}>{item?.insuredName || ""}</TableCell>
    ),
  },
  {
    label: "Ngày sinh ",
    headerClass: "name-benefits",
    renderCell: ({ colIdx, item }) => (
      <TableCell key={colIdx}>{formatDate(item?.insuredDob)}</TableCell>
    ),
  },
  {
    label: "Giới tính",
    headerClass: "benefits",
    renderCell: ({ colIdx, item }) => (
      <TableCell key={colIdx}>
        {item?.insuredGender === 1
          ? "Nam"
          : item?.insuredGender === 0
          ? "Nữ"
          : ""}
      </TableCell>
    ),
  },
  {
    label: "Số CMND/ CCCD/ Hộ chiếu",
    headerClass: "benefits",
    renderCell: ({ colIdx, item }) => (
      <TableCell key={colIdx}>{item?.insuredPrivateId || ""}</TableCell>
    ),
  },
  {
    label: "Mối quan hệ với người mua BH",
    headerClass: "benefits",
    renderCell: ({ colIdx }) => (
      <TableCell key={colIdx}>Chủ hợp đồng</TableCell>
    ),
  },
];

const useMakeStyles = makeStyles(() => ({
  div: {
    paddingBottom: "20px",
  },

  table: {
    minWidth: "770px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      textAlign: "center",
      color: "#4B4B4B !important",
      background: "#F8F8F8 !important",
    },

    "& td": {
      textAlign: "center",
      padding: 0,
      fontWeight: "500",
      background: "white !important",
      borderBottom: "1px solid #ECECEC",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#F8F8F8 !important",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1px solid #D6DEEB",
    background: "#ECECEC !important",
  },
}));
