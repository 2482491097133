import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Slide,
  TextField,
} from "@mui/material";
import React, { useRef } from "react";
import ImgUpload from "../assets/upload-image.svg";
import { useStyles } from "../useStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddMerchant(props) {
  const { open, data, dispatch, state, id } = props;
  const { imgLoading, saving, errors } = state;
  const ref = useRef();
  const classes = useStyles();

  const onClickDiv = (e) => {
    ref.current?.click();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"sm"}
      onClose={() => dispatch("onClose")}
      className={classes.addDialog}
    >
      <DialogTitle>Thông tin khách hàng</DialogTitle>
      <DialogContent>
        <div className="center">
          {data?.url_logo ? (
            <img src={data?.url_logo} alt="upload" className="logo" />
          ) : (
            <img src={ImgUpload} alt="upload" className="logo" />
          )}
          <input
            id="uploadFile"
            type="file"
            onChange={(e) => dispatch({ type: "upLoadFile", e })}
            ref={ref}
            hidden
          />
          {imgLoading && (
            <div className="progress">
              <LinearProgress />
            </div>
          )}
          <Button className="button" onClick={onClickDiv}>
            Upload logo
          </Button>
        </div>
        <div className="label">Tên/ Công ty</div>
        <TextField
          fullWidth
          placeholder="Nhập thông tin"
          value={data?.title || ""}
          onChange={(e) =>
            dispatch({
              type: "onChange",
              name: "title",
              value: e.target.value,
            })
          }
        />
        {errors.includes("title") && (
          <div className="red-text">Vui lòng nhập đầy đủ thông tin.</div>
        )}
        <div className="label">Mã số thuế</div>
        <TextField
          fullWidth
          placeholder="Nhập thông tin"
          value={data?.taxCode || data?.extraInfo?.taxCode || ""}
          onChange={(e) =>
            dispatch({
              type: "onChange",
              name: "taxCode",
              value: e.target.value,
            })
          }
        />
        {errors.includes("taxCode") && (
          <div className="red-text">Vui lòng nhập đầy đủ thông tin.</div>
        )}
        <div className="label">Điện thoại</div>
        <TextField
          fullWidth
          placeholder="Nhập thông tin"
          value={data?.phone || data?.extraInfo?.phone || ""}
          onChange={(e) =>
            dispatch({
              type: "onChange",
              name: "phone",
              value: e.target.value,
            })
          }
        />
        {errors.includes("phone") && (
          <div className="red-text">Vui lòng nhập đầy đủ thông tin.</div>
        )}
        <div className="label">Địa chỉ</div>
        <TextField
          fullWidth
          placeholder="Nhập thông tin"
          value={data?.address || data?.extraInfo?.address || ""}
          onChange={(e) =>
            dispatch({
              type: "onChange",
              name: "address",
              value: e.target.value,
            })
          }
        />
        {errors.includes("address") && (
          <div className="red-text">Vui lòng nhập đầy đủ thông tin.</div>
        )}
        <div className="label">Email</div>
        <TextField
          fullWidth
          placeholder="Nhập thông tin"
          value={data?.email || data?.extraInfo?.email || ""}
          onChange={(e) =>
            dispatch({
              type: "onChange",
              name: "email",
              value: e.target.value,
            })
          }
        />
        {errors.includes("email") && (
          <div className="red-text">Vui lòng nhập đầy đủ thông tin.</div>
        )}
        <div className="center">
          <Button
            disabled={saving}
            className="button"
            onClick={() => dispatch("validate")}
          >
            {saving ? "Đang lưu.." : `${id ? "Cập nhật" : "Lưu lại"}`}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
