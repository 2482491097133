import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import {
  OrderedListOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'

import { Editor, EditorState, RichUtils, getDefaultKeyBinding, convertToRaw,ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

const MyEditor = React.forwardRef(({
  placeholder = '',
  value = '',
  readOnly = false,
}, ref) => {

  useImperativeHandle(ref, () => ({
    getValue() {
      const content = convertToRaw(editorState.getCurrentContent());
      const markup = draftToHtml(content);
      const hasText = editorState.getCurrentContent().hasText()
      return {
        content: markup,
        hasText
      };
    }
  }))


  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const editorRef = useRef(null);

  const focus = () => {
    editorRef.current.focus()
  }

  const onChange = (editorState) => setEditorState(editorState);
  
  const _handleKeyCommand = (command, editorState) => {
    if(readOnly) return;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  }

  const _toggleBlockType = (blockType) => {
    if(readOnly) return;
    onChange(
      RichUtils.toggleBlockType(
        editorState,
        blockType
      )
    );
  }

  const _mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        editorState,
        4,
      );
      if (newEditorState !== editorState) {
        this.onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  const _toggleInlineStyle = (inlineStyle) => {
    if(readOnly) return;
    onChange(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle
      )
    );
  }

  useEffect(() => {
    if (value !== '') {
      const blocksFromHtml = htmlToDraft(value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState)
    }

  }, [value])

  return <>
    <div className="RichEditor-root">
      <BlockStyleControls
        editorState={editorState}
        onToggle={_toggleBlockType}
      />
      <InlineStyleControls
        editorState={editorState}
        onToggle={_toggleInlineStyle}
      />
      <div onClick={focus}>
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={_handleKeyCommand}
          keyBindingFn={_mapKeyToEditorCommand}
          onChange={onChange}
          placeholder={placeholder}
          ref={editorRef}
          spellCheck={true}
          readOnly={readOnly}
        />
      </div>
    </div>
  </>
})

function StyleButton(props) {
  const [className, setClassName] = useState('');

  const onToggle = (e) => {
    e.preventDefault();
    props.onToggle(props.style);
  }

  useEffect(()=>{
  },[props.editorState])

  useEffect(()=>{
    let className = 'RichEditor-styleButton';
    if (props.active) {
      className += ' RichEditor-activeButton';
    }

    setClassName(className);
  },[props.active])
  return (
    <span className={className} onMouseDown={onToggle}>
      {props.label}
    </span>
  );
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: <UnorderedListOutlined />, style: 'unordered-list-item' },
  { label: <OrderedListOutlined />, style: 'ordered-list-item' },
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          editorState={editorState}
        />
      )}
    </div>
  );
};

var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

export default MyEditor;