import React from "react";
import _ from "lodash";
import {
  ListItemText,
  ListItemButton,
  Collapse,
  List,
  ListItemIcon,
} from "@mui/material";

import ItemRender from "./ItemRender";
import iconArrowRight from "../../../../../assets/lauyout/iconArrowRight.svg";
import iconArrowDown from "../../../../../assets/lauyout/iconArrowDown.svg";

export default function ItemRenderChildren(props) {
  const {
    handleClick,
    index,
    listMenu,
    expanded,
    title,
    selected,
    setSelected,
    data = {},
  } = props;
  return (
    <>
      <ListItemButton
        onClick={() => {
          handleClick(index);
        }}
        key={title}
      >
        <ListItemIcon></ListItemIcon>
        <ListItemText
          primary={title}
          sx={{ "& .MuiTypography-root": { fontSize: "15px" } }}
        />
        {expanded ? <img src={iconArrowDown} /> : <img src={iconArrowRight} />}
      </ListItemButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {listMenu.map((item, index) => {
            return (
              <ItemRender
                name={item.name}
                key={`menu_chau_${index}`}
                path={item.path}
                title={item.title}
                paddingChild={true}
                selected={selected}
                setSelected={setSelected}
                data={{
                  role_management: item?.role_management || {},
                  ...item,
                  ...data,
                }}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
}
