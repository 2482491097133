import { makeStyles } from "@mui/styles";

const styleDialog = makeStyles(() => ({
  div: {
    paddingBottom: "0px",
    overflow: "auto",
    maxHeight: "72vh",
  },

  container_diagnosis: { minHeight: "200px" },

  table: (props) => ({
    minWidth: `${props?.widthTable}px`,

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      color: "#0B3C8A",
      padding: "0 5px",
      paddingLeft: "16px",
    },

    "& td": {
      color: "#4B4B4B",
      padding: "5px",
      lineHeight: "26px",
    },

    "& th.code-QL": {
      width: "100px",
    },

    "& th.code": {
      width: "160px",
    },

    "& th.medicine-code": {
      width: "140px",
    },

    "& th.icon": {
      width: "50px",
      right: 0,
      background: "#E3F3FF",
    },

    "& .sticky": {
      position: "sticky",
    },

    "& th.quantity": {
      width: "100px",
    },

    "& th.unit-price": {
      width: "120px",
    },

    "& th.into-money": {
      width: "130px",
    },

    "& th.paidFee": {
      width: "120px",
    },

    "& td.code": {
      fontWeight: "600",
    },

    "& td.icon": {
      paddingLeft: "15px",
      right: 0,
      background: "#F8F8F8",
    },

    "& .MuiPaper-root.MuiPaper-elevation": {
      margin: 0,
      marginTop: "12px",
      maxHeight: "240px",

      "& .MuiAutocomplete-listbox": {
        maxHeight: "240px",
      },
    },
  }),

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#E3F3FF !important",
    padding: "0 8px",
    zIndex: 11,
    top: 0,
    position: "sticky",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid white",
    background: "#F8F8F8 !important",
  },

  textField: {
    "& .MuiInputBase-input": {
      padding: "6px",
      borderRadius: "8px",
    },

    "& fieldset": {
      border: "none",
      boxShadow: "inset 1px 1px 1px rgb(0 0 0 / 25%)",
      borderRadius: "8px",
    },
  },

  autocomplete: {
    "& .MuiAutocomplete-endAdornment": {
      display: "none",
    },

    "& .MuiInputBase-root": {
      padding: "0 4px !important",

      "& input": {
        padding: "6px 4px !important",
      },
    },

    "& .MuiAutocomplete-popper": {
      position: "absolute",
      inset: "8px auto auto 60px",
      width: "380px",
    },
  },

  divDate: {
    width: "100%",

    "& .date-andt": {
      width: "100%",
      border: "1px solid #A0A0A0",
      borderRadius: "16px",
      height: 45,
    },
  },

  //
}));

export default styleDialog;
