import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Box, Grid, ImageList, ImageListItem, Typography } from "@mui/material";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  getDetail,
  getListOrderForClaim,
} from "../../../../../../apis/claimApis";
import {
  Wrapper,
  ContentWrapper,
  Content,
} from "../../.../../../carAccident/styles";
import HeaderBar from "../../components/layout/HeaderBar";
import AdjustIcon from "@mui/icons-material/Adjust";
import TomatoArccodion from "../../components/TomatoArccodion";

import { getTypeInsu } from "../../components/createDetailClaimFile/until";
import Loading from "../../../../../../ui/Loading";
import { formatCurrency, formatDateDMY } from "../../../../../../utils/utils";
import {
  accidentLevel,
  accidentTypes,
  treatmentTypes,
} from "../../components/createDetailClaimFile/until";
import BoxImage from "../../components/createDetailClaimFile/BoxImage";
import CTCheckbox from "../../components/layout/CTCheckBox";
import moment from "moment";
import { Button } from "antd";
import noImage from "../../../../../../assets/icons/noImage.svg";

const getNameValue = (array, value) => {
  const temp = array.find((item) => item.value === value);
  return temp?.name;
};

const InsuTomatoClaimDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [claimDetail, setClaimDetail] = useState({});
  const [customer, setCustomer] = useState(undefined);
  const { insuredInfo = {}, extraInfo = {} } = claimDetail;

  const renderStatusBlock = (status) => {
    if (status) {
      const STATUS = [
        { value: 1, label: "Chờ xử lý", color: "#26A9E0" },
        { value: 2, label: "Đang xử lý", color: "#DBAC06" },
        { value: 3, label: "Chờ phê duyệt", color: "#6372DA" },
        { value: 4, label: "Bổ sung hồ sơ", color: "#FB7800" },
        { value: 5, label: "Đã phê duyệt", color: "#39B44A" },
        { value: 6, label: "Hoàn tất", color: "#39B44A" },
        { value: 7, label: "Từ chối", color: "#BF272D" },
      ];

      return (
        <span
          style={{
            color: STATUS.filter((item) => +item.value === +status)[0].color,
          }}
        >
          <AdjustIcon />{" "}
          {STATUS.filter((item) => +item.value === +status)[0].label}
        </span>
      );
    } else return;
  };

  const getData = async () => {
    const dataApi = await getDetail(id);
    if (dataApi?.data?.statusCode === 200) {
      const tmp = _.get(dataApi, "data.result");

      const dataCustomer = await getListOrderForClaim({
        limit: 10000,
        offset: 0,
        productIdParent: tmp?.productIdParent,
      });
      const customerData =
        dataCustomer?.data?.result?.filter(
          (item) => item.customerId === tmp?.insuredInfo?.customerId
        )?.[0] ?? undefined;
      setCustomer(customerData);
      setClaimDetail(tmp);
    }
    setIsLoading(false);
  };

  const bankInfo = claimDetail?.extraInfo?.beneficiary?.bank || {};

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getData();
  }, [id]);

  const {
    animalBitesBoarding,
    animalBitesOutpatient,
    animalBitesDead,
    animalBitesPermanentDisability,
    brunedTemporaryInjury,
    brunedDead,
    brunedPermanentDisability,
    lifeOutpatientBone,
    lifeOutpatient,
    lifeBoarding,
    lifeOutpatientPermanentDisability,
    lifeBoardingPermanentDisability,
    lifeDead,
    workAccidentTemporaryInjuryBone,
    workAccidentTemporaryInjury,
    workAccidentTemporaryInjuryBoarding,
    workAccidentPermanentDisabilityOutpatient,
    workAccidentPermanentDisabilityOutpatientNoBone,
    workAccidentPermanentDisabilityBoarding,
    workAccidentDead,
    trafficOutpatientTemporaryInjury,
    trafficOutpatientTemporaryInjuryBone,
    trafficOutpatientTemporaryInjuryNoBone,
    trafficBoardingTemporaryInjury,
    trafficPermanentDisabilityOutpatient,
    trafficPermanentDisabilityBoarding,
    trafficPermanentDisabilityOutpatientBone,
    trafficPermanentDisabilityOutpatientNoBone,
    lifeOutpatientPermanentDisabilityBone,
    workAccidentPermanentDisabilityOutpatientBone,
    lifePermanentDisability,
    trafficDead,
  } = getTypeInsu(insuredInfo, extraInfo);

  return (
    <Wrapper>
      <HeaderBar onPress={() => history.goBack()}>
        <div
          style={{
            fontWeight: "700",
            textAlign: "center",
            fontSize: "16px",
            lineHeight: "1em",
          }}
        >
          <div style={{ marginBottom: 10 }}>Hồ Sơ Bồi Thường Mã:</div>
          <div style={{ color: "#0B7CE4" }}>{claimDetail.code}</div>
        </div>
      </HeaderBar>
      {isLoading && <Loading />}
      <ContentWrapper style={{ backgroundColor: "#FFFFFF", marginTop: "45px" }}>
        <Content>
          <Box sx={contentBox}>
            <Typography sx={statTitle}>TÌNH TRẠNG HỒ SƠ</Typography>
            <Typography sx={stat}>
              {renderStatusBlock(claimDetail?.statusId)}
            </Typography>
          </Box>
          <Box sx={contentBox}>
            <TomatoArccodion
              title="Thông tin hợp đồng"
              expanded={true}
              halfShow={true}
            >
              <Box sx={arcdContent}>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Tổng quyền lợi bảo hiểm
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {" "}
                    {customer?.total_insurance_benefits
                      ? formatCurrency(customer?.total_insurance_benefits)
                      : "0"}{" "}
                    VNĐ
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Tổng phí bảo hiểm
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {" "}
                    {claimDetail?.insuredInfo?.fees
                      ? formatCurrency(claimDetail?.insuredInfo?.fees)
                      : "0"}{" "}
                    VNĐ
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>Họ Tên</Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {customer?.name ?? ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Năm sinh
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {moment(
                      claimDetail?.insuredInfo?.insuredDob,
                      "DD/MM/YYYY"
                    ).isValid()
                      ? moment(
                        claimDetail?.insuredInfo?.insuredDob,
                        "DD/MM/YYYY"
                      ).format("YYYY")
                      : ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    CMND/CCCD
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {insuredInfo?.insuredPrivateId ?? ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Số điện thoại
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {customer?.phone ?? ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>Email</Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {customer?.email ?? ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Địa chỉ
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {customer?.address ?? ""}
                  </Typography>
                </Grid>
              </Box>
            </TomatoArccodion>
          </Box>
          <Box sx={contentBox}>
            <TomatoArccodion title="Thông báo bồi thường" expanded>
              <Box sx={arcdContent}>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography className="section-header">
                    THÔNG BÁO BỒI THƯỜNG
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Số tiền yêu cầu bồi thường
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {" "}
                    {claimDetail?.insuredInfo?.requestMoney
                      ? formatCurrency(claimDetail?.insuredInfo?.requestMoney)
                      : "N/A"}{" "}
                    VNĐ
                  </Typography>
                </Grid>

                {/* {claimDetail?.statusId === 6 && ( */}
                {true && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Số tiền không thanh toán
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {" "}
                      {claimDetail?.amount
                        ? formatCurrency(
                          +claimDetail?.insuredInfo?.requestMoney -
                          +claimDetail?.amount
                        )
                        : "N/A"}{" "}
                      VNĐ
                    </Typography>
                  </Grid>
                )}

                {/* {claimDetail?.statusId === 6 && ( */}
                {true && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Số tiền được bồi thường
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {" "}
                      {claimDetail?.amount
                        ? formatCurrency(+claimDetail?.amount)
                        : "N/A"}{" "}
                      VNĐ
                    </Typography>
                  </Grid>
                )}
                {/* <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>Lý do</Typography>
                  <Typography
                    className="textarea"
                    style={{ fontWeight: "bold" }}
                  >
                    {claimDetail?.insuredInfo?.note}
                  </Typography>
                </Grid> */}
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Loại tai nạn
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {getNameValue(
                      accidentTypes,
                      _.get(extraInfo, "accident.type")
                    ) || ""}
                  </Typography>
                </Grid>
                {_.get(extraInfo, "accident.type") === "traffic accident" &&
                  _.get(extraInfo, "accident.level") !== "dead" && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        Điều khiển xe trên 50cc
                      </Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {_.get(extraInfo, "accident.bikeType") === "yes"
                          ? "Có"
                          : "Không"}
                      </Typography>
                    </Grid>
                  )}
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Mức độ tai nạn
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {getNameValue(
                      accidentLevel,
                      _.get(extraInfo, "accident.level")
                    ) || ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Loại hình điều trị
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {getNameValue(
                      treatmentTypes,
                      _.get(insuredInfo, "treatmentType")
                    ) || ""}
                  </Typography>
                </Grid>
                {"boarding" === claimDetail?.insuredInfo?.treatmentType && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Ngày nhập viện
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {formatDateDMY(claimDetail?.insuredInfo?.hospitalizeDate)}
                    </Typography>
                  </Grid>
                )}
                {"boarding" === claimDetail?.insuredInfo?.treatmentType && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography style={{ fontWeight: "bold" }}>
                      Ngày xuất viện
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      {formatDateDMY(claimDetail?.insuredInfo?.dischargeDate)}
                    </Typography>
                  </Grid>
                )}
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Ngày tai nạn
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {formatDateDMY(claimDetail?.insuredInfo?.accidentDate)}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Nơi xảy ra tai nạn
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }} maxHeight={150} overflow={'auto'}>
                    {claimDetail?.insuredInfo?.accidentAt}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Diễn biến tai nạn
                  </Typography>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className="textarea"
                    maxHeight={150} overflow={'auto'}
                  >
                    {claimDetail?.insuredInfo?.discription}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Nơi điều trị
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }} maxHeight={150} overflow={'auto'}>
                    {claimDetail?.insuredInfo?.text}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Tổng chi phí điều trị
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {formatCurrency(claimDetail?.insuredInfo?.treatmentPrice)}
                    VNĐ
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Chẩn đoán bệnh
                  </Typography>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    className="textarea"
                    maxHeight={150} overflow={'auto'}
                  >
                    {claimDetail?.insuredInfo?.diagnostic}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography className="section-header">CHỨNG TỪ</Typography>
                </Grid>

                <div>
                  <Titiel400 title="Hình chụp CMND/CCCD/Passport mặt trước và sau" />
                  {insuredInfo?.images?.id?.map((paths, index) => {
                    return (
                      <div style={{ marginBottom: 15 }}>
                        <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                        <Grid container>
                          {(paths?.links || [noImage]).map((link, i) => (
                            <BoxImage
                              view={true}
                              isShow
                              isMargin
                              url={link}
                              key={i}
                            />
                          ))}
                        </Grid>
                        {
                          paths?.comment && (
                            <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                              <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                              <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                              <div>{paths?.comment?.content}</div>
                            </div>)
                        }
                      </div>
                    );
                  })}
                </div>
                {(!!trafficOutpatientTemporaryInjury ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityOutpatient ||
                  !!trafficPermanentDisabilityBoarding) && (
                    <div>
                      <Titiel400 title="Hình chụp giấy phép lái xe và giấy tờ xe (cavet xe)" />
                      {insuredInfo?.images?.license?.map((paths, index) => {
                        return (
                          <div style={{ marginBottom: 15 }}>
                            <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                            <Grid container>
                              {(paths?.links || [noImage]).map((link, i) => (
                                <BoxImage
                                  view={true}
                                  isShow
                                  isMargin
                                  url={link}
                                  key={i}
                                />
                              ))}
                            </Grid>
                            {
                              paths?.comment && (
                                <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                  <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                  <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                  <div>{paths?.comment?.content}</div>
                                </div>)
                            }
                          </div>
                        );
                      })}
                    </div>
                  )}
                {(!!workAccidentTemporaryInjuryBone ||
                  !!workAccidentTemporaryInjury ||
                  !!workAccidentTemporaryInjuryBoarding ||
                  !!workAccidentPermanentDisabilityOutpatient ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficOutpatientTemporaryInjury ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityOutpatient ||
                  !!trafficPermanentDisabilityBoarding) && (
                    <div>
                      <Titiel400 title="Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản" />
                      {insuredInfo?.images?.report?.map((paths, index) => {
                        return (
                          <div style={{ marginBottom: 15 }}>
                            <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                            <Grid container>
                              {(paths?.links || [noImage]).map((link, i) => (
                                <BoxImage
                                  view={true}
                                  isShow
                                  isMargin
                                  url={link}
                                  key={i}
                                />
                              ))}
                            </Grid>
                            {
                              paths?.comment && (
                                <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                  <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                  <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                  <div>{paths?.comment?.content}</div>
                                </div>)
                            }
                          </div>
                        );
                      })}
                    </div>
                  )}
                {(!!lifeBoarding ||
                  !!lifeBoardingPermanentDisability ||
                  !!workAccidentTemporaryInjuryBoarding ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityBoarding) && (
                    <div>
                      <Titiel400 title="Hình chụp giấy ra viện, đơn thuốc xuất viện" />
                      {insuredInfo?.images?.prescriptionHospitalCheckout?.map(
                        (paths, index) => {
                          return (
                            <div style={{ marginBottom: 15 }}>
                              <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                              <Grid container>
                                {(paths?.links || [noImage]).map((link, i) => (
                                  <BoxImage
                                    view={true}
                                    isShow
                                    isMargin
                                    url={link}
                                    key={i}
                                  />
                                ))}
                              </Grid>
                              {
                                paths?.comment && (
                                  <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                    <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                    <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                    <div>{paths?.comment?.content}</div>
                                  </div>)
                              }
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                {(!!lifeOutpatientBone ||
                  !!lifeOutpatient ||
                  !!lifeOutpatientPermanentDisability ||
                  !!workAccidentTemporaryInjuryBone ||
                  !!workAccidentTemporaryInjury ||
                  !!workAccidentPermanentDisabilityOutpatient ||
                  !!trafficOutpatientTemporaryInjury ||
                  !!trafficPermanentDisabilityOutpatient) && (
                    <div>
                      <Titiel400 title="Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh viện/ phòng khám" />
                      {insuredInfo?.images?.prescription?.map((paths, index) => {
                        return (
                          <div style={{ marginBottom: 15 }}>
                            <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                            <Grid container>
                              {(paths?.links || [noImage]).map((link, i) => (
                                <BoxImage
                                  view={true}
                                  isShow
                                  isMargin
                                  url={link}
                                  key={i}
                                />
                              ))}
                            </Grid>
                            {
                              paths?.comment && (
                                <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                  <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                  <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                  <div>{paths?.comment?.content}</div>
                                </div>)
                            }
                          </div>
                        );
                      })}
                    </div>
                  )}
                {(!!lifeOutpatient ||
                  !!workAccidentTemporaryInjury ||
                  !!workAccidentPermanentDisabilityOutpatientNoBone ||
                  !!trafficOutpatientTemporaryInjuryNoBone ||
                  !!trafficPermanentDisabilityOutpatientNoBone) && (
                    <div>
                      <Titiel400 title="Phiếu chỉ định của bác sĩ đối với yêu cầu xét nghiệm, chiếu chụp và kết quả chiếu chụp-xét nghiệm" />
                      {insuredInfo?.images?.test?.map((paths, index) => {
                        return (
                          <div style={{ marginBottom: 15 }}>
                            <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                            <Grid container>
                              {(paths?.links || [noImage]).map((link, i) => (
                                <BoxImage
                                  view={true}
                                  isShow
                                  isMargin
                                  url={link}
                                  key={i}
                                />
                              ))}
                            </Grid>
                            {
                              paths?.comment && (
                                <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                  <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                  <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                  <div>{paths?.comment?.content}</div>
                                </div>)
                            }
                          </div>
                        );
                      })}
                    </div>
                  )}
                {(!!lifeOutpatientBone ||
                  !!lifeOutpatientPermanentDisabilityBone ||
                  !!workAccidentTemporaryInjuryBone ||
                  !!workAccidentPermanentDisabilityOutpatientBone ||
                  !!trafficOutpatientTemporaryInjuryBone ||
                  !!trafficPermanentDisabilityOutpatientBone) && (
                    <div>
                      <Titiel400 title="Hình chụp phim XQ, chỉ định chụp XQ; hình chụp phiếu đọc kết quả phim XQ" />
                      {insuredInfo?.images?.xq?.map((paths, index) => {
                        return (
                          <div style={{ marginBottom: 15 }}>
                            <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                            <Grid container>
                              {(paths?.links || [noImage]).map((link, i) => (
                                <BoxImage
                                  view={true}
                                  isShow
                                  isMargin
                                  url={link}
                                  key={i}
                                />
                              ))}
                            </Grid>
                            {
                              paths?.comment && (
                                <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                  <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                  <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                  <div>{paths?.comment?.content}</div>
                                </div>)
                            }
                          </div>
                        );
                      })}
                    </div>
                  )}
                {!!brunedTemporaryInjury && (
                  <div>
                    <Titiel400 title="Hình chụp toa thuốc/ giấy ra viện có ghi chẩn đoán “bỏng”, độ bỏng, diện tích bỏng (nếu có), chữ ký bác sỹ và mộc tròn bệnh viện/ phòng khám" />
                    {(insuredInfo?.images?.diagnose || [0]).map(
                      (paths, index) => {
                        return (
                          <div style={{ marginBottom: 15 }}>
                            <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                            <Grid container>
                              {(paths?.links || [noImage]).map((link, i) => (
                                <BoxImage
                                  view={true}
                                  isShow
                                  isMargin
                                  url={link}
                                  key={i}
                                />
                              ))}
                            </Grid>
                            {
                              paths?.comment && (
                                <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                  <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                  <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                  <div>{paths?.comment?.content}</div>
                                </div>)
                            }
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {(!!animalBitesPermanentDisability ||
                  !!brunedPermanentDisability ||
                  !!lifeBoardingPermanentDisability ||
                  !!lifePermanentDisability ||
                  !!workAccidentPermanentDisabilityOutpatient ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficPermanentDisabilityOutpatient ||
                  !!trafficPermanentDisabilityBoarding) && (
                    <div>
                      <Titiel400 title="Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận nguyên nhân" />
                      {insuredInfo?.images?.governmentConfirmation?.map(
                        (paths, index) => {
                          return (
                            <div style={{ marginBottom: 15 }}>
                              <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                              <Grid container>
                                {(paths?.links || [noImage]).map((link, i) => (
                                  <BoxImage
                                    view={true}
                                    isShow
                                    isMargin
                                    url={link}
                                    key={i}
                                  />
                                ))}
                              </Grid>
                              {
                                paths?.comment && (
                                  <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                    <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                    <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                    <div>{paths?.comment?.content}</div>
                                  </div>)
                              }
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                {(!!animalBitesPermanentDisability ||
                  !!brunedPermanentDisability ||
                  !!lifeOutpatientPermanentDisability ||
                  !!lifeBoardingPermanentDisability ||
                  !!workAccidentPermanentDisabilityOutpatient ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficPermanentDisabilityOutpatient ||
                  !!trafficPermanentDisabilityBoarding) && (
                    <div>
                      <Titiel400 title="Giấy chứng nhận thương tật của cơ quan có thẩm quyền" />
                      {insuredInfo?.images?.certificateInjury?.map(
                        (paths, index) => {
                          return (
                            <div style={{ marginBottom: 15 }}>
                              <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                              <Grid container>
                                {(paths?.links || [noImage]).map((link, i) => (
                                  <BoxImage
                                    view={true}
                                    isShow
                                    isMargin
                                    url={link}
                                    key={i}
                                  />
                                ))}
                              </Grid>
                              {
                                paths?.comment && (
                                  <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                    <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                    <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                    <div>{paths?.comment?.content}</div>
                                  </div>)
                              }
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                {(!!animalBitesDead ||
                  !!brunedDead ||
                  !!lifeDead ||
                  !!workAccidentDead ||
                  !!trafficDead) && (
                    <div>
                      <Titiel400 title="Biên bản công an điều tra có kết luận nguyên nhân tử vong/xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận nguyên nhân tử vong" />
                      {insuredInfo?.images?.deadConclusion?.map(
                        (paths, index) => {
                          return (
                            <div style={{ marginBottom: 15 }}>
                              <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                              <Grid container>
                                {(paths?.links || [noImage]).map((link, i) => (
                                  <BoxImage
                                    view={true}
                                    isShow
                                    isMargin
                                    url={link}
                                    key={i}
                                  />
                                ))}
                              </Grid>
                              {
                                paths?.comment && (
                                  <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                    <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                    <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                    <div>{paths?.comment?.content}</div>
                                  </div>)
                              }
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}

                {(!!animalBitesDead ||
                  !!brunedDead ||
                  !!lifeDead ||
                  !!workAccidentDead ||
                  !!trafficDead) && (
                    <div>
                      <Titiel400 title="Giấy chứng tử" />
                      {insuredInfo?.images?.deathCertificate?.map(
                        (paths, index) => {
                          return (
                            <div style={{ marginBottom: 15 }}>
                              <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                              <Grid container>
                                {(paths?.links || [noImage]).map((link, i) => (
                                  <BoxImage
                                    view={true}
                                    isShow
                                    isMargin
                                    url={link}
                                    key={i}
                                  />
                                ))}
                              </Grid>
                              {
                                paths?.comment && (
                                  <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                    <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                    <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                    <div>{paths?.comment?.content}</div>
                                  </div>)
                              }
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}

                {(!!animalBitesDead ||
                  !!brunedDead ||
                  !!lifeDead ||
                  !!workAccidentDead ||
                  !!trafficDead) && (
                    <div>
                      <Titiel400 title="Biên bản phân chia di sản thừa kế có xác nhận của cơ quan chức năng/phường xã" />
                      {insuredInfo?.images?.inheritanceText?.map(
                        (paths, index) => {
                          return (
                            <div style={{ marginBottom: 15 }}>
                              <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                              <Grid container>
                                {(paths?.links || [noImage]).map((link, i) => (
                                  <BoxImage
                                    view={true}
                                    isShow
                                    isMargin
                                    url={link}
                                    key={i}
                                  />
                                ))}
                              </Grid>
                              {
                                paths?.comment && (
                                  <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                    <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                    <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                    <div>{paths?.comment?.content}</div>
                                  </div>)
                              }
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                {(!!animalBitesOutpatient || !!animalBitesBoarding) && (
                  <div>
                    <Titiel400 title="Hình chụp phiếu chích ngừa/ chỉ định chích ngừa" />
                    {insuredInfo?.images?.vaccinationCard?.map(
                      (paths, index) => {
                        return (
                          <div style={{ marginBottom: 15 }}>
                            <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                            <Grid container>
                              {(paths?.links || [noImage]).map((link, i) => (
                                <BoxImage
                                  view={true}
                                  isShow
                                  isMargin
                                  url={link}
                                  key={i}
                                />
                              ))}
                            </Grid>
                            {
                              paths?.comment && (
                                <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                  <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                  <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                  <div>{paths?.comment?.content}</div>
                                </div>)
                            }
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {!!animalBitesBoarding && (
                  <div>
                    <Titiel400 title="Hình chụp giấy ra viện" />
                    {insuredInfo?.images?.hospitalCheckout?.map(
                      (paths, index) => {
                        return (
                          <div style={{ marginBottom: 15 }}>
                            <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                            <Grid container>
                              {(paths?.links || [noImage]).map((link, i) => (
                                <BoxImage
                                  view={true}
                                  isShow
                                  isMargin
                                  url={link}
                                  key={i}
                                />
                              ))}
                            </Grid>
                            {
                              paths?.comment && (
                                <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                  <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                  <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                  <div>{paths?.comment?.content}</div>
                                </div>)
                            }
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {!!animalBitesBoarding && (
                  <div>
                    <Titiel400 title="Hình chụp đơn thuốc xuất viện" />
                    {insuredInfo?.images?.prescriptionCheckout?.map(
                      (paths, index) => {
                        return (
                          <div style={{ marginBottom: 15 }}>
                            <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                            <Grid container>
                              {(paths?.links || [noImage]).map((link, i) => (
                                <BoxImage
                                  view={true}
                                  isShow
                                  isMargin
                                  url={link}
                                  key={i}
                                />
                              ))}
                            </Grid>
                            {
                              paths?.comment && (
                                <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                  <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                  <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                  <div>{paths?.comment?.content}</div>
                                </div>)
                            }
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
                {(!!animalBitesBoarding ||
                  !!lifeBoarding ||
                  !!lifeBoardingPermanentDisability ||
                  !!workAccidentTemporaryInjuryBoarding ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityBoarding) && (
                    <div>
                      <Titiel400 title="Hình chụp bảng kê chi tiết viện phí" />
                      {insuredInfo?.images?.detailedListHospitalFees?.map(
                        (paths, index) => {
                          return (
                            <div style={{ marginBottom: 15 }}>
                              <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                              <Grid container>
                                {(paths?.links || [noImage]).map((link, i) => (
                                  <BoxImage
                                    view={true}
                                    isShow
                                    isMargin
                                    url={link}
                                    key={i}
                                  />
                                ))}
                              </Grid>
                              {
                                paths?.comment && (
                                  <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                    <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                    <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                    <div>{paths?.comment?.content}</div>
                                  </div>)
                              }
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                {(!!animalBitesBoarding ||
                  !!lifeBoarding ||
                  !!lifeBoardingPermanentDisability ||
                  !!workAccidentTemporaryInjuryBoarding ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityBoarding) && (
                    <div>
                      <Titiel400
                        title="Hình chụp giấy chứng nhận phẫu thuật (nếu có)"
                        unRequier
                      />
                      {(insuredInfo?.images?.surgery || [0]).map(
                        (paths, index) => {
                          return (
                            <div style={{ marginBottom: 15 }}>
                              <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                              <Grid container>
                                {(paths?.links || [noImage]).map((link, i) => (
                                  <BoxImage
                                    view={true}
                                    isShow
                                    isMargin
                                    url={link}
                                    key={i}
                                  />
                                ))}
                              </Grid>
                              {
                                paths?.comment && (
                                  <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                    <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                    <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                    <div>{paths?.comment?.content}</div>
                                  </div>)
                              }
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                {(!!brunedTemporaryInjury ||
                  !!brunedPermanentDisability ||
                  !!lifeOutpatientBone ||
                  !!lifeOutpatient ||
                  !!lifeBoarding ||
                  !!lifeOutpatientPermanentDisability ||
                  !!lifeBoardingPermanentDisability ||
                  !!workAccidentTemporaryInjuryBone ||
                  !!workAccidentTemporaryInjury ||
                  !!workAccidentTemporaryInjuryBoarding ||
                  !!workAccidentPermanentDisabilityOutpatient ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficOutpatientTemporaryInjury ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityOutpatient ||
                  !!trafficPermanentDisabilityBoarding) && (
                    <div>
                      <Titiel400 title="Hóa đơn điều trị" />
                      {insuredInfo?.images?.bill?.map((paths, index) => {
                        return (
                          <div style={{ marginBottom: 15 }}>
                            <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                            <Grid container>
                              {(paths?.links || [noImage]).map((link, i) => (
                                <BoxImage
                                  view={true}
                                  isShow
                                  isMargin
                                  url={link}
                                  key={i}
                                />
                              ))}
                            </Grid>
                            {
                              paths?.comment && (
                                <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                  <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                  <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                  <div>{paths?.comment?.content}</div>
                                </div>)
                            }
                          </div>
                        );
                      })}
                    </div>
                  )}
                {(!!animalBitesOutpatient || !!animalBitesBoarding) && (
                  <div>
                    <Titiel400 title="Hóa đơn chích ngừa" />
                    {insuredInfo?.images?.vaccinationBill?.map(
                      (paths, index) => {
                        return (
                          <div style={{ marginBottom: 15 }}>
                            <div style={{ fontWeight: 500, color: '#0B3C8A' }}>Hình gửi lần {index + 1}</div>
                            <Grid container>
                              {(paths?.links || [noImage]).map((link, i) => (
                                <BoxImage
                                  view={true}
                                  isShow
                                  isMargin
                                  url={link}
                                  key={i}
                                />
                              ))}
                            </Grid>
                            {
                              paths?.comment && (
                                <div style={{ background: '#D2E4FF', borderRadius: 8, padding: 10, marginTop: 5, border: '2px solid #e3edfc' }}>

                                  <div style={{ opacity: 0.8, fontSize: 12 }}>Ý kiến ngày {paths?.comment?.createdDate}</div>
                                  <div style={{ fontWeight: 500, fontSize: 12 }}>Nội dung phản hồi:</div>
                                  <div>{paths?.comment?.content}</div>
                                </div>)
                            }
                          </div>
                        );
                      }
                    )}
                  </div>
                )}

                {(!!animalBitesOutpatient ||
                  !!animalBitesBoarding ||
                  !!brunedTemporaryInjury ||
                  !!brunedPermanentDisability ||
                  !!lifeOutpatientBone ||
                  !!lifeBoarding ||
                  !!lifeBoardingPermanentDisability ||
                  !!lifeOutpatient ||
                  !!workAccidentTemporaryInjuryBone ||
                  !!workAccidentTemporaryInjuryBoarding ||
                  !!workAccidentTemporaryInjury ||
                  !!workAccidentPermanentDisabilityOutpatient ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficOutpatientTemporaryInjury ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityOutpatient ||
                  !!trafficPermanentDisabilityBoarding) && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography
                        sx={{ opacity: "0.7", color: "#000 !important" }}
                      >
                        Vui lòng đính kèm link hoá đơn điện tử (Nếu có)
                      </Typography>
                      <Typography className="textarea">
                        <a
                          style={{ textDecoration: "underline", color: "0B3C8A" }}
                        >
                          {claimDetail?.insuredInfo?.billLink}
                        </a>
                      </Typography>
                    </Grid>
                  )}
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  sx={{ marginTop: "10px" }}
                >
                  <Typography className="section-header">
                    THÔNG TIN NGƯỜI THỤ HƯỞNG
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Chủ tài khoản
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {claimDetail?.bankAccountName}
                  </Typography>
                </Grid>
                {_.get(extraInfo, "beneficiary.type") === "relative" && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography>Mối quan hệ với người được bảo hiểm</Typography>
                    <Typography>
                      {_.get(extraInfo, "beneficiary.relationship") === "couple"
                        ? "Vợ/Chồng"
                        : _.get(extraInfo, "beneficiary.relationship") ===
                          "other"
                          ? "Khác"
                          : "Cha mẹ/Con cái"}
                    </Typography>
                  </Grid>
                )}
                {_.get(extraInfo, "beneficiary.type") === "relative" && (
                  <Grid container sx={{ marginBottom: "1rem" }}>
                    <div>
                      <Typography>Chứng từ xác minh quan hệ</Typography>
                      <Grid container>
                        {_.get(insuredInfo, "images.verifyRelationship").map(
                          (list, index) => (
                            <div>
                              <span>Hình gửi lần {index + 1}</span>
                              <Grid container>
                                {(list?.links || [noImage]).map(
                                  (item, index) => (
                                    <BoxImage
                                      key={index}
                                      isShow
                                      isMargin={index !== 2}
                                      url={item || null}
                                    />
                                  )
                                )}
                              </Grid>
                            </div>
                          )
                        )}
                      </Grid>
                    </div>
                  </Grid>
                )}
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Số tài khoản
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {bankInfo?.id || ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Ngân hàng
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {bankInfo?.name || ""}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">
                  <Typography style={{ fontWeight: "bold" }}>
                    Chi nhánh
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {bankInfo?.branch || ""}
                  </Typography>
                </Grid>
              </Box>
            </TomatoArccodion>
          </Box>
          <Box sx={{ ...contentBox, ...agreementBox }}>
            <CTCheckbox checked={true} style={{ alignItems: "flex-start" }} />
            <Typography
              style={{
                fontWeight: "500",
                fontFamily: "Helvetica",
                textAlign: "justify",
              }}
            >
              Tôi đã đọc kỹ và chấp nhận tất cả
              <span
                style={{
                  color: "#1890ff",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {" "}
                các điều khoản trong Quy tắc Bảo hiểm
              </span>{" "}
              của Công ty bảo hiểm. Tôi cam đoan tất cả các thông tin khai báo ở
              trên là đúng sự thật và hiểu rằng bất kỳ thông tin nào khai báo
              không đúng sự thật có thể sẽ dẫn đến việc Giấy chứng nhận bảo
              hiểm/ Hợp đồng bảo hiểm mất hiệu lực và/hoặc Bảo hiểm Công ty bảo
              hiểm có quyền từ chối một phần hoặc toàn bộ trách nhiệm bồi
              thường. Tôi đồng ý sẽ thông báo cho Công ty bảo hiểm về bất kỳ sự
              thay đổi thông tin nào (nếu có). Tôi đồng ý để cho bác sỹ, bệnh
              viện hoặc cơ sở y tế đã điều trị hoặc tư vấn sức khoẻ cho tôi được
              cung cấp cho Công ty bảo hiểm mọi thông tin liên quan đến sức khoẻ
              của tôi nếu có sự nghi vấn, nhằm đáp ứng các yêu cầu về giải quyết
              quyền lợi bảo hiểm.
            </Typography>
          </Box>
          <Box sx={contentBox}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "19px",
                  letterSpacing: "-0.24px",
                  marginBottom: "0.75rem",
                  color: "#0B3C8A",
                }}
              >
                Chữ ký của bạn<span style={{ color: "red" }}>*</span>
              </Typography>
              {/* <a style={{ textDecoration: "underline", fontSize: "15px" }}>
                Ký lại
              </a> */}
            </div>
            <Typography
              className="textarea"
              sx={{
                padding: "1rem",
                width: "100%",
                borderRadius: "12px",
                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.17)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={claimDetail?.insuredInfo?.signature}
                style={{
                  // width: "100%",
                  // height: "auto",
                  maxHeight: '400px'
                }}
              />
            </Typography>
          </Box>

          {claimDetail?.statusId === 4 &&
            claimDetail?.insuredInfo?.requiredUpdate?.length > 0 && (
              <Box
                style={{
                  marginTop: 20,
                }}
              >
                <Button
                  onClick={() => {
                    history.push(`/claim/tomato/update/${claimDetail?.id}`);
                  }}
                  block
                  size="large"
                  style={{
                    backgroundColor: "#0B3C8A",
                    color: "white",
                    borderRadius: 12,
                  }}
                >
                  Cập nhật
                </Button>
              </Box>
            )}
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default InsuTomatoClaimDetail;

const Titiel400 = ({ title, unRequier, unMarginTop }) => {
  return (
    <Box sx={{ marginBottom: "16px", marginTop: unMarginTop ? 0 : "16px" }}>
      <Typography style={styleTitle400}>
        {title}
        <span style={{ color: "red", display: unRequier ? "none" : "" }}>
          *
        </span>
      </Typography>
    </Box>
  );
};

const styleTitle400 = {
  fontWeight: "bold",
  fonStize: "15px",
  lineHeight: "19px",
  letterSpacing: "-0.408px",
  color: "#000000",
};

const contentBox = {
  marginTop: "25px",
};
const agreementBox = {
  display: "flex",
  "& > p": {
    fontSize: "13px",
    lineHeight: "22px",
    marginLeft: "0.5rem",
  },
  "& .MuiSvgIcon-root path": {
    color: "#0B3C8A",
  },
};
const stat = {
  fontSize: "15px",
  lineHeight: "19px",
  letterSpacing: "-0.24px",
  "& svg": {
    width: "16px",
    height: "16px",
    marginRight: "0.25rem",
    display: "inline-flex",
    alignItems: "center",
  },
  "& span": {
    display: "inline-flex",
    alignItems: "center",
  },
};

const statTitle = {
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.32)",
  marginBottom: "0.25rem",
  fontWeight: "600",
  letterSpacing: "-0.24px",
};

const arcdContent = {
  paddingTop: "8px",
  "& p": {
    // fontFamily: "Google Sans",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19px",
    letterSpacing: "-0.24px",
    marginBottom: "0.75rem",

    "&:first-of-type": {
      color: "#0B3C8A",
    },
    "&.section-header": {
      fontSize: "17px",
      fontWeight: "700",
      lineHeight: "21px",
      color: "#000",
      marginBottom: "1rem",
    },
    "&.textarea": {
      padding: "0.75rem",
      width: "100%",
      borderRadius: "12px",
      boxSizing: "border-box",
      border: "1px solid rgba(0, 0, 0, 0.17)",
    },
    "span.required": {
      color: "red",
    },
  },
  "& .image-list": {
    marginBottom: "0.75rem",
    "& .MuiImageListItem-root": {
      borderRadius: "13px",
      overflow: "hidden",
    },
  },
  "& .old-file": {
    marginBottom: "0.2rem",
    "& > div": {
      backgroundColor: "#D2E4FF",
      borderRadius: "10px",
      padding: "0.75rem",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "0.5rem",
      "& p": {
        color: "#000",
        marginBottom: "0",
      },
      "& a": {
        color: "#0B3C8A",
        display: "block",
        position: "relative",
        "&::after": {
          content: "' '",
          width: "100%",
          height: "1px",
          backgroundColor: "#0B3C8A",
          position: "absolute",
          top: "92%",
          left: "50%",
          transform: "translateX(-50%)",
        },
      },
    },
    "& > p": {
      fontSize: "13px",
      marginBottom: "0.5rem",
      color: "#000000",
      opacity: "0.6",
      lineHeight: "16px",
    },
  },
};
