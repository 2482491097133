import { Box, Container, Grid, Typography } from "@mui/material";
import { Button, DatePicker, Form, Input, message, Select } from "antd";
import TableToExcel from "@linways/table-to-excel";
import {
  CalendarIcon,
  CodeIcon,
  EmployeeIcon,
  ExportIcon,
  MobilePhoneIcon,
  PrivateIdIcon,
  StatusIcon,
  UserIcon,
} from "../components/icon/icon";
import RecoupReportTable from "../components/table/RecoupReport";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  getListClaimFromGlobalCare,
  getListClaimFromProvider,
} from "../../../../../apis/carAccidentApis";
import { STATUS } from "../utils/type";
import { getUsers } from "../api/account";
import ClaimReportTable from "../components/table/ClaimReport";
import ExportReportClaimTable from "../components/table/export/claim-report";
import { useRecoilState } from "recoil";
import { ReviewersState } from "../state";

export default function RecoupReport({ records = [], amount = 0 }) {
  const { Option } = Select;
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const [findRecoupForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [render, setRender] = useState(false);
  const [forbidden, setForbidden] = useState(true);
  const [employees, setEmployees] = useState([]);

  const [params, setParams] = useState(null);

  const [exportedData, setExportedData] = useState([]);
  const [exporting, setExporting] = useState(false);

  const [reviewers, setReviewers] = useRecoilState(ReviewersState);

  const getReviewers = async () => {
    const response = await getUsers({
      offset: 0,
      limit: 1000,
      type: "reviewer",
    });
    if (response instanceof Error)
      return message.error("Lấy danh sách nhân sự kiểm tra thất bại");

    setReviewers(response.data?.result?.data);
  };

  useEffect(() => {
    getReviewers();
    findRecoupForm.setFieldsValue({
      toDate: moment(),
      fromDate: moment().subtract(7, "days"),
    });
  }, []);

  useEffect(() => {
    if (!currentUser) window.location.reload();

    if (
      +currentUser.role_id === 2 ||
      currentUser.extra_info?.userType === "globalcare" ||
      currentUser.role_management_claim?.submenu?.claim_report?.view !== 1
    )
      return setForbidden(false);
  }, []);

  useEffect(() => {
    if (forbidden) return;

    setRender(true);
  }, [forbidden]);

  useEffect(() => {
    if (!render) return;

    const values = findRecoupForm.getFieldsValue();
    // delete values.fromDate;
    // delete values.toDate;
    setParams(values);
  }, [render]);

  useEffect(() => {
    if (!render) return;

    async function callApi() {
      setLoading(true);
      const request = {
        code: params.code,
        from: params.fromDate?.startOf("date"),
        to: params.toDate?.endOf("date"),
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        productId: 19,
        insuredName: params.fullname,
        insuredPrivateId: params.privateId,
        statusId: params.status,
        buyerPhone: params.phone,
      };
      const response =
        currentUser.extra_info?.userType === "globalcare"
          ? await getListClaimFromGlobalCare(request).catch((e) => e)
          : await getListClaimFromProvider(request).catch((e) => e);
      if (response instanceof Error) {
        return message.error("Có lỗi xảy ra", 2);
      }
      setData(response.data.result?.data);
      setTotal(response.data.result?.total);
      setLoading(false);
    }

    callApi();
  }, [currentPage]);

  useEffect(() => {
    if (!render) return;
    async function callApi() {
      setLoading(true);
      // const values = findRecoupForm.getFieldsValue();
      const request = {
        code: params.code,
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        productId: 19,
        insuredName: params?.fullname,
        insuredPrivateId: params?.privateId,
        statusId: params?.status,
        buyerPhone: params?.phone,
        from: params?.fromDate?.startOf("date"),
        to: params?.toDate?.endOf("date"),
        offset: pageSize * (currentPage - 1),
        limit: pageSize,
        productId: 19,
      };
      const result =
        currentUser.extra_info?.userType === "globalcare"
          ? await getListClaimFromGlobalCare(request).catch((e) => e)
          : await getListClaimFromProvider(request).catch((e) => e);
      if (result instanceof Error) return message.error("Lỗi khi tải dữ liệu");

      setData(result.data?.result?.data);
      setTotal(result.data?.result?.total);
      setCurrentPage(1);
      setLoading(false);
    }

    callApi();
  }, [params, pageSize]);

  const exportExcel = async () => {
    if (
      currentUser.role_id === 2 ||
      currentUser.extra_info?.userType === "globalcare" ||
      currentUser.role_management_claim?.submenu?.claim_report?.export === 1 ||
      currentUser.role_management_claim?.submenu?.claim_report?.full_access ===
        1
    ) {
      setExporting(true);

      let request = {
        code: params?.code,
        from: params?.fromDate?.startOf("date"),
        to: params?.toDate?.endOf("date"),
        offset: 0,
        limit: 100,
        productId: 19,
        insuredName: params?.fullname,
        insuredPrivateId: params?.privateId,
        statusId: params?.status,
        insuredPhone: params?.phone,
      };

      let temp = [];
      let total = 0;

      let result =
        currentUser.extra_info?.userType === "globalcare"
          ? await getListClaimFromGlobalCare(request).catch((e) => e)
          : await getListClaimFromProvider(request).catch((e) => e);
      if (result instanceof Error) {
        setExporting(false);
        return message.error("Lỗi khi tải dữ liệu");
      }

      temp = result.data?.result?.data;
      total = result.data?.result?.total;

      for (let i = 2; i <= Math.ceil(total / 100); i++) {
        request.offset = (i - 1) * 100;
        result =
          currentUser.extra_info?.userType === "globalcare"
            ? await getListClaimFromGlobalCare(request).catch((e) => e)
            : await getListClaimFromProvider(request).catch((e) => e);
        if (result instanceof Error) {
          setExporting(false);
          return message.error("Lỗi khi tải dữ liệu");
        }

        temp = [...temp, ...result.data?.result?.data];
      }

      setExportedData(temp);

      setTimeout(() => {
        try {
          TableToExcel.convert(document.getElementById("report-claim-table"), {
            name: `bao-cao-boi-thuong-tu-${params.fromDate?.format(
              "DD/MM/YYYY"
            )}-den-${params.toDate?.format("DD/MM/YYYY")}.xlsx`,
            sheet: {
              name: "Sheet 1",
            },
          });
        } catch (e) {
          setExporting(false);
          return message.error("Lỗi khi tải dữ liệu");
        }
        setExporting(false);
      }, 1500);

      return;
    }

    return message.error("Bạn không có quyền xuất báo cáo");
  };

  return (
    <>
      <Container maxWidth={false} style={{ marginTop: 55 }}>
        {forbidden && <>Bạn không có quyền truy cập trang</>}
        {render && (
          <>
            <Grid item xs={12}>
              <Typography fontWeight={500} fontSize={16} color="#2A3790">
                Báo cáo bồi thường
              </Typography>
            </Grid>
            <Form form={findRecoupForm}>
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{
                      background: "#E9FBFF",
                      borderRadius: "8px",
                      boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
                      padding: 10,
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        fontWeight={700}
                        textTransform={"uppercase"}
                        color={"#2A3790"}
                      >
                        Tra cứu báo cáo bồi thường
                      </Typography>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Typography fontSize={18} color="#2A3790">
                        <CodeIcon style={{ verticalAlign: "top" }} />
                        <span> Mã hồ sơ bồi thường</span>
                      </Typography>
                      <Form.Item name="code">
                        <Input
                          className="rounded"
                          size="large"
                          style={{
                            width: "100%",
                            marginTop: 5,
                            background: "transparent",
                          }}
                          placeholder="Nhập thông tin"
                        />
                      </Form.Item>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Typography fontSize={18} color="#2A3790">
                        <UserIcon style={{ verticalAlign: "top" }} />
                        <span> Họ và tên</span>
                      </Typography>
                      <Form.Item name="fullname">
                        <Input
                          className="rounded"
                          size="large"
                          style={{
                            width: "100%",
                            marginTop: 5,
                            background: "transparent",
                          }}
                          placeholder="Nhập thông tin"
                        />
                      </Form.Item>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Typography fontSize={18} color="#2A3790">
                        <MobilePhoneIcon style={{ verticalAlign: "top" }} />
                        <span> Theo số điện thoại</span>
                      </Typography>
                      <Form.Item name="phone">
                        <Input
                          className="rounded"
                          size="large"
                          style={{
                            width: "100%",
                            marginTop: 5,
                            background: "transparent",
                          }}
                          placeholder="Nhập thông tin"
                        />
                      </Form.Item>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Typography fontSize={18} color="#2A3790">
                        <PrivateIdIcon style={{ verticalAlign: "top" }} />
                        <span> Theo CMND/CCCD</span>
                      </Typography>
                      <Form.Item name="privateId">
                        <Input
                          className="rounded"
                          size="large"
                          style={{
                            width: "100%",
                            marginTop: 5,
                            background: "transparent",
                          }}
                          placeholder="Nhập thông tin"
                        />
                      </Form.Item>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Typography fontSize={18} color="#2A3790">
                        <CalendarIcon style={{ verticalAlign: "top" }} />
                        <span> Từ ngày</span>
                      </Typography>
                      <Form.Item name="fromDate">
                        <DatePicker
                          className="rounded"
                          format={"DD/MM/YYYY"}
                          suffixIcon={
                            <CalendarOutlined style={{ color: "#2A3790" }} />
                          }
                          size="large"
                          style={{
                            width: "100%",
                            marginTop: 5,
                            background: "transparent",
                          }}
                          placeholder="Nhập thông tin"
                        />
                      </Form.Item>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Typography fontSize={18} color="#2A3790">
                        <CalendarIcon style={{ verticalAlign: "top" }} />
                        <span> Đến ngày</span>
                      </Typography>
                      <Form.Item name="toDate">
                        <DatePicker
                          format={"DD/MM/YYYY"}
                          className="rounded"
                          suffixIcon={
                            <CalendarOutlined style={{ color: "#2A3790" }} />
                          }
                          size="large"
                          style={{
                            width: "100%",
                            marginTop: 5,
                            background: "transparent",
                          }}
                          placeholder="Nhập thông tin"
                        />
                      </Form.Item>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Typography fontSize={18} color="#2A3790">
                        <StatusIcon style={{ verticalAlign: "top" }} />
                        <span> Theo trạng thái</span>
                      </Typography>
                      <Form.Item name="status" initialValue={null}>
                        <Select
                          className="rounded transparent-background"
                          size="large"
                          style={{ width: "100%", marginTop: 5 }}
                          placeholder="Nhập thông tin"
                        >
                          <Option value={null}>Tất cả</Option>
                          {STATUS.map((item, index) => (
                            <Option value={item.value} key={index}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Typography fontSize={18} color="#2A3790">
                        <EmployeeIcon style={{ verticalAlign: "top" }} />
                        <span> Nhân sự phê duyệt</span>
                      </Typography>
                      <Select
                        className="rounded transparent-background"
                        size="large"
                        style={{ width: "100%", marginTop: 5 }}
                        placeholder="Nhập thông tin"
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {reviewers.map((item, index) => {
                          return (
                            <Option key={index} value={item.id}>
                              {item.fullname}
                            </Option>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={6} sm={4}></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        block
                        loading={loading}
                        onClick={async () => {
                          setParams(findRecoupForm.getFieldsValue());
                        }}
                        icon={<SearchOutlined style={{ color: "white" }} />}
                        style={{
                          background: "#2A3790",
                          borderRadius: 32,
                          color: "white",
                          height: 42,
                          marginTop: 5,
                        }}
                        size="large"
                      >
                        Tìm kiếm
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={4}></Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    fontSize={18}
                    color="#2A3790"
                    textTransform={"uppercase"}
                    fontWeight={"bold"}
                  >
                    kết quả tra cứu
                  </Typography>
                </Grid>

                <Grid
                  xs={6}
                  style={{
                    textAlign: "right",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div style={{ marginRight: 5 }}>
                    <Select
                      defaultValue={10}
                      style={{
                        width: 120,
                        textAlign: "left",
                        color: "#2A3790",
                      }}
                      onChange={(e) => {
                        setPageSize(e);
                      }}
                    >
                      <Option value={10}>Hiển thị 10</Option>
                      <Option value={20}>Hiển thị 20</Option>
                      <Option value={50}>Hiển thị 50</Option>
                    </Select>
                  </div>
                  <div style={{ width: 160 }}>
                    <Button
                      icon={<ExportIcon style={{ verticalAlign: "top" }} />}
                      style={{
                        border: "1px solid #2A3790",
                        color: "#2A3790",
                        background: "#E8EBFF",
                        borderRadius: 8,
                        height: 40,
                      }}
                      block
                      size="large"
                      disabled={exporting}
                      onClick={() => {
                        exportExcel();
                      }}
                    >
                      Xuất báo cáo
                    </Button>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <ClaimReportTable
                    data={data}
                    total={total}
                    pageSize={pageSize}
                    changeCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Container>

      <ExportReportClaimTable
        data={exportedData}
        {...params}
        user={currentUser}
      />
    </>
  );
}
