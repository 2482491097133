import api from "../configs/api";

export function getListUsers(data) {
  return api
    .apiForm("POST", `/api/v2/user/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListUsersTPA(userType, data) {
  return api
    .apiForm("POST", `/api/v2/user/${userType}/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListOrg(data) {
  return api
    .apiForm("POST", `/api/v2/organization/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListRoleGroupByOrg(id) {
  return api
    .apiForm("GET", `/api/v2/organization/${id}/detail`)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getRoleGroupById(id) {
  return api
    .apiForm(
      "GET",
      `/api/v2/role-group/${id}/detail

`
    )
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListProduct(data) {
  return api
    .apiForm("POST", `/api/v2/product-parent/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getDetialAccount(id) {
  return api
    .apiForm("GET", `/api/v2/user/${id}/detail`)
    .then((res) => res)
    .catch((err) => err.response);
}

export function createAccount(type, data) {
  return api
    .apiForm("POST", `/api/v2/user/${type}`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function updateAccount(type, data) {
  return api
    .apiForm("PUT", `/api/v2/user/${type}`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function resetPasswordGC(data) {
  return api
    .apiForm("PUT", `/api/v2/user/reset-password`, data)
    .then((res) => res)
    .catch((err) => err.response);
}
export function resetPassword(type, data) {
  return api
    .apiForm("PUT", `/api/v2/user/${type}/reset-password`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListProvider(data) {
  return api
    .apiForm("POST", `/api/v2/provider/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListMerchant(data) {
  return api
    .apiForm("POST", `/api/v2/merchant/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}
