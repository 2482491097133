import React from "react";
import { Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { createTheme } from "@mui/material";

export default function BottomButton(props) {
  const { title = "", onClick, disabled = false } = props;
  const classes = styleds();

  return (
    <Box className={classes.box}>
      <Button
        disabled={!!disabled}
        className={disabled ? classes.disableButton : classes.button}
        onClick={onClick}
      >
        {title}
      </Button>
    </Box>
  );
}

const styleds = makeStyles(() => {
  return {
    box: {
      position: "sticky",
      width: "100%",
      bottom: 0,
      top: "500px",
      left: 0,
      boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.1)",
      alignItems: "center",
      zIndex: 99,
      background: "white",
      padding: "16px",
      borderRadius: "16px 16px 0px 0px",
    },

    button: {
      backgroundColor: "#284078 !important",
      border: "none",
      width: "100%",
      color: "white !important",
      borderRadius: "8px !important",
      padding: "10px 0 !important",
      fontSize: "17px !important",
      lineHeight: "18px !important",
      fontWeight: "600 !important",
      alignItems: "center !important",
      textAlign: "center !important",
      textTransform: "none !important",

      "&:hover": {
        backgroundColor: "#284078 !important",
      },
    },

    disableButton: {
      background: "#EBF0ED !important",
      border: "none !important",
      width: "100%",
      color: "#C4CFC9 !important",
      borderRadius: "8px !important",
      padding: "10px 0 !important",
      fontSize: "17px !important",
      lineHeight: "18px !important",
      fontWeight: "600 !important",
      alignItems: "center !important",
      textAlign: "center !important",
      textTransform: "none !important",

      "&:hover": {
        backgroundColor: "#EBF0ED !important",
      },
    },
  };
});
