import moment from "moment"
import { ACCIDENT_TYPE, STATUS } from "../../../utils/type"

export default function ExportReportClaimTable(props) {
  return <>
   <div style={{display: 'none'}}>
      <table className="collapse-border" id={'report-claim-table'} data-cols-width="10, 35, 30, 40, 40, 25, 30, 30, 30, 30, 40, 30, 20, 25, 20,20, 25,35">
        <thead style={{ fontSize: '13' }}>
          <tr>
            <th colSpan={26} data-f-name={"Times new roman"} data-f-sz={"24"} data-a-wrap={true} data-a-h={"left"} data-b-a-s={'thin'} data-f-bold={true}>
              BÁO CÁO CHI TIẾT HỒ SƠ BỒI THƯỜNG TỪ NGÀY {props.fromDate?.format('DD/MM/YYYY')} ĐẾN NGÀY {props.toDate?.format('DD/MM/YYYY')}
            </th>
          </tr>
          <tr>
            <th colSpan={4} data-f-name={"Times new roman"} data-f-sz={"16"} data-a-wrap={true} data-a-h={"left"} data-b-a-s={'thin'} data-f-bold={true}>
              TỪ NGÀY {props.fromDate?.format('DD/MM/YYYY')}
            </th>
            <th colSpan={4} data-f-name={"Times new roman"} data-f-sz={"16"} data-a-wrap={true} data-a-h={"left"} data-b-a-s={'thin'} data-f-bold={true}>
              ĐẾN NGÀY {props.toDate?.format('DD/MM/YYYY')}
            </th>
          </tr>

          <tr>
            <th colSpan={3} data-f-name={"Times new roman"} data-f-sz={"16"} data-a-wrap={true} data-a-h={"left"} data-b-a-s={'thin'} data-f-bold={true}>
              TÊN USER XUẤT
            </th>
            <th colSpan={3} data-f-name={"Times new roman"} data-f-sz={"16"} data-a-wrap={true} data-a-h={"left"} data-b-a-s={'thin'}>
              {props.user?.fullname}
            </th>
          </tr>
          <tr style={{ fontSize: '13' }}>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>STT</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>MÃ HỒ SƠ BT</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>TÊN NĐBH</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>SỐ CMND NĐHB</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>SỐ ĐT NĐBH</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>SỐ TIỀN YÊU CẦU BỒI THƯỜNG</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>NGÀY TẠO HỒ SƠ </th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>NGÀY GIỜ DUYỆT KẾT QUẢ</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>TRẠNG THÁI DUYỆT</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>SỐ TIỀN ĐƯỢC BỒI THƯỜNG</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>Lý do từ chối</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>LOẠI TAI NẠN</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>CHẨN ĐOÁN BÁC SĨ</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>Ngày bị tai nạn</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>Nơi điều trị</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>Ngày nhập viện</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>Ngày xuất viện</th>
            <th data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-f-bold={true}>ĐỊA CHỈ NĐBH</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '13' }}>
          {
            props.data.map((item, index) => {
              return <tr key={index} style={{ fontSize: '13' }}>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-t="n">{index + 1}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{item.code}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{item.insuredInfo?.insuredName}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{item.insuredInfo?.insuredPrivateId}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{item.insuredInfo?.insuredPhoneNumber}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{item.elements[0]?.value}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{moment(item.created_date).format('DD/MM/YYYY')}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{+item.statusId ===  5 ? moment(item.updatedDate).format('DD/MM/YYYY HH:mm') : ''}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{STATUS.filter(status => status.value === +item.statusId)[0]?.label}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{item.amount}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{item.insuredInfo?.note}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{ACCIDENT_TYPE.filter(type => type.value === item.extraInfo?.accident?.type)[0]?.label}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{item?.insuredInfo?.diagnostic}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{item.insuredInfo?.accidentDate ? moment(item?.insuredInfo?.accidentDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{item?.insuredInfo?.text}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{item.insuredInfo?.hospitalizeDate ? moment(item?.insuredInfo?.hospitalizeDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}</td>
                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'}>{item.insuredInfo?.dischargeDate ? moment(item?.insuredInfo?.dischargeDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}</td>

                <td data-f-name={"Times new roman"} data-f-sz={"16"} data-b-a-s={'thin'} data-t="n" data-num-fmt={+item.fees === 0 ? '0' : '#,###'}>{item.fees}</td>
              </tr>
            })
          }

        </tbody>
      </table>
    </div>

  </>
}