import React, { Component } from 'react';
import { Modal, Carousel } from 'antd';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';

import './modal.css'

export class ModalListImage extends Component {
  static _sharedModalImage = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isShowModalImage: false,
      listImage: []
    };
    ModalListImage._sharedModalImage = this;
  }

  static show(data) {
    if (ModalListImage._sharedModalImage) {
      ModalListImage._sharedModalImage?.setState({
        isShowModalImage: true,
        listImage: data
      });
    }
  }

  static hide() {
    if (ModalListImage._sharedModalImage) {
      ModalListImage._sharedModalImage?.setState({
        isShowModalImage: false,
        listImage: []
      });
    }
  }

  render() {
    const { listImage } = this.state;

    const handleClose = () => {
      this.setState({
        ...listImage,
        isShowModalImage: false,
        listImage: []
      })
    }

    return (
      <Modal
        visible={this.state.isShowModalImage && ModalListImage._sharedModalImage}
        footer={null}
        onCancel={handleClose}
        zIndex={99999}
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
        bodyStyle={{
          width: '90vw',
          height: '80vh',
        }}
        maskClosable={false}
      >
        <Slide style={styleSlide}>
          {listImage?.map((image, index) => {
            return (
              <div key={image.uid}>
                <div className='title-header'>
                  {index + 1}/{listImage?.length}
                </div>
                {image?.mimetype == "image/jpeg"
                  ? <img
                    alt="image_claim"
                    src={image?.path}
                    className='img-modal'
                  />
                  :
                  <iframe
                    src={`https://docs.google.com/viewer?url=${image?.path}&embedded=true`}
                    width="100%"
                    style={{height: '70vh'}}
                  >
                  </iframe>
                }
              </div>
            )
          })}
        </Slide>
      </Modal>
    );
  }
}

const styleSlide = {
  width: '100%',
  height: '80vh',
}

export default ModalListImage;
