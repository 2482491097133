import { makeStyles } from "@mui/styles";
import { Modal } from "antd";
import React, { useEffect, useState } from "react";

export default function ShowFile(props) {
  const { onClose, open, link = null } = props;
  const classes = useStyle();
  const [randomKey, setRandomKey] = useState(Math.random()); // Generate a random key
  const [loading, setLoading] = useState(true);

  const loadNewDoc = () => {
    setRandomKey(Math.random()); // Change the random key to force a re-render
  };

  const handleIframeLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (open && link) {
      loadNewDoc();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      className={classes.dialog}
    >
      {loading && <div>Vui lòng chờ tải file...</div>}
      <iframe
        key={randomKey}
        src={`https://docs.google.com/viewer?url=${link}&embedded=true`}
        title="Document Viewer"
        onLoad={handleIframeLoad}
        style={{
          display: loading ? "none" : "block",
          width: "100%",
          height: "65vh",
        }}
      ></iframe>
    </Modal>
  );
}

const useStyle = makeStyles(() => ({
  dialog: {
    "& .ant-modal-body": {
      padding: "50px 10px 10px",
    },
  },
}));
