import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShowImg(props) {
  const { dispatch, state } = props;
  const classes = makeStyle();

  const { showImg } = state;

  return (
    <Dialog
      open={!!showImg?.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => dispatch("closeShowImg")}
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialog}
    >
      <DialogContent
        style={{ padding: "30px", background: "#343A40", position: "relative" }}
      >
        <ClearIcon
          className="icon"
          style={{
            zIndex: 11,
            color: "white",
            position: "absolute",
            top: 8,
            right: "8px",
          }}
          onClick={() => dispatch("closeShowImg")}
        />
        <div className={classes.divContent}>
          <div className="number">
            {showImg?.indexImg + 1 + "/" + showImg?.listImg?.length}
          </div>
          <div className={classes.boxImg}>
            <ArrowBackIosIcon
              className={classes.icon}
              onClick={() => dispatch({ type: "changeIndexImg", name: "pre" })}
            />
            <div className={classes.divImg}>
              <img style={{ width: "100%" }} src={showImg.path} alt="imgs" />
            </div>
            <ArrowForwardIosIcon
              className={classes.icon}
              onClick={() => dispatch({ type: "changeIndexImg", name: "next" })}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
    },
  },

  divContent: {
    textAlign: "center",
    color: "white",

    "& .number": {
      marginBottom: "8px",
      fontSize: "18px",
    },
  },

  boxImg: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },

  divImg: {
    width: "100%",
    maxWidth: "500px",
  },

  icon: { color: "#969696" },
}));
