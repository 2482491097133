import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircleIcon from "@mui/icons-material/Circle";
import { TableCell } from "@mui/material";
import moment from "moment";

export const columns = [
  {
    label: "STT",
    headerClass: "STT ",
    renderCell: ({ rowIdx }) => (
      <TableCell className="STT">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "Loại TK",
    // key: "user_type",
    renderCell: ({ rowIdx, item }) => (
      <TableCell className="user_type">
        {item?.user_type || item?.extra_info?.userType || ""}
      </TableCell>
    ),
  },
  {
    label: "Tên tài khoản",
    key: "username",
    // cellClass: "username",
  },
  {
    label: "Số điện thoại",
    key: "phone_number",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Nhóm quyền",
    key: "role_group_title",
  },
  {
    label: "Công ty",
    key: "org_title",
  },
  {
    isGC: true,
    label: "Quản lý bởi",
    key: "manage_by",
  },
  {
    label: "Ngày cập nhật",
    renderCell: ({ rowIdx, item }) => (
      <TableCell>
        {item?.updated_date
          ? moment(item?.updated_date || null).format("DD/MM/YYYY")
          : item?.created_date
          ? moment(item?.created_date || null).format("DD/MM/YYYY")
          : ""}
      </TableCell>
    ),
  },
  {
    label: "TT",
    headerClass: "status",
    renderCell: ({ item }) => (
      <TableCell className="status">
        <div style={{ display: "flex", alignItems: "center" }}>
          <CircleIcon
            fontSize="small"
            style={{ width: "16px" }}
            sx={{
              color: !!item.status ? "#069D27" : "#CFCFCF",
            }}
          />
        </div>
      </TableCell>
    ),
  },
  {
    label: "Xem",
    headerClass: "show-icon sticky",
    renderCell: ({ data, item }) => (
      <TableCell className="show-icon sticky">
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() =>
            data.history.push(`/admin/account-management/detail/${item.id}`)
          }
        >
          <MoreHorizIcon fontSize="small" />
        </div>
      </TableCell>
    ),
  },
];
