import { makeStyles } from "@mui/styles";

export const stylePopoverFilter = makeStyles(() => ({
  container: {
    padding: "12px 6px 0 6px",
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    margin: "12px 0",

    "& .span-time": {
      fontSize: "14px",
      color: "#4B4B4B",
      fontWeight: "700",
    },

    "& .span-delete": {
      fontSize: "14px",
      color: "#0B7CE4",
      fontWeight: "400",
    },
  },

  time: {
    marginTop: "16px",
    paddingBottom: "16px",
    borderBottom: "1px solid #D4D4D4",

    "& .box-date": {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "14px",

      "& .from-date": {
        width: "49%",
      },
      "& .to-date": {
        width: "49%",
      },
    },

    "& .sapn-date": {
      marginBottom: "6px",
    },
  },

  box_check: {
    marginTop: "16px",
    paddingBottom: "16px",
    borderBottom: "1px solid #D4D4D4",

    "& .row": {
      display: "flex",
      flexDirection: "row",
    },

    "& .color-text": {
      marginTop: "8px",
      color: "#0B7CE4",
      fontSize: 14,
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },

    "& .show-move": {
      display: "flex",
      justifyContent: "space-between",
    },
  },

  checkBox: {
    marginRight: "12px",
  },

  apply_button: {
    width: "100%",
    background: "white",
    position: "sticky",
    bottom: 0,
    padding: "0 12px",
    paddingBottom: "6px",

    "& .button-apply": {
      width: "100%",
      color: "white",
      background: "#3961A0",
      padding: "6px 24px",
    },
  },

  //
}));
