import React from "react";
import { Context } from "./Context.js";

export default ({ children }) => {
  const user = localStorage.getItem('user') || false
  const isCheckUser = user && user !== 'undefined' && user !== 'null'
  const store = {
    userProfile: isCheckUser ? JSON.parse(user) : {},
  };
  return (
    <Context.Provider value={store}>{children}</Context.Provider>
  );
};
