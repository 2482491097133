import React from "react";
import { Button, IconButton, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import InsuranceBenefitTable from "./insuranceBenefitsTable";
import { detailProduct } from "../styles/styleDetailProduct";
import editIcon from "../../../../assets/health/edit.svg";
import Icon_empty from "../../../../assets/icons/icon_empty.svg";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default function InfoProductPackage(props) {
  const classes = detailProduct();
  const { state, dispatch } = props;
  const { productPackage, details } = state;
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user")) || {};

  const infoInsurance = [
    {
      title: "Tên gói sản phẩm",
      value: productPackage?.title || "",
      color: "#3961A0",
      wordBreak: true,
    },
    {
      title: "Mã gói bh",
      value: productPackage?.id || "",
      color: "#3961A0",
      wordBreak: true,
    },
    {
      title: "Phí bảo hiểm",
      value:
        (productPackage?.fees || "").replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
        " VNĐ",
      color: "#3961A0",
    },
    {
      title: "Tổng quyền lợi",
      // value:
      //   (productPackage?.totalBenefits || "").replace(
      //     /\B(?=(\d{3})+(?!\d))/g,
      //     "."
      //   ) + " VNĐ",
      value:
        (productPackage?.product_amount || "").replace(
          /\B(?=(\d{3})+(?!\d))/g,
          "."
        ) + " VNĐ",
      color: "#3961A0",
    },
    {
      title: "Ngày tạo",
      value:
        moment(productPackage?.created_date || null).format("DD/MM/YYYY") || "",
      color: "#3961A0",
    },
    {
      title: "Mức miễn thường",
      value:
        (
          get(productPackage, "extra_info.insurance_deductible.value") || ""
        ).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " VNĐ",
      color: "#3961A0",
    },
    {
      title: "Người tham gia",
      value: productPackage?.participants,
      color: "#3961A0",
    },
  ];

  const waitingTime = [
    {
      title: "Tai nạn",
      valueTime: get(productPackage, "extra_info.config_waiting.accident", ""),
      valueAmount: get(
        productPackage,
        "extra_info.config_share_payment.accident",
        ""
      ),
      minWidth: 90,
    },
    {
      title: "Bệnh có sẵn",
      valueTime: get(
        productPackage,
        "extra_info.config_waiting.existing_disease",
        ""
      ),
      valueAmount: get(
        productPackage,
        "extra_info.config_share_payment.existing_disease",
        ""
      ),
      minWidth: 105,
    },
    {
      title: "Bệnh đặt biệt",
      valueTime: get(
        productPackage,
        "extra_info.config_waiting.special_disease",
        ""
      ),
      valueAmount: get(
        productPackage,
        "extra_info.config_share_payment.special_disease",
        ""
      ),
      minWidth: 115,
    },
    {
      title: "Thai sản",
      valueTime: get(productPackage, "extra_info.config_waiting.pregnancy", ""),
      valueAmount: get(
        productPackage,
        "extra_info.config_share_payment.pregnancy",
        ""
      ),
      minWidth: 90,
    },

    {
      title: "Biến chứng thai sản",
      valueTime: get(
        productPackage,
        "extra_info.config_waiting.pregnancy_complications",
        ""
      ),
      valueAmount: get(
        productPackage,
        "extra_info.config_share_payment.pregnancy_complications",
        ""
      ),
      minWidth: 170,
    },
    {
      title: "Bệnh TT nội trú",
      valueTime: get(
        productPackage,
        "extra_info.config_waiting.inpatient_treatment",
        ""
      ),
      valueAmount: get(
        productPackage,
        "extra_info.config_share_payment.inpatient_treatment",
        ""
      ),
      minWidth: 130,
    },
    {
      title: "Bệnh TT ngoại trú",
      valueTime: get(
        productPackage,
        "extra_info.config_waiting.outpatient_treatment",
        ""
      ),
      valueAmount: get(
        productPackage,
        "extra_info.config_share_payment.outpatient_treatment",
        ""
      ),
      minWidth: 150,
    },
    {
      title: "Răng",
      valueTime: get(productPackage, "extra_info.config_waiting.tooth", ""),
      valueAmount: get(
        productPackage,
        "extra_info.config_share_payment.tooth",
        ""
      ),
      minWidth: 90,
    },
  ];

  return (
    <div className={classes.container_info_product_packake}>
      {isEmpty(productPackage) ? (
        <div
          style={{
            height: "calc(100vh - 250px)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className={classes.box_empty}>
            <img src={Icon_empty} alt="empty" />
            <div>Trống</div>
          </div>
        </div>
      ) : (
        <>
          <div className={`${classes.title_product_package} no-padding`}>
            <span>Thông tin bảo hiểm </span>
            <div>
              <span className="label-status">
                {details?.status === 1 && productPackage?.status === 1
                  ? "Đang hoạt động"
                  : "Tạm ngưng hoạt động"}
              </span>
              {user?.role_management_claim?.privileges?.PRODUCT_MANAGE ===
                1 && (
                <IconButton
                  sx={{
                    padding: "6px",
                    marginLeft: "12px",
                  }}
                  onClick={() => {
                    if (+productPackage?.participants > 0) {
                      dispatch({
                        type: "openSnackbar",
                        typeSnackbar: "warning",
                        message:
                          "Gói Sản phẩm đã được sử dụng, vui lòng không thay đổi thông tin",
                      });
                      return;
                    }
                    history.push(
                      `/admin/product-management/detail-update/${productPackage?.id}`,
                      {
                        product_id: productPackage?.product_id,
                      }
                    );
                  }}
                >
                  <img src={editIcon} width={"20px"} />
                </IconButton>
              )}
            </div>
          </div>
          <div className={classes.box_info_product}>
            <div className="title">Thông tin gói bảo hiểm</div>
            <Grid
              container
              spacing={2}
              columns={16}
              sx={{
                background: "#F8F8F8",
                marginTop: "12px",
                width: "100%",
                marginLeft: "0",
                marginBottom: "12px",
              }}
            >
              {infoInsurance.map((item, index) => (
                <Grid item key={index} xs={8} sm={5} md={4} lg={4} xl={3}>
                  <div className="item-grid-package-insurance">
                    <div style={{ color: "#A0A0A0" }}>{item.title}</div>
                    <div
                      style={{
                        color: item.color,
                        fontWeight: "600",
                        wordBreak: item.wordBreak ? "break-all" : "",
                      }}
                    >
                      {item.value}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
            <div className="title">Thời gian chờ</div>
            <div className={classes.containeer_time_remaining}>
              {waitingTime.map((i, index) => (
                <div
                  key={`key_div_render_${index}`}
                  className="div-column"
                  style={{ minWidth: i.minWidth ? i.minWidth : "" }}
                >
                  <div className="head-table">{i.title}</div>
                  <div className="value-table">{i.valueTime}</div>
                </div>
              ))}
            </div>
            <div className="title">Đồng chi trả</div>
            <div className={classes.containeer_time_remaining}>
              {waitingTime.map((i, index) => (
                <div
                  key={`key_div_render_${index}`}
                  className="div-column"
                  style={{ minWidth: i.minWidth ? i.minWidth : "" }}
                >
                  <div className="head-table">{i.title}</div>
                  <div className="value-table">{i.valueAmount}</div>
                </div>
              ))}
            </div>
            <div className="title">Bảng quyền lợi bảo hiểm</div>
            <InsuranceBenefitTable {...{ state, dispatch, name: "details" }} />
          </div>
        </>
      )}
    </div>
  );
}
