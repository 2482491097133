import moment from "moment";
import {
  getDashboard,
  getCompany,
  getListDashboard,
} from "../../../../../apis/health";
import {} from "../utils";
import { get } from "lodash";

//["provider", "globalcare", "merchant"]

const getDataChartOneDate = (array) => {
  if (array.length === 0) return null;
  if (array.length === 1) return array[0];

  const temp = {};
  array.forEach((i) => {
    temp.count_status_id =
      +(temp?.count_status_id || 0) + +(i?.count_status_id || 0);
    temp.total_amount = +(temp?.total_amount || 0) + +(i?.total_amount || 0);
  });
  temp.serial_day_default = array[0].serial_day_default;
  temp.serial_day = array[0].serial_day;

  return temp;
};

export default function dashboardReducer({
  state,
  dispatch,
  action,
  roleAdminMenuItem,
}) {
  const systemType = roleAdminMenuItem?.userType || "";
  const productIdParent = roleAdminMenuItem?.product_id;

  const cases = {
    inittal: async () => {
      const { from, to, shortDashboard } = state;
      const res = {};
      dispatch("onLoading");
      const companyApi = await getCompany();

      if (companyApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại hoặc load lại trang",
          },
        };
      }

      if (companyApi?.data?.statusCode == 200) {
        const tempCompany = companyApi?.data?.result?.data;
        res.listProvider = [
          {
            provider_id: "all",
            provider_title: "Tất cả",
          },
          ...tempCompany,
        ];
        res.provider = res.listProvider[0];

        const [dashboardApi, listDashboard] = await Promise.all([
          getDashboard(systemType, {
            providerId:
              res.provider?.provider_id == "all"
                ? null
                : res.provider?.provider_id,
            productIdParent,
          }),
          getListDashboard(systemType, {
            productIdParent,
            providerId:
              res.provider?.provider_id == "all"
                ? null
                : res.provider?.provider_id,
            from,
            to,
          }),
        ]);

        if (dashboardApi instanceof Error || listDashboard instanceof Error) {
          return {
            loading: false,
            snackbar: {
              open: true,
              type: "error",
              message:
                "Đã xảy ra lỗi, xin vui lòng thử lại hoặc load lại trang",
            },
          };
        }

        const tempListDashboard = listDashboard?.data?.result || [];
        const labelsChart = [];
        tempListDashboard.forEach((item) => {
          const dateChart = moment(item?.serial_day_default).format("DD/MM");
          if (labelsChart.includes(dateChart)) return;
          labelsChart.push(dateChart);
        });

        res.xChart = [...labelsChart];
        res.dataDashboard = dashboardApi?.data?.result;

        const tempDataChart = labelsChart.reduce((array, item) => {
          const dataOfDate = (tempListDashboard || []).filter(
            (i) => moment(i?.serial_day_default).format("DD/MM") === item
          );
          const dataClaimsChart = getDataChartOneDate(
            dataOfDate?.filter((i) => [-1].includes(+(i.status_id || 0)))
          );
          const dataClaimsApproveChart = getDataChartOneDate(
            dataOfDate?.filter((i) => [5, 6].includes(+(i.status_id || 0)))
          );
          const dataClaimsRejectChart = getDataChartOneDate(
            dataOfDate?.filter((i) => [7, 8].includes(+(i.status_id || 0)))
          );

          array = [
            ...array,
            { dataClaimsChart, dataClaimsApproveChart, dataClaimsRejectChart },
          ];

          return array;
        }, []);

        res.dataChart = [...tempDataChart];
        res.dataClaimsChart = tempDataChart.map(
          (i) => get(i, `dataClaimsChart.${shortDashboard}`) || 0
        );
        res.dataClaimsApproveChart = tempDataChart.map(
          (i) => get(i, `dataClaimsApproveChart.${shortDashboard}`) || 0
        );
        res.dataClaimsRejectChart = tempDataChart.map(
          (i) => get(i, `dataClaimsRejectChart.${shortDashboard}`) || 0
        );
      }
      //

      return { ...res, loading: false };
    },

    onSelectCompany: async () => {
      const { provider_id } = action;
      const { listProvider, shortDashboard, from, to } = state;

      const item = listProvider.find((i) => i.provider_id == provider_id);
      const res = {};
      dispatch("onLoading");
      const [dashboardApi, listDashboard] = await Promise.all([
        getDashboard(systemType, {
          providerId: provider_id == "all" ? null : item?.provider_id,
          productIdParent,
        }),
        getListDashboard(systemType, {
          productIdParent,
          providerId: provider_id == "all" ? null : item?.provider_id,
          from,
          to,
        }),
      ]);

      if (dashboardApi instanceof Error || listDashboard instanceof Error) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại hoặc load lại trang",
          },
        };
      }

      const tempListDashboard = listDashboard?.data?.result || [];
      const labelsChart = [];
      tempListDashboard.forEach((item) => {
        const dateChart = moment(item?.serial_day_default).format("DD/MM");
        if (labelsChart.includes(dateChart)) return;
        labelsChart.push(dateChart);
      });

      res.xChart = [...labelsChart];
      res.dataDashboard = dashboardApi?.data?.result;

      const tempDataChart = labelsChart.reduce((array, item) => {
        const dataOfDate = (tempListDashboard || []).filter(
          (i) => moment(i?.serial_day_default).format("DD/MM") === item
        );

        const dataClaimsChart = getDataChartOneDate(
          dataOfDate?.filter((i) => [2, 3].includes(+(i.status_id || 0)))
        );
        const dataClaimsApproveChart = getDataChartOneDate(
          dataOfDate?.filter((i) => [5, 6].includes(+(i.status_id || 0)))
        );
        const dataClaimsRejectChart = getDataChartOneDate(
          dataOfDate?.filter((i) => [7, 8].includes(+(i.status_id || 0)))
        );

        array = [
          ...array,
          { dataClaimsChart, dataClaimsApproveChart, dataClaimsRejectChart },
        ];

        return array;
      }, []);

      res.dataChart = [...tempDataChart];
      res.dataClaimsChart = tempDataChart.map(
        (i) => get(i, `dataClaimsChart.${shortDashboard}`) || 0
      );
      res.dataClaimsApproveChart = tempDataChart.map(
        (i) => get(i, `dataClaimsApproveChart.${shortDashboard}`) || 0
      );
      res.dataClaimsRejectChart = tempDataChart.map(
        (i) => get(i, `dataClaimsRejectChart.${shortDashboard}`) || 0
      );

      return { provider: item, loading: false, ...res };
    },

    onChangeDate: async () => {
      const { name, value } = action;
      const { from, to, provider, shortDashboard } = state;
      let newValue = value;

      if (value == null) {
        newValue =
          name === "from"
            ? moment(to).add(-29, "days").format()
            : moment(from).add(29, "days").format();
      }
      dispatch("onLoading");

      const param = {
        productIdParent,
        providerId:
          provider?.provider_id == "all" ? null : provider?.provider_id,
        from: from,
        to: to,
        [name]: newValue,
      };
      const listDashboard = await getListDashboard(systemType, param);

      if (listDashboard instanceof Error) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại hoặc load lại trang",
          },
        };
      }

      const res = {};

      const tempListDashboard = listDashboard?.data?.result || [];
      const labelsChart = [];
      tempListDashboard.forEach((item) => {
        const dateChart = moment(item?.serial_day_default).format("DD/MM");
        if (labelsChart.includes(dateChart)) return;
        labelsChart.push(dateChart);
      });

      res.xChart = [...labelsChart];

      const tempDataChart = labelsChart.reduce((array, item) => {
        const dataOfDate = (tempListDashboard || []).filter(
          (i) => moment(i?.serial_day_default).format("DD/MM") === item
        );

        const dataClaimsChart = getDataChartOneDate(
          dataOfDate?.filter((i) => [2, 3].includes(+(i.status_id || 0)))
        );
        const dataClaimsApproveChart = getDataChartOneDate(
          dataOfDate?.filter((i) => [5, 6].includes(+(i.status_id || 0)))
        );
        const dataClaimsRejectChart = getDataChartOneDate(
          dataOfDate?.filter((i) => [7, 8].includes(+(i.status_id || 0)))
        );

        array = [
          ...array,
          { dataClaimsChart, dataClaimsApproveChart, dataClaimsRejectChart },
        ];

        return array;
      }, []);

      res.dataChart = [...tempDataChart];
      res.dataClaimsChart = tempDataChart.map(
        (i) => get(i, `dataClaimsChart.${shortDashboard}`) || 0
      );
      res.dataClaimsApproveChart = tempDataChart.map(
        (i) => get(i, `dataClaimsApproveChart.${shortDashboard}`) || 0
      );
      res.dataClaimsRejectChart = tempDataChart.map(
        (i) => get(i, `dataClaimsRejectChart.${shortDashboard}`) || 0
      );

      return { loading: false, [name]: newValue, ...res };
    },

    onChangeShort: () => {
      const { value } = action;
      const { dataChart } = state;

      const dataClaimsChart = dataChart.map(
        (i) => get(i, `dataClaimsChart.${value}`) || 0
      );
      const dataClaimsApproveChart = dataChart.map(
        (i) => get(i, `dataClaimsApproveChart.${value}`) || 0
      );
      const dataClaimsRejectChart = dataChart.map(
        (i) => get(i, `dataClaimsRejectChart.${value}`) || 0
      );

      return {
        shortDashboard: value,
        dataClaimsChart,
        dataClaimsApproveChart,
        dataClaimsRejectChart,
      };
    },

    onChangeShowChart: () => {
      const { name } = action;
      const { showChart, count } = state;
      showChart[name] = !showChart[name];

      return { showChart, count: (count || 0) + 1 };
    },

    closeSnackbar: () => ({ snackbar: { ...state.snackbar, open: false } }),

    onLoading: () => ({ loading: true }),

    //
  };

  return cases[action.type] && cases[action.type];
}
