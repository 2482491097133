export const STATUS = [
  { value: 2, label: 'Chờ xử lý', color: '#26A9E0' },
  { value: 3, label: 'Chờ phê duyệt', color: '#6372DA' },
  { value: 4, label: 'Bổ sung hồ sơ', color: '#FB7800' },
  { value: 5, label: 'Đã phê duyệt', color: '#1E02CF' },
  { value: 6, label: 'Hoàn tất', color: '#39B44A' },
  { value: 7, label: 'Từ chối', color: '#BF272D' },
]

export const ACCIDENT_TYPE = [
  { value: 'animal bites', label: 'Súc vật cắn' },
  { value: 'bruned', label: 'Phỏng' },
  { value: 'work accident', label: 'Tai nạn lao động' },
  { value: 'life', label: 'Tai nạn sinh hoạt' },
  { value: 'traffic accident', label: 'Tai nạn giao thông' },
]

export const ACCIDENT_LEVEL = [
  { value: 'temporary injury', label: 'Tạm thời' },
  { value: 'permanent disability', label: 'Vĩnh viễn' },
  { value: 'dead', label: 'Tử vong' },
]

export const TREATMENT_TYPE = [
  { value: 'outpatient', label: 'Ngoại trú' },
  { value: 'boarding', label: 'Nội trú' },
]