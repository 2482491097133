import { Grid, IconButton } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useReducer from "../../../../library/hooks/useReducer";
import LoadingMui from "../../../../ui/LoadingMui";
import BenefitPackageTable from "../components/BenefitsPackageTable";
import EditDialog from "../components/EditDialog";
import EditInfo from "../components/EditInfo";
import UpdateBenefits from "../components/UpdateBenefits";
import packageReducer from "../useReducer/packageReducer";
import { useStyles } from "../useStyles";
import {
  convertCurrency,
  formatDate,
  getParamUrlByKey,
  renderConfig,
  types,
} from "../utils";

import editIcon from "../assets/edit-info.svg";
import eyeOpenIcon from "../assets/eye-open-ic.png";
import defaultIcon from "../assets/logo-default.svg";

const initialState = {
  data: {},
  selectPackage: {},
  dataEdit: { extra_info: {} },
  addNewGroup: {},
  dataProductParent: {},
  benefitGroup: [],
  editbenefitList: [],
  errors: [],
  rangeFees: 0,
  total_product_amount: 0,
  open: {
    add: false,
    info: false,
    waiting: false,
    share: false,
    benefit: false,
  },
  loading: false,
  loadingTable: false,
  saving: false,
  isReady: false,
};

function Package(props) {
  const history = useHistory();
  const id = getParamUrlByKey("id");
  const classes = useStyles();

  const [state, dispatch] = useReducer({
    reducer: packageReducer,
    name: "packageReducer",
    initialState,
    history,
    id,
  });

  const {
    data,
    loading,
    open,
    benefitGroup,
    selectPackage,
    total_product_amount,
    rangeFees,
    dataProductParent,
    loadingTable,
    isReady,
  } = state;

  useEffect(async () => {
    await dispatch("detailProduct");
    dispatch("setReady");
  }, []);

  useEffect(async () => {
    if (isReady) {
      dispatch("detailProductParent");
      if (selectPackage.id) {
        dispatch("getPackageDetail");
      }
    }
  }, [isReady]);

  return (
    <>
      <div className={classes.headerBar}>
        Gói sản phẩm /<b> Bảo hiểm {data?.title}</b>
      </div>
      <LoadingMui loading={loading} />

      <div className={classes.package}>
        <div className="container">
          <div className="info">
            <Grid container spacing={4}>
              <Grid item xs={3}>
                {data?.urlLogo ? (
                  <img src={data.urlLogo} alt="logo" className="logo" />
                ) : (
                  <img src={defaultIcon} alt="logo" className="logo" />
                )}
                <div className="name">Bảo hiểm {data?.title || ""}</div>
              </Grid>
              <Grid item xs={5}>
                <div className="row">
                  <div>Mã sản phẩm</div>
                  <div className="color">{data?.id || ""}</div>
                </div>
                <div className="row">
                  <div>Tên sản phẩm</div>
                  <div className="color">Bảo hiểm {data?.title || ""}</div>
                </div>
                <div className="row">
                  <div>Thuộc công ty</div>
                  <div className="color">{data?.providerTitle || ""}</div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="row">
                  <div>Loại bảo hiểm</div>
                  <div className="color">
                    {types.find(
                      (i) => i.insurance_code == dataProductParent.insuranceCode
                    )?.insurance_title || ""}
                  </div>
                </div>
                <div className="row">
                  <div>Ngày tạo</div>
                  <div className="color">{formatDate(data?.createdDate)}</div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="fee">
            <div className="fee-text">
              Phí BH: <b className="ml-10">{rangeFees} </b>
            </div>
            <div className="fee-text">
              Quyền lợi:
              <b className="ml-10">
                {" "}
                {convertCurrency(total_product_amount || 0)}
              </b>
            </div>
            <div className="row fee-text">
              Mã quyền lợi sản phẩm{" "}
              <IconButton onClick={() => dispatch("showDetailPackage")}>
                <img src={eyeOpenIcon} alt="eye" />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="group">
            <div className="group-title" onClick={() => dispatch("openAddNew")}>
              Nhóm quyền lợi
            </div>
            {benefitGroup.map((item, index) => (
              <div
                className={`${
                  selectPackage?.index !== index ? "select" : "selected color"
                }`}
                onClick={() =>
                  dispatch({ type: "getPackageDetail", item, index })
                }
              >
                {item?.title}
              </div>
            ))}
          </div>
          <div className="info">
            {isEmpty(benefitGroup) ? (
              <div className="center">Vui lòng thêm nhóm quyền lợi</div>
            ) : (
              <>
                <div className="row">
                  <div className="title">Thông tin gói bảo hiểm</div>
                  <IconButton color="primary">
                    <img
                      src={editIcon}
                      alt="edit"
                      onClick={() => dispatch({ type: "onOpen", name: "info" })}
                    />
                  </IconButton>
                </div>
                <div className="bg-gray row">
                  <div className="center">
                    <div>Tên sản phẩm</div>
                    <div className="color">{selectPackage?.title}</div>
                  </div>
                  <div className="center">
                    <div>Phí bảo hiểm</div>
                    <div className="color">
                      {convertCurrency(selectPackage?.fees)} VNĐ
                    </div>
                  </div>
                  <div className="center">
                    <div>Quyền lợi</div>
                    <div className="color">
                      {convertCurrency(selectPackage?.product_amount)} VNĐ
                    </div>
                  </div>
                  <div className="center">
                    <div>Người tham gia</div>
                    <div className="color">
                      {convertCurrency(selectPackage?.participants) || 0} người
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="title">Thời gian chờ</div>
                  <IconButton color="primary">
                    <img
                      src={editIcon}
                      alt="edit"
                      onClick={() =>
                        dispatch({ type: "onOpen", name: "waiting" })
                      }
                    />
                  </IconButton>
                </div>
                <div className="bg-gray row">
                  {renderConfig.map((i) => (
                    <InfoWaiting
                      title={i.title}
                      name={i.name}
                      selectPackage={selectPackage}
                    />
                  ))}
                </div>
                <div className="row">
                  <div className="title">Đồng chi trả</div>
                  <IconButton color="primary">
                    <img
                      src={editIcon}
                      alt="edit"
                      onClick={() =>
                        dispatch({ type: "onOpen", name: "share" })
                      }
                    />
                  </IconButton>
                </div>
                <div className="bg-gray row">
                  {renderConfig.map((i) => (
                    <InfoShare
                      title={i.title}
                      name={i.name}
                      selectPackage={selectPackage}
                    />
                  ))}
                </div>
                <div className="row">
                  <div className="title">Bảng quyền lợi bảo hiểm</div>
                  <IconButton color="primary">
                    <img
                      src={editIcon}
                      alt="edit"
                      onClick={() => dispatch("openBenefit")}
                    />
                  </IconButton>
                </div>
                <BenefitPackageTable
                  list={
                    isEmpty(selectPackage.benefits)
                      ? dataProductParent.benefitList
                      : selectPackage.benefits
                  }
                  loading={loadingTable}
                />
              </>
            )}
          </div>
        </div>
        <EditInfo
          open={open.add}
          title="add"
          state={state}
          dispatch={dispatch}
        />
        <EditInfo
          open={open.info}
          title="info"
          state={state}
          dispatch={dispatch}
        />
        <EditDialog
          open={open.waiting}
          title="waiting"
          state={state}
          dispatch={dispatch}
        />
        <EditDialog
          open={open.share}
          title="share"
          state={state}
          dispatch={dispatch}
        />
        <UpdateBenefits open={open.benefit} state={state} dispatch={dispatch} />
      </div>
    </>
  );
}

export default Package;

const InfoWaiting = ({ title, name, selectPackage }) => (
  <div className="center">
    <div>{title}</div>
    <div className="color">
      {selectPackage?.extra_info?.config_waiting?.[name] || 0}
    </div>
  </div>
);
const InfoShare = ({ title, name, selectPackage }) => (
  <div className="center">
    <div>{title}</div>
    <div className="color">
      {selectPackage?.extra_info?.config_share_payment?.[name] || 0}%
    </div>
  </div>
);
