import React, { useEffect } from "react";
import { Box, Button, InputAdornment, Pagination, Stack } from "@mui/material";
import {
  ControlPoint as ControlPointIcon,
  VerticalAlignBottom as VerticalAlignBottomIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";

import useReducer from "../../../../library/hooks/useReducer";
import CommonTable from "../../../../library/layout/commonTable";
import { columns } from "../components/columnsManagerBLVP";
import { TextField } from "../overrideMui/index";
import { managerBLVP } from "../styles/styleManagerBLVP";
import { initialStateManagerBLVP } from "../util";
import PopoverFilter from "../components/popoverFilter";
import managerBLVPReducer from "../reducers/managerBLVPReducer";
import TableToExcel from "@linways/table-to-excel";
import TableExportExcelBVPK from "../components/TableExportExcel";

import searchIcon from "./../../../../assets/icons/search-icon.svg";
import LoadingMui from "../../../../ui/LoadingMui";
import Snackbars from "../../../../ui/snackbar";
import { debounce } from "lodash";

export default function ManagerBLVP() {
  const classes = managerBLVP();
  const history = useHistory();
  let flag = false;

  const [state, dispatch, clearStateGlobal] = useReducer({
    initialState: initialStateManagerBLVP,
    reducer: managerBLVPReducer,
    name: "managerBLVPReducer",
  });

  const { pageTable, loading, snackbar, count, listBVPK, dataExportExcel } =
    state;

  useEffect(() => {
    dispatch("initail");

    return clearStateGlobal();
  }, []);

  useEffect(async () => {
    if (count !== 0) {
      const table = document.getElementById("export-data-table");
      TableToExcel.convert(table, {
        name: `bao-cao-benh-vien-phong-kham.xlsx`,
        sheet: {
          name: "Sheet 1",
        },
      });
    }
  }, [count]);

  return (
    <div className={classes.container}>
      <Box className={classes.box_heder}>
        <div className="title-decentraliza">Bảo lãnh viện phí</div>
        <div>
          <Button
            className="buttons-add-decentraliza"
            onClick={() => history.push(`/admin/blvp/craete-request`)}
          >
            <ControlPointIcon fontSize="small" className="icon-button" />
            Tạo yêu cầu
          </Button>
        </div>
      </Box>
      <Box className={classes.box_button}>
        <div>
          <TextField
            placeholder="Nhập tên sản phẩm..."
            className="text-field-search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchIcon} alt="search_icon" />
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch({
                  type: "applyFilter",
                  keyword: e.target.value,
                });
                flag = true;
              }
            }}
            onChange={debounce((e) => {
              if (!flag) {
                dispatch({
                  type: "applyFilter",
                  keyword: e.target.value,
                });
              }
              flag = false;
            }, 1500)}
          />
        </div>
        <div className="div-buttons">
          <Button
            className="button dowload"
            onClick={() => dispatch("exportFileExcelBVPK")}
          >
            <VerticalAlignBottomIcon
              fontSize="small"
              sx={{ marginRight: "8px" }}
            />
            Xuất file
          </Button>
          <Button
            className="button dowload"
            // onClick={() => dispatch("openFilter")}
          >
            <FilterListIcon fontSize="small" sx={{ marginRight: "8px" }} />
            Bộ lọc
          </Button>
        </div>
      </Box>
      <div>
        {
          // listBVPK.length > 0 ?
          <>
            <CommonTable
              columns={columns}
              rows={listBVPK}
              classes={classes}
              data={{ history }}
              event={(type, data) =>
                (ele, ...rest) => {
                  dispatch({ type, data, ele, rest });
                }}
            />

            <div className={classes.footer}>
              <div className="rows-per-page">{`Hiển thị ${
                (pageTable.page - 1 || 0) * 20 + 1
              }-${
                (pageTable.page - 1 || 0) * 20 + 20 > pageTable.total
                  ? pageTable.total
                  : (pageTable.page - 1 || 0) * 20 + 20
              } trong ${pageTable.total || 0}`}</div>
              <Stack spacing={2}>
                <Pagination
                  count={pageTable?.count} // số trang
                  page={pageTable?.page} // đang đứng trang thứ
                  onChange={(e, pageNumber) => {
                    dispatch({ type: "onChangePage", e, pageNumber });
                  }}
                  variant="outlined"
                  color="primary"
                />
              </Stack>
            </div>
          </>
          // ) : (
          //   <div
          //     style={{
          //       fontSize: "16px",
          //       fontWeight: "600",
          //       fontStyle: "italic",
          //       color: "red",
          //       marginTop: "12px",
          //       marginLeft: "36px",
          //     }}
          //   >
          //     Hiện chưa có thông tin nào
          //   </div>
        }
      </div>
      <LoadingMui loading={loading} />
      <Snackbars
        open={snackbar?.open}
        type={snackbar.type}
        message={snackbar?.message}
      />
      <PopoverFilter {...{ state, dispatch }} />
      <TableExportExcelBVPK {...{ data: dataExportExcel }} />
    </div>
  );
}
