import React from "react";
import { Popover, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { getListLicenseById } from "../../utils";

export default function PopoverMoveImg(props) {
  const { state, dispatch } = props;
  const classes = popoverStyle();

  const { detail, listTypeIdLicese, popverMoveImg } = state;

  const listButtonDowload = [
    {
      title: "Tải toàn bộ",
      typeCode: "all",
    },
    {
      title: "Tải từng hình",
      typeCode: "alone",
    },
  ];

  const listRenderPopiover =
    popverMoveImg?.name === "dowload-img"
      ? listButtonDowload
      : [...listTypeIdLicese];

  return (
    <div>
      <Popover
        id={"popover-move-img"}
        open={!!popverMoveImg?.open}
        anchorEl={popverMoveImg?.ref || null}
        onClose={() => {
          dispatch("onClosePopoverMoveImg");
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: 12 }}>
          {listRenderPopiover?.map((item, index) => (
            <div className={classes.divButton} key={item.title}>
              <Button
                className={classes.buttons}
                onClick={() => {
                  if (item.typeCode == "all") {
                    dispatch("downLoadZipImg");
                    return;
                  }
                  if (item.typeCode == "alone") {
                    dispatch("downLoadImg");
                    return;
                  }
                  dispatch({ type: "moveImgTo", item });
                }}
                title={item?.title}
              >
                {item.title}
              </Button>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
}

const popoverStyle = makeStyles((theme) => ({
  divButton: {
    maxWidth: "350px",
    borderBottom: `1px solid #ECECEC`,

    "&:last-child": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  },

  buttons: {
    width: "100%",
    color: "#4B4B4B",
    fontSize: "14px",
    cursor: "pointer",
    textTransform: "none",
    display: "flex",
    justifyContent: "start",
    textAlign: "left",
    padding: "8px 0",
  },

  //
}));
