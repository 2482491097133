//số dòng tối đa xuất báo cáo sp intransit
export const MAXIMUN_LINE_EXPORT_EXCEL_INTRANSIT = "10000";

//số dòng tối đa xuất báo cáo sp fina
export const MAXIMUN_LINE_EXPORT_EXCEL_FINA = "10000";

//số dòng tối đa xuất báo cáo sp pba
export const MAXIMUN_LINE_EXPORT_EXCEL_PBA = "10000";

//số dòng tối đa xuất báo cáo sp VCOTO
export const MAXIMUN_LINE_EXPORT_EXCEL_VCOTO = "10000";

//số dòng tối đa xuất báo cáo sp aharide
export const MAXIMUN_LINE_EXPORT_EXCEL_AHARIDE = "10000";
