import { error } from "pdf-lib";
import api from "../configs/api";

function login(data) {
  return api.apiForm("POST", "/api/v1/auth/sign-in", data);
}

function logout(data) {
  return api.apiForm("POST", "/api/v1/auth/logout", data);
}

function getProfile() {
  return api.apiForm("GET", "/api/v1/user/profile");
}

function updateProfile(data) {
  return api.apiForm("PUT", "/api/v1/user/profile", data);
}

function updatePWD(data) {
  return api.apiForm("PUT", "/api/v1/user/update-password", data);
}

function sendOTPToEmail(data) {
  return api
    .apiForm("POST", "/api/v1/user/getotp/email", data)
    .then((res) => res)
    .catch((error) => {
      if (!!error.response) {
        return error.response.data;
      }
      return error;
    });
}

function ForgotPassword(data) {
  return api
    .apiForm("POST", "/api/v2/user/forgot-password", data)
    .then((res) => res)
    .catch((error) => {
      if (!!error.response) {
        return error.response.data;
      }
      return error;
    });
}

function login_v2(data) {
  return api.apiForm("POST", "/api/v2/auth/sign-in", data);
}

function logout_v2(data) {
  return api
    .apiForm("POST", "/api/v2/auth/logout", data)
    .then((res) => res)
    .catch((error) => error.response);
}

function getProfile_v2() {
  return api
    .apiForm("GET", "/api/v2/user/profile")
    .then((res) => res)
    .catch((error) => error.response);
}

function updateProfile_v2(data) {
  return api.apiForm("PUT", "/api/v2/user/profile", data);
}

function updatePWD_v2(data) {
  return api.apiForm("PUT", "/api/v2/user/update-password", data);
}

function ForgotPassword_v2(data) {
  return api
    .apiForm("POST", "/api/v2/user/forgot-password", data)
    .then((res) => res)
    .catch((error) => {
      if (!!error.response) {
        return error.response.data;
      }
      return error;
    });
}

function sendOTPToEmail_v2(data) {
  return api
    .apiForm("POST", "/api/v2/user/getotp/email", data)
    .then((res) => res)
    .catch((error) => {
      if (!!error.response) {
        return error.response.data;
      }
      return error;
    });
}

export {
  login,
  getProfile,
  updateProfile,
  updatePWD,
  logout,
  sendOTPToEmail,
  ForgotPassword,
  login_v2,
  logout_v2,
  getProfile_v2,
  updateProfile_v2,
  updatePWD_v2,
  sendOTPToEmail_v2,
};
