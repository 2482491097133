import React, { useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useHistory, useLocation, useParams } from "react-router-dom";

import useRerducer from "../../../../../library/hooks/useReducer";
import Snackbars from "../../../../../ui/snackbar";
import { getParamUrlByKey } from "../../../../../utils/utils";
import { HeaderBar, BottomButton } from "../components";
import { initialState } from "../utils";
import yourClaimReducer from "../reducers/yourClaimReducer";

import StatusClaim from "./statusClaim";
import Infor from "./info";
import ShowLicense from "./showLicense";
import ShowLink from "./showLink";
import License from "./license";

const queryString = require("query-string");

export default function HealthInsurance() {
  const isApp = getParamUrlByKey("platform") === "app";
  const location = useLocation();
  const history = useHistory();
  const apptodetail = (location.search || "")
    ?.toString()
    .includes("apptodetail");

  const [state, dispatch] = useRerducer({
    reducer: yourClaimReducer,
    name: "yourClaimReducer",
    initialState,
    location,
    queryString,
    isApp,
    history,
    apptodetail,
  });

  const { curenPage, loading, snackbar, headerTitle, detail } = state;

  useEffect(() => {
    let q = queryString.parse(location?.search);
    !!q.token && localStorage.setItem("token", q.token);
    dispatch("initial");
  }, []);

  const renderPage = () => {
    switch (curenPage) {
      case "status-claim":
        return <StatusClaim {...{ state, dispatch, isApp }} />;
      case "infor":
        return <Infor {...{ state, dispatch, isApp }} />;
      case "show-license":
        return <ShowLicense {...{ state, dispatch, isApp }} />;
      case "show-link":
        return <ShowLink {...{ state, dispatch, isApp }} />;
      case "license":
        return <License {...{ state, dispatch, isApp }} />;

      default:
        break;
    }
  };

  return (
    <div style={{ maxWidth: "600px", margin: "auto" }}>
      <HeaderBar
        title={
          !!headerTitle ? (
            headerTitle
          ) : (
            <span>
              HS bồi thường mã:{" "}
              <span style={{ color: "#024281" }}>{detail?.code}</span>
            </span>
          )
        }
        onPress={() =>
          dispatch({
            type: "submitPress",
          })
        }
        isApp={isApp}
      />
      <div>{renderPage()}</div>
      {curenPage === "license" && (
        <BottomButton
          {...{
            title: "Cập nhật",
            onClick: () =>
              dispatch({
                type: "submitUpdate",
              }),
          }}
        />
      )}
      <Backdrop
        style={{
          zIndex: 9999,
          color: "#fff",
          borderRadius: "5px",
        }}
        open={!!loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbars
        open={!!snackbar.open}
        type={snackbar.type}
        onClose={() => dispatch("closeSnackbar")}
        message={snackbar.message}
      />
    </div>
  );
}
