import { makeStyles } from "@mui/styles";

export const styledNotify = makeStyles(() => ({
  boxLogo: {
    background: "white",
    width: "100%",
    padding: "12px",

    "& .history": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      textDecorationLine: "underline",
      fontSize: "14px",
      fontWeight: "600",

      "& a": {
        color: "#0044CC",
      },
    },

    "& .logo": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginBottom: "12px",
    },

    "& .boxInfor": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "16px",
      textAlign: "center",
      fontWeight: "500",

      "& .line1": {
        color: "#284078",
        fontSize: "17px",
        fontWeight: "600",
      },

      "& .line2": {
        color: "#E16C2B",
      },

      "& a": {
        color: "#3F76DF",
        textDecorationLine: "underline",
        fontStyle: "italic",
      },
    },

    "& .boxText": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",

      "& .line_1": {
        fontSize: "16px",
        fontWeight: "600",
        padding: "16px 0",
      },

      "& .line_2": {
        fontSize: "14px",
      },
    },
  },

  divFooter: {
    background: "white",
    marginTop: "8px",
    display: "flex",
    justifyContent: "space-between",
    textAlign: "center",
    flexDirection: "column",
    padding: "16px 0",

    "& .title": {
      paddingBottom: "8px",
      color: "#284078",
      fontSize: "16px",
      fontWeight: "600",
    },

    "& .infor": {
      display: "flex",
      justifyContent: "space-around",
      width: "100%",

      "& .phone": {
        background: "#284078",
        padding: "12px",
        borderRadius: "10px",
        color: "white",
        fontWeight: "600",
        fontSize: "16px",
      },
      "& .email": {
        padding: "12px 10px",
        border: "1px solid #E16C2B",
        borderRadius: "10px",
        color: "#E16C2B",
        fontSize: "14px",
        fontWeight: "600",
      },
    },
  },
}));
