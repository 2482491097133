import React, { useEffect, useState, useRef } from "react";
import { Wrapper, ContentWrapper, Content } from "./styles";
import { Col, Row, DatePicker, Input, Checkbox, Upload, Modal } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  CaretDownOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import SignatureCanvas from "react-signature-canvas";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import { Select } from "antd";

import AutocompleteWithIcon from "../../../../ui/fields/AutocompleteWithIcon";
import { formValidate } from "../../../../utils/validation/input";
import { InputField } from "../../../../ui/fields";
import Button from "../../../../ui/buttons/Button";
import * as PATH from "../../../../configs/routesConfig";
import useWindowDimensions from "../../../../utils/WindowDimensions";
import { uploadImage, uploadBase64 } from "../../../../apis/uploadApis";
import { getBankList } from "../../../../apis/carAccidentApis";
import HeaderBar from "../../../../ui/HeaderBar";
import "./CarAccident.css";

const { TextArea } = Input;
const { Option } = Select;

const validationSchema = yup.object().shape({
  name: formValidate,
  accident_date: formValidate,
  account_holder: formValidate,
  // account_number: formValidate,
  bank_name: formValidate,
  branch: formValidate,
  note_accident: formValidate,
  gara_data: yup.object().shape({ id: yup.string().required() }),
  list_image: yup.array().min(1),
  signature: yup.string().required(),
  isCheckedNote: yup.boolean().oneOf([true]),
});

const initialValues = {
  name: "",
  accident_date: "",
  account_holder: "",
  account_number: "",
  bank_name: "",
  branch: "",
  note_accident: "",
  gara_data: {},
  list_image: [],
  signature: "",
  isCheckedNote: false,
};

const CreateClaimCarAccident = () => {
  const { width } = useWindowDimensions();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [bankList, setBankList] = useState([]);
  const refSignatureCanvas = useRef("");
  const location = useLocation();
  const history = useHistory();
  const claimId = location?.state?.claimId || 0;
  const listClaim = localStorage.getItem("listClaim") || false;
  const dateFormat = "DD/MM/YYYY";

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmitForm(values);
    },
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    setValues,
    setSubmitting,
  } = formik;
  useEffect(() => {
    if (listClaim) {
      let listClaimLocal = JSON.parse(listClaim);
      if (listClaimLocal[claimId]) {
        setValues(listClaimLocal[claimId]);
      }
    }
  }, []);

  useEffect(() => {
    fetchBankList();
  }, [values]);

  useEffect(() => {
    setDataLocal();
  }, [values]);

  useEffect(() => {
    document.body.style.backgroundColor = "#F7F7F7";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (global.dataGara?.id) {
      if (listClaim) {
        let listClaimLocal = JSON.parse(listClaim);
        if (listClaimLocal[claimId]) {
          listClaimLocal[claimId].gara_data = global.dataGara;
          setValues(listClaimLocal[claimId]);
        }
      } else {
        values.gara_data = global.dataGara;
      }
      global.dataGara = undefined;
    }
  }, []);

  const fetchBankList = () => {
    const params = {
      limit: 100,
      offset: 0,
    };
    getBankList(params)
      .then((res) => {
        setBankList(res?.data?.result?.data);
      })
      .catch((err) => {
        console.error("err", err);
      });
  };

  const setDataLocal = () => {
    const listDataClaim = {};
    listDataClaim[claimId] = values;
    localStorage.setItem("listClaim", JSON.stringify(listDataClaim));
  };

  const onSubmitForm = (values) => {
    values.accident_date = Date(values.accident_date);
    values.banksInfo = bankList.find((i) => i.id === values.bank_name);
    history.push(PATH.SUMMARY_CLAIM_ACCIDENT_PATH + location?.search, {
      dataClaim: values,
      dataUser: location?.state?.dataUser,
      insuranceInfo: location?.state?.dataUser?.insuredInfo,
    });
    setSubmitting(false);
  };

  const handleSelectGara = () => {
    history.push(PATH.SELECT_GARA_PATH + location?.search, {
      providerId: location?.state?.dataUser?.providerId,
    });
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const onChangeCheckbox = (e) => {
    setValues({
      ...values,
      isCheckedNote: e.target.checked,
    });
  };

  const onSignatureEnd = async () => {
    const url = refSignatureCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    let params = [
      {
        document: url,
      },
    ];
    const data = await uploadBase64(params)
      .then(async (res) => {
        return res.data.result[0].path;
      })
      .catch((err) => {
        console.error("err", err);
      });
    setValues({
      ...values,
      signature: data,
    });
  };

  const onClearSign = (e) => {
    setValues({
      ...values,
      signature: "",
    });
  };

  const handlePreview = (file) => () => {
    setPreviewImage(file?.thumbUrl);
    setPreviewVisible(true);
  };

  const handleDeleteImage = (data, list) => () => {
    const newList = list.filter((item) => {
      return item?.thumbUrl !== data?.thumbUrl;
    });
    setValues({ ...values, list_image: newList });
  };

  const uploadImageCustom = async (options, listData) => {
    let form = new FormData();
    form.append("document", options);
    const data = await uploadImage(form)
      .then(async (res) => {
        res.data.result[0].thumbUrl = res.data.result[0].path;
        return res.data.result[0];
      })
      .catch((err) => {
        console.error("err", err);
      });
    const newList = listData;
    newList.push(data);
    setValues({
      ...values,
      list_image: newList,
    });
  };

  const handleSelectBank = (e) => {
    setValues({
      ...values,
      bank_name: e,
    });
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <HeaderBar
          title="Tạo hồ sơ bồi thường"
          background="#F2F2F2"
          onPress={() => history.goBack()}
        />
        <Content style={{ marginTop: 90 }}>
          <Row justify="center">
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <div style={formInfo}>
                <div style={infoAccident}>THÔNG TIN VỀ TAI NẠN</div>
                <form onSubmit={handleSubmit}>
                  <Col style={{ marginBottom: 24 }}>
                    <div style={styleTitle}>
                      Ngày tai nạn<span style={{ color: "red" }}>*</span>
                    </div>
                    <>
                      <DatePicker
                        style={inputDateStyle}
                        status={
                          touched.accident_date && errors.accident_date
                            ? "error"
                            : undefined
                        }
                        name="accident_date"
                        placeholder="Ngày/Tháng/Năm"
                        format={dateFormat}
                        value={
                          values.accident_date
                            ? moment(values.accident_date)
                            : ""
                        }
                        selected={
                          (values.accident_date &&
                            new Date(values.accident_date)) ||
                          null
                        }
                        suffixIcon={null}
                        onChange={(val) => {
                          setValues({
                            ...values,
                            accident_date: val,
                          });
                        }}
                        fontSize={17}
                      />
                      {touched.accident_date && errors.accident_date ? (
                        <div style={{ color: "red" }}>
                          Vui lòng nhập thông tin
                        </div>
                      ) : null}
                    </>
                  </Col>
                  <InputField
                    validateStatus={
                      touched.name && errors.name ? "error" : undefined
                    }
                    help={touched.name && errors.name ? errors.name : ""}
                    name="name"
                    autoComplete="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Địa điểm tai nạn"
                    size="large"
                    inputStyle={inputStyle}
                    title="Địa điểm tai nạn"
                    isRequire={true}
                    styleTitle={styleTitle}
                  />
                  {values.gara_data?.id ? (
                    <div>
                      <div style={styleTitle}>
                        Thông tin Gara<span style={{ color: "red" }}>*</span>
                        <span onClick={handleSelectGara} style={txtReSelect}>
                          Chọn lại
                        </span>
                      </div>
                      <div style={styleInfoGara}>
                        <div>Tên: {values.gara_data?.name}</div>
                        {/* <div>
                          SĐT: {values.gara_data?.garaPhone}
                        </div> */}
                        <div>Địa chỉ: {values.gara_data?.address}</div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div style={styleTitle}>
                        Chọn Gara<span style={{ color: "red" }}>*</span>
                      </div>
                      <div
                        onClick={handleSelectGara}
                        style={errors.gara_data ? selectGaraError : selectGara}
                      >
                        <span style={placeSelectGara}>Chọn Gara</span>
                        <CaretDownOutlined style={iconDropdown} />
                      </div>
                      {errors.gara_data ? (
                        <div style={{ color: "red" }}>
                          Vui lòng chọn gara sửa chữa
                        </div>
                      ) : null}
                    </div>
                  )}
                  <div style={{ marginTop: 24 }}>
                    <div style={styleTitle}>
                      Diễn biến tai nạn<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextArea
                      style={
                        touched.note_accident && errors.note_accident
                          ? styleNoteAccidentError
                          : styleNoteAccident
                      }
                      onChange={handleChange}
                      placeholder="Tường trình sự việc diễn biến tai nạn"
                      name="note_accident"
                      value={values.note_accident}
                    />
                    {touched.note_accident && errors.note_accident ? (
                      <div style={{ color: "red" }}>
                        Vui lòng nhập thông tin
                      </div>
                    ) : null}
                  </div>
                  <div style={{ marginTop: 24 }}>
                    <div style={styleTitle}>
                      Hình ảnh hiện trường
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <Row>
                      <label class="file">
                        <input
                          style={styleListImage}
                          type="file"
                          id="imageFile"
                          accept="image/*"
                          onChange={(e) =>
                            uploadImageCustom(
                              e.target.files[0],
                              values.list_image
                            )
                          }
                        />
                        <div
                          style={{
                            display: "flex",
                            width: width / 3 - 13,
                            height: width / 3 - 13,
                            maxWidth: 106,
                            maxHeight: 106,
                            margin: 4,
                            border: "1px solid rgba(151, 151, 151, 0.24)",
                            borderRadius: 13,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <PlusOutlined
                            style={{
                              fontSize: 25,
                              color: "rgba(151, 151, 151)",
                            }}
                          />
                        </div>
                      </label>
                      {values.list_image?.map((item, index) => {
                        return (
                          <div
                            key={"image" + index}
                            style={{
                              width: width / 3 - 16,
                              height: width / 3 - 16,
                              maxWidth: 106,
                              maxHeight: 106,
                              margin: 4,
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid rgba(151, 151, 151, 0.24)",
                              borderRadius: 13,
                              padding: 1,
                              position: "relative",
                              display: "flex",
                            }}
                          >
                            <CloseOutlined
                              onClick={handleDeleteImage(
                                item,
                                values.list_image
                              )}
                              style={styleIconClose}
                            />
                            <img
                              alt="list_image"
                              onClick={handlePreview(item)}
                              className="img-accident"
                              src={item?.thumbUrl}
                            />
                          </div>
                        );
                      })}
                    </Row>
                    {errors.list_image ? (
                      <div style={{ color: "red" }}>
                        Vui lòng chụp chứng từ, hình ảnh hiện trường xảy ra tai
                        nạn
                      </div>
                    ) : null}
                    <Modal
                      visible={previewVisible}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="image_accident"
                        style={{
                          width: "100%",
                          height: width,
                          maxHeight: 360,
                          marginTop: 30,
                        }}
                        className="show-modal-image"
                        src={previewImage}
                      />
                    </Modal>
                  </div>
                  <div style={infoAccident}>THÔNG TIN TÀI KHOẢN NGÂN HÀNG</div>
                  <InputField
                    validateStatus={
                      touched.account_holder && errors.account_holder
                        ? "error"
                        : undefined
                    }
                    help={
                      touched.account_holder && errors.account_holder
                        ? errors.account_holder
                        : ""
                    }
                    name="account_holder"
                    autoComplete="account_holder"
                    value={values.account_holder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Nguyễn Văn A"
                    size="large"
                    inputStyle={inputStyle}
                    title="Chủ tài khoản"
                    isRequire={true}
                    styleTitle={styleTitle}
                  />
                  <InputField
                    validateStatus={
                      touched.account_number && errors.account_number
                        ? "error"
                        : undefined
                    }
                    help={
                      touched.account_number && errors.account_number
                        ? errors.account_number
                        : ""
                    }
                    name="account_number"
                    autoComplete="account_number"
                    value={values.account_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Nhập số tài khoản"
                    size="large"
                    inputStyle={inputStyle}
                    title="Số tài khoản"
                    isRequire={true}
                    styleTitle={styleTitle}
                  />
                  <div style={styleTitle}>
                    Ngân hàng<span style={{ color: "red" }}>*</span>
                  </div>
                  <div id="select_bank">
                    <Select
                      validateStatus={
                        touched.bank_name && errors.bank_name
                          ? "error"
                          : undefined
                      }
                      help={
                        touched.bank_name && errors.bank_name
                          ? errors.bank_name
                          : ""
                      }
                      name="bank_name"
                      onChange={handleSelectBank}
                      style={{ width: "100%", marginBottom: 20, fontSize: 17 }}
                      placeholder="Ngân hàng"
                      value={values.bank_name}
                      optionLabelProp="label"
                    >
                      {bankList?.map((item) => {
                        return (
                          <Option
                            key={item.id}
                            value={item.id}
                            label={item.name}
                          >
                            <div
                              className="demo-option-label-item"
                              style={{ display: "flex" }}
                            >
                              <span>
                                <img src={item.url} style={styleIconSelect} />
                              </span>
                              {item.name}
                            </div>
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <InputField
                    validateStatus={
                      touched.branch && errors.branch ? "error" : undefined
                    }
                    help={touched.branch && errors.branch ? errors.branch : ""}
                    name="branch"
                    autoComplete="branch"
                    value={values.branch}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Chi nhánh"
                    size="large"
                    inputStyle={inputStyle}
                    title="Chi nhánh"
                    isRequire={true}
                    styleTitle={styleTitle}
                  />
                  <div style={{ marginTop: 10 }}>
                    <Checkbox
                      style={{
                        color: errors.isCheckedNote ? "red" : "#000",
                        textAlign: "justify",
                      }}
                      onChange={onChangeCheckbox}
                      name="isCheckedNote"
                      checked={values.isCheckedNote}
                    >
                      Tôi đã đọc kỹ và chấp nhận tất cả các điều khoản trong Quy
                      tắc Bảo hiểm của Công ty bảo hiểm. Tôi cam đoan tất cả các
                      thông tin khai báo ở trên là đúng sự thật và hiểu rằng bất
                      kỳ thông tin nào khai báo không đúng sự thật có thể sẽ dẫn
                      đến việc Giấy chứng nhận bảo hiểm/ Hợp đồng bảo hiểm mất
                      hiệu lực và/hoặc Bảo hiểm Công ty bảo hiểm có quyền từ
                      chối một phần hoặc toàn bộ trách nhiệm bồi thường. Tôi
                      đồng ý sẽ thông báo cho Công ty bảo hiểm về bất kỳ sự thay
                      đổi thông tin nào (nếu có).
                    </Checkbox>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={titleSignature}>Chữ ký của bạn</div>
                    <div onClick={onClearSign} style={reSign}>
                      Ký lại
                    </div>
                  </div>
                  <div style={signature}>
                    {!values.signature ? (
                      <SignatureCanvas
                        ref={refSignatureCanvas}
                        penColor="black"
                        canvasProps={{ width: width - 32, height: 168 }}
                        onEnd={onSignatureEnd}
                      />
                    ) : (
                      <img
                        alt="signature"
                        src={values.signature}
                        style={{ objectFit: "contain" }}
                      />
                    )}
                  </div>
                  {errors.signature ? (
                    <div style={{ color: "red" }}>Vui lòng ký tên xác nhận</div>
                  ) : null}
                  <div style={{ padding: "20px 10px 0 10px" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitting}
                      // disabled={!isValid}
                      border="14"
                    >
                      Tiếp tục
                    </Button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

const styleIconSelect = {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  background: "#E8E8E8",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "8px",
  padding: "4px",
};

const inputStyle = {
  borderRadius: 9,
  height: 53,
  fontSize: 17,
};

const inputDateStyle = {
  width: "100%",
  borderRadius: 9,
  height: 53,
  fontSize: 17,
};

const formInfo = {
  margin: "0 0 12px 0",
};

const styleTitle = {
  paddingBottom: 14,
  color: "#000000",
  fontWeight: "bold",
  fontSize: 17,
};
const styleNoteAccident = {
  borderRadius: 9,
  height: 126,
  fontSize: 17,
};

const styleInfoGara = {
  padding: 10,
  borderRadius: 9,
  fontSize: 17,
  border: "1px solid rgba(151, 151, 151, 0.24)",
};

const styleNoteAccidentError = {
  borderRadius: 9,
  height: 126,
  fontSize: 17,
  borderColor: "red",
};

const selectGara = {
  display: "flex",
  border: "1px solid rgba(151, 151, 151, 0.24)",
  height: 53,
  alignItems: "center",
  borderRadius: 9,
  backgroundColor: "#FFF",
};

const selectGaraError = {
  display: "flex",
  border: "1px solid red",
  height: 53,
  alignItems: "center",
  borderRadius: 9,
  backgroundColor: "#FFF",
};

const placeSelectGara = {
  flex: 1,
  fontSize: 17,
  color: "#BBB",
  marginLeft: 12,
};

const iconDropdown = {
  marginRight: 20,
  color: "rgba(0, 0, 0, 0.62)",
};

const claimCode = {
  fontSize: 17,
  color: "#0B3C8A",
  fontWeight: "700",
};

const txtReSelect = {
  color: "rgba(215, 59, 59, 1)",
  float: "right",
  fontSize: 15,
  fontWeight: "600",
};

const infoAccident = {
  fontSize: 20,
  textTransform: "uppercase",
  color: "rgba(225, 107, 47, 1)",
  fontWeight: "700",
  margin: "12px 0 12px 0",
};

const signature = {
  width: "100%",
  height: 168,
  border: "1px solid rgba(151, 151, 151, 0.24)",
  borderRadius: 10,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

const titleSignature = {
  flex: 1,
  fontSize: 17,
  color: "rgba(225, 107, 47, 1)",
  fontWeight: "700",
  margin: "14px 0 14px 0",
};

const reSign = {
  fontSize: 15,
  color: "rgba(63, 113, 255, 1)",
  fontWeight: "500",
  margin: "14px 10px 14px 0",
  textDecoration: "underline",
};

const styleListImage = {
  display: "none",
};

const styleIconClose = {
  fontSize: 18,
  color: "rgba(90, 90, 90)",
  position: "absolute",
  right: -7,
  top: -10,
  background: "#FFF",
  padding: 2,
  borderRadius: 10,
  opacity: 0.9,
};

export default CreateClaimCarAccident;
