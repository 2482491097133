import React from "react";
import { makeStyles } from "@mui/styles";
import { Stack, Stepper, Step, StepLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

export default function SteperHeader(props) {
  const { activeStep, insuranceType } = props;
  const classes = useStyled();

  const steps =
    insuranceType?.insuranceCode === "tai_nan" ? [1, 2, 3, 4] : [1, 2, 3];

  return (
    <div style={{ marginBottom: "22px" }}>
      <Stack sx={{ width: "100%" }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                className={classes.stepLabel}
                StepIconComponent={() => <div>{label}</div>}
              />
            </Step>
          ))}
        </Stepper>
      </Stack>
    </div>
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 9,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderTop: "2px dotted #3961A0",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderTop: "2px dotted #3961A0",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTop: "2px dotted #686868",
  },
}));

const useStyled = makeStyles(() => ({
  stepLabel: {
    "& .MuiStepLabel-iconContainer": {
      fontSize: "13px",
      color: "white",
      width: 20,
      height: 20,
      fontWeight: "bold",
      borderRadius: "50%",
      border: "1px solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "& .Mui-completed": {
      background: "#3961A0",
    },

    "& .Mui-active": {
      background: "#3961A0",
    },

    "& .Mui-disabled": {
      background: "#A0A0A0",
    },
  },
  //
}));
