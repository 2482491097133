import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import {
  ControlPoint as ControlPointIcon,
  VerticalAlignBottom as VerticalAlignBottomIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import TableToExcel from "@linways/table-to-excel";

import useReducer from "../../../../library/hooks/useReducer";
import CommonTable from "../../../../library/layout/commonTable";
import { columns } from "../components/columnsAccountManager";
import { TextField, CheckBox } from "../overrideMui/index";
import { accountManagerV2 } from "../styles/styleAccountManagerV2";
import { initialStateAccountManager } from "../util";
import PopoverFilter from "../components/popoverFilter";
import accountManagerReducer from "../reducers/accountManagerReducer";

import TableExportExcel from "../components/TableExportExcel";
import searchIcon from "./../../../../assets/icons/search-icon.svg";
import LoadingMui from "../../../../ui/LoadingMui";
import Snackbars from "../../../../ui/snackbar";

import { dataSearchAccountManegerV2 } from "../../stateRecoil/atom";
import { useRecoilState } from "recoil";
import { debounce } from "lodash";
// import { DatePicker } from "@mui/x-date-pickers";
import { DatePicker } from "antd";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ManagerAccount() {
  const classes = accountManagerV2();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const [dataSearchRecoid, setDataSearchRecoid] = useRecoilState(
    dataSearchAccountManegerV2
  );

  const [state, dispatch, clearStateGlobal] = useReducer({
    initialState: initialStateAccountManager,
    reducer: accountManagerReducer,
    name: "accountManagerReducer",
    dataSearchRecoid,
    setDataSearchRecoid,
  });
  let flag = false;

  const {
    pageTable,
    snackbar,
    loading,
    listUsers,
    dataFill,
    selectUserType,
    dataExport,
    count,
  } = state;

  useEffect(() => {
    return () => {
      const fetchData = async () => {
        if (
          !window.location?.pathname
            .toString()
            .includes("/admin/account-management/detail/")
        ) {
          setDataSearchRecoid({});
        }
      };

      fetchData();
    };
  }, [window.location.pathname]);

  useEffect(() => {
    dispatch("initial");

    return () => clearStateGlobal();
  }, []);

  useEffect(async () => {
    if (count !== 0) {
      const table = document.getElementById("export-data-table");
      TableToExcel.convert(table, {
        name: `bao-cao-tai-khoan.xlsx`,
        sheet: {
          name: "Sheet 1",
        },
      });
    }
  }, [count]);

  const newColumns =
    user?.extra_info?.userType === "globalcare"
      ? columns
      : columns.filter((i) => !i.isGC);

  const debounceChangeValue = debounce(() => {
    if (!flag) {
      dispatch("applyFilter");
    }
    flag = false;
  }, 1800);

  return (
    <div className={classes.container}>
      <Box className={classes.box_heder}>
        <div className="title-decentraliza">Quản lý tài khoản</div>
        {user?.role_management_claim?.privileges?.ACCOUNT_MANAGE === 1 && (
          <div>
            <Button
              className="buttons-add-decentraliza"
              onClick={() =>
                history.push(`/admin/account-management/detail/new`)
              }
            >
              <ControlPointIcon fontSize="small" className="icon-button" />
              Tạo tài khoản
            </Button>
          </div>
        )}
      </Box>
      <Box className={classes.box_button}>
        <div className="box-time-search">
          <span>Chọn ngày</span>
          <DatePicker
            value={dataFill?.from ? moment(dataFill?.from) : null}
            onChange={(e) =>
              dispatch({
                type: "applyFilter",
                name: "from",
                value: e?.format("YYYY-MM-DD") || null,
              })
            }
            allowClear={true}
            style={{
              ...Styles.input,
            }}
            placeholder="Từ Ngày"
            format="DD/MM/YYYY"
          />

          <span>{" - "}</span>
          <DatePicker
            value={dataFill?.to ? moment(dataFill?.to) : null}
            onChange={(e) =>
              dispatch({
                type: "applyFilter",
                name: "to",
                value: e?.format("YYYY-MM-DD") || null,
              })
            }
            allowClear={true}
            style={{
              ...Styles.input,
            }}
            placeholder="Đến Ngày"
            format="DD/MM/YYYY"
          />

          <TextField
            placeholder="Nhập tên..."
            className="text-field-search"
            value={dataFill?.keyword || ""}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch({
                  type: "applyFilter",
                  keyword: e.target.value,
                });
                flag = true;
              }
            }}
            onChange={(e) => {
              debounceChangeValue();
              dispatch({
                type: "onChangeFill",
                name: "keyword",
                value: e.target.value,
              });
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchIcon} alt="search_icon" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="div-buttons">
          {user?.role_management_claim?.privileges?.ACCOUNT_EXPORT === 1 && (
            <Button
              className="button dowload"
              onClick={() => {
                dispatch("exportFileExcel");
              }}
            >
              <VerticalAlignBottomIcon
                fontSize="small"
                sx={{ marginRight: "8px" }}
              />
              Xuất file
            </Button>
          )}
          <Button
            className="button dowload"
            onClick={() => dispatch("openFilter")}
          >
            <FilterListIcon fontSize="small" sx={{ marginRight: "8px" }} />
            Bộ lọc
          </Button>
        </div>
      </Box>
      <div>
        <CommonTable
          columns={newColumns}
          rows={listUsers}
          classes={classes}
          data={{ history }}
          event={(type, data) =>
            (ele, ...rest) => {
              dispatch({ type, data, ele, rest });
            }}
        />

        <div className={classes.footer}>
          {/* <div className="rows-per-page">{`Hiển thị ${1}-${20} trong ${100}`}</div> */}
          <div className="rows-per-page">{`Hiển thị ${
            (pageTable.page - 1 || 0) * 20 + 1
          }-${
            (pageTable.page - 1 || 0) * 20 + 20 > pageTable.total
              ? pageTable.total
              : (pageTable.page - 1 || 0) * 20 + 20
          } trong ${pageTable.total || 0}`}</div>
          <Stack spacing={2}>
            <Pagination
              count={pageTable.count || 1} // số trang
              page={pageTable.page || 1} // đang đứng trang thứ
              onChange={(e, pageNumber) =>
                dispatch({ type: "onChangePage", e, pageNumber })
              }
              variant="outlined"
              color="primary"
            />
          </Stack>
        </div>
      </div>
      <PopoverFilter {...{ state, dispatch, user }} />
      <TableExportExcel data={dataExport} dataSearch={dataFill} />
      <LoadingMui loading={Boolean(loading)} />
      <Snackbars
        open={Boolean(snackbar.open)}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => dispatch("onCloseSnackbar")}
      />
    </div>
  );
}

const Styles = {
  input: {
    fontSize: 16,
    boxShadow: "none",
    borderRadius: 16,
    width: "100%",
    border: "1px solid #A0A0A0",
    maxWidth: "130px",
    margin: "0 6px",
    padding: "6px 11px",
  },
};
