import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Slide,
  TextField,
} from "@mui/material";
import React from "react";
import { useStyles } from "../useStyles";
import { genders, insuredType } from "../utils";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddInfoUser(props) {
  const { open, state, dispatch } = props;
  const { dataInfo, saving, errors } = state;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"sm"}
      onClose={() => dispatch({ type: "onClose", name: "user" })}
      className={classes.addDialog}
    >
      <DialogTitle>Thông tin khách hàng</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="title">
              Người được bảo hiểm<span className="red-text">*</span>
            </div>
            <TextField
              fullWidth
              placeholder="Nhập thông tin"
              value={dataInfo?.insuredName || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChange",
                  name: "insuredName",
                  value: e.target.value,
                })
              }
            />
            {errors.includes("insuredName") && (
              <div className="red-text">Vui lòng nhập thông tin.</div>
            )}
          </Grid>

          <Grid item xs={6}>
            <div className="title">
              Ngày sinh<span className="red-text">*</span>
            </div>
            <DatePicker
              value={dataInfo?.insuredDob || null}
              inputFormat="dd/MM/yyyy"
              placeholder="Chọn ngày tháng năm sinh"
              onChange={(date) => {
                dispatch({
                  type: "onChange",
                  value: date,
                  name: "insuredDob",
                });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            {errors.includes("insuredDob") && (
              <div className="red-text">Vui lòng nhập thông tin.</div>
            )}
          </Grid>
          <Grid item xs={6}>
            <div className="title">Giới tính</div>
            <Select
              fullWidth
              value={dataInfo?.insuredGender || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChange",
                  name: "insuredGender",
                  value: e.target.value,
                })
              }
            >
              {genders.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <div className="title">Số điện thoại</div>
            <TextField
              fullWidth
              placeholder="Nhập SĐT"
              value={dataInfo?.insuredPhone || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChange",
                  name: "insuredPhone",
                  value: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <div className="title">
              CMND/ CCCD<span className="red-text">*</span>
            </div>
            <TextField
              fullWidth
              placeholder="Nhập số CMND/ CCCD"
              value={dataInfo?.insuredPrivateId || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChange",
                  name: "insuredPrivateId",
                  value: e.target.value,
                })
              }
            />
            {errors.includes("insuredPrivateId") && (
              <div className="red-text">Vui lòng nhập thông tin.</div>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className="title">Email</div>
            <TextField
              fullWidth
              placeholder="Nhập Email"
              value={dataInfo?.insuredEmail || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChange",
                  name: "insuredEmail",
                  value: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <div className="title">Địa chỉ</div>
            <TextField
              fullWidth
              placeholder="Nhập địa chỉ"
              value={dataInfo?.insuredAddress || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChange",
                  name: "insuredAddress",
                  value: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <div className="title">Nhân viên/ người thân</div>
            <Select
              fullWidth
              value={dataInfo?.insuredType || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChange",
                  name: "insuredType",
                  value: e.target.value,
                })
              }
            >
              {insuredType.map((i) => (
                <MenuItem key={i.value} value={i.value}>
                  {i.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <div className="title">
              Mã nhân viên<span className="red-text">*</span>
            </div>
            <TextField
              fullWidth
              placeholder="Nhập mã nv"
              value={dataInfo?.certNum || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChange",
                  name: "certNum",
                  value: e.target.value,
                })
              }
              inputProps={{ readOnly: !!dataInfo?.id }}
            />
            {errors.includes("certNum") && (
              <div className="red-text">Vui lòng nhập thông tin.</div>
            )}
          </Grid>
          <Grid item xs={6}>
            <div className="title">
              Ngày bắt đầu<span className="red-text">*</span>
            </div>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={dataInfo?.beginDate || null}
              placeholder="Chọn ngày bắt đầu"
              onChange={(date) => {
                dispatch({
                  type: "onChange",
                  value: date,
                  name: "beginDate",
                });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            {errors.includes("beginDate") && (
              <div className="red-text">Vui lòng nhập thông tin.</div>
            )}
          </Grid>
          <Grid item xs={6}>
            <div className="title">
              Ngày kết thúc<span className="red-text">*</span>
            </div>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={dataInfo?.endDate || null}
              minDate={moment(dataInfo?.beginDate).toDate()}
              placeholder="Chọn ngày kết thúc"
              onChange={(date) => {
                dispatch({
                  type: "onChange",
                  value: date,
                  name: "endDate",
                });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            {errors.includes("endDate") && (
              <div className="red-text">Vui lòng nhập thông tin.</div>
            )}
          </Grid>
        </Grid>
        <div className="center">
          <Button
            disabled={saving}
            className="button"
            onClick={() => dispatch("validate")}
          >
            {saving ? "Đang lưu.." : `${!dataInfo?.id ? "Thêm" : "Cập nhật"}`}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddInfoUser;
