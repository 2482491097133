import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getDatailsClaimCarFrom } from "../api/claim";
import { ModalLoading } from "../../../../../ui/modal";
// import ApproveClaimDetail from "./approve-detail";
import CheckClaimDetail from "./check-detail";

// import { Detail } from '../components/contract-detail/detail'
// import { Gara } from '../components/contract-detail/gara'

const QuotationUpdate = () => {
  const location = useLocation();
  const { id } = useParams();
  const [productCode, setProductCode] = useState(null);
  const [render, setRender] = useState(false);
  const [details, setDetails] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const systemType = !!user ? user?.extra_info?.userType || "" : "";

  useEffect(() => {
    ModalLoading.show();
    getDatailsClaimCarFrom(systemType, id)
      .then((res) => {
        ModalLoading.hide();
        setDetails(res?.data?.result);
      })
      .catch((err) => {
        ModalLoading.hide();
      });
  }, []);

  useEffect(() => {
    setProductCode(location.pathname.split("/")[5]);
    setRender(true);
  }, []);

  return (
    <>
      {render && (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8,
          }}
        >
          <CheckClaimDetail details={details} />
        </Box>
      )}
    </>
  );
};

export default QuotationUpdate;
