import api from "../configs/api";

// const getInforOrder = (data) =>
//   api
//     .apiForm("POST", "/api/v2/order/partner/ahamove/detail", data)
//     .then((res) => res)
//     .catch((error) => error);

const getInforOrder = (data) =>
  api
    .apiForm("POST", "/api/v2/cert/partner/ahamove/detail", data)
    .then((res) => res)
    .catch((error) => error);

const getHistoryOrder = (data) =>
  api
    .apiForm("POST", "/api/v2/claim/partner/ahamove/list", data)
    .then((res) => res)
    .catch((error) => error);

const getListBank = () =>
  api
    .apiForm("POST", "/api/v2/bank/list", {
      limit: 1000,
      //  "offset": lấy từ vị trí
    })
    .then((res) => res)
    .catch((error) => error);

const uploadImg = (data) =>
  api
    .apiForm("POST", "/api/v2/document/upload/cdn", data)
    .then((res) => res)
    .catch((error) => error);

const uploadImgAhamove = (data) =>
  api
    .apiForm("POST", "/api/v2/document/partner/ahamove/upload/cdn", data)
    .then((res) => res)
    .catch((error) => error);

const uploadUrlImg = (data) =>
  api
    .apiForm("POST", "/api/v2/document/partner/ahamove/upload/cdn/url", data)
    .then((res) => res)
    .catch((error) => error);

const uploadImg_base64 = (data) =>
  api
    .apiForm(
      "POST",
      "/api/v2/document/partner/ahamove/upload/cdn/image-base64",
      data
    )
    .then((res) => res)
    .catch((error) => error);

const create = (data) =>
  api
    .apiForm("POST", "/api/v2/claim/partner/ahamove", data)
    .then((res) => res)
    .catch((error) => error);

const getListIndemnify = (systemType = "globalcare", data) =>
  api
    .apiForm("POST", `/api/v2/claim/${systemType}/list`, data)
    .then((res) => res)
    .catch((error) => error);

const getDetailIndemnify = (systemType = "globalcare", id) =>
  api
    .apiForm("GET", `/api/v2/claim/${systemType}/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

const updataDetail = (action, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/globalcare/add-elements/${action}`, data)
    .then((res) => res)
    .catch((error) => error);

const getDetailClaimIntransit = (id) =>
  api
    .apiForm("GET", `/api/v2/claim/client/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

const getFileByUrl = (url) =>
  api
    .apiGetFileByUrl(url)
    .then((res) => res.data)
    .catch((error) => error);

export {
  getInforOrder,
  getListBank,
  uploadImg,
  create,
  getHistoryOrder,
  uploadUrlImg,
  getListIndemnify,
  getDetailIndemnify,
  updataDetail,
  uploadImg_base64,
  getDetailClaimIntransit,
  getFileByUrl,
  uploadImgAhamove,
};
