import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import * as PATH from "../../../../configs/routesConfig";
import './assets/css/style.css'

const routes = [
    {
        path: `${PATH.CLAIM_PHUCBINHAN}`,
        exact: true,
        component: lazy(() => import('./components/home')),
    },
    {
        path: `${PATH.CLAIM_PHUCBINHAN}/intro`,
        exact: true,
        component: lazy(() => import('./components/intro')),
    },
    {
        path: `${PATH.CLAIM_PHUCBINHAN}/list`,
        exact: true,
        component: lazy(() => import('./components/list/ClaimList')),
    },
    {
        path: `${PATH.CLAIM_PHUCBINHAN}/create`,
        exact: true,
        component: lazy(() => import('./components/create/index')),
    },
]

const PhucBinhAnRouter = () => {
    return (
        <Suspense fallback={<></>}>
            {routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}>
                </Route>
            ))}
        </Suspense>
    );
};

export default PhucBinhAnRouter;
