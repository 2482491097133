import React from "react";
import { Backdrop, Box, Typography, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function ProgressLoading(props) {
  const { value = 10, open = false } = props;
  const classes = styleProgressLoading();

  return (
    <Backdrop
      style={{
        zIndex: 9999,
        color: "#fff",
        borderRadius: "5px",
      }}
      open={!!open}
    >
      <Box sx={{ display: "block" }}>
        <Box
          sx={{
            minWidth: 135,
            marginBottom: "12px",
            width: "60vw",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ color: "white", fontWeight: "600" }}
            color="text.secondary"
          >{`AI đang phân tích  ${Math.round(+value)}%`}</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "60vw", mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={+value}
              className={classes.linearProgress}
            />
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
}

const styleProgressLoading = makeStyles((theme) => ({
  linearProgress: {
    height: 20,
    borderRadius: "18px",
    backgroundColor: "#dcdcdc4d",

    "& .MuiLinearProgress-bar": {
      backgroundColor: "white",
    },
  },

  //
}));
