import { TableCell } from "@mui/material";
import { formatDate } from "../util";

export const columns = [
  {
    label: "STT",
    headerClass: "STT ",
    renderCell: ({ rowIdx }) => (
      <TableCell className="STT">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "Họ và tên ",
    key: "insuredName",
  },
  {
    label: "Số thẻ BH",
    key: "certNum",
  },
  {
    label: "CCCD/CMND",
    key: "insuredPrivateId",
  },
  {
    label: "NV/NT",
    key: "insuredType",
  },
  // {
  //   label: "Số diện thoại",
  //   key: "insuredPhone",
  // },
  {
    label: "Ngày bắt đầu",
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {formatDate(item?.beginDate)}
      </TableCell>
    ),
  },
  {
    label: "Ngày hết hạn",
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {formatDate(item?.endDate)}
      </TableCell>
    ),
  },
];
