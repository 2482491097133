import axios from "axios";
import config from "../../../../../configs/config";

export async function getOrders(data) {
  const result = await axios
    .post(`${config.SERVER}/api/v1/tomato/orders`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .catch((e) => e);

  return result;
}

export async function getInsuredPersons(params) {
  const result = await axios
    .post(
      `${config.SERVER}/api/v1/tomato/claim/insured-persons`,
      {
        ...params,
        source: "admin",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .catch((e) => e);

  return result;
}

export async function getInsuredOrders(params) {
  const result = await axios
    .post(
      `${config.SERVER}/api/v1/tomato/claim/insured-orders`,
      {
        ...params,
        source: "admin",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .catch((e) => e);

  return result;
}
