export const initialState = {
  overviewListMerchant: [],
  overviewListProduct: [],
  overviewList: [],
  listOrg: [],
  listProductParent: [],
  listMerchant: [],
  xChart: [],
  showChart: {
    insuredPerson: true,
    claims: true,
    claimsApprove: true,
    claimsReject: true,
  },
  yChart: {},
  overview: {},
  dataChart: {},
  // dataOverview: {},
  payloadApi: {},
  dataFiter: {},
  modalDate: {},
  payloadAutocomplete: {},
  snackbar: {},
  dialogListP_M: {},
  pageListP_M: {},
  loadingAutocomplete: {},
  loading: false,
  shortDashboard: "count_status_id",
  refresh_interval: "off",
};

export const converDate = (string) => {
  if (!string) {
    return null;
  }

  if (string?.indexOf("T") > -1) {
    return string;
  } else {
    if (string.includes("/") || string.includes("-")) {
      const check_string = (string.match(/\//g) || []).length;
      const matches = (string.match(/(\d+)[-\/]/g) || []).map((match) =>
        match.slice(0, -1)
      );

      if (matches[0]?.length == 4) {
        return string;
      }

      return check_string > 0
        ? string.split("/").reverse().join("/")
        : string.split("-").reverse().join("-");
    }

    return null;
  }
};

export const option_refresh_interval = [
  { label: "Off", value: "off" },
  { label: "5s", value: 5 },
  { label: "10s", value: 10 },
  { label: "30s", value: 30 },
  { label: "1m", value: 60 },
  { label: "5m", value: 300 },
  { label: "15m", value: 900 },
  { label: "30m", value: 1800 },
  { label: "1h", value: 3600 },
];
