import React, { useEffect } from "react";

import { styledStep2 } from "../components/step/styledStep2";

export default function Step2(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledStep2(isApp);
  const {
    destination: { data, index },
  } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={classes.container}
      style={{
        minHeight: isApp ? "calc(100vh - 165px)" : "calc(100vh - 125px)",
      }}
    >
      <div className="titleHead">THÔNG TIN NGƯỜI GỬI</div>
      <Row title="Họ và tên" value={data?.sender?.fullName || ""} capitalize />
      <Row title="Số điện thoại" value={data?.sender?.phoneNumber || ""} />
      <Row nbm title="Địa chỉ" value={data?.sender?.address || ""} capitalize />
      <div className="titleHead">THÔNG TIN NGƯỜI NHẬN</div>
      <Row
        title="Họ và tên"
        value={data?.receiver?.fullName || ""}
        capitalize
      />
      <Row title="Số điện thoại" value={data?.receiver?.phoneNumber || ""} />
      <Row
        nbm
        title="Địa chỉ"
        value={data?.receiver?.address || ""}
        capitalize
      />
      <div className="titleHead">THÔNG TIN TÀI XẾ</div>
      <Row title="Họ và tên" value={data?.driver?.fullName || ""} capitalize />
      <Row title="Số điện thoại" value={data?.driver?.phoneNumber || ""} />
      {/* <Row nbm title="Biển số xe" value={data?.driver?.license || ""} /> */}
      <div className={"rowDiv bm"}>
        <span className="titleSpan">{"Biển số xe"}</span>
        <span style={{ textTransform: "uppercase" }} className={"valueSpan"}>
          {data?.driver?.license || ""}
        </span>
      </div>
      <div className="titleHead">THÔNG TIN THANH TOÁN</div>
      <Row
        nbm
        title="Loại thanh toán"
        value={
          data?.paymentInfo?.paymentId === 1
            ? "Ứng thu hộ COD"
            : data?.paymentInfo?.paymentId === 2
            ? "Không ứng COD"
            : null
        }
      />
      <div className="titleHead">LOẠI HÀNG HÓA</div>
      <Row title="Phân loại" value={data?.productInfo?.typeNameOfProduct} />
      <div className="titleHead">TRẠNG THÁI</div>
      <Row
        nbm
        title="Trạng thái"
        value={data?.statusId === 1 ? "Thành công" : "Thất bại"}
      />
    </div>
  );
}

const Row = (props) => {
  const { title, value, nbm, capitalize } = props;

  return (
    <div className={nbm ? "rowDiv" : "rowDiv bm"}>
      <span className="titleSpan">{title}</span>
      <span
        className={capitalize ? "capitalize valueSpan" : "valueSpan"}
        style={{ overflow: "hidden" }}
      >
        {value}
      </span>
    </div>
  );
};
