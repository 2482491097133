import moment from "moment";

import { getStatusById } from "../utils";

export default function MerchantTableExportExcel(props) {
  const { from, to, data } = props;

  const fromDate = !from
    ? "..............."
    : moment(from).format("DD/MM/YYYY");
  const toDate = !to ? "..............." : moment(to).format("DD/MM/YYYY");

  return (
    <>
      <div style={{ display: "none" }}>
        <table
          className="collapse-border"
          id={"merchant-report-export-data-table"}
          data-cols-width="7, 25, 20, 20, 25, 17, 15, 15, 15, 15, 15"
        >
          <thead style={{ fontSize: "13" }}>
            <tr />
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"16"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                BÁO CÁO DOANG NGHIỆP
              </th>
            </tr>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >{`Từ ngày: ${fromDate} ---  Đến Ngày: ${toDate} `}</th>
            </tr>
            <tr />
            <tr />
            <tr />
            <tr />
            <tr style={{ fontSize: "13" }}>
              <Th>STT</Th>
              <Th>Doanh nghiệp </Th>
              <Th>Mã số thuế</Th>
              <Th>Tên sản phẩm</Th>
              <Th>Nhà bảo hiểm</Th>
              <Th>Quản lý bởi</Th>
              <Th>Tỉ lệ BT</Th>
              <Th>{"Tổng đơn BH"}</Th>
              <Th>{"Doanh số (VNĐ)"}</Th>
              <Th>YCBT</Th>
              <Th>{"Tổng bồi thường (VNĐ)"}</Th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13" }}>
            {data.map((item, index) => {
              return (
                <>
                  <tr key={index} style={{ fontSize: "13" }}>
                    <Td>{index + 1}</Td>
                    <Td>{item?.title || ""}</Td>
                    <Td>{item?.tax_code || ""}</Td>
                    <Td>{item.product_title || ""}</Td>
                    <Td>{item.provider_title || ""}</Td>
                    <Td>{item.organization_title}</Td>
                    <Td>
                      {((+item?.total_amount || 0) / (+item?.total_fees || 1)) *
                        100}
                    </Td>
                    <Td>{item?.participants}</Td>
                    <th
                      data-f-name={"Times new roman"}
                      data-f-sz={"12"}
                      data-b-a-s={"thin"}
                      data-a-wrap={true}
                      data-t="n"
                    >
                      {item.total_fees || 0}
                    </th>
                    {/* <Td>
                      {`${item.total_fees || "0"}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                    </Td> */}
                    <Td>{item?.total_claim || ""}</Td>
                    {/* <Td>
                      {`${item.total_amount || "0"}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                    </Td> */}
                    <th
                      data-f-name={"Times new roman"}
                      data-f-sz={"12"}
                      data-b-a-s={"thin"}
                      data-a-wrap={true}
                      data-t="n"
                    >
                      {item.total_amount || 0}
                    </th>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const Th = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-h={"center"}
      data-a-wrap={true}
      data-f-bold={true}
    >
      {props.children}
    </th>
  );
};

const Td = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-wrap={true}
      data-t={props.type || ""}
    >
      {props.children}
    </th>
  );
};
