import {
  Button,
  Card,
  Grid,
  Input,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { useStyles } from "../styles/useStyles";

import code from "../assets/code.svg";
import name from "../assets/user-name-icon.svg";
import merchant from "../assets/merchant.svg";
import phone from "../assets/phone.svg";
import search from "../assets/search.svg";
import status from "../assets/status.svg";
import toDate from "../assets/toDate.svg";

const arr = [
  { title: "Tất cả", id: 0 },
  { title: "Đang xử lý", id: 2 },
  { title: "Bổ sung hồ sơ", id: 4 },
  { title: "Đã duyệt", id: 5 },
  { title: "Hoàn tất", id: 6 },
  { title: "Từ chối", id: 7 },
];

const merchants = [
  { title: "Tất cả", id: 0 },
  { title: "Ahamove", id: 18 },
  { title: "Fina", id: 3 },
];

export default function CarSearch(props) {
  const { state, dispatch, user } = props;
  const classes = useStyles();

  const { dataSearch } = state;

  return (
    <Card className={classes.cardHeadSelect}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <InputText
            icon={phone}
            label={"Số điện thoại NĐBH"}
            placeholder="Nhập thông tin"
            value={dataSearch?.insuredPhone || ""}
            onChange={(e) => {
              if (
                !"0123456789".includes(e?.nativeEvent?.data) &&
                e?.nativeEvent?.data !== null
              )
                return;
              dispatch({
                type: "onChangeSearch",
                name: "insuredPhone",
                value: e.target.value,
              });
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                dispatch("onSubmitSearch");
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InputText
            icon={name}
            label={"Họ tên NĐBH"}
            placeholder="Nhập thông tin"
            value={dataSearch?.insuredName || ""}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                dispatch("onSubmitSearch");
              }
            }}
            onChange={(e) => {
              dispatch({
                type: "onChangeSearch",
                name: "insuredName",
                value: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InputText
            icon={code}
            label={"Mã hồ sơ bồi thường"}
            placeholder="Nhập thông tin"
            value={dataSearch?.code || ""}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                dispatch("onSubmitSearch");
              }
            }}
            onChange={(e) => {
              dispatch({
                type: "onChangeSearch",
                name: "code",
                value: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className={classes.divInput}>
            <div className={classes.label}>
              <img src={status} />
              <div style={{ paddingLeft: "8px", color: "#2A3790" }}>
                {"Trạng thái"}
              </div>
            </div>
            <div className="border" style={{ padding: "0 0 0 8px" }}>
              <Select
                className={classes.select}
                size="small"
                label="Chọn trạng thái"
                fullWidth
                value={dataSearch?.statusId || 0}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    dispatch("onSubmitSearch");
                  }
                }}
                onChange={(e) => {
                  dispatch({
                    type: "onChangeSearch",
                    name: "statusId",
                    value: e.target.value,
                  });
                }}
              >
                {arr.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className={classes.divInput}>
            <div className={classes.label}>
              <img src={toDate} />
              <div style={{ paddingLeft: "8px", color: "#2A3790" }}>
                {"Từ ngày"}
              </div>
            </div>
            <div className="border">
              <DatePicker
                onKeyDown={(e) => {}}
                fullWidth
                disableFuture
                inputFormat="dd/MM/yyyy"
                value={dataSearch?.from || null}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeSearch",
                    name: "from",
                    value: e,
                  })
                }
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    fullWidth
                    {...params}
                    className={classes.date}
                    focused
                    error={false}
                  />
                )}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className={classes.divInput}>
            <div className={classes.label}>
              <img src={toDate} />
              <div style={{ paddingLeft: "8px", color: "#2A3790" }}>
                {"Đến ngày"}
              </div>
            </div>
            <div className="border">
              <DatePicker
                onKeyDown={(e) => {}}
                fullWidth
                disableFuture
                // views={["day", "month", "year"]}
                minDate={dataSearch.from || null}
                inputFormat="dd/MM/yyyy"
                value={dataSearch?.to || null}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeSearch",
                    name: "to",
                    value: e,
                  })
                }
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    fullWidth
                    {...params}
                    className={classes.date}
                    focused
                    error={false}
                  />
                )}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className={classes.divInput}>
            <div className={classes.label}>
              <img src={merchant} />
              <div style={{ paddingLeft: "8px", color: "#2A3790" }}>
                Kênh khai thác
              </div>
            </div>
            <div className="border" style={{ padding: "0 0 0 8px" }}>
              <Select
                className={classes.select}
                size="small"
                label="Chọn trạng thái"
                disabled={user?.extra_info?.userType == "merchant"}
                fullWidth
                value={dataSearch?.merchantId || 0}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    dispatch("onSubmitSearch");
                  }
                }}
                onChange={(e) => {
                  dispatch({
                    type: "onChangeSearch",
                    name: "merchantId",
                    value: e.target.value,
                  });
                }}
              >
                {merchants.map((item, index) => (
                  <MenuItem value={item.id} key={`merchant_item_${index}`}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className={classes.divButton}>
            <Button
              className={classes.buttonSearch}
              onClick={() => dispatch("onSubmitSearch")}
            >
              <img src={search} alt="search" className="img" />
              Tìm kiếm
            </Button>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

const InputText = (props) => {
  const { label, icon, placeholder, value, onChange, name, presIcon } = props;
  const classes = useStyles();
  return (
    <div className={classes.divInput}>
      <div className={classes.label}>
        <img src={icon} />
        <div style={{ paddingLeft: "8px", color: "#2A3790" }}>{label}</div>
      </div>
      <div className="border">
        <Input
          {...props}
          className="input"
          fullWidth
          onChange={onChange}
          value={value}
          placeholder={placeholder}
        />
        {presIcon && (
          <img src={presIcon} atl={name} style={{ maxWidth: "20px" }} />
        )}
      </div>
    </div>
  );
};
