import React, { useCallback, useRef, useState } from "react";
import { InputAdornment } from "@mui/material";

import { TextField } from "../overrideMui";
import { listCompanyStyled } from "../styles/list-company-style";
import searchIcon from "./../../../../assets/icons/search-icon.svg";
import { debounce } from "lodash";

export default function ListCompany(props) {
  const classes = listCompanyStyled();
  const { state, dispatch } = props;
  const { listCompanys, inforCompany, loadingCompany } = state;

  let flag = false;
  const ref = useRef(null);

  const onScroll = () => {
    if (ref.current) {
      const scrollDiv = ref.current;
      const lastItem = scrollDiv.querySelector(
        "#divScroll .scroll-item:last-child"
      );

      if (
        Math.abs(lastItem?.offsetTop - scrollDiv.scrollTop - 178) <
          lastItem?.offsetHeight + scrollDiv.offsetHeight &&
        !loadingCompany
      ) {
        dispatch("getListOrg");
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.title_list}>
        <span>Danh sách</span>
      </div>
      <div className={classes.search_list}>
        <TextField
          fullWidth
          placeholder="Tìm tên công ty..."
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              dispatch({
                type: "searchCompany",
                key: e.target.value,
              });
              flag = true;
            }
          }}
          onChange={debounce((e) => {
            if (!flag) {
              dispatch({ type: "searchCompany", key: e.target.value });
              flag = false;
            }
          }, 2000)}
          // onChange={(e) => {
          //   debounceChange(e);
          //   dispatch({
          //     type: "onChangeSearchCompany",
          //     value: e.target.value,
          //   });
          //   setFlag(true);
          //   return;
          // }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={searchIcon} alt="search_icon" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div
        style={{ overflow: "auto", height: "calc(100vh - 301px)" }}
        ref={ref}
        id="divScroll"
        onScroll={onScroll}
      >
        {listCompanys.map((item, index) => (
          <div
            onClick={() => dispatch({ type: "onClickCompany", item })}
            className={`scroll-item ${classes.item_list} ${
              item.id == inforCompany.id ? classes.active_company : ""
            }`}
            key={`${item?.title}_abc_${index}`}
          >
            {item?.title}{" "}
            <span style={{ textTransform: "uppercase" }}>
              {item?.extra_info?.org_type
                ? "/ " + item?.extra_info?.org_type
                : ""}
            </span>
          </div>
        ))}
        {loadingCompany && (
          <div
            style={{
              fontWeight: "600",
              color: "#0B7CE4",
              width: "100%",
              textAlign: "center",
              fontSize: "17px",
            }}
          >
            Đang tải thêm ...{" "}
          </div>
        )}
      </div>
    </div>
  );
}
