import { ThemeProvider, createTheme, radioClasses } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { Pie } from "react-chartjs-2";

import documentGim from "../../../../../../assets/health/document-gim.svg";
import documentCheck from "../../../../../../assets/health/document-check.svg";
import documentCancel from "../../../../../../assets/health/document-cancel.svg";
import userIcon from "../../../../../../assets/health/user.svg";
import fileMultiple from "../../../../../../assets/health/file-multiple.svg";
import fileMultipleOri from "../../../../../../assets/health/file-multiple-ori.svg";
import fileMultipleGreen from "../../../../../../assets/health/file-multiple-green.svg";
import fileMultipleRed from "../../../../../../assets/health/file-multiple-red.svg";
import { styeleds } from "./styled";

export default function FlagCard(props) {
  const classes = styeleds();
  const { state } = props;
  const { dataDashboard } = state;

  const itemFlag = [
    {
      label: "Người được bảo hiểm",
      icon1: userIcon,
      icon2: fileMultiple,
      number: `${dataDashboard?.insuredPersons?.total_person || 0}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        "."
      ),
      text2: "NĐBH",
      money: `${dataDashboard?.insuredPersons?.total_amount || 0}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        "."
      ),
      color: "#0B3C8A",
    },
    {
      label: "Yêu cầu bồi thường",
      icon1: documentGim,
      icon2: fileMultipleOri,
      number: `${dataDashboard?.claims?.total_claim || 0}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        "."
      ),
      text2: "Hồ sơ",
      money: `${
        dataDashboard?.claims?.total_request_amount_claim || 0
      }`.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      color: "#FFA21F",
    },
    {
      label: "Đã chi trả bồi thường",
      icon1: documentCheck,
      icon2: fileMultipleGreen,
      number: `${
        dataDashboard?.claims_approve?.total_claim_approve || 0
      }`.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      text2: "Hồ sơ",
      money: `${
        dataDashboard?.claims_approve?.total_amount_claim_approve || 0
      }`.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      color: "#069D27",
    },
    {
      label: "Từ chối chi trả",
      icon1: documentCancel,
      icon2: fileMultipleRed,
      number: `${
        dataDashboard?.claims_reject?.total_claim_reject || 0
      }`.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      text2: "Hồ sơ",
      money: `${
        dataDashboard?.claims_reject?.total_amount_claim_reject || 0
      }`.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      color: "#FF003D",
    },
  ];

  const itemGrid = { xs: 9, sm: 6, md: 4, lg: 3, xl: 2.5, xxl: 2 };

  const claimRate = {
    person_percent:
      ((dataDashboard?.insuredPersons?.total_person || 0) * 100) /
      ((dataDashboard?.insuredPersons?.total_person || 0) +
        (dataDashboard?.claims?.total_claim || 0)),
    claim_percent:
      ((dataDashboard?.claims?.total_claim || 0) * 100) /
      ((dataDashboard?.insuredPersons?.total_person || 0) +
        (dataDashboard?.claims?.total_claim || 0)),
    rate: (
      ((dataDashboard?.claims?.total_claim || 0) * 100) /
      (dataDashboard?.insuredPersons?.total_person || 1)
    ).toFixed(1),
  };

  const dataChart = {
    labels: [],
    datasets: [
      {
        data: [
          claimRate?.person_percent.toFixed(1),
          claimRate?.claim_percent.toFixed(1),
        ],
        backgroundColor: ["rgb(57, 97, 160)", "rgb(255, 162, 31)"],
        borderColor: "white",
        borderWidth: 2,
      },
    ],
  };

  return (
    <ThemeProvider
      theme={createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 650,
            md: 800,
            lg: 900,
            xl: 1536,
            xxl: 1950,
          },
        },
      })}
    >
      <div className={classes.contaiFlagCard}>
        <Grid container spacing={2}>
          {itemFlag.map((item, key) => (
            <Grid item {...itemGrid}>
              <div className={classes.itemflagCard}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ color: "#686868", fontWeight: "600" }}>
                    {item.label}
                  </span>
                  <img src={item.icon1} height={24} width={24} />
                </div>
                <div
                  style={{
                    color: item.color,
                    display: "flex",
                    alignItems: "center",
                    margin: "8px 0",
                  }}
                >
                  <img src={item.icon2} height={18} width={18} />
                  <span style={{ fontSize: "18px", margin: "0 8px" }}>
                    {item.number}
                  </span>
                  <span style={{ fontSize: "11px" }}>{item.text2}</span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  <span style={{ fontSize: "20px", fontWeight: "600" }}>
                    {item.money}
                  </span>
                  <span
                    style={{
                      fontSize: "11px",
                      fontWeight: "600",
                      marginLeft: "4px",
                    }}
                  >
                    đ
                  </span>
                </div>
              </div>
            </Grid>
          ))}
          <Grid item {...itemGrid}>
            <div
              className={`${classes.itemflagCard} ${classes.itemflagCardChart}`}
            >
              <div className="infor">
                <div style={{ color: "#686868", fontWeight: "600" }}>
                  Tỉ lệ bồi thường
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#FFA21F",
                      width: "14px",
                      height: "10px",
                      borderRadius: "16px",
                      marginRight: "6px",
                    }}
                  />
                  <span style={{ fontSize: "13px" }}>Yêu cầu bồi thường</span>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#3961A0",
                      width: "14px",
                      height: "10px",
                      borderRadius: "16px",
                      marginRight: "6px",
                    }}
                  />
                  <span style={{ fontSize: "13px" }}>Người được bảo hiểm</span>
                </div>
                <div style={{ fontSize: "13px" }}>
                  Tỉ lệ ước tính:{" "}
                  <span style={{ color: "#069D27" }}>
                    {claimRate?.rate ? `${claimRate?.rate} %` : ""}
                  </span>{" "}
                </div>
              </div>
              <div className="chart">
                <Pie data={dataChart} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}
