import api from "../configs/api";

function getListNotification(type) {
  if (type === "department_garage") {
    type = "department";
  }
  return api.apiForm("POST", `/api/v1/notification/${type}/list`);
}

function getListNotification_v2(type, params) {
  if (type === "department_garage") {
    type = "department";
  }
  return api
    .apiForm("POST", `/api/v2/notification/${type}/list`, params)
    .then((res) => res)
    .catch((error) => error.response);
}

function getListProductClaim(data) {
  return api
    .apiForm("POST", "/api/v2/product-parent/list", data)
    .then((res) => res)
    .catch((error) => error.response);
}

function markReadNotification(id) {
  return api.apiForm("PUT", `/api/v2/notification/${id}/mark-read`);
}

function markReadAllNotification() {
  return api.apiForm("PUT", `/api/v2/notification/mark-all-read`);
}

export {
  getListNotification,
  getListNotification_v2,
  getListProductClaim,
  markReadNotification,
  markReadAllNotification,
};
