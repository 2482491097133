import moment from "moment";
import { useHistory } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { Pagination, Select, Table } from "antd";
import iconDownload from "../assets/icons/iconDownload.svg";
import { useStyles } from "../styles/useStyles";
import { contants_status } from "../utils/common";
import TableToExcel from "@linways/table-to-excel";
import ExportExcelTable from "./excel-table";
import { useEffect } from "react";

const { Option } = Select;

export const AccountTable = ({ state, dispatch, roleAccount }) => {
  const { dataList, listExport, loading, page, count } = state;
  const history = useHistory();
  const classes = useStyles();

  const getLeverUser = (data = []) => {
    const level = [];
    data?.forEach((i) => {
      !!i?.level_id &&
        !level.includes(+i?.level_id) &&
        level.push(+i?.level_id);
    });

    return level.join(", ") || "";
  };

  const columns = [
    {
      title: "STT",
      key: "STT",
      render: (value, record, index) => {
        return index + 1;
      },
      width: "35px",
    },
    {
      title: "Họ tên",
      dataIndex: "fullname",
      key: "fullname",
      width: "200px",
      render: (value) => {
        return <div className={classes.valueName}>{value}</div>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "270px",
      render: (value) => {
        return <div className={classes.valueTable}>{value}</div>;
      },
    },
    {
      title: "User đăng nhập",
      dataIndex: "username",
      key: "username",
      width: "15%",
      render: (value) => {
        return <div className={classes.valueTable}>{value}</div>;
      },
    },
    {
      title: "Tài khoản cấp",
      dataIndex: "submenu",
      key: "submenu",
      width: "15%",
      render: (value) => {
        return (
          <div className={classes.valueTable}>
            {getLeverUser(value || [])
              ? `Cấp ${getLeverUser(value || [])}`
              : ""}
          </div>
        );
      },
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (value) => {
        return (
          <div
            style={{
              color: "white",
              background: `${contants_status[value].bgColor}`,
              padding: "3px 15px",
              borderRadius: "36px",
              whiteSpace: "nowrap",
              width: "max-content",
            }}
          >
            {contants_status[value].label}
          </div>
        );
      },
    },
    {
      show: true,
      title: "Chi tiết",
      dataIndex: "id",
      width: "10%",
      render: (value) => {
        return (
          <a
            onClick={() =>
              // history.push(`/adminv2/account-manage/update/${value}`, {
              //   userId: value,
              //   roleEdit: roleAccount?.view_and_edit === 1,
              // })
              history.push(`/admin/account-manage/update/${value}`, {
                userId: value,
                roleEdit: roleAccount?.view_and_edit === 1,
              })
            }
            style={{ maxWidth: "90%" }}
          >
            <u>Chi tiết TK</u>
          </a>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "created_date",
      key: "created_date",
      width: "10%",
      render: (value) => {
        return moment(value).format("DD/MM/YYYY");
      },
    },
  ];

  const cols = !roleAccount?.view_and_edit
    ? columns.filter((i) => !i.show)
    : columns;

  useEffect(() => {
    if (count > 0) {
      const tableId = "export-data-table-account";
      const name = `quan-ly-tai-khoan.xlsx`;
      TableToExcel.convert(document.getElementById(tableId), {
        name,
        sheet: {
          name: "Sheet 1",
        },
      });
    }
  }, [count]);

  return (
    <Box sx={{ minWidth: 800 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <div className={classes.row}>
          <Typography variant="h5" my={4}>
            Kết quả tra cứu
          </Typography>
          <div style={{ marginLeft: 20 }}>
            <Select
              value={page.pageSize}
              className={classes.selectForm}
              onChange={(e) => {
                dispatch({ type: "onPaging", name: "pageSize", e });
              }}
            >
              <Option value={10}>Hiển thị 10</Option>
              <Option value={20}>Hiển thị 20</Option>
              <Option value={50}>Hiển thị 50</Option>
            </Select>
          </div>
        </div>
        {roleAccount?.export === 1 && (
          <Button
            color="primary"
            onClick={() => dispatch({ type: "search", name: "export" })}
          >
            <img alt="iconDownload" src={iconDownload} /> &nbsp; Xuất báo cáo
          </Button>
        )}
      </Box>
      <Table
        className="table-account-maneger"
        columns={cols}
        rowClassName={(record, index) => "table-row-account-mn"}
        dataSource={dataList}
        loading={loading}
        size="small"
        pagination={false}
      />
      <div className={classes.pagination}>
        <Pagination
          size="small"
          showSizeChanger={false}
          defaultCurrent={5}
          pageSize={page?.pageSize}
          current={page?.pageNumber}
          total={page?.total}
          onChange={(pageNumber, pageSize) => {
            dispatch({
              type: "onPaging",
              name: "pagination",
              pageNumber,
              pageSize,
            });
          }}
        />
      </div>
      <ExportExcelTable data={listExport} />
    </Box>
  );
};
