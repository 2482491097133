import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";

import { getDatailsClaimCarFromProvider_v2 } from "../../../../../apis/carAccidentApis";
import { ModalLoading } from "../../../../../ui/modal";

import ClaimDetailTomato from "./detail-claim-tomato";

const ClaimDetails = () => {
  const user = localStorage.getItem("user") || false;
  const isCheckUser = user && user !== "undefined" && user !== "null";
  const systemType =
    (isCheckUser && JSON.parse(user)?.extra_info?.userType) || 0;

  const { id } = useParams();
  const location = useLocation();
  const [details, setDetails] = useState({});
  const [productCode, setProductCode] = useState("");
  useEffect(() => {
    setProductCode(location.pathname.split("/")[5]);
    fetchData();
    return () => {
      localStorage.removeItem("desNavbar");
      localStorage.removeItem("titleNavbar");
    };
  }, []);

  const fetchData = () => {
    if (systemType === "provider" || systemType === "globalcare") {
      fetchDetails();
    }
  };

  const fetchDetails = () => {
    ModalLoading.show();
    getDatailsClaimCarFromProvider_v2(systemType, id)
      .then((res) => {
        setDetails(res?.data?.result);
        ModalLoading.hide();
      })
      .catch((err) => {
        ModalLoading.hide();
      });
  };
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
          marginTop: "5px",
        }}
      >
        <ClaimDetailTomato details={details} />
      </Box>
    </>
  );
};

export default ClaimDetails;
