import { makeStyles } from "@mui/styles";

export const creatProduct = makeStyles(() => ({
  container: {
    width: "100%",
    padding: "0 12px",
    marginTop: "45px",
  },

  box_heder: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 0",
    alignItems: "center",

    "& .title-creat-product": {
      fontSize: "20px",
      fontWeight: "700",
    },

    "& .buttons-delete": {
      background: "white",
      color: "#4B4B4B",
      fontWeight: "500",
      border: "1px solid #DCDCDC",
      marginRight: "12px",
    },

    "& .buttons-save": {
      background: "#0B7CE4",
      color: "white",
      fontWeight: "500",
    },
  },

  box_logo: {
    background: "white",
    padding: "12px",
    border: "1px solid #ECECEC",

    "& .title-logo": {
      color: "#4B4B4B",
      fontWeight: "700",
      fontSize: "17px",
    },

    "& .box-logo-item": {
      display: "flex",
      alignItems: "center",
      border: "1px dashed #C0C0C0",
      borderRadius: "12px",
      padding: "12px",
      flexDirection: "column",
      marginTop: "12px",

      "& .note-img": {
        color: "#A0A0A0",
        fontSize: "14px",
        display: "contents",
        margin: "8px 0",
      },

      "& .btn-choose-img": {
        color: "#0B7CE4",
        fontSize: "14px",
        background: "#E3F3FF",
        marginTop: "12px",
      },
    },
  },

  box_type_insu: {
    background: "white",
    padding: "12px",
    border: "1px solid #ECECEC",
    margin: "12px 0",

    "& .title": {
      color: "#4B4B4B",
      fontWeight: "700",
      fontSize: "17px",
      marginBottom: "18px",
    },

    "& .label-select": {
      color: "#4B4B4B",
      fontWeight: "500",
      marginBottom: "8px",
      fontSize: "14px",
    },
  },

  box_status: {
    background: "white",
    padding: "12px",
    border: "1px solid #ECECEC",
    margin: "12px 0",

    "& .title": {
      color: "#4B4B4B",
      fontWeight: "700",
      fontSize: "17px",
      marginBottom: "18px",
    },

    "& .div-switch": {
      color: "#4B4B4B",
      fontWeight: "500",
      marginBottom: "8px",
      fontSize: "14px",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },

    "& .date-label": {
      color: "#4B4B4B",
      fontWeight: "500",
      marginBottom: "6px",
      fontSize: "14px",
    },

    "& .date-value": {
      color: "#3961A0",
      fontWeight: "500",
      marginBottom: "6px",
      fontSize: "14px",
    },
  },

  box_infor: {
    background: "white",
    padding: "12px",
    border: "1px solid #ECECEC",

    "& .title": {
      color: "#4B4B4B",
      fontWeight: "700",
      fontSize: "17px",
      marginBottom: "18px",
    },

    "& .label": {
      color: "#4B4B4B",
      fontWeight: "500",
      marginBottom: "6px",
      fontSize: "14px",
    },

    "& .infor-company": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "8px",
      flexWrap: "wrap",
    },
  },

  box_file: {
    background: "white",
    padding: "12px",
    border: "1px solid #ECECEC",

    "& .title": {
      color: "#4B4B4B",
      fontWeight: "700",
      fontSize: "17px",
      marginBottom: "18px",
    },

    "& .label": {
      color: "#4B4B4B",
      fontWeight: "500",
      marginBottom: "6px",
      fontSize: "14px",
    },

    "& .infor-company": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "8px",
    },

    "& .line-file-label": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "12px",
      fontSize: "14px",
    },

    "& .label-attached-file": {
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      cursor: "pointer",
    },

    "& .color-file": {
      color: "#0B7CE4",
      cursor: "pointer",
    },

    "& .attached-file": {
      display: "flex",
      justifyContent: "space-between",
      color: "#0B7CE4",
      borderBottom: "1px solid #ECECEC",
      paddingBottom: "8px",
      marginBottom: "12px",
    },
  },

  //
}));
