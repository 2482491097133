import moment from "moment";

export default function TableExportExcelReport(props) {
  const { data, dataSearch, roleType } = props;

  const fromDate = !dataSearch?.from
    ? "..............."
    : moment(dataSearch?.from).format("DD/MM/YYYY");
  const toDate = !dataSearch?.to
    ? "..............."
    : moment(dataSearch?.to).format("DD/MM/YYYY");

  const getRoleType = (role) => {
    switch (role) {
      case "globalcare":
        return "Global Care";
      case "provider":
        return "Nhà bảo hiểm";
      case "merchant":
        return "Merchant";

      default:
        break;
    }
  };
  const getCompensation = (item) => {
    return item?.elements?.find((i) => i.type_id === 319) || {};
  };

  return (
    <>
      <div style={{ display: "none" }}>
        <table
          className="collapse-border"
          id={"export-data-table"}
          data-cols-width="7, 15, 25, 17, 30, 20, 35, 30, 30, 35, 35, 20, 20, 20, 17, 15, 30, 30, 15, 17, 20, 35, 30, 20, 25, 30"
        >
          <thead style={{ fontSize: "13" }}>
            <tr />
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"16"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                BÁO CÁO CHI TIẾT HỒ SƠ BỒI THƯỜNG - BẢO HIỂM AHARIDE
              </th>
            </tr>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >{`Từ ngày: ${fromDate} Đến Ngày: ${toDate} `}</th>
            </tr>
            <tr />
            <tr>
              <th
                colSpan={3}
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-wrap={true}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                {`Bên xuất báo cáo: ${getRoleType(roleType)}`}
              </th>
            </tr>
            <tr />
            <tr />
            <tr style={{ fontSize: "13" }}>
              <Th>STT</Th>
              <Th>Ngày tạo HSBT</Th>
              <Th>Mã hồ sơ bồi thường</Th>
              <Th>Kênh khai thác</Th>
              <Th>Họ và tên người được bảo hiểm</Th>
              <Th>SĐT NĐBH</Th>
              <Th>Địa chỉ</Th>
              <Th>Mã đơn hàng vận chuyển</Th>
              <Th>Mã chuyến đi</Th>
              <Th>Điểm đi</Th>
              <Th>Điểm đến</Th>
              <Th>Loại xe</Th>
              <Th>Loại tai nạn</Th>
              <Th>Mức độ tai nạn</Th>
              <Th>Loại hình điều trị</Th>
              <Th>Ngày tai nạn</Th>
              <Th>Nơi xảy ra tai nạn</Th>
              <Th>Nơi điều trị</Th>
              <Th>Tổng chi phí điều trị</Th>
              <Th>Trạng thái bồi Thường</Th>
              <Th>Số tiền phê duyệt</Th>
              <Th>Nội dung</Th>
              <Th>Họ và tên người thụ hưởng</Th>
              <Th>Ngân hàng người thụ hưởng</Th>
              <Th>Số tài khoản người thụ hưởng</Th>
              <Th>Chi nhánh ngân hàng</Th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13" }}>
            {props.data.map((item, index) => {
              return (
                <tr key={index} style={{ fontSize: "13" }}>
                  <th
                    data-f-name={"Times new roman"}
                    data-f-sz={"12"}
                    data-a-h={"center"}
                    data-b-a-s={"thin"}
                    data-a-wrap={true}
                  >
                    {index + 1}
                  </th>
                  <Td>
                    {item?.createdDate
                      ? moment(item?.createdDate).format("DD/MM/YYYY")
                      : ""}
                  </Td>
                  <Td>{item?.code || ""}</Td>
                  <Td>{item?.merchantTitle || ""}</Td>
                  <Td>
                    {item?.insuredInfo?.insuredName || item?.buyerName || ""}
                  </Td>
                  <Td>
                    {item?.insuredInfo?.insuredPhone || item?.buyerPhone || ""}
                  </Td>
                  <Td>
                    {item?.insuredInfo?.insuredAddress ||
                      item?.buyerAddress ||
                      ""}
                  </Td>
                  <Td>{item?.extraInfo?.partnerOrderCode || ""}</Td>
                  <Td>
                    {item?.extraInfo?.codeDestination ||
                      item?.extraInfo?.destination?.codeDestination ||
                      ""}
                  </Td>
                  <Td>
                    {item?.extraInfo?.departure ||
                      item?.insuredInfo?.departure ||
                      ""}
                  </Td>
                  <Td>
                    {item?.extraInfo?.destination?.address ||
                      item?.extraInfo?.address ||
                      ""}
                  </Td>
                  <Td>{`${
                    (item?.extraInfo?.driver?.vehicleType ||
                      item?.insuredInfo?.driver?.vehicleType) === "XE_MAY_DIEN"
                      ? "Xe máy điện"
                      : item?.extraInfo?.driver?.vehicleType ||
                        item?.insuredInfo?.driver?.vehicleType ||
                        ""
                  }`}</Td>
                  <Td>
                    {item?.extraInfo?.typeOfAccident === 1
                      ? "Tai nạn giao thông"
                      : ""}
                  </Td>
                  <Td>
                    {item?.extraInfo?.levelAccident === 1
                      ? "Tử vong"
                      : item?.extraInfo?.levelAccident === 2
                      ? "Thương tật vĩnh viễn"
                      : item?.extraInfo?.levelAccident === 3
                      ? "Thương tật tạm thời"
                      : ""}
                  </Td>
                  <Td>
                    {item?.extraInfo?.typeOfAccident === 1
                      ? "Ngoại trú"
                      : "Nội trú"}
                  </Td>
                  <Td>{item?.extraInfo?.dateOfAccident || ""}</Td>
                  <Td>{item?.extraInfo?.positionAccident || ""}</Td>
                  <Td>{item?.extraInfo?.treatmentPlace || ""}</Td>
                  <Td>
                    {item?.extraInfo?.totalAmountTreatment
                      ? `${item?.extraInfo?.totalAmountTreatment}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " VNĐ"
                      : ""}
                  </Td>
                  <Td>
                    {item.statusId === 2
                      ? "Đang xử lý"
                      : item.statusId === 3
                      ? "Đang đợi phê duyệt"
                      : item.statusId === 4
                      ? "Bổ sung hồ sơ"
                      : item.statusId === 5
                      ? "Phê duyệt"
                      : item.statusId === 6
                      ? "Hoàn tất"
                      : item.statusId === 7
                      ? "Từ chối"
                      : item.statusId === 8
                      ? "Khách hàng từ chối"
                      : ""}
                  </Td>
                  <Td>
                    {getCompensation(item)?.value
                      ? `${getCompensation(item)?.value}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " vnđ"
                      : ""}
                  </Td>
                  <Td>{getCompensation(item)?.text || ""}</Td>
                  <Td>
                    {item?.extraInfo?.beneficiaryName ||
                      item?.insuredInfo?.beneficiaryName ||
                      ""}
                  </Td>
                  <Td>
                    {item?.extraInfo?.beneficiaryBankTitle ||
                      item?.insuredInfo?.beneficiaryBankTitle ||
                      ""}
                  </Td>
                  <Td>
                    {item?.extraInfo?.beneficiaryBankAcountNumber ||
                      item?.insuredInfo?.beneficiaryBankAcountNumber ||
                      ""}
                  </Td>
                  <Td>
                    {item?.extraInfo?.beneficiaryBankBranchName ||
                      item?.insuredInfo?.beneficiaryBankBranchName ||
                      ""}
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const Th = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-h={"center"}
      data-a-wrap={true}
      data-f-bold={true}
    >
      {props.children}
    </th>
  );
};

const Td = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-wrap={true}
    >
      {props.children}
    </th>
  );
};
