import { Box, Typography } from "@mui/material";

import iconGC from "../../../../assets/images/logo_gc.png";
import imageLoginSideBar from "../../../../assets/images/image-login.png";
import Logo_VNI from "../../../../assets/images/Logo_VNI.svg";

import { useRecoilState } from "recoil";
import { embededFiNaRC } from "../atomAccount";

const Title = () => {
  return (
    <Typography
      color="textPrimary"
      variant="h5"
      sx={{ color: "#024281", marginTop: 2 }}
    >
      CÔNG TY CỔ PHẦN TƯ VẤN GLOBAL CARE
    </Typography>
  );
};

const TitleVNI = () => {
  return (
    <Typography
      color="textPrimary"
      variant="h5"
      sx={{ color: "#024281", marginTop: 2 }}
    >
      TỔNG CÔNG TY CỔ PHẦN BẢO HIỂM HÀNG KHÔNG
    </Typography>
  );
};

const LogoGC = () => {
  return (
    <img
      alt="GLOBAL CARE"
      src={iconGC}
      style={{
        width: 120,
        marginBottom: "10px",
      }}
    />
  );
};
const LogoVNI = () => {
  return (
    <img
      alt="GLOBAL CARE"
      src={Logo_VNI}
      style={{
        width: 140,
        marginBottom: "10px",
      }}
    />
  );
};

const ImageSideBar = () => {
  return (
    <img
      alt="Admin Login"
      src={imageLoginSideBar}
      style={{
        width: "70%",
      }}
    />
  );
};

export const LoginSidebar = () => {
  const [embededFiNa, setEmbededFiNa] = useRecoilState(embededFiNaRC);
  return (
    <>
      <Box component="main" m={3} borderRadius={2} sx={mainProps}>
        <Box textAlign="center">
          {embededFiNa == "fina.claim.admin" ? <LogoVNI /> : <LogoGC />}
          {embededFiNa == "fina.claim.admin" ? <TitleVNI /> : <Title />}
        </Box>
        <ImageSideBar />
      </Box>
    </>
  );
};

const mainProps = {
  alignItems: "center",
  justifyContent: "space-around",
  display: "flex",
  flexFlow: "column",
  flexGrow: 1,
  minHeight: "calc(100vh - 3rem)",
  background: "#fff",
};
