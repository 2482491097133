export const APP_PATH = "/claim";
export const ADMIN_PATH = "/admin";
// export const ADMIN_V2_PATH = "/adminv2";

//ADMIN VERSION 2
export const ADMIN_V2_LOGIN = "/admin/login";
export const ADMIN_V2 = "/admin/home";
export const ADMIN_V2_ACCOUNT = "/admin/account";
export const ADMIN_V2_CHANGE_PASSWORD = "/admin/change-password";
export const AD_V2_ACCOUNT_MANAGE = "/admin/account/manage";
export const AD_V2_ACCOUNT_MANAGE_CREATE = "/admin/account-manage/create";
// export const AD_V2_ACCOUNT_MANAGE_UPDATE = "/admin/account-manage/update/:id";
export const RECODRS_INDEMNIFY = "/admin/health/indemnify";
export const RECODRS_INDEMNIFY_DETAIL = "/admin/health/indemnify/detail/:id";
export const AD_V2_BUSINESS_REPORT = "/admin/business-report";
export const AD_V2_DASHBOARD = "/admin/dashboard";
export const AD_V2_BUSINESS_REPORT_DETAIL = "/admin/business-report/detail/:id";
export const AD_V2_CLAIM_REPORT = "/admin/claim-report";
export const AD_V2_CLAIM_REPORT_DETAIL = "/admin/claim-report/details/:id";
export const AD_V2_CLAIM_REVIEW = "/admin/claim-review";
export const AD_V2_CLAIM_REVIEW_DETAIL = "/admin/claim-review/details/:id";
export const AD_V2_REPORT = "/admin/report";
// export const AD_V2_CLAIM_REVIEW_UPDATE = "/admin/claim-review/update/:id";
export const AD_V2_CLAIM_APPROVE = "/admin/claim-approve";
export const AD_V2_CLAIM_APPROVE_DETAIL = "/admin/claim-approve/details/:id";
export const AD_V2_HEALTH_DASHBOARD = "/admin/health/dashboard";
export const AD_BENEFIT_CODE = "/admin/benefit-code";
export const AD_PRODUCT = "/admin/product";
export const AD_PRODUCT_DETAIL = "/admin/product/detail";
export const AD_PROVIDER = "/admin/provider";
export const AD_PROVIDER_DETAIL = "/admin/provider/detail";
export const AD_PRODUCT_PACKAGE = "/admin/provider/package";
export const AD_MERCHANT = "/admin/merchant";
export const AD_MERCHANT_DETAIL = "/admin/merchant/detail";
export const AD_MERCHANT_PACKAGE = "/admin/merchant/package";
export const AD_MERCHANT_CONTRACT = "/admin/merchant/contract";
export const AD_V2_DECENTRALIZA = "/admin/role-management";
export const AD_V2_ACCOUNT_v2 = "/admin/account-management";
export const AD_V2_ACCOUNT_DETAIL_v2 = "/admin/account-management/detail/:id";
export const AD_V2_PRODUCT_v2 = "/admin/product-management";
export const AD_V2_CREATE_PRODUCT_v2 = "/admin/product-management/create/:id";
export const AD_V2_DETAIL_PRODUCT_v2 = "/admin/product-management/detail/:id";
export const AD_V2_DETAIL_UPDATE_PRODUCT_v2 =
  "/admin/product-management/detail-update/:id";
export const AD_V2_GCN_MANAGER = "/admin/gcn";
export const AD_V2_GCN_CREATE = "/admin/gcn/create";
export const AD_V2_DETAIL_GCN = "/admin/gcn/detail";
export const AD_V2_MANAGER_BLVP = "/admin/blvp";
export const AD_V2_CREATE_REQUEST_BLVP = "/admin/blvp/craete-request";
export const AD_V2_DETAIL_CREATE_BLVP = "/admin/blvp/craete-request/detail";
export const AD_V2_DETAIL_BLVP = "/admin/blvp/detail/:id";
export const AD_V2_BENEFIT_V2 = "/admin/benefit-management";

export const AD_V2_DOWLOAD_DOCX = "/admin/tai-hsbt";

//health

export const HEALTH_REPORT = "/admin/health/report";

//ADMIN VERSION 2

//CLAIM
export const LIST_CLAIM_PATH = "/claim/list";
export const YOUR_CLAIM_LIST_PATH = "/claim/your-claim-list";

// claim car accident path
export const INSU_INFO_CLAIM_ACCIDENT_PATH = "/claim/car-accident/info";
export const CREATE_CLAIM_ACCIDENT_PATH = "/claim/car-accident/create";
export const SUCCESS_CLAIM_ACCIDENT_PATH = "/claim/car-accident/success";
export const SELECT_GARA_PATH = "/claim/car-accident/select-gara";
export const SELECT_GARA_OTHER_PATH = "/claim/car-accident/select-gara-other";
export const SUMMARY_CLAIM_ACCIDENT_PATH = "/claim/car-accident/summary";
export const LIST_CLAIM_CAR_PATH = "/claim/car-accident/list";
export const DETAILS_CLAIM_CAR_PATH = "/claim/car-accident/details/:id";
export const DETAILS_CLAIM_CAR_BH365 = "/claim/car-accident/details-bh365/:id";

// claim Tomato insurance path
export const CLAIM_TOMATO = "/claim/tomato";
export const CREATE_REQUEST_CLAIM_TOMATO = "/claim/tomato/create";
export const LIST_CLAIM_TOMATO = "/claim/tomato/create/list-claim";
export const CREATE_CLAIM_FILE_TOMATO = "/claim/tomato/create/claim-file";
export const CREATE_DETAIL_CLAIM_FILE_TOMATO =
  "/claim/tomato/create/claim-file-detail";
export const SHOW_DETAIL_CLAIM_TOMATO = "/claim/tomato/claim-file-detail/show";
export const INSU_TOMATO_CLAIM_LIST = "/claim/tomato/list";
export const INSU_TOMATO_CLAIM_DETAIL = "/claim/tomato/detail/:id";
export const INSU_TOMATO_CLAIM_UPDATE = "/claim/tomato/update/:id";
export const CLAIM_BENEFICIARY_LIST = "/claim/tomato/beneficiaryList";

// claim PHUC BINH AN
export const CLAIM_PHUCBINHAN = "/claim/phucbinhan";
export const DETAIL_PHUCBINHAN = "/claim/pba/detail";
export const PHUCBINHAN_BUSINESS_REPORT = "/admin/pba-business-report";
export const PHUCBINHAN_REVIEW_CLAIM = "/admin/pba-review-claim";
export const DETAIL_REVIEW_PBA = "/admin/pba-detail-review";
export const DETAIL_REPORT_PBA = "/admin/pba-detail-business";

//  admin
export const ADMIN_LOGIN = "/admin/login";
export const ADMIN_FORGOT_PASSWORD = "/admin/forgot-password";
export const ADMIN_CHANGE_PASSWORD = "/admin/change-password";
export const ADMIN_CHANGE_PASSWORD_OTP = "/admin/change-password-otp";
export const ADMIN = "/admin/home";
export const ADMIN_ACCOUNT = "/admin/account";
export const ADMIN_PRODUCT_MANAGE = "/admin/product/manage";
export const ADMIN_COMPANY_MANAGE = "/admin/company/manage";
export const ADMIN_MERCHANT_MANAGE = "/admin/merchant/manage";
export const ADMIN_ACCOUNT_MANAGE_CREATE = "/admin/account/manage/create";
export const ADMIN_ACCOUNT_MANAGE_UPDATE = "/admin/account/manage/update/:id";
export const ADMIN_DASHBOARD = "/admin/dashboard";

export const ADMIN_ACCOUNT_MANAGE = "/admin/account/manage";
export const ADMIN_ACCOUNT_CREATE = "/admin/accoun-manage/create";
// export const ADMIN_ACCOUNT_UPDATE = "/admin/account-manage/update/:id";

// export const ADMIN_CONTRACT = '/admin/contract';
// export const ADMIN_CONTRACT_DETAIL = '/admin/contract/details/:id';
// export const ADMIN_CONTRACT_UPDATE = '/admin/contract/update/:id';
// export const ADMIN_BUSINESS_REPORT = '/admin/business-report';
// export const ADMIN_BUSINESS_REPORT_DETAIL = '/admin/business-report/detail';
// export const ADMIN_BUSINESS_REPORT_UPDATE = '/admin/business-report/update/:id';
// export const ADMIN_CLAIM_REPORT = '/admin/claim-report';
// export const ADMIN_CLAIM_REPORT_DETAIL = '/admin/claim-report/details/:id';
// export const ADMIN_CLAIM_REPORT_UPDATE = '/admin/claim-report/update';
// export const ADMIN_CLAIM_APPROVE = '/admin/claim-approve';
// export const ADMIN_CLAIM_APPROVE_DETAIL = '/admin/claim-approve/details/:id';
// export const ADMIN_CLAIM_APPROVE_UPDATE = '/admin/claim-approve/update/:id';

// Bao Lanh Vien Phi
export const BLVP_RECORDS_DETAIL = "/admin/records-detail";
export const BLVP_RECORDS_DETAIL2 = "/admin/records-detail2";
export const BLVP_RECORDS_MANAGE = "/admin/records-manage";
export const BLVP_RECORDS_MANAGE_DETAIL = "/admin/records-manage/detail";
export const BLVP_RECORDS_CREATE = "/admin/records-manage/create";
export const BLVP_RECORDS_CREATE_DETAIL = "/admin/records-manage/create/detail";
export const BLVP_REPORT = "/admin/report";
export const BLVP_REPORT_DETAIL = "/admin/report/detail";
export const BLVP_DASHBOARD = "/admin/guarantee_dashboard";

//BH-VCOTO

export const ADMIN_CONTRACT = "/admin/contract";
export const ADMIN_CONTRACT_DETAIL = "/admin/contract/details/:id";
export const ADMIN_CONTRACT_UPDATE = "/admin/contract/update/:id";
export const ADMIN_BUSINESS_REPORT = "/admin/business-report";
export const ADMIN_BUSINESS_REPORT_DETAIL = "/admin/business-report/detail/:id";
export const ADMIN_BUSINESS_REPORT_UPDATE = "/admin/business-report/update/:id";
export const ADMIN_CLAIM_REPORT = "/admin/claim-report";
export const ADMIN_CLAIM_REPORT_DETAIL = "/admin/claim-report/details/:id";
export const ADMIN_CLAIM_REPORT_UPDATE = "/admin/claim-report/update";
export const ADMIN_CLAIM_APPROVE = "/admin/claim-approve";
export const ADMIN_CLAIM_APPROVE_DETAIL = "/admin/claim-approve/details/:id";
export const ADMIN_CLAIM_APPROVE_UPDATE = "/admin/claim-approve/update/:id";

export const ADMIN_CLAIM_CHECK = "/admin/claim-check";

//Bồi thường viên
export const PROVIDER = "/admin/provider/home";
export const PROVIDER_RECOUP = "/admin/provider/recoup";
export const PROVIDER_DOCUMENT = "/admin/provider/document";
export const PROVIDER_BUSINESS = "/admin/provider/business";
export const PROVIDER_CREATE_ACCOUNT = "/admin/provider/account/create";
export const PROVIDER_ACCOUNT = "/admin/provider/account/manage";
export const PROVIDER_PROFILE = "/admin/provider/profile";

// AHAMOVE
export const CLAIM_AHAMOVE = "/claim/ahamove/intransit";
export const COMPENSATION_REPORTR_INTRANSIT = "/admin/int-compensation-report";
export const REVIEW_CLAIM_INTRANSIT = "/admin/int-review-claim";
export const DETAIL_REVIEW_INTRANSIT = "/admin/int-detail-review";
export const DETAIL_REPORT_INTRANSIT = "/admin/int-detail-report";
export const DETAIL_INTRANSIT_BH365 = "/claim/intransit/detail365";

export const CLAIM_FINA = "/claim/fina/intransit";
export const DETAIL_FINA_BH365 = "/claim/fina/intransit/detail365";

// HEALTH INSURANCE
export const HEALTH_CLAIM = "/claim/health";
export const HISTORY_HEALTH_CLAIM = "/claim/history/health";

// CLAIM AHARIDE
export const CLAIM_AHARIDE = "/claim/ahamove/aharide";
export const AHARIDE_MANAGEMENT = "/admin/aharide/management";
export const AHARIDE_MANAGEMENT_DETAIL = "/admin/aharide/management/detail";
export const AHARIDE_REPORT = "/admin/aharide/report";
export const AHARIDE_REPORT_DETAIL = "/admin/aharide/report/detail";

//embeded
export const COMPENSATION_REPORTR_INTRANSIT_EMBEDED =
  "/admin/embeded/int-compensation-report";
export const DETAIL_REPORT_INTRANSIT_EMBEDED =
  "/admin/embeded/int-detail-report";

// LOYALTY
export const CLAIM_LOYALTY = "/claim/ahamove/loyalty";
export const LOYALTY_MANAGEMENT = "/admin/loyalty/management";
export const LOYALTY_REPORT = "/admin/loyalty/report";
