import React, { useEffect } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";

import useRerducer from "../../../../../library/hooks/useReducer";
import Snackbars from "../../../../../ui/snackbar";
import { getParamUrlByKey } from "../../../../../utils/utils";
import { HeaderBar, BottomButton } from "../components";
import { initialState } from "../utils";
import healthReducer from "../reducers/healthInsuranceReducer";

import GetInfor from "./getInfor";
import ChooseInsurance from "./chooseInsurance";
import License from "./license";
import Infor from "./infor";
import ReviewInfo from "./reviewInfo";
import ShowLicense from "./showLicense";
import ShowLink from "./showLink";
import { useLocation } from "react-router-dom";
import ReportedAccident from "./reportedAccident";
import CCCD from "./CCCD";
import Camera from "../components/camera";

const queryString = require("query-string");

export default function HealthInsurance() {
  const isApp = getParamUrlByKey("platform") === "app";
  const phone = getParamUrlByKey("phone") || null;
  const partner = getParamUrlByKey("partner") || null;
  const declarationApp = getParamUrlByKey("declarationApp") || null;

  const location = useLocation();
  const [state, dispatch] = useRerducer({
    reducer: healthReducer,
    name: "healthReducer",
    initialState,
    phone,
    partner,
    isApp,
    declarationApp,
  });

  const {
    curenPage,
    disableNextButton,
    loading,
    snackbar,
    headerTitle,
    titleButtonBT,
  } = state;

  useEffect(() => {
    let q = queryString.parse(location?.search);
    !!q.token && localStorage.setItem("token", q.token);
    dispatch("initial");
    localStorage.removeItem("data-infor");
  }, []);

  const renderPage = () => {
    switch (curenPage) {
      case "get-infor":
        return <GetInfor {...{ state, dispatch, isApp }} />;
      case "choose-insurance":
        return <ChooseInsurance {...{ state, dispatch, isApp }} />;
      case "reported-accident":
        return <ReportedAccident {...{ state, dispatch, isApp }} />;
      case "cccd":
        return <CCCD {...{ state, dispatch, isApp }} />;
      case "license":
        return <License {...{ state, dispatch, isApp }} />;
      case "infor":
        return <Infor {...{ state, dispatch, isApp }} />;
      case "review-infor":
        return <ReviewInfo {...{ state, dispatch, isApp }} />;
      case "show-license":
        return <ShowLicense {...{ state, dispatch, isApp }} />;
      case "show-link":
        return <ShowLink {...{ state, dispatch, isApp }} />;
      case "camera":
        return <Camera {...{ state, dispatch, isApp }} />;

      default:
        break;
    }
  };

  return (
    <div style={{ maxWidth: "600px", margin: "auto" }}>
      <HeaderBar
        title={headerTitle || ""}
        onPress={() =>
          dispatch({
            type: "submitPress",
          })
        }
        isApp={isApp}
      />
      <div>{renderPage()}</div>
      {!["show-license", "show-link", "camera"].includes(curenPage) && (
        <BottomButton
          {...{
            title: titleButtonBT,
            disabled: disableNextButton,
            onClick: () =>
              dispatch({
                type: "submitNext",
              }),
          }}
        />
      )}
      <Backdrop
        style={{
          zIndex: 9999,
          color: "#fff",
          // position: "absolute",
          borderRadius: "5px",
        }}
        open={!!loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbars
        open={snackbar.open}
        type={snackbar.type}
        onClose={() => dispatch("closeSnackbar")}
        message={snackbar.message}
      />
    </div>
  );
}
