import moment from "moment";
import React from "react";
import Logo from "../../../../../assets/images/LOGO-GC.png";
import Certificate from "../components/DialogCertificate";
import TripBox from "../components/TripBox";
import { useStyles } from "../styles/infoStyles";
import BottomButton from "../components/BottomButton";
import Wait from "../components/DialogWait";

function ChooseDestination({ state, dispatch, isApp }) {
  const { claimInfo, destination, openCertificate, openDialogNoCerLink } =
    state;
  const classes = useStyles();

  return (
    <div>
      <div
        className={classes.history}
        onClick={() => dispatch("goHistoryOrder")}
      >
        Lịch sử khiếu nại
      </div>
      <div className={classes.center}>
        <img src={Logo} alt="logo" width={90} />
        <div className="line1">Cổng hỗ trợ khách hàng từ Global Care</div>
        <div className="line2">Mã đơn hàng: {claimInfo?.orderCode}</div>
        <div>
          Hoàn thành dự kiến vào{" "}
          {moment(claimInfo?.endDate).format("DD/MM/YYYY HH:mm")}
        </div>
        <div className="line3" onClick={() => dispatch("openLink")}>
          Xem giấy chứng nhận bảo hiểm
        </div>
      </div>

      <div className={classes.spacing}></div>

      <div className={classes.boxOrder}>
        <div className="header">Chọn điểm giao cần khiếu nại</div>
        <TripBox
          isSelect={destination?.index === 0 ? 0 : destination?.index || null}
          destination={claimInfo?.destination || []}
          onClick={(data) =>
            dispatch({
              type: "onClickDestination",
              data,
              name: "destination",
            })
          }
        />
      </div>

      <Certificate
        open={openCertificate}
        link={claimInfo?.certLink}
        onClose={() => dispatch("closeCertificate")}
      />

      <Wait
        open={Boolean(openDialogNoCerLink)}
        onClose={() => dispatch("closeDialogNoCerLink")}
      />
    </div>
  );
}

export default ChooseDestination;
