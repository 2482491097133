import React, { useEffect } from "react";
import moment from "moment";

// import Logo from "../../../../../assets/images/LOGO-GC.png";
import Logo from "../assets/fina_logo_v2.png";
import { styledStep1 } from "../components/step/styledStep1";
import TripBox from "../components/step/TripBox";
import Wait from "../components/step/DialogWait";
import Certificate from "../components/step/DialogCertificate";

export default function Step1(props) {
  const { state, dispatch, isApp, codeOrder } = props;
  const classes = styledStep1();
  const { inforOrder, destination, openDialogWait, openCertificate } = state;

  const date = moment(inforOrder?.endDate).format("DD/MM/YYYY, HH:mm");

  useEffect(() => dispatch("initial"), [codeOrder]);

  return (
    <div
      style={{
        minHeight: isApp ? "calc(100vh - 165px)" : "calc(100vh - 125px)",
      }}
    >
      <div className={classes.boxLogo}>
        <div className="history">
          <div
            onClick={() =>
              dispatch({
                type: "changePageHistory",
                page: "history",
              })
            }
          >
            Lịch sử khiếu nại
          </div>
        </div>
        <div className="logo">
          <img src={Logo} alt="logo" width={150} height={48} />
        </div>
        <div className="boxInfor">
          <span className="line1">Cổng hỗ trợ khách hàng từ Fina</span>
          {/* <span className="line1">Cổng hỗ trợ khách hàng từ Global Care</span> */}
          <span className="line2">
            Mã đơn hàng: {inforOrder?.partnerOrderCode}
          </span>
          <span className="line3">
            Hoàn thành dự kiến vào {date.replace(/,/g, "")}
          </span>
          <span
            onClick={() => dispatch("openLink")}
            style={{
              color: "#3F76DF",
              fontStyle: "italic",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Xem giấy chứng nhận bảo hiểm
          </span>
        </div>
      </div>
      {inforOrder.completeStatus === 1 && (
        <div className={classes.boxOrder}>
          <div className="headTitle">Chọn điểm giao cần khiếu nại</div>
          <TripBox
            isSelect={destination?.index === 0 ? 0 : destination?.index || null}
            destination={inforOrder?.destination || []}
            onClick={(data) =>
              dispatch({
                type: "onClickDestination",
                data,
                name: "destination",
              })
            }
          />
        </div>
      )}
      <Wait {...{ dispatch, open: !!openDialogWait }} />
      <Certificate
        open={openCertificate}
        link={inforOrder?.certLink}
        onClose={() => dispatch("closeCertificate")}
      />
    </div>
  );
}
