import moment from "moment";

const formatDate = (date) => {
  if (!date) return "";
  const newDate = moment(date).format("DD/MM/YYYY");
  return newDate !== "Invalid date" ? newDate : "";
};

const getParamUrlByKey = (key) => {
  let search = window.location.search;
  let arr = search.split("?");
  let params = new URLSearchParams(arr[1]);
  let platform = params.get(key) || "";
  return platform;
};
const convertCurrency = (money) => {
  money = !money ? "0" : money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return money;
};

const convertNum = (money) => {
  money = !money ? null : money.toString().replace(/\./g, "");
  return money;
};

const hardTimes = () => {
  const arr = [];
  for (let i = 0; i < 366; i++) {
    arr.push(i);
  }
  return arr;
};

const types = [
  {
    insurance_code: "suc_khoe,tai_nan",
    insurance_title: "Bảo hiểm sức khoẻ, tai nạn",
  },
  { insurance_code: "suc_khoe", insurance_title: "Bảo hiểm sức khoẻ" },
  { insurance_code: "tai_nan", insurance_title: "Bảo hiểm tai nạn" },
  { insurance_code: "khac", insurance_title: "Khác" },
];

const renderConfig = [
  { title: "Tai nạn", name: "accident" },
  { title: "Bệnh có sẵn", name: "existing_disease" },
  { title: "Bệnh đặc biệt", name: "special_disease" },
  { title: "Thai sản", name: "pregnancy" },
  { title: "Biến chứng thai sản", name: "pregnancy_complications" },
  { title: "Bệnh TT Nội trú", name: "inpatient_treatment" },
  { title: "Bệnh TT Ngoại trú", name: "outpatient_treatment" },
  { title: "Răng", name: "tooth" },
];

const config_default_value = {
  accident: 0,
  existing_disease: 0,
  special_disease: 0,
  pregnancy: 0,
  pregnancy_complications: 0,
  inpatient_treatment: 0,
  outpatient_treatment: 0,
  tooth: 0,
};

export {
  formatDate,
  getParamUrlByKey,
  convertNum,
  convertCurrency,
  hardTimes,
  types,
  renderConfig,
  config_default_value,
};
