import {
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  TableCell,
  TableContainer,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useRef } from "react";
import useReducer from "../../../library/hooks/useReducer";
import CommonTable from "../../../library/layout/commonTable";
import benefitCodeReducer from "./benefitCodeReducer";
import AddDialog from "./components/AddDialog";
import { useStyles } from "./useStyles";

import AddIcon from "./assets/add-icon.svg";
import SearchIcon from "./assets/search-icon.svg";

const initialState = {
  typeCode: [],
  rows: [],
  errors: [],
  data: {},
  open: false,
  loading: false,
  saving: false,
  isInput: false,
  scrolling: false,
};

const BenefitCode = () => {
  const classes = useStyles();
  const tableRef = useRef();

  const [state, dispatch] = useReducer({
    reducer: benefitCodeReducer,
    name: "benefitCodeReducer",
    initialState,
  });

  useEffect(() => {
    dispatch("getList");
  }, []);

  const { rows, loading, scrolling } = state;

  const onScroll = () => {
    if (tableRef.current) {
      const scrollDiv = tableRef.current;
      const lastItem = scrollDiv.querySelector(
        "#tableScroll .scroll-item:last-child"
      );
      const { offsetTop, offsetHeight } = lastItem;
      if (
        Math.abs(offsetTop - scrollDiv.scrollTop) <
        offsetHeight + scrollDiv.offsetHeight
      ) {
        dispatch("fetchData");
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className="header">Quản lý mã quyền lợi</div>
      <div className="search">
        <div className="wrapper">
          <TextField
            fullWidth
            placeholder="Tìm kiếm mã quyền lợi"
            className="textfield"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="search-ic" />
                </InputAdornment>
              ),
            }}
            onChange={debounce(
              (e) =>
                dispatch({
                  type: "getList",
                  value: e.target.value,
                }),
              800
            )}
          />
          {loading && <CircularProgress size={25} sx={{ color: "#3961A0" }} />}
        </div>
        <Button className="button" onClick={() => dispatch("onOpen")}>
          <img src={AddIcon} alt="add-ic" className="icon" />
          Thêm mã
        </Button>
      </div>
      <TableContainer
        id="tableScroll"
        ref={tableRef}
        onScroll={debounce(onScroll, 800)}
        className="table-scroll"
        component={Paper}
      >
        <div className={classes.headerTable}>
          <div className="code">Mã quyền lợi</div>
          <div>Tên quyền lợi</div>
        </div>
        <CommonTable
          classes={classes}
          rows={rows}
          columns={columns}
          empty={"Không có thông tin. Vui lòng kiểm tra lại"}
        />
        {scrolling && (
          <div className="scrolling">
            <CircularProgress size={25} sx={{ mb: 2, color: "#0ABAFD" }} />
          </div>
        )}
      </TableContainer>
      <AddDialog state={state} dispatch={dispatch} />
    </div>
  );
};

export default BenefitCode;

const columns = [
  {
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst code"
            : item.isSecond
            ? "isSecond code"
            : "code"
        }
      >
        {item?.code_benefit || ""}
      </TableCell>
    ),
  },
  {
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst name-benefits"
            : item.isSecond
            ? "isSecond name-benefits"
            : "name-benefits"
        }
      >
        {item?.title || ""}
      </TableCell>
    ),
  },
];
