import React from "react";
import { Button } from "@mui/material";

export default function NextButton(props) {
  const { onChangNext, title = "Tiếp tục", disabled = false } = props;
  return (
    <Button disabled={disabled} onClick={onChangNext} sx={button}>
      {title}
    </Button>
  );
}

const button = {
  width: "100%",
  backgroundColor: "#0B3C8A",
  color: "white",
  borderRadius: "14px",
  fontSize: "17px",
  marginTop: "30px",
  paddingY: "15px",
  fontWeight: 400,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#0B3C8A",
  },
};
