import { Avatar, Box, Typography } from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { updateProfile_v2 } from "../../../../../apis/authApis";

const AccountProfile = ({ profiles, loadProfiles, setLoading }) => {
  const onClickUploadImage = async (e) => {
    const _files = e.target.files[0];

    const data = new FormData();
    data.append("avatar", _files);
    setLoading(true);
    const response = await updateProfile_v2(data);
    if (response?.status === 200) {
      loadProfiles();
    }
    setLoading(false);
  };

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "left",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box>
          <Avatar
            src={profiles.avatar || "/static/images/avatars/avatar_6.png"}
            sx={{
              height: 115,
              mb: 2,
              width: 115,
            }}
          />
        </Box>
        <Box maxWidth="100%" ml={3}>
          <Typography display="flex" fullWidth mb={1} fontSize={22}>
            {profiles?.fullname}
          </Typography>
          <Typography
            component="label"
            fullWidth
            color="primary"
            sx={{ display: "flex" }}
          >
            <input
              type="file"
              hidden
              onClick={(e) => (e.target.value = "")}
              onChange={onClickUploadImage}
              accept="image/*"
              capture="camera"
            />
            <CameraAltOutlinedIcon /> &nbsp; Thay đổi ảnh đại diện
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default AccountProfile;
