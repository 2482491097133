import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { ThemeProvider, createTheme, Button, IconButton } from "@mui/material";

import { styleDocumentDetail } from "../../styles/styleDocumentDetail";

// import editContained from "../../../../../assets/health/edit-contained.svg";
import { listTypeOfRisk } from "../../util";
import RequestAdditionalDocuments from "./requestAdditionalDocuments";
import AccordionsDetailDocument from "./accordionDetailDocument";
import BoxImgDocument from "./boxImgDocument";

export default function Documents(props) {
  const { state, dispatch } = props;
  const classes = styleDocumentDetail();
  const { detail, insuranceTypes } = state;

  const insuranceType =
    insuranceTypes.find((i) => i.id == detail?.extraInfo?.typeOfInsurance) ||
    {};

  const typeOfIndimnity =
    (insuranceType?.typeOfIndimnity || []).find(
      (i) => i.id == detail?.extraInfo?.typeOfIndimnity
    ) || {};

  const typeOfRisk =
    detail?.extraInfo?.typeOfInsurance == 1
      ? (typeOfIndimnity?.typeOfRisk || []).find(
          (i) => i.id == detail?.extraInfo?.typeOfRisk
        )
      : listTypeOfRisk.find((i) => i.id == detail?.extraInfo?.typeOfTreatment);

  const dataRender = [
    {
      headLabel: "Loại bảo hiểm",
      value: insuranceType?.title || "-",
      minWidth: 130,
    },
    {
      headLabel: "Loại bồi thường",
      value: typeOfIndimnity?.title || "-",
      minWidth: 140,
    },
    {
      headLabel: "Loại rủi ro",
      value: typeOfRisk?.title || "--",
      minWidth: 100,
    },
    {
      headLabel: "Số tiền yêu cầu bồi thường",
      value: (detail?.requestAmount || "-")
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      minWidth: 220,
    },
  ];

  useEffect(() => {
    dispatch("initialLincense");
  }, []);

  return (
    <div>
      <div className={classes.box_table}>
        <div className={classes.title} style={{ padding: "6px 0" }}>
          <span className="span-title">Thông tin yêu cầu bồi thường</span>
          <div>
            {/* <IconButton
            // onClick={() => dispatch("setOpenDialogClaimInfo")}
            >
              <img src={editContained} />
            </IconButton> */}
          </div>
        </div>
        <div style={{ width: "100%", overflow: "auto" }}>
          <div className={classes.table_info_insuran}>
            {dataRender.map((i, index) => (
              <div
                key={`key_div_render_${index}`}
                className="div-column"
                style={{ minWidth: i.minWidth ? i.minWidth : "" }}
              >
                <div className="head-table">{i.headLabel}</div>
                <div className="value-table">{i.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={classes.container}>
        <ThemeProvider
          theme={createTheme({
            breakpoints: {
              values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                xxl: 2250,
              },
            },
          })}
        >
          <Grid container>
            <Grid item xs={5} sm={5} md={4} xl={4} xxl={3}>
              <AccordionsDetailDocument {...{ state, dispatch }} />
            </Grid>
            <Grid item xs={7} sm={7} md={8} xl={8} xxl={9}>
              <BoxImgDocument {...{ state, dispatch }} />
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
      <div className={classes.additionalRecords}>
        {/* <div className="title-additional-ecords">Yêu cầu bổ sung hồ sơ</div>
        <RequestAdditionalDocuments {...{ state, dispatch }} /> */}
        <div className={classes.buttons}>
          <Button
            className={detail?.statusId === 4 ? "buttons" : "buttons disabled"}
            disabled={detail?.statusId !== 4}
            onClick={() =>
              dispatch({
                type: "submitAdditionalRequest",
              })
            }
          >
            Cập nhật chứng từ
          </Button>
        </div>
      </div>
      {/* <DialogClaimInfor {...{ state, dispatch }} /> */}
    </div>
  );
}
