import { formatFilterDate } from "./utils";

export default function insuranceReducer({ action, state, dispatch }) {
  const cases = {
    resetFilter: () => ({ reportData: { showDate: 0 }, filter: {} }),
    onLoading: () => ({ loading: true }),
    nextPage: () => {
      const { activePage } = state;
      const { item } = action;
      switch (activePage) {
        case 0:
          return { activePage: activePage + 1, propsList: item };
        case 1:
          return { activePage: activePage + 1, insuranceData: item };
        case 2:
          return { activePage: activePage + 1 };
        default:
          break;
      }
    },
    onBack: () => {
      const { activePage } = state;
      return { activePage: activePage - 1 };
    },
    getList: () => {
      const { data } = action;
      return { beneficiaryList: data, loading: false };
    },
    setOrderList: () => {
      const { data } = action;
      return { orderList: data, loading: false };
    },
    onSelect: () => {
      const { reportData, filter } = state;
      const { name, value } = action;
      const res = {};
      reportData[name] = value == 0 ? 0 : +value;
      if (name === "type") {
        res.filter = { ...filter, type: value == 0 ? null : value };
      }

      if (name === "showDate" && value == 0) {
        reportData.fromDate = null;
        reportData.toDate = null;
        res.filter = { ...filter, fromDate: null, toDate: null };
      }
      return { reportData, ...res };
    },
    changeDate: () => {
      const { reportData, filter } = state;
      const { name, value } = action;
      const res = {};
      reportData[name] = value;
      res.filter = { ...filter, [name]: formatFilterDate(name, value) };
      return { reportData, ...res };
    },
    onChange: () => {
      const { value } = action;
      return { email: value };
    },
    setError: () => ({ errEmail: true }),
    sendEmail: () => ({
      openSendMail: false,
      success: true,
      loading: false,
      errEmail: false,
    }),
    closeSuccess: () => ({ success: false, email: "" }),
    openSendMail: () => ({ openSendMail: true }),
    closeSendMail: () => ({ openSendMail: false, errEmail: false, email: "" }),
    openSnackbar: () => {
      const { name } = action;
      switch (name) {
        case "email":
          return {
            snackbar: { open: true, mes: "Gửi mail không thành công" },
            email: "",
          };

        case "downloadFail":
          return {
            snackbar: { open: true, mes: "Tải xuống không thành công" },
          };
        case "downSuccess":
          return {
            snackbar: {
              open: true,
              mes: "Tải xuống thành công!",
              success: true,
            },
          };
      }
    },
    closeSnackbar: () => ({
      snackbar: { open: false, mes: "", success: false },
      loading: false,
    }),
  };
  return cases[action?.type];
}
