import React, { useState } from "react";

import { RightOutlined } from "@ant-design/icons";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import RowData from "./RowData";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "10px",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: "white",
    borderRadius: "10px",
    padding: 0,
    "& .MuiAccordionSummary-expandIconWrapper": {
      color: "white",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0 0 0 8px",
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

export default function OverriderAccordion(props) {
  const { title, isInforContract, children, sum, expense } = props;

  const [open, setOpen] = useState(false);

  const handalChange = () => {
    setOpen(!open);
  };

  return (
    <>
      <Accordion expanded={open} onChange={handalChange}>
        <AccordionSummary>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="stretch"
            spacing={1}
            sx={!open ? boxShadow : {}}
          >
            <Box sx={titleStyle}>
              <Typography sx={{ flexShrink: 0 }}>{title}</Typography>
              <RightOutlined className={!open ? "" : "transform"} />
            </Box>
            {!!isInforContract && (
              <>
                <RowData
                  title="Tổng quyền lợi bảo hiểm"
                  value={
                    (expense
                      ? `${sum || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0) + "VNĐ"
                  }
                />
                <RowData
                  title="Tổng phí bảo hiểm"
                  value={
                    `${expense || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    "VNĐ"
                  }
                />
              </>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  );
}

const titleStyle = {
  display: "flex",
  justifyContent: "space-between",
  height: "45px",
  color: "white",
  backgroundColor: "#0B3C8A",
  borderRadius: "10px",
  alignItems: "center",
  padding: "0 16px",
  marginBottom: "16px",
  "& .anticon-right": {
    display: "flex",
    height: "100%",
    alignItems: "center",
    transition: "0.4s",
  },
  "& .transform": {
    transform: "rotate(90deg)",
    transition: "0.4s",
  },
};

const boxShadow = {
  boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.08)",
  borderRadius: "10px",
};

const inforContract = {
  padding: "0 16px 16px 16px",
};
