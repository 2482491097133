import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import TripBox from "../components/TripBox";
import { useStyles } from "../styles/historyStyles";

export default function HistoryClaim(props) {
  const { state, dispatch, isApp } = props;
  const { historyDestination, historyOrder, tabsStatus, loading } = state;
  const classes = useStyles();

  useEffect(() => dispatch("getHistory"), []);
  const ref2 = useRef();

  const onScroll = () => {
    if (ref2.current) {
      const scrollDiv = ref2.current;
      const lastItem = scrollDiv.querySelector(
        "#divScroll .scroll-item:last-child"
      );
      const { offsetTop, offsetHeight } = lastItem;
      if (
        Math.abs(offsetTop - scrollDiv.scrollTop) <
        offsetHeight + scrollDiv.offsetHeight
      ) {
        dispatch("getItems");
      }
    }
  };

  return (
    <>
      <Box className={classes.boxTabs}>
        <Tabs
          value={tabsStatus}
          onChange={(e, newValue) =>
            dispatch({ type: "getHistory", value: newValue })
          }
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab label="Đang xử lý" value={2} />
          <Tab label="Bổ sung hồ sơ" value={4} />
          <Tab label="Đã duyệt" value={5} />
          <Tab label="Hoàn tất" value={6} />
          <Tab label="Từ chối" value={7} />
        </Tabs>
      </Box>
      <div className={classes.lineSpacing}></div>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress size={24} />
        </div>
      ) : (
        <div
          className={classes.boxTrip}
          id="divScroll"
          ref={ref2}
          style={{
            height: isApp ? "calc(100vh - 240px)" : "calc(100vh - 225px)",
            overflowY: "auto",
          }}
          onScroll={_.debounce(onScroll, 500)}
        >
          <TripBox
            name="history"
            isSelect={
              historyDestination?.index === 0
                ? 0
                : historyDestination?.index || null
            }
            destination={historyOrder || []}
            onClick={(data) =>
              dispatch({
                type: "detailDestinationHistory",
                data,
              })
            }
          />
        </div>
      )}
    </>
  );
}
