import React, { useEffect } from "react";
import _ from "lodash";

import { styledLicense } from "../components/styleds/styeledLicense";
import { CategoryLicense, DialogNoti, DialogShowImg } from "../components";

export default function License(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledLicense();
  const { additionLicesees, detail, dialogNoti } = state;

  useEffect(() => {
    dispatch("getListAddLicese");
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={classes.container}
      style={{
        minHeight: isApp ? "calc(100vh - 173px)" : "calc(100vh - 138px)",
      }}
    >
      <div className={classes.title}>Bổ sung chứng từ</div>
      {additionLicesees.map((item, index, arry) => {
        return (
          <div>
            <CategoryLicense
              key={`box_img_${index}`}
              // error={!!errors[item.typeId]}
              index={index}
              title={item.title}
              text={item.text}
              nonMultiple={!!item?.nonMultiple}
              listImgs={item.documents}
              addImg={(e) => {
                dispatch({ type: "addImg", e, typeId: item.typeId, index });
                e.target.value = null;
              }}
              deleteImg={({ e, indexImg, url }) =>
                dispatch({
                  type: "deleteImg",
                  indexList: indexImg,
                  typeId: item.typeId,
                  index,
                  url,
                })
              }
              showImg={(data) =>
                dispatch({
                  type: "openShowImgDialogInline",
                  data: { index: data.index, itemImg: data?.item || {} },
                  documents: item?.documents || [],
                })
              }
            />
          </div>
        );
      })}
      <DialogNoti {...{ dispatch, data: dialogNoti }} />
      <DialogShowImg {...{ dispatch, state }} />
    </div>
  );
}
