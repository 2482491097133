import { makeStyles } from "@mui/styles";
import { fontWeight } from "@mui/system";

export const styledStep5 = makeStyles(() => ({
  boxContainer: {
    padding: "10px 16px 18px",
    margin: "8px 0",
    background: "white",
  },

  titleHead: {
    fontSize: "16px",
    color: "#284078",
    fontWeight: "500",
    paddingTop: "6px",
  },

  inputDiv: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "10px",
    padding: "12px 8px",
    display: "flex",
    position: "relative",
    marginTop: "30px",

    "& .MuiInputBase-root:before": {
      border: "none !important",
    },

    "& .MuiInputBase-root:after": {
      border: "none !important",
    },

    "& .MuiInputBase-input": {
      boxSizing: "border-box",
    },

    "& .shrink": {
      position: "absolute",
      background: "white",
      padding: "0 6px",
      lineHeight: "18px",
      top: "-11px",
      left: "16px",
      color: "#284078",
      fontWeight: "500",
      fontSize: "15px",
    },

    "&:hover": {
      borderColor: "#284078",
      borderWidth: "2px",
    },
  },

  textAuto: {
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      padding: "1px 65px 1px 0",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    },
  },

  checkbox: {
    margin: "0 !important",

    "& .Mui-checked": {
      color: "#E16C2B",
    },

    "& .MuiCheckbox-root": {
      padding: "0 8px 0 0",
      marginBottom: "22px",
    },
  },

  divErr: {
    fontStyle: "italic",
    color: "red",
    paddingTop: "6px",
  },

  bankImg: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: "#E8E8E8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "8px",
    padding: "10px",
  },

  bankOption: {
    display: "flex",
    flexDirection: "column",

    "& .short-name": {
      fontWeight: "600",
    },
    "& .name": {
      fontSize: "14px",
      color: "#505151",
    },
  },

  signature: {
    width: "100%",
    maxWidth: "600px",
    height: 168,
    border: "1px solid rgba(151, 151, 151, 0.24)",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },

  yourSignature: {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0",

    "& .signature": {
      color: "#0B3C8A",
      fontSize: "17px",
      fontWeight: "600",
    },

    "& .agan": {
      color: "#3F71FF",
      fontSize: "17px",
      fontWeight: "500",
      textDecoration: "underline",
    },
  },

  //
}));
