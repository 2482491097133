import { useEffect } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Drawer,
  useMediaQuery,
  ListItemText,
  ListItemButton,
  Collapse,
  List,
  ListItemIcon,
} from "@mui/material";
import { useRecoilState } from "recoil";

import ItemRender from "./ItemRender";
import ItemRenderChildren from "./ItemRenderChildren";
import iconGC from "../../../../../assets/lauyout/logo-gc.png";
import Logo_VNI from "../../../../../assets/images/Logo_VNI.svg";
import iconArrowRight from "../../../../../assets/lauyout/iconArrowRight.svg";
import iconArrowDown from "../../../../../assets/lauyout/iconArrowDown.svg";
import settingsIcon from "../../../../../assets/lauyout/settings-icon.svg";
import logoutIcon from "../../../../../assets/lauyout/logout-icon.svg";
import * as PATH from "../../../../../configs/routesConfig";
import sidebarReducer from "./sidebarReducer";
import useReducer from "../../../../../library/hooks/useReducer";
import LoadingMui from "../../../../../ui/LoadingMui";

import { listProductClaim } from "../../../stateRecoil/atom";

const initialState = {
  dataMenu: [],
  parentCollapse: { product_parents: true },
  expandSubMenu: {},
  selected: "",
};

export const DashboardSidebar = (props) => {
  const { open, onClose } = props;

  const embededFiNa = localStorage.getItem("embeded") || null;
  const searchParams =
    embededFiNa == "fina.claim.admin" ? "?embeded=fina.claim.admin" : "";
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const systemType = user?.extra_info?.userType || 0;
  const pathname = window.location.pathname;
  // const roleData = user?.role_management_claim || 0;
  const history = useHistory();

  const [listProductClaims, setListProductClaims] =
    useRecoilState(listProductClaim);

  const [state, dispatch] = useReducer({
    initialState,
    reducer: sidebarReducer,
    name: "sidebarReducer",
    setListProductClaims,
    pathname,
    history,
  });

  const { parentCollapse, expandSubMenu, selected, dataMenu, loading } = state;

  useEffect(() => {
    if (pathname.includes(PATH.ADMIN_V2)) {
      dispatch({ type: "setSelected", name: "" });
    } else {
      dispatch({ type: "changePathname", pathname });
    }
  }, [pathname]);

  useEffect(() => {
    dispatch("initial");
  }, []);

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });
  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div>
        <Box sx={{ p: 3, float: "center", textAlign: "center" }}>
          <Link
            to={PATH.ADMIN_V2}
            onClick={() => {
              localStorage.removeItem("roleAdminMenuItem");
            }}
          >
            <img
              src={embededFiNa == "fina.claim.admin" ? Logo_VNI : iconGC}
              height="85"
            />
          </Link>
        </Box>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        {dataMenu
          .sort((a, b) => a.stt - b.stt)
          ?.map((itemMenu, indexMenu) => {
            if (itemMenu?.systemType?.includes(systemType)) {
              return (itemMenu.childrens || [])?.length > 0 ? (
                <>
                  <ListItemButton
                    onClick={() =>
                      dispatch({
                        type: "handleClickParent",
                        name: itemMenu.name,
                      })
                    }
                    // onClick={() => handleClickParent(itemMenu.name)}
                    key={`key_item_listItemButton_${indexMenu}`}
                  >
                    <ListItemIcon>
                      <img src={itemMenu.icon} width={32} />
                    </ListItemIcon>
                    <ListItemText
                      primary={itemMenu.title}
                      sx={{ "& .MuiTypography-root": { fontSize: "15px" } }}
                    />
                    {parentCollapse[itemMenu.name] ? (
                      <img src={iconArrowDown} />
                    ) : (
                      <img src={iconArrowRight} />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={parentCollapse[itemMenu.name]}
                    timeout="auto"
                    unmountOnExit
                    // key={itemMenu.title}
                  >
                    <List component="div" disablePadding>
                      {itemMenu.childrens.map((itemChilder, indexChidren) => {
                        return itemChilder?.childrens?.length > 0 ? (
                          <ItemRenderChildren
                            key={`key_ItemRenderChildren_${indexChidren}`}
                            handleClick={
                              () =>
                                dispatch({
                                  type: "handleClickChild",
                                  product_code: itemChilder.product_code,
                                })
                              // handleClickChild(itemChilder.product_code)
                            }
                            // handleClick={() =>
                            //   handleClickChild(itemChilder.product_code)
                            // }
                            selected={selected}
                            setSelected={(name) =>
                              dispatch({ type: "setSelected", name })
                            }
                            listMenu={itemChilder.childrens}
                            expanded={expandSubMenu[itemChilder.product_code]}
                            title={itemChilder.title}
                            data={{
                              feature_code: itemChilder?.feature_code || null,
                              product_code: itemChilder?.product_code || null,
                              product_id: itemChilder?.product_id || null,
                              product_id_parent:
                                itemChilder?.product_id_parent || null,
                              role_management:
                                itemChilder?.role_management || {},
                            }}
                          />
                        ) : (
                          <ItemRender
                            key={`key_Item_render_children_${indexChidren}`}
                            selected={selected}
                            name={itemChilder.name}
                            setSelected={(name) =>
                              dispatch({ type: "setSelected", name })
                            }
                            path={itemChilder.path}
                            title={itemChilder.title}
                            data={itemChilder}
                          />
                        );
                      })}
                    </List>
                  </Collapse>
                </>
              ) : (
                <ItemRender
                  setSelected={(name) =>
                    dispatch({ type: "setSelected", name })
                  }
                  selected={selected}
                  name={itemMenu.name}
                  key={`item_render_${indexMenu}`}
                  path={itemMenu.path}
                  title={itemMenu.title}
                  icon={itemMenu.icon}
                  data={itemMenu}
                />
              );
            }
          })}
      </Box>
      <div>
        <Box sx={{ py: 3 }}>
          <Link
            to={PATH.ADMIN_V2_CHANGE_PASSWORD}
            style={{ color: "#121828" }}
            key={"setting-account_1"}
            // onClick={() => {}}
          >
            <ListItemButton>
              <ListItemIcon>
                <img alt="icon" src={settingsIcon} />
              </ListItemIcon>
              <ListItemText primary={"Đổi mật khẩu"} />
            </ListItemButton>
          </Link>
          <Link
            to={PATH.ADMIN_V2_LOGIN + searchParams}
            style={{ color: "#121828" }}
            key={"setting-account_2"}
            onClick={() => dispatch("onLogout")}
          >
            <ListItemButton>
              <ListItemIcon>
                <img alt="icon" src={logoutIcon} />
              </ListItemIcon>
              <ListItemText primary={"Đăng xuất"} />
            </ListItemButton>
          </Link>
        </Box>
      </div>
      <LoadingMui loading={loading} />
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            zIndex: 99,
            backgroundColor: "fff",
            width: 270,
            color: "#121828",
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#121828",
          width: 270,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
