import "../styles/index.css";

import {
  Box,
  Container,
  Grid,
  Typography,
  Modal as ModalV2,
} from "@mui/material";

import {
  Button,
  Calendar,
  message,
  Modal,
  Select,
  Radio,
  Row,
  Col,
  Tooltip,
} from "antd";

import {
  AccidentIcon,
  AddressIcon,
  BankIcon,
  BankIdIcon,
  BirthCakeIcon,
  BlueDotIcon,
  BlueNoteIcon,
  CalendarIcon,
  CircleInnerUserIcon,
  DownloadFileIcon,
  EmailIcon,
  FileTextIcon,
  HospitalIcon,
  MobilePhoneIcon,
  PrivateIdIcon,
  RelationshipIcon,
  StatusIcon,
  TotalBenefitIcon,
  TotalInsureFeeIcon,
  UpIcon,
  UserIcon,
} from "../components/icon/icon";

import { DownOutlined, CheckCircleOutlined } from "@ant-design/icons";

import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { formatMoney, removeFormatMoney } from "../utils/money";
import { useRouteMatch } from "react-router-dom";
import {
  addFeedBack,
  getClaimDetail,
  getListHistory,
  getDatailsClaimCarFrom,
} from "../api/claim";
import RenderAccidentType from "../components/views/TypeAccident";
import {
  ACCIDENT_LEVEL,
  ACCIDENT_TYPE,
  STATUS,
  TREATMENT_TYPE,
} from "../utils/type";
import axios from "axios";
import { useForm } from "react-hook-form";
import { titlesList } from "../utils/data";
import Collapse from "../components/collapse";

function TitleModal({
  selectedItem,
  visible,
  setVisible,
  onChange = (item) => {},
}) {
  const [item, setItem] = useState(selectedItem);

  return (
    <Modal
      footer={null}
      visible={visible}
      closable
      width={400}
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div style={{ padding: 15 }}>
        <Typography color="#2A3790" fontSize={20} fontWeight={500}>
          Thay đổi nhân sự phụ trách hồ sơ
        </Typography>

        <div
          style={{
            background: "#F8F9FA",
            minHeight: 150,
            padding: 2,
          }}
        >
          {titlesList.map((title, index) => {
            return (
              <div
                className="effected-hover"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(5, 1fr)",
                }}
                onClick={() => setItem(title.id)}
              >
                <div key={index} style={{ gridColumn: "1/5" }}>
                  {title.title}
                </div>
                <div style={{ padding: 3 }}>
                  <CheckCircleOutlined
                    style={{
                      display: title.id === item ? "block" : "none",
                      color: "#2A3790",
                      fontSize: 15,
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ textAlign: "center", marginTop: 8 }}>
          <Button
            size="large"
            style={{
              borderRadius: 10,
              color: "#FFF",
              background: "#2A3790",
              borderRadius: 20,
              boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
            }}
            onClick={() => {
              onChange(item);
              setVisible(false);
            }}
          >
            Đồng ý
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function RecoupAcceptance({
  data,
  sendFeedback = (type, data) => {},
  afterSend = async () => {},
}) {
  const user = JSON.parse(localStorage.getItem("user"));

  const [visible, setVisible] = useState(false);
  const [visibleResult, setVisibleResult] = useState(false);
  const [visibleAccept, setVisibleAccept] = useState(false);

  const [visibleReject, setVisibleReject] = useState(false);
  const [finalType, setFinalType] = useState(5);
  const [visibleFinalResult, setVisibleFinalResult] = useState(false);
  const {
    register,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const [disabled, setDisabled] = useState(false);

  const moneyWatch = watch("money");
  const titleWatch = watch("title");
  const statusWatch = watch("status");

  useEffect(() => {
    let temp = removeFormatMoney(moneyWatch);
    setValue("money", formatMoney(+temp));
  }, [moneyWatch]);

  useEffect(() => {
    if (!data?.statusId) return;

    setValue("status", +data?.statusId?.toString());
    setDisabled(
      +data?.statusId === 3 ||
        +data?.statusId === 7 ||
        +data?.statusId === 5 ||
        +data?.statusId === 7
    );

    setValue("text", data?.elements[0]?.text);
    setValue("note", data?.elements[0]?.note);

    if (+data?.statusId === 3 || +data?.statusId === 5) {
      setValue(
        "money",
        formatMoney(
          data.amount ||
            data?.elements?.filter((item) => +item.statusId === 3)[0]?.value
        )
      );

      setValue("status", "3");
    }

    if (+data?.statusId === 7) {
      setValue("status", "7");
    }

    if (+data?.statusId === 4) {
      setValue("status", "4");
    }

    return () => {};
  }, [data]);

  useEffect(() => {}, [statusWatch]);

  const feedBack = async (type) => {
    let values = getValues();
    let request = {
      status: values.status,
      note: values.note,
      amount:
        values.status?.toString() === "3"
          ? +removeFormatMoney(values.money)
          : null,
      text: values.text,
      type: 32,
    };

    await addFeedBack(+data?.id, request);
    setVisible(false);
    setVisibleResult(true);
    await afterSend();
  };

  const sendFinal = async (statusId) => {
    let values = getValues();
    let request = {
      status: statusId,
      note: values.note,
      amount: +removeFormatMoney(values.money),
      text: values.text,
      type: 32,
    };

    await addFeedBack(+data?.id, request);
    setVisibleAccept(false);
    setVisibleReject(false);
    setFinalType(statusId);
    setVisibleFinalResult(true);
    await afterSend();
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        alignItems="stretch"
      >
        <Grid item xs={8} style={{ paddingLeft: 30, marginBottom: 20 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ paddingTop: 20 }}>
              <Typography fontWeight={"bold"} color="#2A3790" fontSize={20}>
                Phần duyệt của bồi thường viên
              </Typography>
            </Grid>

            <Grid item xs={4} style={{ paddingLeft: 0 }}>
              <label className="premium-radio" htmlFor="accepted">
                <input
                  type={"radio"}
                  value="3"
                  id="accepted"
                  {...register("status")}
                  disabled={
                    // (+data?.userIdReview !== +user?.id &&
                    //   +data?.userIdAssign !== +user?.id) ||
                    disabled
                  }
                />
                <span class="design"></span>
                <span class="value">Hồ sơ đủ điều kiện</span>
              </label>
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: 0 }}>
              <label className="premium-radio" htmlFor="addition">
                <input
                  type={"radio"}
                  value="4"
                  id="addition"
                  {...register("status")}
                  disabled={
                    // (+data?.userIdReview !== +user?.id &&
                    //   +data?.userIdAssign !== +user?.id) ||
                    disabled
                  }
                />
                <span class="design"></span>
                <span class="value">Hồ sơ bổ sung</span>
              </label>
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: 0 }}>
              <label className="premium-radio" htmlFor="denied">
                <input
                  type={"radio"}
                  value="7"
                  id="denied"
                  {...register("status")}
                  disabled={
                    // (+data?.userIdReview !== +user?.id &&
                    //   +data?.userIdAssign !== +user?.id) ||
                    disabled
                  }
                />
                <span class="design"></span>
                <span class="value">Từ chối</span>
              </label>
            </Grid>
          </Grid>
        </Grid>

        {(+statusWatch === 3 ||
          +data?.statusId === 5 ||
          +data?.statusId === 3) && (
          <Grid item xs={4} style={{ paddingLeft: 0 }}>
            <div
              style={{
                background: "#EBEDFF",
                borderRadius: 8,
                padding: 10,
              }}
            >
              <Typography fontWeight={"bold"} color="#2A3790" fontSize={12}>
                <TotalInsureFeeIcon style={{ marginRight: 5 }} />
                <span style={{ verticalAlign: "super" }}>
                  Số tiền bồi thường
                </span>
              </Typography>

              <div
                style={{
                  marginTop: 5,
                  display: "grid",
                  gridTemplateColumns: "repeat(8, 1fr)",
                  border: "1px solid #2A3790",
                  borderRagdius: 4,
                  background: "white",
                  padding: 3,
                }}
              >
                <div
                  style={{
                    gridColumn: "1/8",
                    textAlign: "right",
                  }}
                >
                  <input
                    onKeyUp={async (e) => {
                      await trigger("money");
                    }}
                    disabled={+data?.statusId === 5 || +data?.statusId === 7}
                    style={{
                      paddingRight: 5,
                      border: "none",
                      outline: "none",
                      textAlign: "right",
                      fontWeight: 500,
                    }}
                    {...register("money", {
                      validate: (e) => {
                        if (+removeFormatMoney(e) === 0)
                          return "Vui lòng nhập số tiền";

                        return null;
                      },
                    })}
                  />
                </div>

                <div style={{ paddingTop: 2 }}>
                  <sup>VNĐ</sup>
                </div>
              </div>
              <div
                style={{
                  minHeight: 16,
                }}
              >
                {errors["money"] && (
                  <div
                    style={{
                      color: "red",

                      fontSize: 12,
                    }}
                  >
                    {errors["money"].message}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        )}
        {/* */}
        {((statusWatch === "3" && +data?.statusId === 3) ||
          data?.statusId === 5 ||
          +data?.statusId === 7) && (
          <>
            <Grid item xs={6} style={{ paddingLeft: 30 }}>
              <Typography color={"#2A3790"} fontSize={14}>
                <FileTextIcon style={{ marginRight: 5 }} />
                <span style={{ verticalAlign: "super" }}>
                  Nội dung được chi trả
                </span>
                <span style={{ verticalAlign: "super", color: "red" }}>*</span>
              </Typography>

              <textarea
                {...register("text", {
                  validate: (e) => {
                    return !e || e === "" ? "Vui lòng nhập nội dung" : null;
                  },
                })}
                disabled={
                  +user.id !== +data?.userIdAssign ||
                  +data?.statusId === 5 ||
                  +data?.statusId === 7
                }
                style={{
                  width: "100%",
                  height: 150,
                  borderRadius: 8,
                  outline: "none",
                  padding: 5,
                  resize: "none",
                }}
                placeholder="Nội dung..."
              />

              <div
                style={{
                  minHeight: 16,
                }}
              >
                {errors["money"] && (
                  <div
                    style={{
                      color: "red",

                      fontSize: 12,
                    }}
                  >
                    {errors["money"].message}
                  </div>
                )}
              </div>
            </Grid>

            <Grid item xs={6} style={{ paddingLeft: 30 }}>
              <Typography color={"#2A3790"} fontSize={14}>
                <FileTextIcon style={{ marginRight: 5 }} />
                <span style={{ verticalAlign: "super" }}>
                  Nội dung không được chi trả
                </span>
              </Typography>
              <textarea
                disabled={
                  +user.id !== +data?.userIdAssign ||
                  +data?.statusId === 5 ||
                  +data?.statusId === 7
                }
                {...register("note")}
                style={{
                  width: "100%",
                  height: 150,
                  borderRadius: 8,
                  outline: "none",
                  padding: 5,
                  resize: "none",
                }}
                placeholder="Nội dung..."
              />
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "center",
                paddingBottom: 20,
                marginTop: 10,
              }}
            >
              <Button
                style={{
                  width: 180,
                  height: 50,
                  background: "#2A3790",
                  borderRadius: 28,
                  color: "white",
                  boxShadow: "0px 0px 16px 8px rgba(0, 0, 0, 0.08)",
                }}
                size="large"
                onClick={async () => {
                  const validate = await trigger();
                  if (validate) {
                    return setVisibleAccept(true);
                  }
                }}
                disabled={
                  +user.id !== +data?.userIdAssign ||
                  +data?.statusId === 5 ||
                  +data?.statusId === 7
                }
              >
                Đồng ý
              </Button>
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "center",
                paddingBottom: 20,
                marginTop: 10,
              }}
            >
              <Button
                disabled={
                  +user.id !== +data?.userIdAssign ||
                  +data?.statusId === 5 ||
                  +data?.statusId === 7
                }
                style={{
                  width: 180,
                  height: 50,
                  background: "#2A3790",
                  borderRadius: 28,
                  color: "white",
                  boxShadow: "0px 0px 16px 8px rgba(0, 0, 0, 0.08)",
                }}
                size="large"
                onClick={async () => {
                  const validate = await trigger();
                  if (validate) {
                    return setVisibleReject(true);
                  }
                }}
              >
                Từ chối
              </Button>
            </Grid>
          </>
        )}

        {statusWatch === "3" &&
          +data?.statusId !== 3 &&
          +data?.statusId !== 7 &&
          +data?.statusId !== 5 && (
            <>
              <Grid item xs={6} style={{ paddingLeft: 30 }}>
                <Typography color={"#2A3790"} fontSize={14}>
                  <FileTextIcon style={{ marginRight: 5 }} />
                  <span style={{ verticalAlign: "super" }}>
                    Nội dung được chi trả
                  </span>
                  <span style={{ verticalAlign: "super", color: "red" }}>
                    *
                  </span>
                </Typography>

                <textarea
                  onKeyUp={async (e) => {
                    await trigger("text");
                  }}
                  {...register("text", {
                    validate: (e) => {
                      return !e || e === "" ? "Vui lòng nhập nội dung" : null;
                    },
                  })}
                  disabled={disabled}
                  style={{
                    width: "100%",
                    height: 150,
                    borderRadius: 8,
                    outline: "none",
                    padding: 5,
                    resize: "none",
                  }}
                  placeholder="Nội dung..."
                />

                <div style={{ minHeight: 16 }}>
                  {errors["text"] && (
                    <div style={{ color: "red", fontSize: 12 }}>
                      {errors["text"].message}
                    </div>
                  )}
                </div>
              </Grid>

              <Grid item xs={6} style={{ paddingLeft: 30 }}>
                <Typography color={"#2A3790"} fontSize={14}>
                  <FileTextIcon style={{ marginRight: 5 }} />
                  <span style={{ verticalAlign: "super" }}>
                    Nội dung không được chi trả
                  </span>
                </Typography>
                <textarea
                  disabled={disabled}
                  {...register("note")}
                  style={{
                    width: "100%",
                    height: 150,
                    borderRadius: 8,
                    outline: "none",
                    padding: 5,
                    resize: "none",
                  }}
                  placeholder="Nội dung..."
                />
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  padding: 0,
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: 20,
                }}
              >
                <Button
                  style={{
                    width: 180,
                    height: 50,
                    background: "#2A3790",
                    borderRadius: 28,
                    color: "white",
                    boxShadow: "0px 0px 16px 8px rgba(0, 0, 0, 0.08)",
                  }}
                  size="large"
                  onClick={async () => {
                    const validate = await trigger();
                    if (!validate) return;
                    setVisible(true);
                  }}
                  disabled={
                    // (+data?.userIdReview !== +user?.id &&
                    //   +data?.userIdAssign !== +user?.id) ||
                    disabled
                  }
                >
                  Gửi phê duyệt
                </Button>
              </Grid>
            </>
          )}

        {statusWatch === "4" && (
          <>
            <Grid item xs={12} style={{ paddingLeft: 30 }}>
              <Typography color={"#2A3790"} fontSize={14}>
                <FileTextIcon style={{ marginRight: 5 }} />
                <span style={{ verticalAlign: "super" }}>Nhập lý do</span>
                <span style={{ verticalAlign: "super", color: "red" }}>*</span>
              </Typography>
              <textarea
                {...register("text", {})}
                disabled={disabled}
                style={{
                  width: "100%",
                  height: 150,
                  borderRadius: 8,
                  outline: "none",
                  padding: 5,
                  resize: "none",
                }}
                placeholder="Nhập nội dung..."
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "center",
                paddingBottom: 20,
              }}
            >
              <Button
                style={{
                  width: 180,
                  height: 50,
                  background: "#2A3790",
                  borderRadius: 28,
                  color: "white",
                  boxShadow: "0px 0px 16px 8px rgba(0, 0, 0, 0.08)",
                }}
                size="large"
                onClick={() => {
                  setVisible(true);
                }}
                disabled={
                  // (+data?.userIdReview !== +user?.id &&
                  //   +data?.userIdAssign !== +user?.id) ||
                  disabled
                }
              >
                Gửi yêu cầu bổ sung
              </Button>
            </Grid>
          </>
        )}

        {statusWatch === "7" && +data?.statusId !== 7 && (
          <>
            <Grid item xs={12} style={{ paddingLeft: 30 }}>
              <Typography color={"#2A3790"} fontSize={14}>
                <FileTextIcon style={{ marginRight: 5 }} />
                <span style={{ verticalAlign: "super" }}>Nhập lý do</span>
                <span style={{ verticalAlign: "super", color: "red" }}>*</span>
              </Typography>
              <textarea
                disabled={disabled}
                style={{
                  width: "100%",
                  height: 150,
                  borderRadius: 8,
                  outline: "none",
                  padding: 5,
                  resize: "none",
                }}
                placeholder="Nhập nội dung..."
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                padding: 0,
                display: "flex",
                justifyContent: "center",
                paddingBottom: 20,
              }}
            >
              <Button
                style={{
                  width: 180,
                  height: 50,
                  background: "#2A3790",
                  borderRadius: 28,
                  color: "white",
                  boxShadow: "0px 0px 16px 8px rgba(0, 0, 0, 0.08)",
                }}
                size="large"
                onClick={() => {
                  setVisible(true);
                }}
                disabled={disabled}
              >
                Gửi phê duyệt
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        centered
        footer={null}
        width={400}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <svg
            width="57"
            height="51"
            viewBox="0 0 57 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M55.8159 44.8038L31.3553 2.43891C31.0383 1.86994 30.575 1.39597 30.0135 1.06601C29.4519 0.736047 28.8124 0.562073 28.1611 0.562073C27.5098 0.562073 26.8703 0.736047 26.3087 1.06601C25.7471 1.39597 25.2839 1.86994 24.9669 2.43891L0.506256 44.8038C0.182015 45.3654 0.0111315 46.0023 0.0107429 46.6508C0.0103542 47.2993 0.180474 47.9365 0.504042 48.4985C0.827609 49.0605 1.29325 49.5275 1.85427 49.8527C2.41528 50.178 3.05195 50.35 3.70043 50.3516H52.6217C53.2702 50.35 53.9069 50.178 54.4679 49.8527C55.0289 49.5275 55.4946 49.0605 55.8181 48.4985C56.1417 47.9365 56.3118 47.2993 56.3114 46.6508C56.311 46.0023 56.1402 45.3654 55.8159 44.8038ZM28.2031 15.3837C28.5765 15.3891 28.9452 15.4688 29.2875 15.618C29.6299 15.7672 29.9391 15.983 30.1973 16.2529C30.4554 16.5228 30.6573 16.8413 30.7911 17.19C30.925 17.5386 30.9882 17.9104 30.977 18.2837L30.5147 34.4227C30.4387 34.9875 30.1604 35.5055 29.7314 35.8806C29.3025 36.2557 28.752 36.4625 28.1821 36.4625C27.6122 36.4625 27.0617 36.2557 26.6328 35.8806C26.2038 35.5055 25.9255 34.9875 25.8495 34.4227L25.2191 18.3678C25.2078 17.991 25.2724 17.6157 25.4088 17.2643C25.5453 16.9129 25.7509 16.5925 26.0135 16.322C26.2761 16.0516 26.5904 15.8366 26.9376 15.6899C27.2849 15.5431 27.658 15.4676 28.035 15.4678L28.2031 15.3837ZM28.2031 44.5096C27.6296 44.5096 27.0689 44.3395 26.592 44.0208C26.1151 43.7022 25.7434 43.2493 25.5239 42.7194C25.3044 42.1895 25.247 41.6064 25.3589 41.0438C25.4708 40.4813 25.747 39.9646 26.1525 39.559C26.5581 39.1534 27.0748 38.8772 27.6374 38.7653C28.1999 38.6534 28.783 38.7109 29.3129 38.9304C29.8428 39.1499 30.2957 39.5216 30.6144 39.9985C30.933 40.4754 31.1031 41.036 31.1031 41.6096C31.1031 42.3787 30.7976 43.1163 30.2537 43.6602C29.7099 44.204 28.9722 44.5096 28.2031 44.5096Z"
              fill="#FFC107"
            />
          </svg>
        </div>

        <div style={{ margin: "10px 0" }}>
          <Typography
            color={"#2A3790"}
            fontSize={18}
            textAlign="center"
            fontWeight={600}
          >
            {(statusWatch === "7" || statusWatch === "3") && (
              <>Bạn đã chắc chắn mọi thông tin đã đúng để gửi đi phê duyệt?</>
            )}

            {statusWatch === "4" && (
              <>
                Bạn đã chắc chắn mọi thông tin đã đúng để gửi cho khách hàng yêu
                cầu bổ sung hồ sơ?
              </>
            )}
          </Typography>
        </div>

        <Row>
          <Col span={12} style={{ textAlign: "center" }}>
            <Button
              size="large"
              style={{
                color: "#2A3790",
                border: "1px solid #2A3790",
                width: 150,
                borderRadius: 32,
                fontWeight: 500,
              }}
            >
              Không
            </Button>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <Button
              size="large"
              onClick={async () => {
                await feedBack();
              }}
              style={{
                background: "#2A3790",
                border: "1px solid #2A3790",
                width: 150,
                borderRadius: 32,
                fontWeight: 500,
                color: "white",
              }}
            >
              Có
            </Button>
          </Col>
        </Row>
      </Modal>

      {/* Modal noti status after call API send approved */}
      <Modal
        visible={visibleResult}
        onCancel={() => setVisible(false)}
        centered
        footer={null}
        width={400}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <svg
            width="57"
            height="57"
            viewBox="0 0 57 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.367 0.5C12.9245 0.5 0.207031 13.2175 0.207031 28.6601C0.207031 44.1026 12.9245 56.82 28.367 56.82C43.8096 56.82 56.527 44.1026 56.527 28.6601C56.527 12.9147 43.8096 0.5 28.367 0.5ZM45.3236 16.851L20.7971 41.0746H20.4944L10.8049 25.0265C16.2552 25.0265 20.4944 30.4768 21.1 31.0824C21.1 31.0824 21.0999 31.0824 21.4027 31.0824L45.3236 16.851C45.0208 16.5482 45.3236 16.5482 45.3236 16.851Z"
              fill="#39B44A"
            />
          </svg>
        </div>

        <div style={{ margin: "10px 0" }}>
          <Typography
            color={"#2A3790"}
            fontSize={18}
            textAlign="center"
            fontWeight={600}
          >
            {(statusWatch === "7" || statusWatch === "3") && (
              <>Bạn đã gửi phê duyệt thành công</>
            )}

            {statusWatch === "4" && <>Bạn đã gửi thành công</>}
          </Typography>
        </div>

        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              size="large"
              onClick={async () => {
                setVisibleResult(false);
              }}
              style={{
                background: "#2A3790",
                border: "1px solid #2A3790",
                width: 150,
                borderRadius: 32,
                fontWeight: 500,
                color: "white",
              }}
            >
              Đóng
            </Button>
          </Col>
        </Row>
      </Modal>

      {/* Modal warning call API send approved */}
      <Modal
        visible={visibleAccept}
        onCancel={() => setVisibleAccept(false)}
        centered
        footer={null}
        width={400}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <svg
            width="57"
            height="51"
            viewBox="0 0 57 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M55.8159 44.8038L31.3553 2.43891C31.0383 1.86994 30.575 1.39597 30.0135 1.06601C29.4519 0.736047 28.8124 0.562073 28.1611 0.562073C27.5098 0.562073 26.8703 0.736047 26.3087 1.06601C25.7471 1.39597 25.2839 1.86994 24.9669 2.43891L0.506256 44.8038C0.182015 45.3654 0.0111315 46.0023 0.0107429 46.6508C0.0103542 47.2993 0.180474 47.9365 0.504042 48.4985C0.827609 49.0605 1.29325 49.5275 1.85427 49.8527C2.41528 50.178 3.05195 50.35 3.70043 50.3516H52.6217C53.2702 50.35 53.9069 50.178 54.4679 49.8527C55.0289 49.5275 55.4946 49.0605 55.8181 48.4985C56.1417 47.9365 56.3118 47.2993 56.3114 46.6508C56.311 46.0023 56.1402 45.3654 55.8159 44.8038ZM28.2031 15.3837C28.5765 15.3891 28.9452 15.4688 29.2875 15.618C29.6299 15.7672 29.9391 15.983 30.1973 16.2529C30.4554 16.5228 30.6573 16.8413 30.7911 17.19C30.925 17.5386 30.9882 17.9104 30.977 18.2837L30.5147 34.4227C30.4387 34.9875 30.1604 35.5055 29.7314 35.8806C29.3025 36.2557 28.752 36.4625 28.1821 36.4625C27.6122 36.4625 27.0617 36.2557 26.6328 35.8806C26.2038 35.5055 25.9255 34.9875 25.8495 34.4227L25.2191 18.3678C25.2078 17.991 25.2724 17.6157 25.4088 17.2643C25.5453 16.9129 25.7509 16.5925 26.0135 16.322C26.2761 16.0516 26.5904 15.8366 26.9376 15.6899C27.2849 15.5431 27.658 15.4676 28.035 15.4678L28.2031 15.3837ZM28.2031 44.5096C27.6296 44.5096 27.0689 44.3395 26.592 44.0208C26.1151 43.7022 25.7434 43.2493 25.5239 42.7194C25.3044 42.1895 25.247 41.6064 25.3589 41.0438C25.4708 40.4813 25.747 39.9646 26.1525 39.559C26.5581 39.1534 27.0748 38.8772 27.6374 38.7653C28.1999 38.6534 28.783 38.7109 29.3129 38.9304C29.8428 39.1499 30.2957 39.5216 30.6144 39.9985C30.933 40.4754 31.1031 41.036 31.1031 41.6096C31.1031 42.3787 30.7976 43.1163 30.2537 43.6602C29.7099 44.204 28.9722 44.5096 28.2031 44.5096Z"
              fill="#FFC107"
            />
          </svg>
        </div>

        <div style={{ margin: "10px 0" }}>
          <Typography
            color={"#2A3790"}
            fontSize={18}
            textAlign="center"
            fontWeight={600}
          >
            Bạn đã chắc chắn mọi thông tin phê duyệt đã đúng?
          </Typography>
        </div>

        <Row>
          <Col span={12} style={{ textAlign: "center" }}>
            <Button
              size="large"
              onClick={() => {
                setVisibleAccept(false);
              }}
              style={{
                color: "#2A3790",
                border: "1px solid #2A3790",
                width: 150,
                borderRadius: 32,
                fontWeight: 500,
              }}
            >
              Không
            </Button>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <Button
              size="large"
              onClick={async () => {
                await sendFinal(5);
              }}
              style={{
                background: "#2A3790",
                border: "1px solid #2A3790",
                width: 150,
                borderRadius: 32,
                fontWeight: 500,
                color: "white",
              }}
            >
              Có
            </Button>
          </Col>
        </Row>
      </Modal>

      {/* Modal warning call API send refuse */}
      <Modal
        visible={visibleReject}
        onCancel={() => setVisibleReject(false)}
        centered
        footer={null}
        width={400}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <svg
            width="57"
            height="51"
            viewBox="0 0 57 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M55.8159 44.8038L31.3553 2.43891C31.0383 1.86994 30.575 1.39597 30.0135 1.06601C29.4519 0.736047 28.8124 0.562073 28.1611 0.562073C27.5098 0.562073 26.8703 0.736047 26.3087 1.06601C25.7471 1.39597 25.2839 1.86994 24.9669 2.43891L0.506256 44.8038C0.182015 45.3654 0.0111315 46.0023 0.0107429 46.6508C0.0103542 47.2993 0.180474 47.9365 0.504042 48.4985C0.827609 49.0605 1.29325 49.5275 1.85427 49.8527C2.41528 50.178 3.05195 50.35 3.70043 50.3516H52.6217C53.2702 50.35 53.9069 50.178 54.4679 49.8527C55.0289 49.5275 55.4946 49.0605 55.8181 48.4985C56.1417 47.9365 56.3118 47.2993 56.3114 46.6508C56.311 46.0023 56.1402 45.3654 55.8159 44.8038ZM28.2031 15.3837C28.5765 15.3891 28.9452 15.4688 29.2875 15.618C29.6299 15.7672 29.9391 15.983 30.1973 16.2529C30.4554 16.5228 30.6573 16.8413 30.7911 17.19C30.925 17.5386 30.9882 17.9104 30.977 18.2837L30.5147 34.4227C30.4387 34.9875 30.1604 35.5055 29.7314 35.8806C29.3025 36.2557 28.752 36.4625 28.1821 36.4625C27.6122 36.4625 27.0617 36.2557 26.6328 35.8806C26.2038 35.5055 25.9255 34.9875 25.8495 34.4227L25.2191 18.3678C25.2078 17.991 25.2724 17.6157 25.4088 17.2643C25.5453 16.9129 25.7509 16.5925 26.0135 16.322C26.2761 16.0516 26.5904 15.8366 26.9376 15.6899C27.2849 15.5431 27.658 15.4676 28.035 15.4678L28.2031 15.3837ZM28.2031 44.5096C27.6296 44.5096 27.0689 44.3395 26.592 44.0208C26.1151 43.7022 25.7434 43.2493 25.5239 42.7194C25.3044 42.1895 25.247 41.6064 25.3589 41.0438C25.4708 40.4813 25.747 39.9646 26.1525 39.559C26.5581 39.1534 27.0748 38.8772 27.6374 38.7653C28.1999 38.6534 28.783 38.7109 29.3129 38.9304C29.8428 39.1499 30.2957 39.5216 30.6144 39.9985C30.933 40.4754 31.1031 41.036 31.1031 41.6096C31.1031 42.3787 30.7976 43.1163 30.2537 43.6602C29.7099 44.204 28.9722 44.5096 28.2031 44.5096Z"
              fill="#FFC107"
            />
          </svg>
        </div>

        <div style={{ margin: "10px 0" }}>
          <Typography
            color={"#2A3790"}
            fontSize={18}
            textAlign="center"
            fontWeight={600}
          >
            Bạn đã chắc chắn mọi thông tin đã đúng để từ chối hồ sơ?
          </Typography>
        </div>

        <Row>
          <Col span={12} style={{ textAlign: "center" }}>
            <Button
              size="large"
              onClick={() => {
                setVisibleReject(false);
              }}
              style={{
                color: "#2A3790",
                border: "1px solid #2A3790",
                width: 150,
                borderRadius: 32,
                fontWeight: 500,
              }}
            >
              Không
            </Button>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <Button
              size="large"
              onClick={async () => {
                await sendFinal(7);
              }}
              style={{
                background: "#2A3790",
                border: "1px solid #2A3790",
                width: 150,
                borderRadius: 32,
                fontWeight: 500,
                color: "white",
              }}
            >
              Có
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={visibleFinalResult}
        onCancel={() => setVisibleFinalResult(false)}
        centered
        footer={null}
        width={400}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <svg
            width="57"
            height="57"
            viewBox="0 0 57 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.3592 0.5C12.9166 0.5 0.199219 13.2175 0.199219 28.6601C0.199219 44.1026 12.9166 56.82 28.3592 56.82C43.8018 56.82 56.5192 44.1026 56.5192 28.6601C56.5192 12.9147 43.8018 0.5 28.3592 0.5ZM45.3158 16.851L20.7893 41.0746H20.4866L10.7971 25.0265C16.2474 25.0265 20.4866 30.4768 21.0921 31.0824C21.0921 31.0824 21.0921 31.0824 21.3949 31.0824L45.3158 16.851C45.013 16.5482 45.3158 16.5482 45.3158 16.851Z"
              fill="#39B44A"
            />
          </svg>
        </div>

        <div style={{ margin: "10px 0" }}>
          <Typography
            color={"#2A3790"}
            fontSize={18}
            textAlign="center"
            fontWeight={600}
          >
            {+finalType === 5 && <>Bạn đã phê duyệt hồ sơ thành công</>}
            {+finalType === 7 && <>Bạn đã gửi thành công</>}
          </Typography>
        </div>

        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              size="large"
              onClick={async () => {
                setVisibleFinalResult(false);
                setFinalType(null);
              }}
              style={{
                background: "#2A3790",
                border: "1px solid #2A3790",
                width: 150,
                borderRadius: 32,
                fontWeight: 500,
                color: "white",
              }}
            >
              Đóng
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

function Step({ detail }) {
  const [open, setOpen] = useState(true);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <div style={{ display: "flex" }}>
          <BlueDotIcon style={{ marginRight: 5 }} />
          <div style={{ marginTop: -4, fontWeight: 700 }}>
            <span style={{ color: "#FB7800" }}> Mã hồ sơ: </span>
            <span style={{ color: "#2A3790" }}> {detail?.code || "N/A"} </span>
          </div>
        </div>
        <div style={{ marginTop: -15 }}>
          <UpIcon
            style={{ transform: open ? "rotate(0deg)" : "rotate(180deg)" }}
          />
        </div>
      </div>

      <div
        style={{
          transition: "0.3s",
          opacity: open ? 1 : 0,
          height: !open ? 0 : "auto",
          minHeight: !open ? 0 : 250,
          borderLeft: "2px solid #2A3790",
          marginLeft: 8,
          marginTop: -8,
          padding: 10,
        }}
      >
        <div style={{ margin: "5px 0" }}>
          <CalendarIcon style={{ marginRight: 5 }} />
          <span
            style={{
              verticalAlign: "top",
              fontSize: 14,
              fontWeight: "bold",
              color: "#2A3790",
            }}
          >
            Ngày tạo hồ sơ:
          </span>
          <span style={{ verticalAlign: "top", fontSize: 14 }}>
            {" "}
            {moment(detail?.claim_created).format("DD/MM/YYYY - hh:mm:ss")}
          </span>
        </div>

        <div style={{ margin: "5px 0" }}>
          <CalendarIcon style={{ marginRight: 5 }} />
          <span
            style={{
              verticalAlign: "top",
              fontSize: 14,
              fontWeight: "bold",
              color: "#2A3790",
            }}
          >
            Ngày cập nhật:
          </span>
          <span style={{ verticalAlign: "top", fontSize: 14 }}>
            {" "}
            {moment(detail?.claim_updated).format("DD/MM/YYYY - hh:mm:ss")}
          </span>
        </div>

        <div style={{ margin: "5px 0" }}>
          <StatusIcon style={{ marginRight: 5 }} />
          <span
            style={{
              verticalAlign: "top",
              fontSize: 14,
              fontWeight: "bold",
              color: "#2A3790",
            }}
          >
            Trạng thái bồi thường:
          </span>
          <span
            style={{
              verticalAlign: "top",
              fontSize: 14,
              color: STATUS.filter(
                (item) => item.value === +detail.status_id
              )[0]?.color,
            }}
          >
            {" "}
            {
              STATUS.filter((item) => item.value === +detail.status_id)[0]
                ?.label
            }
          </span>
        </div>

        <div style={{ margin: "5px 0" }}>
          <CircleInnerUserIcon style={{ marginRight: 5 }} />
          <span
            style={{
              verticalAlign: "top",
              fontSize: 14,
              fontWeight: "bold",
              color: "#2A3790",
            }}
          >
            Người xử lý hồ sơ:
          </span>
          <span
            style={{
              verticalAlign: "top",
              fontSize: 14,
              textTransform: "capitalize",
            }}
          >
            {" "}
            {detail.assigner}
          </span>
        </div>

        <div
          style={{
            margin: "5px 0",
            display: "grid",
            gap: 5,
            gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
          }}
        >
          <div style={{ gridColumn: "span 6 / span 6" }}>
            <FileTextIcon style={{ marginRight: 5 }} />
            <span
              style={{
                verticalAlign: "top",
                fontSize: 14,
                fontWeight: "bold",
                color: "#2A3790",
              }}
            >
              Nội dung:
            </span>
            <div
              style={{
                verticalAlign: "top",
                fontSize: 14,
                textTransform: "capitalize",
                border: "1px solid #2A3790",
                minHeight: 60,
                borderRadius: 8,
                padding: 10,
                color: "#343A40",
              }}
            >
              {detail.text}
            </div>
          </div>

          <div style={{ gridColumn: "span 6 / span 6" }}>
            <FileTextIcon style={{ marginRight: 5 }} />
            <span
              style={{
                verticalAlign: "top",
                fontSize: 14,
                fontWeight: "bold",
                color: "#2A3790",
              }}
            >
              Từ chối:
            </span>
            <div
              style={{
                verticalAlign: "top",
                fontSize: 14,
                textTransform: "capitalize",
                border: "1px solid #2A3790",
                minHeight: 60,
                borderRadius: 8,
                padding: 10,
                color: "#343A40",
              }}
            >
              {detail.note}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function CheckClaimDetail({ details }) {
  const link = useRouteMatch();
  // const [data, setData]= useSta'te(null)
  const [showAccidentDetail, setShowAccidentDetail] = useState(false);
  const [showDiagnosticDetail, setShowDiagnosticDetail] = useState(false);
  const [elements, setElements] = useState([]);
  const [render, setRender] = useState(true);
  const [data, setData] = useState(null);
  const roleAdminMenuItem = JSON.parse(
    localStorage.getItem("roleAdminMenuItem")
  );

  useEffect(() => {
    if (!details) return;

    setData(details);

    return () => {};
  }, [details]);

  useEffect(() => {}, [data]);

  const mountedRef = useRef(true);

  const getDetailData = async () => {
    const response = await getDatailsClaimCarFrom(
      roleAdminMenuItem?.userType,
      data?.id
    );
    if (response instanceof Error) return message.error("Lỗi khi lấy dữ liệu");

    setData(response.data?.result);
  };

  const feedback = async (type, data) => {
    await addFeedBack(data?.id, data);
  };

  const getHistory = async () => {
    const result = await getListHistory(details?.id);
    if (result instanceof Error) return message.error("Lỗi khi lấy dữ liệu");

    setElements(result.data?.result || []);
  };

  useEffect(() => {
    if (!details) return;

    async function callApi() {
      await getHistory();
    }

    callApi();
  }, [details]);

  const renderStatus = (statusId) => {
    let status = STATUS.filter((item) => +item.value === statusId)[0];

    return { colorCode: status?.color, statusText: status?.text };
  };

  return (
    <>
      {render && (
        <Container maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontWeight={500} fontSize={16} color="#2A3790">
                Kiểm tra xử lý hồ sơ /{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {data?.insuredInfo?.insuredName || "N/A"}
                </span>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography fontWeight={500} fontSize={18}>
                <span>Trạng thái:</span>
                <span style={{ color: renderStatus(data?.statusId).colorCode }}>
                  {" "}
                  {
                    STATUS.filter((item) => +item.value === data?.statusId)[0]
                      ?.label
                  }
                </span>
              </Typography>
            </Grid>

            <Grid item xs={4} style={{ minHeight: 950 }}>
              <div
                style={{
                  background: "#EBFFFE",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "10px",
                  padding: 10,
                }}
              >
                <Collapse title="Thông tin hợp đồng">
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <TotalBenefitIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Tổng quyền lợi bảo hiểm
                      </span>
                    </div>

                    <div>
                      {formatMoney(+details?.insuredInfo?.fees * 500)}
                      <sup>VNĐ</sup>
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <UserIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Họ và tên
                      </span>
                    </div>

                    <div>{details?.insuredInfo?.insuredName}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <BirthCakeIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Năm sinh
                      </span>
                    </div>

                    <div>{details?.insuredInfo?.insuredDob || "N/A"}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <PrivateIdIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        CMND/CCCD
                      </span>
                    </div>

                    <div>{details?.insuredInfo?.insuredPrivateId || "N/A"}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <MobilePhoneIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Số điện thoại
                      </span>
                    </div>

                    <div>{details?.insuredInfo?.insuredPhoneNumber}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <EmailIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Email
                      </span>
                    </div>

                    <div>{details?.insuredInfo?.insuredEmail || "N/A"}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <AddressIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Địa chỉ
                      </span>
                    </div>

                    <div>{details?.insuredInfo?.insuredAddress || "N/A"}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>
                </Collapse>

                <Collapse title="Hồ sơ bồi thường">
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <FileTextIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Loại tai nạn
                      </span>
                    </div>

                    <div>
                      {
                        ACCIDENT_TYPE.filter(
                          (item) =>
                            item.value === data?.extraInfo?.accident?.type
                        )[0]?.label
                      }
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <FileTextIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Mức độ tai nạn
                      </span>
                    </div>

                    <div>
                      {
                        ACCIDENT_LEVEL.filter(
                          (item) =>
                            item.value === data?.extraInfo?.accident?.level
                        )[0]?.label
                      }
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <FileTextIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Loại hình điều trị
                      </span>
                    </div>

                    <div>
                      {TREATMENT_TYPE.filter(
                        (item) =>
                          item.value === data?.insuredInfo?.treatmentType
                      )[0]?.label || "N/A"}
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CalendarIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Ngày nhập viện
                      </span>
                    </div>

                    <div>
                      {moment(
                        data?.insuredInfo?.hospitalizeDate,
                        "YYYY-MM-DD"
                      ).isValid()
                        ? moment(
                            data?.insuredInfo?.hospitalizeDate,
                            "YYYY-MM-DD"
                          ).format("DD/MM/YYYY")
                        : "N/A"}
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CalendarIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Ngày xuất viện
                      </span>
                    </div>

                    <div>
                      {moment(
                        data?.insuredInfo?.dischargeDate,
                        "YYYY-MM-DD"
                      ).isValid()
                        ? moment(
                            data?.insuredInfo?.dischargeDate,
                            "YYYY-MM-DD"
                          ).format("DD/MM/YYYY")
                        : "N/A"}
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CalendarIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Ngày tai nạn
                      </span>
                    </div>

                    <div>
                      {moment(
                        data?.insuredInfo?.accidentDate,
                        "YYYY-MM-DD"
                      ).isValid()
                        ? moment(
                            data?.insuredInfo?.accidentDate,
                            "YYYY-MM-DD"
                          ).format("DD/MM/YYYY")
                        : "N/A"}
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <AccidentIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Nơi xảy ra tai nạn
                      </span>
                    </div>

                    <div>{data?.insuredInfo?.accidentAt}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>
                </Collapse>

                <Collapse title={"Diễn biến tai nạn"}>
                  <div
                    style={{
                      position: "relative",
                      border: "1px solid #F1F1F1",
                      padding: "9px 17px",
                      background: "white",
                      borderRadius: 8,
                      maxHeight: !showAccidentDetail ? "60px" : "none",
                      whiteSpace: showAccidentDetail ? "normal" : "nowrap",
                      minHeight: 60,
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 15,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {data?.insuredInfo?.discription}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        cursor: "pointer",
                        color: "#0044CC",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        setShowAccidentDetail((prev) => !prev);
                      }}
                    >
                      {showAccidentDetail ? "Ẩn bớt" : "Xem thêm"}
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <HospitalIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Nơi điều trị
                      </span>
                    </div>

                    <Tooltip
                      placement="top"
                      title={data?.insuredInfo?.text}
                      arrowPointAtCenter
                    >
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: "150px",
                          cursor: "pointer",
                        }}
                      >
                        {data?.insuredInfo?.text}
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <TotalInsureFeeIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Tổng chi phí điều trị
                      </span>
                    </div>

                    <div>
                      {formatMoney(data?.insuredInfo?.treatmentPrice)}
                      <sup>VNĐ</sup>
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>
                </Collapse>

                <Collapse title="Chẩn đoán bệnh">
                  <div
                    style={{
                      position: "relative",
                      border: "1px solid #F1F1F1",
                      padding: "9px 17px",
                      background: "white",
                      borderRadius: 8,
                      maxHeight: !showDiagnosticDetail ? "60px" : "none",
                      whiteSpace: showDiagnosticDetail ? "normal" : "nowrap",
                      minHeight: 60,
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 15,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {data?.insuredInfo?.diagnostic}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        cursor: "pointer",
                        color: "#0044CC",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        setShowDiagnosticDetail((prev) => !prev);
                      }}
                    >
                      {showDiagnosticDetail ? "Ẩn bớt" : "Xem thêm"}
                    </div>
                  </div>
                </Collapse>

                <Collapse title="Thông tin người thụ hưởng">
                  <div
                    style={{
                      position: "relative",
                      border: "1px solid #F1F1F1",
                      padding: "9px 17px",
                      background: "white",
                      borderRadius: 8,
                      maxHeight: !showDiagnosticDetail ? "60px" : "none",
                      whiteSpace: showDiagnosticDetail ? "normal" : "nowrap",
                      minHeight: 60,
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 15,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {data?.insuredInfo?.diagnostic}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        cursor: "pointer",
                        color: "#0044CC",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        setShowDiagnosticDetail((prev) => !prev);
                      }}
                    >
                      {showDiagnosticDetail ? "Ẩn bớt" : "Xem thêm"}
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CircleInnerUserIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Chủ tài khoản
                      </span>
                    </div>

                    <div
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "150px",
                        textTransform: "capitalize",
                      }}
                    >
                      {data?.extraInfo?.beneficiary?.fullname}
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", maxWidth: 180 }}>
                      <RelationshipIcon style={{ marginRight: 5 }} />
                      <div
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                          textTransform: "capitalize",
                        }}
                      >
                        Mối quan hệ với người được bảo hiểm
                      </div>
                    </div>

                    <div style={{}}>{data?.extraInfo?.beneficiary?.type}</div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <BankIdIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Số tài khoản
                      </span>
                    </div>

                    <div
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "150px",
                      }}
                    >
                      {data?.extraInfo?.beneficiary?.bank?.id}
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <BankIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Ngân hàng
                      </span>
                    </div>

                    <Tooltip
                      placement="top"
                      title={data?.extraInfo?.beneficiary?.bank?.name}
                      arrowPointAtCenter
                    >
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: "150px",
                          cursor: "pointer",
                        }}
                      >
                        {data?.extraInfo?.beneficiary?.bank?.name}
                      </div>
                    </Tooltip>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>

                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <BankIcon style={{ marginRight: 5 }} />
                      <span
                        style={{
                          verticalAlign: "top",
                          color: "#2A3790",
                          fontSize: 14,
                        }}
                      >
                        Chi nhánh
                      </span>
                    </div>

                    <div
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "150px",
                      }}
                    >
                      {data?.extraInfo?.beneficiary?.bank?.branch}
                    </div>
                  </div>
                  <div style={{ background: "#D2D2D2", height: 1 }}></div>
                </Collapse>
              </div>
            </Grid>

            <Grid
              container
              item
              spacing={2}
              xs={8}
              style={{
                maxHeight: 800,
              }}
            >
              <Grid item xs={6}>
                <div
                  style={{
                    background: "#FFFFE7",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    minHeight: 580,
                    maxHeight: 580,
                    overflowY: "auto",
                    padding: 10,
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography color="#2A3790" fontWeight={500} fontSize={20}>
                      Chứng từ
                    </Typography>
                    <div style={{ fontWeight: 500, fontSize: 20 }}>
                      {/* Mã hồ sơ: */}
                      <span style={{ color: "#2A3790" }}> {data?.code}</span>
                    </div>
                  </div>
                  {<RenderAccidentType data={data} />}
                </div>
              </Grid>

              <Grid item xs={6}>
                <div
                  style={{
                    borderRadius: 10,
                    background: "#F2FDF4",
                    padding: 10,
                    minHeight: 580,
                    maxHeight: 580,
                    overflowY: "auto",
                    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Typography
                    color="#2A3790"
                    fontWeight={500}
                    fontSize={20}
                    marginBottom={1}
                  >
                    Lịch sử hồ sơ
                  </Typography>
                  {elements?.map((item, index) => {
                    return <Step detail={item} key={index} />;
                  })}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div
                  style={{
                    borderRadius: 10,
                    background: "#FFF",
                    padding: 10,
                    minHeight: 400,
                    maxHeight: 400,
                    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <RecoupAcceptance
                    sendFeedBack={feedback}
                    data={data}
                    afterSend={async () => {
                      await getHistory();
                      await getDetailData();
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid item xs={1}>
              <Button
                size="large"
                block
                style={{
                  borderRadius: 16,
                  background: "#2A3790",
                  background: "white",
                  border: "1px solid #2A3790",
                  minWidth: "120px",
                }}
                onClick={() => {
                  window.history.back();
                }}
              >
                Quay lại
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
