import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from "@mui/icons-material/Cancel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShowImg(props) {
  const { dispatch, showImg } = props;
  const classes = makeStyle();

  return (
    <Dialog
      open={!!showImg?.open}
      TransitionComponent={Transition}
      maxWidth={"xs"}
      keepMounted
      onClose={() => dispatch("closeShowImg")}
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialog}
    >
      <DialogContent style={{ padding: "20px 0px 10px" }}>
        <div className={classes.divContent}>
          <ArrowBackIosIcon
            size="small"
            className={classes.icon}
            onClick={() => dispatch({ type: "changeIndexImg", name: "pre" })}
          />
          <div className={classes.divImg}>
            <img style={{ width: "100%" }} src={showImg?.url} alt="imgs" />
            <CancelIcon
              className="icon"
              onClick={() => dispatch("closeShowImg")}
            />
          </div>
          <ArrowForwardIosIcon
            size="small"
            className={classes.icon}
            onClick={() => dispatch({ type: "changeIndexImg", name: "next" })}
          />
        </div>
        <div className={classes.index}>{`${(showImg?.indexImg || 0) + 1}/${
          (showImg?.lisUrl || []).length || 1
        }`}</div>
      </DialogContent>
    </Dialog>
  );
}

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
      height: "80%",
      width: "95%",
    },
    "& .MuiDialog-paper": {
      margin: 10,
    },
    "& .MuiDialogContent-root": {
      background: "#343A40",
      padding: "40px 0px 20px",
    },
  },

  divContent: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    height: "90%",
    background: "white",
    margin: "28px 0px 10px",
  },

  divImg: {
    width: "100%",
    maxWidth: "380px",
    "& .icon": {
      position: "absolute",
      top: "-40px",
      right: "5px",
      color: "white",
    },
  },

  icon: { color: "#969696", margin: "0 5px" },
  index: {
    marginTop: 10,
    textAlign: "center",
    fontWeight: 600,
    fontSize: 17,
    color: "white",
  },
}));
