import React, { useEffect } from "react";
import {
  Grid,
  Input,
  RadioGroup,
  Radio,
  IconButton,
  FormControlLabel,
} from "@mui/material";

import HeadSteper from "../components/step/headSteper";
import { styledStep3 } from "../components/step/styledStep3";
import iconDate from "../../../../../assets/ahamove/IconDate.svg";
import iconHours from "../../../../../assets/ahamove/IconHours.svg";
import DatePicker from "../components/layout/PickerMobile/DatePicker";
import TimePicker from "../components/layout/PickerMobile/TimePicker";
import moment from "moment";

export default function Step3(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledStep3();

  const {
    destination: { data, index },
    picker,
    extraInfo,
    errors,
    inforOrder,
  } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const maxDate =
    moment() <=
    moment(inforOrder?.endDate ? inforOrder?.endDate : data?.createdDate)
      ? moment().toDate()
      : moment(
          inforOrder?.endDate ? inforOrder?.endDate : data?.createdDate
        ).toDate();

  const maxTime =
    moment(extraInfo?.riskDate)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .format("DD") ==
    moment()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .format("DD")
      ? moment().format("HH:mm")
      : null;

  return (
    <div className={classes.container}>
      <HeadSteper activeStep={0} />
      <div className={classes.incidentInfor}>
        <div className={classes.titleHead}>THÔNG TIN NGƯỜI GỬI</div>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={6} md={6}>
            <div
              className="incident"
              onClick={() =>
                dispatch({ type: "setOpenPicker", name: "riskDate" })
              }
            >
              <span className="sapnDate">Ngày xảy ra sự cố</span>
              <div className={classes.inputDiv}>
                <Input
                  placeholder="DD/MM/YYYY"
                  fullWidth
                  value={
                    extraInfo.riskDate
                      ? moment(extraInfo.riskDate).format("DD/MM/YYYY")
                      : null
                  }
                  readOnly
                />
                <IconButton style={{ padding: "4px" }}>
                  <img src={iconDate} />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={6}>
            <div
              className="incident"
              onClick={() =>
                dispatch({ type: "setOpenPicker", name: "riskTime" })
              }
            >
              <span className="spanHours">Giờ xảy ra sự cố</span>
              <div className={classes.inputDiv}>
                <Input
                  placeholder="HH:MM"
                  fullWidth
                  value={extraInfo.riskTime || null}
                  readOnly
                />
                <IconButton style={{ padding: "4px" }}>
                  <img src={iconHours} alt="iconHours" />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
        {errors.validateDateTime && (
          <div className={classes.error}>
            {/* Giờ xảy ra sự cố phải trong 72 giờ tính từ{" "} */}
            Thời gian xảy ra sự cố trong{" "}
            {/* {data?.paymentInfo?.paymentId === 1 ? "7 ngày" : "24h"} tính từ{" "} */}
            {moment(data.createdDate).add(1, "minute").format("HH:mm") +
              " " +
              moment(data.createdDate).format("DD/MM/YYYY") +
              " - " +
              moment(
                inforOrder?.endDate ? inforOrder?.endDate : data.createdDate
              )
                .add(-1, "minute")
                .format("HH:mm") +
              " " +
              moment(
                inforOrder?.endDate ? inforOrder?.endDate : data.createdDate
              ).format("DD/MM/YYYY")}
          </div>
        )}
        <div className="Describes">
          <span className="sapnDescribe">Mô tả diễn biến</span>
          <div
            className={classes.inputDiv}
            style={{ borderColor: errors.describe ? "red" : "" }}
          >
            <Input
              placeholder="Tường trình diễn biến sự việc."
              fullWidth
              onBlur={() => dispatch("checkError")}
              value={extraInfo?.describe || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChangeExtraInfo",
                  name: "describe",
                  value: e.target.value,
                })
              }
              multiline
              minRows={3}
            />
          </div>
          {errors.describe && (
            <div className={classes.error}>Vui lòng mô tả diễn biến</div>
          )}
        </div>
      </div>
      <div className={classes.declare}>
        <div className={classes.titleHead}>KHAI BÁO GIÁ TRỊ HÀNG HÓA</div>
        <div className={classes.inputDiv}>
          <Input
            placeholder="Nhập giá trị hàng hoá."
            fullWidth
            // type="number"
            value={
              extraInfo.productAmount
                ? `${extraInfo.productAmount}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "."
                  )
                : "0"
            }
            onChange={(e) => {
              if (
                !"0123456789".includes(e?.nativeEvent?.data) &&
                e?.nativeEvent?.data !== null
              ) {
                return;
              }

              dispatch({
                type: "onChangeExtraInfo",
                name: "productAmount",
                value: e?.target?.value,
              });
            }}
          />
          <div style={{ padding: "4px", fontWeight: "500" }}>.VNĐ</div>
        </div>
        {errors.codValue && data?.paymentInfo?.paymentId === 1 && (
          <div className={classes.error}>
            {"giá trị hàng hóa không vượt quá giá trị COD" +
              " " +
              `${data?.codValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
              "đ"}
          </div>
        )}
      </div>
      <div className={classes.declare}>
        <div className={classes.titleHead}>ĐỐI TƯỢNG BẢO HIỂM</div>
        <div className="insured">
          <RadioGroup
            aria-labelledby="declare"
            name="declare"
            value={extraInfo.insuranceObj}
            onChange={(e) =>
              dispatch({
                type: "onChangeExtraInfo",
                name: "insuranceObj",
                value: e?.target?.value,
              })
            }
          >
            {data?.paymentInfo?.paymentId === 1 ? (
              <FormControlLabel
                value={1}
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#E16C2B",
                      },
                    }}
                  />
                }
                label="Hàng hoá"
              />
            ) : (
              <>
                {" "}
                <FormControlLabel
                  value={2}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#E16C2B",
                        },
                      }}
                    />
                  }
                  label="Hàng hoá có hoá đơn"
                />
                <FormControlLabel
                  value={3}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#E16C2B",
                        },
                      }}
                    />
                  }
                  label="Hàng hoá không có hoá đơn"
                />
              </>
            )}
            <FormControlLabel
              value={4}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#E16C2B",
                    },
                  }}
                />
              }
              label="Tài liệu"
            />
          </RadioGroup>
        </div>
      </div>
      <div className={classes.declare}>
        <div className={classes.titleHead}>RỦI RO GẶP PHẢI</div>
        <div className="risk">
          <RadioGroup
            aria-labelledby="risk"
            name="risk"
            row
            value={extraInfo.typeOfAccident}
            onChange={(e) =>
              dispatch({
                type: "onChangeExtraInfo",
                name: "typeOfAccident",
                value: e?.target?.value,
              })
            }
          >
            <FormControlLabel
              value={1}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#E16C2B",
                    },
                  }}
                />
              }
              label="Hư hỏng"
            />
            <FormControlLabel
              value={2}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#E16C2B",
                    },
                  }}
                />
              }
              label="Mất mát"
            />
          </RadioGroup>
        </div>
      </div>
      <DatePicker
        value={extraInfo?.riskDate || null}
        open={picker.riskDate}
        min={moment(data?.createdDate || null).toDate() || null}
        max={maxDate}
        close={() => dispatch({ type: "setOpenPicker", name: "riskDate" })}
        onConfirm={(value) =>
          dispatch({ type: "confirmPicke", name: "riskDate", value })
        }
      />
      <TimePicker
        value={extraInfo?.riskTime || null}
        maxTime={maxTime}
        open={picker.riskTime}
        close={() => dispatch({ type: "setOpenPicker", name: "riskTime" })}
        onConfirm={(value) =>
          dispatch({ type: "confirmPicke", name: "riskTime", value })
        }
      />
    </div>
  );
}
