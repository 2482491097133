import { get, isString, set } from "lodash";

import {
  getConfigHealthInsurance,
  uploadImg,
  todoRemovedLinkS3,
  createRequestBLVP,
} from "../../../../apis/blvp_api";

export default function createGCNReducer(props) {
  const { state, action, dispatch, data, history } = props;

  const cases = {
    //

    initail: async () => {
      const { name } = action;
      const payloadDetail = { elements: [] };

      if (state?.payloadDetail?.elements?.length > 0 && name === "resetState") {
        const urls = [];
        state?.payloadDetail?.elements.forEach((i) => {
          i?.extraInfo?.documents?.forEach((u) => {
            if (u?.path) {
              urls.push(u.path);
            }
          });
        });
        urls.length > 0 && dispatch({ type: "todo", url: urls });
      }

      dispatch("onLoading");

      const dataApi = await getConfigHealthInsurance();

      const insuranceTypes = get(dataApi, "data.result") || [];
      const res = {};

      if (insuranceTypes.length > 0) {
        set(
          payloadDetail,
          "extraInfo.typeOfInsurance",
          get(insuranceTypes, "[0].id")
        );
        res.typeOfIndimnities =
          get(insuranceTypes, "[0].typeOfIndimnity") || [];
        set(
          payloadDetail,
          "extraInfo.typeOfIndimnity",
          get(res, "typeOfIndimnities[0].id") || ""
        );

        res.riskTypes = get(res, "typeOfIndimnities[0].typeOfRisk") || [];
        set(
          payloadDetail,
          "extraInfo.typeOfRisk",
          get(res, "riskTypes[0].id") || ""
        );
      }

      return { insuranceTypes, loading: false, payloadDetail, ...res };
    },

    onChangeTypeOfIndimnity: () => {
      const { name, value } = action;
      const { payloadDetail, insuranceTypes, typeOfIndimnities, errors } =
        state;
      const newError = errors.filter((i) => i !== name);

      if (name === "requestAmount") {
        payloadDetail.requestAmount = value;
        return { payloadDetail, errors: [...newError] };
      }

      set(payloadDetail, `extraInfo.${name}`, value);
      const res = {};
      if (name == "typeOfInsurance") {
        res.typeOfIndimnities = insuranceTypes.find(
          (i) => i.id === value
        )?.typeOfIndimnity;
        set(payloadDetail, `extraInfo.typeOfIndimnity`, null);
        res.riskTypes = [];
        set(payloadDetail, `extraInfo.typeOfRisk`, null);
      }
      if (name == "typeOfIndimnity") {
        res.riskTypes =
          typeOfIndimnities.find((i) => i.id === value)?.typeOfRisk || [];
        set(payloadDetail, `extraInfo.typeOfRisk`, null);
      }

      return { payloadDetail, ...res, errors: [...newError] };
    },

    upDataS3: async () => {
      const { e, item } = action;
      const { payloadDetail } = state;

      const el = document.getElementById(`input_${item.typeCode}`);
      const indexElement = (payloadDetail?.elements || []).findIndex(
        (i) => i.typeCode === item?.typeCode
      );
      const itemElement =
        indexElement < 0
          ? {
              extraInfo: { documents: [] },
              typeId: item.typeId,
              typeCode: item.typeCode,
            }
          : payloadDetail?.elements[indexElement];

      const files = e.target.files;
      if (files.length === 0) {
        el.value = "";
        return;
      }
      const res = {};
      const promises = [];

      for (let i = 0; i < files.length; i++) {
        const data = new FormData();
        data.append("document", files[i]);

        const api = uploadImg(data);

        promises.push(api);
      }
      dispatch("onLoading");

      const resultsApi = await Promise.all(promises);
      const temp = [];
      resultsApi.forEach((api, index) => {
        if (api?.data?.statusCode === 200) {
          const dataApi = get(api, "data.result[0]") || {};

          temp.push({
            path: dataApi?.path,
            size: files[index]?.size,
            original_name: files[index]?.name,
          });
        }
      });
      set(itemElement, "extraInfo.documents", [
        ...itemElement?.extraInfo?.documents,
        ...temp,
      ]);
      if (indexElement < 0) {
        payloadDetail?.elements?.push(itemElement);
      } else {
        set(payloadDetail, `elements[${indexElement}]`, { ...itemElement });
      }
      el.value = "";
      return { payloadDetail, loading: false };
    },

    showAttachedFiel_create: () => {
      const { item } = action;

      if (!item.path) {
        return {
          snackbar: {
            open: true,
            type: "error",
            message:
              "Xin lỗi, có thể link file đã gặp vấn đề. Xin vui lòng thử lại",
          },
        };
      }

      window.open(item.path, "_blank");
    },

    deleteAttachedFiel_create: () => {
      const { documents, element, indexElement } = action;
      const { payloadDetail } = state;

      if (element?.path) {
        dispatch({ type: "todo", url: element?.path });
      }

      const indexDocument = payloadDetail?.elements?.findIndex(
        (i) => i.typeCode === documents.typeCode
      );

      const newDocument =
        get(payloadDetail, `elements[${indexDocument}].extraInfo.documents`) ||
        [];
      newDocument?.splice(indexElement, 1);
      set(payloadDetail, `elements[${indexDocument}].extraInfo.documents`, [
        ...newDocument,
      ]);

      return { payloadDetail };
    },

    submitSentRequest: async () => {
      const { payloadDetail } = state;

      const errors = [];
      !payloadDetail?.requestAmount && errors.push("requestAmount");

      if (errors.length > 0) {
        return {
          errors,
          snackbar: {
            open: true,
            type: "error",
            message: "Vui lòng Nhập đầy đủ thông tin",
          },
        };
      }

      const params = {
        ...payloadDetail,
        ...data,
        featureCode: "BLVP",
      };

      dispatch("onLoading");
      const dataApi = await createRequestBLVP(params);

      if (dataApi?.data?.statusCode != 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi khi tạo, xin vui lòng thử lại",
          },
        };
      }

      if (dataApi?.data?.statusCode === 200) {
        history.push("/admin/blvp");
      }

      return { loading: false };
    },

    //

    closeSnackbar: () => ({ snackbar: { open: false } }),

    onLoading: () => ({ loading: true }),

    todo: () => {
      const { url } = action;
      if (Array.isArray(url)) {
        Promise.all(
          url.map((i) =>
            todoRemovedLinkS3({
              action_code: "document_delete",
              extra_info: {
                url: i,
              },
            })
          )
        );

        return;
      }

      if (url) {
        todoRemovedLinkS3({
          action_code: "document_delete",
          extra_info: {
            url: url,
          },
        });

        return;
      }
    },

    //
  };

  return isString(action.type) && cases[action.type];
}
