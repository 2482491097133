import { message } from "antd";
import { get, isEmpty } from "lodash";
import {
  createProduct,
  getBenefitCode,
  getDetail,
  updateProduct,
  uploadImage,
} from "../../../../apis/productApis";
import { types } from "../utils";

const checkLevel = (data) =>
  data.map((i) => {
    const regex = /\./g;
    if (!i.code_benefit.includes(".")) {
      return { ...i, isFirst: true };
    }
    if (i.code_benefit.match(regex).length === 1) {
      return { ...i, isSecond: true };
    }
    return i;
  });

const renderBenefitList = (data) =>
  data.map((i) => {
    const regex = /\./g;
    if (!i.codeBenefit.includes(".")) {
      return {
        ...i,
        code_benefit: i.codeBenefit,
        isFirst: true,
        checked: true,
      };
    }
    if (i.codeBenefit.match(regex).length === 1) {
      return {
        ...i,
        code_benefit: i.codeBenefit,
        isSecond: true,
        checked: true,
      };
    }
    return { ...i, code_benefit: i.codeBenefit, checked: true };
  });

const sorting = (data) => {
  data.sort((a, b) => {
    const levelsA = a.code_benefit.split(".");
    const levelsB = b.code_benefit.split(".");
    const minLength = Math.min(levelsA.length, levelsB.length);

    for (let i = 0; i < minLength; i++) {
      const levelA = levelsA[i];
      const levelB = levelsB[i];
      if (levelA !== levelB) {
        const numericA = parseInt(levelA, 10);
        const numericB = parseInt(levelB, 10);
        // Comparing the numeric part
        if (!isNaN(numericA) && !isNaN(numericB)) {
          return numericA - numericB;
        }
        // Comparing the alphabetic part
        return levelA.localeCompare(levelB);
      }
    }
    return levelsA.length - levelsB.length;
  });
  return data;
};

const detailReducer = ({
  initialState,
  state,
  action,
  dispatch,
  history,
  id,
}) => {
  const cases = {
    initial: async () => {
      const res = {};
      const p = {
        limit: 100,
        offset: 0,
      };

      dispatch("onLoading");
      const resCode = await getBenefitCode(p);
      if (resCode instanceof Error) {
        message.error("Lỗi lấy danh sách", 2);
        return { loading: false };
      }
      res.data = {
        packages: [],
        totalRows: get(resCode, "data.result.total") || 0,
      };
      const dataApi = get(resCode, "data.result.data") || [];
      const levelRows = checkLevel(dataApi);
      res.rows = sorting(levelRows);
      res.open = false;
      return { ...res, loading: false };
    },

    getDetail: async () => {
      const { data, rows } = state;

      dispatch("onLoading");
      const result = await getDetail(id);
      if (result instanceof Error) {
        message.error("Lỗi lấy chi tiết sản phẩm", 2);
        return { loading: false };
      }
      const dataApi = get(result, "data.result") || {};
      data.logo = dataApi?.urlLogo || "";
      data.type = types.find(
        (i) => i.insurance_code === dataApi?.insuranceCode
      );
      data.title = dataApi?.title || "";
      const benefitList = dataApi?.productParentBenefitList?.data || [];

      const packages = renderBenefitList(benefitList);
      data.packages = sorting(packages);

      const newRows = rows.map((item) => {
        const tmp = (data.packages || []).find(
          (i) => i.code_benefit === item.code_benefit
        );
        if (!isEmpty(tmp)) {
          return { ...item, checked: true };
        }
        return item;
      });

      return { data, rows: newRows, loading: false };
    },

    fetchData: async () => {
      const { rows, data } = state;
      const res = {};
      const offset = rows.length + 1;

      if (offset < data.totalRows) {
        dispatch("onScrolling");
        const p = {
          keyword: "",
          limit: 100,
          offset,
        };
        const result = await getBenefitCode(p);
        if (result instanceof Error) {
          message.error("Lỗi lấy danh sách", 2);
          return { scrolling: false };
        }
        const data = get(result, "data.result.data") || [];
        const newData = checkLevel(data);
        res.rows = [...rows, ...newData];
      }
      return { ...res, scrolling: false };
    },
    onSelect: () => {
      const { data } = state;
      data.type = action?.value || {};
      return { data };
    },
    onChange: () => {
      const { data } = state;
      data.title = action.value;
      return { data };
    },
    upLoadFile: async () => {
      const { e } = action;
      const { data } = state;

      if (e.target.files.length < 1) {
        return;
      }
      const file = e.target.files[0];
      const formdata = new FormData();
      formdata.append("document", file);

      dispatch("loadingImg");
      const dataApi = await uploadImage(formdata);
      const item = get(dataApi, "data.result[0]") || null;
      data.logo = item?.path || null;
      return { data, imgLoading: false };
    },
    onCheck: () => {
      const {
        data: { rowIdx },
        ele,
      } = action;
      const { rows } = state;
      rows[rowIdx].checked = ele.target.checked;
      return { rows };
    },
    addPakages: () => {
      const { rows, data } = state;
      const itemsChecked = rows.filter((i) => !i.isFirst && !!i?.checked);
      const newRows = rows.map((i) => {
        if (i.isFirst) {
          return { ...i, checked: false };
        }
        return i;
      });
      //find parent
      itemsChecked.forEach((row) => {
        const tmp = (row.code_benefit || "").split(".")[0];
        const parentIndex = newRows.findIndex(
          (i) => i.code_benefit === tmp && !!i.isFirst
        );
        newRows[parentIndex].checked = true;
      });

      data.packages = newRows.filter((i) => !!i?.checked);
      return { data, open: false };
    },
    openDialog: () => ({ open: true }),
    onClose: () => ({ open: false }),
    onSave: async () => {
      const { data } = state;
      dispatch("onLoading");
      const configParentBenefits = (data?.packages || []).map((i) => ({
        code_benefit: i.code_benefit,
        title: i.title,
      }));

      const p = {
        title: data?.title || null,
        insurance_code: data?.type?.insurance_code || null,
        url_logo: data?.logo || null,
        configParentBenefits,
      };

      const result = id
        ? await updateProduct({ id, ...p })
        : await createProduct(p);
      if (result instanceof Error) {
        message.error("Có lỗi xảy ra", 2);
        return { loading: false };
      }
      message.success(result?.data?.message || "Tạo sản phẩm thành công.!", 2);
      history.goBack();
      return { loading: false };
    },
    onLoading: () => ({ loading: true }),
    onScrolling: () => ({ scrolling: true }),
    loadingImg: () => ({ imgLoading: true }),
  };
  return cases[action?.type];
};
export default detailReducer;
