import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { UpIcon } from "../icon/icon";


export default function  Collapse({
  title = '',
  children = () => <></>
}) {
  const [open, setOpen] = useState(true);
  const [content, setContent] = useState(null);
  const [childHeight, setChildHeight] = useState(0);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (!content)
      return;

    setRender(true)
  }, [content]);

  useEffect(() => {
    if (!render)
      return;

    setChildHeight(`${+content.clientHeight / 1}rem`);
  }, [open, render]);

  return <>
    <div
      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 15, cursor: 'pointer' }}
      onClick={() => setOpen(!open)}
    >
      <Typography color='#2A3790' fontWeight={500} fontSize={20}>
        {title}
      </Typography>
      <UpIcon style={{ fontSize: 20, transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }} />
    </div>

    <div
      style={{
        maxHeight: open ? childHeight : 0,
        overflow: 'hidden',
        transition: '0.4s max-height'
      }}>
      <div
        ref={ref => { setContent(ref) }}
        style={{
          overflow: 'auto'
        }}
      >
        {
          children
        }
      </div>
    </div>
  </>
}