import React, { useEffect } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Pagination,
  Stack,
} from "@mui/material";

import { useHistory } from "react-router-dom";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

import useReducer from "../../../../library/hooks/useReducer";
import CommonTable from "../../../../library/layout/commonTable";
import { columns } from "../components/columnsCreateRequest";
import { TextField } from "../overrideMui/index";
import { createRequest } from "../styles/styleCreateRequest";
import { initialStateManagerBLVP } from "../util";
import managerBLVPReducer from "../reducers/managerBLVPReducer";

import searchIcon from "./../../../../assets/icons/search-icon.svg";
import { debounce } from "lodash";
import LoadingMui from "../../../../ui/LoadingMui";
import Snackbars from "../../../../ui/snackbar";

export default function CreateRequestBLVP() {
  const classes = createRequest();
  const history = useHistory();

  const [state, dispatch] = useReducer({
    initialState: initialStateManagerBLVP,
    reducer: managerBLVPReducer,
    name: "managerBLVPReducer",
  });

  const { listBVPK_createRequest, pageTable_createRequest, loading, snackbar } =
    state;

  let flag = false;

  useEffect(() => {
    dispatch("initail_createRequest");
  }, []);
  return (
    <div className={classes.container}>
      <Box className={classes.box_heder}>
        <div className="title">Tạo yêu cầu</div>
        <div className="box_search">
          <div className="label">
            Nhập CMND/ CCCD/ Hộ chiếu hoặc số thẻ BH của người được bào hiểm
          </div>
          <TextField
            // placeholder="Nhập tên sản phẩm..."
            className="text-field-search"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <img src={searchIcon} alt="search_icon" />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: false ? (
                <InputAdornment position="end">
                  <IconButton>
                    <ClearOutlinedIcon sx={{ width: "16px" }} />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch({
                  type: "search_create_request",
                  insuredPrivateId: e.target.value,
                });
                flag = true;
              }
            }}
            onChange={debounce((e) => {
              if (!flag) {
                dispatch({
                  type: "search_create_request",
                  insuredPrivateId: e.target.value,
                });
              }
              flag = false;
            }, 1500)}
          />
        </div>
      </Box>
      <div>
        <CommonTable
          columns={columns}
          rows={listBVPK_createRequest}
          classes={classes}
          data={{ history }}
          event={(type, data) =>
            (ele, ...rest) => {
              dispatch({ type, data, ele, rest });
            }}
        />
        <div className={classes.footer}>
          <div className="rows-per-page">{`Hiển thị ${
            pageTable_createRequest.total < 1
              ? 0
              : (pageTable_createRequest.page - 1 || 0) * 20 + 1
          }-${
            (pageTable_createRequest.page - 1 || 0) * 20 + 20 >
            pageTable_createRequest.total
              ? pageTable_createRequest.total
              : (pageTable_createRequest.page - 1 || 0) * 20 + 20
          } trong ${pageTable_createRequest.total || 0}`}</div>
          <Stack spacing={2}>
            <Pagination
              count={pageTable_createRequest?.count} // số trang
              page={pageTable_createRequest?.page} // đang đứng trang thứ
              onChange={(e, pageNumber) => {
                dispatch({ type: "onChangePage_createRequest", e, pageNumber });
              }}
              variant="outlined"
              color="primary"
            />
          </Stack>
        </div>
      </div>
      <LoadingMui loading={loading} />
      <Snackbars
        open={snackbar?.open}
        type={snackbar.type}
        message={snackbar?.message}
      />
    </div>
  );
}
