import { makeStyles } from "@mui/styles";

export const styledStep1 = makeStyles(() => ({
  boxLogo: {
    background: "white",
    width: "100%",
    padding: "12px",

    "& .history": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      textDecorationLine: "underline",
      fontSize: "15px",
      color: "#0044CC",
      fontWeight: "600",

      "& a": {
        color: "#0044CC",
      },
    },

    "& .logo": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginBottom: "12px",
    },

    "& .boxInfor": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "16px",
      textAlign: "center",
      fontWeight: "500",

      "& .line1": {
        color: "#284078",
        fontSize: "17px",
        fontWeight: "600",
      },

      "& .line2": {
        color: "#E16C2B",
      },

      "& a": {
        color: "#3F76DF",
        textDecorationLine: "underline",
        fontStyle: "italic",
      },
    },
  },

  boxOrder: {
    margin: "8px 0",
    padding: "16px",
    background: "white",

    "& .headTitle": {
      color: "#284078",
      fontSize: "16px",
      textAlign: "center",
      fontWeight: "600",
      marginBottom: "12px",
    },
  },
}));
