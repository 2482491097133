import React, { useEffect } from "react";
import { MenuItem } from "@mui/material";
import NumberFormatCustom from "../../../../../ui/fields/FormatCurrency";

import { styledLicense } from "../components/styeleds/styeledLicense";
import { SteperHeader, CategoryLicense, DialogShowImg } from "../components";
import { TextField } from "../overrideMui";
import { listTypeOfTreatments } from "../utils";
import _ from "lodash";
import FormReportedAccident from "./formReportAccident";

export default function License(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledLicense();
  const {
    listImgLicese,
    typeOfIndimnity,
    textLink,
    errors,
    curenStep,
    extraInfo,
    insuranceType,
    requestAmount,
    riskType,
  } = state;

  useEffect(async () => {
    window.scrollTo(0, 0);
  }, []);

  const newListTypeOfTreatments = listTypeOfTreatments.filter((item) => {
    switch (typeOfIndimnity.id) {
      case 4:
        return !!item.maternity;
      case 5:
        return !!item.cancer;
      case 6:
        return !!item.died;
    }
  });

  return (
    <div
      className={classes.container}
      style={{
        minHeight: isApp ? "calc(100vh - 175px)" : "calc(100vh - 133px)",
      }}
    >
      <SteperHeader activeStep={curenStep} insuranceType={insuranceType} />
      <div className={classes.title}>Hồ sơ bồi thường</div>
      <div style={{ marginBottom: "8px" }}>
        Loại bồi thường <span style={{ color: "red" }}>*</span>
      </div>
      <TextField
        fullWidth
        className={classes.disabledTextField}
        value={typeOfIndimnity?.title || ""}
        InputProps={{ readOnly: true }}
      />
      {insuranceType?.insuranceCode == "tai_nan" && (
        <>
          <div style={{ margin: "8px 0" }}>
            {"Loại rủi ro"}
            <span style={{ color: "red" }}>*</span>
          </div>
          <TextField
            fullWidth
            className={classes.disabledTextField}
            value={riskType?.title || ""}
            InputProps={{ readOnly: true }}
          />
        </>
      )}
      {[4, 5, 6].includes(typeOfIndimnity.id) &&
        insuranceType?.insuranceCode == "suc_khoe" && (
          <>
            <div style={{ margin: "8px 0px" }}>
              {typeOfIndimnity.id === 6 ? "Nguyên nhân" : "Loại điều trị"}{" "}
              <span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              select
              error={!!errors?.typeOfTreatment}
              helperText={
                errors.typeOfTreatment ? "Vui lòng chọn thông tin" : false
              }
              onChange={(e) =>
                dispatch({
                  type: "onchangeExtraInfo",
                  value: e.target.value,
                  name: "typeOfTreatment",
                })
              }
              className={classes.disabledTextField}
              value={extraInfo?.typeOfTreatment || ""}
            >
              {(newListTypeOfTreatments || []).map((i, index) => (
                <MenuItem key={index} value={i.value}>
                  {i.title}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      <div className={classes.title}>Chứng từ</div>
      {listImgLicese.map((item, index) => (
        <CategoryLicense
          key={index}
          error={!!errors[item.typeId]}
          title={item.title}
          nonMultiple={!!item?.nonMultiple}
          optional={!!item.optional}
          listImgs={item.documents}
          addImg={(e) => {
            dispatch({ type: "addImg", e, typeId: item.typeId, index });
            e.target.value = null;
          }}
          deleteImg={({ e, indexImg, url }) =>
            dispatch({
              type: "deleteImg",
              indexList: indexImg,
              typeId: item.typeId,
              index,
              url,
            })
          }
          showImg={(data) =>
            dispatch({
              type: "openShowImgDialogInline",
              data: { index: data.index, itemImg: data?.item || {} },
              documents: item?.documents || [],
            })
          }
        />
      ))}
      <div style={{ marginBottom: "4px" }}>
        {"Link hóa đơn điện tử (Nếu có)"}
      </div>
      <div
        style={{ marginBottom: "6px", fontStyle: "italic", fontSize: "12px" }}
      >
        {"( thêm dấu ',' nếu nhiều link )"}
      </div>
      <TextField
        multiline
        maxRows={4}
        fullWidth
        className={classes.linkInvoice}
        value={textLink}
        onChange={(e) => {
          dispatch({
            type: "onChangeLink",
            value: e.target.value,
            clear: e.nativeEvent.data === null,
          });
        }}
      />
      <div style={{ margin: "8px 0" }}>
        {"Nhập số tiền yêu cầu bồi thường"}
        <span style={{ color: "red" }}>*</span>
      </div>

      <TextField
        multiline
        maxRows={4}
        fullWidth
        placeholder="Nhập số tiền"
        className={classes.textFields}
        value={requestAmount || null}
        InputProps={{
          inputComponent: NumberFormatCustom,
          name: "myInput",
        }}
        onChange={(e) => {
          dispatch({
            type: "onChangeRequestAmount",
            value: e.target.value,
          });
        }}
      />
      <DialogShowImg {...{ state, dispatch }} />
      <FormReportedAccident {...{ state, dispatch }} />
    </div>
  );
}
