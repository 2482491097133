import { makeStyles } from "@mui/styles";

export const createGCNStyles = makeStyles(() => ({
  container: {
    width: "100%",
    padding: "0 12px",
    marginTop: "45px",
  },

  box_heder: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 0",
    alignItems: "center",

    "& .title-creat-product": {
      fontSize: "20px",
      fontWeight: "700",
    },

    "& .buttons-delete": {
      background: "white",
      color: "#4B4B4B",
      fontWeight: "500",
      border: "1px solid #DCDCDC",
      marginRight: "12px",
    },

    "& .buttons-save": {
      background: "#0B7CE4",
      color: "white",
      fontWeight: "500",
    },
  },

  box_type_gcn: {
    padding: "15px 20px",
    background: "white",
    border: "1px solid #ECECEC",
    borderRadius: "6px",
    width: "100%",

    "& .title": {
      color: "#4B4B4B",
      fontWeight: "700",
      fontSize: "17px",
      marginBottom: "15px",
    },

    "& .box-select": {
      display: "grid",
      marginBottom: "10px",
      maxWidth: "350px",
    },

    "& .label-select": {
      fontSize: "14px",
      marginBottom: "8px",
    },
  },

  box_buyer: {
    background: "white",
    padding: "15px 20px",
    border: "1px solid #ECECEC",
    borderRadius: "6px",
    width: "100%",
    marginBottom: "12px",

    "& .title": {
      color: "#4B4B4B",
      fontWeight: "700",
      fontSize: "17px",
      marginBottom: "15px",
    },

    "& .label": {
      color: "#4B4B4B",
      fontWeight: "500",
      margin: "6px 0",
      fontSize: "14px",
    },

    "& .infor-buyer": {
      display: "flex",
      justifyContent: "start",
      flexWrap: "wrap",
    },

    "& .div-column": {
      marginRight: "30px",
      minWidth: "250px",
      width: "47%",
      maxWidth: "350px",
    },
  },

  box_insurace: {
    background: "white",
    padding: "12px",
    border: "1px solid #ECECEC",
    borderRadius: "6px",
    width: "100%",
    marginBottom: "12px",

    "& .title": {
      color: "#4B4B4B",
      fontWeight: "700",
      fontSize: "17px",
      marginBottom: "18px",
    },

    "& .label": {
      color: "#4B4B4B",
      fontWeight: "500",
      marginBottom: "6px",
      fontSize: "14px",
    },

    "& .infor-buyer": {
      display: "flex",
      justifyContent: "start",
      flexWrap: "wrap",
    },

    "& .div-column": {
      marginRight: "30px",
      minWidth: "250px",
      width: "47%",
      maxWidth: "350px",
    },
  },

  box_table_beneficiary: {
    background: "white",
    padding: "20px 12px",
    border: "1px solid #ECECEC",
    borderRadius: "6px",
    width: "100%",
    marginBottom: "12px",

    "& .title": {
      color: "#4B4B4B",
      fontWeight: "700",
      fontSize: "17px",
      marginBottom: "18px",
      display: "flex",
      justifyContent: "space-between",
    },

    "& .buttons": {
      padding: "6px 12px",
      color: "#3961A0",
      fontSize: "14px",
      fontWeight: "400",
      border: "1px solid #DCDCDC",
    },

    "& .btn-add-user": {
      marginRight: "12px",
    },
  },

  div: {
    overflow: "auto",
    marginTop: "24px",
    paddingBottom: "0",
  },

  table: {
    minWidth: "1040px",
    paddingLeft: "12px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      fontWeight: "700",
      fontSize: "13px",
    },

    "& td": {
      fontSize: "13px",
    },

    "& .sticky": {
      position: "sticky",
    },

    "& .STT": {
      width: "45px",
      paddingLeft: "12px",
    },

    "& .status": {
      width: "55px",
    },

    "& .show-icon": {
      width: "55px",
      right: 0,
      background: "white",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "2px solid #C0C0C0",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #ECECEC",
    background: "white !important",
  },

  box_empty: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#B6B6B6",
    fontSize: "13px",
    height: "200px",
    background: "#F8F8F8",
    borderRadius: "8px",
    margin: "12px 0",
  },

  redTxt: {
    color: "red",
    fontSize: "12px",
    marginTop: "4px",
  },

  importDialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
    },
    "& .MuiDialogContent-root": {
      padding: "0 20px",
    },
    "& .MuiOutlinedInput-root": {
      height: 45,
    },
    "& .MuiDialogActions-root": {
      padding: "16px 20px",
    },
    "& .Mui-disabled": {
      background: "#CBD5E1 !important",
    },
    "& .upload": {
      textAlign: "center",
      background: "#ECECEC",
      color: "#686868",
      borderRadius: 16,
      padding: 20,
      position: "relative",
    },
    "& .logo": {
      marginBottom: 10,
    },
    "& .bold": {
      fontWeight: 600,
      color: "#4B4B4B",
    },
    "& .text-sm": {
      fontSize: 13,
      lineHeight: 2,
    },
    "& .txt": {
      margin: "10px 0 15px",
    },
    "& .empty": {
      textAlign: "center",
      padding: "60px 0",
    },
    "& .btn-upload": {
      background: "#3961A0",
      color: "#fff",
      padding: "8px 16px",
    },
    "& .btn-cancel": {
      background: "#fff",
      color: "#3961A0",
      padding: "8px 16px",
      border: "1px solid #3961A0",
    },
    "& .drag-active": {
      backgroundColor: "#F8FAFC",
      border: "1px dashed #CBD5E1",
      borderRadius: 16,
    },
    "& .drag-file-element": {
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: 16,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
}));
