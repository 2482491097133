import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import React from "react";
import { useStyles } from "../useStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddDialog(props) {
  const { dispatch, state } = props;
  const { open, data, typeCode, saving, errors, isInput } = state;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"sm"}
      onClose={() => dispatch("onClose")}
      className={classes.dialog}
    >
      <DialogTitle>Tạo thêm mã</DialogTitle>
      <DialogContent>
        <div className="title">Chọn loại mã</div>
        <Autocomplete
          freeSolo
          options={typeCode}
          noOptionsText={"Không tìm thấy dữ liệu"}
          getOptionLabel={(i) => `${i?.code_benefit || ""} ${i?.title || ""}`}
          renderInput={(params) => (
            <TextField {...params} placeholder="Nhập thông tin" size="small" />
          )}
          onInputChange={debounce(
            (event, value) =>
              dispatch({
                type: "getListCode",
                value,
              }),
            500
          )}
          onChange={(e, value) =>
            dispatch({
              type: "onSelect",
              name: "codeBenefitParent",
              value,
            })
          }
        />
        {errors.includes("codeParent") && (
          <div className="red-text">Vui lòng nhập đầy đủ thông tin.</div>
        )}
        <div className="title">Nhập mã mới</div>
        <TextField
          fullWidth
          placeholder="Nhập thông tin"
          value={(data?.codeBenefit || "").toUpperCase()}
          inputProps={{ pattern: "[A-Za-z]*" }}
          onChange={(e) =>
            dispatch({
              type: "changeCode",
              name: "codeBenefit",
              value: !data?.codeBenefitParent?.code_benefit
                ? e.target.value.replace(/[^a-zA-Z]/g, "")
                : e.target.value.replace(/[^0-9.]/g, ""), //only num and .
            })
          }
        />
        {errors.includes("code") && (
          <div className="red-text">Vui lòng nhập đầy đủ thông tin.</div>
        )}
        <div className="title">Tên mã nghiệp vụ</div>
        <TextField
          fullWidth
          placeholder="Nhập thông tin"
          value={data?.title || ""}
          onChange={(e) =>
            dispatch({
              type: "changeCode",
              name: "title",
              value: e.target.value,
            })
          }
        />
        {errors.includes("title") && (
          <div className="red-text">Vui lòng nhập đầy đủ thông tin.</div>
        )}
        <div className="title">Định nghĩa mã</div>
        <Autocomplete
          value={data?.codeRules || null}
          options={define}
          getOptionLabel={(i) => i.name}
          noOptionsText={"Không tìm thấy dữ liệu"}
          renderInput={(params) => (
            <TextField {...params} placeholder="Nhập thông tin" size="small" />
          )}
          onChange={(e, value) =>
            dispatch({
              type: "onSelect",
              name: "codeRules",
              value,
            })
          }
        />
        {errors.includes("codeRules") && (
          <div className="red-text">Vui lòng nhập đầy đủ thông tin.</div>
        )}
        <div className="center">
          <Button
            disabled={saving}
            className="button"
            onClick={() => dispatch("validate")}
          >
            {saving ? "Đang lưu.." : "Lưu lại"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const define = [
  { name: "Lần", value: "limit_times_over_year" },
  { name: "Năm", value: "limit_over_year" },
  { name: "Ngày", value: "limit_over_day" },
  { name: "Khác", value: "other" },
];
