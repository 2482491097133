import React, { useEffect } from "react";
import { Grid, TextField, Typography, MenuItem, Box } from "@mui/material";
import HeaderBar from "../../components/layout/HeaderBar";
import { useStyles } from "../styles";
import {
  convertCurrency,
  formatDate,
  expiredDate,
  formatFilterDate,
} from "../utils";
import { getOrderList } from "../../../../../../apis/claimApis";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const status = [
  { name: "Tất cả trạng thái", value: 0 },
  { name: "Hiệu lực", value: 1 },
  { name: "Hết hạn", value: 2 },
];
const date = ["Tất cả các ngày", "Chọn ngày"];
const today = new Date();

export default function VolatilityReport({ state, dispatch }) {
  const classes = useStyles();
  const { propsList, orderList, reportData, filter } = state;
  useEffect(async () => {
    dispatch("onLoading");
    const { data } = await getOrderList({ customerId: propsList.customerid });
    if (data.statusCode === 200) {
      dispatch("resetFilter");
      dispatch({
        type: "setOrderList",
        data: data?.result?.orders || [],
      });
    }
  }, []);

  const filterItems = async ({ e, name }) => {
    const value = ["fromDate", "toDate"].includes(name) ? e : e.target.value;
    const filterValue = ["fromDate", "toDate"].includes(name)
      ? formatFilterDate(name, value)
      : value;
    const params =
      name === "showDate"
        ? { ...filter, fromDate: null, toDate: null }
        : name === "type"
        ? {
            ...filter,
            type: value == 0 ? null : value,
          }
        : {
            ...filter,
            fromDate: formatFilterDate("fromDate", reportData?.fromDate),
            [name]: filterValue,
          };
    dispatch({
      type: ["fromDate", "toDate"].includes(name) ? "changeDate" : "onSelect",
      name,
      value,
    });
    if (
      !reportData.fromDate &&
      !reportData.toDate &&
      reportData.showDate === 1
    ) {
      return;
    }
    const { data } = await getOrderList({
      customerId: propsList.customerid,
      ...params,
    });

    if (data.statusCode === 200) {
      dispatch({
        type: "setOrderList",
        data: data?.result?.orders || [],
      });
    }
  };

  return (
    <>
      <HeaderBar
        title={"Xem báo cáo biến động"}
        onPress={() => dispatch("onBack")}
      />
      <div className={classes.wrapper}>
        <div className={classes.wrapperBox}>
          <Box className="box">
            <Typography className="total">Tổng quyền lợi bảo hiểm</Typography>
            <Typography className="money">
              {convertCurrency(`${propsList?.total_product_amount || 0}`)} VNĐ
            </Typography>
          </Box>
          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.gridBox}
          >
            <Grid item xs={6} xl={6} sm={6} md={6}>
              <TextField
                fullWidth
                select
                className={classes.txtField}
                value={reportData?.showDate}
                InputLabelProps={{ shrink: false }}
                onChange={(e) => filterItems({ e, name: "showDate" })}
              >
                {Object.keys(date).map((key) => (
                  <MenuItem key={key} value={key}>
                    {date[key]}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} xl={6} sm={6} md={6}>
              <TextField
                select
                fullWidth
                className={classes.txtField}
                value={reportData?.type !== undefined ? reportData.type : 0}
                InputLabelProps={{ shrink: false }}
                onChange={(e) => filterItems({ e, name: "type" })}
              >
                {status.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {!!reportData?.showDate && (
              <>
                <Grid item xs={6} xl={6} sm={6} md={6}>
                  <Typography className="dateTitle">Từ ngày</Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      showToolbar={false}
                      inputFormat="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      value={reportData?.fromDate || null}
                      onChange={(e) =>
                        dispatch({
                          type: "changeDate",
                          name: "fromDate",
                          value: e,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={classes.textfield}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} xl={6} sm={6} md={6}>
                  <Typography className="dateTitle">Đến ngày</Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      showToolbar={false}
                      inputFormat="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      value={reportData?.toDate || null}
                      onChange={(e) => filterItems({ e, name: "toDate" })}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={classes.textfield}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            )}
          </Grid>
        </div>

        {(orderList || []).map((item) => (
          <Box
            key={item.id}
            className={classes.volatilityBox}
            onClick={() => dispatch({ type: "nextPage", item })}
          >
            <Grid container className={classes.rowGrid}>
              <Grid item xs={6} xl={6} sm={6} md={6} className="row">
                <div
                  className={
                    expiredDate(today, new Date(item.end_date))
                      ? "expired"
                      : "effect"
                  }
                >
                  <Typography className="status">
                    {expiredDate(today, new Date(item.end_date))
                      ? "HẾT HẠN"
                      : "HIỆU LỰC"}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} xl={6} sm={6} md={6} className="row right">
                <Typography className="date">
                  Ngày góp - {formatDate(item.created_date)}
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.moneyTitle}>
              QUYỀN LỢI BẢO HIỂM
            </Typography>
            <Typography className="price">
              {convertCurrency(`${item?.product_amount || 0}`)} vnđ
            </Typography>
          </Box>
        ))}
      </div>
    </>
  );
}
