import { Pause } from "@mui/icons-material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Button, IconButton, Box, Slider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import picture from "../../../../../assets/ahamove/picture.svg";
import audio from "../assets/audio.svg";
import deleteIcon from "../assets/delete-icon.svg";
import download from "../assets/download.svg";
import play from "../assets/play-audio.svg";
import upload from "../assets/upload.svg";
import { useStyles } from "../styles/useStyles";
import file from "../assets/file.svg";

import { getFileByUrl } from "../../../../../apis/health";
import ShowFile from "./ShowFile";

const getExtension = (path) => {
  const extension = path?.split(".")?.pop();
  return extension;
};

const formatTime = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${formattedMinutes || "--"}:${formattedSeconds || "--"}`;
};

export default function CardDocument(props) {
  const classes = useStyles();
  const ref = useRef();
  const audioRef = useRef(null);

  const { state, dispatch, form } = props;
  const {
    row,
    statusId = 0,
    updated,
    documentsAdditionalImg,
    documentsAdditionalFile,
    documentAudio,
    loadAudio,
    showFileEvidence,
  } = state;

  const [audioState, setAudioState] = useState({});
  const isReportClaim = form === "report-claim";

  const onClickDiv = (e) => {
    ref.current?.click();
  };

  const handlePlay = async (path, index, item) => {
    if (audioRef.current) {
      audioRef?.current?.pause();
      dispatch({ type: "setPlayIcon", index, all: true });
    }

    if (path.includes(".amr")) {
      dispatch("cantPlayAudio");
      return;
    }

    if (path != audioState.path) {
      const dataFile = await getFileByUrl(path);
      const url = URL.createObjectURL(dataFile);
      const audio = new Audio(url);
      audio.controls = true;
      audioRef.current = audio;
      setAudioState({ ...audioState, path: path });
      dispatch({
        type: "setAudioPlay",
        index,
        progress: 0,
        currentTime: item.currentTime || 0,
        duration: item.duration || 0,
      });
    }

    dispatch({ type: "setPlayIcon", value: false, index });

    audioRef.current.addEventListener("timeupdate", () => {
      const currentTime = audioRef.current.currentTime;
      const duration = audioRef.current.duration;
      const calculatedProgress = (currentTime / duration) * 100;
      dispatch({
        type: "setAudioPlay",
        index,
        progress: Math.round(calculatedProgress),
        currentTime: currentTime,
        duration: duration,
      });
    });

    audioRef?.current?.play();
    audioRef.current.currentTime = item?.currentTime || 0;
    audioRef.current.onended = () => {
      dispatch({ type: "setPlayIcon", value: true, index });
      dispatch({
        index,
        type: "setAudioPlay",
        progress: 0,
        currentTime: 0,
      });
    };
  };

  const handlePause = (item, index, v = null) => {
    if (audioRef.current && item.path == audioState.path) {
      dispatch({ type: "setPlayIcon", value: true, index });
      audioRef?.current?.pause();
      const currentTime = (item?.duration * v) / 100;
      dispatch({
        type: "setAudioPlay",
        index,
        currentTime: Math.round(currentTime),
        progress: v,
      });
    }
  };

  useEffect(() => {
    return () => {
      // Clean up audio elements on component unmount
      audioRef?.current?.pause();
    };
  }, []);

  return (
    <div>
      <div className="headDocument">
        <span
          style={{
            fontWeight: "600",
            whiteSpace: "nowrap",
            marginRight: "12px",
          }}
        >
          Chứng từ
        </span>
        <span style={{ textAlign: "end" }}>
          <span style={{ color: "#000000" }}>Mã hồ sơ:</span>
          {" " + row?.code}
        </span>
      </div>
      <div style={{ width: "100%" }}>
        {row?.elements?.map((item, index) =>
          [200, 201, 202].includes(item?.type_id) ? (
            <div className={classes.boxImg} key={index}>
              <div className="title">
                {item?.type_id === 200
                  ? "Hình ảnh hàng hoá/tài liệu trước khi bị sự cố"
                  : item?.type_id === 201
                  ? "Hình ảnh hàng hoá/tài liệu sau khi bị sự cố"
                  : item?.type_id === 202
                  ? "Hình ảnh hoá đơn"
                  : ""}
                <span style={{ color: "red" }}>*</span>
              </div>
              <div className="div-img">
                {(Array.isArray(item?.extra_info?.documents)
                  ? item?.extra_info?.documents
                  : item?.documents || []
                ).map((i, indexImg) => (
                  <div
                    className="img"
                    onClick={() =>
                      dispatch({
                        type: "showDialog",
                        indexImg,
                        listImg: item,
                      })
                    }
                  >
                    <img src={i?.path} width="100%" height="100%" alt="imge" />
                  </div>
                ))}
              </div>
            </div>
          ) : null
        )}
        {(documentsAdditionalImg.length > 0 ||
          [4, 5, 6, 7].includes(statusId)) && (
          <div className={classes.boxImg} key="additionalImg">
            <div className="title">
              Hình ảnh bổ sung
              <span style={{ color: "red" }}>*</span>
            </div>
            <div className="div-img">
              {!isReportClaim &&
                [4, 5, 6, 7].includes(statusId) &&
                !updated && (
                  <div
                    className={classes.additional_img}
                    onClick={() => {
                      window.document.getElementById(`addImage`).click();
                    }}
                  >
                    <input
                      type="file"
                      hidden
                      id={`addImage`}
                      accept="image/*"
                      onChange={(e) => {
                        dispatch({ type: "additionalImg", e });
                        e.target.value = null;
                      }}
                    />
                    <img src={picture} alt="picture" />
                    <span className="spanPicture">Tải ảnh lên từ thiết bị</span>
                  </div>
                )}
              {documentsAdditionalImg?.map((itemIMG, indexImg) => (
                <div
                  // className="img"
                  className={
                    !isReportClaim &&
                    [4, 5, 6, 7].includes(statusId) &&
                    !updated
                      ? classes.additional_img
                      : "img"
                  }
                >
                  <img
                    onClick={() =>
                      dispatch({
                        type: "showDialog",
                        indexImg,
                        listImg: documentsAdditionalImg,
                        name: "additionalImg",
                      })
                    }
                    src={itemIMG.path || ""}
                    width="100%"
                    height="100%"
                    alt="imge"
                  />
                  {!isReportClaim &&
                    [4, 5, 6, 7].includes(statusId) &&
                    !updated && (
                      <CancelOutlinedIcon
                        className="icon"
                        fontSize="small"
                        style={{ zIndex: 11 }}
                        onClick={() =>
                          dispatch({
                            type: "deleteAdditionalImg",
                            indexImg,
                            itemIMG,
                          })
                        }
                      />
                    )}
                </div>
              ))}
            </div>
          </div>
        )}
        {[4, 5, 6, 7].includes(statusId) && (
          <div className={classes.boxLicense}>
            <div className="title">File ghi âm đính kèm</div>
            <div className="box-audio">
              <input
                ref={ref}
                hidden
                type="file"
                id="audio"
                accept="audio/*"
                onChange={(e) => {
                  dispatch({ type: "addAudio", e });
                  e.target.value = null;
                }}
              />
              {!isReportClaim && !updated && (
                <div className="row bg-gray">
                  <Button
                    disabled={loadAudio}
                    className="button"
                    onClick={onClickDiv}
                  >
                    Chọn file
                  </Button>
                  {loadAudio && (
                    <div className="fs-14">
                      <img src={upload} alt="upload" /> Đang tải lên...
                    </div>
                  )}
                </div>
              )}
              <div className="p-10">
                {documentAudio.map((item, index) => (
                  <div className="border">
                    <div className="row">
                      <div className="row">
                        <img src={audio} alt="audio" className="icon" />
                        <div>
                          <div
                            title={item?.originalname || ""}
                            style={{
                              maxWidth: "165px",
                              whiteSpace: " nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item?.originalname || ""}
                          </div>
                          <div className="size">{item?.size || ""}</div>
                        </div>
                      </div>

                      <div className="row">
                        <IconButton color="primary">
                          {item?.playAudio ? (
                            <img
                              src={play}
                              alt="play"
                              onClick={() => handlePlay(item.path, index, item)}
                            />
                          ) : (
                            <Pause
                              sx={{ mt: "2px" }}
                              onClick={() => handlePause(item, index)}
                            />
                          )}
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={() =>
                            dispatch({ type: "dowloadAudio", index })
                          }
                        >
                          <img src={download} alt="down" />
                        </IconButton>
                        {!isReportClaim && !updated && (
                          <IconButton
                            color="primary"
                            onClick={() =>
                              dispatch({
                                type: "deleteAudio",
                                index,
                                url: item.path,
                              })
                            }
                          >
                            <img src={deleteIcon} alt="del" />
                          </IconButton>
                        )}
                      </div>
                    </div>
                    <Box sx={{ width: "95%" }}>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "end",
                          fontSize: "13px",
                          fontStyle: "italic",
                        }}
                      >{`${formatTime(
                        Math.round(item?.currentTime)
                      )} / ${formatTime(Math.round(item?.duration))}`}</div>
                      <Slider
                        size="small"
                        value={Math.round(item?.progress || 0)}
                        aria-label="Small"
                        onChange={(e, v, a) => {
                          if (item.path != audioState.path && item?.duration) {
                            const currentTime = (item?.duration * v) / 100;
                            dispatch({
                              type: "setAudioPlay",
                              index,
                              currentTime: Math.round(currentTime),
                              progress: v,
                            });
                          } else {
                            handlePause(item, index, v);
                          }
                        }}
                      />
                    </Box>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {[4, 5, 6, 7].includes(statusId) && (
          <div className={classes.boxImg}>
            <div className="title">File tài liệu</div>
            <div className="box-audio">
              <input
                type="file"
                hidden
                id={`addFile-addition`}
                accept={"application/pdf,.ppt, .pptx"}
                onChange={(e) => {
                  dispatch({
                    type: "additionalFile",
                    name: "file",
                    e,
                  });
                }}
              />
              {!isReportClaim && !updated && (
                <div className="row bg-gray">
                  <Button
                    className="button"
                    onClick={() => {
                      window.document
                        .getElementById(`addFile-addition`)
                        .click();
                    }}
                  >
                    Chọn file
                  </Button>
                </div>
              )}
              {(documentsAdditionalFile || []).map((item, idx) => (
                <div className="border" key={idx}>
                  <AcceptFile
                    item={item}
                    index={idx}
                    hidenDeleteBtn={updated || isReportClaim}
                    dispatch={dispatch}
                    name="additionalFile"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <ShowFile
        open={!!showFileEvidence?.open}
        link={showFileEvidence?.link || ""}
        onClose={() => dispatch("closeShowEvidence")}
      />
    </div>
  );
}

const AcceptFile = ({ item, index, hidenDeleteBtn, dispatch, name }) => (
  <div className="row">
    <div
      className="row cursor"
      onClick={() => {
        getExtension(item?.path) !== "pdf"
          ? dispatch({ type: "showEvidenceImg", file: item })
          : dispatch({ type: "showEvidence", file: item });
      }}
    >
      <img
        src={file}
        alt="file"
        style={{ marginRight: "6px", width: "28px" }}
      />
      <div>
        <div>{item?.originalname || ""}</div>
        <div className="size">{item?.size || ""}</div>
      </div>
    </div>
    <div className="row">
      <IconButton
        color="primary"
        onClick={() =>
          dispatch({
            type: "downloadFile",
            path: item?.path,
            name: item?.originalname,
          })
        }
      >
        <img src={download} alt="download" />
      </IconButton>
      {!hidenDeleteBtn && (
        <IconButton
          color="primary"
          onClick={() =>
            dispatch({
              type: "deleteFile",
              index,
              url: item.path,
              name,
            })
          }
        >
          <img src={deleteIcon} alt="del" />
        </IconButton>
      )}
    </div>
  </div>
);
