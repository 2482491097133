import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { message } from "antd";

import LoginLayout from "../login/login-layout";
import { sendOTPToEmail_v2 } from "../../../../apis/authApis";
import iconPhone from "../../../../assets/icons/iconPhone.svg";

const ForgotPassword = () => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      const dataApi = await sendOTPToEmail_v2({ email: values.email });

      if (dataApi?.data?.statusCode === 200) {
        history.push("change-password-otp", { email: values.email });
      } else {
        createFail(dataApi);
      }
    },
  });

  const createFail = async (error) => {
    formik.setSubmitting(false);
    message.error({
      content: error.message,
      duration: 5,
    });
  };

  const embededFiNa = localStorage.getItem("embeded") == "fina.claim.admin";

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexFlow: "column",
          flexGrow: 1,
          minHeight: "100%",
          background: "#FAFAFA",
        }}
      >
        <Container maxWidth="sm" sx={{ marginTop: "auto" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <Typography fontWeight="fontWeightBold" variant="h4">
                Quên mật khẩu
              </Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
              <Typography>
                Chúng tôi sẽ gửi mã OTP qua email mà bạn đã đăng ký dưới đây.
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
              focused
            />
            <Box sx={{ py: 2, mt: 7 }}>
              <Button
                color="gc"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Tiếp tục
              </Button>
            </Box>
          </form>
        </Container>
        <Box textAlign="center" mt="auto" mx="auto" pb={3}>
          <Typography sx={{ color: "#1E6BE5" }} mb={1}>
            <img src={iconPhone} />
            Hotline: {embededFiNa ? "(024) 6276 5555" : "1900 2929 87"}
          </Typography>
          {!embededFiNa && (
            <Typography>Một sản phẩm công nghệ của Global Care</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

const ForgotPasswordPage = () => (
  <LoginLayout>
    <ForgotPassword />
  </LoginLayout>
);

export default ForgotPasswordPage;
