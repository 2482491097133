import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import { styleDocumentDetail } from "../../styles/styleDocumentDetail";
import { Button } from "@mui/material";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "14px", color: "white" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#3961A0",
  color: "white",
  flexDirection: "row-reverse",
  padding: "0 8px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: "8px 0 8px  8px",

    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: "600",
    },
  },
}));

const Accordions = ({ expanded, onChange, title, children }) => {
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ background: "#F8F8F8", padding: "8px" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default function AccordionsDetailDocument(props) {
  const classes = styleDocumentDetail();
  const { state, dispatch } = props;

  const { expandAccordions, typeIdLiceseButton, detail, listTypeIdLicese } =
    state;

  // const listTypeIdLicese = [
  //   {
  //     title: "Hình chụp CMND/ CCCD/ Hộ chiếu mặt trước và sau",
  //     typeId: 500,
  //     typeCode: "private_id",
  //     nonMultiple: true,
  //     typeOfIndimnity: [
  //       { id: 1 },
  //       { id: 2 },
  //       { id: 3 },
  //       { id: 4, typeOfRisk: [1, 2, 3, 4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 6, typeOfRisk: [12, 13, 14] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //   },
  //   {
  //     title: "Biên bản tường trình",
  //     typeId: 537,
  //     typeCode: "report",
  //     typeOfIndimnity: [
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //     optional: [
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //   },
  //   {
  //     title: "Tóm tắt bệnh án, báo cáo y tế, phiếu chỉ định khám",
  //     typeId: 531,
  //     typeCode: "summary_report_health",
  //     typeOfIndimnity: [
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [1, 4] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //     optional: [
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [4] },
  //       { id: 7, typeOfRisk: [7, 8] },
  //       { id: 9, typeOfRisk: [7, 8] },
  //     ],
  //   },
  //   {
  //     title: "Hồ sơ bệnh án",
  //     typeId: 502,
  //     typeCode: "medical_records",
  //     typeOfIndimnity: [
  //       { id: 1 },
  //       { id: 3 },
  //       { id: 4, typeOfRisk: [2, 3, 4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 6, typeOfRisk: [12, 13, 14] },
  //     ],
  //     optional: [
  //       { id: 1 },
  //       { id: 4, typeOfRisk: [2, 3, 4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 6, typeOfRisk: [12, 13, 14] },
  //     ],
  //   },
  //   {
  //     title: "Chỉ định xét nghiệm/ chụp XQ...và kết quả",
  //     typeId: 504,
  //     typeCode: "test_xq_and_result",
  //     typeOfIndimnity: [
  //       { id: 2 },
  //       { id: 3 },
  //       { id: 4, typeOfRisk: [1, 4] },
  //       { id: 5, typeOfRisk: [5] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //     optional: [
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [1, 4] },
  //       { id: 5, typeOfRisk: [5] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //   },
  //   {
  //     title: "Toa thuốc",
  //     typeId: 507,
  //     typeCode: "prescription",
  //     typeOfIndimnity: [
  //       { id: 1 },
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [1, 2, 3, 4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //     optional: [
  //       { id: 1 },
  //       { id: 4, typeOfRisk: [1, 2, 3, 4] },
  //       { id: 5, typeOfRisk: [5] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 8, typeOfRisk: [7] },
  //     ],
  //   },
  //   {
  //     title: "Phiếu thu chi phí khám",
  //     typeId: 525,
  //     typeCode: "examination_receipt",
  //     typeOfIndimnity: [
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //     optional: [
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //   },
  //   {
  //     title: "Phiếu thu chi phí thuốc",
  //     typeId: 524,
  //     typeCode: "prescription_receipt",
  //     typeOfIndimnity: [
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [1, 3, 4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //     optional: [
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [1, 3, 4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //   },
  //   {
  //     title: "Bảng kê chi phí điều trị",
  //     typeId: 506,
  //     typeCode: "treatment_cost_checkout",
  //     optional: true,
  //     typeOfIndimnity: [
  //       { id: 1 },
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [2, 3, 4] },
  //       { id: 5, typeOfRisk: [6] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 8, typeOfRisk: [8, 9, 10, 11] },
  //     ],
  //     optional: [
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [4] },
  //       { id: 5, typeOfRisk: [6] },
  //       { id: 8, typeOfRisk: [8, 9, 10, 11] },
  //     ],
  //   },
  //   {
  //     title: "Hóa đơn thuốc",
  //     typeId: 509,
  //     typeCode: "medicine_bill",
  //     typeOfIndimnity: [
  //       { id: 1 },
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [1, 2, 3, 4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //     optional: [
  //       { id: 1 },
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [1, 2, 3, 4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //   },
  //   {
  //     title: "Hóa đơn chi phí khám",
  //     typeId: 508,
  //     typeCode: "examination_cost_bill",
  //     typeOfIndimnity: [
  //       { id: 1 },
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [1, 2, 3, 4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //     optional: [
  //       { id: 1 },
  //       { id: 2 },
  //       { id: 4, typeOfRisk: [1, 2, 3, 4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //   },
  //   {
  //     title: "Giấy chứng nhận phẫu thuật (nếu có)",
  //     typeId: 503,
  //     typeCode: "surgery_cert",
  //     typeOfIndimnity: [
  //       { id: 1 },
  //       { id: 3 },
  //       { id: 4, typeOfRisk: [3] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //     optional: [
  //       { id: 1 },
  //       { id: 3 },
  //       { id: 4, typeOfRisk: [3] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //   },
  //   {
  //     title: "Kết qủa xét nghiệm và mô bệnh học",
  //     typeId: 513,
  //     typeCode: "test_result_and_histopathology",
  //     typeOfIndimnity: [
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 6, typeOfRisk: [14] },
  //     ],
  //     optional: [
  //       { id: 5, typeOfRisk: [6] },
  //       { id: 6, typeOfRisk: [14] },
  //     ],
  //   },
  //   {
  //     title: "Kết quả chẩn đoán hình ảnh, tế bào học",
  //     typeId: 514,
  //     typeCode: "diagnose_image_cytology",
  //     typeOfIndimnity: [
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 6, typeOfRisk: [14] },
  //     ],
  //     optional: [
  //       { id: 5, typeOfRisk: [6] },
  //       { id: 6, typeOfRisk: [14] },
  //     ],
  //   },
  //   {
  //     title: "Giấy ra viện",
  //     typeId: 510,
  //     typeCode: "hospital_checkout",
  //     typeOfIndimnity: [
  //       { id: 1 },
  //       { id: 3 },
  //       { id: 4, typeOfRisk: [2, 3] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //     optional: [{ id: 3 }, { id: 5, typeOfRisk: [5, 6] }],
  //   },
  //   {
  //     title: "Chi phí tái khám sau xuất viện, đơn thuốc",
  //     typeId: 511,
  //     typeCode: "re-examination_cost",
  //     typeOfIndimnity: [{ id: 4, typeOfRisk: [2, 3] }],
  //     optional: [{ id: 4, typeOfRisk: [2, 3] }],
  //   },
  //   {
  //     title: "Hóa đơn tái khám sau xuất viện, đơn thuốc",
  //     typeId: 512,
  //     typeCode: "re-examination_bill",
  //     typeOfIndimnity: [{ id: 4, typeOfRisk: [2, 3, 4] }],
  //     optional: [{ id: 4, typeOfRisk: [2, 3, 4] }],
  //   },
  //   {
  //     title: "Giấy chứng tử/ trích lục khai tử",
  //     typeId: 515,
  //     typeCode: "death_cert",
  //     typeOfIndimnity: [
  //       { id: 6, typeOfRisk: [12, 13, 14] },
  //       { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
  //     ],
  //   },
  //   {
  //     title: "Phiếu chích ngừa/ chỉ định chích ngừa/ sổ theo dõi chích ngừa",
  //     typeId: 528,
  //     typeCode: "paper_suggestion_book_tracking_of_vaccine",
  //     typeOfIndimnity: [{ id: 8, typeOfRisk: [7] }],
  //   },
  //   {
  //     title:
  //       "Tường trình về tình huống và nguyên nhân tử vong của người được bảo hiểm",
  //     typeId: 527,
  //     typeCode: "situation_&_cause_of_death",
  //     typeOfIndimnity: [{ id: 6, typeOfRisk: [12, 13, 14] }],
  //     optional: [{ id: 6, typeOfRisk: [12, 13, 14] }],
  //   },
  //   {
  //     title: "Giấy chứng nhận thương tật của cơ quan có thẩm quyền",
  //     typeId: 532,
  //     typeCode: "certificate_of_injury",
  //     typeOfIndimnity: [{ id: 3 }, { id: 9, typeOfRisk: [7, 8, 9, 10, 11] }],
  //   },
  //   {
  //     title:
  //       "Xác nhận của chính quyền đại phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận nguyên nhân",
  //     typeId: 533,
  //     typeCode: "certificate_of_reason",
  //     typeOfIndimnity: [{ id: 9, typeOfRisk: [7, 8, 9, 10, 11] }],
  //   },
  //   {
  //     title:
  //       "Biên bản công an điều tra có kết luận nguyên nhân tử vong/ biên bản tai nạn có xác nhận của chính quyền đại phương hoặc cơ quan chủ quản. Bảo cáo y tế/ hồ sơ bệnh án của bệnh viện có kết luận nguyên nhân tử vong",
  //     typeId: 534,
  //     typeCode: "death_investigation_report_from_police",
  //     typeOfIndimnity: [{ id: 10, typeOfRisk: [7, 8, 9, 10, 11] }],
  //   },
  //   {
  //     title:
  //       "Biên bản phân chia di sản thừa kế có xác nhận của cơ quan có thẩm quyền",
  //     typeId: 535,
  //     typeCode: "division_of_inheritance",
  //     typeOfIndimnity: [{ id: 10, typeOfRisk: [7, 8, 9, 10, 11] }],
  //   },
  //   {
  //     title:
  //       "Giấy phép lái xe nếu tai nạn khi đang khi đang điều khiển xe trên 50 cc",
  //     typeId: 536,
  //     typeCode: "driving_licence",
  //     typeOfIndimnity: [
  //       { id: 7, typeOfRisk: [11] },
  //       { id: 8, typeOfRisk: [11] },
  //       { id: 9, typeOfRisk: [11] },
  //       { id: 10, typeOfRisk: [11] },
  //     ],
  //     optional: [
  //       { id: 7, typeOfRisk: [11] },
  //       { id: 8, typeOfRisk: [11] },
  //       { id: 9, typeOfRisk: [11] },
  //       { id: 10, typeOfRisk: [11] },
  //     ],
  //   },
  //   {
  //     title: "Khác",
  //     typeId: 523,
  //     typeCode: "other",
  //     typeOfIndimnity: [
  //       { id: 1 },
  //       { id: 2 },
  //       { id: 3 },
  //       { id: 4, typeOfRisk: [1, 2, 3, 4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 6, typeOfRisk: [12, 13, 14] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10] },
  //       { id: 9, typeOfRisk: [7, 8, 9, 10] },
  //       { id: 10, typeOfRisk: [7, 8, 9, 10] },
  //     ],
  //     optional: [
  //       { id: 1 },
  //       { id: 2 },
  //       { id: 3 },
  //       { id: 4, typeOfRisk: [1, 2, 3, 4] },
  //       { id: 5, typeOfRisk: [5, 6] },
  //       { id: 6, typeOfRisk: [12, 13, 14] },
  //       { id: 7, typeOfRisk: [7, 8, 9, 10] },
  //       { id: 8, typeOfRisk: [7, 8, 9, 10] },
  //       { id: 9, typeOfRisk: [7, 8, 9, 10] },
  //       { id: 10, typeOfRisk: [7, 8, 9, 10] },
  //     ],
  //   },
  // ];

  return (
    <div>
      <Accordions
        key="persionalLicensse"
        title="Chứng từ cá nhân"
        onChange={() =>
          dispatch({ type: "onChangExpand", name: "persionalLicensse" })
        }
        expanded={Boolean(expandAccordions.persionalLicensse)}
      >
        {listTypeIdLicese
          .filter((i) => [500, 537, 517].includes(i.typeId))
          ?.map((i) => (
            <Button
              className={classes.divButton}
              key={`license_button_${i.typeId}`}
              style={{
                background: typeIdLiceseButton === i.typeId ? "#E3F3FF" : "",
                color: i.additionalRequest ? "red" : "",
              }}
              // disabled={
              //   (
              //     (detail?.elements || []).find(
              //       (el) => el.type_code === i.typeCode
              //     )?.extra_info?.documents || []
              //   )?.length == 0
              // }
              onClick={() =>
                dispatch({
                  type: "onClickLicense",
                  item: i,
                })
              }
            >
              {i.title}
            </Button>
          ))}
      </Accordions>
      <Accordions
        key="hospitalLicensse"
        title="Chứng từ bệnh viện"
        onChange={() =>
          dispatch({ type: "onChangExpand", name: "hospitalLicensse" })
        }
        expanded={Boolean(expandAccordions.hospitalLicensse)}
      >
        {listTypeIdLicese
          .filter(
            (i) =>
              ![500, 517, 523, 532, 533, 534, 535, 536, 537].includes(i.typeId)
          )
          .map((i) => (
            <Button
              className={classes.divButton}
              key={i.title}
              style={{
                background: typeIdLiceseButton === i.typeId ? "#E3F3FF" : "",
                color: i.additionalRequest ? "red" : "",
              }}
              onClick={() =>
                dispatch({
                  type: "onClickLicense",
                  item: i,
                })
              }
              // disabled={
              //   (
              //     (detail?.elements || []).find(
              //       (el) => el.type_code === i.typeCode
              //     )?.extra_info?.documents || []
              //   )?.length == 0
              // }
            >
              {i.title}
            </Button>
          ))}
      </Accordions>
      <Accordions
        key="policeLicensse"
        title="Chứng từ cơ quan chức năng"
        onChange={() =>
          dispatch({ type: "onChangExpand", name: "policeLicensse" })
        }
        expanded={Boolean(expandAccordions.policeLicensse)}
      >
        {listTypeIdLicese
          .filter((i) => [523, 532, 533, 534, 535, 536].includes(i.typeId))
          ?.map((i) => (
            <Button
              className={classes.divButton}
              key={`license_button_${i.typeCode}`}
              style={{
                background: typeIdLiceseButton === i.typeId ? "#E3F3FF" : "",
                color: i.additionalRequest ? "red" : "",
              }}
              // disabled={
              //   (
              //     (detail?.elements || []).find(
              //       (el) => el.type_code === i.typeCode
              //     )?.extra_info?.documents || []
              //   )?.length == 0
              // }
              onClick={() =>
                dispatch({
                  type: "onClickLicense",
                  item: i,
                })
              }
            >
              {i.title}
            </Button>
          ))}
      </Accordions>
    </div>
  );
}
