import api from "../configs/api";

const getProviders = (data) =>
  api
    .apiForm("POST", "/api/v2/provider/list", data)
    .then((res) => res)
    .catch((error) => error);

const getDetail = (id) =>
  api
    .apiForm("GET", `/api/v2/provider/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

const createProvider = (data) =>
  api
    .apiForm("POST", "/api/v2/provider", data)
    .then((res) => res)
    .catch((error) => error);

const updateProvider = (data) =>
  api
    .apiForm("PUT", "/api/v2/provider", data)
    .then((res) => res)
    .catch((error) => error);

const addProduct = (data) =>
  api
    .apiForm("POST", "/api/v2/product", data)
    .then((res) => res)
    .catch((error) => error);

const getProductParentList = (data) =>
  api
    .apiForm("POST", "/api/v2/product-parent/list", data)
    .then((res) => res)
    .catch((error) => error);

const getProductDetail = (id) =>
  api
    .apiForm("GET", `/api/v2/product/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

const createProductPackage = (data) =>
  api
    .apiForm("POST", "/api/v2/product-package", data)
    .then((res) => res)
    .catch((error) => error);

const detailProductPackage = (id) =>
  api
    .apiForm("GET", `/api/v2/product-package/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

const updateProductPackage = (data) =>
  api
    .apiForm("PUT", "/api/v2/product-package", data)
    .then((res) => res)
    .catch((error) => error);

const getPackageBenefit = (data) =>
  api
    .apiForm("POST", "/api/v2/product-package-benefit/list", data)
    .then((res) => res)
    .catch((error) => error);

const detailProductParent = (productIdParent) =>
  api
    .apiForm("GET", `/api/v2/product-parent/${productIdParent}/detail`)
    .then((res) => res)
    .catch((error) => error);

const uploadImage = (data) => {
  return api.apiForm("POST", "/api/v2/document/upload/cdn", data);
};
export {
  getProviders,
  getDetail,
  createProvider,
  updateProvider,
  addProduct,
  getProductParentList,
  getProductDetail,
  createProductPackage,
  detailProductPackage,
  updateProductPackage,
  uploadImage,
  getPackageBenefit,
  detailProductParent,
};
