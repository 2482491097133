import React, { useEffect } from "react";
import { Avatar, IconButton, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { deepOrange } from "@mui/material/colors";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import useReducer from "../../../../library/hooks/useReducer";
import { detailProduct } from "../styles/styleDetailProduct";
import { initialStateProductDetail } from "../util";
import detailProductReducer from "../reducers/detailProductReducer";
import editIcon from "../../../../assets/health/edit.svg";

import ProductPackage from "../components/productPackage";
import InfoProductPackage from "../components/infoProductPackage";
import { useHistory, useParams } from "react-router-dom";
import LoadingMui from "../../../../ui/LoadingMui";
import Snackbars from "../../../../ui/snackbar";
import { get } from "lodash";
import moment from "moment";

export default function DetailProduct() {
  const classes = detailProduct();
  const history = useHistory();
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("user")) || {};

  const [state, dispatch] = useReducer({
    initialState: initialStateProductDetail,
    reducer: detailProductReducer,
    name: "detailProductReducer",
    productId: id,
  });

  const { loading, snackbar, details } = state;

  useEffect(() => {
    dispatch("initialProduct");
  }, [id]);

  const maxWidth800 = useMediaQuery("(max-width:800px)");

  return (
    <div className={classes.container}>
      <div className={classes.header_box}>
        <Grid container>
          <Grid xs={5} sm={5} md={3}>
            <div>
              <div style={{ padding: "8px" }}>
                <Avatar
                  sx={{ bgcolor: deepOrange[500] }}
                  alt="Remy Sharp"
                  src={
                    details.url_logo ? details.url_logo : "/broken-image.jpg"
                  }
                >
                  {details?.title?.charAt(0).toUpperCase() || "P"}
                </Avatar>
              </div>
              <div className={classes.box_logo}>
                <span className="name-insurance">{details.title || ""}</span>
                <FiberManualRecordIcon
                  fontSize="small"
                  sx={{
                    color: details?.status === 1 ? "#069D27" : "#A0A0A0",
                    width: "10px",
                  }}
                />
                {user?.role_management_claim?.privileges?.PRODUCT_MANAGE ===
                  1 && (
                  <IconButton
                    sx={{ marginLeft: "8px" }}
                    onClick={() => {
                      history.push(
                        `/admin/product-management/create/${details.id}`
                      );
                    }}
                  >
                    <img src={editIcon} />
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
          <Grid xs={7} sm={7} md={9}>
            <Grid container spacing={maxWidth800 ? 3 : 4} columns={12}>
              <Grid
                className={classes.box_grid_item}
                xs={10}
                sm={6}
                md={3.5}
                lg={3.5}
                xl={3.5}
              >
                <div className="justyfy-between">
                  <span className="label">Loại bảo hiểm</span>
                  <span className="value">{"BH con người"}</span>
                </div>
                <div className="justyfy-between">
                  <span className="label">Tên sản phẩm</span>
                  <span className="value">{details?.title || ""}</span>
                </div>
                <div className="justyfy-between">
                  <span className="label">Mã sản phẩm</span>
                  <span className="value">{details?.product_id_parent}</span>
                </div>
              </Grid>
              <Grid
                className={classes.box_grid_item}
                xs={10}
                sm={6}
                md={5}
                lg={5}
                xl={5}
              >
                <div className="justyfy-between">
                  <span className="label">Thuộc công ty</span>
                  <span className="value">{details.provider_title || ""}</span>
                </div>
                <div className="justyfy-between">
                  <span className="label">Loại HĐ bảo hiểm</span>
                  <span className="value">
                    {get(details, "extra_info.typeOfInsuranceContract") === 1 ||
                    get(details, "extra_info.benefit_type") ===
                      "quyen_loi_rieng"
                      ? "QL riêng"
                      : get(details, "extra_info.benefit_type") ===
                          "quyen_loi_chung" ||
                        get(details, "extra_info.typeOfInsuranceContract") === 2
                      ? "QL chung"
                      : ""}
                  </span>
                </div>
                <div className="justyfy-between">
                  <span className="label">Ngày tạo</span>
                  <span className="value">
                    {moment(details.created_date || null).format("DD/MM/YYYY")}
                  </span>
                </div>
              </Grid>
              <Grid
                className={classes.box_grid_item}
                xs={10}
                sm={6.5}
                md={3.5}
                lg={3.5}
                xl={3.5}
              >
                <div className="justyfy-between">
                  <span className="label">DS CSYT loại trừ</span>
                  <span
                    className="value blue"
                    onClick={() =>
                      dispatch({
                        type: "openAttachedFile",
                        name: "terms_and_conditions",
                      })
                    }
                  >
                    Xem
                  </span>
                </div>
                <div className="justyfy-between">
                  <span className="label">Điều kiện, điều khoản</span>
                  <span
                    className="value blue"
                    onClick={() =>
                      dispatch({
                        type: "openAttachedFile",
                        name: "list_clinic_hospital_linked",
                      })
                    }
                  >
                    Xem
                  </span>
                </div>
                <div className="justyfy-between">
                  <span className="label">DS BV PK liên kết</span>
                  <span
                    className="value blue"
                    onClick={() =>
                      dispatch({
                        type: "openAttachedFile",
                        name: "list_clinic_hospital_exclude",
                      })
                    }
                  >
                    Xem
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid container sx={{ marginTop: "18px" }}>
        <Grid xs={5} sm={5} md={3.5}>
          <ProductPackage {...{ state, dispatch, id }} />
        </Grid>
        <Grid xs={7} sm={7} md={8.5}>
          <InfoProductPackage {...{ state, dispatch }} />
        </Grid>
      </Grid>
      <LoadingMui loading={Boolean(loading)} />
      <Snackbars
        open={Boolean(snackbar.open)}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => dispatch("onCloseSnackbar")}
      />
    </div>
  );
}
