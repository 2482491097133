import React, { useEffect, useRef } from "react";
import _ from "lodash";
import { FormControlLabel, Radio } from "@mui/material";

import { Checkbox } from "../overrideMui";
import { styledChooseInsu } from "../components/styeleds/styledChooseInsu";
import { BoxPackage } from "../components";

export default function ChooseInsurance(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledChooseInsu();
  const { listInsurances, checkAll } = state;

  const ref = useRef();

  useEffect(() => {
    dispatch({ type: "getListPackeInsurance" });
  }, []);

  const onScroll = () => {
    if (ref.current) {
      const scrollDiv = ref.current;

      const lastItem = scrollDiv.querySelector(
        "#divScroll .scroll-item:last-child"
      );
      const { offsetTop, offsetHeight } = lastItem;
      if (
        Math.abs(offsetTop - scrollDiv.scrollTop) <
        offsetHeight + scrollDiv.offsetHeight
      ) {
        dispatch("getItems");
      }
    }
  };

  return (
    <div
      id="divScroll"
      className={classes.container}
      ref={ref}
      style={{
        height: isApp ? "calc(100vh - 175px)" : "calc(100vh - 133px)",
        overflowY: "auto",
      }}
      onScroll={_.debounce(onScroll, 700)}
    >
      <>
        <div className="title-head" style={{ marginBottom: "8px" }}>
          Vui lòng chọn gói bảo hiểm để yêu cầu bồi thường
        </div>
        {listInsurances.map((item, index) => (
          <div
            className={`scroll-item ${classes.packageInsurance}`}
            key={index}
          >
            <Radio
              key={index}
              sx={{ marginRight: "8px", padding: 0 }}
              checked={!!item?.isCheck}
              onChange={(e) =>
                dispatch({
                  type: "oncheckInsurancePackage",
                  value: !item.isCheck,
                  index,
                })
              }
            />
            <BoxPackage
              data={item}
              onClick={(e) =>
                dispatch({
                  type: "oncheckInsurancePackage",
                  value: !item.isCheck,
                  index,
                })
              }
            />
          </div>
        ))}
      </>
    </div>
  );
}
