import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useReducer from "../../../../library/hooks/useReducer";
import CommonTable from "../../../../library/layout/commonTable";
import AddProvider from "../components/AddProvider";
import { columns } from "../components/columns";
import managementReducer from "../useReducer/managementReducer";
import { useStyles } from "../useStyles";

import AddIcon from "../assets/add-icon.svg";
import SearchIcon from "../assets/search-icon.svg";

const initialState = {
  rows: [],
  errors: [],
  data: {},
  open: false,
  loading: false,
  imgLoading: false,
  saving: false,
  pageTable: {
    count: 0,
    page: 0,
    rowsPerPage: 20,
  },
  totalRows: 0,
};

function ProviderManagement() {
  const history = useHistory();
  const classes = useStyles();

  const [state, dispatch] = useReducer({
    reducer: managementReducer,
    name: "managementReducer",
    initialState,
    history,
  });

  useEffect(() => {
    dispatch("getList");
  }, []);

  const { loading, rows, open, pageTable } = state;
  return (
    <div className={classes.container}>
      <div className="header">Quản lý công ty bảo hiểm</div>
      <div className="row search">
        <div className="wrapper">
          <TextField
            fullWidth
            placeholder="Tìm kiếm cty bảo hiểm"
            className="w-80"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="search-ic" />
                </InputAdornment>
              ),
            }}
            onChange={debounce(
              (e) =>
                dispatch({
                  type: "getList",
                  value: e.target.value,
                }),
              800
            )}
          />
          {loading && <CircularProgress size={25} sx={{ color: "#3961A0" }} />}
        </div>
        <Button
          className="button"
          onClick={() => dispatch("openDialog")}
          startIcon={<img src={AddIcon} alt="add-ic" />}
        >
          Thêm cty bảo hiểm
        </Button>
      </div>
      <CommonTable
        classes={classes}
        rows={rows || []}
        columns={columns}
        onClickRow={(data) => dispatch({ type: "changePage", data })}
        page={{
          ...pageTable,
          onChange: (event, page, rowsPerPage) => {
            dispatch({
              type: "onPaging",
              page,
              rowsPerPage,
            });
          },
        }}
        event={(type, data) =>
          (ele, ...rest) => {
            dispatch({ type, data, ele, rest });
          }}
      />
      <AddProvider open={open} state={state} dispatch={dispatch} />
    </div>
  );
}
export default ProviderManagement;
