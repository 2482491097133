import React, { useCallback } from "react";
import { DatePicker, Space } from "antd-mobile";
import moment from "moment";
import "./style.css";

const RenderDatePicker = ({
  open = false,
  min = undefined,
  max = undefined,
  outputFormat = null,
  close,
  onConfirm,
  value,
}) => {
  const now = value ? new Date(value) : new Date();
  const currentDate = new Date();
  // moment(value).format(outputFormat || "DD/MM/YYYY")
  const labelRenderer = useCallback((type, data) => {
    switch (type) {
      case "month":
        return "tháng " + data;
      default:
        return data;
    }
  }, []);
  return (
    <Space align="center">
      <DatePicker
        className="datePicker-overide"
        min={min || new Date(currentDate.getFullYear() - 100, 0, 1)}
        max={max}
        visible={open}
        cancelText="Quay lại"
        confirmText="Chọn"
        renderLabel={labelRenderer}
        onClose={() => close()}
        onConfirm={(value) => {
          onConfirm(value);
        }}
        value={now}
      ></DatePicker>
    </Space>
  );
};

export default RenderDatePicker;
