import { message } from "antd";
import { cloneDeep, get, isEmpty } from "lodash";
import { SSF, read, utils } from "xlsx";
import {
  addContract,
  addContractByExcel,
  detailProductMerchant,
  getContractList,
} from "../../../../apis/merchantApis";
import * as PATH from "../../../../configs/routesConfig";
import { convertCurrency } from "../utils";
import moment from "moment";

const parseExcelDate = (excelDate) => {
  if (!excelDate) {
    return null;
  }
  const dateValue = SSF.parse_date_code(excelDate);
  const date = new Date(dateValue.y, dateValue.m - 1, dateValue.d);
  return date;
};

const renderStt = (end) => {
  const today = moment().format("YYYY/MM/DD");
  const endDate = moment(end).format("YYYY/MM/DD");
  const diffValue = moment(endDate).diff(today, "day");
  if (diffValue < 0) {
    return 3; //het
  }
  if (diffValue > -1 && diffValue < 31) {
    return 2; //sap
  }
  if (diffValue > 30) {
    return 1; //dang
  }
  return null;
};

const packageReducer = ({
  initialState,
  state,
  action,
  dispatch,
  history,
  merchantId,
  id,
}) => {
  const cases = {
    getDetailProductMerchant: async () => {
      dispatch("onLoading");
      const res = {};
      const p = {
        productId: id,
        merchantId: merchantId,
      };
      const result = await detailProductMerchant(p);
      if (result instanceof Error) {
        message.error(
          result.response?.data?.message || "Lỗi lấy chi tiết sản phẩm",
          2
        );
        return { loading: false };
      }

      const dataApi = get(result, "data.result") || {};
      res.data = cloneDeep(dataApi);
      res.productPackageList = get(dataApi, "productPackageList.data") || [];
      res.selectPackage = get(dataApi, "productPackageList.data[0]") || {};

      res.total_product_amount = res.productPackageList.reduce(
        (total, item) => {
          return total + +item.productAmount;
        },
        0
      );
      const feeArr = res.productPackageList
        .map((i) => +i.productAmount)
        .sort((a, b) => a - b);
      res.rangeFees = isEmpty(feeArr)
        ? 0
        : feeArr.length === 1
        ? convertCurrency(feeArr[0])
        : `${convertCurrency(feeArr[0])} - ${convertCurrency(
            feeArr[feeArr.length - 1]
          )}`;

      res.open = { import: false, user: false, benefit: false };
      if (res.selectPackage?.id) {
        dispatch({
          type: "getParticipantList",
          packageId: res.selectPackage?.id,
          data: res.data,
        });
      }
      return { ...res, loading: false };
    },

    getParticipantList: async () => {
      const { selectPackage, data } = state;
      dispatch("onLoadingTable");
      const p = {
        keyword: action.value || "",
        productPackageId: action.packageId || selectPackage.id,
        merchantId: action?.data?.merchantId || data?.merchantId,
        limit: 100,
        offset: 0,
      };
      const result = await getContractList(p);
      if (result instanceof Error) {
        message.error(
          result.response?.data?.message || "Lỗi lấy chi tiết sản phẩm",
          2
        );
        return { loadingTable: false };
      }
      const participantList = get(result, "data.result.data") || {};
      return { participantList, loadingTable: false };
    },

    getParticipantNum: async () => {
      dispatch("onLoadingTable");
      const p = {
        productId: id,
        merchantId: merchantId,
      };
      const result = await detailProductMerchant(p);
      if (result instanceof Error) {
        message.error(
          result.response?.data?.message || "Lỗi lấy chi tiết sản phẩm",
          2
        );
        return { loadingTable: false };
      }
      const productPackageList =
        get(result, "data.result.productPackageList.data") || [];
      return { productPackageList, loadingTable: false };
    },
    changeTab: () => {
      const { open, productPackageList } = state;
      const { value } = action;
      if (value === "add") {
        return { open: { ...open, benefit: true } };
      }
      const selectPackage = productPackageList.find(
        (item) => item.id === value
      );
      if (selectPackage.id) {
        dispatch({ type: "getParticipantList", packageId: selectPackage.id });
      }
      return { selectPackage };
    },
    onChange: () => {
      const { dataInfo } = state;
      const { name, value } = action;
      dataInfo[name] = value;
      return { dataInfo };
    },
    onSelect: async () => {
      const { dataInfo } = state;
      const { name, value } = action;
      dataInfo[name] = value || {};
      return { dataInfo };
    },
    addBenefit: async () => {},

    validate: () => {
      const { dataInfo } = state;
      const errors = [];
      const keys = [
        "insuredName",
        "insuredDob",
        "insuredPrivateId",
        "certNum",
        "beginDate",
        "endDate",
      ];
      keys.forEach((i) => {
        !dataInfo[i] && errors.push(i);
      });
      if (errors.length === 0) {
        dispatch("addUser");
      }
      return { errors };
    },

    addUser: async () => {
      const { data, dataInfo, selectPackage, open } = state;

      const p = {
        beginDate: dataInfo?.beginDate || null,
        endDate: dataInfo?.endDate || null,
        insuredPhone: dataInfo?.insuredPhone || null,
        insuredName: dataInfo?.insuredName || null,
        insuredDob: dataInfo?.insuredDob || null,
        insuredPrivateId: dataInfo?.insuredPrivateId || null,
        insuredEmail: dataInfo?.insuredEmail || null,
        insuredType: dataInfo?.insuredType || null,
        insuredGender: dataInfo?.insuredGender || null,
        insuredAddress: dataInfo?.insuredAddress || null,
        productIdParent: +data?.productIdParent || null,
        providerId: +data?.providerId || null,
        productId: +data?.id || null,
        productPackageId: +selectPackage?.id || null,
        merchantId: +merchantId || null,
      };

      dispatch("onSaving");
      const result = await addContract(p);
      if (result instanceof Error) {
        message.error(
          result.response?.data?.message || "Lỗi thêm thông tin khách hàng",
          2
        );
        return { saving: false };
      }
      message.success("Thêm thông tin khách hàng thành công", 2);
      open.user = false;
      dispatch("getParticipantList");
      dispatch("getParticipantNum");
      return { open, errors: [], saving: false };
    },

    onOpen: () => {
      const { open } = state;
      open[action.name] = true;
      return { open };
    },
    onClose: () => {
      const { open } = state;
      open[action.name] = false;
      if (action.name === "import") {
        return { importList: [], dragActive: false, open };
      }
      return { errors: [], open };
    },
    onLoading: () => ({ loading: true }),
    onLoadingTable: () => ({ loadingTable: true }),
    onUploading: () => ({ uploading: true }),
    onSaving: () => ({ saving: true }),

    export: () => {
      const { count, participantList } = state;
      if (participantList.length === 0) {
        return message.error("Không thể xuất file", 2);
      }
      return { count: count + 1 };
    },

    handleDrag: () => {
      const { e } = action;
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        return { dragActive: true };
      } else if (e.type === "dragleave") {
        return { dragActive: false };
      }
    },
    handleDrop: () => {
      const { e } = action;
      e.preventDefault();
      e.stopPropagation();
      dispatch("inActive");
      if (e.dataTransfer.files.length < 1) {
        return;
      }
      if (e.dataTransfer.files.length > 1) {
        return message.error("Tải lên từng file.", 3);
      }
      const file = e.dataTransfer.files[0];
      const extension = file?.name.split(".")[1];

      if (!["xls", "xlsx"].includes(extension)) {
        return message.error("Chỉ hỗ trợ tải file excel.", 3);
      }

      dispatch("onUploading");
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, { type: "binary" });

        const sheets = wb.SheetNames;
        if (sheets.length) {
          const data = utils.sheet_to_json(wb.Sheets["data"]);

          const rows = data.slice(1); //skip header
          if (rows.length > 1) {
            const formattedData = rows.map((row) => ({
              insuredName: row["HỌ VÀ TÊN"],
              certNum: row["Số thẻ BH"],
              insuredDob: parseExcelDate(row["Ngày sinh"]),
              insuredPrivateId: row["CMND/CCCD"],
              insuredPhone: row[""],
              insuredType: row["NHÂN VIÊN/ NGƯỜI THÂN"],
              beginDate: parseExcelDate(row["NGÀY THAM GIA BH ĐẦU TIÊN"]),
              endDate: parseExcelDate(row["__EMPTY"]),
              status: renderStt(parseExcelDate(row["__EMPTY"])),
            }));
            dispatch({ type: "setList", formattedData });
          }
        }
      };
      reader.readAsArrayBuffer(file);

      return { file, uploading: false };
    },
    handleChange: () => {
      const { e } = action;
      e.preventDefault();
      if (e.target.files.length < 1) {
        return;
      }
      const file = e.target.files[0];
      dispatch("onUploading");

      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, { type: "binary" });

        const sheets = wb.SheetNames;
        if (sheets.length) {
          const data = utils.sheet_to_json(wb.Sheets["data"]);

          const rows = data.slice(1); //skip header
          if (rows.length > 1) {
            const formattedData = rows.map((row) => ({
              insuredName: row["HỌ VÀ TÊN"],
              certNum: row["Số thẻ BH"],
              insuredDob: parseExcelDate(row["Ngày sinh"]),
              insuredPrivateId: row["CMND/CCCD"],
              insuredPhone: row[""],
              insuredType: row["NHÂN VIÊN/ NGƯỜI THÂN"],
              beginDate: parseExcelDate(row["NGÀY THAM GIA BH ĐẦU TIÊN"]),
              endDate: parseExcelDate(row["__EMPTY"]),
              status: renderStt(parseExcelDate(row["__EMPTY"])),
            }));
            dispatch({ type: "setList", formattedData });
          }
        }
      };
      reader.readAsArrayBuffer(file);

      return { file, uploading: false };
    },

    setList: () => ({ importList: action.formattedData }),

    importExcel: async () => {
      const { data, file, open, selectPackage } = state;
      const formData = new FormData();
      formData.append("document", file);
      formData.append("productIdParent", +data?.productIdParent || null);
      formData.append("providerId", +data?.providerId || null);
      formData.append("productId", +data?.productId || null);
      formData.append("merchantId", +merchantId || null);
      formData.append("productPackageId", +selectPackage?.id || null);

      dispatch("onSaving");
      const result = await addContractByExcel(formData);
      if (result instanceof Error) {
        message.error(result.response?.data?.message || "Lỗi upload file", 2);
        return { saving: false };
      }
      message.success(result.data?.message || "Thêm thành công", 2);
      open.import = false;
      dispatch("getParticipantList");
      dispatch("getParticipantNum");
      return { saving: false, open, importList: [] };
    },

    inActive: () => ({ dragActive: false }),

    changePage: () => {
      const { data, rangeFees, total_product_amount, selectPackage } = state;
      const { item } = action.data;
      localStorage.setItem(
        "product-info",
        JSON.stringify({
          providerTitle: data?.providerTitle || null,
          urlLogo: data?.urlLogo || null,
          productId: data?.productId || null,
          title: data?.title || null,
          providerTitle: data?.providerTitle || null,
          insuranceCode: data?.insuranceCode || null,
          createdDate: data?.createdDate || null,
          rangeFees,
          total_product_amount,
        })
      );

      history.push(
        `${PATH.AD_MERCHANT_CONTRACT}?packageId=${selectPackage.id}&id=${item.id}`
      );
    },
  };
  return cases[action?.type];
};
export default packageReducer;
