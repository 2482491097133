import TableToExcel from "@linways/table-to-excel";
import {
  ControlPoint as ControlPointIcon,
  FilterList as FilterListIcon,
  VerticalAlignBottom as VerticalAlignBottomIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Pagination,
  Stack,
  Popover,
} from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import _, { debounce } from "lodash";
import useReducer from "../../../../library/hooks/useReducer";
import CommonTable from "../../../../library/layout/commonTable";
import ExportFileGcn from "../components/ExportFileGcn";
import { Loading } from "../components/Loading";
import { columns } from "../components/columnsGCNManager";
import PopoverFilter from "../components/popoverFilter";
import { TextField } from "../overrideMui/index";
import gcnManagerReducer from "../reducers/gcnManagerReducer";
import { GCNManager } from "../styles/styleGCNManager";
import { initialStateGCNManager } from "../util";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import editIcon from "../../../../assets/health/edit.svg";
import searchIcon from "./../../../../assets/icons/search-icon.svg";
import CircleIcon from "@mui/icons-material/Circle";
// import { DatePicker } from "@mui/x-date-pickers";
import { DatePicker } from "antd";
import moment from "moment";

export default function ManagerGCN() {
  const classes = GCNManager();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user")) || {};

  const [state, dispatch] = useReducer({
    initialState: initialStateGCNManager,
    reducer: gcnManagerReducer,
    name: "gcnManagerReducer",
  });

  let flag = false;

  const {
    selectType,
    list,
    pageTable,
    total,
    dataExport,
    count,
    loading,
    popverEditTabelGCN,
    filter,
  } = state;

  useEffect(async () => {
    if (count !== 0) {
      const table = document.getElementById("export-data-gcn");
      const name = "File quản lý GCN.xlsx";
      TableToExcel.convert(table, {
        name,
        sheet: {
          name: "Sheet 1",
        },
      });
    }
  }, [count]);

  useEffect(() => {
    dispatch("initial");
  }, []);

  return (
    <div className={classes.container}>
      <Box className={classes.box_heder}>
        <div className="title-decentraliza">Quản lý GCN</div>
        {user?.role_management_claim?.privileges?.CERT_MANAGE === 1 && (
          <div>
            <Button
              className="buttons-add-decentraliza"
              onClick={() => history.push(`/admin/gcn/create`)}
            >
              <ControlPointIcon fontSize="small" className="icon-button" />
              Thêm GCN
            </Button>
          </div>
        )}
      </Box>
      <Box className={classes.box_button}>
        <div className="search-box">
          <TextField
            select
            value={"BH con người"}
            className="text-field-selecter"
          >
            {selectType.map((select, index) => (
              <MenuItem key={select.name} value={select.name}>
                {select.name}
              </MenuItem>
            ))}
          </TextField>
          <span>Chọn ngày</span>
          <DatePicker
            // value={moment()}
            value={filter?.from ? moment(filter?.from) : null}
            onChange={(e) =>
              dispatch({
                type: "onFilter",
                name: "from",
                value: e?.format("YYYY-MM-DD") || null,
              })
            }
            allowClear={true}
            style={{
              ...Styles.input,
            }}
            placeholder="Từ Ngày"
            format="DD/MM/YYYY"
          />

          <span>{" - "}</span>
          <DatePicker
            // value={dataSearch[i.name] ? moment(dataSearch[i.name]) : null}
            value={filter?.to ? moment(filter?.to) : null}
            onChange={(e) =>
              dispatch({
                type: "onFilter",
                name: "to",
                value: e?.format("YYYY-MM-DD") || null,
              })
            }
            allowClear={true}
            style={{
              ...Styles.input,
              paddingTop: 7,
              paddingBottom: 7,
            }}
            placeholder="Đến Ngày"
            format="DD/MM/YYYY"
          />

          <TextField
            placeholder="Tìm kiếm..."
            className="text-field-search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchIcon} alt="search_icon" />
                </InputAdornment>
              ),
            }}
            onChange={debounce((e) => {
              if (!flag) {
                dispatch({ type: "onPaging", dataSearch: e.target.value });
              }
              flag = false;
            }, 1800)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                dispatch({ type: "onPaging", dataSearch: e.target.value });
                flag = true;
              }
            }}
          />
        </div>
        <div className="div-buttons">
          {user?.role_management_claim?.privileges?.CERT_EXPORT === 1 && (
            <Button
              className="button dowload"
              onClick={() => dispatch("onSubmitExport")}
            >
              <VerticalAlignBottomIcon
                fontSize="small"
                sx={{ marginRight: "8px" }}
              />
              Xuất file
            </Button>
          )}
          <Button className="button " onClick={() => dispatch("openFilter")}>
            <FilterListIcon fontSize="small" sx={{ marginRight: "8px" }} />
            Bộ lọc
          </Button>
        </div>
      </Box>
      <div style={{ position: "relative" }}>
        <CommonTable
          columns={columns}
          rows={list}
          classes={classes}
          data={{ history }}
          empty={"Chưa có thông tin"}
          event={(type, data) =>
            (ele, ...rest) => {
              dispatch({ type, data, ele, rest });
            }}
        />
        {!_.isEmpty(list) && (
          <div className={classes.footer}>
            <div className="rows-per-page">{`Hiển thị ${
              (pageTable.pageNumber - 1 || 0) * 20 + 1
            }-${
              (pageTable.pageNumber - 1 || 0) * 20 + 20 > total
                ? total
                : (pageTable.pageNumber - 1 || 0) * 20 + 20
            } trong ${total}`}</div>
            <Stack spacing={2}>
              <Pagination
                count={pageTable.count}
                page={pageTable.pageNumber}
                onChange={(e, value) =>
                  dispatch({ type: "onPaging", e, value })
                }
                variant="outlined"
                color="primary"
              />
            </Stack>
          </div>
        )}
      </div>
      <PopoverFilter {...{ state, dispatch }} />
      <ExportFileGcn data={dataExport} />
      <Popover
        id={"popover-edit-tabel-GCN"}
        open={Boolean(popverEditTabelGCN?.open)}
        anchorEl={popverEditTabelGCN?.ref || null}
        onClose={() => {
          dispatch("closePopverEditTableGCN");
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: 6 }}>
          <div className={classes.divButton}>
            <Button
              className={classes.buttons}
              onClick={() =>
                history.push(
                  `/admin/gcn/detail?id=${popverEditTabelGCN?.item?.id}`
                )
              }
            >
              <img src={editIcon} style={{ marginRight: "8px" }} />
              Chỉnh sửa
            </Button>
          </div>
          <div className={classes.divButton}>
            <Button
              className={classes.buttons}
              onClick={() => {
                dispatch("changeStatus");
              }}
            >
              {popverEditTabelGCN?.item?.status ? (
                <DeleteOutlineOutlinedIcon
                  sx={{ marginRight: "8px", fontSize: "18px" }}
                />
              ) : (
                <CircleIcon
                  fontSize="small"
                  sx={{
                    marginRight: "8px",
                    fontSize: "16px",
                    color: "#069D27",
                  }}
                />
              )}
              {popverEditTabelGCN?.item?.status
                ? "Ngưng hoạt động"
                : "Kích hoạt"}
            </Button>
          </div>
        </div>
      </Popover>
      <Loading loading={loading} />
    </div>
  );
}
const Styles = {
  input: {
    fontSize: 16,
    boxShadow: "none",
    borderRadius: 16,
    width: "100%",
    border: "1px solid #A0A0A0",
    maxWidth: "130px",
    margin: "0 6px",
    padding: "7px 11px",
  },
};
