import React, { useEffect } from "react";
import {} from "@mui/material";

import { styledShowLicense } from "../components/styeleds/styledShowLicense";
import img from "../../../../../assets/health/img-icon.svg";
import imgDisabel from "../../../../../assets/health/img-icon-disabel.svg";
import { DialogShowImg } from "../components";

export default function ShowLicense(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledShowLicense();
  const { listImgLicese, nameShowImgPage, arrayLinks, dataPrivateId } = state;

  const imgPrivateId = {
    title: "Hình chụp CMND/ CCCD/ Hộ chiếu mặt trước và sau",
    typeId: 500,
    typeCode: "private_id",
    documents: (dataPrivateId?.extraInfo?.documents || []).filter(
      (i) => !!i.path
    ),
  };

  const newList =
    nameShowImgPage === "img" ? [imgPrivateId, ...listImgLicese] : arrayLinks;

  return (
    <div
      className={classes.container}
      style={{
        minHeight: isApp ? "calc(100vh - 175px)" : "calc(100vh - 133px)",
      }}
    >
      {nameShowImgPage === "img" && (
        <div className={classes.title}>Chứng từ</div>
      )}
      <div className={classes.boxListImg}>
        {newList.map((item, index) =>
          nameShowImgPage === "img" ? (
            <div
              className={classes.proveRelationship}
              key={index}
              style={{
                borderBottom:
                  listImgLicese.length - 1 === index ? "" : "1px solid #D6DEEB",
              }}
              onClick={() =>
                (item?.documents || []).length === 0
                  ? dispatch({
                      type: "openSnacbars",
                      typeSnackbar: "warning",
                      message: "Bạn chưa có hình ảnh nào, vui lòng thêm nếu có",
                    })
                  : dispatch({ type: "openDaShowImg", item })
              }
            >
              <div style={{ maxWidth: "85%" }}>{item.title}</div>
              <img
                src={(item?.documents || []).length > 0 ? img : imgDisabel}
              />
            </div>
          ) : (
            <div
              className={classes.proveRelationship}
              key={index}
              style={{
                borderBottom:
                  newList.length - 1 === index ? "" : "1px solid #D6DEEB",
              }}
              onClick={() => {
                dispatch({ type: "openPageShowLink", index });
              }}
            >
              <div
                style={{
                  color: "#0B3C8A",
                  fontStyle: "italic",
                  wordWrap: "anywhere",
                }}
              >
                {item}
              </div>
            </div>
          )
        )}
      </div>
      <DialogShowImg {...{ dispatch, state }} />
    </div>
  );
}
