import React, { useEffect, useState } from "react";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import { reportStyle } from "../style/styleReport";
import { TextField } from "../overrideMui";
import {
  InputAdornment,
  MenuItem,
  Autocomplete,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import { debounce, get } from "lodash";
import { VerticalAlignBottom as VerticalAlignBottomIcon } from "@mui/icons-material";
import searchIcon from "./../../../../assets/icons/search-icon.svg";

export default function HeadFilter(props) {
  const classes = reportStyle();
  const { state, dispatch, userFromProfile } = props;
  const [scrollPosition, setScrollPosition] = useState({});
  let flag = false;

  const {
    tabs,
    dataFiter,
    loadingAutocomplete,
    payloadAutocomplete,
    payloadApi,
    listOrg,
    listMerchant,
    listProductParent,
  } = state;
  const userTypeFromProfile = userFromProfile?.extra_info?.userType;

  const listTypeAccount =
    userTypeFromProfile === "globalcare"
      ? [
          { label: "Tất Cả", value: "all" },
          { label: "TPAG", value: "tpag" },
          { label: "TPAS", value: "tpas" },
          { label: "TPA", value: "tpa" },
        ]
      : userTypeFromProfile === "tpag"
      ? [
          { label: "Tất Cả", value: "all" },
          { label: "TPAG", value: "tpag" },
          { label: "TPAS", value: "tpas" },
        ]
      : [];

  const getValueAutocomplete = (id, name) => {
    if (name === "org") {
      const title = get(payloadAutocomplete, `org.title`);
      const temp =
        title || title === ""
          ? {
              title: title,
            }
          : listOrg.find((i) => i.id == id) || null;

      return temp;
    }

    if (name === "product") {
      const temp = listProductParent.find((i) => id == i.id) || null;
      return temp;
    }
  };

  const onScrollListAutocomplete = (name) => {
    const elment = document.getElementsByClassName(`div-scroll-${name}`);
    const pageListAuto = get(payloadAutocomplete, `${name}.page`) || 0;
    const totalListAuto = get(payloadAutocomplete, `${name}.total`) || 0;

    if (elment.length > 0 && +pageListAuto * 10 < +totalListAuto) {
      const scrollDiv = elment[0];
      const listItems = document.querySelectorAll(`.scroll-item-${name}`);
      const lastItem = listItems[listItems.length - 1] || null;
      if (
        Math.abs(lastItem?.offsetTop - scrollDiv.scrollTop) <
          lastItem?.offsetHeight + scrollDiv.offsetHeight &&
        !loadingAutocomplete[name]
      ) {
        dispatch({ type: "getMoveListAutocomplete", name });
        setScrollPosition({
          ...scrollPosition,
          [name]: scrollDiv.scrollHeight,
        });
      }
    }
  };

  useEffect(() => {
    const element = document.getElementsByClassName("div-scroll-merchant");
    if (element.length > 0) {
      const element_scroll = element[0];
      element_scroll.scrollTop = scrollPosition?.merchant || 0;
    }
  }, [loadingAutocomplete?.merchant]);

  useEffect(() => {
    const element = document.getElementsByClassName("div-scroll-org");
    if (element.length > 0) {
      const element_scroll = element[0];
      element_scroll.scrollTop = scrollPosition?.org || 0;
    }
  }, [loadingAutocomplete?.org]);

  useEffect(() => {
    const element = document.getElementsByClassName("div-scroll-product");
    if (element.length > 0) {
      const element_scroll = element[0];
      element_scroll.scrollTop = scrollPosition?.product || 0;
    }
  }, [loadingAutocomplete?.product]);

  return (
    <div className={classes.head_filter}>
      <div className="first-header">
        <div className="box-select">
          <span
            style={{ marginRight: "12px", fontWeight: "500", color: "#61646D" }}
          >
            Chọn khung thời gian{" "}
          </span>
          <TextField
            value={get(dataFiter, `${tabs?.key}.valueTextDate`) || ""}
            onClick={() => {
              dispatch("openModalDate");
            }}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon
                    fontSize="small"
                    sx={{ color: "#686868" }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {["globalcare", "tpag"].includes(userTypeFromProfile) && (
            <>
              <span
                style={{
                  margin: "0 12px",
                  fontWeight: "500",
                  color: "#61646D",
                }}
              >
                Loại tài khoản{" "}
              </span>
              <TextField
                select
                value={get(dataFiter, `${tabs?.key}.typeAccount`) || ""}
                sx={{ minWidth: "100px" }}
                onChange={(e) => {
                  dispatch({
                    type: "onSelectTypeAccount",
                    value: e.target.value,
                  });
                }}
              >
                {listTypeAccount.map((item, index) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
              <span
                style={{ margin: "0 8px", fontWeight: "500", color: "#61646D" }}
              >
                Đơn vị TPA{" "}
              </span>
              <div style={{ position: "relative" }}>
                <Autocomplete
                  id="autocomple_tpa_id"
                  disablePortal
                  sx={{
                    "& .MuiInputBase-root": {
                      padding: "1px",
                      paddingLeft: "8px",
                      paddingRight: "32px",
                      minWidth: "190px",
                    },
                  }}
                  options={listOrg}
                  getOptionLabel={(options) => options?.title || ""}
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    getValueAutocomplete(
                      get(payloadApi, `${tabs?.key}.orgId`),
                      "org"
                    ) || null
                  }
                  onChange={(e, value, reason) => {
                    if (reason === "selectOption") {
                      dispatch({
                        type: "onChangPayloadApi",
                        name: "org",
                        value: value?.id,
                      });
                    }
                    if (reason === "clear") {
                      dispatch({
                        type: "onInputChangeOrg",
                        value: "",
                      });
                    }
                  }}
                  onInputChange={debounce((e, value, reason) => {
                    if (reason === "input") {
                      dispatch({ type: "onInputChangeOrg", value });
                    }
                  }, 550)}
                  noOptionsText={
                    <div>
                      {loadingAutocomplete?.org
                        ? "Đang tải thêm ... "
                        : "Không tìm thấy dữ liệu"}
                    </div>
                  }
                  ListboxComponent={(paramsList) => (
                    <div
                      {...paramsList}
                      className={`${paramsList.className} div-scroll-org`}
                      onScroll={() => {
                        onScrollListAutocomplete("org");
                      }}
                    >
                      {paramsList?.children?.map((i) => (
                        <li
                          {...i?.props}
                          key={i?.key}
                          className={`${i?.props?.className} scroll-item-org`}
                        />
                      ))}
                      {loadingAutocomplete?.org && (
                        <div
                          style={{
                            fontWeight: "600",
                            color: "#0B7CE4",
                            width: "100%",
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                        >
                          Đang tải thêm ...{" "}
                        </div>
                      )}
                    </div>
                  )}
                  PopperComponent={(props) => {
                    return (
                      <div
                        {...props}
                        className={`${props.className} ${classes.max_height_autocomplete}`}
                        style={{
                          width:
                            document.getElementById("autocomple_tpa_id")
                              .offsetWidth + 80,
                          position: "absolute",
                          display: props.open ? "" : "none",
                        }}
                        placement={"bottom-start"}
                      >
                        {props.children}
                      </div>
                    );
                  }}
                />
                <Backdrop
                  style={{
                    color: "#fff",
                    borderRadius: "5px",
                    position: "absolute",
                  }}
                  open={loadingAutocomplete?.orgBr}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </div>
            </>
          )}
        </div>
        <Button
          className="button dowload"
          onClick={() => dispatch("exportFileExcel")}
        >
          <VerticalAlignBottomIcon
            fontSize="small"
            sx={{ marginRight: "8px" }}
          />
          Xuất file
        </Button>
      </div>
      <div className="secont-header">
        <TextField value={1} select className="select-type-insurance">
          {[{ label: "BH con người", value: 1 }].map((i, key) => (
            <MenuItem key={key} value={i.value}>
              {i.label}
            </MenuItem>
          ))}
        </TextField>
        {tabs?.key === "claim" && (
          <>
            <div style={{ position: "relative" }}>
              <Autocomplete
                id="autocomple_product_id"
                disablePortal
                limitTags={2}
                sx={{
                  marginRight: "8px",
                  "& .MuiInputBase-root": {
                    padding: "1px",
                    paddingLeft: "8px",
                    paddingRight: "32px",
                    minWidth: "190px",
                    // maxWidth: "350px",
                  },
                }}
                options={listProductParent}
                getOptionLabel={(options) => options?.title || ""}
                // fullWidth
                renderInput={(params) => (
                  <TextField {...params} placeholder="Sản Phẩm" />
                )}
                value={
                  getValueAutocomplete(
                    get(payloadApi, `${tabs.key}.productIdParent`),
                    "product"
                  ) || []
                }
                onChange={(e, value, reason) => {
                  dispatch({
                    type: "onChangPayloadApi",
                    name: "product",
                    value: value?.id,
                  });
                }}
                onInputChange={debounce((e, value, reason) => {
                  if (reason === "input") {
                    dispatch({ type: "onInputChangeProduct", value });
                  }
                }, 800)}
                noOptionsText={
                  <div>
                    {loadingAutocomplete?.product
                      ? "Đang tải thêm ... "
                      : "Không tìm thấy dữ liệu"}
                  </div>
                }
                ListboxComponent={(paramsList) => (
                  <div
                    {...paramsList}
                    className={`${paramsList.className} div-scroll-product`}
                    onScroll={() => {
                      onScrollListAutocomplete("product");
                    }}
                  >
                    {paramsList?.children?.map((i) => (
                      <li
                        {...i?.props}
                        key={i?.key}
                        className={`${i?.props?.className} scroll-item-product`}
                      />
                    ))}
                    {loadingAutocomplete?.product && (
                      <div
                        style={{
                          fontWeight: "600",
                          color: "#0B7CE4",
                          width: "100%",
                          textAlign: "center",
                          fontSize: "14px",
                        }}
                      >
                        Đang tải thêm ...{" "}
                      </div>
                    )}
                  </div>
                )}
                PopperComponent={(props) => {
                  return (
                    <div
                      {...props}
                      className={`${props.className} ${classes.max_height_autocomplete}`}
                      style={{
                        width:
                          document.getElementById("autocomple_product_id")
                            .offsetWidth + 80,
                        position: "absolute",
                        display: props.open ? "" : "none",
                      }}
                      placement={"bottom-start"}
                    >
                      {props.children}
                    </div>
                  );
                }}
              />
            </div>
            <TextField
              select
              className="select-type-insurance"
              placeholder="Trạng thái"
              value={dataFiter?.claim?.statusId || 0}
              onChange={(e) => {
                dispatch({
                  type: "onChangPayloadApi",
                  name: "statusId",
                  value: e.target.value,
                });
              }}
            >
              {[
                { label: "Tất cả", statusId: 0, statusIds: [] },
                { label: "Đang xử lý", statusId: 1 },
                {
                  label: "Đồng ý bồi thường",
                  statusId: 2,
                },
                {
                  label: "Từ chối bồi thường",
                  statusId: 3,
                },
              ].map((i, key) => (
                <MenuItem key={key} value={i.statusId}>
                  {i.label}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
        <TextField
          placeholder="Tìm kiếm ..."
          className="text-field-search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={searchIcon} alt="search_icon" />
              </InputAdornment>
            ),
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              dispatch({
                type: "onChangPayloadApi",
                name: "keyword",
                value: e.target.value,
              });
              flag = true;
            }
          }}
          onChange={debounce((e) => {
            if (!flag) {
              dispatch({
                type: "onChangPayloadApi",
                name: "keyword",
                value: e.target.value,
              });
            }
            flag = false;
          }, 1800)}
        />
      </div>
    </div>
  );
}
