import { makeStyles } from "@mui/styles";

export const productManagerV2 = makeStyles(() => ({
  container: {
    width: "100%",
    marginTop: "45px",
    padding: "0 12px",
  },

  box_heder: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 0",
    alignItems: "center",

    "& .title-decentraliza": {
      fontSize: "20px",
      fontWeight: "700",
    },

    "& .buttons-add-decentraliza": {
      background: "#0B7CE4",
      color: "white",
      fontWeight: "500",

      "& .icon-button": { marginRight: "12px" },
    },
  },

  box_button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .text-field-selecter": {
      width: "150px",
      marginRight: "16px",
    },

    "& .text-field-search": {
      minWidth: "240px",
    },

    "& .div-buttons": {
      "& .button": {
        color: "#4B4B4B",
        background: "white",
        border: "1px solid #ECECEC",
        minWidth: "110px",
      },

      "& .dowload": {
        marginRight: "8px",
      },
    },

    "& .search-box": {
      display: "flex",
      alignItems: "center",
    },
  },

  div: {
    overflow: "auto",
    marginTop: "24px",
    paddingBottom: "0",
  },

  table: {
    minWidth: "1220px",
    paddingLeft: "12px",

    "& .none": {
      display: "none",
      transition: "opacity 1s ease-out",
      opacity: 0,
    },

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      fontWeight: "700",
      fontSize: "13px",
    },

    "& td": {
      fontSize: "13px",
    },

    "& td.child": {
      background: "#F1F2F4 !important",
    },

    "& td.headChild": {
      fontWeight: "600",
      color: "#61646D",
    },

    // "& .expan": {
    //   transform: "rotate(-180deg)",
    //   transition: "0.6s",
    // },

    "& .sticky": {
      position: "sticky",
    },

    "& .STT": {
      width: "40px",
      paddingLeft: "12px",
      transition: "0.7s",
    },

    "& .participant": {
      width: "125px",
      textAlign: "center",
    },

    "& .status": {
      width: "90px",
      textAlign: "center",
    },

    "& .show-icon": {
      width: "50px",
      right: 0,
      background: "white",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "2px solid #C0C0C0",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #ECECEC",
    background: "white !important",
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "white",
    padding: "8px 12px",

    "& .MuiTablePagination-root": {
      borderBottom: "none",
    },

    "& .MuiToolbar-root": {
      "& .MuiTablePagination-selectLabel": {
        display: "none",
      },

      "& .MuiInputBase-root": {
        display: "none",
      },

      "& .MuiTablePagination-displayedRows": {
        margin: "auto",
      },
    },

    "& .rows-per-page": {
      color: "#3961A0",
      fontSize: "14px",
    },
  },

  date: {
    margin: "0 4px",
    maxWidth: "140px",

    "& .MuiInputBase-root": {
      paddingLeft: "4px",
    },
    "& .MuiInputBase-input": {
      fontSize: "15px",
      padding: "9px 6px",
    },
    "& .MuiButtonBase-root": {
      padding: "6px",
      "& .MuiSvgIcon-root": {
        width: "20px",
      },
    },
  },

  //
}));
