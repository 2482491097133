import {
  CircularProgress,
  Paper,
  TableCell,
  TableContainer,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import CommonTable from "../../../../library/layout/commonTable";
import { _formatMoney, code_rules } from "../util";

export default function InsuranceBenefitTable({ list, loadingTable }) {
  const classes = useStyles();

  return (
    <>
      {loadingTable && <CircularProgress size={25} sx={{ color: "#0ABAFD" }} />}
      <TableContainer className={classes.container} component={Paper}>
        <CommonTable
          classes={classes}
          rows={list || []}
          columns={columns}
          empty={"Chưa thêm quyền lợi"}
        />
      </TableContainer>
    </>
  );
}

const columns = [
  {
    label: "Mã quyền lợi",
    headerClass: "code",
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst code"
            : item.isSecond
            ? "isSecond code"
            : "code"
        }
      >
        {item?.code_benefit || item?.codeBenefit || ""}
      </TableCell>
    ),
  },
  {
    label: "Tên quyền lợi ",
    headerClass: "name-benefits",
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst name-benefits"
            : item.isSecond
            ? "isSecond name-benefits"
            : "name-benefits"
        }
      >
        <>
          <div>
            {item?.title || ""} <br />
            <span className="light-text">
              Loại:{" "}
              {code_rules.find((i) => i.id == item?.configBenefit?.code_rules)
                ?.title || ""}
            </span>
          </div>
        </>
      </TableCell>
    ),
  },
  {
    label: "Quyền lợi",
    headerClass: "benefits",
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst benefit"
            : item.isSecond
            ? "isSecond benefit"
            : "benefit"
        }
      >
        <div>
          <span>
            {!item?.configBenefit?.value
              ? ""
              : !item?.configBenefit?.note
              ? `${_formatMoney(item?.configBenefit?.value)} đ`
              : `${item?.configBenefit?.note} đ`}
          </span>
          <br />
          <span className="light-text">
            {item.isFirst ? (
              item?.typeOfIndemnify
            ) : item?.configBenefit?.code_rules !== 1 ? (
              <span>
                GH phí: {_formatMoney(item?.configBenefit?.limit_fees)}{" "}
                <span style={{ marginLeft: "10px" }}>
                  GH {item?.configBenefit?.code_rules === 2 ? " Ngày" : " Lần"}:{" "}
                </span>
                {item?.configBenefit?.code_rules === 2
                  ? item?.configBenefit?.limit_day || " -"
                  : item?.configBenefit?.limit_number_of_times || " -"}
              </span>
            ) : (
              ""
            )}
          </span>
        </div>
      </TableCell>
    ),
  },
];

const useStyles = makeStyles(() => ({
  container: { marginTop: 15 },
  div: {
    paddingBottom: "20px",
  },

  table: {
    minWidth: "800px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      textAlign: "center",
      fontWeight: 600,
      color: "white !important",
      background: "none !important",
    },

    "& td": {
      textAlign: "center",
      padding: 0,
      color: "#4B4B4B",
    },

    "& th.code": {
      width: "120px",
      textAlign: "left",
      background: "#3961A0 !important",
    },

    "& th.name-benefits": {
      textAlign: "left",
    },

    "& th.benefits": {
      width: "230px",
    },

    "& td.code": {
      padding: "0 8px",
      textAlign: "left",
    },

    "& td.isFirst": {
      background: "#A8D9FF",
      fontWeight: 700,
    },

    "& td.isSecond": {
      background: "#E3F3FF",
      fontWeight: 500,
    },

    "& td.name-benefits": {
      textAlign: "left",
      padding: "5px",
      lineHeight: 2,
      fontWeight: 600,
    },

    "& td.benefit": {
      lineHeight: 2,
      fontWeight: 600,
    },

    "& .light-text": {
      color: "#686868",
      fontSize: 13,
      fontWeight: 400,
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#3961A0 !important",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #D6DEEB",
    background: "white !important",
  },
}));
