import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import { PDFViewer } from "@react-pdf/renderer";

import LoadingMui from "../../../../../../../ui/LoadingMui";
import DialogNoti from "../../DialogNoti";
import styledSendApproval from "./styledSendApproval";
import Approval from "./approval";
import ExportClaimPDF from "./ExportClaimPDF";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogSendApproval(props) {
  const { state, dispatch } = props;
  const classes = styledSendApproval();

  const {
    dialogSendApproval,
    dialogNoti,
    loading,
    detail,
    noteUseLV1,
    listImgBase64PDFfile = [],
  } = state;

  return (
    <Dialog
      open={!!dialogSendApproval?.open}
      TransitionComponent={Transition}
      maxWidth={"2000px"}
      onClose={() => dispatch("closeDialogSendApproval")}
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton onClick={() => dispatch("closeDialogSendApproval")}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "0 18px 16px 18px" }}>
        <Grid container>
          <Grid item xs={4}>
            <Approval {...{ state, dispatch }} />
          </Grid>
          <Grid item xs={8}>
            <div style={{ paddingLeft: "12px" }}>
              <PDFViewer
                style={{
                  width: "100%",
                  height: "75vh",
                  minHeight: "400px",
                  maxHeight: "750px",
                }}
              >
                <ExportClaimPDF
                  detail={detail}
                  approveAmount={noteUseLV1?.amount}
                  noteUseLV1={noteUseLV1}
                  listImgBase64PDFfile={listImgBase64PDFfile}
                />
              </PDFViewer>
            </div>
          </Grid>
        </Grid>
        <DialogNoti data={dialogNoti} dispatch={dispatch} loading={loading} />
        <LoadingMui loading={loading} />
      </DialogContent>
    </Dialog>
  );
}
