import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  -webkit-box-align: center;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
`;

export const Content = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 12px 16px 60px 16px;
`;
