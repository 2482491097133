import { makeStyles } from "@mui/styles";

export const tableReportStyle = makeStyles(() => ({
  container: {
    width: "100%",
    margin: "24px 0",
  },

  containerTabler: {
    background: "white",
    boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.1)",
    padding: "12px",
  },

  titleTable: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },

  div: {
    paddingBottom: "20px",
    overflow: "auto",
  },

  divTable: { maxHeight: "500px" },

  table: {
    minWidth: "500px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      textAlign: "center",
      color: "#4B4B4B !important",
      background: "#F8F8F8 !important",
      fontWeight: "600",
    },

    "& th.stt": {
      width: "40px",
    },

    "& td": {
      textAlign: "center",
      padding: 0,
      fontWeight: "500",
      background: "white !important",
      borderBottom: "1px solid #ECECEC",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#F8F8F8 !important",
    padding: "0 8px",
    top: 0,
    position: "sticky",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1px solid #D6DEEB",
    background: "#ECECEC !important",
  },

  //
}));
