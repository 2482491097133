import React, { useEffect } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import LoadingMui from "../../../../../ui/LoadingMui";
import useReducer from "../../../../../library/hooks/useReducer";
import Snackbars from "../../../../../ui/snackbar";
import recordsDetailReducer from "../reducer/recordsDetailReducer";
import {
  initialDetailState,
  getStatusById,
  listTypeOfRisk,
  typeOfIndimnity,
} from "../utils";
import { HeaderDetail, RightStatusTabs, TabsDetail } from "../components";
import styleRecordsDetail from "../style/records-detail-css";
import { getParamUrlByKey } from "../../../../../utils/utils";
import ExportClaimRefuseDocx from "../components/claimLicense/dialogSendApproval/ExportClaimRefuseDocx";

export default function RecodrsIndemnifyDetail() {
  const classes = styleRecordsDetail();
  const location = useLocation();
  const users = JSON.parse(localStorage.getItem("user")) || {};
  const product_id_parent = getParamUrlByKey("product_id_parent") || "";
  const getRoleFromProfileUser =
    (_.get(users, "role_management_claim.product_parents", []) || []).find(
      (i) => i.product_id_parent == product_id_parent
    ) || {};
  const level_id = +getRoleFromProfileUser?.level_id || null;

  // const level_id = JSON.parse(localStorage.getItem("product_id"))?.level_id;
  const systemType = _.isEmpty(users) ? "" : users?.extra_info?.userType;
  const { pathname } = location;
  const id = pathname.slice(pathname.search("detail/") + 7);

  const [state, dispatch, clearStateGlobal] = useReducer({
    initialState: initialDetailState,
    name: "recordsDetailReducer",
    reducer: recordsDetailReducer,
    id,
    level_id,
    systemType,
  });

  const { loading, snackbar, detail } = state;

  useEffect(() => {
    dispatch("initialDetail");

    return () => clearStateGlobal();
  }, []);

  // 4 = thai sản, 5 = Bệnh hiểm nghèo 6 = tử vong

  const getTextbyId = () => {
    if (detail?.extraInfo?.typeOfInsurance === 2) {
      return [4, 5, 6].includes(detail?.extraInfo?.typeOfIndimnity)
        ? `${
            typeOfIndimnity.find(
              (i) => i.id === detail?.extraInfo?.typeOfIndimnity
            )?.title
          } / ${
            listTypeOfRisk.find(
              (i) => i.id === detail?.extraInfo?.typeOfTreatment
            )?.title
          }`
        : typeOfIndimnity.find(
            (i) => i.id == detail?.extraInfo?.typeOfIndimnity
          )?.title;
    }

    return `${
      listTypeOfRisk.find((i) => i.id === detail?.extraInfo?.typeOfRisk)?.title
    } / ${
      typeOfIndimnity.find((i) => i.id === detail?.extraInfo?.typeOfIndimnity)
        ?.title
    }`;
  };

  return _.isEmpty(getRoleFromProfileUser) ? (
    <div className={classes.container}>
      <div>Xin lỗi bạn chưa có quyền truy cập nội dung này</div>
    </div>
  ) : (
    <ThemeProvider
      theme={createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 2250,
          },
        },
      })}
    >
      <div className={classes.container}>
        <div className="title">
          Hồ sơ bồi thường / {getTextbyId()} /{" "}
          <span style={{ fontWeight: "600" }}>{detail?.code || ""}</span>
          {" - "}{" "}
          <span
            style={{
              color: getStatusById(detail.statusId).color || "",
              fontWeight: "600",
            }}
          >
            {getStatusById(detail.statusId).label}
          </span>
        </div>
        <Grid container style={{ margin: "6px" }}>
          <Grid
            item
            sm={12}
            md={9}
            xl={9}
            xxl={10}
            style={{
              padding: "0 10px",
              overflow: "auto",
              height: "calc(100vh - 135px)",
            }}
          >
            <HeaderDetail {...{ state, dispatch, classes, level_id }} />
            <TabsDetail
              {...{ state, dispatch, classes, level_id, systemType, id }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            md={3}
            xl={3}
            xxl={2}
            style={{ background: "white" }}
          >
            <RightStatusTabs {...{ state, dispatch, classes, level_id }} />
          </Grid>
        </Grid>
        <LoadingMui loading={loading} />
        <Snackbars
          open={snackbar.open}
          type={snackbar.type}
          onClose={() => dispatch("closeSnackbar")}
          message={snackbar.message}
        />
      </div>
    </ThemeProvider>
  );
}
