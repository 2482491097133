import { useState, useMemo } from "react";
import _ from "lodash";

const useReducer = ({ reducer, name = "reducers", initialState, ...data }) => {
  const global = window;
  const [state, setState] = useState(initialState);
  useMemo(() => {
    _.set(global, `reducers.${name}`, {});
  }, []);

  const setValue = (changeState) => {
    if (changeState) {
      const { func } = changeState;
      delete changeState.func;
      const newState = {
        ...state,
        ..._.get(global, `reducers.${name}`, {}),
        ...changeState,
      };
      setState(newState);
      Object.assign(_.get(global, `reducers.${name}`, {}), newState);
      _.isFunction(func) && func();
    }
  };

  const dispatch = async (params, newState) => {
    const action = typeof params === "string" ? { type: params } : params || {};
    if (!!action?.type && _.isFunction(reducer)) {
      const func = reducer({
        state: {
          ...state,
          ..._.get(global, `reducers.${name}`, {}),
          ...(newState || {}),
        },
        action,
        dispatch,
        ...data,
      });

      const changeState =
        func.constructor.name === "AsyncFunction" ? await func() : func();
      changeState && setValue(changeState);
    }
  };

  const clearStateGlobal = (initial = {}, isSetState = false) => {
    _.set(global, `reducers.${name}`, { ...initial });
    isSetState && setState({ ...initial });
    !_.isEmpty(data.event) &&
      _.isFunction(reducer) &&
      data.event.removeAllListeners(
        Object.keys(reducer({ action: {} }, "events") || {})
      );
  };

  return [state, dispatch, clearStateGlobal];
};

export default useReducer;
