import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Row } from 'antd';

const HeaderBar = ({
  title = '',
  onPress = () => {},
  background = '#FFF'
}) => {
  return (
    <Row
      style={{
      position: 'fixed',
      height: 40,
      width: '100%',
      top: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 7777777,
      background,
    }}>
      <div style={styleButtonBack} onClick={onPress}>
        <LeftOutlined style={{fontSize: 17}}/>
      </div>
      <div style={styleTitle}>
        {title}
      </div>
    </Row>
  );
};

const styleButtonBack = {
  padding: '0px 14px 5px 14px',
  position: 'absolute',
  left: 0,
  top: 10
}

const styleTitle = {
  fontSize: 17,
  fontWeight: '700',
  alignItems: 'center',
  textAlign: 'center'
}

export default HeaderBar
