import { pdf } from "@react-pdf/renderer";
import { message } from "antd";
import { saveAs } from "file-saver";
import _, { isEmpty } from "lodash";
import moment from "moment";
import {
  createLoyalty,
  getFileByUrl,
  getHistoryOrder,
  getInforOrder,
  uploadImgLoyalty,
} from "../../../../../apis/claimLoyaltyApis";
import ExportRequestClaimPDF from "../components/ExportRequestClaimPDF";
import { getExtension, getParamUrlByKey } from "../utils";

function loyaltyReducer({ state, dispatch, initialState, action, resetState }) {
  const platform = getParamUrlByKey("platform");
  const platformKey = platform === "app" || !platform ? "bh365" : platform;

  const cases = {
    goHistoryOrder: () => ({ currentStep: 9 }),
    setValueTab: () => ({ tabsStatus: action.value }),
    getHistory: async () => {
      const { value } = action;
      const { destination, tabsStatus, claimInfo } = state;
      if (value) {
        dispatch({ type: "setValueTab", value });
      }

      const buyerPhone = destination?.data?.sender?.phoneNumber || null;
      const productIdParent =
        claimInfo?.productIdParent === 34 ? { productIdParent: 34 } : {};

      const res = {};
      dispatch("onLoading");
      const dataApi = await getHistoryOrder(
        {
          statusId: value || tabsStatus || 3,
          limit: 10,
          offset: 0,
          buyerPhone,
          ...productIdParent,
        },
        platformKey
      );

      if (dataApi?.data?.statusCode === 200) {
        res.historyOrder = _.get(dataApi, "data.result.data") || [];
        res.historyDestination =
          { data: res.historyOrder?.[0], index: 0 } || {};
      }

      return { ...res, loading: false };
    },
    detailDestinationHistory: () => ({ currentStep: 10 }),

    changeCodeOrder: () => ({ codeOrder: action.value }),
    handleClearCode: () => {
      const { errors } = state;
      errors.codeOrder = false;
      return { codeOrder: "", errors };
    },
    getListCode: async () => {
      const { codeOrder, errors } = state;
      const res = {};
      if (codeOrder.length < 6)
        return message.error("Nhập mã đơn hàng tối thiểu 6 kí tự");
      if (codeOrder) {
        dispatch("onLoading");
        const dataApi = await getInforOrder(
          {
            code: codeOrder || "",
            productIdParent: 34,
            limit: 10,
            offset: 0,
          },
          platformKey
        );
        if (dataApi instanceof Error) {
          errors.push("codeOrder");
          return { errors, loading: false };
        }
        res.codeOrderList = _.get(dataApi, "data.result.data") || [];
        if (_.isEmpty(res.codeOrderList)) {
          errors.push("codeOrder");
        } else {
          res.errors = [];
        }

        return { errors, ...res, loading: false };
      }
    },
    detailCode: () => {
      const { codeOrderList } = state;
      const { index } = action;
      const res = {};

      res.claimInfo = codeOrderList[index] || {};
      const desArr = codeOrderList[index]?.destination || [];
      res.destination =
        desArr.length > 0
          ? { data: desArr[desArr.length - 1], index: desArr.length - 1 }
          : {};
      res.disabledBtn = desArr.length == 0;
      res.expiry = false;

      //check expiry
      if (!Boolean(res.claimInfo?.ignoreEndDate)) {
        const expiry = 10081;
        const now = moment(new Date());
        const date = moment(res.claimInfo?.endDate);
        const durationMinutes = Math.ceil(
          moment.duration(now.diff(date)).asMinutes()
        );

        if (durationMinutes >= expiry) {
          res.currentStep = 8; //note success
          res.statusNotify = "warring";
          res.expiry = durationMinutes >= expiry;
          return res;
        }
      }

      return { ...res, currentStep: 2 };
    },
    onClickDestination: () => {
      const {
        data: { item, index },
        name,
      } = action;

      const res = {};
      if (name === "destination") {
        res.destination = { data: item, index };
      }
      return res;
    },
    onBack: () => {
      const { currentStep, expiry } = state;
      if (currentStep === 8) {
        return { ...resetState, currentStep: 1 };
      }
      if (currentStep === 9) {
        if (expiry) return { currentStep: 1 }; //back from history step when expiry
        return { currentStep: 2, extraInfo: {}, elements: [] };
      }
      return { currentStep: currentStep - 1 };
    },
    changePageNext: () => {
      const { currentStep, destination, expiry } = state;
      if (currentStep === 2) {
        if (_.isEmpty(destination)) return;
        return { currentStep: currentStep + 1 };
      }
      if (currentStep === 7) {
        const errors = valiDatePage(state);
        if (errors.length > 0) {
          return { errors };
        }
        return { confirmCreate: true, errors: [] };
      }
      if (currentStep === 9) {
        if (expiry) return { currentStep: 1 }; //back from history step when expiry
        return { currentStep: 2, extraInfo: {}, elements: [] };
      }
      if (currentStep === 10) {
        return { currentStep: 9 };
      }

      if (currentStep > 2 && currentStep !== 8) {
        const errors = valiDatePage(state);
        if (errors.length > 0) {
          return { errors };
        }
      }
      return { currentStep: currentStep + 1 };
    },
    setOpenPicker: () => {
      const { name } = action;
      const { picker } = state;
      return { picker: { ...picker, [name]: !picker?.[name] } };
    },
    onChangeExtraInfo: () => {
      const { name, value } = action;
      const { extraInfo } = state;
      if (name === "productAmount") {
        extraInfo[name] = value.replace(/\./g, "").replace(/^0+/, "");
      } else {
        extraInfo[name] = value;
      }
      return { extraInfo };
    },

    changeType: () => {
      const { extraInfo } = state;
      extraInfo.typeOfAccident = action.value;
      const errors = valiDatePage(state);
      return { extraInfo, errors };
    },

    openLink: () => {
      const { claimInfo } = state;
      if (claimInfo?.certLink) {
        return { openCertificate: true };
      } else {
        return { openDialogNoCerLink: true };
      }
    },

    setOpenPicker: () => {
      const { name } = action;
      const { picker } = state;

      return { picker: { ...picker, [name]: !picker[name] } };
    },

    confirmPicker: () => {
      const { name, value } = action;
      const { extraInfo } = state;
      extraInfo[name] = value;

      if (name === "riskTime") {
        const newValue = `${value[0]}` + ":" + `${value[1]}`;
        extraInfo[name] = newValue;
      }

      if (
        name === "riskDate" &&
        moment(value).format("DD") === moment().format("DD")
      ) {
        const newTime = moment().format("HH:mm");
        extraInfo.riskTime = newTime;
      }

      return { extraInfo };
    },

    closeCertificate: () => ({ openCertificate: false }),

    onLoadingImg: () => {
      const { loadingImg } = state;
      loadingImg[action.name] = true;
      return { loadingImg };
    },

    addImg: async () => {
      const { e, id, typeCode } = action;
      const { elements, loadingImg } = state;
      const index = (elements || []).findIndex((i) => i.typeId === id);
      const file = e.target.files[0];
      const data = new FormData();

      if (!file) {
        return;
      }

      data.append("document", file);

      dispatch({ type: "onLoadingImg", name: `i${id}` });
      const resApi = await uploadImgLoyalty(data);

      if (resApi?.data?.statusCode === 200) {
        const dataApi = _.get(resApi, "data.result[0]") || {};
        loadingImg[`i${id}`] = false;

        if (index === -1) {
          elements.push({
            typeId: id,
            typeCode,
            extraInfo: {
              documents: [{ path: dataApi?.path, size: dataApi?.size }],
            },
            text: null,
            value: null,
          });
        } else {
          const items = elements[index];
          elements[index] = {
            ...items,
            extraInfo: {
              documents: [
                ...items?.extraInfo?.documents,
                {
                  path: dataApi?.path,
                  size: dataApi?.size,
                },
              ],
            },
          };
        }
      }
      return {
        elements,
        loadingImg,
      };
    },

    showImg: () => {
      const { url, typeId, indexImg } = action;
      const { showImg, elements } = state;
      const res = {};
      const arr = elements.find((e) => e.typeId == typeId);
      res.showImg = {
        ...showImg,
        open: true,
        lisUrl: arr?.extraInfo?.documents || [],
        url,
        indexImg,
      };
      return res;
    },

    showContractImg: () => {
      const { file } = action;
      const { showImg } = state;
      showImg.lisUrl = [file];
      showImg.url = file.path;
      showImg.indexImg = 0;
      showImg.open = true;
      return { showImg };
    },

    showImgHistory: () => {
      const { url, typeId, indexImg } = action;
      const { showImg, historyDestination } = state;
      const res = {};
      const elements = historyDestination?.data?.elements || [];
      const arr = elements.find((e) => e.type_id == typeId);
      res.showImg = {
        ...showImg,
        open: true,
        lisUrl: arr?.extra_info?.documents || [],
        url,
        indexImg,
      };
      return res;
    },

    deleteImg: () => {
      const { typeId, index } = action;
      const { elements } = state;
      const elementIndx = elements.findIndex((i) => i.typeId === typeId);
      elements[elementIndx]?.extraInfo?.documents.splice(index, 1);
      return { elements };
    },

    deleteEvidence: () => {
      const { url } = action;
      const { elements } = state;

      const elementIndx = elements.findIndex((i) => i.typeId === 206);
      const arr =
        elements.find((i) => i.typeId === 206)?.extraInfo?.documents || [];
      const itemIndex = arr.findIndex((i) => i.path === url);
      elements[elementIndx]?.extraInfo?.documents.splice(itemIndex, 1);

      dispatch({ type: "handleAudio", elements });

      return { elements };
    },

    changeIndexImg: () => {
      const { showImg } = state;
      const res = {};
      const { name } = action;
      if (name === "next") {
        const index =
          showImg?.indexImg === showImg?.lisUrl?.length - 1
            ? 0
            : showImg?.indexImg + 1;

        res.showImg = {
          ...showImg,
          indexImg: index,
          url: showImg?.lisUrl[index]?.path,
        };
      } else {
        const index =
          showImg?.indexImg === 0
            ? showImg?.lisUrl.length - 1
            : showImg?.indexImg - 1;

        res.showImg = {
          ...showImg,
          indexImg: index,
          url: showImg?.lisUrl[index]?.path,
        };
      }

      return res;
    },

    addImgEvidence: async () => {
      const { e, typeCode, nameType } = action;
      const { elements, loadingImg } = state;
      const index = (elements || []).findIndex((i) => i.typeId === 206);
      const file = e.target.files[0];
      const data = new FormData();

      if (!file) {
        return;
      }

      data.append("document", file);

      dispatch({ type: "onLoadingImg", name: `${nameType}206` });
      const resApi = await uploadImgLoyalty(data);

      if (resApi?.data?.statusCode === 200) {
        const dataApi = _.get(resApi, "data.result[0]") || {};
        const fileOriginalName =
          nameType === "evidence_image"
            ? {}
            : { originalname: dataApi?.originalname };

        loadingImg[`${nameType}206`] = false;
        if (index === -1) {
          elements.push({
            typeId: 206,
            typeCode,
            extraInfo: {
              documents: [
                {
                  path: dataApi?.path,
                  size: dataApi?.size,
                  type: nameType,
                  ...fileOriginalName,
                },
              ],
            },
            text: null,
            value: null,
          });
        } else {
          const items = elements[index];
          elements[index] = {
            ...items,
            extraInfo: {
              documents: [
                ...items?.extraInfo?.documents,
                {
                  path: dataApi?.path,
                  size: dataApi?.size,
                  type: nameType,
                  ...fileOriginalName,
                },
              ],
            },
          };
        }
        if (nameType === "evidence_audio") {
          dispatch({ type: "handleAudio", elements });
        }
      }

      return { loading: false, elements, loadingImg };
    },

    addDriverFile: async () => {
      const { e, nameType } = action;
      const { extraInfo, loadingImg } = state;
      const file = e.target.files[0];
      const data = new FormData();
      if (!file) {
        return;
      }

      data.append("document", file);
      dispatch({ type: "onLoadingImg", name: nameType });
      const resApi = await uploadImgLoyalty(data);
      if (resApi instanceof Error) {
        loadingImg[nameType] = false;
        message.error(resApi?.data?.message, 3);
        return { loadingImg };
      }
      if (resApi?.data?.statusCode === 200) {
        const dataApi = _.get(resApi, "data.result[0]") || {};
        loadingImg[nameType] = false;

        if (nameType === "contractFiles") {
          const obj =
            getExtension(dataApi?.path) !== "pdf" ? {} : { type: "file" };

          extraInfo.contractFiles = [
            ...(extraInfo?.contractFiles || []),
            {
              path: dataApi?.path,
              size: `${((dataApi?.size || 0) / 1024).toFixed()} KB`,
              originalname: dataApi?.originalname,
              ...obj,
            },
          ];
        } else {
          extraInfo[`${nameType}Cmnd`] = {
            path: dataApi?.path,
            type: nameType,
          };
        }
      }
      return { loadingImg, extraInfo };
    },

    handleAudio: () => {
      const { elements } = action;
      const listImg206 =
        (elements || []).find((item) => item.typeId === 206)?.extraInfo
          ?.documents || [];

      const audioFiles =
        listImg206.filter((i) => i.type === "evidence_audio") || [];

      const audioList = audioFiles.map((i) => ({ ...i, play: true }));
      return { audioList };
    },

    setPlayIcon: () => {
      const { audioList } = state;
      const { index, all, value } = action;

      if (all) {
        const arr = audioList.map((i) => ({
          ...i,
          play: true,
        }));
        return { audioList: arr };
      }

      audioList[index] = {
        ...audioList[index],
        play: value,
      };

      return { audioList };
    },

    showEvidence: () => {
      const { showFileEvidence } = state;
      const { file } = action;
      showFileEvidence.link = file.path;
      showFileEvidence.open = true;
      return { showFileEvidence };
    },

    closeShowEvidence: () => ({
      showFileEvidence: { link: null, open: false },
    }),

    onChangeValue: () => {
      const { name, value } = action;
      const { extraInfo } = state;
      extraInfo[name] = value;
      return { extraInfo };
    },

    checkCommit: () => ({ checkedCommit: action.value }),
    closeShowImg: () => ({ showImg: { ...state.showImg, open: false } }),
    onLoading: () => ({ loading: true }),
    openDialogNoti: () => ({ confirmCreate: true }),
    closeDialogNoti: () => ({ confirmCreate: false }),

    uploadForm: async () => {
      const { elements } = state;
      const blobPdf = await new pdf(ExportRequestClaimPDF({ state })).toBlob();
      if (!blobPdf) {
        return;
      }

      const requestFile = new File([blobPdf], `request-claim-form.pdf`);
      const data = new FormData();
      data.append("document", requestFile);

      dispatch("onLoading");
      const cdnPdfApi = await uploadImgLoyalty(data);
      if (cdnPdfApi instanceof Error) {
        message.error("Error upload request claim form");
        return { loading: false };
      }
      const dataRequest = _.get(cdnPdfApi, "data.result[0]") || {};

      elements.push({
        typeId: 207,
        typeCode: "file_compensation_claim_records",
        extraInfo: {
          documents: [
            {
              path: dataRequest?.path,
              size: dataRequest?.size,
              type: "request_doc",
              originalname: "Giấy yêu cầu bảo hiểm.pdf",
            },
          ],
        },
        text: null,
        value: null,
      });
      return elements;
    },

    onSubmit: async () => {
      await dispatch("uploadForm");
      dispatch("create");
    },

    downloadFile: async () => {
      const { path, name } = action;
      const dataApi = await getFileByUrl(path);
      saveAs(dataApi, `${name}`);
    },

    deleteContractFile: () => {
      const { extraInfo } = state;
      extraInfo?.contractFiles.splice(action.index, 1);
      return { extraInfo };
    },

    create: async () => {
      const {
        elements,
        extraInfo,
        claimInfo,
        destination: { data, index },
      } = state;

      const res = {};
      dispatch("onLoading");

      const newElements = [
        ...elements,
        {
          typeId: 208,
          typeCode: "private_id",
          extraInfo: {
            documents: [extraInfo.frontCmnd, extraInfo.backCmnd],
          },
        },
        {
          typeId: 209,
          typeCode: "driver_contract",
          extraInfo: {
            documents: extraInfo.contractFiles,
          },
        },
      ];

      const initialRiskDate =
        moment() <= moment(claimInfo?.endDate)
          ? moment()
          : moment(claimInfo?.endDate);

      const params = {
        productIdParent: claimInfo?.productIdParent,
        productId: claimInfo?.productId,
        providerId: claimInfo?.providerId,
        platform: platformKey,
        certNum: claimInfo?.certNum,
        insuredInfo: {
          ...claimInfo?.insuredInfo,
          beginDate: claimInfo?.createdDate,
          endDate: claimInfo?.endDate,
          certLink: claimInfo?.certLink,
        },
        extraInfo: {
          gcOrderCode: claimInfo?.gcOrderCode,
          orderCode: claimInfo?.orderCode,
          codeDestination: data?.codeDestination,
          partnerOrderCode: claimInfo?.partnerOrderCode,
          statusId: 0,
          beneficiaryName: "CONG TY CO PHAN DICH VU TUC THOI",
          beneficiaryBankId: 19,
          beneficiaryBank: "Ngân hàng Techcombank - CN Thắng Lợi",
          beneficiaryBankAcountNumber: "19131637055028",
          beneficiaryEmail: extraInfo?.beneficiaryEmail,
          riskDate: extraInfo?.riskDate?.toISOString() || initialRiskDate,
          riskTime:
            extraInfo?.riskTime || moment(initialRiskDate).format("HH:mm"),
          describe: extraInfo?.describe,
          typeOfAccident: extraInfo?.typeOfAccident,
          createdDate: claimInfo?.createdDate,
          address: data?.address,
          codValue: data?.codValue,
          productAmount: extraInfo?.productAmount || data?.productAmount,
          driver: {
            ...data.driver,
            fullName: extraInfo?.driverName,
            address: extraInfo?.driverAddress,
            householdAddress: extraInfo?.householdAddress,
            hometown: extraInfo?.driverHometown,
            receivingTimeOfDriver: data?.createdDate,
            deliveryTime: claimInfo?.endDate,
          },
          sender: data?.sender,
          receiver: {
            fullName: extraInfo?.receiverName || data?.receiver?.fullName,
            phoneNumber:
              extraInfo?.receiverPhone || data?.receiver?.phoneNumber,
            address: data?.receiver?.address,
          },
          paymentInfo: data?.paymentInfo,
          productInfo: data?.productInfo,

          listOfRelativesToDriver: [
            {
              fullName: extraInfo?.relativeName1,
              phoneNumber: extraInfo?.relativePhone1,
              yearOfBirth: extraInfo?.relativeDob1,
              relationshipWithDriver: extraInfo?.relationship1,
            },
            {
              fullName: extraInfo?.relativeName2,
              phoneNumber: extraInfo?.relativePhone2,
              yearOfBirth: extraInfo?.relativeDob2,
              relationshipWithDriver: extraInfo?.relationship2,
            },
          ],
        },
        elements: newElements,
      };
      const dataApi = await createLoyalty(params, platformKey);
      if (dataApi instanceof Error) {
        message.error("Lỗi tạo yêu cầu bồi thường");
        return { loading: false };
      }

      res.loading = false;
      res.confirmCreate = false;
      res.currentStep = 8;
      res.statusNotify = "success";
      res.codeOrder = "";
      res.codeOrderList = [];

      return res;
    },

    setInitialRiskDate: () => {
      const { claimInfo, extraInfo } = state;
      extraInfo.riskDate =
        moment() <= moment(claimInfo?.endDate)
          ? moment().toDate()
          : moment(claimInfo?.endDate).toDate();
      extraInfo.riskTime = moment(extraInfo.riskDate).format("HH:mm");
      return { extraInfo };
    },

    closeDialogNoCerLink: () => ({ openDialogNoCerLink: false }),
  };
  return cases[action?.type];
}
export default loyaltyReducer;

const valiDateTime = ({ claimInfo, extraInfo, destination }) => {
  const finishDay = moment(
    claimInfo?.endDate ? claimInfo?.endDate : destination?.data?.createdDate
  )
    .set({
      second: 0,
      millisecond: 0,
    })
    .toDate();

  const riskTime = extraInfo?.riskTime?.split(":").map((i) => i);
  const riskDate = moment(extraInfo?.riskDate)
    .set({
      hour: riskTime[0],
      minute: riskTime[1],
      second: 0,
      millisecond: 0,
    })
    .toDate();

  if (
    moment(destination?.data?.createdDate).toDate() > riskDate ||
    moment(finishDay).toDate() < riskDate
  ) {
    return "validateDateTime";
  }
};

const valiDatePage = (state) => {
  const {
    currentStep,
    extraInfo,
    destination,
    elements,
    checkedCommit,
    claimInfo,
  } = state;
  const errors = [];

  const checkInfoDriver = () => {
    !(extraInfo.driverName || destination?.data?.driver?.fullName) &&
      errors.push("driverName");
    !(extraInfo?.driverPhoneNumber || destination?.data?.driver?.phoneNumber) &&
      errors.push("driverPhoneNumber");
    !(extraInfo?.driverAddress || destination?.data?.driver?.address) &&
      errors.push("driverAddress");
    [
      "driverHometown",
      "householdAddress",
      "relativeName1",
      "relativePhone1",
      "relativeDob1",
      "relationship1",
      "relativeName2",
      "relativePhone2",
      "relativeDob2",
      "relationship2",
    ].forEach((key) => {
      !extraInfo?.[key] && errors.push(key);
    });
    (!extraInfo?.frontCmnd || !extraInfo?.backCmnd) && errors.push("cmnd");
    isEmpty(extraInfo?.contractFiles) && errors.push("contractFiles");
    return errors;
  };

  const checkInfoBooker = () => {
    ["receiverName", "receiverPhone"].forEach((key) => {
      !extraInfo?.[key] && errors.push(key);
    });
    return errors;
  };

  const checkStep1 = () => {
    !extraInfo.describe && errors.push("describe");
    !extraInfo.productAmount &&
      destination?.data?.paymentInfo?.paymentId === 1 &&
      +extraInfo.productAmount !== 0 &&
      errors.push("productAmount");

    +extraInfo?.productAmount > +destination?.data?.codValue &&
      destination?.data?.paymentInfo?.paymentId === 1 &&
      errors.push("codValue");

    !extraInfo.typeOfAccident && errors.push("typeOfAccident");

    if (extraInfo.riskTime && extraInfo.riskDate) {
      const validateDateTime = valiDateTime({
        claimInfo,
        extraInfo,
        destination,
      });
      validateDateTime === "validateDateTime" &&
        errors.push("validateDateTime");
    }

    return errors;
  };

  const checkStep2 = () => {
    const img200 = elements.find((e) => e.typeId == 200);
    (img200?.extraInfo?.documents || []).length === 0 && errors.push("img200");
    const img202 = elements.find((e) => e.typeId == 202);
    (img202?.extraInfo?.documents || []).length === 0 && errors.push("img202");
    const img206 = elements.find((e) => e.typeId == 206);
    (img206?.extraInfo?.documents || []).length === 0 && errors.push("img206");
    return errors;
  };

  const checkStep3 = () => {
    const filterEmail =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    // const signature = elements.find((i) => i.typeId === 204) || {};

    !extraInfo.beneficiaryEmail && errors.push("email");
    extraInfo.beneficiaryEmail &&
      !filterEmail.test(extraInfo.beneficiaryEmail) &&
      errors.push("emailType");

    !checkedCommit && errors.push("checkedCommit");
    // _.isEmpty(signature) && errors.push("signature");

    return errors;
  };

  switch (currentStep) {
    case 3:
      return checkInfoDriver();
    case 4:
      return checkInfoBooker();
    case 5:
      return checkStep1();
    case 6:
      return checkStep2();
    case 7:
      return checkStep3();
  }
};
