import { makeStyles } from "@mui/styles";

const styleRecordsDetail = makeStyles((theme) => {
  return {
    container: {
      marginTop: "40px",
      padding: "16px 0",

      "& .title": {
        width: "1005",
        corlo: "#4B4B4B",
        fontSize: "15px",
        padding: "12px 0 12px 16px",
        background: "white",
        margin: "0 6px",
        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
        position: "sticky",
        top: "64px",
        zIndex: 2,
      },
    },

    containerHeaderDetail: {
      width: "100%",
      padding: "8px 6px",
      background: "white",
      display: "flex",
      justifyContent: "space-between",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)",

      "& .box-infor": {
        fontSize: "13px",

        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "0 6px",
      },

      "& .border": { borderRight: "1px solid #DCDCDC" },

      "& .box-insurance": {
        fontSize: "13px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        padding: "0 6px",
      },
    },

    containerTabsDetail: {
      marginTop: "16px",
    },

    tabsDetail: {
      borderBottom: "1px solid #3961A0",
      zIndex: 3,
      position: "sticky",
      top: 0,
      background: "white",

      "& .MuiTabScrollButton-root": {
        width: "20px",
      },

      "& .MuiButtonBase-root.MuiTab-root": {
        fontSize: "14px",
        textTransform: "none",
        fontWeight: "500",
        color: "#3961A0",
      },

      "& .MuiButtonBase-root.Mui-selected": {
        fontWeight: "700",
        color: "#3961A0",
        background: "white",
      },

      "& .MuiTabs-indicator": {
        background: "#3961A0",
      },
    },

    containerRightStatusTab: {
      background: "white",
      overflow: "auto",
      height: "calc(100vh - 140px)",
    },

    //
  };
});

export default styleRecordsDetail;
