import moment from "moment";
import { getStatusById, converDate } from "../utils";

export default function TableExportExcel(props) {
  const { dataSearch, data } = props;

  const fromDate = !dataSearch?.from
    ? "..............."
    : moment(dataSearch?.from).format("DD/MM/YYYY");
  const toDate = !dataSearch?.to
    ? "..............."
    : moment(dataSearch?.to).format("DD/MM/YYYY");

  return (
    <>
      <div style={{ display: "none" }}>
        <table
          className="collapse-border"
          id={"export-data-table"}
          data-cols-width="7, 7, 20, 20, 15, 15, 10, 25, 35, 25, 27, 17, 17, 25, 25, 20, 25, 15, 20"
        >
          <thead style={{ fontSize: "13" }}>
            <tr />
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"16"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                BÁO CÁO CHI TIẾT HỒ SƠ BỒI THƯỜNG
              </th>
            </tr>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >{`Từ ngày: ${fromDate} Đến Ngày: ${toDate} `}</th>
            </tr>
            <tr />
            {/* <tr>
              <th
                colSpan={3}
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-wrap={true}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                {`Bên xuất báo cáo: ${getRoleType(roleType)}`}
              </th>
            </tr> */}
            <tr />
            <tr />
            <tr style={{ fontSize: "13" }}>
              <th />
              <Th>STT</Th>
              <Th>Họ và tên </Th>
              <Th>CMND/ CCCD </Th>
              <Th>SĐT </Th>
              <Th>Ngày sinh</Th>
              <Th>Giới tính</Th>
              <Th>Email </Th>
              <Th>Địa chỉ </Th>
              <Th>Số hợp đồng </Th>
              <Th>Mã HS bồi thường </Th>
              <Th>Ngày tạo hồ sơ </Th>
              <Th>Ngày cập nhật </Th>
              <Th>Nhân sự xử lý hồ sơ </Th>
              <Th>Nhân sự phê duyệt </Th>
              <Th>Số tiền yêu cầu (VNĐ) </Th>
              <Th>Số tiền đã duyệt (VNĐ) </Th>
              <Th>Trạng thái</Th>
              <Th>Số tiền bồi thường (VNĐ) </Th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13" }}>
            {data.map((item, index) => {
              return (
                <tr key={index} style={{ fontSize: "13" }}>
                  <th />
                  <Td>{index + 1}</Td>
                  <Td>{item?.insuredInfo?.insuredName || ""}</Td>
                  <Td>{item?.insuredInfo?.insuredPrivateId || ""}</Td>
                  <Td>{item?.insuredInfo?.insuredPhone || ""}</Td>
                  <Td>
                    {converDate(item?.insuredInfo?.insuredDob)
                      ? moment(
                          converDate(item?.insuredInfo?.insuredDob)
                        ).format("DD/MM/YYYY")
                      : ""}
                  </Td>
                  <Td>
                    {item?.insuredInfo?.insuredGender == 0
                      ? "Nữ"
                      : item?.insuredInfo?.insuredGender == 1
                      ? "Nam"
                      : ""}
                  </Td>
                  <Td>{item?.insuredInfo?.insuredEmail || ""}</Td>
                  <Td>{item?.insuredInfo?.insuredAddress || ""}</Td>
                  <Td>{item?.certNum || ""}</Td>
                  <Td>{item?.code || ""}</Td>
                  <Td>
                    {item.createdDate
                      ? moment(item.createdDate).format("DD/MM/YYYY")
                      : ""}
                  </Td>
                  <Td>
                    {item.updatedDate
                      ? moment(item.updatedDate).format("DD/MM/YYYY")
                      : ""}
                  </Td>
                  <Td>{item.userFullnameReview || ""}</Td>
                  <Td>{item.userFullnameAssign || ""}</Td>
                  <th
                    data-f-name={"Times new roman"}
                    data-f-sz={"12"}
                    data-b-a-s={"thin"}
                    data-a-wrap={true}
                    data-t="n"
                  >
                    {item.requestAmount || 0}
                  </th>
                  <th
                    data-f-name={"Times new roman"}
                    data-f-sz={"12"}
                    data-b-a-s={"thin"}
                    data-a-wrap={true}
                    data-t="n"
                  >
                    {item?.approveAmount || 0}
                  </th>
                  <Td>{getStatusById(item.statusId).label}</Td>
                  <th
                    data-f-name={"Times new roman"}
                    data-f-sz={"12"}
                    data-b-a-s={"thin"}
                    data-a-wrap={true}
                    data-t="n"
                  >
                    {item?.amount || 0}
                  </th>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const Th = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-h={"center"}
      data-a-wrap={true}
      data-f-bold={true}
    >
      {props.children}
    </th>
  );
};

const Td = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-wrap={true}
    >
      {props.children}
    </th>
  );
};
