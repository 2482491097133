/*eslint-disable */
import React from 'react';
import * as yup from 'yup';
import moment from 'moment';
// import strings from '~/localization';
/** Regex Validation **/
export const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const phoneRegex2 = /^(09|03|07|08|05)+([0-9]{8})$/;
export const emailRegex = /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,10}/i;
const isEmailOrPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$|^[A-Z0-9._%-]+[+\w-]+@[A-Z0-9.-]+\.[A-Z]{2,10}/i;

const usernameRequired = 'Vui lòng nhập tên đăng nhập';
const nameRequired = 'Vui lòng nhập Họ tên';
const formRequired = 'Vui lòng nhập thông tin';
const phoneInvalid = 'Số điện thoại không hợp lệ';
const genderRequired = 'Vui lòng chọn giới tính';
const dateRequired = 'Vui lòng chọn ngày sinh';
const emailInvalid = 'Email không hợp lệ';
const emailRequired = 'Vui lòng nhập email';
const codeInvalid = 'Mã xác nhận không hợp lệ';
const lastNameRequired = 'Last name is required';
const firstNameRequired = 'First name is required';

const passwordSchema = yup.string().min(8, 'Mật khẩu ít nhất 8 ký tự').matches(/^(?=.*[a-zA-Z])(?=.*[0-9])/, 'Mật khẩu phải bao gồm chữ và số');

export const requiredNoteField = yup.string().required('Vui lòng nhập ghi chú trước khi nhấn ”Phê duyệt", “Từ chối" hoặc “Bổ sung hồ sơ"');
export const formValidate = yup.string().trim().required(formRequired).max(160, 'Độ dài tối da 160 ký tự');
export const formValidateUploadAcceptance = yup.array().min(1, 'Vui lòng tải lên chứng từ');
export const passwordValidate = passwordSchema.required('Vui lòng nhập mật khẩu').max(60, 'Mật khẩu tối đa 60 ký tự');
export const passwordConfirmationValidate = passwordValidate.oneOf([yup.ref('password'), null], 'Xác thực khẩu không khớp');
export const requiredValidField = yup.string().required('Vui lòng điền thông tin');
export const usernameValidate = yup.string().trim().required(usernameRequired);
export const nameValidate = yup.string().trim().required(nameRequired).max(64, 'Độ dài tối da 64 ký tự');
export const addressValidate = yup.string().trim().required('Vui lòng nhập địa chỉ').max(64, 'Độ dài tối da 64 ký tự');
export const dateValidate = yup.string().required(dateRequired).nullable();
export const codeValidate = yup.string().min(6, codeInvalid).required(codeInvalid);
export const firstNameValidate = yup.string().trim().required(firstNameRequired);
export const costValidate = yup.number().typeError('Vui nhập nhập số').required(formRequired).min(0, 'Giá trị không hợp lệ');;
export const lastNameValidate = yup.string().trim().required(lastNameRequired);
export const minZeroNRValue = yup.number().min(0, 'Giá trị không hợp lệ');
export const _18yearsValidate = yup
  .string()
  .required("Vui lòng điền thông tin")
  .test("DOB", "Vui lòng điền thông tin 12312", (value) => {
    return moment().diff(moment(value, 'DD/MM/YYYY'), "years") >= 18;
  });
export const phoneOrEmailValidate = yup
  .string()
  .trim()
  .required('Vui lòng điền thông tin')
  .matches(isEmailOrPhone, 'Tên tài khoản là email hoặc số điện thoại')
  .max(50, 'Độ dài tối da 50 ký tự');
export const emailValidate = yup
  .string()
  .trim()
  .matches(emailRegex, emailInvalid)
  .required(emailRequired)
  .max(50, 'Email dài tối đa 50 ký tự');
export const emailValidateNotRequire = yup.string().trim().matches(emailRegex, emailInvalid).required(formRequired).max(50, 'Email dài tối đa 50 ký tự');
export const genderValidate = yup.string().required(genderRequired);
export const phoneValidate = yup
  .string()
  .trim()
  .matches(phoneRegex, phoneInvalid)
  .required(formRequired)
  .max(10, 'Số điện thoại dài tối đa 10 ký tự');
export const phoneValidateNotRequire = yup.string().trim().matches(phoneRegex, phoneInvalid).max(10, 'Số điện thoại dài tối đa 10 ký tự');
export const phoneValidate2 = yup
  .string()
  .trim()
  .matches(phoneRegex2, phoneInvalid)
  .required(phoneInvalid)
  .max(10, 'Số điện thoại dài tối đa 10 ký tự');

export const checkTypeLogin = (input) => {
  if (input.match(phoneRegex)) {
    return 'phone';
  } else if (input.match(emailRegex)) {
    return 'email';
  }
  return false;
};
