export const initialStateManagerBLVP = {
  listBVPK: [],
  dataExportExcel: [],
  listBVPK_createRequest: [],
  snackbar: {},
  pageTable: {},
  pageTable_createRequest: {},
  dataSearch: {},
  loading: false,
  count: 0,
  insuredPrivateId: null,
};

export const initialStateDetailBLVP = {
  rowsBeneficiary: [],
  listLicese: [],
  listTypeIdLicese: [],
  // listCommentLinceseAdd: [],
  listApprovalHistory: [],
  detail: { statusId: 2 },
  snackbar: {},
  tabsDetail: { value: 0, key: "contract_infomation" },
  expandAccordions: {},
  tabsRight: 0,
  typeIdLiceseButton: null,
  tabButtonTabsRight: "new-approve",
};

export const initialStateCreateRequestBLVP = {
  insuranceTypes: [],
  typeOfIndimnities: [],
  riskTypes: [],
  errors: [],
  payloadDetail: { elements: [] },
  snackbar: {},
  loading: false,
};

export const converDate = (string) => {
  if (!string) {
    return null;
  }

  if (string?.indexOf("T") > -1) {
    return string;
  } else {
    if (string.includes("/") || string.includes("-")) {
      const check_string = (string.match(/\//g) || []).length;
      const matches = (string.match(/(\d+)[-\/]/g) || []).map((match) =>
        match.slice(0, -1)
      );

      if (matches[0]?.length == 4) {
        return string;
      }

      return check_string > 0
        ? string.split("/").reverse().join("/")
        : string.split("-").reverse().join("-");
    }

    return null;
  }
};

export const getStatusById = (id) => {
  switch (id) {
    case 2:
      return {
        label: "Đang xử lý",
        color: "#FFA21F",
        labelReport: "Đang xử lý",
        colorReport: "#FFA21F",
      };
    case 3:
      return {
        label: "Chờ phê duyệt",
        color: "#FF003D",
        labelReport: "Đang xử lý",
        colorReport: "#FFA21F",
      };

    case 4:
      return {
        label: "Bổ sung hồ sơ",
        color: "#0B7CE4",
        labelReport: "Đang xử lý",
      };
    case 5:
      return {
        label: "Đã phê duyệt",
        color: "#069D27",
        labelReport: "Đồng ý bồi thường",
        colorReport: "#069D27",
      };
    case 6:
      return {
        label: "Hoàn thành",
        color: "#0B3C8A",
        labelReport: "Đồng ý bồi thường",
        colorReport: "#069D27",
      };
    case 7:
      return {
        label: "Từ chối",
        color: "#FF003D",
        labelReport: "Từ chối bồi thường",
        colorReport: "#FF003D",
      };
    case 8:
      return {
        label: "KH từ chối",
        color: "#FF003D",
        labelReport: "Từ chối bồi thường",
        colorReport: "#FF003D",
      };
    case 9:
      return {
        label: "Chấp nhận bồi thường",
        color: "#069D27",
        labelReport: "Đồng ý bồi thường",
        colorReport: "#069D27",
      };
    case 10:
      return {
        label: "Không hợp lệ",
        color: "#F24822",
        labelReport: "Từ chối bồi thường",
        colorReport: "#FF003D",
      };

    default:
      return {};
  }
};

export const listTypeOfRisk = [
  { title: "Khám thai định kỳ", id: 1, parentId: 4 },
  { title: "Sanh thường", id: 2, parentId: 4 },
  { title: "Sanh mổ", id: 3, parentId: 4 },
  { title: "Biến chứng thai sản", id: 4, parentId: 4 },
  { title: "Ung thư", id: 5, parentId: 5 },
  { title: "Đau tim/ Đột quỵ", id: 6, parentId: 5 },
  { title: "Súc vật cắn", id: 7 },
  { title: "Bỏng/phỏng", id: 8 },
  { title: "Tai nạn sinh hoạt", id: 9 },
  { title: "Tai nạn lao động", id: 10 },
  { title: "Tai nạn giao thông", id: 11 },
  { title: "Bệnh thông thường", id: 12, parentId: 6 },
  { title: "Đau tim/ Đột quỵ", id: 13, parentId: 6 },
  { title: "Bệnh hiểm nghèo", id: 14, parentId: 6 },
];

export const getListLicenseById = ({
  typeOfInsurance,
  typeOfIndimnity,
  typeOfRisk = null,
}) => {
  if (!typeOfInsurance) return [];

  const getNewId = () => {
    switch (typeOfIndimnity) {
      case 1:
        return 7;
      case 2:
        return 8;
      case 3:
        return 9;
      case 6:
        return 10;
      default:
        return null;
    }
  };

  const newTypeOfIndimnity =
    typeOfInsurance == 1 ? getNewId() : typeOfIndimnity;

  const data = listLicense.reduce((array, item) => {
    const listIdItem = item.typeOfIndimnity.map((i) => i.id);
    const listIdOptional = (item.optional || []).map((i) => i.id);

    if (!typeOfRisk) {
      array = listIdItem.includes(newTypeOfIndimnity)
        ? [
            ...array,
            {
              title: item.title,
              typeId: item.typeId,
              typeCode: item.typeCode,
              nonMultiple: !!item.nonMultiple,
              optional: listIdOptional.includes(newTypeOfIndimnity),
            },
          ]
        : array;
    } else {
      const listTypeOfIndimnityTypeOfRisk =
        item.typeOfIndimnity?.find((i) => i.id == newTypeOfIndimnity)
          ?.typeOfRisk || [];
      const listTypeOfIndimnityOptional =
        item.optional?.find((i) => i.id == newTypeOfIndimnity)?.typeOfRisk ||
        [];

      array =
        listIdItem.includes(newTypeOfIndimnity) &&
        listTypeOfIndimnityTypeOfRisk.includes(typeOfRisk)
          ? [
              ...array,
              {
                title: item.title,
                typeId: item.typeId,
                typeCode: item.typeCode,
                nonMultiple: !!item.nonMultiple,
                optional:
                  listIdOptional.includes(newTypeOfIndimnity) &&
                  listTypeOfIndimnityOptional.includes(typeOfRisk),
              },
            ]
          : array;
    }

    return [...array];
  }, []);

  return data;
};

export const listLicense = [
  {
    title: "Hình chụp CMND/ CCCD/ Hộ chiếu mặt trước và sau",
    typeId: 500,
    typeCode: "private_id",
    nonMultiple: true,
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Biên bản tường trình",
    typeId: 537,
    typeCode: "report",
    typeOfIndimnity: [
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Tóm tắt bệnh án, báo cáo y tế, phiếu chỉ định khám",
    typeId: 531,
    typeCode: "summary_report_health",
    typeOfIndimnity: [
      { id: 2 },
      { id: 4, typeOfRisk: [1, 4] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [4] },
      { id: 7, typeOfRisk: [7, 8] },
      { id: 9, typeOfRisk: [7, 8] },
    ],
  },
  {
    title: "Hồ sơ bệnh án",
    typeId: 502,
    typeCode: "medical_records",
    typeOfIndimnity: [
      { id: 1 },
      { id: 3 },
      { id: 4, typeOfRisk: [2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
    ],
    optional: [
      { id: 1 },
      { id: 4, typeOfRisk: [2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
    ],
  },
  {
    title: "Chỉ định xét nghiệm/ chụp XQ...và kết quả",
    typeId: 504,
    typeCode: "test_xq_and_result",
    typeOfIndimnity: [
      { id: 2 },
      { id: 3 },
      { id: 4, typeOfRisk: [1, 4] },
      { id: 5, typeOfRisk: [5] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [1, 4] },
      { id: 5, typeOfRisk: [5] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Toa thuốc",
    typeId: 507,
    typeCode: "prescription",
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 1 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7] },
    ],
  },
  {
    title: "Phiếu thu chi phí khám",
    typeId: 525,
    typeCode: "examination_receipt",
    typeOfIndimnity: [
      { id: 2 },
      { id: 4, typeOfRisk: [4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Phiếu thu chi phí thuốc",
    typeId: 524,
    typeCode: "prescription_receipt",
    typeOfIndimnity: [
      { id: 2 },
      { id: 4, typeOfRisk: [1, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [1, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Bảng kê chi phí điều trị",
    typeId: 506,
    typeCode: "treatment_cost_checkout",
    optional: true,
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [2, 3, 4] },
      { id: 5, typeOfRisk: [6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [4] },
      { id: 5, typeOfRisk: [6] },
      { id: 8, typeOfRisk: [8, 9, 10, 11] },
    ],
  },
  {
    title: "Hóa đơn thuốc",
    typeId: 509,
    typeCode: "medicine_bill",
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Hóa đơn chi phí khám",
    typeId: 508,
    typeCode: "examination_cost_bill",
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Giấy chứng nhận phẫu thuật (nếu có)",
    typeId: 503,
    typeCode: "surgery_cert",
    typeOfIndimnity: [
      { id: 1 },
      { id: 3 },
      { id: 4, typeOfRisk: [3] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 1 },
      { id: 3 },
      { id: 4, typeOfRisk: [3] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Kết qủa xét nghiệm và mô bệnh học",
    typeId: 513,
    typeCode: "test_result_and_histopathology",
    typeOfIndimnity: [
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [14] },
    ],
    optional: [
      { id: 5, typeOfRisk: [6] },
      { id: 6, typeOfRisk: [14] },
    ],
  },
  {
    title: "Kết quả chẩn đoán hình ảnh, tế bào học",
    typeId: 514,
    typeCode: "diagnose_image_cytology",
    typeOfIndimnity: [
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [14] },
    ],
    optional: [
      { id: 5, typeOfRisk: [6] },
      { id: 6, typeOfRisk: [14] },
    ],
  },
  {
    title: "Giấy ra viện",
    typeId: 510,
    typeCode: "hospital_checkout",
    typeOfIndimnity: [
      { id: 1 },
      { id: 3 },
      { id: 4, typeOfRisk: [2, 3] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [{ id: 3 }, { id: 5, typeOfRisk: [5, 6] }],
  },
  {
    title: "Chi phí tái khám sau xuất viện, đơn thuốc",
    typeId: 511,
    typeCode: "re-examination_cost",
    typeOfIndimnity: [{ id: 4, typeOfRisk: [2, 3] }],
    optional: [{ id: 4, typeOfRisk: [2, 3] }],
  },
  {
    title: "Hóa đơn tái khám sau xuất viện, đơn thuốc",
    typeId: 512,
    typeCode: "re-examination_bill",
    typeOfIndimnity: [{ id: 4, typeOfRisk: [2, 3, 4] }],
    optional: [{ id: 4, typeOfRisk: [2, 3, 4] }],
  },
  {
    title: "Giấy chứng tử/ trích lục khai tử",
    typeId: 515,
    typeCode: "death_cert",
    typeOfIndimnity: [
      { id: 6, typeOfRisk: [12, 13, 14] },
      { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Phiếu chích ngừa/ chỉ định chích ngừa/ sổ theo dõi chích ngừa",
    typeId: 528,
    typeCode: "paper_suggestion_book_tracking_of_vaccine",
    typeOfIndimnity: [{ id: 8, typeOfRisk: [7] }],
  },
  {
    title:
      "Tường trình về tình huống và nguyên nhân tử vong của người được bảo hiểm",
    typeId: 527,
    typeCode: "situation_&_cause_of_death",
    typeOfIndimnity: [{ id: 6, typeOfRisk: [12, 13, 14] }],
    optional: [{ id: 6, typeOfRisk: [12, 13, 14] }],
  },
  {
    title: "Giấy chứng nhận thương tật của cơ quan có thẩm quyền",
    typeId: 532,
    typeCode: "certificate_of_injury",
    typeOfIndimnity: [{ id: 3 }, { id: 9, typeOfRisk: [7, 8, 9, 10, 11] }],
  },
  {
    title:
      "Xác nhận của chính quyền đại phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận nguyên nhân",
    typeId: 533,
    typeCode: "certificate_of_reason",
    typeOfIndimnity: [{ id: 9, typeOfRisk: [7, 8, 9, 10, 11] }],
  },
  {
    title:
      "Biên bản công an điều tra có kết luận nguyên nhân tử vong/ biên bản tai nạn có xác nhận của chính quyền đại phương hoặc cơ quan chủ quản. Bảo cáo y tế/ hồ sơ bệnh án của bệnh viện có kết luận nguyên nhân tử vong",
    typeId: 534,
    typeCode: "death_investigation_report_from_police",
    typeOfIndimnity: [{ id: 10, typeOfRisk: [7, 8, 9, 10, 11] }],
  },
  {
    title:
      "Biên bản phân chia di sản thừa kế có xác nhận của cơ quan có thẩm quyền",
    typeId: 535,
    typeCode: "division_of_inheritance",
    typeOfIndimnity: [{ id: 10, typeOfRisk: [7, 8, 9, 10, 11] }],
  },
  {
    title:
      "Giấy phép lái xe nếu tai nạn khi đang khi đang điều khiển xe trên 50 cc",
    typeId: 536,
    typeCode: "driving_licence",
    typeOfIndimnity: [
      { id: 7, typeOfRisk: [11] },
      { id: 8, typeOfRisk: [11] },
      { id: 9, typeOfRisk: [11] },
      { id: 10, typeOfRisk: [11] },
    ],
    optional: [
      { id: 7, typeOfRisk: [11] },
      { id: 8, typeOfRisk: [11] },
      { id: 9, typeOfRisk: [11] },
      { id: 10, typeOfRisk: [11] },
    ],
  },
  {
    title: "Khác",
    typeId: 523,
    typeCode: "other",
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
      { id: 7, typeOfRisk: [7, 8, 9, 10] },
      { id: 8, typeOfRisk: [7, 8, 9, 10] },
      { id: 9, typeOfRisk: [7, 8, 9, 10] },
      { id: 10, typeOfRisk: [7, 8, 9, 10] },
    ],
    optional: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
      { id: 7, typeOfRisk: [7, 8, 9, 10] },
      { id: 8, typeOfRisk: [7, 8, 9, 10] },
      { id: 9, typeOfRisk: [7, 8, 9, 10] },
      { id: 10, typeOfRisk: [7, 8, 9, 10] },
    ],
  },
];
