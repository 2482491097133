import React, { useEffect, useRef } from "react";
import { Tabs, Box, Tab } from "@mui/material";

import { styledStepHistory } from "../components/step/styledHistory";
import TripBox from "../components/step/TripBox";
import _ from "lodash";

export default function History(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledStepHistory();

  const { historyDestination, historyOrder, tabsStatus } = state;

  useEffect(() => dispatch("initHistory"), []);
  const ref2 = useRef();

  const onScroll = () => {
    if (ref2.current) {
      const scrollDiv = ref2.current;
      const lastItem = scrollDiv.querySelector(
        "#divScroll .scroll-item:last-child"
      );
      const { offsetTop, offsetHeight } = lastItem;
      if (
        Math.abs(offsetTop - scrollDiv.scrollTop) <
        offsetHeight + scrollDiv.offsetHeight
      ) {
        dispatch("getItems");
      }
    }
  };

  return (
    <div
      className={classes.container}
      // style={{ height: isApp ? "calc(100vh - 165px)" : "calc(100vh - 125px)" }}
    >
      <Box className={classes.boxTabs}>
        <Tabs
          value={tabsStatus}
          onChange={(e, newValue) =>
            dispatch({ type: "onChangeTabs", value: newValue })
          }
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab label="Đang xử lý" value={2} />
          <Tab label="Hoàn tất" value={6} />
          <Tab label="Từ chối" value={7} />
          <Tab label="Bổ sung hồ sơ" value={4} />
        </Tabs>
      </Box>
      <div
        className={classes.boxTrip}
        id="divScroll"
        ref={ref2}
        style={{
          height: isApp ? "calc(100vh - 240px)" : "calc(100vh - 225px)",
          overflowY: "auto",
        }}
        onScroll={_.debounce(onScroll, 500)}
      >
        <TripBox
          name="history"
          isSelect={
            historyDestination?.index === 0
              ? 0
              : historyDestination?.index || null
          }
          destination={historyOrder?.data || []}
          onClick={(data) =>
            dispatch({
              type: "onClickDestination",
              data,
              name: "historyDestination",
            })
          }
        />
      </div>
    </div>
  );
}
