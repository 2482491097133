import * as React from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  Button,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/Close";
import imgRefure from "../../../../../assets/health/imgRefure.svg";
import { TextField } from "../overrideMui/index";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogRefuseClaim(props) {
  const { state, dispatch } = props;
  const classes = makeStyle();

  const { dialogRefuseClaim } = state;

  return (
    <Dialog
      open={!!dialogRefuseClaim.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => dispatch("setOpenDialogRefuse")}
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialog}
    >
      <DialogContent style={{ padding: "20px 16px" }}>
        <div className={classes.head}>
          <img src={imgRefure} />
        </div>
        <div style={{ textAlign: "center", margin: "18px 0" }}>
          Chúng tôi rất lấy làm tiếc vì sự bất tiện này. Quý khách hãy nhập nội
          dung từ chối thông báo tại ô bên dưới. Bộ phận chăm sóc khách hàng của
          chúng tôi sẽ liên hệ với Quý khách trong thời gian sớm nhất.{" "}
        </div>
        <TextField
          multiline
          fullWidth
          rows={6}
          placeholder="Nhập nội dung"
          value={dialogRefuseClaim?.text || ""}
          onChange={(e) =>
            dispatch({ type: "onChangTextNoteRefuse", value: e?.target?.value })
          }
        />
        <div className={classes.buttons}>
          <Button
            className="btn-refuse btn"
            onClick={() => dispatch("setOpenDialogRefuse")}
          >
            Đóng
          </Button>
          <Button
            className="btn-agree btn"
            onClick={() => {
              dispatch("sendRequestRefuse");
            }}
          >
            Gửi
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      width: "100%",
      maxWidth: "550px",
      margin: 0,
      position: "absolute",
      bottom: 0,
    },
  },

  head: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "24px",
  },

  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    margin: "18px 0",

    "& .btn": {
      width: "40%",
      textTransform: "none",
      borderRadius: "9px",
      border: "1px solid #0B3C8A",
      boxShadow: "0px 10px 12px rgba(0, 0, 0, 0.2)",
    },

    "& .btn-refuse": {
      color: "#0B3C8A",
    },

    "& .btn-agree": {
      color: "white",
      backgroundColor: "#0B3C8A",
    },

    "& .btn-agree:hover": {
      backgroundColor: "#0B3C8A",
    },
  },
}));
