import React from "react";
import moment from "moment";
import { get, isEmpty } from "lodash";
import { Button, IconButton } from "@mui/material";

import { styleTabsRight } from "../../styles/styleTabsRight";
// import DialogNoti from "../DialogNoti";
// import DialogNote from "./dialogNote";
import AddIcon from "../../../../../assets/health/add-icon.svg";
import AttatchIcon from "../../../../../assets/health/attatch-icon.svg";
import { getStatusById } from "../../util";

export default function ApproveTabsRight(props) {
  const { state, dispatch } = props;
  const classes = styleTabsRight();

  const {
    detail,
    dialogNoti,
    listNote,
    currentFilePdf,
    tabButtonTabsRight,
    rowsHistoryTable1,
  } = state;
  const { elements = [] } = detail;

  const getComment = (item) => {
    const data_element_note = detail?.elements?.filter(
      (i) =>
        i.type_id === 520 &&
        i.status_id != 2 &&
        get(i, "extra_info.documents[0].path") ===
          get(item, "extra_info.documents[0].path")
    );
    return (data_element_note || []).reverse();
  };

  return (
    <>
      <div className={classes.containerStatus}>
        <div className={classes.row}>
          <div className="box-button">
            <Button
              onClick={() =>
                dispatch({ type: "changeButtonRightTabs", name: "new-approve" })
              }
              className={
                tabButtonTabsRight === "new-approve"
                  ? "button btn-new btn-active"
                  : "button btn-new"
              }
              variant="outlined"
            >
              Mới
            </Button>
            <Button
              onClick={() =>
                dispatch({ type: "changeButtonRightTabs", name: "approved" })
              }
              className={
                tabButtonTabsRight === "approved"
                  ? "button btn-approve btn-active"
                  : "button btn-approve"
              }
              variant="outlined"
            >
              Đã duyệt
            </Button>
          </div>
          <div
            className={classes.boxAdd}
            onClick={() =>
              dispatch({ type: "openDialogNote", name: "addNote" })
            }
          >
            <img src={AddIcon} alt="add-icon" className="icon color" />
            <span className="color">Thêm ghi chú</span>
          </div>
        </div>
        {tabButtonTabsRight == "new-approve" && (
          <div className={classes.box}>
            <div className="bold">Yêu cầu duyệt</div>
            <div className="gray">
              {moment(currentFilePdf?.data_element?.created_date).format(
                "HH:mm DD/MM/YYYY"
              )}
            </div>
            <div>{`Hồ sơ số ${detail?.code || ""}  chờ phê duyệt`}</div>
            {(currentFilePdf?.data_element_note || []).map((item, key) =>
              item?.text ? (
                <div className="gray spacing" key={`not-elements-${key}`}>
                  {`${item?.extra_info?.user?.userNote?.fullname || ""}:`}{" "}
                  <span className="color"> {item?.text || ""}</span>
                </div>
              ) : null
            )}
            <div className={classes.row}>
              <div>
                <IconButton
                  style={{ padding: "4px" }}
                  onClick={() => {
                    window.open(currentFilePdf?.path);
                  }}
                >
                  <img src={AttatchIcon} alt="attatch" className="icon color" />
                </IconButton>
                1
              </div>
              {(1 == 2 && currentFilePdf?.data_element?.status_id == 2) ||
              (1 == 1 && currentFilePdf?.data_element?.status_id == 3) ? (
                <></>
              ) : [4, 5, 6, 7, 8, 9, 10].includes(
                  +(currentFilePdf?.data_element?.status_id || 0)
                ) ? (
                <div
                  style={{
                    color: getStatusById(
                      currentFilePdf?.data_element?.status_id
                    ).color,
                  }}
                >
                  {getStatusById(currentFilePdf?.data_element?.status_id).label}
                </div>
              ) : (
                <div>
                  {1 !== 1 && (
                    <Button
                      className={
                        // users?.id != detail?.userIdAssign
                        true ? "refuse refuse-disabled" : "refuse"
                      }
                      // disabled={users?.id != detail?.userIdAssign}
                      variant="outlined"
                      onClick={() =>
                        dispatch({
                          type: "openDialogNote",
                          name: "submitRefuse",
                        })
                      }
                    >
                      Từ chối
                    </Button>
                  )}
                  <Button
                    className={1 != 1 ? "agree agree-disabled" : "agree"}
                    variant="contained"
                    disabled={1 != 1}
                    onClick={() =>
                      dispatch({
                        type: "openDialogNote",
                        name: 1 == 1 ? "sendRequestApprpve" : "submitApprove",
                      })
                    }
                  >
                    {1 == 1 ? "Gửi yêu cầu" : "Phê duyệt"}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}

        {tabButtonTabsRight == "approved" &&
          rowsHistoryTable1?.map((item, index) =>
            [5, 6, 7, 8].includes(item?.status_id) ? (
              <div className={classes.box} key={`approve-${index}`}>
                <div className="bold">Yêu cầu duyệt</div>
                <div className="gray">
                  {moment(currentFilePdf?.data_element?.created_date).format(
                    "HH:mm DD/MM/YYYY"
                  )}
                </div>
                <div>{`Hồ sơ số ${detail?.code || ""}  chờ phê duyệt`}</div>
                {(getComment(item) || []).map((itemComment, key) =>
                  !!itemComment?.text ? (
                    <div className="gray spacing" key={`not-elements-${key}`}>
                      {`${
                        itemComment?.extra_info?.user?.userNote?.fullname || ""
                      }:`}{" "}
                      <span className="color"> {itemComment?.text || ""}</span>
                    </div>
                  ) : null
                )}
                <div className={classes.row}>
                  <div>
                    <IconButton
                      style={{ padding: "4px" }}
                      onClick={() => {
                        window.open(get(item, "extra_info.documents[0].path"));
                      }}
                    >
                      <img
                        src={AttatchIcon}
                        alt="attatch"
                        className="icon color"
                      />
                    </IconButton>
                    1
                  </div>

                  <div
                    style={{
                      color: getStatusById(item?.status_id).color,
                    }}
                  >
                    {getStatusById(item?.status_id).label}
                  </div>
                </div>
              </div>
            ) : null
          )}
        {[{}].map((item, index) => (
          <div className={classes.box} key={`note_coment_${index}`}>
            <div className="bold">Ghi chú</div>
            <div className="gray">
              {moment(item?.created_date).format("HH:mm, DD/MM/YYYY")}
            </div>
            {item.type_id == 520 ? (
              <div className="gray">{`Bởi Khách hàng`}</div>
            ) : (
              <div className="gray">{`Bởi ${
                item?.extra_info?.user?.userNote?.fullname || ""
              } (cấp ${
                item?.extra_info?.user?.userNote?.level_id || ""
              })`}</div>
            )}
            <div className="color">{item?.text || ""}</div>
          </div>
        ))}
        {/* <DialogNote {...{ state, dispatch, level_id }} />
        <DialogNoti {...{ state, dispatch, data: dialogNoti }} /> */}
      </div>
    </>
  );
}
