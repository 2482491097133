import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Slide,
} from "@mui/material";
import React from "react";
import { useStyles } from "../useStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddBenefitGroup(props) {
  const { open, dataInfo, dispatch } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"sm"}
      onClose={() => dispatch({ type: "onClose", name: "benefit" })}
      className={classes.addDialog}
    >
      <DialogTitle>Thêm nhóm quyền lợi</DialogTitle>
      <DialogContent>
        <div className="title">Thêm nhóm quyền lợi</div>
        <Select
          fullWidth
          value={dataInfo?.benefits || null}
          onChange={(e, value) =>
            dispatch({
              type: "onSelect",
              name: "benefits",
              value,
            })
          }
        >
          {[].map((i) => (
            <MenuItem value={i?.id}>{i?.title || ""}</MenuItem>
          ))}
        </Select>
        <div className="center">
          <Button
            disabled={!dataInfo?.benefits}
            className="button"
            onClick={() => dispatch("addBenefit")}
          >
            Thêm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AddBenefitGroup;
