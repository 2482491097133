import { atom } from "recoil";

export const dataSearchList = atom({
  key: "dataSearchListAccount",
  default: {},
});

export const embededFiNaRC = atom({
  key: "embededFiNa",
  default: null,
});
