import moment from "moment";

export default function TableExportExcel(props) {
  const { dataSearch, roleType } = props;

  const fromDate = !dataSearch?.from
    ? "..............."
    : moment(dataSearch?.from).format("DD/MM/YYYY");
  const toDate = !dataSearch?.to
    ? "..............."
    : moment(dataSearch?.to).format("DD/MM/YYYY");

  const getRoleType = (role) => {
    switch (role) {
      case "globalcare":
        return "Global Care";
      case "provider":
        return "Nhà bảo hiểm";
      case "merchant":
        return "Merchant";

      default:
        break;
    }
  };
  const getCompensation = (item) => {
    return item?.elements?.find((i) => i.type_id === 203) || {};
  };

  // const dataTable = (item = {}, index = 0) => [
  //   { header: "STT", body: index + 1 },
  //   { header: "Họ Và Tên Người tạo đơn", body: "" },
  //   { header: "SỐ ĐT KH", body: "" },
  //   { header: "Mã Hồ Sơ Bồi Thường", body: item?.code || "" },
  //   {
  //     header: "Mã đơn hàng Ahamove",
  //     body: item?.extraInfo?.partnerOrderCode || "",
  //   },
  //   { header: "Số tiền KH mua bảo hiểm", body: "" },
  //   { header: "Ngày Tạo Đơn", body: "" },
  //   {
  //     header: "Ngày giờ claim BH",
  //     body: item?.extraInfo?.createdDate
  //       ? moment(item?.extraInfo?.createdDate).format("DD/MM/YYYY HH:mm")
  //       : "",
  //   },
  //   { header: "Ngày/ giờ cập nhật tình trạng Claim BH", body: "" },
  //   { header: "Số tiền khách hàng mua Bảo hiểm (COD/Iterm Value)", body: "" },
  //   { header: "Số tiền KH request Bồi thường", body: "" },
  //   { header: "Số tiền BH chấp nhận đền bù", body: "" },
  //   { header: "Trạng thái hàng hóa tổn thất", body: "" },
  //   {
  //     header:
  //       "Lý do duyệt/ không duyệt hồ sơ (Chi tiết + cuộc gọi với khách hàng, tài xế, các bên liên quan nếu cần) (nếu có)",
  //     body: getCompensation(item)?.text || "",
  //   },
  //   { header: "Lý do chung (case Reject)", body: "" },
  //   { header: "Trạng Thái Claim BH (Reject, Approved, Check)", body: "" },
  //   {
  //     header:
  //       "Updated Trạng Thái Claim BH cuối cùng của các đơn check (Reject, Approved)",
  //     body: "",
  //   },
  // ];

  return (
    <>
      <div style={{ display: "none" }}>
        <table
          className="collapse-border"
          id={"export-data-table"}
          data-cols-width="7, 15, 25, 17, 30, 15, 17, 17, 20, 20, 20, 15, 35, 15, 20, 15, 20, 20, 35"
        >
          <thead style={{ fontSize: "13" }}>
            <tr />
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"16"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                BÁO CÁO CHI TIẾT HỒ SƠ BỒI THƯỜNG_BẢO HIỂM INTRANSIT
              </th>
            </tr>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >{`Từ ngày: ${fromDate} Đến Ngày: ${toDate} `}</th>
            </tr>
            <tr />
            <tr>
              <th
                colSpan={3}
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-wrap={true}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                {`Bên xuất báo cáo: ${getRoleType(roleType)}`}
              </th>
            </tr>
            <tr />
            <tr />
            <tr style={{ fontSize: "13" }}>
              <Th>STT</Th>
              <Th>Ngày tạo HSBT</Th>
              <Th>Mã hồ sơ bồi thường</Th>
              <Th>Kênh khai thác</Th>
              <Th>Họ và tên người được bảo hiểm</Th>
              <Th>SĐT NĐBH</Th>
              <Th>Mã đơn hàng vận chuyển</Th>
              <Th>Mã chuyến đi</Th>
              <Th>Loại thanh toán</Th>
              <Th>Loại hàng hóa</Th>
              <Th>Trạng thái đơn hàng</Th>
              <Th>Ngày xảy ra sự cố</Th>
              <Th>Mô tả diễn biến</Th>
              <Th>{"Giá trị hàng hóa (VNĐ)"}</Th>
              <Th>Đối tượng bảo hiểm</Th>
              <Th>Rủi ro gặp phải</Th>
              <Th>Trạng thái bồi thường</Th>
              <Th>{"Số tiền phê duyệt (VNĐ)"}</Th>
              <Th>Nội dung</Th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13" }}>
            {props.data.map((item, index) => {
              return (
                <tr key={index} style={{ fontSize: "13" }}>
                  <Td>{index + 1}</Td>
                  <Td>
                    {item?.extraInfo?.createdDate
                      ? moment(item?.extraInfo?.createdDate).format(
                          "DD/MM/YYYY"
                        )
                      : ""}
                  </Td>
                  <Td>{item?.code || ""}</Td>
                  <Td>{item?.merchantTitle || ""}</Td>
                  <Td>{item?.insuredInfo?.insuredName || ""}</Td>
                  <Td>{item?.insuredInfo?.insuredPhone || ""}</Td>
                  <Td>{item?.extraInfo?.partnerOrderCode || ""}</Td>
                  <Td>{item?.extraInfo?.codeDestination || ""}</Td>
                  <Td>
                    {item?.extraInfo?.paymentInfo?.paymentId === 1
                      ? "Ứng thu hộ COD"
                      : "Không ứng COD"}
                  </Td>
                  <Td>
                    {item?.extraInfo?.productInfo?.typeNameOfProduct || ""}
                  </Td>
                  <Td>
                    {item?.extraInfo?.statusId === 0
                      ? "Thất bại"
                      : "Thành công"}
                  </Td>
                  <Td>
                    {item?.extraInfo?.riskDate
                      ? moment(item?.extraInfo?.riskDate).format("DD/MM/YYYY")
                      : ""}
                  </Td>
                  <Td>{item?.extraInfo?.describe || ""}</Td>
                  {/* <Td>
                    {item?.extraInfo?.productAmount || ""}
                    {item?.extraInfo?.productAmount
                      ? `${item?.extraInfo?.productAmount}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " vnđ"
                      : ""}
                  </Td> */}
                  <th
                    data-f-name={"Times new roman"}
                    data-f-sz={"12"}
                    data-b-a-s={"thin"}
                    data-a-wrap={true}
                    data-t="n"
                  >
                    {item?.extraInfo?.productAmount || ""}
                  </th>
                  <Td>
                    {+item?.extraInfo?.insuranceObj === 1
                      ? "Hàng hoá"
                      : +item?.extraInfo?.insuranceObj === 2
                      ? "Hàng hoá có hoá đơn"
                      : +item?.extraInfo?.insuranceObj === 3
                      ? "Hàng hoá không có hoá đơn"
                      : +item?.extraInfo?.insuranceObj === 4
                      ? "Tài liệu"
                      : ""}
                  </Td>
                  <Td>
                    {item?.extraInfo?.typeOfAccident === 1
                      ? "Hư hỏng"
                      : "Mất mát"}
                  </Td>
                  <Td>
                    {item.statusId === 2
                      ? "Đang xử lý"
                      : item.statusId === 3
                      ? "Đang đợi phê duyệt"
                      : item.statusId === 4
                      ? "Bổ sung hồ sơ"
                      : item.statusId === 5
                      ? "Phê duyệt"
                      : item.statusId === 6
                      ? "Hoàn tất"
                      : item.statusId === 7
                      ? "Từ chối"
                      : item.statusId === 8
                      ? "Khách hàng từ chối"
                      : ""}
                  </Td>
                  <th
                    data-f-name={"Times new roman"}
                    data-f-sz={"12"}
                    data-b-a-s={"thin"}
                    data-a-wrap={true}
                    data-t="n"
                  >
                    {item?.amount ? item?.amount : ""}
                  </th>
                  {/* <Td>
                    {getCompensation(item)?.value
                      ? getCompensation(item)?.value
                      : ""}
                    {getCompensation(item)?.value
                      ? `${getCompensation(item)?.value}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          "."
                        ) + " vnđ"
                      : ""}
                  </Td> */}
                  <Td>{getCompensation(item)?.text || ""}</Td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const Th = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-h={"center"}
      data-a-wrap={true}
      data-f-bold={true}
    >
      {props.children}
    </th>
  );
};

const Td = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-wrap={true}
    >
      {props.children}
    </th>
  );
};
