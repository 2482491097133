import React, { useEffect } from "react";
import { IconButton, TableCell, Autocomplete, Popper } from "@mui/material";
import NumberFormat from "react-number-format";
import { createFilterOptions } from "@mui/material/Autocomplete";

import CommonTable from "../../../../../../../library/layout/commonTable";
import styleDialog from "./styleDialog";
import { TextField } from "../../../overrideMui";

import addSquare from "../../../../../../../assets/health/add-square.svg";
import minusSquare from "../../../../../../../assets/health/minus-square.svg";
import _ from "lodash";

export default function OtherTableUpdate(props) {
  const { state, dispatch } = props;
  const classes = styleDialog({
    widthTable: 810,
  });

  const { rowsOtherTables, detail, listBenefits } = state;

  const getDataByTypeOfIndimnity = () => {
    switch (+detail?.extraInfo?.typeOfIndimnity) {
      case 3:
        return {
          name: "injuries",
          thName: "Loại thương tật",
          columns: [...columnsTable],
          title: "Tỉ lệ thương tật",
        };
      case 5:
        return {
          name: "canceOrFatalDiseaseOrStroke",
          thName: "Loại bệnh",
          rows: [],
          columns: [...columnsTable.filter((i) => !i.dontShow)],
          title: "Ung thư/ bệnh hiểm nghèo/ đột quỵ",
        };
      case 6:
        return {
          name: "deaths",
          thName: "Nguyên nhân tử vong",
          rows: [],
          columns: [...columnsTable.filter((i) => !i.dontShow)],
          title: "Tử vong",
        };
      default:
        return {};
    }
  };

  useEffect(() => {}, []);

  return (
    <div className={classes.container_diagnosis}>
      <CommonTable
        classes={classes}
        rows={rowsOtherTables}
        columns={getDataByTypeOfIndimnity()?.columns || []}
        data={{
          name: getDataByTypeOfIndimnity().name,
          thName: getDataByTypeOfIndimnity().thName,
          listBenefits,
        }}
        event={(type, data) =>
          (ele, ...rest) => {
            dispatch({ type, data, ele, rest });
          }}
      />
    </div>
  );
}

const columnsTable = [
  {
    label: "Mã QL",
    headerClass: "code-QL",
    renderCell: ({ rowIdx, classes, item, event, data }) => {
      return (
        <TableCell className="code-QL">
          {
            <Autocomplete
              loading
              key={`${rowIdx}_${item.titleBenefit}`}
              freeSolo
              disableClearable
              noOptionsText={"Không tìm thấy dữ liệu"}
              PopperComponent={(props) => {
                return (
                  <Popper
                    {...props}
                    style={{
                      width: "480px",
                    }}
                    placement={"bottom-start"}
                  >
                    {props.children}
                  </Popper>
                );
              }}
              options={data?.listBenefits || []}
              getOptionLabel={(option) => option?.code_benefit}
              renderOption={(props, option) => (
                <div {...props} style={{ width: "100%" }}>
                  {option?.code_benefit || ""} - {option?.title || ""}
                </div>
              )}
              className={`${classes.autocomplete}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  className={classes.textField}
                />
              )}
              filterOptions={(options, params) => {
                const temp = options.filter((i) => {
                  return (
                    i.code_benefit
                      .toLowerCase()
                      .includes(params?.inputValue.toLowerCase()) ||
                    i.title
                      .toLowerCase()
                      .includes(params?.inputValue.toLowerCase())
                  );
                });
                return [...temp];
              }}
              defaultValue={{
                code_benefit: item?.codeBenefit || "",
                title: item?.titleBenefit || "",
              }}
              onChange={event(`otherTableOnChangText`, {
                index: rowIdx,
                name: "QL_code",
              })}
            />
          }
        </TableCell>
      );
    },
  },
  {
    renderHeader: ({ data }) => (
      <TableCell className="name-service">{data?.thName || ""}</TableCell>
    ),
    renderCell: ({ rowIdx, classes, data, event, item }) => (
      <TableCell className="otherText">
        <TextField
          fullWidth
          className={classes.textField}
          onChange={event(`otherTableOnChangText`, {
            index: rowIdx,
            name: "otherText",
          })}
          value={item?.otherText || ""}
        />
      </TableCell>
    ),
  },
  {
    label: "Tỉ lệ (%)",
    headerClass: "percent",
    dontShow: true,
    renderCell: ({ rowIdx, classes, data, event, item }) => (
      <TableCell className="percent">
        <TextField
          fullWidth
          className={classes.textField}
          onChange={event(`otherTableOnChangText`, {
            index: rowIdx,
            name: "percent",
          })}
          value={item?.percent || 0}
        />
      </TableCell>
    ),
  },
  {
    label: "Quyền lợi",
    headerClass: "benefitAmount",
    renderCell: ({ rowIdx, classes, item, event, data }) => (
      <TableCell className="benefitAmount">
        <NumberFormat
          customInput={TextField}
          className={classes.textField}
          // readOnly={true}
          disabled={true}
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          // onChange={event(`otherTableOnChangText`, {
          //   index: rowIdx,
          //   name: "benefitAmount",
          // })}
          value={item.benefitAmount || ""}
          fullWidth
        />
      </TableCell>
    ),
  },

  {
    label: "Thành tiền",
    headerClass: "into-money",
    renderCell: ({ rowIdx, classes, data, event, item }) => (
      <TableCell className="into-money">
        <NumberFormat
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          className={classes.textField}
          onChange={event(`otherTableOnChangText`, {
            index: rowIdx,
            name: "fee",
          })}
          value={item.fee || ""}
          fullWidth
        />
      </TableCell>
    ),
  },
  {
    renderHeader: ({ event }) => (
      <TableCell className="icon sticky">
        <IconButton
          onClick={event("addRowsTableUpdate", { name: "otherTable" })}
        >
          <img src={addSquare} />
        </IconButton>
      </TableCell>
    ),
    renderCell: ({ event, rowIdx }) => (
      <TableCell className="icon sticky">
        <IconButton
          onClick={event("deleteRowsTableUpdate", {
            name: "otherTable",
            index: rowIdx,
          })}
        >
          <img src={minusSquare} />
        </IconButton>
      </TableCell>
    ),
  },
];
