import { makeStyles } from "@mui/styles";

export const groupRolesStyle = makeStyles(() => ({
  container: {
    width: "100%",
    padding: "24px 0 12px 0",
    borderRight: "1px solid #C0C0C0",
  },

  title_list: {
    fontSize: "20px",
    color: "#4B4B4B",
    fontWeight: "700",
    padding: "0 12px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .buttons-delete": {
      color: "#4B4B4B",
      border: "1px solid #DCDCDC",
      padding: "6px 16px",
    },

    "& .buttons-save": {
      color: "white",
      background: "#0B7CE4",
      marginLeft: "12px",
      padding: "6px 16px",
    },

    "& .disabled": {
      color: "white",
      background: "#A0A0A0",
    },
  },

  conten: {
    width: "100%",
    margin: "12px 0",
  },

  group_role: {
    height: "100%",
    width: "100%",
    background: "#F8F8F8",

    "& .title-group-role": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "55px",
      padding: "0 12px",
      fontSize: "16px",
      fontWeight: "600",
      color: "#4B4B4B",
      borderBottom: "1px solid #C0C0C0",

      "& .buttons-add": {
        color: "#0B7CE4",
        border: "1px solid #0B7CE4",
        borderRadius: "8px",
      },
    },

    "& .item-group-role": {
      padding: "12px 12px",
      fontSize: "14px",
      fontWeight: "600",
      minHeight: "52px",
      display: "flex",
      alignItems: "center",

      "&:hover": { background: "#b0e2ff52", cursor: "pointer" },
    },

    "& .chonsen": {
      color: "#3961A0",
      background: "white",
      justifyContent: "space-between",
      paddingRight: "2px",
    },
  },

  list_role: {
    width: "100%",
    padding: "0 12px",

    "& .title-list-role": {
      display: "flex",
      alignItems: "center",
      height: "55px",
      fontSize: "16px",
      fontWeight: "600",
      color: "#4B4B4B",
      borderBottom: "1px solid #C0C0C0",
    },

    "& .item-list-role": {
      margin: "16px 0",

      "& .item-check-box": {
        margin: "8px 0",
        display: "flex",
        alignItems: "center",
      },
    },
  },

  checkBox: {
    marginRight: "12px",
  },

  //
}));
