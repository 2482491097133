import React, { useEffect } from "react";
import { Button, Card, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { PDFDownloadLink } from "@react-pdf/renderer";

import Snackbars from "../../../../../ui/snackbar";
import useReducer from "../../../../../library/hooks/useReducer";
import CardInfor from "../components/CardInfor";
import CardDocument from "../components/CardDocument";
import CardHistory from "../components/CardHistory";
import ShowImgDialog from "../components/DialogShowImg";
import ConfirmDialog from "../components/confirmDialog";
import detailReducer from "../reducer/detailReducer";
import { useStyles } from "../styles/useStyles";
import ExportPDF_PBA from "../components/ExportPDF-PBA";

import { getParamUrlByKey } from "../../../../../utils/utils";
import EyeIcon from "../../intransit/assets/eyeIcon.svg";
import Download from "../../intransit/assets/software-download.svg";

const initialState = {
  rows: [],
  dataExport: [],
  documentsAdditionalImg: [],
  renderListImg: [],
  row: {},
  showImg: {},
  dataSearch: {},
  errors: {},
  snackbar: {},
  pageTable: {
    count: 0,
    page: 0,
    rowsPerPage: 10,
  },
  count: 0,
  approvalAmount: "",
  content: "",
  setPage: "compensation",
  loading: false,
  confirmDialog: false,
};

export default function DetailReport(props) {
  const { form } = props;
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const roleType = user?.extra_info?.userType;
  const id = getParamUrlByKey("id");

  const [state, dispatch] = useReducer({
    initialState,
    reducer: detailReducer,
    name: "detailReducer",
    roleType,
    id,
  });
  const {
    row,
    loading,
    confirmDialog,
    snackbar,
    statusId = 0,
    initStatusId = 0,
    updated,
  } = state;

  const statusCurent = () => {
    switch (row.statusId) {
      case 2:
        return { color: "#26A9E0", text: "Đang xử lý" };
      case 4:
        return { color: "#FB7800", text: "Bổ sung hồ sơ" };
      case 6:
        return { color: "#39B44A", text: "Hoàn tất" };
      case 7:
        return { color: "#BF272D", text: "Từ chối" };

      default:
        break;
    }
  };

  useEffect(() => dispatch("initial"), []);

  let dataFilePdf = null;

  return (
    <div className={classes.container}>
      <div className="head-title">
        {form === "review-claim"
          ? "Kiểm Tra Xử Lý Hồ Sơ/"
          : "Báo Cáo Bồi Thường/"}{" "}
        <span style={{ textTransform: "capitalize" }}>
          {row?.insuredInfo?.insuredName}{" "}
        </span>
      </div>
      <div style={{ fontSize: "18px", color: "#000000", fontWeight: "600" }}>
        Trạng thái:{" "}
        <span
          style={{
            color: statusCurent()?.color,
          }}
        >
          {" " + statusCurent()?.text}{" "}
        </span>
      </div>
      <Card sx={{ padding: "6px", marginTop: "25px" }}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} sm={12} md={4}>
            <Card className={classes.infor}>
              <CardInfor {...{ row, dispatch }} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
              }}
            >
              <Card className={classes.document}>
                <CardDocument {...{ form, state, dispatch }} />
              </Card>
              <div className={classes.exportButton}>
                <Button
                  className="bt-show"
                  onClick={() => {
                    window.open(row?.insuredInfo?.certLink || "");
                  }}
                >
                  <img src={EyeIcon} style={{ marginRight: "6px" }} />
                  Xem Chứng Nhận Bảo Hiểm
                </Button>
                {roleType === "globalcare" && (
                  <>
                    <div style={{ display: "none" }}>
                      <PDFDownloadLink
                        document={<ExportPDF_PBA data={row} />}
                        fileName="Hồ sơ trả tiền bảo hiểm.pdf"
                      >
                        {({ blob, url, loading: loadingPDF, error }) => {
                          dataFilePdf = blob;
                        }}
                      </PDFDownloadLink>
                    </div>
                    <Button
                      onClick={() =>
                        dispatch({ type: "downLoadZip", file: dataFilePdf })
                      }
                      style={{
                        textDecoration: "none",
                        backgroundColor: "#FB7800",
                        fontWeight: "600",
                        fontSize: "14px",
                        borderRadius: "8px",
                        padding: "6px",
                        background: "#FB7800",
                        color: "white",
                      }}
                    >
                      <img src={Download} style={{ marginRight: "6px" }} />
                      Tải Hồ Sơ
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card className={classes.history}>
              <CardHistory {...{ form, state, dispatch }} />
            </Card>
          </Grid>
        </Grid>
      </Card>
      <div className={classes.buttonDiv}>
        <Button className={classes.button} onClick={() => dispatch("back")}>
          Quay lại
        </Button>
        {form === "review-claim" && (
          <Button
            disabled={
              statusId === 2 ||
              initStatusId === 7 ||
              initStatusId === 6 ||
              updated
            }
            className={
              statusId === 2 ||
              initStatusId === 7 ||
              initStatusId === 6 ||
              updated
                ? "disabled button"
                : "button"
            }
            onClick={() => dispatch("updata")}
          >
            Cập nhập
          </Button>
        )}
      </div>
      <ShowImgDialog {...{ dispatch, state }} />
      <ConfirmDialog {...{ dispatch, open: confirmDialog, loading }} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbars
        open={snackbar.open}
        onClose={() => dispatch("closeSnackbar")}
        message={snackbar.message}
      />
    </div>
  );
}
