import React from "react";
import { makeStyles } from "@mui/styles";
import { Autocomplete, Box, TextField } from "@mui/material";

export default function AutocompleteWithIcon(props) {
  const classes = styled();
  const {
    options = [],
    value = null,
    onChange,
    label,
    placeholder,
    getOptionLabel,
    renderOption,
  } = props;

  return (
    <div className={classes.inputDiv} key={"AutocompleteWithIcon"}>
      <Autocomplete
        disablePortal
        key={"AutocompleteWithIcon"}
        id="combo-box-demo"
        options={options}
        value={value || {}}
        sx={{ width: "100%" }}
        getOptionLabel={(option) => option[getOptionLabel] || ""}
        renderOption={(props, option) => (
          <Box
            style={{ paddingLeft: "6px" }}
            component="li"
            sx={{
              "& > img": { mr: 2, flexShrink: 0 },
              paddingLeft: "6px",
            }}
            {...props}
          >
            <div className={classes.bankImg}>
              <img
                loading="lazy"
                style={{ width: "100%", height: "100%" }}
                src={option[renderOption.urlImg]}
                alt={option[renderOption.title]}
              />
            </div>
            <div className={classes.bankOption}>
              <span className="short-name">{option[renderOption.title]}</span>
              <span className="name">{option.name}</span>
            </div>
          </Box>
        )}
        onChange={(e, data) => onChange(e, data)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            className={classes.textAuto}
            placeholder={placeholder}
            style={{ fontSize: "16px" }}
          />
        )}
      />
      {label && (
        <div className="shrink" key={label}>
          {label} <span style={{ color: "red" }}>*</span>
        </div>
      )}
    </div>
  );
}

const styled = makeStyles(() => ({
  textAuto: {
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      padding: "1px 65px 1px 0",
      fontSize: "16px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    },
  },

  inputDiv: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "10px",
    padding: "12px 8px",
    display: "flex",
    position: "relative",
    marginTop: "12px",

    "& .MuiInputBase-root:before": {
      border: "none !important",
    },

    "& .MuiInputBase-root:after": {
      border: "none !important",
    },

    "& .MuiInputBase-input": {
      boxSizing: "border-box",
    },

    "& .shrink": {
      position: "absolute",
      background: "white",
      padding: "0 6px",
      lineHeight: "18px",
      top: "-11px",
      left: "16px",
      color: "#284078",
      fontWeight: "500",
      fontSize: "15px",
    },

    "&:hover": {
      borderColor: "#284078",
      borderWidth: "2px",
    },
  },

  bankImg: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: "#E8E8E8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "8px",
    padding: "10px",
  },
}));
