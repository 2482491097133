import { get, isEmpty, isString, set } from "lodash";
import { SSF, read, utils } from "xlsx";

import {
  getDetailProduct,
  getListProductPackage,
  getDetailProductPackage,
  getListBenefits,
  createProductPackage,
  updateProductPackage,
} from "../../../../apis/productApi_v2";
import { removeUnicode } from "../../../../utils/utils";
import { conver_type_of_indemnify } from "../util";

const mapData = (data = []) => {
  if (data.length === 0) return [];

  return data.map((i) => {
    const regex = /\./g;
    if (!i.code_benefit.includes(".")) {
      return { ...i, isFirst: true };
    }
    if (i.code_benefit.match(regex).length === 1) {
      return { ...i, isSecond: true };
    }
    return i;
  });
};

const sortData = (array) => {
  return array.sort((a, b) => {
    const [aParts, bParts] = [
      a.code_benefit.split("."),
      b.code_benefit.split("."),
    ];
    const minLength = Math.min(aParts.length, bParts.length);

    for (let i = 0; i < minLength; i++) {
      const aIsNumber = /^\d+$/.test(aParts[i]);
      const bIsNumber = /^\d+$/.test(bParts[i]);

      if (aIsNumber && bIsNumber) {
        const aPartNum = parseInt(aParts[i]);
        const bPartNum = parseInt(bParts[i]);

        if (aPartNum !== bPartNum) {
          return aPartNum - bPartNum;
        }
      } else {
        const comparison = aParts[i].localeCompare(bParts[i]);
        if (comparison !== 0) {
          return comparison;
        }
      }
    }

    return aParts.length - bParts.length;
  });
};

const readFile = (file, benefits) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const res = {};
    reader.onload = (event) => {
      const wb = read(event.target.result, { type: "binary" });
      const sheets = wb.Sheets[wb.SheetNames] || {};

      if (!isEmpty(sheets)) {
        const keyB = Object.keys(sheets).filter(
          (key) =>
            key.toString().includes("B") &&
            removeUnicode(
              get(sheets, `${key}.v`, "")?.toLowerCase()?.replace(/\s/g, "")
            ) !== "manv"
        );
        res.rows = keyB.reduce((arrays, keyB) => {
          const valueB = get(sheets, `${keyB}`) || {};
          const valueC = get(sheets, `${keyB.replace(/B/g, "C")}`) || {};
          // const valueD = get(sheets, `${keyB.replace(/B/g, "D")}`) || {};
          // const valueE = get(sheets, `${keyB.replace(/B/g, "E")}`) || {};
          const valueF = get(sheets, `${keyB.replace(/B/g, "F")}`) || {};
          const valueH = get(sheets, `${keyB.replace(/B/g, "H")}`) || {};
          const valueG = get(sheets, `${keyB.replace(/B/g, "G")}`) || {};

          const itemBenefitAPI = benefits.find(
            (i) => i.code_benefit === valueB?.v || i.code_benefit === valueB?.h
          );

          if (isEmpty(itemBenefitAPI)) {
            return arrays;
          }

          const item = { ...(itemBenefitAPI || {}) };
          const levelBennefit =
            item?.config_benefit?.benefit_level ||
            (valueB?.v || valueB?.h).split(".")?.length;

          if (levelBennefit === 1 || levelBennefit === 2) {
            item.isFirst = levelBennefit === 1;
            item.isSecond = levelBennefit === 2;
            const type_of_indemnify = conver_type_of_indemnify(
              valueC?.v || valueC?.h || ""
            );
            item.config_benefit = {
              ...item.config_benefit,
              type_of_indemnify: levelBennefit === 1 ? type_of_indemnify : null,
              // benefit_level: levelBennefit,
              value:
                (valueF?.v || valueF?.h || "")
                  ?.toString()
                  ?.replace(/\.|\s|đ|d/g, "") || "0",
            };
          }

          if ([2, 3].includes(item?.config_benefit?.code_rules)) {
            set(
              item,
              "config_benefit.limit_fees",
              (valueH?.v || valueH?.h || "")
                ?.toString()
                ?.replace(/\.|\s|đ|d/g, "")
            );
            item?.config_benefit?.code_rules === 2 &&
              set(
                item,
                "config_benefit.limit_day",
                (valueG?.v || valueG?.h || "")
                  ?.toString()
                  ?.replace(/\.|\s|đ|d/g, "")
              );
            item?.config_benefit?.code_rules === 3 &&
              set(
                item,
                "config_benefit.limit_number_of_times",
                (valueG?.v || valueG?.h || "")
                  ?.toString()
                  ?.replace(/\.|\s|đ|d/g, "")
              );
          }

          item.config_benefit = {
            ...item.config_benefit,
            value:
              (valueF?.v || valueF?.h || "")
                ?.toString()
                ?.replace(/\.|\s|đ|d/g, "") || "0",
          };

          return [...arrays, item];
        }, []);
      }
      resolve(res?.rows || []);
    };
    reader.readAsArrayBuffer(file);
  });
};

export default function detailProductReducer(props) {
  const { state, action, dispatch, productId, productPackage_id, history } =
    props;

  const cases = {
    initialProduct: async () => {
      dispatch("onLoading");

      const [detailProductApi, productPackageApi] = await Promise.all([
        getDetailProduct(productId),
        getListProductPackage({ productId: productId }),
      ]);
      const responseDetailProductApi = get(detailProductApi, "data", {}) || {};
      const responseProductPackageApi =
        get(productPackageApi, "data", {}) || {};
      const res = {};
      if (responseDetailProductApi.statusCode !== 200) {
        return {
          snackbar: {
            open: true,
            type: "error",
            message:
              "Đã xảy ra lỗi trong quá trình lấy dữ liệu, xin vui lòng thử lại",
          },
          loading: false,
        };
      }

      res.productPackage = {};

      if ((get(responseProductPackageApi, "result", []) || []).length > 0) {
        const firstItem = get(responseProductPackageApi, "result[0]", {}) || {};
        const detailProductPackageApi = await getDetailProductPackage(
          firstItem.id
        );

        res.productPackages =
          get(responseProductPackageApi, "result", []) || [];
        const benefits = sortData(
          mapData(get(detailProductPackageApi, "data.result.benefits") || [])
        );

        res.productPackage = {
          indexActive: 0,
          ...(get(detailProductPackageApi, "data.result", {}) || {}),
          benefits,
          // totalBenefits,
        };
      }

      const details = get(responseDetailProductApi, "result", {}) || {};

      return { loading: false, details, ...res };
    },

    openAttachedFile: () => {
      const { details } = state;
      const { name } = action;
      const links = get(details, "extra_info.documents", []).find(
        (i) => i.type_code === name
      );

      if (!links?.path) {
        return {
          snackbar: {
            type: "warning",
            open: true,
            message: "Chưa có tài liệu nào",
          },
        };
      }

      links?.path && window.open(links.path);
    },

    onClickProductPackage: async () => {
      const { item, index } = action;
      const { productPackage } = state;

      if (index === productPackage?.indexActive) {
        return;
      }

      dispatch("onLoading");
      const res = {};
      const dataApi = await getDetailProductPackage(item.id);
      const response = get(dataApi, "data") || {};

      if (response.statusCode !== 200) {
        return {
          laoding: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Xảy ra lỗi khi lấy data, xin vui lòng thử lại",
          },
        };
      }

      const benefits = sortData(
        mapData(get(response, "result.benefits") || [])
      );

      res.productPackage = {
        indexActive: index,
        ...(get(response, "result", {}) || {}),
        benefits,
        // totalBenefits,
      };

      return { loading: false, ...res };
    },

    //

    initial_updateDetail: async () => {
      dispatch("onLoading");

      const arrAPi = [
        getListBenefits({
          limit: 500,
          offset: 0,
        }),
        getDetailProduct(productId),
      ];
      productPackage_id !== "new" &&
        arrAPi.push(getDetailProductPackage(productPackage_id));

      const [dataApi, detailApi, productPackageApi] = await Promise.all(arrAPi);
      const response = get(dataApi, "data", {}) || {};
      const details = get(detailApi, "data.result", {}) || {};
      const responseProductPackage = get(productPackageApi, "data", {}) || {};
      const res = {};

      if (response.statusCode === 200) {
        res.benefits = sortData(get(response, "result.data", []) || []);
        res.valueAutocomplete = get(response, "result.data[0]", {}) || {};
      }

      const benefits = sortData(
        mapData(get(responseProductPackage, "result.benefits") || [])
      );
      res.payloadProductPackage =
        productPackage_id !== "new"
          ? {
              ...(get(responseProductPackage, "result", {}) || {}),
              benefits,
            }
          : { status: 1 };

      return { loading: false, ...res, details };
    },

    onChangePayload: () => {
      const { name, value } = action;
      const { payloadProductPackage, errors } = state;

      if (name === "insurance_deductible") {
        set(
          payloadProductPackage,
          "extra_info.insurance_deductible.value",
          value
        );
        return { payloadProductPackage };
      }
      payloadProductPackage[name] = value;
      const newErrors = errors.filter((i) => i != name);
      return { payloadProductPackage, errors: [...newErrors] };
    },

    onChangeBenefit: () => {
      const { value, name, id } = action;
      const { dialogAddBenefit } = state;
      const res = {};
      const errors = state.errors.filter((i) => i !== name);
      if (name === "code_benefit") {
        dialogAddBenefit.data = {
          // ...(dialogAddBenefit.data || {}),
          code_benefit: value?.code_benefit,
          title: value?.title,
          config_benefit: {
            ...(value?.config_benefit || {}),
            benefit_level:
              get(value, "config_benefit.benefit_level") ||
              get(value, "config_benefit.type_code_benefit") ||
              value?.code_benefit?.split(".")?.length ||
              null,
          },
        };

        return { valueAutocomplete: value, dialogAddBenefit, errors };
      }

      if (name === "code_rules") {
        delete dialogAddBenefit?.data?.config_benefit?.limit_day;
        delete dialogAddBenefit?.data?.config_benefit?.limit_month;
        delete dialogAddBenefit?.data?.config_benefit?.limit_number_of_times;
        delete dialogAddBenefit?.data?.config_benefit?.limit_fees;
      }

      if (name === "code_rules" && [7, 8].includes(value)) {
        switch (value) {
          case 7:
            set(dialogAddBenefit, `data.config_benefit.limit_fees`, 1);
            break;
          case 8:
            set(dialogAddBenefit, `data.config_benefit.limit_fees`, 2);
            break;
          default:
            break;
        }
      }

      if (name === "type_of_indemnify") {
        let type_of_indemnify =
          get(
            dialogAddBenefit,
            "data.config_benefit.type_of_indemnify" || []
          ) || [];

        if (value) {
          type_of_indemnify.push(id);
        } else {
          type_of_indemnify = type_of_indemnify.filter((i) => i !== id);
        }

        set(
          dialogAddBenefit,
          "data.config_benefit.type_of_indemnify",
          type_of_indemnify
        );

        return { dialogAddBenefit, errors };
      }

      set(dialogAddBenefit, `data.config_benefit.${name}`, value);

      return { dialogAddBenefit, errors };
    },

    onChangeTmeAndPayment: () => {
      const { name, value, typeTable } = action;
      const { payloadProductPackage } = state;

      set(payloadProductPackage, `extra_info.${typeTable}.${name}`, value);
      return { payloadProductPackage };
    },

    openDialogAddBenefit: () => {
      const { isEdit = false } = action;
      const { valueAutocomplete, popverEdit, benefits } = state;
      const newValueAutocomplete = isEdit
        ? benefits.find(
            (i) => i.code_benefit === popverEdit?.item?.code_benefit
          )
        : valueAutocomplete;
      const data = isEdit
        ? { ...popverEdit?.item }
        : {
            code_benefit: valueAutocomplete?.code_benefit,
            title: valueAutocomplete?.title,
            config_benefit: {
              ...(get(valueAutocomplete, "config_benefit", {}) || {}),
              benefit_level:
                get(valueAutocomplete, "code_benefit", "")?.split(".")
                  ?.length || null,
            },
          };
      const index = isEdit ? popverEdit.index : null;

      return {
        valueAutocomplete: newValueAutocomplete,
        popverEdit: { ...popverEdit, open: false },
        dialogAddBenefit: {
          open: true,
          isEdit,
          data,
          index,
        },
      };
    },

    addBenefitRow: () => {
      const { payloadProductPackage, dialogAddBenefit, snackbar } = state;
      const errors = [];
      const level =
        dialogAddBenefit.data?.config_benefit?.benefit_level ||
        dialogAddBenefit.data?.config_benefit?.type_code_benefit;

      !dialogAddBenefit?.data?.code_benefit && errors.push("code_benefit");
      // !dialogAddBenefit?.data?.config_benefit?.code_rules &&
      //   errors.push("code_rules");
      !dialogAddBenefit?.data?.config_benefit?.value && errors.push("value");
      (dialogAddBenefit?.data?.config_benefit?.type_of_indemnify || [])
        ?.length === 0 &&
        level === 1 &&
        errors.push("type_of_indemnify");

      if (errors.length > 0) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Vui lòng nhập đầy đủ thông tin",
          },
          errors,
        };
      }

      const checkAlreadyCode = (payloadProductPackage?.benefits || []).some(
        (i) => i?.code_benefit === dialogAddBenefit?.data?.code_benefit
      );

      if (checkAlreadyCode) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "warning",
            message: `Mã ${dialogAddBenefit?.data?.code_benefit} đã tồn tại trong bảng quyền lời của bạn !!`,
          },
        };
      }

      const benefits = [
        ...(payloadProductPackage?.benefits || []),
        {
          ...dialogAddBenefit.data,
          isFirst: level === 1,
          isSecond: level === 2,
        },
      ];

      const sortedData = sortData(benefits);
      payloadProductPackage.benefits = [...sortedData];
      dispatch("closeDialogAddBenefit");
      return { payloadProductPackage };
    },

    saveChangeBenefit: () => {
      const { payloadProductPackage, dialogAddBenefit, snackbar } = state;
      const errors = [];
      const level =
        dialogAddBenefit.data?.config_benefit?.benefit_level ||
        dialogAddBenefit.data?.config_benefit?.type_code_benefit;

      !dialogAddBenefit?.data?.code_benefit && errors.push("code_benefit");
      // !dialogAddBenefit?.data?.config_benefit?.code_rules &&
      //   errors.push("code_rules");
      !dialogAddBenefit?.data?.config_benefit?.value && errors.push("value");
      (dialogAddBenefit?.data?.config_benefit?.type_of_indemnify || [])
        ?.length === 0 &&
        level === 1 &&
        errors.push("type_of_indemnify");

      if (errors.length > 0) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Vui lòng nhập đầy đủ thông tin",
          },
          errors,
        };
      }

      set(payloadProductPackage, `benefits[${dialogAddBenefit.index}]`, {
        ...dialogAddBenefit.data,
        isFirst: level === 1,
        isSecond: level === 2,
      });

      dispatch("closeDialogAddBenefit");
      return { payloadProductPackage };
    },

    closeDialogAddBenefit: () => ({
      dialogAddBenefit: { open: false, data: {} },
    }),

    deleteBennefit: () => {
      const { popverEdit, payloadProductPackage } = state;

      (payloadProductPackage?.benefits || [])?.splice(popverEdit?.index, 1);
      dispatch("closePopverEdit");
      return { payloadProductPackage };
    },

    openPopverEdit: () => {
      const { item, e, index } = action;
      return {
        popverEdit: { open: true, ref: e.currentTarget, item, index },
      };
    },

    closePopverEdit: () => {
      return {
        popverEdit: { ...state.popverEdit, open: false },
      };
    },

    importTemplate: async () => {
      const { file } = action;
      const { benefits, payloadProductPackage } = state;

      const res = {};

      dispatch("onLoading");
      const data = await readFile(file, benefits);

      const listDataBenefitAlready = data.filter((i) =>
        (payloadProductPackage.benefits || []).some(
          (y) => i?.code_benefit === y?.code_benefit
        )
      );

      if (listDataBenefitAlready?.length > 0) {
        const newData = data.filter(
          (i) =>
            !listDataBenefitAlready.some(
              (j) => i?.code_benefit === j?.code_benefit
            )
        );
        payloadProductPackage.benefits = [
          ...(payloadProductPackage.benefits || []),
          ...newData,
        ].sort((a, b) => {
          const [aParts, bParts] = [
            a.code_benefit.split("."),
            b.code_benefit.split("."),
          ];
          const minLength = Math.min(aParts.length, bParts.length);

          for (let i = 0; i < minLength; i++) {
            const comparison = aParts[i].localeCompare(bParts[i]);
            if (comparison !== 0) {
              return comparison;
            }
          }

          return aParts.length - bParts.length;
        });

        return {
          loading: false,
          payloadProductPackage,
          listDataBenefitAlready,
          openDialogWarning: true,
        };
      }

      payloadProductPackage.benefits = [
        ...(payloadProductPackage.benefits || []),
        ...data,
      ].sort((a, b) => {
        const [aParts, bParts] = [
          a.code_benefit.split("."),
          b.code_benefit.split("."),
        ];
        const minLength = Math.min(aParts.length, bParts.length);

        for (let i = 0; i < minLength; i++) {
          const comparison = aParts[i].localeCompare(bParts[i]);
          if (comparison !== 0) {
            return comparison;
          }
        }

        return aParts.length - bParts.length;
      });

      return {
        loading: false,
        payloadProductPackage,
        listDataBenefitAlready: [],
      };
    },

    submitSaveProductPackage: async () => {
      const { payloadProductPackage } = state;
      // const errors = [];
      // !payloadProductPackage.title && errors.push("title");

      const errors = [];
      !payloadProductPackage?.title && errors.push("title");

      if (errors.length > 0) {
        return {
          errors,
          snackbar: {
            open: true,
            type: "error",
            message: "Vui lòng nhập đầy đủ thông tin bên dưới!",
          },
        };
      }

      dispatch("onLoading");
      const paramsBenefits = (payloadProductPackage?.benefits || []).map(
        (i) => {
          delete i.isFirst;
          delete i.isSecond;
          return i;
        }
      );

      const product_amount = paramsBenefits?.reduce((sum, item) => {
        if (
          item?.config_benefit?.benefit_level === 1 ||
          item?.code_benefit?.split(".") === 1
        ) {
          return sum + +(item?.config_benefit?.value || 0);
        }
        return sum;
      }, 0);
      const dataAPI =
        productPackage_id !== "new"
          ? await updateProductPackage({
              ...payloadProductPackage,
              benefits: paramsBenefits,
              product_amount,
            })
          : await createProductPackage({
              ...payloadProductPackage,
              benefits: paramsBenefits,
              productId: productId,
              product_amount,
            });
      const response = get(dataAPI, "data");

      if (response.statusCode === 200) {
        history.push(`/admin/product-management/detail/${productId}`);
      }

      return { loading: false };
    },

    dowTemplateBenefits: async () => {
      const { benefits } = state;

      if (benefits.length === 0) {
        const res = {};
        const dataApi = await getListBenefits({
          limit: 500,
          offset: 0,
        });

        if (dataApi?.data?.statusCode !== 200) {
          return {
            snackbar: {
              open: true,
              type: "error",
              message:
                "Đã xảy ra lỗi trong quá trình tải tài liệu, xin vui lòng thử lại",
            },
          };
        }

        res.benefits = sortData(get(dataApi, "data.result.data", []) || []);

        return { count: state.count + 1, ...res };
      }

      return { count: state.count + 1 };
    },

    onCloseDialogWarning: () => {
      const { name } = action;
      const { listDataBenefitAlready, payloadProductPackage } = state;
      let newData = [...(payloadProductPackage.benefits || [])];

      if (name === "skip") {
        newData = [
          ...(payloadProductPackage.benefits || []),
          ...listDataBenefitAlready,
        ];
      }

      if (name === "replace") {
        newData = newData.map((i) => {
          const itemReplace = listDataBenefitAlready.find(
            (j) => j?.code_benefit === i?.code_benefit
          );
          return itemReplace ? { ...itemReplace } : { ...i };
        });
      }

      payloadProductPackage.benefits = [...newData].sort((a, b) => {
        const [aParts, bParts] = [
          a.code_benefit.split("."),
          b.code_benefit.split("."),
        ];
        const minLength = Math.min(aParts.length, bParts.length);

        for (let i = 0; i < minLength; i++) {
          const comparison = aParts[i].localeCompare(bParts[i]);
          if (comparison !== 0) {
            return comparison;
          }
        }

        return aParts.length - bParts.length;
      });

      return { payloadProductPackage, openDialogWarning: false };
    },

    //

    openSnackbar: () => ({
      snackbar: {
        open: true,
        type: action.typeSnackbar,
        message: action.message,
      },
    }),

    onCloseSnackbar: () => ({ snackbar: { open: false } }),

    onLoading: () => ({ loading: true }),

    //
  };

  return isString(action.type) && cases[action.type];
}
