import React, { useEffect } from "react";
import moment from "moment";

import { styledFormReportAccident } from "../components/styeleds/styleFormRPAccident";
import html2canvas from "html2canvas";

export default function FormReportedAccident(props) {
  const { state, dispatch } = props;
  const classes = styledFormReportAccident();
  const {
    insuredInfo,
    extraInfo,
    listInsuranceesCheck,
    insuranceType,
    alreadyAccidentReportImg,
  } = state;
  const temp = listInsuranceesCheck[0] || {};

  useEffect(async () => {
    const elements = document.getElementById("form-report-accident");
    if (
      insuranceType?.insuranceCode === "tai_nan" &&
      !alreadyAccidentReportImg
    ) {
      const canvas = await html2canvas(elements);
      dispatch({ type: "formReportAccidnet", canvas });
    }
    elements.style.display = "none";
  }, []);

  return (
    <div className={classes.container} id="form-report-accident">
      <div className={classes.QuocHieu}>
        <span className="qh">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</span>
        <span className="tieu-ngu">Độc lập - Tự do - Hạnh phúc</span>
        <span>--o0o--</span>
      </div>
      <div className={classes.title}>TƯỜNG TRÌNH TAI NẠN</div>
      <div className={classes.form}>
        <div className="line name">
          {"Họ tên người bị nạn: "}
          <span style={{ textTransform: "capitalize" }}>
            {insuredInfo?.insuredName || ""}
          </span>
        </div>
        <div className="line">
          {"Địa chỉ thường trú: " + ` ${insuredInfo?.insuredAddress || ""}`}
        </div>
        <div className="line">
          {"Số điện thoại liên lạc: " +
            ` ${temp?.insuredInfo?.insuredPhone || ""}`}
        </div>
        <div className="line">
          {"Số CMND: " + `  ${temp?.insuredInfo?.insuredPrivateId || ""}`}
        </div>
        <div className="line">
          {"Tai nạn xảy ra vào hồi : " +
            ` ${moment(extraInfo?.dateOfAccident).format("HH")}` +
            " giờ " +
            `  ${moment(extraInfo?.dateOfAccident).format("mm")}` +
            " Ngày " +
            ` ${moment(extraInfo?.dateOfAccident).format("DD")}` +
            " tháng " +
            `  ${moment(extraInfo?.dateOfAccident).format("MM")}` +
            " năm " +
            `  ${moment(extraInfo?.dateOfAccident).format("YYYY")}`}
        </div>
        <div className="line">
          {"Nơi xảy ra tai nạn: " + `  ${extraInfo?.placeOfAccident || ""}`}
        </div>
        <div className="line">
          {"Nguyên nhân tai nạn: " + ` ${extraInfo?.reason || ""}`}
        </div>
        <div className="line">
          {"Diễn biến: " + `  ${extraInfo?.accidentSituation || ""}`}
        </div>
        <div className="line">
          {"Hậu quả: " + ` ${extraInfo?.result || ""}`}
        </div>
      </div>
    </div>
  );
}
