import React, { useRef } from "react";
import _, { get } from "lodash";
import moment from "moment";
import { Button, IconButton } from "@mui/material";

import LoadingMui from "../../../../../../ui/LoadingMui";
import BeneficiaryInformation from "../claim/beneficiaryInformation";
import paymentStyles from "./paymentStyles";
import uploadIcon from "../../../../../../assets/health/upload-icon.svg";
import DialogShowImg from "../DialogShowImg";

export default function PayMent(props) {
  const { state, dispatch } = props;
  const user = JSON.parse(localStorage.getItem("user"));
  const classes = paymentStyles();

  const { detail, loading } = state;
  const checkUpload =
    detail?.statusId == 9 &&
    get(
      user,
      "role_management_claim.privileges.CLAIM_PAYMENT_ORDER_UPLOAD",
      null
    ) === 1;

  const temsp = (detail?.elements || []).filter(
    (i) =>
      i.type_id == 522 &&
      i.type_code == "action_element_complete" &&
      _.get(i, "extra_info.documents[0].path")
  );

  const files = temsp.map((i) => {
    const path = _.get(i, "extra_info.documents[0].path") || "";
    const size = _.get(i, "extra_info.documents[0].size");
    const name = path ? path.split("/")[path.split("/").length - 1] : "";
    const created_date = moment(i.created_date).format("HH:mm, DD/MM/YYYY");

    return { path, name, size, created_date };
  });

  const ref = useRef();

  const onClickDiv = (e) => {
    ref.current?.click();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      // detail?.statusId == 9
      checkUpload
    ) {
      dispatch({ type: "uploadComplete", files: e.dataTransfer.files[0] });
    } else {
      dispatch({
        type: "openSnackbar",
        typeSnackbar: "error",
        message:
          // detail?.statusId == 9
          checkUpload
            ? "Xin vui lòng thử lại"
            : "Trạng thái HS chưa hợp lệ, xin vui lòng kiểm tra lại",
      });
    }
  };

  return (
    <div className={classes.container}>
      <div className="header-title">Thông tin thụ hưởng</div>
      <BeneficiaryInformation {...{ state, dispatch }} />
      <div className="header-title">Upload uỷ nhiệm chi</div>
      <form
        className="upload-box"
        onDrop={handleDrop}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          id="uploadFile"
          disabled={!checkUpload}
          type="file"
          onChange={(e) => {
            dispatch({ type: "uploadComplete", files: e.target.files[0] });
            e.target.value = null;
          }}
          ref={ref}
          hidden
        />
        <img
          src={uploadIcon}
          style={{ width: "60px", height: "60px" }}
          onClick={onClickDiv}
        />
        <div className="text-bold">Kéo thả file vào đây</div>
        <div className="text">Các file định dạng: JPG, PNG</div> <br />
        <div>Hoặc</div> <br />
        <Button
          disabled={!checkUpload}
          className="button"
          variant="contained"
          onClick={onClickDiv}
        >
          Tải file lên
        </Button>
        <div className="text">Dung lượng dưới : 2MB</div>
      </form>
      <div className="text-file">Files</div>
      {files?.map((item, index) => (
        <div className="files" key={`rows_file_${index}`}>
          <div className="info-file">
            <img
              src={item.path}
              className="img"
              onClick={() =>
                dispatch({ type: "openDialogshowImg", path: item.path })
              }
            />
            <div className="name">{"Immage" + " " + item.name}</div>
            <div className="file">{item.created_date}</div>
            <div className="file">{item.size + "Kb"}</div>
          </div>
          {/* <IconButton></IconButton> */}
        </div>
      ))}
      <LoadingMui loading={loading} />
      <DialogShowImg {...{ state, dispatch }} />
    </div>
  );
}
