import React, { useState, useEffect } from "react";
import { Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { message } from "antd";

import { getParamUrlByKey } from "../utils/utils";
import { messages } from "../utils/messages";
import { getProfile_v2, logout_v2 } from "../apis/authApis";

// ADMIN V2

import AdminV2Login from "../views/admin-v2/account/login";
import AdminV2Router from "./AdminV2Router";
import DashboardLayoutV2 from "../views/admin-v2/layout/layout-dashboard/dashboard-layout";
import AccountManageV2 from "../views/admin-v2/account/account-manage/page/account-manage";
import AccountCreateV2 from "../views/admin-v2/account/account-manage/page/account-create";
import AccountInfor_v2 from "../views/admin-v2/account/account-infor";
import ChangePassword_v2 from "../views/admin-v2/account/password/changePassword";
import BenefitCode from "../views/admin-v2/benefit-code";
import ProductManagement from "../views/admin-v2/product/page/management";
import ProductDetail from "../views/admin-v2/product/page/detail";
import ProviderManagement from "../views/admin-v2/provider/page/management";
import ProviderDetail from "../views/admin-v2/provider/page/detail";
import InsurancePackage from "../views/admin-v2/provider/page/package";
import MerchantManagement from "../views/admin-v2/merchant/page/management";
import MerchantDetail from "../views/admin-v2/merchant/page/detail";
import MerchantPackage from "../views/admin-v2/merchant/page/package";
import MerchantContract from "../views/admin-v2/merchant/page/contract";
import Home from "../views/admin-v2/home";
import ForgotPasswordLogin from "../views/admin-v2/account/password/password-forgot";
import ChangePasswordOTP from "../views/admin-v2/account/password/password-change-otp";
import CompensationDetailReport from "../views/admin-v2/claim-management/intransit/page/detail-report";
import CompensationReport from "../views/admin-v2/claim-management/intransit/page/compensation-report";
import Decentraliza from "../views/admin-v2/decentraliza";
import ManagerAccount from "../views/admin-v2/account-v2/page/manager";
import DetailAccount from "../views/admin-v2/account-v2/page/detailAccount";
import ManagerProduct from "../views/admin-v2/product-v2/page/manager";
import BenefitCode_v2 from "../views/admin-v2/benefit-code-v2";
import CreatProduct from "../views/admin-v2/product-v2/page/creactProduct";
import DetailProduct from "../views/admin-v2/product-v2/page/detailProduct";
import DetailUpdateProduct from "../views/admin-v2/product-v2/page/detailUpdateProduct";
import ManagerGCN from "../views/admin-v2/GCN/page/manager";
import CreateGCN from "../views/admin-v2/GCN/page/createGCN";
import DetailGCN from "../views/admin-v2/GCN/page/detailGCN";
import ManagerBLVP from "../views/admin-v2/BLVP/page/manager";
import CreateRequestBLVP from "../views/admin-v2/BLVP/page/createRequest";
import DetailCeateBLVP from "../views/admin-v2/BLVP/page/detailCreate";
import DetailBLVP from "../views/admin-v2/BLVP/page/detailBLVP";

import DashboardLayoutEmbeded from "../views/admin-v2/layout/layout-embeded";

// ADMIN V2

import { theme } from "../views/admin/globalcare/theme/index";
import History from "../configs/history";
import * as PATH from "../configs/routesConfig";
import InsuranceInfo from "../views/claim/create/carAccident/InsuranceInfo";
import CreateClaimCarAccident from "../views/claim/create/carAccident/CreateClaimCarAccident";
import SummaryClaimCarAccident from "../views/claim/create/carAccident/SummaryClaimCarAccident";
import ClaimCarAccidentSuccess from "../views/claim/create/carAccident/ClaimCarAccidentSuccess";
import SelectGara from "../views/claim/create/carAccident/SelectGara";
import SelectGaraOther from "../views/claim/create/carAccident/SelectGaraOther";
import ClaimList from "../views/claim/list/ClaimList";
import YourClaimList from "../views/claim/list/YourClaimList";
import DetailsClaimCarAccident from "../views/claim/create/carAccident/DetailsClaimCarAccident";

// import AdminLogin from "../views/admin/globalcare/page/login";
// import ForgotPasswordLogin from "../views/admin/globalcare/page/password-forgot";
// import ChangePasswordLogin from "../views/admin/globalcare/page/password-change";
// import ChangePasswordOTP from "../views/admin/globalcare/page/password-change-otp";
// import Home from "../views/admin/globalcare/page/home";
// import CarInsuranceDashboard from "../views/admin/globalcare/page/dashboard";
// import ContractTable from "../views/admin/globalcare/page/contract-table";
// import ContractDetail from "../views/admin/globalcare/page/contract-detail";
// import ContractUpdate from "../views/admin/globalcare/page/contract-update";
// import BusinessTable from "../views/admin/globalcare/page/business-report-table";
import BusinessDetail from "../views/admin/globalcare/page/business-detail";
// import BusinessUpdate from "../views/admin/globalcare/page/business-update";
// import ClaimTable from "../views/admin/globalcare/page/claim-report-table";
// import ClaimDetails from "../views/admin/globalcare/page/claim-detail";
// import ClaimUpdate from "../views/admin/globalcare/page/claim-update";
// import QuotationTable from "../views/admin/globalcare/page/quotation-approve-table";
// import QuotationApproveDetails from "../views/admin/globalcare/page/quotation-approve-detail";
// import QuotationUpdate from "../views/admin/globalcare/page/quotation-update";
// import Account from "../views/admin/globalcare/page/account";
// import ProductManage from "../views/admin/globalcare/page/product-manage";
// import CompanyManage from "../views/admin/globalcare/page/company-manage";
// import MerchantManage from "../views/admin/globalcare/page/merchant-manage";
// import AccountManageCreate from "../views/admin/globalcare/page/account-manage-create";
// import { DashboardLayout } from "../views/admin/globalcare/components/layout-dashboard/dashboard-layout";
// import {
//   getListProductClaim,
//   getListProductClaim_v2,
// } from "../apis/productManageApis";
import InsuPhucbinhanHome from "../views/claim/create/phucbinhan";
import InsuTomatoHome from "../views/claim/create/tomato";
import CreateRequestClaimTomato from "../views/claim/create/tomato/request";
import ListClaimTomato from "../views/claim/create/tomato/request/create/listClaim";
import CreateClaimFileTomat from "../views/claim/create/tomato/request/create/createClaimFile";
import CreateDetailClaimFileTomat from "../views/claim/create/tomato/request/create/createDetailClaimFile";
import InsuTomatoClaimList from "../views/claim/create/tomato/request/list";
import InsuTomatoClaimDetail from "../views/claim/create/tomato/request/detail";
import InsuTomatoClaimUpdate from "../views/claim/create/tomato/request/detail/update";
import ShowDetailClaimTomato from "../views/claim/create/tomato/request/create/showDetail";

// import AccountManage from "../views/admin/account-manage/page/account-manage";
// import AccountCreate from "../views/admin/account-manage/page/account-create";

// import BLVPRecordsManagement from "../views/admin/blvp/page/records/records-manage";
// import BLVPRecordsDetail from "../views/admin/blvp/page/records/records-detail";
// import BLVPRecordsDetail2 from "../views/admin/blvp/page/records/records-detail-step2";
// import BLVPRecordsManagementdetail from "../views/admin/blvp/page/records/records-manage-detail";
// import BLVPCreateRecords from "../views/admin/blvp/page/create/create-records";
// import BLVPCreateDetail from "../views/admin/blvp/page/create/create-detail";
// import BLVPReport from "../views/admin/blvp/page/report";
// import BLVPReportDetail from "../views/admin/blvp/page/report-detail";
// import BLVPDashboard from "../views/admin/blvp/page/dashboard";

import InsuranceContract from "../views/claim/create/tomato/insuranceContract";
// import QuotationCheckTable from "../views/admin/globalcare/page/quotation-check-table";

import AhamoveClaim from "../views/claim/create/ahamove/main";
// import CompensationReport from "../views/admin/intransit/page/compensation-report";
// import CompensationDetailReport from "../views/admin/intransit/page/detail-report";
// import CompensationReportPBA from "../views/admin/PBA/page/compensation-report";
// import CompensationDetailReportPBA from "../views/admin/PBA/page/detail-report";

import DetailPBA from "../views/claim/create/phucbinhan/details/index";
import DetailIntransitBH365 from "../views/claim/detailBH365/intransit";
import DetailsCarAccidentBH365 from "../views/claim/detailBH365/vcoto";

// CLAIM V2

import HealthInsurance from "../views/claim/create/health/pages";
import HistoryOfHealthClaim from "../views/claim/detailBH365/health/page";
import FinaClaim from "../views/claim/create/fina/main";
import DetailFinaBH365 from "../views/claim/detailBH365/fina";
import AharideHome from "../views/claim/create/aharide";
import Loyalty from "../views/claim/create/loyalty/main";

const redirectTo = (user) => {
  if (+user?.extra_info?.providerId === 7) {
    return PATH.PROVIDER;
  }

  return PATH.ADMIN;
};

const AppRouter = (props) => {
  return (
    <Router history={History}>
      <Route path={PATH.APP_PATH} key={PATH.APP_PATH} component={Main} />
      <Route path={PATH.ADMIN_PATH} key={PATH.ADMIN_PATH} component={AdminV2} />
      {/* <Route path={PATH.ADMIN_PATH} key={PATH.ADMIN_PATH} component={Admin} /> */}
    </Router>
  );
};

export default AppRouter;

const Main = () => {
  let location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={100}>
        <Switch location={location}>
          <Route
            exact
            path={PATH.LIST_CLAIM_PATH}
            component={() => <ClaimList />}
          />
          <Route
            path={PATH.YOUR_CLAIM_LIST_PATH}
            component={() => <YourClaimList />}
          />
          <Route
            exact
            path={PATH.INSU_INFO_CLAIM_ACCIDENT_PATH}
            component={() => <InsuranceInfo />}
          />
          <Route
            exact
            path={PATH.CREATE_CLAIM_ACCIDENT_PATH}
            component={() => <CreateClaimCarAccident />}
          />
          <Route
            exact
            path={PATH.SUMMARY_CLAIM_ACCIDENT_PATH}
            component={() => <SummaryClaimCarAccident />}
          />
          <Route
            exact
            path={PATH.SUCCESS_CLAIM_ACCIDENT_PATH}
            component={() => <ClaimCarAccidentSuccess />}
          />
          <Route
            exact
            path={PATH.SELECT_GARA_PATH}
            component={() => <SelectGara />}
          />
          <Route
            exact
            path={PATH.SELECT_GARA_OTHER_PATH}
            component={() => <SelectGaraOther />}
          />
          <Route
            exact
            path={PATH.DETAILS_CLAIM_CAR_PATH}
            component={() => <DetailsClaimCarAccident />}
          />
          <Route path={PATH.CLAIM_PHUCBINHAN} render={InsuPhucbinhanHome} />
          <Route
            exact
            path={PATH.CLAIM_TOMATO}
            component={() => <InsuTomatoHome />}
          />
          <Route
            exact
            path={PATH.CREATE_REQUEST_CLAIM_TOMATO}
            component={() => <CreateRequestClaimTomato />}
          />
          <Route
            exact
            path={PATH.LIST_CLAIM_TOMATO}
            component={() => <ListClaimTomato />}
          />
          <Route
            exact
            path={PATH.CREATE_CLAIM_FILE_TOMATO}
            component={() => <CreateClaimFileTomat />}
          />
          <Route
            exact
            path={PATH.CREATE_DETAIL_CLAIM_FILE_TOMATO}
            component={() => <CreateDetailClaimFileTomat />}
          />
          <Route
            exact
            path={PATH.SHOW_DETAIL_CLAIM_TOMATO}
            component={() => <ShowDetailClaimTomato />}
            key={PATH.SHOW_DETAIL_CLAIM_TOMATO}
          />
          <Route
            exact
            path={PATH.INSU_TOMATO_CLAIM_LIST}
            component={InsuTomatoClaimList}
            key={PATH.INSU_TOMATO_CLAIM_LIST}
          />
          <Route
            exact
            path={PATH.INSU_TOMATO_CLAIM_DETAIL}
            component={InsuTomatoClaimDetail}
            key={PATH.INSU_TOMATO_CLAIM_DETAIL}
          />
          <Route
            exact
            path={PATH.INSU_TOMATO_CLAIM_UPDATE}
            component={InsuTomatoClaimUpdate}
            key={PATH.INSU_TOMATO_CLAIM_UPDATE}
          />
          <Route
            exact
            path={PATH.CLAIM_BENEFICIARY_LIST}
            component={InsuranceContract}
            key={PATH.CLAIM_BENEFICIARY_LIST}
          />
          <Route
            exact
            path={PATH.CLAIM_AHAMOVE}
            component={AhamoveClaim}
            key={PATH.CLAIM_AHAMOVE}
          />
          <Route
            exact
            path={PATH.DETAIL_PHUCBINHAN}
            component={DetailPBA}
            key={PATH.DETAIL_PHUCBINHAN}
          />
          <Route
            exact
            path={PATH.DETAIL_INTRANSIT_BH365}
            component={DetailIntransitBH365}
            key={PATH.DETAIL_INTRANSIT_BH365}
          />
          <Route
            exact
            path={PATH.DETAILS_CLAIM_CAR_BH365}
            component={DetailsCarAccidentBH365}
            key={PATH.DETAIL_INTRANSIT_BH365}
          />
          <Route
            exact
            path={PATH.HEALTH_CLAIM}
            component={HealthInsurance}
            key={PATH.HEALTH_CLAIM}
          />
          <Route
            exact
            path={PATH.HISTORY_HEALTH_CLAIM}
            component={HistoryOfHealthClaim}
            key={PATH.HISTORY_HEALTH_CLAIM}
          />
          <Route
            exact
            path={PATH.CLAIM_FINA}
            component={FinaClaim}
            key={PATH.CLAIM_FINA}
          />
          <Route
            exact
            path={PATH.DETAIL_FINA_BH365}
            component={DetailFinaBH365}
            key={PATH.DETAIL_FINA_BH365}
          />
          <Route path={PATH.CLAIM_AHARIDE} render={AharideHome} />
          <Route
            exact
            path={PATH.CLAIM_LOYALTY}
            component={Loyalty}
            key={PATH.CLAIM_LOYALTY}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

const AdminV2 = () => {
  const user = localStorage.getItem("user") || false;
  const [perMission, setPermission] = useState(false);
  const isCheckUser = user && user !== "undefined" && user !== "null";
  const systemType =
    (isCheckUser && JSON.parse(user)?.extra_info?.userType) || 0;

  const isEmbeded = `${window.location.pathname}`.includes("embeded");
  const embededToken = isEmbeded ? getParamUrlByKey("token") : false;
  const token = isEmbeded
    ? embededToken || localStorage.getItem("token") || false
    : localStorage.getItem("token") || false;

  useEffect(async () => {
    if (token && isEmbeded) {
      const dataApi = await getProfile_v2();
      if (dataApi?.data?.statusCode == 200) {
        await localStorage.setItem("user", JSON.stringify(dataApi.data.result));
      }
    }
  }, []);

  // useEffect(() => {
  //   if (!window.location.pathname.includes("admin/home")) {
  //     const product_parent_id = getParamUrlByKey("product_parent_id");
  //     const userPrivileges = user?.role_management_claim?.privileges || {};
  //     const userProductParents =
  //       user?.role_management_claim?.product_parents || [];

  //     if (product_parent_id) {
  //       const check = userProductParents
  //         .map((i) => i.product_id_parent)
  //         .some((j) => j == product_parent_id);

  //       setPermission(check);
  //       return;
  //     }
  //     console.log(
  //       ">>>>>>window.location.pathname",
  //       window.location.pathname,
  //       window.location
  //     );
  //   }
  // }, [window.location.pathname]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <Route
            exact
            path={PATH.ADMIN_V2_LOGIN}
            component={AdminV2Login}
            key={PATH.ADMIN_V2_LOGIN}
          />
          <Route
            exact
            path={PATH.ADMIN_FORGOT_PASSWORD}
            component={ForgotPasswordLogin}
            key={PATH.ADMIN_FORGOT_PASSWORD}
          />
          <Route
            exact
            path={PATH.ADMIN_V2_CHANGE_PASSWORD}
            component={ChangePassword_v2}
            key={PATH.ADMIN_V2_CHANGE_PASSWORD}
          />
          <Route
            exact
            path={PATH.ADMIN_CHANGE_PASSWORD_OTP}
            component={ChangePasswordOTP}
            key={PATH.ADMIN_CHANGE_PASSWORD_OTP}
          />

          {token && isEmbeded ? (
            <DashboardLayoutEmbeded>
              <Route
                exact
                path={PATH.COMPENSATION_REPORTR_INTRANSIT_EMBEDED}
                component={() => (
                  <CompensationReport form="report-claim" embeded={true} />
                )}
                key={PATH.COMPENSATION_REPORTR_INTRANSIT_EMBEDED}
              />
              <Route
                exact
                path={PATH.DETAIL_REPORT_INTRANSIT_EMBEDED}
                component={() => (
                  <CompensationDetailReport
                    form="report-claim"
                    embeded={true}
                  />
                )}
                key={PATH.DETAIL_REPORT_INTRANSIT_EMBEDED}
              />
            </DashboardLayoutEmbeded>
          ) : (
            token && (
              <DashboardLayoutV2>
                <Route
                  exact
                  path={PATH.ADMIN_V2}
                  component={Home}
                  key={PATH.ADMIN_V2}
                />
                {/* <Route
                  exact
                  path={PATH.AD_V2_ACCOUNT_MANAGE}
                  component={AccountManageV2}
                  key={PATH.AD_V2_ACCOUNT_MANAGE}
                />
                <Route
                  exact
                  path={PATH.AD_V2_ACCOUNT_MANAGE_CREATE}
                  component={AccountCreateV2}
                  key={PATH.AD_V2_ACCOUNT_MANAGE_CREATE}
                />
                <Route
                  exact
                  path={PATH.AD_V2_ACCOUNT_MANAGE_UPDATE}
                  component={AccountCreateV2}
                  key={PATH.AD_V2_ACCOUNT_MANAGE_UPDATE}
                />*/}

                <Route
                  exact // profile account
                  path={PATH.ADMIN_V2_ACCOUNT}
                  component={AccountInfor_v2}
                  key={PATH.ADMIN_V2_ACCOUNT}
                />
                <Route
                  exact
                  path={PATH.AD_BENEFIT_CODE}
                  component={BenefitCode}
                  key={PATH.AD_BENEFIT_CODE}
                />
                <Route
                  exact
                  path={PATH.AD_PRODUCT}
                  component={ProductManagement}
                  key={PATH.AD_PRODUCT}
                />
                <Route
                  exact
                  path={PATH.AD_PRODUCT_DETAIL}
                  component={ProductDetail}
                  key={PATH.AD_PRODUCT_DETAIL}
                />
                <Route
                  exact
                  path={PATH.AD_PROVIDER}
                  component={ProviderManagement}
                  key={PATH.AD_PROVIDER}
                />
                <Route
                  exact
                  path={PATH.AD_PROVIDER_DETAIL}
                  component={ProviderDetail}
                  key={PATH.AD_PROVIDER_DETAIL}
                />
                <Route
                  exact
                  path={PATH.AD_PRODUCT_PACKAGE}
                  component={InsurancePackage}
                  key={PATH.AD_PRODUCT_PACKAGE}
                />
                <Route
                  exact
                  path={PATH.AD_MERCHANT}
                  component={MerchantManagement}
                  key={PATH.AD_MERCHANT}
                />
                <Route
                  exact
                  path={PATH.AD_MERCHANT_DETAIL}
                  component={MerchantDetail}
                  key={PATH.AD_MERCHANT_DETAIL}
                />
                <Route
                  exact
                  path={PATH.AD_MERCHANT_PACKAGE}
                  component={MerchantPackage}
                  key={PATH.AD_MERCHANT_PACKAGE}
                />
                <Route
                  exact
                  path={PATH.AD_MERCHANT_CONTRACT}
                  component={MerchantContract}
                  key={PATH.AD_MERCHANT_CONTRACT}
                />
                <Route
                  exact
                  path={PATH.AD_V2_DECENTRALIZA}
                  component={Decentraliza}
                  key={PATH.AD_V2_DECENTRALIZA}
                />
                <Route
                  exact
                  path={PATH.AD_V2_ACCOUNT_v2}
                  component={ManagerAccount}
                  key={PATH.AD_V2_ACCOUNT_v2}
                />
                <Route
                  exact
                  path={PATH.AD_V2_ACCOUNT_DETAIL_v2}
                  component={DetailAccount}
                  key={PATH.AD_V2_ACCOUNT_DETAIL_v2}
                />
                <Route
                  exact
                  path={PATH.AD_V2_PRODUCT_v2}
                  component={ManagerProduct}
                  key={PATH.AD_V2_PRODUCT_v2}
                />
                <Route
                  exact
                  path={PATH.AD_V2_CREATE_PRODUCT_v2}
                  component={CreatProduct}
                  key={PATH.AD_V2_CREATE_PRODUCT_v2}
                />
                <Route
                  exact
                  path={PATH.AD_V2_DETAIL_PRODUCT_v2}
                  component={DetailProduct}
                  key={PATH.AD_V2_DETAIL_PRODUCT_v2}
                />
                <Route
                  exact
                  path={PATH.AD_V2_DETAIL_UPDATE_PRODUCT_v2}
                  component={DetailUpdateProduct}
                  key={PATH.AD_V2_DETAIL_UPDATE_PRODUCT_v2}
                />
                <Route
                  exact
                  path={PATH.AD_V2_GCN_MANAGER}
                  component={ManagerGCN}
                  key={PATH.AD_V2_GCN_MANAGER}
                />
                <Route
                  exact
                  path={PATH.AD_V2_GCN_CREATE}
                  component={CreateGCN}
                  key={PATH.AD_V2_GCN_CREATE}
                />
                <Route
                  exact
                  path={PATH.AD_V2_DETAIL_GCN}
                  component={DetailGCN}
                  key={PATH.AD_V2_DETAIL_GCN}
                />
                <Route
                  exact
                  path={PATH.AD_V2_MANAGER_BLVP}
                  component={ManagerBLVP}
                  key={PATH.AD_V2_MANAGER_BLVP}
                />
                <Route
                  exact
                  path={PATH.AD_V2_CREATE_REQUEST_BLVP}
                  component={CreateRequestBLVP}
                  key={PATH.AD_V2_CREATE_REQUEST_BLVP}
                />
                <Route
                  exact
                  path={PATH.AD_V2_DETAIL_CREATE_BLVP}
                  component={DetailCeateBLVP}
                  key={PATH.AD_V2_DETAIL_CREATE_BLVP}
                />
                <Route
                  exact
                  path={PATH.AD_V2_DETAIL_BLVP}
                  component={DetailBLVP}
                  key={PATH.AD_V2_DETAIL_BLVP}
                />
                <Route
                  exact
                  path={PATH.AD_V2_BENEFIT_V2}
                  component={BenefitCode_v2}
                  key={PATH.AD_V2_BENEFIT_V2}
                />

                {systemType === "department_garage" ? (
                  <>
                    <Route
                      exact
                      path={PATH.ADMIN_BUSINESS_REPORT_DETAIL}
                      component={BusinessDetail}
                      key={PATH.ADMIN_BUSINESS_REPORT_DETAIL}
                    />
                  </>
                ) : (
                  <AdminV2Router />
                )}
              </DashboardLayoutV2>
            )
          )}
          <Redirect
            to={token ? redirectTo(JSON.parse(user)) : PATH.ADMIN_V2_LOGIN}
          />
        </Switch>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

// const Admin = () => {
//   const user = localStorage.getItem("user") || false;
//   const isCheckUser = user && user !== "undefined" && user !== "null";
//   const systemType =
//     (isCheckUser && JSON.parse(user)?.extra_info?.userType) || 0;
//   const token = localStorage.getItem("token") || false;
//   const [listMenu, setListMenu] = useState([true]);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = () => {
//     getListProductClaim()
//       .then((res) => {
//         const data = res?.data?.result?.data;
//         setListMenu(data);
//       })
//       .catch((err) => {
//         console.error("err", err);
//       });
//   };

//   const setRouter = (id, productCode) => {
//     return (
//       <>
//         <Route
//           exact
//           path={PATH.ADMIN_DASHBOARD + `/${productCode}`}
//           component={CarInsuranceDashboard}
//           key={PATH.ADMIN_DASHBOARD}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_CONTRACT_DETAIL + `/${productCode}`}
//           component={ContractDetail}
//           key={PATH.ADMIN_CONTRACT_DETAIL}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_CONTRACT_UPDATE + `/${productCode}`}
//           component={ContractUpdate}
//           key={PATH.ADMIN_CONTRACT_UPDATE}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_CONTRACT + `/${productCode}`}
//           children={({ match }) => <ContractTable match={match} />}
//           key={PATH.ADMIN_CONTRACT}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_BUSINESS_REPORT_DETAIL + `/${productCode}`}
//           component={BusinessDetail}
//           key={PATH.ADMIN_BUSINESS_REPORT_DETAIL}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_BUSINESS_REPORT_UPDATE + `/${productCode}`}
//           component={BusinessUpdate}
//           key={PATH.ADMIN_BUSINESS_REPORT_UPDATE}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_BUSINESS_REPORT + `/${productCode}`}
//           component={({ match }) => <BusinessTable match={match} />}
//           key={PATH.ADMIN_BUSINESS_REPORT}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_CLAIM_REPORT_DETAIL + `/${productCode}`}
//           component={ClaimDetails}
//           key={PATH.ADMIN_CLAIM_REPORT_DETAIL}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_CLAIM_REPORT_UPDATE + `/${productCode}`}
//           component={ClaimUpdate}
//           key={PATH.ADMIN_CLAIM_REPORT_UPDATE}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_CLAIM_REPORT + `/${productCode}`}
//           component={({ match }) => <ClaimTable match={match} />}
//           key={PATH.ADMIN_CLAIM_REPORT}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_CLAIM_APPROVE_DETAIL + `/${productCode}`}
//           component={QuotationApproveDetails}
//           key={PATH.ADMIN_CLAIM_APPROVE_DETAIL}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_CLAIM_APPROVE_UPDATE + `/${productCode}`}
//           component={QuotationUpdate}
//           key={PATH.ADMIN_CLAIM_APPROVE_UPDATE}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_CLAIM_APPROVE + `/${productCode}`}
//           component={QuotationTable}
//           key={PATH.ADMIN_CLAIM_APPROVE}
//         />
//         <Route
//           exact
//           path={PATH.ADMIN_CLAIM_CHECK + `/${productCode}`}
//           component={QuotationCheckTable}
//           key={PATH.ADMIN_CLAIM_CHECK}
//         />
//         <Route
//           exact
//           path={PATH.BLVP_RECORDS_MANAGE + `/${productCode}`}
//           component={BLVPRecordsManagement}
//           key={PATH.BLVP_RECORDS_MANAGE}
//         />
//         <Route
//           exact
//           path={PATH.BLVP_RECORDS_DETAIL + `/${productCode}`}
//           component={BLVPRecordsDetail}
//           key={PATH.BLVP_RECORDS_DETAIL}
//         />
//         <Route
//           exact
//           path={PATH.BLVP_RECORDS_DETAIL2 + `/${productCode}`}
//           component={BLVPRecordsDetail2}
//           key={PATH.BLVP_RECORDS_DETAIL2}
//         />
//         <Route
//           exact
//           path={PATH.BLVP_RECORDS_MANAGE_DETAIL + `/${productCode}`}
//           component={BLVPRecordsManagementdetail}
//           key={PATH.BLVP_RECORDS_MANAGE_DETAIL}
//         />
//         <Route
//           exact
//           path={PATH.BLVP_RECORDS_CREATE + `/${productCode}`}
//           component={BLVPCreateRecords}
//           key={PATH.BLVP_RECORDS_CREATE}
//         />
//         <Route
//           exact
//           path={PATH.BLVP_RECORDS_CREATE_DETAIL + `/${productCode}`}
//           component={BLVPCreateDetail}
//           key={PATH.BLVP_RECORDS_CREATE_DETAIL}
//         />
//         <Route
//           exact
//           path={PATH.BLVP_REPORT + `/${productCode}`}
//           component={BLVPReport}
//           key={PATH.BLVP_REPORT}
//         />
//         <Route
//           exact
//           path={PATH.BLVP_REPORT_DETAIL + `/${productCode}`}
//           component={BLVPReportDetail}
//           key={PATH.BLVP_REPORT_DETAIL}
//         />
//         <Route
//           exact
//           path={PATH.BLVP_DASHBOARD + `/${productCode}`}
//           component={BLVPDashboard}
//           key={PATH.BLVP_DASHBOARD}
//         />
//         <Route
//           exact
//           path={PATH.COMPENSATION_REPORTR_INTRANSIT + `/${productCode}`}
//           component={() => <CompensationReport form="report-claim" />}
//           key={PATH.COMPENSATION_REPORTR_INTRANSIT}
//         />
//         <Route
//           exact
//           path={PATH.REVIEW_CLAIM_INTRANSIT + `/${productCode}`}
//           component={() => <CompensationReport form="review-claim" />}
//           key={PATH.REVIEW_CLAIM_INTRANSIT}
//         />
//         <Route
//           exact
//           path={PATH.DETAIL_REVIEW_INTRANSIT + `/${productCode}`}
//           component={() => <CompensationDetailReport form="review-claim" />}
//           key={PATH.DETAIL_REVIEW_INTRANSIT}
//         />
//         <Route
//           exact
//           path={PATH.DETAIL_REPORT_INTRANSIT + `/${productCode}`}
//           component={() => <CompensationDetailReport form="report-claim" />}
//           key={PATH.DETAIL_REPORT_INTRANSIT}
//         />
//         <Route
//           exact
//           path={PATH.PHUCBINHAN_BUSINESS_REPORT + `/${productCode}`}
//           component={() => <CompensationReportPBA form="report-claim" />}
//           key={PATH.PHUCBINHAN_BUSINESS_REPORT}
//         />
//         <Route
//           exact
//           path={PATH.PHUCBINHAN_REVIEW_CLAIM + `/${productCode}`}
//           component={() => <CompensationReportPBA form="review-claim" />}
//           key={PATH.PHUCBINHAN_REVIEW_CLAIM}
//         />
//         <Route
//           exact
//           path={PATH.DETAIL_REVIEW_PBA + `/${productCode}`}
//           component={() => <CompensationDetailReportPBA form="review-claim" />}
//           key={PATH.DETAIL_REVIEW_PBA}
//         />
//         <Route
//           exact
//           path={PATH.DETAIL_REPORT_PBA + `/${productCode}`}
//           component={() => <CompensationDetailReportPBA form="report-claim" />}
//           key={PATH.DETAIL_REPORT_PBA}
//         />
//       </>
//     );
//   };

//   return (
//     <LocalizationProvider dateAdapter={AdapterDateFns}>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <Switch>
//           {
//             // <Route
//             //   exact
//             //   path={PATH.ADMIN_LOGIN}
//             //   component={AdminLogin}
//             //   key={PATH.ADMIN_LOGIN}
//             // />
//             // <Route
//             //   exact
//             //   path={PATH.ADMIN_FORGOT_PASSWORD}
//             //   component={ForgotPasswordLogin}
//             //   key={PATH.ADMIN_FORGOT_PASSWORD}
//             // />
//             // <Route
//             //   exact
//             //   path={PATH.ADMIN_CHANGE_PASSWORD}
//             //   component={ChangePasswordLogin}
//             //   key={PATH.ADMIN_CHANGE_PASSWORD}
//             // />
//           }
//           <Route
//             exact
//             path={PATH.ADMIN_CHANGE_PASSWORD_OTP}
//             component={ChangePasswordOTP}
//             key={PATH.ADMIN_CHANGE_PASSWORD_OTP}
//           />

//           {token && (
//             <DashboardLayout dataMenu={listMenu}>
//               <Route
//                 exact
//                 path={PATH.ADMIN}
//                 component={Home}
//                 key={PATH.ADMIN}
//               />
//               <Route
//                 exact
//                 path={PATH.ADMIN_DASHBOARD}
//                 component={CarInsuranceDashboard}
//                 key={PATH.ADMIN_DASHBOARD}
//               />
//               {
//                 // <Route
//                 //   exact
//                 //   path={PATH.ADMIN_ACCOUNT_MANAGE}
//                 //   component={AccountManage}
//                 //   key={PATH.ADMIN_ACCOUNT_MANAGE}
//                 // />
//                 // <Route
//                 //   exact
//                 //   path={PATH.ADMIN_ACCOUNT_CREATE}
//                 //   component={AccountCreate}
//                 //   key={PATH.ADMIN_ACCOUNT_CREATE}
//                 // />
//                 // <Route
//                 //   exact
//                 //   path={PATH.ADMIN_ACCOUNT_UPDATE}
//                 //   component={AccountCreate}
//                 //   key={PATH.ADMIN_ACCOUNT_UPDATE}
//                 // />
//               }
//               <Route
//                 exact
//                 path={PATH.ADMIN_PRODUCT_MANAGE}
//                 component={ProductManage}
//                 key={PATH.ADMIN_PRODUCT_MANAGE}
//               />
//               <Route
//                 exact
//                 path={PATH.ADMIN_ACCOUNT}
//                 component={Account}
//                 key={PATH.ADMIN_ACCOUNT}
//               />
//               <Route
//                 exact
//                 path={PATH.ADMIN_ACCOUNT_MANAGE_CREATE}
//                 component={AccountManageCreate}
//                 key={PATH.ADMIN_ACCOUNT_MANAGE_CREATE}
//               />
//               <Route
//                 exact
//                 path={PATH.ADMIN_ACCOUNT_MANAGE_UPDATE}
//                 component={AccountManageCreate}
//                 key={PATH.ADMIN_ACCOUNT_MANAGE_UPDATE}
//               />
//               <Route
//                 exact
//                 path={PATH.ADMIN_COMPANY_MANAGE}
//                 component={CompanyManage}
//                 key={PATH.ADMIN_COMPANY_MANAGE}
//               />
//               <Route
//                 exact
//                 path={PATH.ADMIN_MERCHANT_MANAGE}
//                 component={MerchantManage}
//                 key={PATH.ADMIN_MERCHANT_MANAGE}
//               />

//               {systemType === "department_garage" ? (
//                 <>
//                   <Route
//                     exact
//                     path={PATH.ADMIN_BUSINESS_REPORT_DETAIL}
//                     component={BusinessDetail}
//                     key={PATH.ADMIN_BUSINESS_REPORT_DETAIL}
//                   />
//                   <Route
//                     exact
//                     path={PATH.ADMIN_BUSINESS_REPORT_UPDATE}
//                     component={BusinessUpdate}
//                     key={PATH.ADMIN_BUSINESS_REPORT_UPDATE}
//                   />
//                   <Route
//                     exact
//                     path={PATH.ADMIN_BUSINESS_REPORT}
//                     component={BusinessTable}
//                     key={PATH.ADMIN_BUSINESS_REPORT}
//                   />
//                   <Route
//                     exact
//                     path={PATH.ADMIN_CONTRACT_UPDATE}
//                     component={ContractUpdate}
//                     key={PATH.ADMIN_CONTRACT_UPDATE}
//                   />
//                   <Route
//                     exact
//                     path={PATH.ADMIN_CONTRACT}
//                     children={({ match }) => <ContractTable match={match} />}
//                     key={PATH.ADMIN_CONTRACT}
//                   />
//                   <Route
//                     exact
//                     path={PATH.ADMIN_CONTRACT_DETAIL}
//                     component={ContractDetail}
//                     key={PATH.ADMIN_CONTRACT_DETAIL}
//                   />
//                 </>
//               ) : (
//                 listMenu?.map((item) =>
//                   setRouter(item?.product_id, item?.product_code)
//                 )
//               )}
//             </DashboardLayout>
//           )}
//           <Redirect
//             to={token ? redirectTo(JSON.parse(user)) : PATH.ADMIN_LOGIN}
//           />
//         </Switch>
//       </ThemeProvider>
//     </LocalizationProvider>
//   );
// };
