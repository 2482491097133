import axios from "axios";
import api from "../configs/api";
import config from "../configs/config";

const getParamUrl = () => {
  let search = window.location.search;
  let arr = search.split("?");
  let params = new URLSearchParams(arr[1]);
  let token = params.get("token");
  return token;
};

const headers = {
  Authorization: `Bearer ${getParamUrl()}`,
};

function apiForm(method, url, data = {}) {
  switch (method) {
    case "POST":
      return axios({ method: "POST", url: config.SERVER + url, data, headers });
    case "GET":
      return axios({ method: "GET", url: config.SERVER + url, headers });
    case "PUT":
      return axios({ method: "PUT", url: config.SERVER + url, data, headers });
  }
}

function apiGetFileByUrl(url) {
  return axios({
    method: "GET",
    url: url,
    responseType: "blob",
  });
}

const getInforOrder = (data, platformKey = "bh365") =>
  apiForm(
    "POST",
    `/api/v2/cert/partner/ahamove/list?platform=${platformKey}`,
    data
  )
    .then((res) => res)
    .catch((error) => error);

const uploadImgLoyalty = (data) =>
  apiForm("POST", "/api/v2/document/partner/ahamove/upload/cdn", data)
    .then((res) => res)
    .catch((error) => error);

const createLoyalty = (data, platformKey = "bh365") =>
  apiForm("POST", `/api/v2/claim/partner/ahamove?platform=${platformKey}`, data)
    .then((res) => res)
    .catch((error) => error);

const getHistoryOrder = (data, platformKey = "bh365") =>
  apiForm(
    "POST",
    `/api/v2/claim/partner/ahamove/list?platform=${platformKey}`,
    data
  )
    .then((res) => res)
    .catch((error) => error);

const getDetailOrder = (id) =>
  apiForm("GET", `api/v2/claim/partner/ahamove/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

const getFileByUrl = (url) =>
  apiGetFileByUrl(url)
    .then((res) => res.data)
    .catch((error) => error);

//BTV

const getListIndemnify = (systemType = "globalcare", data) =>
  api
    .apiForm("POST", `/api/v2/claim/${systemType}/list`, data)
    .then((res) => res)
    .catch((error) => error);

const getDetailIndemnify = (id) =>
  api
    .apiForm("GET", `/api/v2/claim/partner/ahamove/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

const updateDetailIndemnify = (systemType = "globalcare", action, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}/add-elements/${action}`, data)
    .then((res) => res)
    .catch((error) => error);

const updateIndemnifyByMerchant = (data) =>
  api
    .apiForm("PUT", `/api/v2/claim/merchant`, data)
    .then((res) => res)
    .catch((error) => error);

export {
  createLoyalty,
  getDetailIndemnify,
  getDetailOrder,
  getFileByUrl,
  getHistoryOrder,
  getInforOrder,
  getListIndemnify,
  updateDetailIndemnify,
  uploadImgLoyalty,
  updateIndemnifyByMerchant,
};
