import { message } from "antd";
import { get, isString } from "lodash";
import { getDetailGCN, updateContract } from "../../../../apis/gcnApis";
import { getParamUrlByKey, type_of_indemnify } from "../util";

const checkLevel = (data) =>
  data
    .map((i) => {
      const regex = /\./g;
      if (!i.codeBenefit.includes(".")) {
        const type = [];
        (i?.configBenefit?.type_of_indemnify || []).forEach((item) => {
          const i = type_of_indemnify.find((i) => i.id === item);
          if (i) type.push(i.title);
        });
        const typeOfIndemnify = type.join("/ ");
        return {
          ...i,
          isFirst: true,
          typeOfIndemnify: typeOfIndemnify,
        };
      }
      if (i.codeBenefit.match(regex).length === 1) {
        return {
          ...i,
          isSecond: true,
        };
      }
      return i;
    })
    .sort((a, b) => {
      const [aParts, bParts] = [
        a.codeBenefit.split("."),
        b.codeBenefit.split("."),
      ];
      const minLength = Math.min(aParts.length, bParts.length);

      for (let i = 0; i < minLength; i++) {
        const aIsNumber = /^\d+$/.test(aParts[i]);
        const bIsNumber = /^\d+$/.test(bParts[i]);

        if (aIsNumber && bIsNumber) {
          const aPartNum = parseInt(aParts[i]);
          const bPartNum = parseInt(bParts[i]);

          if (aPartNum !== bPartNum) {
            return aPartNum - bPartNum;
          }
        } else {
          const comparison = aParts[i].localeCompare(bParts[i]);
          if (comparison !== 0) {
            return comparison;
          }
        }
      }

      return aParts.length - bParts.length;
    });

export default function detailGCNReducer(props) {
  const { state, action, dispatch } = props;
  const id = getParamUrlByKey("id");
  const cases = {
    initial: async () => {
      const res = {};
      dispatch("onLoading");
      const resApi = await getDetailGCN(id);

      if (resApi instanceof Error) {
        message.error("Lỗi lấy chi tiết", 2);
        return { loading: false };
      }
      res.data = get(resApi, "data.result") || {};
      const list = get(resApi, "data.result.productPackageBenefits") || [];
      res.benefitList = checkLevel(list);
      res.infoPackageEdit = {
        insuredPhone: res.data?.insuredPhone,
        insuredName: res.data?.insuredName,
        insuredDob: res.data?.insuredDob
          ? res.data?.insuredDob?.split("T")[0]
          : "",
        insuredPrivateId: res.data?.insuredPrivateId,
        insuredEmail: res.data?.insuredEmail,
        insuredType: res.data?.insuredType,
        productPackageId: res.data?.productPackageId,
        insuredGender: res.data?.insuredGender,
        insuredAddress: res.data?.insuredAddress,
        // titleMerchant: res.data?.titleMerchant,
        // taxCode: res.data?.extraInfo?.taxCode || res.data?.taxCode,
      };
      res.paymentEdit =
        get(resApi, "data.result.extraInfo.config_share_payment") ||
        get(
          resApi,
          "data.result.productPackageExtraInfo.config_share_payment"
        ) ||
        {};
      res.waitingEdit =
        get(resApi, "data.result.extraInfo.config_waiting") ||
        get(resApi, "data.result.productPackageExtraInfo.config_waiting") ||
        {};
      return { ...res, loading: false, edit: {} };
    },

    openAttachedFile: () => {
      const { data } = state;
      const { name } = action;
      if (name === "rule") {
        const link = get(data, "productExtraInfo.insurance_rule_url", "");
        link && window.open(link);
      } else {
        const link = get(data, "productExtraInfo.documents", []).find(
          (i) => i.type_code === name
        );
        link.path && window.open(link.path);
      }
    },

    editInfo: () => {
      const { edit } = state;
      const { name } = action;
      edit[name] = true;
      return { edit };
    },

    onChangeWaiting: () => {
      const { waitingEdit } = state;
      const { name, value } = action;
      waitingEdit[name] = value;
      return { waitingEdit };
    },

    onChangePayment: () => {
      const { paymentEdit } = state;
      const { name, value } = action;
      paymentEdit[name] = value;
      return { paymentEdit };
    },

    onChangeInfo: () => {
      const { infoPackageEdit } = state;
      const { name, value } = action;
      infoPackageEdit[name] = value;
      return { infoPackageEdit };
    },

    updateContract: async () => {
      const { data, edit, paymentEdit, waitingEdit, infoPackageEdit } = state;
      const res = {};
      dispatch("onLoading");
      const newInfo = edit.info ? infoPackageEdit : data;
      const p = {
        id: data?.id,
        insuredPhone: newInfo?.insuredPhone,
        insuredName: newInfo?.insuredName,
        insuredDob: newInfo?.insuredDob,
        insuredPrivateId: newInfo?.insuredPrivateId,
        insuredEmail: newInfo?.insuredEmail,
        insuredType: data?.insuredType,
        productPackageId: data?.productPackageId,
        insuredGender: newInfo?.insuredGender,
        insuredAddress: newInfo?.insuredAddress,
        // titleMerchant: newInfo?.titleMerchant || null,
        extraInfo: {
          config_share_payment: edit.payment
            ? paymentEdit
            : data?.extraInfo?.config_share_payment,
          config_waiting: edit.waiting
            ? waitingEdit
            : data?.extraInfo?.config_waiting,
          // taxCode: newInfo?.taxCode || null,
        },
      };

      const resApi = await updateContract(p);

      if (resApi instanceof Error) {
        message.error("Cập nhật không thành công", 2);
        return { loading: false };
      }
      if (resApi?.data?.statusCode === 200) {
        dispatch("initial");
        window.scrollTo(0, 0);
        message.success("Cập nhật thành công", 3);
        return { edit: {}, loading: false };
      }
      return { ...res, loading: false };
    },

    onLoading: () => ({ loading: true }),
    onLoadingTable: () => ({ loadingTable: true }),
  };

  return isString(action.type) && cases[action.type];
}
