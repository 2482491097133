import React, { useEffect, useState } from "react";
import moment from "moment";

import styleLicense from "./styleLicense";
import arrowRotateLeft from "../../../../../../assets/health/arrow-rotate-left.svg";
import arrowRotateRight from "../../../../../../assets/health/arrow-rotate-right.svg";
import zoomOut from "../../../../../../assets/health/zoom-out.svg";
import zoomIn from "../../../../../../assets/health/zoom-in.svg";
import download from "../../../../../../assets/health/download.svg";
import move from "../../../../../../assets/health/OjnVFC.svg";
import upload from "../../../../../../assets/health/upload.svg";
import { IconButton, LinearProgress } from "@mui/material";
import PopoverMoveImg from "./popoverMoveImg";

export default function BoxImg(props) {
  const { state, dispatch, level_id } = props;
  const classes = styleLicense();
  const [loadImg, setLoadmg] = useState(true);

  const { listLicese, imgLicese, pictureBox } = state;

  const listIcon = [
    {
      icon: arrowRotateLeft,
      name: "",
      idButton: "",
      title: "Xoay ảnh sang trái",
      disabled: listLicese.length == 0,
      onClick: () =>
        dispatch({ type: "onChangePictureBox", typeAction: "rotated-left" }),
    },
    {
      icon: arrowRotateRight,
      name: "",
      idButton: "",
      title: "Xoay ảnh sang phải",
      disabled: listLicese.length == 0,
      onClick: () =>
        dispatch({ type: "onChangePictureBox", typeAction: "rotated-right" }),
    },
    {
      icon: zoomOut,
      name: "",
      idButton: "",
      title: "Phóng to hình ảnh",
      disabled: listLicese.length == 0,
      onClick: () =>
        dispatch({ type: "onChangePictureBox", typeAction: "zoom-out" }),
    },
    {
      icon: zoomIn,
      name: "",
      idButton: "",
      title: "Thu nhỏ hình ảnh",
      disabled: listLicese.length == 0,
      onClick: () =>
        dispatch({ type: "onChangePictureBox", typeAction: "zoom-in" }),
    },
    {
      icon: move,
      name: "",
      idButton: "move-img-to-element",
      title: "Di chuyển ảnh đến",
      disabled: listLicese.length == 0 || level_id > 1,
      onClick: (e) => dispatch({ type: "openPopoverMoveImg", name: "move", e }),
    },
    {
      icon: download,
      name: "",
      idButton: "dowload-img",
      title: "Tải ảnh về",
      disabled: listLicese.length == 0,
      onClick: (e) =>
        dispatch({ type: "openPopoverMoveImg", name: "dowload-img", e }),
    },
    {
      icon: upload,
      name: "",
      idButton: "",
      title: "Tải ảnh lên",
      disabled: level_id > 1,
      onClick: () => {
        document.getElementById("input_upload_img")?.click();
      },
    },
  ];

  useEffect(() => {
    const boxElement = document.getElementById("picture-box");

    if (boxElement) {
      boxElement.style.transform = `rotate(${
        pictureBox?.rotate || 0
      }deg) scale(${pictureBox?.scale || 1})`;
    }
  }, [pictureBox]);

  const dragOverContainer = (e) => {
    e.preventDefault();
    const draggable = document?.querySelector(".dragging");
    const afterElement = getDragAfterElement(e?.currentTarget, e.clientX);
    if (!afterElement) {
      e?.currentTarget?.appendChild(draggable);
    } else {
      e.currentTarget.insertBefore(draggable, afterElement);
    }
  };

  const getDragAfterElement = (container, x) => {
    const draggableElements = [
      ...container.querySelectorAll(".draggable:not(.dragging)"),
    ];

    return draggableElements.reduce(
      (closet, child) => {
        const box = child.getBoundingClientRect();
        const offset = x - box.right;
        if (offset < 0 && offset > closet.offset) {
          return { offset: offset, element: child };
        } else return closet;
      },
      {
        offset: Number.NEGATIVE_INFINITY,
      }
    ).element;
  };

  useEffect(() => {
    setLoadmg(true);
  }, [imgLicese?.path]);

  return (
    <div className={classes.containerBoxImg}>
      <div className={classes.headerTitleBoxImg}>
        <div className="titile">{`${imgLicese?.nameLicense} (${listLicese.length})`}</div>
        <div className="buttons">
          {listIcon.map((item, index) => (
            <IconButton
              key={index}
              title={item.title || ""}
              id={item.idButton}
              disabled={item?.disabled || imgLicese?.typeId == 516}
              onClick={item.onClick}
            >
              <img src={item.icon} />
            </IconButton>
          ))}
          <input
            hidden
            multiple
            type="file"
            accept="image/*"
            id="input_upload_img"
            onChange={(e) => {
              dispatch({ type: "upLoadImgFromBTV", e });
              e.target.value = null;
            }}
          />
        </div>
      </div>
      <PopoverMoveImg {...{ state, dispatch }} />

      {imgLicese?.typeId == 516 ? (
        <>
          {listLicese.map((links, index) => (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "12px",
                alignItems: "center",
                cursor: "pointer",
              }}
              key={`links_hoa_don_${index}`}
              onClick={() => {
                window.open(links.path, "_blank");
              }}
            >
              <div
                style={{
                  color: "#0B3C8A",
                  fontWeight: "500",
                  maxWidth: "75%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {links.path}
              </div>
              <div style={{ fontSize: "14px" }}>
                {moment(imgLicese?.ccreated_date).format("HH:mm DD/MM/YYYY")}
              </div>
            </div>
          ))}
        </>
      ) : listLicese.length > 0 ? (
        <>
          <div className={classes.BoxImg}>
            <div className="img" id="picture-box">
              {loadImg && <LinearProgress />}
              <img
                style={{ width: "100%", display: loadImg ? "none" : "block" }}
                src={imgLicese?.path || ""}
                onLoad={() => setLoadmg(false)}
              />
            </div>
          </div>
          <div className={classes.BoxItemImg}>
            <div className="infor-img">
              <div>
                <span style={{ marginRight: "12px" }}>
                  {"Image " +
                    (imgLicese.original_name ||
                      (imgLicese?.path || "").slice(
                        imgLicese?.path?.lastIndexOf(".") - 13
                      ))}
                </span>
                {imgLicese.createdDate
                  ? moment(imgLicese.createdDate).format("HH:mm DD/MM/YYYY")
                  : ""}
              </div>
              <div
                style={{
                  marginLeft: "12px",
                  minWidth: "60px",
                  textAlign: "end",
                }}
              >{`${Math.round(imgLicese?.size / 1024) || 12} kb`}</div>
            </div>
            <div
              className="list-img"
              id={"container-img-drag"}
              onDragOver={dragOverContainer}
            >
              {listLicese.map((item, index) => (
                <div
                  className="item-img draggable"
                  id={"draggable"}
                  key={item.path}
                  style={{
                    border:
                      imgLicese.indexImg === index ? "2px solid #3961A0" : "",
                  }}
                  onClick={() => dispatch({ type: "onChangInmg", index })}
                  onDragStart={(e) => {
                    e.currentTarget.classList.add("dragging");
                  }}
                  onDragEnd={(e) => {
                    e.currentTarget.classList.remove("dragging");
                    const elements = document.querySelectorAll("#draggable");
                    const dataVlaue = Array.from(elements).map((i) => {
                      const img = i?.querySelector("img");
                      return img.src;
                    });
                    if (dataVlaue.length > 1) {
                      dispatch({ type: "changeIndexImg", newArray: dataVlaue });
                    }
                  }}
                  draggable
                >
                  <img
                    style={{ width: "90px", height: "90px" }}
                    src={item.path}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            marginTop: "12px",
            fontSize: "18px",
            color: "red",
            fontWeight: "600",
            fontStyle: "italic",
          }}
        >
          Khách hàng không upload ảnh ở phần này
        </div>
      )}
    </div>
  );
}
