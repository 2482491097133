import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PDFViewer, BlobProvider } from "@react-pdf/renderer";

import { styleDialogShowPDF } from "./styleds/styleDialogShowPDF";
import ExportPDF from "../../../create/health/components/ExportPDF";
import { Document, Page, pdfjs } from "react-pdf";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogShowPDF(props) {
  const { state, dispatch } = props;
  const classes = styleDialogShowPDF();

  const { dialogShowPDF, detail } = state;

  const [states, setStates] = React.useState({
    numPages: "" || 1,
    pageShow: 1,
    width: 0,
    link: "",
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setStates({ ...state, loading: true });
    if (numPages > 1) {
      setStates({ ...state, numPages });
    }
  };

  return (
    <Dialog
      open={!!dialogShowPDF?.open}
      TransitionComponent={Transition}
      maxWidth={"2000px"}
      onClose={() => dispatch("closeDialogShowPDF")}
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton onClick={() => dispatch("closeDialogShowPDF")}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "0 12px 16px 12px" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <div id="pdfBox">
            <BlobProvider document={<ExportPDF detail={detail} />}>
              {({ blob, url }) => {
                return (
                  <Document
                    file={url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={console.error}
                    loading={<div>Loading File ... </div>}
                  >
                    <div
                      style={{
                        height: "75vh",
                        width:
                          window?.document?.getElementById("pdfBox")
                            ?.offsetWidth,
                        overflow: "scroll",
                      }}
                    >
                      {Array.from(new Array(states.numPages), (el, index) => (
                        <Page
                          width={
                            window?.document?.getElementById("pdfBox")
                              ?.offsetWidth
                          }
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      ))}
                    </div>
                  </Document>
                );
              }}
            </BlobProvider>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
