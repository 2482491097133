import * as React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Slide,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import note_icon from "../assets/note_icon.png";

import { MAXIMUN_LINE_EXPORT_EXCEL_PBA } from "../../../../../utils/contains";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Note(props) {
  const { dispatch, open, loading } = props;
  const classes = makeStyle();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => dispatch("closeMaxLineDialog")}
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialog}
    >
      <DialogContent style={{ padding: "20px 0" }}>
        <div className={classes.divContent}>
          <img src={note_icon} alt="wait" width={"100px"} />
          <div className="note">Lưu ý</div>
          <div className="text">
            <span>{`Dữ liệu xuất báo cáo đã vượt quá ${MAXIMUN_LINE_EXPORT_EXCEL_PBA.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              "."
            )} dòng,`}</span>
            <span>{`Hệ thống chỉ hỗ trợ xuất tối đa ${MAXIMUN_LINE_EXPORT_EXCEL_PBA.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              "."
            )} dòng/lần`}</span>
            <span>Bạn có muốn tiếp tục?</span>
          </div>
        </div>
        <div className={classes.buttons}>
          <Button
            className="cancel"
            onClick={() => dispatch("closeMaxLineDialog")}
          >
            Đóng
          </Button>
          <Button className="agree" onClick={() => dispatch("getListExport")}>
            Xác nhận
          </Button>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
    },
  },

  divContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .note": {
      color: "#2A3790",
      fontWeight: "700",
      fontSize: "24px",
      padding: "24px 0 0",
    },

    "& .text": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
      margin: "16px",
      color: "#FB7800",
      fontStyle: "italic",
    },
  },

  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",

    "& .cancel": {
      width: "40%",
      border: "1px solid #EBEDFF",
      borderRadius: "24px",
      color: "#2A3790",
    },

    "& .agree": {
      width: "40%",
      background: "#2A3790",
      border: "1px solid #2A3790",
      borderRadius: "24px",
      color: "white",
    },
  },
}));
