import React from "react";
import { Button, Dialog, IconButton, Slide, TextField } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

import LoadingMui from "../../../../../../ui/LoadingMui";
import gim from "../../../../../../assets/health/gim.svg";
import styleStatus from "./styleds";
import { get } from "lodash";
import { getParamUrlByKey } from "../../../../../../utils/utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getContentDialogNote = ({
  name,
  roleAdminMenuItem,
  approveInfo,
  level_id,
}) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const product_parents_from_user = get(
    user,
    "role_management_claim.product_parents",
    []
  );
  const product_id_parent = getParamUrlByKey("product_id_parent");

  const approve_from =
    product_parents_from_user.find(
      (i) => i?.product_id_parent === product_id_parent
    )?.approve_from || null;

  const approve_to =
    product_parents_from_user.find(
      (i) => i?.product_id_parent === product_id_parent
    )?.approve_to || 0;

  // sendRequestApprpve submitApprove submitRefuse

  switch (name) {
    case "sendRequestApprpve":
      return ["Bạn có chắc chắn muốn gửi yêu cầu", "phê duyệt lên cấp trên"];

    case "submitApprove":
      return +(approveInfo?.approve_amount || 0) > approve_to ||
        (+approveInfo?.approve_amount == 0 && level_id != 4)
        ? ["Bạn có chắc chắn muốn gửi yêu cầu", "phê duyệt lên cấp trên"]
        : [
            "Bạn có chắc chắn gửi phê duyệt yêu",
            "cầu bồi thường cho khách hàng",
          ];

    case "submitRefuse":
      return ["Bạn có chắc chắn muốn gửi từ chối", "yêu cầu bồi thường"];

    default:
      return [];
  }
};

export default function DialogNote(props) {
  const { state, dispatch, level_id } = props;
  const classes = styleStatus();
  const roleAdminMenuItem =
    JSON.parse(localStorage.getItem("roleAdminMenuItem")) || {};

  const { dialogNote, loading, currentFilePdf } = state;

  return (
    <Dialog
      fullWidth
      // maxWidth={"xs"}
      open={dialogNote.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => dispatch("closeDialogNote")}
      className={classes.dialog}
    >
      <div className={classes.row}>
        <div className="header">{dialogNote.title || ""}</div>
        <IconButton size="small" onClick={() => dispatch("closeDialogNote")}>
          <CloseOutlined fontSize="small" />
        </IconButton>
      </div>
      <TextField
        multiline
        fullWidth
        minRows={4}
        value={dialogNote.note || ""}
        onChange={(e) =>
          dispatch({ type: "onChangeNoteRightTabs", value: e.target.value })
        }
        placeholder="Nhập nội dung"
        className="input"
      />
      <div className={dialogNote.name === "addNote" ? "row" : "row-space"}>
        {dialogNote.name !== "addNote" && (
          <span>
            <IconButton
              style={{ height: "30px" }}
              onClick={() => {
                window.open(currentFilePdf.path);
              }}
            >
              <img src={gim} />
            </IconButton>
            1
          </span>
        )}
        <div>
          <Button
            className="cancel border-blue"
            variant="outlined"
            onClick={() => dispatch("closeDialogNote")}
          >
            Bỏ qua
          </Button>
          <Button
            className="agree blue"
            variant="contained"
            onClick={() => {
              if (!dialogNote.note) {
                dispatch({
                  type: "openSnackbar",
                  typeSnackbar: "error",
                  message: "Vui lòng nhập nội dung",
                });
                return;
              }

              dialogNote.name === "addNote"
                ? dispatch("addNote")
                : dispatch({
                    type: "openDialogNoti",
                    typeDialog: "warning",
                    name: dialogNote.name,
                    textContent: getContentDialogNote({
                      level_id,
                      name: dialogNote.name,
                      roleAdminMenuItem,
                      approveInfo:
                        currentFilePdf?.data_element?.extra_info
                          ?.approve_info || {},
                    }),
                  });
            }}
          >
            Gửi
          </Button>
        </div>
      </div>
      <LoadingMui loading={loading} />
    </Dialog>
  );
}
