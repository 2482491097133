import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Button, IconButton, MenuItem, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect } from "react";

import useReducer from "../../../../library/hooks/useReducer";
import { Loading } from "../components/Loading";
import InsuranceBenefitTable from "../components/insuranceBenefitsTable";
import TableListBeneficiary from "../components/tableListBeneficiary";
import { TextField } from "../overrideMui";
import detailGCNReducer from "../reducers/detailGCNReducer";
import { detailStyles } from "../styles/styleDetailGCN";
import { _formatMoney, formatDate, genders, renderAccident } from "../util";

import editIcon from "../assets/edit-icon.svg";
import DefaultIcon from "../assets/logo_default.svg";
import { DatePicker } from "@mui/x-date-pickers";
import { date } from "@linways/table-to-excel";
import { get } from "lodash";
import moment from "moment";

const initialState = {
  data: {},
  benefitList: [],
  loading: false,
  loadingTable: false,
  edit: {},
  infoPackageEdit: {},
  paymentEdit: {},
  waitingEdit: {},
};

export default function DetailGCN() {
  const classes = detailStyles();
  const maxWidth800 = useMediaQuery("(max-width:800px)");

  const [state, dispatch, clearStateGlobal] = useReducer({
    reducer: detailGCNReducer,
    name: "detailGCNReducer",
    initialState,
  });

  useEffect(() => {
    dispatch("initial");
    return () => clearStateGlobal();
  }, []);

  const {
    data,
    benefitList,
    loadingTable,
    loading,
    edit,
    infoPackageEdit,
    paymentEdit,
    waitingEdit,
  } = state;

  const getValueWaiting = (value) => {
    if (!value) return "";
    const beginDate = data?.beginDate.split("T");
    if (beginDate.length > 0) {
      const dateUsed = +value - moment().diff(moment(beginDate[0]), "days");
      return dateUsed > 0 ? dateUsed.toString() : "0";
    }
    return "";
  };

  return (
    <div className={classes.container}>
      <Loading loading={loading} />
      <div className={classes.header_box}>
        <Grid container>
          <Grid xs={2} sm={2} md={2.5}>
            <div>
              <div style={{ padding: "8px" }}>
                <img
                  src={
                    data?.productUrlLogo ||
                    data?.productUrlLogoParent ||
                    DefaultIcon
                  }
                  alt="logo"
                  className="logo"
                />
              </div>
              <div className={classes.box_logo}>
                <span className="name-insurance">
                  BH {data?.productTitle || data?.productTitleParent || ""}
                </span>
                <FiberManualRecordIcon
                  fontSize="small"
                  sx={{
                    color: data?.productStatus ? "#069D27" : "#A0A0A0",
                    width: "10px",
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid xs={10} sm={10} md={9.5}>
            <Grid container spacing={maxWidth800 ? 4 : 6} columns={12}>
              <Grid
                className={classes.box_grid_item}
                xs={10}
                sm={6}
                md={4}
                lg={4}
                xl={4}
              >
                <div className="justyfy-between">
                  <span className="label">Loại bảo hiểm</span>
                  <span className="value">BH con người</span>
                </div>
                <div className="justyfy-between">
                  <span className="label">Tên sản phẩm</span>
                  <span className="value">
                    {" "}
                    BH {data?.productTitle || data?.productTitleParent || ""}
                  </span>
                </div>
                <div className="justyfy-between">
                  <span className="label">Mã sản phẩm</span>
                  <span className="value">{data?.productId || ""}</span>
                </div>
              </Grid>
              <Grid
                className={classes.box_grid_item}
                xs={10}
                sm={6}
                md={4}
                lg={4}
                xl={4}
              >
                <div className="justyfy-between">
                  <span className="label">Thuộc công ty</span>
                  <span className="value">{data?.providerTitle || ""}</span>
                </div>
                <div className="justyfy-between">
                  <span className="label">Loại HĐ bảo hiểm</span>
                  <span className="value">
                    {data?.productExtraInfo?.typeOfInsuranceContract === 1 ||
                    data?.productExtraInfo?.benefit_type === "quyen_loi_rieng"
                      ? "Quyền lợi riêng"
                      : data?.productExtraInfo?.typeOfInsuranceContract === 2 ||
                        data?.productExtraInfo?.benefit_type ===
                          "quyen_loi_chung"
                      ? "Quyền lợi chung"
                      : ""}
                  </span>
                </div>
                <div className="justyfy-between">
                  <span className="label">Ngày tạo</span>
                  <span className="value">{formatDate(data?.createdDate)}</span>
                </div>
              </Grid>
              <Grid
                className={classes.box_grid_item}
                xs={10}
                sm={6}
                md={4}
                lg={4}
                xl={4}
              >
                <div className="justyfy-between">
                  <span className="label">DS CSYT loại trừ</span>
                  <span
                    className="value blue"
                    onClick={() =>
                      dispatch({
                        type: "openAttachedFile",
                        name: "list_clinic_hospital_exclude",
                      })
                    }
                  >
                    Xem
                  </span>
                </div>
                <div className="justyfy-between">
                  <span className="label">Điều kiện, điều khoản</span>
                  <span
                    className="value blue"
                    onClick={() =>
                      dispatch({
                        type: "openAttachedFile",
                        name: "terms_and_conditions",
                      })
                    }
                  >
                    Xem
                  </span>
                </div>
                <div className="justyfy-between">
                  <span className="label">Giấy chứng nhận BH</span>
                  <span
                    className="value blue"
                    onClick={() =>
                      dispatch({
                        type: "openAttachedFile",
                        name: "rule",
                      })
                    }
                  >
                    Xem
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.box_infor_insurance}>
        <div className="row">
          <div className="title">Thông tin gói bảo hiểm</div>
          <IconButton color="primary">
            <img
              src={editIcon}
              alt="edit"
              onClick={() => dispatch({ type: "editInfo", name: "info" })}
            />
          </IconButton>
        </div>
        <div className="box_infor">
          {inforPackageInsurance(data).map((item, index) => (
            <div key={`item_box_insurance_infor_${index}`} className="box_item">
              <div className="item_label">{item.label}</div>

              {edit.info && !!item.canEdit ? (
                <>
                  {item.type === "select" && (
                    <TextField
                      sx={{ width: "80%" }}
                      select
                      value={infoPackageEdit?.insuredGender}
                      onChange={(e) =>
                        dispatch({
                          type: "onChangeInfo",
                          name: "insuredGender",
                          value: e.target.value,
                        })
                      }
                    >
                      {genders.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}

                  {item.type === "date" && (
                    <DatePicker
                      value={
                        infoPackageEdit?.insuredDob
                          ? moment(infoPackageEdit?.insuredDob).toDate() || null
                          : null
                      }
                      inputFormat="dd/MM/yyyy"
                      placeholder="Chọn ngày sinh"
                      onChange={(date) => {
                        dispatch({
                          type: "onChangeInfo",
                          name: "insuredDob",
                          value: moment(date).format("YYYY/MM/DD"),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField sx={{ width: "80%" }} {...params} />
                      )}
                    />
                  )}

                  {!["select", "date"].includes(item.type) && (
                    <TextField
                      sx={{ width: "80%" }}
                      value={get(infoPackageEdit, `[${item.canEdit}]`) || ""}
                      onChange={(e) =>
                        dispatch({
                          type: "onChangeInfo",
                          name: item.canEdit,
                          value: e.target.value,
                        })
                      }
                    />
                  )}
                </>
              ) : (
                <div
                  className="item_value"
                  style={{ color: item.colorText || "#686868" }}
                >
                  {item.value}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="title">Danh sách người hưởng bảo hiểm</div>
      <TableListBeneficiary infoData={data} />
      <div className="row">
        <div className="title">Thời gian chờ</div>
        <IconButton color="primary">
          <img
            src={editIcon}
            alt="edit"
            onClick={() => dispatch({ type: "editInfo", name: "waiting" })}
          />
        </IconButton>
      </div>
      <div className={classes.containeer_time_remaining}>
        {renderAccident.map((i, index) => (
          <div
            key={`key_div_render_${index}`}
            className="div-column"
            style={{ minWidth: i.minWidth ? i.minWidth : "" }}
          >
            <div className="head-table">{i.title}</div>

            {edit.waiting ? (
              <TextField
                sx={{ width: "80%" }}
                value={waitingEdit?.[i.name] || ""}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeWaiting",
                    name: i.name,
                    value: e.target.value,
                  })
                }
              />
            ) : (
              <div className="value-table">
                {getValueWaiting(
                  get(data, `extraInfo.config_waiting.${i?.name}`) ||
                    get(data, "productPackageExtraInfo.config_waiting")
                ) || ""}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="row">
        <div className="title">Đồng chi trả</div>
        <IconButton color="primary">
          <img
            src={editIcon}
            alt="edit"
            onClick={() => dispatch({ type: "editInfo", name: "payment" })}
          />
        </IconButton>
      </div>
      <div className={classes.containeer_time_remaining}>
        {renderAccident.map((i, index) => (
          <div
            key={`key_div_render_${index}`}
            className="div-column"
            style={{ minWidth: i.minWidth ? i.minWidth : "" }}
          >
            <div className="head-table">{i.title}</div>

            {edit.payment ? (
              <TextField
                sx={{ width: "80%" }}
                value={paymentEdit?.[i.name] || ""}
                onChange={(e) =>
                  dispatch({
                    type: "onChangePayment",
                    name: i.name,
                    value: e.target.value,
                  })
                }
              />
            ) : (
              <div className="value-table">
                {
                  // data?.extraInfo?.config_share_payment[i.name]
                  get(data, `extraInfo.config_share_payment.${i.name}`) ||
                    get(
                      data,
                      `productPackageExtraInfo.config_share_payment.${i.name}`
                    ) ||
                    ""
                }
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="title">Bảng quyền lợi bảo hiểm</div>
      <InsuranceBenefitTable list={benefitList} loadingTable={loadingTable} />
      {(edit.info || edit.waiting || edit.payment) && (
        <div className={classes.button}>
          <Button
            className="btn-upload"
            onClick={() => dispatch("updateContract")}
          >
            Cập nhật
          </Button>
        </div>
      )}
    </div>
  );
}

const inforPackageInsurance = (data) => {
  const DataRepoce = [
    {
      label: "Số HD bảo hiểm",
      value: data?.certNum,
      colorText: "#3961A0",
    },
    {
      label: "Số thẻ bảo hiểm",
      value: data?.certNum,
      colorText: "#3961A0",
    },
    {
      label: "Tên gói bảo hiểm",
      value: data?.productPackageTitle,
      colorText: "#3961A0",
    },
    {
      label: "Phí bảo hiểm",
      value: _formatMoney(data?.fees),
      colorText: "#3961A0",
    },

    {
      canEdit: "insuredPhone",
      label: "Số điện thoại",
      value: data?.insuredPhone,
    },
    {
      canEdit: "insuredEmail",
      label: "Email",
      value: data?.insuredEmail,
    },
    {
      label: "Mức miễn thường ",
      value: _formatMoney(data?.insuranceDeductible),
    },
    {
      label: "Ngày tham đầu tiên",
      value: formatDate(data?.createdDate),
    },
    {
      label: "Ngày hiệu lực",
      value: formatDate(data?.beginDate),
    },
    {
      label: "Ngày hết hạn",
      value: formatDate(data?.endDate),
    },
    {
      label: "Nơi mua bảo hiểm",
      value: data?.titleMerchant,
    },
  ];

  data?.titleMerchant
    ? DataRepoce.splice(
        4,
        0,
        ...[
          {
            label: "Tên doanh nghiệp",
            value: data?.titleMerchant,
          },
          {
            label: "Mã số thuế",
            value: data?.extraInfo?.taxCode || data?.taxCode || "",
          },
        ]
      )
    : DataRepoce.splice(
        4,
        0,
        ...[
          {
            canEdit: "insuredName",
            label: "Chủ hợp đồng",
            value: data?.insuredName,
          },
          {
            type: "select",
            canEdit: "insuredGender",
            label: "Giới tính",
            value:
              data.insuredGender === 1
                ? "Nam"
                : data.insuredGender === 0
                ? "Nữ"
                : "",
          },
          {
            type: "date",
            canEdit: "insuredDob",
            label: "Ngày sinh",
            value: formatDate(data?.insuredDob),
          },
          {
            canEdit: "insuredPrivateId",
            label: "CMND/ CCCD/ Hộ chiếu",
            value: data?.insuredPrivateId,
          },
          {
            canEdit: "insuredAddress",
            label: "Địa chỉ",
            value: data?.insuredAddress,
          },
        ]
      );

  return DataRepoce;
};
