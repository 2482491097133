import { makeStyles } from "@mui/styles";

export const createRequest = makeStyles(() => ({
  container: {
    width: "100%",
    marginTop: "45px",
    padding: "0 12px",
  },

  box_heder: {
    padding: "16px 0",
    alignItems: "center",

    "& .title": {
      fontSize: "20px",
      fontWeight: "700",
    },

    "& .box_search": {
      marginTop: "18px",
      width: "100%",
      textAlign: "center",

      "& .label": {
        fontSize: "16px",
        fontWeight: "500",
        color: "#686868",
        marginBottom: "12px",
      },

      "& .text-field-search": {
        width: "60%",
        minWidth: "300px",
        maxWidth: "750px",
      },
    },
  },

  box_button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .text-field-selecter": {
      width: "150px",
      marginRight: "16px",
    },

    "& .text-field-search": {
      minWidth: "260px",
    },

    "& .div-buttons": {
      "& .button": {
        color: "#4B4B4B",
        background: "white",
        marginRight: "12px",
        border: "1px solid #ECECEC",
        minWidth: "130px",
      },
    },
  },

  div: {
    overflow: "auto",
    marginTop: "24px",
    paddingBottom: "0",
  },

  table: {
    minWidth: "1340px",
    paddingLeft: "12px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      fontWeight: "700",
      fontSize: "13px",
    },

    "& td": {
      fontSize: "13px",
    },

    "& .sticky": {
      position: "sticky",
    },

    "& .STT": {
      width: "45px",
      paddingLeft: "12px",
    },

    "& .status": {
      width: "55px",
    },

    "& td.create": {
      width: "50px",
      right: 0,
      background: "white",
      cursor: "pointer",
      color: "#0B7CE4",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "2px solid #C0C0C0",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #ECECEC",
    background: "white !important",
  },

  box_heder_detail_create: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 0",
    alignItems: "center",

    "& .title": {
      fontSize: "20px",
      fontWeight: "700",
    },

    "& .buttons-delete": {
      background: "white",
      color: "#4B4B4B",
      fontWeight: "500",
      border: "1px solid #DCDCDC",
      marginRight: "12px",
    },

    "& .buttons-send": {
      background: "#0B7CE4",
      color: "white",
      fontWeight: "500",
    },
  },

  box_info_insurance: {
    border: "1px solid #ECECEC",
    borderRadius: "8px",
    background: "white",
    padding: "12px",
    fontSize: "15px",
    marginBottom: "12px",

    "& .title": {
      marginBottom: "12px",
      fontSize: "18px",
      fontWeight: "600",
      color: "#4B4B4B",
    },

    "& .label": {
      marginBottom: "2px",
      fontWeight: "400",
      color: "#61646D",
    },

    "& .value": {
      fontWeight: "600",
      color: "#3961A0",
    },
  },

  box_type_compensation: {
    border: "1px solid #ECECEC",
    borderRadius: "8px",
    background: "white",
    padding: "12px",
    fontSize: "15px",
    marginBottom: "12px",

    "& .title": {
      marginBottom: "12px",
      fontSize: "18px",
      fontWeight: "600",
      color: "#4B4B4B",
    },

    "& .box_text": {
      width: "45%",
      minWidth: "300px",
      marginBottom: "12px",

      "& .label": {
        marginBottom: "6px",
        fontWeight: "400",
        color: "#61646D",
      },
    },
  },

  box_document: {
    border: "1px solid #ECECEC",
    borderRadius: "8px",
    background: "white",
    padding: "12px",
    fontSize: "15px",
    marginBottom: "12px",

    "& .title": {
      marginBottom: "12px",
      fontSize: "18px",
      fontWeight: "600",
      color: "#4B4B4B",
    },

    "& .label_document": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "16px",

      "& .button_file": {
        fontWeight: "400",
        color: "#3961A0",
        padding: "6px 12px",
        border: "1px solid #DCDCDC",

        "& .icon_upload": {
          marginRight: "8px",

          "& path": {
            stroke: "#3961A0",
          },
        },
      },
    },

    "& .attached-file": {
      display: "flex",
      justifyContent: "space-between",
      color: "#0B7CE4",
      borderBottom: "1px solid #ECECEC",
      paddingBottom: "8px",
      marginBottom: "12px",
    },

    "& .label-attached-file": {
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      cursor: "pointer",
    },
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "white",
    padding: "8px 12px",

    "& .MuiTablePagination-root": {
      borderBottom: "none",
    },

    "& .MuiToolbar-root": {
      "& .MuiTablePagination-selectLabel": {
        display: "none",
      },

      "& .MuiInputBase-root": {
        display: "none",
      },

      "& .MuiTablePagination-displayedRows": {
        margin: "auto",
      },
    },

    "& .rows-per-page": {
      color: "#3961A0",
      fontSize: "14px",
    },
  },

  //
}));
