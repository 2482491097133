import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import useReducer from "../../../../library/hooks/useReducer";
import { creatProduct } from "../styles/styleCreatProduct";
import { initialStateProductManager } from "../util";
import productManagerReducer from "../reducers/productManagerReducer";
import LoadingMui from "../../../../ui/LoadingMui";
import Snackbars from "../../../../ui/snackbar";
import LogoProduct from "../components/creatProiductLogo";
import InfoProduc from "../components/creatProductInfo";
import { get } from "lodash";
import { useHistory, useParams } from "react-router-dom";

export default function CreatProduct() {
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const userType = get(user, "extra_info.userType", "") || "";
  const classes = creatProduct();
  const history = useHistory();

  const [state, dispatch, clearStateGlobal] = useReducer({
    initialState: initialStateProductManager,
    reducer: productManagerReducer,
    name: "productManagerReducer",
    userType,
    history,
    id,
  });

  const { loading, snackbar } = state;

  useEffect(() => {
    dispatch("initial_creat");

    return () => clearStateGlobal();
  }, []);

  return (
    <div className={classes.container}>
      <Box className={classes.box_heder}>
        <div className="title-creat-product">{id === "new" ? "Tạo Sản Phẩm " : "Cập Nhật Sản Phẩm"}</div>
        <div>
          <Button
            className="buttons-delete"
            onClick={() => dispatch("deleteAll_create")}
          >
            Xoá hết
          </Button>
          <Button className="buttons-save" onClick={() => dispatch("validate")}>
            Lưu lại
          </Button>
        </div>
      </Box>
      <Grid container spacing={1}>
        <Grid xs={6} sm={5} md={3.5}>
          <LogoProduct {...{ state, dispatch, userType, id }} />
        </Grid>
        <Grid xs={6} sm={7} md={8.5}>
          <InfoProduc {...{ state, dispatch, id }} />
        </Grid>
      </Grid>
      <LoadingMui loading={Boolean(loading)} />
      <Snackbars
        open={Boolean(snackbar.open)}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => dispatch("onCloseSnackbar")}
      />
    </div>
  );
}
