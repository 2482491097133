import React, { useEffect } from "react";
import { Wrapper, ContentWrapper, Content } from "../carAccident/styles";
import { Col, Row } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Grid, Typography, IconButton } from "@mui/material";

import addFile from "../../../../assets/icons/addFile.svg";
import fileTomato from "../../../../assets/icons/fileTomato.svg";
import * as PATH from "../../../../configs/routesConfig";
import HeaderBar from "./components/layout/HeaderBar";
const queryString = require("query-string");

const InsuTomatoHome = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let q = queryString.parse(location?.search);
    if(!!q.token){localStorage.setItem("token", q.token);}
  }, []);

  const handleClickBtn1 = () => {
    history.push(PATH.CREATE_REQUEST_CLAIM_TOMATO + "?goback");
  };

  const handleClickBtnList = () => {
    history.push(PATH.CLAIM_BENEFICIARY_LIST + "?goback");
  };

  const onGoBack = () => {
    window.ReactNativeWebView.postMessage('onGoBack');
  }

  return (
    <Wrapper>
      <HeaderBar
        title="Bảo hiểm TOMATO"
        background="#F2F2F2"
        onPress={onGoBack}
      />
      <ContentWrapper style={{ backgroundColor: "white", marginTop: 30 }}>
        <Content>
          <Row justify="center">
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <div style={formInfo}>
                <div style={infoAccident}>Tiện ích</div>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={6} height="20vh">
                    <Box
                      sx={bgDashboardIntro}
                      component="button"
                      onClick={handleClickBtn1}
                    >
                      <div style={divIcon}>
                        <IconButton
                          sx={{
                            fontSize: "32px",
                            color: "#024281",
                          }}
                        >
                          <img src={addFile} alt="addFile" />
                        </IconButton>
                      </div>
                      <div style={divText}>
                        <Typography sx={typographyStyle}>
                          {"Tạo yêu cầu bồi thường"}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item sm={6} xs={6} height="20vh">
                    <Box
                      sx={bgDashboardIntro2}
                      component="button"
                      onClick={handleClickBtnList}
                    >
                      <div style={divIcon}>
                        <IconButton
                          sx={{
                            fontSize: "32px",
                            color: "#D4540B",
                          }}
                        >
                          <img src={fileTomato} alt="addFile" />
                        </IconButton>
                      </div>
                      <div style={divText}>
                        <Typography sx={typographyStyle}>
                          {"Hợp đồng bảo hiểm của bạn"}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Col>
          </Row>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default InsuTomatoHome;

const formInfo = {
  margin: "24px 0 12px 0",
};

const infoAccident = {
  fontFamily: `"Averta Std CY",sans-serif`,
  fontSize: 24,
  lineHeight: "30px",
  color: "#000000",
  margin: "12px 0 12px 0",
};

const bgDashboardIntro = {
  borderWidth: 0,
  display: "block",
  background: "#F0F7FF",
  boxShadow: "0px 0px 13.6464px rgba(0, 0, 0, 0.05)",
  borderRadius: "21.8343px",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  color: "#013C76",
};

const bgDashboardIntro2 = {
  borderWidth: 0,
  background: "#FFF1E5",
  boxShadow: "0px 0px 13.6464px rgba(0, 0, 0, 0.05)",
  borderRadius: "21.8343px",
  height: "100%",
  display: "block",
  justifyContent: "center",
  alignItems: "center",
  color: "#013C76",
};

const divIcon = {
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
};

const divText = {
  display: "flex",
  width: "80%",
  justifyContent: "flex-start",
};

const typographyStyle = {
  fontWeight: "600",
  fontSize: 14,
  padding: "8px",
};
