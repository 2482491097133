import React from "react";
import { Button, IconButton, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import InsuranceBenefitTable from "./insuranceBenefitsTable";
import { detailProduct } from "../styles/styleDetailProduct";
import { Switch, TextField } from "../overrideMui";
import { ReactComponent as ImportFile } from "../assets/icon_import_file.svg";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { get } from "lodash";
import NumberFormat from "react-number-format";

export default function UpdateProductPackageInfor(props) {
  const classes = detailProduct();

  const { state, dispatch } = props;
  const { details, payloadProductPackage, errors } = state;

  const waitingTime = [
    {
      title: "Tai nạn",
      valueTime: get(
        payloadProductPackage,
        "extra_info.config_waiting.accident"
      ),
      valueAmount: get(
        payloadProductPackage,
        "extra_info.config_share_payment.accident"
      ),
      name: "accident",
      minWidth: 90,
    },
    {
      title: "Bệnh có sẵn",
      valueTime: get(
        payloadProductPackage,
        "extra_info.config_waiting.existing_disease"
      ),
      valueAmount: get(
        payloadProductPackage,
        "extra_info.config_share_payment.existing_disease"
      ),
      name: "existing_disease",
      minWidth: 105,
    },
    {
      title: "Bệnh đặt biệt",
      valueTime: get(
        payloadProductPackage,
        "extra_info.config_waiting.special_disease"
      ),
      valueAmount: get(
        payloadProductPackage,
        "extra_info.config_share_payment.special_disease"
      ),
      name: "special_disease",
      minWidth: 115,
    },
    {
      title: "thai sản",
      valueTime: get(
        payloadProductPackage,
        "extra_info.config_waiting.pregnancy"
      ),
      valueAmount: get(
        payloadProductPackage,
        "extra_info.config_share_payment.pregnancy"
      ),
      name: "pregnancy",
      minWidth: 90,
    },
    {
      title: "Biến chứng thai sản",
      valueTime: get(
        payloadProductPackage,
        "extra_info.config_waiting.pregnancy_complications"
      ),
      valueAmount: get(
        payloadProductPackage,
        "extra_info.config_share_payment.pregnancy_complications"
      ),
      name: "pregnancy_complications",
      minWidth: 170,
    },
    {
      title: "Bệnh TT nội trú",
      valueTime: get(
        payloadProductPackage,
        "extra_info.config_waiting.inpatient_treatment"
      ),
      valueAmount: get(
        payloadProductPackage,
        "extra_info.config_share_payment.inpatient_treatment"
      ),
      name: "inpatient_treatment",
      minWidth: 130,
    },
    {
      title: "Bệnh TT ngoại trú",
      valueTime: get(
        payloadProductPackage,
        "extra_info.config_waiting.outpatient_treatment"
      ),
      valueAmount: get(
        payloadProductPackage,
        "extra_info.config_share_payment.outpatient_treatment"
      ),
      name: "outpatient_treatment",
      minWidth: 150,
    },
    {
      title: "Răng",
      valueTime: get(payloadProductPackage, "extra_info.config_waiting.tooth"),
      valueAmount: get(
        payloadProductPackage,
        "extra_info.config_share_payment.tooth"
      ),
      name: "tooth",
      minWidth: 90,
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.box_update_product_pakage_infor}>
        <div className="title-label">Thông tin gói bảo hiểm</div>
        <div className="box-content-infor">
          <div className="box-text-field">
            <div className="label-text-field">Tên gói sản phẩm</div>
            <TextField
              placeholder="Tên gói sản phẩm"
              fullWidth
              error={(errors || [])?.includes("title")}
              onChange={(e) => {
                dispatch({
                  type: "onChangePayload",
                  name: "title",
                  value: e.target.value,
                });
              }}
              value={payloadProductPackage?.title || ""}
            />
          </div>
          <div className="box-text-field">
            <div className="label-text-field">Phí bảo hiểm</div>
            <NumberFormat
              thousandsGroupStyle="thousand"
              decimalSeparator=","
              thousandSeparator="."
              customInput={TextField}
              placeholder="Nhập phí bảo hiểm"
              fullWidth
              // className={classes.textField}
              onChange={(e) => {
                dispatch({
                  type: "onChangePayload",
                  value: (e.target.value || "").replace(/\./g, ""),
                  name: "fees",
                });
              }}
              value={payloadProductPackage?.fees || ""}
            />
          </div>
          <div className="box-text-field">
            <div className="label-text-field">Mức miễn thường</div>
            <NumberFormat
              thousandsGroupStyle="thousand"
              decimalSeparator=","
              thousandSeparator="."
              customInput={TextField}
              placeholder="Nhập mức miễn thường"
              fullWidth
              // className={classes.textField}
              onChange={(e) => {
                dispatch({
                  type: "onChangePayload",
                  value: (e.target.value || "").replace(/\./g, ""),
                  name: "insurance_deductible",
                });
              }}
              value={
                payloadProductPackage?.extra_info?.insurance_deductible
                  ?.value || ""
              }
            />
          </div>
        </div>
      </div>
      <div className={classes.box_update_product_wartting}>
        <div className="title-label">Thời gian chờ</div>
        <div className="box-content-infor">
          {waitingTime.map((item, index) => (
            <div className="box-text-field" key={`box-text-field-${index}`}>
              <div className="label-text-field">{item.title}</div>
              <TextField
                fullWidth
                onChange={(e) =>
                  dispatch({
                    type: "onChangeTmeAndPayment",
                    value: e.target.value,
                    name: item.name,
                    typeTable: "config_waiting",
                  })
                }
                value={item.valueTime}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={classes.box_update_product_wartting}>
        <div className="title-label">Đồng chi trả</div>
        <div className="box-content-infor">
          {waitingTime.map((item, index) => (
            <div className="box-text-field" key={`box-text-field-${index}`}>
              <div className="label-text-field">{item.title}</div>
              <TextField
                fullWidth
                value={item.valueAmount}
                onChange={(e) =>
                  dispatch({
                    type: "onChangeTmeAndPayment",
                    value: e.target.value,
                    name: item.name,
                    typeTable: "config_share_payment",
                  })
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className={classes.box_update_table}>
        <div className="title">
          <span>Quyền lợi bảo hiểm</span>
          <div>
            <Button
              className="buttons"
              onClick={() => {
                dispatch({ type: "dowTemplateBenefits" });
              }}
            >
              {" "}
              <VerticalAlignBottomOutlinedIcon
                fontSize="small"
                sx={{ marginRight: "6px" }}
              />{" "}
              Tải template
            </Button>

            <Button
              className="buttons"
              sx={{ marginX: "8px" }}
              onClick={() => {
                document.getElementById("input_template_file").click();
              }}
            >
              <input
                hidden
                type="file"
                accept=".xlsx, .xls"
                id="input_template_file"
                onChange={(e) => {
                  dispatch({ type: "importTemplate", file: e.target.files[0] });
                  e.target.value = "";
                }}
              />
              <ImportFile
                style={{ marginRight: "6px", width: "18px", height: "18px" }}
              />{" "}
              Import file
            </Button>
            <Button
              className="buttons add-button"
              onClick={() => dispatch("openDialogAddBenefit")}
            >
              <AddOutlinedIcon
                sx={{
                  border: "1px solid #0B7CE4",
                  borderRadius: "18px",
                  marginRight: "6px",
                }}
                fontSize="small"
              />
              Thêm quyền lợi
            </Button>
          </div>
        </div>
        <InsuranceBenefitTable {...{ state, dispatch }} />
      </div>
    </div>
  );
}
