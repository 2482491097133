import { makeStyles } from "@mui/styles";

export const reportStyle = makeStyles(() => ({
  container: {
    marginTop: "60px",
    padding: "0 12px",
    width: "100%",
  },

  title: {
    width: "100%",
    fontSize: "24px",
    fontWeight: "700",
    margin: "12px 0",
  },

  tabsDetail: {
    marginBottom: "16px",
    borderBottom: "1px solid #DCDCDC",
    zIndex: 3,
    position: "sticky",
    top: 0,

    "& .MuiTabScrollButton-root": {
      width: "20px",
    },

    "& .MuiButtonBase-root.MuiTab-root": {
      fontSize: "14px",
      textTransform: "none",
      fontWeight: "500",
      color: "#3961A0",
    },

    "& .MuiButtonBase-root.Mui-selected": {
      fontWeight: "700",
      color: "#3961A0",
    },

    "& .MuiTabs-indicator": {
      background: "#3961A0",
    },
  },

  head_filter: {
    width: "100%",
    fontSize: "14px",

    "& .first-header": {
      display: "flex",
      background: "white",
      padding: "12px",
      alignItems: "center",
      boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.1)",
      justifyContent: "space-between",
      "& .box-select": {
        display: "flex",
        alignItems: "center",
      },
    },

    "& .secont-header": {
      marginTop: "8px",
      display: "flex",
      background: "white",
      padding: "12px",
      alignItems: "center",
      boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.1)",

      "& .select-type-insurance": {
        width: "135px",
        marginRight: "8px",

        "& .MuiInputBase-root": {
          paddingLeft: "0px",
        },
      },

      "& .text-field-search": {
        minWidth: "240px",
      },
    },

    "& .button": {
      color: "#4B4B4B",
      background: "white",
      border: "1px solid #ECECEC",
      fontWeight: "500",
      padding: "8px",
    },
  },

  textFieldStandard: {
    width: "150px",
  },

  div: {
    marginTop: "12px",
  },

  table: {
    minWidth: "1650px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      fontWeight: "600",
      textAlign: "center",
      whiteSpace: "nowrap",
      fontSize: "13px",
    },

    "& td": {
      textAlign: "center",
      padding: 0,
      fontSize: "13px",
    },

    "& .stickey": {
      position: "sticky",
    },

    "& th.stt": {
      width: "45px",
    },

    "& th.code": {
      width: "155px",
    },

    "& th.amount": {
      width: "145px",
    },

    "& th.provider_title": {
      minWidth: "220px",
    },

    "& th.YCBT": {
      width: "130px",
    },

    "& th.status": {
      width: "125px",
    },

    "& th.full-name": {
      minWidth: "180px",
      textTransform: "capitalize",
    },

    "& th.view-records": {
      right: 0,
      background: "white",
    },

    "& td.full-name": {
      textTransform: "capitalize",
    },

    "& td.view-records": {
      right: 0,
      background: "white",
    },

    "& td.reviewer": {
      minWidth: "160px",
      paddingLeft: "8px",
      textAlign: "center",
      // display: "flex",
      // justifyContent: "space-between",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "2px solid #686868",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #D6DEEB",
    background: "white !important",
  },

  max_height_autocomplete: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      margin: 0,
      marginTop: "12px",
      maxHeight: "300px",

      "& .MuiAutocomplete-listbox": {
        maxHeight: "300px",
      },
    },
  },

  //
}));
