import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    marginTop: "55px",
    padding: "0 16px",

    "& .head-title": { color: "#2A3790", fontWeight: "600" },
    "& .result-text": { marginTop: 20, color: "red", fontSize: 17 },
  },

  cardHeadSelect: {
    background: "#ECF8FD",
    marginTop: "12px",
    padding: "24px",
    borderRadius: "12px",
  },

  divInput: {
    "& .input": {
      padding: "6px 6px 6px 0",
      width: "100%",

      "&:before": { borderBottom: "none !important" },
      "&:after": { borderBottom: "none !important" },
    },

    "& .border": {
      display: "flex",
      border: "1px solid #2A3790",
      borderRadius: "8px",
      padding: "0 8px",
    },
  },

  label: {
    display: "flex",
    width: "100%",
    marginBottom: "6px",
    paddingLeft: "6px",
  },

  buttonSearch: {
    width: "99%",
    maxWidth: "240px",
    background: "#2A3790",
    color: "white",

    "&:hover": {
      background: "#2A3790",
    },

    "& .img": {
      marginRight: "12px",
    },
  },

  select: {
    padding: "6px 0",
    background: "#ECF8FD",

    "& .MuiSelect-select.MuiSelect-outlined": {
      paddingTop: "4px",
      paddingBottom: "5px",
      paddingLeft: 0,
      border: "none",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    },
  },

  selectPage: {
    paddingLeft: "6px",
    width: "150px",
    border: "1px solid #2A3790",
    borderRadius: "8px",
    display: "flex",
    height: "35px",

    "& .select-number": {
      "& .MuiSelect-select.MuiSelect-outlined": {
        paddingTop: "4px",
        paddingBottom: "5px",
        paddingLeft: 0,
        border: "none",
        fontWeight: "600",
        fontSize: "15px",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
      },
    },
  },

  boxTable: {
    marginTop: "16px",

    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: "12px",
      borderBottom: "1px solid #BDBDBD",

      "& .button-table": {
        display: "flex",
      },
    },

    "& .head-title": {
      fontSize: "20px",
    },
    "& .icon": { width: 25, marginRight: "6px" },

    "& .button": {
      border: "1px solid #2A3790",
      borderRadius: "8px",
      marginLeft: "12px",
      display: "flex",
      alignItems: "center",
      padding: "0 6px",

      "& .MuiButtonBase-root.MuiButton-root": {
        padding: "0",
        color: "#2A3790",
      },
    },
  },

  div: {
    overflowX: "auto",
  },

  table: {
    marginTop: "20px",
    minWidth: "1208px",

    "& .sticky": {
      position: "sticky",
    },

    "& thead th": { color: "#2A3790 !important", textAlign: "center" },

    "& thead .stt": { width: "50px" },

    "& thead .date": { width: "140px" },

    "& thead .show": { width: "120px", right: 0 },

    "& tbody td": {
      textAlign: "center",
    },

    "& tbody .odd": {
      backgroundColor: "white",
    },

    "& tbody .show": {
      textAlign: "center",
      right: 0,
      background: "white",
    },

    "& tbody .color2": {
      color: "#26A9E0",
    },

    "& tbody .color4": {
      color: "#FB7800",
    },

    "& tbody .color5": {
      color: "#39B44A",
    },

    "& tbody .color7": {
      color: "#BF272D",
    },
  },

  footer: {
    "& .MuiPagination-root": {
      "& .MuiButtonBase-root.Mui-selected": {
        color: "#2A3790",
        border: "1px solid #2A3790",
        backgroundColor: "rgba(89, 136, 255, 0.12)",
      },
    },
  },

  infor: {
    background: "#EBFFFE",
  },

  document: {
    background: "#FFFFE7",
    // height: "calc(100% - 100px)",
    padding: "12px 0",

    "& .headDocument": {
      display: "flex",
      justifyContent: "space-between",
      color: "#284078",
      fontSize: "16px",
      fontWeight: "600",
      padding: "12px",
    },
  },

  history: {
    background: "#EBFFFE",
    height: "100%",

    "& .head-history": {
      padding: "12px 24px",
      color: "#284078",
      fontSize: "18px",
      fontWeight: "600",
    },

    "& .form-infor": {
      display: "flex",
      alignItems: "start",
      background: "white",
      padding: "8px",
      fontSize: "14px",

      "& .inline": {
        margin: "8px 0",

        "& .title": {
          color: "#284078",
          fontWeight: "600",
        },

        "& img": {
          marginRight: "4px",
          width: "20px",
          height: "20px",
        },
      },
    },
  },

  accordionRoot: {
    background: "#EBFFFE",
    boxShadow: "none",
    minHeight: "38px",
    margin: "0",

    "&:before": { backgroundColor: "#EBFFFE" },

    "& .MuiAccordionSummary-root": {
      minHeight: "38px",
    },

    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0",
    },

    "& .MuiButtonBase-root.MuiAccordionSummary-root": {
      padding: "0 12px",
    },

    "& .MuiAccordionDetails-root": {
      padding: "8px 12px 0",
    },

    "& .titleAccodion": {
      color: "#284078",
      fontSize: "18px",
      fontWeight: "600",
    },
  },

  boxAccor: {
    background: "#EBFFFE",
    padding: "0 0 12px 0",

    "& .Mui-expanded": {
      margin: "0 0 4px 0",
    },

    "& .rowDiv": {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "14px",
      // fontWeight: "500",
      marginBottom: "8px",
      borderBottom: "1px solid #D2D2D2",
      paddingBottom: "6px",

      "& .titleSpan": {
        color: "#2A3790",
        whiteSpace: "nowrap",
        marginRight: "6px",
      },
    },

    "& .capitalize": {
      textTransform: "capitalize",
    },
  },

  boxImg: {
    width: "100%",
    margin: "12px 0",
    overflowX: "auto",
    padding: "0 12px",

    "& .title": {
      fontSize: "14px",
      color: "#2A3790",
      fontWeight: "600",
      marginBottom: "12px",
      left: 0,
      position: "sticky",
    },

    "& .div-img": {
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },

    "& .img": {
      marginRight: "12px",
      width: "110px",
      height: "90px",
      display: "flex",
      alignItems: "center",
      background: "white",
    },

    "& .not-have-img": {
      margin: "12px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      color: "red",
      fontStyle: "italic",
    },
  },

  boxImgAdditional: {
    width: "100%",
    margin: "12px 0",
    overflowX: "auto",
    background: "white",
    padding: "12px",

    "& .title": {
      fontSize: "14px",
      color: "#2A3790",
      fontWeight: "600",
      marginBottom: "12px",
      left: 0,
      position: "sticky",
    },

    "& .div-img": {
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },

    "& .img": {
      marginRight: "12px",
      width: "110px",
      height: "90px",
      display: "flex",
      alignItems: "center",
      background: "white",
    },
  },

  boxLine: {
    padding: "0 12px",

    "& .input-multiline": {
      background: "white",
      fontSize: "14px",
      padding: "8px !important",
      borderRadius: "8px",
      border: "1px solid #F1F1F1",

      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },

      "&::before": {
        borderBottom: "none",
      },

      "&::after": {
        borderBottom: "none",
      },
    },

    "& .lineTitle": {
      margin: "8px 0",
      color: "#284078",
      fontSize: "18px",
      fontWeight: "600",
    },
  },

  button: {
    color: "#2A3790",
    width: "200px",
    border: "2px solid #2A3790",
    borderRadius: "32px",
    margin: "20px",
  },

  buttonDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",

    "& .button": {
      width: "200px",
      marginLeft: "70px",
      background: "#2A3790",
      color: "white",
      borderRadius: "32px",
      margin: "20px",
    },

    "& .disabled": {
      background: "#a8a9ac",
    },
  },

  date: {
    "& .MuiInputBase-root": {
      background: "#eaf8fd",
      height: "46px",
    },

    "& .MuiInputBase-input": {
      padding: "13px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    },
  },

  additional_img: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "8px",
    border: "1px solid #284078",
    borderRadius: "12px",
    width: "110px",
    marginRight: "8px",
    marginBottom: "6px",
    position: "relative",
    height: "90px",

    "& .spanPicture": {
      color: "#284078",
      textAlign: "center",
      fontWeight: "500",
      fontSize: "14px",
    },

    "& .icon": {
      position: "absolute",
      top: 0,
      right: 0,
    },
  },

  exportButton: {
    padding: "2px",
    marginTop: "25px",
    display: "flex",
    justifyContent: "space-around",

    "& .bt-show": {
      padding: "6px",
      background: "#1A427D",
      color: "white",
      fontSize: " 14px",
    },
  },
}));

export { useStyles };
