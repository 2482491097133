import MuiTextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const TextField = styled(MuiTextField)({
  "& .MuiInputBase-input": {
    padding: "12px 13px",
    background: "white",
    borderRadius: "16px",
  },

  "& fieldset": {
    borderRadius: "16px",
    border: "1px solid #DCDCDC",
  },
});

export default TextField;
