import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import TableToExcel from "@linways/table-to-excel";
import { useRecoilState } from "recoil";

import { useStyles } from "../styles/useStyles";
import useReducer from "../../../../../library/hooks/useReducer";
import CommonTable from "../../../../../library/layout/commonTable";
import { columns } from "../components/columns";
import pbaReducer from "../reducer/pbaReducer";
import TableExportExcelPBA from "../components/TableExportExcelPBA";
import TableExportExcelPBA_GC from "../components/TableExportExcelPBA-GC";

import CarSearch from "../components/CarSearch";
import DialogNote from "../components/DialogNote";
import exportIcon from "../assets/exportIcon.svg";
import { dataSearchList } from "../atomPBA";

const initialState = {
  rows: [],
  dataExport: [],
  row: {},
  showImg: {},
  dataSearch: {},
  errors: {},
  pageTable: {
    count: 0,
    page: 0,
    rowsPerPage: 10,
  },
  count: 0,
  totalRows: 0,
  approvalAmount: "",
  content: "",
  setPage: "compensation",
  loading: false,
  confirmDialog: false,
  openMaxLineDialog: false,
};

export default function CompensationReport(props) {
  const { form } = props;
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const roleType = user?.extra_info?.userType;
  const history = useHistory();

  const [dataSearchPBAList, setDataSearchPBAList] =
    useRecoilState(dataSearchList);
  const [isFirstRender, setIsFirstRender] = useState(false);

  const [state, dispatch] = useReducer({
    initialState: initialState,
    reducer: pbaReducer,
    name: "pbaReducer",
    history,
    form,
    roleType,
    dataSearchPBAList,
    setDataSearchPBAList,
  });

  const {
    loading,
    rows,
    pageTable,
    count,
    dataExport,
    dataSearch,
    openMaxLineDialog,
  } = state;

  useEffect(() => {
    setIsFirstRender(true);
    return () => {
      const fetchData = async () => {
        if (
          !["/admin/pba-detail-review", "/admin/pba-detail-business"].includes(
            window.location?.pathname
          )
        ) {
          setDataSearchPBAList({});
        }
      };

      fetchData();
    };
  }, [window.location.pathname]);

  useEffect(() => {
    if (isFirstRender) {
      dispatch("initial");
    }
  }, [isFirstRender]);

  useEffect(async () => {
    if (count !== 0) {
      const table =
        roleType === "globalcare"
          ? document.getElementById("export-data-table-pba-gc")
          : document.getElementById("export-data-table-pba");
      TableToExcel.convert(table, {
        name: `bao-cao-boi-thuong-phuc-binh-an.xlsx`,
        sheet: {
          name: "Sheet 1",
        },
      });
    }
  }, [count]);

  return (
    <div className={classes.container}>
      <div className="head-title">
        {form === "review-claim"
          ? "Kiểm Tra Xử Lý Hồ Sơ"
          : "Báo Cáo Bồi Thường"}
      </div>
      <CarSearch {...{ state, dispatch }} />
      <div className={classes.boxTable}>
        <div className="head">
          <div className="head-title">KẾT QUẢ TRA CỨU</div>
          <div className="button-table">
            <div className={classes.selectPage}>
              <Select
                className="select-number"
                fullWidth
                size="small"
                value={pageTable?.rowsPerPage || 10}
                style={{ color: "#2A3790" }}
                onChange={(e) => {
                  dispatch({
                    type: "onPaging",
                    page: pageTable?.page,
                    rowsPerPage: e?.target?.value,
                  });
                }}
              >
                <MenuItem value={10}>Hiển thị 10</MenuItem>
                <MenuItem value={20}>Hiển thị 20</MenuItem>
                <MenuItem value={50}>Hiển thị 50</MenuItem>
              </Select>
            </div>
            {form === "report-claim" && (
              <div className="button">
                <Button onClick={() => dispatch("onSubmitExport")}>
                  <img
                    src={exportIcon}
                    style={{ width: 25, marginRight: "6px" }}
                    alt="ic"
                  />
                  Xuất báo cáo
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {rows.length > 0 ? (
        <CommonTable
          columns={columns}
          rows={rows}
          classes={classes}
          page={{
            ...pageTable,
            onChange: (event, page, rowsPerPage) => {
              dispatch({
                type: "onPaging",
                page,
                rowsPerPage,
              });
            },
          }}
          event={(type, data) =>
            (ele, ...rest) => {
              dispatch({ type, data, ele, rest });
            }}
        />
      ) : (
        <div style={{ marginTop: "20px", color: "red", fontSize: "18px" }}>
          Không có kết quả giống với từ khoá bạn tìm. Vui lòng kiểm tra lại
        </div>
      )}
      <DialogNote
        open={!!openMaxLineDialog}
        dispatch={dispatch}
        loading={loading}
      />
      <TableExportExcelPBA
        data={dataExport}
        dataSearch={dataSearch}
        roleType={roleType}
      />
      <TableExportExcelPBA_GC data={dataExport} dataSearch={dataSearch} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
