import React from "react";
import { Tabs, Tab } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { labelTabs } from "../utils";

import ContractInfomation from "./contract-information";
import Lincense from "./license";
import Claim from "./claim";
import ClaimLicensse from "./claimLicense";
import DialogNoti from "./DialogNoti";
import RecordkeepingTalbe from "./recordkeepingTable";
import Payment from "./payment";

const renderTab = ({
  tabKey,
  state,
  dispatch,
  level_id,
  systemType,
  detail,
  id,
  rowsInsuranceBenefit,
  tabsDetail,
  dialogInsuranceInfo,
}) => {
  switch (tabKey) {
    case "contract_infomation":
      return <ContractInfomation {...{ state, dispatch }} />;
    case "lincense":
      return <Lincense {...{ state, dispatch, level_id }} />;
    case "claim":
      return (
        <Claim
          {...{
            level_id,
            systemType,
            detail,
            id,
            rowsInsuranceBenefit,
            tabsDetail,
            dialogInsuranceInfo,
            transData: (data) => dispatch({ type: "transData", data }),
          }}
        />
      );
    case "claim-license":
      return <ClaimLicensse {...{ state, dispatch, level_id }} />;
    case "recordkeeping-talbe":
      return <RecordkeepingTalbe {...{ state, dispatch, level_id }} />;
    case "payment":
      return <Payment {...{ state, dispatch }} />;

    default:
      return null;
  }
};

export default function TabsDetail(props) {
  const { state, dispatch, classes, level_id, systemType, id } = props;
  const {
    tabsDetail,
    dialogNoti,
    detail,
    rowsInsuranceBenefit,
    dialogInsuranceInfo,
  } = state;

  return (
    <div className={classes.containerTabsDetail}>
      <Tabs
        className={classes.tabsDetail}
        value={tabsDetail.value}
        onChange={(e, value) => {
          dispatch({ type: "onChangeTabs", value });
        }}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="basic tabs example"
      >
        {labelTabs.map((item, index) => (
          <Tab
            label={
              <span style={{ display: "flex", alignItems: "center" }}>
                {item} <ArrowRightIcon />
              </span>
            }
            key={item}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      <div>
        {renderTab({
          tabKey: tabsDetail.key,
          state,
          dispatch,
          level_id,
          systemType,
          detail,
          id,
          rowsInsuranceBenefit,
          tabsDetail,
          dialogInsuranceInfo,
        })}
      </div>
      <DialogNoti data={dialogNoti} dispatch={dispatch} />
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
