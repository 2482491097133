import { get, isEmpty, isString, set } from "lodash";
import {
  getDetailClaim,
  getConfigHealthInsurance,
  cdn,
  todoRemovedLinkS3,
} from "../../../../apis/blvp_api";

import { getListLicenseById, listLicense as listLicenseUnit } from "../util";

export default function detailBLVPReducer(props) {
  const { state, action, dispatch, id } = props;
  const userFromProfile = JSON.parse(localStorage.getItem("user")) || {};
  const userType = get(userFromProfile, "extra_info.userType");

  const cases = {
    initail: async () => {
      dispatch("onLoading");
      const [dataApi, configApi] = await Promise.all([
        getDetailClaim(userType, id),
        getConfigHealthInsurance(),
      ]);

      const res = {};
      const insuranceTypes = get(configApi, "data.result") || [];
      if (dataApi?.data?.statusCode === 200) {
        res.detail = get(dataApi, "data.result") || {};
        res.rowsBeneficiary =
          res.detail?.insuredInfo instanceof Object
            ? [res.detail.insuredInfo]
            : res.detail.insuredInfo || [];
      }

      return { loading: false, ...res, insuranceTypes };
    },

    onChangeTabsDetail: () => {
      const { value } = action;

      switch (value) {
        case 0:
          return { tabsDetail: { value, key: "contract_infomation" } };

        case 1:
          return { tabsDetail: { value, key: "documents" } };

        case 2:
          return { tabsDetail: { value, key: "approval_history" } };

        default:
          return { tabsDetail: { value, key: "contract_infomation" } };
      }
    },

    onChangeTabRight: () => {
      const { value } = action;
      return { tabsRight: value };
    },

    changeButtonRightTabs: () => {
      const { name } = action;
      return { tabButtonTabsRight: name };
    },

    // Document tabs

    initialLincense: () => {
      const { detail } = state;

      const listTypeIdLicese = [];

      const listRequestAdditionalDocument = (detail.elements || []).reduce(
        (array, item) => {
          if (item.type_id === 520) {
            const elements_amend = [];
            item?.extra_info?.elements_amend?.forEach((i) => {
              const itemElementUpdate = (detail.elements || []).find(
                (z) => (z.typeCode || z.type_code) === i.typeCode
              );

              const check = itemElementUpdate
                ? new Date(item.created_date) >
                  new Date(itemElementUpdate.created_date)
                : true;
              if (i.typeCode && check) {
                elements_amend.push({
                  ...i,
                  created_date: item.created_date,
                  additionalRequest: true,
                });
              }
            });

            return [...array, ...elements_amend];
          }
          return array;
        },
        []
      );

      const elementsLicense = detail.elements || [];

      listLicenseUnit.forEach((i) => {
        const check = elementsLicense.some((j) => j.type_code === i.typeCode);
        const checkJustRequestAdditional =
          !check &&
          listRequestAdditionalDocument.some((j) => j.typeCode === i.typeCode);

        if (check) {
          const tempElements = elementsLicense.find(
            (a) => a.type_code === i.typeCode
          );

          const tempRequestAdditionalDocument =
            listRequestAdditionalDocument.find(
              (z) => i.typeCode === z.typeCode
            ) || {};

          listTypeIdLicese.push({
            title: i.title,
            typeId: i.typeId,
            typeCode: i.typeCode,
            ...tempElements,
            ...tempRequestAdditionalDocument,
          });
        }

        if (checkJustRequestAdditional) {
          const tempElements = listRequestAdditionalDocument.find(
            (a) => a.typeCode === i.typeCode
          );

          listTypeIdLicese.push({
            title: i.title,
            typeId: i.typeId,
            typeCode: i.typeCode,
            ...tempElements,
          });
        }
      });

      const tempImgLicese = listTypeIdLicese.find((i) =>
        Boolean(get(i, "extra_info.documents[0].path"))
      );

      const listLicese = isEmpty(tempImgLicese)
        ? []
        : tempImgLicese?.extra_info?.documents || [];
      const imgLicese = isEmpty(tempImgLicese)
        ? {}
        : {
            indexImg: 0,
            nameLicense: tempImgLicese.title,
            typeId: tempImgLicese.typeId,
            typeCode: tempImgLicese.typeCode,
            created_date: tempImgLicese.created_date,
            additionalRequest: tempImgLicese.additionalRequest,
            text: tempImgLicese.text,
          };

      const typeIdLiceseButton = tempImgLicese?.type_id;

      const expandAccordions = {
        hospitalLicensse: true,
        persionalLicensse: true,
      };

      return {
        listTypeIdLicese,
        imgLicese,
        listLicese,
        expandAccordions,
        typeIdLiceseButton,
      };
    },

    onChangExpand: () => {
      const { name } = action;
      const { expandAccordions } = state;
      expandAccordions[name] = !expandAccordions[name];
      return { expandAccordions };
    },

    onClickLicense: () => {
      const { item } = action;
      const res = {};

      res.listLicese = item?.extra_info?.documents || [];

      res.imgLicese =
        res.listLicese.length > 0
          ? {
              ...((get(item, "extra_info.documents") || []).find(
                (i) => !!i?.path
              ) || {}),
              indexImg: 0,
              nameLicense: item?.title,
              typeId: item?.typeId,
              typeCode: item?.type_code || item?.typeCode,
              created_date: item?.created_date || null,
              additionalRequest: item?.additionalRequest,
              text: item?.text,
            }
          : {
              indexImg: 0,
              nameLicense: item?.title,
              typeId: item?.typeId,
              typeCode: item.type_code || item.typeCode,
              created_date: item?.created_date || null,
              additionalRequest: item?.additionalRequest,
              text: item?.text,
            };

      res.typeIdLiceseButton = item?.typeId || item?.type_id;

      return { ...res };
    },

    uploadImgToS3: async () => {
      const { e } = action;
      const { imgLicese, listTypeIdLicese } = state;
      const indexLisTypeId = listTypeIdLicese.findIndex(
        (i) => i.typeId === imgLicese.typeId
      );

      const files = e.target.files;
      if (files.length === 0) {
        return;
      }
      const res = {};
      dispatch("onLoading");

      const promises = [];

      for (let i = 0; i < files.length; i++) {
        const data = new FormData();
        data.append("document", files[i]);
        promises.push(cdn(data));
      }

      const resuiltApi = await Promise.all(promises);
      resuiltApi.forEach((api, i) => {
        if (api?.data?.statusCode === 200) {
          const dataApi = get(api, "data.result[0]") || {};
          const dataImg = {
            path: dataApi?.path,
            size: dataApi?.size,
            original_name: files[i]?.name || "",
            isNewImg: true,
          };
          const checkDocument = Array.isArray(
            listTypeIdLicese[indexLisTypeId]?.extra_info?.documents
          );

          checkDocument &&
            listTypeIdLicese[indexLisTypeId]?.extra_info?.documents?.push(
              dataImg
            );
          !checkDocument &&
            set(listTypeIdLicese, `[${indexLisTypeId}].extra_info.documents`, [
              dataImg,
            ]);
        }
        if (api?.data?.statusCode !== 200) return;
      });

      const listLicese =
        get(listTypeIdLicese, `[${indexLisTypeId}].extra_info.documents`) || [];

      return { loading: false, listLicese, listTypeIdLicese };
    },

    onChangInmg: () => {
      const { index } = action;
      const { listLicese, imgLicese } = state;
      return {
        imgLicese: {
          ...imgLicese,
          indexImg: index,
          ...listLicese[index],
        },
      };
    },

    deleteImgAdditionalRequest: () => {
      const { item, index } = action;
      const { listTypeIdLicese, listLicese, imgLicese } = state;
      const indexItemOfListTypeIdLicese = listTypeIdLicese.findIndex(
        (i) => i.typeId === imgLicese?.typeId
      );
      const res = {};
      if (indexItemOfListTypeIdLicese > -1) {
        const temp = get(
          listTypeIdLicese,
          `[${indexItemOfListTypeIdLicese}].extra_info.documents`
        );
        dispatch({ type: "deleteS3Url", url: item.path });
        temp?.length > 0 && temp.splice(index, 1);
        temp?.length > 0 &&
          set(
            listTypeIdLicese,
            `[${indexItemOfListTypeIdLicese}].extra_info.documents`,
            [...temp]
          );
        res.listLicese = [...temp];
        if (item.path === imgLicese.path) {
          res.imgLicese = {
            ...imgLicese,
            path: temp[0]?.path || "",
            original_name: temp[0]?.original_name || "",
            size: temp[0]?.size || 0,
          };
        }
      } else {
        return {
          snackbar: {
            type: "error",
            open: true,
            message:
              "Thao tác thất bại, vui lòng liên hệ kỹ thuật đễ đc hỗ trợ",
          },
        };
      }

      return { ...res, listTypeIdLicese };
    },

    submitAdditionalRequest: () => {
      const { listTypeIdLicese } = state;

      const listElementAdditionalRequets = listTypeIdLicese.filter(
        (i) => i.additionalRequest
      );

      const check = listElementAdditionalRequets.some((item) => {
        const checkItem = (item?.extra_info?.documents || []).some(
          (i) => i.isNewImg
        );
        return !checkItem;
      });

      if (check) {
        return {
          snackbar: {
            open: true,
            type: "error",
            message: "Vui lòng cung cấp đủ thông tin cần bổ sung.",
          },
        };
      }

      dispatch("onLoading");

      const params = {};

      return { loading: false };
    },

    // approval history tabs

    inittalHistory: () => {
      const { detail } = state;

      const listApprovalHistory = (detail.elements || []).filter((item) => {
        return (
          item.type_id == 521 &&
          [2, 3, 5, 7, 8, 9, 10].includes(item.status_id) &&
          !!(get(item, "extra_info.documents[0].path") || null)
        );
      });

      return { listApprovalHistory };
    },

    //

    closeSnackbar: () => ({ snackbar: { open: false } }),

    onLoading: () => ({ loading: true }),

    deleteS3Url: () => {
      const { url } = action;

      if (Array.isArray(url)) {
        const arrayApi = [];
        url.forEach((i) => {
          arrayApi.push(
            todoRemovedLinkS3({
              action_code: "document_delete",
              extra_info: { url: i },
            })
          );
        });

        Promise.all(arrayApi);
      }

      todoRemovedLinkS3({
        action_code: "document_delete",
        extra_info: { url },
      });
    },

    //
  };

  return isString(action.type) && cases[action.type];
}
