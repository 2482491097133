import { Pagination } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { formatMoney } from "../../utils/money";
import {
  BirthCakeIcon,
  CalendarIcon,
  CodeIcon,
  GrayEyeIcon,
  MerchantIcon,
  MiniMoneyIcon,
  OrangeEyeIcon,
  StatusIcon,
  UserIcon,
} from "../icon/icon";

function ActionColumn({ record, text }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (+user.role_id === 2 || +user.role_id === 1) return setDisabled(false);

    if (user?.role_management_claim?.tomato) {
      if (+record?.userIdReview === +user?.id) {
        return setDisabled(false);
      }

      if (+record?.userIdAssign === +user?.id) {
        return setDisabled(false);
      }

      if (
        user?.role_management_claim?.tomato[
          "quyen-quan-ly-chung.xem-chi-tiet-ho-so"
        ]
      )
        return setDisabled(false);
    }
  }, []);

  return disabled ? (
    <div style={{ textAlign: "center" }}>
      <GrayEyeIcon />
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <a href={`/admin/business-report/detail/${record.customerid}/tomato`}>
        <OrangeEyeIcon />
      </a>
    </div>
  );
}

const column = [
  {
    key: "stt",
    title: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
          textAlign: "center",
          padding: "0 8px",
        }}
      >
        <span
          style={{
            color: "#2A3790",
            fontWeight: 500,
            fontSize: 14,
            verticalAlign: "top",
          }}
        >
          STT
        </span>
      </div>
    ),
    minWidth: "35px",
    render: (text, record, index) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 14,
          }}
        >
          {index + 1}
        </div>
      );
    },
  },
  {
    key: "customerid",
    title: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
          textAlign: "center",
          padding: "0 8px",
        }}
      >
        <span
          style={{
            color: "#2A3790",
            fontWeight: 500,
            fontSize: 14,
            verticalAlign: "top",
          }}
        >
          Mã khách hàng
        </span>
      </div>
    ),
    minWidth: "50px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 14,
          }}
        >
          {text}
        </div>
      );
    },
  },
  {
    key: "insuredname",
    title: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
          textAlign: "center",
          padding: "0 8px",
        }}
      >
        <UserIcon style={{ marginRight: 5 }} />
        <span
          style={{
            color: "#2A3790",
            fontWeight: 500,
            fontSize: 14,
            verticalAlign: "top",
          }}
        >
          {" "}
          Họ và tên
        </span>
      </div>
    ),
    minWidth: "200px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 14,
          }}
        >
          {text}
        </div>
      );
    },
  },
  {
    key: "phonenumber",
    title: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
          textAlign: "center",
          padding: "0 8px",
        }}
      >
        <CodeIcon style={{ marginRight: 5 }} />
        <span
          style={{
            color: "#2A3790",
            fontWeight: 500,
            fontSize: 14,
            verticalAlign: "top",
          }}
        >
          {" "}
          Số điện thoại
        </span>
      </div>
    ),
    minWidth: "140px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 14,
          }}
        >
          {text || "N/A"}
        </div>
      );
    },
  },
  {
    key: "dob",
    title: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
          textAlign: "center",
          padding: "0 8px",
        }}
      >
        <BirthCakeIcon style={{ marginRight: 5 }} />
        <span
          style={{
            color: "#2A3790",
            fontWeight: 500,
            fontSize: 14,
            verticalAlign: "top",
          }}
        >
          {" "}
          Ngày sinh
        </span>
      </div>
    ),
    minWidth: "140px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 14,
            textAlign: "center",
          }}
        >
          {text || "N/A"}
        </div>
      );
    },
  },
  {
    key: "merchant_title",
    title: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
          textAlign: "center",
          padding: "0 8px",
        }}
      >
        <MerchantIcon style={{ marginRight: 5 }} />
        <span
          style={{
            color: "#2A3790",
            fontWeight: 500,
            fontSize: 14,
            verticalAlign: "top",
          }}
        >
          Kênh bán
        </span>
      </div>
    ),
    minWidth: "140px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 14,
          }}
        >
          {record.merchant_title}
        </div>
      );
    },
  },
  {
    key: "total_fees",
    title: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
          textAlign: "center",
          padding: "0 8px",
        }}
      >
        <MiniMoneyIcon style={{ marginRight: 5 }} />
        <span
          style={{
            color: "#2A3790",
            fontWeight: 500,
            fontSize: 14,
            verticalAlign: "top",
          }}
        >
          {" "}
          Giá trị đơn hàng
        </span>
      </div>
    ),
    minWidth: "180px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 14,
          }}
        >
          {formatMoney(text)}vnđ
        </div>
      );
    },
  },
  {
    key: "total_product_amount",
    title: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
          textAlign: "center",
          padding: "0 8px",
        }}
      >
        <MiniMoneyIcon style={{ marginRight: 5 }} />
        <span
          style={{
            color: "#2A3790",
            fontWeight: 500,
            fontSize: 14,
            verticalAlign: "top",
          }}
        >
          {" "}
          Quyến lợi
        </span>
      </div>
    ),
    minWidth: "180px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 14,
          }}
        >
          {formatMoney(text)}vnđ
        </div>
      );
    },
  },
  {
    key: "id",
    title: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
          textAlign: "center",
          padding: "0 8px",
        }}
      >
        <StatusIcon style={{ marginRight: 5 }} />
        <span
          style={{
            color: "#2A3790",
            fontWeight: 500,
            fontSize: 14,
            verticalAlign: "top",
          }}
        >
          Chi tiết hồ sơ
        </span>
      </div>
    ),
    minWidth: "150px",
    render: (text, record) => {
      return <ActionColumn text={text} record={record} />;
    },
  },
];

function Row(props) {
  return (
    <>
      {props.data.map((item, index) => {
        return (
          <tr key={index} style={{ background: "white", marginBottom: 10 }}>
            {column.map((col, i) => {
              return (
                <td key={i} style={{ padding: 10 }}>
                  {col.render
                    ? col.render(item[col["key"]], item, index)
                    : item[col["key"]]}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
}

export default function BusinessReportTable({
  pageSize = 10,
  currentPage = 1,
  setCurrentPage = () => {},
  total,
  data = [],
}) {
  return (
    <>
      <div
        style={{
          height: 1,
          background: "#BDBDBD",
        }}
      />
      <div style={{ overflowX: "auto", minHeight: 450, maxHeight: 450 }}>
        <table className="responsive" style={{ width: "100%" }}>
          <thead>
            <tr>
              {column.map((item, index) => (
                <th key={index} style={{ minWidth: item.minWidth }}>
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <Row data={data} />
          </tbody>
        </table>
      </div>

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Pagination
          size="small"
          current={currentPage}
          total={total}
          pageSize={pageSize}
          onChange={(page, pageSize) => {
            setCurrentPage(page);
          }}
          showSizeChanger={false}
        />
      </div>
    </>
  );
}
