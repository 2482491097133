import { makeStyles } from "@mui/styles";

export const styledChooseInsu = makeStyles(() => ({
  container: {
    background: "#F8F8F8",
    padding: "16px",
    "& .title-head": {
      color: "#686868",
    },
  },

  checkAll: {
    margin: "8px 0 !important",

    "& .MuiFormControlLabel-label": {
      marginLeft: "8px",
      color: "#686868",
    },
  },

  packageInsurance: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  },

  //
}));
