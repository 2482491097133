import { makeStyles } from "@mui/styles";

const styleApprovalHistory = makeStyles((theme) => ({
  containerDiv: {
    marginTop: "12px",
    background: "white",
    padding: "12px",
    position: "relative",

    "& .header-title": {
      fontWeight: "600",
      fontSize: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "12px",
    },

    "& .not-yet": {
      width: "100%",
      textAlign: "center",
      marginTop: "24px",
      color: "red",
      fontStyle: "italic",
    },
  },

  border: {
    width: "100%",
    border: "3px solid #fafafa",
  },

  div: { paddingBottom: "24px" },

  table: {
    minWidth: "900px",
    marginBottom: "12px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      padding: "10px 5px 10px 12px",
      fontWeight: "600",
    },

    "& th.code": {
      width: "250px",
    },

    "& th.amount": {
      width: "150px",
    },

    "& th.status": {
      width: "125px",
    },

    "& th.button_option": {
      width: "50px",
    },

    "& td": {
      color: "#4B4B4B",
      padding: "5px 5px 5px 12px",
      lineHeight: "26px",
    },

    // "& td.sticky": {
    //   position: "sticky",
    //   right: 0,
    //   zIndex: 1,
    // },

    "& td.code": {
      cursor: "pointer",
    },

    "& td.choose": {
      background: "#E3F3FF",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#D6DEEB !important",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid white",
    background: "#F8F8F8",
    "&:hover": { backgroundColor: "#E3F3FF" },
  },

  buttons_popover: {
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    "& .button-top": {
      textTransform: "none",
      color: "black",
      borderBottom: "1px solid #DCDCDC",
      borderRadius: 0,
      justifyContent: "flex-start",
    },
    "& .button-bottom": {
      textTransform: "none",
      color: "black",
      borderTop: "1px solid #DCDCDC",
      borderRadius: 0,
      justifyContent: "flex-start",
    },
  },
  //
}));

export default styleApprovalHistory;
