import React from "react";
import _, { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { Box, InputAdornment } from "@mui/material";

import LoadingMui from "../../../ui/LoadingMui";
import useReducer from "../../../library/hooks/useReducer";
import CommonTable from "../../../library/layout/commonTable";
import Snackbars from "../../../ui/snackbar";
import columns from "./components/columnRecords";
import styleDowDocx from "./style/records-css";
import TextField from "./components/TextField";
import searchIcon from "./../../../assets/icons/search-icon.svg";
import dowDocXReducer from "./dowdocxReducer";

const initialState = {
  rows: [],
  loading: false,
  snackbar: {},
  pageTable: {},
  keyword: "",
};

export default function DowDocX({ role_type = null }) {
  const classes = styleDowDocx();
  const history = useHistory();

  const [state, dispatch] = useReducer({
    initialState,
    name: "DowDocXReducer",
    reducer: dowDocXReducer,
    history,
  });

  let flag = false;

  const { pageTable, loading, rows, snackbar } = state;

  return (
    <div className={classes.container}>
      <div className="title">Tải hồ sơ</div>
      <Box className={classes.box_button}>
        <span>Mã hồ sơ bồi thường: </span>
        <TextField
          placeholder="Nhập Mã hồ sơ bồi thường..."
          className="text-field-search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={searchIcon} alt="search_icon" />
              </InputAdornment>
            ),
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              dispatch({
                type: "search",
                keyword: e.target.value,
              });
              flag = true;
            }
          }}
          onChange={debounce((e) => {
            if (!flag) {
              dispatch({
                type: "search",
                keyword: e.target.value,
              });
            }
            flag = false;
          }, 1800)}
        />
      </Box>

      {rows.length === 0 ? (
        <div className={classes.noData}>
          Hiện không có không có hồ sơ bồi thường nào{" "}
        </div>
      ) : (
        <CommonTable
          rows={rows}
          columns={columns}
          classes={classes}
          data={{}}
          page={{
            ...pageTable,
            onChange: (event, page, rowsPerPage) => {
              dispatch({
                type: "onchangePage",
                page,
                rowsPerPage,
              });
            },
          }}
          event={(type, data) =>
            (ele, ...rest) => {
              dispatch({ type, data, ele, rest });
            }}
        />
      )}
      <LoadingMui loading={loading} />
      <Snackbars
        open={snackbar.open}
        type={snackbar.type}
        onClose={() => dispatch("closeSnackbar")}
        message={snackbar.message}
      />
    </div>
  );
}
