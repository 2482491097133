import api from "../configs/api";

const getProducts = (data) =>
  api
    .apiForm("POST", "/api/v2/product-parent/list", data)
    .then((res) => res)
    .catch((error) => error);

const getBenefitCode = (data) =>
  api
    .apiForm("POST", "/api/v2/benefit/list", data)
    .then((res) => res)
    .catch((error) => error);

const createProduct = (data) =>
  api
    .apiForm("POST", "/api/v2/product-parent", data)
    .then((res) => res)
    .catch((error) => error);

const getDetail = (id) =>
  api
    .apiForm("GET", `/api/v2/product-parent/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

const updateProduct = (data) =>
  api
    .apiForm("PUT", "/api/v2/product-parent", data)
    .then((res) => res)
    .catch((error) => error);

const uploadImage = (data) => {
  return api.apiForm("POST", "/api/v2/document/upload/cdn", data);
};

export {
  getProducts,
  getBenefitCode,
  createProduct,
  uploadImage,
  getDetail,
  updateProduct,
};
