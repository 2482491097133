import { makeStyles } from "@mui/styles";

const styleClaimLicense = makeStyles((theme) => ({
  container: {
    marginTop: "12px",
    background: "white",
    padding: "12px",
    position: "relative",

    "& .header-title": {
      fontWeight: "600",
      fontSize: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "12px",

      "& .icon-button": {
        padding: "3px",
      },
      "& .icon-svg": {
        width: "22px",
        height: "22px",
      },
    },
  },

  radio: {
    padding: "6px",
  },

  textField: {
    "& .MuiInputBase-input": {
      padding: "6px",
      borderRadius: "8px",
      fontSize: "14px",
    },

    "& .MuiInputBase-multiline": {
      padding: "1px 0 !important",
    },

    "& fieldset": {
      border: "none",
      boxShadow: "inset 1px 1px 1px rgb(0 0 0 / 25%)",
      borderRadius: "8px",
    },
  },

  div: { paddingBottom: "24px" },

  table: {
    minWidth: "800px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      color: "#0B3C8A",
      padding: "10px 5px 10px 5px",
    },

    "& th.code": {
      width: "100px",
    },

    "& th.diagnosis-name": {
      maxWidth: "280px",
    },

    // "& th.accept": {
    //   textAlign: "center",
    //   width: "85px",
    // },

    "& th.refuse": {
      textAlign: "center",
      width: "60px",
    },

    "& th.fees": {
      textAlign: "center",
      width: "120px",
      color: "#3961A0",
    },

    "& th.approved": {
      textAlign: "center",
      width: "130px",
    },

    "& th.reason": {
      textAlign: "center",
    },

    "& th.reason-expense-cell": {
      textAlign: "center",
      width: "150px",
    },

    "& td": {
      color: "#4B4B4B",
      padding: "5px 5px 5px 5px",
      lineHeight: "26px",
    },

    "& td.name": {
      paddingLeft: "12px",
    },

    "& td.diagnosis-name": {
      maxWidth: "280px",
      paddingLeft: "12px",
    },

    "& td.accept": {
      textAlign: "center",
    },

    "& td.refuse": {
      textAlign: "center",
    },

    "& td.fees": {
      textAlign: "center",
      color: "#3961A0",
    },

    "& td.approve-fees": {
      textAlign: "center",
    },

    "& .textMoney": {
      "& .MuiInputBase-input.MuiOutlinedInput-input": {
        textAlign: "center",
      },
    },

    "& td.approved": {
      textAlign: "center",
    },

    "& td.reason": {
      textAlign: "center",
    },

    "& td.cell-colap": {
      fontWeight: "600",
    },

    "& .error": { color: "red" },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#E3F3FF !important",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid white",
    background: "#F8F8F8 !important",
  },

  rowColap: {
    background: "#DCDCDC !important",
  },

  totalFees: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "42px",

    "& .conten": {
      width: "65%",
      background: "#ECECEC",
      color: "#3961A0",
      paddingLeft: "10px",
    },

    "& .fees": {
      width: "35%",
      background: "#3961A0",
      color: "white",
      textAlign: "end",
      paddingRight: "10px",
    },
  },

  border: {
    left: 0,
    width: "100%",
    border: "4px solid #fafafa",
    position: "absolute",
  },

  containerFees: {
    paddingTop: "24px",
  },

  boxNote: {
    paddingTop: "12px",
    width: "100%",

    "& .note": {
      fontSize: "14px",
      paddingLeft: "6px",
      fontWeight: "600",
    },
  },

  multilineTextField: {
    "& .MuiInputBase-multiline": {
      padding: "0px !important",

      "& textarea": {
        background: "#F8F8F8",
        boxShadow: "inset 0px 1px 1px rgba(0, 0, 0, 0.25)",
      },

      "& fieldset": {
        border: "none",
      },
    },
  },

  textFieldRation: {
    borderRadius: "8px",

    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      background: "white",
      borderRadius: "8px",
    },

    "& .MuiTextField-root.MuiInputBase-input": {
      padding: "6px 13px",
      borderRadius: "8px",
      fontSize: "14px",
    },

    "& fieldset": {
      border: "none",
      borderRadius: "8px",
      boxShadow: "inset 0px 1px 1px rgba(0, 0, 0, 0.25)",
    },
  },

  boxFees: {
    paddingLeft: "12px",
    fontSize: "14px",

    "& .total-fees": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      color: "white",
      fontWeight: "600",
      background: "#FFA21F",
      padding: "4px 8px",
      marginBottom: "12px",
    },

    "& .ratio-cost": {
      fontSize: "14px",
      color: "#3961A0",
      padding: "8px",
      fontWeight: "600",
      background: "#E3F3FF",

      "& .box": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "&:not(:last-child)": {
          marginBottom: "8px",
        },
      },

      "& .lable": {
        width: "50%",
      },

      "& .textField-fees": {
        width: "50%",
      },
    },
  },

  buttons: {
    width: "100%",
    padding: "12px 0",
    display: "flex",
    justifyContent: "end",

    "& .buttons": {
      textTransform: "none",
      background: "#3961A0",
      color: "white",
      borderRadius: "12px",
      padding: "10px 32px",
      fontWeight: "600",
    },

    "& .disabled": {
      background: "#A0A0A0",
    },
  },

  boxShowImg: {
    height: "85vh",
    position: "fixed",
    top: "70px",
    right: 6,
    zIndex: 4,
    MozTransition: "all 0.7s ease",
    WebkitTransition: "all 0.7s ease",
    OTransition: "all 0.7s ease",
    transition: "all 0.7s ease",
    // overflow: "auto",
  },

  //
}));

export default styleClaimLicense;
