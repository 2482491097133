import React from "react";
import { IconButton } from "@mui/material";

import editContained from "../../../../../../assets/health/edit-contained.svg";
import chevronDown from "../../../../../../assets/health/chevron-down.svg";

export default function Title(props) {
  const { title, addIcon, expan, onEdit, onExpan, unEdit, onClickAdd } = props;

  return (
    <div className="header-title">
      <span>
        {title}
        {addIcon && (
          <span>
            <IconButton onClick={onClickAdd} title="Danh mục quyền lợi">
              <img src={addIcon} />{" "}
            </IconButton>
          </span>
        )}
      </span>
      <div>
        {!unEdit && (
          <IconButton onClick={onEdit}>
            <img src={editContained} />
          </IconButton>
        )}
        <IconButton
          onClick={onExpan}
          style={{
            transform: expan ? "rotate(-180deg)" : "",
            transition: "all 0.5s",
          }}
        >
          <img src={chevronDown} />
        </IconButton>
      </div>
    </div>
  );
}
