import { makeStyles } from "@mui/styles";

export const detailProduct = makeStyles(() => ({
  container: {
    width: "100%",
    padding: "0 12px",
    marginTop: "45px",
  },

  box_logo: {
    "& .name-insurance": {
      color: "#4B4B4B",
      fontSize: "18px",
      fontWeight: "700",
    },
  },

  header_box: {
    width: "100%",
    background: "white",
    padding: "12px",
    border: "1px solid #ECECEC",
    marginTop: "12px",
  },

  box_grid_item: {
    "& .justyfy-between": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "",
      width: "100%",
      margin: "8px 0",
    },

    "& .label": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#4B4B4B",
    },

    "& .value": {
      fontSize: "14px",
      fontWeight: "700",
      color: "#4B4B4B",
      textAlign: "right",
    },
    "& .blue": {
      color: "#3961A0",
      cursor: "pointer",
    },
  },

  container_product_packake: {
    width: "100%",
    background: "#dddddd6b",
    height: "100%",
    borderRight: "1px solid #D3D3D3",
  },

  title_product_package: {
    height: "55px",
    width: "100%",
    padding: "6px 12px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #C0C0C0",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "600",
    color: "#4B4B4B",

    "& .label-status": {
      padding: "6px 12px",
      fontSize: "12px",
      fontWeight: "400",
      borderRadius: "12px",
      background: "#E3F3FF",
      color: "#0B7CE4",
    },
  },

  box_label_product: {
    width: "100%",

    "& .label-product": {
      width: "100%",
      padding: "8px 12px",
      paddingLeft: "24px",
      color: "#4B4B4B",
    },

    "& .active": {
      color: "#3961A0",
      background: "white",
    },

    "& .label-product:hover": {
      color: "#3961A0",
      cursor: "pointer",
    },
  },

  container_info_product_packake: {
    width: "100%",
    background: "white",
    padding: "0 12px",

    "& .no-padding": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  box_info_product: {
    marginTop: "12px",

    "& .title": {
      marginTop: "12px",
      marginBottom: "8px",
      color: "#4B4B4B",
      fontSize: "14px",
      fontWeight: "700",
    },

    "& .item-grid-package-insurance": {
      fontSize: "14px",
    },
  },

  containeer_time_remaining: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    marginBottom: "12px",
    background: "#F8F8F8",
    flexWrap: "wrap",

    "& .div-column": {
      display: "flex",
      flexDirection: "column",
      background: "#F8F8F8",
      justifyContent: "center",
      marginRight: "12px",
      width: "10%",
      maxWidth: "200px",
    },

    "& .head-table": {
      textAlign: "center",
      color: "#A0A0A0",
      padding: "6px",
    },

    "& .value-table": {
      textAlign: "center",
      color: "#3961A0",
      fontSize: "13px",
      fontWeight: "600",
      padding: "6px",
      paddingBottom: "24px",
    },
  },

  box_heder: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 0",
    alignItems: "center",

    "& .title-creat-product": {
      fontSize: "20px",
      fontWeight: "700",
    },

    "& .buttons-delete": {
      background: "white",
      color: "#4B4B4B",
      fontWeight: "500",
      border: "1px solid #DCDCDC",
      marginRight: "12px",
    },

    "& .buttons-save": {
      background: "#0B7CE4",
      color: "white",
      fontWeight: "500",
    },
  },

  box_status: {
    background: "white",
    padding: "12px",
    border: "1px solid #ECECEC",
    margin: "12px 0",

    "& .title": {
      color: "#4B4B4B",
      fontWeight: "700",
      fontSize: "17px",
      marginBottom: "18px",
    },

    "& .div-switch": {
      color: "#4B4B4B",
      fontWeight: "500",
      marginBottom: "8px",
      fontSize: "14px",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },

    "& .date-label": {
      color: "#4B4B4B",
      fontWeight: "500",
      marginBottom: "6px",
      fontSize: "14px",
    },

    "& .date-value": {
      color: "#3961A0",
      fontWeight: "600",
      marginBottom: "6px",
      fontSize: "14px",
    },
  },

  no_margin_top: {
    marginTop: 0,
  },

  box_update_product_pakage_infor: {
    padding: "12px",
    background: "white",
    border: "1px solid #ECECEC",

    "& .title-label": {
      fontSize: "18px",
      color: "#4B4B4B",
      fontWeight: "700",
      marginBottom: "16px",
    },

    "& .box-content-infor": {
      display: "flex",
      flexWrap: "wrap",
    },

    "& .box-text-field": {
      marginRight: "12px",
      width: "45%",
      minWidth: "270px",
      maxWidth: "450px",
    },

    "& .label-text-field": {
      fontSize: "14px",
      color: "#4B4B4B",
      fontWeight: "500",
      margin: "8px 0",
    },
  },

  box_update_product_wartting: {
    padding: "12px",
    background: "white",
    border: "1px solid #ECECEC",
    marginTop: "12px",

    "& .title-label": {
      fontSize: "18px",
      color: "#4B4B4B",
      fontWeight: "700",
      marginBottom: "16px",
    },

    "& .box-content-infor": {
      display: "flex",
      flexWrap: "wrap",
    },

    "& .box-text-field": {
      marginRight: "12px",
      width: "21%",
      minWidth: "180px",
      maxWidth: "300px",
    },

    "& .label-text-field": {
      fontSize: "14px",
      color: "#4B4B4B",
      fontWeight: "500",
      margin: "8px 0",
    },
  },

  box_update_table: {
    padding: "12px",
    background: "white",
    border: "1px solid #ECECEC",
    marginTop: "12px",

    "& .title": {
      fontSize: "18px",
      color: "#4B4B4B",
      fontWeight: "700",
      marginBottom: "16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    "& .buttons": {
      background: "white",
      border: "1px solid #DCDCDC",
      color: "#3961A0",
      fontWeight: "500",
    },

    "& .add-button": {
      background: "#E3F3FF",
      color: "#0B7CE4",
    },
  },

  box_empty: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#B6B6B6",
    fontSize: "13px",
  },

  //
}));
