import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

export default function LoadingTable({ loading }) {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        position: "absolute",
        alignItems: "flex-start",
      }}
      open={loading}
    >
      <CircularProgress color="inherit" style={{ marginTop: "20%" }} />
    </Backdrop>
  );
}
