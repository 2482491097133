import React from "react";
import { Stepper, Step, StepLabel, styled } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { makeStyles } from "@mui/styles";

const steps = ["Báo cáo sự cố", "Cung cấp hình ảnh", "Người thụ hưởng"];
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#E16C2B",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#E16C2B",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#C4CFC9",
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

export default function HeadSteper(props) {
  const classes = styleds();
  const { activeStep = 0 } = props;

  return (
    <div className={classes.div}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<QontoConnector />}
        className={classes.stepper}
      >
        {steps.map((label, index) => (
          <Step key={index} className={classes.step}>
            <StepLabel
              StepIconComponent={() => (
                <div
                  className={
                    activeStep >= index
                      ? "divIcon icon-completed"
                      : "divIcon icon-wait "
                  }
                >
                  {index + 1}
                </div>
              )}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

const styleds = makeStyles(() => ({
  div: {
    padding: "12px 0",
    pointerEvents: "none",
    background: "white",
    marginBottom: "8px",
  },

  stepper: {
    padding: "0 !important",

    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#56b3e5",
    },
  },

  step: {
    "& .divIcon": {
      width: "22px",
      border: "1px solid",
      borderRadius: "50%",
      lineHeight: "20px",
      textAlign: "center",
    },

    "& .icon-completed": {
      borderColor: "#E16C2B",
      color: "white",
      background: "#E16C2B",
    },

    "& .icon-wait": {
      borderColor: "#C4CFC9",
      color: "#646D69",
      background: "#C4CFC9",
    },

    "& .MuiStepLabel-labelContainer": {
      marginTop: 0,
      "& span": {
        fontSize: "12px",
        marginTop: "12px",
      },
    },
  },
}));
