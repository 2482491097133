export function formatMoney(money) {
  if (!money)
    return 0;
  return money?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}

export function removeFormatMoney(string) {
  return string?.toString().replace(/[^0-9\.-]+/g, '');
}

export function removeFormatFloat(string) {
  return string?.toString().replace(/[^0-9\-]+/g, '');
}