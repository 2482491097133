import React from 'react';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

const ItemDropDown = ({
  title = '',
  isShowData = false,
  children,
  onPress = () => {}
}) => {
  return (
    <div style={styleDropItem} className='ItemDropDown'>
      <div onClick={onPress} style={styleDropDown}>
        <div style={styleTitleDrop}>
          {title}
        </div>
        {!isShowData ? <DownOutlined style={styleDropIcon} /> :
          <RightOutlined style={styleDropIcon}/>
        }
      </div>
      {children}
    </div>
  );
};


const styleDropDown = {
  display: 'flex',
  borderRadius: 11,
  height: 45,
  backgroundColor: '#0B3C8A',
  alignItems: 'center',
  padding: 12
}

const styleDropItem = {
  background: 'white',
  color: '#000',
  borderRadius: 11
}

const styleDropIcon = {
  marginRight: 5,
  color: 'white!important',
  fill: '#FFF!important'
}

const styleTitleDrop = {
  flex: 1,
  fontSize: "17px",
  color: 'white',
  fontWeight: '600'
}

export default ItemDropDown
