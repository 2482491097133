import * as React from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  Button,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/Close";
import cccd1 from "../../../../../assets/health/cccd-instruction-1.svg";
import cccd2 from "../../../../../assets/health/cccd-instruction-2.svg";
import cccd3 from "../../../../../assets/health/cccd-instruction-3.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogInstruction(props) {
  const { open, onClose } = props;
  const classes = makeStyle();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialog}
    >
      <DialogContent style={{ padding: "20px 0" }}>
        <div className={classes.head}>
          Hướng dẫn chụp giấy tờ tuỳ thân{" "}
          <IconButton
            style={{ position: "fixed", right: "16px" }}
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <div className={classes.box_content}>
          <div className="inline">
            <img src={cccd1} />
            <div style={{ marginLeft: "12px" }}>
              Giấy CMND/CCCD/Hộ chiếu bản gốc và còn hiệu lực
            </div>
          </div>
          <div className="inline">
            <img src={cccd2} />
            <div style={{ marginLeft: "12px" }}>
              Giữ giấy tờ nằm thẳng trong khung hình
            </div>
          </div>
          <div className="inline">
            <img src={cccd3} />
            <div style={{ marginLeft: "12px" }}>
              Tránh chụp tối, mờ, loé sáng
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      width: "100%",
      maxWidth: "550px",
      margin: 0,
      position: "absolute",
      bottom: 0,
    },
  },

  head: {
    width: "100%",
    fontSize: "16px",
    color: "#3961A0",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "12px",
    borderBottom: "1px solid #D6DEEB",
  },

  box_content: {
    padding: "24px 16px",

    "& .inline": {
      display: "flex",
      alignItems: "center",
      marginBottom: "24px",
    },
  },
}));
