import React, { useEffect } from "react";
import moment from "moment";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { createRequest } from "../styles/styleCreateRequest";
import { Box, Button, IconButton, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TextField } from "../overrideMui";
import { useLocation, useHistory } from "react-router-dom";

import { ReactComponent as Icon_upload } from "../../../../assets/icons/Icon_upload.svg";
import useReducer from "../../../../library/hooks/useReducer";
import createGCNReducer from "../reducers/createGCNReducer";
import { initialStateCreateRequestBLVP } from "../util";
import LoadingMui from "../../../../ui/LoadingMui";
import Snackbars from "../../../../ui/snackbar";

export default function DetailCeate() {
  const classes = createRequest();
  const localtion = useLocation();
  const history = useHistory();
  const data = { ...(localtion?.state?.data || {}) };

  const [state, dispatch] = useReducer({
    initialState: initialStateCreateRequestBLVP,
    reducer: createGCNReducer,
    name: "createGCNReducer",
    data,
    history,
  });

  const {
    loading,
    snackbar,
    insuranceTypes,
    payloadDetail,
    typeOfIndimnities,
    riskTypes,
    errors,
  } = state;

  const inforInsurance = [
    { label: "Công ty bảo hiểm", value: data?.provider_title || "--" },
    { label: "Đơn vị xử lý", value: data?.organization_title || "--" },
    { label: "Bảo hiểm tham gia", value: data?.productTitle || "--" },
    { label: "Mã sản phẩm", value: data?.productPackageId || "--" },
    {
      label: "Ngày hết hạn",
      value: data?.endDate ? moment(data.endDate).format("DD/MM/YYYY") : "--",
    },
  ];

  const inforBeneficiary = [
    { label: "Họ và tên", value: data?.insuredInfo?.insuredName || "--" },
    { label: "CMND/ CCCD", value: data?.insuredInfo?.insuredPrivateId || "--" },
    { label: "Số thẻ bảo hiểm", value: data?.certNum || "--" },
  ];

  const documents = [
    {
      label: "Tóm tắt bệnh án, báo cáo y tế",
      typeId: 531,
      typeCode: "summary_report_health",
      elements:
        payloadDetail?.elements?.find(
          (i) => i?.typeCode === "summary_report_health"
        )?.extraInfo?.documents || [],
    },
    {
      label: "Chỉ định xét nghiệm/ chụp XQ...và kết quả",
      typeId: 504,
      typeCode: "test_xq_and_result",
      elements:
        payloadDetail?.elements?.find(
          (i) => i?.typeCode === "test_xq_and_result"
        )?.extraInfo?.documents || [],
    },
    {
      label: "Bảng kê chi phí điều trị",
      typeId: 506,
      typeCode: "treatment_cost_checkout",
      elements:
        payloadDetail?.elements?.find(
          (i) => i?.typeCode === "treatment_cost_checkout"
        )?.extraInfo?.documents || [],
    },
    {
      label: "Toa thuốc",
      typeId: 507,
      typeCode: "prescription",
      elements:
        payloadDetail?.elements?.find((i) => i?.typeCode === "prescription")
          ?.extraInfo?.documents || [],
    },
    {
      label: "Phiếu thu chi phí khám",
      typeId: 525,
      typeCode: "examination_receipt",
      elements:
        payloadDetail?.elements?.find(
          (i) => i?.typeCode === "examination_receipt"
        )?.extraInfo?.documents || [],
    },
    {
      label: "Hoá đơn chi phí khám",
      typeId: 508,
      typeCode: "examination_cost_bill",
      elements:
        payloadDetail?.elements?.find(
          (i) => i?.typeCode === "examination_cost_bill"
        )?.extraInfo?.documents || [],
    },
    {
      label: "Phiếu thu chi phí thuốc",
      typeId: 524,
      typeCode: "prescription_receipt",
      elements:
        payloadDetail?.elements?.find(
          (i) => i?.typeCode === "prescription_receipt"
        )?.extraInfo?.documents || [],
    },
    {
      label: "Hoá đơn chi phí thuốc",
      typeId: 509,
      typeCode: "medicine_bill",
      elements:
        payloadDetail?.elements?.find((i) => i?.typeCode === "medicine_bill")
          ?.extraInfo?.documents || [],
    },
  ];

  useEffect(() => {
    dispatch("initail");
  }, []);

  return (
    <div className={classes.container}>
      <Box className={classes.box_heder_detail_create}>
        <div className="title">Tạo yêu cầu</div>
        <div>
          <Button
            className="buttons-delete"
            onClick={() => dispatch({ type: "initail", name: "resetState" })}
          >
            Xoá hết
          </Button>
          <Button
            className="buttons-send"
            onClick={() => dispatch("submitSentRequest")}
          >
            Gửi đi
          </Button>
        </div>
      </Box>
      <Grid container spacing={1.5}>
        <Grid xs={12} sm={5} md={3.5}>
          <div className={classes.box_info_insurance}>
            <div className="title">Thông tin bảo hiểm</div>
            {inforInsurance.map((item, index) => (
              <div
                key={`item_infoe_insurance_${index}`}
                style={{ marginBottom: "10px" }}
              >
                <div className="label">{item.label}</div>
                <div className="value">{item.value}</div>
              </div>
            ))}
          </div>
          <div className={classes.box_info_insurance}>
            <div className="title">Thông tin người được bảo hiểm</div>
            {inforBeneficiary.map((item, index) => (
              <div
                key={`item_infoe_insurance_${index}`}
                style={{ marginBottom: "8px" }}
              >
                <div className="label">{item.label}</div>
                <div className="value">{item.value}</div>
              </div>
            ))}
          </div>
        </Grid>
        <Grid xs={12} sm={7} md={8.5}>
          <div className={classes.box_type_compensation}>
            <div className="title">Loại bồi thường</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div className="box_text">
                <div className="label">Loại bảo hiểm</div>
                <TextField
                  select
                  fullWidth
                  value={payloadDetail?.extraInfo?.typeOfInsurance || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "onChangeTypeOfIndimnity",
                      name: "typeOfInsurance",
                      value: e.target.value,
                    })
                  }
                >
                  {insuranceTypes.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="box_text">
                <div className="label">Loại bồi thường</div>
                <TextField
                  fullWidth
                  select
                  value={payloadDetail?.extraInfo?.typeOfIndimnity || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "onChangeTypeOfIndimnity",
                      name: "typeOfIndimnity",
                      value: e.target.value,
                    })
                  }
                >
                  {typeOfIndimnities?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {payloadDetail?.extraInfo?.typeOfInsurance === 1 && (
                <div className="box_text">
                  <div className="label">Loại bồi thường</div>
                  <TextField
                    select
                    fullWidth
                    value={payloadDetail?.extraInfo?.typeOfRisk || ""}
                    onChange={(e) =>
                      dispatch({
                        type: "onChangeTypeOfIndimnity",
                        name: "typeOfRisk",
                        value: e.target.value,
                      })
                    }
                  >
                    {riskTypes?.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
              <div className="box_text">
                <div className="label">Nhập số tiền yêu cầu</div>
                <TextField
                  fullWidth
                  placeholder="Nhập số tiền yêu cầu"
                  error={errors.includes("requestAmount")}
                  helperText={
                    errors.includes("requestAmount")
                      ? "Vui lòng nhập số tiền yêu cầu"
                      : false
                  }
                  onChange={(e) => {
                    if (
                      !"0123456789".includes(e?.nativeEvent?.data) &&
                      e?.nativeEvent?.data !== null
                    )
                      return;
                    dispatch({
                      type: "onChangeTypeOfIndimnity",
                      name: "requestAmount",
                      value: e.target.value?.replace(/\./g, ""),
                    });
                  }}
                  value={`${payloadDetail.requestAmount || ""}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "."
                  )}
                />
              </div>
            </div>
          </div>
          <div className={classes.box_document}>
            <div className="title">Thông tin file chứng từ</div>
            {documents.map((item, index) => (
              <div key={`item_document_box_${index}`}>
                <div className="label_document">
                  <div>{item.label}</div>
                  <Button
                    className="button_file"
                    onClick={() => {
                      document
                        .getElementById(`input_${item.typeCode}`)
                        ?.click();
                    }}
                  >
                    <Icon_upload className="icon_upload" />
                    <input
                      type="file"
                      id={`input_${item.typeCode}`}
                      multiple
                      hidden
                      onChange={(e) => {
                        dispatch({ type: "upDataS3", e, item });
                      }}
                    />
                    Đính kèm file
                  </Button>
                </div>
                {item?.elements?.map((j, indexJ) => (
                  <div
                    className="attached-file"
                    key={`attached-file-line-${indexJ}-${index}`}
                  >
                    <span className="label-attached-file">
                      <AttachFileOutlinedIcon
                        sx={{
                          color: "#0B3C8A",
                          marginRight: "6px",
                          border: "1px solid #0B3C8A",
                          borderRadius: "6px",
                          padding: "1px",
                        }}
                      />
                      {j?.original_name || ""}{" "}
                    </span>
                    <div style={{ minWidth: "64px" }}>
                      <IconButton
                        sx={{ padding: "6px" }}
                        onClick={() =>
                          dispatch({
                            type: "deleteAttachedFiel_create",
                            documents: item,
                            element: j,
                            indexElement: indexJ,
                          })
                        }
                      >
                        <DeleteOutlineOutlinedIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        sx={{ padding: "6px" }}
                        onClick={() =>
                          dispatch({
                            type: "showAttachedFiel_create",
                            item: j,
                          })
                        }
                      >
                        <VisibilityIcon
                          fontSize="small"
                          sx={{ color: "#FFA21F" }}
                        />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
      <LoadingMui loading={loading} />
      <Snackbars
        open={snackbar?.open}
        type={snackbar.type}
        message={snackbar?.message}
        onClose={() => dispatch("closeSnackbar")}
      />
    </div>
  );
}
