import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import HeaderBar from "../../components/layout/HeaderBar";
import { useHistory } from "react-router-dom";
import { useStyles } from "../styles";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { convertCurrency } from "../utils";
import { getBeneficiaryList } from "../../../../../../apis/claimApis";

export default function BeneficiaryList({ state, dispatch }) {
  const classes = useStyles();
  const history = useHistory();

  useEffect(async () => {
    dispatch("onLoading");
    const response = await getBeneficiaryList({});
    if (response.data.statusCode === 200) {
      dispatch({ type: "getList", data: response.data?.result?.data || [] });
    }
  }, []);

  const { beneficiaryList } = state;
  return (
    <>
      <HeaderBar
        title={"Danh sách người được bảo hiểm"}
        background="#F2F2F2"
        onPress={() => history.goBack()}
      />
      <div className={classes.wrapper} style={{marginTop: 65}}>
        <Typography className={classes.title}>
          CHỌN NGƯỜI ĐƯỢC BẢO HIỂM
        </Typography>
        {(beneficiaryList || []).map((item) => (
          <Box
            key={item.customerid}
            className={classes.box}
            onClick={() => dispatch({ type: "nextPage", item })}
          >
            <Typography className="name">{item.buyer_name || ""}</Typography>
            <Grid container className={classes.rowGrid}>
              <Grid item xs={6} xl={6} sm={6} md={6} className="row">
                <LocalPhoneOutlinedIcon className="icon phone" />
                <Typography className="title">
                  {item?.phonenumber || ""}
                </Typography>
              </Grid>
              <Grid item xs={6} xl={6} sm={6} md={6} className="row right">
                <BadgeOutlinedIcon className="icon dob" />
                <Typography className="title">{item?.dob || ""}</Typography>
              </Grid>
            </Grid>
            <Typography className={classes.moneyTitle}>
              SỐ TIỀN ĐÓNG GÓP
            </Typography>
            <Typography className="price">
              {convertCurrency(`${item?.total_fees || 0}`)} vnđ
            </Typography>
          </Box>
        ))}
      </div>
    </>
  );
}
