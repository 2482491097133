import React from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import rightCorner from "../../../../../../assets/images/rightCorner.svg";

export default function TripBox(props) {
  const { isSelect, destination, onClick, name } = props;
  const classes = usemakeStyle();

  const converDDMMYYYY = (date) => {
    const data = moment(date).format("DD/MM/YYYY, HH:mm");
    return data.replace(/,/g, "");
  };

  return (
    <>
      {(destination || []).map((items, index) => (
        <div
          onClick={() => onClick({ items, index })}
          key={index}
          id={`el${index}`}
          className={`scroll-item ${
            isSelect === index ? classes.boxSelect : classes.box
          }`}
        >
          <div className="heads">
            {"Chuyến đi" +
              " " +
              `#${
                name === "history"
                  ? items?.extra_info?.codeDestination
                  : items?.codeDestination
              }`}
          </div>
          <div className="boxLine">
            <div className="divLine line1">
              <span>Ngày khởi tạo</span>
              <span>
                {converDDMMYYYY(
                  name === "history"
                    ? items?.extra_info?.createdDate
                    : items?.createdDate
                )}
              </span>
            </div>
            <div className="divLine line2">
              <span>Thông tin điểm giao</span>
              <span className="address" style={{ overflow: "hidden" }}>
                {name === "history"
                  ? items?.extra_info?.address
                  : items?.address}
              </span>
            </div>
            <div className="divLine line3">
              <span>Giá trị COD</span>
              <span>
                {`${
                  name === "history"
                    ? items?.extra_info?.codValue
                    : items?.codValue || 0
                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "đ"}
              </span>
            </div>
          </div>
          {isSelect === index && (
            <div className="imgCheck">
              <img src={rightCorner} alt="check" />
            </div>
          )}
        </div>
      ))}
    </>
  );
}

const usemakeStyle = makeStyles(() => ({
  box: {
    border: "1px solid #D2D2D2",
    borderRadius: "10px",
    marginBottom: "16px",
    paddingBottom: "12px",

    "& .heads": {
      background: "#969696",
      color: "#FFFFFF",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      textAlign: "left",
      fontWeight: "600",
      fontSize: "16px",
      padding: "8px 12px",
      margin: 0,
    },

    "& .boxLine": {
      padding: "12px",
      fontSize: "15px",

      "& .divLine": {
        display: "flex",
        justifyContent: "space-between",
        color: "#000000",

        "& .address ": {
          width: "55%",
        },
      },
    },

    "& .address": { textAlign: "right" },
  },

  boxSelect: {
    border: "1px solid #284078",
    borderRadius: "10px",
    paddingBottom: "12px",
    marginBottom: "16px",
    position: "relative",
    boxShadow: "0 0 10px 5px rgba(40, 64, 120, 0.25)",

    "& .heads": {
      background: "#284078",
      color: "#FFFFFF",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      textAlign: "left",
      fontWeight: "600",
      fontSize: "15px",
      padding: "8px 12px",
      margin: 0,
    },

    "& .boxLine": {
      padding: "12px",

      "& .divLine": {
        fontSize: "15px",
        display: "flex",
        justifyContent: "space-between",
        color: "#000000",

        "& .address ": {
          width: "55%",
        },
      },
    },

    "& .imgCheck": {
      position: "absolute",
      right: "-1px",
      bottom: 0,

      "& img": {
        borderBottomRightRadius: "10px",
      },
    },

    "& .address": { textAlign: "right" },
  },
}));
