import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, message, Row } from "antd";
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

import useReducer from "../../reducer";
import CTCheckbox from "../../components/layout/CTCheckBox";
import createDetailClaimFileReducer from "../../reducer/createDetailClaimFileReducer";
import { Wrapper, ContentWrapper, Content } from "../../../carAccident/styles";
import HeaderBar from "../../components/layout/HeaderBar";
import NextButton from "../../components/layout/NextButton";
import BoxImage from "../../components/createDetailClaimFile/BoxImage";
import Modal from "../../components/createDetailClaimFile/Modal";
import {
  accidentTypes,
  getTypeInsu,
} from "../../components/createDetailClaimFile/until";
import { uploadImage } from "../../../../../../apis/uploadApis";
import { getBankList } from "../../../../../../apis/claimApis";
import { style } from "@mui/system";
import { CREATE_CLAIM_FILE_TOMATO } from "../../../../../../configs/routesConfig";

const initialState = { paramsData: {}, open: false, errors: [], loading: {} };

const relationships = [
  { name: "Vợ/Chồng", value: "couple" },
  { name: "Cha mẹ/Con cái", value: "parent child" },
  { name: "Khác", value: "other" },
];

export default function CreateDetailClaimFile() {
  const history = useHistory();
  const location = useLocation();
  const [bankList, setBankList] = useState([]);

  const [state, dispatch] = useReducer({
    reducer: createDetailClaimFileReducer,
    name: "createDetailClaimFileReducer",
    initialState,
    history,
    location,
  });

  const itemContract = location.state.itemContract;
  const { paramsData, open, errors, loading } = state;
  const { insuredInfo = {}, extraInfo = {} } = paramsData;

  const {
    animalBitesBoarding,
    animalBitesOutpatient,
    animalBitesDead,
    animalBitesPermanentDisability,
    brunedTemporaryInjury,
    brunedDead,
    brunedPermanentDisability,
    lifeOutpatientBone,
    lifeOutpatient,
    lifeBoarding,
    lifePermanentDisability,
    lifeOutpatientPermanentDisability,
    lifeOutpatientPermanentDisabilityBone,
    lifeBoardingPermanentDisability,
    lifeDead,
    workAccidentTemporaryInjuryBone,
    workAccidentTemporaryInjury,
    workAccidentTemporaryInjuryBoarding,
    workAccidentPermanentDisabilityOutpatient,
    workAccidentPermanentDisabilityOutpatientBone,
    workAccidentPermanentDisabilityOutpatientNoBone,
    workAccidentPermanentDisabilityBoarding,
    workAccidentDead,
    trafficOutpatientTemporaryInjury,
    trafficOutpatientTemporaryInjuryBone,
    trafficOutpatientTemporaryInjuryNoBone,
    trafficBoardingTemporaryInjury,
    trafficPermanentDisabilityOutpatient,
    trafficPermanentDisabilityBoarding,
    trafficPermanentDisabilityOutpatientBone,
    trafficPermanentDisabilityOutpatientNoBone,
    trafficDead,
  } = getTypeInsu(insuredInfo, extraInfo);

  useEffect(async () => {
    dispatch("initial");
    await getBanks();
    window.scrollTo(0, 0);
  }, []);

  const getBanks = async () => {
    const dataApi = await (
      await fetch("https://core.globalcare.vn/api/v1/data/bank/list")
    ).json();
    setBankList(dataApi.result);
  };

  const resizeMe = (img) => {
    var canvas = document.createElement("canvas");
    var width = img.width;
    var height = img.height;
    const max_width = 1200;
    const max_height = 1200;
    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        height = Math.round((height *= max_width / width));
        width = max_width;
      }
    } else {
      if (height > max_height) {
        width = Math.round((width *= max_height / height));
        height = max_height;
      }
    }

    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    // Compress jpg, 70%
    return canvas.toDataURL("image/jpeg", 0.7);
  };

  const _resizeVolunmImage = ({ name, e }) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];

    var reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (event) => {
      var blob = new Blob([event.target.result]);
      window.URL = window.URL || window.webkitURL;
      var blobURL = window.URL.createObjectURL(blob);
      var image = new Image();
      image.src = blobURL;

      image.onload = () => {
        const resized = resizeMe(image);
        return upLoadFile(DataURIToBlob(resized), name, file.name);
      };
    };
  };

  const upLoadFile = async (img, name, fileName) => {
    const file = new File([img], fileName, {
      lastModified: new Date().getTime(),
      type: img.type,
    });

    const data = new FormData();
    data.append("document", file);

    dispatch({ type: "onLoading", name });
    const dataApi = await uploadImage(data).catch((e) => e);
    if (dataApi instanceof Error) {
      message.error("Có lỗi xảy ra", 2);
      return dispatch({ type: "cancelUpload", name });
    }

    const item = _.get(dataApi, "data.result[0]") || null;
    dispatch({ type: "upLoadFile", name, item });
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  return (
    <Wrapper>
      <HeaderBar
        title={
          accidentTypes.find(
            (el) => el.value === _.get(paramsData, "extraInfo.accident.type")
          )?.name
        }
        background="white"
        onPress={() =>
          history.push(CREATE_CLAIM_FILE_TOMATO + location.search, {
            itemContract: itemContract,
            paramsData: paramsData,
          })
        }
      />
      <ContentWrapper
        style={{
          backgroundColor: "white",
          marginTop: 10,
          ...wrapSelect,
        }}
      >
        <Content>
          <Row justify="center">
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <div style={{ margin: "30px 0 8px 0" }}>
                <Titiel700 title="chứng từ" />
                <div>
                  <Titiel400 title="Hình chụp CMND/CCCD/Passport mặt trước và sau" />
                  <Grid container>
                    <BoxImage
                      isNew
                      isMargin
                      onClick={(e) => _resizeVolunmImage({ e, name: "id" })}
                      onLoading={loading?.id}
                    />
                    {(_.get(insuredInfo, "images.id") || []).map(
                      (item, index) => (
                        <BoxImage
                          key={index}
                          isMargin
                          url={item || null}
                          deleteImg={() =>
                            dispatch({
                              type: "deleteImg",
                              name: "id",
                              rowIndex: index,
                            })
                          }
                        />
                      )
                    )}
                  </Grid>
                  {errors.includes("idImage") && (
                    <HyperText title="Vui lòng chụp/ tải lên đầy đủ hai mặt CMND/CCCD/Passport hợp lệ" />
                  )}
                </div>
                {(!!trafficOutpatientTemporaryInjury ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityOutpatient ||
                  !!trafficPermanentDisabilityBoarding) && (
                  <div>
                    <Titiel400 title="Hình chụp giấy phép lái xe và giấy tờ xe (cavet xe)" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({ e, name: "license" })
                        }
                        onLoading={loading?.license}
                      />
                      {(_.get(insuredInfo, "images.license") || []).map(
                        (item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "license",
                                rowIndex: index,
                              })
                            }
                          />
                        )
                      )}
                    </Grid>
                    {errors.includes("license") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!workAccidentTemporaryInjuryBone ||
                  !!workAccidentTemporaryInjury ||
                  !!workAccidentTemporaryInjuryBoarding ||
                  !!workAccidentPermanentDisabilityOutpatient ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficOutpatientTemporaryInjury ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityOutpatient ||
                  !!trafficPermanentDisabilityBoarding) && (
                  <div>
                    <Titiel400 title="Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({ e, name: "report" })
                        }
                        onLoading={loading?.report}
                      />
                      {(_.get(insuredInfo, "images.report") || []).map(
                        (item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "report",
                                rowIndex: index,
                              })
                            }
                          />
                        )
                      )}
                    </Grid>
                    {errors.includes("report") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!lifeBoarding ||
                  !!lifeBoardingPermanentDisability ||
                  !!workAccidentTemporaryInjuryBoarding ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityBoarding) && (
                  <div>
                    <Titiel400 title="Hình chụp giấy ra viện, đơn thuốc xuất viện" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({
                            e,
                            name: "prescriptionHospitalCheckout",
                          })
                        }
                        onLoading={loading?.prescriptionHospitalCheckout}
                      />
                      {(
                        _.get(
                          insuredInfo,
                          "images.prescriptionHospitalCheckout"
                        ) || []
                      ).map((item, index) => (
                        <BoxImage
                          key={index}
                          isMargin={index !== 2}
                          url={item || null}
                          deleteImg={() =>
                            dispatch({
                              type: "deleteImg",
                              name: "prescriptionHospitalCheckout",
                              rowIndex: index,
                            })
                          }
                        />
                      ))}
                    </Grid>
                    {errors.includes("prescriptionHospitalCheckout") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}

                {(!!lifeOutpatientBone ||
                  !!lifeOutpatient ||
                  !!lifeOutpatientPermanentDisability ||
                  !!workAccidentTemporaryInjuryBone ||
                  !!workAccidentTemporaryInjury ||
                  !!workAccidentPermanentDisabilityOutpatient ||
                  !!trafficOutpatientTemporaryInjury ||
                  !!trafficPermanentDisabilityOutpatient) && (
                  <div>
                    <Titiel400 title="Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh viện/ phòng khám" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({ e, name: "prescription" })
                        }
                        onLoading={loading?.prescription}
                      />
                      {(_.get(insuredInfo, "images.prescription") || []).map(
                        (item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "prescription",
                                rowIndex: index,
                              })
                            }
                          />
                        )
                      )}
                    </Grid>
                    {errors.includes("prescription") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!lifeOutpatient ||
                  !!workAccidentTemporaryInjury ||
                  !!workAccidentPermanentDisabilityOutpatientNoBone ||
                  !!trafficOutpatientTemporaryInjuryNoBone ||
                  !!trafficPermanentDisabilityOutpatientNoBone) && (
                  <div>
                    <Titiel400 title="Phiếu chỉ định của bác sĩ đối với yêu cầu xét nghiệm, chiếu chụp và kết quả chiếu chụp-xét nghiệm" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) => _resizeVolunmImage({ e, name: "test" })}
                        onLoading={loading?.test}
                      />
                      {(_.get(insuredInfo, "images.test") || []).map(
                        (item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "test",
                                rowIndex: index,
                              })
                            }
                          />
                        )
                      )}
                    </Grid>
                    {errors.includes("test") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!lifeOutpatientBone ||
                  !!lifeOutpatientPermanentDisabilityBone ||
                  !!workAccidentTemporaryInjuryBone ||
                  !!workAccidentPermanentDisabilityOutpatientBone ||
                  !!trafficOutpatientTemporaryInjuryBone ||
                  !!trafficPermanentDisabilityOutpatientBone) && (
                  <div>
                    <Titiel400 title="Hình chụp phim XQ, chỉ định chụp XQ; hình chụp phiếu đọc kết quả phim XQ" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) => _resizeVolunmImage({ e, name: "xq" })}
                        onLoading={loading?.xq}
                      />
                      {(_.get(insuredInfo, "images.xq") || []).map(
                        (item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "xq",
                                rowIndex: index,
                              })
                            }
                          />
                        )
                      )}
                    </Grid>
                    {errors.includes("xq") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {!!brunedTemporaryInjury && (
                  <div>
                    <Titiel400 title="Hình chụp toa thuốc/ giấy ra viện có ghi chẩn đoán “bỏng”, độ bỏng, diện tích bỏng (nếu có), chữ ký bác sỹ và mộc tròn bệnh viện/ phòng khám" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({ e, name: "diagnose" })
                        }
                        onLoading={loading?.diagnose}
                      />
                      {(_.get(insuredInfo, "images.diagnose") || []).map(
                        (item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "diagnose",
                                rowIndex: index,
                              })
                            }
                          />
                        )
                      )}
                    </Grid>
                    {errors.includes("diagnose") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!animalBitesPermanentDisability ||
                  !!brunedPermanentDisability ||
                  !!lifeBoardingPermanentDisability ||
                  !!lifePermanentDisability ||
                  !!workAccidentPermanentDisabilityOutpatient ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficPermanentDisabilityOutpatient ||
                  !!trafficPermanentDisabilityBoarding) && (
                  <div>
                    <Titiel400 title="Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận nguyên nhân" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({
                            e,
                            name: "governmentConfirmation",
                          })
                        }
                        onLoading={loading?.governmentConfirmation}
                      />
                      {(
                        _.get(insuredInfo, "images.governmentConfirmation") ||
                        []
                      ).map((item, index) => (
                        <BoxImage
                          key={index}
                          isMargin={index !== 2}
                          url={item || null}
                          deleteImg={() =>
                            dispatch({
                              type: "deleteImg",
                              name: "governmentConfirmation",
                              rowIndex: index,
                            })
                          }
                        />
                      ))}
                    </Grid>
                    {errors.includes("governmentConfirmation") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!animalBitesPermanentDisability ||
                  !!brunedPermanentDisability ||
                  !!lifeOutpatientPermanentDisability ||
                  !!lifeBoardingPermanentDisability ||
                  !!workAccidentPermanentDisabilityOutpatient ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficPermanentDisabilityOutpatient ||
                  !!trafficPermanentDisabilityBoarding) && (
                  <div>
                    <Titiel400 title="Giấy chứng nhận thương tật của cơ quan có thẩm quyền" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({ e, name: "certificateInjury" })
                        }
                        onLoading={loading?.certificateInjury}
                      />
                      {(
                        _.get(insuredInfo, "images.certificateInjury") || []
                      ).map((item, index) => (
                        <BoxImage
                          key={index}
                          isMargin={index !== 2}
                          url={item || null}
                          deleteImg={() =>
                            dispatch({
                              type: "deleteImg",
                              name: "certificateInjury",
                              rowIndex: index,
                            })
                          }
                        />
                      ))}
                    </Grid>
                    {errors.includes("certificateInjury") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!animalBitesDead ||
                  !!brunedDead ||
                  !!lifeDead ||
                  !!workAccidentDead ||
                  !!trafficDead) && (
                  <div>
                    <Titiel400 title="Biên bản công an điều tra có kết luận nguyên nhân tử vong/xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận nguyên nhân tử vong" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({ e, name: "deadConclusion" })
                        }
                        onLoading={loading?.deadConclusion}
                      />
                      {(_.get(insuredInfo, "images.deadConclusion") || []).map(
                        (item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "deadConclusion",
                                rowIndex: index,
                              })
                            }
                          />
                        )
                      )}
                    </Grid>
                    {errors.includes("deadConclusion") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!animalBitesDead ||
                  !!brunedDead ||
                  !!lifeDead ||
                  !!workAccidentDead ||
                  !!trafficDead) && (
                  <div>
                    <Titiel400 title="Giấy chứng tử" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({ e, name: "deathCertificate" })
                        }
                        onLoading={loading?.deathCertificate}
                      />
                      {(
                        _.get(insuredInfo, "images.deathCertificate") || []
                      ).map((item, index) => (
                        <BoxImage
                          key={index}
                          isMargin={index !== 2}
                          url={item || null}
                          deleteImg={() =>
                            dispatch({
                              type: "deleteImg",
                              name: "deathCertificate",
                              rowIndex: index,
                            })
                          }
                        />
                      ))}
                    </Grid>
                    {errors.includes("deathCertificate") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!animalBitesDead ||
                  !!brunedDead ||
                  !!lifeDead ||
                  !!workAccidentDead ||
                  !!trafficDead) && (
                  <div>
                    <Titiel400 title="Biên bản phân chia di sản thừa kế có xác nhận của cơ quan chức năng/phường xã" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({ e, name: "inheritanceText" })
                        }
                        onLoading={loading?.inheritanceText}
                      />
                      {(_.get(insuredInfo, "images.inheritanceText") || []).map(
                        (item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "inheritanceText",
                                rowIndex: index,
                              })
                            }
                          />
                        )
                      )}
                    </Grid>
                    {errors.includes("inheritanceText") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!animalBitesOutpatient || !!animalBitesBoarding) && (
                  <div>
                    <Titiel400 title="Hình chụp phiếu chích ngừa/ chỉ định chích ngừa" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({ e, name: "vaccinationCard" })
                        }
                        onLoading={loading?.vaccinationCard}
                      />
                      {(_.get(insuredInfo, "images.vaccinationCard") || []).map(
                        (item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "vaccinationCard",
                                rowIndex: index,
                              })
                            }
                          />
                        )
                      )}
                    </Grid>
                    {errors.includes("vaccinationCard") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {!!animalBitesBoarding && (
                  <div>
                    <Titiel400 title="Hình chụp giấy ra viện" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({ e, name: "hospitalCheckout" })
                        }
                        onLoading={loading?.hospitalCheckout}
                      />
                      {(
                        _.get(insuredInfo, "images.hospitalCheckout") || []
                      ).map((item, index) => (
                        <BoxImage
                          key={index}
                          isMargin={index !== 2}
                          url={item || null}
                          deleteImg={() =>
                            dispatch({
                              type: "deleteImg",
                              name: "hospitalCheckout",
                              rowIndex: index,
                            })
                          }
                        />
                      ))}
                    </Grid>
                    {errors.includes("hospitalCheckout") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {!!animalBitesBoarding && (
                  <div>
                    <Titiel400 title="Hình chụp đơn thuốc xuất viện" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({
                            e,
                            name: "prescriptionCheckout",
                          })
                        }
                        onLoading={loading?.prescriptionCheckout}
                      />
                      {(
                        _.get(insuredInfo, "images.prescriptionCheckout") || []
                      ).map((item, index) => (
                        <BoxImage
                          key={index}
                          isMargin={index !== 2}
                          url={item || null}
                          deleteImg={() =>
                            dispatch({
                              type: "deleteImg",
                              name: "prescriptionCheckout",
                              rowIndex: index,
                            })
                          }
                        />
                      ))}
                    </Grid>
                    {errors.includes("prescriptionCheckout") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!animalBitesBoarding ||
                  !!lifeBoarding ||
                  !!lifeBoardingPermanentDisability ||
                  !!workAccidentTemporaryInjuryBoarding ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityBoarding) && (
                  <div>
                    <Titiel400 title="Hình chụp bảng kê chi tiết viện phí" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({
                            e,
                            name: "detailedListHospitalFees",
                          })
                        }
                        onLoading={loading?.detailedListHospitalFees}
                      />
                      {(
                        _.get(insuredInfo, "images.detailedListHospitalFees") ||
                        []
                      ).map((item, index) => (
                        <BoxImage
                          key={index}
                          isMargin={index !== 2}
                          url={item || null}
                          deleteImg={() =>
                            dispatch({
                              type: "deleteImg",
                              name: "detailedListHospitalFees",
                              rowIndex: index,
                            })
                          }
                        />
                      ))}
                    </Grid>
                    {errors.includes("detailedListHospitalFees") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!animalBitesBoarding ||
                  !!lifeBoarding ||
                  !!lifeBoardingPermanentDisability ||
                  !!workAccidentTemporaryInjuryBoarding ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityBoarding) && (
                  <div>
                    <Titiel400
                      title="Hình chụp giấy chứng nhận phẫu thuật (nếu có)"
                      unRequier
                    />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({ e, name: "surgery" })
                        }
                        onLoading={loading?.surgery}
                      />
                      {(_.get(insuredInfo, "images.surgery") || []).map(
                        (item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "surgery",
                                rowIndex: index,
                              })
                            }
                          />
                        )
                      )}
                    </Grid>
                  </div>
                )}
                {(!!animalBitesOutpatient || !!animalBitesBoarding) && (
                  <div>
                    <Titiel400 title="Hóa đơn chích ngừa" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) =>
                          _resizeVolunmImage({ e, name: "vaccinationBill" })
                        }
                        onLoading={loading?.vaccinationBill}
                      />
                      {(_.get(insuredInfo, "images.vaccinationBill") || []).map(
                        (item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "vaccinationBill",
                                rowIndex: index,
                              })
                            }
                          />
                        )
                      )}
                    </Grid>
                    {errors.includes("vaccinationBill") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!brunedTemporaryInjury ||
                  !!brunedPermanentDisability ||
                  !!lifeOutpatientBone ||
                  !!lifeOutpatient ||
                  !!lifeBoarding ||
                  !!lifeOutpatientPermanentDisability ||
                  !!lifeBoardingPermanentDisability ||
                  !!workAccidentTemporaryInjuryBone ||
                  !!workAccidentTemporaryInjury ||
                  !!workAccidentTemporaryInjuryBoarding ||
                  !!workAccidentPermanentDisabilityOutpatient ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficOutpatientTemporaryInjury ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityOutpatient ||
                  !!trafficPermanentDisabilityBoarding) && (
                  <div>
                    <Titiel400 title="Hóa đơn điều trị" />
                    <Grid container>
                      <BoxImage
                        isNew
                        isMargin
                        onClick={(e) => _resizeVolunmImage({ e, name: "bill" })}
                        onLoading={loading?.bill}
                      />
                      {(_.get(insuredInfo, "images.bill") || []).map(
                        (item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "bill",
                                rowIndex: index,
                              })
                            }
                          />
                        )
                      )}
                    </Grid>
                    {errors.includes("bill") && (
                      <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                    )}
                  </div>
                )}
                {(!!animalBitesOutpatient ||
                  !!animalBitesBoarding ||
                  !!brunedTemporaryInjury ||
                  !!brunedPermanentDisability ||
                  !!lifeOutpatientBone ||
                  !!lifeBoarding ||
                  !!lifeBoardingPermanentDisability ||
                  !!lifeOutpatient ||
                  !!workAccidentTemporaryInjuryBone ||
                  !!workAccidentTemporaryInjuryBoarding ||
                  !!workAccidentTemporaryInjury ||
                  !!workAccidentPermanentDisabilityOutpatient ||
                  !!workAccidentPermanentDisabilityBoarding ||
                  !!trafficOutpatientTemporaryInjury ||
                  !!trafficBoardingTemporaryInjury ||
                  !!trafficPermanentDisabilityOutpatient ||
                  !!trafficPermanentDisabilityBoarding) && (
                  <div>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        lineHeight: "19px",
                        letterSpacing: "-0.408px",
                        padding: "16px 0",
                        color: "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      {" "}
                      Vui lòng đính kèm link hóa đơn điện tử (nếu có)
                    </Typography>
                    <TextField
                      fullWidth
                      value={insuredInfo?.billLink || ""}
                      onChange={(e) =>
                        dispatch({
                          type: "onChange",
                          name: "billLink",
                          value: e.target.value,
                        })
                      }
                      sx={{
                        ...textfield,
                        "& input": {
                          color: "#04409A",
                          textDecoration: "underline",
                        },
                      }}
                    />
                  </div>
                )}
                <div style={{ width: "100%", height: 10 }} />
                <Titiel700 title="Thông tin người thụ hưởng" />
                <Typography sx={{ color: "#0B3C8A", lineHeight: "1.35em" }}>
                  Lưu ý tài khoản ngân hàng này là tài khoản của người được bảo
                  hiểm hoặc người thừa kế của người được bảo hiểm
                </Typography>

                <Titiel400 title="Chọn người thụ hưởng" />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  {_.get(extraInfo, "accident.level") !== "dead" && (
                    <FormControlLabel
                      control={
                        <CTCheckbox
                          onChange={(e) =>
                            dispatch({
                              type: "onChange",
                              name: "type",
                              value: e.target.checked ? "self" : null,
                            })
                          }
                          checked={
                            _.get(paramsData, "extraInfo.beneficiary.type") ===
                            "self"
                          }
                          sx={{ color: "#0B3C8A" }}
                        />
                      }
                      label="Bản thân"
                    />
                  )}
                  <FormControlLabel
                    control={
                      <CTCheckbox
                        onChange={(e) =>
                          _.get(paramsData, "extraInfo.accident.level") !==
                            "dead" &&
                          dispatch({
                            type: "onChange",
                            name: "type",
                            value: e.target.checked ? "relative" : null,
                          })
                        }
                        // checked={
                        //   _.get(extraInfo, "accident.level") === "dead"
                        //     ? true
                        //     : _.get(
                        //         paramsData,
                        //         "extraInfo.beneficiary.type"
                        //       ) === "relative"
                        // }
                        checked={
                          _.get(paramsData, "extraInfo.beneficiary.type") ===
                          "relative"
                        }
                        sx={{ color: "#0B3C8A" }}
                      />
                    }
                    label="Người thân"
                  />
                </Box>
                {errors.includes("type") && (
                  <HyperText title="Vui lòng chọn thông tin trên" noneMagin />
                )}
                {(_.get(paramsData, "extraInfo.beneficiary.type") ===
                  "relative" ||
                  _.get(extraInfo, "accident.level") === "dead") && (
                  <>
                    <Titiel400 title="Mối quan hệ với người được bảo hiểm" />
                    <TextField
                      fullWidth
                      value={paramsData?.extraInfo?.beneficiary?.relationship}
                      onChange={(e) => {
                        return dispatch({
                          type: "onChange",
                          name: "relationship",
                          value: e.target.value,
                        });
                      }}
                      select
                      sx={textfield}
                    >
                      {relationships.map((item, key) => (
                        <MenuItem key={key} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    {errors.includes("relationship") && (
                      <HyperText
                        title="Vui lòng nhập thông tin trên"
                        noneMagin
                      />
                    )}
                  </>
                )}

                <Titiel400 title="Họ tên người thụ hưởng" />
                <TextField
                  fullWidth
                  sx={textfield}
                  value={paramsData?.extraInfo?.beneficiary?.fullname}
                  onChange={(e) =>
                    dispatch({
                      type: "onChange",
                      name: "fullname",
                      value: e.target.value,
                    })
                  }
                  placeholder="Họ tên người thụ hưởng"
                />
                {errors.includes("fullname") && (
                  <HyperText title="Vui lòng nhập thông tin trên" noneMagin />
                )}

                {
                  // (
                  //   !!animalBitesDead ||
                  //   !!brunedDead ||
                  //   !!lifeDead ||
                  //   !!workAccidentDead)
                  _.get(paramsData, "extraInfo.beneficiary.type") ===
                    "relative" && (
                    <div>
                      <Titiel400 title="Chứng từ xác minh quan hệ" />
                      <Grid container>
                        <BoxImage
                          isNew
                          isMargin
                          onClick={(e) =>
                            _resizeVolunmImage({
                              e,
                              name: "verifyRelationship",
                            })
                          }
                        />
                        {(
                          _.get(insuredInfo, "images.verifyRelationship") || []
                        ).map((item, index) => (
                          <BoxImage
                            key={index}
                            isMargin={index !== 2}
                            url={item || null}
                            deleteImg={() =>
                              dispatch({
                                type: "deleteImg",
                                name: "verifyRelationship",
                                rowIndex: index,
                              })
                            }
                          />
                        ))}
                      </Grid>
                      {errors.includes("verifyRelationship") && (
                        <HyperText title="Vui lòng chụp/ tải lên chứng từ" />
                      )}
                    </div>
                  )
                }

                <Titiel400 title="Chọn ngân hàng" />
                <TextField
                  select
                  value={_.get(extraInfo, "beneficiary.bank.name") || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "onChange",
                      name: "name",
                      value: e.target.value,
                    })
                  }
                  placeholder="Nhập tên ngân hàng của bạn"
                  fullWidth
                  sx={{
                    ...textfield,
                  }}
                >
                  {bankList.map((item, key) => (
                    <MenuItem key={key} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
                {errors.includes("name") && (
                  <HyperText title="Vui lòng nhập thông tin trên" noneMagin />
                )}
                <Titiel400 title="Số tài khoản" />
                <TextField
                  value={_.get(extraInfo, "beneficiary.bank.id") || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "onChange",
                      name: "id",
                      value: e.target.value,
                    })
                  }
                  placeholder="Nhập số tài khoản ngân hàng của bạn"
                  inputProps={{ inputMode: "numeric" }}
                  fullWidth
                  sx={textfield}
                />
                {errors.includes("id") && (
                  <HyperText title="Thông tin chưa hợp lệ" noneMagin />
                )}
                <Titiel400 title="Nhập tên chi nhánh ngân hàng" />
                <TextField
                  value={_.get(extraInfo, "beneficiary.bank.branch") || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "onChange",
                      name: "branch",
                      value: e.target.value,
                    })
                  }
                  placeholder="Nhập tên chi nhánh"
                  fullWidth
                  sx={textfield}
                />
                {errors.includes("branch") && (
                  <HyperText title="Vui lòng nhập thông tin trên" noneMagin />
                )}
                <Modal open={open} close={() => dispatch("closeModal")} />
                <NextButton onChangNext={() => dispatch("onSubmit")} />
              </div>
            </Col>
          </Row>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
}

const Titiel700 = ({ title }) => {
  return (
    <Box sx={{ marginBottom: "16px" }}>
      <Typography sx={styleTitle700}>{title}</Typography>
    </Box>
  );
};

const Titiel400 = ({ title, unRequier, unMarginTop, blue = false }) => {
  let titStyle = styleTitle400;
  if (blue) {
    titStyle.color = "#0B3C8A !important";
  }
  return (
    <Box sx={{ marginBottom: "16px", marginTop: unMarginTop ? 0 : "16px" }}>
      <Typography sx={titStyle}>
        {title}
        <span style={{ color: "red", display: unRequier ? "none" : "" }}>
          *
        </span>
      </Typography>
    </Box>
  );
};
const HyperText = ({ title, noneMagin }) => {
  return (
    <Box sx={{ marginTop: noneMagin ? 0 : "12px" }}>
      <Typography sx={hyperText}>{title}</Typography>
    </Box>
  );
};

const styleTitle700 = {
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "25px",
  letterSpacing: "-0.408px",
  textTransform: "uppercase",
  color: "#0B3C8A",
};

const styleTitle400 = {
  fontWeight: "bold",
  fonStize: "15px",
  lineHeight: "19px",
  letterSpacing: "-0.408px",
  color: "#000000",
};

const hyperText = {
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "16px",
  letterSpacing: "-0.408px",
  color: "#FF2929",
};

const textfield = {
  marginBottom: "16px",
  background: "#FFFFFF",
  border: "1px solid rgba(151, 151, 151, 0.241965)",
  boxSizing: " border-box",
  boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.054428)",
  borderRadius: "9px",
  "& input": {
    color: "#00000",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    display: "none",
  },
};

const wrapSelect = {};
