import { message } from "antd";
import { detailContract, updateContract } from "../../../../apis/merchantApis";
import { cloneDeep, get } from "lodash";

const checkLevel = (data) =>
  data.map((i) => {
    const regex = /\./g;
    if (!i.codeBenefit.includes(".")) {
      return { ...i, isFirst: true };
    }
    if (i.codeBenefit.match(regex).length === 1) {
      return { ...i, isSecond: true };
    }
    return i;
  });

const contractReducer = ({
  initialState,
  state,
  action,
  dispatch,
  history,
  id,
  packageId,
}) => {
  const cases = {
    getOldData: () => {
      const oldData = JSON.parse(localStorage?.getItem("product-info"));
      return { oldData };
    },
    getDetailContract: async () => {
      dispatch("onLoading");
      const res = {};
      const result = await detailContract(id);
      if (result instanceof Error) {
        message.error(result.response?.data?.message || "Lỗi lấy chi tiết", 2);
        return { loading: false };
      }
      res.data = get(result, "data.result") || {};
      const list =
        get(result, "data.result.productPackageBenefitList.data") || [];
      res.benefitList = checkLevel(list);
      return { ...res, loading: false };
    },
    onChange: () => {
      const { dataInfo } = state;
      const { name, value } = action;
      dataInfo[name] = value;
      return { dataInfo };
    },
    selectShareTime: () => {
      const { editData } = state;
      const { name, value } = action;
      editData.config_share_payment = {
        ...editData.config_share_payment,
        [name]: +value || 0,
      };
      return { editData };
    },
    selectWaitingTime: () => {
      const { editData } = state;
      const { name, value } = action;
      editData.config_waiting = {
        ...editData.config_waiting,
        [name]: +value || 0,
      };
      return { editData };
    },
    onOpenInfo: () => {
      const { open, data } = state;
      open.user = true;
      const dataInfo = cloneDeep(data);
      return { open, dataInfo };
    },
    onOpen: () => {
      const { open, data } = state;
      const { name } = action;
      open[name] = true;
      const editData = cloneDeep(data?.extraInfo || {});
      return { open, editData };
    },
    onClose: () => {
      const { open } = state;
      open[action.name] = false;
      return { open, errors: [] };
    },
    onSaving: () => ({ saving: true }),
    onLoading: () => ({ loading: true }),

    validate: () => {
      const { dataInfo } = state;
      const errors = [];
      const keys = [
        "insuredName",
        "insuredDob",
        "insuredPrivateId",
        "beginDate",
        "endDate",
      ];
      keys.forEach((i) => {
        !dataInfo[i] && errors.push(i);
      });
      if (errors.length === 0) {
        dispatch("updateInfo");
      }
      return { errors };
    },

    updateInfo: async () => {
      const { data, dataInfo, open } = state;
      const p = {
        id: +dataInfo?.id,
        // certNum: dataInfo?.certNum || null,
        insuredPhone: dataInfo?.insuredPhone || null,
        insuredName: dataInfo?.insuredName || null,
        insuredDob: dataInfo?.insuredDob || null,
        insuredPrivateId: dataInfo?.insuredPrivateId || null,
        insuredEmail: dataInfo?.insuredEmail || null,
        insuredType: dataInfo?.insuredType || null,
        insuredGender: dataInfo?.insuredGender || null,
        insuredAddress: dataInfo?.insuredAddress || null,
        productPackageId: +packageId,
        extraInfo: data?.extraInfo,
      };

      dispatch("onSaving");
      const result = await updateContract(p);
      if (result instanceof Error) {
        message.error(
          result.response?.data?.message || "Lỗi cập nhật thông tin khách hàng",
          2
        );
        return { saving: false };
      }
      message.success("Cập nhật thông tin khách hàng thành công", 2);
      open.user = false;
      dispatch("getDetailContract");
      return { open, errors: [], saving: false };
    },

    saveTime: async () => {
      const { data, open, editData } = state;
      const p = {
        id: +data?.id,
        certNum: data?.certNum || null,
        insuredPhone: data?.insuredPhone || null,
        insuredName: data?.insuredName || null,
        insuredDob: data?.insuredDob || null,
        insuredPrivateId: data?.insuredPrivateId || null,
        insuredEmail: data?.insuredEmail || null,
        insuredType: data?.insuredType || null,
        insuredGender: data?.insuredGender || null,
        insuredAddress: data?.insuredAddress || null,
        productPackageId: +packageId,
        extraInfo: editData,
      };

      dispatch("onSaving");
      const result = await updateContract(p);
      if (result instanceof Error) {
        message.error(
          result.response?.data?.message || "Lỗi cập nhật thông tin khách hàng",
          2
        );
        return { saving: false };
      }
      message.success("Cập nhật thông tin khách hàng thành công", 2);
      open[action.name] = false;
      dispatch("getDetailContract");
      return { open, saving: false };
    },
  };
  return cases[action?.type];
};
export default contractReducer;
