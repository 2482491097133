import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  title: {
    color: "#284078",
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 8,
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  textField: {
    "& .MuiInputBase-input": {
      padding: "6px",
      borderRadius: "8px",
    },
    "& fieldset": {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root": {
      height: 44,
      width: "calc(100% - 8px)",
      paddingLeft: 10,
    },
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },

  button: {
    borderRadius: 8,
    background: "#284078",
    fontWeight: 600,
    color: "white",
    height: 44,
    fontSize: 15,
    "&:hover": {
      background: "#284078",
      color: "white",
    },
  },

  spacing: {
    height: 5,
    background: "#F8F8F8",
    width: "100vw",
    margin: "15px 0 10px -10px",
  },

  code: {
    padding: 10,
    gap: 10,
    margin: "5px 0",
    background: "#F8F9FA",
    fontSize: 16,
  },

  history: {
    textAlign: "right",
    color: "#0044CC",
    fontWeight: 600,
    marginBottom: 10,
    textDecoration: "underline",
  },

  center: {
    textAlign: "center",
    "& .line1": {
      fontSize: 17,
      color: "#284078",
      fontWeight: 600,
      marginTop: 15,
    },
    "& .line2": {
      fontSize: 16,
      color: "#E16C2B",
      fontWeight: 500,
    },
    "& .line3": {
      color: "#3F76DF",
      fontStyle: "italic",
      textDecoration: "underline",
      cursor: "pointer",
    },
  },

  boxOrder: {
    margin: "8px 0",
    padding: "16px 0",
    background: "white",
    "& .header": {
      color: "#284078",
      fontSize: "16px",
      textAlign: "center",
      fontWeight: "600",
      marginBottom: "12px",
    },
  },

  container: {
    fontSize: 13,
    "& .header": {
      fontSize: 17,
      color: "#284078",
      fontWeight: 600,
      paddingTop: 5,
    },
    "& .title": {
      fontSize: 16,
      fontWeight: 600,
      paddingTop: "10px",
    },
    "& .rowDiv": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: 4,
    },
    "& .titleSpan": {
      whiteSpace: "nowrap",
      width: "max-content",
      marginRight: 12,
    },
    "& .my-5": { margin: "5px 0" },
  },

  divInfo: {
    "& .row": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .label": {
      color: "#284078",
      fontWeight: 500,
      padding: "8px 0",
      fontSize: 14,
    },
    "& .box-img": {
      border: "1px solid #ECECEC",
      borderRadius: 8,
      width: "49%",
      padding: 10,
      "& .frame-img": {
        height: "100px",
        textAlign: "center",
      },
      "& .img": {
        height: "100%",
        maxWidth: "100%",
      },
      "& .btn": {
        width: "100%",
        background: "#284078",
        color: "#fff",
        borderRadius: 4,
        fontSize: 13,
        fontWeight: 600,
        marginTop: 8,
      },
    },

    "& .box-file": {
      border: "1px solid #ECECEC",
      borderRadius: 8,
      "& .border-item": {
        borderBottom: "1px solid #E9ECEF",
        margin: "8px 4px 0",
      },
      "& .size": {
        fontSize: 13,
        fontStyle: "italic",
      },
    },
    "& .upload": {
      padding: "4px 8px",
      background: "#F8F9FA",
      "& .button": {
        background: "#E4E7EB",
        border: "#E4E7EB",
        color: "#343A40",
        borderRadius: 4,
        fontSize: 13,
      },
    },
  },

  error: { color: "red", textAlign: "center", marginTop: 50 },
  errText: { color: "red", margin: "-10px 0 10px" },
  readOnly: {
    "& .MuiTextField-root": { margin: "8px 0" },
    "& .MuiInputBase-root": {
      borderRadius: 10,
      background: "#F1F1F1",
      fontSize: 14,
    },
    "& .MuiInputLabel-root": {
      borderRadius: 10,
      padding: "0px 10px",
      background: "#fff",
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px 15px 10px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D2D2D2 !important",
    },
    "& .MuiFormLabel-root": {
      color: "#284078 !important",
      fontWeight: 500,
      fontSize: 17,
    },
  },
  inputText: {
    "& .MuiTextField-root": { margin: "8px 0" },
    "& .MuiInputBase-root": {
      borderRadius: 10,
      fontSize: 14,
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 15px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D2D2D2 !important",
    },
    "& .MuiFormLabel-root": {
      color: "#284078 !important",
      fontWeight: 500,
      fontSize: 17,
    },
  },
  collapse: {
    "& .adm-list-item": { padding: 0, border: "none" },
    "& .adm-list-item-content": { padding: 0, border: "none" },
    "& .adm-list-item-content-main": {
      padding: "0px",
      lineHeight: "40px",
      color: "#343A40",
      fontWeight: 600,
      fontSize: 16,
    },
    "& .adm-list-default": { border: "none" },
    "& a:hover": { color: "black" },
  },
  textErr: {
    color: "red",
    fontSize: 12,
    marginTop: 5,
    fontWeight: 400,
    lineHeight: "25px",
  },
  bookerInput: {
    "& .MuiTextField-root": { margin: "8px 0" },
    "& .MuiInputBase-root": {
      borderRadius: 10,
      background: "#F1F1F1",
      fontSize: 14,
    },
    "& .MuiInputLabel-root": {
      borderRadius: 10,
      padding: "0px 10px",
      background: "#fff",
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D2D2D2 !important",
    },
    "& .MuiFormLabel-root": {
      color: "#284078 !important",
      fontWeight: 500,
      fontSize: 17,
    },
  },
}));
