import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogShowImg(props) {
  const { state, dispatch } = props;
  const classes = useMakeStyles();

  const { dialogShowImg } = state;

  return (
    <Dialog
      open={!!dialogShowImg?.open}
      TransitionComponent={Transition}
      maxWidth={"lg"}
      onClose={() => dispatch("closeDialogShowImg")}
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton onClick={() => dispatch("closeDialogShowImg")}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "0 18px 16px 18px" }}>
        <div className={classes.divImg}>
          <img src={dialogShowImg.path} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

const useMakeStyles = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
      margin: "24px",
      width: "100%",
    },
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    padding: "8px 18px",

    "& .content-title": {
      color: "#4B4B4B",
      fontWeight: "600",
      fontSize: "14px",
    },
  },

  divImg: {
    width: "100%",
  },

  //
}));
