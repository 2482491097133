import styled from "@emotion/styled";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getListNotification_v2 } from "../../../../apis/notificationApis";
import * as PATH from "../../../../configs/routesConfig";
import { ModalNotification } from "./modal/modal-notification";

import avatarDefault from "../../../../assets/lauyout/avatar-img.svg";
import notificationsIcon from "../../../../assets/lauyout/notifications-icon.svg";
import { useRecoilState } from "recoil";
import { notification } from "../../stateRecoil/atom";

// const packageJson = require("../../../../../package.json");
// const version = packageJson.version;

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[0],
}));

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, ...other } = props;

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const systemType = user?.extra_info?.userType || 0;
  const isRoot = user?.role_management_claim?.is_root || 0;

  const [notificationes, setNotificationes] = useRecoilState(notification);
  const [showModalNotification, setShowModalNotification] = useState(false);
  const [version, setVersion] = useState("");
  // const [notificationList, setNotificationList] = useState({});

  const callApi = async () => {
    const timestamp = new Date().getTime();
    const response = await fetch(`/meta.json?_=${timestamp}`);
    const data = await response.json();
    data.version && setVersion(data.version);

    if (!systemType) {
      return;
    }

    const dataAPi = await getListNotification_v2(systemType, {
      limit: 20,
      offset: 0,
    });
    if (dataAPi?.data?.statusCode != 200) {
      // setLoading(false);x
      setNotificationes({});
    }
    if (dataAPi?.data?.statusCode == 200) {
      setNotificationes(dataAPi.data.result);
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  const onChangeShowModalNotification = () => {
    setShowModalNotification(!showModalNotification);
    // callApi();
  };

  const { goBack, push } = useHistory();
  const avatar = user?.avatar;
  const showIconBacks = showIconBack();

  return (
    <>
      <DashboardNavbarRoot sx={cssNavbarRoot} {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: "inline-flex",
                lg: "none",
              },
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          {!showIconBacks.status && (
            <Tooltip title="Back" style={{ borderRadius: 5 }}>
              <IconButton
                sx={{ ml: 1 }}
                onClick={goBack}
                // hidden={location.state}
              >
                <KeyboardBackspaceIcon /> &nbsp;
                <Box display="flex" flexDirection="column">
                  <Typography textAlign="left">{props.titleNavbar}</Typography>
                  <Typography color="#000">{props.desNavbar}</Typography>
                </Box>
              </IconButton>
            </Tooltip>
          )}
          {/* <span style={{ color: "#4B4B4B", fontSize: "15px" }}>Phat dt</span> */}
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Thông báo">
            <IconButton
              onClick={onChangeShowModalNotification}
              style={{ marginRight: "12px" }}
            >
              <Badge
                badgeContent={+notificationes?.totalUnread || 0}
                color="error"
              >
                <img src={notificationsIcon} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Box
            onClick={() => {
              push(PATH.ADMIN_V2_ACCOUNT);
            }}
            sx={{
              display: "flex",
              borderLeft: "1px solid #DCDCDC",
              cursor: "pointer",
            }}
          >
            <IconButton sx={{ ml: 1, mr: 1 }}>
              <Avatar
                sx={{
                  height: 40,
                  width: 40,
                }}
                src={avatar || avatarDefault}
              ></Avatar>
            </IconButton>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: "150px",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "#0B3C8A",
                  fontWeight: "600",
                  fontSize: 13,
                  marginTop: "10px",
                }}
              >
                {user?.fullname || ""}
              </span>
              <span
                style={{ color: "#4B4B4B", fontWeight: "600", fontSize: 13 }}
              >
                {`Cấp  ${
                  (user?.extra_info?.user_title_id ||
                    user?.extra_info?.user_title) === 1
                    ? "Admin"
                    : (user?.extra_info?.user_title_id ||
                        user?.extra_info?.user_title) === 2
                    ? "User"
                    : ""
                }`}
              </span>
              {!!isRoot && (
                <span
                  style={{
                    color: "#4B4B4B",
                    fontStyle: "italic",
                    fontSize: 12,
                    marginTop: "5px",
                  }}
                >
                  {version}
                </span>
              )}
            </div>
          </Box>
        </Toolbar>
        <ModalNotification
          {...{
            showModalNotification,
            onClose: onChangeShowModalNotification,
          }}
          key="ModalNotification"
        />
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

const showIconBack = () => {
  const result = { status: false, value: "" };
  const pathName = window.location.pathname;
  for (let i = 0; i < items.length; i++) {
    if (pathName.includes(items[i].href)) {
      result["value"] = items[i].title;
    }
    if (pathName === items[i].href) {
      result["status"] = true;
      return result;
    }
  }
  return result;
};

const items = [
  {
    href: PATH.ADMIN_V2,
  },
  {
    href: PATH.AD_V2_ACCOUNT_MANAGE,
  },
  {
    href: PATH.RECODRS_INDEMNIFY,
  },
  {
    href: PATH.PHUCBINHAN_BUSINESS_REPORT,
  },
  {
    href: PATH.PHUCBINHAN_REVIEW_CLAIM,
  },
  {
    href: PATH.COMPENSATION_REPORTR_INTRANSIT,
  },
  {
    href: PATH.REVIEW_CLAIM_INTRANSIT,
  },
  {
    href: PATH.AD_V2_ACCOUNT_v2,
  },
  {
    href: PATH.AD_V2_PRODUCT_v2,
  },
  {
    href: PATH.AD_V2_DECENTRALIZA,
  },
  {
    href: PATH.AD_V2_GCN_MANAGER,
  },
  {
    href: PATH.AD_V2_MANAGER_BLVP,
  },
];

const cssNavbarRoot = {
  zIndex: 99,
  left: {
    lg: 270,
  },
  width: {
    lg: "calc(100% - 270px)",
  },
};
