import React from "react";
import {
  Page,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import _ from "lodash";

Font.register({
  family: "times-new-roman",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman@1.0.4/Times New Roman.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-bold@1.0.4/Times New Roman Bold.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-italic@1.0.4/Times New Roman Italic.ttf",
      fontStyle: "italic",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-bold-italic@1.0.4/Times New Roman Bold Italic.ttf",
      fontStyle: "bold-italic",
    },
  ],
});

export default function ListImgPDF({ data }) {
  return (
    <Document>
      <Page size="A4">
        {data.map((img) =>
          !!img ? (
            <View
              key={img}
              style={{
                padding: "28px 16px",
                width: "100%",
                margin: "auto",
                maxHeight: "95%",
              }}
            >
              <Image
                src={img || ""}
                style={{
                  width: "100%",
                  maxHeight: "93%",
                }}
              />
            </View>
          ) : (
            <View />
          )
        )}
      </Page>
    </Document>
  );
}
