import React from "react";
import _ from "lodash";
import moment from "moment";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
import { styled } from "@mui/material/styles";

import { styleTabsRight } from "../../styles/styleTabsRight";
import { ReactComponent as FileEyeIcon } from "../../../../../assets/health/file-eye-02.svg";
import { ReactComponent as FilePlus } from "../../../../../assets/health/file-plus.svg";
import { ReactComponent as UserProfile } from "../../../../../assets/health/user-profile-check.svg";
import { ReactComponent as FileCheck } from "../../../../../assets/health/file-check.svg";
import { ReactComponent as BankNote } from "../../../../../assets/health/bank-note.svg";
import { ReactComponent as RefuseIcon } from "../../../../../assets/health/refuse-icon.svg";
import { ReactComponent as Customer } from "../../../../../assets/health/customer.svg";

export default function StatusTabsRight(props) {
  const { state } = props;
  const classes = styleTabsRight();

  const { detail } = state;
  const { elements = [] } = detail;

  const element_amend = [];
  const tempElementAmend =
    elements.filter((i) => i.type_id === 520 && i.status_id == 4)?.reverse() ||
    [];

  tempElementAmend?.forEach((m, index) => {
    const elementsAmend = m?.extra_info?.elements_amend || [];
    element_amend.push(m);
    let checkUdated = [];
    elementsAmend.forEach((el) => {
      const elementBykey =
        elements?.filter((i) => i.type_code === el.typeCode)?.reverse() || [];

      const indexElment = elementBykey.findIndex(
        (i) =>
          moment(i?.created_date).diff(moment(m?.created_date), "minutes") > 0
      );

      if (indexElment < 0) {
        return;
      }

      const temp = elementBykey[indexElment];

      !_.isEmpty(temp) &&
        checkUdated.push({
          type_code: temp.type_code,
          type_id: temp.type_id,
          created_date: temp.created_date,
        });

      return;
    });

    checkUdated.length > 0 &&
      element_amend.push({
        checkUdated: [...checkUdated],
        isDataUpdate: true,
        created_date: checkUdated[0]?.created_date || null,
      });
  });

  const steps = [
    {
      label: "Thanh toán",
      nameIcon: "complete",
      statusIcon: 6,
      // hide: _.isEmpty(element_complete),
      content: (i) => (
        <div style={{ fontSize: "13px", margin: "4px 0" }}>
          <div style={{ margin: "8px 0" }}>
            <div>
              Hồ sơ yêu cầu bồi thường đã được{" "}
              <span style={{ fontWeight: "600" }}>
                {i?.extra_info?.user?.userAssign?.fullname}
              </span>{" "}
              hoàn thành bồi thường{" "}
            </div>
            <div style={{ color: "#A0A0A0", paddingTop: "4px" }}>
              {moment(i?.created_date).format("HH:mm DD/MM/YYYY")}{" "}
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Khách hàng",
      nameIcon: "customer",
      statusIcon: 9,
      // hide: _.isEmpty(element_customer_accept),
      content: (i) => (
        <div style={{ fontSize: "13px", margin: "4px 0" }}>
          <div style={{ margin: "8px 0" }}>
            <div>
              Hồ sơ yêu cầu bồi thường số
              <span style={{ fontWeight: "600" }}>{detail?.code}</span> đã được{" "}
              {"được xác nhận"}
              {/* <span style={{ fontWeight: "600" }}>
                  {i?.extra_info?.user?.userAssign?.fullname}
                </span>{" "} */}
              bởi khách hàng{" "}
            </div>
            <div style={{ color: "#A0A0A0", paddingTop: "4px" }}>
              {moment(i?.created_date).format("HH:mm DD/MM/YYYY")}{" "}
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Khách hàng",
      nameIcon: "customer",
      statusIcon: 8,
      // hide: _.isEmpty(element_customer_refuse),
      content: (i) => (
        <div style={{ fontSize: "13px", margin: "4px 0" }}>
          <div style={{ margin: "8px 0" }}>
            <div>
              Hồ sơ yêu cầu bồi thường số{" "}
              <span style={{ fontWeight: "600" }}>{detail?.code}</span> đã không{" "}
              {"được xác nhận"}{" "}
              {/* <span style={{ fontWeight: "600" }}>
                  {i?.extra_info?.user?.userAssign?.fullname}
                </span>{" "} */}
              bởi khách hàng{" "}
            </div>
            <div style={{ color: "#A0A0A0", paddingTop: "4px" }}>
              {moment(i?.created_date).format("HH:mm DD/MM/YYYY")}{" "}
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Hồ sơ bị từ chối",
      nameIcon: "refuse",
      statusIcon: 10,
      // hide: _.isEmpty(element_refuse),
      content: (i) => (
        <div style={{ fontSize: "13px", margin: "4px 0" }}>
          <div style={{ margin: "8px 0" }}>
            <div>
              Hồ sơ yêu cầu bồi thường số{" "}
              <span style={{ fontWeight: "600" }}>{detail?.code}</span> đã bị từ
              chối bồi thường{" "}
              {/* đã được{" "}
                <span style={{ fontWeight: "600" }}>
                  {i?.extra_info?.user?.userAssign?.fullname}
                </span>{" "}
                từ chối bồi thường{" "} */}
            </div>
            <div style={{ color: "#A0A0A0", paddingTop: "4px" }}>
              {moment(i?.created_date).format("HH:mm DD/MM/YYYY")}{" "}
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Duyệt hồ sơ",
      nameIcon: "approved",
      statusIcon: 5,
      // hide: _.isEmpty(element_approve),
      content: (i) => (
        <div style={{ fontSize: "13px", margin: "4px 0" }}>
          <div style={{ margin: "8px 0" }}>
            <div>
              Hồ sơ bồi thường{" "}
              {/* số{" "}
                <span style={{ fontWeight: "600" }}>{detail?.code}</span>  */}
              đã {i.status_id == 5 ? "được phê duyệt" : "bị từ chối duyệt"} bởi{" "}
              {i?.extra_info?.user?.userAssign?.fullname || ""}
            </div>
            <div style={{ color: "#A0A0A0", paddingTop: "4px" }}>
              {moment(i?.created_date).format("HH:mm DD/MM/YYYY")}{" "}
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Chờ phê duyệt",
      nameIcon: "waiting",
      statusIcon: 3,
      // hide: _.isEmpty(request_approve),
      content: (i) => (
        <div style={{ fontSize: "13px", margin: "4px 0" }}>
          <div style={{ margin: "8px 0" }}>
            <div>
              Hồ sơ yêu cầu bồi thường số{" "}
              <span style={{ fontWeight: "600" }}>{detail?.code}</span> đang chờ{" "}
              {i?.extra_info?.user?.userAssign?.fullname || ""} phê duyệt
            </div>
            <div style={{ color: "#A0A0A0", paddingTop: "4px" }}>
              {moment(i?.created_date).format("HH:mm DD/MM/YYYY")}{" "}
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Bổ sung hồ sơ",
      nameIcon: "additional",
      statusIcon: 4,
      // hide: _.isEmpty(element_amend),
      content: (i) => (
        <div style={{ fontSize: "13px", margin: "4px 0" }}>
          <div key={`${i?.id}_dev`} style={{ margin: "8px 0" }}>
            <>
              {(i?.checkUdated || [])?.length > 0 && (
                <>
                  {" "}
                  <div style={{ paddingTop: "6px" }}>
                    {` ${
                      (i?.checkUdated || [])?.length
                    } chứng từ mới được bổ sung`}{" "}
                  </div>
                  <div style={{ color: "#A0A0A0", paddingTop: "4px" }}>
                    {moment(i?.checkUdated[0]?.created_date).format(
                      "HH:mm DD/MM/YYYY"
                    )}{" "}
                  </div>
                </>
              )}{" "}
              <div>
                {`${
                  i?.extra_info?.user?.userReview?.fullname || ""
                } yêu cầu bổ sung ${
                  (i?.extra_info?.elements_amend || [])?.length
                } chứng từ.`}{" "}
              </div>
              <div style={{ color: "#A0A0A0", paddingTop: "4px" }}>
                {moment(i?.created_date).format("HH:mm DD/MM/YYYY")}{" "}
              </div>
            </>
          </div>
        </div>
      ),
    },
    {
      label: "Đang xử lý",
      nameIcon: "handling",
      statusIcon: 2,
      content: (i) => (
        <div style={{ fontSize: "13px", margin: "12px 0" }}>
          <div>
            Hồ sơ yêu cầu bồi thường đang được xử lý bởi bồi thường viên{" "}
            <span style={{ fontWeight: "600" }}>
              {detail?.userFullnameReview || ""}
            </span>{" "}
          </div>
          <div style={{ color: "#A0A0A0", paddingTop: "4px" }}>
            {detail?.updatedDate
              ? moment(detail?.updatedDate).format("HH:mm DD/MM/YYYY")
              : ""}
          </div>
        </div>
      ),
    },
  ];

  const elementStatus = [
    ...(detail?.elements || [])
      .filter((i) => i.type_id == 520)
      .map((i) => {
        const temp = steps.find((el) => {
          if (i.status_id == 5 && detail?.amount === 0) {
            return el.statusIcon == 10;
          }

          if (i.status_id == 7) {
            return el.statusIcon == 5;
          }

          return el.statusIcon == i.status_id;
        });

        if (i.status_id == 4) {
          const dataImgElementAment = [];
          (i?.extra_info?.elements_amend || []).forEach((amen) => {
            const dataImgAmengUpdate = (detail?.elements || []).find(
              (de) =>
                de.type_id == amen.typeId && (de.text || "").includes(`${i.id}`)
            );

            (dataImgAmengUpdate?.extra_info?.documents || []).length > 0 &&
              dataImgElementAment.push(dataImgAmengUpdate);
          });

          if (dataImgElementAment.length > 0) {
            temp.checkUdated = dataImgElementAment;
          }
        }

        return { ...temp, ...i };
      })
      .sort((a, b) => {
        let sortItem = 0;
        if (a.created_date && b.created_date) {
          sortItem = new Date(b.created_date) - new Date(a.created_date);
        }
        return sortItem;
      }),
    {
      label: "Đang xử lý",
      nameIcon: "handling",
      statusIcon: 2,
      content: (i) => (
        <div style={{ fontSize: "13px", margin: "12px 0" }}>
          <div>
            Hồ sơ yêu cầu bồi thường đang được xử lý bởi bồi thường viên{" "}
            <span style={{ fontWeight: "600" }}>
              {detail?.userFullnameReview || ""}
            </span>{" "}
          </div>
          <div style={{ color: "#A0A0A0", paddingTop: "4px" }}>
            {moment(detail?.createdDate).format("HH:mm DD/MM/YYYY")}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={classes.containerStatus}>
      <Stepper activeStep={0} orientation="vertical" connector={<div />}>
        {elementStatus.map((step, index) => (
          <Step active={true} className={classes.step}>
            <StepLabel
              StepIconComponent={(props) => (
                <Icon
                  {...props}
                  nameIcon={step.nameIcon}
                  statusIcon={step.statusIcon}
                  statusId={detail.statusId}
                  className={classes.iconStatus}
                  last={index == elementStatus.length - 1}
                />
              )}
            >
              <div className={classes.titile}>{step.label}</div>
            </StepLabel>
            <StepContent>
              <div>{(step?.content && step?.content(step)) || null}</div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

const IconRoot = styled("div")(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...ownerState.active,
  ...ownerState.completed,
}));

function Icon(props) {
  const { active, completed, className, statusIcon, statusId, icon } = props;
  const icons = {
    handling: <FileEyeIcon />,
    additional: <FilePlus />,
    waiting: <UserProfile />,
    approved: <FileCheck />,
    complete: <BankNote />,
    refuse: <RefuseIcon />,
    customer: <Customer />,
  };

  const checkStatusDetail = checkStatusId(statusId) == statusIcon && icon == 1;

  return (
    <IconRoot
      ownerState={{ completed, active }}
      className={`${className} ${checkStatusDetail ? "status-detail" : ""}`}
    >
      {icons[props.nameIcon]}
    </IconRoot>
  );
}

const checkStatusId = (statusId) => {
  switch (statusId) {
    case 1:
      return 2;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    case 6:
      return 6;
    case 7:
      return 7;
    case 8:
      return 8;
    case 9:
      return 9;
    case 10:
      return 2;
    default:
      return null;
  }
};
