import api from "../configs/api";

const getListClaim = (data) =>
  api
    .apiForm(
      "POST",
      `/api/v2/cert/partner/ahamove/list?partner=ahamove.claim.admin`,
      data
    )
    .then((res) => res)
    .catch((error) => error);

const getDetailClaim = (data) =>
  api
    .apiForm(
      "POST",
      `/api/v2/cert/partner/ahamove/detail?partner=ahamove.claim.admin`,
      data
    )
    .then((res) => res)
    .catch((error) => error);

const createClaim = (data) =>
  api
    .apiForm("POST", `/api/v2/claim/partner/ahamove`, data)
    .then((res) => res)
    .catch((error) => error);

const getBankList = (data) =>
  api
    .apiForm("POST", `/api/v2/bank/list`, data)
    .then((res) => res)
    .catch((error) => error);

const getListIndemnify = (systemType = "globalcare", data) =>
  api
    .apiForm("POST", `/api/v2/claim/${systemType}/list`, data)
    .then((res) => res)
    .catch((error) => error);

const getDetailIndemnify = (id) =>
  api
    .apiForm("GET", `/api/v2/claim/partner/ahamove/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

const updateDetailIndemnify = (systemType = "globalcare", action, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/${systemType}/add-elements/${action}`, data)
    .then((res) => res)
    .catch((error) => error);

const getHistoryOrder = (data) =>
  api
    .apiForm(
      "POST",
      `/api/v2/claim/partner/ahamove/list?phone=${data?.buyerPhone}&partner=ahamove.claim.admin`,
      data
    )
    .then((res) => res)
    .catch((error) => error);

const uploadImg = (data) =>
  api
    .apiForm("POST", "/api/v2/document/upload/cdn", data)
    .then((res) => res)
    .catch((error) => error);

const getFileByUrl = (url) =>
  api
    .apiGetFileByUrl(url)
    .then((res) => res.data)
    .catch((error) => error);

const uploadImage = (data) => {
  return api.apiForm(
    "POST",
    "/api/v2/document/partner/ahamove/upload/cdn",
    data
  );
};

const uploadBase64 = (data) => {
  return api.apiForm(
    "POST",
    "/api/v2/document/partner/ahamove/upload/cdn/image-base64",
    data
  );
};

export {
  getListClaim,
  getDetailClaim,
  createClaim,
  getBankList,
  getListIndemnify,
  getDetailIndemnify,
  updateDetailIndemnify,
  getHistoryOrder,
  uploadImg,
  getFileByUrl,
  uploadImage,
  uploadBase64,
};
