import React, { useEffect, useState } from "react";
import { Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Document, Page, pdfjs } from "react-pdf";
import { Modal } from "antd";
import { StaticDatePicker } from "@mui/lab";

import wait from "../../../../../../assets/ahamove/wait.png";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Certificate(props) {
  const { onClose, open, link } = props;
  const classes = makeStyle();
  const [state, setState] = useState({
    numPages: "" || 1,
    pageShow: 1,
    width: 0,
    link: "",
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setState({ ...state, loading: true });
    if (numPages > 1) {
      setState({ ...state, numPages });
    }
  };

  useEffect(() => {
    if (open) {
      setState({ ...StaticDatePicker, link: link });
    }
  }, [open]);

  return (
    <Modal
      key={open ? "pdf_document_open" : "pdf_document_closed"}
      open={open}
      footer={null}
      onCancel={() => {
        setState({ ...StaticDatePicker, link: "" });
        onClose();
      }}
      className={classes.content}
    >
      <div style={{}} id="pdfBox">
        <Document
          file={state.link}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          loading={<Loading />}
          // renderMode={"canvas"}
          // rotate={90}
        >
          <div
            style={{
              height: "65vh",
              width: window?.document?.getElementById("pdfBox")?.offsetWidth,
              overflow: "scroll",
            }}
          >
            {Array.from(new Array(state.numPages), (el, index) => (
              <Page
                width={window?.document?.getElementById("pdfBox")?.offsetWidth}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </div>
        </Document>
      </div>
    </Modal>
  );
}

const makeStyle = makeStyles(() => ({
  content: {
    "& .ant-modal-content": {
      "& .ant-modal-close": {
        top: "6px",
        right: "8px",

        "& .ant-modal-close-x": {
          width: "24px",
          height: "24px",
          lineHeight: "32px",
        },
      },

      "& .ant-modal-body": {
        padding: "36px 0 24px 0",
      },
    },
  },

  divContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .text": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",
      marginTop: "12px",

      "& .title": {
        fontSize: "15px",
      },

      "& .action": {
        fontSize: "13px",
        color: "#FB7800",
        fontStyle: "italic",
      },
    },
  },
}));

const Loading = () => {
  const classes = makeStyle();
  return (
    <div className={classes.divContent}>
      <img src={wait} alt="wait" width={"40%"} />
      <div className="text">
        <span className="title">Vui lòng đợi giấy chứng nhận được tải về</span>
        <span className="action">Quá trình này sẽ mất khoảng vài phút</span>
      </div>
    </div>
  );
};
