import React, { useEffect, useRef } from "react";
import {
  Input,
  FormControlLabel,
  Autocomplete,
  TextField,
  Checkbox,
  Box,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SignatureCanvas from "react-signature-canvas";

import HeadSteper from "../components/step/headSteper";
import { styledStep5 } from "../components/step/styledStep5";
import _ from "lodash";

export default function Step5(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledStep5();
  const resSignature = useRef();
  const {
    banks,
    extraInfo,
    bank,
    errors,
    chekedCommit,
    loadingSignature = false,
    elements,
  } = state;
  const width = window.innerWidth > 600 ? 600 : window.innerWidth;
  const itemSignature = elements.find((i) => i.typeId === 204) || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        minHeight: isApp ? "calc(100vh - 165px)" : "calc(100vh - 125px)",
      }}
    >
      <HeadSteper activeStep={2} />
      <div className={classes.boxContainer}>
        <div className={classes.titleHead}>THÔNG TIN NGƯỜI THỤ HƯỞNG</div>
        {texBox.map((item, index) =>
          !item.autocomplete ? (
            <>
              <div className={classes.inputDiv} key={index}>
                <Input
                  key={item.name}
                  placeholder={item.placeholder}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input": {
                      textTransform:
                        item.capitalize && extraInfo[item.name]
                          ? "capitalize"
                          : "",
                    },
                  }}
                  value={extraInfo[item.name] || ""}
                  onChange={(e) =>
                    dispatch({
                      type: "onChangeExtraInfo",
                      name: item.name,
                      value: e.target.value,
                      key: e?.nativeEvent?.data,
                    })
                  }
                />
                <div className="shrink" key={item.label}>
                  {item.label} <span style={{ color: "red" }}>*</span>
                </div>
              </div>
              {!!item.errorText && !!errors[item.errorText] && (
                <div className={classes.divErr}>{errors[item.errorText]}</div>
              )}
            </>
          ) : (
            <div className={classes.inputDiv} key={index}>
              <Autocomplete
                disablePortal
                key={index}
                id="combo-box-demo"
                options={banks}
                value={bank || {}}
                sx={{ width: "100%" }}
                getOptionLabel={(option) => option?.short_name || ""}
                noOptionsText={"Không tìm thấy dữ liệu"}
                renderOption={(props, option) => (
                  <Box
                    style={{ paddingLeft: "6px" }}
                    component="li"
                    sx={{
                      "& > img": { mr: 2, flexShrink: 0 },
                      paddingLeft: "6px",
                    }}
                    {...props}
                  >
                    <div className={classes.bankImg}>
                      <img
                        loading="lazy"
                        style={{ width: "100%", height: "100%" }}
                        src={option.url}
                        alt={option.short_name}
                      />
                    </div>
                    <div className={classes.bankOption}>
                      <span className="short-name">{option.short_name}</span>
                      <span className="name">{option.name}</span>
                    </div>
                  </Box>
                )}
                onChange={(e, data) =>
                  dispatch({
                    type: "onChangeExtraInfo",
                    name: "bank",
                    value: data,
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    className={classes.textAuto}
                    placeholder={item.placeholder}
                  />
                )}
              />
              <div className="shrink" key={item.label}>
                {item.label} <span style={{ color: "red" }}>*</span>
              </div>
            </div>
          )
        )}
      </div>
      <div className={classes.boxContainer}>
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
              checked={chekedCommit}
              onChange={(e) =>
                dispatch({ type: "checkCommit", value: e.target.checked })
              }
            />
          }
          label={
            <span style={{ color: "#284078", fontSize: "17px" }}>
              Tôi cam kết các thông tin trong yêu cầu bồi thường này là đúng sự
              thật.
            </span>
          }
        />

        <div className={classes.yourSignature}>
          <span className="signature">
            Chữ ký của bạn <spsan style={{ color: "red" }}>*</spsan>
          </span>
          <span
            className="agan"
            onClick={() => {
              dispatch({ type: "clearSignature", ref: resSignature });
            }}
          >
            Ký lại
          </span>
        </div>
        <div className={classes.signature}>
          {itemSignature?.documents?.length > 0 ? (
            <img
              alt="signature"
              src={_.get(itemSignature, "documents[0].path")}
              style={{ objectFit: "contain", maxWidth: "90%" }}
            />
          ) : (
            <div style={{ position: "relative", width: "100%" }}>
              <Backdrop
                style={{
                  zIndex: 99,
                  color: "#fff",
                  position: "absolute",
                  borderRadius: "5px",
                }}
                open={loadingSignature}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <SignatureCanvas
                ref={resSignature}
                penColor="black"
                canvasProps={{ height: 168, width: width - 32 }}
                onEnd={_.debounce(
                  () =>
                    dispatch({
                      type: "handlSignature",
                      ref: resSignature,
                    }),
                  1500
                )}
              />
            </div>
          )}
        </div>
        {errors?.signature && (
          <Box sx={{ marginTop: "12px", color: "red" }}>
            <Typography>Vui lòng ký vào ô trên</Typography>
          </Box>
        )}
      </div>
    </div>
  );
}

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#F5FAF7",
  backgroundImage: "#F5FAF7",
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#E16C2B",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#E16C2B",
  },
});

const texBox = [
  {
    label: "Họ và tên",
    placeholder: "Nhập họ và tên",
    name: "beneficiaryName",
    capitalize: true,
  },
  {
    label: "Số điện thoại",
    placeholder: "Nhập số điện thoại",
    name: "beneficiaryPhone",
    errorText: "phoneType",
  },
  {
    label: "Ngân hàng",
    placeholder: "Nhập tên ngân hàng",
    name: "bank",
    autocomplete: true,
  },
  {
    label: "Số tài khoản",
    placeholder: "Nhập số tài khoản ngân hàng",
    name: "beneficiaryBankAcountNumber",
  },
  {
    label: "Email",
    placeholder: "Nhập email",
    name: "beneficiaryEmail",
    errorText: "emailType",
  },
];
