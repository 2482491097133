import { useEffect, useState } from "react";
import { Box, Typography, Container, Grid, Button } from "@mui/material";
import { isValid, format } from "date-fns";
import moment from "moment";
import { Link } from "react-router-dom";

import parseCurrency from "../../../../../utils/parseCurrency";
import IconTime from "../../../../../assets/icons/ic_time.png";
import { SeverityPill } from "../severity-pill";

export const Detail = (props) => {
  const [listImage, setListImage] = useState([]);
  const [history, setHistory] = useState([]);
  const [listTotalRepair, setListTotalRepair] = useState([]);
  const { details, historyLists } = props;

  // useEffect(() => {
  //   if(details) {
  //     let newData = details?.elements?.filter(item => {
  //       return item.typeId === 2
  //     })
  //     let newTotalRepair = details?.elements?.filter(item => {
  //       return item.typeId === 4
  //     })
  //     let newHistory = details?.elements?.filter(item => {
  //       return item.typeId === 10
  //     })

  //     setHistory(sortDateTime(newHistory))
  //     setListTotalRepair(sortDateTime(newTotalRepair))
  //     setListImage(newData[0]?.documents)
  //   }
  // }, [])

  const sortDateTime = (data) => {
    data.sort((a, b) => {
      return new Date(a.createdDate) - new Date(b.createdDate);
    });
    return data;
  };

  const boxItem = [
    {
      listTitle: "Thông tin hợp đồng",
      listItem: [
        {
          label: "Phí bảo hiểm",
          value: parseCurrency(parseInt(details?.insuredInfo?.price)),
        },
        {
          label: "Ngày hiệu lực",
          value: moment(details?.insuredInfo?.beginDate).format(
            "hh:mm:ss; DD/MM/YYYY"
          ),
        },
        {
          label: "Ngày hết hạn",
          value: moment(details?.insuredInfo?.endDate).format(
            "hh:mm:ss; DD/MM/YYYY"
          ),
        },
        {
          label: "Họ tên",
          value: details?.insuredInfo?.insuredName,
        },
        {
          label: "Ngày sinh",
          value: details?.insuredInfo?.insuredDob,
        },
        {
          label: "CMND/CCCD/Hộ chiếu",
          value: details?.insuredInfo?.insuredPrivateId,
        },
        {
          label: "Biển số xe",
          value: details?.insuredInfo?.licenseNumber?.toString(),
        },
        {
          label: "Năm sản xuất",
          value: details?.insuredInfo?.yearOfManufacture?.toString(),
        },
        {
          label: "Hiệu xe",
          value: details?.insuredInfo?.carTitle,
        },
        {
          label: "Số chỗ ngồi",
          value: details?.insuredInfo?.seats?.toString(),
        },
        {
          label: "Số điện thoại",
          value: details?.insuredInfo?.insuredPhone,
        },
        {
          label: "Email",
          value: details?.insuredInfo?.insuredEmail,
        },
        {
          label: "Địa chỉ",
          value: details?.insuredInfo?.insuredAddress,
        },
      ],
    },
  ];

  const boxItemI = [
    {
      listTitle: "Lịch sử duyệt hồ sơ",
      listItem: historyLists,
      code: details?.code,
      licenseNumber: details?.insuredInfo?.licenseNumber,
      departmentName: details?.departmentName,
      status: details?.statusId,
    },
  ];

  const checkStatusCertNum = () => {
    if (moment().isBefore(moment(details?.insuredInfo?.beginDate))) return null;
    return moment().isBetween(
      moment(details?.insuredInfo?.beginDate),
      moment(details?.insuredInfo?.endDate)
    );
  };

  return (
    <Container maxWidth={false}>
      <Box {...props}>
        <Typography fontWeight="fontWeightBold" variant="h5" mb={1}>
          {details?.insuredInfo?.insuredName} - mã hợp đồng bảo hiểm:{" "}
          {details?.insuredInfo?.certNum}
        </Typography>
        {checkStatusCertNum() != null && (
          <SeverityPill color={status[checkStatusCertNum()].value}>
            {status[checkStatusCertNum()].label}
          </SeverityPill>
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box
            my={3}
            p={3}
            sx={{
              maxWidth: "600px",
              background: "#fff",
              borderRadius: "12px",
            }}
          >
            {boxItem.map((v, i) => (
              <Box mb={3} key={i}>
                <Typography mb={1} variant="h6" style={styleTitle} color="#fff">
                  {v.listTitle}
                </Typography>
                {v.listItem.map((item, i) => (
                  <ListItemCustom item={item} i={i} />
                ))}
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            my={3}
            p={3}
            sx={{
              maxWidth: "600px",
              background: "#fff",
              borderRadius: "12px",
            }}
          >
            {boxItemI.map((v, i) => (
              <Box mb={3} key={i}>
                <Typography mb={1} variant="h6" style={styleTitle} color="#fff">
                  {v.listTitle}
                </Typography>

                <Typography mb={1} variant="h6" style={styleRightTitle}>
                  <img alt="example" style={styleIconSuccess} src={IconTime} />
                  Ngày tạo hồ sơ:{" "}
                  {moment(history[0]?.createdDate).format("DD/MM/YYYY")}
                </Typography>
                {v.listItem.map((item, i) => (
                  <ListItemClaim
                    item={item}
                    // listTotalRepair={listTotalRepair}
                    i={i}
                    code={v.code}
                    licenseNumber={v.licenseNumber}
                    departmentName={v.departmentName}
                    userFullnameAssign={details.userFullnameAssign}
                    status={v.status}
                  />
                ))}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid item>
          <a
            style={{
              background: "#497CFF",
              color: "#FFF",
              marginTop: 30,
              padding: "15px 20px",
              borderRadius: 5,
              display: "flex",
              alignContent: "center",
            }}
            href={details?.insuredInfo?.certLink}
            target="_blank"
            download
          >
            <span>
              <svg
                width="27"
                height="24"
                viewBox="0 0 27 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.6348 15.43V3.38"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.9084 12.5L13.6234 15.42L10.3384 12.49"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.8492 8.11997H19.8954C22.1848 8.11997 24.0354 9.75997 24.0354 11.8V16.68C24.0354 18.71 22.1792 20.35 19.8954 20.35H7.3573C5.0623 20.35 3.20605 18.7 3.20605 16.66V11.775C3.20605 9.74497 5.0578 8.09497 7.34043 8.09497H8.39793"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            &nbsp; Tải giấy chứng nhận
          </a>
        </Grid>
      </Grid>
    </Container>
  );
};

const ListItemCustom = ({ item, i }) => (
  <>
    <Box
      hidden={!item?.value}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      key={i}
    >
      <Typography color="#024281" fontWeight={600}>
        {item.label}
      </Typography>
      <Typography mb={1} textAlign="right">
        {isValid(item.value)
          ? format(item.value, "HH:mm:ss dd-MM-yyyy")
          : item.value}
      </Typography>
    </Box>
    {item.content && (
      <Typography style={{ padding: "5px 10px 0 10px" }}>
        {item.content}
      </Typography>
    )}
  </>
);

const ListItemClaim = ({
  item,
  listTotalRepair,
  i,
  code,
  licenseNumber,
  departmentName,
  userFullnameAssign,
  status,
}) => {
  return (
    <div
      style={{
        background: i % 2 ? "#FFFFFF" : "#F5F5F5",
        margin: "-22px -24px 20px -24px",
        padding: 24,
      }}
      key={i}
    >
      <div style={styleCountClaim}>Lần {i + 1}</div>
      <div style={styleRow}>
      <Typography fontWeight={600} fontSize={17}>
        Mã hồ sơ bảo hiểm:
      </Typography>
      <Typography mb={1} textAlign='right'>{item?.code}</Typography>
      </div>
      <div style={styleRow}>
        <Typography fontWeight={600} fontSize={17}>Biển số:  </Typography>
        <Typography mb={1} textAlign='right'>{licenseNumber}</Typography>
      </div>
      <div style={styleRow}>
        <Typography fontWeight={600} fontSize={17}>Gara kiểm tra:  </Typography>
        <Typography mb={1} textAlign='right'>{item?.departmentName}</Typography>
      </div>
      <div style={styleRow}>
        <Typography fontWeight={600} fontSize={17}>Bồi thường viên:  </Typography>
        <Typography mb={1} textAlign='right'>{item?.userFullnameAssign}</Typography>
      </div>
      <div style={styleRow}>
        <Typography fontWeight={600} fontSize={17}>Trạng thái bồi thường:  </Typography>
        <Typography mb={1} textAlign='right'>
         {item?.statusId !== 0 && (
          <SeverityPill color={contants_status[item?.statusId]?.value}>
            {contants_status[item?.statusId].label}
          </SeverityPill>)
          }
        </Typography>
      </div>
      <div style={styleRow}>
        <Typography fontWeight={600} fontSize={17}>Ngày xử lý hồ sơ:  </Typography>
        <Typography mb={1} textAlign='right'>
        {moment(item?.createdDate).format('HH:mm:ss ; DD/MM/YYYY')}
        </Typography>
      </div>
      <div style={styleRow}>
        <Typography fontWeight={600} fontSize={17}>Tổng chi phí sửa chữa:  </Typography>
      <Typography mb={1} textAlign='right'>
        {parseCurrency(
          parseInt(
            item?.elements?.filter((item) => item.type_id === 4)[0]?.value
          ) || null
        )}
      </Typography>
      </div>
      <div style={styleRow}>
        <Typography fontWeight={600} fontSize={17}>Phí phê duyệt bồi thường:  </Typography>
        <Typography mb={1} textAlign='right'>
        {parseCurrency(
          parseInt(
            item?.elements?.filter(
              (item) => item.type_id === 10 && item.status_id == 5
            )[0]?.value
          ) || null
        )}
      </Typography>
      </div>
    </div>
  );
};

const ListItemImages = ({ item, index }) => {
  return (
    <Box component="a" href={item?.path} target="_blank" mx={1} key={index}>
      <Box sx={cssListItemImages}>
        <img
          alt="listItemImages"
          src={item?.path}
          style={{
            width: 60,
            height: 60,
          }}
        />
      </Box>
    </Box>
  );
};

const contants_status = [
  { value: "", label: "" },
  { value: "new", label: "Chưa được xử lý" },
  { value: "inprocess", label: "Đang xử lý" },
  { value: "waiting_approve", label: "Chờ phê duyệt" },
  { value: "waiting_update", label: "Bổ sung hồ sơ" },
  { value: "approved", label: "Đã phê duyệt" },
  { value: "completed", label: "Hoàn thành" },
  { value: "rejected", label: "Từ chối" },
];

const status = {
  true: { value: "completed", label: "Hiệu lực" },
  false: { value: "rejected", label: "Hết hạn" },
};

const cssListItemImages = {
  background: "#F8F8F8",
  border: "0.5px dashed rgba(0, 0, 0, 0.19)",
  borderRadius: "13px",
  height: 68,
  width: 68,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

const styleTitle = {
  background: "#E16B2F",
  margin: "-22px -24px 20px -24px",
  padding: "14px 28px 14px 28px",
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
};

const styleIconSuccess = {
  width: 24,
  height: 24,
  margin: "5px 10px 10px 0px",
};

const styleRightTitle = {
  fontSize: 20,
  justifyContent: "center",
  marginBottom: 30,
};

const styleCountClaim = {
  display: "flex",
  width: 100,
  height: 40,
  background: "#E16B2F",
  borderTopRightRadius: 20,
  borderBottomRightRadius: 20,
  margin: "-24px 0px 16px -24px",
  justifyContent: "center",
  fontSize: 17,
  alignItems: "center",
  color: "#FFF",
};

const styleRow = {
  display: "flex",
  justifyContent: "space-between",
}