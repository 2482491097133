import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spinner, Modal } from 'antd';

const Loading = () => {
  return (
    <Modal
      visible={true}
      footer={null}
      closable={false}
      style={{
        alignItems: 'center',
        display: 'flex',
        position: 'fixed',
        zIndex: 999,
        inset: '0px',
        padding: '20px',
        justifyContent: 'center',
      }}
      bodyStyle={{
        padding: 0,
        background: 'rgba(0, 0, 0, 0.9)',
      }}
    >
      <LoadingOutlined style={{
        fontSize: 40,
        position: 'absolute',
        margin: 'auto'
      }}/>
    </Modal>
  );
};

export default Loading
