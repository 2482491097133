import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { _formatMoney, formatDate } from "../utils";

import signature from "../../../../../assets/loyalty/sign.png";

Font.register({
  family: "times-new-roman",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman@1.0.4/Times New Roman.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-bold@1.0.4/Times New Roman Bold.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-italic@1.0.4/Times New Roman Italic.ttf",
      fontStyle: "italic",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-bold-italic@1.0.4/Times New Roman Bold Italic.ttf",
      fontStyle: "bold-italic",
    },
  ],
});

export default function ExportRequestClaimPDF({ state }) {
  const { claimInfo, destination, extraInfo } = state;
  const isHangKhong = claimInfo?.providerId == 10;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section_report}>
          <View style={styles.header}>
            <Text>GIẤY YÊU CẦU BỒI THƯỜNG</Text>
            <Text style={{ fontStyle: "italic", fontWeight: 600 }}>
              CLAIM LETTER
            </Text>
            <Text>*******************</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <View style={styles.col}>
              <View style={styles.row}>
                <Text style={{ textDecoration: "underline" }}>Kính gửi:</Text>
                <Text style={{ fontWeight: 600, marginLeft: 10 }}>
                  {isHangKhong
                    ? "CÔNG TY BẢO HIỂM HÀNG KHÔNG"
                    : "CÔNG TY BẢO MINH BẾN THÀNH"}
                </Text>
              </View>
              <Text style={styles.italic}>To</Text>
            </View>
          </View>
          <View style={styles.viewCol}>
            <Text>
              {isHangKhong
                ? "Chúng tôi có mua Bảo hiểm tại Hàng Không với các chi tiết như sau: "
                : "Chúng tôi có mua Bảo hiểm tại Bảo Minh với các chi tiết như sau: "}
            </Text>
            <Text style={styles.italic}>
              {isHangKhong
                ? "We have to buy insurance in HangKhong with details as follows: "
                : "We have to buy insurance in BaoMinh with details as follows: "}
            </Text>
          </View>

          <View style={styles.viewCol}>
            <Text>
              Hợp đồng bảo hiểm số:{" "}
              <Text style={{ fontWeight: 600 }}>{claimInfo?.certNum}</Text>
            </Text>
            <Text style={styles.italic}>Policy No.: {claimInfo?.certNum}</Text>
          </View>

          <View style={styles.viewCol}>
            <Text>
              Tổng số tiền Bảo hiểm:{" "}
              <Text style={{ fontWeight: 600 }}>
                {" "}
                {_formatMoney(
                  extraInfo?.productAmount || destination?.data?.productAmount
                )}{" "}
                VNĐ
              </Text>
            </Text>
            <Text style={styles.italic}>
              Sum Insured:{" "}
              {_formatMoney(
                extraInfo?.productAmount || destination?.data?.productAmount
              )}{" "}
              VNĐ
            </Text>
          </View>

          <View style={styles.viewCol}>
            <Text>
              Thời hạn Bảo hiểm từ ngày {formatDate(claimInfo?.createdDate)} đến
              ngày: {formatDate(claimInfo?.endDate)}
            </Text>
            <Text style={styles.italic}>
              Insurance Period Form To: {formatDate(claimInfo?.createdDate)} -{" "}
              {formatDate(claimInfo?.endDate)}
            </Text>
          </View>

          <View style={styles.viewCol}>
            <Text>
              Tài sản trên đã bị tổn thất vào hồi:{" "}
              {extraInfo?.riskTime ||
                moment(claimInfo?.endDate).format("HH:mm")}
              , ngày{" "}
              {moment(extraInfo?.riskDate?.toISOString()).format(
                "DD/MM/YYYY"
              ) || formatDate(claimInfo?.endDate)}{" "}
              do: {extraInfo?.describe}
            </Text>
            <Text style={styles.italic}>
              Date & Time of Loss:{" "}
              {moment(extraInfo?.riskDate?.toISOString()).format(
                "DD/MM/YYYY"
              ) || formatDate(claimInfo?.endDate)}
            </Text>
            <Text style={styles.italic}>
              Cause of Loss: {extraInfo?.describe}
            </Text>
          </View>

          <View style={styles.viewCol}>
            <Text>
              Rủi ro:
              <Text style={{ fontWeight: 600 }}> </Text>
            </Text>
            <Text style={styles.italic}>Risk: </Text>
          </View>

          <View style={styles.viewCol}>
            <Text>Chi phí khác:</Text>
            <Text style={styles.italic}>Other costs:</Text>
          </View>

          <View style={styles.viewCol}>
            <Text>
              Tính thành tiền:
              <Text style={{ fontWeight: 600 }}>
                {" "}
                {_formatMoney(
                  extraInfo?.productAmount || destination?.data?.productAmount
                )}{" "}
                VNĐ
              </Text>
            </Text>
            <Text style={styles.italic}>
              The total number of money:{" "}
              {_formatMoney(
                extraInfo?.productAmount || destination?.data?.productAmount
              )}{" "}
              VNĐ
            </Text>
          </View>

          <View style={styles.viewCol}>
            <Text>
              Chúng tôi gửi kèm theo đây toàn bộ hồ sơ đòi bồi thường. Đề nghị
              qúy Tổng Công ty chuyển số tiền bồi thường vào tài khoản số:
              <Text style={{ fontWeight: 600 }}> 19131637055028</Text>
            </Text>
            <Text>
              Tại Ngân hàng:{" "}
              <Text style={{ fontWeight: 600 }}>
                Ngân hàng Techcombank - CN Thắng Lợi
              </Text>
            </Text>
            <Text style={styles.italic}>
              {isHangKhong
                ? "We enclose here the whole compensation records. Suggest HangKhong Insurance Corporation transferred the amount of compensation on account of: 19131637055028"
                : "We enclose here the whole compensation records. Suggest BaoMinh Insurance Corporation transferred the amount of compensation on account of: 19131637055028"}
            </Text>
            <Text style={styles.italic}>At the Bank: Techcombank</Text>
          </View>

          <View style={styles.viewCol}>
            <Text>Trân trọng kính chào.</Text>
            <Text style={styles.italic}>Sincerely</Text>
          </View>

          <View style={styles.section_sign}>
            <View style={styles.sign}>
              <Text>
                {`Tp.HCM, ngày ${moment().format("D")} tháng ${moment().format(
                  "M"
                )} năm ${moment().format("YYYY")}`}
              </Text>
              <Text style={{ fontStyle: "italic" }}>
                Date: {moment().format("DD/MM/YYYY")}
              </Text>
              <Image
                src={signature || ""}
                style={{
                  width: "100%",
                  marginTop: 10,
                }}
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontFamily: "times-new-roman",
    fontSize: 11,
  },
  section_report: {
    margin: 30,
    lineHeight: 1.4,
  },
  header: {
    margin: "5px 0 10px",
    fontWeight: 600,
    fontSize: 14,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  viewCol: {
    display: "flex",
    flexDirection: "column",
    marginTop: 8,
  },
  italic: {
    fontStyle: "italic",
  },
  section_sign: {
    display: "flex",
    fontSize: "11px",
    justifyContent: "end",
    textAlign: "right",
    position: "relative",
  },

  sign: {
    display: "flex",
    flexDirection: "column",
    fontSize: "11px",
    justifyContent: "center",
    textAlign: "center",
    position: "absolute",
    width: "45%",
    right: "10px",
  },
});
