import { makeStyles } from "@mui/styles";

export const styledLicense = makeStyles(() => ({
  container: {
    background: "#F8F8F8",
    padding: "16px",
    "& .title-head": {
      color: "#686868",
    },
  },

  title: {
    fontSize: "18px",
    fontWeight: "700",
    color: "black",
    margin: "12px 0",
  },

  disabledTextField: {
    "& .MuiInputBase-input": {
      background: "#F8F8F8 !important",
    },
  },

  linkInvoice: {
    "& .MuiInputBase-multiline": { padding: "0 !important" },

    "& .MuiInputBase-input": {
      color: "#0B7CE4",
      textDecoration: "underline",
    },
  },

  textFields: {
    "& .MuiInputBase-multiline": { padding: "0 !important" },
  },

  //
}));
