import React, { useEffect } from "react";
import {} from "@mui/material";

import { styledShowLicense } from "../components/styleds/styledShowLicense";
import img from "../../../../../assets/health/img-icon.svg";
import imgDisabel from "../../../../../assets/health/img-icon-disabel.svg";
import { DialogShowImg, AccordionsSteper } from "../components";

export default function ShowLicense(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledShowLicense();
  const { listLicese, nameShowImgPage, arrayLinks } = state;
  const newList =
    nameShowImgPage === "img" ? listLicese : arrayLinks[0]?.documents || [];

  return (
    <div
      className={classes.container}
      style={{
        minHeight: isApp ? "calc(100vh - 95px)" : "calc(100vh - 60px)",
      }}
    >
      {nameShowImgPage === "img" && (
        <div className={classes.title}>Chứng từ</div>
      )}
      <div className={classes.boxListImg}>
        {newList.map((item, index) =>
          nameShowImgPage === "img" ? (
            item?.licese?.length > 1 ? (
              <div
                style={{
                  borderBottom:
                    (newList || []).length - 1 === index
                      ? ""
                      : "1px solid #D6DEEB",
                }}
              >
                <AccordionsSteper
                  dispatch={dispatch}
                  data={item}
                  expanded={!!state[`img${item.typeId}`]}
                  onClick={() =>
                    dispatch({ type: "expandedImg", name: `img${item.typeId}` })
                  }
                />
              </div>
            ) : (
              <div
                className={classes.proveRelationship}
                key={`div_show_licenes_true_${index}`}
                style={{
                  borderBottom:
                    (newList || []).length - 1 === index
                      ? ""
                      : "1px solid #D6DEEB",
                }}
                onClick={() => dispatch({ type: "openDaShowImg", item })}
              >
                <div style={{ maxWidth: "85%" }}>{item.title}</div>
                <img src={(item?.licese || []).length > 0 ? img : imgDisabel} />
              </div>
            )
          ) : (
            <div
              className={classes.proveRelationship}
              key={`div_show_licenes_false_${index}`}
              style={{
                borderBottom:
                  newList.length - 1 === index ? "" : "1px solid #D6DEEB",
              }}
              onClick={() => {
                dispatch({ type: "openPageShowLink", index });
              }}
            >
              <div
                style={{
                  color: "#0B3C8A",
                  fontStyle: "italic",
                  wordWrap: "anywhere",
                }}
              >
                {item}
              </div>
            </div>
          )
        )}
      </div>
      <DialogShowImg {...{ dispatch, state }} />
    </div>
  );
}
