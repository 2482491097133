import React, { useRef } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import _ from "lodash";

import PVI from "../assets/PVI.jpg";

Font.register({
  family: "times-new-roman",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman@1.0.4/Times New Roman.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-bold@1.0.4/Times New Roman Bold.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-italic@1.0.4/Times New Roman Italic.ttf",
      fontStyle: "italic",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-bold-italic@1.0.4/Times New Roman Bold Italic.ttf",
      fontStyle: "bold-italic",
    },
  ],
});

export default function ExportPDF_PBA({ data = {} }) {
  const tempSignature = data?.elements?.find((i) => i.type_id === 318)
    ?.extra_info?.documents;

  const signature = Array.isArray(tempSignature)
    ? tempSignature[0]
    : _.get(
        data?.elements?.find((i) => i.type_id === 318),
        "documents[0]"
      ) || {};

  const line1 = [
    {
      title: "Người yêu cầu trả tiền bảo hiểm: ",
      value: data?.insuredInfo?.beneficiaryName,
      capitalize: true,
    },
    {
      title: "Mối quan hệ với Người được bảo hiểm: ",
      value: data?.insuredInfo?.relationshipWithBuyerName,
    },
    {
      title: "Địa chỉ: ",
      value: "..................",
    },
  ];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ ...styles.view1 }}>
          <Image source={PVI} style={styles.image} />
          <View style={{ textAlign: "center" }}>
            <Text style={styles.view1T1}>GIẤY YÊU CẦU TRẢ TIỀN BẢO HIỂM</Text>
            <Text style={styles.view1T2}>
              {"( Vui lòng điền đầy đủ thông tin theo các mục dưới đây"}
            </Text>
            <Text style={{ fontStyle: "italic", fontSize: "10px" }}>
              {"và gửi lại cho Bảo hiểm PVI ngay khi kết thúc việc điều trị )"}
            </Text>
          </View>
        </View>
        <View style={styles.view2}>
          <Text style={styles.view2T1}>
            A. THÔNG TIN VỀ NGƯỜI YÊU CẦU TRẢ TIỀN BẢO HIỂM
          </Text>
          <View>
            {line1.map((v, i) => (
              <Text
                key={v.title}
                style={{
                  marginBottom: "3px",
                  textTransform: v.capitalize ? "capitalize" : "",
                }}
              >
                {v.title} <Text key={i}>{v.value}</Text>
              </Text>
            ))}
            <View style={styles.view2V1}>
              <Text style={styles.view2T3}>
                {"Số điện thoại: "} <Text>{" ..................."}</Text>
              </Text>
              <Text style={styles.view2T4}>
                {"Email: "} <Text>{" ..................."}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text style={styles.view3T1}>
            {"B. THÔNG TIN VỀ NGƯỜI ĐƯỢC BẢO HIỂM (NĐBH)"}
          </Text>
          <View>
            <View style={styles.vLine}>
              <Text style={styles.vLine1T1}>
                {"Họ tên NĐBH: "}{" "}
                <Text style={{ textTransform: "capitalize" }}>
                  {data?.insuredInfo?.insuredName}
                </Text>
              </Text>
              <Text>{"Giới tính: "}</Text>
              <Text style={styles.boder}>{""}</Text>
              <Text style={{ marginRight: "6px" }}>{"Nam"}</Text>
              <Text style={styles.boder}>{""}</Text>
              <Text>{"Nữ"}</Text>
            </View>
            <View style={{ ...styles.vLine, marginTop: "3px" }}>
              <Text style={styles.vLine2T1}>
                {"Số CMND/Hộ chiếu: "}{" "}
                <Text>
                  {data?.insuredInfo?.insuredPrivateId ||
                    " ..................."}
                </Text>
              </Text>
              <Text style={{ width: "49%" }}>
                {"Ngày sinh: "} <Text>{" ..................."}</Text>
              </Text>
            </View>
            <Text style={{ width: "100%", marginBottom: "3px" }}>
              {"Đơn vị tham gia bảo hiểm: "}{" "}
              <Text>{" ..................."}</Text>
            </Text>
            <Text style={{ width: "100%" }}>
              {"Số GCNBH/Số thẻ BH: "} <Text>{data?.certNum}</Text>
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.view3T1}>
            {"C. THÔNG TIN VỀ TAI NẠN / BỆNH VÀ KHÁM CHỮA"}
          </Text>
          <View>
            <View style={styles.vLine}>
              <Text style={styles.vLine2T1}>
                {"Ngày tai nạn: "}
                <Text>{data?.extraInfo?.dateOfAccident || ""}</Text>
              </Text>
              <Text style={{ width: "49%" }}>
                {"Nơi xảy ra tai nạn: "}{" "}
                <Text>{data?.extraInfo?.positionAccident || ""}</Text>
              </Text>
            </View>
            <View style={styles.vLine}>
              <Text style={styles.vLine2T1}>
                {"Ngày khám bệnh: "}{" "}
                <Text>{data?.extraInfo?.dayOfTreatment}</Text>
              </Text>
              <Text style={{ width: "49%" }}>
                {"Ngày nhập viện: "}{" "}
                <Text>
                  {data?.extraInfo?.dateOfHospital ||
                    data?.extraInfo?.dayOfTreatment ||
                    ""}
                </Text>
              </Text>
            </View>
            <Text style={{ width: "100%", marginBottom: "3px" }}>
              {"Nơi điều trị: "} <Text>{data?.extraInfo?.treatmentPlace}</Text>
            </Text>
            <Text style={{ width: "100%", marginBottom: "3px" }}>
              {"Nguyên nhân / Chẩn đoán về tai nạn/bệnh: "}{" "}
              <Text>{data?.extraInfo?.diagnoses}</Text>
            </Text>
            <Text style={{ width: "100%", marginBottom: "3px" }}>
              {"Hậu quả: "} <Text>{" ..................."}</Text>
            </Text>
            <View style={{ ...styles.vLine, marginBottom: "3px" }}>
              <View style={{ ...styles.vLine, width: "45%" }}>
                <Text>{"Hình thức điều trị: "}</Text>
                <Text style={styles.boder}>
                  {data?.extraInfo?.typeOfTreatment !== 1 ? "X" : ""}
                </Text>
                <Text style={{ marginRight: "12px" }}>{"Ngoại trú"}</Text>
                <Text style={styles.boder}>
                  {data?.extraInfo?.typeOfTreatment === 1 ? "X" : ""}
                </Text>
                <Text>{"Nội trú "}</Text>
              </View>
              <Text>{" - "}</Text>
              <View style={{ ...styles.vLine, width: "55%" }}>
                <Text style={styles.vLine2T1}>
                  {"từ ngày: "} <Text>{" ..................."}</Text>
                </Text>
                <Text style={{ width: "49%" }}>
                  {"đến ngày: "} <Text>{" ..................."}</Text>
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text style={styles.view3T1}>{"D. THÔNG TIN THANH TOÁN"}</Text>
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              border: "1px solid black",
            }}
          >
            <View style={{ width: "55%" }}>
              <Text
                style={{
                  width: "100%",
                  padding: "8px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Nội dung yêu cầu chi trả bảo hiểm
              </Text>
            </View>
            <View style={{ width: "45%", borderLeft: "1px solid black" }}>
              <View
                style={{
                  width: "100%",
                  padding: "4px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                <Text style={{ marginBottom: "3px" }}>
                  Hình thức thanh toán
                </Text>
                <Text>Thông tin người thụ hưởng</Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              border: "1px solid black",
              borderTop: "none",
            }}
          >
            <View style={{ width: "55%" }}>
              <Text
                style={{
                  width: "100%",
                  padding: "4px 4px 8px 4px",
                  borderBottom: "1px solid black",
                }}
              >
                {"1. Tổng số tiền yêu cầu chi trả: "}{" "}
                <Text>
                  {`${data?.extraInfo?.totalAmountTreatment || 0}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "."
                  ) + " VNĐ"}
                </Text>
              </Text>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text
                  style={{
                    width: "55%",
                    padding: "4px 4px 8px 4px",
                  }}
                >
                  {"2. Chi trả bảo hiểm cho trường hợp: "}
                  <Text>{"................................"}</Text>
                </Text>
                <View
                  style={{
                    width: "45%",
                    textAlign: "left",
                    padding: "4px 4px 8px 4px",
                    borderLeft: "1px solid black",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "4px",
                    }}
                  >
                    <Text style={styles.boder}>
                      {data?.extraInfo?.levelAccident === 1 ? "X" : ""}
                    </Text>
                    <Text>{" Tử vong"}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "4px",
                    }}
                  >
                    <Text style={styles.boder}>
                      {data?.extraInfo?.levelAccident === 2 ||
                      data?.extraInfo?.levelAccident === 3
                        ? "X"
                        : ""}
                    </Text>
                    <Text>{" Thương tật"}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "4px",
                    }}
                  >
                    <Text style={styles.boder}>{""}</Text>
                    <Text>{" Chi phí y tế"}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "4px",
                    }}
                  >
                    <Text style={styles.boder}>{""}</Text>
                    <Text>{" Vận chuyển cấp cứu"}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "4px",
                    }}
                  >
                    <Text style={styles.boder}>{""}</Text>
                    <Text>{" Trợ cấp"}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "45%",
                borderLeft: "1px solid black",
                padding: "4px 4px 8px 4px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "4px",
                }}
              >
                <Text style={styles.boder}>{""}</Text>
                <Text>{" Tiền mặt"}</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "4px",
                }}
              >
                <Text style={styles.boder}>{"X"}</Text>
                <Text>{" Chuyển khoản"}</Text>
              </View>
              <Text
                style={{
                  width: "100%",
                  marginBottom: "5px",
                  marginLeft: "6px",
                }}
              >
                {"Người thụ hưởng: "}{" "}
                <Text style={{ textTransform: "capitalize" }}>
                  {data?.insuredInfo?.beneficiaryName || ""}
                </Text>
              </Text>
              <Text
                style={{
                  width: "100%",
                  marginBottom: "5px",
                  marginLeft: "6px",
                }}
              >
                {"Số tài khoản: "}{" "}
                <Text>
                  {data?.insuredInfo?.beneficiaryBankAcountNumber || ""}
                </Text>
              </Text>
              <Text
                style={{
                  width: "100%",
                  marginBottom: "5px",
                  marginLeft: "6px",
                }}
              >
                {"Ngân hàng: "}{" "}
                <Text>{data?.insuredInfo?.beneficiaryBankTitle || ""}</Text>
              </Text>
              <Text
                style={{
                  width: "100%",
                  marginBottom: "5px",
                  marginLeft: "6px",
                }}
              >
                {"Địa chỉ ngân hàng: "}{" "}
                <Text>
                  {data?.insuredInfo?.beneficiaryBankBranchName || ""}
                </Text>
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text style={styles.view3T1}>{"E. CAM KẾT VÀ ỦY QUYỀN"}</Text>
          <Text style={{ marginBottom: "6px" }}>
            Tôi cam đoan những thông tin kê khai trên đây là chính xác và đầy
            đủ. Tôi xin hoàn toàn chịu trách nhiệm trước pháp luật nếu có bất cứ
            sự sai lệch nào về thông tin đã cung cấp và bất cứ tranh chấp nào về
            quyền thụ hưởng số tiền được chi trả bảo hiểm.
          </Text>
          <Text>
            {
              "Tôi cũng đồng ý rằng, bằng Giấy yêu cầu trả tiền bảo hiểm này, tôi cho phép đại diện của Bảo hiểm PVI được quyền tiếp xúc với các bên thứ ba để thu thập thông tin cần thiết cho việc xét bồi thường này, bao gồm nhưng không giới hạn ở việc tiếp xúc với (các) bác sĩ đã và đang điều trị cho tôi."
            }
          </Text>
        </View>
        <View
          style={{ marginTop: "8px", display: "flex", flexDirection: "row" }}
        >
          <View style={{ width: "50%", textAlign: "center" }}>
            <Text style={{ width: "100%", fontWeight: "bold" }}>XÁC NHẬN </Text>
            <Text
              style={{
                width: "100%",
                fontStyle: "italic",
              }}
            >
              {"(Chữ ký và dấu của đơn vị tham gia bảo hiểm/cơ quan"}
            </Text>
            <Text
              style={{
                width: "100%",
                fontStyle: "italic",
              }}
            >
              {"chủ quản hoặc chính quyền, công an nơi xảy ra tai nạn)"}
            </Text>
          </View>
          <View
            style={{ width: "50%", textAlign: "center", position: "relative" }}
          >
            <Text style={{ width: "100%", fontStyle: "italic" }}>
              {`... ... ..., ngày ${moment(data.createdDate).format(
                "D"
              )} / ${moment(data.createdDate).format("M")} / ${moment(
                data.createdDate
              ).format("YYYY")}`}
            </Text>
            <Text
              style={{ width: "100%", fontWeight: "bold", margin: "3px 0" }}
            >
              NGƯỜI YÊU CẦU
            </Text>
            <Text style={{ width: "100%", fontStyle: "italic" }}>
              {"(Ký và ghi rõ họ tên)"}
            </Text>
            {signature.path && (
              <Image
                source={signature.path}
                style={{
                  width: "130px",
                  height: "55px",
                  position: "absolute",
                  top: "55px",
                  right: "60px",
                }}
              />
            )}
            <Text
              style={{
                width: "100%",
                marginTop: "70px",
                textTransform: "capitalize",
              }}
            >
              {data?.insuredInfo?.beneficiaryName}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "times-new-roman",
    padding: "16px 24px 0",
    fontSize: "11px",
  },

  view1: { width: "100%", position: "relative", marginTop: "12px" },
  image: { width: "40px", height: "55px", position: "absolute", left: 0 },
  view1T1: { fontSize: "15px", fontWeight: "bold", marginBottom: "3px" },
  view1T2: {
    fontStyle: "italic",
    fontSize: "10px",
    marginTop: "6px",
    marginBottom: "3px",
  },

  view2: { marginTop: "18px" },
  view2T1: { fontWeight: "bold", marginBottom: "6px" },
  view2V1: { display: "flex", flexDirection: "row" },
  view2T3: { width: "50%", marginBottom: "3px" },
  view2T4: { width: "50%", paddingLeft: "6px", marginBottom: "3px" },

  view3T1: { fontWeight: "bold", margin: "6px 0" },
  vLine: { display: "flex", flexDirection: "row" },
  vLine1T1: { width: "60%", paddingRight: "6px", marginBottom: "3px" },
  vLine2T1: { width: "50%", paddingRight: "6px", marginBottom: "3px" },
  boder: {
    width: "15px",
    height: "15px",
    border: "1px solid black",
    display: "flex",
    textAlign: "center",
    margin: "0 6px",
    // paddingTop: "2px",
    lineHeight: 0,
  },
});
