import _ from "lodash";
import moment from "moment";
import * as PATH from "../../../../../configs/routesConfig";
import { MAXIMUN_LINE_EXPORT_EXCEL_AHARIDE } from "../../../../../utils/contains";
import { getListIndemnify } from "../../../../../apis/claimLoyaltyApis";

export default function managementReducer({
  state,
  dispatch,
  action,
  form,
  roleType,
  user,
  history,
  dataSearchLoyaltyList,
  setDataSearchLoyaltyList,
}) {
  const cases = {
    initial: async () => {
      // const { reset } = action;
      const res = {};
      // if (reset) {
      //   await setDataSearchLoyaltyList({});
      //   res.resetDataSearch = true;
      // }

      dispatch("onLoading");

      const params = _.isEmpty(dataSearchLoyaltyList?.dataSearch)
        ? {
            insuredPhone: "",
            insuredName: "",
            certNum: null,
            statusId: null,
            from: moment().subtract(1, "months").toDate(),
            to: moment().toDate(),
            productIdParent: 34,
            merchantId:
              user?.extra_info?.userType == "merchant"
                ? user?.extra_info?.merchantId
                : null,
            limit: 10,
            offset: 0,
          }
        : {
            ...dataSearchLoyaltyList?.dataSearch,
            merchantId:
              user?.extra_info?.userType == "merchant"
                ? user?.extra_info?.merchantId
                : dataSearchLoyaltyList?.dataSearch?.merchantId || null,
            productIdParent: 34,
            limit: dataSearchLoyaltyList?.pageTable?.rowsPerPage || 10,
            offset:
              dataSearchLoyaltyList?.pageTable?.page *
                dataSearchLoyaltyList?.pageTable?.rowsPerPage || 0,
          };

      const dataApi = await getListIndemnify(roleType, params);

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.rows = _.get(dataApi, "data.result.data") || [];
        res.totalRows = _.get(dataApi, "data.result.total") || 0;
        res.pageTable = {
          count: Math.ceil(dataApi.data.result.total / 10),
          page: dataSearchLoyaltyList?.pageTable?.page || 0,
        };
        res.dataSearch = _.isEmpty(dataSearchLoyaltyList?.dataSearch)
          ? {
              from: moment().subtract(1, "months").toDate(),
              to: moment().toDate(),
              merchantId:
                roleType == "merchant" ? user?.extra_info?.merchantId : null,
            }
          : {
              ...dataSearchLoyaltyList?.dataSearch,
              merchantId:
                roleType == "merchant" ? user?.extra_info?.merchantId : null,
            };
      }

      return { ...res, loading: false };
    },

    onChangeSearch: () => {
      const { name, value } = action;
      const { dataSearch } = state;
      dataSearch[name] = value;

      return { dataSearch };
    },

    changePage: async () => {
      const {
        data: { item },
      } = action;
      form === "approve-claim" &&
        history.push(`${PATH.LOYALTY_MANAGEMENT}/detail?id=${item.id}`);

      form === "report-claim" &&
        history.push(`${PATH.LOYALTY_REPORT}/detail?id=${item.id}`);
    },

    onPaging: async () => {
      const { page, rowsPerPage } = action;
      const { dataSearch } = state;

      dispatch("onLoading");

      const params = {
        insuredPhone: dataSearch?.insuredPhone || "",
        insuredName: dataSearch?.insuredName || null,
        code: dataSearch?.code || null,
        statusId: dataSearch?.statusId || null,
        from: dataSearch?.from || null,
        to: dataSearch?.to || null,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        productIdParent: 34,
        merchantId: dataSearch?.merchantId || null,
      };

      const dataApi = await getListIndemnify(roleType, params);

      const res = {};

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.rows = _.get(dataApi, "data.result.data") || [];
        res.totalRows = _.get(dataApi, "data.result.total") || 0;
        res.pageTable = {
          count: Math.ceil(dataApi.data.result.total / (rowsPerPage || 10)),
          page: page,
          rowsPerPage: rowsPerPage,
        };
        setDataSearchLoyaltyList({
          dataSearch: { ...dataSearch },
          pageTable: { ...res.pageTable },
        });
      }

      return { ...res, loading: false };
    },

    onSubmitSearch: async () => {
      const { dataSearch, pageTable } = state;

      dispatch("onLoading");

      const params = {
        insuredPhone: dataSearch?.insuredPhone || "",
        insuredName: dataSearch?.insuredName || null,
        code: dataSearch?.code || null,
        statusId: dataSearch?.statusId || null,
        from: dataSearch?.from || null,
        to: dataSearch?.to || null,
        limit: pageTable?.rowsPerPage || 10,
        offset: 0,
        productIdParent: 34,
        merchantId: dataSearch?.merchantId || null,
      };
      setDataSearchLoyaltyList({
        dataSearch: { ...dataSearch },
        pageTable: { ...pageTable },
      });
      const dataApi = await getListIndemnify(roleType, params);

      const res = {};

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.rows = _.get(dataApi, "data.result.data") || [];
        res.totalRows = _.get(dataApi, "data.result.total") || 0;
        res.pageTable = {
          count: Math.ceil(
            dataApi.data.result.total / (pageTable?.rowsPerPage || 10)
          ),
          page: 0,
          rowsPerPage: pageTable?.rowsPerPage,
        };
      }

      return { ...res, loading: false };
    },

    onLoading: () => ({ loading: true }),

    onSubmitExport: () => {
      const { totalRows } = state;

      if (totalRows > +MAXIMUN_LINE_EXPORT_EXCEL_AHARIDE) {
        return { openMaxLineDialog: true };
      } else {
        dispatch("getListExport");
      }
    },

    getListExport: async () => {
      const { count, dataSearch } = state;
      const res = {};
      dispatch("onLoading");

      const params = {
        insuredPhone: dataSearch?.insuredPhone || "",
        insuredName: dataSearch?.insuredName || null,
        code: dataSearch?.code || null,
        statusId: dataSearch?.statusId || null,
        from: dataSearch?.from || null,
        to: dataSearch?.to || null,
        limit: +MAXIMUN_LINE_EXPORT_EXCEL_AHARIDE,
        offset: 0,
        productIdParent: 34,
        merchantId: dataSearch?.merchantId || null,
        export: 1,
      };

      const dataApi = await getListIndemnify(roleType, params);

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.count = +count + 1;
        res.dataExport = _.get(dataApi, "data.result.data") || [];
      }

      return { ...res, loading: false, openMaxLineDialog: false };
    },

    closeMaxLineDialog: () => ({ openMaxLineDialog: false }),
  };

  return action?.type && cases[action?.type];
}
