import { Form, message, Modal, Pagination, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { PROVIDER_RECOUP } from "../../../../../../configs/routesConfig";
import { getUsers } from "../../api/account";
import { changeReviewer } from "../../api/claim";
import { ReviewersState } from "../../state";
import { formatMoney } from "../../utils/money";
import { STATUS } from "../../utils/type";
import {
  BlueEyeIcon,
  BlueNoteIcon,
  CalendarIcon,
  CodeIcon,
  EmployeeIcon,
  GrayEyeIcon,
  MiniMoneyIcon,
  OrangeEyeIcon,
  StatusIcon,
  UserIcon,
} from "../icon/icon";

const { Option } = Select;

function ActionColumn({ record, text }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const roleAdminMenuItem = JSON.parse(
    localStorage.getItem("roleAdminMenuItem")
  );
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (
      roleAdminMenuItem?.role_management?.level_id == 1 &&
      +user?.id == +record?.userIdReview
    ) {
      setDisabled(false);
    }
    // if (+user.role_id === 2 || +user.role_id === 1) setDisabled(false);
    // if (user?.role_management_claim?.tomato) {
    //   if (+record?.userIdReview === +user?.id) {
    //     setDisabled(false);
    //   }
    //   if (+record?.userIdAssign === +user?.id) {
    //     setDisabled(false);
    //   }
    //   if (
    //     user?.role_management_claim?.tomato[
    //       "quyen-quan-ly-chung.xem-chi-tiet-ho-so"
    //     ]
    //   )
    //     setDisabled(false);
    // }
  }, []);

  return disabled ? (
    <div style={{ textAlign: "center" }}>
      <GrayEyeIcon />
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <a href={`/admin/claim-review/details/${text}/tomato`}>
        <OrangeEyeIcon />
      </a>
    </div>
  );
}

function ReviewUserChange({ text, record }) {
  const [reviewers, setReviewers] = useRecoilState(ReviewersState);
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const [disabled, setDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [updated, setUpdated] = useState(null);

  useEffect(() => {
    if (+record.statusId !== 1) return;

    if (+user.role_id === 2) return setDisabled(false);

    if (user?.role_management_claim?.tomato) {
      if (
        user?.role_management_claim?.tomato[
          "quyen-quan-ly-chung.gan-nhan-vien-xu-ly"
        ]
      ) {
        return setDisabled(false);
      }
    }
  }, []);

  const updateReviewer = async () => {
    setProcessing(true);
    const response = await changeReviewer(
      form.getFieldsValue()?.userIdReview,
      record?.id
    );

    if (response instanceof Error) return message.error(response.message);

    setTimeout(() => {
      setProcessing(false);
      setVisible(false);
      setUpdated(
        reviewers.filter(
          (reviewer) => +reviewer.id === +response.data?.result?.user_id_review
        )[0]?.fullname
      );
      message.success("Cập nhật nhân viên xử lý thành công");
    }, 1500);
  };

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "20px 80px",
          placeContent: "center",
          height: "100%",
          padding: "0 8px",
          gap: "2px",
        }}
      >
        <span style={{ textAlign: "left", marginLeft: 10 }}>
          {updated || text || "N/A"}
        </span>
      </div>

      <Modal
        centered={true}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => {
          updateReviewer();
        }}
        okText="Xác nhận"
        cancelText="Hủy"
        title="Gán nhân viên xử lý"
        confirmLoading={processing}
      >
        <Form form={form}>
          <Form.Item name={"userIdReview"} initialValue={+record.userIdReview}>
            <Select
              showSearch={true}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
            >
              {reviewers.map((item, index) => {
                return (
                  <Option key={index} value={item.id}>
                    {item.fullname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const column = [
  {
    key: "id",
    title: (
      <div
        style={{
          display: "grid",
          placeContent: "center",
          height: "100%",
          padding: "0 8px",
          gap: "2px",
        }}
      >
        <span
          style={{ color: "#2A3790", fontWeight: 500, textAlign: "center" }}
        >
          {" "}
          STT
        </span>
      </div>
    ),
    minWidth: "50px",
    render: (text, record) => {
      return (
        <div style={{ textTransform: "capitalize", textAlign: "center" }}>
          {text}
        </div>
      );
    },
  },
  {
    key: "fullname",
    title: (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "20px 80px",
          placeContent: "center",
          height: "100%",
          padding: "0 8px",
          gap: "2px",
        }}
      >
        <UserIcon
          style={{
            textAlign: "right",
          }}
        />
        <span
          style={{ color: "#2A3790", fontWeight: 500, textAlign: "center" }}
        >
          {" "}
          Họ và tên
        </span>
      </div>
    ),
    minWidth: "120px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          {record?.insuredInfo?.insuredName}
        </div>
      );
    },
  },
  {
    key: "code",
    title: (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "20px 80px",
          placeContent: "center",
          height: "100%",
          padding: "0 8px",
          gap: "2px",
        }}
      >
        <CodeIcon
          style={{
            justifyContent: "end",
            display: "flex",
            alignItems: "center",
          }}
        />
        <span
          style={{ color: "#2A3790", fontWeight: 500, textAlign: "center" }}
        >
          {" "}
          Mã hồ sơ bồi thường
        </span>
      </div>
    ),
    minWidth: "100px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          {text}
        </div>
      );
    },
  },
  {
    key: "createdDate",
    title: (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "20px 80px",
          placeContent: "center",
          height: "100%",
          padding: "0 8px",
          gap: "2px",
        }}
      >
        <CalendarIcon
          style={{
            justifyContent: "end",
            display: "flex",
            alignItems: "center",
          }}
        />
        <span
          style={{ color: "#2A3790", fontWeight: 500, textAlign: "center" }}
        >
          {" "}
          Ngày tạo hồ sơ
        </span>
      </div>
    ),
    minWidth: "100px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          {moment(text).format("DD/MM/YYYY")}
        </div>
      );
    },
  },
  {
    key: "updatedDate",
    title: (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "20px 80px",
          placeContent: "center",
          height: "100%",
          padding: "0 8px",
          gap: "2px",
        }}
      >
        <CalendarIcon
          style={{
            justifyContent: "end",
            display: "flex",
            alignItems: "center",
          }}
        />
        <span
          style={{ color: "#2A3790", fontWeight: 500, textAlign: "center" }}
        >
          {" "}
          Ngày cập nhật mới nhất
        </span>
      </div>
    ),
    minWidth: "100px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          {text ? moment(text).format("DD/MM/YYYY") : "N/A"}
        </div>
      );
    },
  },
  {
    key: "required_money",
    title: (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "20px 80px",
          placeContent: "center",
          height: "100%",
          padding: "0 8px",
          gap: "2px",
        }}
      >
        <MiniMoneyIcon
          style={{
            justifyContent: "end",
            display: "flex",
            alignItems: "center",
          }}
        />
        <span
          style={{ color: "#2A3790", fontWeight: 500, textAlign: "center" }}
        >
          {" "}
          Số tiền yêu cầu
        </span>
      </div>
    ),
    minWidth: "120px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          {formatMoney(record?.insuredInfo?.treatmentPrice)}vnđ
        </div>
      );
    },
  },
  {
    key: "status_id",
    title: (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "20px 80px",
          placeContent: "center",
          height: "100%",
          padding: "0 8px",
          gap: "2px",
        }}
      >
        <StatusIcon
          style={{
            justifyContent: "end",
            display: "flex",
            alignItems: "center",
          }}
        />
        <span
          style={{ color: "#2A3790", fontWeight: 500, textAlign: "center" }}
        >
          {" "}
          Trạng thái
        </span>
      </div>
    ),
    minWidth: "100px",
    render: (text, record) => {
      let status = STATUS.filter(
        (item) => +item.value === +record?.statusId
      )[0];
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            color: status?.color,
            fontSize: 12,
          }}
        >
          {status?.label}
        </div>
      );
    },
  },
  {
    key: "userFullnameReview",
    title: (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "20px 80px",
          placeContent: "center",
          height: "100%",
          padding: "0 8px",
          gap: "2px",
        }}
      >
        <EmployeeIcon
          style={{
            justifyContent: "end",
            display: "flex",
            alignItems: "center",
          }}
        />
        <span
          style={{ color: "#2A3790", fontWeight: 500, textAlign: "center" }}
        >
          {" "}
          Nhân sự kiểm tra
        </span>
      </div>
    ),
    minWidth: "160px",
    render: (text, record) => {
      return <ReviewUserChange text={text} record={record} />;
    },
  },
  {
    key: "userFullnameAssign",
    title: (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "20px 80px",
          placeContent: "center",
          height: "100%",
          padding: "0 8px",
          gap: "2px",
        }}
      >
        <EmployeeIcon
          style={{
            justifyContent: "end",
            display: "flex",
            alignItems: "center",
          }}
        />
        <span
          style={{ color: "#2A3790", fontWeight: 500, textAlign: "center" }}
        >
          {" "}
          Nhân sự phê duyệt
        </span>
      </div>
    ),
    minWidth: "160px",
    render: (text, record) => {
      return (
        <div
          style={{
            textTransform: "capitalize",
            textAlign: "center",
            fontSize: 12,
          }}
        >
          <span style={{ textAlign: "left", marginLeft: 10 }}>
            {text || "N/A"}
          </span>
        </div>
      );
    },
  },
  {
    key: "id",
    title: (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "20px 80px",
          placeContent: "center",
          height: "100%",
          padding: "0 8px",
          gap: "2px",
        }}
      >
        <BlueEyeIcon
          style={{
            justifyContent: "end",
            display: "flex",
            alignItems: "center",
          }}
        />
        <span
          style={{ color: "#2A3790", fontWeight: 500, textAlign: "center" }}
        >
          {" "}
          Kiểm tra hồ sơ
        </span>
      </div>
    ),
    minWidth: "90px",
    render: (text, record) => {
      const user = JSON.parse(localStorage.getItem("user"));
      let disabled = true;
      if (user?.role_management_claim?.tomato) {
        if (+record?.userIdReview === +user?.id) {
          disabled = false;
        }

        if (+record?.userIdAssign === +user?.id) {
          disabled = false;
        }

        if (
          user?.role_management_claim?.tomato[
            "quyen-quan-ly-chung.xem-chi-tiet-ho-so"
          ]
        )
          disabled = false;
      }

      return <ActionColumn text={text} record={record} />;
    },
  },
];

function Row(props) {
  return (
    <>
      {props.data.map((item, index) => {
        return (
          <tr key={index} style={{ background: "white", marginBottom: 10 }}>
            {column.map((col, i) => {
              return (
                <td key={i} style={{ padding: 10 }}>
                  {col.render
                    ? col.render(item[col["key"]], item)
                    : item[col["key"]]}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
}

export default function CheckClaimTable({
  data = [],
  total = 0,
  pageSize = 10,
  changeCurrentPage = () => {},
  currentPage = 1,
}) {
  return (
    <>
      <div
        style={{
          height: 1,
          background: "#BDBDBD",
        }}
      />
      <div style={{ overflowX: "auto", minHeight: 450, maxHeight: 450 }}>
        <table className="responsive" style={{ width: "100%" }}>
          <thead>
            <tr>
              {column.map((item, index) => (
                <th key={index} style={{ minWidth: item.minWidth }}>
                  {item.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <Row data={data} />
          </tbody>
        </table>
      </div>

      <div style={{ display: "flex", justifyContent: "end", marginTop: 10 }}>
        <Pagination
          size="small"
          current={currentPage}
          total={total}
          pageSize={pageSize}
          showSizeChanger={false}
          onChange={(page, pageSize) => {
            changeCurrentPage(page);
          }}
        />
      </div>
    </>
  );
}
