import React from "react";
import { IconButton, TableCell } from "@mui/material";

import userIcon from "../assets/user-name-icon.svg";
import codeIcon from "../assets/codeIcon.svg";
import date from "../assets/toDate.svg";
import moneyIcon from "../assets/moneyIcon.svg";
import showIcon from "../assets/showIcon.svg";
import statusIcon from "../assets/statusIcon.svg";
import showButton from "../assets/showButton.svg";
import merchant from "../assets/merchant.svg";
import moment from "moment";
import { renderStatus } from "../utils";

export const columns = [
  {
    label: "STT",
    headerClass: "stt",
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={`stt`} key={colIdx}>
        {rowIdx + 1}
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, index, event }) => (
      <TableCell key={index} className="">
        <>
          <img src={userIcon} style={{ marginRight: "6px" }} />
          Họ tên
        </>
      </TableCell>
    ),
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={``} key={colIdx}>
        {item?.buyerName ||
          item?.insuredInfo?.insuredName ||
          item?.extraInfo?.sender?.fullName ||
          ""}
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, index, event }) => (
      <TableCell key={index} className="">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={codeIcon} style={{ marginRight: "6px" }} />
          <span>Mã hồ sơ bồi thường</span>
        </div>
      </TableCell>
    ),
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={``} key={colIdx}>
        {item?.code || ""}
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, index, event }) => (
      <TableCell key={index} className="merchant">
        <div style={{ display: "flex" }}>
          <img src={merchant} style={{ marginRight: "6px" }} />
          <span style={{ textAlign: "center" }}>Kênh khai thác</span>
        </div>
      </TableCell>
    ),
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={`merchant`} key={colIdx}>
        {item?.merchantTitle || ""}
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, index, event }) => (
      <TableCell key={index} className="date-creat">
        <>
          <img src={date} style={{ marginRight: "6px" }} />
          Ngày tạo hồ sơ
        </>
      </TableCell>
    ),
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell
        className={``}
        key={colIdx}
        title={
          item?.createdDate
            ? moment(item?.createdDate).format("HH:mm DD/MM/YYYY")
            : ""
        }
      >
        {item?.createdDate
          ? moment(item?.createdDate).format("DD/MM/YYYY")
          : ""}
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, index, event }) => (
      <TableCell key={index} className="date">
        <div style={{ display: "flex" }}>
          <img src={date} style={{ marginRight: "6px" }} />
          <span>Ngày cập nhật hồ sơ</span>
        </div>
      </TableCell>
    ),
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell
        className={``}
        key={colIdx}
        title={
          item?.updatedDate
            ? moment(item?.updatedDate).format("HH:mm DD/MM/YYYY")
            : ""
        }
      >
        {item?.updatedDate
          ? moment(item?.updatedDate).format("DD/MM/YYYY")
          : ""}
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, index, event }) => (
      <TableCell key={index} className="">
        <>
          <img src={moneyIcon} style={{ marginRight: "6px" }} />
          Số tiền yêu cầu
        </>
      </TableCell>
    ),
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={``} key={colIdx}>
        {item.requestAmount
          ? `${item.requestAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          : ""}
        {item.requestAmount && <span> vnđ</span>}
      </TableCell>
    ),
  },
  {
    label: "Số tiền đã duyệt",
    renderCell: ({ item }) => (
      <TableCell>
        {[5, 6, 8, 9].includes(item?.statusId)
          ? `${item.amount || "-"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            " VNĐ"
          : "- VNĐ"}
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, index, event }) => (
      <TableCell key={index} className="">
        <>
          <img src={statusIcon} style={{ marginRight: "6px" }} />
          Trạng thái
        </>
      </TableCell>
    ),
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell
        className={`  ${
          item.statusId === 2
            ? "color2"
            : item.statusId === 4
            ? "color4"
            : item.statusId === 5 || item.statusId === 6
            ? "color5"
            : item.statusId === 7
            ? "color7"
            : ""
        }`}
        key={colIdx}
      >
        {renderStatus(item.statusId)}
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, index, event }) => (
      <TableCell key={index} className="show sticky">
        <div style={{ display: "flex" }}>
          <img src={showIcon} style={{ marginRight: "6px" }} />
          <span>Kiểm tra hồ sơ</span>
        </div>
      </TableCell>
    ),
    renderCell: ({ rowIdx, colIdx, event, item }) => (
      <TableCell className={`show sticky`} key={colIdx}>
        <IconButton onClick={event("changePage", { name: "detail", item })}>
          <img src={showButton} alt="showButton" />
        </IconButton>
      </TableCell>
    ),
  },
];
