import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Button } from "antd";
import _, { debounce } from "lodash";
import React from "react";
import { useStyles } from "../styles/infoStyles";

import list from "../../../../../assets/loyalty/list.svg";
import search from "../../../../../assets/loyalty/search.svg";
import clear from "../../../../../assets/loyalty/clear.svg";

function SearchStep({ state, dispatch }) {
  const { loading, codeOrderList, codeOrder, errors } = state;
  const classes = useStyles();

  return (
    <div>
      <div className={classes.title}>TÌM KIẾM ĐƠN HÀNG</div>
      <div className={classes.row}>
        <TextField
          className={classes.textField}
          fullWidth
          placeholder="Nhập mã đơn hàng"
          value={codeOrder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={search} alt="search" />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton onClick={() => dispatch("handleClearCode")}>
                <img src={clear} alt="clear" width={16} />
              </IconButton>
            ),
          }}
          onChangeCapture={(e) =>
            dispatch({ type: "changeCodeOrder", value: e.target.value })
          }
        />
        <Button
          onClick={() =>
            dispatch({
              type: "getListCode",
            })
          }
          className={classes.button}
        >
          Tìm kiếm
        </Button>
      </div>
      <div className={classes.spacing}></div>
      <div>Kết quả tìm kiếm:</div>
      {loading && (
        <CircularProgress size={24} sx={{ m: 1, color: "#284078" }} />
      )}
      {errors.includes("codeOrder") && (
        <div className={classes.error}>Không tìm thấy đơn hàng</div>
      )}
      {codeOrderList.map((item, index) => (
        <div
          key={index}
          className={classes.code}
          onClick={() => dispatch({ type: "detailCode", index })}
        >
          <img src={list} alt="list" style={{ marginRight: 8 }} />
          {item?.orderCode || ""}
        </div>
      ))}
    </div>
  );
}

export default SearchStep;
