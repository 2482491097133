import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Box, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import {
  Wrapper,
  ContentWrapper,
  Content,
} from "../../.../../../carAccident/styles";
import HeaderBar from "../../components/layout/HeaderBar";
import { ReactComponent as GroupIcon } from "../../../../../../assets/icons/Group.svg";
import ChevronRightIcon from "@mui/icons-material/ArrowForwardIos";
import { getListClaim } from "../../../../../../apis/claimApis";
import { getRoute } from "../../../../../../utils/utils";
import { INSU_TOMATO_CLAIM_DETAIL } from "../../../../../../configs/routesConfig";
import moment from "moment";
import Loading from "../../../../../../ui/Loading";

const InsuTomatoClaimList = () => {
  const history = useHistory();
  const [listClaim, setListClaim] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function callApi() {
      await getClaimList();
    }

    callApi();
  }, []);

  const getClaimList = async () => {
    setLoading(true);
    getListClaim({
      limit: 300,
      offset: 0,
      productIdParent: 19,
    }).then(async (res) => {
      const data = _.get(res, "data.result.data") || [];
      if (data) {
        setListClaim([...data]);
      }
      
      setLoading(false);
    });
  };

  const Submit = (item) => {
    history.push(getRoute(INSU_TOMATO_CLAIM_DETAIL, item.id) + "?goback");
  };

  const renderStatusBlock = (status) => {
    if (status) {
      const STATUS = [
        { value: 1, label: 'Chờ xử lý', color: '#26A9E0' },
        { value: 2, label: 'Đang xử lý', color: '#DBAC06' },
        { value: 3, label: 'Chờ phê duyệt', color: '#6372DA' },
        { value: 4, label: 'Bổ sung hồ sơ', color: '#FB7800' },
        { value: 5, label: 'Đã phê duyệt', color: '#39B44A' },
        { value: 6, label: 'Hoàn tất', color: '#39B44A' },
        { value: 7, label: 'Từ chối', color: '#BF272D' },
      ]
      return (
        <span
          style={{
            color: STATUS.filter((item) => +item.value === +status)[0].color,
          }}
        >
          {STATUS.filter((item) => +item.value === +status)[0].label}
        </span>
      );
    } else return;
  };

  return (
    <Wrapper>
      <HeaderBar
        title="Hồ sơ bồi thường của bạn"
        background="white"
        onPress={() => history.goBack()}
      />
      <ContentWrapper style={{ backgroundColor: "#FFFFFF", marginTop: 10 }}>
        <Content>
          <div style={formInfo}>
            {loading && <Loading />}
            {
              !loading && <>
                {(listClaim || []).map((item, key) => (
                  <Box sx={infoInsu} key={key} onClick={() => Submit(item)}>
                    <Grid container direction="row" alignItems="start">
                      <Grid item>
                        <div style={claimItemIcon}>
                          <GroupIcon style={{ width: "20px", height: "20px" }} />
                        </div>
                      </Grid>
                      <div style={claimItemBody}>
                        <div>
                          <Typography sx={name}>
                            HS bồi thường mã:{" "}
                            <span style={{ color: "#0B3C8A" }}>{item.code}</span>
                          </Typography>
                          <Box sx={stat}>{renderStatusBlock(item.statusId)}</Box>
                          <Typography sx={date}>
                            {/* {(item?.createdDate).slice(
                          (item?.createdDate).indexOf("T") + 1,
                          (item?.createdDate).indexOf("T") + 6
                        )}{" "}
                        {new Date(item?.createdDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-")} */}
                            {item?.createdDate &&
                              moment(item?.createdDate).format("HH:mm DD-MM-YYYY")}
                          </Typography>
                        </div>
                        <ChevronRightIcon
                          style={{ width: "15px", height: "15px" }}
                        />
                      </div>
                    </Grid>
                  </Box>
                ))}
              </>
            }
          </div>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default InsuTomatoClaimList;

const formInfo = {
  margin: "43px 0 8px 0",
};

const infoInsu = {
  padding: "0",
  backgroundColor: "white",
  borderRadius: "12px",
  marginTop: "25px",
  cursor: "`pointer`",
  "&:hover": {
    backgroundColor: "rgba(11, 60, 138, 0.17)",
  },
};

const claimItemIcon = {
  backgroundColor: "#0B3C8A",
  width: "52px",
  height: "52px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "16px",
};

const claimItemBody = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flex: 1,
  marginLeft: "1rem",
  paddingBottom: "1rem",
  borderBottom: "0.5px solid rgba(0, 0, 0, 0.19)",
};

const name = {
  fontSize: 15,
  lineHeight: "19px",
  fontWeight: "600",
  letterSpacing: "-0.24 px",
};

const stat = {
  display: "flex",
  fontFamily: `"Averta Std CY",sans-serif`,
  fontSize: 13,
  lineHeight: "16px",
  padding: "5px 0",
  "& .title": {
    fontSize: 13,
  },
};

const date = {
  fontSize: 12,
  fontWeight: "700",
  color: "rgba(0, 0, 0, 0.32)",
};
