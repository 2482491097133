import React, { useEffect } from "react";
import _ from "lodash";
import {
  TextField,
  MenuItem,
  Grid,
  FormControlLabel,
  Box,
  Stack,
  InputAdornment,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { accidentTypes, treatmentTypes } from "../createDetailClaimFile/until";
import CTCheckbox from "../layout/CTCheckBox";
import { parseISO } from "date-fns";
import moment from "moment";
import { notification } from "antd";
const number = "0123456789";

const RenderSpan = (props) => {
  const { padding, noRequired } = props;

  return (
    <div
      style={{
        fontSize: 17,
        lineHeight: "22px",
        padding: !!padding ? padding : "16px 0",
        fontWeight: "bold",
      }}
    >
      <span>
        {props?.title || ""}
        {!noRequired && <span style={{ color: "red" }}>*</span>}
      </span>
    </div>
  );
};

const DatePicker = (props) => {
  const { isError, onChange, value } = props;
  let val = !value ? null : new Date(parseISO(value));
  // if (val) val = val.setDate(val.getDate() + 1);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={1}>
        <DesktopDatePicker
          utcOffset={0}
          inputFormat="dd/MM/yyyy"
          value={val}
          onChange={(e) => {
            onChange(e);
          }}
          renderInput={(params) => <TextField {...params} sx={textField} />}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default function ClaimFile(props) {
  const { state, dispatch } = props;
  const { paramsData, errors } = state;
  const { insuredInfo = {}, extraInfo = {} } = paramsData;

  const bone =
    ["life", "work accident", "traffic accident"].includes(
      _.get(extraInfo, "accident.type")
    ) && _.get(insuredInfo, "treatmentType") === "outpatient";

  return (
    <div>
      <RenderSpan title="Loại tai nạn" />
      <TextField
        select
        fullWidth
        value={_.get(extraInfo, "accident.type") || ""}
        sx={textField}
        onChange={(e) =>
          dispatch({
            type: "onChange",
            name: "type",
            value: e.target.value,
          })
        }
        helperText={
          errors.includes("type") ? "Vui lòng chọn loại tai nạn" : false
        }
      >
        {accidentTypes.map((item, key) => (
          <MenuItem key={key} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
      <RenderSpan title="Mức độ tai nạn" />
      <Box sx={{ display: "block" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControlLabel
            control={
              <CTCheckbox
                onChange={(e) =>
                  dispatch({
                    type: "onChange",
                    name: "level",
                    value: e.target.checked ? "dead" : null,
                  })
                }
                checked={_.get(extraInfo, "accident.level") === "dead"}
              />
            }
            label="Tử vong"
          />
          <FormControlLabel
            control={
              <CTCheckbox
                onChange={(e) =>
                  dispatch({
                    type: "onChange",
                    name: "level",
                    value: e.target.checked ? "permanent disability" : null,
                  })
                }
                checked={
                  _.get(extraInfo, "accident.level") === "permanent disability"
                }
              />
            }
            label="Thương tật vĩnh viễn"
          />
        </Box>
        <FormControlLabel
          control={
            <CTCheckbox
              onChange={(e) =>
                dispatch({
                  type: "onChange",
                  name: "level",
                  value: e.target.checked ? "temporary injury" : null,
                })
              }
              checked={
                _.get(extraInfo, "accident.level") === "temporary injury"
              }
            />
          }
          label="Thương tật tạm thời"
        />
        {errors.includes("level") && (
          <p style={p}>Vui lòng nhập đầy đủ thông tin</p>
        )}
      </Box>
      {_.get(extraInfo, "accident.type") === "traffic accident" &&
        _.get(extraInfo, "accident.level") !== "dead" && (
          <>
            <RenderSpan
              title="Có điều khiển xe trên 50cc không?"
              noRequired={true}
            />
            <Box sx={{ display: "block" }}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
                }}
              >
                <FormControlLabel
                  style={{ gridColumn: "span 6 / span 6" }}
                  control={
                    <CTCheckbox
                      onChange={(e) =>
                        dispatch({
                          type: "onChange",
                          name: "bikeType",
                          value: e.target.checked ? "yes" : null,
                        })
                      }
                      checked={_.get(extraInfo, "accident.bikeType") === "yes"}
                    />
                  }
                  label="Có"
                />
                <FormControlLabel
                  control={
                    <CTCheckbox
                      onChange={(e) =>
                        dispatch({
                          type: "onChange",
                          name: "bikeType",
                          value: e.target.checked ? "no" : null,
                        })
                      }
                      checked={_.get(extraInfo, "accident.bikeType") !== "yes"}
                    />
                  }
                  label="Không"
                />
              </Box>
            </Box>
          </>
        )}

      {_.get(extraInfo, "accident.level") !== "dead" && (
        <>
          <RenderSpan title="Loại điều trị" />
          <TextField
            select
            fullWidth
            value={insuredInfo?.treatmentType || ""}
            sx={textField}
            onChange={(e) =>
              dispatch({
                type: "onChange",
                name: "treatmentType",
                value: e.target.value,
              })
            }
            helperText={
              errors.includes("treatmentType")
                ? "Vui lòng chọn đầy đủ thông tin"
                : false
            }
          >
            {treatmentTypes.map((item, key) => (
              <MenuItem key={key} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
      {insuredInfo.treatmentType === "boarding" &&
        extraInfo?.accident?.level !== "dead" && (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={6} md={6} lg={6} xl={6} xxl={6}>
                <RenderSpan title="Ngày nhập viện" />
                <DatePicker
                  value={insuredInfo?.hospitalizeDate || null}
                  onChange={(e) => {
                    dispatch({
                      type: "onChange",
                      value: e,
                      isDate: true,
                      name: "hospitalizeDate",
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6} xxl={6}>
                <RenderSpan title="Ngày xuất viện" />
                <DatePicker
                  value={insuredInfo?.dischargeDate || null}
                  onChange={(e) =>
                    dispatch({
                      type: "onChange",
                      value: e,
                      isDate: true,
                      name: "dischargeDate",
                    })
                  }
                />
              </Grid>
            </Grid>
            {(errors.includes("hospitalizeDate") ||
              errors.includes("dischargeDate")) && (
              <p style={p}>Ngày tháng chưa hợp lệ</p>
            )}
            {errors.includes("hospitalizeDate_outdate") ? (
              <p style={p}>Ngày nhập/xuất viện không trước ngày tai nạn</p>
            ) : (
              <></>
            )}
            {errors.includes("duplicateDate") && (
              <p style={p}>Ngày nhập viện và xuất viện không trùng nhau</p>
            )}
            {errors.includes("errorDate1") && (
              <p style={p}>Không nhập trước ngày 06/05/2022</p>
            )}
          </>
        )}
      {!!bone && _.get(extraInfo, "accident.level") !== "dead" && (
        <Box sx={{ display: "block" }}>
          <FormControlLabel
            control={
              <CTCheckbox
                onChange={(e) =>
                  dispatch({
                    type: "onChange",
                    name: "relate",
                    value: e.target.checked ? "bone" : "",
                  })
                }
                checked={_.get(extraInfo, "accident.relate") === "bone"}
              />
            }
            label="Liên quan đến xương"
          />
          <FormControlLabel
            control={
              <CTCheckbox
                onChange={(e) =>
                  dispatch({
                    type: "onChange",
                    name: "relate",
                    value: e.target.checked ? null : "",
                  })
                }
                checked={_.get(extraInfo, "accident.relate") === null}
              />
            }
            label="Không liên quan đến xương"
          />
        </Box>
      )}

      <RenderSpan title="Ngày tai nạn" />
      <DatePicker
        isError={_.isEmpty(insuredInfo?.accidentDate)}
        value={insuredInfo?.accidentDate || null}
        onChange={(e) =>
          dispatch({
            type: "onChange",
            value: e,
            isDate: true,
            name: "accidentDate",
          })
        }
      />
      {errors.includes("accidentDate") && (
        <p style={p}>Ngày tháng chưa hợp lệ</p>
      )}
      {errors.includes("errorDate2") && (
        <p style={p}>Không nhập trước ngày 06/05/2022</p>
      )}
      <RenderSpan title="Nơi xảy ra tai nạn" />
      <TextField
        placeholder="Tại nhà/Công ty/Công trường ..."
        fullWidth
        sx={textField}
        value={insuredInfo?.accidentAt || ""}
        onChange={(e) =>
          dispatch({
            type: "onChange",
            value: e.target.value,
            name: "accidentAt",
          })
        }
        helperText={
          errors.includes("accidentAt")
            ? "Vui lòng nhập đầy đủ thông tin"
            : false
        }
      />
      <RenderSpan title="Diễn biến tai nạn" />
      <TextField
        fullWidth
        multiline
        rows={3}
        placeholder="Nhập nội dung ..."
        sx={textFieldMutiline}
        helperText={
          errors.includes("discription")
            ? "Vui lòng nhập đầy đủ thông tin"
            : false
        }
        value={insuredInfo?.discription || ""}
        onChange={(e) =>
          dispatch({
            type: "onChange",
            value: e.target.value,
            name: "discription",
          })
        }
      />
      <RenderSpan title="Nơi điều trị" />
      <TextField
        fullWidth
        sx={textField}
        value={insuredInfo?.text || ""}
        onChange={(e) =>
          dispatch({
            type: "onChange",
            value: e.target.value,
            name: "text",
          })
        }
        helperText={
          errors.includes("text") ? "Vui lòng nhập đầy đủ thông tin" : false
        }
        placeholder="Chọn bệnh viện điều trị"
      />
      {_.get(extraInfo, "accident.level") !== "dead" && (
        <>
          <RenderSpan title="Tổng chi phí điều trị" />
          <TextField
            fullWidth
            value={
              insuredInfo?.treatmentPrice
                ? `${insuredInfo?.treatmentPrice}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    "."
                  )
                : ""
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">VNĐ</InputAdornment>,
            }}
            inputProps={{ inputMode: "numeric" }}
            onChange={(e) => {
              if (
                !number.includes(e?.nativeEvent?.data) &&
                e?.nativeEvent?.data !== null
              )
                return;
              dispatch({
                type: "onChange",
                value: `${e.target.value}`.replace(/\./g, ""),
                name: "treatmentPrice",
              });
            }}
            placeholder="Nhập chi phí điều trị"
            sx={textFieldMutiline}
            helperText={
              errors.includes("treatmentPrice")
                ? "Vui lòng nhập đầy đủ thông tin"
                : errors.includes("treatmentPrice2")
                ? "Chi phí nhỏ nhất là 1000 vnđ"
                : false
            }
          />
        </>
      )}
      <RenderSpan title="Chẩn đoán bệnh" padding="16px 0 10px 0" />
      <div
        style={{ fontSize: "13px", lineHeaght: "16px", marginBottom: "12px" }}
      >
        Vui lòng ghi cụ thể chẩn đoán bệnh tại toa thuốc/ giấy ra viện/ hoặc
        nguyên nhân tử vong trong giấy chứng từ.
      </div>

      <TextField
        fullWidth
        multiline
        value={insuredInfo?.diagnostic || ""}
        onChange={(e) =>
          dispatch({
            type: "onChange",
            name: "diagnostic",
            value: e.target.value,
          })
        }
        rows={3}
        placeholder="Nhập nội dung ... "
        sx={textFieldMutiline}
        helperText={
          errors.includes("diagnostic")
            ? "Vui lòng nhập đầy đủ thông tin"
            : false
        }
      />
    </div>
  );
}

const textField = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "53px",
    "& legend": {
      width: 0,
    },
  },
  "& .MuiFormHelperText-root": {
    color: "#FF2929",
  },
};

const textFieldMutiline = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    "& legend": {
      width: 0,
    },
  },
  "& .MuiFormHelperText-root": {
    color: "#FF2929",
  },
};

const p = {
  color: "#FF2929",
  fontWeight: "400",
  fontSize: "0.75rem",
  lineHeight: 1.66,
  letterSpacing: "0.03333em",
  textAlign: "left",
  marginTop: "3px",
  marginRight: "14px",
  marginBottom: 0,
  marginLeft: "14px",
};
