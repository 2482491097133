import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    margin: "55px 16px 16px",
    padding: "20px",
    "& .header": {
      color: "#2A3790",
      fontWeight: "600",
      fontSize: 18,
      marginBottom: "20px",
    },
    "& .wrapper": {
      width: "40%",
      display: "flex",
      alignItems: "center",
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .search": {
      justifyContent: "space-between",
      marginBottom: 20,
    },
    "& .button": {
      background: "#3961A0",
      borderRadius: 5,
      color: "#fff",
      padding: "4px 16px",
      height: 45,
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .w-80": {
      width: "80%",
      marginRight: 16,
    },
    "& .MuiOutlinedInput-root": {
      height: 45,
    },
  },

  create: {
    margin: "55px 0px 16px",
    padding: "20px",
    "& .info": {
      background: "#fff",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      height: "fit-content",
      padding: 15,
      marginRight: 20,
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .center": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .box": {
      padding: "15px 10px",
      background: "#FFF",
      border: "1px solid #DCDCDC",
      boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "6px",
      alignItems: "center",
      justifyContent: "flex-start",
      minHeight: "120px",
      "&:hover": {
        cursor: "pointer",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
      },
    },
    "& .add-product": {
      color: "#0B7CE4",
      marginTop: 8,
    },
    "& .name-product": {
      color: "#3961A0",
      textAlign: "center",
      fontWeight: 600,
      fontSize: 18,
      marginBottom: 5,
    },
    "& .tax-code": {
      color: "#4B4B4B",
      fontWeight: 600,
      marginBottom: 10,
    },
    "& .bold": {
      fontSize: 17,
      color: "#4B4B4B",
      fontWeight: 600,
    },
    "& .value": {
      color: "#4B4B4B",
      fontWeight: 600,
      marginLeft: 10,
    },
    "& .border": {
      border: "0.5px dashed rgba(0, 0, 0, 0.25)",
    },
    "& .logo": {
      width: "55px",
      height: "55px",
      objectFit: "contain",
      marginRight: 8,
      borderRadius: "50%",
    },
    "& .logo-product": {
      width: "125px",
      height: "125px",
      objectFit: "contain",
      marginBottom: 15,
      borderRadius: "50%",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
    },
    "& .title": {
      fontWeight: 700,
      color: "#4B4B4B",
    },
    "& .date": {
      fontSize: 15,
      color: "#4B4B4B",
    },
    "& .button": {
      background: "#3961A0",
      borderRadius: 5,
      color: "#fff",
      padding: "8px 16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .data": {
      justifyContent: "space-between",
      marginTop: 15,
    },
    "& .create-date": {
      textAlign: "center",
      marginTop: 15,
    },
    "& .nowrap": {
      whiteSpace: "nowrap",
    },
    "& .break-word": {
      wordBreak: "break-word",
    },
    "& .MuiGrid-root": {
      height: "fit-content",
    },
  },

  table: {
    minWidth: "1200px",
    "& thead .center": {
      textAlign: "center",
    },
    "& thead .logo": {
      width: "150px",
      textAlign: "center",
    },
    "& thead .nv": {
      maxWidth: "100px",
    },
    "& tbody .center": {
      textAlign: "center",
    },
    "& tbody .logo": {
      width: "55px",
      height: "55px",
      borderRadius: "50%",
      objectFit: "contain",
      border: "0.5px dashed rgba(0, 0, 0, 0.25)",
    },
    "& tbody .fullname": {
      fontWeight: 600,
      color: "#3961A0",
      whiteSpace: "nowrap",
      width: "max-content",
    },
    "& tbody .date": {
      color: "#3961A0",
      textAlign: "center",
    },
    "& tbody .stt": {
      textAlign: "center",
      padding: "4px 12px",
      borderRadius: 20,
    },
    "& tbody .color1": {
      background: "#E3F3FF",
      color: "#0B7CE4",
    },
    "& tbody .color2": {
      background: "#FFECB3",
      color: "#FFA21F",
    },
    "& tbody .color3": {
      background: "#FFD3D3",
      color: "#FF003D",
    },
  },

  cellRow: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  footer: {
    marginTop: 10,
  },

  package: {
    marginTop: "55px",
    padding: "16px 0",
    "& .container": {
      display: "flex",
      flexDirection: "row",
      margin: 16,
    },
    "& .info": {
      marginRight: 16,
      background: "#fff",
      padding: 20,
      borderRadius: 8,
      flexGrow: 1,
    },
    "& .logo": {
      width: "60px",
      height: "60px",
      objectFit: "contain",
      borderRadius: "50%",
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .name": {
      fontSize: 18,
      fontWeight: 700,
      marginTop: 10,
    },
    "& .color": {
      color: "#3961A0",
      fontWeight: 500,
    },
    "& .fee": {
      background: "#3961A0",
      color: "#fff",
      padding: 15,
      borderRadius: 8,
    },
    "& .fee-text": {
      alignItems: "center",
      margin: "5px 0",
    },
    "& .ml-10": {
      marginLeft: 10,
    },
    "& .btn": {
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .add-icon": {
      margin: "8px",
    },
    "& .tabs": {
      margin: "0 16px",
    },
    "& .add-group": {
      textAlign: "center !important",
      background: "#F5F5F5 !important",
    },
    "& .title-tab": {
      color: "#3961A0",
      fontWeight: 600,
    },
    "& .fee-tab": {
      fontSize: 16,
      fontWeight: 600,
    },
    "& .wrapper": {
      background: "#fff",
      margin: "5px 16px",
      padding: 16,
    },
    "& .w-80": {
      width: "80%",
    },
    "& .mb-20": {
      marginBottom: 20,
    },
    "& .ml-10": {
      marginLeft: 10,
    },
    "& .search": {
      width: "40%",
    },
    "& .button": {
      background: "#0B7CE4",
      borderRadius: 5,
      color: "#fff",
      padding: "4px 16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .bg-gray": {
      margin: "0 0 20px",
      padding: 10,
      background: "#F8F8F8",
    },
    "& .detail": {
      margin: 16,
      background: "#fff",
      padding: 16,
      borderRadius: 8,
    },
    "& .center": {
      textAlign: "center",
    },
    "& .bold": {
      fontWeight: 600,
    },
    "& .title-info": {
      color: "#A0A0A0",
    },
    "& .MuiGrid-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      height: 35,
    },
    "& .MuiTab-root": {
      textTransform: "none",
      color: "#4B4B4B",
      fontSize: 15,
      fontWeight: 400,
      marginRight: "8px",
      borderRadius: "8px 8px 0 0",
      background: "#EAECEF",
      textAlign: "left",
      lineHeight: 1.5,
    },
    "& .Mui-selected": {
      background: "#fff",
    },
  },

  headerBar: {
    width: "100%",
    color: "#4B4B4B",
    fontSize: "15px",
    padding: "12px 0 12px 16px",
    background: "white",
    margin: "0 4px",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
    position: "sticky",
    top: "64px",
    zIndex: 2,
  },

  addDialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
    },
    "& .MuiDialogContent-root": {
      padding: "0 20px 20px",
    },
    "& .MuiOutlinedInput-root": {
      height: 45,
    },
    "& .Mui-disabled": {
      background: "#E4E4E4 !important",
    },
    "& .title": {
      color: "#686868",
      margin: "0 0 5px",
    },
    "& .center": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .button": {
      background: "#3961A0",
      color: "#fff",
      marginTop: 15,
      padding: "10px 40px",
    },
    "& .logo": {
      width: "125px",
      height: "125px",
      objectFit: "contain",
      borderRadius: "50%",
      border: "0.5px dashed rgba(0, 0, 0, 0.25)",
      "&:hover": {
        cursor: "pointer",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
      },
    },
    "& .progress": {
      width: "30%",
      maxWidth: "150px",
      marginTop: 5,
    },
    "& .label": {
      color: "#4B4B4B",
      margin: "12px 0 5px",
    },
    "& .red-text": {
      color: "red",
      fontSize: 15,
      marginTop: 4,
      fontStyle: "italic",
    },
  },

  importDialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
    },
    "& .MuiDialogContent-root": {
      padding: "0 20px",
    },
    "& .MuiOutlinedInput-root": {
      height: 45,
    },
    "& .MuiDialogActions-root": {
      padding: "16px 20px",
    },
    "& .Mui-disabled": {
      background: "#CBD5E1 !important",
    },
    "& .upload": {
      textAlign: "center",
      background: "#ECECEC",
      color: "#686868",
      borderRadius: 16,
      padding: 20,
      position: "relative",
    },
    "& .logo": {
      marginBottom: 10,
    },
    "& .bold": {
      fontWeight: 600,
      color: "#4B4B4B",
    },
    "& .text-sm": {
      fontSize: 13,
      lineHeight: 2,
    },
    "& .txt": {
      margin: "10px 0 15px",
    },
    "& .empty": {
      textAlign: "center",
      padding: "60px 0",
    },
    "& .btn-upload": {
      background: "#3961A0",
      color: "#fff",
      padding: "8px 16px",
    },
    "& .btn-cancel": {
      background: "#fff",
      color: "#3961A0",
      padding: "8px 16px",
      border: "1px solid #3961A0",
    },
    "& .drag-active": {
      backgroundColor: "#F8FAFC",
      border: "1px dashed #CBD5E1",
      borderRadius: 16,
    },
    "& .drag-file-element": {
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: 16,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },

  gridItem: {
    paddingLeft: "10px !important",
  },
}));
