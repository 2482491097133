import React, { useEffect } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { styledInfo } from "../components/styleds/styledInfor";
import { Row, Accordions, DialogShowImg } from "../components";
import { Checkbox } from "../overrideMui";
import { listTypeOfRisk, listBeneficiaryInfo, typeOfIndimnity } from "../utils";
import img from "../../../../../assets/health/img-icon.svg";
import moment from "moment";

export default function Info(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledInfo();

  const { accodiorInsuredInfor, accodiorBeneficiary, detail } = state;
  const { insuredInfo = {}, extraInfo = {}, elements = [] } = detail;

  const listInsurancedPersonInfo = [
    {
      title: "Họ và tên",
      value: detail?.insuredInfo?.insuredName,
      capitalize: true,
    },
    {
      title: "Năm sinh",
      value: moment(detail?.insuredInfo?.insuredDob).format("DD/MM/YYYY"),
      date: true,
    },
    {
      title: "Giới tính",
      value:
        detail?.insuredInfo?.insuredGender == 0
          ? "Nam"
          : detail?.insuredInfo?.insuredGender == 1
          ? "Nữ"
          : "",
    },
    {
      title: "CMND/CCCD/ Hộ chiếu",
      value: detail?.insuredInfo?.insuredPrivateId || "",
    },
    { title: "Email", value: detail?.insuredInfo?.insuredEmail || "" },
    {
      title: "Loại bảo hiểm",
      value:
        detail?.extraInfo?.typeOfInsurance == 1
          ? "Tai nạn"
          : detail?.extraInfo?.typeOfInsurance == 2
          ? "Sức khoẻ"
          : "",
    },
    {
      title: "Loại bồi thường",
      value:
        typeOfIndimnity.find((i) => i.id == detail?.extraInfo?.typeOfIndimnity)
          ?.title || "",
    },
    {
      title: "Loại rủi ro",
      value:
        listTypeOfRisk.find(
          (i) =>
            i.id ==
            (detail?.extraInfo?.typeOfRisk ||
              detail?.extraInfo?.typeOfTreatment)
        )?.title || "--",
    },
    {
      title: "Số tiền yêu cầu bồi thường",
      value:
        `${detail?.requestAmount || "0"}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          "."
        ) + " đ",
    },
  ];

  const imgSign =
    elements.find((i) => i.type_id === 518)?.extra_info?.documents || [];

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch("getListImgLicense");
  }, []);

  return (
    <div
      className={classes.container}
      style={{
        minHeight: isApp ? "calc(100vh - 175px)" : "calc(100vh - 133px)",
      }}
    >
      <Accordions
        classes={classes}
        titleHeader="Thông tin người được bảo hiểm"
        expanded={accodiorInsuredInfor}
        onClick={() =>
          dispatch({ type: "onChangeAccodior", name: "accodiorInsuredInfor" })
        }
      >
        <div style={{ padding: "0 12px" }}>
          {listInsurancedPersonInfo.map((item, index) =>
            (item?.doneShow || []).includes(extraInfo?.typeOfRisk) ? null : (
              <Row
                key={index}
                title={item.title}
                capitalize={item.capitalize}
                value={item.value || ""}
              />
            )
          )}
        </div>
        <div
          className={classes.licenseImg}
          onClick={() => dispatch({ type: "openPageShowImg", name: "img" })}
        >
          <span>Hình ảnh chứng từ</span>
          <KeyboardArrowRightIcon />
        </div>
        <div
          className={classes.licenseImg}
          onClick={() => dispatch("openLinks")}
          // onClick={() => {
          //   arrayLinks.filter((i) => !!i).length === 0
          //     ? dispatch({
          //         type: "openSnacbars",
          //         typeSnackbar: "warning",
          //         message:
          //           "Bạn chưa có Link hoá đơn điện tử nào, vui lòng thêm nếu có",
          //       })
          //     : arrayLinks.filter((i) => !!i).length === 1
          //     ? dispatch({ type: "openPageShowLink", index: 0 })
          //     : dispatch({ type: "openPageShowImg", name: "link" });
          // }}
        >
          <span>Hóa đơn điện tử</span>
          <KeyboardArrowRightIcon />
        </div>
      </Accordions>
      <Accordions
        classes={classes}
        titleHeader="Người thụ hưởng"
        expanded={accodiorBeneficiary}
        onClick={() =>
          dispatch({ type: "onChangeAccodior", name: "accodiorBeneficiary" })
        }
      >
        <div style={{ padding: "0 12px" }}>
          {listBeneficiaryInfo.map((item, index) =>
            item.name === "relationshipWithBuyerName" &&
            ![1, 2, 3].includes(
              extraInfo?.relationshipWithBuyerId ||
                insuredInfo.relationshipWithBuyerId
            ) ? null : (
              <Row
                key={index}
                title={item.title}
                value={
                  item.name === "beneficiaryBank"
                    ? extraInfo[item.name] ||
                      extraInfo.beneficiaryBankTitle ||
                      insuredInfo.beneficiaryBankTitle
                    : extraInfo[item.name] || insuredInfo[item.name]
                }
              />
            )
          )}
        </div>
        {([2, 3, 4].includes(
          extraInfo.beneficiaryId || insuredInfo.beneficiaryId
        ) ||
          (extraInfo?.relationshipWithInsuredPersonId ||
            insuredInfo?.relationshipWithInsuredPersonId) == 2) && (
          <div
            className={classes.proveRelationship}
            onClick={() => dispatch({ type: "openDaShowImg", typeId: 517 })}
          >
            <div style={{ maxWidth: "75%" }}>
              {
                " Các giấy tờ chứng minh quan hệ giữa người được bảo hiểm (CMND/CCCD, Giấy kết hôn, Giấy khai sinh, Văn bản ủy uyền...)"
              }
            </div>
            <img src={img} />
          </div>
        )}
      </Accordions>
      <div className={classes.rules}>
        <Checkbox
          style={{ marginRight: "8px", paddingTop: "3px" }}
          checked={true}
        />
        <div>
          Tôi đã đọc kỹ và chấp nhận tất cả{" "}
          <span style={{ cursor: "pointer", color: "#0B3C8A" }}>
            {" "}
            các điều khoản trong Quy tắc Bảo hiểm{" "}
          </span>
          của Công ty bảo hiểm. Tôi cam đoan tất cả các thông tin khai báo ở
          trên là đúng sự thật và hiểu rằng bất kỳ thông tin nào khai báo không
          đúng sự thật có thể sẽ dẫn đến việc Giấy chứng nhận bảo hiểm/ Hợp đồng
          bảo hiểm mất hiệu lực và/hoặc Bảo hiểm Công ty bảo hiểm có quyền từ
          chối một phần hoặc toàn bộ trách nhiệm bồi thường. Tôi đồng ý sẽ thông
          báo cho Công ty bảo hiểm về bất kỳ sự thay đổi thông tin nào{" "}
          {" (nếu có)"}. Tôi đồng ý để cho bác sỹ, bệnh viện hoặc cơ sở y tế đã
          điều trị hoặc tư vấn sức khoẻ cho tôi được cung cấp cho Công ty bảo
          hiểm mọi thông tin liên quan đến sức khoẻ của tôi nếu có sự nghi vấn,
          nhằm đáp ứng các yêu cầu về giải quyết quyền lợi bảo hiểm.
        </div>
      </div>
      <div>
        <div className={classes.signatureTitle}>
          <span>
            Chữ ký của bạn <span style={{ color: "red" }}>*</span>
          </span>
        </div>
        <div className={classes.signature}>
          <div
            style={{
              height: "180px",
              padding: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="signature"
              src={imgSign[0]?.path || ""}
              style={{ objectFit: "contain", maxWidth: "90%" }}
            />
          </div>
        </div>
      </div>
      <DialogShowImg {...{ dispatch, state }} />
    </div>
  );
}
