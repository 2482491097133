import React, { useEffect } from "react";
import { IconButton, Input } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";

import { styledReportAccident } from "../components/styeleds/styledReportAccident";
import { SteperHeader } from "../components";
import { TextField } from "../overrideMui";
import DatePicker from "../components/PickerMobile/DatePicker";
import TimePicker from "../components/PickerMobile/TimePicker";
import iconDate from "../../../../../assets/health/IconDate.svg";
import iconHours from "../../../../../assets/health/IconHours.svg";

export default function ReportedAccident(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledReportAccident();
  const { curenStep, insuranceType, insuredInfo, extraInfo, picker } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch("inittalReportedAccident");
  }, []);

  return (
    <div
      className={classes.container}
      style={{
        minHeight: isApp ? "calc(100vh - 175px)" : "calc(100vh - 130px)",
      }}
    >
      <SteperHeader activeStep={curenStep} insuranceType={insuranceType} />
      <div className={classes.title}>Tường trình tai nạn</div>
      <div className={classes.label}>
        Họ và tên người bị tai nạn <span style={{ color: "red" }}>*</span>{" "}
      </div>
      <TextField
        fullWidth
        placeholder="Nhập thông tin"
        className={classes.capitalize}
        value={insuredInfo?.insuredName || ""}
        onChange={(e) => {
          dispatch({
            type: "onChangeReportedAccident",
            value: e?.target?.value,
            name: "insuredName",
          });
        }}
      />
      <div className={classes.label}>
        Địa chỉ thường trú <span style={{ color: "red" }}>*</span>{" "}
      </div>
      <TextField
        fullWidth
        placeholder="Nhập thông tin"
        value={insuredInfo?.insuredAddress || ""}
        onChange={(e) => {
          dispatch({
            type: "onChangeReportedAccident",
            value: e?.target?.value,
            name: "insuredAddress",
          });
        }}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        style={{ margin: "8px 0" }}
      >
        <Grid item xs={6} md={6}>
          <div
            onClick={() =>
              dispatch({ type: "setOpenPicker", name: "riskTime" })
            }
          >
            <span className="sapnDate">
              Giờ xảy ra tai nạn <span style={{ color: "red" }}>*</span>
            </span>
            <div className={classes.inputDiv}>
              <Input
                placeholder="Chọn giờ"
                fullWidth
                value={
                  extraInfo.dateOfAccident
                    ? moment(extraInfo.dateOfAccident).format("HH:mm")
                    : ""
                }
                readOnly
                className="input-date"
              />
              <IconButton style={{ padding: "6px" }}>
                <img src={iconHours} alt="iconHours" />
              </IconButton>
            </div>
          </div>
        </Grid>
        <Grid item xs={6} md={6}>
          <div
            className="incident"
            onClick={() =>
              dispatch({ type: "setOpenPicker", name: "riskDate" })
            }
          >
            <span className="spanHours">
              Ngày xảy ra tai nạn<span style={{ color: "red" }}>*</span>
            </span>
            <div className={classes.inputDiv}>
              <Input
                placeholder="Chọn ngày"
                fullWidth
                value={
                  extraInfo.dateOfAccident
                    ? moment(extraInfo.dateOfAccident).format("DD/MM/YYYY")
                    : ""
                }
                readOnly
                className="input-time"
              />
              <IconButton style={{ padding: "6px" }}>
                <img src={iconDate} />
              </IconButton>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={classes.label}>
        Nơi xảy ra tai nạn <span style={{ color: "red" }}>*</span>{" "}
      </div>
      <TextField
        fullWidth
        placeholder="Nhập thông tin"
        value={extraInfo?.placeOfAccident || ""}
        onChange={(e) => {
          dispatch({
            type: "onChangeReportedAccident",
            value: e?.target?.value,
            name: "placeOfAccident",
          });
        }}
      />
      <div className={classes.label}>
        Nguyên nhân tai nạn <span style={{ color: "red" }}>*</span>{" "}
      </div>
      <TextField
        fullWidth
        placeholder="Nhập thông tin"
        multiline
        rows={3}
        className={classes.textField}
        value={extraInfo?.reason}
        onChange={(e) => {
          dispatch({
            type: "onChangeReportedAccident",
            value: e?.target?.value,
            name: "reason",
          });
        }}
      />
      <div className={classes.label}>
        Diễn biến tai nạn <span style={{ color: "red" }}>*</span>{" "}
      </div>
      <TextField
        fullWidth
        placeholder="Nhập thông tin"
        multiline
        rows={3}
        className={classes.textField}
        value={extraInfo?.accidentSituation}
        onChange={(e) => {
          dispatch({
            type: "onChangeReportedAccident",
            value: e?.target?.value,
            name: "accidentSituation",
          });
        }}
      />
      <div className={classes.label}>
        Hậu quả tai nạn <span style={{ color: "red" }}>*</span>{" "}
      </div>
      <TextField
        fullWidth
        placeholder="Nhập thông tin"
        multiline
        rows={3}
        className={classes.textField}
        value={extraInfo?.result}
        onChange={(e) => {
          dispatch({
            type: "onChangeReportedAccident",
            value: e?.target?.value,
            name: "result",
          });
        }}
      />
      <DatePicker
        // value={true}
        open={picker?.riskDate}
        // min={moment(data?.createdDate).toDate() || null}
        max={moment().toDate()}
        close={() => dispatch({ type: "setOpenPicker", name: "riskDate" })}
        onConfirm={(value) =>
          dispatch({
            type: "onChangeReportedAccident",
            name: "riskDate",
            value,
          })
        }
      />
      <TimePicker
        value={moment().format("HH:mm")}
        // maxTime={maxTime}
        open={picker?.riskTime}
        close={() => dispatch({ type: "setOpenPicker", name: "riskTime" })}
        onConfirm={(value) =>
          dispatch({
            type: "onChangeReportedAccident",
            name: "riskTime",
            value,
          })
        }
      />
    </div>
  );
}
