import { makeStyles } from "@mui/styles";

export const accountDetail = makeStyles(() => ({
  container: {
    width: "100%",
    marginTop: "45px",
    padding: "0 12px",
  },

  box_heder: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 0",
    alignItems: "center",

    "& .title-account-detail": {
      fontSize: "20px",
      fontWeight: "700",
    },

    "& .buttons-delete": {
      background: "white",
      color: "#4B4B4B",
      fontWeight: "400",
      marginRight: "12px",
      padding: "6px 16px",
      border: "1px solid #DCDCDC",
    },

    "& .buttons-save": {
      background: "#0B7CE4",
      color: "white",
      fontWeight: "400",
      padding: "6px 16px",
    },
  },

  containerTypeAccount: {
    width: "100%",
    background: "white",
  },

  typeAccount: {
    padding: "12px",
    boxShadow: "1px 2px 4px 1px #adacac",

    "& .title": {
      fontSize: "18px",
      fontWeight: "600",
      color: "#4B4B4B",
      marginBottom: "12px",
    },

    "& .label-select": {
      fontSize: "15px",
      fontWeight: "400",
      color: "#4B4B4B",
      margin: "8px 0",
    },
  },

  status: {
    padding: "12px",
    boxShadow: "1px 2px 4px 1px #adacac",
    margin: "8px 0",

    "& .title": {
      fontSize: "18px",
      fontWeight: "600",
      color: "#4B4B4B",
      marginBottom: "12px",
    },

    "& .switch": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },

    "& .label-switch": {
      fontSize: "14px",
      color: "#4B4B4B",
    },

    "& .label-date": {
      fontSize: "14px",
      color: "#4B4B4B",
      margin: "8px 0",
    },

    "& .date": {
      fontSize: "14px",
      color: "#3961A0",
    },
  },

  password: {
    padding: "12px",
    boxShadow: "1px 2px 4px 1px #adacac",
    margin: "8px 0",

    "& .title": {
      fontSize: "18px",
      fontWeight: "600",
      color: "#4B4B4B",
      marginBottom: "12px",
    },

    "& .buttons": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    "& .label": {
      fontSize: "14px",
      color: "#4B4B4B",
    },

    "& .button": {
      fontSize: "14px",
      color: "white",
      background: "#3961A0",
      fontWeight: "400",
      padding: "4px 16px",
    },

    "& .disabel": {
      background: "#A0A0A0",
    },
  },

  containerInforAccount: {
    width: "100%",
    boxShadow: "1px 1px 3px 0px #adacac",
    background: "white",
    padding: "12px",

    "& .label": {
      fontSize: "14px",
      color: "#4B4B4B",
      marginBottom: "6px",
    },

    "& .box-conten": {
      marginBottom: "18px",
    },
  },

  title_label: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#4B4B4B",
    marginBottom: "16px",
  },

  rowProduct: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center ",
    marginBottom: "8px",
  },

  textField_select: {
    width: "90px",

    "& .MuiInputBase-root": {
      paddingLeft: "8px",
    },

    "& .MuiSelect-select": {
      padding: "5px 0",
      fontSize: "14px",
    },
  },

  approval_level: {
    display: "flex",
    justifyContent: "space-between",

    "& .approval": {
      width: "48%",
      fontSize: "14px",
    },

    "& .fullwidth": {
      width: "100%",
    },
  },

  box_img_chekBox: {
    display: "flex",
    alignItems: "center",
  },

  box_show_move: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "500",
    color: "#0B7CE4",
    fontSize: "14px",
    marginTop: "12px",

    "& .move": {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      marginRight: "6px",
    },

    "& .collap": {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "6px",
    },
  },

  approval_level: {
    display: "flex",
    justifyContent: "space-between",

    "& .approval": {
      width: "48%",
      fontSize: "14px",
    },
  },

  box_img_chekBox: {
    display: "flex",
    alignItems: "center",
  },

  autocomplete: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      margin: 0,
      marginTop: "12px",
      maxHeight: "300px",

      "& .MuiAutocomplete-listbox": {
        maxHeight: "300px",
      },
    },
  },

  //
}));
