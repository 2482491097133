import { Carousel, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { AttachFileIcon, LinkUrlIcon } from "../icon/icon";
import { SendOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import axios from "axios";
import config from "./../../../../../../configs/config";

export function ImageView({
  content = null,
  index = 0,
  claimId = 0,
  type = "",
  claim,
  commentable = true,
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { register, getValues } = useForm();
  const [disabledComment, setDisabledComment] = useState(false);
  const [data, setData] = useState(content);

  const [visible, setVisible] = useState(false);

  const getData = async () => {
    const result = await axios
      .post(
        `${config.SERVER}/api/v1/tomato/claim/${claimId}/comment`,
        {
          type: type,
          docId: data.id,
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .catch((err) => err);
    if (result instanceof Error) return message.error("Lỗi kết nối server");

    setData(result.data?.result?.data);
  };

  const commentDoc = async () => {
    if (
      // (+user?.id !== +claim?.userIdReview && +user?.id !== +claim?.userIdAssign) ||
      +claim?.statusId === 5 ||
      +claim?.statusId === 7
    )
      return;
    if (disabledComment) return;

    const values = getValues();
    if (!values.content) {
      return message.error("Vui lòng nhập nội dung bổ sung");
    }

    setDisabledComment(true);
    await axios
      .post(
        `${config.SERVER}/api/v1/tomato/claim/${claimId}/comment/docs`,
        {
          type: type,
          docId: data.id,
          content: values?.content,
        },
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .catch((e) => {
        setDisabledComment(false);
        return e;
      });

    await getData();
    setDisabledComment(false);
  };

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#EBEDFF",
            borderRadius: 4,
            padding: 10,
          }}
        >
          <div>
            <AttachFileIcon style={{ marginRight: 10 }} />{" "}
            <span style={{ verticalAlign: "top" }}>
              Hình gửi {commentable ? `lần ${index + 1}` : ""} -{" "}
              {data?.createdDate || "N/A"}
            </span>
          </div>
          <div
            style={{ color: "#2A3790", cursor: "pointer" }}
            onClick={() => setVisible(true)}
          >
            Xem
          </div>
        </div>

        {data?.comment && (
          <>
            <div style={{ fontSize: 12, marginLeft: 20 }}>
              {data?.comment?.createdDate}
            </div>
            <div style={{ fontSize: 14, marginLeft: 20 }}>
              {data?.comment?.content}
            </div>
          </>
        )}
      </div>
      {!data?.comment && commentable && (
        <>
          <div
            style={{
              borderRadius: 10,
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 30px",
              background: "white",
              height: 45,
              padding: 12,
            }}
          >
            <div style={{ gridColumn: "1/4" }}>
              <input
                style={{ border: "none", outline: "none", width: "100%" }}
                {...register("content")}
                placeholder="Nhập ý kiến của bồi thường viên"
                disabled={
                  // (+user?.id !== +claim?.userIdReview && +user?.id !== +claim?.userIdAssign)
                  // ||
                  +claim?.statusId === 5 || +claim?.statusId === 7
                }
              />
            </div>
            <div style={{ cursor: "pointer" }} onClick={commentDoc}>
              <SendOutlined style={{ color: "#2A3790" }} />
            </div>
          </div>
        </>
      )}
      <Modal
        width={800}
        visible={visible}
        closable
        onCancel={() => setVisible(false)}
      >
        <Carousel>
          {data?.links?.map((link, index) => {
            return (
              <div>
                <img src={link} alt="" style={{ maxWidth: 600 }} key={index} />
              </div>
            );
          })}
        </Carousel>
      </Modal>
    </>
  );
}

function AnimalBitesBoarding({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau{" "}
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            index={index}
            claim={data}
            content={image}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp phiếu chích ngừa/chỉ định chích ngừa{" "}
      </div>
      {data?.insuredInfo?.images?.vaccinationCard?.map((image, index) => {
        return (
          <ImageView
            key={index}
            index={index}
            claim={data}
            content={image}
            claimId={data?.id}
            type={"vaccinationCard"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy ra viện{" "}
      </div>
      {data?.insuredInfo?.images?.hospitalCheckout?.map((image, index) => {
        return (
          <ImageView
            key={index}
            index={index}
            claim={data}
            content={image}
            claimId={data?.id}
            type={"hospitalCheckout"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy đơn thuốc xuất viện{" "}
      </div>
      {data?.insuredInfo?.images?.prescriptionCheckout?.map((image, index) => {
        return (
          <ImageView
            key={index}
            index={index}
            claim={data}
            content={image}
            claimId={data?.id}
            type={"prescriptionCheckout"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp bảng kê chi tiết viện phí{" "}
      </div>
      {data?.insuredInfo?.images?.detailedListHospitalFees?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"detailedListHospitalFees"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy chứng nhận phẫu thuật{" "}
      </div>
      {data?.insuredInfo?.images?.surgery?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"surgery"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.vaccinationBill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"vaccinationBill"}
          />
        );
      })}
      {/* 
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#EBEDFF', borderRadius: 4, padding: 10, marginTop: 10 }}>
      <div>
        <LinkUrlIcon style={{ marginRight: 10 }} /> <a href={data?.insuredInfo?.images?.billLink} style={{ verticalAlign: 'top', }}>{data?.insuredInfo?.images?.billLink}</a>
      </div>
      <div style={{ color: '#2A3790' }}>Xem</div>
    </div> */}
    </>
  );
}

function AnimalBitesOutPatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau{" "}
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp phiếu chích ngừa/chỉ định chích ngừa{" "}
      </div>
      {data?.insuredInfo?.images?.vaccinationCard?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"vaccinationCard"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.vaccinationBill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"vaccinationBill"}
          />
        );
      })}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#EBEDFF",
          borderRadius: 4,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div>
          <LinkUrlIcon style={{ marginRight: 10 }} />{" "}
          <a
            href={data?.insuredInfo?.images?.billLink}
            style={{ verticalAlign: "top" }}
          >
            {data?.insuredInfo?.images?.billLink}
          </a>
        </div>
        <div style={{ color: "#2A3790" }}>Xem</div>
      </div>
    </>
  );
}

function AnimalBitesDead({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau{" "}
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an điều tra có kết luận nguyên nhân tử vong/xác nhận của
        chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận
        nguyên nhân tử vong{" "}
      </div>
      {data?.insuredInfo?.images?.deadConclusion?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"deadConclusion"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>Giấy chứng tử </div>
      {data?.insuredInfo?.images?.deathCertificate?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"deathCertificate"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản phân chia di sản thừa kế có xác nhận của cơ quan chức
        năng/phường xã
      </div>
      {data?.insuredInfo?.images?.inheritanceText?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"inheritanceText"}
          />
        );
      })}
    </>
  );
}

function AnimalBitesPermanentDisability({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau{" "}
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện
        có kết luận nguyên nhân
      </div>
      {data?.insuredInfo?.images?.governmentConfirmation?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"governmentConfirmation"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận thương tật của cơ quan có thẩm quyền
      </div>
      {data?.insuredInfo?.images?.certificateInjury?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"certificateInjury"}
          />
        );
      })}
    </>
  );
}

function BurnedTemporaryInjury({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau{" "}
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc/ giấy ra viện có ghi chẩn đoán “bỏng”, độ bỏng, diện
        tích bỏng (nếu có), chữ ký bác sỹ và mộc tròn bệnh viện/ phòng khám
      </div>
      {data?.insuredInfo?.images?.diagnose?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"diagnose"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>Hoá đơn điều trị</div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#EBEDFF",
          borderRadius: 4,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div>
          <LinkUrlIcon style={{ marginRight: 10 }} />{" "}
          <a
            href={data?.insuredInfo?.images?.billLink}
            style={{ verticalAlign: "top" }}
          >
            {data?.insuredInfo?.images?.billLink}
          </a>
        </div>
        <div style={{ color: "#2A3790" }}>Xem</div>
      </div>
    </>
  );
}

function BurnedDead({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau{" "}
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an điều tra có kết luận nguyên nhân tử vong/xác nhận của
        chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận
        nguyên nhân tử vong
      </div>
      {data?.insuredInfo?.images?.deadConclusion?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"deadConclusion"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>Giấy chứng tử</div>
      {data?.insuredInfo?.images?.deathCertificate?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"deathCertificate"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản phân chia di sản thừa kế có xác nhận của cơ quan chức năng/
        phường xã
      </div>
      {data?.insuredInfo?.images?.inheritanceText?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"inheritanceText"}
          />
        );
      })}
    </>
  );
}

function BurnedPermanentDisability({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau{" "}
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện
        có kết luận nguyên nhân
      </div>
      {data?.insuredInfo?.images?.governmentConfirmation?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"governmentConfirmation"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận thương tật của cơ quan có thẩm quyền
      </div>
      {data?.insuredInfo?.images?.certificateInjury?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"certificateInjury"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>Hoá đơn điều trị</div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function AccidenWorkBoneTemporaryInjuryOutpatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau{" "}
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh
        viện/ phòng khám{" "}
      </div>
      {data?.insuredInfo?.images?.prescription?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"prescription"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp phim XQ, chỉ định chụp XQ; hình chụp phiếu đọc kết quả phim XQ
      </div>
      {data?.insuredInfo?.images?.xq?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"xq"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#EBEDFF",
          borderRadius: 4,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div>
          <LinkUrlIcon style={{ marginRight: 10 }} />{" "}
          <a
            href={data?.insuredInfo?.images?.billLink}
            style={{ verticalAlign: "top" }}
          >
            {data?.insuredInfo?.images?.billLink}
          </a>
        </div>
        <div style={{ color: "#2A3790" }}>Xem</div>
      </div>
    </>
  );
}

function AccidentWorkTemporaryInjuryOutpatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau{" "}
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh
        viện/ phòng khám{" "}
      </div>
      {data?.insuredInfo?.images?.prescription?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"prescription"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Phiếu chỉ định của bác sĩ đối với yêu cầu xét nghiệm, chiếu chụp và kết
        quả chiếu chụp-xét nghiệm{" "}
      </div>
      {data?.insuredInfo?.images?.test?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"test"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#EBEDFF",
          borderRadius: 4,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div>
          <LinkUrlIcon style={{ marginRight: 10 }} />{" "}
          <a
            href={data?.insuredInfo?.images?.billLink}
            style={{ verticalAlign: "top" }}
          >
            {data?.insuredInfo?.images?.billLink}
          </a>
        </div>
        <div style={{ color: "#2A3790" }}>Xem</div>
      </div>
    </>
  );
}

function AccidentWorkBonePermanentDisabbilityOutpatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau{" "}
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh
        viện/ phòng khám{" "}
      </div>
      {data?.insuredInfo?.images?.prescription?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"prescription"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp phim XQ, chỉ định chụp XQ; hình chụp phiếu đọc kết quả phim XQ
      </div>
      {data?.insuredInfo?.images?.xq?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"xq"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện
        có kết luận nguyên nhân{" "}
      </div>
      {data?.insuredInfo?.images?.governmentConfirmation?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"governmentConfirmation"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận thương tật của cơ quan có thẩm quyền{" "}
      </div>
      {data?.insuredInfo?.images?.certificateInjury?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"certificateInjury"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#EBEDFF",
          borderRadius: 4,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div>
          <LinkUrlIcon style={{ marginRight: 10 }} />{" "}
          <a
            href={data?.insuredInfo?.images?.billLink}
            style={{ verticalAlign: "top" }}
          >
            {data?.insuredInfo?.images?.billLink}
          </a>
        </div>
        <div style={{ color: "#2A3790" }}>Xem</div>
      </div>
    </>
  );
}

function AccidentWorkPermanentDisabbilityBoarding({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau{" "}
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy ra viện, đơn thuốc xuất viện
      </div>
      {data?.insuredInfo?.images?.prescriptionHospitalCheckout?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"prescriptionHospitalCheckout"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp bảng kê chi tiết viện phí
      </div>
      {data?.insuredInfo?.images?.detailedListHospitalFees?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"detailedListHospitalFees"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giầy chứng nhận phẫu thuật
      </div>
      {data?.insuredInfo?.images?.surgery?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"surgery"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện
        có kết luận nguyên nhân{" "}
      </div>
      {data?.insuredInfo?.images?.governmentConfirmation?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"governmentConfirmation"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận thương tật của cơ quan có thẩm quyền{" "}
      </div>
      {data?.insuredInfo?.images?.certificateInjury?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"certificateInjury"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#EBEDFF",
          borderRadius: 4,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div>
          <LinkUrlIcon style={{ marginRight: 10 }} />{" "}
          <a
            href={data?.insuredInfo?.images?.billLink}
            style={{ verticalAlign: "top" }}
          >
            {data?.insuredInfo?.images?.billLink}
          </a>
        </div>
        <div style={{ color: "#2A3790" }}>Xem</div>
      </div>
    </>
  );
}

function AccidentWorkPermanentDisabbilityOutpatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau{" "}
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh
        viện/ phòng khám{" "}
      </div>
      {data?.insuredInfo?.images?.prescription?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"prescription"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Phiếu chỉ định của bác sĩ đối với yêu cầu xét nghiệm, chiếu chụp và kết
        quả chiếu chụp-xét nghiệm{" "}
      </div>
      {data?.insuredInfo?.images?.test?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"test"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện
        có kết luận nguyên nhân
      </div>
      {data?.insuredInfo?.images?.governmentConfirmation?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"governmentConfirmation"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.treatmentBill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"treatmentBill"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận thương tật của cơ quan có thẩm quyền
      </div>
      {data?.insuredInfo?.images?.certificateInjury?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"certificateInjury"}
          />
        );
      })}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#EBEDFF",
          borderRadius: 4,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div>
          <LinkUrlIcon style={{ marginRight: 10 }} />{" "}
          <a
            href={data?.insuredInfo?.images?.billLink}
            style={{ verticalAlign: "top" }}
          >
            {data?.insuredInfo?.images?.billLink}
          </a>
        </div>
        <div style={{ color: "#2A3790" }}>Xem</div>
      </div>
    </>
  );
}

function AccidentWorkTemporaryInjuryBoarding({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy ra viện, đơn thuốc xuất viện{" "}
      </div>
      {data?.insuredInfo?.images?.prescriptionHospitalCheckout?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"prescriptionHospitalCheckout"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp bảng kê chi tiết viện phí{" "}
      </div>
      {data?.insuredInfo?.images?.detailedListHospitalFees?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"detailedListHospitalFees"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận phẫu thuật{" "}
      </div>
      {data?.insuredInfo?.images?.surgery?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"surgery"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#EBEDFF",
          borderRadius: 4,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div>
          <LinkUrlIcon style={{ marginRight: 10 }} />{" "}
          <a
            href={data?.insuredInfo?.images?.billLink}
            style={{ verticalAlign: "top" }}
          >
            {data?.insuredInfo?.images?.billLink}
          </a>
        </div>
        <div style={{ color: "#2A3790" }}>Xem</div>
      </div>
    </>
  );
}

function AccidentWorkDead({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an điều tra có kết luận nguyên nhân tử vong/xác nhận của
        chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận
        nguyên nhân tử vong
      </div>

      {data?.insuredInfo?.images?.deadConclusion?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"deadConclusion"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>Giấy chứng tử </div>
      {data?.insuredInfo?.images?.deathCertificate?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"deathCertificate"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản phân chia di sản thừa kế có xác nhận của cơ quan chức năng/
        phường xã{" "}
      </div>
      {data?.insuredInfo?.images?.inheritanceText?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"inheritanceText"}
          />
        );
      })}
    </>
  );
}

function LifeAccidentDead({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>

      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an điều tra có kết luận nguyên nhân tử vong/xác nhận của
        chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận
        nguyên nhân tử vong{" "}
      </div>
      {data?.insuredInfo?.images?.deadConclusion?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"deadConclusion"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>Giấy chứng tử </div>
      {data?.insuredInfo?.images?.deathCertificate?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"deathCertificate"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản phân chia di sản thừa kế có xác nhận của cơ quan chức năng/
        phường xã
      </div>
      {data?.insuredInfo?.images?.inheritanceText?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"inheritanceText"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Chứng từ xác minh quan hệ
      </div>
      {data?.insuredInfo?.images?.verifyRelationship?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"inheritanceText"}
          />
        );
      })}
    </>
  );
}

function LifeAccidentBoneTemporaryOutpatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh
        viện/ phòng khám{" "}
      </div>
      {data?.insuredInfo?.images?.prescription?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"prescription"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp phim XQ, chỉ định chụp XQ; hình chụp phiếu đọc kết quả phim XQ
      </div>
      {data?.insuredInfo?.images?.xq?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"xq"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function LifeAccidentTemporaryBoarding({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy ra viện, đơn thuốc xuất viện
      </div>
      {data?.insuredInfo?.images?.prescriptionHospitalCheckout?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"prescriptionHospitalCheckout"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp bảng kê chi tiết viện phí{" "}
      </div>
      {data?.insuredInfo?.images?.detailedListHospitalFees?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"detailedListHospitalFees"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận phẫu thuật{" "}
      </div>
      {data?.insuredInfo?.images?.surgery?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"surgery"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>Hoá đơn điều trị</div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function LifeAccidentTemporaryOutpatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh
        viện/ phòng khám{" "}
      </div>
      {data?.insuredInfo?.images?.prescription?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"prescription"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Phiếu chỉ định của bác sĩ đối với yêu cầu xét nghiệm, chiếu chụp và kết
        quả chiếu chụp-xét nghiệm{" "}
      </div>
      {data?.insuredInfo?.images?.test?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"test"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function LifeAccidentPermannentBoneOutpatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh
        viện/ phòng khám{" "}
      </div>
      {data?.insuredInfo?.images?.prescription?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"prescription"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp phim XQ, chỉ định chụp XQ; hình chụp phiếu đọc kết quả phim XQ
      </div>
      {data?.insuredInfo?.images?.xq?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"xq"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện
        có kết luận nguyên nhân
      </div>
      {data?.insuredInfo?.images?.governmentConfirmation?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"governmentConfirmation"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận thương tật của cơ quan có thẩm quyền
      </div>
      {data?.insuredInfo?.images?.certificateInjury?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"certificateInjury"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function LifeAccidentPermannentOutpatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh
        viện/ phòng khám{" "}
      </div>
      {data?.insuredInfo?.images?.prescription?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"prescription"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Phiếu chỉ định của bác sĩ đối với yêu cầu xét nghiệm, chiếu chụp và kết
        quả chiếu chụp-xét nghiệm{" "}
      </div>
      {data?.insuredInfo?.images?.test?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"test"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện
        có kết luận nguyên nhân
      </div>
      {data?.insuredInfo?.images?.governmentConfirmation?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"governmentConfirmation"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận thương tật của cơ quan có thẩm quyền
      </div>
      {data?.insuredInfo?.images?.certificateInjury?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"certificateInjury"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function LifeAccidentPermannentBoarding({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy ra viện, đơn thuốc xuất viện
      </div>
      {data?.insuredInfo?.images?.prescriptionHospitalCheckout?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"prescriptionHospitalCheckout"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp bảng kê chi tiết viện phí
      </div>
      {data?.insuredInfo?.images?.detailedListHospitalFees?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"detailedListHospitalFees"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy chứng nhận phẫu thuật
      </div>
      {data?.insuredInfo?.images?.surgery?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"surgery"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện
        có kết luận nguyên nhân
      </div>
      {data?.insuredInfo?.images?.governmentConfirmation?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"governmentConfirmation"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận thương tật của cơ quan có thẩm quyền
      </div>
      {data?.insuredInfo?.images?.certificateInjury?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"certificateInjury"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hoá đơn điều trị{" "}
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function TrafficAccidentTemporaryBoneOutPatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy phép lái xe và giấy tờ xe (cavet xe)
      </div>
      {data?.insuredInfo?.images?.license?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"license"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh
        viện/ phòng khám{" "}
      </div>
      {data?.insuredInfo?.images?.prescription?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"prescription"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp phim XQ, chỉ định chụp XQ; hình chụp phiếu đọc kết quả phim XQ
      </div>
      {data?.insuredInfo?.images?.xq?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"xq"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>Hoá đơn điều trị</div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function TrafficAccidentTemporaryOutPatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy phép lái xe và giấy tờ xe (cavet xe)
      </div>
      {data?.insuredInfo?.images?.license?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"license"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh
        viện/ phòng khám{" "}
      </div>
      {data?.insuredInfo?.images?.prescription?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"prescription"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Phiếu chỉ định của bác sĩ đối với yêu cầu xét nghiệm, chiếu chụp và kết
        quả chiếu chụp-xét nghiệm{" "}
      </div>
      {data?.insuredInfo?.images?.test?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"test"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>Hoá đơn điều trị</div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function TrafficAccidentTemporaryBoarding({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy phép lái xe và giấy tờ xe (cavet xe)
      </div>
      {data?.insuredInfo?.images?.license?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"license"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy ra viện, đơn thuốc xuất viện
      </div>
      {data?.insuredInfo?.images?.prescriptionHospitalCheckout?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"prescriptionHospitalCheckout"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy chứng nhận phẩu thuật
      </div>
      {data?.insuredInfo?.images?.surgery?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"surgery"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp bảng kê chi tiết viện phí
      </div>
      {data?.insuredInfo?.images?.detailedListHospitalFees?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"detailedListHospitalFees"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>Hoá đơn điều trị</div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function TrafficAccidentPermannentOutpatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy phép lái xe và giấy tờ xe (cavet xe)
      </div>
      {data?.insuredInfo?.images?.license?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"license"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh
        viện/ phòng khám{" "}
      </div>
      {data?.insuredInfo?.images?.prescription?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"prescription"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Phiếu chỉ định của bác sĩ đối với yêu cầu xét nghiệm, chiếu chụp và kết
        quả chiếu chụp-xét nghiệm{" "}
      </div>
      {data?.insuredInfo?.images?.test?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"test"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện
        có kết luận nguyên nhân
      </div>
      {data?.insuredInfo?.images?.governmentConfirmation?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"governmentConfirmation"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận thương tật của cơ quan có thẩm quyền
      </div>
      {data?.insuredInfo?.images?.certificateInjury?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"certificateInjury"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp hoá đơn điều trị
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function TrafficAccidentPermannentBoneOutpatient({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy phép lái xe và giấy tờ xe (cavet xe)
      </div>
      {data?.insuredInfo?.images?.license?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"license"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và mộc tròn bệnh
        viện/ phòng khám{" "}
      </div>
      {data?.insuredInfo?.images?.prescription?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"prescription"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp phim XQ, chỉ định chụp XQ; hình chụp phiếu đọc kết quả phim XQ
      </div>
      {data?.insuredInfo?.images?.xq?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"xq"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện
        có kết luận nguyên nhân
      </div>
      {data?.insuredInfo?.images?.governmentConfirmation?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"governmentConfirmation"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận thương tật của cơ quan có thẩm quyền
      </div>
      {data?.insuredInfo?.images?.certificateInjury?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"certificateInjury"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp hoá đơn điều trị
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function TrafficAccidentPermannentBoarding({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy phép lái xe và giấy tờ xe (cavet xe)
      </div>
      {data?.insuredInfo?.images?.license?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"license"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an/ cơ quan chức năng/ cơ quan chủ quản
      </div>
      {data?.insuredInfo?.images?.report?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"report"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy ra viện, đơn thuốc xuất viện
      </div>
      {data?.insuredInfo?.images?.prescriptionHospitalCheckout?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"prescriptionHospitalCheckout"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp bảng kê chi tiết viện phí{" "}
      </div>
      {data?.insuredInfo?.images?.detailedListHospitalFees?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"detailedListHospitalFees"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp giấy chứng nhận phẫu thuật
      </div>
      {data?.insuredInfo?.images?.surgery?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"surgery"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Xác nhận của chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện
        có kết luận nguyên nhân
      </div>
      {data?.insuredInfo?.images?.governmentConfirmation?.map(
        (image, index) => {
          return (
            <ImageView
              key={index}
              claim={data}
              content={image}
              index={index}
              claimId={data?.id}
              type={"governmentConfirmation"}
            />
          );
        }
      )}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Giấy chứng nhận thương tật của cơ quan có thẩm quyền
      </div>
      {data?.insuredInfo?.images?.certificateInjury?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"certificateInjury"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp hoá đơn điều trị
      </div>
      {data?.insuredInfo?.images?.bill?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"bill"}
          />
        );
      })}
    </>
  );
}

function TrafficAccidentDead({ data = {} }) {
  return (
    <>
      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Hình chụp CMNND/CCCD/Passport mặt trước và sau
      </div>
      {data?.insuredInfo?.images?.id?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"id"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản công an điều tra có kết luận nguyên nhân tử vong/xác nhận của
        chính quyền địa phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận
        nguyên nhân tử vong{" "}
      </div>
      {data?.insuredInfo?.images?.deadConclusion?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"deadConclusion"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>Giấy chứng tử </div>
      {data?.insuredInfo?.images?.deathCertificate?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"deathCertificate"}
          />
        );
      })}

      <div style={{ margin: "10px 0", color: "#2A3790" }}>
        Biên bản phân chia di sản thừa kế có xác nhận của cơ quan chức năng/
        phường xã
      </div>
      {data?.insuredInfo?.images?.inheritanceText?.map((image, index) => {
        return (
          <ImageView
            key={index}
            claim={data}
            content={image}
            index={index}
            claimId={data?.id}
            type={"inheritanceText"}
          />
        );
      })}
    </>
  );
}

export default function RenderAccidentType({ data = {} }) {
  switch (data?.extraInfo?.accident?.type) {
    case "animal bites":
      if (data?.extraInfo?.accident?.level === "permanent disability")
        return <AnimalBitesPermanentDisability data={data} />;

      if (data?.extraInfo?.accident?.level === "dead")
        return <AnimalBitesDead data={data} />;

      if (
        data?.extraInfo?.accident?.level === "temporary injury" &&
        data?.insuredInfo?.treatmentType === "boarding"
      )
        return <AnimalBitesBoarding data={data} />;

      if (
        data?.extraInfo?.accident?.level === "temporary injury" &&
        data?.insuredInfo?.treatmentType === "outpatient"
      )
        return <AnimalBitesOutPatient data={data} />;
      break;

    case "bruned":
      if (data?.extraInfo?.accident?.level === "temporary injury")
        return <BurnedTemporaryInjury data={data} />;

      if (data?.extraInfo?.accident?.level === "dead")
        return <BurnedDead data={data} />;

      if (data?.extraInfo?.accident?.level === "permanent disability")
        return <BurnedPermanentDisability data={data} />;
      break;

    case "work accident":
      if (
        data?.insuredInfo?.treatmentType === "outpatient" &&
        data?.extraInfo?.accident?.level === "temporary injury" &&
        data?.extraInfo?.accident?.relate === "bone"
      )
        return <AccidenWorkBoneTemporaryInjuryOutpatient data={data} />;

      if (
        data?.insuredInfo?.treatmentType === "outpatient" &&
        data?.extraInfo?.accident?.level === "temporary injury"
      )
        return <AccidentWorkTemporaryInjuryOutpatient data={data} />;

      if (
        data?.insuredInfo?.treatmentType === "boarding" &&
        data?.extraInfo?.accident?.level === "temporary injury"
      )
        return <AccidentWorkTemporaryInjuryBoarding data={data} />;

      if (
        data?.insuredInfo?.treatmentType === "outpatient" &&
        data?.extraInfo?.accident?.level === "permanent disability" &&
        data?.extraInfo?.accident?.relate === "bone"
      )
        return <AccidentWorkBonePermanentDisabbilityOutpatient data={data} />;

      if (
        data?.insuredInfo?.treatmentType === "outpatient" &&
        data?.extraInfo?.accident?.level === "permanent disability"
      )
        return <AccidentWorkPermanentDisabbilityOutpatient data={data} />;

      if (
        data?.insuredInfo?.treatmentType === "boarding" &&
        data?.extraInfo?.accident?.level === "permanent disability"
      )
        return <AccidentWorkPermanentDisabbilityBoarding data={data} />;

      if (data?.extraInfo?.accident?.level === "dead")
        return <AccidentWorkDead data={data} />;

      break;

    case "life":
      if (
        data?.insuredInfo?.treatmentType === "outpatient" &&
        data?.extraInfo?.accident?.level === "temporary injury" &&
        data?.extraInfo?.accident?.relate === "bone"
      )
        return <LifeAccidentBoneTemporaryOutpatient data={data} />; //tạm thời ngoại trú liên quan đến xương

      if (
        data?.insuredInfo?.treatmentType === "outpatient" &&
        data?.extraInfo?.accident?.level === "temporary injury"
      ) {
        return <LifeAccidentTemporaryOutpatient data={data} />; //tạm thời ngoại trú không liên quan đến xương
      }

      if (data?.extraInfo?.accident?.level === "dead")
        return <LifeAccidentDead data={data} />; //tử vong

      if (
        data?.insuredInfo?.treatmentType === "boarding" &&
        data?.extraInfo?.accident?.level === "temporary injury"
      ) {
        return <LifeAccidentTemporaryBoarding data={data} />; //tạm thời nội trú
      }

      if (
        data?.insuredInfo?.treatmentType === "outpatient" &&
        data?.extraInfo?.accident?.level === "permanent disability" &&
        data?.extraInfo?.accident?.relate === "bone"
      ) {
        return <LifeAccidentPermannentBoneOutpatient data={data} />; //vĩnh viễn ngoại trú liên quan đến xương
      }

      if (
        data?.insuredInfo?.treatmentType === "outpatient" &&
        data?.extraInfo?.accident?.level === "permanent disability"
      ) {
        return <LifeAccidentPermannentOutpatient data={data} />; //vĩnh viễn ngoại trú không liên quan đến xương
      }

      if (
        data?.insuredInfo?.treatmentType === "boarding" &&
        data?.extraInfo?.accident?.level === "permanent disability"
      ) {
        return <LifeAccidentPermannentBoarding data={data} />; // Vĩnh viễn nội trú
      }

    case "traffic accident":
      if (
        data?.insuredInfo?.treatmentType === "outpatient" &&
        data?.extraInfo?.accident?.level === "temporary injury" &&
        data?.extraInfo?.accident?.relate === "bone"
      )
        return <TrafficAccidentTemporaryBoneOutPatient data={data} />; //tạm thời ngoại trú liên quan đến xương

      if (
        data?.insuredInfo?.treatmentType === "outpatient" &&
        data?.extraInfo?.accident?.level === "temporary injury"
      )
        return <TrafficAccidentTemporaryOutPatient data={data} />; //tạm thời ngoại trú không liên quan đến xương

      if (
        data?.insuredInfo?.treatmentType === "boarding" &&
        data?.extraInfo?.accident?.level === "temporary injury"
      )
        return <TrafficAccidentTemporaryBoarding data={data} />; //tạm thời nội trú

      if (
        data?.insuredInfo?.treatmentType === "outpatient" &&
        data?.extraInfo?.accident?.level === "permanent disability" &&
        data?.extraInfo?.accident?.relate === "bone"
      ) {
        return <TrafficAccidentPermannentBoneOutpatient data={data} />; //vĩnh viễn ngoại trú liên quan đến xương
      }

      if (
        data?.insuredInfo?.treatmentType === "outpatient" &&
        data?.extraInfo?.accident?.level === "permanent disability"
      ) {
        return <TrafficAccidentPermannentOutpatient data={data} />; //vĩnh viễn ngoại trú không liên quan đến xương
      }

      if (
        data?.insuredInfo?.treatmentType === "boarding" &&
        data?.extraInfo?.accident?.level === "permanent disability"
      )
        return <TrafficAccidentPermannentBoarding data={data} />; //Vĩnh viễn nội trú

      if (data?.extraInfo?.accident?.level === "dead")
        return <TrafficAccidentDead data={data} />; //tử vong

    default:
      return <></>;
  }
}
