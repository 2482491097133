import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useHistory, useParams } from "react-router-dom";

import useReducer from "../../../../library/hooks/useReducer";
// import { TextField } from "../overrideMui/index";
import { detailProduct } from "../styles/styleDetailProduct";
import { initialStateProductDetail } from "../util";
import detailProductReducer from "../reducers/detailProductReducer";
import InfoDetailUpdateProduct from "../components/infoDetailUpdateProduct";
import UpdateProductPackageInfor from "../components/updateProductPackageInfor";
import LoadingMui from "../../../../ui/LoadingMui";
import Snackbars from "../../../../ui/snackbar";
import DialogAddBenefit from "../components/dialogAddBenefit";
import TemplateBenefitExcel from "../components/TemplateBenefitExcel";

import TableToExcel from "@linways/table-to-excel";
import DialogWarning from "../components/dialogWarning";

export default function DetailUpdateProduct() {
  const classes = detailProduct();
  const { id } = useParams();
  const { location } = useHistory();
  const history = useHistory();
  const [state, dispatch] = useReducer({
    initialState: initialStateProductDetail,
    reducer: detailProductReducer,
    name: "detailProductReducer",
    productPackage_id: id,
    productId: location?.state?.product_id,
    history,
  });
  const { loading, snackbar, count, benefits, openDialogWarning } = state;

  useEffect(() => dispatch("initial_updateDetail"), []);

  useEffect(async () => {
    if (count !== 0) {
      const table = document.getElementById("template-benefits-table");
      TableToExcel.convert(table, {
        name: `template_ma_nghiep_vu_.xlsx`,
        sheet: {
          name: "Sheet 1",
        },
      });
    }
  }, [count]);

  return (
    <div className={classes.container}>
      <Box className={classes.box_heder}>
        <div className="title-creat-product">Gói sản phẩm </div>
        <div>
          <Button
            className="buttons-delete"
            // onClick={() => dispatch("openDialogAddCompany")}
          >
            Xoá hết
          </Button>
          <Button
            className="buttons-save"
            onClick={() => dispatch("submitSaveProductPackage")}
          >
            Lưu lại
          </Button>
        </div>
      </Box>
      <Grid container sx={{ marginTop: "18px" }} spacing={1.5}>
        <Grid xs={5} sm={5} md={3.5}>
          <InfoDetailUpdateProduct {...{ state, dispatch }} />
        </Grid>
        <Grid xs={7} sm={7} md={8.5}>
          <UpdateProductPackageInfor {...{ state, dispatch }} />
        </Grid>
      </Grid>
      <DialogAddBenefit {...{ state, dispatch }} />
      <DialogWarning {...{ open: openDialogWarning, dispatch }} />
      <LoadingMui loading={Boolean(loading)} />
      <Snackbars
        open={Boolean(snackbar.open)}
        type={snackbar.type}
        message={snackbar.message}
        onClose={() => dispatch("onCloseSnackbar")}
      />
      <TemplateBenefitExcel listBenefits={benefits} />
    </div>
  );
}
