import React from "react";
import { TableCell } from "@mui/material";

import styleCI from "./styleCI";
import CommonTable from "../../../../../../library/layout/commonTable";
import { get } from "lodash";

export default function TableSick(props) {
  const classes = styleCI();
  const { state, name } = props;
  const isAvailable = name == "sick-available";

  const { detail } = state;
  const rows = () => {
    if (isAvailable) {
      const temp = get(detail, `benefitConfig.cert.disease_existed_list`) || [];
      return temp;
    }

    const temp = get(detail, `benefitConfig.cert.disease_excluded_list`) || [];

    return temp;
  };

  return (
    <div>
      <CommonTable rows={rows()} columns={columns} classes={classes} />
    </div>
  );
}

const columns = [
  {
    label: "Mã bệnh",
    headerClass: "sick-code",
    renderCell: ({ item }) => (
      <TableCell className="sick-code">{item.code || ""}</TableCell>
    ),
  },
  {
    label: "Tên bệnh",
    headerClass: "sick-name",
    renderCell: ({ item }) => (
      <TableCell className="sick-name">{item.name || ""}</TableCell>
    ),
  },
];
