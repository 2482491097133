import { Box, Container, Grid, Typography } from "@mui/material";

import imgDashboardIntro from "./assets/images/dashboard-intro.png";
import imgDashboardIntro2 from "./assets/images/dashboard-intro-2.png";

const Home = () => {
  const embeded = localStorage.getItem("embeded") || null;
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 8,
          height: "calc(100vh - 25px)",
          minHeight: "680px",
        }}
      >
        <Container
          maxWidth={false}
          style={{
            height: "calc(100% - 25px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography
              fontWeight="fontWeightBold"
              color="textPrimary"
              variant="h4"
              sx={{ marginBottom: 5 }}
            >
              Dashboard
            </Typography>

            <Grid container spacing={3}>
              <Grid item sm={6} xs={12} height="50vh">
                <Box sx={bgDashboardIntro}>
                  <img src={imgDashboardIntro} />
                </Box>
              </Grid>
              <Grid item sm={6} xs={12} height="50vh">
                <Box sx={bgDashboardIntro2}>
                  <img src={imgDashboardIntro2} />
                </Box>
              </Grid>
            </Grid>
          </div>
          <div>
            <Box>
              <Typography
                color="#024281"
                fontSize="24px"
                sx={{ marginBottom: 1 }}
              >
                {embeded == "fina.claim.admin"
                  ? "Tổng công ty cổ phần bảo hiểm hàng không"
                  : "Công ty cổ phần Global Care"}
              </Typography>
            </Box>
            <Box>
              <Typography fontSize="18px">
                {embeded == "fina.claim.admin"
                  ? "Tầng 15, Toà nhà Geleximco, 36 Hoàn Cầu, Đống Đa, Hà Nội"
                  : "1/2 Đường số 33, Phường An Khánh, Tp. Thủ Đức"}
                <br />
                Hotline:{" "}
                {embeded == "fina.claim.admin"
                  ? "(024) 6276 5555"
                  : "1900 29 29 87"}{" "}
              </Typography>
            </Box>
          </div>
        </Container>
      </Box>
    </>
  );
};

export default Home;

const bgDashboardIntro = {
  background: "rgba(236, 140, 27, 0.21)",
  boxShadow: "0px 0px 13.6464px rgba(0, 0, 0, 0.05)",
  borderRadius: "21.8343px",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const bgDashboardIntro2 = {
  background: "#C2CEEE",
  boxShadow: "0px 0px 13.6464px rgba(0, 0, 0, 0.05)",
  borderRadius: "21.8343px",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
