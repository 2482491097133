import { makeStyles } from "@mui/styles";

const styleClaim = makeStyles((theme) => ({
  container: {
    marginTop: "12px",
    background: "white",
    padding: "12px",

    "& .header-title": {
      fontWeight: "600",
      fontSize: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },

  container_profile_information: {
    background: "#F8F8F8",
    width: "100%",
    padding: "12px",
    marginBottom: "24px",
    marginTop: "8px",
    transition: "2s",
    "& .lable": {
      color: "#A0A0A0",
      marginBottom: 5,
    },
    "& .item-grid-profile-information": {
      fontSize: "14px",
    },
  },

  div: {
    marginBottom: "24px",
    marginTop: "8px",
  },

  table: {
    minWidth: "600px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      color: "#0B3C8A",
      paddingLeft: "12px",
    },

    "& td": {
      color: "##4B4B4B",
      paddingLeft: "12px",
      lineHeight: "26px",
    },

    "& th.code": {
      width: "120px",
    },

    "& td.code": {
      fontWeight: "600",
      minHeight: "37px",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#E3F3FF !important",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid white",
    background: "#F8F8F8 !important",
  },

  totalCost: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "5px 10px",
    lineHeight: "26px",
    background: "#FFA21F",
    color: "white",
    fontWeight: "600",
    marginTop: "12px",
  },

  buttons: {
    width: "100%",
    padding: "12px 0",
    display: "flex",
    justifyContent: "end",

    "& .buttons": {
      textTransform: "none",
      background: "#3961A0",
      color: "white",
      borderRadius: "12px",
      padding: "12px 24px",
      fontWeight: "600",
    },

    "& .disabled": {
      background: "#A0A0A0",
    },
  },

  clontainerDialogCodeBenefit: {
    padding: "12px 4px",
    minWidth: "550px",

    "& .box-benefits": {
      overflowY: "auto",
      height: "calc(80vh - 115px)",
      margin: "12px 0",
    },
  },

  textField: {
    "& .MuiInputBase-input": {
      padding: "6px",
      borderRadius: "8px",
    },

    "& fieldset": {
      border: "none",
      boxShadow: "inset 1px 0px 2px 1px rgb(0 0 0 / 25%)",
      borderRadius: "8px",
    },
  },

  rowDialogCodeBenefit: {
    display: "flex",
    margin: "6px 0",
    width: "100%",
    maxWidth: "550px",
    fontSize: "14px",
    alignItems: "center",
    padding: "3px 4px",
    cursor: "pointer",

    "& .checkBox": {
      paddingRight: "12px",
    },

    "& .title-benefit": {
      maxWidth: "440px",
    },

    "&:hover": {
      background: "#E3F3FF",
    },
  },

  buttonsBenefit: {
    width: "100%",
    padding: "8px 0",
    display: "flex",
    justifyContent: "center",

    "& .buttons": {
      width: "100%",
      maxWidth: "500px",
      textTransform: "none",
      background: "#3961A0",
      color: "white",
      borderRadius: "12px",
      padding: "8px 24px",
      fontWeight: "600",
    },

    "& .disabled": {
      background: "#ECECEC",
      color: "#000000",
    },
  },

  divErrors: {
    paddingLeft: "12px",
    fontSize: "13px",
    color: "red",
    fontStyle: "italic",
  },

  //
}));

export default styleClaim;
