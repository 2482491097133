import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AccountProfile from "./components/account-profile";
import AccountProfileDetails from "./components/account-profile-details";
import { getProfile_v2 } from "../../../../apis/authApis";
import LoadingMui from "../../../../ui/LoadingMui";

const AccountInfor = () => {
  const [profiles, setProfiles] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadProfiles();
  }, []);

  const loadProfiles = () => {
    setLoading(true);
    getProfile_v2()
      .then((res) => {
        setLoading(false);
        setProfiles({ ...res?.data?.result });
        localStorage.setItem("user", JSON.stringify({ ...res.data.result }));
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 8,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            Thông tin tài khoản
          </Typography>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <AccountProfile
                profiles={profiles}
                loadProfiles={loadProfiles}
                setLoading={setLoading}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "auto" }}>
              <AccountProfileDetails
                profiles={profiles}
                setProfiles={setProfiles}
                setLoading={setLoading}
              />
            </Grid>
          </Grid>
        </Container>
        <LoadingMui loading={loading} />
      </Box>
    </>
  );
};

export default AccountInfor;
