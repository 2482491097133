import moment from "moment";

export default function TableExportExcelProduct(props) {
  const { dataSearch } = props;

  const fromDate = !dataSearch?.from
    ? "..............."
    : moment(dataSearch?.from).format("DD/MM/YYYY");
  const toDate = !dataSearch?.to
    ? "..............."
    : moment(dataSearch?.to).format("DD/MM/YYYY");

  return (
    <>
      <div style={{ display: "none" }}>
        <table
          className="collapse-border"
          id={"export-data-table"}
          data-cols-width="7, 15, 25, 25, 20, 25, 15, 25, 20, 20, 20, 20, 10, 20, 25, 20"
        >
          <thead style={{ fontSize: "13" }}>
            <tr />
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"16"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                BÁO CÁO SẢN PHẨM
              </th>
            </tr>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >{`Từ ngày: ${fromDate} Đến Ngày: ${toDate} `}</th>
            </tr>
            <tr />
            <tr />
            <tr />
            <tr />
            <tr style={{ fontSize: "13" }}>
              <Th>STT</Th>
              <Th>Tên sản phẩm</Th>
              <Th>Mã sản phẩm</Th>
              <Th>Công ty bảo hiểm</Th>
              <Th>Số lượng gói</Th>
              <Th>Ngày tạo</Th>
              <Th>Ngày cập nhật</Th>
              <Th>Trạng thái</Th>
              <Th>Gói bảo hiểm</Th>
              <Th>Mã gói</Th>
              <Th>Phí bảo hiểm</Th>
              <Th>Quyển lợi</Th>
              <Th>Người tham gia</Th>
              <Th>Ngày tạo gói BH</Th>
              <Th>Ngày cập nhật gói BH</Th>
              <Th>Trạng thái gói BH</Th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13" }}>
            {props.data.map((item, index) => {
              return (
                <>
                  <tr key={index} style={{ fontSize: "13" }}>
                    <Td>{index + 1}</Td>
                    <Td>{item?.title || ""}</Td>
                    <Td>{item?.id || ""}</Td>
                    <Td>{item?.provider_title || ""}</Td>
                    <Td>{item?.number_of_packages || ""}</Td>
                    <Td>
                      {item?.created_date
                        ? moment(item?.created_date).format("DD/MM/YYYY")
                        : ""}
                    </Td>
                    <Td>
                      {item?.updated_date
                        ? moment(item?.updated_date).format("DD/MM/YYYY")
                        : ""}
                    </Td>
                    <Td>
                      {item?.status === 1
                        ? "Đang hoạt động"
                        : item?.status === 0
                        ? "Ngưng hoạt động"
                        : ""}
                    </Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                    <Td>{""}</Td>
                  </tr>
                  {(item?.packages || [])?.map((p, index) => (
                    <tr key={index} style={{ fontSize: "13" }}>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{""}</Td>
                      <Td>{p?.title}</Td>
                      <Td>{p?.id || ""}</Td>
                      <Td type="n">{p?.fees || ""}</Td>
                      <Td type="n">{p?.product_amount || ""}</Td>
                      <Td type="n">{p?.participants || ""}</Td>
                      <Td>
                        {p?.created_date
                          ? moment(p?.created_date).format("DD/MM/YYYY")
                          : ""}
                      </Td>
                      <Td>
                        {p?.updated_date
                          ? moment(p?.updated_date).format("DD/MM/YYYY")
                          : ""}
                      </Td>
                      <Td>
                        {p?.status === 1
                          ? "Đang hoạt động"
                          : p?.status === 0
                          ? "Ngưng hoạt động"
                          : ""}
                      </Td>
                    </tr>
                  ))}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const Th = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-h={"center"}
      data-a-wrap={true}
      data-f-bold={true}
    >
      {props.children}
    </th>
  );
};

const Td = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-wrap={true}
      data-t={props.type || ""}
    >
      {props.children}
    </th>
  );
};
