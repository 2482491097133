import { makeStyles } from "@mui/styles";

export const styledCCCD = makeStyles(() => ({
  container: {
    background: "#F8F8F8",
    padding: "16px",
  },

  containerCamera: {
    background: "#4B4B4B",
    padding: "16px",

    "& .div-icon-camera": {
      color: "white",
      display: "flex",
      fontSize: "14px",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "40px",
    },

    "& .div-instruction": {
      width: "calc(100% - 32px)",
      display: "flex",
      justifyContent: "center",
      bottom: "30px",
      position: "fixed",
    },

    "& .title-face-private": {
      width: "100%",
      fontSize: "22px",
      color: "white",
      fontWeight: "700",
      textAlign: "center",
      margin: "24px 0 48px 0",
    },
  },

  title: {
    fontSize: "16px",
    fontWeight: "700",
    color: "black",
    marginTop: "12px",
  },

  boxCCCDImg: {
    width: "100%",
    background: "white",
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    padding: "10px 6px",

    "& .div-img-cccd": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%",
    },
  },

  buttonImg: {
    textTransform: "none !important",
    border: "1px solid #0B3C8A !important",
    borderRadius: "10px !important",
    color: "#0B3C8A !important",
  },

  label: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#4B4B4B",
    margin: "8px 0",
  },

  box_title_camera: {
    background: "#000000",
    color: "white",
    width: "100%",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    textAlign: "center",
    fontSize: "14px",
    padding: "8px 0",
  },

  inputCapitalize: {
    "& input": { textTransform: "capitalize" },
  },

  //
}));
