import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

import { TextField, CheckBox } from "../overrideMui";
import { debounce } from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogAddCompany(props) {
  const { state, dispatch } = props;
  const classes = useMakeStyles();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const [scrollPosition, setScrollPosition] = useState({});

  const {
    dialogCreatCompany,
    usingRoleGroupBasic,
    listCompanysTPAG,
    merchants,
    merchant,
    payloadApiAutocomplete,
    loadingAutocomplete,
  } = state;

  const listCompanys =
    user?.extra_info?.userType === "tpag"
      ? [
          { name: "Merchant", id: "merchant" },
          { name: "TPAS", id: "tpas" },
        ]
      : ["tpas", "tpa"].includes(user?.extra_info?.userType)
      ? [{ name: "Merchant", id: "merchant" }]
      : [
          { name: "GC System", id: "globalcare" },
          { name: "Provider", id: "provider" },
          // { name: "GC Merchant", id: "merchant_gc" },
          { name: "Merchant", id: "merchant" },
          { name: "TPAG", id: "tpag" },
          { name: "TPAS", id: "tpas" },
          { name: "TPA", id: "tpa" },
          { name: "BVPK", id: "bvpk" },
        ];

  const onScrollListMerchant = () => {
    const element = document.getElementsByClassName("div-scroll");
    if (
      element.length > 0 &&
      +payloadApiAutocomplete?.merchant?.page * 10 <
        +payloadApiAutocomplete?.merchant?.total
    ) {
      const scrollDiv = element[0];
      const listItems = document.querySelectorAll(".scroll-item");
      const lastItem = listItems[listItems.length - 1] || null;

      if (
        Math.abs(lastItem?.offsetTop - scrollDiv.scrollTop + 70) <
          lastItem?.offsetHeight + scrollDiv.offsetHeight &&
        !loadingAutocomplete?.merchant
      ) {
        dispatch("getMoveMerchant");
        setScrollPosition({
          ...scrollPosition,
          merchant: scrollDiv.scrollHeight,
        });
      }
    }
  };

  useEffect(() => {
    const element = document.getElementsByClassName("div-scroll");
    if (element.length > 0) {
      const element_scroll = element[0];
      element_scroll.scrollTop = scrollPosition?.merchant || 0;
    }
  }, [loadingAutocomplete?.merchant]);

  return (
    <Dialog
      open={Boolean(dialogCreatCompany.open)}
      TransitionComponent={Transition}
      onClose={() => dispatch("closeDialogAddCompany")}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {" "}
        {dialogCreatCompany.update ? "Cập nhật" : "Thêm mới công ty"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => dispatch("closeDialogAddCompany")}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className={classes.content}>
          {!dialogCreatCompany.update && (
            <>
              <span className="span-title">Loại tài khoản</span>
              <TextField
                select
                fullWidth
                className="textfield-input"
                value={dialogCreatCompany?.typeAccount || ""}
                // disabled={user?.extra_info?.userType === "tpag"}
                onChange={(e) => {
                  dispatch({
                    type: "onChangeDialogCreatCompany",
                    name: "typeAccount",
                    value: e.target.value,
                  });
                }}
              >
                {listCompanys.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
          <span className="span-title">Tên công ty</span>
          {dialogCreatCompany?.typeAccount === "merchant" &&
          !dialogCreatCompany.update ? (
            <Autocomplete
              disablePortal
              className="textfield-input"
              sx={{
                minWidth: "350px",
                "& .MuiInputBase-root": {
                  padding: "1px",
                  paddingLeft: "8px",
                  paddingRight: "32px",
                },
              }}
              options={[...merchants]}
              getOptionLabel={(options) => options?.title || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Nhập hoặc chọn doanh nghiệp có sẵn"
                />
              )}
              value={merchant || null}
              onChange={(e, value, reason) => {
                dispatch({
                  type: "onChangeMerchant",
                  value,
                  reason,
                });
              }}
              onInputChange={debounce((e, value, reason) => {
                if (!["tpas"].includes(user?.extra_info?.userType)) {
                  dispatch({
                    type: "onInputChangeMerchant",
                    value,
                    reason,
                  });
                }
              }, 600)}
              noOptionsText={
                <div>
                  {loadingAutocomplete?.merchant
                    ? "Đang tải thêm ... "
                    : "Không tìm thấy dữ liệu"}
                </div>
              }
              ListboxComponent={(paramsList) => (
                <div
                  {...paramsList}
                  className={`${paramsList.className} div-scroll`}
                  style={{ maxHeight: "190px" }}
                  onScroll={onScrollListMerchant}
                >
                  {paramsList?.children?.map((i) => (
                    <li
                      {...i?.props}
                      key={i?.key}
                      className={`${i?.props?.className} scroll-item`}
                    />
                  ))}
                  {loadingAutocomplete?.merchant && (
                    <div
                      style={{
                        fontWeight: "600",
                        color: "#0B7CE4",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      Đang tải thêm ...{" "}
                    </div>
                  )}
                </div>
              )}
            />
          ) : (
            <TextField
              fullWidth
              className="textfield-input"
              value={dialogCreatCompany?.nameCompany || ""}
              onChange={(e) => {
                dispatch({
                  type: "onChangeDialogCreatCompany",
                  name: "nameCompany",
                  value: e.target.value,
                });
              }}
            />
          )}
          {["tpas"].includes(dialogCreatCompany?.typeAccount) &&
            !dialogCreatCompany.update &&
            user?.extra_info?.userType === "globalcare" && (
              <>
                <span className="span-title">Được quản lý bởi</span>
                <TextField
                  select
                  fullWidth
                  className="textfield-input"
                  value={dialogCreatCompany?.id_parent || ""}
                  onChange={(e) => {
                    dispatch({
                      type: "onChangeDialogCreatCompany",
                      name: "id_parent",
                      value: e.target.value,
                    });
                  }}
                >
                  {listCompanysTPAG.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}

          {!dialogCreatCompany.update && (
            <FormControlLabel
              sx={{ margin: 0 }}
              control={
                <CheckBox
                  className={classes.checkBox}
                  checked={usingRoleGroupBasic}
                  onChange={(e) =>
                    dispatch({
                      type: "checkUsingRoleGroupBasic",
                      value: e.target.checked,
                    })
                  }
                />
              }
              label="Sử dụng bộ phân quyền cơ bản"
            />
          )}
        </div>
        <div className={classes.buttons}>
          <Button
            className="button-save"
            onClick={() =>
              Boolean(dialogCreatCompany.update)
                ? dispatch("updateCompany")
                : dispatch("createCompany")
            }
          >
            Lưu lại{" "}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const useMakeStyles = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
      margin: "24px",
      width: "100%",
    },
  },

  title: {
    fontSize: "15px",
    margin: "16px 0",
    fontWeight: "600",
  },

  content: {
    fontSize: "14px",
    marginBottom: "16px",

    "& .textfield-input": {
      margin: "8px 0",
    },
  },

  buttons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",

    "& .button-save": {
      color: "white",
      background: "#3961A0",
      fontWeight: "500",
      padding: "8px 24px",
    },
  },

  checkBox: {
    marginRight: "12px",
  },

  //
}));
