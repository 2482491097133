import React from "react";
import { Tabs, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Status from "./right-status-tabs/status";
import Approve from "./right-status-tabs/approve";
import CheckAI from "./right-status-tabs/checkAI";
import DialogDetailPDF from "./DialogDetailPDF";

export default function RightStatusTabs(props) {
  const { state, dispatch, level_id } = props;
  const { tabRight } = state;
  const classes = makeStyle();

  const getTabById = () => {
    switch (+tabRight) {
      case 0:
        return <Status {...{ state, dispatch, level_id }} />;
      // case 1:
      //   return <CheckAI {...{ state, dispatch, level_id }} />;
      case 1:
        return <Approve {...{ state, dispatch, level_id }} />;

      default:
        break;
    }
  };

  return (
    <div className={classes.containerRightStatusTab}>
      <Tabs
        className={classes.tabsDetail}
        value={tabRight}
        onChange={(e, newValue) =>
          dispatch({ type: "onChangeTabRightStatus", newValue })
        }
        variant="scrollable"
        scrollButtons="auto"
        aria-label="basic tabs example"
      >
        <Tab label={"Trạng thái"} {...a11yProps(0)} className="buttons bt-1" />
        {/* <Tab label={"Check AI"} {...a11yProps(1)} className="buttons bt-1" /> */}
        <Tab label={"Phê duyệt"} {...a11yProps(1)} className="buttons bt-1" />
      </Tabs>
      <div>{getTabById()}</div>
      <DialogDetailPDF {...{ state, dispatch, level_id }} />
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const makeStyle = makeStyles((theme) => {
  return {
    tabsDetail: {
      borderBottom: "1px solid #3961A0",
      zIndex: 3,
      position: "sticky",
      top: 0,
      background: "white",

      "& .MuiTabScrollButton-root": {
        width: "20px",
      },

      "& .buttons": {
        fontSize: "14px",
        textTransform: "none",
        fontWeight: "500",
        color: "#3961A0",
        padding: "4px",
        fontSize: "13px",
      },

      "& .buttons.Mui-selected": {
        fontWeight: "700",
        color: "#3961A0",
        background: "white",
      },

      "& .MuiTabs-indicator": {
        background: "#3961A0",
      },
    },

    containerRightStatusTab: {
      background: "white",
      overflow: "auto",
      height: "calc(100vh - 140px)",
    },

    //
  };
});
