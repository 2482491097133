import { makeStyles } from "@mui/styles";

const styleCI = makeStyles((theme) => ({
  container: {
    marginTop: "12px",
    background: "white",
    padding: "12px",

    "& .title-packege-insurance": {
      padding: "8px 0",
      fontWeight: "600",
      fontSize: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },

  container_package_insurance: {
    background: "#F8F8F8",
    width: "100%",
    padding: "12px",
    marginBottom: "12px",

    "& .item-grid-package-insurance": {
      fontSize: "14px",
    },
  },

  div: {
    paddingBottom: "24px",
  },

  table: {
    minWidth: "900px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      fontWeight: "500",
      textAlign: "center",
      background: "none !important",
    },

    "& td": {
      textAlign: "center",
      padding: 0,
    },

    "& th.stt": {
      width: "45px",
    },

    "& th.fullname": {
      width: "150px",
    },

    "& th.cmnd": {
      width: "200px",
    },

    "& th.relationship": {
      width: "235px",
    },

    "& th.sick-code": {
      width: "150px",
      textAlign: "left",
    },

    "& th.sick-name": {
      textAlign: "left",
    },

    "& td.sick-code": {
      textAlign: "left",
      paddingLeft: "5px",
    },

    "& td.sick-name": {
      textAlign: "left",
      paddingLeft: "5px",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#ECECEC !important",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #D6DEEB",
    background: "white !important",
  },

  containeer_time_remaining: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
    background: "#F8F8F8",

    "& .div-column": {
      display: "flex",
      flexDirection: "column",
      background: "#F8F8F8",
      justifyContent: "center",
    },

    "& .head-table": {
      textAlign: "center",
      color: "#A0A0A0",
      padding: "6px",
    },

    "& .value-table": {
      textAlign: "center",
      color: "#3961A0",
      fontSize: "13px",
      fontWeight: "600",
      padding: "6px",
      paddingBottom: "24px",
    },
  },

  //
}));

export default styleCI;
