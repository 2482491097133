import { ThemeProvider, createTheme, radioClasses } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { Pie } from "react-chartjs-2";

import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";

import icon_dashboard_total from "../../../../assets/icons/Icon-dashboard-total.svg";
import icon_dashboard_claim from "../../../../assets/icons/Icon-dashboard-claim.svg";
import icon_dashboard_approve from "../../../../assets/icons/Icon-dashboar-approve.svg";
import icon_dashboard_reject from "../../../../assets/icons/Icon-dashboard-reject.svg";

import { dashboardStyle } from "../style/styleDashboard";

function formatNumber(number) {
  let integerPart = Math.floor(number).toString();
  const decimalPart = (number % 1).toFixed(1).substring(2);
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return integerPart + "," + decimalPart;
}

export default function FlagCard(props) {
  const classes = dashboardStyle();
  const { state } = props;
  const { overview } = state;

  const itemFlag = [
    {
      label: "Tổng đơn bảo hiểm",
      icon1: icon_dashboard_total,
      number: `${overview?.current_period?.cert?.total_cert || 0}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        "."
      ),
      money: `${overview?.current_period?.cert?.total_fees || 0}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        "."
      ),
      color: "#0B3C8A",
      percent:
        (((+overview?.current_period?.cert?.total_fees || 0) -
          (+overview?.previous_period?.cert?.total_fees || 0)) *
          100) /
        (+overview?.previous_period?.cert?.total_fees || 1),
    },
    {
      label: "Yêu cầu bồi thường",
      icon1: icon_dashboard_claim,
      number: `${overview?.current_period?.claim?.total_claim || 0}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        "."
      ),
      money: `${
        overview?.current_period?.claim?.total_amount_claim_request || 0
      }`.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      color: "#FFA21F",

      percent:
        (((+overview?.current_period?.claim?.total_amount_claim_request || 0) -
          (+overview?.previous_period?.claim?.total_amount_claim_request ||
            0)) *
          100) /
        (+overview?.previous_period?.claim?.total_amount_claim_request || 1),
    },
    {
      label: "Chấp nhận bồi thường",
      icon1: icon_dashboard_approve,
      number: `${
        overview?.current_period?.claim_approve?.total_claim_approve || 0
      }`.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      money: `${
        overview?.current_period?.claim_approve?.total_amount_claim_approve || 0
      }`.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      color: "#069D27",

      percent:
        (((+overview?.current_period?.claim_approve
          ?.total_amount_claim_approve || 0) -
          (+overview?.previous_period?.claim_approve
            ?.total_amount_claim_approve || 0)) *
          100) /
        (+overview?.previous_period?.claim_approve
          ?.total_amount_claim_approve || 1),
    },
    {
      label: "Từ chối bồi thường",
      icon1: icon_dashboard_reject,
      number: `${
        overview?.current_period?.claim_reject?.total_claim_reject || 0
      }`.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      money: `${
        overview?.current_period?.claim_reject?.total_amount_claim_reject || 0
      }`.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      color: "#FF003D",

      percent:
        (((+overview?.current_period?.claim_reject?.total_amount_claim_reject ||
          0) -
          (+overview?.previous_period?.claim_reject
            ?.total_amount_claim_reject || 0)) *
          100) /
        (+overview?.previous_period?.claim_reject?.total_amount_claim_reject ||
          1),
    },
  ];

  const itemGrid = { xs: 9, sm: 6, md: 4, lg: 3, xl: 2.5, xxl: 2 };

  const claimRate = {
    total_order: (
      ((overview?.current_period?.cert?.total_cert || 0) * 100) /
      ((overview?.current_period?.cert?.total_cert || 0) +
        (overview?.current_period?.claim_approve?.total_claim_approve || 0))
    ).toFixed(1),
    claim: (
      ((overview?.current_period?.claim_approve?.total_claim_approve || 0) *
        100) /
      ((overview?.current_period?.cert?.total_cert || 0) +
        (overview?.current_period?.claim_approve?.total_claim_approve || 0))
    ).toFixed(1),
    rate: formatNumber(
      ((overview?.current_period?.claim_approve?.total_claim_approve || 0) *
        100) /
        (overview?.current_period?.cert?.total_cert || 1)
    ),
  };

  const dataChart = {
    labels: [],
    datasets: [
      {
        data: [claimRate.total_order, claimRate.claim],
        backgroundColor: ["#80CE51", "#48B0EB"],
        borderColor: "white",
        borderWidth: 1,
      },
    ],
  };

  return (
    <ThemeProvider
      theme={createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 650,
            md: 800,
            lg: 900,
            xl: 1536,
            xxl: 1950,
          },
        },
      })}
    >
      <div className={classes.contaiFlagCard}>
        <Grid container spacing={0.5}>
          {itemFlag.map((item, key) => (
            <Grid item {...itemGrid}>
              <div className={classes.itemflagCard}>
                <div className="header-icon">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        color: "#4B4B4B",
                        fontWeight: "600",
                        marginBottom: "6px",
                      }}
                    >
                      {" "}
                      {item.number || 0}
                    </span>
                    <span
                      style={{
                        color: "#61646D",
                        fontWeight: "600",
                        fontSize: "12px",
                      }}
                    >
                      {item.label}
                    </span>
                  </div>
                  <div className="icon-right">
                    <img src={item.icon1} height={24} width={24} />
                  </div>
                </div>
                <div className="header-number" style={{}}>
                  <span style={{ fontSize: "20px", fontWeight: "600" }}>
                    {item.money}
                  </span>
                  <span
                    style={{
                      fontSize: "11px",
                      fontWeight: "600",
                      marginLeft: "4px",
                    }}
                  >
                    đ
                  </span>
                </div>
                <div className="header-compare">
                  {item?.percent >= 0 ? (
                    <CallMadeIcon sx={{ color: "#34C759" }} fontSize="small" />
                  ) : (
                    <CallReceivedIcon
                      sx={{ color: "#FF003D" }}
                      fontSize="small"
                    />
                  )}
                  <span
                    style={{
                      margin: "0 8px",
                      color: item?.percent >= 0 ? "#34C759" : "#FF003D",
                      fontSize: "16px",
                    }}
                  >
                    {/* {Math.abs(item.compare)} */}
                    {formatNumber(item?.percent)?.replace(/\-/g, "") + "%"}
                  </span>
                  <span style={{ color: "#6685B6", fontSize: "12px" }}>
                    {/* {
                    item?.percent < 0
                      ? `-${Math.abs(item.percent)}%`
                      : `+${Math.abs(item.percent)}%`}{" "} */}
                    so với kỳ trước
                  </span>
                </div>
              </div>
            </Grid>
          ))}
          <Grid item {...itemGrid}>
            <div
              className={`${classes.itemflagCard} ${classes.itemflagCardChart}`}
            >
              <div className="infor">
                <div style={{ color: "#686868", fontWeight: "600" }}>
                  Tỉ lệ bồi thường
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#80CE51",
                      width: "10px",
                      height: "10px",
                      borderRadius: "16px",
                      marginRight: "6px",
                    }}
                  />
                  <span style={{ fontSize: "13px" }}>Tổng đơn bảo hiểm</span>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: "#48B0EB",
                      width: "10px",
                      height: "10px",
                      borderRadius: "16px",
                      marginRight: "6px",
                    }}
                  />
                  <span style={{ fontSize: "13px" }}>Yêu cầu bồi thường</span>
                </div>
                <div style={{ fontSize: "13px" }}>
                  Tỉ lệ ước tính:{" "}
                  <span style={{ color: "#069D27" }}>
                    {claimRate?.rate ? `${claimRate?.rate}%` : ""}
                  </span>{" "}
                </div>
              </div>
              <div className="chart">
                <Pie data={dataChart} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}
