import React, { useEffect, useState } from "react";
import { debounce, get } from "lodash";
import {
  Autocomplete,
  IconButton,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import { creatProduct } from "../styles/styleCreatProduct";
import { Switch, TextField } from "../overrideMui";
import Icon_upload from "../../../../assets/icons/Icon_upload.svg";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

const contracts = [
  { label: "Quyền lợi riêng", id: "quyen_loi_rieng" },
  { label: "Quyền lợi chung", id: "quyen_loi_chung" },
];

const Loading = ({ open }) => {
  return (
    <Backdrop
      style={{
        color: "#fff",
        borderRadius: "5px",
        position: "absolute",
      }}
      open={Boolean(open)}
    >
      <CircularProgress
        color="inherit"
        style={{ width: "25px", height: "25px" }}
      />
    </Backdrop>
  );
};

export default function InfoProduc(props) {
  const classes = creatProduct();
  const { state, dispatch, id } = props;
  const [scrollPosition, setScrollPosition] = useState({});

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const userType = get(user, "extra_info.userType", "") || "";
  const {
    listProviders,
    payloadCreate,
    errors,
    listOrganization,
    listProviderParent,
    loadingAutocomplete,
    payloadListAutocomplete,
  } = state;
  const documents = get(payloadCreate, "extra_info.documents", []) || [];

  const getValueAutocomplete = (id, name) => {
    if (name === "provider_parent") {
      const temp = id
        ? listProviderParent.find((i) => i.id == id) || null
        : {
            title: payloadListAutocomplete?.provider_parent?.params?.keyword,
          };
      return temp;
    }

    if (name === "org_id") {
      const temp = id
        ? listOrganization.find((i) => i.id == id) || null
        : {
            title: payloadListAutocomplete?.org?.params?.title,
          };

      return temp;
    }

    return listProviders.find((i) => i.id == id) || null;
  };

  const onScrollListAutocomplete = (name) => {
    const elment = document.getElementsByClassName(`div-scroll-${name}`);
    const pageListAuto = get(payloadListAutocomplete, `${name}.page`) || 0;
    const totalListAuto = get(payloadListAutocomplete, `${name}.total`) || 0;

    if (elment.length > 0 && +pageListAuto * 10 < +totalListAuto) {
      const scrollDiv = elment[0];
      const listItems = document.querySelectorAll(`.scroll-item-${name}`);
      const lastItem = listItems[listItems.length - 1] || null;
      if (
        Math.abs(lastItem?.offsetTop - scrollDiv.scrollTop) <
          lastItem?.offsetHeight + scrollDiv.offsetHeight &&
        !loadingAutocomplete[name]
      ) {
        dispatch({ type: "getMoveListAutocomplete", name });
        setScrollPosition({
          ...scrollPosition,
          [name]: scrollDiv.scrollHeight,
        });
      }
    }
  };

  useEffect(() => {
    const element = document.getElementsByClassName(
      "div-scroll-provider_parent"
    );
    if (element.length > 0) {
      const element_scroll = element[0];
      element_scroll.scrollTop = scrollPosition?.provider_parent || 0;
    }
  }, [loadingAutocomplete?.provider_parent]);

  useEffect(() => {
    const element = document.getElementsByClassName("div-scroll-org");
    if (element.length > 0) {
      const element_scroll = element[0];
      element_scroll.scrollTop = scrollPosition?.org || 0;
    }
  }, [loadingAutocomplete?.org]);

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.box_infor}>
        <div className="title">Thông tin bảo hiểm</div>
        <div style={{ marginBottom: "12px" }} className={"infor-company"}>
          <div style={{ width: "100%" }}>
            <div className="label">Nhập tên sản phẩm</div>
            <TextField
              fullWidth
              placeholder="Nhập tên sản phẩm"
              error={Boolean(errors.includes("title"))}
              value={payloadCreate?.title || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChangePayload_create",
                  value: e.target.value,
                  name: "title",
                })
              }
            />
          </div>
        </div>
        <div className={"infor-company"}>
          <div style={{ width: "47%" }}>
            <div className="label">Thuộc công ty bảo hiểm</div>
            <Autocomplete
              disablePortal
              disabled={payloadCreate?.provider_id_parent && id !== "new"}
              sx={{
                "& .MuiInputBase-root": {
                  padding: "1px",
                  paddingLeft: "8px",
                  paddingRight: "32px",
                },
              }}
              options={listProviderParent}
              getOptionLabel={(options) => options?.title || ""}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Nhập hoặc chọn doanh nghiệp có sẵn"
                  error={errors.includes("provider_id_parent")}
                />
              )}
              value={
                getValueAutocomplete(
                  payloadCreate?.provider_id_parent,
                  "provider_parent"
                ) || null
              }
              onChange={(e, value, reason) => {
                if (reason === "selectOption") {
                  dispatch({
                    type: "onChangePayload_create",
                    name: "provider_id_parent",
                    value: value?.id,
                  });
                }
                if (reason === "clear") {
                  dispatch({
                    type: "resetAutocomplete",
                    name: "provider_id_parent",
                    value: null,
                  });
                  dispatch({
                    type: "clearAutocomplete",
                    name: "provider_parent",
                  });
                }
              }}
              onInputChange={debounce((e, value, reason) => {
                if (reason === "input" && value) {
                  dispatch({ type: "onInputChangeProviderParent", value });
                }
              }, 600)}
              noOptionsText={
                <div>
                  {loadingAutocomplete?.provider_parent
                    ? "Đang tải thêm ... "
                    : "Không tìm thấy dữ liệu"}
                </div>
              }
              ListboxComponent={(paramsList) => (
                <div
                  {...paramsList}
                  className={`${paramsList.className} div-scroll-provider_parent`}
                  onScroll={() => {
                    onScrollListAutocomplete("provider_parent");
                  }}
                >
                  {paramsList?.children?.map((i) => (
                    <li
                      {...i?.props}
                      key={i?.key}
                      className={`${i?.props?.className} scroll-item-provider_parent`}
                    />
                  ))}
                  {loadingAutocomplete?.provider_parent && (
                    <div
                      style={{
                        fontWeight: "600",
                        color: "#0B7CE4",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      Đang tải thêm ...{" "}
                    </div>
                  )}
                </div>
              )}
            />
          </div>
          <div style={{ width: "47%" }}>
            <div className="label">Chi nhánh</div>
            <div style={{ position: "relative" }}>
              <Autocomplete
                disabled={payloadCreate?.provider_id && id !== "new"}
                value={getValueAutocomplete(payloadCreate?.provider_id) || null}
                options={listProviders}
                getOptionLabel={(i) => i?.title || ""}
                noOptionsText={"Không tìm thấy dữ liệu"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    error={errors.includes("provider_id")}
                  />
                )}
                onChange={(e, value) =>
                  dispatch({
                    type: "onChangePayload_create",
                    name: "provider_id",
                    value: value?.id,
                  })
                }
              />
              <Loading open={loadingAutocomplete?.loadListProvider} />
            </div>
          </div>
          <div style={{ width: "47%", marginTop: "12px" }}>
            <div className="label">Loại hợp đồng bảo hiểm</div>
            <TextField
              fullWidth
              select
              disabled={id !== "new"}
              error={Boolean(errors.includes("benefit_type"))}
              value={payloadCreate?.extra_info?.benefit_type || ""}
              onChange={(e) =>
                dispatch({
                  type: "onChangePayload_create",
                  name: "benefit_type",
                  value: e.target.value,
                  is_extra_info: true,
                })
              }
            >
              {contracts.map((item, index) => (
                <MenuItem key={`item_contract_${index}`} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          {userType === "globalcare" && (
            <div style={{ width: "47%", marginTop: "12px" }}>
              <div className="label">Quản lý bởi công ty</div>
              <Autocomplete
                disablePortal
                disabled={payloadCreate?.org_id && id !== "new"}
                sx={{
                  "& .MuiInputBase-root": {
                    padding: "1px",
                    paddingLeft: "8px",
                    paddingRight: "32px",
                  },
                }}
                options={listOrganization}
                getOptionLabel={(options) => options?.title || ""}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Nhập hoặc chọn doanh nghiệp có sẵn"
                    error={errors.includes("org_id")}
                  />
                )}
                value={
                  getValueAutocomplete(payloadCreate?.org_id, "org_id") || null
                }
                onChange={(e, value, reason) => {
                  if (reason === "selectOption") {
                    dispatch({
                      type: "onChangePayload_create",
                      name: "org_id",
                      value: value.id,
                    });
                  }
                  if (reason === "clear") {
                    dispatch({
                      type: "resetAutocomplete",
                      name: "org_id",
                      value: null,
                    });
                    dispatch({
                      type: "clearAutocomplete",
                      name: "org",
                    });
                  }
                }}
                onInputChange={debounce((e, value, reason) => {
                  if (reason === "input" && value) {
                    dispatch({ type: "onInputChangeOrg", value });
                  }
                }, 600)}
                noOptionsText={
                  <div>
                    {loadingAutocomplete?.org
                      ? "Đang tải thêm ... "
                      : "Không tìm thấy dữ liệu"}
                  </div>
                }
                ListboxComponent={(paramsList) => (
                  <div
                    {...paramsList}
                    className={`${paramsList.className} div-scroll-org`}
                    onScroll={() => {
                      onScrollListAutocomplete("org");
                    }}
                  >
                    {paramsList?.children?.map((i) => (
                      <li
                        {...i?.props}
                        key={i?.key}
                        className={`${i?.props?.className} scroll-item-org`}
                      />
                    ))}
                    {loadingAutocomplete?.org && (
                      <div
                        style={{
                          fontWeight: "600",
                          color: "#0B7CE4",
                          width: "100%",
                          textAlign: "center",
                          fontSize: "14px",
                        }}
                      >
                        Đang tải thêm ...{" "}
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.box_file}>
        <div className="title">File đính kèm</div>
        <div>
          <div className="line-file-label">
            <span>Điều kiện điều khoản</span>
            <span
              className="color-file"
              onClick={() => {
                document.getElementById("terms_and_conditions").click();
              }}
            >
              <img src={Icon_upload} alt="icon_upload" /> Đính kèm file
              <input
                hidden
                type="file"
                accept=".pdf, .doc, .txt"
                id="terms_and_conditions"
                onChange={(e) => {
                  dispatch({
                    type: "upLoadImg_create",
                    e,
                    typeCode: "terms_and_conditions",
                    typeId: 1,
                  });
                  e.target.value = null;
                }}
              />
            </span>
          </div>
          {documents
            .filter((i) => i.type_code === "terms_and_conditions")
            .map((m, index) => (
              <div
                className="attached-file"
                key={`attached-file-line-${index}`}
              >
                <span
                  className="label-attached-file"
                  onClick={() => {
                    window.open(m.path);
                  }}
                >
                  <AttachFileOutlinedIcon
                    sx={{
                      color: "#0B7CE4",
                      marginRight: "6px",
                      border: "1px solid #0B7CE4",
                      borderRadius: "8px",
                      padding: "1px",
                    }}
                  />{" "}
                  {m.original_name || ""}
                </span>
                <IconButton
                  sx={{ padding: "6px" }}
                  onClick={() =>
                    dispatch({
                      type: "deleteAttachedFiel_create",
                      item: m,
                      index,
                    })
                  }
                >
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </IconButton>
              </div>
            ))}
          <div className="line-file-label">
            <span>DS Bệnh viện phòng khám liên kết</span>
            <span
              className="color-file"
              onClick={() => {
                document.getElementById("list_clinic_hospital_linked").click();
              }}
            >
              <img src={Icon_upload} alt="icon_upload" /> Đính kèm file
              <input
                hidden
                type="file"
                accept=".pdf, .doc, .txt"
                id="list_clinic_hospital_linked"
                onChange={(e) => {
                  dispatch({
                    type: "upLoadImg_create",
                    e,
                    typeCode: "list_clinic_hospital_linked",
                    typeId: 2,
                  });
                  e.target.value = null;
                }}
              />
            </span>
          </div>
          {documents
            .filter((i) => i.type_code === "list_clinic_hospital_linked")
            .map((m, index) => (
              <div
                className="attached-file"
                key={`attached-file-line-${index}`}
              >
                <span className="label-attached-file">
                  <AttachFileOutlinedIcon
                    sx={{
                      color: "#0B7CE4",
                      marginRight: "6px",
                      border: "1px solid #0B7CE4",
                      borderRadius: "8px",
                      padding: "1px",
                    }}
                  />{" "}
                  {m.original_name || ""}
                </span>
                <IconButton
                  sx={{ padding: "6px" }}
                  onClick={() =>
                    dispatch({
                      type: "deleteAttachedFiel_create",
                      item: m,
                      index,
                    })
                  }
                >
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </IconButton>
              </div>
            ))}
          <div className="line-file-label">
            <span>DS Cơ sở y tế loại trừ</span>
            <span
              className="color-file"
              onClick={() => {
                document.getElementById("list_clinic_hospital_exclude").click();
              }}
            >
              <img src={Icon_upload} alt="icon_upload" /> Đính kèm file
              <input
                hidden
                type="file"
                accept=".pdf, .doc, .txt"
                id="list_clinic_hospital_exclude"
                onChange={(e) => {
                  dispatch({
                    type: "upLoadImg_create",
                    e,
                    typeCode: "list_clinic_hospital_exclude",
                    typeId: 3,
                  });
                  e.target.value = null;
                }}
              />
            </span>
          </div>
          {documents
            .filter((i) => i.type_code === "list_clinic_hospital_exclude")
            .map((m, index) => (
              <div
                className="attached-file"
                key={`attached-file-line-${index}`}
              >
                <span className="label-attached-file">
                  <AttachFileOutlinedIcon
                    sx={{
                      color: "#0B7CE4",
                      marginRight: "6px",
                      border: "1px solid #0B7CE4",
                      borderRadius: "8px",
                      padding: "1px",
                    }}
                  />{" "}
                  {m.original_name || ""}
                </span>
                <IconButton
                  sx={{ padding: "6px" }}
                  onClick={() =>
                    dispatch({
                      type: "deleteAttachedFiel_create",
                      item: m,
                      index,
                    })
                  }
                >
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </IconButton>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
