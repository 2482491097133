import { formatDate, insuredType } from "../utils";

export default function TableExportExcelReport(props) {
  const { data } = props;

  return (
    <>
      <div style={{ display: "none" }}>
        <table
          className="collapse-border"
          id={"export-data-table"}
          data-cols-width="7, 25, 25, 15, 20, 20, 15, 15, 15, 15, 20"
        >
          <thead style={{ fontSize: "13" }}>
            <tr />
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"16"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                BÁO CÁO CHI TIẾT KHÁCH HÀNG
              </th>
            </tr>
            <tr />
            <tr />
            <tr style={{ fontSize: "13" }}>
              <Th>STT</Th>
              <Th>Họ và tên</Th>
              <Th>Mã nhân viên</Th>
              <Th>Ngày sinh</Th>
              <Th>CMND/ CCCD</Th>
              <Th>Số điện thoại</Th>
              <Th>Nhân viên/ người thân</Th>
              <Th>Ngày bắt đầu</Th>
              <Th>Ngày kết thúc</Th>
              <Th>Ngày tham gia đầu tiên</Th>
              <Th> Trạng thái</Th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13" }}>
            {data.map((item, index) => {
              return (
                <tr key={index} style={{ fontSize: "13" }}>
                  <Td>{index + 1}</Td>
                  <Td>{item?.insuredName || ""}</Td>
                  <Td>{item?.certNum || ""}</Td>
                  <Td>{item?.insuredDob || ""}</Td>
                  <Td>{item?.insuredPrivateId || ""}</Td>
                  <Td>{item?.insuredPhone || ""}</Td>
                  <Td>
                    {insuredType.find((i) => i.value === item?.insured_type)
                      ?.title || ""}
                  </Td>
                  <Td>{formatDate(item?.beginDate)}</Td>
                  <Td>{formatDate(item?.endDate)}</Td>
                  <Td>{formatDate(item?.sourceCreatedDate)}</Td>
                  <Td>
                    {item.status === 1
                      ? "Đang hoạt động"
                      : item.status === 2
                      ? "Sắp hết hạn"
                      : item.status === 3
                      ? "Hết hạn"
                      : ""}
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const Th = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-h={"center"}
      data-a-wrap={true}
      data-f-bold={true}
    >
      {props.children}
    </th>
  );
};

const Td = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-wrap={true}
    >
      {props.children}
    </th>
  );
};
