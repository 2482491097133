import React, { useEffect, useLayoutEffect } from "react";

import tag from "../../../../../../assets/health/tag.svg";
import Title from "./Title";

import { TableCell } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { CheckBox } from "../../overrideMui";
import CommonTable from "../../../../../../library/layout/commonTable";
import styleOtherTable from "./styleOtherTable";

export default function OtherTable(props) {
  const { state, dispatch, level_id } = props;
  const classes = styleOtherTable();

  const {
    otherExpan,
    detail,
    checkedAllOtherTable,
    rowsOtherTables,
    otherTablesTotal,
  } = state;

  const getDataByTypeOfIndimnity = () => {
    switch (+detail?.extraInfo?.typeOfIndimnity) {
      case 3:
        return {
          name: "injuries",
          thName: "Loại thương tật",
          rows: [],
          columns: [...columnsTable],
          title: "Tỉ lệ thương tật",
        };
      case 5:
        return {
          name: "canceOrFatalDiseaseOrStroke",
          thName: "Loại bệnh",
          rows: [],
          columns: [...columnsTable.filter((i) => !i.dontShow)],
          title: "Ung thư/ bệnh hiểm nghèo/ đột quỵ",
        };
      case 6:
        return {
          name: "deaths",
          thName: "Nguyên nhân tử vong",
          rows: [],
          columns: [...columnsTable.filter((i) => !i.dontShow)],
          title: "Tử vong",
        };
      default:
        return {};
    }
  };

  useEffect(() => {
    document.getElementById(`other-table-box`).style.height = otherExpan
      ? 0
      : `${
          document?.getElementById(`other-table`)?.getBoundingClientRect()
            ?.height + 26
        }px`;
  }, [otherExpan, rowsOtherTables?.length]);

  useLayoutEffect(() => {
    function updateSize() {
      document.getElementById(`other-table-box`).style.height = otherExpan
        ? 0
        : `${
            document?.getElementById(`other-table`)?.getBoundingClientRect()
              ?.height + 26
          }px`;
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [otherExpan, rowsOtherTables?.length]);

  return (
    <div>
      <Title
        title={getDataByTypeOfIndimnity()?.title || ""}
        unEdit={level_id !== 1}
        // addIcon={level_id === 1 ? tag : false}
        expan={!!otherExpan}
        // onClickAdd={() =>
        //   dispatch({
        //     type: "openDialogCodeBenefits",
        //     name: "otherTable",
        //   })
        // }
        onEdit={() =>
          dispatch({
            type: "openDialogUpdata",
            name: "otherTable",
          })
        }
        onExpan={() => dispatch({ type: "expanBox", name: "otherExpan" })}
      />
      <div
        className={classes.container_diagnosis}
        id={`other-table-box`}
        style={{
          opacity: otherExpan ? 0 : 1,
          padding: otherExpan ? 0 : "",
          margin: otherExpan ? "8px 0" : "",
          MozTransition: "all 0.7s ease",
          WebkitTransition: "all 0.7s ease",
          OTransition: "all 0.7s ease",
          transition: "all 0.7s ease",
        }}
      >
        <div id={"other-table"}>
          <CommonTable
            classes={classes}
            rows={rowsOtherTables}
            columns={getDataByTypeOfIndimnity()?.columns || []}
            data={{
              thName: getDataByTypeOfIndimnity()?.thName || "",
              checkedAllOtherTable,
              error: false,
              level_id,
            }}
            event={(type, data) =>
              (ele, ...rest) => {
                dispatch({ type, data, ele, rest });
              }}
          />
          <Grid container className={classes.gridBottom}>
            <Grid item xs={7.5}>
              <div className="sum">Tổng phí</div>
            </Grid>
            <Grid item xs={4.5}>
              <div className="money">
                {`${otherTablesTotal || 0}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  "."
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

const columnsTable = [
  {
    renderHeader: ({ data: { checkedAllOtherTable }, event }) => (
      <TableCell className="check-box">
        <CheckBox
          key="all"
          checked={!!checkedAllOtherTable}
          onChange={event("checkAllTable", { name: "otherTable" })}
        />
      </TableCell>
    ),
    renderCell: ({ item, event, data: { name }, rowIdx }) => (
      <TableCell className="check-box">
        <CheckBox
          key={`${item.id || ""}_${rowIdx}`}
          checked={!!item.checked}
          onChange={event("checkItemTable", {
            name: "otherTable",
            index: rowIdx,
          })}
        />
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data }) => (
      <TableCell className="name-service">{data?.thName || ""}</TableCell>
    ),
    renderCell: ({ rowIdx, item }) => (
      <TableCell className="name-service">
        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          {!!item?.codeBenefit && (
            <div
              title={item?.titleBenefit}
              style={{
                marginRight: "8px",
                padding: "0 12px",
                background: "#D6DEEB",
                borderRadius: "18px",
                display: "flex",
                width: "44%",
                height: "26px",
                maxWidth: "400px",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "6px" }}>
                {item?.codeBenefit || ""}
              </span>
              <div
                style={{
                  fontWeight: "600",
                  color: "#4B4B4B",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item?.titleBenefit || ""}
              </div>
            </div>
          )}
          <div>{item.otherText || ""}</div>
        </div>
      </TableCell>
    ),
  },
  {
    label: "Tỉ lệ (%)",
    headerClass: "quantity",
    dontShow: true,
    renderCell: ({ rowIdx, classes, event, data, item }) => (
      <TableCell className="quantity">{+item.percent || 0}</TableCell>
    ),
  },
  {
    label: "Quyền lợi",
    headerClass: "unit-price",
    renderCell: ({ rowIdx, classes, event, data, item }) => (
      <TableCell className="unit-price">
        {`${item.benefitAmount || ""}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      </TableCell>
    ),
  },
  {
    label: "Thành tiền",
    headerClass: "money",
    renderCell: ({ rowIdx, classes, event, data, item }) => (
      <TableCell className="money">
        {/* <NumberFormat
          disabled={data.level_id !== 1}
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          className={classes.textField}
          onChange={event(`benefitsOnChangText`, {
            index: rowIdx,
            name: "fee",
          })}
          value={item.fee || ""}
          fullWidth
        /> */}
        {`${item.fee || ""}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      </TableCell>
    ),
  },
];
