import React, { useEffect } from "react";
import { Box, Button, Unstable_Grid2 as Grid } from "@mui/material";
import { ControlPoint as ControlPointIcon } from "@mui/icons-material";

import useReducer from "../../../library/hooks/useReducer";
import ListCompany from "./components/listCompany";
import GroupRoles from "./components/GroupRoles";
import { useStyles } from "./styles/style-index";
import decentralizaReducer from "./decentralizaReducer";
import { initialState } from "./util";
import DialogAddCompany from "./components/dialogAddCompany";
import LoadingMui from "../../../ui/LoadingMui";
import Snackbars from "../../../ui/snackbar";
import { get } from "lodash";

export default function Decentraliza() {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const classes = useStyles();
  const [state, dispatch, clearStateGlobal] = useReducer({
    initialState,
    name: "decentralizaReducer",
    reducer: decentralizaReducer,
  });

  const { loading, snackbar } = state;

  useEffect(() => {
    dispatch("initail");
    return () => clearStateGlobal();
  }, []);

  const roleCreate =
    get(user, "role_management_claim.privileges.ROLE_GROUP_MANAGE") === 1;

  return (
    <div className={classes.container}>
      <Box className={classes.box_heder}>
        <div className="title-decentraliza">Quản lý phân quyền</div>
        {roleCreate && get(user, "extra_info.userType") !== "merchant" && (
          <div>
            <Button
              disabled={!roleCreate}
              className={
                roleCreate
                  ? "buttons-add-decentraliza"
                  : "buttons-add-decentraliza disabled"
              }
              onClick={() => dispatch("openDialogAddCompany")}
            >
              <ControlPointIcon fontSize="small" className="icon-button" />
              Tạo phân quyền mới
            </Button>
          </div>
        )}
      </Box>
      <Grid container className={classes.grid_container}>
        <Grid xs={3}>
          <ListCompany {...{ state, dispatch }} />
        </Grid>
        <Grid xs={9}>
          <GroupRoles {...{ state, dispatch, roleCreate, user }} />
        </Grid>
      </Grid>
      <DialogAddCompany {...{ state, dispatch }} />
      <LoadingMui loading={loading} />
      <Snackbars
        open={Boolean(snackbar.open)}
        type={snackbar.type}
        message={snackbar?.message}
        onClose={() => dispatch("onCloseSnackbar")}
      />
    </div>
  );
}
