import React, { useEffect } from "react";
import { Button, Card, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { PDFDownloadLink } from "@react-pdf/renderer";
import _ from "lodash";

import Snackbars from "../../../../../ui/snackbar";
import useReducer from "../../../../../library/hooks/useReducer";
import CardInfor from "../components/CardInfor";
import CardDocument from "../components/CardDocument";
import CardHistory from "../components/CardHistory";
import ShowImgDialog from "../components/DialogShowImg";
import detailReducer from "../reducer/detailReducer";
import ConfirmDialog from "../components/confirmDialog";
import { useStyles } from "../styles/useStyles";
import { getParamUrlByKey } from "../../../../../utils/utils";

import EyeIcon from "../assets/eyeIcon.svg";
import Download from "../assets/software-download.svg";
import ExportPDF from "../components/ExportPDF";

const initialState = {
  rows: [],
  dataExport: [],
  documentsAdditionalFile: [],
  row: {},
  showFileEvidence: {},
  showImg: {},
  dataSearch: {},
  documentsAdditionalImg: [],
  errors: {},
  pageTable: {
    count: 0,
    page: 0,
    rowsPerPage: 10,
  },
  snackbar: {},
  count: 0,
  approvalAmount: "",
  content: "",
  setPage: "compensation",
  loading: false,
  confirmDialog: false,
  documentAudio: [],
  loadAudio: false,
};

export default function DetailReport(props) {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const roleType = user?.extra_info?.userType;
  const { form, embeded } = props;
  const id = getParamUrlByKey("id");

  const [state, dispatch] = useReducer({
    initialState,
    reducer: detailReducer,
    name: "detailReducer",
    roleType,
    id,
  });
  const {
    row,
    loading,
    confirmDialog,
    snackbar,
    initStatusId,
    statusId,
    updated,
  } = state;

  const statusCurent = () => {
    switch (row.statusId) {
      case 2:
        return { color: "#26A9E0", text: "Đang xử lý" };
      case 4:
        return { color: "#FB7800", text: "Bổ sung hồ sơ" };
      case 5:
        return { color: "#39B44A", text: "Phê duyệt" };
      case 6:
        return { color: "#39B44A", text: "Hoàn tất" };
      case 7:
        return { color: "#BF272D", text: "Từ chối" };

      default:
        break;
    }
  };

  useEffect(() => dispatch("initial"), []);

  let dataFilePdf = null;

  return (
    <div
      className={classes.container}
      style={{ marginTop: embeded ? "6px" : "55px" }}
    >
      <div className="head-title">
        {form === "review-claim"
          ? "Kiểm Tra Xử Lý Hồ Sơ/"
          : "Báo Cáo Bồi Thường/"}{" "}
        <span style={{ textTransform: "capitalize" }}>
          {row?.insuredInfo?.insuredName}{" "}
        </span>
      </div>
      <div style={{ fontSize: "18px", color: "#000000", fontWeight: "600" }}>
        Trạng thái:{" "}
        <span
          style={{
            color: statusCurent()?.color,
          }}
        >
          {" " + statusCurent()?.text}{" "}
        </span>
      </div>
      <Card sx={{ padding: "6px", marginTop: "25px" }}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} sm={12} md={4}>
            <Card className={classes.infor}>
              <CardInfor row={row} dispatch={dispatch} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card className={classes.document}>
              <CardDocument {...{ form, state, dispatch }} />
            </Card>
            <div className={classes.exportButton}>
              <Button
                className="bt-show"
                onClick={() => {
                  window.open(row?.insuredInfo?.certLink || "");
                }}
              >
                <img src={EyeIcon} style={{ marginRight: "6px" }} />
                Xem Chứng Nhận Bảo Hiểm
              </Button>
              {roleType === "globalcare" && (
                <>
                  <div style={{ display: "none" }}>
                    <PDFDownloadLink
                      document={<ExportPDF data={row} />}
                      fileName="Hồ sơ yêu cầu bồi thường.pdf"
                    >
                      {({ blob, url, loading: loadingPDF, error }) => {
                        dataFilePdf = blob;
                      }}
                    </PDFDownloadLink>
                  </div>
                  <Button
                    onClick={() =>
                      dispatch({ type: "downLoadZip", file: dataFilePdf })
                    }
                    style={{
                      textDecoration: "none",
                      backgroundColor: "#FB7800",
                      fontWeight: "600",
                      fontSize: "14px",
                      borderRadius: "8px",
                      padding: "6px",
                      background: "#FB7800",
                      color: "white",
                    }}
                  >
                    <img src={Download} style={{ marginRight: "6px" }} />
                    Tải Hồ Sơ
                  </Button>
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card className={classes.history}>
              <CardHistory {...{ form, state, dispatch }} />
            </Card>
          </Grid>
        </Grid>
      </Card>
      <div className={classes.row}>
        <Button className={classes.button} onClick={() => dispatch("back")}>
          Quay lại
        </Button>
        {form === "review-claim" && (
          <Button
            disabled={
              statusId === 2 ||
              initStatusId === 7 ||
              initStatusId === 6 ||
              updated
            }
            className={
              statusId === 2 ||
              initStatusId === 7 ||
              initStatusId === 6 ||
              updated
                ? "disabled button"
                : "button"
            }
            onClick={() => dispatch("update")}
          >
            Cập nhật
          </Button>
        )}
      </div>
      <ShowImgDialog {...{ dispatch, state }} />
      <ConfirmDialog {...{ dispatch, open: confirmDialog, loading }} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbars
        open={snackbar.open}
        type={snackbar.type}
        onClose={() => dispatch("closeSnackbar")}
        message={snackbar.message}
      />
    </div>
  );
}
