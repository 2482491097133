import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { ListItemText, ListItemButton, ListItemIcon } from "@mui/material";
import { useHistory } from "react-router-dom";

import { useRecoilState } from "recoil";
import { roleAdminMenuItem } from "../../../stateRecoil/atom";

export default function ItemRender(props) {
  const {
    path,
    title,
    icon,
    paddingChild,
    selected,
    name,
    setSelected,
    data = {},
  } = props;

  const [roleAdminMenuItems, setRoleAdminMenuItems] =
    useRecoilState(roleAdminMenuItem);

  const history = useHistory();
  const hanldeClick = () => {
    const user = JSON.parse(localStorage.getItem("user")) || {};
    const userType = !user ? "" : user?.extra_info?.userType;
    localStorage.setItem(
      "roleAdminMenuItem",
      JSON.stringify({ ...data, userType })
    );
    setRoleAdminMenuItems({ ...data, userType });
    // history.push(path);
  };
  return (
    <Link
      to={{
        pathname: path,
        search: data?.product_id_parent
          ? `product_id_parent=${data?.product_id_parent}`
          : "",
        query: { ...data },
      }}
      style={{ color: "#121828" }}
      key={title}
      onClick={() => {
        hanldeClick();
        setSelected(name);
      }}
    >
      <ListItemButton
        selected={
          (window.location.pathname.includes(path) && selected === name) ||
          selected === name
        }
        sx={{ pl: paddingChild ? 4 : "" }}
      >
        <ListItemIcon>
          {icon && <img alt="icon" src={icon} height="32px" width="32px" />}
        </ListItemIcon>
        <ListItemText
          primary={title}
          className="phatdt-ottt"
          sx={{ "& .MuiTypography-root": { fontSize: "15px" } }}
        />
      </ListItemButton>
    </Link>
  );
}
