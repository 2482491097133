import { message } from "antd";
import _ from "lodash";
import { SSF, read, utils } from "xlsx";
import {
  createContract,
  getOrganizationList,
  getProductList,
  getListMerchant,
  createMerchant,
} from "../../../../apis/gcnApis";
import moment from "moment";

const parseExcelDate = (excelDate, name = null) => {
  if (!excelDate) {
    return null;
  }

  const dateValue = SSF.parse_date_code(excelDate);
  const date = new Date(dateValue.y, dateValue.m - 1, dateValue.d);

  // if (name === "insuredDob") {
  //   const newdate = moment(date).format("YYYY/MM/DD");
  //   return newdate;
  // }

  const newdate = moment(date).format("YYYY/MM/DD");
  return newdate;
};

const readFile = (dispatch, file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = read(event.target.result, { type: "binary" });
      const sheets = wb.SheetNames;

      if (sheets.length) {
        const data = utils.sheet_to_json(_.get(wb, `Sheets[${sheets[0]}]`));

        const rows = data.slice(1); //skip header
        if (rows.length > 1) {
          const formattedData = rows.map((row) => ({
            insuredName: row["HỌ VÀ TÊN"],
            certNum: row["Số thẻ BH"],
            insuredDob: parseExcelDate(row["Ngày sinh"]),
            insuredPrivateId: row["CMND/CCCD"]?.toString(),
            // insuredPhone: row[""],
            insuredType: row["NHÂN VIÊN/ NGƯỜI THÂN"],
            beginDate: parseExcelDate(row["NGÀY THAM GIA BH ĐẦU TIÊN"]),
            endDate: parseExcelDate(row["__EMPTY"]),
          }));
          dispatch({ type: "setList", formattedData });
        }
      }
      resolve("done");
    };
    reader.readAsArrayBuffer(file);
  });
};

const delay = () => {
  return new Promise((resole) => {
    setTimeout(() => {
      resole("done");
    }, 800);
  });
};

export default function createGCNReducer(props) {
  const { state, action, dispatch, history } = props;
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const userTypeFromProfile = user?.extra_info?.userType || "";

  const cases = {
    getDataList: async () => {
      const res = {};
      const getOrgTypes =
        userTypeFromProfile === "globalcare"
          ? ["tpas", "tpa"]
          : userTypeFromProfile === "tpag"
          ? ["tpas"]
          : ["tpa", "tpas"].includes(userTypeFromProfile)
          ? null
          : null;
      dispatch("onLoading");
      const [prodResponse, orgaResponse] = await Promise.all([
        getProductList({
          statusId: 1,
          limit: 10,
          offset: 0,
        }),
        getOrganizationList({
          limit: 10,
          offset: 0,
          org_types: getOrgTypes,
        }),
      ]);

      if (prodResponse instanceof Error || orgaResponse instanceof Error) {
        message.error("Có lỗi xảy ra", 2);
        return { loading: false };
      }

      res.products = _.get(prodResponse, "data.result.data") || [];
      res.organizes = _.get(orgaResponse, "data.result.data") || [];
      res.payloadListAutocomplete = {
        org: {
          page: 1,
          total: _.get(orgaResponse, "data.result.total"),
          param: { org_types: getOrgTypes },
        },
        product: {
          page: 1,
          total: _.get(prodResponse, "data.result.total"),
          param: { statusId: 1 },
        },
      };
      res.loading = false;
      res.addInfo = {
        orgId: _.get(orgaResponse, "data.result.data[0].id") || "",
      };
      if (["tpas", "tpa"].includes(userTypeFromProfile)) {
        res.addInfo = {
          orgId: _.get(user, "extra_info.org_id") || null,
        };
      }

      const merchantResponse = await getListMerchant({
        orgId: res?.addInfo?.orgId || null,
        limit: 10,
        offset: 0,
      });

      res.merchants = _.get(merchantResponse, "data.result.data") || [];

      return res;
    },

    onChangeAdd: () => {
      const { addUserData } = state;
      const { name, value } = action;
      addUserData[name] = value;
      return { addUserData };
    },

    onChangeInfo: async () => {
      const { addInfo, payloadListAutocomplete } = state;
      const { name, value } = action;
      addInfo[name] = value;
      const res = {};

      if (name === "orgId") {
        dispatch("onLoading");
        _.set(payloadListAutocomplete, "org.param.title", null);
        const merchantResponse = await getListMerchant({
          orgId: value || null,
          limit: 20,
          offset: 0,
        });

        res.payloadListAutocomplete = {
          ...payloadListAutocomplete,
          merchant: {
            page: 1,
            total: _.get(merchantResponse, "data.result.total"),
            param: { orgId: value || null },
          },
        };
        res.merchant = {};
        res.merchants = [
          ...(_.get(merchantResponse, "data.result.data") || []),
        ];
        addInfo.tax_code = "";
      }

      if (name === "productId") {
        addInfo.productId = value?.id;
        addInfo.productIdParent = value?.product_id_parent;
        addInfo.providerId = value?.provider_id;
        res.packages = [...(value?.packages || [])];
        addInfo.productPackageId = "";
      }

      return { addInfo, ...res, loading: false, payloadListAutocomplete };
    },

    onInputChangeOrg: async () => {
      const { value } = action;
      const { payloadListAutocomplete, loadingAutocomplete } = state;

      await dispatch({ type: "resetAutocomplete", value, name: "org" });

      dispatch({ type: "onLoadingAutocomplete", name: "org" });
      const orgaResponse = await getOrganizationList({
        limit: 20,
        offset: 0,
        ...(payloadListAutocomplete?.org?.param || {}),
        title: value || null,
      });
      const res = {};
      await delay();
      if (_.get(state, "payloadListAutocomplete.org.param.title") !== value) {
        return;
      }

      res.organizes = [...(_.get(orgaResponse, "data.result.data") || [])];
      res.payloadListAutocomplete = {
        ...payloadListAutocomplete,
        org: {
          ...payloadListAutocomplete.org,
          page: 1,
          total: _.get(orgaResponse, "data.result.total"),
        },
      };

      loadingAutocomplete.org = false;

      return { loadingAutocomplete, ...res };
    },

    onChangeMerchant: () => {
      const { reason, value } = action;
      const { addInfo } = state;
      if (reason === "reset") {
        return;
      }

      addInfo.tax_code = value?.extra_info?.taxCode || null;
      return { merchant: { ...(value || {}) }, addInfo };
    },

    onInputChangeMerchant: async () => {
      const { value } = action;
      const { payloadListAutocomplete, loadingAutocomplete, addInfo } = state;

      await dispatch({ type: "resetAutocomplete", value, name: "merchant" });

      dispatch({ type: "onLoadingAutocomplete", name: "merchant" });
      const merchantsApi = await getListMerchant({
        limit: 10,
        offset: 0,
        ...(payloadListAutocomplete?.merchant?.param || {}),
        title: value || null,
        orgId: addInfo?.orgId || null,
      });
      const res = {};
      await delay();
      if (_.get(state, "payloadListAutocomplete.merchant.title") !== value) {
        return;
      }

      res.merchants = [...(_.get(merchantsApi, "data.result.data") || [])];
      res.payloadListAutocomplete = {
        ...payloadListAutocomplete,
        merchant: {
          ...payloadListAutocomplete.merchant,
          page: 1,
          total: _.get(merchantsApi, "data.result.total"),
        },
      };

      loadingAutocomplete.merchant = false;

      return { loadingAutocomplete, ...res };
    },

    onInputChangeProduct: async () => {
      const { value } = action;
      const { payloadListAutocomplete, loadingAutocomplete } = state;

      await dispatch({ type: "resetAutocomplete", value, name: "product" });

      dispatch({ type: "onLoadingAutocomplete", name: "product" });

      const productApi = await getProductList({
        limit: 10,
        offset: 0,
        ...(payloadListAutocomplete?.merchant?.param || {}),
        keyword: value || null,
      });
      const res = {};
      await delay();
      if (_.get(state, "payloadListAutocomplete.product.keyword") !== value) {
        return;
      }

      res.products = [...(_.get(productApi, "data.result.data") || [])];
      res.payloadListAutocomplete = {
        ...payloadListAutocomplete,
        product: {
          ...payloadListAutocomplete.product,
          page: 1,
          total: _.get(productApi, "data.result.total"),
          param: {
            ...(payloadListAutocomplete?.product?.param || {}),
            keyword: value || null,
          },
        },
      };

      loadingAutocomplete.product = false;

      return { loadingAutocomplete, ...res };
    },

    resetAutocomplete: async () => {
      const { value, name } = action;
      const { addInfo, payloadListAutocomplete } = state;

      const res = {};

      if (name === "org") {
        _.set(payloadListAutocomplete, "org.param.title", value);
        addInfo.orgId = null;
      }
      if (name === "merchant") {
        res.merchant = value ? { title: value } : {};
        addInfo.tax_code = null;
        _.set(payloadListAutocomplete, "merchant.title", value);
      }

      if (name === "product") {
        _.set(payloadListAutocomplete, "product.keyword", value);
        // addInfo.productId = null;
        // addInfo.productIdParent = null;
        // addInfo.providerId = null;
        // res.packages = [];
        // addInfo.productPackageId = "";

        // dispatch({ type: "onLoadingAutocomplete", name: "product" });
        // const dataApi = await getProductList({
        //   limit: 20,
        //   offset: 0,
        //   statusId: 1,
        // });
        // res.products = [...(_.get(dataApi, "data.result.data") || [])];
        // res.payloadListAutocomplete = {
        //   ...payloadListAutocomplete,
        //   product: {
        //     page: 1,
        //     total: _.get(dataApi, "data.result.total"),
        //     param: { statusId: 1 },
        //   },
        // };
        // loadingAutocomplete.product = false;
      }

      return { payloadListAutocomplete, addInfo, ...res };
    },

    getMoveListOrg: async () => {
      const { payloadListAutocomplete, organizes, loadingAutocomplete } = state;

      dispatch({ type: "onLoadingAutocomplete", name: "org" });
      const orgaResponse = await getOrganizationList({
        limit: 10,
        offset: payloadListAutocomplete?.org?.page * 10 || 0,
        ...(payloadListAutocomplete?.org?.param || {}),
      });
      const res = {};

      res.organizes = [
        ...organizes,
        ...(_.get(orgaResponse, "data.result.data") || []),
      ];
      res.payloadListAutocomplete = {
        ...payloadListAutocomplete,
        org: {
          page: payloadListAutocomplete?.org?.page + 1,
          total: _.get(orgaResponse, "data.result.total"),
          param: { ...(payloadListAutocomplete?.org?.param || {}) },
        },
      };

      loadingAutocomplete.org = false;

      return { loadingAutocomplete, ...res };
    },

    getMoveListAutocomplete: async () => {
      const { name } = action;
      const {
        payloadListAutocomplete,
        merchants,
        loadingAutocomplete,
        products,
        addInfo,
      } = state;

      dispatch({ type: "onLoadingAutocomplete", name });

      const dataApi =
        name === "merchant"
          ? await getListMerchant({
              limit: 10,
              offset: payloadListAutocomplete?.merchant?.page * 10 || 0,
              ...(payloadListAutocomplete?.merchant?.param || {}),
              orgId: addInfo?.orgId || null,
            })
          : await getProductList({
              limit: 10,
              offset: payloadListAutocomplete?.product?.page * 10 || 0,
              ...(payloadListAutocomplete?.product?.param || {}),
            });
      const res = {};

      if (name === "merchant") {
        res.merchants = [
          ...merchants,
          ...(_.get(dataApi, "data.result.data") || []),
        ];
        res.payloadListAutocomplete = {
          ...payloadListAutocomplete,
          merchant: {
            page: payloadListAutocomplete?.merchant?.page + 1,
            total: _.get(dataApi, "data.result.total"),
            param: { ...(payloadListAutocomplete?.merchant?.param || {}) },
          },
        };
      }

      if (name === "product") {
        res.products = [
          ...products,
          ...(_.get(dataApi, "data.result.data") || []),
        ];
        res.payloadListAutocomplete = {
          ...payloadListAutocomplete,
          product: {
            page: payloadListAutocomplete?.product?.page + 1,
            total: _.get(dataApi, "data.result.total"),
            param: { ...(payloadListAutocomplete?.product?.param || {}) },
          },
        };
      }
      loadingAutocomplete[name] = false;

      return { loadingAutocomplete, ...res };
    },

    openDialogUserCreateGCN: () => {
      const { dialogAddUser } = state;
      const { name } = action;
      return { dialogAddUser: { ...dialogAddUser, open: name } };
    },

    closeDialogUserCreateGCN: () => {
      const { dialogAddUser } = state;
      const res = {};
      res.dialogAddUser = { ...dialogAddUser, open: null };
      res.addUserData = {};
      res.errAddUser = [];
      return res;
    },

    closeDialogImportExcel: () => {
      const { dialogAddUser } = state;
      const res = {};
      res.dialogAddUser = { ...dialogAddUser, open: null };
      res.importList = [];
      res.dragActive = false;
      return res;
    },

    addUser: () => {
      const { addUserData, listOfInsuredPeople, dialogAddUser } = state;
      const res = {};
      const errAddUser = [];
      !addUserData.insuredName && errAddUser.push("insuredName");
      !addUserData.insuredDob && errAddUser.push("insuredDob");
      ![0, 1].includes(addUserData.insuredGender) &&
        errAddUser.push("insuredGender");
      // !addUserData.insuredPhone && errAddUser.push("insuredPhone");
      !addUserData.insuredPrivateId && errAddUser.push("insuredPrivateId");
      !addUserData.insuredType && errAddUser.push("insuredType");
      !addUserData.certNum && errAddUser.push("certNum");
      !addUserData.beginDate && errAddUser.push("beginDate");
      !addUserData.endDate && errAddUser.push("endDate");

      if (errAddUser.length > 0) {
        return { errAddUser };
      }
      res.listOfInsuredPeople = [...listOfInsuredPeople, addUserData];
      res.addUserData = {};
      res.dialogAddUser = { ...dialogAddUser, open: null };
      return { ...res, errAddUser };
    },

    onLoading: () => ({ loading: true }),

    resetContract: () => {
      const res = {};
      res.addInfo = {};
      res.merchant = {};
      res.listOfInsuredPeople = [];
      return res;
    },

    createContract: async () => {
      const { addInfo, listOfInsuredPeople, merchant } = state;

      const errInfo = [];
      !addInfo.certNumGroup && errInfo.push("certNumGroup");
      // !addInfo.orgId && errInfo.push("orgId");
      !merchant.title && errInfo.push("merchant");
      !addInfo.productId && errInfo.push("productId");
      !addInfo.tax_code && errInfo.push("tax_code");
      !addInfo.productPackageId && errInfo.push("productPackageId");

      if (errInfo.length > 0) {
        return { errInfo };
      }

      const p = {
        productIdParent: addInfo?.productIdParent,
        providerId: addInfo?.providerId,
        productId: addInfo?.productId,
        productPackageId: addInfo?.productPackageId,
        merchantId: merchant?.id,
        orgId: addInfo?.orgId,
        certNumGroup: addInfo?.certNumGroup,
        listOfInsuredPeople,
        extra_info: {
          tax_code: addInfo?.tax_code,
        },
      };
      dispatch("onLoading");
      const res = {};

      if (!merchant?.id) {
        const createMerchantApi = await createMerchant({
          title: merchant.title,
          orgId: addInfo?.orgId,
          extraInfo: {
            taxCode: addInfo?.tax_code,
            // merchantType: "tpa_cert",
          },
        });
        if (createMerchantApi?.data?.statusCode !== 200) {
          message.error("Có lỗi xảy ra", 2);
          return { loading: false };
        }

        p.merchantId = _.get(createMerchantApi, "data.result.id");
        res.merchant = _.get(createMerchantApi, "data.result") || {};
      }

      const response = await createContract(p);

      if (response instanceof Error) {
        message.error("Có lỗi xảy ra", 2);
        return { loading: false, ...res };
      }
      if (response?.data?.statusCode === 200) {
        message.success("Thêm mới GCN thành công", 3);
        history.push(`/admin/gcn`);
        return { errInfo: [], loading: false };
      }
    },

    onUploading: () => ({ uploading: true }),

    handleDrag: () => {
      const { e } = action;
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        return { dragActive: true };
      } else if (e.type === "dragleave") {
        return { dragActive: false };
      }
    },

    inActive: () => ({ dragActive: false }),

    handleDrop: async () => {
      const { e } = action;
      e.preventDefault();
      e.stopPropagation();
      dispatch("inActive");
      if (e.dataTransfer.files.length < 1) {
        return;
      }
      if (e.dataTransfer.files.length > 1) {
        return message.error("Tải lên từng file.", 3);
      }
      const file = e.dataTransfer.files[0];
      const extension = file?.name.split(".")[1];

      if (!["xls", "xlsx"].includes(extension)) {
        return message.error("Chỉ hỗ trợ tải file excel.", 3);
      }

      dispatch("onUploading");
      await readFile(dispatch, file);

      return { file, uploading: false };
    },

    handleChange: async () => {
      const { e } = action;
      e.preventDefault();
      if (e.target.files.length < 1) {
        return;
      }
      const file = e.target.files[0];
      dispatch("onUploading");
      await readFile(dispatch, file);
      return { file, uploading: false };
    },

    setList: () => ({ importList: action.formattedData }),

    importExcel: async () => {
      const { listOfInsuredPeople, importList, dialogAddUser } = state;
      const res = {};
      message.success("Cập nhật file thành công", 2);
      res.listOfInsuredPeople = [...listOfInsuredPeople, ...importList];
      res.dialogAddUser = { ...dialogAddUser, open: null };
      res.importList = [];
      res.saving = false;
      return res;
    },

    onLoadingAutocomplete: () => ({
      loadingAutocomplete: {
        ...state.loadingAutocomplete,
        [action.name]: true,
      },
    }),

    //
  };

  return _.isString(action.type) && cases[action.type];
}
