import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  List,
  ListItem,
  Paper,
  Modal,
  Box,
  Popover,
  IconButton,
  Button,
} from "@mui/material";
import moment from "moment";
import { useRecoilState } from "recoil";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import tomatoIcon from "../../../../../assets/health/tomato-icon.svg";
import familyCareIcon from "../../../../../assets/health/family-icon.svg";
import easyCareIcon from "../../../../../assets/health/easy-care-ht-icon.svg";
import bhnIcon from "../../../../../assets/health/bhn-ht-icon.svg";
import logoDefaul from "../../../../../assets/health/logo_defaul.svg";
import { debounce } from "lodash";
import { listProductClaim, notification } from "../../../stateRecoil/atom";
import useReducer from "../../../../../library/hooks/useReducer";
import modalReducer from "./modalReducer";

const initialState = {
  notificationList: [],
  pageNoti: {},
};

export const ModalNotification = (props) => {
  const { showModalNotification, onClose } = props;
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const [listProductClaims, setListProductClaims] =
    useRecoilState(listProductClaim);
  const [notificationes, setNotificationes] = useRecoilState(notification);
  const history = useHistory();

  const [state, dispatch] = useReducer({
    initialState,
    reducer: modalReducer,
    name: "modalReducer",
    listProductClaims,
    history,
    user,
    onClose,
    setNotificationes,
    notificationes,
  });

  const { loading, notificationList, pageNoti, popoverNoti } = state;

  const ref = useRef(null);

  useEffect(() => {
    if (showModalNotification) {
      dispatch("initial");
    }
  }, [showModalNotification]);

  const getNameProduct = (id) => {
    return listProductClaims.find((i) => i.product_id == id)?.title || "";
  };

  const onScroll = () => {
    if (ref.current) {
      const scrollDiv = ref.current;

      const lastItem = scrollDiv.querySelector(
        "#divScroll .scroll-item:last-child"
      );
      const { offsetTop, offsetHeight } = lastItem;
      if (
        Math.abs(offsetTop - scrollDiv.scrollTop) <
        offsetHeight + scrollDiv.offsetHeight
      ) {
        dispatch("onPaging");
      }
    }
  };

  const refButton = useRef(null);

  return (
    <Modal
      open={showModalNotification}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box style={modalAccount}>
          {
            <div style={{ position: "relative" }}>
              <Box
                sx={{
                  width: "100%",
                  padding: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{}}>{`Mới (${
                  pageNoti?.totalUnread || "0"
                })`}</span>
                <IconButton
                  ref={refButton}
                  onClick={() => {
                    dispatch("openPopoverNoti");
                  }}
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </Box>
              <Popover
                open={popoverNoti}
                onClose={() => dispatch("closePopoverNoti")}
                anchorEl={refButton?.current}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
              >
                <div style={{ width: "250px", padding: "4px" }}>
                  <Button
                    sx={{ width: "100%", color: "#284078" }}
                    onClick={() => dispatch("markAllRead")}
                  >
                    Đã xem tất cả
                  </Button>
                </div>
              </Popover>
              <Paper
                style={{ overflow: "auto", height: "78vh" }}
                id="divScroll"
                ref={ref}
                onScroll={debounce(onScroll, 300)}
              >
                {(notificationList || [])?.length > 0 ? (
                  <List sx={{ maxHeight: 750 }} alignItems="start">
                    {notificationList.map((notification, i) => (
                      <ListItem
                        onClick={(e) =>
                          dispatch({
                            type: "handleClickNoti",
                            notification,
                          })
                        }
                        divider={i < notificationList.length - 1}
                        key={notification.id}
                        className="scroll-item"
                        style={{
                          background:
                            notification.is_read == 0
                              ? "rgb(82 115 136 / 14%)"
                              : "",
                          marginTop: "4px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            cursor: "pointer",
                          }}
                        >
                          <div style={{ width: "45px", margin: "0 6px" }}>
                            <img
                              alt={notification.title}
                              src={
                                notification?.url_logo ||
                                getLogoById(
                                  +notification?.extra_info?.productIdParent ||
                                    0
                                )
                              }
                              style={{
                                height: 30,
                                width: 30,
                              }}
                            />
                          </div>
                          <div style={{ width: "99%", lineHeight: "24px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingRight: "8px",
                              }}
                            >
                              <span
                                style={{ fontSize: "15px", fontWeight: "600" }}
                              >
                                {getNameProduct(
                                  notification?.extra_info?.productIdParent
                                )}
                              </span>
                              <span
                                style={{
                                  fontSize: "13px",
                                  color: getStatusById(
                                    notification?.extra_info?.claimStatusId
                                  ).color,
                                }}
                              >
                                {
                                  getStatusById(
                                    notification?.extra_info?.claimStatusId
                                  ).label
                                }
                              </span>
                            </div>
                            <div style={{ color: "#686868", fontSize: "13px" }}>
                              Mã HS:{" "}
                              {` ${notification?.extra_info?.claimCode} ${
                                getStatusById(
                                  notification?.extra_info?.claimStatusId
                                ).label
                              }`}
                            </div>
                            <div style={{ color: "#686868", fontSize: "13px" }}>
                              Họ và tên:{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {" " + notification?.extra_info?.insuredName}
                              </span>
                            </div>
                            <div
                              style={{
                                color:
                                  moment().diff(
                                    moment(notification.created_date),
                                    "minutes"
                                  ) > 59
                                    ? "#686868"
                                    : "#0B7CE4",
                                fontSize: "13px",
                              }}
                            >
                              {moment().diff(
                                moment(notification.created_date),
                                "minutes"
                              ) > 59
                                ? moment(notification.created_date).format(
                                    "HH:MM, DD/MM/YYYY"
                                  )
                                : moment().diff(
                                    moment(notification.created_date),
                                    "minutes"
                                  ) + " Phút trước"}
                            </div>
                          </div>
                        </div>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <div style={noNotification}>Hiện chưa có thông báo nào</div>
                )}
              </Paper>
              {loading && <div style={noNotification}>Đang tải thêm ...</div>}
            </div>
          }
        </Box>
      </>
    </Modal>
  );
};

export const getLogoById = (id) => {
  switch (id) {
    case 6:
      return bhnIcon;
    case 8:
      return easyCareIcon;
    case 19:
      return tomatoIcon;
    case 22:
      return familyCareIcon;

    default:
      return logoDefaul;
  }
};

const modalAccount = {
  position: "absolute",
  top: "60px",
  width: "500px",
  right: "10px",
  background: "#fff",
  borderRadius: "14px",
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.07)",
};

const noNotification = {
  width: "100%",
  padding: "12px",
  fontSize: "18px",
  color: "#3961A0",
  fontWeight: "600",
  textAlign: "center",
  position: "absolute",
  bottom: 0,
  background: "white",
};

export const getStatusById = (id) => {
  switch (id) {
    case 2:
      return {
        label: "Đang chờ xử lý",
        color: "#FFA21F",
      };
    case 3:
      return {
        label: "Đang chờ phê duyệt",
        color: "#FF003D",
      };

    case 4:
      return {
        label: "Bổ sung hồ sơ",
        color: "#0B7CE4",
      };
    case 5:
      return {
        label: "Đã phê duyệt",
        color: "#069D27",
      };
    case 6:
      return {
        label: "Hoàn thành",
        color: "#0B3C8A",
      };
    case 7:
    case 8:
      return {
        label: "Từ chối",
        color: "#FF003D",
      };

    default:
      return {};
  }
};
