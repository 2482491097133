import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircleIcon from "@mui/icons-material/Circle";
import { TableCell } from "@mui/material";

import logo_defaul from "../../../../assets/health/logo_defaul.svg";
import moment from "moment";

export const columns = [
  {
    label: "STT",
    headerClass: "STT ",
    renderCell: ({ rowIdx }) => (
      <TableCell className="STT">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "BH tham gia ",
    renderCell: ({ rowIdx, item }) => (
      <TableCell className="product-insurance">
        <div>
          <img
            src={item.productUrlLogo ? item.productUrlLogo : logo_defaul}
            width={25}
            height={25}
            style={{ marginRight: "6px" }}
          />{" "}
          {item.productTitle || ""}
        </div>
      </TableCell>
    ),
  },
  {
    label: "Họ và tên",
    renderCell: ({ rowIdx, item }) => (
      <TableCell className="">{item?.insuredInfo?.insuredName || ""}</TableCell>
    ),
  },
  {
    label: "Số thè  bảo hiểm",
    key: "certNum",
  },
  {
    label: "CCCD/CMND",
    renderCell: ({ rowIdx, item }) => (
      <TableCell className="">
        {item?.insuredInfo?.insuredPrivateId || ""}
      </TableCell>
    ),
  },
  {
    label: "Số diện thoại",
    renderCell: ({ rowIdx, item }) => (
      <TableCell className="">
        {item?.insuredInfo?.insuredPhone || ""}
      </TableCell>
    ),
  },
  {
    label: "Ngày hết hạn",
    renderCell: ({ rowIdx, item }) => (
      <TableCell className="">
        {item?.endDate ? moment(item?.endDate).format("DD/MM/YYYY") : ""}
      </TableCell>
    ),
  },
  {
    label: "Công ty bảo hiểm",
    // headerClass: "show-icon sticky",
    key: "provider_title",
  },
  {
    label: "Đơn vị sử lý",
    // headerClass: "show-icon sticky",
    renderCell: ({ rowIdx, item }) => (
      <TableCell className="">{item?.organization_title || ""}</TableCell>
    ),
  },
  {
    label: "",
    // headerClass: "show-icon sticky",
    key: "typeAccount",
    renderCell: ({ data, item }) => (
      <TableCell
        className="create sticky"
        onClick={() => {
          data.history.push({
            pathname: `/admin/blvp/craete-request/detail`,
            state: { data: { ...item } },
          });
        }}
      >
        Tạo
      </TableCell>
    ),
  },
];
