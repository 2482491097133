import React from "react";
import _ from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  div: {
    paddingBottom: "50px",
  },

  divTable: {
    overflowX: "auto",
  },

  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
  tableRow: {
    "& td": {
      padding: "5px",
      lineHeight: "40px",
      "& button": {
        padding: "8px",
      },
    },
  },
  tableRowHead: {
    "& th": {
      padding: "10px 5px",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    borderRadius: "15px",
    justifyContent: "flex-end",

    "& .MuiTablePagination-root": {
      borderBottom: "none",
    },

    "& .MuiToolbar-root": {
      "& .MuiTablePagination-selectLabel": {
        display: "none",
      },

      "& .MuiInputBase-root": {
        display: "none",
      },

      "& .MuiTablePagination-displayedRows": {
        margin: "auto",
      },
    },

    "& .select": {
      "& .MuiSelect-select": {
        padding: "5px 35px 5px 15px",
      },
    },
  },
}));

export default function CommonTable({
  empty,
  data,
  rows,
  columns,
  // sorts,
  page,
  isHeader = true,
  event,
  onClickRow,
  id,
  classes = {},
}) {
  const style = useStyle();

  return (
    <div className={`${style.div} ${classes.div}`}>
      <div className={`${style.divTable} ${classes.divTable}`}>
        <Table className={classes.table} id={id || "table"}>
          {isHeader && columns && (
            <TableHead className={classes.header}>
              <TableRow
                className={`${style.tableRowHead} ${classes.headerRow}  `}
              >
                {columns.map((item, index) => {
                  if (_.isFunction(item.renderHeader))
                    return item.renderHeader({
                      item,
                      event,
                      index,
                      data,
                      classes,
                    });

                  const rest = {};
                  for (let i = 0; i < (item.events || []).length; i++) {
                    const ele = item.events[i];
                    rest[ele.type] = event(ele.name, item);
                  }

                  return (
                    <TableCell
                      className={`${item.headerClass || ""} ${
                        classes.headerClass
                      }`}
                      key={index}
                      {...rest}
                    >
                      {/* {item.sort ? (
                      sorts[item.sort] === "ASC" ? (
                        <ArrowUpwardIcon color="action" />
                      ) : (
                        <ArrowDownwardIcon color="action" />
                      )
                    ) : (
                      ""
                    )} */}
                      {item.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          )}
          <TableBody className={classes.body}>
            {rows?.map((item, rowIdx) => {
              return (
                <TableRow
                  key={rowIdx}
                  className={`scroll-item ${style.tableRow} ${
                    classes.cellRow || ""
                  } ${rowIdx % 2 ? "even" : "odd"} ${
                    !!item.displayNone ? "none" : ""
                  }`}
                  onClick={() =>
                    _.isFunction(onClickRow)
                      ? onClickRow({ item, rowIdx })
                      : null
                  }
                >
                  {columns.map((column, colIdx) => {
                    if (_.isFunction(column.renderCell)) {
                      return column.renderCell({
                        item,
                        classes,
                        column,
                        rowIdx,
                        colIdx,
                        event,
                        data,
                      });
                    }
                    return (
                      <TableCell
                        className={`${classes.cellClass} ${column.cellClass}`}
                        key={colIdx}
                      >
                        {_.get(
                          item,
                          `${column.key}.value`,
                          _.get(item, `${column.key}`, "")
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {empty && rows.length === 0 && (
              <TableRow className={classes.empty}>
                <TableCell colSpan={columns.length}>{empty}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {page && (
        <div className={`${classes.footer} ${style.footer}`}>
          <Stack spacing={2}>
            <Pagination
              count={page.count || 0}
              page={page.page + 1 || 1}
              onChange={(e, pageNumber) => {
                const rowsPerPage = page.rowsPerPage || 10;
                page.onChange(e, pageNumber - 1, rowsPerPage);
              }}
              variant="outlined"
              color="primary"
            />
          </Stack>
        </div>
      )}
    </div>
  );
}
