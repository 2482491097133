import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  titleName: {
    fontSize: 30,
    fontWeight: 600,
    marginBottom: "8px",
  },
  valueTable: {
    color: "#000000",
    // maxWidth: "90%",
    fontSize: 15,
    wordBreak: "break-word",
    wordBreak: "break-all",
  },
  valueName: {
    color: "#000000",
    maxWidth: "90%",
    fontSize: 15,
    wordBreak: "break-word",
    wordBreak: "break-all",
    textTransform: "capitalize",
  },
  gridContainer: {
    "& .titleWrapper": {
      fontSize: 18,
      fontWeight: 500,
      color: "#ffffff",
      borderRadius: "10px 10px 0px 0px",
      background: "#E16B2F",
      padding: "5px 16px",
    },
    "& .bodyWrapper": {
      padding: "16px",
      background: "#fff",
      minHeight: "340px",
    },

    "& .textField": {
      margin: "10px 0",
    },
    "& .cicle": {
      width: "10px",
      height: "10px",
      background: "#22BD57",
      borderRadius: 100,
    },
    "& .rowText": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },

    "& .number": {
      margin: "5px 16px",
      color: "rgba(0, 0, 0, 0.52)",
    },
  },
  titleWrapper: {
    fontSize: 18,
    fontWeight: 500,
    color: "#ffffff",
    borderRadius: "10px 10px 0px 0px",
    background: "#E16B2F",
    padding: "5px 16px",
  },

  roleContainer: {
    marginTop: "16px",
  },
  roleWrapper: {
    margin: 0,
    padding: 0,
    width: "100%",
    background: "#fff",
    "& .gridCheckBox": {
      display: "flex",
      flexDirection: "column",
      padding: "0 0 0 16px",
    },

    "& .textField": {
      marginBottom: "16px",
      width: "80%",
    },
  },
  checkBox: {
    fontSize: 16,
    marginBottom: "8px",
  },
  titleType: {
    background: "#EBEAEA",
    padding: "5px 16px",
  },
  padding: {
    padding: "16px",
  },
  divCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px",
    fontSize: 18,
  },
  button: {
    background: "#E16B2F",
    borderRadius: 10,
    color: "white",
    width: "180px",
    margin: "0 15px",
    "&:hover": {
      backgroundColor: "#B27B16",
    },
  },
  unBlockBtn: {
    background: "#EAEAEA",
    borderRadius: 10,
    color: "#000",
    width: "180px",
    margin: "0 15px",
  },
  dialog: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    padding: "25px",
    "& .changeTxt": {
      color: "#22BD57",
    },
  },
  txtField: { margin: "16px 0" },
  endText: {
    display: "flex",
    justifyContent: "flex-end",
  },
  errText: {
    color: "red",
    margin: "-8px 16px 16px",
    padding: 0,
  },
  redTxt: {
    color: "red",
    marginBottom: "5px",
  },
  gridPermission: {
    margin: "8px",
  },
  textFieldName: {
    margin: "10px 0",
    "& .MuiOutlinedInput-input": {
      textTransform: "capitalize",
    },
  },
  select: {
    fontSize: 16,
    boxShadow: "none",
    borderRadius: 9,
    width: "100%",
    border: "1px solid #DDDDDD",
    padding: "7px 0px 7px 0px",
  },
  selectForm: { width: 120, textAlign: "left", color: "#2A3790" },
  pagination: { display: "flex", justifyContent: "end", marginTop: 10 },
  row: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
}));
