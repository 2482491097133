import { get, includes, isEmpty, isString, set, values } from "lodash";
import {
  getListCompanys,
  createCompany,
  updateCompany,
  getDetailCompany,
  deleteCompany,
  creatGroupRole,
  getDetailGroupRole,
  updateDetailGroupRole,
  deleteGroupRole,
  getListMerchant,
  createMerchant,
  updateMerchant,
} from "../../../apis/decentralizaApi";

import { defaultRole } from "./util";

const delay = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 800);
  });

export default function decentralizaReducer(props) {
  const { state, action, dispatch } = props;
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const isAdminGc =
    get(user, "extra_info.userType") === "globalcare" &&
    get(user, "role_management_claim.privileges.ROLE_GROUP_MANAGE") === 1;

  const userTypeFromProfile = get(user, "extra_info.userType") || "";

  const cases = {
    initail: async () => {
      dispatch("onLoading");

      const dataApi = await getListCompanys({ limit: 20, offset: 0 });
      const responseAPi = get(dataApi, "data", {});

      if (responseAPi.statusCode != 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi khi lấy dữ liệu, vui lòng hãy thử lại",
          },
        };
      }

      const res = {};
      if (responseAPi.statusCode == 200) {
        res.page = {
          company: { page: 1, total: get(responseAPi, "result.total", 0) },
        };
        res.listCompanys = get(responseAPi, "result.data", []) || [];
        res.listCompanysTPAG = res.listCompanys.filter((i) =>
          get(user, "extra_info.userType") === "globalcare"
            ? ["globalcare", "tpag", "tpas", "tpa"].includes(
                i?.extra_info?.org_type
              )
            : i?.extra_info?.org_type === "tpag"
        );
        res.inforCompany = get(responseAPi, "result.data[0]", {}) || {};
        res.groupRoleChosen =
          get(responseAPi, "result.data[0].role_groups[0]", {}) || {};

        if (
          (res?.inforCompany?.id_parent || res?.inforCompany?.parent_id) &&
          get(user, "extra_info.userType") === "globalcare"
        ) {
          const parentItemApi = await getDetailCompany(
            res?.inforCompany?.id_parent || res?.inforCompany.parent_id
          );

          const parentItem = get(parentItemApi, "data.result", {});

          res.parentRole = Boolean(
            res?.inforCompany?.id_parent || res?.inforCompany.parent_id
          )
            ? (parentItem?.role_groups || []).reduce((obj, item) => {
                const privileges = item?.privileges || {};

                const newData = Object.keys(privileges)
                  .filter((key) => privileges[key] === 1)
                  .reduce((result, key) => {
                    result[key] = privileges[key];
                    return result;
                  }, {});

                return { ...obj, ...newData };
              }, {})
            : defaultRole;
        } else {
          const privileges = get(user, "role_management_claim.privileges", {});
          res.parentRole = Object.keys(privileges).reduce((obj, item) => {
            if (privileges[item]) {
              return { ...obj, [item]: 1 };
            }

            return { ...obj };
          }, {});
        }
      }

      return { loading: false, ...res };
    },

    getListOrg: async () => {
      const { listCompanys, page, searchCompanyKey } = state;
      if (page?.company?.page * 20 > page?.company?.total) {
        return;
      }

      dispatch("loadingCompany");
      const dataApi = await getListCompanys({
        title: searchCompanyKey || "",
        limit: 20,
        offset: page?.company?.page * 20,
      });
      const responseAPi = get(dataApi, "data", {});

      const res = {};
      if (responseAPi.statusCode == 200) {
        res.page = {
          company: {
            page: page?.company?.page + 1,
            total: get(responseAPi, "result.total", 0),
          },
        };
        res.listCompanys = [
          ...listCompanys,
          ...(get(responseAPi, "result.data", []) || []),
        ];
        res.listCompanysTPAG = res.listCompanys.filter((i) =>
          get(user, "extra_info.userType") === "globalcare"
            ? ["globalcare", "tpag", "tpas", "tpa"].includes(
                i?.extra_info?.org_type
              )
            : i?.extra_info?.org_type === "tpag"
        );
      }

      return { loadingCompany: false, ...res };
    },

    openDialogAddCompany: async () => {
      const { dialogCreatCompany, inforCompany, listCompanys, merchants } =
        state;

      const typeAccount = action?.update
        ? inforCompany?.extra_info?.org_type || ""
        : get(user, "extra_info.userType") === "tpag"
        ? "tpas"
        : "";
      const res = {};
      if (merchants.length === 0) {
        dispatch("onLoading");
        const listMerchantApi = await getListMerchant({
          noneOrg: true,
          orgId:
            userTypeFromProfile === "tpas"
              ? get(user, "extra_info.org_id", null)
              : null,
          limit: 20,
          offset: 0,
        });

        res.merchants = get(listMerchantApi, "data.result.data") || [];
        res.payloadApiAutocomplete = {
          merchant: {
            page: 1,
            total: get(listMerchantApi, "data.result.total", 0),
            param: {
              noneOrg: true,
              orgId:
                userTypeFromProfile === "tpas"
                  ? get(user, "extra_info.org_id", null)
                  : null,
            },
          },
        };
      }

      return {
        dialogCreatCompany: {
          ...dialogCreatCompany,
          open: true,
          update: Boolean(action?.update),
          typeAccount: typeAccount,
          nameCompany: action?.update ? inforCompany.title : null,
          id_parent: action?.update
            ? inforCompany.id_parent || inforCompany.parent_id
            : null,
        },
        ...res,
        loading: false,
      };
    },

    onChangeDialogCreatCompany: () => {
      const { name, value } = action;
      const { dialogCreatCompany, listCompanys } = state;
      dialogCreatCompany[name] = value;

      const listCompanysTPAG = listCompanys.filter((i) =>
        get(user, "extra_info.userType") === "globalcare" &&
        dialogCreatCompany?.typeAccount === "merchant"
          ? ["globalcare", "tpag", "tpas", "tpa"].includes(
              i?.extra_info?.org_type
            )
          : i?.extra_info?.org_type === "tpag"
      );
      if (name === "typeAccount") {
        dialogCreatCompany.id_parent = null;
      }

      return { dialogCreatCompany, listCompanysTPAG };
    },

    onChangeMerchant: () => {
      const { value, reason } = action;
      const { dialogCreatCompany, merchant = {} } = state;
      if (reason === "reset") {
        return;
      }

      if (reason === "input") {
        merchant.title = value;
        dialogCreatCompany.nameCompany = value;
        return { merchant, dialogCreatCompany };
      }
      dialogCreatCompany.nameCompany = value?.title || "";

      return { merchant: value || {}, dialogCreatCompany };
    },

    onInputChangeMerchant: async () => {
      const { value, reason } = action;
      const {
        dialogCreatCompany,
        merchant = {},
        payloadApiAutocomplete,
        loadingAutocomplete,
      } = state;

      const res = {};

      merchant.title = value;

      if (!value) {
        dispatch({ type: "loadingAutocomplete", name: "merchant" });
        const listMerchantApi = await getListMerchant({
          noneOrg: true,
          orgId:
            userTypeFromProfile === "tpas"
              ? get(user, "extra_info.org_id", null)
              : null,
          limit: 10,
          offset: 0,
        });

        res.merchants = get(listMerchantApi, "data.result.data") || [];
        res.payloadApiAutocomplete = {
          merchant: {
            page: 1,
            total: get(listMerchantApi, "data.result.total", 0),
            param: {
              ...(payloadApiAutocomplete?.merchant?.param || {}),
              title: null,
            },
          },
        };

        return {
          ...res,
          loadingAutocomplete: { ...loadingAutocomplete, merchant: false },
        };
      }

      if (
        value &&
        payloadApiAutocomplete?.merchant?.page * 10 <
          payloadApiAutocomplete?.merchant?.total
      ) {
        dispatch({ type: "loadingAutocomplete", name: "merchant" });
        const listMerchantApi = await getListMerchant({
          ...(payloadApiAutocomplete?.merchant?.param || {}),
          title: value,
          limit: 10,
          offset: 0,
        });

        res.merchants = get(listMerchantApi, "data.result.data") || [];
        res.payloadApiAutocomplete = {
          merchant: {
            page: 1,
            total: get(listMerchantApi, "data.result.total", 0),
            param: {
              ...(payloadApiAutocomplete?.merchant?.param || {}),
              title: value,
            },
          },
        };

        return {
          ...res,
          loadingAutocomplete: { ...loadingAutocomplete, merchant: false },
        };
      }

      if (reason === "reset") {
        return;
      }

      dialogCreatCompany.nameCompany = value;
      return { merchant, dialogCreatCompany };
    },

    closeDialogAddCompany: () => {
      const { errors } = state;
      return {
        dialogCreatCompany: {
          open: false,
          typeAccount: null,
          nameCompany: null,
          update: false,
          id_parent: null,
        },
        errors: { ...errors, dialogAddCompany: [] },
        merchant: {},
      };
    },

    createCompany: async () => {
      const {
        dialogCreatCompany,
        usingRoleGroupBasic,
        merchant,
        merchants,
        page,
      } = state;

      if (
        isEmpty(dialogCreatCompany.typeAccount) ||
        isEmpty(dialogCreatCompany.nameCompany) ||
        (isAdminGc &&
          ["tpas"].includes(dialogCreatCompany.typeAccount) &&
          isEmpty(dialogCreatCompany?.id_parent))
      ) {
        return {
          snackbar: {
            open: true,
            type: "error",
            message: "Vui lòng nhập đủ thông tin bên dưới",
          },
        };
      }

      dispatch("onLoading");
      const typeAccount = dialogCreatCompany.typeAccount;
      const res = {};

      if (
        !merchant?.id &&
        ["tpag", "tpa"].includes(userTypeFromProfile) &&
        dialogCreatCompany.typeAccount === "merchant"
      ) {
        const createMerchantAPi = await createMerchant({
          title: merchant.title,
          orgId: user?.extra_info?.org_id,
          extraInfo: {
            taxCode: null,
          },
        });

        if (createMerchantAPi?.data?.statusCode !== 200) {
          return {
            loading: false,
            snackbar: {
              open: true,
              type: "error",
              message:
                "Đã xảy ra lỗi trong quá trình thêm mới phân quyền này. Xin vui lòng thử lại",
            },
          };
        }

        merchant.id = createMerchantAPi?.data?.result?.id;
      }

      const paramsMerchant =
        dialogCreatCompany.typeAccount === "merchant"
          ? { merchant_id: merchant.id }
          : {};

      const dataApi = await createCompany(typeAccount, {
        title: dialogCreatCompany.nameCompany,
        id_parent:
          dialogCreatCompany.typeAccount === "merchant"
            ? merchant?.orgId || null
            : dialogCreatCompany?.id_parent || null,
        using_role_group_basic: usingRoleGroupBasic ? 1 : 0,
        extra_info: {
          org_type: typeAccount,
          ...paramsMerchant,
        },
      });

      const responseApi = get(dataApi, "data", {});
      if (responseApi.statusCode != 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
        };
      }
      const itemCompny = get(responseApi, "result.userId[0]") || {};

      const listCompanysApi = await getListCompanys({ limit: 20, offset: 0 });

      const newListCompanys =
        get(listCompanysApi, "data.result.data", []) || [];
      res.inforCompany =
        newListCompanys.find((i) => i.id == itemCompny.id) || {};
      res.groupRoleChosen = get(res.inforCompany, "role_groups[0]", {}) || {};
      res.page = {
        ...page,
        company: {
          page: 1,
          total: get(listCompanysApi, "data.result.total", 0),
        },
      };

      if (res?.inforCompany?.id_parent || res?.inforCompany?.parent_id) {
        const parentItemApi = await getDetailCompany(
          res?.inforCompany?.id_parent || res?.inforCompany?.parent_id
        );

        const parentItem = get(parentItemApi, "data.result", {});

        res.parentRole = Boolean(
          res?.inforCompany?.id_parent || res?.inforCompany?.parent_id
        )
          ? (parentItem?.role_groups || []).reduce((obj, item) => {
              const privileges = item?.privileges || {};

              const newData = Object.keys(privileges)
                .filter((key) => privileges[key] === 1)
                .reduce((result, key) => {
                  result[key] = privileges[key];
                  return result;
                }, {});

              return { ...obj, ...newData };
            }, {})
          : defaultRole;
      }

      return {
        loading: false,
        ...res,
        snackbar: {
          type: "success",
          open: true,
          message: "Tạo công ty thành công.",
        },
        listCompanys: newListCompanys,
        dialogCreatCompany: {
          open: false,
          typeAccount: null,
          nameCompany: null,
          update: false,
        },
      };
    },

    updateCompany: async () => {
      const { dialogCreatCompany, inforCompany, listCompanys } = state;

      const res = {};
      const dataApi = await updateCompany({
        id: inforCompany.id,
        title: dialogCreatCompany.nameCompany,
      });

      const responseApi = get(dataApi, "data", {});
      if (responseApi.statusCode != 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
        };
      }
      res.inforCompany = {
        ...inforCompany,
        title: dialogCreatCompany.nameCompany,
      };
      const index = listCompanys.findIndex((i) => i.id === inforCompany?.id);
      listCompanys[index] = { ...res.inforCompany };

      return {
        loading: false,
        ...res,
        snackbar: {
          type: "success",
          open: true,
          message: "Cập nhật thông tin công ty thành công.",
        },
        listCompanys,
        dialogCreatCompany: {
          open: false,
          typeAccount: null,
          nameCompany: null,
          update: false,
        },
      };
    },

    onChangeDialogAddRole: () => {
      const { dialogAddRole } = state;
      const { name, value } = action;
      dialogAddRole[name] = value;

      return { dialogAddRole };
    },

    onClickCompany: async () => {
      const { item } = action;
      const { inforCompany, listCompanys } = state;

      if (inforCompany.id == item.id) {
        return;
      }
      const res = {};

      res.inforCompany = item;
      res.groupRoleChosen = get(item, "role_groups[0]", {}) || {};

      if (
        ["tpas", "merchant"].includes(item?.extra_info?.org_type) &&
        get(user, "extra_info.userType") === "globalcare"
      ) {
        dispatch("onLoading");
        const parentItemApi = await getDetailCompany(
          res?.inforCompany.id_parent || res?.inforCompany.parent_id
        );
        const parentItem = get(parentItemApi, "data.result", {});

        res.parentRole = Boolean(item.id_parent || item.parent_id)
          ? (parentItem?.role_groups || []).reduce((obj, item) => {
              const privileges = item?.privileges || {};

              const newData = Object.keys(privileges)
                .filter((key) => privileges[key] === 1)
                .reduce((result, key) => {
                  result[key] = privileges[key];
                  return result;
                }, {});

              return { ...obj, ...newData };
            }, {})
          : defaultRole;
      }

      return { ...res, loading: false };
    },

    onChangeSearchCompany: () => ({ searchCompanyKey: action.value }),

    searchCompany: async () => {
      const { key } = action;
      const { page } = state;
      const res = {};
      res.searchCompanyKey = key;

      dispatch("onLoading");
      const dataApi = await getListCompanys({
        title: key || "",
        offset: 0,
        limit: 20,
      });
      const responseAPi = get(dataApi, "data", {});
      if (responseAPi.statusCode != 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi khi lấy dữ liệu, vui lòng hãy thử lại",
          },
        };
      }

      if (responseAPi.statusCode == 200) {
        res.page = {
          ...page,
          company: {
            page: 1,
            total: get(dataApi, "data.result.total", 0),
          },
        };
        res.listCompanys = get(responseAPi, "result.data", []) || [];
        const temp = get(responseAPi, "result.data[0]", {});
        res.inforCompany = get(responseAPi, "result.data[0]", {});
        res.groupRoleChosen = get(
          responseAPi,
          "result.data[0].role_groups[0]",
          {}
        );
        if (
          (res?.inforCompany?.id_parent || res?.inforCompany?.parent_id) &&
          get(user, "extra_info.userType") === "globalcare"
        ) {
          const parentItemApi = await getDetailCompany(
            res?.inforCompany?.id_parent || res?.inforCompany?.parent_id
          );

          const parentItem = get(parentItemApi, "data.result", {});

          res.parentRole = Boolean(
            res?.inforCompany?.id_parent || res?.inforCompany.parent_id
          )
            ? (parentItem?.role_groups || []).reduce((obj, item) => {
                const privileges = item?.privileges || {};

                const newData = Object.keys(privileges)
                  .filter((key) => privileges[key] === 1)
                  .reduce((result, key) => {
                    result[key] = privileges[key];
                    return result;
                  }, {});

                return { ...obj, ...newData };
              }, {})
            : defaultRole;
        }
      }

      return { loading: false, ...res };
    },

    deletaCompany: async () => {
      const { inforCompany, listCompanys } = state;

      const indexCompany = listCompanys.findIndex(
        (i) => i.id == inforCompany.id
      );

      dispatch("onLoading");
      const res = {};
      const dataApi = await deleteCompany(inforCompany.id);
      const responseAPi = get(dataApi, "data", {});

      if (responseAPi.statusCode != 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Xóa công ty không thanhw công, vui lòng hãy thử lại",
          },
        };
      }

      if (responseAPi.statusCode == 200) {
        listCompanys.splice(indexCompany, 1);

        const temp = listCompanys[0];
        // res.infoCompany = ;
        if (!isEmpty(temp)) {
          const [infoCompanyApi, groupRoleChosenApi] = await Promise.all([
            getDetailCompany(temp?.id),
            getDetailGroupRole(get(temp, "role_groups[0].id")),
          ]);
          const responseInfoCompanyApi = get(infoCompanyApi, "data", {});
          const responseGroupRoleChosenApi = get(
            groupRoleChosenApi,
            "data",
            {}
          );
          if (responseInfoCompanyApi.statusCode != 200) {
            return {
              loading: false,
              snackbar: {
                open: true,
                type: "error",
                message: "Đã xảy ra lỗi khi lấy dữ liệu, vui lòng hãy thử lại",
              },
            };
          }
          res.inforCompany = get(responseInfoCompanyApi, "result", {}) || {};
          res.groupRoleChosen =
            get(responseGroupRoleChosenApi, "result", {}) || {};
        }
      }

      return {
        ...res,
        loading: false,
        snackbar: {
          open: true,
          type: "success",
          message: `Xóa toàn bộ phân quyền ${
            inforCompany.title || ""
          } thành công`,
        },
      };
    },

    openDialogAddRole: () => {
      const { item = {}, isUpdate = false, index } = action;

      if (isUpdate) {
        return {
          dialogAddRole: {
            open: true,
            nameRoleGroup: item.title,
            ...item,
            isUpdate,
            index,
          },
        };
      }

      return {
        dialogAddRole: {
          open: true,
        },
      };
    },

    closeDialogAddRole: () => ({ dialogAddRole: { open: false } }),

    creatRoleGrounp: async () => {
      const { inforCompany, dialogAddRole, listCompanys } = state;

      if (isEmpty(dialogAddRole.nameRoleGroup)) {
        return {
          snackbar: {
            open: true,
            type: "error",
            message: "Vui lòng nhập tên nhóm quyền",
          },
        };
      }
      dispatch("onLoading");

      const dataApi = await creatGroupRole({
        title: dialogAddRole.nameRoleGroup,
        org_id: inforCompany?.id || null,
        privileges: {},
      });
      const response = get(dataApi, "data", {});
      if (response.statusCode != 200) {
        return {
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
          loading: false,
        };
      }

      const indexListCompanys = listCompanys.findIndex(
        (i) => i.id == inforCompany.id
      );
      const item = get(response, `result[0]`, {}) || {};
      listCompanys[indexListCompanys] = {
        ...listCompanys[indexListCompanys],
        role_groups: [
          ...get(listCompanys, `[${indexListCompanys}].role_groups`, []),
          {
            created_date: item.created_date || null,
            id: item.id || null,
            title: item.title,
          },
        ],
      };
      (inforCompany?.role_groups || []).push({
        created_date: item.created_date || null,
        id: item.id || null,
        title: item.title,
      });

      return {
        listCompanys,
        groupRoleChosen: { ...item },
        loading: false,
        dialogAddRole: { open: false },
      };
    },

    onClickGroupRole: async () => {
      const { item, index } = action;
      const {} = state;

      return { groupRoleChosen: { ...item } };
    },

    choosePrivileges: () => {
      const { name, value } = action;
      const { groupRoleChosen, inforCompany } = state;
      set(groupRoleChosen, `privileges.${name}`, value ? 1 : 0);

      const index = inforCompany?.role_groups?.findIndex(
        (i) => i.id == groupRoleChosen?.id
      );
      index > -1 &&
        set(inforCompany, `role_groups[${index}]`, {
          ...groupRoleChosen,
        });

      return { groupRoleChosen, inforCompany };
    },

    updateGroupRole: async () => {
      const { groupRoleChosen, dialogAddRole, inforCompany, listCompanys } =
        state;

      dispatch("onLoading");

      const params = dialogAddRole.isUpdate
        ? {
            id: groupRoleChosen.id || null,
            title: dialogAddRole.nameRoleGroup || null,
            privileges: groupRoleChosen.privileges,
          }
        : {
            id: groupRoleChosen.id || null,
            title: groupRoleChosen.title || null,
            privileges: groupRoleChosen.privileges,
          };

      const dataApi = await updateDetailGroupRole(params);
      const response = get(dataApi, "data");

      if (response.statusCode != 200) {
        return {
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
          loading: false,
        };
      }

      if (dialogAddRole.isUpdate) {
        set(
          inforCompany,
          `role_groups[${dialogAddRole.index}].title`,
          dialogAddRole.nameRoleGroup
        );
        dispatch("closeDialogAddRole");
      }

      const indexListCompanys = listCompanys.findIndex(
        (i) => i.id == inforCompany.id
      );
      const indexListGroupRole = (inforCompany.role_groups || []).findIndex(
        (i) => i.id == groupRoleChosen.id
      );
      set(
        listCompanys,
        `[${indexListCompanys}].role_groups[${indexListGroupRole}]`,
        { ...groupRoleChosen }
      );

      return {
        snackbar: {
          open: true,
          type: "success",
          message: "Cập nhật thông tin thành công.",
        },
        loading: false,
        inforCompany,
        listCompanys,
      };
    },

    deleteGroupRole: async () => {
      const { index } = action;
      const { groupRoleChosen, inforCompany } = state;

      dispatch("onLoading");
      const dataApi = await deleteGroupRole(groupRoleChosen.id);
      const response = get(dataApi, "data");

      if (response.statusCode != 200) {
        return {
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
          loading: false,
        };
      }

      (inforCompany.role_groups || []).splice(index, 1);

      const detailGroupRoleApi = await getDetailGroupRole(
        get(inforCompany, `role_groups[0].id`)
      );
      const responseDetailGroupRoleApi = get(detailGroupRoleApi, "data");
      const data = get(responseDetailGroupRoleApi, "result", {}) || {};

      return {
        snackbar: {
          open: true,
          type: "success",
          message: "Xóa nhóm quyền thành công.",
        },
        loading: false,
        inforCompany,
        groupRoleChosen: data,
      };
    },

    getMoveMerchant: async () => {
      const { payloadApiAutocomplete, loadingAutocomplete, merchants } = state;

      const res = {};
      dispatch({ type: "loadingAutocomplete", name: "merchant" });
      const listMerchantApi = await getListMerchant({
        limit: 10,
        offset: payloadApiAutocomplete?.merchant?.page * 20,
        noneOrg: true,
        ...(payloadApiAutocomplete?.merchant.param || {}),
      });
      res.merchants = [
        ...merchants,
        ...get(listMerchantApi, "data.result.data", []),
      ];
      res.payloadApiAutocomplete = {
        ...payloadApiAutocomplete,
        merchant: {
          page: +payloadApiAutocomplete?.merchant?.page + 1,
          total: get(listMerchantApi, "data.result.total", 0),
          param: {
            ...(payloadApiAutocomplete?.merchant.param || {}),
          },
        },
      };

      return {
        ...res,
        loadingAutocomplete: { ...loadingAutocomplete, merchant: false },
      };
    },

    checkUsingRoleGroupBasic: () => ({ usingRoleGroupBasic: action.value }),

    onCloseSnackbar: () => ({ snackbar: { ...state.snackbar, open: false } }),

    onLoading: () => ({ loading: true }),

    loadingCompany: () => ({ loadingCompany: true }),

    loadingAutocomplete: () => ({
      loadingAutocomplete: {
        ...state.loadingAutocomplete,
        [action.name]: true,
      },
    }),

    //
  };

  return isString(action.type) && cases[action.type];
}
