import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export default function Accordions(props) {
  const {
    classes,
    titleHeader,
    children,
    expanded,
    onClick = () => {},
  } = props;
  return (
    <Accordion className={classes.accordion} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandLessIcon style={{ color: "white" }} />}
        className={classes.accordiontitle}
        onClick={onClick}
      >
        <Typography>{titleHeader}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "8px 0" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
