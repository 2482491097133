import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowDown";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import moment from "moment";
import React from "react";
import { useStyles } from "../styles/useStyles";
import { _formatMoney, formatDate } from "../utils";
import FileItem from "./FileItem";

import accountNumber from "../assets/accountNumber.svg";
import action from "../assets/action.svg";
import address from "../assets/address.svg";
import bank from "../assets/bank.svg";
import classify from "../assets/classify.svg";
import cod from "../assets/cod.svg";
import email from "../assets/email.svg";
import license from "../assets/license.svg";
import location from "../assets/location.svg";
import money from "../assets/money.svg";
import oclock from "../assets/oclock.svg";
import phone from "../assets/phone.svg";
import risk from "../assets/risk.svg";
import statusIcon from "../assets/status-icon.svg";
import date from "../assets/toDate.svg";
import userIcon from "../assets/user-name-icon.svg";
import user from "../assets/user.svg";
import vehicle from "../assets/vehicle.svg";

export default function CardInfor(props) {
  const classes = useStyles();
  const { row, dispatch } = props;

  const listImg208 =
    (row?.elements || []).find((item) => item.type_id === 208)?.extra_info
      ?.documents || [];

  const listImg209 =
    (row?.elements || []).find((item) => item.type_id === 209)?.extra_info
      ?.documents || [];

  return (
    <div className={classes.boxAccor}>
      <Accordions
        divTitleAccor={
          <div className="titleAccodion">
            Chuyến đi #
            <span className="code" onClick={() => dispatch("copyToClipboard")}>
              {row?.extraInfo?.codeDestination || ""}
            </span>
          </div>
        }
      >
        <Row
          icon={date}
          title="Ngày khởi tạo"
          value={moment(row?.extraInfo?.createdDate).format("DD/MM/YYYY")}
        />
        <Row
          icon={location}
          title="Thông tin điểm giao"
          value={row?.extraInfo?.address}
        />
        <Row
          icon={cod}
          title="Giá trị COD"
          value={_formatMoney(row?.extraInfo?.codValue || 0) + "đ"}
        />
      </Accordions>
      <Accordions titleAccor={"Thông tin người gửi"}>
        <Row
          icon={user}
          title="Họ và tên"
          value={row?.extraInfo?.sender?.fullName || ""}
          capitalize
        />
        <Row
          icon={phone}
          title="Số điện thoại"
          value={row?.extraInfo?.sender?.phoneNumber || ""}
        />
        <Row
          icon={address}
          title="Địa chỉ"
          value={row?.extraInfo?.sender?.address || ""}
        />
      </Accordions>
      <Accordions titleAccor={"Thông tin người nhận"}>
        <Row
          icon={user}
          title="Họ và tên"
          value={row?.extraInfo?.receiver?.fullName || ""}
          capitalize
        />
        <Row
          icon={phone}
          title="Số điện thoại"
          value={row?.extraInfo?.receiver?.phoneNumber || ""}
        />
        <Row
          icon={address}
          title="Địa chỉ"
          value={row?.extraInfo?.receiver?.address || ""}
        />
      </Accordions>

      <div className={classes.boxLine}>
        <div className="lineTitle">Thông tin thanh toán</div>
        <Row
          icon={date}
          title="Loại thanh toán"
          value={
            row?.extraInfo?.paymentInfo?.paymentId === 1
              ? "Ứng thu hộ COD"
              : "Không ứng COD"
          }
        />
        <div className="lineTitle">Phân loại hàng hoá</div>
        <Row
          icon={classify}
          title="Phân loại"
          value={row?.extraInfo?.productInfo?.typeNameOfProduct || ""}
        />
        <div className="lineTitle">Trạng thái</div>
        <Row
          icon={statusIcon}
          title="Trạng thái"
          value={row?.extraInfo?.statusId === 0 ? "Thất bại" : "Thành công"}
        />
      </div>
      <Accordions titleAccor={"Thông tin sự cố"}>
        <Row
          icon={date}
          title="Ngày xảy ra sự cố"
          value={moment(row?.extraInfo?.riskDate).format("DD/MM/YYYY")}
        />
        <Row
          icon={oclock}
          title="Giờ xảy ra sự cố"
          value={row?.extraInfo?.riskTime || ""}
        />
        <Row
          icon={action}
          title="Mô tả diễn biến"
          value={row?.extraInfo?.describe || ""}
        />
      </Accordions>
      <div className={classes.boxLine}>
        <div className="lineTitle">Khai báo giá trị hàng hoá</div>
        <Row
          icon={money}
          title="Giá trị hàng hoá"
          value={
            `${row?.extraInfo?.productAmount || 0}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              "."
            ) + "đ"
          }
        />
        <div className="lineTitle">Rủi ro gặp phải</div>
        <Row
          icon={risk}
          title="Rủi ro"
          value={
            +row?.extraInfo?.typeOfAccident === 3
              ? "Shipper chiếm dụng hàng hoá"
              : "Shipper chiếm dụng COD"
          }
        />
      </div>
      <Accordions titleAccor={"Thông tin tài xế"}>
        {renderDriverInfo(row?.extraInfo).map((i, idx) => (
          <div key={`bene-${idx}`}>
            <Row
              icon={i.icon}
              title={i.title}
              value={i.value || ""}
              capitalize={!!i?.capitalize}
            />
          </div>
        ))}
        <div>
          <div className="row">
            <span className="titleSpan">
              <img
                src={accountNumber}
                alt="img"
                style={{ marginRight: "5px" }}
                height={24}
                width={24}
              />
              CMND/CCCD 2 mặt của tài xế
            </span>
          </div>
          <div className="row">
            <div className="frame-img">
              <img
                src={listImg208.find((i) => i.type === "front")?.path}
                alt="front"
                className="img"
                onClick={() =>
                  dispatch({
                    type: "showDialog",
                    indexImg: 0,
                    listImg: listImg208,
                  })
                }
              />
            </div>
            <div className="frame-img">
              <img
                src={listImg208.find((i) => i.type === "back")?.path}
                alt="back"
                className="img"
                onClick={() =>
                  dispatch({
                    type: "showDialog",
                    indexImg: 1,
                    listImg: listImg208,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div>
          <span className="titleSpan">
            <img
              src={accountNumber}
              alt="img"
              style={{ marginRight: "5px" }}
              height={24}
              width={24}
            />
            Hợp đồng tài xế ahamove cấp
          </span>
          <div className="mt-10">
            {listImg209.map((i, index) => (
              <div key={index}>
                <FileItem item={i} dispatch={dispatch} />
              </div>
            ))}
          </div>
        </div>
        {(row?.extraInfo?.listOfRelativesToDriver || []).map((item, index) => (
          <div key={index}>
            <div className="relative">{`Thông tin người thân ${
              index + 1
            }`}</div>
            <Row
              icon={user}
              title="Họ và tên"
              value={item?.fullName || ""}
              capitalize={true}
            />
            <Row
              icon={phone}
              title="Số điện thoại"
              value={item?.phoneNumber || ""}
            />
            <Row icon={date} title="Năm sinh" value={item?.yearOfBirth || ""} />
            <Row
              icon={userIcon}
              title="Mối quan hệ với tài xế"
              value={item?.relationshipWithDriver || ""}
            />
          </div>
        ))}
      </Accordions>
      <Accordions titleAccor={"Thông tin người thụ hưởng"}>
        {renderBeneficiary(row?.extraInfo).map((i, idx) => (
          <div key={`bene-${idx}`}>
            <Row icon={i.icon} title={i.title} value={i.value || ""} />
          </div>
        ))}
      </Accordions>
    </div>
  );
}

const Accordions = (props) => {
  const classes = useStyles();
  const { children, titleAccor, divTitleAccor } = props;

  return (
    <>
      <Accordion className={classes.accordionRoot}>
        <AccordionSummary
          expandIcon={<KeyboardArrowUpIcon style={{ color: "#284078" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {divTitleAccor ? (
            divTitleAccor
          ) : (
            <span className="titleAccodion">{titleAccor}</span>
          )}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  );
};

const Row = (props) => {
  const { title, value, capitalize, icon } = props;
  return (
    <div className="rowDiv">
      <span className="titleSpan">
        <img
          src={icon}
          alt="img"
          style={{ marginRight: "5px" }}
          height={24}
          width={24}
        />
        {title}
      </span>
      <span
        className={capitalize ? "valueSpan capitalize" : "valueSpan"}
        title={value}
        style={{ textAlign: "end" }}
      >
        {value}
      </span>
    </div>
  );
};

const renderDriverInfo = (data) => [
  {
    icon: user,
    title: "Họ và tên",
    value: data?.driver?.fullName,
    capitalize: true,
  },
  { icon: phone, title: "Số điện thoại", value: data?.driver?.phoneNumber },
  {
    icon: address,
    title: "Địa chỉ hộ khẩu (theo CMND/CCCD)",
    value: data?.driver?.householdAddress,
    capitalize: true,
  },
  {
    icon: address,
    title: "Quê quán",
    value: data?.driver?.hometown,
    capitalize: true,
  },
  {
    icon: address,
    title: "Nơi ở hiện tại",
    value: data?.driver?.address,
    capitalize: true,
  },
  {
    icon: license,
    title: "Biển số xe",
    value: data?.driver?.license,
    capitalize: true,
  },
  { icon: vehicle, title: "Loại xe", value: data?.driver?.vehicleType },
  {
    icon: date,
    title: "Thời gian nhận hàng",
    value: formatDate(data?.driver?.receivingTimeOfDriver),
  },
  {
    icon: date,
    title: "Thời gian giao hàng",
    value: formatDate(data?.driver?.deliveryTime),
  },
];

const renderBeneficiary = (data) => [
  { icon: user, title: "Tên tài khoản", value: data?.beneficiaryName },
  { icon: bank, title: "Ngân hàng", value: data?.beneficiaryBank },
  {
    icon: accountNumber,
    title: "Số tài khoản",
    value: data?.beneficiaryBankAcountNumber,
  },
  { icon: email, title: "Email", value: data?.beneficiaryEmail },
];
