import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  useTheme,
  FormControlLabel,
  Radio,
  MenuItem,
  Checkbox,
} from "@mui/material";
// import { DatePicker } from "antd";

import { dashboardStyle } from "../style/styleDashboard";
import { TextField } from "../overrideMui";
// import moment from "moment";
// import { converDate } from "../utils";

export default function ChartCard(props) {
  const { state, dispatch } = props;
  const classes = dashboardStyle();
  const theme = useTheme();
  const { shortDashboard, showChart, xChart, yChart, dataFiter } = state;

  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: false,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const dataSet = [
    {
      label: "Người được bảo hiểm",
      data: [...(yChart?.insuredPerson || [])],
      borderColor: "rgb(57,97,160)",
      backgroundColor: "rgb(57,97,160)",
      borderWidth: 1,
      name: "insuredPerson",
    },
    {
      label: "HS yêu cầu bồi thường",
      data: [...(yChart?.claimsChart || [])],
      borderColor: "rgb(255,162,31)",
      backgroundColor: "rgb(255,162,31)",
      borderWidth: 1,
      name: "claims",
    },
    {
      label: "Chi trả bồi thường",
      data: [...(yChart?.claimsApproveChart || [])],
      borderColor: "rgb(6,157,39)",
      backgroundColor: "rgba(6,157,39, 0.5)",
      borderWidth: 1,
      name: "claimsApprove",
    },
    {
      label: "Từ chối chi trả",
      data: [...(yChart?.claimsRejectChart || [])],
      borderColor: "rgb(255,0,61)",
      backgroundColor: "rgb(255,0,61)",
      borderWidth: 1,
      name: "claimsReject",
    },
  ];

  const data = {
    labels: [...(xChart || [])],
    datasets: dataSet.filter((i) => !!showChart[i.name]),
  };

  return (
    <div className={classes.containerChart}>
      <div className={classes.headerChart}>
        <div className="check-box">
          <div
            style={{
              marginRight: "24px",
              color: "#4B4B4B",
              fontWeight: "600",
              minWidth: "200px",
            }}
          >
            Tổng quan{" "}
            <span
              style={{ color: "#6685B6", fontSize: "12px", fontWeight: "500" }}
            >
              {` ${dataFiter?.valueTextDate || ""}`}
            </span>
          </div>
          <div style={{ width: "75%" }}>
            <FormControlLabel
              className="form-control"
              id="insuredPerson"
              control={<Checkbox className="no-1" />}
              label="Người được bảo hiểm"
              onChange={(e) => {
                dispatch({
                  type: "onChangeShowChart",
                  value: e.target.checked,
                  name: "insuredPerson",
                });
              }}
              checked={!!showChart?.insuredPerson}
            />
            <FormControlLabel
              className="form-control"
              id="claims"
              onChange={(e) => {
                dispatch({
                  type: "onChangeShowChart",
                  value: e.target.checked,
                  name: "claims",
                });
              }}
              checked={!!showChart?.claims}
              control={<Checkbox className="no-2" />}
              label="HS yêu cầu bồi thường"
            />
            <FormControlLabel
              className="form-control"
              id="onChangeShowChart"
              onChange={(e) => {
                dispatch({
                  type: "onChangeShowChart",
                  value: e.target.checked,
                  name: "claimsApprove",
                });
              }}
              checked={!!showChart?.claimsApprove}
              control={<Checkbox className="no-3" />}
              label="Chi trả bồi thường"
            />
            <FormControlLabel
              className="form-control"
              id="onChangeShowChart"
              onChange={(e) => {
                dispatch({
                  type: "onChangeShowChart",
                  value: e.target.checked,
                  name: "claimsReject",
                });
              }}
              checked={!!showChart?.claimsReject}
              control={<Checkbox className="no-4" />}
              label="Từ chối chi trả"
            />
          </div>
          <div
            style={{
              display: "flex",
              fontSize: "14px",
              minWidth: "175px",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "6px" }}>Sắp xếp</span>
            <TextField
              sx={{ width: "115px" }}
              select
              value={shortDashboard}
              onChange={(e) => {
                dispatch({ type: "selectShortChart", value: e.target.value });
              }}
            >
              {[
                { name: "Số đơn", value: "count_status_id" },
                { name: "Số tiền", value: "total_amount" },
              ].map((item, key) => (
                <MenuItem value={item.value} key={key}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </div>
      <div
        class="chart-container"
        style={{
          position: "relative",
          width: "100%",
          height: "70vh",
          maxHeight: "700px",
          margin: "24px 0",
        }}
      >
        <Line options={options} data={data} />
      </div>
    </div>
  );
}

const Styles = {
  titleInput: {
    zIndex: 4,
    top: "-12px",
    left: 3,
    position: "absolute",
    background: "#ffffff",
    marginLeft: 8,
    width: "fit-content",
    fontWeight: 500,
    padding: "0px 5px",
    fontSize: 15,
    color: "#3961A0",
  },
  input: {
    fontSize: 16,
    boxShadow: "none",
    borderRadius: 16,
    width: "170px",
    border: "1px solid #A0A0A0",
  },
};
