import api from "../configs/api";

export function getListbvpk(systemType, data) {
  return api
    .apiForm("POST", `/api/v2/claim/${systemType}/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListbvpk_createRequest(data) {
  return api
    .apiForm("POST", `/api/v2/cert/bvpk/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getConfigHealthInsurance() {
  return api
    .apiForm("GET", "/api/config/health/insurance", {})
    .then((res) => res)
    .catch((error) => error);
}

export function uploadImg(data) {
  return api
    .apiForm("POST", "/api/v2/document/upload/cdn", data)
    .then((res) => res)
    .catch((error) => error);
}

export function todoRemovedLinkS3(data) {
  return api
    .apiForm("POST", `/api/v2/todo`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export const createRequestBLVP = (data) =>
  api
    .apiForm("POST", "/api/v2/claim/bvpk", data)
    .then((res) => res)
    .catch((error) => error?.response);

export const getDetailClaim = (systemType, id) =>
  api
    .apiForm("GET", `/api/v2/claim/${systemType}/${id}/detail`)
    .then((res) => res)
    .catch((error) => error.response);

export const cdn = (data) =>
  api
    .apiForm("POST", "/api/v2/document/upload/cdn", data)
    .then((res) => res)
    .catch((error) => error.response);
