import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import React from "react";
import picture from "../../../../../assets/ahamove/picture.svg";
import { useStyles } from "../styles/useStyles";

export default function CardDocument(props) {
  const classes = useStyles();
  const { state, dispatch, form } = props;
  const {
    row,
    statusId = 0,
    updated,
    documentsAdditionalImg,
    renderListImg,
  } = state;
  const isReportClaim = form === "report-claim";

  const getListImg = (id) => {
    const temps = row?.elements?.find((i) => i.type_id === id);
    return Array.isArray(temps?.extra_info?.documents)
      ? temps?.extra_info?.documents
      : temps?.documents || [];
  };

  return (
    <div>
      <div className="headDocument">
        <span
          style={{
            fontWeight: "600",
            whiteSpace: "nowrap",
            marginRight: "12px",
          }}
        >
          Chứng từ
        </span>
        <span style={{ textAlign: "end" }}>
          <span style={{ color: "#000000" }}>Mã hồ sơ:</span>
          {" " + row?.code}
        </span>
      </div>
      <div style={{ width: "100%" }}>
        {renderListImg?.map((item, index) => (
          <div className={classes.boxImg} key={index}>
            <div className="title">
              {item.title}
              <span
                style={{ color: "red", display: item.optional ? "none" : "" }}
              >
                *
              </span>
            </div>
            <div className="div-img">
              {getListImg(item.typeId).length > 0 ? (
                (getListImg(item.typeId) || []).map((i, indexImg) => (
                  <div
                    key={i?.path}
                    className="img"
                    onClick={() =>
                      dispatch({
                        type: "showDialog",
                        indexImg,
                        listImg: getListImg(item.typeId),
                      })
                    }
                  >
                    <img
                      src={i?.path}
                      width="100%"
                      style={{ maxHeight: "75px" }}
                      alt="imge"
                    />
                  </div>
                ))
              ) : (
                <div className="not-have-img">
                  Khách hàng không upload ảnh ở mục này
                </div>
              )}
            </div>
          </div>
        ))}
        {(documentsAdditionalImg.length > 0 ||
          [4, 5, 6, 7].includes(statusId)) && (
          <div className={classes.boxImgAdditional} key="additionalImg">
            <div className="title">
              Chứng từ bổ sung
              {/* <span style={{ color: "red" }}>*</span> */}
            </div>
            <div className="div-img">
              {!isReportClaim &&
                [4, 5, 6, 7].includes(statusId) &&
                !updated && (
                  <div
                    className={classes.additional_img}
                    onClick={() => {
                      window.document.getElementById(`addImage`).click();
                    }}
                  >
                    <input
                      type="file"
                      hidden
                      id={`addImage`}
                      accept="image/*"
                      onChange={(e) => {
                        dispatch({ type: "additionalImg", e });
                        e.target.value = null;
                      }}
                    />
                    <img src={picture} alt="picture" />
                    <span className="spanPicture">Tải ảnh lên từ thiết bị</span>
                  </div>
                )}
              {documentsAdditionalImg?.map((itemIMG, indexImg) => (
                <div
                  key={indexImg}
                  // className="img"
                  className={
                    !isReportClaim &&
                    [4, 5, 6, 7].includes(statusId) &&
                    !updated
                      ? classes.additional_img
                      : "img"
                  }
                >
                  <img
                    key={itemIMG.path}
                    onClick={() =>
                      dispatch({
                        type: "showDialog",
                        indexImg,
                        listImg: documentsAdditionalImg,
                        name: "additionalImg",
                      })
                    }
                    src={itemIMG.path || ""}
                    width="100%"
                    height="100%"
                    alt="imge"
                  />
                  {!isReportClaim &&
                    [4, 5, 6, 7].includes(statusId) &&
                    !updated && (
                      <CancelOutlinedIcon
                        key={itemIMG.path}
                        className="icon"
                        fontSize="small"
                        style={{ zIndex: 11 }}
                        onClick={() =>
                          dispatch({
                            type: "deleteAdditionalImg",
                            indexImg,
                            itemIMG,
                          })
                        }
                      />
                    )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
