import React, { useEffect, useState } from "react";
import { Button, Modal, DatePicker, Space, ConfigProvider } from "antd";

import ClearIcon from "@mui/icons-material/Clear";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";

import "../style/modalFilterDateCss.css";
import moment from "moment";

const { RangePicker } = DatePicker;

export default function ModalFilterDate(props) {
  const { state, dispatch } = props;
  const [openC, setOpenC] = useState(false);

  const { modalDate } = state;

  const listClick1 = [
    { label: "Hôm nay", name: "today" },
    { label: "Hôm qua", name: "yesterday" },
    { label: "Trong 7 ngày", name: "in7Day" },
    { label: "Trong 30 ngày", name: "in30Day" },
  ];
  const listClick2 = [
    { label: "Theo ngày", name: "byDate" },
    { label: "Theo tuần", name: "byWeek" },
    { label: "Theo tháng", name: "byMonth" },
    { label: "Theo năm", name: "byYear" },
    { label: "Tuỳ chọn", name: "option" },
  ];
  useEffect(() => {
    if (
      ["byDate", "byWeek", "byMonth", "byYear"].includes(modalDate.option) &&
      modalDate?.open
    ) {
    }
    setOpenC(Boolean(true));
  }, [modalDate?.open, modalDate?.option]);

  const renderContentDate = () => {
    switch (modalDate?.option) {
      case "today":
        return (
          <div className="content-date-option">
            {moment().format("DD/MM/YYYY")}
          </div>
        );
      case "yesterday":
        return (
          <div className="content-date-option" style={{ marginTop: "30px" }}>
            {moment().subtract(1, "days").format("DD/MM/YYYY")}
          </div>
        );
      case "in7Day":
        return (
          <div className="content-date-option" style={{ marginTop: "65px" }}>
            {moment().subtract(7, "days").format("DD/MM/YYYY") +
              " - " +
              moment().format("DD/MM/YYYY")}
          </div>
        );
      case "in30Day":
        return (
          <div className="content-date-option" style={{ marginTop: "98px" }}>
            {moment().subtract(30, "days").format("DD/MM/YYYY") +
              " - " +
              moment().format("DD/MM/YYYY")}
          </div>
        );
      case "byDate":
        return (
          <Space direction="vertical" className="space-datePicker">
            <DatePicker
              open={openC}
              showNow={false}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              value={modalDate?.dataDate || moment()}
              className="datePicker-input"
              popupClassName="popup-calendar"
              onChange={(date, dateString) => {
                dispatch({
                  type: "onChangeDate",
                  date,
                  dateString,
                  actionDate: "day",
                });
              }}
            />
          </Space>
        );
      case "byWeek":
        return (
          <Space direction="vertical" className="space-datePicker">
            <DatePicker
              open={openC}
              showNow={false}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              value={modalDate?.dataDate || moment()}
              picker="week"
              className="datePicker-input"
              popupClassName="popup-calendar"
              onChange={(date, dateString) => {
                dispatch({
                  type: "onChangeDate",
                  date,
                  dateString,
                  actionDate: "byWeek",
                });
              }}
            />
          </Space>
        );
      case "byMonth":
        return (
          <Space direction="vertical" className="space-datePicker">
            <DatePicker
              open={openC}
              showNow={false}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              value={modalDate?.dataDate || moment()}
              picker="month"
              className="datePicker-input"
              popupClassName="popup-calendar"
              onChange={(date, dateString) => {
                dispatch({
                  type: "onChangeDate",
                  date,
                  dateString,
                  actionDate: "byMonth",
                });
              }}
            />
          </Space>
        );
      case "byYear":
        return (
          <Space direction="vertical" className="space-datePicker">
            <DatePicker
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
              open={openC}
              showNow={false}
              value={modalDate?.dataDate || moment()}
              picker="year"
              className="datePicker-input"
              popupClassName="popup-calendar"
              onChange={(date, dateString) => {
                dispatch({
                  type: "onChangeDate",
                  date,
                  dateString,
                  actionDate: "byYear",
                });
              }}
            />
          </Space>
        );
      case "option":
        return (
          <>
            <div
              style={{
                width: "100%",
                position: "absolute",
                display: "flex",
                top: "-3px",
              }}
            >
              <div style={{ marginLeft: "8px", width: "50%" }}>
                {" "}
                Từ ngày:{" "}
                {modalDate?.dataDateOptionFrom
                  ? moment(modalDate?.dataDateOptionFrom).format("DD/MM/YYYY")
                  : ""}
              </div>
              <div style={{ marginLeft: "8px" }}>
                {" "}
                Đến ngày:{" "}
                {modalDate?.dataDateOptionTo
                  ? moment(modalDate?.dataDateOptionTo).format("DD/MM/YYYY")
                  : ""}
              </div>
            </div>
            <Space
              direction="vertical"
              className="space-datePicker-option-from"
            >
              <DatePicker
                open={openC}
                showNow={false}
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
                value={modalDate?.dataDateOptionFrom || moment()}
                className="datePicker-input"
                popupClassName="popup-calendar-option"
                onChange={(date, dateString) => {
                  dispatch({
                    type: "onChangeDate",
                    date,
                    dateString,
                    actionDate: "option-from",
                  });
                }}
              />
            </Space>
            <Space direction="vertical" className="space-datePicker-option-to">
              <DatePicker
                key={"option-to"}
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
                open={openC}
                showNow={false}
                value={modalDate?.dataDateOptionTo || moment()}
                className="datePicker-input"
                popupClassName="popup-calendar-option"
                onChange={(date, dateString) => {
                  dispatch({
                    type: "onChangeDate",
                    date,
                    dateString,
                    actionDate: "option-to",
                  });
                }}
              />
            </Space>
          </>
        );
    }
  };
  return (
    <Modal
      open={Boolean(modalDate?.open)}
      onCancel={async () => {
        await setOpenC(false);
        dispatch("closeModalDate");
      }}
      footer={null}
      closable={false}
      className={"modal-filter-date-dashboard"}
      width={800}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <IconButton
          sx={{ padding: "4px" }}
          onClick={async () => {
            await setOpenC(false);
            dispatch("closeModalDate");
          }}
        >
          <ClearIcon fontSize="small" />
        </IconButton>
      </div>
      <div className="conten-modal-filter">
        <div className="title-date">
          {listClick1.map((i, index) => (
            <div
              className={
                modalDate?.option === i.name
                  ? `title-modal-dashboard active`
                  : `title-modal-dashboard`
              }
              key={`list-1-${index}`}
              onClick={() => {
                dispatch({ type: "selectOption", option: i.name });
              }}
            >
              {i.label}
            </div>
          ))}
          <div className="border-modal-dashboard"></div>
          {listClick2.map((i, index) => (
            <div
              className={
                modalDate?.option === i.name
                  ? `title-modal-dashboard active list-2`
                  : `title-modal-dashboard list-2`
              }
              key={`list-2-${index}`}
              onClick={() => {
                dispatch({ type: "selectOption", option: i.name });
              }}
            >
              <span>{i.label}</span>
              <ChevronRightIcon fontSize="small" sx={{ color: "#61646D" }} />
            </div>
          ))}
        </div>
        <div className="conten-date">{renderContentDate()}</div>
      </div>
      <div className="acction-modal-filter">
        <Button
          className="button-ok"
          onClick={async () => {
            await setOpenC(false);
            dispatch("onSubmitModalDate");
          }}
        >
          OK
        </Button>
      </div>
    </Modal>
  );
}
