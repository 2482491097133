import { atom } from "recoil";

export const listProductClaim = atom({
  key: "listProductClaim",
  default: [],
});

export const roleAdminMenuItem = atom({
  key: "roleAdminMenuItem",
  default: {},
});

export const dataSearchAccountManegerV2 = atom({
  key: "dataSearchAccountManegerV2",
  default: {},
});

export const notification = atom({
  key: "notification",
  default: {},
});
