import moment from "moment";

import { getStatusById } from "../utils";

export default function ClaimTableExportExcel(props) {
  const { from, to, data } = props;

  const fromDate = !from
    ? "..............."
    : moment(from).format("DD/MM/YYYY");
  const toDate = !to ? "..............." : moment(to).format("DD/MM/YYYY");

  return (
    <>
      <div style={{ display: "none" }}>
        <table
          className="collapse-border"
          id={"claim-report-export-data-table"}
          data-cols-width="7, 25, 25, 25, 25, 17, 15, 15, 20, 20, 20, 20"
        >
          <thead style={{ fontSize: "13" }}>
            <tr />
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"16"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >
                BÁO CÁO BỒI THƯỜNG
              </th>
            </tr>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th
                data-f-name={"Times new roman"}
                data-f-sz={"12"}
                data-a-h={"left"}
                data-b-a-s={"none"}
                data-f-bold={true}
              >{`Từ ngày: ${fromDate} ---  Đến Ngày: ${toDate} `}</th>
            </tr>
            <tr />
            <tr />
            <tr />
            <tr />
            <tr style={{ fontSize: "13" }}>
              <Th>STT</Th>
              <Th>Tên </Th>
              <Th>Mã hồ sơ</Th>
              <Th>Tên sản phẩm</Th>
              <Th>Doanh nghiệp</Th>
              <Th>Ngày</Th>
              <Th>{"Số tiền yêu cầu (VNĐ)"}</Th>
              <Th>{"Số tiền bồi thường (VNĐ)"}</Th>
              <Th>NS xử lý HS</Th>
              <Th>NS phê duyệt</Th>
              <Th>Trạng thái</Th>
              <Th>Quản lý bởi</Th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13" }}>
            {data.map((item, index) => {
              return (
                <>
                  <tr key={index} style={{ fontSize: "13" }}>
                    <Td>{index + 1}</Td>
                    <Td>{item?.insuredInfo?.insuredName || ""}</Td>
                    <Td>{item.code || ""}</Td>
                    <Td>{item.productTitle || ""}</Td>
                    <Td>{item.merchantTitle || ""}</Td>
                    <Td>
                      {item?.createdDate
                        ? moment(item?.createdDate).format("DD/MM/YYYY")
                        : ""}
                    </Td>
                    {/* <Td>
                      {`${item.requestAmount || "0"}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      ) + " VNĐ"}
                    </Td> */}
                    <th
                      data-f-name={"Times new roman"}
                      data-f-sz={"12"}
                      data-b-a-s={"thin"}
                      data-a-wrap={true}
                      data-t="n"
                    >
                      {item.requestAmount || 0}
                    </th>
                    {/* <Td>
                      {[5, 6, 8, 9].includes(item?.statusId)
                        ? `${item.amount || "-"}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          ) + " VNĐ"
                        : "- VNĐ"}
                    </Td> */}
                    <th
                      data-f-name={"Times new roman"}
                      data-f-sz={"12"}
                      data-b-a-s={"thin"}
                      data-a-wrap={true}
                      data-t="n"
                    >
                      {[5, 6, 8, 9].includes(item?.statusId)
                        ? item.amount || 0
                        : 0}
                    </th>
                    <Td>{item.userFullnameReview || ""}</Td>
                    <Td>{item.userFullnameAssign || ""}</Td>
                    <Td>{getStatusById(item.statusId).labelReport}</Td>
                    <Td>{item?.organizationTitle || ""}</Td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const Th = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-h={"center"}
      data-a-wrap={true}
      data-f-bold={true}
    >
      {props.children}
    </th>
  );
};

const Td = (props) => {
  return (
    <th
      data-f-name={"Times new roman"}
      data-f-sz={"12"}
      data-b-a-s={"thin"}
      data-a-wrap={true}
      data-t={props.type || ""}
    >
      {props.children}
    </th>
  );
};
