import React from "react";
import _ from "lodash";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    textAlign: "center",
    fontFamily: "Open Sans",
    padding: "16px",
  },
  countryTitle1: {
    fontSize: "11px",
  },
});

export default function ExportPDF({ data = {} }) {
  const createdDate = moment(data?.extraInfo?.createdDate);
  const tableCell = [
    {
      title1: "Tên đơn vị giao hàng: ",
      value1: "AHAMOVE",
      title2: "Phương tiện vận tải: ",
      value2: "Xe máy",
    },
    {
      title1: "Tên người thụ hưởng: ",
      value1: data?.extraInfo?.beneficiaryName || "",
      capitalize1: true,
      title2: "Biển số: ",
      value2: data?.extraInfo?.driver?.license || "",
    },
    {
      title1: "Email: ",
      value1: data?.extraInfo?.beneficiaryEmail || "",
      title2: "Mã đơn hàng: ",
      value2: data?.extraInfo?.partnerOrderCode || "",
    },
    {
      title1: "Đơn BH số: ",
      value1: data?.certNum || "",
      title2: "Mã chuyến đi: ",
      value2: data?.extraInfo?.codeDestination || "",
    },
    {
      title1: "Loại hàng hoá: ",
      value1: data?.extraInfo?.productInfo?.typeNameOfProduct || "",
      title2: "Đối tượng bảo hiểm: ",
      value2:
        +data?.extraInfo?.insuranceObj === 1
          ? "Hàng hoá"
          : +data?.extraInfo?.insuranceObj === 2
          ? "Hàng hoá có hoá đơn"
          : +data?.extraInfo?.insuranceObj === 3
          ? "Hàng hoá không có hoá đơn"
          : +data?.extraInfo?.insuranceObj === 4
          ? "Tài liệu"
          : "",
    },
    {
      title1: "Hiệu lực BH từ: ",
      value1: data?.insuredInfo?.beginDate
        ? moment(data?.insuredInfo?.beginDate).format("HH:mm DD/MM/YYYY")
        : "",
      title2: "Loại thanh toán: ",
      value2:
        data?.extraInfo?.paymentInfo?.paymentId === 1
          ? "Ứng thu hộ COD"
          : "Không ứng COD",
    },
    {
      title1: "Hiệu lực BH đến: ",
      value1: data?.insuredInfo?.endDate
        ? moment(data?.insuredInfo?.endDate).format("HH:mm DD/MM/YYYY")
        : "",
      title2: "Ngày xảy ra sự cố: ",
      value2: moment(data?.extraInfo?.riskDate).format("DD/MM/YYYY"),
    },
    {
      title1: "Giá trị hàng hoá: ",
      value1:
        `${data?.extraInfo?.productAmount || 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          "."
        ) + "đ",
      title2: "Địa chỉ người gửi: ",
      value2: data?.extraInfo?.sender?.address || "",
    },
    {
      title1: "Rủi ro gặp phải: ",
      value1: +data?.extraInfo?.typeOfAccident === 1 ? "Hư hỏng" : "Mất mát",
      title2: "Địa chỉ người nhận: ",
      value2: data?.extraInfo?.receiver?.address || "",
      bottom: true,
    },
  ];

  const insuranceInfor = [
    {
      title1: "Họ tên chủ tài khoản: ",
      capitalize1: true,
      value1: data?.extraInfo?.beneficiaryName || "",
      title2: "Ngân hàng: ",
      value2: ` ${data?.extraInfo?.beneficiaryBank || ""}`,
    },
    {
      title1: "Số điện thoại: ",
      value1: data?.extraInfo?.beneficiaryPhone || "",
      title2: "Số tài khoản: ",
      value2: ` ${data?.extraInfo?.beneficiaryBankAcountNumber || ""}`,
    },
  ];

  const signature =
    _.get(
      data?.elements?.find((i) => i.type_code === "sign" || i.type_id == 204),
      "extra_info.documents[0]"
    ) || {};

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ ...styles.countryTitle1 }}>
          <Text style={{ ...styles.countryTitle1 }}>
            CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
          </Text>
          <Text style={{ fontWeight: 600, fontSize: "12px" }}>
            Độc lập - Tự do - Hạnh phúc
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              borderTop: "1px solid black",
              margin: "12px 0",
              width: "130px",
            }}
          />
        </View>
        <View
          style={{
            marginTop: "2px",
            marginBottom: "14px",
            fontWeight: 600,
            fontSize: "14px",
          }}
        >
          <Text>THÔNG BÁO TỔN THẤT VÀ YÊU CẦU</Text>
          <Text>BỒI THƯỜNG VỀ HÀNG HOÁ VẬN CHUYỂN</Text>
        </View>
        <View
          style={{
            fontSize: "11px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Text style={{ fontWeight: 600 }}>{"Kính gửi:  "}</Text>
          <Text> CTCP Bảo Hiểm Hàng Không</Text>
        </View>
        <View style={{ width: "100%", textAlign: "left" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "11px",
              justifyContent: "flex-start",
              paddingLeft: "16px",
              margin: "12px 0",
            }}
          >
            <Text>
              {"Tên người yêu cầu bồi thường: "}
              <Text
                style={{ textTransform: "capitalize" }}
              >{` ${data?.insuredInfo?.insuredName}`}</Text>
            </Text>
            <Text>
              {"Số điện thoại: " + ` ${data?.insuredInfo?.insuredPhone}`}
            </Text>
            <Text>
              Chúng tôi gửi khiếu nại tới Công ty kèm theo hồ sơ tổn thất và đề
              nghị Công ty giải quyết sớm.
            </Text>
          </View>
          <View style={{ display: "flex", width: "100%" }}>
            {tableCell.map((item, index) => (
              <View
                key={index}
                style={{
                  width: "100%",
                  display: "flex",
                  borderLeft: "1px solid black",
                  borderTop: "1px solid black",
                  borderRight: "1px solid black",
                  borderBottom: item.bottom ? "1px solid black" : "none",
                  fontSize: "11px",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    padding: "4px 0",
                    paddingLeft: "6px",
                  }}
                >
                  <Text>
                    {`${item.title1} `}{" "}
                    <Text
                      style={{
                        textTransform: item.capitalize1 ? "capitalize" : "",
                      }}
                    >{`${item.value1} `}</Text>
                  </Text>
                </View>
                <View
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    padding: "4px 0",
                    paddingLeft: "6px",
                    borderLeft: "1px solid black",
                  }}
                >
                  <Text>{`${item.title2} ${item.value2}`}</Text>
                </View>
              </View>
            ))}
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "11px",
              justifyContent: "flex-start",
              paddingLeft: "16px",
              margin: "8px 0",
              width: "100%",
            }}
          >
            <Text style={{ paddingLeft: "24px" }}>
              Mô tả tổn thất, diễn biến:{" "}
            </Text>
            <Text style={{ paddingTop: "6px" }}>
              {data?.extraInfo?.describe}
            </Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                textDecoration: "underline",
                fontSize: "11px",
                padding: "6px 0",
              }}
            >
              <Text>Thông tin người thụ hưởng: </Text>
            </View>
            {insuranceInfor.map((item, index) => (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "11px",
                  justifyContent: "flex-start",
                }}
              >
                <View style={{ width: "50%" }}>
                  <Text>
                    {item.title1}{" "}
                    <Text
                      style={{
                        textTransform: item.capitalize1 ? "capitalize" : "",
                      }}
                    >{` ${item.value1}`}</Text>
                  </Text>
                </View>
                <View style={{ width: "50%" }}>
                  <Text>
                    {item.title2} <Text>{` ${item.value2}`}</Text>
                  </Text>
                </View>
              </View>
            ))}
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: "11px",
                justifyContent: "flex-start",
                margin: "8px 0",
              }}
            >
              <Text>
                <Text style={{ fontWeight: 600 }}>{"Cam đoan: "}</Text>
                Tôi xin cam đoan những kê khai trên đây là đúng sự thực và theo
                sự hiểu biết của tôi. Nếu có gì sai, tôi xin chịu hoàn toàn
                trách nhiệm.
              </Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              fontSize: "11px",
              justifyContent: "end",
              textAlign: "right",
              // marginTop: "4px",
              position: "relative",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "11px",
                justifyContent: "center",
                textAlign: "center",
                position: "absolute",
                width: "45%",
                right: "20px",
              }}
            >
              <Text>{`......... Ngày ${createdDate.format(
                "D"
              )} tháng ${createdDate.format("M")} năm ${createdDate.format(
                "YYYY"
              )}`}</Text>
              <Text style={{ fontWeight: 600, marginBottom: "8px" }}>
                Người khai
              </Text>
              {signature?.path ? (
                <>
                  <Image
                    source={signature?.path || ""}
                    style={{
                      width: "90px",
                      height: "55px",
                      display: "flex",
                      justifyContent: "center",
                      position: "absolute",
                      left: "33%",
                      top: "40px",
                    }}
                  />
                  <Text
                    style={{
                      fontWeight: 600,
                      textTransform: "capitalize",
                      marginTop: "58px",
                    }}
                  >
                    {data?.insuredInfo?.insuredName || ""}
                  </Text>
                </>
              ) : null}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
