import React, { useEffect, useRef, useState } from "react";
import { IconButton, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ReactComponent as ArrowRotateLeft } from "../../../../../../../assets/health/arrow-rotate-left.svg";
import { ReactComponent as ArrowRotateRight } from "../../../../../../../assets/health/arrow-rotate-right.svg";
import { ReactComponent as ZoomOut } from "../../../../../../../assets/health/zoom-out.svg";
import { ReactComponent as ZoomIn } from "../../../../../../../assets/health/zoom-in-2.svg";

export default function BoxImgLicense(props) {
  const { state, dispatch } = props;
  const classes = makeStyle();
  const [loadImg, setLoadmg] = useState(true);
  const ref = useRef(null);

  const { pictureBox, imgLicenseShow } = state;

  useEffect(() => {
    const boxElement = document.getElementById("picture-box-2");
    if (boxElement) {
      boxElement.style.transform = `rotate(${
        pictureBox?.rotate || 0
      }deg) scale(${pictureBox?.scale || 1})`;
    }
  }, [pictureBox]);

  useEffect(() => {
    setLoadmg(true);
  }, [imgLicenseShow?.path]);

  return (
    <div className={classes.container}>
      <div className="header">Chứng từ</div>
      {loadImg && <LinearProgress />}
      <div className={classes.content}>
        <div className="box-img" id="scroll-div">
          <div className="box-img-scroll" id="picture-box-2">
            <img
              src={imgLicenseShow.path}
              onLoad={() => setLoadmg(false)}
              style={{ width: "100%", display: loadImg ? "none" : "block" }}
            />
          </div>
        </div>
        <div className={classes.buttons}>
          <div className="left-button">
            <IconButton
              onClick={() => {
                dispatch({
                  type: "onChangeIndexImg",
                  name: "pree",
                });
              }}
            >
              <NavigateBeforeIcon style={{ color: "white" }} />
            </IconButton>
            <span>{`${imgLicenseShow.indexImg} / ${
              (imgLicenseShow.list || []).length
            } `}</span>
            <IconButton
              onClick={() => {
                dispatch({
                  type: "onChangeIndexImg",
                  name: "next",
                });
              }}
            >
              <KeyboardArrowRightIcon style={{ color: "white" }} />
            </IconButton>
          </div>
          <div>
            <IconButton
              onClick={() =>
                dispatch({
                  type: "onChangePictureBox",
                  typeAction: "rotated-left",
                })
              }
            >
              <ArrowRotateLeft className="icon-button" />
            </IconButton>
            <IconButton
              onClick={() =>
                dispatch({
                  type: "onChangePictureBox",
                  typeAction: "rotated-right",
                })
              }
            >
              <ArrowRotateRight className="icon-button" />
            </IconButton>
            <IconButton
              onClick={() =>
                dispatch({ type: "onChangePictureBox", typeAction: "zoom-out" })
              }
            >
              <ZoomOut className="icon-button" />
            </IconButton>
            <IconButton
              onClick={() =>
                dispatch({ type: "onChangePictureBox", typeAction: "zoom-in" })
              }
            >
              <ZoomIn />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

const makeStyle = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",

    "& .header": {
      color: "#3961A0",
      fontWeight: "600",
      background: "#ECECEC",
      padding: "8px",
    },
  },

  content: {
    width: "100%",
    background: "#686868",
    padding: "8px",
    // height: "calc(100% - 40px)",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "& .box-img": {
      overflow: "scroll",
      height: "100%",
      width: "100%",

      "& .box-img-scroll": {
        width: "100%",
        // height: "100%",
        display: "flex",
        transition: `transform 0.5s`,
      },
    },
  },

  buttons: {
    display: "flex",
    justifyContent: "space-between",

    "& .left-button": {
      color: "white",
    },

    "& .icon-button": {
      "& path": {
        stroke: "white",
      },
    },
  },

  //
}));
