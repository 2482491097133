import { message } from "antd";
import { cloneDeep, get } from "lodash";
import {
  addProductMerchant,
  getMerchantDetail,
  getProductList,
  getProductParentList,
  updateMerchants,
  uploadImage,
} from "../../../../apis/merchantApis";
import * as PATH from "../../../../configs/routesConfig";

const detailReducer = ({
  initialState,
  state,
  action,
  dispatch,
  history,
  id,
}) => {
  const cases = {
    getDetail: async () => {
      dispatch("onLoading");
      const result = await getMerchantDetail(id);
      if (result instanceof Error) {
        message.error(
          result.response?.data?.message || "Lỗi lấy chi tiết khách hàng",
          2
        );
        return { loading: false };
      }
      const data = get(result, "data.result") || {};
      data.url_logo = data?.urlLogo;
      data.taxCode = get(data, "extraInfo.taxCode");
      data.phone = get(data, "extraInfo.phone");
      data.address = get(data, "extraInfo.address");
      data.email = get(data, "extraInfo.email");

      const open = { merchant: false, product: false };
      return { data, open, loading: false };
    },
    getListProductParent: async () => {
      const { data } = state;
      const { value } = action;
      const p = {
        keyword: value || "",
        enableClaim: 1,
        limit: 100,
        offset: 0,
      };
      const res = await getProductParentList(p);
      if (res instanceof Error) {
        message.error(res.response?.data?.message || "Lỗi lấy danh sách", 2);
      }
      const product_parent = get(res, "data.result.data") || [];
      return { product_parent };
    },
    onSelect: async () => {
      const { data } = state;
      const { name, value } = action;
      const res = {};
      data[name] = value || {};
      if (name === "productParent") {
        res.products = [];
        data.provider = {};
        if (data.productParent?.id) {
          dispatch("getListProduct");
        }
      }
      return { ...res, data };
    },
    getListProduct: async () => {
      const { data } = state;
      const { value } = action;
      const p = {
        keyword: value || "",
        productIdParent: data.productParent.id,
        statusId: 1,
        limit: 100,
        offset: 0,
      };
      const res = await getProductList(p);
      if (res instanceof Error) {
        message.error(res.response?.data?.message || "Lỗi lấy danh sách", 2);
      }
      const products = get(res, "data.result") || [];
      return { products };
    },
    upLoadFile: async () => {
      const { e } = action;
      const { editData } = state;

      if (e.target.files.length < 1) {
        return;
      }
      const file = e.target.files[0];
      const formdata = new FormData();
      formdata.append("document", file);

      dispatch("loadImg");
      const dataApi = await uploadImage(formdata);
      const item = get(dataApi, "data.result[0]") || null;
      editData.url_logo = item?.path || null;
      return { editData, imgLoading: false };
    },
    onChange: () => {
      const { editData } = state;
      const { name, value } = action;
      editData[name] = value;
      return { editData };
    },

    validate: () => {
      const { editData } = state;
      const errors = [];
      const keys = ["title", "taxCode", "phone", "address", "email"];
      keys.forEach((i) => {
        !editData[i] && errors.push(i);
      });
      if (errors.length === 0) {
        dispatch("onSave");
      }
      return { errors };
    },
    onSave: async () => {
      const { editData, open } = state;
      const d = {
        id,
        title: editData?.title,
        url_logo: editData?.url_logo,
        extraInfo: {
          taxCode: editData?.taxCode,
          phone: editData?.phone,
          address: editData?.address,
          email: editData?.email,
        },
      };
      dispatch("onSaving");
      const res = await updateMerchants(d);
      if (res instanceof Error) {
        message.error(res.response?.data?.message || "Lỗi cập nhật", 2);
        return { saving: false };
      }
      message.success(res.data?.message || "Cập nhật thành công", 2);
      dispatch("getDetail");
      return { data: {}, saving: false, open: { ...open, merchant: false } };
    },

    addProduct: async () => {
      const { data, open } = state;
      const p = {
        productId: +data.provider.id,
        merchantId: +id,
      };
      const res = await addProductMerchant(p);
      if (res instanceof Error) {
        return message.error(
          res.response?.data?.message || "Lỗi thêm sản phẩm",
          2
        );
      }
      message.success("Thêm sản phẩm thành công.!", 2);
      const products = [];
      data.productParent = {};
      data.provider = {};
      open.product = false;
      dispatch("getDetail");
      return { open, products, data };
    },

    onOpen: () => {
      const { open, data } = state;
      const res = {};
      open[action.name] = true;
      if (action.name === "merchant") {
        res.editData = cloneDeep(data);
      }
      return { ...res, open };
    },
    onClose: () => {
      const { open, data } = state;
      if (!action.name) {
        return { errors: [], open: { ...open, merchant: false } };
      }
      const product_parent = [];
      open.product = false;
      data.insurance_type = {};
      data.product_name = {};
      return { open, data, product_parent, errors: [] };
    },
    onLoading: () => ({ loading: true }),
    loadImg: () => ({ imgLoading: true }),
    changePage: async () => {
      const { item } = action;
      history.push(
        `${PATH.AD_MERCHANT_PACKAGE}?merchantId=${id}&id=${item.product_id}`
      );
    },
  };
  return cases[action?.type];
};
export default detailReducer;
