// Hồ sơ thương tật tạm thời liên quan đến xương / Ngoại trú
const bone_temporary_out = [
  {
    id: 1,
    typeId: 300,
    typeCode: "private_id",
    title: "Hình chụp CMND/CCCD mặt trước và sau",
    minimumUpload: 2,
  },
  {
    id: 2,
    typeId: 301,
    typeCode: "vehicles",
    title: "Hình chụp giấy đăng ký, đăng kiểm, giấy phép lái xe (cavet xe)",
  },
  {
    id: 3,
    typeId: 302,
    typeCode: "prescription",
    title:
      "Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và dấu mộc bệnh viện/ phòng khám/cơ sở y tế",
  },
  {
    id: 4,
    typeId: 303,
    typeCode: "xq",
    title:
      "Hình chụp chỉ định chụp XQ, MRI… hoặc các chỉ định xét nghiệm tương tự",
  },
  {
    id: 5,
    typeId: 304,
    typeCode: "xq_result",
    title:
      "Hình chụp phiếu đọc kết quả phim XQ, MRI… hoặc các kết quả xét nghiệm tương tự",
  },
  {
    id: 7,
    typeId: 320,
    typeCode: "photo_info_booking",
    title: "Hình chụp thông tin đặt chuyến",
    text: true,
  },
  {
    id: 6,
    typeId: 305,
    typeCode: "bill",
    title: "Hình chụp hoá đơn điều trị",
    text: true,
  },
];

// Hồ sơ thương tật tạm thời / Ngoại trú
const temporary_out = [
  {
    id: 1,
    typeId: 300,
    typeCode: "private_id",
    title: "Hình chụp CMND/CCCD mặt trước và sau",
    minimumUpload: 2,
  },
  {
    id: 2,
    typeId: 301,
    typeCode: "vehicles",
    title: "Hình chụp giấy đăng ký, đăng kiểm, giấy phép lái xe (cavet xe)",
  },
  {
    id: 3,
    typeId: 302,
    typeCode: "prescription",
    title:
      "Hình chụp toa thuốc có ghi chẩn đoán, có chữ ký bác sỹ và dấu mộc bệnh viện/ phòng khám/cơ sở y tế",
  },
  {
    id: 4,
    typeId: 307,
    typeCode: "examination_and_result",
    title:
      "Phiếu chỉ định của bác sĩ đối với yêu cầu xét nghiệm, phiếu chụp và kết quả chiếu chụp-xét nghiệm",
  },
  {
    id: 7,
    typeId: 320,
    typeCode: "photo_info_booking",
    title: "Hình chụp thông tin đặt chuyến",
    text: true,
  },
  {
    id: 5,
    typeId: 305,
    typeCode: "bill",
    title: "Hình chụp hoá đơn điều trị",
    text: true,
  },
];

// Hồ sơ thương tật tạm thời /nội trú
const temporary_in = [
  {
    id: 1,
    typeId: 300,
    typeCode: "private_id",
    title: "Hình chụp CMND/CCCD mặt trước và sau",
    minimumUpload: 2,
  },
  {
    id: 2,
    typeId: 301,
    typeCode: "vehicles",
    title: "Hình chụp giấy đăng ký, đăng kiểm, giấy phép lái xe (cavet xe)",
  },
  {
    id: 3,
    typeId: 308,
    typeCode: "hospital_checkout",
    title: "Hình chụp giấy ra viện, đơn thuốc xuất viện",
  },
  {
    id: 4,
    typeId: 309,
    typeCode: "detail_hospital_checkout",
    title: "Hình chụp bảng kê chi tiết viện phí",
  },
  {
    id: 5,
    typeId: 310,
    typeCode: "surgery",
    title: "Hình chụp giấy chứng nhận phẫu thuật (nếu có)",
    optional: true,
  },
  {
    id: 7,
    typeId: 320,
    typeCode: "photo_info_booking",
    title: "Hình chụp thông tin đặt chuyến",
    text: true,
  },
  {
    id: 6,
    typeId: 305,
    typeCode: "bill",
    title: "Hình chụp hoá đơn điều trị",
    text: true,
  },
];

// Hồ sơ thương tật vĩnh viễn
const hurt_forever = [
  {
    id: 1,
    typeId: 300,
    typeCode: "private_id",
    title: "Hình chụp CMND/CCCD mặt trước và sau",
    minimumUpload: 2,
  },
  {
    id: 2,
    typeId: 301,
    typeCode: "vehicles",
    title: "Hình chụp giấy đăng ký, đăng kiểm, giấy phép lái xe (cavet xe)",
  },
  {
    id: 3,
    typeId: 311,
    typeCode: "hospital_report",
    title: "Hình chụp báo cáo y tế/ Hồ sơ bệnh án của bệnh viện ( nếu có )",
    optional: true,
  },
  {
    id: 4,
    typeId: 312,
    typeCode: "injured",
    title: "Hình chụp giấy chứng nhận thương tật của cơ quan có thẩm quyền",
  },
  // {
  //     id: 5,
  //     typeId: 310,
  //     title: 'Hình chụp giấy chứng nhận phẩu thuật (nếu có)',
  //     optional: true,
  // },
  // {
  //     id: 6,
  //     typeId: 305,
  //     title: 'Hình chụp hoá đơn điều trị',
  //     text: true,
  // },
  {
    id: 7,
    typeId: 320,
    typeCode: "photo_info_booking",
    title: "Hình chụp thông tin đặt chuyến",
    text: true,
  },
];

// Hồ sơ tử vong
const dead = [
  {
    id: 1,
    typeId: 300,
    typeCode: "private_id",

    title: "Hình chụp CMND/CCCD mặt trước và sau",
    minimumUpload: 2,
  },
  {
    id: 2,
    typeId: 313,
    typeCode: "beneficiary_private_id",
    title:
      "Hình chụp CMND/CCCD mặt trước và sau của người thụ hưởng quyền lợi bảo hiểm",
    minimumUpload: 2,
  },
  {
    id: 3,
    typeId: 314,
    typeCode: "government_report_ dead",
    title:
      "Hình chụp bản gốc biên bản công an điều tra có kết luận nguyên nhân tử vong/ biên bản tai nạn có xác nhận của chính quyền địa phương hoặc cơ quan chủ quản.",
  },
  {
    id: 4,
    typeId: 311,
    typeCode: "government_report_ dead",
    title:
      "Hình chụp báo cáo y tế/ Hồ sơ bệnh án của bệnh viện có kết luận nguyên nhân tử vong (nếu có)",
    optional: true,
  },
  {
    id: 5,
    typeId: 316,
    typeCode: "death_cert",
    title: "Hình chụp giấy chứng tử",
  },
  {
    id: 6,
    typeId: 317,
    typeCode: "testament",
    title:
      "Hình chụp biên bản phân chia di sản thừa kế có xác nhận của cơ quan chức năng/ phường xã",
    text: true,
  },
  {
    id: 7,
    typeId: 320,
    typeCode: "photo_info_booking",
    title: "Hình chụp thông tin đặt chuyến",
    text: true,
  },
];

export { bone_temporary_out, temporary_out, temporary_in, hurt_forever, dead };
