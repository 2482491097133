import moment from "moment";

const formatDate = (date) => {
  if (!date) return "";
  const newDate = moment(date).format("DD/MM/YYYY");
  return newDate !== "Invalid date" ? newDate : "";
};

const getParamUrlByKey = (key) => {
  let search = window.location.search;
  let arr = search.split("?");
  let params = new URLSearchParams(arr[1]);
  let platform = params.get(key) || "";
  return platform;
};

const types = [
  {
    insurance_code: "suc_khoe,tai_nan",
    insurance_title: "Bảo hiểm sức khoẻ, tai nạn",
  },
  { insurance_code: "suc_khoe", insurance_title: "Bảo hiểm sức khoẻ" },
  { insurance_code: "tai_nan", insurance_title: "Bảo hiểm tai nạn" },
  { insurance_code: "khac", insurance_title: "Khác" },
];

export { formatDate, getParamUrlByKey, types };
