import axios from "axios";
import config from "../../../../../configs/config";

export async function getRoles() {
  return await axios
    .get(`${config.SERVER}/api/v1/tomato/user/role`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .catch((e) => e);
}

export async function getUsers(params) {
  return await axios
    .get(`${config.SERVER}/api/v1/tomato/users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params,
    })
    .catch((e) => e);
}

export async function getUserDetail(id) {
  return await axios
    .get(`${config.SERVER}/api/v1/tomato/user/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .catch((e) => e);
}

export async function updateUserStatus(userId, status) {
  return await axios
    .put(
      `${config.SERVER}/api/v1/tomato/user/${userId}`,
      {
        status: status ? 1 : 0,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .catch((e) => e);
}

export async function updateUserInfoAPI(userId, data) {
  return await axios
    .put(`${config.SERVER}/api/v1/tomato/user/${userId}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .catch((e) => e);
}

export async function createUserApi(data) {
  return await axios
    .post(`${config.SERVER}/api/v1/tomato/user`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .catch((e) => e);
}
