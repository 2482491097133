import React from "react";
import { Button, MenuItem } from "@mui/material";

import { detailProduct } from "../styles/styleDetailProduct";
import { Switch, TextField } from "../overrideMui";
import moment from "moment";
import { useParams } from "react-router-dom";

export default function InfoDetailUpdateProduct(props) {
  const classes = detailProduct();
  const { state, dispatch } = props;
  const { id } = useParams();

  const { details, payloadProductPackage } = state;

  return (
    <div style={{ width: "100%" }}>
      <div className={`${classes.box_status} ${classes.no_margin_top} `}>
        <div className="title">Thông tin sản phẩm</div>
        <div className="date-label">Loại bảo hiểm</div>
        <div className="date-value">{"BH con người"}</div>
        <div className="date-label">Tên sản phẩm</div>
        <div className="date-value">{details?.title || ""}</div>
        <div className="date-label">Mã sản phẩm</div>
        <div className="date-value">{details?.product_id_parent || ""}</div>
      </div>
      <div className={classes.box_status}>
        <div className="title">Trạng thái</div>
        <div className="div-switch">
          <span>Trạng thái hoạt động</span>
          <Switch
            checked={Boolean(payloadProductPackage?.status)}
            // disabled={id == "new"}
            onChange={(e) => {
              dispatch({
                type: "onChangePayload",
                name: "status",
                value: e.target.checked ? 1 : 0,
              });
            }}
          />
        </div>
        <div className="date-label">Ngày tạo</div>
        <div className="date-value">
          {moment(id === "new" ? {} : details?.created_date || "").format(
            "DD/MM/YYYY HH:mm"
          )}
        </div>
        <div className="date-label">Ngày cập nhật</div>
        <div className="date-value">-</div>
      </div>
    </div>
  );
}
