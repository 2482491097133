import React, { useEffect, useState, useRef } from "react";
import { Row } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

import { getListYourClaim } from "../../../apis/carAccidentApis";
import { getListYourClaim_v2 } from "../../../apis/apis-v2";
import iconListInsurance from "../../../assets/icons/iconListInsurance.svg";
import iconArrowRight from "../../../assets/icons/iconArrowRight.svg";
import { getRoute } from "../../../utils/utils";
import * as PATH from "../../../configs/routesConfig";
import "./list.css";
import HeaderBar from "../../../ui/HeaderBar";
const queryString = require("query-string");

const YourClaimList = () => {
  const history = useHistory();
  const location = useLocation();
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productIdParent, setProductIdParent] = useState("");
  const [listDataFilter, setListDataFilter] = useState([]);

  const contants_status = [
    { value: 1, label: "Chờ xử lý", color: "#26A9E0" },
    { value: 2, label: "Đang xử lý", color: "#DBAC06" },
    { value: 3, label: "Chờ phê duyệt", color: "#6372DA" },
    { value: 4, label: "Bổ sung hồ sơ", color: "#FB7800" },
    { value: 5, label: "Đã phê duyệt", color: "#1E02CF" },
    { value: 6, label: "Hoàn tất", color: "#39B44A" },
    { value: 7, label: "Từ chối", color: "#BF272D" },
  ];

  useEffect(() => {
    const q = queryString.parse(location?.search);
    setProductIdParent(q.productIdParent || "");
    localStorage.setItem("token", q.token);
    document.body.style.backgroundColor = "#F7F7F7";
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (productIdParent) {
      getList();
    }
  }, [productIdParent]);

  const getList = () => {
    setIsLoading(true);
    const params = {
      productIdParent: productIdParent,
      limit: 1000,
    };
    +productIdParent == 19
      ? getListYourClaim(params)
          .then((res) => {
            const data = res?.data?.result?.data;
            // Tạm thời chỉ lấy danh sách sản phẩm vật chất ô tô
            setListData(data);
            setListDataFilter(data);
            setIsLoading(false);
          })
          .catch((err) => {
            console.error("err", err);
            setIsLoading(false);
          })
      : getListYourClaim_v2(params)
          .then((res) => {
            const data = res?.data?.result?.data;
            // Tạm thời chỉ lấy danh sách sản phẩm vật chất ô tô
            setListData(data);
            setListDataFilter(data);
            setIsLoading(false);
          })
          .catch((err) => {
            console.error("err", err);
            setIsLoading(false);
          });
  };

  const handleGetDetails = (item) => () => {
    const id =
      +productIdParent == 19 ? item?.productIdParent : item?.product_id_parent;

    switch (+id) {
      case 15:
        history.push(`${PATH.DETAIL_PHUCBINHAN}?id=${item?.id}&goback`);
        break;
      case 17:
        history.push(
          getRoute(PATH.DETAILS_CLAIM_CAR_BH365, item.id) + "?goback",
          {
            claimId: item.id,
          }
        );
        break;
      case 19:
        history.push(
          getRoute(PATH.INSU_TOMATO_CLAIM_DETAIL, item.id) + "?goback"
        );
        break;
      case 24:
        history.push(`${PATH.DETAIL_INTRANSIT_BH365}?id=${item?.id}&goback`);
        break;
      // case 6:
      // case 8:
      // case 22:
      // case 31:
      //   history.push(`${PATH.HISTORY_HEALTH_CLAIM}?id=${item?.id}&goback`);
      default:
        history.push(`${PATH.HISTORY_HEALTH_CLAIM}?id=${item?.id}&goback`);
    }
  };

  const onGoBack = () => {
    window.ReactNativeWebView.postMessage("onGoBack");
  };

  const renderListData = (item, index) => {
    return (
      <Row
        style={{
          minHeight: 100,
          display: "flex",
          alignItems: "center",
          marginTop: 20,
        }}
        key={index}
        onClick={handleGetDetails(item)}
      >
        <img
          src={iconListInsurance}
          alt="iconListInsurance"
          style={{
            width: 52,
            height: 52,
            marginBottom: 10,
          }}
        />
        <div
          style={{
            borderBottom: "0.5px solid rgba(0, 0, 0, 0.19)",
            paddingBottom: 10,
            flex: 1,
          }}
        >
          <div style={styleLeftItem}>
            <Typography color="#000">
              HS bồi thường mã:
              <span style={{ fontSize: 15, color: "#024281" }}>
                {" "}
                {item?.code}
              </span>
            </Typography>
            <Typography
              style={styleTitleI}
              color={
                contants_status.filter(
                  (status) => +status.value === +item?.statusId
                )[0]?.color
              }
            >
              {
                contants_status.filter(
                  (status) => +status.value === +item?.statusId
                )[0]?.label
              }
            </Typography>
            <div style={styleTxtContent}>
              {moment(
                item?.created_date ? item?.created_date : item?.createdDate
              ).format("HH:mm DD/MM/YYYY")}
            </div>
          </div>
        </div>
        <img
          src={iconArrowRight}
          alt="iconArrowRight"
          style={{
            marginBottom: 10,
          }}
        />
      </Row>
    );
  };

  return (
    <div style={styleContainer}>
      <HeaderBar
        title=" Hồ sơ bồi thường của bạn"
        background="#F2F2F2"
        onPress={onGoBack}
      />
      {isLoading && (
        <div style={styleLoading}>
          <LoadingOutlined
            style={{
              fontSize: 30,
              color: "#E16B2F",
            }}
          />
        </div>
      )}
      {!listDataFilter?.length && !isLoading ? (
        <div style={styleDataNull}>Không tìm thấy dữ liệu</div>
      ) : (
        listDataFilter?.map((item, index) => renderListData(item, index))
      )}
      <div style={{ height: 50 }} />
    </div>
  );
};

const styleContainer = {
  paddingLeft: 16,
  marginTop: 55,
};

const styleLeftItem = {
  paddingLeft: 14,
};

const styleTitleI = {
  fontSize: 15,
};

const styleTxtContent = {
  fontSize: 15,
  fontWeight: "400",
  color: "rgba(0, 0, 0, 1)",
  margin: "4px 0 4px 0",
};

const styleDataNull = {
  fontSize: 17,
  color: "rgba(0, 0, 0, 0.32)",
  textAlign: "center",
};

const styleLoading = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  marginTop: 50,
};

export default YourClaimList;
