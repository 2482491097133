import React from "react";
import * as ReactDOM from "react-dom";
import { Button } from "@mui/material";

export default function Modal(props) {
  const { open, close } = props;

  const handalClick = (e) => {
    //  close();
  };

  return (
    <div
      onClick={() => close()}
      style={{ ...modal, display: open ? "flex" : "none" }}
      className="modal"
    >
      <div style={modalContent}>
        <div style={content}>
          <div
            style={{
              ...button,
              borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            Chụp hình
          </div>
          <div style={button}>Tải ảnh lên</div>
        </div>
        <div style={cancel}>Hủy</div>
      </div>
    </div>
  );
}

const modal = {
  position: "fixed",
  zIndex: 11111,
  paddingTop: "100px",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  overflow: "auto",
  backgroundColor: "rgb(0,0,0)",
  backgroundColor: "rgba(0,0,0,0.4)",
  display: "flex",
  justifyContent: "center",
};

const modalContent = {
  padding: "20px 20px 71px 20px",
  width: "100%",
  bottom: "24px",
  maxWidth: "500px",
  display: "flex",
  flexDirection: "column",
  alignSelf: "flex-end",
};

const content = {
  bottom: "61px",
  width: "100%",
  background: "#FFFFFF",
  borderRadius: "14px",
};

const button = {
  width: "100%",
  borderRadius: "14px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#FFFFFF",
  height: "52px",
  fontWeight: "700",
  fontSize: "17px",
  lineHeight: "22px",
  letterSpacing: "-0.408px",
  color: "#000000",
};

const cancel = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#FFFFFF",
  borderRadius: "14px",
  marginTop: "24px",
  height: "52px",
  fontWeight: "700",
  fontSize: "17px",
  lineHeight: "22px",
  letterSpacing: "-0.408px",
  color: "#FE3030",
};
