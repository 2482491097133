import React from "react";

import styleCI from "./styleCI";
import { get } from "lodash";
import moment from "moment";

export default function TimeRemaining(props) {
  const classes = styleCI();
  const { state, name } = props;
  const isCopay = name == "copay";

  const { detail } = state;

  const waitedDay = moment().diff(moment(detail.beginDate), "days");
  const getValue = (name) => {
    if (isCopay) {
      const temp =
        // get(detail, `benefitConfig.cert.config_share_payment.${name}`) != 0 ||
        !!get(detail, `benefitConfig.cert.config_share_payment.${name}`)
          ? get(detail, `benefitConfig.cert.config_share_payment.${name}`)
          : // get(detail, `benefitConfig.package.config_share_payment.${name}`) !=
          //     0 ||
          !!get(detail, `benefitConfig.package.config_share_payment.${name}`)
          ? get(detail, `benefitConfig.package.config_share_payment.${name}`)
          : "0";

      return temp === "--" ? temp : `${temp} %`;
    }

    const temp =
      // get(detail, `benefitConfig.cert.config_waiting.${name}`) != 0 ||
      !!get(detail, `benefitConfig.cert.config_waiting.${name}`)
        ? get(detail, `benefitConfig.cert.config_waiting.${name}`)
        : //  get(detail, `benefitConfig.package.config_waiting.${name}`) != 0 ||
        !!get(detail, `benefitConfig.package.config_waiting.${name}`)
        ? get(detail, `benefitConfig.package.config_waiting.${name}`)
        : "0";

    return temp > 0 ? (temp - waitedDay > -1 ? temp - waitedDay : 0) : temp;
  };

  const dataRender = [
    {
      headLabel: "Tai nạn",
      value: getValue("accident"),
      minWidth: 70,
    },
    {
      headLabel: "Bệnh có sẵn",
      value: getValue("existing_disease"),
      minWidth: 120,
    },
    {
      headLabel: "Bệnh đặc biệt",
      value: getValue("special_disease"),
      minWidth: 120,
    },
    {
      headLabel: "Thai sản",
      value: getValue("pregnancy"),
      minWidth: 90,
    },
    {
      headLabel: "Biến chứng thai sản",
      value: getValue("pregnancy_complications"),
      minWidth: 170,
    },
    {
      headLabel: "Bệnh TT Nội trú",
      value: getValue("inpatient_treatment"),
      minWidth: 180,
    },
    {
      headLabel: "Bệnh TT Ngoại trú",
      value: getValue("outpatient_treatment"),
      minWidth: 180,
    },
    {
      headLabel: "Răng",
      value: getValue("tooth"),
      minWidth: 70,
    },
  ];

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <div className={classes.containeer_time_remaining}>
        {dataRender.map((i, index) => (
          <div
            key={`key_div_render_${index}`}
            className="div-column"
            style={{ minWidth: i.minWidth ? i.minWidth : "" }}
          >
            <div className="head-table">{i.headLabel}</div>
            <div className="value-table">{i.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
