import React from "react";
import { useStyles } from "../styles/infoStyles";
import { TextField } from "@mui/material";

function InfoBooker({ state, dispatch }) {
  const {
    extraInfo,
    destination: { data, index },
    errors,
  } = state;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className="header my-5">THÔNG TIN NGƯỜI GỬI</div>
      <Row
        title="Họ và tên"
        value={data?.sender?.fullName || ""}
        styleValue={{ textTransform: "capitalize" }}
      />
      <Row title="Số điện thoại" value={data?.sender?.phoneNumber || ""} />
      <Row
        title="Địa chỉ"
        value={data?.sender?.address || ""}
        styleValue={{ textTransform: "capitalize" }}
      />
      <div className="header my-5">THÔNG TIN NGƯỜI NHẬN</div>
      <div style={{ marginTop: 20 }}>
        <TextField
          fullWidth
          focused
          size="small"
          placeholder="Nhập thông tin"
          label={
            <div>
              Họ và tên <span style={{ color: "red" }}> *</span>
            </div>
          }
          onChange={(e) =>
            dispatch({
              type: "onChangeValue",
              name: "receiverName",
              value: e.target.value,
            })
          }
          value={extraInfo?.receiverName || data?.receiver?.fullName || ""}
          className={classes.inputText}
        />
        {errors.includes("receiverName") && (
          <div className={classes.textErr}>Vui lòng nhập đầy đủ thông tin</div>
        )}
      </div>
      <div style={{ marginTop: 20 }}>
        <TextField
          fullWidth
          focused
          size="small"
          placeholder="Nhập thông tin"
          label={
            <div>
              Số điện thoại <span style={{ color: "red" }}> *</span>
            </div>
          }
          onChange={(e) =>
            dispatch({
              type: "onChangeValue",
              name: "receiverPhone",
              value: e.target.value,
            })
          }
          value={extraInfo?.receiverPhone || data?.receiver?.fullName || ""}
          className={classes.inputText}
        />
        {errors.includes("receiverPhone") && (
          <div className={classes.textErr}>Vui lòng nhập đầy đủ thông tin</div>
        )}
      </div>
      <div style={{ marginTop: 20 }}>
        <TextField
          fullWidth
          focused
          multiline
          rows={3}
          size="small"
          placeholder="Nhập thông tin"
          label={
            <div>
              Địa chỉ
              <span style={{ color: "red" }}> *</span>
            </div>
          }
          value={data?.receiver?.address || ""}
          className={classes.bookerInput}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
      <div className="header my-5">THÔNG TIN THANH TOÁN</div>
      <Row
        title="Loại thanh toán"
        value={
          data?.paymentInfo?.paymentId === 1
            ? "Ứng thu hộ COD"
            : data?.paymentInfo?.paymentId === 2
            ? "Không ứng COD"
            : null
        }
      />
      <div className="header my-5">LOẠI HÀNG HOÁ</div>
      <Row
        title="Phân loại"
        value={data?.productInfo?.typeNameOfProduct || ""}
      />
      <div className="header my-5">TRẠNG THÁI</div>
      <Row
        title="Trạng thái"
        value={data?.statusId === 1 ? "Thành công" : "Thất bại"}
      />
    </div>
  );
}

export default InfoBooker;

const Row = ({ title, value, styleValue }) => {
  return (
    <div className="rowDiv">
      <span className="titleSpan">{title}</span>
      <span className="valueSpan" style={{ overflow: "hidden", ...styleValue }}>
        {value}
      </span>
    </div>
  );
};
