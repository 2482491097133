import { makeStyles } from "@mui/styles";
import React from "react";
import CommonTable from "../../../../library/layout/commonTable";
import emptyIcon from "../assets/empty.svg";
import { columns } from "./columns-import";

function ImportTable({ list }) {
  const classes = useStyles();
  return (
    <CommonTable
      classes={classes}
      rows={list}
      columns={columns}
      empty={
        <div className="empty">
          <img src={emptyIcon} alt="empty" />
        </div>
      }
    />
  );
}

export default ImportTable;

const useStyles = makeStyles(() => ({
  table: {
    marginTop: "20px",
    minWidth: "1300px",

    "& thead th": {
      textAlign: "center",
    },
    "& thead .stt": {
      minWidth: "45px",
    },
    "& thead .left": {
      textAlign: "left",
    },
    "& thead .nv": {
      maxWidth: "100px",
    },
    "& tbody .center": {
      textAlign: "center",
    },
    "& tbody .fullname": {
      fontWeight: 600,
      color: "#3961A0",
      whiteSpace: "nowrap",
      width: "max-content",
    },
    "& tbody .date": {
      color: "#3961A0",
      textAlign: "center",
    },
    "& tbody .stt": {
      textAlign: "center",
      padding: "4px 12px",
      borderRadius: 20,
    },
    "& tbody .color1": {
      background: "#E3F3FF",
      color: "#0B7CE4",
    },
    "& tbody .color2": {
      background: "#FFECB3",
      color: "#FFA21F",
    },
    "& tbody .color3": {
      background: "#FFD3D3",
      color: "#FF003D",
    },
  },
  div: {
    paddingBottom: 0,
  },
}));
