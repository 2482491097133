import React from "react";
import { get, isEmpty } from "lodash";
import {
  Button,
  Unstable_Grid2 as Grid,
  IconButton,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import DialogAddRole from "./dialogAddRole";
import { CheckBox } from "../overrideMui";
import { groupRolesStyle } from "../styles/group-role-style";
import { ReactComponent as EditIcon } from "../../../../assets/health/edit.svg";
import { defaultRole } from "../util";

export default function GroupRoles(props) {
  const classes = groupRolesStyle();
  const { state, dispatch, roleCreate, user } = props;
  const { inforCompany, groupRoleChosen, parentRole } = state;

  const getParentRole = () => {
    switch (user.extra_info?.userType) {
      case "globalcare":
        if (
          !["tpas", "merchant"].includes(inforCompany?.extra_info?.org_type)
        ) {
          return defaultRole;
        } else {
          return parentRole;
        }
      case "tpag":
      case "tpas":
      case "tpa":
      case "merchant":
        return get(user, "role_management_claim.privileges");
      default:
        return {};
    }
  };
  const listRole = [
    {
      title: "Quản lý bảo lãnh viện phí",
      lists: [
        {
          label: "Quản lý bảo lãnh viện phí",
          name: "BLVP_MANAGE",
          disabled: getParentRole()?.BLVP_MANAGE !== 1,
        },
        {
          label: "Xem danh sách hồ sơ",
          name: "BLVP_VIEW",
          disabled: getParentRole()?.BLVP_VIEW !== 1,
        },
      ],
    },
    {
      title: "Quản lý sản phẩm",
      name: "product",
      lists: [
        {
          label: "Quản lý sản phẩm",
          name: "PRODUCT_MANAGE",
          disabled: getParentRole()?.PRODUCT_MANAGE !== 1,
        },
        {
          label: "Xem danh sách sản phẩm",
          name: "PRODUCT_VIEW",
          disabled: getParentRole()?.PRODUCT_VIEW !== 1,
        },
        {
          label: "Xuất báo cáo",
          name: "PRODUCT_EXPORT",
          disabled: getParentRole()?.PRODUCT_EXPORT !== 1,
        },
      ],
    },
    {
      title: "Quản lý GCN",
      lists: [
        {
          label: "Quản lý GCN",
          name: "CERT_MANAGE",
          disabled: getParentRole()?.CERT_MANAGE !== 1,
        },
        {
          label: "Xem danh sách GCN",
          name: "CERT_VIEW",
          disabled: getParentRole()?.CERT_VIEW !== 1,
        },
        {
          label: "Xuất báo cáo",
          name: "CERT_EXPORT",
          disabled: getParentRole()?.CERT_EXPORT !== 1,
        },
      ],
    },
    {
      title: "Quản lý bồi thường",
      lists: [
        {
          label: "Xem dashboard",
          name: "CLAIM_DASHBOARD_VIEW",
          disabled: getParentRole()?.CLAIM_DASHBOARD_VIEW !== 1,
        },
        {
          label: "Xem danh sách YCBT",
          name: "CLAIM_VIEW",
          disabled: getParentRole()?.CLAIM_VIEW !== 1,
        },
        {
          label: "Xử lý bồi thường",
          name: "CLAIM_CHECK",
          disabled: getParentRole()?.CLAIM_CHECK !== 1,
        },
        {
          label: "Phê duyệt bồi thường",
          name: "CLAIM_APPROVE",
          disabled: getParentRole()?.CLAIM_APPROVE !== 1,
        },
        {
          label: "Upload uỷ nhiệm chi",
          name: "CLAIM_PAYMENT_ORDER_UPLOAD",
          disabled: getParentRole()?.CLAIM_PAYMENT_ORDER_UPLOAD !== 1,
        },
        {
          label: "Xem báo cáo bồi thường",
          name: "CLAIM_REPORT",
          disabled: getParentRole()?.CLAIM_REPORT !== 1,
        },
        {
          label: "Xuất báo cáo",
          name: "CLAIM_EXPORT",
          disabled: getParentRole()?.CLAIM_EXPORT !== 1,
        },
      ],
    },
    {
      title: "Quản lý phân quyền",
      name: "roleGroup",
      lists: [
        {
          label: "Quản lý các nhóm chức năng",
          name: "ROLE_GROUP_MANAGE",
          disabled: getParentRole()?.ROLE_GROUP_MANAGE !== 1,
        },
        {
          label: "Xem các nhóm chức năng",
          name: "ROLE_GROUP_VIEW",
          disabled: getParentRole()?.ROLE_GROUP_VIEW !== 1,
        },
      ],
    },
    {
      title: "Quản lý tài khoản",
      lists: [
        {
          label: "Quản lý tài khoản",
          name: "ACCOUNT_MANAGE",
          disabled: getParentRole()?.ACCOUNT_MANAGE !== 1,
        },
        {
          label: "Xem danh sách tài khoản",

          name: "ACCOUNT_VIEW",
          disabled: getParentRole()?.ACCOUNT_VIEW !== 1,
        },
        {
          label: "Báo cáo",

          name: "ACCOUNT_EXPORT",
          disabled: getParentRole()?.ACCOUNT_EXPORT !== 1,
        },
      ],
    },
    {
      title: "Quản lý mã nghiệp vụ",
      name: "benefit",
      lists: [
        {
          label: "Quản lý mã nghiệp vụ",
          check: false,
          name: "BENEFIT_MANAGE",
          disabled: getParentRole()?.BENEFIT_MANAGE !== 1,
        },
        {
          label: "Xem danh sách nghiệp vụ",
          check: false,
          name: "BENEFIT_VIEW",
          disabled: getParentRole()?.BENEFIT_VIEW !== 1,
        },
      ],
    },
  ];

  const newListRole = listRole.reduce((array, item) => {
    if (
      (inforCompany?.extra_info?.org_type !== "globalcare" &&
        item.name === "benefit") ||
      (["tpas", "merchant"].includes(inforCompany?.extra_info?.org_type) &&
        item.name === "product")
    ) {
      return array;
    }

    const checkItem = item.lists.some((i) => !i.disabled);

    if (checkItem) {
      const newListItem = item.lists.filter((i) => !i.disabled);
      return [...array, { ...item, lists: [...newListItem] }];
    }
    return array;
  }, []);

  const checkUserRole =
    inforCompany.id == get(user, "extra_info.org_id", null) &&
    groupRoleChosen.id == get(user, "extra_info.role_group_id", null);

  return (
    <div className={classes.container}>
      <div className={classes.title_list}>
        <div>
          <span className="role-manager">{inforCompany.title || ""}</span>
          {roleCreate && (
            <IconButton
              sx={{ marginLeft: "8px" }}
              disabled={!roleCreate}
              onClick={() =>
                dispatch({ type: "openDialogAddCompany", update: true })
              }
            >
              <EditIcon style={{ width: "20px", height: "20px" }} />
            </IconButton>
          )}
        </div>
        <div className="buttons">
          {user.extra_info?.userType === "globalcare" && (
            <Button
              className={
                user.extra_info?.userType !== "globalcare"
                  ? "buttons-delete disabled"
                  : "buttons-delete"
              }
              disabled={user.extra_info?.userType !== "globalcare"}
              onClick={() => dispatch("deletaCompany")}
            >
              Xoá hết
            </Button>
          )}
          {!Boolean(checkUserRole) && (
            <Button
              className="buttons-save"
              onClick={() => dispatch("updateGroupRole")}
            >
              Lưu lại
            </Button>
          )}
        </div>
      </div>
      <div className={classes.conten}>
        <Grid container>
          <Grid xs={3.6}>
            <div className={classes.group_role}>
              <div className="title-group-role">
                <span>Nhóm quyền</span>
                <IconButton
                  className="buttons-add"
                  size="small"
                  onClick={() => dispatch("openDialogAddRole")}
                >
                  <AddIcon size="small" sx={{ fontSize: "14px" }} />
                </IconButton>
              </div>
              <div style={{ overflow: "auto", height: "calc(100vh - 305px)" }}>
                {(inforCompany?.role_groups || []).map((item, index) => (
                  <div
                    className={
                      groupRoleChosen.id == item.id
                        ? "item-group-role chonsen"
                        : "item-group-role"
                    }
                    key={`item_grounp_role_${index}_1`}
                    onClick={() => {
                      groupRoleChosen.id != item.id &&
                        dispatch({
                          type: "onClickGroupRole",
                          item,
                          index,
                        });
                    }}
                  >
                    <span>{item.title}</span>
                    {groupRoleChosen.id == item.id && (
                      <div>
                        <IconButton
                          sx={{ padding: "6px" }}
                          onClick={() =>
                            dispatch({
                              type: "openDialogAddRole",
                              item,
                              index,
                              isUpdate: true,
                            })
                          }
                        >
                          <EditIcon style={{ width: "16px", height: "16px" }} />
                        </IconButton>
                        {!Boolean(checkUserRole) && (
                          <IconButton
                            sx={{ padding: "6px" }}
                            disabled={Boolean(checkUserRole)}
                            onClick={() =>
                              dispatch({ type: "deleteGroupRole", index })
                            }
                          >
                            <DeleteOutlineIcon
                              sx={{ width: "16px", height: "16px" }}
                            />
                          </IconButton>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid xs={8.4}>
            <div className={classes.list_role}>
              <div className="title-list-role">
                <span>Danh sách quyền</span>
              </div>
              <div style={{ overflow: "auto", height: "calc(100vh - 301px)" }}>
                {isEmpty(groupRoleChosen)
                  ? null
                  : newListRole.map((item, index) => {
                      return (
                        <div
                          className="item-list-role"
                          key={`item_list_role_${index}`}
                        >
                          <div
                            style={{ marginBottom: "16px", fontWeight: "600" }}
                          >
                            {item.title}
                          </div>
                          <FormGroup>
                            {item?.lists.map((itemList, index) => (
                              <div
                                className="item-check-box"
                                key={`item_checkBox_${index}`}
                              >
                                <FormControlLabel
                                  sx={{ margin: 0 }}
                                  control={
                                    <CheckBox
                                      disabled={
                                        Boolean(checkUserRole) ||
                                        Boolean(itemList.disabled)
                                      }
                                      className={classes.checkBox}
                                      checked={Boolean(
                                        get(
                                          groupRoleChosen,
                                          `privileges.${itemList.name}`,
                                          0
                                        )
                                      )}
                                      onChange={(e) =>
                                        dispatch({
                                          type: "choosePrivileges",
                                          name: itemList.name,
                                          value: e.target.checked,
                                        })
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={itemList?.label}
                                />
                              </div>
                            ))}
                          </FormGroup>
                        </div>
                      );
                    })}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <DialogAddRole {...props} />
    </div>
  );
}
