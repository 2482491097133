import _ from "lodash";
import moment from "moment";
import { getListIndemnify } from "../../../../../apis/claimPBAApi";
import * as PATH from "../../../../../configs/routesConfig";
import { MAXIMUN_LINE_EXPORT_EXCEL_AHARIDE } from "../../../../../utils/contains";

export default function managementReducer({
  state,
  dispatch,
  action,
  form,
  roleType,
  user,
  history,
  dataSearchAharideList,
  setdataSearchAharideList,
}) {
  const cases = {
    initial: async () => {
      const res = {};
      dispatch("onLoading");

      const params = _.isEmpty(dataSearchAharideList)
        ? {
            from: moment().subtract(1, "months"),
            to: moment().toDate(),
            limit: 10,
            offset: 0,
            productIdParent: 32,
            merchantId:
              roleType == "merchant" ? user?.extra_info?.merchantId : null,
          }
        : {
            ...dataSearchAharideList,
            limit: 10,
            offset: 0,
            merchantId:
              roleType == "merchant" ? user?.extra_info?.merchantId : null,
            productIdParent: 32,
          };

      const dataApi = await getListIndemnify(roleType, params);

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.rows = _.get(dataApi, "data.result.data") || [];
        res.totalRows = _.get(dataApi, "data.result.total") || 0;
        res.pageTable = {
          count: Math.ceil(dataApi.data.result.total / 10),
          page: 0,
        };
        res.dataSearch = _.isEmpty(dataSearchAharideList)
          ? {
              from: moment().subtract(1, "months"),
              to: moment().toDate(),
              merchantId:
                roleType == "merchant" ? user?.extra_info?.merchantId : null,
            }
          : {
              ...dataSearchAharideList,
              merchantId:
                roleType == "merchant" ? user?.extra_info?.merchantId : null,
            };
      }

      return { ...res, loading: false };
    },

    onChangeSearch: () => {
      const { name, value } = action;
      const { dataSearch } = state;
      dataSearch[name] = value;

      return { dataSearch };
    },

    changePage: async () => {
      const {
        data: { item },
      } = action;
      form === "approve-claim" &&
        history.push(`${PATH.AHARIDE_MANAGEMENT_DETAIL}?id=${item.id}`);

      form === "report-claim" &&
        history.push(`${PATH.AHARIDE_REPORT_DETAIL}?id=${item.id}`);
    },

    onPaging: async () => {
      const { page, rowsPerPage } = action;
      const { dataSearch } = state;

      dispatch("onLoading");

      const params = {
        insuredPhone: dataSearch?.insuredPhone || "",
        insuredPrivateId: dataSearch?.insuredPrivateId || null,
        code: dataSearch?.code || null,
        statusId: dataSearch?.statusId || null,
        from: dataSearch?.from || null,
        to: dataSearch?.to || null,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        productIdParent: 32,
        merchantId: dataSearch?.merchantId || null,
      };

      const dataApi = await getListIndemnify(roleType, params);

      const res = {};

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.rows = _.get(dataApi, "data.result.data") || [];
        res.totalRows = _.get(dataApi, "data.result.total") || 0;
        res.pageTable = {
          count: Math.ceil(dataApi.data.result.total / (rowsPerPage || 10)),
          page: page,
          rowsPerPage: rowsPerPage,
        };
      }

      return { ...res, loading: false };
    },

    onSubmitSearch: async () => {
      const { dataSearch, pageTable } = state;

      dispatch("onLoading");

      const params = {
        insuredPhone: dataSearch?.insuredPhone || "",
        insuredPrivateId: dataSearch?.insuredPrivateId || null,
        code: dataSearch?.code || null,
        statusId: dataSearch?.statusId || null,
        from: dataSearch?.from || null,
        to: dataSearch?.to || null,
        limit: pageTable?.rowsPerPage || 10,
        offset: 0,
        productIdParent: 32,
        merchantId: dataSearch?.merchantId || null,
      };
      setdataSearchAharideList({ ...dataSearch });
      const dataApi = await getListIndemnify(roleType, params);

      const res = {};

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.rows = _.get(dataApi, "data.result.data") || [];
        res.totalRows = _.get(dataApi, "data.result.total") || 0;
        res.pageTable = {
          count: Math.ceil(
            dataApi.data.result.total / (pageTable?.rowsPerPage || 10)
          ),
          page: 0,
          rowsPerPage: pageTable?.rowsPerPage,
        };
      }

      return { ...res, loading: false };
    },

    onLoading: () => ({ loading: true }),

    onSubmitExport: () => {
      const { totalRows } = state;

      if (totalRows > +MAXIMUN_LINE_EXPORT_EXCEL_AHARIDE) {
        return { openMaxLineDialog: true };
      } else {
        dispatch("getListExport");
      }
    },

    getListExport: async () => {
      const { count, dataSearch } = state;
      const res = {};
      dispatch("onLoading");

      const params = {
        insuredPhone: dataSearch?.insuredPhone || "",
        insuredPrivateId: dataSearch?.insuredPrivateId || null,
        code: dataSearch?.code || null,
        statusId: dataSearch?.statusId || null,
        from: dataSearch?.from || null,
        to: dataSearch?.to || null,
        limit: +MAXIMUN_LINE_EXPORT_EXCEL_AHARIDE,
        offset: 0,
        productIdParent: 32,
        merchantId: dataSearch?.merchantId || null,
        export: 1,
      };

      const dataApi = await getListIndemnify(roleType, params);

      if (dataApi instanceof Error) {
        return { loading: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.count = +count + 1;
        res.dataExport = _.get(dataApi, "data.result.data") || [];
      }

      return { ...res, loading: false, openMaxLineDialog: false };
    },

    closeMaxLineDialog: () => ({ openMaxLineDialog: false }),
  };

  return action?.type && cases[action?.type];
}
