import React, { Suspense, lazy } from "react";
import { Route } from "react-router-dom";
import * as PATH from "../../../../configs/routesConfig";
import "./assets/css/style.css";

const routes = [
  {
    path: `${PATH.CLAIM_AHARIDE}`,
    exact: true,
    component: lazy(() => import("./components/home")),
  },
  {
    path: `${PATH.CLAIM_AHARIDE}/intro`,
    exact: true,
    component: lazy(() => import("./components/intro")),
  },
  {
    path: `${PATH.CLAIM_AHARIDE}/list`,
    exact: true,
    component: lazy(() => import("./components/list/ClaimList")),
  },
  {
    path: `${PATH.CLAIM_AHARIDE}/create`,
    exact: true,
    component: lazy(() => import("./components/create")),
  },
  {
    path: `${PATH.CLAIM_AHARIDE}/detail`,
    exact: true,
    component: lazy(() => import("./detail")),
  },
];

const AharideRouter = () => {
  return (
    <Suspense fallback={<></>}>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        ></Route>
      ))}
    </Suspense>
  );
};

export default AharideRouter;
