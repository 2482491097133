import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";
import { DatePicker } from "antd";
import moment from "moment";
import { roles } from "../utils/common";
const { RangePicker } = DatePicker;

const status = [
  { value: 1, label: "Hoạt động" },
  { value: 0, label: "Không hoạt động" },
];

export const AccountManagerSearch = ({ state, dispatch, systemType }) => {
  const { searchData, organList, productList } = state;

  return (
    <>
      <Box>
        <Typography color="textPrimary" variant="h6" my={4}>
          Tra cứu tài khoản
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            placeholder="Nhập họ tên"
            label="Họ tên"
            onChange={(e) =>
              dispatch({ type: "onChange", name: "fullname", e })
            }
            value={searchData?.fullname || ""}
            focused
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            placeholder="Nhập email"
            label="Email"
            onChange={(e) => dispatch({ type: "onChange", name: "email", e })}
            value={searchData?.email || ""}
            focused
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            fullWidth
            placeholder="Tên đăng nhập"
            label="User đăng nhập"
            onChange={(e) =>
              dispatch({ type: "onChange", name: "username", e })
            }
            value={searchData?.username || ""}
            focused
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Tình trạng"
            onChange={(e) => dispatch({ type: "onChange", name: "status", e })}
            select
            value={searchData?.status === 0 ? 0 : searchData?.status || "all"}
            focused
          >
            <MenuItem value={"all"}>Chọn tất cả</MenuItem>
            {status.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {systemType === "globalcare" && (
          <>
            <Grid item xs={3}>
              <TextField
                fullWidth
                select
                label="Quyền truy cập hệ thống kênh quản lý"
                value={searchData?.role || "all"}
                onChange={(e) =>
                  dispatch({ type: "onSelect", name: "role", e })
                }
              >
                <MenuItem value={"all"}>Chọn tất cả</MenuItem>
                {roles.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {searchData?.role === 1 && (
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  select
                  label="Công ty bảo hiểm"
                  value={searchData?.providerId || ""}
                  onChange={(e) =>
                    dispatch({ type: "onSelect", name: "providerId", e })
                  }
                >
                  <MenuItem value={"all"}>Chọn tất cả</MenuItem>
                  {organList?.map((option) => (
                    <MenuItem
                      key={option.provider_id || option.id}
                      value={option.provider_id || option.id}
                    >
                      {option.provider_code || option.code}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </>
        )}

        {/* {((systemType === "globalcare" && searchData?.role === 1) ||
          systemType === "provider") && (
          <Grid item xs={3}>
            <TextField
              fullWidth
              select
              label="Sản phẩm"
              value={searchData?.productId || null}
              onChange={(e) =>
                dispatch({ type: "onSelect", name: "productId", e })
              }
            >
              <MenuItem value={"all"}>Chọn tất cả</MenuItem>
              {productList?.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option.product_id || option.feature_code}
                >
                  {option.product_title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )} */}

        {searchData?.role === 4 && (
          <Grid item xs={3}>
            <TextField
              fullWidth
              select
              label="Bệnh viện phòng khám"
              value={searchData?.departmentId || 2}
              onChange={(e) =>
                dispatch({ type: "onSelect", name: "departmentId", e })
              }
            >
              <MenuItem value={"all"}>Chọn tất cả</MenuItem>
              {organList?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        {searchData?.role === 5 && (
          <Grid item xs={3}>
            <TextField
              fullWidth
              select
              label="Tên đối tác"
              value={searchData?.merchantId || ""}
              onChange={(e) =>
                dispatch({ type: "onSelect", name: "merchantId", e })
              }
            >
              <MenuItem value={"all"}>Chọn tất cả</MenuItem>
              {organList?.map((option) => (
                <MenuItem
                  key={option.merchant_id || option.id}
                  value={option.merchant_id || option.id}
                >
                  {option.merchant_title || option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        {["provider", "globalcare", "merchant"].includes(systemType) && (
          <Grid item xs={3}>
            <div style={{ position: "relative", width: "100%" }}>
              <div style={Styles.titleInput}>{"Chọn ngày"}</div>
              <div>
                <RangePicker
                  value={
                    searchData?.from && searchData?.to
                      ? [moment(searchData?.from), moment(searchData?.to)]
                      : null
                  }
                  onChange={(e) =>
                    dispatch({
                      type: "onChange",
                      name: "changeDate",
                      e,
                    })
                  }
                  allowClear={true}
                  style={{
                    ...Styles.input,
                    paddingTop: 11,
                    paddingBottom: 8,
                  }}
                  placeholder={["Từ ngày", "Đến ngày"]}
                  format="DD/MM/YYYY"
                />
              </div>
            </div>
          </Grid>
        )}

        <Grid item xs={3}>
          <Button
            type="submit"
            fullWidth
            color="gc"
            variant="contained"
            sx={{ height: "100%" }}
            onClick={() => dispatch("search")}
          >
            Tra cứu
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const Styles = {
  titleInput: {
    zIndex: 4,
    top: "-10px",
    left: 3,
    position: "absolute",
    background: "#ffffff",
    marginLeft: 8,
    width: "fit-content",
    fontWeight: 500,
    padding: "0px 5px",
    fontSize: 13,
  },
  input: {
    fontSize: 15,
    boxShadow: "none",
    borderRadius: 8,
    width: "100%",
    border: "1.5px solid rgb(230 230 230)",
    height: "56px",
  },
};
