import { message } from "antd";
import { cloneDeep, get, isEmpty } from "lodash";
import {
  createProductPackage,
  detailProductPackage,
  detailProductParent,
  getProductDetail,
  updateProductPackage,
} from "../../../../apis/providerApis";
import { config_default_value, convertCurrency, convertNum } from "../utils";

const checkLevel = (data, code) =>
  data.map((i) => {
    const regex = /\./g;
    if (!i[code].includes(".")) {
      return { ...i, isFirst: true };
    }
    if (i[code].match(regex).length === 1) {
      return { ...i, isSecond: true };
    }
    return i;
  });

const packageReducer = ({
  initialState,
  state,
  action,
  dispatch,
  history,
  id,
}) => {
  const cases = {
    detailProduct: async () => {
      const res = {};
      dispatch("onLoading");
      const result = await getProductDetail(id);
      if (result instanceof Error) {
        message.error(result.response?.data?.message || "Lỗi lấy chi tiết", 2);
        return { loading: false };
      }

      const dataApi = get(result, "data.result") || {};
      res.data = dataApi;
      res.benefitGroup = get(dataApi, "productPackageList.data") || [];
      res.selectPackage = { ...res.benefitGroup[0], index: 0 };
      res.total_product_amount = res.benefitGroup.reduce((total, item) => {
        return total + +item.product_amount;
      }, 0);
      const feeArr = res.benefitGroup
        .map((i) => +i.product_amount)
        .sort((a, b) => a - b);

      res.rangeFees = isEmpty(feeArr)
        ? 0
        : feeArr.length === 1
        ? convertCurrency(feeArr[0])
        : `${convertCurrency(feeArr[0])} - ${convertCurrency(
            feeArr[feeArr.length - 1]
          )}`;
      return { ...res, loading: false };
    },

    detailProductParent: async () => {
      const { data, dataProductParent } = state;

      dispatch("onLoading");
      const resBenefit = await detailProductParent(data.productIdParent);
      if (resBenefit instanceof Error) {
        message.error("Có lỗi xảy ra", 2);
        return { loading: false };
      }
      const list =
        get(resBenefit, "data.result.productParentBenefitList.data") || [];
      dataProductParent.benefitList = checkLevel(list, "codeBenefit");
      dataProductParent.insuranceCode =
        get(resBenefit, "data.result.insuranceCode") || "";
      return { dataProductParent, loading: false };
    },

    getPackageDetail: async () => {
      const { selectPackage } = state;
      const { item, index } = action; //in selectPackage
      const res = {};
      action.load ? dispatch("loadingTable") : dispatch("onLoading");
      const resDetail = await detailProductPackage(
        item?.id || selectPackage.id
      );
      if (resDetail instanceof Error) {
        message.error(resDetail.response?.data?.message || "Có lỗi xảy ra", 2);
        return { loading: false };
      }
      const newData = get(resDetail, "data.result") || {};
      const list = get(resDetail, "data.result.benefits.data") || [];
      res.selectPackage = {
        ...newData,
        index: index || selectPackage.index,
        benefits: checkLevel(list, "code_benefit"),
      };
      return { ...res, loading: false, loadingTable: false };
    },
    selectShareTime: () => {
      const { dataEdit } = state;
      const { name, value } = action;
      dataEdit.extra_info.config_share_payment = {
        ...dataEdit.extra_info?.config_share_payment,
        [name]: +value || 0,
      };
      return { dataEdit };
    },
    selectWaitingTime: () => {
      const { dataEdit } = state;
      const { name, value } = action;
      dataEdit.extra_info.config_waiting = {
        ...dataEdit.extra_info?.config_waiting,
        [name]: +value || 0,
      };
      return { dataEdit };
    },
    onChange: () => {
      const { dataEdit, addNewGroup } = state;
      const { name, value } = action;
      if (action.title === "add") {
        addNewGroup[name] = value;
        return { addNewGroup };
      }
      dataEdit[name] = value;
      return { dataEdit };
    },
    changeFee: () => {
      const { addNewGroup, dataEdit } = state;
      const { name, value } = action;
      if (action.title === "add") {
        addNewGroup[name] = value;
        return { addNewGroup };
      }
      dataEdit[name] = value;
      return { dataEdit };
    },
    changeBenefit: () => {
      const {
        data: { index },
        ele,
      } = action;
      const { editbenefitList } = state;
      editbenefitList[index].config_benefit = { value: ele.target.value };
      return { editbenefitList };
    },
    onOpen: () => {
      const { open, selectPackage } = state;
      open[action.name] = true;
      const dataEdit = cloneDeep(selectPackage);
      return { open, dataEdit };
    },
    openAddNew: () => {
      const { open } = state;
      open.add = true;
      return { open, addNewGroup: {} };
    },
    openBenefit: async () => {
      const { open, dataProductParent, selectPackage } = state;
      open.benefit = true;
      const editbenefitList = cloneDeep(
        isEmpty(selectPackage.benefits)
          ? dataProductParent.benefitList
          : selectPackage.benefits
      );
      return { open, editbenefitList };
    },
    onClose: () => {
      const { open } = state;
      open[action.name] = false;
      return { open };
    },
    createPackage: async () => {
      const { data, addNewGroup, open } = state;
      const params = {
        title: addNewGroup?.title,
        fees: addNewGroup?.fees,
        product_amount: addNewGroup?.product_amount,
        productId: data.id,
        extra_info: {
          config_waiting: config_default_value,
          config_share_payment: config_default_value,
        },
      };
      dispatch("onSaving");
      const result = await createProductPackage(params);
      if (result instanceof Error) {
        message.error("Lỗi thêm nhóm quyền lợi", 2);
        return { saving: false };
      }
      message.success("Thêm nhóm quyền lợi thành công", 2);
      open.add = false;
      dispatch("detailProduct");
      dispatch("detailProductParent");
      return { open, saving: false };
    },
    updateInfoPackage: async () => {
      const { dataEdit, dataProductParent, selectPackage, open } = state;
      const benefits = dataProductParent.benefitList.map((item) => ({
        product_package_id: selectPackage.id,
        code_benefit: item.codeBenefit,
        config_benefit: {
          value: item?.config_benefit?.value || null,
        },
      }));

      const params = {
        id: selectPackage.id,
        title: dataEdit?.title,
        fees: dataEdit?.fees,
        product_amount: dataEdit?.product_amount,
        extra_info: {
          config_waiting:
            selectPackage.extra_info?.config_waiting || config_default_value,
          config_share_payment:
            selectPackage.extra_info?.config_share_payment ||
            config_default_value,
        },
        benefits,
      };
      dispatch("onSaving");
      const result = await updateProductPackage(params);
      if (result instanceof Error) {
        message.error("Lỗi cập nhật thông tin quyền lợi", 2);
        return { saving: false };
      }
      message.success(result.data?.message || "Cập nhật thành công", 2);
      open.info = false;
      dispatch("detailProduct");
      dispatch("getPackageDetail");
      return { open, saving: false };
    },

    saveTime: async () => {
      const { dataEdit, dataProductParent, selectPackage, open } = state;
      const benefits = dataProductParent.benefitList.map((item) => ({
        product_package_id: selectPackage.id,
        code_benefit: item.codeBenefit,
        config_benefit: {
          value: item?.config_benefit?.value || null,
        },
      }));

      const params = {
        id: selectPackage.id,
        title: selectPackage?.title,
        fees: selectPackage?.fees,
        product_amount: selectPackage?.product_amount,
        extra_info: {
          config_waiting:
            dataEdit.extra_info?.config_waiting || config_default_value,
          config_share_payment:
            dataEdit.extra_info?.config_share_payment || config_default_value,
        },
        benefits,
      };

      dispatch("onSaving");
      const result = await updateProductPackage(params);
      if (result instanceof Error) {
        message.error("Lỗi cập nhật thông tin quyền lợi", 2);
        return { saving: false };
      }
      message.success(result.data?.message || "Cập nhật thành công", 2);
      open[action.name] = false;
      dispatch("getPackageDetail");
      return { open, saving: false };
    },

    updateBenefit: async () => {
      const { open, editbenefitList, selectPackage } = state;
      const benefits = editbenefitList.map((i) => {
        const value = !i?.config_benefit?.value
          ? null
          : +convertNum(i?.config_benefit?.value);
        return {
          product_package_id: selectPackage.id,
          code_benefit: i?.codeBenefit || i?.code_benefit || "",
          config_benefit: {
            value,
          },
        };
      });

      const params = {
        id: selectPackage.id,
        title: selectPackage?.title,
        fees: selectPackage?.fees,
        product_amount: selectPackage?.product_amount,
        extra_info: {
          config_waiting:
            selectPackage.extra_info?.config_waiting || config_default_value,
          config_share_payment:
            selectPackage.extra_info?.config_share_payment ||
            config_default_value,
        },
        benefits,
      };

      dispatch("onSaving");
      const result = await updateProductPackage(params);
      if (result instanceof Error) {
        message.error("Lỗi cập nhật quyền lợi bảo hiểm", 2);
        return { saving: false };
      }
      message.success(result.data?.message || "Cập nhật thành công", 2);
      open.benefit = false;
      dispatch({ type: "getPackageDetail", load: "table" });
      return { open, saving: false };
    },
    onLoading: () => ({ loading: true }),
    onSaving: () => ({ saving: true }),
    loadingTable: () => ({ loadingTable: true }),
    setReady: () => ({ isReady: true }),
  };
  return cases[action?.type];
};
export default packageReducer;
