import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { message } from "antd";

import LoginLayout from "../login/login-layout";
import { ForgotPassword } from "../../../../apis/authApis";
import { getParamUrlByKey } from "../../../../utils/utils";

import iconPhone from "../../../../assets/icons/iconPhone.svg";

const ChangePassword = () => {
  const history = useHistory();
  const location = useLocation();
  const otp = getParamUrlByKey("codeOTP") || "";
  const email = getParamUrlByKey("email") || location?.state?.email;
  const embededFiNa = localStorage.getItem("embeded") == "fina.claim.admin";

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmgPassword, setShowConfirmgPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      otp: otp,
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().max(255).required("Vui lòng nhập thông tin"),
      newPassword: Yup.string()
        .min(8, "Vui lòng nhập ít nhất 8 ký tự")
        .max(255)
        .required("Vui lòng nhập thông tin"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        "Mật khẩu không khớp"
      ),
    }),

    onSubmit: async (values) => {
      const params = {
        email: email,
        otp: values.otp,
        password: values.newPassword,
      };

      const dataApi = await ForgotPassword(params);

      if (dataApi?.data?.statusCode === 200) {
        history.push("login");
      } else {
        createFail(dataApi);
      }
      formik.setSubmitting(false);
    },
  });

  const createFail = async (error) => {
    formik.setSubmitting(false);
    message.error({
      content: error.message,
      duration: 5,
    });
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexFlow: "column",
          flexGrow: 1,
          minHeight: "100%",
          background: "#FAFAFA",
        }}
      >
        <Container maxWidth="sm" sx={{ marginTop: "auto" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 3 }}>
              <Typography
                fontWeight="fontWeightBold"
                color="textPrimary"
                variant="h4"
              >
                Đổi mật khẩu
              </Typography>
            </Box>
            <Box sx={{ mt: 3, mb: 2 }}>
              <Typography>
                Vui lòng nhập mã OTP được gửi vào email và tiến hành đổi lại{" "}
                <br />
                mật khẩu mới.
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.otp && formik.errors.otp)}
              fullWidth
              helperText={formik.touched.otp && formik.errors.otp}
              label="mã OTP"
              margin="normal"
              name="otp"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.otp}
              variant="outlined"
              focused
            />
            <TextField
              error={Boolean(
                formik.touched.newPassword && formik.errors.newPassword
              )}
              fullWidth
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              label="Mật khẩu mới"
              margin="normal"
              name="newPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.newPassword}
              variant="outlined"
              focused
              type={showNewPassword ? "text" : "password"}
              inputProps={{
                autoComplete: "new-password",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {!showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              error={Boolean(
                formik.touched.confirmPassword && formik.errors.confirmPassword
              )}
              fullWidth
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              label="Xác nhận mật khẩu"
              margin="normal"
              name="confirmPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              variant="outlined"
              focused
              type={showConfirmgPassword ? "text" : "password"}
              inputProps={{
                autoComplete: "new-password",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmgPassword(!showConfirmgPassword)
                      }
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {!showConfirmgPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ py: 2, mt: 5 }}>
              <Button
                color="gc"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Tiếp tục
              </Button>
            </Box>
          </form>
        </Container>
        <Box textAlign="center" mt="auto" mx="auto" pb={3}>
          <Typography sx={{ color: "#1E6BE5" }} mb={1}>
            <img src={iconPhone} />
            Hotline: {embededFiNa ? "(024) 6276 5555" : "1900 2929 87"}
          </Typography>
          {!embededFiNa && (
            <Typography>Một sản phẩm công nghệ của Global Care</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

const ChangePasswordOTP = () => (
  <LoginLayout>
    <ChangePassword />
  </LoginLayout>
);

export default ChangePasswordOTP;
