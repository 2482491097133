import _ from "lodash";
import { SHOW_DETAIL_CLAIM_TOMATO } from "../../../../../configs/routesConfig";
import { getTypeInsu } from "../components/createDetailClaimFile/until";

const dataTemps = [
  "Họ tên",
  "Năm sinh",
  "CMND/CCCD/Hộ chiếu",
  "Số điện thoại",
  "Email",
  "Địa chỉ",
];

const createDetailClaimFileReducer = ({ state, action, history, location }) => {
  const { itemContract, paramsData } = location.state;

  const cases = {
    initial: () => {
      const infoContracts = dataTemps.map((item) => {
        switch (item) {
          case "Họ tên":
            return { title: item, value: itemContract?.name || "" };
          case "Năm sinh":
            return { title: item, value: itemContract?.birthday || "" };
          case "CMND/CCCD/Hộ chiếu":
            return {
              title: item,
              value: itemContract?.citizen_identification || "",
            };
          case "Số điện thoại":
            return { title: item, value: itemContract?.phone || "" };
          case "Email":
            return { title: item, value: itemContract?.email || "" };
          case "Địa chỉ":
            return { title: item, value: itemContract?.address || "" };
        }
      });

      const sum = _.get(itemContract, "insured_info.price") | "";
      return { infoContracts, sum, paramsData };
    },

    onChange: () => {
      const { name, value } = action;
      const { paramsData } = state;

      if (name === "billLink") {
        _.set(paramsData, ["insuredInfo", "billLink"], value);
      }

      if (["type", "relationship", "fullname"].includes(name)) {
        _.set(paramsData, ["extraInfo", "beneficiary", `${name}`], value);
        if (name === "type") {
          _.get(paramsData, "extraInfo.beneficiary.type") === "self"
            ? _.set(
                paramsData,
                ["extraInfo", "beneficiary", "fullname"],
                _.get(itemContract, "name") || ""
              )
            : _.set(paramsData, ["extraInfo", "beneficiary", "fullname"], "");
        }
      }

      if (["name", "id", "branch"].includes(name)) {
        _.set(
          paramsData,
          ["extraInfo", "beneficiary", "bank", `${name}`],
          value
        );
      }
      return { paramsData };
    },

    openModal: () => ({ open: true }),

    closeModal: () => ({ open: false }),

    onLoading: () => {
      const { name } = action;
      const { loading } = state;
      loading[name] = true;
      return { loading };
    },
    upLoadFile: async () => {
      const { item, name } = action;
      const { paramsData, loading } = state;
      const images = paramsData?.insuredInfo?.images || {};
      const oldvlaue = !images[name] ? [] : images[name];
      images[name] = [...oldvlaue, item?.path || null];

      paramsData.insuredInfo.images = { ...images };
      loading[name] = false;
      return { paramsData, loading };
    },

    cancelUpload: async () => {
      const { name } = action;
      const { paramsData, loading } = state;
      loading[name] = false;
      return { paramsData, loading };
    },

    deleteImg: () => {
      const { paramsData } = state;
      const { name, rowIndex } = action;
      paramsData?.insuredInfo?.images?.[name].splice(rowIndex, 1);
      return { paramsData };
    },

    onSubmit: () => {
      const { paramsData } = state;
      const { insuredInfo = {}, extraInfo = {} } = paramsData;
      const errors = [];
      const {
        animalBitesBoarding,
        animalBitesOutpatient,
        animalBitesDead,
        animalBitesPermanentDisability,
        brunedTemporaryInjury,
        brunedDead,
        brunedPermanentDisability,
        lifeOutpatientBone,
        lifeOutpatient,
        lifeBoarding,
        lifePermanentDisability,
        lifeOutpatientPermanentDisability,
        lifeOutpatientPermanentDisabilityBone,
        lifeBoardingPermanentDisability,
        lifeDead,
        workAccidentTemporaryInjuryBone,
        workAccidentTemporaryInjury,
        workAccidentTemporaryInjuryBoarding,
        workAccidentPermanentDisabilityOutpatient,
        workAccidentPermanentDisabilityOutpatientBone,
        workAccidentPermanentDisabilityOutpatientNoBone,
        workAccidentPermanentDisabilityBoarding,
        workAccidentDead,
        trafficOutpatientTemporaryInjury,
        trafficOutpatientTemporaryInjuryBone,
        trafficOutpatientTemporaryInjuryNoBone,
        trafficBoardingTemporaryInjury,
        trafficPermanentDisabilityOutpatient,
        trafficPermanentDisabilityBoarding,
        trafficPermanentDisabilityOutpatientBone,
        trafficPermanentDisabilityOutpatientNoBone,
        trafficDead,
      } = getTypeInsu(insuredInfo, extraInfo);

      if ((insuredInfo?.images?.id || []).length < 2) {
        errors.push("idImage");
      }

      if (
        (insuredInfo?.images?.report || []).length < 1 &&
        (!!workAccidentTemporaryInjuryBone ||
          !!workAccidentTemporaryInjury ||
          !!workAccidentTemporaryInjuryBoarding ||
          !!workAccidentPermanentDisabilityOutpatient ||
          !!workAccidentPermanentDisabilityBoarding ||
          !!trafficOutpatientTemporaryInjury ||
          !!trafficBoardingTemporaryInjury ||
          !!trafficPermanentDisabilityOutpatient ||
          !!trafficPermanentDisabilityBoarding)
      ) {
        errors.push("report");
      }

      if (
        (insuredInfo?.images?.prescription || []).length < 1 &&
        (!!lifeOutpatientBone ||
          !!lifeOutpatient ||
          !!lifeOutpatientPermanentDisability ||
          !!workAccidentTemporaryInjuryBone ||
          !!workAccidentTemporaryInjury ||
          !!workAccidentPermanentDisabilityOutpatient ||
          !!trafficOutpatientTemporaryInjury ||
          !!trafficPermanentDisabilityOutpatient)
      ) {
        errors.push("prescription");
      }

      if (
        (insuredInfo?.images?.prescriptionCheckout || []).length < 1 &&
        !!animalBitesBoarding
      ) {
        errors.push("prescriptionCheckout");
      }

      if (
        (insuredInfo?.images?.prescriptionHospitalCheckout || []).length < 1 &&
        (!!lifeBoarding ||
          !!lifeBoardingPermanentDisability ||
          !!workAccidentTemporaryInjuryBoarding ||
          !!workAccidentPermanentDisabilityBoarding ||
          !!trafficBoardingTemporaryInjury ||
          !!trafficPermanentDisabilityBoarding)
      ) {
        errors.push("prescriptionHospitalCheckout");
      }

      if (
        (insuredInfo?.images?.test || []).length < 1 &&
        (!!lifeOutpatient ||
          !!workAccidentTemporaryInjury ||
          !!workAccidentPermanentDisabilityOutpatientNoBone ||
          !!trafficOutpatientTemporaryInjuryNoBone ||
          !!trafficPermanentDisabilityOutpatientNoBone)
      ) {
        errors.push("test");
      }

      if (
        (insuredInfo?.images?.xq || []).length < 1 &&
        (!!lifeOutpatientBone ||
          !!lifeOutpatientPermanentDisabilityBone ||
          !!workAccidentTemporaryInjuryBone ||
          !!workAccidentPermanentDisabilityOutpatientBone ||
          !!trafficOutpatientTemporaryInjuryBone ||
          !!trafficPermanentDisabilityOutpatientBone)
      ) {
        errors.push("xq");
      }

      if (
        (insuredInfo?.images?.bill || []).length < 1 &&
        (!!brunedTemporaryInjury ||
          !!brunedPermanentDisability ||
          !!lifeOutpatientBone ||
          !!lifeOutpatient ||
          !!lifeBoarding ||
          !!lifeOutpatientPermanentDisability ||
          !!lifeBoardingPermanentDisability ||
          !!workAccidentTemporaryInjuryBone ||
          !!workAccidentTemporaryInjury ||
          !!workAccidentTemporaryInjuryBoarding ||
          !!workAccidentPermanentDisabilityOutpatient ||
          !!workAccidentPermanentDisabilityBoarding ||
          !!trafficOutpatientTemporaryInjury ||
          !!trafficBoardingTemporaryInjury ||
          !!trafficPermanentDisabilityOutpatient ||
          !!trafficPermanentDisabilityBoarding)
      ) {
        errors.push("bill");
      }

      if (
        (insuredInfo?.images?.governmentConfirmation || []).length < 1 &&
        (!!animalBitesPermanentDisability ||
          !!brunedPermanentDisability ||
          !!lifePermanentDisability ||
          !!lifeBoardingPermanentDisability ||
          !!workAccidentPermanentDisabilityOutpatient ||
          !!workAccidentPermanentDisabilityBoarding ||
          !!trafficPermanentDisabilityOutpatient ||
          !!trafficPermanentDisabilityBoarding)
      ) {
        errors.push("governmentConfirmation");
      }

      if (
        (insuredInfo?.images?.certificateInjury || []).length < 1 &&
        (!!animalBitesPermanentDisability ||
          !!brunedPermanentDisability ||
          !!lifeOutpatientPermanentDisability ||
          !!lifeBoardingPermanentDisability ||
          !!workAccidentPermanentDisabilityOutpatient ||
          !!workAccidentPermanentDisabilityBoarding ||
          !!trafficPermanentDisabilityOutpatient ||
          !!trafficPermanentDisabilityBoarding)
      ) {
        errors.push("certificateInjury");
      }

      if (
        (insuredInfo?.images?.deadConclusion || []).length < 1 &&
        (!!animalBitesDead ||
          !!brunedDead ||
          !!lifeDead ||
          !!workAccidentDead ||
          !!trafficDead)
      ) {
        errors.push("deadConclusion");
      }

      if (
        (insuredInfo?.images?.deathCertificate || []).length < 1 &&
        (!!animalBitesDead ||
          !!brunedDead ||
          !!lifeDead ||
          !!workAccidentDead ||
          !!trafficDead)
      ) {
        errors.push("deathCertificate");
      }

      if (
        (insuredInfo?.images?.verifyRelationship || []).length < 1 &&
        _.get(paramsData, "extraInfo.beneficiary.type") === "relative"
      ) {
        errors.push("verifyRelationship");
      }

      if (
        (insuredInfo?.images?.inheritanceText || []).length < 1 &&
        (!!animalBitesDead ||
          !!brunedDead ||
          !!lifeDead ||
          !!workAccidentDead ||
          !!trafficDead)
      ) {
        errors.push("inheritanceText");
      }

      if (
        (insuredInfo?.images?.license || []).length < 1 &&
        (!!trafficOutpatientTemporaryInjury ||
          !!trafficBoardingTemporaryInjury ||
          !!trafficPermanentDisabilityOutpatient ||
          !!trafficPermanentDisabilityBoarding)
      ) {
        errors.push("license");
      }

      if (
        (insuredInfo?.images?.vaccinationCard || []).length < 1 &&
        (!!animalBitesOutpatient || !!animalBitesBoarding)
      ) {
        errors.push("vaccinationCard");
      }

      if (
        (insuredInfo?.images?.hospitalCheckout || []).length < 1 &&
        !!animalBitesBoarding
      ) {
        errors.push("hospitalCheckout");
      }

      if (
        (insuredInfo?.images?.detailedListHospitalFees || []).length < 1 &&
        (!!animalBitesBoarding ||
          !!lifeBoarding ||
          !!lifeBoardingPermanentDisability ||
          !!workAccidentTemporaryInjuryBoarding ||
          !!workAccidentPermanentDisabilityBoarding ||
          !!trafficBoardingTemporaryInjury ||
          !!trafficPermanentDisabilityBoarding)
      ) {
        errors.push("detailedListHospitalFees");
      }

      if (
        (insuredInfo?.images?.vaccinationBill || []).length < 1 &&
        (!!animalBitesOutpatient || !!animalBitesBoarding)
      ) {
        errors.push("vaccinationBill");
      }

      ["type", "relationship", "fullname"].forEach((key) => {
        if (key === "relationship") {
          if (
            _.get(extraInfo, "beneficiary.type") === "relative" &&
            !_.get(extraInfo, `beneficiary.${key}`)
          ) {
            errors.push(key);
          }
        } else {
          if (!_.get(extraInfo, `beneficiary.${key}`)) {
            errors.push(key);
          }
        }
      });

      ["name", "id", "branch"].forEach((key) => {
        if (!_.get(extraInfo, `beneficiary.bank.${key}`)) {
          errors.push(key);
        }
        if ("id" === key) {
          if (/^\d+$/.test(_.get(extraInfo, "beneficiary.bank.id")) === false) {
            errors.push(key);
          }
        }
      });

      if (errors.length === 0) {
        paramsData.certNum = itemContract?.certNum;
        paramsData.productIdParent = 19;
        history.push(SHOW_DETAIL_CLAIM_TOMATO + location.search, {
          itemContract: itemContract,
          paramsData: paramsData,
        });
      }
      return { errors };
    },
  };

  return cases[action?.type];
};

export default createDetailClaimFileReducer;
