import { Button, Card } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect } from "react";

import useReducer from "../../../../../library/hooks/useReducer";
import Snackbars from "../../../../../ui/snackbar";
import { getParamUrlByKey } from "../../../../../utils/utils";
import CardDocument from "../components/CardDocument";
import CardHistory from "../components/CardHistory";
import CardInfor from "../components/CardInfor";
import ShowImgDialog from "../components/DialogShowImg";
import Loading from "../components/Loading";
import ConfirmDialog from "../components/confirmDialog";
import detailReducer from "../reducer/detailReducer";
import { useStyles } from "../styles/useStyles";

import download from "../assets/download.svg";
import eyeIcon from "../assets/eyeIcon.svg";

const initialState = {
  rows: [],
  dataExport: [],
  row: {},
  showImg: {},
  errors: {},
  pageTable: {
    count: 0,
    page: 0,
    rowsPerPage: 10,
  },
  snackbar: {},
  count: 0,
  approvalAmount: "",
  content: "",
  setPage: "compensation",
  loading: false,
  confirmDialog: false,
  documentsAdditionalImg: [],
  documentsAdditionalFile: [],
  documentAudio: [],
  loadAudio: false,
  showFileEvidence: {},
  evidenceAudio: [],
  acceptSignFile: [],
  loadFile: false,
  openNotiUpdating: false,
};

const statusCurrent = (id) => {
  switch (id) {
    case 2:
      return { color: "#26A9E0", text: "Đang xử lý" };
    case 4:
      return { color: "#FB7800", text: "Bổ sung hồ sơ" };
    case 5:
      return { color: "#39B44A", text: "Đã duyệt" };
    case 6:
      return { color: "#39B44A", text: "Hoàn tất" };
    case 7:
      return { color: "#BF272D", text: "Từ chối" };
    default:
      break;
  }
};

export default function Detail({ form }) {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const roleType = user?.extra_info?.userType;
  const id = getParamUrlByKey("id");

  const [state, dispatch] = useReducer({
    reducer: detailReducer,
    name: "detailReducer",
    initialState,
    roleType,
    id,
  });
  const {
    row,
    loading,
    confirmDialog,
    snackbar,
    statusId = 0,
    initStatusId = 0,
    updated,
    updatedSignFile,
  } = state;

  useEffect(() => dispatch("initial"), []);

  let dataFilePdf = null;

  return (
    <div className={classes.container}>
      <div className="head-title">
        {form === "approve-claim"
          ? "Kiểm Tra Xử Lý Hồ Sơ/"
          : "Báo Cáo Bồi Thường/"}{" "}
        <span style={{ textTransform: "capitalize" }}>
          {row?.insuredInfo?.insuredName || ""}
        </span>
      </div>
      <div style={{ fontSize: "18px", color: "#000000", fontWeight: "600" }}>
        Trạng thái:{" "}
        <span
          style={{
            color: statusCurrent(row?.statusId)?.color,
          }}
        >
          {" " + statusCurrent(row?.statusId)?.text}{" "}
        </span>
      </div>
      <Card sx={{ padding: "6px", marginTop: "25px" }}>
        <Grid container spacing={0.5}>
          <Grid item xs={12} sm={12} md={4}>
            <Card className={classes.infor}>
              <CardInfor {...{ row, dispatch }} />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
              }}
            >
              <Card className={classes.document}>
                <CardDocument {...{ form, state, dispatch }} />
              </Card>
              <div className={classes.exportButton}>
                <Button
                  className="bt-show"
                  onClick={() => {
                    window.open(row?.insuredInfo?.certLink || "");
                  }}
                >
                  <img src={eyeIcon} style={{ marginRight: "6px" }} />
                  Xem Chứng Nhận Bảo Hiểm
                </Button>
                {["globalcare", "provider"].includes(roleType) && (
                  <Button
                    onClick={() =>
                      dispatch({ type: "downLoadZip", file: dataFilePdf })
                    }
                    style={{
                      textDecoration: "none",
                      backgroundColor: "#FB7800",
                      fontWeight: "600",
                      fontSize: "14px",
                      borderRadius: "8px",
                      padding: "6px",
                      background: "#FB7800",
                      color: "white",
                    }}
                  >
                    <img src={download} style={{ marginRight: "6px" }} />
                    Tải Hồ Sơ
                  </Button>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card className={classes.history}>
              <CardHistory {...{ form, state, dispatch }} />
            </Card>
          </Grid>
        </Grid>
      </Card>
      <div className={classes.buttonDiv}>
        <Button className={classes.button} onClick={() => dispatch("back")}>
          Quay lại
        </Button>
        {form === "approve-claim" &&
          !(statusId === 2 || [6, 7].includes(initStatusId) || updated) && (
            <Button
              // disabled={
              //   statusId === 2 || [6, 7].includes(initStatusId) || updated
              // }
              className={
                statusId === 2 || [6, 7].includes(initStatusId) || updated
                  ? "disabled button"
                  : "button"
              }
              onClick={() => dispatch("update")}
            >
              Cập nhật
            </Button>
          )}
        {form === "report-claim" && (statusId === 5 || initStatusId === 5) && (
          <Button
            disabled={updatedSignFile}
            className={updatedSignFile ? "disabled button" : "button"}
            onClick={() => dispatch("openConfirmDialog")}
          >
            Cập nhật
          </Button>
        )}
      </div>

      <ShowImgDialog {...{ dispatch, state }} />
      <ConfirmDialog {...{ state, dispatch, open: confirmDialog, form }} />
      <Loading loading={loading} />
      <Snackbars
        open={snackbar.open}
        onClose={() => dispatch("closeSnackbar")}
        message={snackbar.message}
        type={snackbar.type}
      />
    </div>
  );
}
