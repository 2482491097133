import {
  Close as CloseIcon,
  FilterList as FilterListIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import { Drawer, FormControlLabel, FormGroup } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Button } from "antd";
import React, { useEffect, useRef } from "react";

import DefaultIcon from "../assets/logo_default.svg";
import { CheckBox, TextField } from "../overrideMui";
import { stylePopoverFilter } from "../styles/stylePopoverFilter";
import { get } from "lodash";

export default function PopoverFilter({ state, dispatch }) {
  const classes = stylePopoverFilter();
  const {
    filter,
    products,
    organizes,
    expand,
    loadingFilter,
    oldProducts,
    oldOrganizes,
    pageFilter,
  } = state;

  const ref2 = useRef(null);

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={Boolean(filter?.open)}
        onClose={() => dispatch("closeFilter")}
        sx={{
          "& .MuiPaper-elevation": {
            marginTop: "60px",
            marginRight: "12px",
            height: "max-content",
            maxHeight: "85vh",
            borderRadius: "8px",
          },
        }}
      >
        <div
          style={{ width: "370px", height: "100%", overflow: "auto" }}
          id="divScroll"
          ref={ref2}
        >
          <div className={classes.container}>
            <div className={classes.header}>
              <div className="title">
                <FilterListIcon fontSize="small" sx={{ marginRight: "8px" }} />
                <span>Bộ lọc</span>
              </div>
              <CloseIcon
                fontSize="small"
                sx={{ marginRight: "8px", cursor: "pointer" }}
                onClick={() => dispatch("closeFilter")}
              />
            </div>
            <div className={classes.box_check}>
              <div className={classes.title}>
                <span className="span-time">Sản phẩm</span>
                <span
                  className="span-delete"
                  onClick={() =>
                    dispatch({ type: "deleteFilter", name: "product" })
                  }
                >
                  Xoá
                </span>
              </div>
              <FormGroup>
                {products.map((item, index) => (
                  <div
                    key={`prod_${index}`}
                    style={{ marginBottom: "8px" }}
                    className="scroll-item-product"
                  >
                    <FormControlLabel
                      sx={{
                        margin: 0,
                        "& .MuiTypography-root": {
                          fontSize: "14px",
                        },
                      }}
                      control={
                        <CheckBox
                          className={classes.checkBox}
                          checked={!!item?.checked}
                          onClick={(e) =>
                            dispatch({
                              type: "onCheck",
                              name: "product",
                              index,
                              value: e.target.checked,
                            })
                          }
                        />
                      }
                      label={
                        <div className="row">
                          <img
                            src={item?.url_logo || DefaultIcon}
                            className="icon"
                          />
                          <div>{item?.title || ""}</div>
                        </div>
                      }
                    />
                  </div>
                ))}
              </FormGroup>
              {loadingFilter?.product && (
                <div
                  style={{
                    fontWeight: "600",
                    color: "#0B7CE4",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "17px",
                  }}
                >
                  Đang tải thêm ...{" "}
                </div>
              )}
              <div className="show-move">
                {(get(pageFilter, "product.total", 1) >
                  get(pageFilter, "product.page", 1) * 10 ||
                  oldProducts?.length > products?.length) && (
                  <div
                    className="row color-text"
                    onClick={() => {
                      if (!loadingFilter.product) {
                        dispatch({
                          type: "handleShow",
                          name: "product",
                        });
                      }
                    }}
                  >
                    Hiển thị thêm{" "}
                    <ExpandMoreIcon color="#0B7CE4" sx={{ width: "20px" }} />
                  </div>
                )}
                {products.length > 5 && (
                  <div
                    className="row color-text"
                    onClick={() =>
                      dispatch({
                        type: "handleShrink",
                        name: "product",
                      })
                    }
                  >
                    Thu gọn{" "}
                    <ExpandLessIcon color="#0B7CE4" sx={{ width: "20px" }} />
                  </div>
                )}
              </div>
            </div>

            <div className={classes.box_check}>
              <div className={classes.title}>
                <span className="span-time">Doanh nghiệp</span>
                <span
                  className="span-delete"
                  onClick={() =>
                    dispatch({ type: "deleteFilter", name: "organize" })
                  }
                >
                  Xoá
                </span>
              </div>
              <div>
                <FormGroup>
                  {organizes.map((item, index) => (
                    <div
                      key={`organ_${index}`}
                      style={{ marginBottom: "8px" }}
                      className="scroll-item-merchant"
                    >
                      <FormControlLabel
                        sx={{
                          margin: 0,
                          "& .MuiTypography-root": {
                            fontSize: "14px",
                          },
                        }}
                        control={
                          <CheckBox
                            className={classes.checkBox}
                            checked={!!item?.checked}
                            onClick={(e) =>
                              dispatch({
                                type: "onCheck",
                                name: "organize",
                                index,
                                value: e.target.checked,
                              })
                            }
                          />
                        }
                        label={item?.title || ""}
                      />
                    </div>
                  ))}
                </FormGroup>
              </div>
              {loadingFilter?.merchant && (
                <div
                  style={{
                    fontWeight: "600",
                    color: "#0B7CE4",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "17px",
                  }}
                >
                  Đang tải thêm ...{" "}
                </div>
              )}
              <div className="show-move">
                {(get(pageFilter, "merchant.total", 1) >
                  get(pageFilter, "merchant.page", 1) * 10 ||
                  oldOrganizes?.length > organizes?.length) && (
                  <div
                    className="row color-text"
                    onClick={() => {
                      if (!loadingFilter.merchant) {
                        dispatch({
                          type: "handleShow",
                          name: "merchant",
                        });
                      }
                    }}
                  >
                    Hiển thị thêm{" "}
                    <ExpandMoreIcon color="#0B7CE4" sx={{ width: "20px" }} />
                  </div>
                )}
                {organizes.length > 5 && (
                  <div
                    className="row color-text"
                    onClick={() =>
                      dispatch({
                        type: "handleShrink",
                        name: "merchant",
                      })
                    }
                  >
                    Thu gọn{" "}
                    <ExpandLessIcon color="#0B7CE4" sx={{ width: "20px" }} />
                  </div>
                )}
              </div>
            </div>
            <Button
              className={classes.button}
              onClick={() => dispatch("onFilter")}
            >
              Áp dụng
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
