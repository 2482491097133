import moment from "moment";

const _formatMoney = (str) => {
  if (str) {
    return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return 0;
};

const formatDate = (date) => {
  if (!date) return "";
  const newDate = moment(date).format("DD/MM/YYYY");
  return newDate !== "Invalid date" ? newDate : "";
};

const getExtension = (path) => {
  const extension = path?.split(".")?.pop();
  return extension;
};

const read3Number = (numb) => {
  const numbName = [
    "Không",
    "Một",
    "Hai",
    "Ba",
    "Bốn",
    "Năm",
    "Sáu",
    "Bảy",
    "Tám",
    "Chín",
  ];

  const result = numb
    .toString()
    .split("")
    .reverse()
    .map((val, index) => {
      let level = ["", " Mươi", " Trăm"];
      return numbName[val - 0] + level[index];
    })
    .reverse()
    .join(" ")
    .replace("Không Mươi", "Linh")
    .replace("Một Mươi", "Mười")
    .replace("Mươi Không", "Mươi")
    .replace("Mười Không", "Mười")
    .replace("Mươi Năm", "Mươi Lăm")
    .replace("Mươi Bốn", "Mươi Tư")
    .replace("Linh Bốn", "Linh Tư")
    .replace(" Linh Không", "");
  return result;
};

const convertMoneyNumberToString = (num) => {
  if (!num) return;

  const newValue = num
    .toLocaleString("en")
    .split(",")
    .reverse()
    .map((val, index) => {
      let level = ["", " Nghìn", " Triệu", " Tỉ", " Nghìn", " Triệu"];
      if (!(val - 0)) {
        if (index === 3) {
          return level[index];
        }
        return "";
      }
      return read3Number(val) + level[index];
    })
    .reverse()
    .join(" ")
    .trim()
    .replace("  ", " ");

  return newValue;
};

const renderStatus = (id) => {
  switch (id) {
    case 2:
      return "Đang xử lý";
    case 3:
      return "Đang đợi phê duyệt";
    case 4:
      return "Bổ sung hồ sơ";
    case 5:
      return "Đã duyệt";
    case 6:
      return "Hoàn tất";
    case 7:
      return "Từ chối";
    case 8:
      return "Khách hàng từ chối";
    default:
      return "";
  }
};

export {
  _formatMoney,
  formatDate,
  getExtension,
  read3Number,
  convertMoneyNumberToString,
  renderStatus,
};
