import React, { useEffect, useState, useRef } from "react";
import { Wrapper, ContentWrapper, Content } from "./styles";
import { Col, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

import * as PATH from "../../../configs/routesConfig";
import { getListClaim } from "../../../apis/carAccidentApis";
import Button from "../../../ui/buttons/Button";
import { InputField } from "../../../ui/fields";
import HeaderBar from "../create/tomato/components/layout/HeaderBar";
import "./list.css";
const queryString = require("query-string");

const ClaimList = () => {
  const history = useHistory();
  const location = useLocation();
  const [listData, setListData] = useState([]);
  const [listDataFilter, setListDataFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [dataSelected, setDataSelected] = useState({});
  useEffect(() => {
    let q = queryString.parse(location?.search);
    localStorage.setItem("token", q.token);

    document.body.style.backgroundColor = "#F7F7F7";
    window.scrollTo({ top: 0, behavior: "smooth" });
    getList();
  }, []);

  const getList = () => {
    setIsLoading(true);
    const params = {
      productIdParent: 17,
      limit: 1000,
    };
    getListClaim(params)
      .then((res) => {
        const data = res?.data?.result?.data;
        setListData(data);
        setListDataFilter(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("err", err);
      });
  };

  const getStatus = (statusId) => {
    switch (statusId) {
      case 1:
        return "Chưa được xử lý";
      case 6:
        return "Đã bồi thường";
      case 7:
        return "Từ chối hsbt";
      default:
        return "Chưa được xử lý";
    }
  };

  const getBackgroundStatus = (statusId) => {
    switch (statusId) {
      case 1:
        return "#2F00B6";
      case 6:
        return "#069D27";
      case 7:
        return "#FF4F4F";
      default:
        return "#2F00B6";
    }
  };

  const setBackgroundSelected = (certNum) => {
    let background = "#FFF";
    if (dataSelected?.certNum && dataSelected?.certNum === certNum) {
      background = "#FFA375";
    }
    return background;
  };

  const handleSelectData = (item, index) => () => {
    if (item?.statusId === 100) {
      setDataSelected({});
      history.push(
        `/claim/car-accident/details/${item?.certNum}` + location?.search
      );
    } else {
      if (itemSelected === index) {
        setDataSelected({});
        setItemSelected(null);
      } else {
        setDataSelected(item);
        setItemSelected(index);
      }
    }
  };

  const handleContinue = (data) => () => {
    switch (data?.productIdParent) {
      case 17:
        history.push(
          PATH.INSU_INFO_CLAIM_ACCIDENT_PATH + location?.search + "&goback",
          { data }
        );
        break;
      case 19:
        history.push(
          PATH.CREATE_REQUEST_CLAIM_TOMATO + location?.search + "&goback",
          { data }
        );
        break;
      default:
        break;
    }
  };

  const renderListData = (item, index) => {
    return (
      <Row
        onClick={handleSelectData(item, index)}
        style={{
          marginTop: 16,
          marginBottom: 16,
          backgroundColor: itemSelected === index ? "#FFA375" : "#FFF",
          borderRadius: 12,
          minHeight: 100,
          position: "relative",
        }}
        key={index}
      >
        <div style={styleLeftItem}>
          <div style={styleTitleI}>SỐ HỢP ĐỒNG</div>
          <div style={styleTxtContent}>{item?.certNum}</div>
          <div style={styleTxtContent}>Biển số xe: {item?.licenseNumber}</div>
          <div style={styleTitleII}>THỜI HẠN BẢO HIỂM</div>
          <div style={styleTxtContent}>
            Ngày hiệu lực:{" "}
            {moment(item?.beginDate).format("hh:mm:ss; DD/MM/YYYY")}
          </div>
          <div style={styleTxtContent}>
            Ngày hết hạn: {moment(item?.endDate).format("hh:mm:ss; DD/MM/YYYY")}
          </div>
        </div>
        {item?.statusId ? (
          <div style={styleStatus}>
            <div
              style={{
                textAlign: "center",
                padding: 7,
                color: "#FFF",
                fontSize: 13,
                textTransform: "uppercase",
                fontWeight: "700",
                borderTopRightRadius: 12,
                borderTopLeftRadius: 12,
                borderBottomLeftRadius: 12,
                background: getBackgroundStatus(item?.statusId),
                minHeight: 48,
                maxWidth: 102,
              }}
            >
              {getStatus(item?.statusId)}
            </div>
          </div>
        ) : null}
      </Row>
    );
  };

  const handleSearch = (e) => {
    let newList = listData.filter((item) => {
      const isCheck = item?.licenseNumber
        ?.toLowerCase()
        .indexOf(e.target.value.toLowerCase());
      return isCheck > -1;
    });
    setTimeout(() => setListDataFilter(newList), 300);
  };

  const onGoBack = () => {
    window.ReactNativeWebView.postMessage("onGoBack");
  };

  return (
    <Wrapper>
      <HeaderBar
        title="Bảo hiểm vật chất ô tô"
        background="#F7F7F7"
        onPress={onGoBack}
      />
      <ContentWrapper style={{ marginTop: 55 }}>
        <Content>
          <Row justify="center">
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <InputField
                name="search"
                autoComplete="search"
                placeholder="Tìm kiếm biển số xe"
                size="largeSize"
                inputStyle={inputStyle}
                hasIconLeft
                iconStart={
                  <SearchOutlined
                    style={{ paddingRight: 5, color: "rgba(0, 0, 0, 0.54)" }}
                  />
                }
                onChange={handleSearch}
              />
              {isLoading && (
                <div style={styleLoading}>
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                      color: "#E16B2F",
                    }}
                  />
                </div>
              )}

              {listDataFilter?.length ? (
                dataSelected?.certNum ? (
                  <div style={styleNoteSuccess}>
                    Nhấn nút "Tiếp tục" để chuyển đến bước kế tiếp
                  </div>
                ) : (
                  <div style={styleNote}>
                    Vui lòng chọn hợp đồng để tiếp tục tạo yêu cầu bồi thường
                  </div>
                )
              ) : null}
              {!listDataFilter?.length && !isLoading ? (
                <div style={styleDataNull}>Không tìm thấy dữ liệu</div>
              ) : (
                listDataFilter?.map((item, index) =>
                  renderListData(item, index)
                )
              )}
            </Col>
          </Row>
          <div style={{ height: 50 }} />
        </Content>
      </ContentWrapper>
      {dataSelected?.certNum && (
        <div style={styleButton}>
          <Button
            type="primary"
            htmlType="submit"
            border="14"
            onClick={handleContinue(dataSelected)}
          >
            Tiếp tục
          </Button>
        </div>
      )}
    </Wrapper>
  );
};

const inputStyle = {
  borderRadius: 9,
  fontSize: 17,
};

const styleNote = {
  color: "rgba(255, 41, 41, 1)",
  fontSize: 18,
  margin: "20px 0 20px 0",
};

const styleNoteSuccess = {
  color: "rgba(42, 182, 92, 1)",
  fontSize: 18,
  margin: "20px 0 20px 0",
};

const styleLeftItem = {
  padding: 16,
};

const styleTitleI = {
  fontSize: 17,
  fontWeight: "bold",
};

const styleTitleII = {
  fontSize: 13,
  fontWeight: "bold",
  color: "rgba(0, 0, 0, 0.32)",
};

const styleTxtContent = {
  fontSize: 15,
  fontWeight: "400",
  color: "rgba(0, 0, 0, 1)",
  margin: "4px 0 4px 0",
};

const styleStatus = {
  position: "absolute",
  top: 0,
  right: 0,
};

const styleButton = {
  width: "100%",
  display: "flex",
  position: "fixed",
  bottom: 0,
  background: "#FFF",
  padding: "10px 24px 24px 24px",
};

const styleDataNull = {
  fontSize: 17,
  color: "rgba(0, 0, 0, 0.32)",
  textAlign: "center",
};

const styleLoading = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
};

export default ClaimList;
