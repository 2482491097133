import api from "../configs/api";

export function getOverview(systemType, data) {
  return api
    .apiForm("POST", `/api/v2/dashboard/${systemType}/overview`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getOverviewList(systemType, data) {
  return api
    .apiForm("POST", `/api/v2/dashboard/${systemType}/overview/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getOverviewListProduct(systemType, data) {
  return api
    .apiForm("POST", `/api/v2/dashboard/${systemType}/product/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getOverviewListMerchant(systemType, data) {
  return api
    .apiForm("POST", `/api/v2/dashboard/${systemType}/merchant/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListOrg(data) {
  return api
    .apiForm("POST", `/api/v2/organization/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListProductParent(data) {
  return api
    .apiForm("POST", `/api/v2/product-parent/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}

export function getListMerchant(data) {
  return api
    .apiForm("POST", `/api/v2/merchant/filter/list`, data)
    .then((res) => res)
    .catch((err) => err.response);
}
