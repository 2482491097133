import { Backdrop, Button, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import warring from "../assets/warring.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDialog(props) {
  const { state, dispatch, open, form } = props;
  const { loading, statusId, openNotiUpdating } = state;
  const classes = makeStyle();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => dispatch("closeConfirm")}
      aria-describedby="alert-dialog-slide-description"
      className={classes.dialog}
    >
      <DialogContent style={{ padding: "20px" }}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className={classes.divContent}>
          <div className="img">
            <img src={warring} width={85} height={71} alt="warring" />
          </div>
          {openNotiUpdating ? (
            <>
              <div className="noti">Đang cập nhật yêu cầu bồi thường</div>
              <CircularProgress size={24} sx={{ m: 1, color: "#284078" }} />
              <div className="agre">
                Quá trình này sẽ mất khoảng vài phút...
              </div>
            </>
          ) : (
            <>
              <div className="confirm">Xác nhận trạng thái</div>
              <div className="agre">Khi đã xác nhận sẽ không thay đổi được</div>
              <div className="button">
                <Button
                  className="cancel"
                  onClick={() => dispatch("closeConfirm")}
                >
                  Đóng
                </Button>
                {form === "approve-claim" && (
                  <Button
                    onClick={() =>
                      statusId === 5
                        ? dispatch("onApprove")
                        : dispatch("onSubmitUpdate")
                    }
                    className="submit"
                  >
                    Xác nhận
                  </Button>
                )}
                {form === "report-claim" && (
                  <Button
                    onClick={() => dispatch("uploadAcceptSign")}
                    className="submit"
                  >
                    Xác nhận
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
    },
  },

  divContent: {
    width: "100%",
    textAlign: "center",

    "& .confirm": {
      margin: "12px 0",
      fontSize: "24px",
      color: "#2A3790",
      fontWeight: "600",
    },

    "& .noti": {
      margin: "15px 0",
      fontSize: "20px",
      color: "#2A3790",
      fontWeight: "600",
    },

    "& .agre": {
      margin: "12px 0",
      fontSize: "16px",
      color: "#FB7800",
      fontWeight: "500",
      fontStyle: "italic",
    },

    "& .button": {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",

      "& .cancel": {
        width: "45%",
        color: "#2A3790",
        border: "1px solid #EBEDFF",
        borderRadius: "50px",
      },

      "& .submit": {
        width: "45%",
        color: "white",
        background: "#2A3790",
        border: "1px solid #EBEDFF",
        borderRadius: "50px",
      },
    },
  },
}));
