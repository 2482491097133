import { useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DashboardNavbar } from "./dashboard-navbar";
import { DashboardSidebar } from "./sidebar/dashboard-sidebar";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 25,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 270,
  },
}));

export default function DashboardLayout(props) {
  const { children } = props;
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [desNavbar, setDesNavbar] = useState("");
  const [titleNavbar, setTitleNavbar] = useState("");

  return (
    <>
      <DashboardLayoutRoot>
        <Box sx={cssLayoutRoot}>{children}</Box>
      </DashboardLayoutRoot>
      <DashboardNavbar
        onSidebarOpen={() => setSidebarOpen(true)}
        desNavbar={desNavbar}
        titleNavbar={titleNavbar}
      />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
    </>
  );
}

const cssLayoutRoot = {
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
};
