import { get, isEmpty, isString } from "lodash";
import {
  getListUsers,
  getListUsersTPA,
  getListOrg,
} from "../../../../apis/account_v2";
import { userTypes } from "../util";
import moment from "moment";

export default function accountManagerReducer(props) {
  const { state, action, dispatch, dataSearchRecoid, setDataSearchRecoid } =
    props;
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const tpaAccount = get(user, "extra_info.userType", "") || "";
  const org_id = get(user, "extra_info.org_id", "");

  const cases = {
    initial: async () => {
      const res = {};
      const dataFill = isEmpty(dataSearchRecoid)
        ? {
            from: moment().subtract(30, "days").format(),
            to: moment().format(),
          }
        : { ...dataSearchRecoid };
      switch (tpaAccount) {
        case "globalcare":
          res.selectUserType = userTypes;
          break;
        case "tpag":
          res.selectUserType = userTypes.filter(
            (i) => !["globalcare", "tpa"].includes(i.value)
          );
          break;
        case "tpas":
          res.selectUserType = userTypes.filter((i) =>
            ["all", "merchant", "tpas"].includes(i.value)
          );
          break;
        case "tpa":
          res.selectUserType = userTypes.filter((i) =>
            ["all", "merchant", "tpa"].includes(i.value)
          );
          break;
        case "merchant":
          res.selectUserType = userTypes.filter((i) =>
            ["all", "merchant"].includes(i.value)
          );
          break;
        case "provider":
          res.selectUserType = userTypes.filter((i) =>
            ["all", "provider"].includes(i.value)
          );
          break;

        default:
          return [];
      }

      dispatch("onLoading");

      const arrApi = [];
      ["tpas", "tpag", "tpa", "merchant", "provider"].includes(tpaAccount)
        ? arrApi.push(
            getListUsersTPA(tpaAccount, {
              limit: 20,
              offset: 0,
              ...dataFill,
              userTypes:
                isEmpty(dataFill?.userTypes) ||
                (dataFill?.userTypes || [])?.includes("all")
                  ? null
                  : dataFill?.userTypes,
            })
          )
        : arrApi.push(
            getListUsers({
              limit: 20,
              offset: 0,
              ...dataFill,
              userTypes:
                isEmpty(dataFill?.userTypes) ||
                (dataFill?.userTypes || [])?.includes("all")
                  ? null
                  : dataFill?.userTypes,
            })
          );

      const [usersApi] = await Promise.all(arrApi);
      dispatch("getListOrg");

      const response = get(usersApi, "data", {}) || {};

      if (response?.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
        };
      }

      const listUsers = get(response, "result.data") || [];

      // const roleGroups = get(responseRolegroups, "result") || [];
      const pageTable = {
        total: get(response, "result.total", 0) || 0,
        page: 1,
        count: Math.ceil(get(response, "result.total", 0) / 20),
      };
      dataFill.userTypes = isEmpty(dataSearchRecoid)
        ? [get(res, "selectUserType[0].value", "")]
        : dataFill.userTypes;

      return {
        loading: false,
        listUsers,
        pageTable,
        ...res,
        dataFill,
      };
    },

    getListOrg: async () => {
      const orgApi = await getListOrg({ limit: 20, offset: 0 });
      const listOrg = [...(get(orgApi, "data.result.data") || [])].slice(0, 5);
      const tempRoleGroups = (get(orgApi, "data.result.data") || []).reduce(
        (array, i) => {
          return (array = [
            ...array,
            ...i?.role_groups.map((a) => ({
              ...a,
            })),
          ]);
        },
        []
      );
      const roleGroups = [...tempRoleGroups].slice(0, 5);
      const oldListOrg = [...get(orgApi, "data.result.data", [])];
      const oldRoleGroups = [...tempRoleGroups];
      const pageFilter = {
        orgIds: { page: 1, total: get(orgApi, "data.result.total", 0) },
      };

      return { listOrg, roleGroups, oldListOrg, oldRoleGroups, pageFilter };
    },

    onSelectUserType: async () => {
      const { value } = action;
      let { dataFill } = state;
      const newValue =
        value[value.length - 1] === "all"
          ? ["all"]
          : value.filter((i) => i !== "all");

      dispatch("onLoading");
      dataFill.userTypes = newValue;

      const usersApi = ["tpas", "tpag", "tpa"].includes(tpaAccount)
        ? await getListUsersTPA(tpaAccount, {
            limit: 20,
            offset: 0,
            ...dataFill,
            userTypes:
              newValue?.length === 0 || newValue?.includes("all")
                ? null
                : newValue,
          })
        : await getListUsers({
            limit: 20,
            offset: 0,
            ...dataFill,
            userTypes:
              newValue?.length === 0 || newValue?.includes("all")
                ? null
                : newValue,
          });
      const response = get(usersApi, "data", {}) || {};

      if (response?.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
        };
      }

      const listUsers = get(response, "result.data") || [];
      const pageTable = {
        total: get(response, "result.total", 0) || 0,
        page: 1,
        count: Math.ceil(get(response, "result.total", 0) / 20),
      };

      setDataSearchRecoid({ ...dataFill });

      return { loading: false, listUsers, pageTable, dataFill };
    },

    onChangePage: async () => {
      const { pageNumber } = action;
      const { dataFill } = state;
      const params = {
        // from: dataFill?.from || null,
        // to: dataFill?.to || null,
        // enableClaim: dataFill?.enableClaim,
        limit: 20,
        offset: (pageNumber - 1) * 20 || 0,
        ...dataFill,
        userTypes:
          isEmpty(dataFill?.userTypes) ||
          (dataFill?.userTypes || [])?.includes("all")
            ? null
            : dataFill?.userTypes,
      };

      dispatch("onLoading");
      const dataApi = ["tpas", "tpag", "tpa"].includes(tpaAccount)
        ? await getListUsersTPA(tpaAccount, params)
        : await getListUsers(params);
      const response = get(dataApi, "data", {});

      if (response.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lội khi lấy thông tin, xin vui lòng thử lại",
          },
        };
      }
      const listUsers = get(response, "result.data", []) || [];
      const pageTable = {
        total: get(response, "result.total", 0),
        page: pageNumber,
        count: Math.ceil(get(response, "result.total", 0) / 20),
      };

      return {
        loading: false,
        listUsers,
        pageTable,
      };
    },

    openFilter: () => {
      const { filter } = state;

      return { filter: { ...filter, open: true } };
    },

    onChangeFill: () => {
      const { name, checked, value } = action;
      const { dataFill } = state;

      if (name === "keyword") {
        dataFill[name] = value;
        return { dataFill };
      }

      let temp = (dataFill[name] || []).filter((i) => i !== "all");

      if (checked) {
        temp.push(value);
      } else {
        temp =
          temp.filter((i) => i != value).length === 0
            ? null
            : temp.filter((i) => i != value);
      }
      dataFill[name] = temp;
      return { dataFill };
    },

    deleteDataFill: () => {
      const { name } = action;
      const { dataFill } = state;

      if (name === "time") {
        dataFill.from = null;
        dataFill.to = null;
      } else {
        dataFill[name] = [];
      }

      return { dataFill };
    },

    applyFilter: async () => {
      const { keyword = null, name, value } = action;
      const { dataFill } = state;
      if (keyword || keyword === "") {
        dataFill.keyword = keyword;
      }

      if (name) {
        dataFill[name] = value;
      }

      dispatch("onLoading");
      const usersApi = ["tpas", "tpag", "tpa"].includes(tpaAccount)
        ? await getListUsersTPA(tpaAccount, {
            limit: 20,
            offset: 0,
            ...dataFill,
            userTypes:
              dataFill?.userTypes?.includes("all") ||
              dataFill?.userTypes?.length === 0
                ? null
                : dataFill?.userTypes,
          })
        : await getListUsers({
            limit: 20,
            offset: 0,
            ...dataFill,
            userTypes:
              dataFill?.userTypes?.includes("all") ||
              dataFill?.userTypes?.length === 0
                ? null
                : dataFill?.userTypes,
          });
      const response = get(usersApi, "data", {}) || {};

      if (response?.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
        };
      }

      const listUsers = get(response, "result.data") || [];
      const pageTable = {
        total: get(response, "result.total", 0) || 0,
        page: 1,
        count: Math.ceil(get(response, "result.total", 0) / 20),
      };

      setDataSearchRecoid({ ...dataFill });

      return {
        loading: false,
        listUsers,
        pageTable,
        filter: { open: false },
      };
    },

    closeFilter: () => {
      const { filter } = state;
      return { filter: { ...filter, open: false } };
    },

    exportFileExcel: async () => {
      const { dataFill } = state;
      dispatch("onLoading");
      const dataApi = [("tpas", "tpag", "tpa")].includes(tpaAccount)
        ? await getListUsersTPA(tpaAccount, {
            limit: 500,
            offset: 0,
            ...dataFill,
            userTypes:
              dataFill?.userTypes?.includes("all") ||
              dataFill?.userTypes?.length === 0
                ? null
                : dataFill.userTypes,
          })
        : await getListUsers({
            limit: 500,
            offset: 0,
            ...dataFill,
            userTypes:
              dataFill?.userTypes?.includes("all") ||
              dataFill?.userTypes?.length === 0
                ? null
                : dataFill.userTypes,
          });

      if (dataApi?.data?.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi trong quá trình tải, xin vui lòng thử lại",
          },
        };
      }

      const dataExport = get(dataApi, "data.result.data", []) || [];

      return { dataExport, loading: false, count: state.count + 1 };
    },

    showMove: async () => {
      const { name } = action;
      const { pageFilter, oldListOrg, listOrg, loadingFilter, oldRoleGroups } =
        state;

      const res = {};
      if (name === "orgIds") {
        if (oldListOrg.length > listOrg.length) {
          return { listOrg: [...oldListOrg] };
        }

        if (pageFilter?.orgIds.page * 20 >= pageFilter?.orgIds?.total) {
          return;
        }

        dispatch({ type: "onLoadingFilter", name: "orgIds" });
        const orgApi = await getListOrg({
          limit: 20,
          offset: pageFilter?.orgIds.page * 20,
        });

        if (orgApi?.data?.statusCode !== 200) {
          return {
            loading: false,
            snackbar: {
              open: true,
              type: "error",
              message: "Xin lỗi, đã xảy ra lỗi trong quá trình lấy dữ liệu",
            },
          };
        }

        res.listOrg = [...listOrg, ...(get(orgApi, "data.result.data") || [])];
        res.oldListOrg = [
          ...oldListOrg,
          ...(get(orgApi, "data.result.data") || []),
        ];
        res.pageFilter = {
          orgIds: {
            page: pageFilter?.orgIds?.page + 1,
            total: get(orgApi, "data.result.total", 0),
          },
        };
      }

      if (name === "roleGroupIds") {
        res.roleGroups = [...oldRoleGroups];
      }

      return { ...res, loadingFilter: { ...loadingFilter, [name]: false } };
    },

    collapse: () => {
      const { name } = action;
      const { listOrg, roleGroups } = state;

      const res = {};
      if (name === "orgIds") {
        res.listOrg = [...listOrg].splice(0, 5);
      }

      if (name === "roleGroupIds") {
        res.roleGroups = roleGroups.splice(0, 5);
      }

      return { ...res };
    },

    //

    onCloseSnackbar: () => ({ snackbar: { open: false } }),

    onLoading: () => ({ loading: true }),

    onLoadingFilter: () => ({
      loadingFilter: { ...state.loadingFilter, [action.name]: true },
    }),

    //
  };

  return isString(action.type) && cases[action.type];
}
