import { makeStyles } from "@mui/styles";
import { Modal } from "antd";
import React from "react";

export default function ShowFile(props) {
  const { onClose, open, link } = props;
  const classes = useStyle();

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      className={classes.dialog}
    >
      <iframe
        src={`https://docs.google.com/viewer?url=${link}&embedded=true`}
        title="DOCX Document"
        style={{ width: "100%", height: "65vh" }}
      ></iframe>
    </Modal>
  );
}

const useStyle = makeStyles(() => ({
  dialog: {
    "& .ant-modal-body": {
      padding: "50px 10px 10px",
    },
  },
}));
