import { useEffect, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import { message } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";

import LoginLayout from "./login-layout";
import iconPhone from "../../../../assets/icons/iconPhone.svg";
import { login_v2, getProfile_v2 } from "../../../../apis/authApis";
import { messages } from "../../../../utils/messages";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { getParamUrlByKey } from "../../../../utils/utils";
import { useRecoilState } from "recoil";
import { embededFiNaRC } from "../atomAccount";

const Login = () => {
  const [showPassWord, setShowPassWord] = useState(false);
  const [embededFiNa, setEmbededFiNa] = useRecoilState(embededFiNaRC);

  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().max(255).required("Tên đăng nhập là bắt buộc"),
      password: Yup.string().max(255).required("Mật khẩu là bắt buộc"),
    }),
    onSubmit: () => {
      handleLogin();
    },
  });
  const token = localStorage.getItem("token") || false;

  useEffect(() => {
    if (token) {
      getUserInfo();
    }
  }, []);

  const handleLogin = () => {
    const { email, password } = formik.values;
    const params = {
      username: email,
      password,
    };
    login_v2(params)
      .then((res) => {
        localStorage.setItem("token", res?.data?.result?.token);
        // localStorage.setItem("adminv2", true);

        getUserInfo(res?.data?.result);
        // window.location.reload();
      })
      .catch((err) => {
        console.error("err", err?.response?.data);
        createFail(err?.response?.data);
      });
  };

  const createFail = async (error) => {
    formik.setSubmitting(false);
    message.error({
      content: messages.alert[error?.messageCode] || messages.alert.login_error,
      duration: 5,
    });
  };

  const getUserInfo = (data) => {
    getProfile_v2()
      .then(async (res) => {
        const searchKey =
          embededFiNa == "fina.claim.admin" ? "?embeded=fina.claim.admin" : "";
        await localStorage.setItem("user", JSON.stringify(res.data.result));
        +res.data.result.extra_info?.providerId === 7
          ? history.push("/admin/provider/home" + searchKey)
          : history.push("/admin/home" + searchKey);
        // : history.push("/adminv2/home");
        formik.setSubmitting(false);
      })
      .catch((err) => {
        message.error({
          content:
            messages.alert[err?.response?.data?.messageCode] ||
            messages.alert.error,
          duration: 5,
        });
      });
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexFlow: "column",
          flexGrow: 1,
          minHeight: "100%",
          background: "#FAFAFA",
        }}
      >
        <Container maxWidth="sm" sx={{ marginTop: "auto" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 3 }}>
              <Typography
                fontWeight="fontWeightBold"
                color="textPrimary"
                variant="h4"
              >
                Đăng nhập
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="User ID"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              variant="outlined"
              focused
            />
            <TextField
              // inputProps={{
              //   autocomplete: 'password',
              //   form: {
              //     autocomplete: 'off',
              //   },
              // }}
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Mật khẩu"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showPassWord ? "text" : "password"}
              value={formik.values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setShowPassWord(!showPassWord)}
                  >
                    {showPassWord ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }}
              focused
            />
            <Link
              to="forgot-password"
              color="gray"
              underline="hover"
              sx={{
                cursor: "pointer",
              }}
            >
              <Typography
                textAlign="right"
                color="black"
                sx={{
                  cursor: "pointer",
                }}
              >
                Quên mật khẩu?
              </Typography>
            </Link>

            <Box sx={{ py: 2, mt: 5 }}>
              <LoadingButton
                color="gc"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              >
                Đăng nhập
              </LoadingButton>
            </Box>
          </form>
        </Container>
        <Box textAlign="center" mt="auto" mx="auto" pb={3}>
          <Typography sx={{ color: "#1E6BE5" }} mb={1}>
            <img src={iconPhone} />
            Hotline:{" "}
            {embededFiNa == "fina.claim.admin"
              ? "(024) 6276 5555"
              : "1900 2929 87"}
          </Typography>
          {embededFiNa != "fina.claim.admin" && (
            <Typography>Một sản phẩm công nghệ của Global Care</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

const LoginPage = () => {
  localStorage.setItem("embeded", getParamUrlByKey("embeded") || null);
  const [embededFiNa, setEmbededFiNa] = useRecoilState(embededFiNaRC);
  setEmbededFiNa(getParamUrlByKey("embeded") || null);

  return (
    <LoginLayout>
      <Login />
    </LoginLayout>
  );
};

export default LoginPage;
