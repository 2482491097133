import { makeStyles } from "@mui/styles";

export const styledFormReportAccident = makeStyles(() => ({
  container: {
    background: "white",
    padding: "60px",
    paddingTop: "100px",
    width: "790px",
    height: "822px",
    margin: "auto",
    fontFamily: "Times New Roman",
  },

  QuocHieu: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    fontSize: "15px",

    "& .qh": {
      fontWeight: "500",
    },

    "& .tieu-ngu": { fontWeight: "700" },
  },

  title: {
    width: "100%",
    textAlign: "center",
    margin: "36px 0",
    fontSize: "22px",
    fontWeight: "700",
  },

  form: {
    width: "100%",
    textAlign: "left",
    fontSize: "15",
    fontWeight: "500",
    padding: "0 12px",

    "& .line": {
      margin: "8px 0",
    },
  },

  //
}));
