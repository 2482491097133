import api from "../configs/api";

const getListIndemnify = (systemType = "globalcare", data) =>
  api
    .apiForm("POST", `/api/v2/claim/${systemType}/list`, data)
    .then((res) => res)
    .catch((error) => error);

const getDetailIndemnify = (systemType = "globalcare", id) =>
  api
    .apiForm("GET", `/api/v2/claim/${systemType}/${id}/detail`)
    .then((res) => res)
    .catch((error) => error);

const updataDetailIndemnifyl = (action, data) =>
  api
    .apiForm("PUT", `/api/v2/claim/globalcare/add-elements/${action}`, data)
    .then((res) => res)
    .catch((error) => error);

const uploadImg = (data) =>
  api
    .apiForm("POST", "/api/v2/document/upload/cdn", data)
    .then((res) => res)
    .catch((error) => error);

const getDetail365PBA = (id) =>
  api.apiForm("GET", `/api/v2/claim/client/${id}/detail`);

const getListYourClaim_v2 = (data) => {
  return api.apiForm("POST", "/api/v2/claim/client/list", data);
};

const getListClaim_v2 = (data) => {
  return api.apiForm("POST", "/api/v2/cert/list", data);
};

const uploadImage_v2 = (data) => {
  return api.apiForm("POST", "/api/v2/document/upload/cdn", data);
};

function getBankList_v2(data) {
  return api.apiForm("POST", "/api/v2/bank/list", data);
}

function uploadBase64_v2(data) {
  return api.apiForm("POST", "/api/v2/document/upload/cdn/image-base64", data);
}

function getInfoCarAccident_v2(data) {
  return api.apiForm("POST", "/api/v2/cert/detail", data);
}

function createOrder_v2(data) {
  return api.apiForm("POST", "/api/v2/claim/client", data);
}

export {
  getListIndemnify,
  getDetailIndemnify,
  updataDetailIndemnifyl,
  getDetail365PBA,
  uploadImg,
  getListClaim_v2,
  getListYourClaim_v2,
  uploadImage_v2,
  getBankList_v2,
  uploadBase64_v2,
  getInfoCarAccident_v2,
  createOrder_v2,
};
