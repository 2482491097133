import React from "react";
import { Button, Dialog, DialogContent, Slide } from "@mui/material";
import { makeStyles } from "@mui/styles";

import warning from "../../../../assets/images/notifica_warning.svg";
import sussecc from "../../../../assets/images/notifica_success.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogWarning(props) {
  const classes = useStyle();
  const { open, dispatch } = props;

  return (
    <React.Fragment>
      <Dialog
        open={Boolean(open)}
        TransitionComponent={Transition}
        // onClose={() => dispatch("onCloseDialogWarning")}
      >
        <DialogContent className={classes.content}>
          <div className="div-img">
            <img src={warning} width={80} />
          </div>
          <div className="message-text">
            Một số mã trong file của bạn đã có trong bảng quyền lợi
          </div>
          <div className="div-button">
            <Button
              onClick={() =>
                dispatch({ type: "onCloseDialogWarning", name: "skip" })
              }
              className="btn no"
            >
              Bỏ Qua
            </Button>
            <Button
              className="btn yes"
              onClick={() =>
                dispatch({ type: "onCloseDialogWarning", name: "replace" })
              }
            >
              Ghi Đè
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const useStyle = makeStyles(() => ({
  content: {
    minWidth: "300px",

    "& .div-img": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginBottom: "24px",
    },

    "& .message-text": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginBottom: "24px",
      fontSize: "15px",
      fontWeight: "600",
    },

    "& .div-button": {
      width: "100%",
      display: "flex",
      justifyContent: "center",

      "& .btn": { fontSize: "15px", fontWeight: "400", padding: "6px 18px" },
      "& .yes": {
        color: "white",
        border: "1px solid #3961A0",
        background: "#3961A0",
      },
      "& .no": {
        color: "#3961A0",
        border: "1px solid #3961A0",
        marginRight: "12px",
      },
    },
  },
}));
