import api from "../configs/api";

const getBenefitCode = (data) =>
  api
    .apiForm("POST", "/api/v2/benefit/list", data)
    .then((res) => res)
    .catch((error) => error);

const createBenefitCode = (data) =>
  api
    .apiForm("POST", "/api/v2/benefit", data)
    .then((res) => res)
    .catch((error) => error);

const updateBenefitCode = (data) =>
  api
    .apiForm("PUT", "/api/v2/benefit", data)
    .then((res) => res)
    .catch((error) => error);

export { getBenefitCode, createBenefitCode, updateBenefitCode };
