import { IconButton, TableCell } from "@mui/material";
import moment from "moment";

import showIcon from "../../../../../assets/health/show-icon.svg";
import editReviewer from "../../../../../assets/health/edit-reviewer.svg";
import dontShowIcon from "../../../../../assets/health/dontShowIcon.svg";
import { getStatusById } from "../utils";
import { get, isEmpty } from "lodash";

const columns = [
  {
    label: "STT",
    headerClass: "stt",
    key: "name",
    renderCell: ({ rowIdx }) => (
      <TableCell className="stt">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "Họ và tên",
    headerClass: "full-name",
    renderCell: ({ item }) => (
      <TableCell className="full-name">
        {item?.insuredInfo?.insuredName || ""}
      </TableCell>
    ),
  },
  {
    label: "Mã HS bồi thường",
    headerClass: "code",
    renderCell: ({ item }) => (
      <TableCell className="code">{item.code}</TableCell>
    ),
  },
  {
    label: "Ngày tạo hồ sơ",
    renderCell: ({ item, data }) => {
      let check = false;
      if (item?.statusId == 2) {
        const dayItem = item?.createdDate;
        check = moment().diff(moment(dayItem), "minutes") > 719;
      }
      if ([3, 4].includes(item?.statusId)) {
        const updatedDate = item?.updatedDate;
        check = moment().diff(moment(updatedDate), "minutes") > 1439;
      }

      return (
        <TableCell
          style={{ color: check ? "red" : "" }}
          title={
            item.createdDate
              ? moment(item.createdDate).format("HH:mm DD/MM/YYYY")
              : ""
          }
        >
          {item.createdDate
            ? moment(item.createdDate).format("DD/MM/YYYY")
            : ""}
        </TableCell>
      );
    },
  },
  {
    label: "Ngày cập nhật",
    renderCell: ({ item }) => (
      <TableCell
        title={
          item.updatedDate
            ? moment(item.updatedDate).format("HH:mm DD/MM/YYYY")
            : ""
        }
      >
        {item.updatedDate
          ? moment(item.updatedDate).format("DD/MM/YYYY")
          : "--"}
      </TableCell>
    ),
  },
  {
    label: "Số tiền yêu cầu",
    renderCell: ({ item }) => (
      <TableCell>
        {`${item.requestAmount || "0"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          " VNĐ"}
      </TableCell>
    ),
  },
  {
    label: "Số tiền đã duyệt",
    renderCell: ({ item }) => (
      <TableCell>
        {[5, 6, 8, 9].includes(item?.statusId)
          ? `${item.amount || "-"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            " VNĐ"
          : "- VNĐ"}
      </TableCell>
    ),
  },
  {
    label: "Trạng thái",
    renderCell: ({ item, data }) => (
      <TableCell
        style={{
          fontWeight: "500",
          color:
            data.role_type == "claim_report"
              ? getStatusById(item.statusId).colorReport
              : getStatusById(item.statusId).color,
        }}
      >
        {data.role_type == "claim_report"
          ? getStatusById(item.statusId).labelReport
          : getStatusById(item.statusId).label}
      </TableCell>
    ),
  },
  {
    label: "Nhân sự xử lý hồ sơ",
    renderCell: ({ item, data, event, rowIdx }) => (
      <TableCell className="reviewer">
        <div>
          <span style={{ marginRight: "12px" }}>
            {item.userFullnameReview || ""}
          </span>
          {data.level_id != 1 &&
            data.role_type != "claim_report" &&
            data?.users?.extra_info?.user_title_id == 1 && (
              <IconButton
                onClick={event("openDialogUpdateReveiwer", { item, rowIdx })}
              >
                <img src={editReviewer} />
              </IconButton>
            )}
        </div>
      </TableCell>
    ),
  },
  {
    label: "Nhân sự phê duyệt",
    renderCell: ({ item, data, rowIdx, event }) => (
      // <TableCell>{item.userFullnameAssign || ""}</TableCell>
      <TableCell className="reviewer">
        <div>
          <span style={{ marginRight: "12px" }}>
            {item.userFullnameAssign || ""}
          </span>
          {data.level_id != 1 &&
            data.role_type != "claim_report" &&
            item.userFullnameAssign &&
            data?.users?.extra_info?.user_title_id == 1 && (
              <IconButton
                onClick={event("openDialogUpdateReveiwer", {
                  item,
                  rowIdx,
                  isAssignApprover: true,
                })}
              >
                <img src={editReviewer} />
              </IconButton>
            )}
        </div>
      </TableCell>
    ),
  },
  {
    label: "Xem hồ sơ",
    headerClass: "view-records stickey",
    renderCell: ({ item, data, event }) => {
      return (
        <TableCell className="view-records stickey">
          <IconButton
            disabled={
              data?.users?.role_management_claim?.is_root == 1 ||
              data?.users?.extra_info?.userType == "globalcare"
                ? false
                : data.role_type == "claim_report"
                ? [2, 3, 4].includes(item.statusId)
                : data.level_id == 1
                ? data?.user_id != item?.userIdReview
                : data?.user_id != item?.userIdAssign
            }
            // disabled={
            //   data.role_type == "claim_report"
            //     ? [2, 3, 4].includes(item.statusId)
            //     : data.level_id == 1
            //     ? data?.user_id != item?.userIdReview
            //     : data?.user_id != item?.userIdAssign
            // }
            onClick={event("onClickShowDetail", { item })}
          >
            <img
              src={
                data?.users?.role_management_claim?.is_root == 1 ||
                data?.users?.extra_info?.userType == "globalcare"
                  ? showIcon
                  : data.role_type == "claim_report"
                  ? [2, 3, 4].includes(item.statusId)
                    ? dontShowIcon
                    : showIcon
                  : (+data.level_id == 1 &&
                      data?.user_id == item?.userIdReview) ||
                    (+data.level_id != 1 && data?.user_id == item?.userIdAssign)
                  ? showIcon
                  : dontShowIcon
              }
            />
          </IconButton>
        </TableCell>
      );
    },
  },
];

export default columns;
