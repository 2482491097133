import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from "@mui/material";
import React from "react";
import NumberFormat from "react-number-format";
import { useStyles } from "../useStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditInfo(props) {
  const { open, dispatch, state, title } = props;
  const { dataEdit, saving, addNewGroup } = state;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"sm"}
      onClose={() => dispatch({ type: "onClose", name: title })}
      className={classes.editDialog}
    >
      <DialogTitle>
        {title === "add" ? "Thêm nhóm quyền lợi" : "Thông tin gói bảo hiểm"}
      </DialogTitle>
      <DialogContent>
        <div className="title">Tên nhóm quyền lợi</div>
        <TextField
          fullWidth
          placeholder="Nhập thông tin"
          value={(title === "add" ? addNewGroup?.title : dataEdit?.title) || ""}
          onChange={(e) =>
            dispatch({
              type: "onChange",
              name: "title",
              value: e.target.value,
              title,
            })
          }
        />
        <div className="title">Phí bảo hiểm</div>
        <NumberFormat
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          onChange={(e) => {
            dispatch({
              type: "changeFee",
              name: "fees",
              value: +e.target.value.replace(/\./g, ""),
              title,
            });
          }}
          value={(title === "add" ? addNewGroup?.fees : dataEdit?.fees) || ""}
          placeholder="Nhập thông tin"
          fullWidth
        />
        <div className="title">Tổng quyền lợi</div>
        <NumberFormat
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          onChange={(e) => {
            dispatch({
              type: "changeFee",
              name: "product_amount",
              value: +e.target.value.replace(/\./g, ""),
              title,
            });
          }}
          value={
            (title === "add"
              ? addNewGroup?.product_amount
              : dataEdit?.product_amount) || ""
          }
          placeholder="Nhập thông tin"
          fullWidth
        />
        <div className="center">
          <Button
            className="button"
            disabled={saving}
            onClick={() =>
              dispatch(title === "add" ? "createPackage" : "updateInfoPackage")
            }
          >
            {saving ? "Đang lưu.." : `${title === "add" ? "Thêm" : "Cập nhật"}`}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
