import React, { useEffect, useRef } from "react";
import _ from "lodash";
import {
  Dialog,
  DialogContent,
  Button,
  Slide,
  IconButton,
} from "@mui/material";

import styleClaim from "./styleClaim";
import LoadingMui from "../../../../../../ui/LoadingMui";
import search from "../../.../../../../../../assets/health/search.svg";
import { TextField, CheckBox } from "../../overrideMui";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogCodeBenefit(props) {
  const { state, dispatch } = props;
  const classes = styleClaim();
  const ref = useRef();

  const { loading, dialogCodeBenefit, listBenefits, selectedBenefits } = state;

  // const onScroll = () => {
  //   if (ref.current) {
  //     const scrollDiv = ref.current;

  //     const lastItem = scrollDiv.querySelector(
  //       "#divScroll .scroll-item:last-child"
  //     );
  //     const { offsetTop, offsetHeight } = lastItem;
  //     if (
  //       Math.abs(offsetTop - scrollDiv.scrollTop) <
  //       offsetHeight + scrollDiv.offsetHeight
  //     ) {
  //       dispatch("getListBenefits");
  //     }
  //   }
  // };

  // useEffect(() => {
  //   listBenefits.length == 0 && dispatch("getListBenefitsInittal");
  // }, []);

  return (
    <Dialog
      open={dialogCodeBenefit.open}
      TransitionComponent={Transition}
      maxWidth={"2000px"}
      // fullScreen
      onClose={() => dispatch("closeDialogCodeBenefits")}
      className={classes.dialog}
    >
      <DialogContent style={{ padding: "0 12px 16px 12px" }}>
        <div>
          <div className={classes.clontainerDialogCodeBenefit}>
            <TextField
              className={classes.textField}
              fullWidth
              placeholder="Tìm kiếm"
              onChange={(e) =>
                dispatch({
                  type: "searchListBenefits",
                  value: e.target.value,
                })
              }
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <img src={search} />
                  </IconButton>
                ),
              }}
            />
            <div
              className="box-benefits"
              id="divScroll"
              ref={ref}
              // onScroll={_.debounce(onScroll, 500)}
            >
              {listBenefits.map((item, index) => (
                <div
                  key={index}
                  className={`scroll-item ${classes.rowDialogCodeBenefit}`}
                >
                  <div style={{ width: "110px", display: "flex" }}>
                    <CheckBox
                      className="checkBox"
                      checked={
                        item.code_benefit === selectedBenefits?.code_benefit
                      }
                      onChange={(e) =>
                        dispatch({
                          type: "selectedBenefits",
                          item,
                          value: e.target.checked,
                        })
                      }
                    />
                    <span className="code">{item.code_benefit || ""}</span>
                  </div>
                  <div className="title-benefit">{item.title || ""}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.buttonsBenefit}>
            <Button
              disabled={!selectedBenefits?.code_benefit}
              className={
                !selectedBenefits?.code_benefit ? "disabled buttons" : "buttons"
              }
              onClick={() => dispatch("applyBenefitsIntoTable")}
            >
              Áp dụng
            </Button>
          </div>
          <LoadingMui loading={loading} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
