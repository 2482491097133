import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { message } from "antd";
import * as Yup from "yup";
import { Box, Button, Container, TextField, Typography } from "@mui/material";

import LoginLayout from "../login/login-layout";
import { updatePWD_v2 } from "../../../../apis/authApis";
import * as PATH from "../../../../configs/routesConfig";

import iconPhone from "../../../../assets/icons/iconPhone.svg";

const ChangePassword = () => {
  const history = useHistory();
  const embededFiNa = localStorage.getItem("embeded") == "fina.claim.admin";
  const searchKey = embededFiNa ? "?embeded=fina.claim.admin" : "";

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().max(255).required("Vui lòng nhập thông tin"),
      newPassword: Yup.string()
        .min(8, "Vui lòng nhập ít nhất 8 ký tự")
        .max(255)
        .required("Vui lòng nhập thông tin"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        "Mật khẩu không khớp"
      ),
    }),

    onSubmit: (values) => {
      updatePWD_v2(values)
        .then((v) => {
          if (v.data.statusCode == 200) {
            message.success({
              content: v.data.message,
              duration: 5,
            });
            history.push(PATH.ADMIN_V2_LOGIN + searchKey);
          } else {
            message.error({
              content: v.data.message,
              duration: 5,
            });
          }
          formik.setSubmitting(false);
        })
        .catch((e) => {
          message.error({
            content: "Mật khẩu trước đó không đúng.",
            duration: 5,
          });
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexFlow: "column",
          flexGrow: 1,
          minHeight: "100%",
          background: "#FAFAFA",
        }}
      >
        <Container maxWidth="sm" sx={{ marginTop: "auto" }}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 3 }}>
              <Typography
                fontWeight="fontWeightBold"
                color="textPrimary"
                variant="h4"
              >
                Đổi mật khẩu
              </Typography>
            </Box>
            <Box sx={{ mt: 3, mb: 2 }}>
              <Typography>
                Vui lòng đăng nhập bằng mật khẩu hiện tại và tiến hành đổi{" "}
                <br />
                mật khẩu mới.
              </Typography>
            </Box>
            <TextField
              error={Boolean(
                formik.touched.oldPassword && formik.errors.oldPassword
              )}
              fullWidth
              helperText={
                formik.touched.oldPassword && formik.errors.oldPassword
              }
              label="Mật khẩu hiện tại"
              margin="normal"
              name="oldPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.oldPassword}
              variant="outlined"
              focused
            />
            <TextField
              error={Boolean(
                formik.touched.newPassword && formik.errors.newPassword
              )}
              fullWidth
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              label="Mật khẩu mới"
              margin="normal"
              name="newPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.newPassword}
              variant="outlined"
              focused
            />
            <TextField
              error={Boolean(
                formik.touched.confirmPassword && formik.errors.confirmPassword
              )}
              fullWidth
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              label="Xác nhận mật khẩu"
              margin="normal"
              name="confirmPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              variant="outlined"
              focused
            />
            <Box sx={{ py: 2, mt: 5 }}>
              <Button
                color="gc"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Tiếp tục
              </Button>
            </Box>
          </form>
        </Container>
        <Box textAlign="center" mt="auto" mx="auto" pb={3}>
          <Typography sx={{ color: "#1E6BE5" }} mb={1}>
            <img src={iconPhone} />
            Hotline: {embededFiNa ? "(024) 6276 5555" : "1900 2929 87"}
          </Typography>
          {!embededFiNa && (
            <Typography>Một sản phẩm công nghệ của Global Care</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

const ChangePasswordPage = () => (
  <LoginLayout>
    <ChangePassword />
  </LoginLayout>
);

export default ChangePasswordPage;
