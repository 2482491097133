import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    margin: "55px 16px 16px",
    padding: "20px",
    "& .header": {
      color: "#2A3790",
      fontWeight: "600",
      fontSize: 18,
      marginBottom: "20px",
    },
    "& .wrapper": {
      width: "40%",
      display: "flex",
      alignItems: "center",
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .search": {
      justifyContent: "space-between",
      marginBottom: 20,
    },
    "& .button": {
      background: "#3961A0",
      borderRadius: 5,
      color: "#fff",
      padding: "4px 16px",
      height: 45,
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .w-80": {
      width: "80%",
      marginRight: 16,
    },
    "& .MuiOutlinedInput-root": {
      height: 45,
    },
  },

  create: {
    margin: "55px 0px 16px",
    padding: "20px",
    "& .info": {
      background: "#fff",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      height: "fit-content",
      padding: 15,
      minWidth: "250px",
      // marginRight: 20,
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
    },
    "& .center": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .box": {
      padding: "15px 10px",
      background: "#FFF",
      border: "1px solid #DCDCDC",
      boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "6px",
      alignItems: "center",
      justifyContent: "flex-start",
      minHeight: "120px",
      "&:hover": {
        cursor: "pointer",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
      },
    },
    "& .add-product": {
      color: "#0B7CE4",
      marginTop: 8,
    },
    "& .name-product": {
      color: "#3961A0",
      textAlign: "center",
      fontWeight: 600,
      fontSize: 18,
      marginBottom: 5,
    },
    "& .tax-code": {
      color: "#4B4B4B",
      fontWeight: 600,
      marginBottom: 10,
    },
    "& .bold": {
      fontSize: 17,
      color: "#4B4B4B",
      fontWeight: 600,
    },
    "& .value": {
      color: "#4B4B4B",
      fontWeight: 600,
      marginLeft: 10,
    },
    "& .border": {
      border: "0.5px dashed rgba(0, 0, 0, 0.25)",
    },
    "& .logo": {
      width: "55px",
      height: "55px",
      objectFit: "contain",
      marginRight: 8,
      borderRadius: "50%",
    },
    "& .logo-product": {
      width: "125px",
      height: "125px",
      objectFit: "contain",
      marginBottom: 15,
      borderRadius: "50%",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
    },
    "& .title": {
      fontWeight: 700,
      color: "#4B4B4B",
    },
    "& .date": {
      fontSize: 15,
      color: "#4B4B4B",
    },
    "& .button": {
      background: "#3961A0",
      borderRadius: 5,
      color: "#fff",
      padding: "8px 16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .data": {
      justifyContent: "space-between",
      marginTop: 15,
    },
    "& .create-date": {
      textAlign: "center",
      marginTop: 15,
    },
    "& .nowrap": {
      whiteSpace: "nowrap",
    },
    "& .break-word": {
      wordBreak: "break-word",
    },
    "& .MuiGrid-root": {
      height: "fit-content",
    },
  },

  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
    },
    "& .MuiDialogContent-root": {
      padding: "0 20px 20px",
    },
    "& .MuiOutlinedInput-root": {
      height: 45,
    },
    "& .Mui-disabled": {
      background: "#E4E4E4 !important",
    },
    "& .center": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .logo": {
      width: "125px",
      height: "125px",
      objectFit: "contain",
      borderRadius: "50%",
      border: "0.5px dashed rgba(0, 0, 0, 0.25)",
      "&:hover": {
        cursor: "pointer",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
      },
    },
    "& .progress": {
      width: "30%",
      maxWidth: "150px",
      marginTop: 5,
    },
    "& .button": {
      background: "#3961A0",
      color: "#fff",
      marginTop: 15,
      padding: "10px 40px",
    },
    "& .title": {
      color: "#4B4B4B",
      margin: "12px 0 5px",
    },
    "& .red-text": {
      color: "red",
      fontSize: 15,
      marginTop: 4,
      fontStyle: "italic",
    },
  },
  divTable: {
    "& .center": {
      textAlign: "center",
    },
    "& .logo": {
      width: "55px",
      height: "55px",
      objectFit: "contain",
      borderRadius: "50%",
      border: "0.5px dashed rgba(0, 0, 0, 0.25)",
    },
  },
  cellRow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  footer: {
    marginTop: 10,
  },

  package: {
    marginTop: "55px",
    padding: "16px 0",
    "& .container": {
      display: "flex",
      flexDirection: "row",
      margin: 16,
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .group": {
      padding: "12px 0",
      marginRight: 16,
      minWidth: "200px",
    },
    "& .group-title": {
      color: "#4B4B4B",
      fontWeight: 600,
      textAlign: "center",
      padding: "8px 16px",
      marginBottom: "5px",
      "&:hover": {
        cursor: "pointer",
        background: "rgba(89, 136, 255, 0.06)",
      },
    },
    "& .info": {
      marginRight: 16,
      background: "#fff",
      padding: 20,
      borderRadius: 8,
      flexGrow: 1,
    },
    "& .center": {
      textAlign: "center",
    },
    "& .name": {
      fontSize: 18,
      fontWeight: 700,
      marginTop: 10,
    },
    "& .title": {
      color: "#4B4B4B",
      fontWeight: 600,
    },
    "& .color": {
      color: "#3961A0",
      fontWeight: 500,
    },
    "& .fee": {
      background: "#3961A0",
      color: "#fff",
      padding: 15,
      borderRadius: 8,
    },
    "& .fee-text": {
      alignItems: "center",
      margin: "5px 0",
    },
    "& .ml-10": {
      marginLeft: 10,
    },
    "& .selected": {
      background: "#fff",
      padding: "8px 16px",
      margin: "8px 0",
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .select": {
      color: "#4B4B4B",
      fontWeight: 500,
      padding: "8px 16px",
      margin: "8px 0",
      "&:hover": {
        cursor: "pointer",
        background: "#fff",
      },
    },
    "& .bg-gray": {
      margin: "5px 0 20px",
      padding: 10,
      background: "#F8F8F8",
    },
    "& .logo": {
      width: "60px",
      height: "60px",
      objectFit: "contain",
      borderRadius: "50%",
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
    },
  },
  editDialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
    },
    "& .MuiDialogContent-root": {
      padding: "0 20px 20px",
    },
    "& .MuiOutlinedInput-root": {
      height: 45,
    },
    "& .Mui-disabled": {
      background: "#E4E4E4 !important",
    },
    "& .title": {
      color: "#4B4B4B",
      margin: "12px 0 8px",
    },
    "& .center": {
      textAlign: "center",
    },
    "& .button": {
      background: "#3961A0",
      color: "#fff",
      marginTop: 20,
      padding: "10px 40px",
    },
  },
  headerBar: {
    width: "100%",
    color: "#4B4B4B",
    fontSize: "15px",
    padding: "12px 0 12px 16px",
    background: "white",
    margin: "0 4px",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
    position: "sticky",
    top: "64px",
    zIndex: 2,
  },
}));
