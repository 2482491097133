import moment from "moment";
import React from "react";
import Certificate from "../components/DialogCertificate";
import { useStyles } from "../styles/stepperStyles";

import Logo from "../../../../../assets/images/LOGO-GC.png";
import emailIcon from "../../../../../assets/loyalty/email-icon.svg";
import phoneIcon from "../../../../../assets/loyalty/phone-icon.svg";
import received from "../../../../../assets/loyalty/received.png";
import warring from "../../../../../assets/loyalty/waring.png";
import Wait from "../components/DialogWait";

export default function Success(props) {
  const { state, dispatch, isApp } = props;
  const classes = useStyles();

  const { claimInfo, statusNotify, openCertificate, openDialogNoCerLink } =
    state;
  const date = moment(claimInfo?.endDate).format("DD/MM/YYYY, HH:mm");

  return (
    <div
      style={{
        minHeight: isApp ? "calc(100vh - 165px)" : "calc(100vh - 125px)",
      }}
    >
      <div style={{ background: "white" }}>
        <div className={classes.boxSuccess}>
          <div className="logo">
            <img src={Logo} alt="logo" width={130} />
          </div>
          {statusNotify === "success" ? (
            <div className="boxText">
              <span className="line_1">
                Global Care đã nhận được khiếu nại của bạn
              </span>
              <span className="line_2">Chúng tôi sẽ phản hồi sớm nhất</span>
            </div>
          ) : (
            <div className="boxInfor">
              <span className="line1">
                Cổng hỗ trợ khách hàng từ Global Care
              </span>
              <span className="line2">Mã đơn hàng: {claimInfo?.orderCode}</span>
              <span>Hoàn thành dự kiến vào {date.replace(/,/g, "")}</span>
              <span onClick={() => dispatch("openLink")} className="line3">
                Xem giấy chứng nhận bảo hiểm
              </span>
            </div>
          )}
        </div>
        <div
          style={{
            paddingBottom: "8px",
            paddingTop: statusNotify === "success" ? "60px" : "0px",
          }}
        >
          <img
            src={statusNotify === "success" ? received : warring}
            alt="warring"
            width={"100%"}
          />
        </div>

        {statusNotify !== "success" && (
          <div
            style={{
              width: "100%",
              padding: "0 16px 10px",
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            Khiếu nại chỉ có thể được tiếp nhận trong 7 ngày từ thời điểm hoàn
            thành đơn hàng
          </div>
        )}
      </div>
      <div className={classes.spacing} style={{ height: 10 }}></div>
      <div className={classes.divFooter}>
        <div className="title">Mọi thắc mắc xin vui lòng liên hệ</div>
        <div className="infor">
          <div
            className="phone"
            onClick={() => {
              window.open("tel:1900292987", "_self");
            }}
          >
            <img src={phoneIcon} alt="p" style={{ marginRight: "6px" }} />
            1900 29 29 87
          </div>
          <div
            className="email"
            onClick={() => {
              window.location.assign("mailto:cskh@globalcare.vn");
            }}
          >
            <img src={emailIcon} alt="e" style={{ marginRight: "6px" }} />
            cskh@globalcare.vn
          </div>
        </div>
      </div>
      <Certificate
        open={openCertificate}
        link={claimInfo?.certLink}
        onClose={() => dispatch("closeCertificate")}
      />
      <Wait
        open={Boolean(openDialogNoCerLink)}
        onClose={() => dispatch("closeDialogNoCerLink")}
      />
    </div>
  );
}
