//claimComponentReducer

import _ from "lodash";
import { pdf } from "@react-pdf/renderer";

import {
  getDetailClaim,
  checkLicenseClaim,
  uploadImg,
  getListDisease,
  getListMedicine,
  addNote,
  OCR,
  mappingBenefit,
  getListDiseaseOCR,
  getDetailInsurance,
} from "../../../../../apis/health";
import { getListLicenseById } from "../utils";
import ListImgPDF from "../components/ListImgPDF";
import moment from "moment";
import { converDate } from "../utils";
import { removeUnicode, compare } from "../../../../../utils/utils";

const checkErrorTable = ({
  rowsExamination = [],
  rowsMedicines = [],
  rowsOtherBenefits = [],
}) => {
  const err = [];

  for (let i = 0; i < rowsExamination.length; i++) {
    if (_.isEmpty(rowsExamination[i]?.codeBenefit)) {
      err.push("medical");
      break;
    }
  }

  for (let i = 0; i < rowsMedicines.length; i++) {
    if (_.isEmpty(rowsMedicines[i]?.codeBenefit)) {
      err.push("prescription");
      break;
    }
  }

  for (let i = 0; i < rowsOtherBenefits.length; i++) {
    if (_.isEmpty(rowsOtherBenefits[i]?.codeBenefit)) {
      err.push("benefits");
      break;
    }
  }

  return err;
};

const getTotalFees = ({
  rowsExamination,
  rowsMedicines,
  rowsOtherBenefits,
  rowsBills,
  rowsOtherTables,
}) => {
  let examinationFeesTotal = 0;
  let medicineFeesTotal = 0;
  let othersTotal = 0;
  let billsTotal = 0;
  let otherTablesTotal = 0;
  rowsExamination.forEach((i) => {
    examinationFeesTotal =
      examinationFeesTotal + +(`${i?.fee}`?.replace(/\./g, "") || 0);
  });
  rowsBills.forEach((i) => {
    billsTotal = billsTotal + +(`${i?.fee}`?.replace(/\./g, "") || 0);
  });
  rowsMedicines.forEach((i) => {
    medicineFeesTotal =
      medicineFeesTotal + +(`${i?.fee}`?.replace(/\./g, "") || 0);
  });
  rowsOtherBenefits.forEach((i) => {
    othersTotal = othersTotal + +(`${i?.fee}`?.replace(/\./g, "") || 0);
  });
  rowsOtherTables.forEach((i) => {
    otherTablesTotal =
      otherTablesTotal + +(`${i?.fee}`?.replace(/\./g, "") || 0);
  });

  const finalFeesTotal =
    examinationFeesTotal +
    medicineFeesTotal +
    othersTotal +
    billsTotal +
    otherTablesTotal;

  return {
    examinationFeesTotal,
    billsTotal,
    medicineFeesTotal,
    othersTotal,
    finalFeesTotal,
    otherTablesTotal,
  };
};

const delay = (t) => {
  return new Promise((resole) => {
    setTimeout(() => {
      resole("done");
    }, t || 600);
  });
};

const convertNumber = (string, type = null) => {
  const newString = string.toString().replace(/\s+/g, "");

  const temp = newString?.match(/\.(\d+)$/);

  if (temp) {
    const isDecimal = +temp[1] > 0;
    const check = temp[1] == "0" || temp[1] == "00";

    if (isDecimal && type === "amount") {
      return newString.replace(/\.(?=.*\.)/g, "").replace(/0+$/, "");
    }

    return check
      ? newString.replace(/(\.)?0+(?!\d)+$/g, "").replace(/\./g, "")
      : newString.replace(/\./g, "");
  }

  return newString;
};

export default function claimComponentReducer({
  state,
  dispatch,
  action,
  id,
  level_id,
  systemType,
  transData,
  rowsInsuranceBenefit,
  dialogInsuranceInfo,
}) {
  const cases = {
    //------>

    inittailClaim: () => {
      const { detail } = action;
      const res = {};
      let fees = {};
      res.listBenefits = [...rowsInsuranceBenefit];
      res.dataBenefits = [...rowsInsuranceBenefit];
      res.detail = detail;
      res.rowsBeneficiary =
        res.detail?.insuredInfo instanceof Object
          ? [detail.insuredInfo]
          : detail.insuredInfo || [];
      res.insuredPersonInformation = {
        ...detail.insuredInfo,
        ...detail.extraInfo,
        merchantTitle:
          detail?.extraInfo?.merchantTitle || detail?.merchantTitle || "",
      };
      res.rowsDiagnosis = detail?.extraInfo?.doctorDiagnoses || [];
      res.rowsExamination = detail?.extraInfo?.examinationFees || [];
      res.rowsBills = detail?.extraInfo?.bills || [];
      res.rowsMedicines = detail?.extraInfo?.medicines || [];
      res.rowsOtherBenefits = detail?.extraInfo?.others || [];
      switch (detail?.extraInfo?.typeOfIndimnity) {
        case 3:
          res.rowsOtherTables = (detail?.extraInfo?.injuries || []).map(
            (i) => ({
              ...i,
              otherText: i.injuries || "",
            })
          );
          break;
        case 5:
          res.rowsOtherTables = (
            detail?.extraInfo?.canceOrFatalDiseaseOrStroke || []
          ).map((i) => ({
            ...i,
            otherText: i.diseaseType || "",
          }));
          break;
        case 6:
          res.rowsOtherTables = (detail?.extraInfo?.deaths || []).map((i) => ({
            ...i,
            otherText: i.reasonOfDeath || "",
          }));
          break;

        default:
          break;
      }

      fees = getTotalFees({
        rowsExamination: res.rowsExamination || [],
        rowsBills: res.rowsBills || [],
        rowsMedicines: res.rowsMedicines || [],
        rowsOtherBenefits: res.rowsOtherBenefits || [],
        rowsOtherTables: res.rowsOtherTables || [],
      });

      const temp = [];
      detail?.elements?.forEach((i) => {
        if (i?.extra_info?.documents?.length > 0 && i.type_code !== "sign") {
          // const listImg = i?.extra_info?.documents.map((e) => e.path);
          i?.extra_info?.documents.forEach((doc) => {
            doc?.path && temp.push(doc.path);
          });
        }
        return;
      });
      res.imgLicenseShow = { list: temp, path: temp[0] || "", indexImg: 1 };

      return { ...res, ...fees };
    },

    // getDetailInsurance: async () => {
    //   const { detail } = state;
    //   const claimId = _.get(detail, "elements[0].claim_id") || "";

    //   const dataApi = await getDetailInsurance(systemType, claimId);

    //   const res = {};

    //   if (dataApi?.data?.statusCode === 200) {
    //     res.detailInsurance = _.get(dataApi, "data.result") || [];
    //   }

    //   return res;
    // },

    //* run useEffect file Claim to check data PDF from elements
    checkDataElements: async () => {
      const { detail, progressLoading } = state;

      const claimId = _.get(detail, "elements[0].claim_id") || "";
      const elements = detail?.elements;
      const listCdnPDF = [];
      const res = {};
      dispatch({ type: "progressLoading", value: 20, open: true });

      const listImg = getListLicenseById({
        typeOfRisk: detail?.extraInfo?.typeOfRisk,
        typeOfTreatment: [7, 8, 9, 10, 11].includes(
          detail.extraInfo?.typeOfRisk
        )
          ? detail.extraInfo?.typeOfIndimnity || null
          : detail.extraInfo?.typeOfTreatment || null,
      });

      for (let i = 0; i < listImg.length; i++) {
        const itemElementsDetail = elements?.find(
          (e) => listImg[i].typeCode === e.type_code
        );

        if (
          _.isEmpty(itemElementsDetail) ||
          (itemElementsDetail?.extra_info?.documents || [])?.length < 2 ||
          !_.isEmpty(itemElementsDetail?.extra_info?.documents_pdf)
        )
          continue;

        const dataListimgPdf = itemElementsDetail?.extra_info?.documents
          ?.filter((j) => j?.path)
          ?.map((m) => m.path);

        const blobPdf = await new pdf(
          ListImgPDF({ data: dataListimgPdf })
        ).toBlob();

        if (!blobPdf) continue;
        const myFile = new File([blobPdf], `documents_pdf.pdf`);
        const data = new FormData();
        data.append("document", myFile);
        const cdnPdfApi = await uploadImg(data);

        if (cdnPdfApi instanceof Error) continue;

        listCdnPDF.push({
          ...itemElementsDetail,
          documents_pdf: {
            path: _.get(cdnPdfApi, "data.result[0].path") || null,
            size: _.get(cdnPdfApi, "data.result[0].size") || null,
          },
        });
      }

      if (listCdnPDF.length > 0) {
        const elementsParams = listCdnPDF.map((i) => ({
          id: i.id,
          claimId: i.claim_id,
          extraInfo: {
            documents_pdf: i.documents_pdf,
          },
        }));
        const params = { claimId, elements: elementsParams };
        const dataApi = await addNote(systemType, params);

        if (dataApi instanceof Error) {
          return { progressLoading: { ...progressLoading, open: false } };
        }
      }

      dispatch({ type: "progressLoading", value: 40, open: true });
      dispatch("OCR");
    },

    //* ORC
    OCR: async () => {
      const {
        detail,
        insuredPersonInformation,
        rowsExamination,
        rowsMedicines,
        rowsDiagnosis,
        rowsBills,
      } = state;
      const claimId = _.get(detail, "elements[0].claim_id") || "";

      const [claimDetailApi, OcrApi] = await Promise.all([
        getDetailClaim(systemType, id),
        // OCR(systemType, { claimId }),
        () => {
          return {};
        },
      ]);

      dispatch({ type: "progressLoading", value: 60, open: true });
      const res = {};

      if (claimDetailApi instanceof Error) {
        dispatch({ type: "progressLoading", value: 60, open: false });
        return {
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
        };
      }

      if (claimDetailApi?.data?.statusCode === 200) {
        const tempClaimDetailApi = _.get(claimDetailApi, "data.result") || {};
        res.detail = tempClaimDetailApi;
        transData({
          detail: tempClaimDetailApi,
        });
      }

      const dataOcrApi = _.get(OcrApi, "data.result") || [];
      let tempOcrApi = _.get(OcrApi, "data.result") || [];

      if (tempOcrApi.length === 0) {
        const elementOrc = (res?.detail?.elements || []).filter(
          (i) => !_.isEmpty(i?.extra_info?.ocr) || i?.extra_info?.ocr === ""
        );
        tempOcrApi = [...elementOrc];
      }

      const elementsOcr = [];
      tempOcrApi.forEach((i) => {
        const itemElementDetail = res?.detail?.elements?.find(
          (e) => e.type_code === i.type_code
        );

        if (
          !_.isEmpty(itemElementDetail) &&
          itemElementDetail?.extra_info?.ocr == undefined
        ) {
          _.set(itemElementDetail, "extra_info.ocr", i.extra_info.ocr);

          elementsOcr.push({
            id: itemElementDetail.id,
            claimId: itemElementDetail.claim_id,
            extraInfo: {
              ocr: i.extra_info.ocr,
            },
          });
        }
      });

      // if (elementsOcr?.length > 0) {
      //   const paramsUpdate = { claimId, elements: elementsOcr };
      //   await addNote(systemType, paramsUpdate);
      // }

      const ocrPrescription = tempOcrApi?.find(
        (i) => i.type_code == "prescription"
      );

      const ocrExamination_cost_bill = tempOcrApi?.find(
        (i) => i.type_code == "examination_cost_bill"
      );

      const ocrTreatment_cost_checkout = tempOcrApi?.find(
        (i) => i.type_code == "treatment_cost_checkout"
      );

      const ocrMedicine_bill = tempOcrApi?.find(
        (i) => i.type_code == "medicine_bill"
      );

      const ocrHospital_checkout = tempOcrApi?.find(
        (i) => i.type_code == "hospital_checkout"
      );

      const treatmentPlace = insuredPersonInformation.treatmentPlace
        ? insuredPersonInformation.treatmentPlace
        : _.get(ocrPrescription, "extra_info.ocr[0].info.medical_facility") ||
          _.get(ocrMedicine_bill, "extra_info.ocr[0].info.supplier") ||
          _.get(ocrExamination_cost_bill, "extra_info.ocr[0].info.supplier") ||
          "";

      const dateOfHospital = insuredPersonInformation.dateOfHospital
        ? insuredPersonInformation.dateOfHospital
        : _.get(
            ocrHospital_checkout,
            "extra_info.ocr[0].info.hospitalization_date"
          ) || "";

      const dateOutOfHospital = insuredPersonInformation.dateOutOfHospital
        ? insuredPersonInformation.dateOutOfHospital
        : _.get(
            ocrHospital_checkout,
            "extra_info.ocr[0].info.hospital_discharge_date"
          ) || "";

      let timeInHospital = insuredPersonInformation?.timeInHospital || null;
      if (dateOfHospital && dateOutOfHospital) {
        timeInHospital = moment(converDate(dateOutOfHospital)).diff(
          moment(converDate(dateOfHospital)),
          "days"
        );
      }

      res.insuredPersonInformation = {
        ...insuredPersonInformation,
        treatmentPlace,
        dateOfHospital,
        dateOutOfHospital,
        timeInHospital,
      };

      if (
        (_.isEmpty(rowsDiagnosis) && !_.isEmpty(ocrPrescription)) ||
        !_.isEmpty(ocrPrescription?.extra_info?.ocr)
      ) {
        let tempOcrDiagnosis = [];
        const dataOcrDiagnosis = _.get(ocrPrescription, "extra_info.ocr") || [];

        for (let i = 0; i < dataOcrDiagnosis.length; i++) {
          const ListdiagnoseFromOcr =
            _.get(ocrPrescription, `extra_info.ocr[${i}].info.icd_10`) || [];

          if (ListdiagnoseFromOcr.length > 0) {
            const arrString = ListdiagnoseFromOcr?.map(
              (i) => i?.icd || null
            )?.filter((i) => i !== null);

            const listdiagnoseApi = await getListDiseaseOCR({
              limit: 100,
              offset: 0,
              codes: arrString,
            });
            dispatch({ type: "progressLoading", value: 80, open: true });
            if (listdiagnoseApi?.data?.statusCode == 200) {
              const temps = listdiagnoseApi?.data?.result || [];
              temps.forEach((i) =>
                tempOcrDiagnosis.push({
                  diseaseCode: i?.code || "",
                  diseaseName: i?.name || "",
                })
              );
            }
          } else {
            const stringDiagnose =
              _.get(ocrPrescription, `extra_info.ocr[${i}].info.diagnose`) ||
              "";
            const listDiagnose = stringDiagnose.split(/[;,]/);
            listDiagnose.forEach((i) =>
              tempOcrDiagnosis.push({
                diseaseCode: "",
                diseaseName: i || "",
              })
            );
          }
        }
        res.rowsDiagnosis = [...tempOcrDiagnosis];
      }

      if (
        _.isEmpty(rowsExamination) ||
        !_.isEmpty(ocrTreatment_cost_checkout?.extra_info?.ocr)
      ) {
        let orcExamination = [];
        const findFeeIndexOcr = (
          _.get(ocrTreatment_cost_checkout, "extra_info.ocr[0].json[0]") || []
        ).find((i) => i.value.toLowerCase().includes("thành tiền"));

        if (!_.isEmpty(ocrTreatment_cost_checkout)) {
          const checkTypeData =
            _.get(ocrTreatment_cost_checkout, "extra_info.ocr[0].json") || [];

          if (checkTypeData.length > 0) {
            (_.get(ocrTreatment_cost_checkout, "extra_info.ocr") || []).forEach(
              (i) => {
                const temp = i?.json || [];
                orcExamination = [...orcExamination, ...temp];
              }
            );
          } else {
            const temp =
              _.get(
                ocrTreatment_cost_checkout,
                "extra_info.ocr[0].info.table.info_table"
              ) || [];
            temp.forEach((i) => {
              orcExamination = [...orcExamination, ...i];
            });
          }
        }

        const rowExaminatio = [];

        orcExamination?.forEach((i, index) => {
          const tempTable = {};

          if (!_.isEmpty(ocrTreatment_cost_checkout)) {
            if (
              `${_.get(i, "[0].value") || ""}`.replace(/[()]/g, "").length <
                2 ||
              /^[a-zA-Z]+$/.test(
                removeUnicode(`${_.get(i, "[3].value") || ""}`).replace(
                  /\s+/g,
                  ""
                )
              )
            )
              return;

            tempTable.serviceName = _.get(i, "[0].value") || "";
            tempTable.price = _.get(i, "[3].value")
              ? convertNumber(_.get(i, "[3].value"))
              : "";
            tempTable.amount = _.get(i, "[2].value")
              ? convertNumber(_.get(i, "[2].value"), "amount")
              : "";

            tempTable.fee =
              findFeeIndexOcr > -1
                ? convertNumber(_.get(i, `[${findFeeIndexOcr}].value`))
                : _.get(i, "[6].value")
                ? convertNumber(_.get(i, "[3].value"))
                : "";

            !_.isEmpty(tempTable?.serviceName) && rowExaminatio.push(tempTable);
            return;
          }

          const indexExclude = i?.findIndex(
            (ex) => ex.label == "description" && +ex.value >= 0
          );
          if (index == 0 || index == indexExclude) return;

          i?.forEach((e) => {
            switch (e.label) {
              case "description":
                return (tempTable.serviceName = e?.value || "Null");
              case "unit_price":
                return (tempTable.price = convertNumber(e.value));
              case "quantity":
                return (tempTable.amount = convertNumber(e.value));
              case "amount_total":
                return (tempTable.fee = convertNumber(e.value));

              default:
                break;
            }
          });

          !_.isEmpty(tempTable) && rowExaminatio.push(tempTable);
        });

        res.rowsExamination = rowExaminatio || [];
        let fees = 0;
        rowExaminatio?.forEach((i) => {
          fees = fees + +`${i?.fee || "0"}`.replace(/\./g, "");
        });
        res.examinationFeesTotal = fees;
      }

      if (
        _.isEmpty(rowsBills) ||
        !_.isEmpty(ocrExamination_cost_bill?.extra_info?.ocr)
      ) {
        const orcExamination_cost_bill =
          _.get(ocrExamination_cost_bill, "extra_info.ocr[0].info.table") || [];

        const rowBills = [];

        orcExamination_cost_bill?.forEach((i, index) => {
          const tempTable = {};

          const indexExclude = i?.findIndex(
            (ex) => ex.label == "description" && +ex.value >= 0
          );
          if (index == 0 || index == indexExclude) return;

          i?.forEach((e) => {
            switch (e.label) {
              case "description":
                return (tempTable.serviceName = e?.value || "Null");
              case "unit_price":
                return (tempTable.price = convertNumber(e.value));
              case "quantity":
                return (tempTable.amount = convertNumber(e.value));
              case "amount_total":
                return (tempTable.fee = convertNumber(e.value));

              default:
                break;
            }
          });

          !_.isEmpty(tempTable) && rowBills.push(tempTable);
        });

        res.rowsBills = rowBills || [];
        let fees = 0;
        rowBills?.forEach((i) => {
          fees = fees + +`${i?.fee || "0"}`.replace(/\./g, "");
        });
        res.billsTotal = fees;
      }

      if (
        (_.isEmpty(rowsMedicines) && !_.isEmpty(ocrMedicine_bill)) ||
        !_.isEmpty(ocrMedicine_bill?.extra_info?.ocr)
      ) {
        const orcMedicine =
          _.get(ocrMedicine_bill, "extra_info.ocr[0].info.table") || [];

        const rowMedicine = [];

        orcMedicine?.forEach((i, index) => {
          const indexExclude = i?.findIndex(
            (ex) => ex.label == "description" && +ex.value >= 0
          );
          if (index == 0 || index == indexExclude) return;

          const tempTable = {};

          i?.forEach((e) => {
            switch (e.label) {
              case "description":
                return (tempTable.medicineName = e?.value || "Null");
              case "unit_price":
                return (tempTable.price =
                  e.value?.replace(/(\.)?(0)+$/g, "") || "");
              case "quantity":
                return (tempTable.amount =
                  e.value?.replace(/(\.)?(0)+$/g, "") || "");
              case "amount_before_tax":
              case "amount_total":
                return (tempTable.fee =
                  e.value?.replace(/(\.)?(0)+$/g, "") || "");

              default:
                break;
            }
          });

          !_.isEmpty(tempTable) && rowMedicine.push(tempTable);
        });

        res.rowsMedicines = rowMedicine;
        let fees = 0;
        rowMedicine.forEach((i) => {
          fees = fees + +(i?.fee || "0").replace(/\./g, "");
        });
        res.medicineFeesTotal = fees;
      }

      if (!_.isEmpty(res.rowsMedicines) || !_.isEmpty(res.rowsExamination)) {
        const paramsMappingBenefit = {
          productPackageId: res?.detail?.productPackageId,
          examinationFees: (res.rowsExamination || []).map((i) => ({
            serviceName: i.serviceName,
          })),
          medicines: (res.rowsMedicines || []).map((i) => ({
            medicineName: i.medicineName,
          })),
        };

        const mappingBenefitApi = await mappingBenefit(paramsMappingBenefit);
        dispatch({ type: "progressLoading", value: 95, open: true });
        const temp = _.get(mappingBenefitApi, "data.result") || {};
        res.rowsExamination =
          temp?.examinationFees?.length > 0
            ? (res.rowsExamination || []).map((i) => {
                const item =
                  temp?.examinationFees?.find(
                    (e) => e.serviceName === i.serviceName
                  ) || {};
                return { ...i, ...item };
              })
            : res.rowsExamination || [];

        res.rowsMedicines =
          temp?.medicines?.length > 0
            ? (res.rowsMedicines || []).map((i) => {
                const item =
                  temp?.medicines?.find(
                    (e) => e.medicineName === i.medicineName
                  ) || {};
                return { ...i, ...item };
              })
            : res.rowsMedicines || [];
      }

      dispatch({ type: "progressLoading", value: 100, open: true });
      const wait = await delay();
      dispatch({ type: "progressLoading", value: 0, open: false });

      return {
        ...res,
      };
    },

    getListDisease: async () => {
      const { payloadAutocomplete } = state;

      transData({ loading: true });

      const res = {};

      const apiListDisease = await getListDisease({
        limit: 20,
        offset: 0,
      });

      if (apiListDisease?.data?.statusCode === 200) {
        res.listNameDisease = apiListDisease?.data?.result?.data || [];
        res.payloadAutocomplete = {
          ...payloadAutocomplete,
          diagnosis: {
            page: 1,
            total: _.get(apiListDisease, "data.result.total"),
            params: {},
          },
        };
      }
      transData({ loading: false });
      return { ...res, loading: false };
    },

    getListMedicine: async () => {
      const { payloadAutocomplete } = state;

      transData({ loading: true });
      const res = {};

      const apiMedicine = await getListMedicine({
        limit: 20,
        offset: 0,
      });

      if (apiMedicine?.data?.statusCode === 200) {
        res.listNameMedicine = apiMedicine?.data?.result?.data || [];
        res.payloadAutocomplete = {
          ...payloadAutocomplete,
          prescription: {
            page: 1,
            total: _.get(apiMedicine, "data.result.total"),
            params: {},
          },
        };
      }

      transData({ loading: false });
      return { ...res, loading: false };
    },

    //* fucn to chang Expand to open or close the box from file Claim
    expanBox: () => {
      const { name } = action;
      return { [name]: !state[name] };
    },

    //* fucn to chang input of modal update from file Claim
    onChangTextTableUpdate: () => {
      const { name, value } = action;
      const { insuredPersonInformation } = state;
      insuredPersonInformation[name] = value;

      if (name == "typeOfRisk") {
        insuredPersonInformation.dateOfHospital = null;
        insuredPersonInformation.dateOutOfHospital = null;
      }

      if (name == "dateOfHospital") {
        insuredPersonInformation.dateOutOfHospital = null;
      }
      if (
        !!insuredPersonInformation.dateOutOfHospital &&
        !!insuredPersonInformation.dateOfHospital
      ) {
        const startDate = moment(
          insuredPersonInformation.dateOfHospital
        ).format("YYYY/MM/DD");
        const endDate = moment(
          insuredPersonInformation.dateOutOfHospital
        ).format("YYYY/MM/DD");
        insuredPersonInformation.timeInHospital =
          moment(endDate).diff(moment(startDate), "days") > 0
            ? moment(endDate).diff(moment(startDate), "days") + 1
            : 1;
      }

      return { insuredPersonInformation };
    },

    //* fucn to add row  of modal update from file Claim
    addRowsTableUpdate: () => {
      const {
        data: { name },
      } = action;
      const {
        rowsDiagnosis,
        rowsExamination,
        rowsMedicines,
        rowsOtherBenefits,
        rowsBills,
        rowsOtherTables,
      } = state;

      const newItem = {};

      name === "diagnosis" && rowsDiagnosis.push(newItem);
      name === "medical" && rowsExamination.push(newItem);
      name === "prescription" && rowsMedicines.push(newItem);
      name === "benefits" && rowsOtherBenefits.push(newItem);
      name === "bill" && rowsBills.push(newItem);
      name === "otherTable" && rowsOtherTables.push(newItem);

      return {
        rowsDiagnosis,
        rowsExamination,
        rowsMedicines,
        rowsOtherBenefits,
        rowsBills,
        rowsOtherTables,
      };
    },

    //* fucn to update data when submit button "Cập nhật"  of modal update from file Claim
    updateTable: () => {
      const { dialogUpdate } = state;

      return {
        dialogUpdate: {
          ...dialogUpdate,
          open: false,
        },
        oldRowsTable: [],
      };
    },

    //* func to open modal update from file Claim
    openDialogUpdata: () => {
      const { name } = action;
      const {
        dialogUpdate,
        rowsDiagnosis,
        rowsExamination,
        rowsMedicines,
        rowsBills,
        rowsOtherTables,
        insuredPersonInformation,
        detail,
      } = state;

      // dispatch("onLoading");
      // transData({ loading: true });
      let array = [];
      let keys = [];
      const res = {};
      if (["profileInformation", "beneficiaryInformation"].includes(name)) {
        res.oldData = { ...insuredPersonInformation };
      }
      if (name === "diagnosis") {
        array = [...rowsDiagnosis];
        keys = ["prescription"];
      }

      if (name === "medical") {
        array = [...rowsExamination];
        keys = ["treatment_cost_checkout"];
      }

      if (name === "bill") {
        array = [...rowsBills];
        keys = [
          "bill",
          "examination_cost_bill",
          "medicine_bill",
          "re-examination_bill",
        ];
      }

      if (name === "prescription") {
        array = [...rowsMedicines];
        keys = ["medicine"];
      }

      if (name === "otherTable") {
        array = [...rowsOtherTables];
        keys = ["prescription"];
      }
      const oldRowsTable = [...array];
      // transData({ loading: false });

      const temp = [];

      keys.forEach((i) => {
        const elementsByTypecode = detail?.elements.find(
          (el) => i == el.type_code
        );

        if (elementsByTypecode?.extra_info?.documents?.length > 0) {
          const listImg = elementsByTypecode?.extra_info?.documents
            ?.filter((i) => !!i.path)
            ?.map((e) => e.path);
          temp.push(...listImg);
        }
        return;
      });

      const checkTypecode = [];

      detail?.elements?.forEach((i) => {
        if (
          i?.extra_info?.documents?.length > 0 &&
          i.type_code !== "sign" &&
          !keys.includes(i.type_code) &&
          !checkTypecode.includes(i.type_code)
        ) {
          const listImg = i?.extra_info?.documents
            ?.filter((i) => !!i.path)
            ?.map((e) => e.path);
          temp.push(...listImg);
        }

        checkTypecode.push(i.type_code);
        return;
      }, []);

      return {
        imgLicenseShow: {
          list: temp,
          path: temp[0] || "",
          indexImg: 1,
        },
        dialogUpdate: {
          ...dialogUpdate,
          open: true,
          nameTable: name,
        },
        oldRowsTable,
        ...res,
        // loading: false,
      };
    },

    //* func to close modal update from file Claim
    closeDialogUpdate: () => {
      const { dialogUpdate, oldRowsTable, oldData = {} } = state;

      const res = {};
      let fees = 0;

      if (
        ["profileInformation", "beneficiaryInformation"].includes(
          dialogUpdate.name
        )
      ) {
        res.insuredPersonInformation = { ...oldData };
        res.oldData = {};
      }

      if (dialogUpdate.nameTable === "diagnosis") {
        res.rowsDiagnosis = [...oldRowsTable];
      }

      if (dialogUpdate.nameTable === "medical") {
        res.rowsExamination = [...oldRowsTable];
        res.rowsExamination.forEach((i) => {
          fees = fees + +`${i?.fee || "0"}`.replace(/\./g, "");
        });
        res.examinationFeesTotal = fees;
      }

      if (dialogUpdate.nameTable === "bill") {
        res.rowsBills = [...oldRowsTable];
        res.rowsBills.forEach((i) => {
          fees = fees + +`${i?.fee || "0"}`.replace(/\./g, "");
        });
        res.FeesTotal = fees;
      }

      if (dialogUpdate.nameTable === "prescription") {
        res.rowsMedicines = [...oldRowsTable];
        res.rowsMedicines.forEach((i) => {
          fees = fees + +`${i?.fee || "0"}`.replace(/\./g, "");
        });
        res.medicineFeesTotal = fees;
      }

      if (dialogUpdate.nameTable === "otherTable") {
        res.rowsOtherTables = [...oldRowsTable];
        res.rowsOtherTables.forEach((i) => {
          fees = fees + +`${i?.fee || "0"}`.replace(/\./g, "");
        });
        res.otherTablesTotal = fees;
      }

      return {
        dialogUpdate: { ...dialogUpdate, open: false },
        ...res,
        oldRowsTable: [],
      };
    },

    //* func to change data input of modal update diagnosis "Chẩn đoán bệnh" from file Claim
    diagnosisOnChangText: () => {
      const {
        data: { index, name },
        ele,
        rest,
      } = action;
      const { rowsDiagnosis } = state;
      const value = rest[0] || {};
      rowsDiagnosis[index] = {
        ...rowsDiagnosis[index],
        diseaseCode: value?.code || "",
        diseaseName: value?.name || "",
      };

      return { rowsDiagnosis };
    },

    //* func to change data input of modal update List of medical expenses "Bảng kê chi phí khám" from file Claim
    medicalOnChangText: () => {
      const {
        data: { index, name },
        ele,
        rest,
      } = action;
      const { rowsExamination } = state;

      const item =
        name == "QL_code"
          ? rowsExamination[index]
          : {
              ...rowsExamination[index],
              [name]: ele.target.value,
            };
      if (name == "QL_code") {
        const value = rest[0] || {};
        item.codeBenefit = value.code_benefit || "";
        item.titleBenefit = value.title || "";
      }

      if (name == "fee") {
        item.price = 0;
        const value = ele?.target?.value.replace(/\./g, "");
        item.fee = value;
      }

      if (name == "amount") {
        item[name] = ele?.target?.value;
        const fee = +(item?.amount || 0) * +`${item?.price || "0"}` || "";
        item.fee = Math.round(fee);
      }

      if (name == "price") {
        item[name] = +ele?.target?.value.replace(/\./g, "").replace(/\,/g, ".");
        const fee = +(item?.amount || 0) * +`${item?.price || "0"}` || "";
        item.fee = Math.round(fee);
      }

      rowsExamination[index] = { ...item };
      const examinationFeesTotal =
        rowsExamination.reduce((total, item) => {
          return +total + (+item.fee || 0);
        }, 0) || 0;
      return {
        rowsExamination,
        examinationFeesTotal: Math.round(examinationFeesTotal),
      };
    },

    billOnChangText: () => {
      const {
        data: { index, name },
        ele,
        rest,
      } = action;
      const { rowsBills } = state;

      const item =
        name == "QL_code"
          ? rowsBills[index]
          : {
              ...rowsBills[index],
              [name]: ele.target.value,
            };

      if (name == "QL_code") {
        const value = rest[0] || {};
        item.codeBenefit = value.code_benefit || "";
        item.titleBenefit = value.title || "";
      }

      if (name == "fee") {
        item.price = 0;
        const value = ele?.target?.value.replace(/\./g, "");
        item.fee = value;
      }

      if (name == "amount") {
        item[name] = ele?.target?.value;
        const fee = +(item?.amount || 0) * +`${item?.price || "0"}` || "";
        item.fee = Math.round(fee);
      }

      if (name == "price") {
        item[name] = +ele?.target?.value.replace(/\./g, "").replace(/\,/g, ".");
        const fee = +(item?.amount || 0) * +`${item?.price || "0"}` || "";

        item.fee = Math.round(fee);
      }

      rowsBills[index] = { ...item };
      const billsTotal =
        rowsBills.reduce((total, item) => {
          return total + (+item.fee || 0);
        }, 0) || 0;

      return { rowsBills, billsTotal: Math.round(billsTotal) };
    },

    //* Tên thuốc
    prescriptionOnChangText: () => {
      const {
        data: { index, name },
        ele,
        rest,
      } = action;

      const { rowsMedicines } = state;
      let item = ["QL_code", "medicineNameCode"].includes(name)
        ? rowsMedicines[index]
        : {
            ...rowsMedicines[index],
            [name]: ele.target.value,
          };

      if (name == "QL_code") {
        const value = rest[0] || {};
        item.codeBenefit = value.code_benefit || "";
        item.titleBenefit = value.title || "";
      }

      if (name === "medicineNameCode") {
        const value = rest[0];
        item = {
          ...rowsMedicines[index],
          medicineName: value?.name || "",
          medicineCode: value?.code || "",
        };
      }

      if (name == "fee") {
        item.price = 0;
        const value = ele?.target?.value.replace(/\./g, "");
        item.fee = value;
      }

      if (name == "amount") {
        item[name] = ele?.target?.value;
        const fee = +(item?.amount || 0) * +`${item?.price || "0"}` || "";
        item.fee = Math.round(fee);
      }

      if (name == "price") {
        item[name] = +ele?.target?.value.replace(/\./g, "").replace(/\,/g, ".");
        const fee = +(item?.amount || 0) * +`${item?.price || "0"}` || "";

        item.fee = Math.round(fee);
      }

      rowsMedicines[index] = { ...item };
      const medicineFeesTotal =
        rowsMedicines.reduce((total, item) => {
          return total + (+item.fee || 0);
        }, 0) || 0;

      return {
        rowsMedicines,
        medicineFeesTotal: Math.round(medicineFeesTotal),
      };
    },

    //* Trợ cấp khác
    benefitsOnChangText: () => {
      const {
        data: { index, name },
        ele,
      } = action;
      const { rowsOtherBenefits } = state;
      let item = {
        ...rowsOtherBenefits[index],
        [name]: ele.target.value,
      };

      if (name == "fee") {
        item.price = 0;
        const value = ele?.target?.value.replace(/\./g, "");
        item.fee = value;
      }

      if (name == "numberOfDays") {
        item[name] = ele?.target?.value;
        const fee = +(item?.amount || 0) * +`${item?.price || "0"}` || "";
        item.fee = Math.round(fee);
      }

      if (name == "price") {
        item[name] = +ele?.target?.value.replace(/\./g, "").replace(/\,/g, ".");
        const fee = +(item?.numberOfDays || 0) * +`${item?.price || "0"}` || "";

        item.fee = Math.round(fee);
      }

      rowsOtherBenefits[index] = { ...item };
      const othersTotal =
        rowsOtherBenefits.reduce((total, item) => {
          return total + item.fee;
        }, 0) || 0;

      return { rowsOtherBenefits, othersTotal: Math.round(othersTotal) };
    },

    otherTableOnChangText: () => {
      const {
        data: { index, name },
        ele,
        rest,
      } = action;

      const { rowsOtherTables, snackbar, listBenefits, detailInsurance } =
        state;
      const detailInsurances =
        dialogInsuranceInfo?.data || detailInsurance || [];
      let item = ["QL_code"].includes(name)
        ? rowsOtherTables[index]
        : {
            ...rowsOtherTables[index],
            [name]: ele.target.value,
          };
      let remainingAmount =
        detailInsurances?.find(
          (i) => item.codeBenefit?.charAt(0) == i.codeBenefitDetail
        ) || {};

      if (name == "QL_code") {
        const value = rest[0] || {};
        item.benefitAmount = value?.config_benefit?.value;
        if (
          typeof value?.config_benefit?.value != "number" &&
          isNaN(value?.config_benefit?.value)
        ) {
          const benefitParent = listBenefits.find(
            (i) => i.code_benefit == value?.code_benefit_parent
          );
          item.benefitAmount = !_.isEmpty(benefitParent)
            ? benefitParent?.config_benefit?.value
            : "";
        }

        remainingAmount = detailInsurances?.find(
          (i) =>
            i.codeBenefitDetail ==
            (value?.code_benefit_parent || value?.code_benefit)
        );

        item.codeBenefit = value.code_benefit || "";
        item.titleBenefit = value.title || "";
        item.fee = remainingAmount?.limitAmountLeft || item.benefitAmount;
        item.percent = 100;
      }

      if (name == "percent") {
        const value = ele.target.value;
        if (_.isEmpty(item.codeBenefit)) {
          transData({
            snackbar: {
              ...snackbar,
              open: true,
              type: "error",
              message: "Vui lòng chọn mã quyền lợi",
            },
          });
          return {};
        }

        if (value > 100) {
          transData({
            snackbar: {
              ...snackbar,
              open: true,
              type: "error",
              message: "Tỉ lệ tối đa là 100%",
            },
          });
          return {};
        }

        item[name] = value;
        item.fee =
          ((remainingAmount?.limitAmountLeft || item.benefitAmount) * +value) /
          100;
        if (`${value}`.startsWith("0") && value != 0) {
          item[name] = value.slice(1);
        }
      }

      if (name == "fee") {
        const value = ele?.target?.value.replace(/\./g, "");
        item.fee = value;
        item.percent = Number.isInteger(
          (+(value?.replace(/\./g, "") || 0) * 100) /
            (remainingAmount?.limitAmountLeft || item.benefitAmount)
        )
          ? (+(value?.replace(/\./g, "") || 0) * 100) /
            (remainingAmount?.limitAmountLeft || item.benefitAmount)
          : (
              (+(value?.replace(/\./g, "") || 0) * 100) /
              (remainingAmount?.limitAmountLeft || item.benefitAmount)
            ).toFixed(3);

        if (_.isEmpty(item.codeBenefit)) {
          transData({
            snackbar: {
              ...snackbar,
              open: true,
              type: "error",
              message: "Vui lòng chọn mã quyền lợi",
            },
          });
          return {};
        }

        if (
          +(value?.replace(/\./g, "") || 0) >
          (+(remainingAmount?.limitAmountLeft || item.benefitAmount) || 0)
        ) {
          transData({
            snackbar: {
              ...snackbar,
              open: true,
              type: "error",
              message: "Số tiền không được vượt quá số tiền quyền lợi",
            },
          });
          return {};
        }
      }

      if (+item?.amount > 0 && item?.price) {
        item = {
          ...item,
          fee:
            +item?.amount * +`${item?.price || "0"}`.replace(/\./g, "") || "",
        };
      }

      rowsOtherTables[index] = { ...item };
      const otherTablesTotal =
        rowsOtherTables.reduce((total, item) => {
          return total + (+item.fee || 0);
        }, 0) || 0;

      return {
        rowsOtherTables,
        otherTablesTotal: Math.round(otherTablesTotal),
      };
    },

    //* fucn to delete row  of modal update from file Claim
    deleteRowsTableUpdate: () => {
      const {
        data: { name, index },
      } = action;
      const {
        rowsDiagnosis,
        rowsExamination,
        rowsMedicines,
        rowsOtherBenefits,
        rowsBills,
        rowsOtherTables,
      } = state;
      const res = {};

      name === "diagnosis" && rowsDiagnosis.splice(index, 1);
      name === "medical" && rowsExamination.splice(index, 1);
      name === "prescription" && rowsMedicines.splice(index, 1);
      name === "benefits" && rowsOtherBenefits.splice(index, 1);
      name === "bill" && rowsBills.splice(index, 1);
      name === "otherTable" && rowsOtherTables.splice(index, 1);

      let fees = 0;
      if (name === "medical") {
        rowsExamination.forEach((i) => {
          fees = fees + +`${i?.fee || "0"}`.replace(/\./g, "");
        });
        res.examinationFeesTotal = fees;
      }

      if (name === "prescription") {
        rowsMedicines.forEach((i) => {
          fees = fees + +`${i?.fee || "0"}`.replace(/\./g, "");
        });
        res.medicineFeesTotal = fees;
      }

      if (name === "benefits") {
        rowsOtherBenefits.forEach((i) => {
          fees = fees + +`${i?.fee || "0"}`.replace(/\./g, "");
        });
        res.othersTotal = fees;
      }

      if (name === "bill") {
        rowsBills.forEach((i) => {
          fees = fees + +`${i?.fee || "0"}`.replace(/\./g, "");
        });
        res.billsTotal = fees;
      }

      if (name === "otherTable") {
        rowsOtherTables.forEach((i) => {
          fees = fees + +`${i?.fee || "0"}`.replace(/\./g, "");
        });
        res.otherTablesTotal = fees;
      }

      return {
        ...res,
        rowsDiagnosis,
        rowsExamination,
        rowsMedicines,
        rowsOtherBenefits,
        rowsBills,
      };
    },

    getNameDisease: async () => {
      const { value, nameText, index, reason } = action;
      const { rowsDiagnosis, payloadAutocomplete } = state;
      const res = {};
      if (reason.toString() == "selectOption" || reason.toString() == "reset") {
        return {};
      }
      await dispatch({
        type: "setDataAutocomplete",
        value,
        name: "diagnosis",
        nameText,
        index,
      });

      const dataApi = await getListDisease({
        limit: 10,
        offset: 0,
        [nameText]: value,
      });

      if (dataApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...state.snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi trong quá trình tìm kiếm, vui lòng thử lại",
          },
        };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.listNameDisease = _.get(dataApi, "data.result.data") || [];
        res.payloadAutocomplete = {
          ...payloadAutocomplete,
          diagnosis: {
            page: 1,
            total: _.get(dataApi, "data.result.total"),
            params: { [nameText]: value },
          },
        };
      }

      return { ...res };
    },

    getNameMedicine: async () => {
      const { value, nameText, index } = action;
      const { rowsMedicines, payloadAutocomplete } = state;

      await dispatch({
        type: "setDataAutocomplete",
        value,
        name: "prescription",
        nameText,
        index,
      });

      const res = {};
      const dataApi = await getListMedicine({
        [nameText]: value,

        limit: 10,
        offset: 0,
      });

      if (dataApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...state.snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi trong quá trình tìm kiếm, vui lòng thử lại",
          },
        };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.listNameMedicine = _.get(dataApi, "data.result.data") || [];
        res.payloadAutocomplete = {
          ...payloadAutocomplete,
          prescription: {
            page: 1,
            total: _.get(dataApi, "data.result.total"),
            params: { [nameText]: value },
          },
        };
      }

      return { ...res };
    },

    setDataAutocomplete: () => {
      const { name, value, nameText, index } = action;
      const { rowsDiagnosis, rowsMedicines } = state;

      if (name === "diagnosis") {
        const nameFiled = nameText === "code" ? "diseaseCode" : "diseaseName";
        _.set(rowsDiagnosis, `[${index}].${nameFiled}`, value);

        // const newValue =
        //   nameText === "code" ? { diseaseCode: value } : { diseaseName: value };
        // rowsDiagnosis[index] = {
        //   ...rowsDiagnosis[index],
        //   ...newValue,
        // };
      }

      if (name === "prescription") {
        const newValue =
          nameText === "code"
            ? { medicineCode: value }
            : { medicineName: value };

        rowsMedicines[index] = {
          ...rowsMedicines[index],
          ...newValue,
        };
      }

      return { rowsDiagnosis, rowsMedicines };
    },

    getMoveListAutocomplete: async () => {
      const { name } = action;
      const {
        loadingAutocomplete,
        payloadAutocomplete,
        listNameDisease,
        listNameMedicine,
      } = state;

      const res = {};

      dispatch({ type: "onloadingAutocomplete", name });
      if (name === "diagnosis") {
        const dataApi = await getListDisease({
          limit: 10,
          offset: payloadAutocomplete?.diagnosis?.page * 10 || 0,
          ..._.get(payloadAutocomplete, "diagnosis.params", {}),
        });

        if (dataApi?.data?.statusCode === 200) {
          payloadAutocomplete.diagnosis = {
            ...payloadAutocomplete.diagnosis,
            page: (payloadAutocomplete?.diagnosis?.page || 0) + 1,
            total: _.get(dataApi, "data.result.total"),
          };
          res.listNameDisease = [
            ...listNameDisease,
            ..._.get(dataApi, "data.result.data", []),
          ];
        }
      }

      if (name === "prescription") {
        const dataApi = await getListMedicine({
          limit: 10,
          offset: payloadAutocomplete?.prescription?.page * 10 || 0,
          ..._.get(payloadAutocomplete, "prescription.params", {}),
        });

        if (dataApi?.data?.statusCode === 200) {
          payloadAutocomplete.prescription = {
            ...payloadAutocomplete.prescription,
            page: (payloadAutocomplete?.prescription?.page || 0) + 1,
            total: _.get(dataApi, "data.result.total"),
          };
          res.listNameMedicine = [
            ...listNameMedicine,
            ..._.get(dataApi, "data.result.data", []),
          ];
        }
      }

      loadingAutocomplete[name] = false;
      return { loadingAutocomplete, payloadAutocomplete, ...res };
    },

    //* fucn to open modal benefits from file Claim
    openDialogCodeBenefits: () => {
      const { name, data } = action;
      const { benefitsOption, dialogCodeBenefit } = state;

      if (data?.name === "benefits") {
        return {
          benefitsOption: {
            ...benefitsOption,
            name: "benefits",
            index: data.index,
          },
          dialogCodeBenefit: { ...dialogCodeBenefit, open: true },
        };
      }

      return {
        benefitsOption: { ...benefitsOption, name, index: null },
        dialogCodeBenefit: { ...dialogCodeBenefit, open: true },
      };
    },

    searchListBenefits: async () => {
      const { value } = action;
      const { dataBenefits } = state;

      const dataSearch = dataBenefits.filter(
        (i) => compare(value, i.title) || compare(value, i.code_benefit)
      );

      return { listBenefits: [...dataSearch] };
    },

    closeDialogCodeBenefits: () => {
      const { dialogCodeBenefit } = state;

      return {
        dialogCodeBenefit: { ...dialogCodeBenefit, open: false },
        selectedBenefits: {},
      };
    },

    checkAllTable: () => {
      const {
        data: { name },
      } = action;
      const {
        rowsExamination,
        rowsMedicines,
        rowsBills,
        rowsOtherTables,
        checkedAllMedical,
        checkedAllBill,
        checkedAllPrescription,
        checkedAllOtherTable,
      } = state;

      const res = {};
      switch (name) {
        case "medicalExpan":
          res.rowsExamination = rowsExamination.map((i) => ({
            ...i,
            checked: !checkedAllMedical,
          }));
          res.checkedAllMedical = !checkedAllMedical;
          return { ...res };

        case "bill":
          res.rowsBills = rowsBills.map((i) => ({
            ...i,
            checked: !checkedAllBill,
          }));
          res.checkedAllBill = !checkedAllBill;
          return { ...res };

        case "prescriptionExpan":
          res.rowsMedicines = rowsMedicines.map((i) => ({
            ...i,
            checked: !checkedAllPrescription,
          }));
          res.checkedAllPrescription = !checkedAllPrescription;
          return { ...res };

        case "otherTable":
          res.rowsOtherTables = rowsOtherTables.map((i) => ({
            ...i,
            checked: !checkedAllOtherTable,
          }));
          res.checkedAllOtherTable = !checkedAllOtherTable;
          return { ...res };

        default:
          return { ...res };
      }
    },

    checkItemTable: () => {
      const {
        data: { name, index },
        ele,
      } = action;
      const { rowsExamination, rowsMedicines, rowsBills, rowsOtherTables } =
        state;

      const res = {};

      switch (name) {
        case "medicalExpan":
          rowsExamination[index] = {
            ...rowsExamination[index],
            checked: ele?.target?.checked,
          };
          res.checkedAllMedical = rowsExamination.every(
            (i) => i.checked === true
          );
          return { ...res, rowsExamination };

        case "bill":
          rowsBills[index] = {
            ...rowsBills[index],
            checked: ele?.target?.checked,
          };
          res.checkedAllBill = rowsBills.every((i) => i.checked === true);
          return { ...res, rowsBills };

        case "prescriptionExpan":
          rowsMedicines[index] = {
            ...rowsMedicines[index],
            checked: ele?.target?.checked,
          };
          res.checkedAllPrescription = rowsMedicines.every(
            (i) => i.checked === true
          );
          return { ...res, rowsMedicines };

        case "otherTable":
          rowsOtherTables[index] = {
            ...rowsOtherTables[index],
            checked: ele?.target?.checked,
          };

          res.checkedAllOtherTable = rowsOtherTables.every(
            (i) => i.checked === true
          );
          return { ...res, rowsOtherTables };

        default:
          return {};
      }

      // if (name === "medicalExpan") {
      //   rowsExamination[index] = {
      //     ...rowsExamination[index],
      //     checked: ele?.target?.checked,
      //   };
      //   res.checkedAllMedical = rowsExamination.every(
      //     (i) => i.checked === true
      //   );
      // }

      // if (name === "bill") {
      //   rowsBills[index] = {
      //     ...rowsBills[index],
      //     checked: ele?.target?.checked,
      //   };
      //   res.checkedAllBill = rowsBills.every((i) => i.checked === true);
      // }

      // if (name === "prescriptionExpan") {
      //   rowsMedicines[index] = {
      //     ...rowsMedicines[index],
      //     checked: ele?.target?.checked,
      //   };
      //   res.checkedAllPrescription = rowsMedicines.every(
      //     (i) => i.checked === true
      //   );
      // }

      // if (name === "otherTable") {
      //   rowsOtherTables[index] = {
      //     ...rowsOtherTables[index],
      //     checked: ele?.target?.checked,
      //   };

      //   res.checkedAllOtherTable = rowsOtherTables.every(
      //     (i) => i.checked === true
      //   );
      // }

      // return {
      //   ...res,
      //   rowsExamination,
      //   rowsMedicines,
      //   rowsBills,
      //   rowsOtherTables,
      // };
    },

    selectedBenefits: () => {
      const { item, value } = action;

      if (!value) {
        return { selectedBenefits: {} };
      }

      return { selectedBenefits: { ...item } };
    },

    applyBenefitsIntoTable: () => {
      const {
        selectedBenefits,
        rowsExamination,
        rowsBills,
        rowsMedicines,
        rowsOtherBenefits,
        rowsOtherTables,
        benefitsOption,
      } = state;

      const res = {};
      if (benefitsOption.name === "medical") {
        res.rowsExamination = rowsExamination.map((item) => {
          if (item.checked) {
            return {
              ...item,
              codeBenefit: selectedBenefits.code_benefit || "",
              titleBenefit: selectedBenefits.title || "",
              checked: false,
            };
          }
          return item;
        });
        res.checkedAllMedical = false;
      }

      if (benefitsOption.name === "bill") {
        res.rowsBills = rowsBills.map((item) => {
          if (item.checked) {
            return {
              ...item,
              codeBenefit: selectedBenefits.code_benefit || "",
              titleBenefit: selectedBenefits.title || "",
              checked: false,
            };
          }
          return item;
        });
        res.checkedAllBill = false;
      }

      if (benefitsOption.name === "prescription") {
        res.rowsMedicines = rowsMedicines.map((item) => {
          if (item.checked) {
            return {
              ...item,
              codeBenefit: selectedBenefits.code_benefit || "",
              titleBenefit: selectedBenefits.title || "",
              checked: false,
            };
          }
          return item;
        });
        res.checkedAllPrescription = false;
      }

      if (benefitsOption.name === "benefits") {
        rowsOtherBenefits[benefitsOption.index] = {
          ...rowsOtherBenefits[benefitsOption.index],
          codeBenefit: selectedBenefits.code_benefit || "",
          titleBenefit: selectedBenefits.title || "",
        };
      }

      if (benefitsOption.name === "otherTable") {
        res.rowsOtherTables = rowsOtherTables.map((item) => {
          if (item.checked) {
            return {
              ...item,
              codeBenefit: selectedBenefits.code_benefit || "",
              titleBenefit: selectedBenefits.title || "",
              checked: false,
            };
          }
          return item;
        });
        res.checkedAllOtherTable = false;
      }

      const error = checkErrorTable({
        rowsExamination,
        rowsMedicines,
        rowsOtherBenefits,
      });

      const err = {};
      error.forEach((i) => {
        err[i] = true;
      });

      dispatch("closeDialogCodeBenefits");
      return { ...res, rowsOtherBenefits, errors: err };
    },

    finalFeesTotal: () => {
      const {
        medicineFeesTotal,
        othersTotal,
        examinationFeesTotal,
        billsTotal,
        otherTablesTotal,
      } = state;

      const fee =
        +medicineFeesTotal +
        +othersTotal +
        +examinationFeesTotal +
        +billsTotal +
        +otherTablesTotal;

      return {
        finalFeesTotal: Math.round(fee),
      };
    },

    checkClaim: async () => {
      const {
        detail,
        finalFeesTotal,
        rowsDiagnosis,
        rowsExamination,
        rowsBills,
        rowsMedicines,
        rowsOtherBenefits,
        examinationFeesTotal,
        billsTotal,
        medicineFeesTotal,
        othersTotal,
        errors,
        insuredPersonInformation,
        otherTablesTotal,
        rowsOtherTables,
      } = state;
      const claimId = _.get(detail, "elements[0].claim_id") || "";

      const error = checkErrorTable({
        rowsExamination,
        rowsMedicines,
        rowsOtherBenefits,
      });

      if (error?.length > 0) {
        error.forEach((i) => {
          errors[i] = true;
        });
        return {
          errors,
          snackbar: {
            open: true,
            type: "error",
            message: "Vui lòng điền đầy đủ thông tin vào bên dưới",
          },
        };
      }

      transData({ loading: true });

      let temp = {};

      switch (detail?.extraInfo?.typeOfIndimnity) {
        case 3:
          temp = {
            injuryFeesTotal: otherTablesTotal,
            injuries: rowsOtherTables.map((i) => ({
              injuryType: i.otherText,
              benefitAmount: i.benefitAmount,
              percent: i.percent,
              fee: +`${i.fee || "0"}`,
              codeBenefit: i.codeBenefit,
              titleBenefit: i.titleBenefit,
            })),
          };
          break;
        case 5:
          temp = {
            canceOrFatalDiseaseOrStrokeFeesTotal: otherTablesTotal,
            canceOrFatalDiseaseOrStroke: rowsOtherTables.map((i) => ({
              diseaseType: i.otherText,
              benefitAmount: i.benefitAmount,
              fee: +`${i.fee || "0"}`,
              codeBenefit: i.codeBenefit,
              titleBenefit: i.titleBenefit,
            })),
          };
          break;
        case 6:
          temp = {
            deathFeesTotal: otherTablesTotal,
            deaths: rowsOtherTables.map((i) => ({
              reasonOfDeath: i.otherText,
              benefitAmount: i.benefitAmount,
              fee: +`${i.fee || "0"}`,
              codeBenefit: i.codeBenefit,
              titleBenefit: i.titleBenefit,
            })),
          };
          break;
        default:
          break;
      }

      const dataTableExtraInfo = [3, 5, 6].includes(
        +detail?.extraInfo?.typeOfIndimnity
      )
        ? {
            doctorDiagnoses: [],
            examinationFeesTotal: 0,
            examinationFees: [],
            billsTotal: 0,
            bills: [],
            medicineFeesTotal: 0,
            medicines: [],
            othersTotal,
            others: rowsOtherBenefits?.map((i) => {
              return {
                codeBenefit: i?.codeBenefit || "",
                titleBenefit: i?.titleBenefit || "",
                serviceName: i?.titleBenefit || "",
                numberOfDays: +i?.numberOfDays,
                price: +`${i?.price || ""}`,
                fee: +`${i?.fee || ""}`,
              };
            }),
            otherTablesTotal,
            ...temp,
          }
        : {
            doctorDiagnoses: rowsDiagnosis?.map((i) => {
              return {
                diseaseCode: i?.diseaseCode,
                diseaseName: i.diseaseName,
              };
            }),
            examinationFeesTotal,
            examinationFees: rowsExamination?.map((i) => {
              return {
                codeBenefit: i?.codeBenefit || "",
                titleBenefit: i?.titleBenefit || "",
                serviceName: i?.serviceName,
                paidFee: +`${i?.paidFee || "0"}`.replace(/\./g, "") || null,
                amount: +i?.amount,
                price: +`${i?.price || ""}`,
                fee: +`${i?.fee || ""}`,
              };
            }),
            billsTotal,
            bills: rowsBills?.map((i) => {
              return {
                codeBenefit: i?.codeBenefit || "",
                titleBenefit: i?.titleBenefit || "",
                serviceName: i?.serviceName,
                paidFee: +`${i?.paidFee || "0"}`.replace(/\./g, "") || null,
                amount: +i?.amount,
                price: +`${i?.price || ""}`,
                fee: +`${i?.fee || ""}`,
              };
            }),
            medicineFeesTotal,
            medicines: rowsMedicines?.map((i) => {
              return {
                codeBenefit: i?.codeBenefit || "",
                titleBenefit: i?.titleBenefit || "",
                medicineName: i?.medicineName,
                medicineCode: i?.medicineCode,
                paidFee: +`${i?.paidFee || "0"}`.replace(/\./g, "") || null,
                amount: +i?.amount,
                price: +`${i?.price || ""}`,
                fee: +`${i?.fee || ""}`,
              };
            }),
            othersTotal,
            others: rowsOtherBenefits?.map((i) => {
              return {
                codeBenefit: i?.codeBenefit || "",
                titleBenefit: i?.titleBenefit || "",
                serviceName: i?.titleBenefit || "",
                numberOfDays: +i?.numberOfDays,
                price: +`${i?.price || ""}`,
                fee: +`${i?.fee || ""}`,
              };
            }),
          };

      const params = {
        claimId,
        insuredInfo: {
          insuredName: insuredPersonInformation?.insuredName,
          relationshipWithInsuredPersonId:
            insuredPersonInformation?.relationshipWithInsuredPersonId,
          relationshipWithBuyerName:
            insuredPersonInformation?.relationshipWithBuyerName,
          insuredPrivateId: insuredPersonInformation?.insuredPrivateId,
          insuredDob: insuredPersonInformation?.insuredDob,
          insuredPhone: insuredPersonInformation?.insuredPhone,
          insuredEmail: insuredPersonInformation?.insuredEmail,
          insuredGender: insuredPersonInformation?.insuredGender,
          insuredAddress: insuredPersonInformation?.insuredAddress,
        },
        extraInfo: {
          countCheckInput: 1,
          merchantTitle: insuredPersonInformation?.merchantTitle,
          typeOfInsurance: insuredPersonInformation?.typeOfInsurance,
          typeOfIndimnity: insuredPersonInformation?.typeOfIndimnity,
          typeOfRisk: insuredPersonInformation?.typeOfRisk || null,
          treatmentPlace: insuredPersonInformation?.treatmentPlace,
          departmentTreatment: insuredPersonInformation?.departmentTreatment,
          dateOfHospital: insuredPersonInformation?.dateOfHospital,
          dateOutOfHospital: insuredPersonInformation?.dateOutOfHospital,
          timeInHospital: insuredPersonInformation?.timeInHospital,
          doctorName: insuredPersonInformation?.doctorName,
          finalFeesTotal: finalFeesTotal,
          beneficiaryBankAcountNumber:
            insuredPersonInformation?.beneficiaryBankAcountNumber,
          beneficiaryBankBranchName:
            insuredPersonInformation?.beneficiaryBankBranchName,
          beneficiaryBank:
            insuredPersonInformation?.beneficiaryBank ||
            insuredPersonInformation?.beneficiaryBankTitle,
          beneficiaryName: insuredPersonInformation?.beneficiaryName,
          ...dataTableExtraInfo,
        },
      };

      const dataApi = await checkLicenseClaim(systemType, params);

      if (dataApi instanceof Error) {
        transData({
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi khi kiểm tra hồ sơ. Xin vui lòng thử lại",
          },
        });
        return {};
      }

      transData({
        tabsDetail: 3,
        snackbar: {
          open: true,
          type: "success",
          message: "Kiểm tra hồ sơ thành công",
        },
        loading: false,
        examinationFeesTotal,
        medicineFeesTotal,
        othersTotal,
      });

      return;
    },

    progressLoading: () => {
      const { open, value } = action;
      return { progressLoading: { open, value } };
    },

    onChangePictureBox: () => {
      const { typeAction } = action;
      const { pictureBox } = state;
      switch (typeAction) {
        case "rotated-left":
          return {
            pictureBox: {
              ...pictureBox,
              rotate: (pictureBox.rotate || 0) - 90,
            },
          };
        case "rotated-right":
          return {
            pictureBox: {
              ...pictureBox,
              rotate: (pictureBox.rotate || 0) + 90,
            },
          };
        case "zoom-in":
          return {
            pictureBox: {
              ...pictureBox,
              scale: (pictureBox.scale || 1) + 0.25,
            },
          };
        case "zoom-out":
          return {
            pictureBox: {
              ...pictureBox,
              scale: (pictureBox.scale || 1) - 0.25,
            },
          };
      }
    },

    onChangeIndexImg: () => {
      const { name } = action;
      const { imgLicenseShow } = state;

      if (name === "next") {
        const indexImg =
          imgLicenseShow.indexImg === imgLicenseShow.list.length
            ? 1
            : imgLicenseShow.indexImg + 1;
        imgLicenseShow.indexImg = indexImg;
        imgLicenseShow.path = imgLicenseShow.list[indexImg - 1] || "";
      } else {
        const indexImg =
          imgLicenseShow.indexImg === 1
            ? imgLicenseShow.list.length
            : imgLicenseShow.indexImg - 1;
        imgLicenseShow.indexImg = indexImg;
        imgLicenseShow.path = imgLicenseShow.list[indexImg - 1] || "";
      }

      return { imgLicenseShow };
    },

    onloadingAutocomplete: () => ({
      loadingAutocomplete: {
        ...state.loadingAutocomplete,
        [action.name]: true,
      },
    }),

    //------>
  };

  return cases[action.type] && cases[action.type];
}
