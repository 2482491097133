import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const DashboardLayoutRootEmbededs = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
}));

export default function DashboardLayoutEmbeded(props) {
  const { children } = props;

  return (
    <>
      <DashboardLayoutRootEmbededs>
        <Box sx={cssLayoutRoot}>{children}</Box>
      </DashboardLayoutRootEmbededs>
    </>
  );
}

const cssLayoutRoot = {
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
  padding: "16px",
  paddingTop: "0",
};
