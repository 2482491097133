import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
  TableCell,
  TableContainer,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { debounce } from "lodash";
import React, { useRef } from "react";
import CommonTable from "../../../../library/layout/commonTable";
import { convertCurrency } from "../utils";
import NumberFormat from "react-number-format";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateBenefits(props) {
  const { open, dispatch, state } = props;
  const { editbenefitList, saving } = state;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"md"}
      onClose={() => dispatch({ type: "onClose", name: "benefit" })}
      className={classes.dialog}
    >
      <DialogTitle>Bảng quyền lợi bảo hiểm</DialogTitle>
      <DialogContent>
        <div className={classes.headerTable}>
          <div className="code">Mã quyền lợi</div>
          <div className="name">Tên quyền lợi</div>
          <div className="benefit">Quyền lợi</div>
        </div>
        <CommonTable
          classes={classes}
          rows={editbenefitList || []}
          columns={columns}
          empty={"Không có thông tin. Vui lòng kiểm tra lại"}
          event={(type, data) =>
            (ele, ...rest) => {
              dispatch({ type, data, ele, rest });
            }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          disabled={saving}
          className="button"
          onClick={() => dispatch("updateBenefit")}
        >
          {saving ? "Đang lưu.." : "Cập nhật"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const columns = [
  {
    renderCell: ({ item, event, rowIdx }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst code"
            : item.isSecond
            ? "isSecond code"
            : "code"
        }
      >
        {item?.codeBenefit || item?.code_benefit || ""}
      </TableCell>
    ),
  },
  {
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst name-benefits"
            : item.isSecond
            ? "isSecond name-benefits"
            : "name-benefits"
        }
      >
        {item?.title || ""}
      </TableCell>
    ),
  },
  {
    renderCell: ({ rowIdx, event, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst benefit"
            : item.isSecond
            ? "isSecond benefit"
            : "benefit"
        }
      >
        <NumberFormat
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          onChange={event("changeBenefit", { index: rowIdx })}
          value={item?.config_benefit?.value || ""}
          variant="standard"
          fullWidth
        />
      </TableCell>
    ),
  },
];

const useStyles = makeStyles(() => ({
  dialog: {
    height: "100%",
    "& .button": {
      background: "#3961A0",
      borderRadius: 5,
      color: "#fff",
      marginBottom: 5,
      padding: "10px 20px",
    },
    "& .MuiPaper-root-MuiDialog-paper": {
      maxHeight: "80%",
    },
    "& .MuiDialogContent-root": {
      padding: "0 24px",
    },
    "& .Mui-disabled": {
      background: "#E4E4E4 !important",
    },
  },
  table: {
    minWidth: "600px",
    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& td": {
      textAlign: "left",
      color: "#4B4B4B",
    },

    "& td.code": {
      padding: "0 8px",
      background: "#fff",
      width: "150px",
    },

    "& td.isFirst": {
      background: "#09BAFD",
      fontWeight: 700,
    },

    "& td.isSecond": {
      background: "#E3F3FF",
      fontWeight: 500,
    },

    "& td.name-benefits": {
      padding: "0 8px",
    },

    "& td.benefit": {
      width: "180px",
    },

    "& .MuiInput-input": {
      padding: "5px 10px",
      textAlign: "right",
    },

    "& .MuiTextField-root": {
      paddingRight: "15px",
    },
  },

  div: {
    paddingBottom: "15px",
  },

  header: {
    display: "none",
  },

  headerTable: {
    color: "#fff",
    fontWeight: 600,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: "#3961A0",
    padding: "8px",
    position: "sticky",
    top: 0,
    zIndex: 3,
    "& .code": {
      width: "150px",
    },
    "& .name": {
      flexGrow: 1,
      textAlign: "left",
    },
    "& .benefit": {
      width: "160px",
      textAlign: "center",
    },
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #D6DEEB",
    background: "white !important",
  },
}));
