import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Box, Grid, Typography } from "@mui/material";
import { Col, Row } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";

import { Wrapper, ContentWrapper, Content } from "../../../carAccident/styles";
import HeaderBar from "../../components/layout/HeaderBar";
import { getListOrderForClaim } from "../../../../../../apis/claimApis";
import {
  CREATE_CLAIM_FILE_TOMATO,
  CREATE_REQUEST_CLAIM_TOMATO,
} from "../../../../../../configs/routesConfig";
import { formatCurrency } from "../../../../../../utils/utils";

const ListClaimTomato = () => {
  const history = useHistory();
  const location = useLocation();

  const [listContracts, setListContracts] = useState([]);

  useEffect(async () => {
    const data = {
      productIdParent: 19,
      limit: 100,
      offset: 0,
    };

    const dataApi = await getListOrderForClaim(data);
    if (dataApi?.data?.statusCode === 200) {
      localStorage.setItem("order", JSON.stringify(dataApi?.data?.result));
      setListContracts(dataApi?.data?.result);
    }
  }, []);

  return (
    <Wrapper>
      <HeaderBar
        title="Tạo yêu cầu bồi thường"
        background="#F2F2F2"
        onPress={() => history.push(CREATE_REQUEST_CLAIM_TOMATO + "?goback")}
      />
      <ContentWrapper style={{ backgroundColor: "white" }}>
        <Content>
          <Row justify="center">
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <div style={formInfo}>
                <Typography
                  sx={{
                    fontSize: "17px",
                    lineHeight: "25px",
                    color: "#838383",
                    fontWeight: "400",
                  }}
                >
                  Vui chọn người được bảo hiểm khai báo hồ sơ bồi thường
                </Typography>
                {listContracts?.map((item, index) => (
                  <Box
                    key={index}
                    sx={infoInsu}
                    onClick={() => {
                      localStorage.setItem("insured", JSON.stringify(item));
                      return history.push(
                        CREATE_CLAIM_FILE_TOMATO + location.search,
                        { itemContract: item, create: "new" }
                      );
                    }}
                  >
                    <Grid
                      container
                      spacing={1}
                      direction="column"
                      justifyContent="center"
                      alignItems="stretch"
                    >
                      <Grid item sx={{}}>
                        <Typography sx={name}>{item.name || ""}</Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        sx={{ display: "flex" }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box sx={phone}>
                          <LocalPhoneOutlinedIcon className="icon" />
                          <Typography className="title">
                            {item?.phone || ""}
                          </Typography>
                        </Box>
                        <Box sx={phone}>
                          <BadgeOutlinedIcon
                            sx={{ color: "#03A9F4 !important" }}
                            className="icon"
                          />
                          <Typography className="title">
                            {item?.birthday || ""}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Typography sx={moneyTitle}>
                          SỐ TIỀN ĐÓNG GÓP
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography sx={{ textAlign: "left" }}>
                          {formatCurrency(item?.price) || "0"} VNĐ
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </div>
            </Col>
          </Row>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ListClaimTomato;

const formInfo = {
  margin: "40px 0 8px 0",
};

const titleTutorial = {
  fontSize: 17,
  color: "#838383",
  lineHeight: "22px",
  letterSpacing: 0,
};

const infoInsu = {
  width: "100%",
  boxShadow: "0px 9px 46px rgb(0 0 0 / 6%)",
  padding: "16px",
  backgroundColor: "white",
  borderRadius: "12px",
  marginTop: "12px",
  "&:hover": {
    background: "rgba(11, 60, 138, 0.17)",
  },
};

const name = {
  textAlign: "left",
  fontSize: 17,
  lineHeight: "22px",
  fontWeight: "700",
  letterSpacing: "-0.24 px",
  textTransform: "capitalize",
};

const phone = {
  display: "flex",
  "& .icon": {
    color: "#0B3C8A",
    fontSize: 16,
    marginRight: "6px",
  },
  "& .title": {
    fontSize: 15,
    lineHeight: "19px",
  },
};

const moneyTitle = {
  textAlign: "left",
  fontSize: 13,
  fontWeight: "700",
  color: "rgba(0, 0, 0, 0.32)",
};
