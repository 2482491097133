import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    "& .header": {
      fontSize: 17,
      color: "#284078",
      fontWeight: 600,
      padding: "5px 0",
    },
    "& .mt-10": {
      marginTop: 10,
    },
    "& .my-15": {
      margin: "15px 0",
    },
    "& .warring": {
      display: "flex",
      alignItems: "center",
      color: "#E16C2B",
      background: "#FFF4E4",
      borderRadius: 8,
      padding: 8,
      fontSize: 13,
      fontWeight: 500,
    },
    "& .icon": {
      marginRight: 5,
    },
  },

  inputDiv: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "6px",
    marginTop: "6px",
    padding: "7px 6px",
    display: "flex",

    "& .MuiInputBase-root:before": {
      border: "none !important",
    },

    "& .MuiInputBase-root:after": {
      border: "none !important",
    },

    "& .MuiInputBase-input": {
      boxSizing: "border-box",
    },
  },

  spacing: {
    height: 5,
    background: "#F8F8F8",
    width: "100vw",
    margin: "15px 0 10px -10px",
  },

  error: {
    color: "red",
    paddingTop: "6px",
    fontSize: 13,
  },

  errText: { color: "red", fontSize: 13, margin: "-10px 0 10px" },

  divImg: {
    width: "100%",
    overflow: "auto",
    "& .title": {
      padding: "6px 0",
      position: "sticky",
      left: 0,
      fontSize: 14,
    },
    "& .upload": {
      padding: 10,
      borderRadius: 8,
      border: "1px dashed #D2D2D2",
      background: "#F8F9FA",
      margin: "8px 0",
      minHeight: 40,
    },
    "& .row": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #EDEDED",
      padding: "5px 0",
    },
    "& .row-audio": {
      display: "flex",
      alignItems: "center",
    },
    "& .text-audio": {
      color: "#284078",
      fontWeight: 500,
    },
  },

  boxUpload: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "1px solid #284078",
    borderRadius: 12,
    padding: "8px",
    width: "100px",
    margin: "0px 8px 8px 0px",
    "& .note": {
      color: "#284078",
      textAlign: "center",
      fontWeight: 500,
      fontSize: 12,
      marginTop: 5,
    },
  },

  boxImg: {
    position: "relative",
    margin: "0px 8px 8px 0px",
    border: "1px solid #D2D2D2",
    width: 80,
    height: 100,
    "& .image": {
      width: "100%",
      height: "100%",
    },
    "& .del-icon": {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 9,
    },
  },

  lineImg: {
    display: "flex",
    width: "fit-content",
    margin: "4px 0px",
  },

  textField: {
    "& .MuiInputBase-root": {
      margin: "15px 0",
      borderRadius: 10,
      background: "#F1F1F1",
    },
    "& .MuiInputLabel-root": {
      top: 15,
      borderRadius: 15,
      padding: "0px 10px",
      background: "#fff",
    },
  },

  divInput: {
    "& .MuiInputBase-root": {
      margin: "15px 0",
      borderRadius: 10,
    },
    "& .MuiInputLabel-root": {
      top: 15,
    },
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },

  boxSuccess: {
    background: "white",
    width: "100%",
    padding: "12px",

    "& .history": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      textDecorationLine: "underline",
      fontSize: "14px",
      fontWeight: "600",

      "& a": {
        color: "#0044CC",
      },
    },

    "& .logo": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginBottom: "12px",
    },

    "& .boxInfor": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "16px",
      textAlign: "center",
      fontWeight: "500",

      "& .line1": {
        color: "#284078",
        fontSize: "17px",
        fontWeight: "600",
      },

      "& .line2": {
        color: "#E16C2B",
      },

      "& .line3": {
        color: "#3F76DF",
        fontStyle: "italic",
        textDecoration: "underline",
        cursor: "pointer",
      },
    },

    "& .boxText": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "center",

      "& .line_1": {
        fontSize: "16px",
        fontWeight: "600",
        padding: "16px 0",
      },

      "& .line_2": {
        fontSize: "14px",
      },
    },
  },
  divFooter: {
    marginTop: "8px",
    textAlign: "center",
    padding: "16px 0",

    "& .title": {
      paddingBottom: "8px",
      color: "#284078",
      fontSize: "16px",
      fontWeight: "600",
    },

    "& .infor": {
      display: "flex",
      justifyContent: "space-around",
      width: "100%",

      "& .phone": {
        background: "#284078",
        padding: "12px",
        borderRadius: "10px",
        color: "white",
        fontWeight: "600",
        fontSize: "16px",
      },
      "& .email": {
        padding: "12px 10px",
        border: "1px solid #E16C2B",
        borderRadius: "10px",
        color: "#E16C2B",
        fontSize: "14px",
        fontWeight: "600",
      },
    },
  },
}));
