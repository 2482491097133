import React from "react";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export default function CategoryLicense(props) {
  const {
    nonMultiple,
    listImgs = [],
    title,
    addImg,
    error,
    showImg,
    deleteImg,
    optional,
  } = props;
  const classes = useStyled();
  const newList =
    listImgs.length === 0
      ? [1, 2, 3]
      : listImgs.length === 1
      ? [...listImgs, 2, 3]
      : listImgs.length === 2
      ? [...listImgs, 3]
      : listImgs;

  return (
    <div className={classes.container}>
      <div className={classes.title} style={{ color: error ? "red" : "" }}>
        {title}
        {!optional && <span style={{ color: "red" }}>*</span>}
      </div>
      <div className={classes.box}>
        <div
          className={classes.addImg}
          style={{ borderColor: error ? "red" : "" }}
          onClick={() => document.getElementById(title).click()}
        >
          <AddIcon fontSize="large" />
          <input
            type="file"
            hidden
            id={title}
            accept="image/*"
            multiple={!nonMultiple}
            onChange={addImg}
          />
        </div>
        {newList.map((item, index) => (
          <div
            className={classes.boxImg}
            key={index}
            style={{
              marginRight: index + 1 === newList.length ? 0 : "",
              borderColor: error ? "red" : "",
            }}
          >
            {item.path ? (
              <>
                <img
                  onClick={() => showImg({ item, index })}
                  width={68}
                  height={68}
                  src={item.path}
                  alt="img"
                  style={{ borderRadius: "12px" }}
                />
                {!item?.isCanvas && (
                  <CancelOutlinedIcon
                    style={{ zIndex: 1 }}
                    className="icon-closse"
                    fontSize="small"
                    onClick={(e) =>
                      deleteImg({ e, indexImg: index, url: item.path })
                    }
                  />
                )}
              </>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

const useStyled = makeStyles(() => ({
  container: {
    overflow: "auto",
  },

  title: {
    marginBottom: "6px",
    left: 0,
    position: "sticky",
  },

  box: {
    display: "flex",
    width: "fit-content",
    marginBottom: "12px",
  },

  addImg: {
    width: "70px",
    height: "70px",
    border: "1px dashed #686868",
    borderRadius: "12px",
    cursor: "pointer",
    color: "#0B3C8A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "18px",
    // left: 0,
    // position: "sticky",
    // zIndex: 1,
    // background: "white",
  },

  boxImg: {
    width: "70px",
    height: "70px",
    border: "1px dashed #686868",
    borderRadius: "12px",
    cursor: "pointer",
    color: "#0B3C8A",
    marginRight: "18px",
    position: "relative",
    textAlign: "center",

    "& .icon-closse": {
      color: "#1A1A1A",
      position: "absolute",
      top: -6,
      right: -6,
      background: "white",
      borderRadius: "12px",
    },
  },

  //
}));
