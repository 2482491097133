import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import { useStyles } from "../styles/historyStyles";
import { _formatMoney, formatDate, getExtension } from "../utils";

import downloadIcon from "../../../../../assets/loyalty/download-file.svg";
import fileAudio from "../../../../../assets/loyalty/file-audio.svg";
import fileIcon from "../../../../../assets/loyalty/file.svg";
import ShowImg from "../components/DialogShowImg";
import ShowFile from "../components/ShowFile";

export default function HistoryDetail(props) {
  const { state, dispatch } = props;
  const classes = useStyles();
  const {
    historyDestination: { data, index },
    showFileEvidence,
    showImg,
  } = state;

  const renderImg = [
    {
      title: "Hình ảnh hàng hoá trước khi giao",
      id: 200,
      typeCode: "goods_document_before_risk",
      listImg: (data?.elements || []).find((item) => item.type_id === 200)
        ?.extra_info,
    },
    {
      title: "Hình ảnh hóa đơn",
      id: 202,
      typeCode: "bill",
      listImg: (data?.elements || []).find((item) => item.type_id === 202)
        ?.extra_info,
    },
  ];

  const paymentContent =
    (data.elements || []).find((item) => item.type_id === 203) || {};

  const listImg206 =
    (data?.elements || []).find((item) => item.type_id === 206)?.extra_info
      ?.documents || [];

  const listImg208 =
    (data?.elements || []).find((item) => item.type_id === 208)?.extra_info
      ?.documents || [];

  const listImg209 =
    (data?.elements || []).find((item) => item.type_id === 209)?.extra_info
      ?.documents || [];

  return (
    <div className={classes.container}>
      <div className="header">THÔNG TIN VỀ SỰ CỐ</div>
      <Row
        title="Ngày xảy ra sự cố"
        value={formatDate(data?.extra_info?.riskDate)}
      />
      <Row title="Giờ xảy ra sự cố" value={data?.extra_info?.riskTime || ""} />
      <Row title="Mô tả diễn biến" value={data?.extra_info?.describe || ""} />
      <div className="header">KHAI BÁO GIÁ TRỊ HÀNG HÓA</div>
      <Row
        title="Giá trị hàng hoá"
        value={`${_formatMoney(data?.extra_info?.codValue)} VNĐ`}
      />
      <div className="header">RỦI RO GẶP PHẢI</div>
      <div style={{ fontSize: "15px", fontWeight: "500" }}>
        {+data?.extra_info?.typeOfAccident === 3
          ? "Shipper chiếm dụng hàng hoá"
          : "Shipper chiếm dụng COD"}
      </div>
      <div className={classes.spacing}></div>
      <div className={classes.containImg}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownOutlinedIcon style={{ color: "#284078" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span className="titleAccodion">HÌNH ẢNH</span>
          </AccordionSummary>
          <AccordionDetails>
            {renderImg.map((i, index) => (
              <div className={classes.divImg} key={`boxImg${index}`}>
                <div className="title">
                  {i.title} <span style={{ color: "red" }}>*</span>
                </div>
                <div className={classes.lineImg}>
                  {(i.listImg?.documents || []).map((item, idx) => (
                    <div className={classes.boxImg} key={`img${idx}`}>
                      <img
                        key={index}
                        src={item?.path}
                        alt={`picture ${index}`}
                        className="image"
                        onClick={() =>
                          dispatch({
                            type: "showImgHistory",
                            url: item?.path,
                            typeId: i.id,
                            indexImg: idx,
                          })
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className={classes.divImg} key={`boxImg2`}>
              <div className="title">
                File hình ảnh<span style={{ color: "red" }}>*</span>
              </div>
              <div className={classes.lineImg}>
                {(
                  listImg206.filter((i) => i.type === "evidence_image") || []
                ).map((item, idx) => (
                  <div className={classes.boxImg} key={`eImg${idx}`}>
                    <img
                      key={index}
                      src={item?.path}
                      alt={`picture ${index}`}
                      className="image"
                      onClick={() =>
                        dispatch({
                          type: "showImgHistory",
                          url: item?.path,
                          typeId: 206,
                          indexImg: idx,
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.divImg} key={`boxAudio`}>
              <div className="title">
                File ghi âm<span style={{ color: "red" }}>*</span>
              </div>
              <div className="upload">
                {(
                  listImg206.filter((i) => i.type === "evidence_audio") || []
                ).map((i, idx) => (
                  <div className="border" key={idx}>
                    <img src={fileAudio} alt="file" /> {i?.originalname || ""}
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.divImg} key={`boxFile`}>
              <div className="title">
                File tài liệu<span style={{ color: "red" }}>*</span>
              </div>
              <div className="upload">
                {(
                  listImg206.filter((i) => i.type === "evidence_file") || []
                ).map((i, idx) => (
                  <div
                    className="border"
                    key={idx}
                    onClick={() => dispatch({ type: "showEvidence", file: i })}
                  >
                    <img src={fileAudio} alt="file" /> {i?.originalname || ""}
                  </div>
                ))}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className={classes.containImg}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownOutlinedIcon style={{ color: "#284078" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span className="titleAccodion">
              THÔNG TIN TÀI XẾ VÀ NGƯỜI THÂN
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="title">Thông tin tài xế</div>
            <div>CMND/CCCD 2 mặt của tài xế</div>
            <div className="row">
              <div className="box-img">
                <div className="frame-img">
                  <img
                    src={listImg208?.find((i) => i.type === "front")?.path}
                    alt="front"
                    className="img"
                    onClick={() =>
                      dispatch({
                        type: "showImgHistory",
                        url: listImg208?.find((i) => i.type === "front")?.path,
                        typeId: 208,
                        indexImg: 0,
                      })
                    }
                  />
                </div>
                <div className="text">Ảnh mặt trước</div>
              </div>
              <div className="box-img">
                <div className="frame-img">
                  <img
                    src={listImg208?.find((i) => i.type === "back")?.path}
                    alt="back"
                    className="img"
                    onClick={() =>
                      dispatch({
                        type: "showImgHistory",
                        url: listImg208?.find((i) => i.type === "back")?.path,
                        typeId: 208,
                        indexImg: 1,
                      })
                    }
                  />
                </div>
                <div className="text">Ảnh mặt sau</div>
              </div>
            </div>
            <div>Hợp đồng tài xế ahamove cấp</div>
            <div className="box-file">
              {(listImg209 || []).map((item, index) => (
                <div className="border-item" key={index}>
                  <div className="row">
                    <div
                      className="row"
                      onClick={() => {
                        getExtension(item?.path) !== "pdf"
                          ? dispatch({ type: "showContractImg", file: item })
                          : dispatch({ type: "showEvidence", file: item });
                      }}
                    >
                      <img
                        src={fileIcon}
                        alt="file"
                        style={{ marginRight: "6px", width: "22px" }}
                      />
                      <div>
                        <div>{item?.originalname || ""}</div>
                        <div className="size">{item?.size || ""}</div>
                      </div>
                    </div>
                    <div className="row">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          dispatch({
                            type: "downloadFile",
                            path: item?.path,
                            name: item?.originalname,
                          })
                        }
                      >
                        <img
                          src={downloadIcon}
                          alt="download"
                          style={{ width: "16px" }}
                        />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {renderDriverInfo(data?.extra_info).map((item, index) => (
              <Row
                title={item.label}
                value={item?.value || ""}
                index={index}
                capitalize={item?.capitalize}
              />
            ))}
            {(data?.extra_info?.listOfRelativesToDriver || []).map(
              (item, index) => (
                <div key={index}>
                  <div className="title">{`Thông tin người thân ${
                    index + 1
                  }`}</div>
                  <Row
                    title="Họ và tên"
                    value={item?.fullName || ""}
                    capitalize={true}
                  />
                  <Row title="Số điện thoại" value={item?.phoneNumber || ""} />
                  <Row title="Năm sinh" value={item?.yearOfBirth || ""} />
                  <Row
                    title="Mối quan hệ với tài xế"
                    value={item?.relationshipWithDriver || ""}
                  />
                </div>
              )
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      <div className={classes.containImg}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownOutlinedIcon style={{ color: "#284078" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span className="titleAccodion">THÔNG TIN PHÊ DUYỆT</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.boxTextRow}>
              <Row
                title="Số tiền phê duyệt"
                value={`${_formatMoney(paymentContent?.value)} VNĐ`}
              />
              <div className={classes.ellipsis}>
                <span className="titleSpan">Nội dung</span>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  value={paymentContent?.text || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                  className="border-none"
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className={classes.containImg}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ArrowDropDownOutlinedIcon style={{ color: "#284078" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span className="titleAccodion">THÔNG TIN NGƯỜI THỤ HƯỞNG</span>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.boxTextRow}>
              <Row
                title="Tên tài khoản"
                value={"CONG TY CO PHAN DICH VU TUC THOI"}
              />
              <Row
                title="Ngân hàng"
                value={"Ngân hàng Techcombank - CN Thắng Lợi"}
              />
              <Row title="Số tài khoản" value={"19131637055028"} />
              <Row
                title="Email"
                value={data?.extra_info?.beneficiaryEmail || ""}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <ShowImg {...{ dispatch, showImg }} />
      <ShowFile
        open={!!showFileEvidence?.open}
        link={showFileEvidence?.link || ""}
        onClose={() => dispatch("closeShowEvidence")}
      />
    </div>
  );
}

const Row = (props) => {
  const { title, value, capitalize, index } = props;
  return (
    <div key={index} className="rowDiv">
      <span className="titleSpan">{title}</span>
      <span
        className={capitalize ? "valueSpan capitalize" : "valueSpan"}
        title={value}
      >
        {value}
      </span>
    </div>
  );
};

const renderDriverInfo = (data) => [
  { label: "Họ và tên", value: data?.driver?.fullName, capitalize: true },
  { label: "Số điện thoại", value: data?.driver?.phoneNumber },
  {
    label: "Biển số xe",
    value: data?.driver?.license,
    capitalize: true,
  },
  { label: "Loại xe", value: data?.driver?.vehicleType },
  { label: "Thời gian nhận hàng", value: data?.driver?.receivingTimeOfDriver },
  { label: "Thời gian giao hàng", value: data?.driver?.deliveryTime },
];
