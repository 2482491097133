import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { TableCell, ThemeProvider, createTheme } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { tableReportStyle } from "../style/styleTableReport";
import CommonTable from "../../../../library/layout/commonTable";

export default function TableReport(props) {
  const classes = tableReportStyle();
  const rows = [{ name: "1" }, { name: "2" }, { name: "3" }, { name: "4" }];
  const { state, dispatch } = props;
  const { dataFiter, overviewListProduct, overviewListMerchant } = state;

  return (
    <ThemeProvider
      theme={createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 650,
            md: 900,
            lg: 1100,
            xl: 1536,
            xxl: 1950,
          },
        },
      })}
    >
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <div className={classes.containerTabler}>
              <div className={classes.titleTable}>
                <div>
                  <span style={{ fontWeight: "600" }}>Sản phẩm</span>
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#6685B6",
                      marginLeft: "6px",
                    }}
                  >{` ${dataFiter?.valueTextDate || ""}`}</span>
                </div>
                <div
                  onClick={() =>
                    dispatch({ type: "openDialogListP_M", name: "product" })
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#0B7CE4",
                    }}
                  >
                    Xem thêm
                  </span>
                  <ChevronRightIcon
                    fontSize="small"
                    sx={{ color: "#0B7CE4" }}
                  />
                </div>
              </div>
              <div>
                <CommonTable
                  columns={columns}
                  rows={overviewListProduct}
                  classes={classes}
                  data={{ nameTable: "product" }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={classes.containerTabler}>
              <div className={classes.titleTable}>
                <div>
                  <span style={{ fontWeight: "600" }}>Doanh nghiệp</span>
                  <span
                    style={{
                      fontSize: "13px",
                      color: "#6685B6",
                      marginLeft: "6px",
                    }}
                  >{` ${dataFiter?.valueTextDate || ""}`}</span>
                </div>
                <div
                  onClick={() =>
                    dispatch({ type: "openDialogListP_M", name: "merchant" })
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#0B7CE4",
                    }}
                  >
                    Xem thêm
                  </span>
                  <ChevronRightIcon
                    fontSize="small"
                    sx={{ color: "#0B7CE4" }}
                  />
                </div>
              </div>
              <div>
                <CommonTable
                  columns={columns}
                  rows={overviewListMerchant}
                  classes={classes}
                  data={{ nameTable: "merchant" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
}

const columns = [
  {
    label: "TH",
    headerClass: "stt",
    renderCell: ({ rowIdx, colIdx }) => (
      <TableCell className={``} key={colIdx}>
        {rowIdx + 1}
      </TableCell>
    ),
  },
  {
    renderHeader: ({ data, item, colIdx }) => (
      <TableCell className={``} key={colIdx}>
        {data?.nameTable === "product" ? "Tên sản phẩm" : "Doanh nghiệp"}
      </TableCell>
    ),
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {item.title}
      </TableCell>
    ),
  },
  {
    label: "Tỉ lệ BT",
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {+item?.fees
          ? ((+item?.amount / (+item?.fees || 1)) * 100).toFixed(1)
          : 0}
      </TableCell>
    ),
  },
  {
    label: "YCBT",
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {item?.total_claim}
      </TableCell>
    ),
  },
  {
    label: "Tổng mức BT",
    renderCell: ({ colIdx, item }) => (
      <TableCell className={``} key={colIdx}>
        {`${item.amount || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      </TableCell>
    ),
  },
];
