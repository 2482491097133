import * as React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button } from "@mui/material";
import { Modal } from "antd";
import Grid from "@mui/material/Unstable_Grid2";

import InforRecords from "./inforRecords";
import TableDilaogUpdate from "./tableDialog";
import BoxImgLicense from "./boxImgLicense";
import OtherTableUpdate from "./otherTable";

export default function DialogUpdate(props) {
  const { state, dispatch } = props;
  const classes = makeStyle();

  const { dialogUpdate, detail } = state;

  const render = (name) => {
    switch (name) {
      case "profileInformation":
        return {
          title: "Thông tin hồ sơ",
          element: (
            <InforRecords
              {...{ state, dispatch, name: dialogUpdate?.nameTable }}
            />
          ),
        };
      case "beneficiaryInformation":
        return {
          title: "Thông tin thụ hưởng",
          element: (
            <InforRecords
              {...{ state, dispatch, name: dialogUpdate?.nameTable }}
            />
          ),
        };
      case "diagnosis":
        return {
          title: "Chẩn đoán bệnh",
          element: (
            <TableDilaogUpdate
              {...{ state, dispatch, name: dialogUpdate?.nameTable }}
            />
          ),
        };
      case "medical":
        return {
          title: "Bảng kê chi phí khám",
          element: (
            <TableDilaogUpdate
              {...{ state, dispatch, name: dialogUpdate?.nameTable }}
            />
          ),
        };
      case "bill":
        return {
          title: "Hóa đơn",
          element: (
            <TableDilaogUpdate
              {...{ state, dispatch, name: dialogUpdate?.nameTable }}
            />
          ),
        };
      case "prescription":
        return {
          title: "Toa thuốc",
          element: (
            <TableDilaogUpdate
              {...{ state, dispatch, name: dialogUpdate?.nameTable }}
            />
          ),
        };

      case "otherTable":
        return {
          title:
            +detail?.extraInfo?.typeOfIndimnity == 3
              ? "Tỉ lệ thương tật"
              : +detail?.extraInfo?.typeOfIndimnity == 5
              ? "Ung thư/ bệnh hiểm nghèo/ đột quỵ"
              : "Tử vong",
          element: <OtherTableUpdate {...{ state, dispatch }} />,
        };

      default:
        return {};
    }
  };

  return (
    <Modal
      title={render(dialogUpdate?.nameTable).title}
      // visible=
      open={!!dialogUpdate?.open}
      onCancel={() => dispatch("closeDialogUpdate")}
      okText={false}
      centered
      width={"98%"}
      style={{ maxHeight: "95vh" }}
      footer={null}
      className={classes.modal_body}
    >
      <Grid container spacing={1} columns={20}>
        <Grid sm={13} md={12} maxHeight={"80vh"}>
          <div className={classes.left_box}>
            <div className={classes.clontainerContent}>
              {render(dialogUpdate?.nameTable).element}
            </div>
            <div className={classes.buttons}>
              <Button
                className="buttons"
                onClick={() => dispatch("updateTable")}
              >
                Cập nhật
              </Button>
            </div>
          </div>
        </Grid>
        <Grid sm={7} md={8} maxHeight={"80vh"}>
          <BoxImgLicense {...props} />
        </Grid>
      </Grid>
    </Modal>
  );
}

const makeStyle = makeStyles(() => ({
  modal_body: {
    "& .ant-modal-body": {
      padding: "24px 12px",
    },
  },

  clontainerContent: {
    width: "100%",
    minWidth: "500px",
    // maxHeight: "75vh",
    // overflow: "auto",
  },

  left_box: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  buttons: {
    width: "100%",
    paddingTop: "12px",
    display: "flex",
    justifyContent: "end",
    position: "sticky",
    bottom: "0px",
    background: "white",

    "& .buttons": {
      textTransform: "none",
      background: "#3961A0",
      color: "white",
      borderRadius: "12px",
      padding: "8px 24px",
      fontWeight: "600",
    },
  },
}));
