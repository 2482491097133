import React, { useEffect, useState } from "react";
import { Button, Popover } from "@mui/material";

import styledRecordkeeping from "./styledRecordkeeping";
import { columns } from "./columns";
import CommonTable from "../../../../../../library/layout/commonTable";

export default function RecordkeepingTalbe(props) {
  const { state, dispatch, level_id } = props;
  const classes = styledRecordkeeping();
  const {
    rowsHistory,
    rowsHistoryTable1,
    detail,
    currentFilePdf,
    popoverbutton,
  } = state;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickP = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch("inittalHistory");
  }, []);

  const handleClick = ({ item, name }) => {
    if (name == "show_detail_pdf") {
      dispatch({ type: "openDialogDetailPDF", item, name: "item_history" });
    } else {
      dispatch({ type: "chooseDataFilePDF", item });
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div className={classes.containerDiv}>
        <div className="header-title">{`Lịch sử phê duyệt HS-${detail?.code}`}</div>
        <CommonTable
          classes={classes}
          columns={columns}
          rows={rowsHistoryTable1}
          data={{
            detail,
            name: "approval_history",
            handleClick,
            currentFilePdf,
            handleClickP,
            dispatch,
            level_id,
          }}
        />
      </div>
      <div className={classes.border} />
      <div className={classes.containerDiv}>
        <div className="header-title">Lịch sử bồi thường</div>
        {rowsHistory.length === 0 ? (
          <div className="not-yet">Chưa có lịch sử bồi thường nào</div>
        ) : (
          <CommonTable
            classes={classes}
            columns={columns}
            rows={rowsHistory}
            data={{ name: "history", handleClick }}
          />
        )}
      </div>
      <Popover
        id={id}
        open={!!popoverbutton.open}
        anchorEl={popoverbutton?.anchorEl || null}
        onClose={() => dispatch({ type: "closePopoverButton" })}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div className={classes.buttons_popover}>
          <Button
            className="button-top"
            onClick={() =>
              dispatch({
                type: "changeStatusFilepdf",
                name:
                  popoverbutton.statusItem == 2 ? "invalidPDF" : "reconfirm",
              })
            }
          >
            {popoverbutton.statusItem == 2
              ? "Hồ sơ không hợp lệ"
              : "Gửi lại xác nhận"}
          </Button>
          <Button
            className="button-bottom"
            onClick={() =>
              dispatch({
                type: "changeStatusFilepdf",
                name: popoverbutton.statusItem == 2 ? "deletePDF" : "cancelPDF",
              })
            }
          >
            {popoverbutton.statusItem == 2 ? "Xoá nháp" : "Huỷ hồ sơ"}
          </Button>
        </div>
      </Popover>
    </div>
  );
}
