import api from "../configs/api";

function getListProviderClaim() {
  return api.apiForm("POST", "/api/v1/provider-claim/list");
}

function getListMerchant() {
  return api.apiForm("POST", "/api/v1/merchant/list");
}

function createAccountProvider(data) {
  return api.apiForm("POST", "/api/v1/user/provider", data);
}

function createAccountMerchant(data) {
  return api.apiForm("POST", "/api/v1/user/merchant", data);
}

function createAccountGlobalCare(data) {
  return api.apiForm("POST", "/api/v1/user/globalcare", data);
}

function createAccountGara(data) {
  return api.apiForm("POST", "/api/v1/user/provider/department/garage", data);
}

function createAccountHospital(data) {
  return api.apiForm("POST", "/api/v1/user/provider/department/hospital", data);
}

function updateAccountProvider(data) {
  return api.apiForm("PUT", "/api/v1/user/provider", data);
}

function updateAccountMerchant(data) {
  return api.apiForm("PUT", "/api/v1/user/merchant", data);
}

function updateAccountGlobalCare(data) {
  return api.apiForm("PUT", "/api/v1/user/globalcare", data);
}

function updateAccountGara(data) {
  return api.apiForm("PUT", "/api/v1/user/provider/department/garage", data);
}

function getListGaraByProvider(data) {
  return api.apiForm("POST", "/api/v1/department/provider/garage/list");
}

function getListAccountGlobalcare(data) {
  return api.apiForm("POST", "/api/v1/user/list", data);
}

function getListAccountFromProvider(data) {
  return api.apiForm(
    "POST",
    "/api/v1/user/provider/department/garage/list",
    data
  );
}

function updateAccountLock(data) {
  return api.apiForm("PUT", `/api/v1/user/lock`, data);
}

function getAccountDetailsFromGlobalCare(userId) {
  return api.apiForm("GET", `/api/v1/user/${userId}/detail`);
}

function getAccountDetailsFromProvider(userId) {
  return api.apiForm("GET", `/api/v1/user/${userId}/detail-department`);
}

function getConfigAll() {
  return api.apiForm("GET", "/api/v1/config/all");
}

function updatePasswordFromGlobalCare(data) {
  return api.apiForm("PUT", "/api/v1/user/reset-password", data);
}

function updatePasswordFromProvider(data) {
  return api.apiForm("PUT", "/api/v1/user/provider/reset-password", data);
}

export {
  getListProviderClaim,
  getListMerchant,
  createAccountProvider,
  createAccountMerchant,
  createAccountGlobalCare,
  getListGaraByProvider,
  createAccountGara,
  getListAccountGlobalcare,
  getAccountDetailsFromGlobalCare,
  updateAccountGara,
  updateAccountProvider,
  updateAccountMerchant,
  updateAccountGlobalCare,
  updatePasswordFromGlobalCare,
  getListAccountFromProvider,
  getAccountDetailsFromProvider,
  updatePasswordFromProvider,
  updateAccountLock,
  getConfigAll,
  createAccountHospital,
};
