import React from 'react';
import { Input, Button } from 'antd';
import { FieldStyle } from './styles';

const InputField = ({
  iconStart,
  iconEnd,
  label,
  name,
  type,
  disabled,
  value,
  help,
  size,
  typeIcon,
  hasCustom,
  hasIconLeft,
  hasIconRight,
  placeholder,
  onBlur,
  onChange,
  validateStatus,
  iconStyle,
  inputStyle,
  labelCol,
  autoComplete,
  wrapperCol,
  addonAfter,
  btnRemove,
  functionBtnRemove,
  title,
  styleTitle,
  isRequire,
  ...rest
}) => {
  return (
    <FieldStyle
      label={label}
      size={size}
      validateStatus={validateStatus}
      help={help}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      {title ?
        <div style={styleTitle || styleDefaultTitle}>
          {title}{isRequire ? <span style={{color: 'red'}}>*</span> : null}
        </div> : null}
      <Input
        name={name}
        value={value}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplete}
        disabled={disabled}
        maxLength={250}
        prefix={hasIconLeft && iconStart}
        // suffix={
        //   hasIconRight &&
        //   (hasCustom ? (
        //     <Icon type={iconEnd} style={iconStyle} />
        //   ) : (
        //       <Icon type={iconEnd} style={iconStyle} />
        //     ))
        // }
        placeholder={placeholder}
        style={inputStyle}
        addonAfter={addonAfter}
        {...rest}
      />
      {btnRemove && <Button onClick={functionBtnRemove} className="style-btn-remove-ant-form-item" style={{paddingLeft: 0}}>x</Button>}
    </FieldStyle>
  )
}

const styleDefaultTitle = {
  color: '#000000',
  fontWeight: 'bold',
  fontSize: 17,
  opacity: 0.5
}

export default InputField
