import moment from "moment";

export const initialState = {
  listDetails: [],
  listLicese: [],
  additionLicesees: [],
  listImgShow: [],
  arrayLinks: [],
  indexImgShow: {},
  dialogNoti: {},
  dialogRefuseClaim: {},
  detail: {},
  snackbar: {},
  totalList: 0,
  page: 0,
  nameShowImgPage: "",
  curenPage: "status-claim",
  headerTitle: "Hồ sơ bồi thường của bạn",
  accodiorInsuredInfor: true,
  accodiorBeneficiary: true,
  disableBtn: false,
};

export const listBeneficiaryInfo = [
  { title: "Chủ tài khoản", name: "beneficiaryName", capitalize: true },
  { title: "MQH với người được BH", name: "relationshipWithBuyerName" },
  { title: "Số tài khoản", name: "beneficiaryBankAcountNumber" },
  { title: "Ngân hàng", name: "beneficiaryBank" },
  { title: "Chi nhánh", name: "beneficiaryBankBranchName" },
];

export const typeOfIndimnity = [
  { title: "Nội trú", id: 1 },
  { title: "Ngoại trú", id: 2 },
  { title: "Thương tật vĩnh viễn", id: 3 },
  { title: "Thai sản", id: 4 },
  { title: "Bệnh hiểm nghèo hoặc ung thư", id: 5 },
  { title: "Tử vong", id: 6 },
];

export const listTypeOfRisk = [
  { title: "Khám thai định kỳ", id: 1, parentId: 4 },
  { title: "Sanh thường", id: 2, parentId: 4 },
  { title: "Sanh mổ", id: 3, parentId: 4 },
  { title: "Biến chứng thai sản", id: 4, parentId: 4 },
  { title: "Ung thư", id: 5, parentId: 5 },
  { title: "Đau tim/ Đột quỵ", id: 6, parentId: 5 },
  { title: "Súc vật cắn", id: 7 },
  { title: "Bỏng/phỏng", id: 8 },
  { title: "Tai nạn sinh hoạt", id: 9 },
  { title: "Tai nạn lao động", id: 10 },
  { title: "Tai nạn giao thông", id: 11 },
  { title: "Bệnh thông thường", id: 12, parentId: 6 },
  { title: "Đau tim/ Đột quỵ", id: 13, parentId: 6 },
  { title: "Bệnh hiểm nghèo", id: 14, parentId: 6 },
];

export const listTypeOfTreatments = [
  { title: "Nội trú", value: 1 },
  { title: "Ngoại trú", value: 2 },
  { title: "Thương tật vĩnh viễn", value: 3 },
  { title: "Khám thai định kỳ", value: 4, maternity: true },
  { title: "Sanh thường", value: 5, maternity: true },
  { title: "Sanh mổ", value: 6, maternity: true },
  { title: "Biến chứng thai sản", value: 7, maternity: true },
  { title: "Ung thư", value: 8, cancer: true },
  { title: "Đau tim/ Đột quỵ", value: 9, cancer: true },
  { title: "Ung thư", value: 1, died: true },
  { title: "Đau tim/ Đột quỵ", value: 2, died: true },
  { title: "Bệnh thông thường", value: 3, died: true },
];

export const getListLicenseById = ({
  typeOfInsurance,
  typeOfIndimnity,
  typeOfRisk = null,
}) => {
  if (!typeOfInsurance) return [];

  const getNewId = () => {
    switch (typeOfIndimnity) {
      case 1:
        return 7;
      case 2:
        return 8;
      case 3:
        return 9;
      case 6:
        return 10;
      default:
        return null;
    }
  };

  const newTypeOfIndimnity =
    typeOfInsurance == 1 ? getNewId() : typeOfIndimnity;

  const data = listLicense.reduce((array, item) => {
    const listIdItem = item.typeOfIndimnity.map((i) => i.id);
    const listIdOptional = (item.optional || []).map((i) => i.id);

    if (!typeOfRisk) {
      array = listIdItem.includes(newTypeOfIndimnity)
        ? [
            ...array,
            {
              title: item.title,
              typeId: item.typeId,
              typeCode: item.typeCode,
              nonMultiple: !!item.nonMultiple,
              optional: listIdOptional.includes(newTypeOfIndimnity),
            },
          ]
        : array;
    } else {
      const listTypeOfIndimnityTypeOfRisk =
        item.typeOfIndimnity?.find((i) => i.id == newTypeOfIndimnity)
          ?.typeOfRisk || [];
      const listTypeOfIndimnityOptional =
        item.optional?.find((i) => i.id == newTypeOfIndimnity)?.typeOfRisk ||
        [];

      array =
        listIdItem.includes(newTypeOfIndimnity) &&
        listTypeOfIndimnityTypeOfRisk.includes(typeOfRisk)
          ? [
              ...array,
              {
                title: item.title,
                typeId: item.typeId,
                typeCode: item.typeCode,
                nonMultiple: !!item.nonMultiple,
                optional:
                  listIdOptional.includes(newTypeOfIndimnity) &&
                  listTypeOfIndimnityOptional.includes(typeOfRisk),
              },
            ]
          : array;
    }

    return [...array];
  }, []);

  return data;
};

export const listLicense = [
  {
    title: "Hình chụp CMND/ CCCD/ Hộ chiếu mặt trước và sau",
    typeId: 500,
    typeCode: "private_id",
    nonMultiple: true,
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Biên bản tường trình",
    typeId: 537,
    typeCode: "report",
    typeOfIndimnity: [
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Tóm tắt bệnh án, báo cáo y tế, phiếu chỉ định khám",
    typeId: 531,
    typeCode: "summary_report_health",
    typeOfIndimnity: [
      { id: 2 },
      { id: 4, typeOfRisk: [1, 4] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [4] },
      { id: 7, typeOfRisk: [7, 8] },
      { id: 9, typeOfRisk: [7, 8] },
    ],
  },
  {
    title: "Hồ sơ bệnh án",
    typeId: 502,
    typeCode: "medical_records",
    typeOfIndimnity: [
      { id: 1 },
      { id: 3 },
      { id: 4, typeOfRisk: [2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
    ],
    optional: [
      { id: 1 },
      { id: 4, typeOfRisk: [2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
    ],
  },
  {
    title: "Chỉ định xét nghiệm/ chụp XQ...và kết quả",
    typeId: 504,
    typeCode: "test_xq_and_result",
    typeOfIndimnity: [
      { id: 2 },
      { id: 3 },
      { id: 4, typeOfRisk: [1, 4] },
      { id: 5, typeOfRisk: [5] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [1, 4] },
      { id: 5, typeOfRisk: [5] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Toa thuốc",
    typeId: 507,
    typeCode: "prescription",
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 1 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7] },
    ],
  },
  {
    title: "Phiếu thu chi phí khám",
    typeId: 525,
    typeCode: "examination_receipt",
    typeOfIndimnity: [
      { id: 2 },
      { id: 4, typeOfRisk: [4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Phiếu thu chi phí thuốc",
    typeId: 524,
    typeCode: "prescription_receipt",
    typeOfIndimnity: [
      { id: 2 },
      { id: 4, typeOfRisk: [1, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [1, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Bảng kê chi phí điều trị",
    typeId: 506,
    typeCode: "treatment_cost_checkout",
    optional: true,
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [2, 3, 4] },
      { id: 5, typeOfRisk: [6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [4] },
      { id: 5, typeOfRisk: [6] },
      { id: 8, typeOfRisk: [8, 9, 10, 11] },
    ],
  },
  {
    title: "Hóa đơn thuốc",
    typeId: 509,
    typeCode: "medicine_bill",
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Hóa đơn chi phí khám",
    typeId: 508,
    typeCode: "examination_cost_bill",
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Giấy chứng nhận phẫu thuật (nếu có)",
    typeId: 503,
    typeCode: "surgery_cert",
    typeOfIndimnity: [
      { id: 1 },
      { id: 3 },
      { id: 4, typeOfRisk: [3] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 1 },
      { id: 3 },
      { id: 4, typeOfRisk: [3] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Kết qủa xét nghiệm và mô bệnh học",
    typeId: 513,
    typeCode: "test_result_and_histopathology",
    typeOfIndimnity: [
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [14] },
    ],
    optional: [
      { id: 5, typeOfRisk: [6] },
      { id: 6, typeOfRisk: [14] },
    ],
  },
  {
    title: "Kết quả chẩn đoán hình ảnh, tế bào học",
    typeId: 514,
    typeCode: "diagnose_image_cytology",
    typeOfIndimnity: [
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [14] },
    ],
    optional: [
      { id: 5, typeOfRisk: [6] },
      { id: 6, typeOfRisk: [14] },
    ],
  },
  {
    title: "Giấy ra viện",
    typeId: 510,
    typeCode: "hospital_checkout",
    typeOfIndimnity: [
      { id: 1 },
      { id: 3 },
      { id: 4, typeOfRisk: [2, 3] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [{ id: 3 }, { id: 5, typeOfRisk: [5, 6] }],
  },
  {
    title: "Chi phí tái khám sau xuất viện, đơn thuốc",
    typeId: 511,
    typeCode: "re-examination_cost",
    typeOfIndimnity: [{ id: 4, typeOfRisk: [2, 3] }],
    optional: [{ id: 4, typeOfRisk: [2, 3] }],
  },
  {
    title: "Hóa đơn tái khám sau xuất viện, đơn thuốc",
    typeId: 512,
    typeCode: "re-examination_bill",
    typeOfIndimnity: [{ id: 4, typeOfRisk: [2, 3, 4] }],
    optional: [{ id: 4, typeOfRisk: [2, 3, 4] }],
  },
  {
    title: "Giấy chứng tử/ trích lục khai tử",
    typeId: 515,
    typeCode: "death_cert",
    typeOfIndimnity: [
      { id: 6, typeOfRisk: [12, 13, 14] },
      { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Phiếu chích ngừa/ chỉ định chích ngừa/ sổ theo dõi chích ngừa",
    typeId: 528,
    typeCode: "paper_suggestion_book_tracking_of_vaccine",
    typeOfIndimnity: [{ id: 8, typeOfRisk: [7] }],
  },
  {
    title:
      "Tường trình về tình huống và nguyên nhân tử vong của người được bảo hiểm",
    typeId: 527,
    typeCode: "situation_&_cause_of_death",
    typeOfIndimnity: [{ id: 6, typeOfRisk: [12, 13, 14] }],
    optional: [{ id: 6, typeOfRisk: [12, 13, 14] }],
  },
  {
    title: "Giấy chứng nhận thương tật của cơ quan có thẩm quyền",
    typeId: 532,
    typeCode: "certificate_of_injury",
    typeOfIndimnity: [{ id: 3 }, { id: 9, typeOfRisk: [7, 8, 9, 10, 11] }],
  },
  {
    title:
      "Xác nhận của chính quyền đại phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận nguyên nhân",
    typeId: 533,
    typeCode: "certificate_of_reason",
    typeOfIndimnity: [{ id: 9, typeOfRisk: [7, 8, 9, 10, 11] }],
  },
  {
    title:
      "Biên bản công an điều tra có kết luận nguyên nhân tử vong/ biên bản tai nạn có xác nhận của chính quyền đại phương hoặc cơ quan chủ quản. Bảo cáo y tế/ hồ sơ bệnh án của bệnh viện có kết luận nguyên nhân tử vong",
    typeId: 534,
    typeCode: "death_investigation_report_from_police",
    typeOfIndimnity: [{ id: 10, typeOfRisk: [7, 8, 9, 10, 11] }],
  },
  {
    title:
      "Biên bản phân chia di sản thừa kế có xác nhận của cơ quan có thẩm quyền",
    typeId: 535,
    typeCode: "division_of_inheritance",
    typeOfIndimnity: [{ id: 10, typeOfRisk: [7, 8, 9, 10, 11] }],
  },
  {
    title:
      "Giấy phép lái xe nếu tai nạn khi đang khi đang điều khiển xe trên 50 cc",
    typeId: 536,
    typeCode: "driving_licence",
    typeOfIndimnity: [
      { id: 7, typeOfRisk: [11] },
      { id: 8, typeOfRisk: [11] },
      { id: 9, typeOfRisk: [11] },
      { id: 10, typeOfRisk: [11] },
    ],
    optional: [
      { id: 7, typeOfRisk: [11] },
      { id: 8, typeOfRisk: [11] },
      { id: 9, typeOfRisk: [11] },
      { id: 10, typeOfRisk: [11] },
    ],
  },
  {
    title: "Khác",
    typeId: 523,
    typeCode: "other",
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
      { id: 7, typeOfRisk: [7, 8, 9, 10] },
      { id: 8, typeOfRisk: [7, 8, 9, 10] },
      { id: 9, typeOfRisk: [7, 8, 9, 10] },
      { id: 10, typeOfRisk: [7, 8, 9, 10] },
    ],
    optional: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
      { id: 7, typeOfRisk: [7, 8, 9, 10] },
      { id: 8, typeOfRisk: [7, 8, 9, 10] },
      { id: 9, typeOfRisk: [7, 8, 9, 10] },
      { id: 10, typeOfRisk: [7, 8, 9, 10] },
    ],
  },
];

export const formatDate = (date) => {
  if (!date) return "";
  const newDate = moment(date).format("DD/MM/YYYY");
  return newDate !== "Invalid date" ? newDate : "--";
};

export const convertCurrency = (money) => {
  money = !money ? "0" : money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return money;
};

export const renderRelationship = (id) => {
  switch (id) {
    case 1:
      return "Bản thân";
    case 2:
      return "Vợ/chồng";
    case 3:
      return "Con cái";
    default:
      return "Bản thân";
  }
};
