import { makeStyles } from "@mui/styles";

const paymentStyles = makeStyles((theme) => ({
  container: {
    marginTop: "12px",
    background: "white",
    padding: "12px",
    "& .header-title": {
      fontWeight: "600",
      fontSize: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .text-file": {
      fontWeight: "500",
      marginBottom: 12,
    },
    "& .upload-box": {
      background: "#ECECEC",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: 40,
      margin: "8px 0 24px 0",
    },
    "& .text-bold": {
      marginTop: "8px",
      fontWeight: "600",
    },
    "& .text": {
      fontSize: "13px",
      marginTop: "8px",
      lineHeight: 1.5,
    },
    "& .button": {
      fontSize: "13px",
      marginTop: "8px",
      textTransform: "none",
    },
    "& .files": {
      display: "flex",
      alignItems: "center",
      margin: "12px 0",
    },
    "& .info-file": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: "12px 0",
    },
    "& .name": {
      maxWidth: "400px",
      marginRight: 24,
    },

    "& .img": {
      width: 40,
      height: 40,
      borderRadius: 8,
      marginRight: 40,
      border: "0.5px dashed green",
      cursor: "pointer",
    },
    "& .file": {
      marginRight: 24,
      whiteSpace: "nowrap",
    },
  },
}));

export default paymentStyles;
