import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

import useReducer from "../../../../../library/hooks/useReducer";
import ahamoveRrducer from "../reducer/ahamoveReducer";
import { HeaderBar, BottomButton } from "../components/layout";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import History from "./history";
import DetailHistory from "./detailHistory";
import Notify from "./notify";

const initialState = {
  banks: [],
  elements: [],
  elementsHistory: [],
  inforOrder: {},
  historyOrder: {},
  destination: {},
  historyDestination: {},
  picker: {},
  extraInfo: {},
  errors: {},
  page: {
    total: 1,
    offset: 0,
  },
  showImg: {},
  tabsStatus: 2,
  currentStep: 1,
  statusNotify: null,
  disabledBottomButton: true,
  openCertificate: false,
  chekedCommit: false,
  loading: true,
};

export default function AhamoveClaim() {
  let arr = window.location.search.split("?");
  let params = new URLSearchParams(arr[1]);
  const isApp = params.get("platform") === "app";
  const codeOrder = params.get("code");

  const History = useHistory();

  const [state, dispatch] = useReducer({
    reducer: ahamoveRrducer,
    name: "ahamoveClaimRrducer",
    initialState,
    isApp,
    codeOrder,
    History,
  });

  const {
    loading,
    currentStep,
    disabledBottomButton,
    historyDestination,
    inforOrder,
  } = state;

  const isUpdate =
    +historyDestination?.data?.statusId === 4 &&
    currentStep === "detailHistory";

  const title = getTitle(currentStep);
  const titleBottom = getTitleBottom(currentStep);

  return (
    <div style={{ maxWidth: "600px", margin: "auto" }}>
      <HeaderBar
        title={title}
        onPress={() => dispatch({ type: "changePagePress" })}
        isApp={isApp}
      />
      <div
        style={{
          minHeight: isApp ? "550px" : "600px",
          background: inforOrder.completeStatus === 0 ? "white" : "#FEF3ED",
        }}
      >
        {getStep({ currentStep, state, dispatch, isApp, codeOrder })}
      </div>
      {(inforOrder.completeStatus === 1 ||
        currentStep === "detailHistory" ||
        currentStep === "history") && (
        <BottomButton
          {...{
            title: titleBottom,
            onClick: () =>
              dispatch({
                type: "changePageNext",
                page: getNamePage(currentStep),
                isUpdate,
              }),
            disabled: disabledBottomButton,
          }}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

const getStep = ({ currentStep, state, dispatch, isApp, codeOrder }) => {
  switch (currentStep) {
    case 1:
      return <Step1 {...{ state, dispatch, isApp, codeOrder }} />;
    case 2:
      return <Step2 {...{ state, dispatch, isApp }} />;
    case 3:
      return <Step3 {...{ state, dispatch, isApp }} />;
    case 4:
      return <Step4 {...{ state, dispatch, isApp }} />;
    case 5:
      return <Step5 {...{ state, dispatch, isApp }} />;
    case "history":
      return <History {...{ state, dispatch, isApp }} />;
    case "detailHistory":
      return <DetailHistory {...{ state, dispatch, isApp }} />;
    case "notify":
      return <Notify {...{ state, dispatch, isApp }} />;

    default:
      return null;
  }
};

const getTitle = (data) => {
  switch (data) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case "detailHistory":
    case "notify":
      return "Khai giá hàng hóa";
    case "history":
      return "Lịch sử khiếu nại";
  }
};

const getTitleBottom = (data) => {
  switch (data) {
    case 1:
    case 3:
    case 4:
      return "Tiếp tục";
    case 2:
      return "Báo cáo sự cố";
    case "history":
      return "Tiếp tục";
    case "detailHistory":
      return "Quay Lại";
    case "notify":
      return "Lịch sử khiếu nại";
    case 5:
      return "Xác nhận";
  }
};

const getNamePage = (data) => {
  switch (data) {
    case 1:
    case 3:
    case 4:
    case 5:
    case 2:
      return null;
    case "history":
      return "detailHistory";
    case "detailHistory":
    case "notify":
      return "history";
  }
};
