import { makeStyles } from "@mui/styles";
import React from "react";
import rightCorner from "../../../../../assets/images/rightCorner.svg";
import { _formatMoney, convertDateHour } from "../utils";

export default function TripBox(props) {
  const { isSelect, destination, onClick, name } = props;
  const classes = useStyles();

  return (
    <>
      {(destination || []).map((item, index) => (
        <div
          onClick={() => onClick({ item, index })}
          key={index}
          id={`el${index}`}
          className={`scroll-item ${
            isSelect === index ? classes.boxSelect : classes.box
          }`}
        >
          <div className="heads">{`Chuyến đi #${
            name === "history"
              ? item?.extra_info?.codeDestination
              : item?.codeDestination
          }`}</div>
          <div className="boxLine">
            <div className="divLine line1">
              <span>Ngày khởi tạo</span>
              <span>
                {convertDateHour(
                  name === "history"
                    ? item?.extra_info?.createdDate
                    : item?.createdDate
                )}
              </span>
            </div>
            <div className="divLine line2">
              <span className="title">Thông tin điểm giao</span>
              <span className="address" style={{ overflow: "hidden" }}>
                {name === "history" ? item?.extra_info?.address : item?.address}
              </span>
            </div>
            <div className="divLine line3">
              <span>Giá trị COD</span>
              <span>
                {`${_formatMoney(
                  name === "history"
                    ? item?.extra_info?.codValue
                    : item?.codValue || 0
                )}đ`}
              </span>
            </div>
          </div>
          {isSelect === index && (
            <div className="imgCheck">
              <img src={rightCorner} alt="check" />
            </div>
          )}
        </div>
      ))}
    </>
  );
}

const useStyles = makeStyles(() => ({
  box: {
    border: "1px solid #D2D2D2",
    borderRadius: "10px",
    marginBottom: "16px",
    paddingBottom: "12px",

    "& .heads": {
      background: "#969696",
      color: "#FFFFFF",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      textAlign: "left",
      fontWeight: "600",
      fontSize: "15px",
      padding: "8px 12px",
      margin: 0,
    },

    "& .boxLine": {
      padding: "12px",
      fontSize: "15px",
      "& .divLine": {
        display: "flex",
        justifyContent: "space-between",
        color: "#000000",
      },
    },

    "& .address": { textAlign: "right", textTransform: "capitalize" },
  },

  boxSelect: {
    border: "1px solid #284078",
    borderRadius: "10px",
    paddingBottom: "12px",
    marginBottom: "16px",
    position: "relative",
    boxShadow: "0 0 8px 0 rgba(40, 64, 120, 0.25)",

    "& .heads": {
      background: "#284078",
      color: "#FFFFFF",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      textAlign: "left",
      fontWeight: "600",
      fontSize: "15px",
      padding: "8px 12px",
      margin: 0,
    },

    "& .boxLine": {
      padding: "12px",
      "& .divLine": {
        display: "flex",
        justifyContent: "space-between",
        color: "#000000",
        "& .title": {
          whiteSpace: "nowrap",
        },
        "& .address ": {
          textTransform: "capitalize",
        },
      },
    },
    "& .imgCheck": {
      position: "absolute",
      right: "-1px",
      bottom: 0,
      "& img": {
        borderBottomRightRadius: "10px",
      },
    },

    "& .address": { textAlign: "right" },
  },
}));
