import { makeStyles } from "@mui/styles";

export const styledInfo = makeStyles(() => ({
  container: {
    background: "#F8F8F8",
    padding: "16px",
    "& .title-head": {
      color: "#686868",
    },
  },

  title: {
    fontSize: "18px",
    fontWeight: "700",
    color: "black",
    margin: "12px 0",
  },

  disabledTextField: {
    marginBottom: "12px !important",

    "& .MuiInputBase-input": {
      background: "#F8F8F8 !important",
    },
  },

  unDisabledTextField: {
    // marginBottom: "12px !important",

    "& .MuiInputBase-input": {
      background: "white !important",
    },
  },

  textBlu: {
    color: "#3961A0",
    marginBottom: "12px",
  },

  choosPerson: {
    display: "flex",

    "& .self": {
      marginLeft: 0,
      marginRight: "56px",

      "& .MuiCheckbox-root": {
        marginRight: "12px",
      },
    },
    "& .relative": {
      marginLeft: 0,

      "& .MuiCheckbox-root": {
        marginRight: "12px",
      },
    },
  },

  textField: {
    marginBottom: "8px !important",
  },

  textFieldCapitalize: {
    "& input": {
      textTransform: "capitalize",
    },
  },

  inputDiv: {
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon": {
      borderRadius: "16px",
      paddingTop: "12px",
      paddingBottom: "12px",
    },

    "& .MuiInputBase-root:before": {
      border: "none !important",
    },

    "& .MuiInputBase-root:after": {
      border: "none !important",
    },

    "& .MuiInputBase-input": {
      boxSizing: "border-box",
    },

    "& .shrink": {
      position: "absolute",
      background: "white",
      padding: "0 6px",
      lineHeight: "18px",
      top: "-11px",
      left: "16px",
      color: "#284078",
      fontWeight: "500",
      fontSize: "15px",
    },

    "&:hover": {
      borderColor: "#284078",
      borderWidth: "2px",
    },
  },

  bankImg: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    background: "#E8E8E8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "8px",
    padding: "10px",
  },

  bankOption: {
    display: "flex",
    flexDirection: "column",

    "& .short-name": {
      fontWeight: "600",
    },
    "& .name": {
      fontSize: "14px",
      color: "#505151",
    },
  },

  textAuto: {
    "& .MuiInputBase-root": {
      borderRadius: "16px",
      padding: "12px !important",
    },
  },

  //
}));
