import { Pause } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getFileByUrl } from "../../../../../apis/claimLoyaltyApis";
import ShowImg from "../components/DialogShowImg";
import HeadStepper from "../components/HeadStepper";
import ShowFile from "../components/ShowFile";
import { useStyles } from "../styles/stepperStyles";

import add from "../../../../../assets/loyalty/add.svg";
import closeImg from "../../../../../assets/loyalty/close-image.svg";
import danger from "../../../../../assets/loyalty/danger.svg";
import deleteImg from "../../../../../assets/loyalty/delete-img.svg";
import fileAudio from "../../../../../assets/loyalty/file-audio.svg";
import picture from "../../../../../assets/loyalty/picture.svg";
import play from "../../../../../assets/loyalty/play-audio.svg";

function Step2({ state, dispatch }) {
  const {
    elements,
    destination: { data, index },
    errors,
    showImg,
    loadingImg,
    showFileEvidence,
    audioList,
  } = state;
  const classes = useStyles();
  const [audioState, setAudioState] = useState({});
  const ref = useRef();
  const audioRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      // Clean up audio elements on component unmount
      audioRef?.current?.pause();
    };
  }, []);

  const renderImg = [
    {
      title: "Hình ảnh hàng hoá trước khi giao",
      id: 200,
      typeCode: "goods_document_before_risk",
      listImg: (elements || []).find((item) => item.typeId === 200)?.extraInfo,
    },
    {
      title:
        "Hình ảnh hóa đơn hàng hóa/ order form/ thông tin đơn hàng trên hệ thống có thể hiện giá trị hàng hoá",
      id: 202,
      typeCode: "bill",
      listImg: (elements || []).find((item) => item.typeId === 202)?.extraInfo,
    },
  ];

  const onClickDiv = (e) => {
    ref.current?.click();
  };

  const handlePlay = async (path, index) => {
    if (audioRef.current) {
      dispatch({ type: "setPlayIcon", index, all: true });
      audioRef?.current?.pause();
    }

    if (path.includes(".amr")) {
      return message.error("Trình duyệt chưa hỗ trợ file này");
    }

    if (path != audioState?.path) {
      const dataFile = await getFileByUrl(path);
      const url = URL.createObjectURL(dataFile);
      const audio = new Audio(url);
      audio.controls = true;
      audioRef.current = audio;
      setAudioState({ ...audioState, path: path });
    }

    dispatch({ type: "setPlayIcon", value: false, index });
    audioRef?.current?.play();
    audioRef.current.onended = () => {
      dispatch({ type: "setPlayIcon", value: true, index });
    };
  };

  const handlePause = (item, index) => {
    if (audioRef?.current && item.path == audioState.path) {
      dispatch({ type: "setPlayIcon", value: true, index });
      audioRef?.current?.pause();
    }
  };

  const listImg206 =
    (elements || []).find((item) => item.typeId === 206)?.extraInfo
      ?.documents || [];

  return (
    <div className={classes.container}>
      <HeadStepper activeStep={1} />
      <div className={classes.spacing}></div>
      <div className="header">HÌNH ẢNH</div>
      <div className="warring">
        <img src={danger} alt="danger" className="icon" />
        Cung cấp ít nhất 1 hình ảnh theo các mục sau
      </div>
      {renderImg.map((i, index) => (
        <div className={classes.divImg} key={`boxImg${index}`}>
          <div className="title">
            {i.title} <span style={{ color: "red" }}>*</span>
          </div>
          <div className={classes.lineImg}>
            <div
              className={classes.boxUpload}
              onClick={() => {
                window.document.getElementById(`addImage${index}`).click();
              }}
            >
              <input
                type="file"
                hidden
                id={`addImage${index}`}
                accept="image/*"
                onChange={(e) => {
                  dispatch({
                    type: "addImg",
                    e,
                    id: i.id,
                    typeCode: i.typeCode,
                  });
                }}
              />
              <img src={picture} alt="picture" />
              <span className="note">Tải ảnh lên từ thiết bị</span>
            </div>
            {(i.listImg?.documents || []).map((item, idx) => (
              <div className={classes.boxImg} key={`img${idx}`}>
                <img
                  key={index}
                  src={item?.path}
                  alt={`picture ${index}`}
                  className="image"
                  onClick={() =>
                    dispatch({
                      type: "showImg",
                      url: item?.path,
                      typeId: i.id,
                      indexImg: idx,
                    })
                  }
                />
                <img
                  src={closeImg}
                  alt="del"
                  className="del-icon"
                  onClick={() =>
                    dispatch({
                      type: "deleteImg",
                      typeId: i.id,
                      index: idx,
                    })
                  }
                />
              </div>
            ))}
            {i.id === 200 && !!loadingImg?.i200 && (
              <CircularProgress size={24} sx={{ m: 1, color: "#284078" }} />
            )}
            {i.id === 202 && !!loadingImg?.i202 && (
              <CircularProgress size={24} sx={{ m: 1, color: "#284078" }} />
            )}
          </div>
          {errors.includes("img200", "img202") && (
            <div className={classes.error}>Vui lòng thêm ít nhất 1 ảnh</div>
          )}
        </div>
      ))}
      <div className="header">BẰNG CHỨNG NỖ LỰC TRUY TÌM TÀI XẾ</div>
      <div className="warring">
        <img src={danger} alt="danger" className="icon" />
        Cung cấp ít nhất 1 trong 3 bằng chứng dưới đây
      </div>
      <div className={classes.divImg}>
        <div className="title">File hình ảnh</div>
        <div className={classes.lineImg}>
          <div
            className={classes.boxUpload}
            onClick={() => {
              window.document.getElementById(`addImage-evidence`).click();
            }}
          >
            <input
              type="file"
              hidden
              id={`addImage-evidence`}
              accept="image/*"
              onChange={(e) => {
                dispatch({
                  type: "addImgEvidence",
                  e,
                  typeCode: "file_evidence_look_for_driver",
                  nameType: "evidence_image",
                });
              }}
            />
            <img src={picture} alt="picture" />
            <span className="note">Tải ảnh lên từ thiết bị</span>
          </div>
          {(listImg206.filter((i) => i.type === "evidence_image") || []).map(
            (item, idx) => (
              <div className={classes.boxImg} key={`img${idx}`}>
                <img
                  key={index}
                  src={item?.path}
                  alt={`picture ${index}`}
                  className="image"
                  onClick={() =>
                    dispatch({
                      type: "showImg",
                      url: item?.path,
                      typeId: 206,
                      indexImg: idx,
                    })
                  }
                />
                <img
                  src={closeImg}
                  alt="del"
                  className="del-icon"
                  onClick={() =>
                    dispatch({
                      type: "deleteEvidence",
                      url: item?.path,
                    })
                  }
                />
              </div>
            )
          )}
          {!!loadingImg?.evidence_image206 && (
            <CircularProgress size={24} sx={{ m: 1, color: "#284078" }} />
          )}
        </div>
        {errors.includes("img206") && (
          <div className={classes.error}>Vui lòng upload hình ảnh</div>
        )}
      </div>
      <div className={classes.divImg}>
        <div className="title">File ghi âm</div>
        <div onClick={onClickDiv}>
          <input
            ref={ref}
            hidden
            type="file"
            id="audio"
            accept="audio/*"
            onChange={(e) => {
              dispatch({
                type: "addImgEvidence",
                e,
                typeCode: "file_evidence_look_for_driver",
                nameType: "evidence_audio",
              });
            }}
          />
          <img src={add} alt="add" /> Tải lên
          {!!loadingImg?.evidence_audio206 && (
            <CircularProgress size={15} sx={{ ml: 1, color: "#284078" }} />
          )}
        </div>
        <div className="upload">
          {(audioList || []).map((item, index) => (
            <div className="row" key={index}>
              <div className={`${item?.play ? "" : "text-audio"}`}>
                <img src={fileAudio} alt="file" /> {item?.originalname || ""}
              </div>
              <div className="row-audio">
                {item?.play ? (
                  <>
                    <img
                      src={play}
                      alt="play"
                      onClick={() => handlePlay(item.path, index)}
                    />
                  </>
                ) : (
                  <Pause
                    sx={{ fontSize: 20 }}
                    onClick={() => handlePause(item, index)}
                  />
                )}
                <img
                  src={deleteImg}
                  alt="del"
                  style={{ marginLeft: 8 }}
                  onClick={() =>
                    dispatch({
                      type: "deleteEvidence",
                      url: item?.path,
                    })
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.divImg}>
        <div className="title">File tài liệu</div>
        <div
          onClick={() => {
            window.document.getElementById(`addFile-evidence`).click();
          }}
        >
          <input
            type="file"
            hidden
            id={`addFile-evidence`}
            accept={
              "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.ppt, .pptx"
            }
            onChange={(e) => {
              dispatch({
                type: "addImgEvidence",
                e,
                typeCode: "file_evidence_look_for_driver",
                nameType: "evidence_file",
              });
            }}
          />
          <img src={add} alt="add" /> Tải lên
          {!!loadingImg?.evidence_file206 && (
            <CircularProgress size={15} sx={{ ml: 1, color: "#284078" }} />
          )}
        </div>
        <div className="upload">
          {(listImg206.filter((i) => i.type === "evidence_file") || []).map(
            (i, idx) => (
              <div className="row" key={idx}>
                <div
                  onClick={() => dispatch({ type: "showEvidence", file: i })}
                >
                  <img src={fileAudio} alt="file" /> {i?.originalname || ""}
                </div>
                <img
                  src={deleteImg}
                  alt="del"
                  onClick={() =>
                    dispatch({
                      type: "deleteEvidence",
                      url: i?.path,
                    })
                  }
                />
              </div>
            )
          )}
        </div>
      </div>

      <ShowImg {...{ dispatch, showImg }} />
      <ShowFile
        open={!!showFileEvidence?.open}
        link={showFileEvidence?.link || ""}
        onClose={() => dispatch("closeShowEvidence")}
      />
    </div>
  );
}

export default Step2;
