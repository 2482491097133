import React, { useRef } from "react";
import _ from "lodash";
import { IconButton, Backdrop, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

import { Button, Modal } from "antd";

export default function BoxImage(props) {
  const [visible, setVisible] = React.useState(false);
  const { url, isNew, isMargin, onClick, deleteImg, isShow, onLoading } = props;
  const isIcon = !_.isEmpty(url);
  const ref = useRef();

  const onClickDiv = (e) => {
    ref.current?.click();
  };

  const showImage = () => {
    setVisible(true);
  };

  return (
    <>
      <div
        style={{ ...box, marginRight: isMargin ? "16px" : 0 }}
        // onClick={onClickDiv}
      >
        {isIcon && !isShow && (
          <IconButton sx={iconCancal} onClick={() => deleteImg()}>
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
        {!isNew ? (
          isIcon && (
            <img
              src={url}
              style={{ width: "100%", height: "100%", borderRadius: "13px" }}
              onClick={showImage}
            />
          )
        ) : (
          <>
            <Backdrop
              style={{
                zIndex: 99,
                color: "#fff",
                position: "absolute",
                borderRadius: "5px",
              }}
              open={!!onLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <input
              id="uploadFile"
              type="file"
              onChange={(e) => {
                onClick(e);
                e.target.value = null;
              }}
              ref={ref}
              hidden
            />
            <IconButton onClick={onClickDiv}>
              <AddIcon />
            </IconButton>
          </>
        )}
      </div>
      <Modal
        mask={false}
        visible={visible}
        footer={
          <Button
            onClick={() => {
              setVisible(false);
            }}
          >
            Đóng
          </Button>
        }
        bodyStyle={{
          padding: "35px 20px 5px",
          background: "transparent",
        }}
        centered
        onCancel={() => {
          setVisible(false);
        }}
      >
        <img
          src={url}
          style={{ width: "100%", height: "100%", borderRadius: "13px" }}
        />
      </Modal>
    </>
  );
}

const box = {
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#0B3C8A",
  width: "70px",
  height: "70px",
  border: "0.5px dashed rgba(0, 0, 0, 0.19)",
  borderRadius: "13px",
  backgroundColor: "#F8F8F8",
};

const iconCancal = {
  position: "absolute",
  background: "#CFCFCF",
  width: "16px",
  top: "-8px",
  height: "16px",
  right: "-8px",
  "&:hover": {
    backgroundColor: "#CFCFCF",
  },
};
