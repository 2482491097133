import api from "../configs/api";

export function getInforOrder({
  params,
  codeOrder,
  gc_order_code,
  phone,
  partner,
}) {
  return api
    .apiForm(
      "POST",
      `/api/v2/cert/partner/ahamove/detail?code=${codeOrder}&gc_order_code=${gc_order_code}&phone=${phone}&partner=${partner}`,
      params
    )
    .then((res) => res)
    .catch((error) => error);
}

export function getHistoryOrder({
  params,
  codeOrder,
  gc_order_code,
  phone,
  partner,
}) {
  return api
    .apiForm(
      "POST",
      `/api/v2/claim/partner/ahamove/list?code=${codeOrder}&gc_order_code=${gc_order_code}&phone=${phone}&partner=${partner}`,
      params
    )
    .then((res) => res)
    .catch((error) => error);
}

export function getListBank() {
  return api
    .apiForm("POST", `/api/v2/bank/list`, {
      limit: 1000,
      //  "offset": lấy từ vị trí
    })
    .then((res) => res)
    .catch((error) => error);
}

export function uploadImg({
  params,
  codeOrder,
  gc_order_code,
  phone,
  partner,
}) {
  return api
    .apiForm(
      "POST",
      `/api/v2/document/upload/cdn?code=${codeOrder}&gc_order_code=${gc_order_code}&phone=${phone}&partner=${partner}`,
      params
    )
    .then((res) => res)
    .catch((error) => error);
}

export function uploadImgAhamove({
  params,
  codeOrder,
  gc_order_code,
  phone,
  partner,
}) {
  return api
    .apiForm(
      "POST",
      `/api/v2/document/partner/ahamove/upload/cdn?code=${codeOrder}&gc_order_code=${gc_order_code}&phone=${phone}&partner=${partner}`,
      params
    )
    .then((res) => res)
    .catch((error) => error);
}

export function uploadUrlImg({
  params,
  codeOrder,
  gc_order_code,
  phone,
  partner,
}) {
  return api
    .apiForm(
      "POST",
      `/api/v2/document/partner/ahamove/upload/cdn/url?code=${codeOrder}&gc_order_code=${gc_order_code}&phone=${phone}&partner=${partner}`,
      params
    )
    .then((res) => res)
    .catch((error) => error);
}

export function uploadImg_base64({
  params,
  codeOrder,
  gc_order_code,
  phone,
  partner,
}) {
  return api
    .apiForm(
      "POST",
      `/api/v2/document/partner/ahamove/upload/cdn/image-base64?code=${codeOrder}&gc_order_code=${gc_order_code}&phone=${phone}&partner=${partner}`,
      params
    )
    .then((res) => res)
    .catch((error) => error);
}

export function create({ params, codeOrder, gc_order_code, phone, partner }) {
  return api
    .apiForm(
      "POST",
      `/api/v2/claim/partner/ahamove?code=${codeOrder}&gc_order_code=${gc_order_code}&phone=${phone}&partner=${partner}`,
      params
    )
    .then((res) => res)
    .catch((error) => error);
}

export function getListIndemnify(
  systemType = "globalcare",
  { params, codeOrder, gc_order_code, phone, partner }
) {
  return api
    .apiForm(
      "POST",
      `/api/v2/claim/${systemType}/list?code=${codeOrder}&gc_order_code=${gc_order_code}&phone=${phone}&partner=${partner}`,
      params
    )
    .then((res) => res)
    .catch((error) => error);
}

export function getDetailIndemnify(
  systemType = "globalcare",
  { id, codeOrder, gc_order_code, phone, partner }
) {
  return api
    .apiForm(
      "GET",
      `/api/v2/claim/${systemType}/${id}/detail?code=${codeOrder}&gc_order_code=${gc_order_code}&phone=${phone}&partner=${partner}`
    )
    .then((res) => res)
    .catch((error) => error);
}

export function updataDetail(
  action,
  { params, codeOrder, gc_order_code, phone, partner }
) {
  return api
    .apiForm(
      "PUT",
      `/api/v2/claim/globalcare/add-elements/${action}?code=${codeOrder}&gc_order_code=${gc_order_code}&phone=${phone}&partner=${partner}`,
      params
    )
    .then((res) => res)
    .catch((error) => error);
}
export function getDetailClaimIntransit({
  id,
  codeOrder,
  gc_order_code,
  phone,
  partner,
}) {
  return api
    .apiForm(
      "GET",
      `/api/v2/claim/client/${id}/detail?code=${codeOrder}&gc_order_code=${gc_order_code}&phone=${phone}&partner=${partner}`
    )
    .then((res) => res)
    .catch((error) => error);
}

export function getFileByUrl(url) {
  return api
    .apiGetFileByUrl(url)
    .then((res) => res.data)
    .catch((error) => error);
}
