import { makeStyles } from "@mui/styles";

export const styleTabsRight = makeStyles(() => ({
  containerStatus: {
    padding: "12px",
  },

  // timeLine: {
  //   padding: 0,
  //   margin: 0,
  //   display: "flex",
  //   alignItems: "flex-start",
  // },

  step: {
    "& .MuiStepContent-root.MuiStepContent-last": {
      borderLeft: "1px dashed #bdbdbd",
    },

    "& .MuiStepContent-root": {
      borderLeft: "1px dashed #bdbdbd",
    },

    "& .status-detail": {
      backgroundColor: "#E3F3FF",
      padding: "3px",
      width: "30px",
      height: "30px",

      "& path": {
        stroke: "#0B7CE4",
      },
    },
  },

  titile: {
    color: "#4B4B4B",
    fontWeight: "700",
  },

  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 13,

    "& .box-button": {
      width: "60%",
      display: "flex",
    },

    "& .button": {
      textTransform: "none",
      color: "#4B4B4B",
      border: "0.5px solid #A0A0A0",
      borderRadius: 0,
      fontSize: "13px",
    },
    "& .icon": {
      color: "#6685B6",
    },

    "& .color": {
      color: "#6685B6",
    },

    "& .btn-new": {
      backgroundColor: "#E3F3FF",
    },

    "& .btn-new": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },

    "& .btn-approve": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
    },

    "& .btn-active": {
      backgroundColor: "#E3F3FF",
    },

    "& .refuse": {
      textTransform: "none",
      marginRight: 8,
      padding: "2px 15px",
      color: "#3961A0",
      border: "1px solid #3961A0",
    },

    "& .refuse-disabled": {
      color: "#A0A0A0 !important",
      border: "1px solid #A0A0A0 !important",
    },

    "& .agree": {
      textTransform: "none",
      color: "white",
      border: 1,
      padding: "2px 15px",
      backgroundColor: "#3961A0",
    },

    "& .agree-disabled": {
      color: "white !important",
      backgroundColor: "#A0A0A0 !important",
    },
  },

  box: {
    fontSize: 14,
    padding: 10,
    margin: "10px 0",
    borderRadius: 5,
    lineHeight: 2,
    border: "1px solid #A0A0A0",
    "& .bold": {
      fontWeight: "bold",
    },
    "& .gray": {
      color: "#A0A0A0",
      fontStyle: "italic",
    },
    "& .color": {
      color: "#6685B6",
      fontStyle: "italic",
    },
    "& .spacing": {
      marginBottom: 10,
    },
  },

  boxAdd: {
    width: "35%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 14,
    cursor: "pointer",
    "& .icon": {
      width: 20,
      height: 20,
      marginRight: 5,
      color: "#6685B6",
    },
    "& .color": {
      color: "#6685B6",
    },
  },

  // dialog: {
  //   "& .MuiPaper-root.MuiPaper-elevation": {
  //     borderRadius: "12px",
  //     maxWidth: "500px",
  //     margin: 0,
  //     padding: 20,
  //   },
  //   "& .MuiInputBase-multiline": {
  //     padding: "10px !important",
  //     fontSize: "14px !important",
  //   },
  //   "& .row": {
  //     display: "flex",
  //     flexDirection: "row",
  //     justifyContent: "center",
  //     marginTop: 15,
  //   },

  //   "& .row-space": {
  //     display: "flex",
  //     flexDirection: "row",
  //     justifyContent: "space-between",
  //     alignItems: "center",
  //     marginTop: 15,
  //   },
  //   "& .input": {
  //     paddingTop: 20,
  //   },
  //   "& .header": {
  //     fontSize: 15,
  //     color: "#6685B6",
  //     fontWeight: "bold",
  //   },
  //   "& .cancel": {
  //     textTransform: "none",
  //     marginRight: 8,
  //     padding: "2px 15px",
  //   },
  //   "& .agree": {
  //     textTransform: "none",
  //     color: "#fff",
  //     border: 0.5,
  //     padding: "2px 15px",
  //   },
  //   "& .border-blue": {
  //     color: "#3961A0",
  //     border: "0.5px solid #3961A0",
  //   },
  //   "& .blue": {
  //     backgroundColor: "#3961A0",
  //   },
  // },

  iconStatus: {
    width: "24px",
    height: "24px",
  },

  //
}));
