import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import React, { useEffect } from "react";
import sign from "../../../../../assets/health/sign.png";
import stamp from "../../../../../assets/health/stamp.png";
import LogoGC from "../../../../../assets/lauyout/logo-gc.png";
import check_signature_file from "../../../../../assets/health/check_signature_pdfFile.png";

import {
  convertCurrency,
  formatDate,
  renderRelationship,
  typeOfIndimnity,
} from "../utils";

Font.register({
  family: "times-new-roman",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman@1.0.4/Times New Roman.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-bold@1.0.4/Times New Roman Bold.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/times-new-roman-italic@1.0.4/Times New Roman Italic.ttf",
      fontStyle: "italic",
    },
  ],
});

export default function ExportPDF({ detail = {} }) {
  const section_report_tt_chuyen_khoan = [
    {
      label: "Họ và tên chủ tài khoản: ",
      value:
        detail?.extraInfo?.beneficiaryName ||
        detail?.insuredInfo?.beneficiaryName ||
        "",
    },
    {
      label: "Mối quan hệ với người được BH: ",
      value: renderRelationship(
        detail?.insuredInfo?.relationshipWithInsuredPersonId
      ),
    },
    {
      label: "Ngân hàng: ",
      value:
        detail?.extraInfo?.beneficiaryBank ||
        detail?.extraInfo?.beneficiaryBankTitle ||
        detail?.insuredInfo?.beneficiaryBankTitle ||
        "",
    },

    {
      label: "Chi nhánh: ",
      value:
        detail?.extraInfo?.beneficiaryBankBranchName ||
        detail?.insuredInfo?.beneficiaryBankBranchName ||
        "",
    },

    {
      label: "Số tài khoản: ",
      value:
        detail?.extraInfo?.beneficiaryBankAcountNumber ||
        detail?.insuredInfo?.beneficiaryBankAcountNumber ||
        "",
    },
  ];

  useEffect(() => {
    const canvasElement = window.document.querySelectorAll("#phatdt");
    if (canvasElement) {
      // canvasElement.style.objectFit = "contain";
    }
  }, []);

  return (
    <Document style={{ scale: 0.1 }}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section_report}>
          <View style={styles.section_row}>
            {/* <Image
              src={LogoGC}
              style={{
                width: "100%",
                maxWidth: "15%",
              }}
            /> */}
            <Text></Text>
            <Text>
              {`TPHCM, ngày ${moment().format("DD")} tháng ${moment().format(
                "MM"
              )} năm ${moment().format("YYYY")}`}
            </Text>
          </View>
          <Text
            style={{
              textAlign: "center",
              fontSize: 14,
              fontWeight: "bold",
              marginTop: "12px",
            }}
          >
            {`THÔNG BÁO ${!detail?.amount ? "TỪ CHỐI " : ""}BỒI THƯỜNG`}
          </Text>
          <Text
            style={{ textAlign: "center", marginBottom: 15 }}
          >{`Số hồ sơ bồi thường: ${detail?.code || ""}`}</Text>
          <View style={styles.break}>
            <View style={styles.flex_row}>
              <Text>Kính gửi: Quý khách hàng,</Text>
              <Text style={{ textTransform: "capitalize" }}>
                {detail?.insuredInfo?.insuredName || ""}
              </Text>
            </View>
          </View>
          <Text style={styles.break}>
            Công ty {`${detail?.organizationTitle || ""}`} thông báo đến Quý
            khách hàng thông tin hồ sơ yêu cầu trả tiền bảo hiểm sau đây:
          </Text>
          <View style={styles.section_list}>
            <Text style={styles.flex_row}>
              <Text> - Người được bảo hiểm: </Text>
              <Text style={{ textTransform: "capitalize" }}>
                {detail?.insuredInfo?.insuredName || ""}
              </Text>
            </Text>
            <Text>
              - Số hợp đồng bảo hiểm:{" "}
              {detail?.certNum || detail?.cert_num || ""}
            </Text>
            <Text>
              - Thời hạn bảo hiểm: từ {formatDate(detail?.beginDate)} đến{" "}
              {formatDate(detail?.endDate)}
            </Text>
            <Text>
              - Đơn vị tham gia bảo hiểm/ mã đơn hàng bảo hiểm:{" "}
              {detail?.certNum || ""}
            </Text>
            <Text>
              - Số tiền yêu cầu chi trả:{" "}
              {convertCurrency(detail?.requestAmount)}
            </Text>
          </View>
          <Text style={styles.break}>
            {detail?.amount
              ? "Sau khi xem xét hồ sơ yêu cầu chi trả bảo hiểm của Quý khách, chúng tôi thông báo chi trả bảo hiểm cho Quý khách như sau:"
              : "Sau khi xem xét chi tiết hồ sơ, bộ phận Bồi thường Global Care rất tiếc gửi đến Quý khách thông báo từ chối bồi thường cho hồ sơ yêu cầu thanh toán tiền bảo hiểm của Quý khách như sau:"}
          </Text>
          <View style={styles.break}>
            <View style={styles.flex_row}>
              <Text style={styles.table_cell}>Loại bồi thường</Text>
              <Text style={styles.table_cell}>Số tiền YCBT</Text>
              <Text style={styles.table_cell}>Số tiền không thanh toán</Text>
              <Text style={styles.table_cell}>Số tiền được bồi thường</Text>
            </View>
            <View style={styles.flex_row}>
              <Text style={styles.table_cell}>
                {typeOfIndimnity.find(
                  (i) => i.id == detail?.extraInfo?.typeOfIndimnity
                )?.title || ""}
              </Text>
              <Text style={styles.table_cell}>
                {convertCurrency(detail?.requestAmount)}
              </Text>
              <Text style={styles.table_cell}>
                {(detail?.requestAmount || 0) - (detail?.amount || 0) < 0
                  ? 0
                  : convertCurrency(
                      (detail?.requestAmount || 0) - (detail?.amount || 0)
                    )}
              </Text>
              <Text style={styles.table_cell}>
                {convertCurrency(detail?.amount)}
              </Text>
            </View>
            <View style={styles.flex_row}>
              <Text style={styles.table_cell}>Tổng cộng</Text>
              <Text style={styles.table_cell}>
                {convertCurrency(detail?.requestAmount)}
              </Text>
              <Text style={styles.table_cell}>
                {(detail?.requestAmount || 0) - (detail?.amount || 0) < 0
                  ? 0
                  : convertCurrency(
                      (detail?.requestAmount || 0) - (detail?.amount || 0)
                    )}
              </Text>
              <Text style={styles.table_cell}>
                {convertCurrency(detail?.amount)}
              </Text>
            </View>
          </View>
          <Text style={styles.break}>
            Diễn giải (nếu có):{" "}
            {detail.elements?.find(
              (item) => item.type_id == 521 && ![2].includes(item?.status_id)
            )?.text || ""}
          </Text>
          <Text style={styles.break}>
            Số tiền trên sẽ được chuyển vào tài khoản theo thông tin sau:
          </Text>
          <View style={styles.section_list}>
            {section_report_tt_chuyen_khoan.map((item, index) => (
              <Text key={index}>
                + {item.label} {item.value}
              </Text>
            ))}
          </View>
          <Text style={styles.break}>
            Quý khách vui lòng kiểm tra tài khoản sau 3 ngày kể từ ngày nhận
            được thông báo này.
          </Text>
          <Text style={styles.break}>
            Nếu có bất kỳ thắc mắc nào liên quan đến việc giải quyết chi trả bảo
            hiểm nêu trên hoặc cần thêm thông tin, Quý khách vui lòng liên hệ
            với chúng tôi để được giải đáp.
          </Text>
          <Text style={styles.break}>Trân trọng.</Text>
          <View style={{ ...styles.break, position: "relative" }}>
            <Text style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              ​CÔNG TY {`${detail?.organizationTitle || ""}`}
            </Text>
            <Text style={{ marginTop: "8px" }}>Người phê duyệt</Text>
            <Text style={{ textTransform: "capitalize" }}>
              {detail?.userFullnameAssign || ""}
            </Text>
            <Image
              style={styles.img_check}
              src={check_signature_file}
              cache={false}
            />
          </View>
          {/* <View style={styles.break}>
            <Image style={styles.sign} src={sign} cache={false} />
            <Image style={styles.stamp} src={stamp} cache={false} />
          </View>
          <View style={{ marginTop: 60 }}>
            <Text style={{ fontWeight: "bold" }}>
              ​CÔNG TY CP TƯ VẤN GLOBAL CARE
            </Text>
            <Text style={{ fontStyle: "italic" }}>
              Office: (+84 28) 7307 7878 | Hotline: 1900 29 29 87
            </Text>
            <Text style={{ fontStyle: "italic" }}>
              1/2 đường 33, P.An Khánh, TP.Thủ Đức.
            </Text>
          </View> */}
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "times-new-roman",
    padding: "16px",
    fontSize: 12,
  },
  section_report: {
    margin: 30,
    lineHeight: 1.2,
  },

  section_row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  section_list: {
    marginLeft: 15,
  },
  break: {
    marginTop: 8,
  },
  table_cell: {
    width: "25%",
    padding: "8px",
    color: "black",
    border: "0.5px solid #686868",
  },
  stamp: { width: "15%" },
  sign: {
    width: "40%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "10%",
  },
  img_check: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "22%",
    left: "13%",
    opacity: "0.7",
  },
  flex_row: {
    display: "flex",
    flexDirection: "row",
  },
});
