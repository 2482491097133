import { accountDetail } from "../styles/styleDetailAccount";
import { TextField, Switch } from "../overrideMui/index";
import {
  Autocomplete,
  Button,
  MenuItem,
  Popper,
  Paper,
  ListboxComponent,
} from "@mui/material";
import moment from "moment";

import { userType, accountType } from "../util";
import { debounce, get, isEmpty } from "lodash";
import { useEffect, useState } from "react";

export default function TypeAccountDetail(props) {
  const [scrollPosition, setScrollPosition] = useState({});

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const userTypeFromProfile = get(user, "extra_info.userType", "") || "";
  const isAdminGC =
    (Boolean(
      get(user, "role_management_claim.is_admin", null) &&
        get(user, "role_management_claim.is_root", null) !== 1
    ) ||
      get(user, "role_management_claim.privileges.ACCOUNT_MANAGE", null) ===
        1) &&
    get(user, "extra_info.userType", "") === "globalcare" &&
    get(user, "role_management_claim.is_root", null) !== 1;
  const classes = accountDetail();
  const { state, dispatch, userId } = props;

  const {
    payloadAcount,
    listOrg,
    details,
    listMerchants,
    errors,
    loadingAutocomplete,
    payloadListAutocomplete,
  } = state;

  const disabelTypeAccount =
    (isAdminGC && payloadAcount?.extra_info?.userType === "globalcare") ||
    (userTypeFromProfile === "tpag" &&
      ["tpag"].includes(payloadAcount?.extra_info?.userType)) ||
    (userTypeFromProfile === "tpas" &&
      ["tpas"].includes(payloadAcount?.extra_info?.userType)) ||
    (userTypeFromProfile === "tpa" &&
      ["tpa"].includes(payloadAcount?.extra_info?.userType)) ||
    ["merchant", "provider"].includes(userTypeFromProfile);

  const disabelBelongCompany =
    (isAdminGC && payloadAcount?.extra_info?.userType === "globalcare") ||
    (userTypeFromProfile === "tpag" &&
      ["tpag"].includes(payloadAcount?.extra_info?.userType)) ||
    (userTypeFromProfile === "tpa" &&
      payloadAcount?.extra_info?.userType !== "merchant") ||
    (userTypeFromProfile === "tpas" &&
      payloadAcount?.extra_info?.userType === "tpas") ||
    [("merchant", "provider")].includes(userTypeFromProfile);

  const newUserType = () => {
    //nếu tk là admin gc và root
    if (
      Boolean(get(user, "role_management_claim.is_root", null) || null) ||
      isAdminGC
    )
      return userType;

    // nếu là tài khoản tpag
    if (userTypeFromProfile === "tpag")
      return userType.filter(
        (i) => !["globalcare", "tpa", "bvpk", "provider"].includes(i.value)
      );

    // nếu là tài khoản tpas
    if (userTypeFromProfile === "tpas")
      return userType.filter((i) => ["tpas", "merchant"].includes(i.value));

    // nếu là tài khoản tpa
    if (userTypeFromProfile === "tpa")
      return userType.filter((i) => ["tpa", "merchant"].includes(i.value));

    // nếu là tài khoản merchant
    if (userTypeFromProfile === "merchant")
      return userType.filter((i) => ["merchant"].includes(i.value));

    // nếu là tài khoản provider
    if (userTypeFromProfile === "provider")
      return userType.filter((i) => ["provider"].includes(i.value));

    return [];
  };

  const getValueAutocomplete = (id, name) => {
    if (name === "org_id") {
      const temp = listOrg.find((i) => i.id == id);
      return payloadListAutocomplete?.org?.param?.title
        ? { title: payloadListAutocomplete?.org?.param?.title }
        : isEmpty(temp)
        ? null
        : temp;
    }

    const tempProvider = listMerchants.find((i) => i.id == id);

    return payloadListAutocomplete?.provider?.param?.keyword
      ? { title: payloadListAutocomplete?.provider?.param?.keyword }
      : isEmpty(tempProvider)
      ? null
      : tempProvider;
  };

  const onScrollListOrg = () => {
    const a1 = document.getElementsByClassName("div-scroll");
    if (
      a1.length > 0 &&
      +payloadListAutocomplete?.org?.page * 10 <
        +payloadListAutocomplete?.org?.total
    ) {
      const scrollDiv = a1[0];
      const listItems = document.querySelectorAll(".scroll-item");
      const lastItem = listItems[listItems.length - 1] || null;
      if (
        Math.abs(lastItem?.offsetTop - scrollDiv.scrollTop + 70) <
          lastItem?.offsetHeight + scrollDiv.offsetHeight &&
        !loadingAutocomplete?.org
      ) {
        dispatch("getMoveListOrg");
        setScrollPosition({ ...scrollPosition, org: scrollDiv.scrollHeight });
      }
    }
  };

  const onScrollListProvider = () => {
    const a1 = document.getElementsByClassName("div-provider-scroll");
    if (
      a1.length > 0 &&
      +payloadListAutocomplete?.provider?.page * 10 <
        +payloadListAutocomplete?.provider?.total
    ) {
      const scrollDiv = a1[0];
      const listItems = document.querySelectorAll(".scroll-provider-item");
      const lastItem = listItems[listItems.length - 1] || null;
      if (
        Math.abs(lastItem?.offsetTop - scrollDiv.scrollTop + 70) <
          lastItem?.offsetHeight + scrollDiv.offsetHeight &&
        !loadingAutocomplete?.provider
      ) {
        dispatch("getMoveListProvider");
        setScrollPosition({
          ...scrollPosition,
          provider: scrollDiv.scrollHeight,
        });
      }
    }
  };

  useEffect(() => {
    const a1 = document.getElementsByClassName("div-scroll");
    if (a1.length > 0) {
      const element_scroll = a1[0];
      element_scroll.scrollTop = scrollPosition?.org || 0;
    }
  }, [loadingAutocomplete?.org]);

  useEffect(() => {
    const a2 = document.getElementsByClassName("div-provider-scroll");
    if (a2.length > 0) {
      const element_scroll_provider = a2[0];
      // element_scroll_provider.scrollTop = 1000 || 0;
      element_scroll_provider.scrollTop = scrollPosition?.provider || 0;
    }
  }, [loadingAutocomplete?.provider]);

  return (
    <div className={classes.containerTypeAccount}>
      <div className={classes.typeAccount}>
        <div className="title">Chọn loại tài khoản</div>
        <div className="label-select">Thuộc tài khoản</div>
        <TextField
          select
          fullWidth
          disabled={
            Boolean(userId !== "new") ||
            ["merchant", "provider"].includes(userTypeFromProfile)
          }
          // sx={{ maxWidth: "300px" }}
          value={payloadAcount?.extra_info?.userType || ""}
          onChange={(e) =>
            dispatch({
              type: "onChangeTypeAccount",
              value: e.target.value,
              name: "userType",
            })
          }
        >
          {newUserType()?.map((item, index) => (
            <MenuItem key={`key_select_${index}`} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
        <div className="label-select">Loại tài khoản</div>
        <TextField
          select
          fullWidth
          disabled={Boolean(disabelTypeAccount)}
          // sx={{ maxWidth: "300px" }}
          value={
            payloadAcount?.extra_info?.user_title_id ||
            payloadAcount?.extra_info?.user_title ||
            ""
          }
          onChange={(e) =>
            dispatch({
              type: "onChangeTypeAccount",
              value: e.target.value,
              name: "user_title_id",
            })
          }
        >
          {accountType.map((item, index) => (
            <MenuItem key={`key_select_${index}`} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>

        <>
          <div className="label-select">Thuộc công ty </div>
          <Autocomplete
            id="autocomple_org_id"
            key={"org"}
            value={
              getValueAutocomplete(
                payloadAcount?.extra_info?.org_id,
                "org_id"
              ) || null
            }
            options={listOrg}
            disabled={
              disabelBelongCompany ||
              (Boolean(userId !== "new") && !!details?.extra_info?.org_id)
            }
            getOptionLabel={(i) => i?.title || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Nhập thông tin"
                error={errors.includes("org_id")}
                size="small"
              />
            )}
            onChange={(e, value, reason) => {
              if (reason === "selectOption") {
                dispatch({
                  type: "onChangeTypeAccount",
                  value: value,
                  name: "org_id",
                });
              }

              if (reason === "clear") {
                dispatch({ type: "onInputChangeOrg", value: "" });
              }
            }}
            noOptionsText={
              <div>
                {loadingAutocomplete?.org
                  ? "Đang tải thêm ... "
                  : "Không có dữ liệu"}
              </div>
            }
            onInputChange={debounce((e, value, reason) => {
              if (reason === "input") {
                dispatch({ type: "onInputChangeOrg", value });
              }
            }, 600)}
            ListboxComponent={(paramsList) => (
              <div
                {...paramsList}
                className={`${paramsList.className} div-scroll`}
                onScroll={onScrollListOrg}
              >
                {paramsList?.children?.map((i) => (
                  <li
                    {...i?.props}
                    key={i?.key}
                    className={`${i?.props?.className} scroll-item`}
                  />
                ))}
                {loadingAutocomplete?.org && (
                  <div
                    style={{
                      fontWeight: "600",
                      color: "#0B7CE4",
                      width: "100%",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                  >
                    Đang tải thêm ...{" "}
                  </div>
                )}
              </div>
            )}
            PopperComponent={(props) => {
              return (
                <div
                  {...props}
                  className={`${props.className} ${classes.autocomplete}`}
                  style={{
                    width:
                      document.getElementById("autocomple_org_id").offsetWidth +
                      50,
                    position: "absolute",
                    display: props.open ? "" : "none",
                  }}
                  placement={"bottom-start"}
                >
                  {props.children}
                </div>
              );
            }}
          />
        </>

        {payloadAcount?.extra_info?.userType === "provider" && (
          <>
            <div className="label-select">Kênh / Công ty provider</div>
            <Autocomplete
              key={"provider"}
              id="autocomple_provider_id"
              value={
                getValueAutocomplete(payloadAcount?.extra_info?.providerId) ||
                null
              }
              options={listMerchants}
              disabled={
                (Boolean(userId !== "new") &&
                  !!details?.extra_info?.providerId) ||
                userTypeFromProfile === "provider"
              }
              getOptionLabel={(i) =>
                i?.code ? i?.title + " ( " + i?.code + " ) " : i?.title || ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Nhập thông tin"
                  size="small"
                  error={errors.includes("providerId")}
                />
              )}
              onChange={(e, value, reason) => {
                if (reason === "selectOption") {
                  dispatch({
                    type: "onChangeTypeAccount",
                    value: value?.id,
                    name: "providerId",
                  });
                }

                if (reason === "clear") {
                  dispatch({ type: "onInputChangeProvider", value: "" });
                }
              }}
              noOptionsText={
                <div>
                  {loadingAutocomplete?.provider
                    ? "Đang tải thêm ... "
                    : "No options"}
                </div>
              }
              onInputChange={debounce((e, value, reason) => {
                if (reason === "input") {
                  dispatch({ type: "onInputChangeProvider", value });
                }
              }, 600)}
              ListboxComponent={(paramsList) => (
                <div
                  {...paramsList}
                  className={`${paramsList.className} div-provider-scroll`}
                  onScroll={onScrollListProvider}
                >
                  {paramsList?.children?.map((i) => (
                    <li
                      {...i?.props}
                      key={i?.key}
                      className={`${i?.props?.className} scroll-provider-item`}
                    />
                  ))}
                  {loadingAutocomplete?.provider && (
                    <div
                      style={{
                        fontWeight: "600",
                        color: "#0B7CE4",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      Đang tải thêm ...{" "}
                    </div>
                  )}
                </div>
              )}
              PopperComponent={(props) => {
                return (
                  <div
                    {...props}
                    className={`${props.className} ${classes.autocomplete}`}
                    style={{
                      width:
                        document.getElementById("autocomple_provider_id")
                          .offsetWidth + 50,
                      position: "absolute",
                      display: props.open ? "" : "none",
                    }}
                    placement={"bottom-start"}
                  >
                    {props.children}
                  </div>
                );
              }}
            />
          </>
        )}
      </div>
      <div className={classes.status}>
        <div className="title">Trạng thái</div>
        <div className="switch">
          <span className="label-switch">Trạng thái hoạt động </span>
          <Switch
            // disabled={Boolean(userId === "new")}
            checked={Boolean(payloadAcount?.status)}
            onChange={(e) =>
              dispatch({ type: "changeStatus", value: e.target.checked })
            }
          />
        </div>
        <div className="label-date">Ngày tạo </div>
        <div className="date">
          {userId === "new"
            ? moment().format("DD/MM/YYYY, HH:mm")
            : moment(details.created_date || null).format("DD/MM/YYYY, HH:mm")}
        </div>
        <div className="label-date">Ngày cập nhật </div>
        <div className="date">
          {userId !== "new" && details?.updated_date
            ? moment(details?.updated_date).format("DD/MM/YYYY, HH:mm")
            : ""}
        </div>
      </div>
      <div className={classes.password}>
        <div className="title">Mật khẩu</div>
        <div className="buttons">
          <span className="label">Reset mật khẩu</span>
          <Button
            className={userId === "new" ? "button disabel" : "button"}
            onClick={() => {
              if (userId === "new") {
                return;
              }
              dispatch({
                type: "openDialogNotification",
                typeDialog: "warning",
                message: ["Bạn có chắc muốn reset lại mật khẩu"],
              });
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
}
