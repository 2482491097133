import * as React from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  Button,
  Autocomplete,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { TextField } from "../overrideMui";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogUpdateReview(props) {
  const { dispatch, state } = props;
  const classes = makeStyle();

  const { dialogUpdateReview, listReviewer } = state;

  return (
    <Dialog
      open={dialogUpdateReview?.open}
      TransitionComponent={Transition}
      onClose={() => dispatch("closeDialogUpdateReveiwer")}
      className={classes.dialog}
    >
      <DialogContent style={{ padding: "20px 0" }}>
        <div className={classes.divContent}>
          <div
            style={{ width: "100%", textAlign: "start", marginBottom: "16px" }}
          >
            {`vui lòng chọn nhân sự xử lý hồ sơ : ${
              dialogUpdateReview?.code || ""
            }`}{" "}
          </div>
          <Autocomplete
            options={listReviewer || []}
            value={dialogUpdateReview?.reveiwer || null}
            noOptionsText={"Không tìm thấy dữ liệu"}
            onChange={(e, value) =>
              dispatch({ type: "onChangeReveiwer", value })
            }
            getOptionLabel={(option) => option?.fullname || ""}
            className={classes.autocomplete}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={dialogUpdateReview?.error}
                helperText={
                  dialogUpdateReview?.error
                    ? "Vui lòng chọn nhân sự xử lý"
                    : false
                }
              />
            )}
          />
        </div>
        <div className={classes.button}>
          <Button
            style={{
              ...styleButton,
              color: "#0B3C8A",
              background: "white",
              borderRadius: "12px",
              marginRight: "12px",
              border: "1px solid #0B3C8A",
            }}
            onClick={() => dispatch("closeDialogUpdateReveiwer")}
          >
            Huỷ
          </Button>
          <Button
            style={styleButton}
            onClick={() => dispatch("updateReveiwer")}
          >
            Xác nhận
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const styleButton = {
  width: "40%",
  color: "white",
  textTransform: "none",
  background: "#0B3C8A",
  fontWeight: "600",
  borderRadius: "12px",
};

const makeStyle = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
      margin: 0,
      padding: "0 24px",
    },
  },

  divContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
  },

  button: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  autocomplete: {
    width: "70%",
    marginBottom: "16px",
  },
}));
