import { makeStyles } from "@mui/styles";

export const styledStep3 = makeStyles(() => ({
  container: {},

  titleHead: {
    fontSize: "18px",
    color: "#284078",
    fontWeight: "500",
    padding: "6px 0",
  },

  incidentInfor: {
    padding: "10px 16px",
    margin: "8px 0",
    background: "white",

    "& .sapnDate": {
      fontSize: "15px",
      marginBottom: "12px",
    },

    "& .spanHours": {
      fontSize: "15px",
      marginBottom: "12px",
    },

    "& .Describes": {
      marginTop: "8px",
    },

    "& .sapnDescribe": {
      fontSize: "16px",
    },
  },

  inputDiv: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "6px",
    marginTop: "6px",
    padding: "7px 6px",
    display: "flex",

    "& .MuiInputBase-root:before": {
      border: "none !important",
    },

    "& .MuiInputBase-root:after": {
      border: "none !important",
    },

    "& .MuiInputBase-input": {
      boxSizing: "border-box",
    },
  },

  declare: {
    background: "white",
    padding: "10px 16px",
    margin: "8px 0",
  },

  error: {
    fontStyle: "italic",
    color: "red",
    paddingTop: "6px",
  },
}));
