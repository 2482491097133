import React from "react";
import { useStyles } from "../styles/useStyles";

import cham from "../assets/cham.svg";
import date from "../assets/toDate.svg";
import statusIcon from "../assets/statusIcon.svg";
import userIcon from "../assets/user-name-icon.svg";
import approveMoney from "../assets/approveMoney.svg";
import contentIcon from "../assets/content.svg";
import { Button } from "@mui/material";
import { includes } from "lodash";
import moment from "moment";

const arr = [
  { title: "Đang xử lý", id: 2 },
  { title: "Bổ sung hồ sơ", id: 4 },
  { title: "Hoàn tất", id: 6 },
  { title: "Từ chối", id: 7 },
];

export default function CardHistory(props) {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const { state, dispatch, form } = props;
  const {
    row,
    content,
    approvalAmount,
    initStatusId = 0,
    statusId = 0,

    errors,
    updated,
  } = state;

  const isReportClaim = form === "report-claim";

  return (
    <div>
      <div className="head-history">Lịch sử hồ sơ</div>
      <div className="form-infor">
        <img
          src={cham}
          alt="dog"
          style={{ marginRight: "8px", marginTop: "3px" }}
        />
        <div style={{ width: "calc(100% - 26px)" }}>
          <div>
            Mã hồ sơ: <span>{row?.code}</span>
          </div>
          <div className="inline">
            <img src={date} alt="image" width={22} height={22} />
            <span className="title">Ngày tạo hồ sơ:</span>
            <span>
              {" "}
              {row?.createdDate
                ? moment(row?.createdDate).format("DD/MM/YYYY HH:mm:ss ") + "'"
                : ""}
            </span>
          </div>
          <div className="inline">
            <img src={date} alt="image" width={22} height={22} />
            <span className="title">Ngày cập nhập:</span>
            <span>
              {" "}
              {row?.updatedDate
                ? moment(row?.updatedDate).format("DD/MM/YYYY HH:mm:ss ") + "'"
                : ""}
            </span>
          </div>
          <div className="inline">
            <img src={userIcon} alt="image" width={22} height={22} />
            <span className="title">Người xử lý hồ sơ:</span>
            <span>{" " + row?.userFullnameReview}</span>
          </div>
          <div className="inline">
            <img src={statusIcon} alt="image" width={22} height={22} />
            <span className="title">Trạng thái bồi thường:</span>
            {updated || isReportClaim ? (
              <span> {arr.find((i) => i.id === statusId)?.title}</span>
            ) : (
              <select
                id="cars"
                disabled={initStatusId === 7}
                value={statusId}
                onChange={(e) => dispatch({ type: "changeStatusId", e })}
                style={{
                  width: "calc(100% - 180px)",
                  marginLeft: "5px",
                  fontSize: "12px",
                  maxWidth: "140px",
                }}
              >
                {arr.map((i) => (
                  <option value={i.id} key={i.id}>
                    {i.title}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="inline">
            <img src={approveMoney} alt="image" width={22} height={22} />
            <span className="title">Số tiền phê duyệt:</span>
            {updated || isReportClaim || statusId === 7 ? (
              <span>
                {" " +
                  (approvalAmount || approvalAmount === 0
                    ? `${approvalAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    : "")}
              </span>
            ) : (
              <input
                name="cars"
                id="cars"
                value={
                  approvalAmount
                    ? `${approvalAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    : ""
                }
                onChange={(e) => {
                  if (
                    !"0123456789".includes(e?.nativeEvent?.data) &&
                    e?.nativeEvent?.data !== null
                  )
                    return;
                  dispatch({
                    type: "onchangeApprovalAmount",
                    e,
                  });
                }}
                style={{
                  width: "calc(100% - 150px)",
                  marginLeft: "5px",
                  fontSize: "12px",
                  maxWidth: "140px",
                  padding: "0 4px",
                }}
              />
            )}
          </div>
          {errors?.approvalAmount && (
            <div style={{ fontSize: "13px", color: "red" }}>
              Vui lòng nhập số tiền phê duyệt
            </div>
          )}
          <div className="inline">
            <img src={contentIcon} alt="image" width={22} height={22} />
            <span className="title">Nội dung</span>
            <div style={{ marginTop: "12px" }}>
              {updated || isReportClaim ? (
                <span>{content}</span>
              ) : (
                <textarea
                  name="cars"
                  id="cars"
                  row="20"
                  placeholder="Nhập nội dung"
                  onChange={(e) => dispatch({ type: "onchangeContent", e })}
                  value={content}
                  style={{
                    width: "100%",
                    fontSize: "12px",
                    padding: "6px",
                    borderRadius: "8px",
                    height: "80px",
                    maxWidth: "400px",
                  }}
                />
              )}
            </div>
            {errors?.errorContent && (
              <div style={{ fontSize: "13px", color: "red" }}>
                Vui lòng nhập nội dung
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
