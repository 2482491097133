import { Paper, TableCell, TableContainer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import CommonTable from "../../../../library/layout/commonTable";
import { convertCurrency } from "../utils";

export default function BenefitPackageTable({ list }) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container} component={Paper}>
      <CommonTable
        classes={classes}
        rows={list || []}
        columns={columns}
        empty={"Chưa thêm quyền lợi"}
      />
    </TableContainer>
  );
}

const columns = [
  {
    label: "Mã quyền lợi",
    headerClass: "code",
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst code"
            : item.isSecond
            ? "isSecond code"
            : "code"
        }
      >
        {item?.code_benefit || item?.codeBenefit || ""}
      </TableCell>
    ),
  },
  {
    label: "Tên quyền lợi ",
    headerClass: "name-benefits",
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst name-benefits"
            : item.isSecond
            ? "isSecond name-benefits"
            : "name-benefits"
        }
      >
        {item?.title || ""}
      </TableCell>
    ),
  },
  {
    label: "Quyền lợi",
    headerClass: "benefits",
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={item.isFirst ? "isFirst" : item.isSecond ? "isSecond" : ""}
      >
        {!item?.configBenefit?.value
          ? ""
          : !item?.configBenefit?.note
          ? convertCurrency(item?.configBenefit?.value)
          : item?.configBenefit?.note}
      </TableCell>
    ),
  },
];

const useStyles = makeStyles(() => ({
  container: { marginTop: 15 },
  div: {
    paddingBottom: "20px",
  },

  table: {
    minWidth: "800px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      textAlign: "center",
      fontWeight: 600,
      color: "white !important",
      background: "none !important",
    },

    "& td": {
      textAlign: "center",
      padding: 0,
    },

    "& th.code": {
      width: "120px",
      textAlign: "left",
      background: "#3961A0 !important",
    },

    "& th.name-benefits": {
      textAlign: "left",
    },

    "& th.benefits": {
      width: "230px",
    },

    "& td.code": {
      padding: "0 8px",
      textAlign: "left",
    },

    "& td.isFirst": {
      background: "#09BAFD",
      fontWeight: 700,
    },

    "& td.isSecond": {
      background: "#E3F3FF",
      fontWeight: 500,
    },

    "& td.name-benefits": {
      textAlign: "left",
      padding: "0 5px",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#3961A0 !important",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #D6DEEB",
    background: "white !important",
  },
}));
