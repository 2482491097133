import { Pause } from "@mui/icons-material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Box, Button, IconButton, Slider } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { getFileByUrl } from "../../../../../apis/health";
import ShowFile from "../../../../claim/create/loyalty/components/ShowFile";
import { useStyles } from "../styles/useStyles";
import { getExtension } from "../utils";

import picture from "../../../../../assets/ahamove/picture.svg";
import audio from "../assets/audio.svg";
import deleteIcon from "../assets/delete-icon.svg";
import download from "../assets/download-file.svg";
import audioFile from "../assets/file-audio.svg";
import file from "../assets/file.svg";
import play from "../assets/play-audio.svg";
import upload from "../assets/upload.svg";

const formatTime = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${formattedMinutes || "--"}:${formattedSeconds || "--"}`;
};

const renderImg = (elements) => [
  {
    title: "Hình ảnh hàng hoá trước khi giao",
    id: 200,
    typeCode: "goods_document_before_risk",
    listImg: (elements || []).find((item) => item.type_id === 200)?.extra_info
      ?.documents,
  },
  {
    title: "Hình ảnh hóa đơn",
    id: 202,
    typeCode: "bill",
    listImg: (elements || []).find((item) => item.type_id === 202)?.extra_info
      ?.documents,
  },
];

export default function CardDocument(props) {
  const classes = useStyles();
  const ref = useRef();
  const audioRef = useRef(null);

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const isAdminAhamove = user.username === "ahamove.claim.admin";

  const { state, dispatch, form } = props;
  const {
    row,
    statusId = 0,
    updated,
    documentsAdditionalFile,
    documentsAdditionalImg,
    documentAudio,
    loadAudio,
    showFileEvidence,
    evidenceAudio,
    loadFile,
    acceptSignFile,
    updatedSignFile,
  } = state;

  const [audioState, setAudioState] = useState({});
  const isReportClaim = form === "report-claim";

  const onClickDiv = (e) => {
    ref.current?.click();
  };

  const handlePlay = async (path, index, item, name = "") => {
    if (audioRef.current) {
      audioRef?.current?.pause();
      dispatch({ type: "setPlayIcon", index, all: true, name });
    }

    if (path.includes(".amr")) {
      dispatch("cantPlayAudio");
      return;
    }

    if (path != audioState.path) {
      const dataFile = await getFileByUrl(path);
      const url = URL.createObjectURL(dataFile);
      const audio = new Audio(url);
      audio.controls = true;
      audioRef.current = audio;
      setAudioState({ ...audioState, path: path });
      if (!name) {
        dispatch({
          type: "setAudioPlay",
          index,
          progress: 0,
          currentTime: item.currentTime || 0,
          duration: item.duration || 0,
        });
      }
    }

    dispatch({ type: "setPlayIcon", value: false, index, name });
    if (!name) {
      audioRef.current.addEventListener("timeupdate", () => {
        const currentTime = audioRef.current.currentTime;
        const duration = audioRef.current.duration;
        const calculatedProgress = (currentTime / duration) * 100;
        dispatch({
          type: "setAudioPlay",
          index,
          progress: Math.round(calculatedProgress),
          currentTime: currentTime,
          duration: duration,
        });
      });
    }

    audioRef?.current?.play();
    if (name === "evidence") {
      audioRef.current.onended = () => {
        dispatch({ type: "setPlayIcon", value: true, index, name });
      };
    } else {
      audioRef.current.currentTime = item?.currentTime || 0;
      audioRef.current.onended = () => {
        dispatch({ type: "setPlayIcon", value: true, index });
        dispatch({
          index,
          type: "setAudioPlay",
          progress: 0,
          currentTime: 0,
        });
      };
    }
  };

  const handlePause = (item, index, v = null, name = "") => {
    if (audioRef.current && item.path == audioState.path) {
      dispatch({ type: "setPlayIcon", value: true, index, name });
      audioRef?.current?.pause();
      if (name !== "evidence") {
        const currentTime = (item?.duration * v) / 100;
        dispatch({
          type: "setAudioPlay",
          index,
          currentTime: Math.round(currentTime),
          progress: v,
        });
      }
    }
  };

  useEffect(() => {
    return () => {
      // Clean up audio elements on component unmount
      audioRef?.current?.pause();
    };
  }, []);

  const listImg203 =
    (row?.elements || []).find((item) => item.type_id === 203)?.extra_info
      ?.documents || [];

  const listImg206 =
    (row?.elements || []).find((item) => item.type_id === 206)?.extra_info
      ?.documents || [];

  const listImg207 =
    (row?.elements || []).find((item) => item.type_id === 207)?.extra_info
      ?.documents || [];

  return (
    <div>
      <div className="headDocument">Mã hồ sơ: {row?.code || ""}</div>
      <div style={{ width: "100%" }}>
        <div className={classes.whiteBox}>
          {[2, 5, 6].includes(statusId) &&
            !_.isEmpty(listImg207.filter((i) => i.type === "accept_doc")) && (
              <div className="py-10">
                <div className="title">
                  Mẫu chấp nhận bồi thường và thế quyền
                </div>
                {isAdminAhamove && (
                  <div className="color-text">
                    Đối tác vui lòng tải mẫu giấy chấp nhận bồi thường và thế
                    quyền theo mẫu dưới đây, ký xác nhận và tải lên để hoàn tất
                    hồ sơ bồi thường.
                  </div>
                )}
                <div className={classes.boxImg}>
                  <div className="upload">
                    {(
                      listImg207.filter((i) => i.type === "accept_doc") || []
                    ).map((item, idx) => (
                      <div key={idx}>
                        <FileItem item={item} dispatch={dispatch} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

          {[5, 6].includes(statusId) && !isAdminAhamove && updatedSignFile && (
            <>
              <div className="title">
                Tải lên thông tin (file pdf, png, jpg)
              </div>
              <div className={classes.boxImg}>
                <div className="upload">
                  {(
                    listImg207.filter((i) => i.type === "accept_sign_doc") || []
                  ).map((item, idx) => (
                    <div className="border" key={idx}>
                      <FileItem item={item} dispatch={dispatch} />
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {[5, 6].includes(statusId) && isReportClaim && isAdminAhamove && (
            <>
              <div className="title">
                Tải lên thông tin (file pdf, png, jpg)
              </div>
              <div className={classes.boxImg}>
                <div className="box-file">
                  <input
                    hidden
                    type="file"
                    id="accept-file"
                    accept="image/*, application/pdf, .pdf"
                    onChange={(e) => {
                      dispatch({ type: "addAcceptSignFile", e });
                      e.target.value = null;
                    }}
                  />
                  {!updatedSignFile && (
                    <div className="row bg-gray">
                      <Button
                        disabled={loadFile}
                        className="button"
                        onClick={() => {
                          window.document.getElementById(`accept-file`).click();
                        }}
                      >
                        Chọn file
                      </Button>
                      {loadFile && (
                        <div style={{ fontSize: 14 }}>
                          <img src={upload} alt="upload" /> Đang tải lên...
                        </div>
                      )}
                    </div>
                  )}
                  <div className={classes.boxImg}>
                    {acceptSignFile?.map((item, index) => (
                      <div className="border" key={index}>
                        <AcceptFile
                          item={item}
                          index={index}
                          hidenDeleteBtn={updatedSignFile}
                          dispatch={dispatch}
                          name="acceptSign"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="title">Chứng từ</div>
        {renderImg(row?.elements).map((item, index) => (
          <div className={classes.boxImg} key={index}>
            <div className="title">{item?.title}</div>
            <div className="div-img">
              {(item.listImg || []).map((i, indexImg) => (
                <div
                  className="img"
                  onClick={() =>
                    dispatch({
                      type: "showDialog",
                      indexImg,
                      listImg: item.listImg,
                    })
                  }
                >
                  <img src={i?.path} width="100%" height="100%" alt="imge" />
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className="title">Bằng chứng nỗ lực truy tìm tài xế</div>
        <div className={classes.boxImg}>
          <div className="title">File hình ảnh</div>
          <div className="div-img">
            {(listImg206.filter((i) => i.type === "evidence_image") || []).map(
              (i, indexImg) => (
                <div
                  className="img"
                  onClick={() =>
                    dispatch({
                      type: "showDialog",
                      indexImg,
                      listImg: listImg206.filter(
                        (i) => i.type === "evidence_image"
                      ),
                    })
                  }
                >
                  <img src={i?.path} width="100%" height="100%" alt="imge" />
                </div>
              )
            )}
          </div>
        </div>
        <div className={classes.boxImg}>
          <div className="title">File ghi âm</div>
          <div className="upload">
            {(evidenceAudio || []).map((item, index) => (
              <div className="border" key={index}>
                <div className="row">
                  <div className="row">
                    <img
                      src={audioFile}
                      alt="file"
                      style={{ marginRight: "6px", width: "20px" }}
                    />
                    {item?.originalname || ""}
                  </div>
                  <div className="row">
                    <IconButton color="primary">
                      {item?.playAudio ? (
                        <img
                          src={play}
                          alt="play"
                          onClick={() => {
                            handlePlay(item.path, index, item, "evidence");
                          }}
                        />
                      ) : (
                        <Pause
                          sx={{ fontSize: 20 }}
                          onClick={() =>
                            handlePause(item, index, null, "evidence")
                          }
                        />
                      )}
                    </IconButton>
                    <img
                      src={download}
                      alt="download"
                      className="icon"
                      onClick={() =>
                        dispatch({
                          type: "downloadFile",
                          path: item?.path,
                          name: item?.originalname,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.boxImg}>
          <div className="title">
            File tài liệu<span style={{ color: "red" }}>*</span>
          </div>
          <div className="upload">
            {(listImg206.filter((i) => i.type === "evidence_file") || []).map(
              (item, idx) => (
                <div className="border" key={idx}>
                  <FileItem item={item} dispatch={dispatch} />
                </div>
              )
            )}
          </div>
        </div>

        <div className="title">Hồ sơ yêu cầu bồi thường</div>
        <div className={classes.boxImg}>
          <div className="upload">
            <FileItem
              item={listImg207.find((i) => i.type === "request_doc")}
              dispatch={dispatch}
            />
          </div>
        </div>
        <div className={classes.whiteBox}>
          {(listImg203.length > 0 || [4, 5, 6, 7].includes(statusId)) && (
            <>
              <div className="title pt-10">Thông tin bổ sung</div>
              <div className={classes.boxImg}>
                <div className="title">Hình ảnh bổ sung</div>
                <div className="div-img">
                  {!isReportClaim &&
                    [4, 5, 6, 7].includes(statusId) &&
                    !updated && (
                      <div
                        className={classes.additional_img}
                        onClick={() => {
                          window.document.getElementById(`addImage`).click();
                        }}
                      >
                        <input
                          type="file"
                          hidden
                          id={`addImage`}
                          accept="image/*"
                          onChange={(e) => {
                            dispatch({ type: "additionalFile", e });
                            e.target.value = null;
                          }}
                        />
                        <img src={picture} alt="picture" />
                        <span className="spanPicture">
                          Tải ảnh lên từ thiết bị
                        </span>
                      </div>
                    )}
                  {documentsAdditionalImg?.map((itemIMG, indexImg) => (
                    <div
                      className={
                        !isReportClaim &&
                        [4, 5, 6, 7].includes(statusId) &&
                        !updated
                          ? classes.additional_img
                          : "img"
                      }
                    >
                      <img
                        onClick={() =>
                          dispatch({
                            type: "showDialog",
                            indexImg,
                            listImg: documentsAdditionalImg,
                          })
                        }
                        src={itemIMG?.path}
                        width="100%"
                        height="100%"
                        alt="addition"
                      />
                      {!isReportClaim &&
                        [4, 5, 6, 7].includes(statusId) &&
                        !updated && (
                          <CancelOutlinedIcon
                            className="icon"
                            fontSize="small"
                            style={{ zIndex: 11 }}
                            onClick={() =>
                              dispatch({
                                type: "deleteAdditionalImg",
                                indexImg,
                                itemIMG,
                              })
                            }
                          />
                        )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {[4, 5, 6, 7].includes(statusId) && (
            <div className={classes.boxImg}>
              <div className="title">File ghi âm đính kèm</div>
              <div className="box-audio">
                <input
                  ref={ref}
                  hidden
                  type="file"
                  id="audio"
                  accept="audio/*"
                  onChange={(e) => {
                    dispatch({ type: "addAudio", e });
                    e.target.value = null;
                  }}
                />
                {!isReportClaim && !updated && (
                  <div className="row bg-gray">
                    <Button
                      disabled={loadAudio}
                      className="button"
                      onClick={onClickDiv}
                    >
                      Chọn file
                    </Button>
                    {loadAudio && (
                      <div style={{ fontSize: 14 }}>
                        <img src={upload} alt="upload" /> Đang tải lên...
                      </div>
                    )}
                  </div>
                )}
                {documentAudio.map((item, index) => (
                  <div className="border">
                    <div className="row">
                      <div className="row">
                        <img src={audio} alt="audio" className="icon" />
                        <div>
                          <div
                            title={item?.originalname || ""}
                            className="name"
                          >
                            {item?.originalname || ""}
                          </div>
                          <div className="size">{item?.size || ""}</div>
                        </div>
                      </div>

                      <div className="row">
                        <IconButton color="primary">
                          {item?.playAudio ? (
                            <img
                              src={play}
                              alt="play"
                              onClick={() => handlePlay(item.path, index, item)}
                            />
                          ) : (
                            <Pause
                              sx={{ mt: "2px" }}
                              onClick={() => handlePause(item, index)}
                            />
                          )}
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={() =>
                            dispatch({ type: "dowloadAudio", index })
                          }
                        >
                          <img src={download} alt="down" />
                        </IconButton>
                        {!isReportClaim && !updated && (
                          <IconButton
                            color="primary"
                            onClick={() =>
                              dispatch({
                                type: "deleteAudio",
                                index,
                                url: item.path,
                              })
                            }
                          >
                            <img src={deleteIcon} alt="del" />
                          </IconButton>
                        )}
                      </div>
                    </div>
                    <Box sx={{ padding: "0 15px" }}>
                      <div
                        style={{
                          width: "100%",
                          textAlign: "end",
                          fontSize: "13px",
                          fontStyle: "italic",
                        }}
                      >{`${formatTime(
                        Math.round(item?.currentTime)
                      )} / ${formatTime(Math.round(item?.duration))}`}</div>
                      <Slider
                        size="small"
                        value={Math.round(item?.progress || 0)}
                        aria-label="Small"
                        onChange={(e, v, a) => {
                          if (item.path != audioState.path && item?.duration) {
                            const currentTime = (item?.duration * v) / 100;
                            dispatch({
                              type: "setAudioPlay",
                              index,
                              currentTime: Math.round(currentTime),
                              progress: v,
                            });
                          } else {
                            handlePause(item, index, v);
                          }
                        }}
                      />
                    </Box>
                  </div>
                ))}
              </div>
            </div>
          )}
          {[4, 5, 6, 7].includes(statusId) && (
            <div className={classes.boxImg}>
              <div className="title">File tài liệu</div>
              <div className="box-audio">
                <input
                  type="file"
                  hidden
                  id={`addFile-addition`}
                  accept={"application/pdf,.ppt, .pptx"}
                  onChange={(e) => {
                    dispatch({
                      type: "additionalFile",
                      name: "file",
                      e,
                    });
                  }}
                />
                {!isReportClaim && !updated && (
                  <div className="row bg-gray">
                    <Button
                      className="button"
                      onClick={() => {
                        window.document
                          .getElementById(`addFile-addition`)
                          .click();
                      }}
                    >
                      Chọn file
                    </Button>
                  </div>
                )}
                {(documentsAdditionalFile || []).map((item, idx) => (
                  <div className="border" key={idx}>
                    <AcceptFile
                      item={item}
                      index={idx}
                      hidenDeleteBtn={updated || isReportClaim}
                      dispatch={dispatch}
                      name="additionalFile"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <ShowFile
        open={!!showFileEvidence?.open}
        link={showFileEvidence?.link || ""}
        onClose={() => dispatch("closeShowEvidence")}
      />
    </div>
  );
}

const FileItem = ({ item, dispatch }) => (
  <div className="row">
    <div
      className="row cursor"
      onClick={() =>
        getExtension(item?.path) === "pdf"
          ? window.open(item?.path, "_blank")
          : ["docx", "doc"].includes(getExtension(item?.path))
          ? dispatch({ type: "showEvidence", file: item })
          : dispatch({ type: "showEvidenceImg", file: item })
      }
    >
      <img src={file} alt="file" style={{ marginRight: "6px" }} />
      {item?.originalname || ""}
    </div>
    <img
      src={download}
      alt="download"
      className="icon"
      onClick={() =>
        dispatch({
          type: "downloadFile",
          path: item?.path,
          name: item?.originalname,
        })
      }
    />
  </div>
);

const AcceptFile = ({ item, index, hidenDeleteBtn, dispatch, name }) => (
  <div className="row">
    <div
      className="row cursor"
      onClick={() => {
        getExtension(item?.path) !== "pdf"
          ? dispatch({ type: "showEvidenceImg", file: item })
          : dispatch({ type: "showEvidence", file: item });
      }}
    >
      <img
        src={file}
        alt="file"
        style={{ marginRight: "6px", width: "28px" }}
      />
      <div>
        <div>{item?.originalname || ""}</div>
        <div className="size">{item?.size || ""}</div>
      </div>
    </div>
    <div className="row">
      <IconButton
        color="primary"
        onClick={() =>
          dispatch({
            type: "downloadFile",
            path: item?.path,
            name: item?.originalname,
          })
        }
      >
        <img src={download} alt="download" />
      </IconButton>
      {!hidenDeleteBtn && (
        <IconButton
          color="primary"
          onClick={() =>
            dispatch({
              type: "deleteFile",
              index,
              url: item.path,
              name,
            })
          }
        >
          <img src={deleteIcon} alt="del" />
        </IconButton>
      )}
    </div>
  </div>
);
