import TableToExcel from "@linways/table-to-excel";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import useReducer from "../../../../library/hooks/useReducer";
import CommonTable from "../../../../library/layout/commonTable";
import LoadingMui from "../../../../ui/LoadingMui";
import AddBenefitGroup from "../components/AddBenefitGroup";
import AddInfoUser from "../components/AddInfoUser";
import ImportExcel from "../components/ImportExcel";
import TableExportExcelReport from "../components/TableExportExcel";
import { columns } from "../components/columns-user";
import packageReducer from "../useReducer/packageReducer";
import { useStyles } from "../useStyles";
import { convertCurrency, formatDate, getParamUrlByKey, types } from "../utils";

import addGray from "../assets/add-gray.svg";
import addUser from "../assets/add-user.svg";
import downloadIcon from "../assets/download.svg";
import eyeOpenIcon from "../assets/eye-open-ic.png";
import importIcon from "../assets/import.svg";
import defaultIcon from "../assets/logo-default.svg";
import searchIcon from "../assets/search-icon.svg";

const initialState = {
  data: {},
  dataInfo: {},
  dataProductParent: {},
  productPackageList: [],
  participantList: [],
  importList: [],
  open: {
    import: false,
    user: false,
    benefit: false,
  },
  loading: false,
  loadingTable: false,
  dragActive: false,
  uploading: false,
  saving: false,
  selectPackage: {},
  total_product_amount: 0,
  rangeFees: 0,
  count: 0,
  errors: [],
};

function Package(props) {
  const history = useHistory();
  const id = getParamUrlByKey("id");
  const merchantId = getParamUrlByKey("merchantId");
  const classes = useStyles();

  const [state, dispatch] = useReducer({
    reducer: packageReducer,
    name: "packageReducer",
    initialState,
    history,
    merchantId,
    id,
  });

  const {
    data,
    loading,
    loadingTable,
    open,
    selectPackage,
    participantList,
    total_product_amount,
    rangeFees,
    dataInfo,
    count,
    productPackageList,
  } = state;

  useEffect(() => {
    dispatch("getDetailProductMerchant");
  }, []);

  useEffect(() => {
    if (count !== 0) {
      const table = document.getElementById("export-data-table");
      const name = "Bao-cao-khách hàng.xlsx";
      TableToExcel.convert(table, {
        name,
        sheet: {
          name: "Sheet 1",
        },
      });
    }
  }, [count]);

  return (
    <>
      <div className={classes.headerBar}>
        Khách hàng/ <b>{data?.providerTitle || ""} </b>
      </div>
      <LoadingMui loading={loading} />

      <div className={classes.package}>
        <div className="container">
          <div className="info">
            <Grid container spacing={4}>
              <Grid item xs={3}>
                {data?.urlLogo ? (
                  <img src={data.urlLogo} alt="logo" className="logo" />
                ) : (
                  <img src={defaultIcon} alt="logo" className="logo" />
                )}
                <div className="name">Bảo hiểm {data?.title || ""}</div>
              </Grid>
              <Grid item xs={5}>
                <div className="row">
                  <div>Mã sản phẩm</div>
                  <div className="color">{data?.productId || ""}</div>
                </div>
                <div className="row">
                  <div>Tên sản phẩm</div>
                  <div className="color">Bảo hiểm {data?.title || ""}</div>
                </div>
                <div className="row">
                  <div>Thuộc công ty</div>
                  <div className="color">{data?.providerTitle || ""}</div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="row">
                  <div>Loại bảo hiểm</div>
                  <div className="color">
                    {types.find((i) => i.insurance_code == data.insuranceCode)
                      ?.insurance_title || ""}
                  </div>
                </div>
                <div className="row">
                  <div>Ngày tạo</div>
                  <div className="color">{formatDate(data?.createdDate)}</div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="fee">
            <div className="fee-text">
              Phí BH: <b className="ml-10">{rangeFees} </b>
            </div>
            <div className="fee-text">
              Quyền lợi:
              <b className="ml-10">
                {" "}
                {convertCurrency(total_product_amount || 0)}
              </b>
            </div>
            <div className="row fee-text">
              Mã quyền lợi sản phẩm{" "}
              <IconButton onClick={() => dispatch("showDetailPackage")}>
                <img src={eyeOpenIcon} alt="eye" />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="tabs">
          <Tabs
            value={selectPackage?.id || 0}
            onChange={(e, value) => dispatch({ type: "changeTab", value })}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            {productPackageList?.map((item, index) => (
              <Tab
                {...a11yProps(index)}
                key={item.id}
                value={item.id}
                label={
                  <div>
                    <div className="title-tab">{item?.title}</div>
                    <div>Quyền lợi: {item?.productAmount || 0} VNĐ </div>
                    <div>Người tham gia: {item?.participants || 0} người</div>
                    <div>
                      Phí BH:{" "}
                      <span className="fee-tab">
                        {convertCurrency(item?.fess)} VNĐ
                      </span>
                    </div>
                  </div>
                }
                wrapped
              />
            ))}
            <Tab
              key={"add"}
              value={"add"}
              label={
                <div>
                  <img src={addGray} alt="add" className="add-icon" />
                  <div>Thêm nhóm quyền lợi</div>
                </div>
              }
              wrapped
              className="add-group"
            />
          </Tabs>
        </div>
        <div className="wrapper">
          <div className="row mb-20">
            <div className="search">
              <TextField
                fullWidth
                className="w-80"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={searchIcon} alt="search-ic" />
                    </InputAdornment>
                  ),
                }}
                onChange={debounce(
                  (e) =>
                    dispatch({
                      type: "getParticipantList",
                      value: e.target.value,
                    }),
                  800
                )}
              />
              {loadingTable && (
                <CircularProgress
                  size={25}
                  sx={{ mt: 0.5, ml: 1, color: "#3961A0" }}
                />
              )}
            </div>
            <div>
              <Button
                className="button"
                onClick={() => dispatch({ type: "onOpen", name: "user" })}
                startIcon={<img src={addUser} alt="add-ic" />}
              >
                Thêm người
              </Button>
              <img
                src={downloadIcon}
                alt="download"
                className="btn ml-10"
                onClick={() => dispatch("export")}
              />
              <img
                src={importIcon}
                alt="import"
                className="btn ml-10"
                onClick={() => dispatch({ type: "onOpen", name: "import" })}
              />
            </div>
          </div>
          <CommonTable
            classes={classes}
            rows={participantList}
            columns={columns}
            onClickRow={(data) => dispatch({ type: "changePage", data })}
          />
          <AddInfoUser open={open.user} state={state} dispatch={dispatch} />
          <AddBenefitGroup
            open={open.benefit}
            dataInfo={dataInfo}
            dispatch={dispatch}
          />
          <ImportExcel open={open.import} state={state} dispatch={dispatch} />
          <TableExportExcelReport data={participantList} />
        </div>
      </div>
    </>
  );
}

export default Package;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
