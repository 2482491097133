import _ from "lodash";
import moment from "moment";

import {
  getListClaim,
  getListReviewer,
  updateReviewer,
  getDetailClaim,
} from "../../../../../apis/health";
import { getParamUrlByKey } from "../../../../../utils/utils";

export default function recordsReducer({
  state,
  dispatch,
  action,
  role_type,
  product_id,
  systemType,
  history,
  dataSearchHealthList,
  setDataSearchHealthList,
}) {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const userType = _.get(user, "extra_info.userType") || "globalcare";
  const product_id_parent = getParamUrlByKey("product_id_parent") || "";

  const cases = {
    initial: async function () {
      const { snackbar, dataSearch } = state;
      dispatch("onLoading");
      const res = {};
      const paramsListClaim = _.isEmpty(dataSearchHealthList)
        ? {
            productIdParent: product_id,
            code: null,
            insuredName: null,
            insuredPhone: null,
            insuredPrivateId: null,
            userFullnameReview: null,
            statusId: null,
            from: moment()
              .set({ hour: 0, minute: 0, second: 0 })
              .subtract(30, "days")
              .format(),
            to: moment().set({ hour: 0, minute: 0, second: 0 }).format(),
            limit: 20,
            offset: 0,
          }
        : {
            ...dataSearchHealthList,
            productIdParent: product_id,
            limit: 20,
            offset: 0,
          };

      const dataApi = await getListClaim(systemType, paramsListClaim);

      if (dataApi instanceof Error) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại",
          },
          loading: false,
        };
      }

      if (_.get(dataApi, "data.statusCode") === 200) {
        res.rows = _.get(dataApi, "data.result.data") || [];

        res.pageTable = {
          count: Math.ceil((_.get(dataApi, "data.result.total") || 50) / 20),
          page: 0,
          rowsPerPage: 20,
        };
        res.dataSearch = _.isEmpty(dataSearchHealthList)
          ? {
              ...dataSearch,
              from: moment()
                .set({ hour: 0, minute: 0, second: 0 })
                .subtract(30, "days")
                .format(),
              to: moment().set({ hour: 0, minute: 0, second: 0 }).format(),
            }
          : {
              ...dataSearchHealthList,
            };
      }

      return { loading: false, ...res };
    },

    onChangeSearch: () => {
      const { value, name } = action;
      const { dataSearch } = state;
      if (name === "statusId" && role_type === "claim_report") {
        const listStatusId = [
          { name: "Tất cả", statusId: 0, statusIds: null },
          { name: "Đang xử lý", statusId: 1, statusIds: [1, 2, 3, 4] },
          { name: "Đồng ý bồi thường", statusId: 2, statusIds: [5, 6, 9] },
          { name: "Từ chối bồi thường", statusId: 3, statusIds: [7, 8, 10] },
        ];
        dataSearch.statusIds = listStatusId.find(
          (i) => i.statusId === value
        )?.statusIds;
      }

      dataSearch[name] = value;
      return { dataSearch };
    },

    submitSearch: async () => {
      const { dataSearch, pageTable, snackbar } = state;

      const newDateSearch = { ...dataSearch };
      if (role_type === "claim_report") {
        delete newDateSearch.statusId;
      } else {
        delete newDateSearch.statusIds;
      }

      const params = {
        productIdParent: product_id,
        code: null,
        insuredName: null,
        insuredPhone: null,
        insuredPrivateId: null,
        userFullnameReview: null,
        statusId: null,
        from: null,
        to: null,
        limit: pageTable.rowsPerPage || 20,
        offset: 0,
        ...newDateSearch,
      };
      setDataSearchHealthList({ ...dataSearch });

      dispatch("onLoading");
      const res = {};

      const dataApi = await getListClaim(systemType, params);

      if (dataApi instanceof Error) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại",
          },
          loading: false,
        };
      }

      if (_.get(dataApi, "data.statusCode") === 200) {
        res.rows = _.get(dataApi, "data.result.data") || [];

        res.pageTable = {
          count: Math.ceil((_.get(dataApi, "data.result.total") || 50) / 20),
          page: 0,
          rowsPerPage: pageTable.rowsPerPage || 20,
        };
      }

      return { loading: false, ...res };
    },

    onchangePage: async () => {
      const { name, page, rowsPerPage } = action;
      const { dataSearch, snackbar } = state;
      const params = {
        productIdParent: product_id,
        code: null,
        insuredName: null,
        insuredPhone: null,
        insuredPrivateId: null,
        userFullnameReview: null,
        statusId: null,
        from: null,
        to: null,
        limit: rowsPerPage || 20,
        offset: name === "rowsPerPage" ? 0 : page * rowsPerPage,
        ...dataSearch,
      };
      const res = {};
      dispatch("onLoading");

      const dataApi = await getListClaim(systemType, params);

      if (dataApi instanceof Error) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại",
          },
          loading: false,
        };
      }

      if (_.get(dataApi, "data.statusCode") === 200) {
        res.rows = _.get(dataApi, "data.result.data") || [];

        res.pageTable = {
          count: Math.ceil(
            (_.get(dataApi, "data.result.total") || 50) / (rowsPerPage || 20)
          ),
          page: name === "rowsPerPage" ? 0 : page,
          rowsPerPage: rowsPerPage || 20,
        };
      }

      return { loading: false, ...res };
    },

    onSubmitExport: async () => {
      const { dataSearch, count, snackbar } = state;
      const params = {
        productIdParent: product_id,
        code: null,
        insuredName: null,
        insuredPhone: null,
        insuredPrivateId: null,
        userFullnameReview: null,
        statusId: null,
        from: null,
        to: null,
        limit: 5000,
        offset: 0,
        ...dataSearch,
      };

      dispatch("onLoading");
      const res = {};

      const dataApi = await getListClaim(systemType, params);

      if (dataApi instanceof Error) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại",
          },
          loading: false,
        };
      }

      if (_.get(dataApi, "data.statusCode") === 200) {
        res.dataExportExcel = _.get(dataApi, "data.result.data") || [];
      }

      return { loading: false, ...res, count: (count || 0) + 1 };
    },

    onChangeReveiwer: () => {
      const { value } = action;
      const { dialogUpdateReview } = state;

      return {
        dialogUpdateReview: {
          ...dialogUpdateReview,
          reveiwer: value,
          error: false,
        },
      };
    },

    updateReveiwer: async () => {
      const { dialogUpdateReview, snackbar, rows } = state;
      const item = rows[dialogUpdateReview.rowIdx];
      const claimId = item?.id;
      if (_.isEmpty(dialogUpdateReview.reveiwer) || !Boolean(claimId)) {
        return { dialogUpdateReview: { ...dialogUpdateReview, error: true } };
      }

      dispatch("onLoading");

      const dataApi = await updateReviewer(userType, {
        userId: dialogUpdateReview?.reveiwer?.id,
        claimId,
        grantTo: dialogUpdateReview?.grantTo || null,
      });

      if (dataApi instanceof Error) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi khi thay đổi nhân sự. Xin vui lòng thử lại",
          },
          loading: false,
        };
      }
      const userReview =
        dialogUpdateReview.grantTo === "reviewer"
          ? {
              userIdReview: dialogUpdateReview?.reveiwer?.id,
              userFullnameReview: dialogUpdateReview?.reveiwer?.fullname,
            }
          : {
              userIdAssign: dialogUpdateReview?.reveiwer?.id,
              userFullnameAssign: dialogUpdateReview?.reveiwer?.fullname,
            };

      rows[dialogUpdateReview.rowIdx] = {
        ...item,
        ...userReview,
      };

      return {
        snackbar: {
          ...snackbar,
          open: true,
          type: "success",
          message: dialogUpdateReview?.isAssignApprover
            ? "Gán nhân sự phê duyệt thành công."
            : "Gán nhân sự xử lý thành công.",
        },
        rows,
        loading: false,
        dialogUpdateReview: { open: false },
      };
    },

    openDialogUpdateReveiwer: async () => {
      const {
        data: { item, rowIdx, isAssignApprover },
      } = action;
      const { dialogUpdateReview, listReviewer, snackbar } = state;
      dispatch("onLoading");

      const res = {};
      // res.listReviewer = [...listReviewer];
      // dialogUpdateReview.reveiwer = listReviewer.find(
      //   (i) =>
      //     i?.id == (isAssignApprover ? item?.userIdAssign : item?.userIdReview)
      // );
      dialogUpdateReview.grantTo = isAssignApprover ? "approver" : "reviewer";
      const levelIds = isAssignApprover ? [2, 3, 4, 5] : [1];

      const dataApi = await getListReviewer(userType, {
        levelIds,
        productIdParent: product_id,
        limit: 500,
        offset: 0,
        orgId: item?.org_id || item?.orgId || null,
      });

      if (dataApi instanceof Error) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại",
          },
          loading: false,
        };
      }

      const temp = _.get(dataApi, "data.result.data") || [];
      res.listReviewer = temp;
      dialogUpdateReview.reveiwer = temp.find(
        (i) =>
          i?.id == (isAssignApprover ? item?.userIdAssign : item?.userIdReview)
      );

      return {
        ...res,
        dialogUpdateReview: {
          ...dialogUpdateReview,
          open: true,
          code: item?.code || "",
          rowIdx: rowIdx,
        },
        loading: false,
      };
    },

    onClickShowDetail: async () => {
      const {
        data: { item },
      } = action;

      if (role_type == "claim_report") {
        dispatch("onLoading");
        const detailClaimApi = await getDetailClaim(userType, item.id);

        const res = {};
        if (detailClaimApi?.data?.statusCode === 200) {
          const detailClaim = _.get(detailClaimApi, "data.result") || {};

          const elementPdf = detailClaim?.elements?.find((i) => {
            const url = _.get(i, "extra_info.documents[0].path") || null;
            return (
              [5, 6, 7, 8, 9, 10].includes(+i.status_id) &&
              !!url &&
              i?.type_id === 521
            );
          });

          if (elementPdf) {
            const url = _.get(elementPdf, "extra_info.documents[0].path");
            window.open(url, "_blank");
          } else {
            res.snackbar = {
              open: true,
              type: "error",
              message:
                "Có thể bồi thường này chưa có file, vui lòng liên hệ với quản trị viện đễ được hỗ trợ !!",
            };
          }
        }

        return { loading: false, ...res };
      }

      history.push(
        `/admin/health/indemnify/detail/${item.id}?product_id_parent=${product_id_parent}`
      );
    },

    closeDialogUpdateReveiwer: () => ({
      dialogUpdateReview: {},
    }),

    closeDialogDetailPDF: () => ({
      dialogDetailPDF: false,
      detail: {},
    }),

    closeSnackbar: () => ({ snackbar: { ...state.snackbar, open: false } }),

    onLoading: () => ({ loading: true }),

    offLoading: () => ({ loading: false }),

    //
  };

  return !!action?.type && cases[action.type];
}
