import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 99,
    color: "#fff",
    position: "absolute",
    borderRadius: "5px",
  },
  wrapper: {
    marginTop: "55px",
    width: "100%",
  },
  title: {
    paddingLeft: "16px",
    color: "#0B3C8A",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "25px",
  },
  box: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
    boxShadow: "0px 9px 46px rgb(0 0 0 / 6%)",
    backgroundColor: "white",
    borderRadius: "12px",
    margin: "16px",
    padding: "16px",
    "&:hover": {
      background: "#EDF2F9",
    },
    "& .name": {
      paddingBottom: "5px",
      textAlign: "left",
      fontSize: 17,
      lineHeight: "22px",
      fontWeight: "700",
      letterSpacing: "-0.24 px",
      textTransform: "capitalize",
    },
    "& .price": {
      fontSize: "15px",
      lineHeight: "19px",
    },
  },
  moneyTitle: {
    padding: "5px 0",
    textAlign: "left",
    color: "rgba(0, 0, 0, 0.32)",
    textTransform: "uppercase",
    fontSize: "13px  !important",
    lineHeight: "16px !important",
    fontWeight: "700 !important",
  },
  volatilityBox: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
    boxShadow: "0px 9px 46px rgb(0 0 0 / 6%)",
    backgroundColor: "white",
    borderRadius: "12px",
    margin: "14px 16px",
    padding: "16px",
    "&:hover": {
      background: "#EDF2F9",
    },
    "& .price": {
      fontSize: "15px",
      lineHeight: "19px",
    },
  },
  rowGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .row": {
      display: "flex",
      paddingBottom: "7px",
    },
    "& .right": {
      justifyContent: "end",
    },
    "& .icon": {
      fontSize: 16,
      marginRight: "6px",
    },
    "& .phone": {
      color: "#0B3C8A",
    },
    "& .dob": {
      color: "#03A9F4",
    },
    "& .title": {
      fontSize: "15px",
      lineHeight: "19px",
    },
    "& .effect": {
      borderRadius: "28px",
      backgroundColor: "#0BBD32",
      padding: "7px 10px 5px 10px",
    },
    "& .expired": {
      borderRadius: "28px",
      backgroundColor: "#FF2929",
      padding: "5px 10px",
    },
    "& .status": {
      fontWeight: 600,
      fontSize: "11px",
      lineHeight: "14px",
      color: "white",
    },
    "& .date": {
      fontWeight: 500,
      fontSize: "13px",
      lineHeight: "16px",
      opacity: 0.5,
      color: "#000",
    },
  },
  wrapperBox: {
    boxShadow: "0px 0px 145px rgba(0, 0, 0, 0.09)",
    backgroundColor: "#EBF3FF",
    borderRadius: "10px",
    margin: "16px",
    padding: "16px",
    "& .box": {
      padding: "16px",
      background: "#BAD5FF",
      boxShadow: "0px 0px 145px rgba(0, 0, 0, 0.09)",
      borderRadius: "10px",
    },
    "& .total": {
      color: "#000000",
      opacity: 0.5,
      fontSize: "15px",
      fontWeight: 600,
      lineHeight: "16px",
    },
    "& .money": {
      color: "#FF2929",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "16px",
      paddingTop: "15px",
    },
  },
  gridBox: {
    paddingTop: "12px",
    "& .dateTitle": {
      padding: "3px 0 10px 0",
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "19px",
    },
  },
  wrapperDialog: {
    height: "40%",
    top: " 60% !important",
    zIndex: 100,
    borderRadius: "15px",
    "& .MuiDialogContent-root": {
      paddingTop: "16px !important",
    },
    "& .btn": {
      right: 16,
      top: 16,
      width: "24px",
      height: "24px",
      backgroundColor: "#F3F3F3",
      color: "#070707",
      position: "absolute",
    },
    "& .title": {
      paddingBottom: "10px",
    },
  },
  button: {
    textAlign: "center",
    borderRadius: "5px",
    borderRadius: "14px",
    margin: "20px 0",
    textTransform: "none",
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
    backgroundColor: "#0B3C8A",
  },
  wrapperInfo: {
    marginTop: "50px",
    width: "100%",

    "& .title": {
      padding: "5px 16px",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "25px",
    },
  },
  info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "6px 16px",
    "& .key": {
      fontFamily: `"Google Sans",sans-serif`,
      fontSize: "17px",
      lineHeight: "22px",
      color: "#0B3C8A",
      fontWeight: 500,
      letterSpacing: "-0.408px",
    },
    "& .value": {
      fontFamily: `"Google Sans",sans-serif`,
      fontSize: "17px",
      lineHeight: "21px",
      textAlign: "right",
      letterSpacing: "-0.408px",
    },
  },
  bottomNav: {
    position: "absolute",
    width: "100%",
    zIndex: 99,
    bottom: 0,
  },
  dialog: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: "15px",
    "& .MuiDialog-container": {
      width: "100%",
    },
    "& .MuiPaper-root-MuiDialog-paper": {
      margin: 0,
    },
    "& .MuiDialogContent-root": {
      padding: "16px",
    },
    "& .titleDialog": {
      textAlign: "center",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "25px",
    },
    "& .txtDialog": {
      textAlign: "center",
      fontSize: "15px",
      lineHeight: "19px",
    },
    "& .txtSuccess": {
      textAlign: "center",
      fontSize: "17px",
      lineHeight: "22px",
    },
    "& .txtField": {
      //background: "#F1F1F1",
      borderRadius: "10px",
      marginTop: "10px",
    },
    "& .img": {
      padding: "0 5px 5px 0",
    },
    "& legend": {
      display: "none",
    },
  },
  textfield: {
    "& legend": {
      display: "none",
    },
  },
  txtField: {
    "& legend": {
      display: "none",
    },
    "& .MuiSelect-select": {
      padding: "10px 8px",
    },
  },
  bottomAction: { fontWeight: 700, color: "black" },
}));
