import { isString, set, get, isEmpty, includes } from "lodash";

import {
  getDetialAccount,
  getListOrg,
  getListRoleGroupByOrg,
  getListProduct,
  getRoleGroupById,
  createAccount,
  updateAccount,
  // getListUsersTPA,
  // getListUsers,
  resetPasswordGC,
  resetPassword,
  getListProvider,
  getListMerchant,
} from "../../../../apis/account_v2";

// import { userType, accountType } from "../util";

const delay = (t) => {
  return new Promise((resole) => {
    setTimeout(() => {
      resole("done");
    }, t);
  });
};

export default function detailAccountReducer(props) {
  const { state, action, dispatch, userId, history } = props;
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const userTypeFromProfile = get(user, "extra_info.userType", "") || "";
  const listProductFromUser =
    get(user, "role_management_claim.product_parents", []) || [];
  const orgIdFromUser = get(user, "extra_info.org_id", "") || "";

  const isRoot = Boolean(
    get(user, "role_management_claim.is_root", null) || null
  );
  const isAdminGC =
    (Boolean(
      get(user, "role_management_claim.is_admin", null) &&
        get(user, "role_management_claim.is_root", null) != 1
    ) ||
      get(user, "role_management_claim.privileges.ACCOUNT_MANAGE", null) ===
        1) &&
    userTypeFromProfile === "globalcare" &&
    get(user, "role_management_claim.is_root", null) !== 1;

  const getOrgIdForListProduct = ({ payloadAcount }) => {
    switch (userTypeFromProfile) {
      case "globalcare":
        if (
          ["tpas", "merchant"].includes(payloadAcount?.extra_info?.userType)
        ) {
          return payloadAcount?.extra_info?.org_id_parent || null;
        }
        if (payloadAcount?.extra_info?.userType === "provider") {
          return null;
        }
        return payloadAcount?.extra_info?.org_id || null;

      case "tpag":
        return orgIdFromUser;

      case "tpas":
        return get(user, "extra_info.org_id_parent") || null;

      case "tpa":
        return orgIdFromUser;

      case "merchant":
        return get(user, "extra_info.org_id_parent") || null;

      case "bvpk":
        return null;

      default:
        return null;
    }
  };

  const cases = {
    initials: async () => {
      const payloadAcount = {};
      const res = {};

      if (isAdminGC || isRoot) {
        set(payloadAcount, "extra_info.userType", "globalcare");
      }
      if (
        ["tpag", "tpas", "tpa", "merchant", "provider"].includes(
          userTypeFromProfile
        )
      ) {
        set(payloadAcount, "extra_info.userType", userTypeFromProfile);
        userTypeFromProfile !== "tpag" &&
          set(
            payloadAcount,
            "extra_info.org_id_parent",
            get(user, "extra_info.org_id_parent", "")
          );
      }

      set(payloadAcount, "extra_info.insurance_codes", ["con_nguoi"]);
      set(payloadAcount, "extra_info.user_title_id", 2);
      set(payloadAcount, "status", 1);

      const arrApi = [
        getListOrg({
          limit: 10,
          offset: 0,
          org_type: get(payloadAcount, "extra_info.userType", null),
        }),
      ];

      if (userTypeFromProfile === "merchant") {
        // arrApi.push(
        //   getListMerchant({
        //     limit: 500,
        //     offset: 0,
        //   })
        // );
        set(
          payloadAcount,
          "extra_info.merchantId",
          user?.extra_info?.merchantId
        );
      }

      if (userTypeFromProfile === "provider") {
        arrApi.push(
          getListProvider({
            limit: 20,
            offset: 0,
          })
        );
        set(
          payloadAcount,
          "extra_info.providerId",
          user?.extra_info?.providerId
        );
      }

      dispatch("onLoading");
      const [listOrgAPi, listMerchantAPI] = await Promise.all(arrApi);

      const responseOrg = get(listOrgAPi, "data");
      res.listMerchants = get(listMerchantAPI, "data.result.data" || []) || [];
      res.payloadListAutocomplete = {
        org: {
          total: get(listOrgAPi, "data.result.total", 0),
          page: 1,
          param: { org_type: get(payloadAcount, "extra_info.userType", null) },
        },
        provider: {
          total: get(listMerchantAPI, "data.result.total", 0),
          page: 1,
          param: {},
        },
      };

      if (responseOrg.statusCode === 200) {
        res.listOrg = get(responseOrg, "result.data", []) || [];

        const firstItem = isRoot
          ? res.listOrg?.find(
              (i) => i?.extra_info?.org_type === "globalcare"
            ) || get(responseOrg, "result.data[0]", {})
          : ["tpag", "tpa", "merchant", "provider", "tpas"].includes(
              userTypeFromProfile
            )
          ? res.listOrg?.find(
              (i) => i?.id == get(user, "extra_info.org_id", "")
            ) || get(responseOrg, "result.data[0]", {})
          : get(responseOrg, "result.data[0]", {}) || {};

        set(payloadAcount, "extra_info.org_id", firstItem?.id || "");
        res.roleGroupByOrg = get(firstItem, "role_groups", []) || [];
        set(
          payloadAcount,
          "extra_info.role_group_id",
          get(firstItem, "role_groups[0].id") || null
        );
        res.roleGroup = get(firstItem, "role_groups[0]", {}) || {};

        const listProductsApi = await getListProduct({
          statusId: 1,
          insuranceCode: "con_nguoi",
          orgId:
            payloadAcount?.extra_info?.userType == "globalcare"
              ? null
              : getOrgIdForListProduct({
                  payloadAcount,
                }),
          limit: 500,
          offset: 0,
        });

        res.listProducts =
          userTypeFromProfile === "globalcare" ||
          (["tpag", "tpa"].includes(userTypeFromProfile) &&
            (get(user, "extra_info.user_title") ||
              get(user, "extra_info.user_title_id")) === 1)
            ? get(listProductsApi, "data.result.data", []) || []
            : get(listProductsApi, "data.result.data", []).filter((i) =>
                listProductFromUser
                  .map((j) => +j?.product_id_parent)
                  .includes(+i.id)
              );
      }

      return { payloadAcount, loading: false, ...res };
    },

    initialsUpdate: async () => {
      dispatch("onLoading");
      const detailAPI = await getDetialAccount(userId);
      // const [detailAPI, listOrgAPi] = await Promise.all([
      //   getDetialAccount(userId),
      //   getListOrg({ limit: 200, offset: 0 }),
      // ]);
      const payloadAcount = {};
      const res = {};

      const responseDetail = get(detailAPI, "data", {}) || {};
      res.details = get(responseDetail, "result", {}) || {};

      if (responseDetail.statusCode !== 200) {
        return {
          loaidng: false,
          snackbar: {
            open: true,
            type: "error",
            message:
              "Đã xảy ra lỗi trong quá trình lấy dữ liệu, xin vui lòng thử lại",
          },
        };
      }

      payloadAcount.extra_info = {
        ...get(responseDetail, "result.extra_info", {}),
        userType: get(responseDetail, "result.extra_info.userType", null),
      };
      payloadAcount.fullname = get(responseDetail, "result.fullname", "");
      payloadAcount.username = get(responseDetail, "result.username", "");
      payloadAcount.email = get(responseDetail, "result.email", "");
      payloadAcount.status = get(responseDetail, "result.status", "");
      payloadAcount.phone_number = get(
        responseDetail,
        "result.phone_number",
        ""
      );
      set(
        payloadAcount,
        "role_management.claim.providers",
        get(responseDetail, "result.role_management_claim.providers", [])
      );

      const arrayApi = [
        getListOrg({
          limit: 10,
          offset: 0,
          org_type: get(responseDetail, "result.extra_info.userType", null),
          org_id: get(responseDetail, "result.extra_info.org_id", null),
        }),
        getListRoleGroupByOrg(
          get(responseDetail, "result.extra_info.org_id", "")
        ),
        getRoleGroupById(
          get(responseDetail, "result.extra_info.role_group_id", "")
        ),
        getListProduct({
          statusId: 1,
          orgId: ["bvpk", "provider", "globalcare"].includes(
            get(res, "details.extra_info.userType", null)
          )
            ? null
            : getOrgIdForListProduct({
                payloadAcount,
              }),
          insuranceCodes:
            get(res, "details.extra_info.insurance_codes", [])?.length > 0
              ? get(res, "details.extra_info.insurance_codes")
              : null,
          limit: 500,
          offset: 0,
          providers:
            res?.details?.extra_info?.userType === "bvpk"
              ? get(responseDetail, "result.role_management_claim.providers") ||
                null
              : res?.details?.extra_info?.userType === "provider"
              ? get(responseDetail, "result.extra_info.providerId")
                ? [get(responseDetail, "result.extra_info.providerId")]
                : null
              : null,
        }),
      ];

      res?.details?.extra_info?.userType === "merchant" &&
        // arrayApi.push(
        //   getListMerchant({
        //     limit: 500,
        //     offset: 0,
        //   })
        // );

        ["provider", "bvpk"].includes(res?.details?.extra_info?.userType) &&
        arrayApi.push(
          getListProvider({
            limit: 20,
            offset: 0,
            provider_id: get(
              responseDetail,
              "result.extra_info.providerId",
              null
            ),
          })
        );

      const [
        listOrgAPi,
        listCompanyByOrg,
        roleGroupApi,
        listProductsApi,
        listMerchantAPI,
      ] = await Promise.all(arrayApi);

      res.listOrg = get(listOrgAPi, "data.result.data", []) || [];
      res.payloadListAutocomplete = {
        org: {
          total: get(listOrgAPi, "data.result.total", 0),
          page: 1,
          param: {
            org_type: get(responseDetail, "result.extra_info.userType", null),
            org_id: get(responseDetail, "result.extra_info.org_id", null),
          },
        },
        provider: {
          total: get(listMerchantAPI, "data.result.total", 0),
          page: 1,
          param: {
            provider_id: get(
              responseDetail,
              "result.extra_info.providerId",
              null
            ),
          },
        },
      };

      if (get(listMerchantAPI, "data.statusCode") === 200) {
        if (res?.details?.extra_info?.userType === "bvpk") {
          res.listProviderBVKP =
            get(listMerchantAPI, "data.result.data" || []) || [];
          res.oldListProviderBVKP = [
            ...(get(listMerchantAPI, "data.result.data" || []) || []),
          ];
        } else {
          res.listMerchants =
            get(listMerchantAPI, "data.result.data" || []) || [];
        }
      }

      if (
        ["tpas", "merchant"].includes(
          get(responseDetail, "result.extra_info.userType", null)
        )
      ) {
        const itemOrg = res.listOrg?.find(
          (i) => i.id === get(responseDetail, "result.extra_info.org_id", "")
        );
        !isEmpty(itemOrg) &&
          set(
            payloadAcount,
            "extra_info.org_id_parent",
            itemOrg?.id_parent || itemOrg?.parent_id
          );
      }

      const product_parents =
        get(
          responseDetail,
          "result.role_management_claim.product_parents",
          []
        ) || [];

      res.listProducts = (
        userTypeFromProfile === "globalcare" ||
        (["tpag", "tpa"].includes(userTypeFromProfile) &&
          (get(user, "extra_info.user_title") ||
            get(user, "extra_info.user_title_id")) === 1)
          ? get(listProductsApi, "data.result.data", []) || []
          : get(listProductsApi, "data.result.data", []).filter((i) =>
              listProductFromUser
                .map((j) => +j?.product_id_parent)
                .includes(+i.id)
            )
      ).map((item) => {
        const temp =
          product_parents.find((i) => i.product_id_parent == item.id) || {};

        return { ...item, ...temp, isCheck: !isEmpty(temp) };
      });
      res.roleGroup = get(roleGroupApi, "data.result", {}) || {};
      res.roleGroupByOrg =
        get(listCompanyByOrg, "data.result.role_groups", []) || [];

      return { loading: false, ...res, payloadAcount };
    },

    onChangeTypeAccount: async () => {
      const { name, value } = action;
      const {
        payloadAcount,
        listProducts,
        listOrg,
        errors,
        roleGroupByOrg,
        payloadListAutocomplete,
      } = state;
      const res = {};

      res.listProducts = listProducts.map((i) => ({
        ...i,
        isCheck: false,
        level_id: null,
        approve_from: null,
        approve_to: null,
      }));

      dispatch("onLoading");
      // set(payloadAcount, "extra_info.org_id_parent", null);
      switch (name) {
        case "userType":
          set(payloadAcount, "extra_info.userType", value);
          set(payloadAcount, "extra_info.merchantId", null);
          set(payloadAcount, "extra_info.providerId", null);
          set(payloadAcount, "extra_info.user_title_id", 1);
          set(payloadListAutocomplete, "org.param.org_type", value);

          if (value === "tpas") {
            userTypeFromProfile === "tpas" &&
              set(payloadAcount, "extra_info.user_title_id", 2);
          }

          if (!["bvpk"].includes(value)) {
            set(payloadAcount, "role_management.claim.providers", null);
            const arrayApi = [
              getListOrg({
                limit: 10,
                offset: 0,
                org_type: value,
              }),
            ];

            // value === "merchant" &&
            //   arrayApi.push(
            //     getListMerchant({
            //       limit: 500,
            //       offset: 0,
            //     })
            //   );

            value === "provider" &&
              arrayApi.push(
                getListProvider({
                  limit: 20,
                  offset: 0,
                })
              );
            const [listOrgAPi, listMerchantAPI] = await Promise.all(arrayApi);
            res.listMerchants =
              get(listMerchantAPI, "data.result.data" || []) || [];

            if (listOrgAPi?.data?.statusCode === 200) {
              res.listOrg = get(listOrgAPi, "data.result.data" || []) || [];
              res.payloadListAutocomplete = {
                ...payloadListAutocomplete,
                org: {
                  total: get(listOrgAPi, "data.result.total", 0),
                  page: 1,
                  param: {
                    org_type: value,
                  },
                },
                provider: {
                  total: get(listMerchantAPI, "data.result.total", 0),
                  page: 1,
                  param: {},
                },
              };
              let firstItem = get(listOrgAPi, "data.result.data[0]") || {};
              // if (value === "merchant") {
              //   firstItem =
              //     res?.listOrg?.find(
              //       (i) => i.id == get(res.listMerchants, "[0].orgId")
              //     ) || {};
              // }
              set(
                payloadAcount,
                "extra_info.org_id",
                value === "merchant"
                  ? firstItem.id || get(res.listMerchants, "[0].orgId")
                  : firstItem.id
              );

              res.roleGroupByOrg = get(firstItem, "role_groups", []) || [];

              set(
                payloadAcount,
                "extra_info.role_group_id",
                get(firstItem, "role_groups[0].id") || null
              );
              res.roleGroup = get(firstItem, "role_groups[0]", {}) || {};

              ["tpas", "merchant"].includes(value) &&
                set(
                  payloadAcount,
                  "extra_info.org_id_parent",
                  firstItem?.id_parent || firstItem.parent_id || null
                );

              const listProductApi = await getListProduct({
                providers:
                  value === "provider"
                    ? [get(res.listMerchants, "[0].id")]
                    : null,
                statusId: 1,
                orgId:
                  value === "globalcare"
                    ? null
                    : getOrgIdForListProduct({ payloadAcount }),
                insuranceCodes:
                  payloadAcount?.extra_info?.insurance_codes || null,
                limit: 500,
                offset: 0,
              });

              res.listProducts =
                userTypeFromProfile === "globalcare" ||
                (["tpag", "tpa"].includes(userTypeFromProfile) &&
                  (get(user, "extra_info.user_title") ||
                    get(user, "extra_info.user_title_id")) === 1)
                  ? get(listProductApi, "data.result.data", []) || []
                  : get(listProductApi, "data.result.data", []).filter((i) =>
                      listProductFromUser
                        .map((j) => +j?.product_id_parent)
                        .includes(+i.id)
                    );
            }
          }

          if (isAdminGC && value === "globalcare") {
            set(payloadAcount, "extra_info.user_title_id", 2);
            // const temp =
            //   res.listOrg?.find(
            //     (i) => i?.extra_info?.org_type === "globalcare"
            //   ) || res.listOrg[0];
            // set(payloadAcount, "extra_info.org_id", temp?.id || "");
          }

          if (userTypeFromProfile === "tpag" && value === "tpag") {
            set(payloadAcount, "extra_info.user_title_id", 2);
          }

          if (value === "bvpk") {
            set(payloadAcount, "role_management.claim.providers", []);
            set(payloadAcount, "extra_info.insurance_codes", ["con_nguoi"]);
            // set(payloadAcount, "extra_info.org_id", null);
            const [lisProviderApi, listProductsApi, listOrgAPi] =
              await Promise.all([
                getListProvider({
                  limit: 20,
                  offset: 0,
                  enable_claim: 1,
                }),
                getListProduct({
                  statusId: 1,
                  insuranceCode: "con_nguoi",
                  orgId: null,
                  limit: 500,
                  offset: 0,
                }),
                getListOrg({
                  limit: 10,
                  offset: 0,
                  org_type: value,
                }),
              ]);

            res.listProviderBVKP =
              get(lisProviderApi, "data.result.data", []) || [];
            res.oldListProviderBVKP = [
              ...(get(lisProviderApi, "data.result.data" || []) || []),
            ];
            res.listProducts =
              get(listProductsApi, "data.result.data", []) || [];
            res.listOrg = get(listOrgAPi, "data.result.data" || []) || [];
            res.payloadListAutocomplete = {
              ...payloadListAutocomplete,
              org: {
                total: get(listOrgAPi, "data.result.total", 0),
                page: 1,
                param: {
                  org_type: value,
                },
              },
              provider: {
                total: get(lisProviderApi, "data.result.total", 0),
                page: 1,
                param: { enable_claim: 1 },
              },
            };
            const firstItem = get(listOrgAPi, "data.result.data[0]") || {};
            set(payloadAcount, "extra_info.org_id", firstItem.id || null);
            res.roleGroupByOrg = get(firstItem, "role_groups", []) || [];

            set(
              payloadAcount,
              "extra_info.role_group_id",
              get(firstItem, "role_groups[0].id") || null
            );
            res.roleGroup = get(firstItem, "role_groups[0]", {}) || {};
          }

          if (value === "merchant") {
            set(
              payloadAcount,
              "extra_info.merchantId",
              get(res.listMerchants, "[0].id")
            );
            // set(payloadAcount, "extra_info.user_title_id", 2);
          }

          if (value === "provider") {
            set(
              payloadAcount,
              "extra_info.providerId",
              get(res.listMerchants, "[0].id")
            );
          }

          break;

        case "user_title_id":
          set(payloadAcount, "extra_info.user_title_id", value);
          if (
            value === 2 &&
            ["tpag", "tpas", "globalcare"].includes(
              payloadAcount?.extra_info?.userType
            )
          ) {
            const dataApi = await getListProduct({
              statusId: 1,
              insuranceCodes:
                payloadAcount?.extra_info?.insurance_codes?.length > 0
                  ? payloadAcount?.extra_info?.insurance_codes
                  : null,
              orgId:
                payloadAcount?.extra_info?.userType === "globalcare"
                  ? null
                  : getOrgIdForListProduct({ payloadAcount }),
              limit: 500,
              offset: 0,
            });
            res.listProducts =
              userTypeFromProfile === "globalcare" ||
              (["tpag", "tpa"].includes(userTypeFromProfile) &&
                (get(user, "extra_info.user_title") ||
                  get(user, "extra_info.user_title_id")) === 1)
                ? get(dataApi, "data.result.data", []) || []
                : get(dataApi, "data.result.data", []).filter((i) =>
                    listProductFromUser
                      .map((j) => +j?.product_id_parent)
                      .includes(+i.id)
                  );
          }

          break;

        case "org_id":
          set(payloadAcount, "extra_info.org_id", value?.id);
          res.roleGroupByOrg = value?.role_groups || [];
          set(
            payloadAcount,
            "extra_info.role_group_id",
            get(value, "role_groups[0].id") || null
          );
          set(payloadListAutocomplete, "org.param.title", "");

          if (payloadAcount?.extra_info?.userType === "merchant") {
            // set(payloadAcount, "extra_info.org_id", value?.orgId);
            set(
              payloadAcount,
              "extra_info.merchantId",
              value?.extra_info?.merchant_id
            );
            // const itemOrg = listOrg.find((i) => i.id === value?.orgId);
            // res.roleGroupByOrg = itemOrg?.role_groups || [];
            // set(
            //   payloadAcount,
            //   "extra_info.role_group_id",
            //   get(itemOrg, "role_groups[0].id") || null
            // );
          }

          const arrApi = [];
          payloadAcount?.extra_info?.userType !== "provider" &&
            arrApi.push(
              getListProduct({
                statusId: 1,
                insuranceCodes: get(
                  payloadAcount,
                  "extra_info.insurance_codes",
                  null
                ),
                orgId: getOrgIdForListProduct({ payloadAcount }),
                limit: 500,
                offset: 0,
              })
            );
          const [listProductsApi] = await Promise.all(arrApi);

          if (listProductsApi?.data?.statusCode === 200) {
            res.listProducts =
              userTypeFromProfile === "globalcare" ||
              (["tpag", "tpa"].includes(userTypeFromProfile) &&
                (get(user, "extra_info.user_title") ||
                  get(user, "extra_info.user_title_id")) === 1)
                ? get(listProductsApi, "data.result.data", []) || []
                : get(listProductsApi, "data.result.data", []).filter((i) =>
                    listProductFromUser
                      .map((j) => +j?.product_id_parent)
                      .includes(+i.id)
                  );
          }

          break;

        case "merchantId":
          set(payloadAcount, "extra_info.merchantId", value);

          break;

        case "providerId":
          set(payloadAcount, "extra_info.providerId", value);
          set(payloadListAutocomplete, "provider.param.keyword", "");
          const listProductApi = await getListProduct({
            statusId: 1,
            insuranceCodes: get(
              payloadAcount,
              "extra_info.insurance_codes",
              null
            ),
            orgId: null,
            providers: [value],
            limit: 500,
            offset: 0,
          });
          if (listProductApi?.data?.statusCode === 200) {
            res.listProducts =
              userTypeFromProfile === "globalcare" ||
              (["tpag", "tpa"].includes(userTypeFromProfile) &&
                (get(user, "extra_info.user_title") ||
                  get(user, "extra_info.user_title_id")) === 1)
                ? get(listProductApi, "data.result.data", []) || []
                : get(listProductApi, "data.result.data", []).filter((i) =>
                    listProductFromUser
                      .map((j) => +j?.product_id_parent)
                      .includes(+i.id)
                  );
          }

          break;

        case "role_group_id":
          set(payloadAcount, "extra_info.role_group_id", value);
          res.roleGroup = roleGroupByOrg.find((i) => i.id == value) || {};

          break;

        default:
          break;
      }
      res.errors = errors.filter(
        (i) => !["org_id", "merchantId", "providerId"].includes(i)
      );

      return { loading: false, payloadAcount, ...res };
    },

    onChangeInforAccount: () => {
      const { name, value } = action;
      const { payloadAcount, errors } = state;

      payloadAcount[name] = value;
      const res = {};
      res.errors = errors.filter((i) => i !== name);

      return { payloadAcount, ...res };
    },

    onCheckProductParent: () => {
      const { item, indexProduct, checked } = action;
      const { listProducts, roleGroup, payloadAcount } = state;
      listProducts[indexProduct] = {
        ...item,
        isCheck: checked,
      };

      if (
        get(roleGroup, "privileges.CLAIM_APPROVE", null) === 1 &&
        payloadAcount?.extra_info?.userType === "tpag"
      ) {
        listProducts[indexProduct] = {
          ...item,
          isCheck: checked,
          level_id: 5,
        };
      }

      if (
        get(roleGroup, "privileges.CLAIM_CHECK", null) === 1 &&
        ["tpas", "tpa"].includes(payloadAcount?.extra_info?.userType) &&
        payloadAcount?.extra_info?.user_title_id === 2
      ) {
        listProducts[indexProduct] = {
          ...item,
          isCheck: checked,
          level_id: 1,
        };
      }

      return { listProducts };
    },

    onChangeItemProduct: () => {
      const { item, index, value, name } = action;
      const { listProducts } = state;

      if (name == "approve_to") {
        const itemProductFormUser = listProductFromUser.find(
          (i) => i.product_id_parent == item.id
        );
        if (
          +itemProductFormUser?.approve_to > 0 &&
          +value > +itemProductFormUser?.approve_to
        ) {
          return {
            snackbar: {
              open: true,
              type: "error",
              message: `Số tiền tối đa dưới không được quá ${itemProductFormUser?.approve_to
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")} đ`,
            },
          };
        }
      }

      listProducts[index] = {
        ...item,
        [name]: value,
      };

      if (name === "level_id") {
        listProducts[index] = {
          ...item,
          [name]: value,
          approve_from: null,
          approve_to: null,
        };
      }

      return { listProducts };
    },

    changeStatus: () => {
      const { value } = action;
      const { payloadAcount } = state;
      payloadAcount.status = value ? 1 : 0;
      return { payloadAcount };
    },

    onCheckTypeOfInsuranceForUser: async () => {
      const { code, value } = action;
      const { payloadAcount } = state;
      const insurance_codes = value
        ? [...get(payloadAcount, "extra_info.insurance_codes", []), code]
        : get(payloadAcount, "extra_info.insurance_codes", []).filter(
            (i) => i != code
          );
      set(payloadAcount, "extra_info.insurance_codes", insurance_codes);
      const res = {};

      if (
        // payloadAcount?.extra_info?.userType === "globalcare" ||
        ["tpag", "tpa", "globalcare"].includes(
          payloadAcount?.extra_info?.userType
        ) &&
        payloadAcount?.extra_info?.user_title_id === 1
      ) {
        return { payloadAcount };
      }

      const params = {
        statusId: 1,
        insuranceCodes: insurance_codes?.length > 0 ? insurance_codes : null,
        orgId:
          payloadAcount?.extra_info?.userType === "globalcare"
            ? null
            : getOrgIdForListProduct({ payloadAcount }),
        limit: 500,
        offset: 0,
      };

      dispatch("onLoading");
      const dataApi = await getListProduct(params);
      res.listProducts =
        userTypeFromProfile === "globalcare" ||
        (["tpag", "tpa"].includes(userTypeFromProfile) &&
          (get(user, "extra_info.user_title") ||
            get(user, "extra_info.user_title_id")) === 1)
          ? get(dataApi, "data.result.data", []) || []
          : get(dataApi, "data.result.data", []).filter((i) =>
              listProductFromUser
                .map((j) => +j?.product_id_parent)
                .includes(+i.id)
            );

      return { loading: false, payloadAcount, ...res };
    },

    onCheckProviderBVPK: async () => {
      const { item, value } = action;
      const { payloadAcount } = state;

      const res = {};
      const providerCheck = value
        ? [
            ...get(payloadAcount, "role_management.claim.providers", []),
            +item.id,
          ]
        : get(payloadAcount, "role_management.claim.providers", []).filter(
            (i) => i != item.id
          );
      set(payloadAcount, "role_management.claim.providers", providerCheck);
      dispatch("onLoading");
      const listProductsApi = await getListProduct({
        statusId: 1,
        orgId: null,
        insuranceCodes: ["con_nguoi"],
        limit: 500,
        offset: 0,
        providers: providerCheck.length > 0 ? providerCheck : null,
      });

      if (listProductsApi?.data?.statusCode === 200) {
        res.listProducts = get(listProductsApi, "data.result.data", []) || [];
      }

      return { payloadAcount, ...res, loading: false };
    },

    submitSave: async () => {
      const { payloadAcount, listProducts, details } = state;

      const errors = [];

      !payloadAcount?.username && errors.push("username");
      !payloadAcount?.fullname && errors.push("fullname");
      !payloadAcount?.email && errors.push("email");
      !payloadAcount?.extra_info?.org_id && errors.push("org_id");
      !payloadAcount?.extra_info?.merchantId &&
        payloadAcount?.extra_info?.userType === "merchant" &&
        errors.push("merchantId");
      !payloadAcount?.extra_info?.providerId &&
        payloadAcount?.extra_info?.userType === "provider" &&
        errors.push("providerId");

      if (errors.length > 0) {
        return {
          snackbar: {
            open: true,
            type: "error",
            message: "Vui lòng nhập đầy đủ thông tin",
          },
          errors,
        };
      }

      const product_parents = listProducts
        .filter((i) => i.isCheck)
        .map((i) => ({
          product_id_parent: i.id,
          approve_from: i.approve_from || null,
          approve_to: i.approve_to || null,
          level_id: i.level_id === "none" ? null : i.level_id || null,
        }));

      const userType = payloadAcount.extra_info?.userType;
      // const userType =
      //   payloadAcount.extra_info?.userType === "merchant_gc"
      //     ? "merchant"
      //     : payloadAcount.extra_info?.userType;

      const params = {
        ...payloadAcount,
        extra_info: {
          ...payloadAcount.extra_info,
          userType,
          // org_id_parent:,
        },
        role_management: {
          claim: {
            product_parents,
            ...(payloadAcount?.role_management?.claim || {}),
          },
        },
      };

      dispatch("onLoading");

      const dataApi =
        userId === "new"
          ? await createAccount(userType, params)
          : await updateAccount(payloadAcount?.extra_info?.userType, {
              ...params,
              id: details?.id || null,
            });

      if (
        ["user.admin.existed.error", "user.existed"].includes(
          dataApi?.data?.messageCode
        )
      ) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Tài khoản admin đã tồn tại.",
          },
        };
      }

      if (dataApi?.data?.statusCode !== 200) {
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message:
              dataApi?.data?.messageCode === "user.missing.permission"
                ? "Xin lỗi, bạn không quyền để tạo TK này"
                : "Đã xảy ra lỗi trong quá trình tạo, vui lòng thử lại",
          },
        };
      }

      userId === "new" && (await delay(2000));
      history.push("/admin/account-management");
      return {
        loading: false,
        snackbar: {
          open: true,
          type: "success",
          message:
            userId === "new"
              ? "Tạo tài khoản thành công"
              : "Cập nhật tài khoản thành công",
        },
      };
    },

    openDialogNotification: () => {
      const { typeDialog, message } = action;
      return { dialogNotifica: { open: true, type: typeDialog, message } };
    },

    onCloseDialogNotifica: () => {
      return { dialogNotifica: { open: false } };
    },

    resetPassword: async () => {
      const { details } = state;
      dispatch("onLoading");
      const dataApi =
        userTypeFromProfile === "globalcare"
          ? await resetPasswordGC({ userId: details.id })
          : await resetPassword(userTypeFromProfile, { userId: details.id });

      if (dataApi?.data?.statusCode !== 200) {
        dispatch("onCloseDialogNotifica");
        return {
          loading: false,
          snackbar: {
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
        };
      }

      dispatch({
        type: "openDialogNotification",
        typeDialog: "success",
        message: ["Reset mật khẩu thành công"],
      });

      return {
        loading: false,
      };
    },

    getMoveListOrg: async () => {
      const { payloadListAutocomplete, listOrg, loadingAutocomplete } = state;

      dispatch({ type: "onLoadingAutocomolete", name: "org" });
      const listOrgAPi = await getListOrg({
        ...get(payloadListAutocomplete, "org.param", {}),
        limit: 10,
        offset: payloadListAutocomplete?.org?.page * 10 || 0,
      });
      const res = {};

      res.listOrg = [
        ...listOrg,
        ...(get(listOrgAPi, "data.result.data" || []) || []),
      ];
      res.payloadListAutocomplete = {
        ...payloadListAutocomplete,
        org: {
          total: get(listOrgAPi, "data.result.total", 0),
          page: payloadListAutocomplete?.org?.page + 1,
          param: {
            ...get(payloadListAutocomplete, "org.param", {}),
          },
        },
      };

      return {
        ...res,
        loadingAutocomplete: { ...loadingAutocomplete, org: false },
      };
    },

    onInputChangeOrg: async () => {
      const { value } = action;
      const { payloadListAutocomplete, loadingAutocomplete, payloadAcount } =
        state;
      // set(payloadAcount, "extra_info.org_id", null);

      await dispatch({ type: "resetAutocomplete", value, name: "org" });
      dispatch({ type: "onLoadingAutocomolete", name: "org" });
      const listOrgAPi = await getListOrg({
        ...get(payloadListAutocomplete, "org.param", {}),
        title: value || null,
        limit: 10,
        offset: 0,
      });
      const res = {};
      await delay(800);
      if (get(state, "payloadListAutocomplete.org.param.title") !== value) {
        return;
      }
      res.listOrg = [...(get(listOrgAPi, "data.result.data" || []) || [])];
      res.payloadListAutocomplete = {
        ...payloadListAutocomplete,
        org: {
          ...payloadListAutocomplete.org,
          total: get(listOrgAPi, "data.result.total", 0),
          page: 1,
        },
      };
      return {
        ...res,
        // payloadAcount,
        loadingAutocomplete: { ...loadingAutocomplete, org: false },
      };
    },

    resetAutocomplete: () => {
      const { value, name } = action;
      const { payloadAcount, payloadListAutocomplete } = state;

      if (name === "org") {
        set(payloadAcount, "extra_info.org_id", null);
        set(payloadListAutocomplete, "org.param.title", value);
      }
      if (name === "provider") {
        set(payloadAcount, "extra_info.providerId", null);
        set(payloadListAutocomplete, "provider.param.keyword", value);
      }

      return { payloadAcount, payloadListAutocomplete };
    },

    getMoveListProvider: async () => {
      const {
        payloadListAutocomplete,
        listMerchants,
        loadingAutocomplete,
        listProviderBVKP,
        oldListProviderBVKP,
        payloadAcount,
      } = state;

      if (
        payloadAcount?.extra_info?.userType === "bvpk" &&
        oldListProviderBVKP?.length > listProviderBVKP?.length
      ) {
        return { listProviderBVKP: [...oldListProviderBVKP] };
      }

      dispatch({ type: "onLoadingAutocomolete", name: "provider" });

      const listMerchantAPI = await getListProvider({
        ...get(payloadListAutocomplete, "provider.param", {}),
        limit: 10,
        offset: payloadListAutocomplete?.provider?.page * 10 || 0,
      });
      const res = {};

      if (payloadAcount?.extra_info?.userType === "bvpk") {
        res.listProviderBVKP = [
          ...listProviderBVKP,
          ...(get(listMerchantAPI, "data.result.data" || []) || []),
        ];
        res.oldListProviderBVKP = [
          ...oldListProviderBVKP,
          ...(get(listMerchantAPI, "data.result.data" || []) || []),
        ];
      } else {
        res.listMerchants = [
          ...listMerchants,
          ...(get(listMerchantAPI, "data.result.data" || []) || []),
        ];
      }

      res.payloadListAutocomplete = {
        ...payloadListAutocomplete,
        provider: {
          total: get(listMerchantAPI, "data.result.total", 0),
          page: payloadListAutocomplete?.provider?.page + 1,
          param: {
            ...get(payloadListAutocomplete, "provider.param", {}),
          },
        },
      };

      return {
        ...res,
        loadingAutocomplete: { ...loadingAutocomplete, provider: false },
      };
    },

    collapListProvider: () => {
      const { listProviderBVKP } = state;
      const newList = listProviderBVKP.slice(0, 20);
      return { listProviderBVKP: [...newList] };
    },

    onInputChangeProvider: async () => {
      const { value } = action;
      const { payloadListAutocomplete, listMerchants, loadingAutocomplete } =
        state;

      await dispatch({ type: "resetAutocomplete", value, name: "provider" });
      dispatch({ type: "onLoadingAutocomolete", name: "provider" });

      const listMerchantAPI = await getListProvider({
        ...get(payloadListAutocomplete, "provider.param", {}),
        keyword: value,
        limit: 10,
        offset: 0,
      });
      await delay(800);
      if (
        get(state, "payloadListAutocomplete.provider.param.keyword") !== value
      ) {
        return;
      }
      const res = {};
      res.listMerchants = [
        ...(get(listMerchantAPI, "data.result.data" || []) || []),
      ];
      res.payloadListAutocomplete = {
        ...payloadListAutocomplete,
        provider: {
          ...payloadListAutocomplete.provider,
          total: get(listMerchantAPI, "data.result.total", 0),
          page: 1,
        },
      };

      return {
        ...res,
        loadingAutocomplete: { ...loadingAutocomplete, provider: false },
      };
    },

    //

    closeSnackbar: () => ({ snackbar: { open: false } }),

    onLoading: () => ({ loading: true }),

    onLoadingAutocomolete: () => ({
      loadingAutocomplete: {
        ...state?.loadingAutocomplete,
        [action.name]: true,
      },
    }),

    //
  };

  return isString(action.type) && cases[action.type];
}
