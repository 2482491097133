import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { MenuItem } from "@mui/material";

import useReducer from "../../../../../library/hooks/useReducer";
import LoadingMui from "../../../../../ui/LoadingMui";
import Snackbars from "../../../../../ui/snackbar";
import ChartCard from "../components/dashboard/chartCard";
import FlagCard from "../components/dashboard/flagCard";
import { styeleds } from "../components/dashboard/styled";
import { TextField } from "../overrideMui";
import dashboardReducer from "../reducer/dashboardReducer";
import { initialDashboardState } from "../utils";

export default function Dashboard() {
  const classes = styeleds();
  const roleAdminMenuItem = JSON.parse(
    localStorage.getItem("roleAdminMenuItem")
  );

  const [state, dispatch] = useReducer({
    initialState: initialDashboardState,
    name: "dashboardReducer",
    reducer: dashboardReducer,
    roleAdminMenuItem,
  });

  const { listProvider, provider, loading, snackbar } = state;

  useEffect(() => dispatch("inittal"), []);

  return (
    <div className={classes.container}>
      <div className={classes.selectComperny}>
        <span>Nhà bảo hiểm</span>
        <TextField
          className={`${classes.textFieldSelect}`}
          select
          disabled={roleAdminMenuItem?.userType === "provider"}
          value={provider?.provider_id || ""}
          onChange={(e) =>
            dispatch({ type: "onSelectCompany", provider_id: e?.target?.value })
          }
        >
          {listProvider?.map((item, key) => (
            <MenuItem value={item?.provider_id} key={key}>
              {item?.provider_title}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <FlagCard {...{ state, dispatch }} />
      <ChartCard {...{ state, dispatch }} />
      <LoadingMui loading={loading} />
      <Snackbars
        open={!!snackbar.open}
        type={snackbar.type}
        onClose={() => dispatch("closeSnackbar")}
        message={snackbar.message}
      />
    </div>
  );
}
