import React from "react";
import { makeStyles } from "@mui/styles";
import { TableCell } from "@mui/material";

import CommonTable from "../../../../../../library/layout/commonTable";
import { type_of_indemnify, code_rules } from "../../utils";

const getType = (id, name) => {
  const itemType = code_rules.find((i) => i.id === id) || {};
  const itemIndemnify = type_of_indemnify.find((i) => i.id === id);
  return name === "type" ? itemType?.title || "" : itemIndemnify?.title || "";
};

export default function InsuranceBenefitTable(props) {
  const classes = useMakeStykles();
  const { state } = props;
  const { rowsInsuranceBenefit } = state;

  return (
    <div>
      <CommonTable
        classes={classes}
        rows={rowsInsuranceBenefit}
        columns={columns}
      />
    </div>
  );
}

const columns = [
  {
    label: "Mã quyền lợi",
    headerClass: "code",
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst code stickey"
            : item.isSecond
            ? "isSecond code stickey"
            : "code stickey"
        }
      >
        {item?.code_benefit || ""}
      </TableCell>
    ),
  },
  {
    label: "Tên quyền lợi ",
    headerClass: "name-benefits",
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst name-benefits"
            : item.isSecond
            ? "isSecond name-benefits"
            : "name-benefits"
        }
      >
        {/* {item?.title || ""} */}
        <>
          <div>{item?.title || ""}</div>
          <div
            style={{
              color: "#686868",
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: "400",
            }}
          >
            {"Loại: " +
              ((
                code_rules?.find(
                  (i) => i.id === item?.config_benefit?.code_rules
                ) || {}
              )?.title || "")}
          </div>
        </>
      </TableCell>
    ),
  },
  {
    label: "Quyền lợi",
    headerClass: "benefits",
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={item.isFirst ? "isFirst" : item.isSecond ? "isSecond" : " "}
      >
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {+item?.newValue >= 0
              ? `${item?.newValue || "0"}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              : item?.config_benefit?.value || "0"}
            {/* {+item?.config_benefit?.value >= 0
              ? `${
                  +item?.config_benefit?.value - +(item.amount_is_used || 0) ||
                  "0"
                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              : item?.config_benefit?.value || "0"} */}
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#4B4B4B",
              lineHeight: "22px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              {`${(item?.config_benefit?.type_of_indemnify || [])
                .map((item) => getType(item, "type_of_indemnify"))
                .join(" / ")}`}
            </div>
            {item?.config_benefit?.code_rules !== 1 && (
              <div>{`GH phí:${
                +item?.config_benefit?.limit_fees > 0
                  ? item?.config_benefit?.limit_fees
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                  : "0"
              } đ GH  ${
                item?.config_benefit?.code_rules === 2 ? "ngày" : "lần"
              }: ${
                item?.config_benefit?.code_rules === 2
                  ? (+item?.config_benefit?.limit_day || 0) -
                      (+item?.total_date_is_used || 0) <
                    0
                    ? 0
                    : (+item?.config_benefit?.limit_day || 0) -
                      (+item?.total_date_is_used || 0)
                  : (+item?.config_benefit?.limit_number_of_times || 0) -
                      (+item?.number_time_of_claim_is_used || 0) <
                    0
                  ? 0
                  : (+item?.config_benefit?.limit_number_of_times || 0) -
                    (+item?.number_time_of_claim_is_used || 0)
              }`}</div>
            )}
          </div>
        </>
      </TableCell>
    ),
  },
];

const useMakeStykles = makeStyles(() => ({
  div: {
    paddingBottom: "20px",
  },

  table: {
    minWidth: "800px",

    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& th": {
      textAlign: "center",
      color: "white !important",
      background: "none !important",
    },

    "& td": {
      textAlign: "center",
      padding: 0,
      fontWeight: "600",
    },

    "& .stickey": {
      position: "sticky",
    },

    "& th.code": {
      width: "120px",
      textAlign: "left",
      background: "#3961A0 !important",
    },

    "& th.name-benefits": {
      textAlign: "left",
    },

    "& th.benefits": {
      width: "230px",
    },

    "& td.code": {
      padding: "0 5px",
      paddingLeft: "8px",
      textAlign: "left",
      fontWeight: "500",
      background: " white",
    },

    "& td.isFirst": {
      background: "#09BAFD",
    },

    "& td.isSecond": {
      background: "#E3F3FF",
    },

    "& td.name-benefits": {
      textAlign: "left",
      padding: "0 5px",
    },
  },

  headerRow: {
    borderTop: "none",
    borderBottom: "none",
    background: "#3961A0 !important",
    padding: "0 8px",
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #D6DEEB",
    background: "white !important",
  },
}));
