import _ from "lodash";

export const accidentTypes = [
  { name: "Súc vật cắn", value: "animal bites" },
  { name: "Hồ sơ bỏng/ phỏng", value: "bruned" },
  { name: "Tai nạn sinh hoạt", value: "life" },
  { name: "Tai nạn lao động", value: "work accident" },
  { name: "Tai nạn giao thông", value: "traffic accident" },
];

export const accidentLevel = [
  { name: "Thương tật tạm thời", value: "temporary injury" },
  { name: "Thương tật vĩnh viễn", value: "permanent disability" },
  { name: "Tử vong", value: "dead" },
];

export const treatmentTypes = [
  { name: "Nội trú", value: "boarding" },
  { name: "Ngoại Trú", value: "outpatient" },
];

export function getTypeInsu(insuredInfo, extraInfo) {
  const animalBitesBoarding =
    _.get(extraInfo, "accident.type") === "animal bites" &&
    _.get(extraInfo, "accident.level") === "temporary injury" &&
    _.get(insuredInfo, "treatmentType") === "boarding";

  const animalBitesOutpatient =
    _.get(extraInfo, "accident.type") === "animal bites" &&
    _.get(extraInfo, "accident.level") === "temporary injury" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const animalBitesDead =
    _.get(extraInfo, "accident.type") === "animal bites" &&
    _.get(extraInfo, "accident.level") === "dead";

  const animalBitesPermanentDisability =
    _.get(extraInfo, "accident.type") === "animal bites" &&
    _.get(extraInfo, "accident.level") === "permanent disability";

  const brunedTemporaryInjury =
    _.get(extraInfo, "accident.type") === "bruned" &&
    _.get(extraInfo, "accident.level") === "temporary injury";

  const brunedDead =
    _.get(extraInfo, "accident.type") === "bruned" &&
    _.get(extraInfo, "accident.level") === "dead";

  const brunedPermanentDisability =
    _.get(extraInfo, "accident.type") === "bruned" &&
    _.get(extraInfo, "accident.level") === "permanent disability";

  const lifeOutpatientBone =
    _.get(extraInfo, "accident.type") === "life" &&
    _.get(extraInfo, "accident.relate") === "bone" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const lifeOutpatient =
    _.get(extraInfo, "accident.type") === "life" &&
    _.get(extraInfo, "accident.relate") === null &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const lifeBoarding =
    _.get(extraInfo, "accident.type") === "life" &&
    _.get(extraInfo, "accident.level") === "temporary injury" &&
    _.get(insuredInfo, "treatmentType") === "boarding";

  const lifeOutpatientPermanentDisability =
    _.get(extraInfo, "accident.type") === "life" &&
    _.get(extraInfo, "accident.level") === "permanent disability" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const lifeOutpatientPermanentDisabilityBone =
    _.get(extraInfo, "accident.type") === "life" &&
    _.get(extraInfo, "accident.level") === "permanent disability" &&
    _.get(extraInfo, "accident.relate") === "bone" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const lifePermanentDisability =
    _.get(extraInfo, "accident.type") === "life" &&
    _.get(extraInfo, "accident.level") === "permanent disability";

  const lifeBoardingPermanentDisability =
    _.get(extraInfo, "accident.type") === "life" &&
    _.get(extraInfo, "accident.level") === "permanent disability" &&
    _.get(insuredInfo, "treatmentType") === "boarding";

  const lifeDead =
    _.get(extraInfo, "accident.type") === "life" &&
    _.get(extraInfo, "accident.level") === "dead";

  const workAccidentTemporaryInjuryBone =
    _.get(extraInfo, "accident.type") === "work accident" &&
    _.get(extraInfo, "accident.level") === "temporary injury" &&
    _.get(extraInfo, "accident.relate") === "bone" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const workAccidentTemporaryInjury =
    _.get(extraInfo, "accident.type") === "work accident" &&
    _.get(extraInfo, "accident.level") === "temporary injury" &&
    _.get(extraInfo, "accident.relate") === null &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const workAccidentTemporaryInjuryBoarding =
    _.get(extraInfo, "accident.type") === "work accident" &&
    _.get(extraInfo, "accident.level") === "temporary injury" &&
    _.get(insuredInfo, "treatmentType") === "boarding";

  const workAccidentPermanentDisabilityOutpatient =
    _.get(extraInfo, "accident.type") === "work accident" &&
    _.get(extraInfo, "accident.level") === "permanent disability" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const workAccidentPermanentDisabilityOutpatientBone =
    _.get(extraInfo, "accident.type") === "work accident" &&
    _.get(extraInfo, "accident.level") === "permanent disability" &&
    _.get(extraInfo, "accident.relate") === "bone" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const workAccidentPermanentDisabilityOutpatientNoBone =
    _.get(extraInfo, "accident.type") === "work accident" &&
    _.get(extraInfo, "accident.level") === "permanent disability" &&
    _.get(extraInfo, "accident.relate") !== "bone" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const workAccidentPermanentDisabilityBoarding =
    _.get(extraInfo, "accident.type") === "work accident" &&
    _.get(extraInfo, "accident.level") === "permanent disability" &&
    _.get(insuredInfo, "treatmentType") === "boarding";

  const workAccidentDead =
    _.get(extraInfo, "accident.type") === "work accident" &&
    _.get(extraInfo, "accident.level") === "dead";

  const trafficOutpatientTemporaryInjury =
    _.get(extraInfo, "accident.type") === "traffic accident" &&
    _.get(extraInfo, "accident.level") === "temporary injury" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const trafficOutpatientTemporaryInjuryBone =
    _.get(extraInfo, "accident.type") === "traffic accident" &&
    _.get(extraInfo, "accident.level") === "temporary injury" &&
    _.get(extraInfo, "accident.relate") === "bone" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const trafficOutpatientTemporaryInjuryNoBone =
    _.get(extraInfo, "accident.type") === "traffic accident" &&
    _.get(extraInfo, "accident.level") === "temporary injury" &&
    _.get(extraInfo, "accident.relate") !== "bone" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const trafficBoardingTemporaryInjury =
    _.get(extraInfo, "accident.type") === "traffic accident" &&
    _.get(extraInfo, "accident.level") === "temporary injury" &&
    _.get(insuredInfo, "treatmentType") === "boarding";

  const trafficPermanentDisabilityBoarding =
    _.get(extraInfo, "accident.type") === "traffic accident" &&
    _.get(extraInfo, "accident.level") === "permanent disability" &&
    _.get(insuredInfo, "treatmentType") === "boarding";

  const trafficPermanentDisabilityOutpatient =
    _.get(extraInfo, "accident.type") === "traffic accident" &&
    _.get(extraInfo, "accident.level") === "permanent disability" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const trafficPermanentDisabilityOutpatientBone =
    _.get(extraInfo, "accident.type") === "traffic accident" &&
    _.get(extraInfo, "accident.level") === "permanent disability" &&
    _.get(extraInfo, "accident.relate") === "bone" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const trafficPermanentDisabilityOutpatientNoBone =
    _.get(extraInfo, "accident.type") === "traffic accident" &&
    _.get(extraInfo, "accident.level") === "permanent disability" &&
    _.get(extraInfo, "accident.relate") !== "bone" &&
    _.get(insuredInfo, "treatmentType") === "outpatient";

  const trafficDead =
    _.get(extraInfo, "accident.type") === "traffic accident" &&
    _.get(extraInfo, "accident.level") === "dead";

  return {
    animalBitesBoarding,
    animalBitesOutpatient,
    animalBitesDead,
    animalBitesPermanentDisability,
    brunedTemporaryInjury,
    brunedDead,
    brunedPermanentDisability,
    lifeOutpatientBone,
    lifeOutpatient,
    lifeBoarding,
    lifePermanentDisability,
    lifeOutpatientPermanentDisability,
    lifeOutpatientPermanentDisabilityBone,
    lifeBoardingPermanentDisability,
    lifeDead,
    workAccidentTemporaryInjuryBone,
    workAccidentTemporaryInjury,
    workAccidentTemporaryInjuryBoarding,
    workAccidentPermanentDisabilityOutpatient,
    workAccidentPermanentDisabilityOutpatientBone,
    workAccidentPermanentDisabilityOutpatientNoBone,
    workAccidentPermanentDisabilityBoarding,
    workAccidentDead,
    trafficOutpatientTemporaryInjury,
    trafficOutpatientTemporaryInjuryBone,
    trafficOutpatientTemporaryInjuryNoBone,
    trafficBoardingTemporaryInjury,
    trafficPermanentDisabilityOutpatient,
    trafficPermanentDisabilityBoarding,
    trafficPermanentDisabilityOutpatientBone,
    trafficPermanentDisabilityOutpatientNoBone,
    trafficDead,
  };
}
