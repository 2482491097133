import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import LoadingMui from "../../../../../ui/LoadingMui";
import { Document, Page } from "react-pdf";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogDetailPDF(props) {
  const { state, dispatch } = props;
  const classes = useMakeStyles();

  const [states, setStates] = React.useState({
    numPages: "" || 1,
    pageShow: 1,
    width: 0,
    link: "",
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setStates({ ...state, loading: true });
    if (numPages > 1) {
      setStates({ ...state, numPages });
    }
  };

  const { loading, dialogDetailPDF } = state;

  return (
    <Dialog
      open={!!dialogDetailPDF?.open}
      TransitionComponent={Transition}
      maxWidth={"lg"}
      onClose={() => dispatch("closeDialogDetailPDF")}
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton onClick={() => dispatch("closeDialogDetailPDF")}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "0 18px 16px 18px" }}>
        <div style={{ paddingLeft: "12px" }}>
          <Document
            file={
              "https://s3.globalcare.vn/claim-development/2023-7-20/1689843585694/document-anonymous-1689843585678.pdf"
            }
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
            loading={<div>Loading File ... </div>}
          >
            <div className={classes.div_page_pdf}>
              {Array.from(new Array(states.numPages), (el, index) => (
                <Page
                  className="page-pdf"
                  width={
                    window?.document?.getElementById("pdfBox")?.offsetWidth
                  }
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </div>
          </Document>
        </div>
        <LoadingMui loading={loading} />
      </DialogContent>
    </Dialog>
  );
}

const useMakeStyles = makeStyles(() => ({
  dialog: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: "12px",
      margin: "24px",
      width: "100%",
    },
  },

  dialogTitle: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    padding: "8px 18px",

    "& .content-title": {
      color: "#4B4B4B",
      fontWeight: "600",
      fontSize: "14px",
    },
  },

  div_page_pdf: {
    height: "75vh",
    width: window?.document?.getElementById("pdfBox")?.offsetWidth,
    overflow: "scroll",
    textAlign: "center",

    "& .page-pdf": {
      display: "flex",
      justifyContent: "center",

      // "& .react-pdf__Page__canvas": {
      //   borderBottom: "1px solid red",
      //   marginBottom: "8px",
      // },
    },
  },
}));
