import React from "react";
import {
  Dialog,
  Slide,
  DialogContent,
  MenuItem,
  FormControl,
  FormGroup,
  FormControlLabel,
  Button,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckBox, TextField } from "../overrideMui";
import { code_rules, limit_fees, type_of_indemnify } from "../util";
import NumberFormat from "react-number-format";
import { get } from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogAddBenefit(props) {
  const { state, dispatch } = props;
  const classes = useStyles();
  const { dialogAddBenefit, benefits, valueAutocomplete, errors } = state;

  const codeRuleId = +get(dialogAddBenefit, "data.config_benefit.code_rules");
  const renderUI = () => {
    switch (codeRuleId) {
      case 2:
      case 3:
      case 4:
      case 5:
        return (
          <div className={classes.box_choose_type}>
            <div className="box choose-type">
              <div className={classes.label}>
                {"Giới hạn" + ([2, 4].includes(codeRuleId) ? " ngày" : " lần")}
              </div>
              {/* <TextField fullWidth /> */}
              <NumberFormat
                customInput={TextField}
                fullWidth
                onChange={(e) => {
                  dispatch({
                    type: "onChangeBenefit",
                    value: e.target.value || "",
                    name: [2, 4].includes(codeRuleId)
                      ? "limit_day"
                      : "limit_number_of_times",
                  });
                }}
                value={
                  get(
                    dialogAddBenefit,
                    `data.config_benefit.${
                      [2, 4].includes(codeRuleId)
                        ? "limit_day"
                        : "limit_number_of_times"
                    }`
                  ) || ""
                }
              />
            </div>
            <div className="box total">
              <div className={classes.label}>Giới hạn phí</div>
              <NumberFormat
                thousandsGroupStyle="thousand"
                decimalSeparator=","
                thousandSeparator="."
                customInput={TextField}
                fullWidth
                onChange={(e) => {
                  dispatch({
                    type: "onChangeBenefit",
                    value: (e.target.value || "").replace(/\./g, ""),
                    name: "limit_fees",
                  });
                }}
                value={
                  get(dialogAddBenefit, "data.config_benefit.limit_fees") || ""
                }
              />
            </div>
          </div>
        );
      case 7:
      case 8:
        return (
          <div className={classes.box_choose_type}>
            <div className="box choose-type">
              <div className={classes.label}>
                {"Giới hạn" + (codeRuleId === 7 ? " ngày" : " tháng")}
              </div>
              <NumberFormat
                customInput={TextField}
                fullWidth
                onChange={(e) => {
                  dispatch({
                    type: "onChangeBenefit",
                    value: (e.target.value || "").replace(/\./g, ""),
                    name: codeRuleId === 7 ? "limit_day" : "limit_month",
                  });
                }}
                value={
                  get(
                    dialogAddBenefit,
                    `data.config_benefit.${
                      codeRuleId === 7 ? "limit_day" : "limit_month"
                    }`
                  ) || ""
                }
              />
            </div>
            <div className="box total">
              <div className={classes.label}>Giới hạn phí</div>
              <TextField
                fullWidth
                select
                disabled
                value={codeRuleId === 7 ? 1 : 2}
              >
                {limit_fees.map((item, index) => (
                  <MenuItem
                    key={`item_select_limit_fees_${index}`}
                    value={item.id}
                  >
                    {item.title}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <Dialog
      open={Boolean(dialogAddBenefit?.open)}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"md"}
      onClose={() => dispatch("closeDialogAddBenefit")}
      aria-describedby="alert-dialog-slide-description"
      sx={{ "& .MuiPaper-root": { maxWidth: "800px" } }}
    >
      <DialogContent sx={{ maxWidth: "800px" }}>
        <div className={classes.title}>{"Tạo mã quyền lợi"}</div>
        <div>
          <div className={classes.label}>Chọn mã quyền lợi</div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={benefits}
            noOptionsText={"Không tìm thấy dữ liệu"}
            getOptionLabel={(option) =>
              option.code_benefit + "  " + option.title
            }
            value={valueAutocomplete}
            onChange={(e, value) =>
              dispatch({
                type: "onChangeBenefit",
                value,
                e,
                name: "code_benefit",
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={Boolean(errors.includes("code_benefit"))}
              />
            )}
          />
        </div>
        <div className={classes.box_choose_type}>
          <div className="box choose-type">
            <div className={classes.label}>Chọn loại</div>
            <TextField
              fullWidth
              select
              disabled
              // error={Boolean(errors.includes("code_rules"))}
              // onChange={(e) =>
              //   dispatch({
              //     type: "onChangeBenefit",
              //     value: e.target.value,
              //     name: "code_rules",
              //   })
              // }
              value={
                get(dialogAddBenefit, "data.config_benefit.code_rules") || ""
              }
            >
              {code_rules.map((item, index) => (
                <MenuItem key={`item_benefit_code_${index}`} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="box total">
            <div className={classes.label}>Tổng quyền lợi</div>
            {/* <TextField fullWidth /> */}
            <NumberFormat
              thousandsGroupStyle="thousand"
              decimalSeparator=","
              thousandSeparator="."
              customInput={TextField}
              fullWidth
              error={Boolean(errors.includes("value"))}
              // className={classes.textField}
              onChange={(e) => {
                dispatch({
                  type: "onChangeBenefit",
                  value: (e.target.value || "").replace(/\./g, ""),
                  name: "value",
                });
              }}
              value={get(dialogAddBenefit, "data.config_benefit.value") || ""}
            />
          </div>
        </div>
        {renderUI()}
        {(valueAutocomplete?.code_benefit || "")?.split(".")?.length === 1 && (
          <div style={{ marginTop: "12px" }}>
            <div className={classes.label}>Chọn loại bồi thường</div>
            <FormControl
              required
              error={Boolean(errors.includes("type_of_indemnify"))}
              component="fieldset"
              sx={{ width: "100%" }}
              variant="standard"
            >
              <FormGroup row>
                {type_of_indemnify.map((item, index) => (
                  <FormControlLabel
                    key={item.label}
                    control={
                      <CheckBox
                        checked={Boolean(
                          (
                            get(
                              dialogAddBenefit,
                              "data.config_benefit.type_of_indemnify",
                              []
                            ) || []
                          ).includes(item.id)
                        )}
                        onChange={(e) =>
                          dispatch({
                            type: "onChangeBenefit",
                            name: "type_of_indemnify",
                            id: item.id,
                            value: e.target.checked,
                          })
                        }
                        // name="gilad"
                      />
                    }
                    label={item.title}
                    className={classes.formControl}
                  />
                ))}
              </FormGroup>
              {errors.includes("type_of_indemnify") && (
                <FormHelperText>Vui lòng chọn loại bồi thường</FormHelperText>
              )}
            </FormControl>
          </div>
        )}
        <div className={classes.buttons}>
          <Button
            className="button cancel"
            onClick={() => dispatch("closeDialogAddBenefit")}
          >
            Bỏ qua
          </Button>
          <Button
            className="button save"
            onClick={() => {
              dialogAddBenefit?.isEdit
                ? dispatch("saveChangeBenefit")
                : dispatch("addBenefitRow");
            }}
          >
            {Boolean(dialogAddBenefit?.isEdit) ? "Lưu" : "Thêm"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "18px",
    fontWeight: "600",
    color: "#4B4B4B",
    marginBottom: "12px",
  },

  label: {
    fontSize: "15px",
    color: "#4B4B4B",
    marginBottom: "8px",
  },

  box_choose_type: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .box": {
      width: "47%",
      marginTop: "12px",
    },
  },

  formControl: {
    marginLeft: 0,
    marginRight: "32px",
    marginTop: "8px",
    alignItems: "center",
    justifyContent: "space-between",

    "& .MuiCheckbox-root": {
      marginRight: "12px",
    },

    "& .MuiTypography-root": {
      fontSize: "14px",
      color: "#4B4B4B",
    },
  },

  buttons: {
    padding: "12px 0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "12px",

    "& .button": {
      padding: "8px 24px",
      fontSize: "14px",
      fontWeight: "600",
      border: "1px solid #DCDCDC",
    },

    "& .cancel": {
      marginRight: "12px",
      background: "white",
      color: "#3961A0",
    },

    "& .save": {
      background: "#3961A0",
      color: "white",
      padding: "8px 28px",
    },
  },
}));
