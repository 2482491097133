import { isEmpty, get } from "lodash";

import { getListProductClaim } from "../../../../../apis/notificationApis";
import { logout_v2 } from "../../../../../apis/authApis";
import { itemMenu, getItemSubMenus } from "./itemMenu";
import { getParamUrlByKey } from "../../../../../utils/utils";

export default function sidebarReducer({
  state,
  dispatch,
  action,
  setListProductClaims,
  pathname,
  history,
}) {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const roleData = get(user, "role_management_claim", {}) || {};
  const privilegesFromUser =
    get(user, "role_management_claim.privileges", {}) || {};
  const productParentsFromUser =
    get(user, "role_management_claim.product_parents", []) || [];

  const cases = {
    initial: async () => {
      dispatch("onLoading");
      const getListProductClaimApi = await getListProductClaim({
        limit: 1000,
        offset: 0,
      });
      const res = { dataMenu: [] };

      if (getListProductClaimApi?.data?.statusCode === 200) {
        const temp = getListProductClaimApi?.data?.result?.data || [];
        setListProductClaims([...temp]);
        const listProductForMenu = temp.reduce((array, item) => {
          const itemProductParentsFromUser = productParentsFromUser.find(
            (i) => i.product_id_parent == item.id
          );

          if (itemProductParentsFromUser) {
            const itemMenuChildren = getItemSubMenus({
              ...itemProductParentsFromUser,
              ...item,
            });

            const childrens = (itemMenuChildren.childrens || []).reduce(
              (arrayChilder, j) => {
                const check = j.privileges.some(
                  (jp) => +privilegesFromUser[jp] === 1
                );
                if (check) {
                  return [...arrayChilder, j];
                }
                return arrayChilder;
              },
              []
            );

            return [
              ...array,
              {
                ...itemProductParentsFromUser,
                ...item,
                ...itemMenuChildren,
                childrens,
              },
            ];
          }

          return array;
        }, []);
        // const listProductForMenu = productParentsFromUser.map((i) => {
        //   const itemProduct =
        //     temp.find(
        //       (p) =>
        //         i.product_id_parent == p.id ||
        //         i.product_id_parent == p.product_id
        //     ) || {};

        //   const itemMenuChildren = getItemSubMenus({ ...i, ...itemProduct });

        //   return { ...itemProduct, ...i, ...itemMenuChildren };
        // });
        res.dataMenu = [];
        const keyAlready = [];

        for (let key in privilegesFromUser) {
          if (!Boolean(privilegesFromUser[key]) || keyAlready.includes(key)) {
            continue;
          }
          keyAlready.push(key);

          const tempItemMenu =
            itemMenu.find((i) => (i.role_group || []).includes(key)) || {};

          tempItemMenu.childrens =
            tempItemMenu?.name === "product_parents"
              ? listProductForMenu
              : tempItemMenu.childrens;

          const checkedDataItemMenu = res.dataMenu.some(
            (i) => i.name === tempItemMenu.name
          );

          !isEmpty(tempItemMenu) &&
            !checkedDataItemMenu &&
            res.dataMenu.push(tempItemMenu);

          if (
            key === "CLAIM_DASHBOARD_VIEW" &&
            Boolean(privilegesFromUser[key])
          ) {
            const itemDashboart =
              itemMenu.find((i) => i.name === "dashboard") || {};
            !isEmpty(itemDashboart) && res.dataMenu.push(itemDashboart);
          }
          if (key === "CLAIM_REPORT" && Boolean(privilegesFromUser[key])) {
            const itemReport = itemMenu.find((i) => i.name === "bao-cao") || {};
            !isEmpty(itemReport) && res.dataMenu.push(itemReport);
          }
        }

        // res.dataMenu = [...res.dataMenu.sort((a, b) => a.stt - b.stt)];

        if (get(user, "role_management_claim.is_root", null) === 1) {
          res.dataMenu = itemMenu.map((i) => {
            return i.name === "product_parents"
              ? { ...i, childrens: listProductForMenu }
              : { ...i };
          });
        }

        const product_parent_id = getParamUrlByKey("product_parent_id");
        if (product_parent_id) {
          const checkProductParentsMenu =
            res.dataMenu?.find((i) => i.name === "product_parents") || {};

          if (!isEmpty(checkProductParentsMenu)) {
            const itemExpan = (checkProductParentsMenu?.childrens || []).find(
              (i) =>
                i.id == product_parent_id ||
                i.product_id_parent == product_parent_id
            );

            if (!isEmpty(itemExpan)) {
              res.expandSubMenu = { [itemExpan.name]: true };
              const selectedItem =
                itemExpan?.childrens.find(
                  (i) =>
                    i.path.includes(pathname) ||
                    i.path_involve.some((j) => pathname.includes(j))
                ) || {};

              res.selected = selectedItem?.name || "";
            }
          }
        } else {
          const checkItemMenu =
            res.dataMenu?.find(
              (i) =>
                pathname.includes(i.path) ||
                (i?.path_involve || []).some((j) => pathname.includes(j))
            ) || {};
          res.selected = isEmpty(checkItemMenu)
            ? ""
            : checkItemMenu?.name || "";
        }

        res.parentCollapse = res.dataMenu?.some(
          (i) => i.name === "product_parents"
        )
          ? { product_parents: true }
          : {};
      }

      if (getListProductClaimApi?.data?.statusCode == 401) {
        const token = localStorage.getItem("token") || null;
        history.push("/admin/login");

        logout_v2({ token: token });
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("roleAdminMenuItem");
      }

      return { ...res, loading: false };
    },

    handleClickParent: () => {
      const { name } = action;
      const { parentCollapse } = state;

      return {
        parentCollapse: {
          [name]: !parentCollapse[name],
        },
      };
    },

    handleClickChild: () => {
      const { product_code } = action;
      const { expandSubMenu } = state;

      return {
        expandSubMenu: { [product_code]: !expandSubMenu[product_code] },
      };
    },

    setSelected: () => {
      const { name } = action;
      return { selected: name };
    },

    changePathname: () => {
      const { pathname } = action;
      const { dataMenu } = state;

      const res = {};
      const product_id_parent = getParamUrlByKey("product_id_parent");
      if (product_id_parent) {
        const checkProductParentsMenu =
          dataMenu?.find((i) => i.name === "product_parents") || {};

        if (!isEmpty(checkProductParentsMenu)) {
          const itemExpan = (checkProductParentsMenu?.childrens || []).find(
            (i) =>
              i.id == product_id_parent ||
              i.product_id_parent == product_id_parent
          );

          if (!isEmpty(itemExpan)) {
            res.expandSubMenu = { [itemExpan.name]: true };
            const selectedItem =
              itemExpan?.childrens.find(
                (i) =>
                  i.path.includes(pathname) ||
                  i.path_involve.some((j) => pathname.includes(j))
              ) || {};

            res.selected = selectedItem?.name || "";
          }
        }
      } else {
        const checkItemMenu =
          dataMenu?.find(
            (i) =>
              pathname.includes(i.path) ||
              (i?.path_involve || []).some((j) => pathname.includes(j))
          ) || {};
        res.selected = isEmpty(checkItemMenu) ? "" : checkItemMenu?.name || "";
      }

      return { ...res };
    },

    onLogout: async () => {
      const token = localStorage.getItem("token") || null;
      logout_v2({ token: token });
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("roleAdminMenuItem");
    },

    onLoading: () => ({ loading: true }),

    //
  };

  return cases[action.type] && cases[action.type];
}
