export const initialState = {
  listOrg: [],
  listProductParent: [],
  listClaimReport: [],
  listProductReport: [],
  listMerchantReport: [],
  listReportExportExcel: [],
  tabs: { key: "claim", value: 2 },
  payloadApi: {},
  pageTable: {},
  dataFiter: {},
  modalDate: {},
  payloadAutocomplete: {},
  snackbar: {},
  loadingAutocomplete: {},
  loading: false,
  count: 0,
};

export const converDate = (string) => {
  if (!string) {
    return null;
  }

  if (string?.indexOf("T") > -1) {
    return string;
  } else {
    if (string.includes("/") || string.includes("-")) {
      const check_string = (string.match(/\//g) || []).length;
      const matches = (string.match(/(\d+)[-\/]/g) || []).map((match) =>
        match.slice(0, -1)
      );

      if (matches[0]?.length == 4) {
        return string;
      }

      return check_string > 0
        ? string.split("/").reverse().join("/")
        : string.split("-").reverse().join("-");
    }

    return null;
  }
};

export const getStatusById = (id) => {
  switch (id) {
    case 2:
    case 3:
    case 4:
      return {
        colorReport: "#FFA21F",
        labelReport: "Đang xử lý",
      };
    case 5:
    case 6:
    case 9:
      return {
        labelReport: "Đồng ý bồi thường",
        colorReport: "#069D27",
      };

    case 7:
    case 8:
    case 10:
      return {
        labelReport: "Từ chối bồi thường",
        colorReport: "#FF003D",
      };

    default:
      return {};
  }
};
