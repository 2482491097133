import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Input,
} from "@mui/material";
import _, { get } from "lodash";
import moment from "moment";
import React from "react";

import accountNumber from "../assets/accountNumber.svg";
import back from "../assets/back.svg";
import bankBranch from "../assets/bankBranch.svg";
import benefits from "../assets/benefits.svg";
import cartype from "../assets/carType.svg";
import cmnd from "../assets/cmnd.svg";
import cost from "../assets/cost.svg";
import departure from "../assets/departure.svg";
import address from "../assets/address.svg";
import documentIcon from "../assets/documentIcon.svg";
import license from "../assets/license.svg";
import phone from "../assets/phone.svg";
import placeOfAccident from "../assets/placeOfAccident.svg";
import placeOfTreatment from "../assets/placeOfTreatment.svg";
import proveRelation from "../assets/proveRelation.svg";
import relationship from "../assets/relationship.svg";
import date from "../assets/toDate.svg";
import user from "../assets/user.svg";
import { useStyles } from "../styles/useStyles";

export default function CardInfor(props) {
  const classes = useStyles();
  const { row, dispatch } = props;

  const dataRelationship = row?.elements?.find((i) => i.type_id === 306) || {};

  return (
    <div className={classes.boxAccor}>
      <Accordions titleAccor="Thông tin hợp đồng">
        <Row
          icon={benefits}
          title="Tổng quyền lợi bảo hiểm"
          value={
            `${row?.productAmount || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
            " VNĐ"
          }
        />
        <Row
          icon={cost}
          title="Tổng phí bảo hiểm"
          value={
            `${row?.fees || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " VNĐ"
          }
        />
        <Row
          icon={cost}
          title="Số tiền bồi thường đã chi trả"
          value={
            `${row?.amount || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "đ"
          }
        />
        <div className="rowDiv">
          <span
            className="titleSpan"
            style={{ alignItems: "center", display: "flex" }}
          >
            <img
              src={cmnd}
              alt="img"
              style={{ marginRight: "4px" }}
              height={24}
              width={24}
            />
            Hiệu lực bảo hiểm
          </span>
          <div
            className={"valueSpan"}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <span>{row?.insuredInfo?.beginDate || ""} </span>
            <span className={"valueSpan"} style={{ textAlign: "end" }}>
              {row?.insuredInfo?.endDate || ""}
            </span>
          </div>
        </div>
        <Row
          icon={documentIcon}
          title="Chuyến đi"
          value={
            row?.extraInfo?.codeDestination ||
            row?.extraInfo?.destination?.codeDestination
          }
        />
      </Accordions>
      <Accordions titleAccor="Thông tin bên đặt xe">
        <Row
          icon={user}
          title="Họ tên"
          value={row?.buyerName || row?.insuredInfo?.insuredName || ""}
          capitalize={true}
        />
        <Row
          icon={phone}
          title="Số điện thoại"
          value={row?.buyerPhone || row?.insuredInfo?.insuredPhone || ""}
        />
        <Row
          icon={address}
          title="Địa chỉ"
          value={row?.buyerAddress || row?.insuredInfo?.insuredAddress || ""}
        />
        <Row
          icon={departure}
          title="Điểm đi"
          value={row?.extraInfo?.departure || row?.insuredInfo?.departure || ""}
        />
        <Row
          icon={departure}
          title="Điểm đến"
          value={
            row?.extraInfo?.destination?.address ||
            row?.extraInfo?.address ||
            ""
          }
          // value={row?.insuredInfo?.destination || ""}
        />
      </Accordions>
      <Accordions titleAccor="Thông tin tài xế">
        <Row
          icon={user}
          title="Họ tên chủ xe"
          value={
            row.extraInfo?.driver?.name || row.insuredInfo?.driver?.name || ""
          }
          capitalize={true}
        />
        <Row
          icon={phone}
          title="Số điện thoại"
          value={
            row.extraInfo?.driver?.phone ||
            row?.insuredInfo?.driver?.phone ||
            ""
          }
        />
        <Row
          icon={license}
          title="Biển số xe/ số khung/ số máy"
          value={
            row.extraInfo?.driver?.license ||
            row?.insuredInfo?.driver?.license ||
            ""
          }
        />
      </Accordions>
      <Accordions titleAccor={"Hồ sơ bồi thường"}>
        <Row
          icon={documentIcon}
          title="Loại tai nạn"
          value={
            row?.extraInfo?.typeOfAccident === 1 ? "Tai nạn giao thông" : ""
          }
        />
        <Row
          icon={documentIcon}
          title="Mức độ tai nạn"
          value={
            row?.extraInfo?.levelAccident === 1
              ? "Tử vong"
              : row?.extraInfo?.levelAccident === 2
              ? "Thương tật vĩnh viễn"
              : row?.extraInfo?.levelAccident === 3
              ? "Thương tật tạm thời"
              : ""
          }
        />
        {row?.extraInfo?.levelAccident === 3 && (
          <Row
            icon={documentIcon}
            title="Loại hình điều trị"
            value={
              row?.extraInfo?.typeOfTreatment === 1 ? "Ngoại trú" : "Nội trú"
            }
          />
        )}
        <Row
          icon={date}
          title="Ngày tai nạn"
          value={
            row?.extraInfo?.dateOfAccident ? row?.extraInfo?.dateOfAccident : ""
          }
        />
        <Row
          icon={placeOfAccident}
          title="Nơi xảy ra tai nạn"
          value={row?.extraInfo?.positionAccident || ""}
        />
      </Accordions>
      <div className={classes.boxLine}>
        <div className="lineTitle">Diễn biến tai nạn</div>
        <Input
          style={{ marginBottom: "12px" }}
          className="input-multiline"
          fullWidth
          multiline
          readOnly
          value={row?.extraInfo?.reason}
        />
        {row?.extraInfo?.levelAccident === 3 &&
        row?.extraInfo?.typeOfTreatment !== 1 ? (
          <>
            {" "}
            <Row
              icon={date}
              title="Ngày nhập viện"
              value={
                row?.extraInfo?.dateOfHospital || row?.extraInfo?.dayOfTreatment
                  ? row?.extraInfo?.dateOfHospital ||
                    row?.extraInfo?.dayOfTreatment
                  : ""
              }
            />
            <Row
              icon={date}
              title="Ngày xuất viện"
              value={
                row?.extraInfo?.dateOutOfHospital ||
                row?.extraInfo?.dayOfTreatment
                  ? row?.extraInfo?.dateOutOfHospital ||
                    row?.extraInfo?.dayOfTreatment
                  : ""
              }
            />
          </>
        ) : row?.extraInfo?.levelAccident !== 1 ? (
          <Row
            icon={date}
            title="Ngày điều trị"
            value={
              row?.extraInfo?.dayOfTreatment
                ? row?.extraInfo?.dayOfTreatment
                : ""
            }
          />
        ) : null}
        <Row
          icon={placeOfTreatment}
          title="Nơi điều trị"
          value={row?.extraInfo?.treatmentPlace}
        />
        {row?.extraInfo?.levelAccident !== 1 && (
          <Row
            icon={cost}
            title="Tổng chi phí điều trị"
            value={
              `${row?.extraInfo?.totalAmountTreatment || 0}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                "."
              ) + " VNĐ"
            }
          />
        )}
        <div className="lineTitle">Chẩn đoán bệnh</div>
        <Input
          className="input-multiline"
          fullWidth
          multiline
          readOnly
          value={row?.extraInfo?.diagnoses}
        />
        <div className="lineTitle">Thông tin người thụ hưởng</div>
        <Row
          icon={user}
          title="Chủ tài khoản"
          value={
            row?.extraInfo?.beneficiaryName ||
            row?.insuredInfo?.beneficiaryName ||
            ""
          }
        />
        {[2, 3, 4].includes(
          row?.extraInfo?.relationshipWithBuyerId ||
            row?.insuredInfo?.relationshipWithBuyerId
        ) && (
          <>
            <div className="rowDiv">
              <span
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <img
                  src={relationship}
                  alt="img"
                  style={{ marginRight: "4px" }}
                  height={24}
                  width={24}
                />
                <span style={{ color: "#2A3790" }}>
                  Mối quan hệ với người được bảo hiểm
                </span>
              </span>
              <span
                className={"valueSpan"}
                style={{
                  minWidth: "100px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {row?.extraInfo?.relationshipWithBuyerName ||
                  row?.insuredInfo?.relationshipWithBuyerName ||
                  ""}
              </span>
            </div>
            <div className="rowDiv" style={{ flexDirection: "column" }}>
              <span
                style={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <img
                  src={proveRelation}
                  alt="img"
                  style={{ marginRight: "4px" }}
                  height={24}
                  width={24}
                />
                <span style={{ color: "#2A3790" }}>
                  Chứng từ xác minh quan hệ{" "}
                  <span style={{ color: "red" }}>*</span>
                </span>
              </span>
              <div
                style={{ width: "100%", marginTop: "6px", overflowX: "auto" }}
              >
                <Box sx={{ display: "flex", width: "fit-content" }}>
                  {_.get(dataRelationship, "documents", [])?.map(
                    (ell, index) => (
                      <Box
                        key={index}
                        onClick={() =>
                          dispatch({
                            type: "showDialog",
                            name: "relationship",
                            listImg: dataRelationship?.documents || [],
                            indexImg: index,
                          })
                        }
                        sx={{
                          width: "75px",
                          height: "75px",
                          border: "1px dotted rgba(0, 0, 0, 0.19) ",
                          borderRadius: "16px",
                          padding: "1px",
                          marginRight: "12px",
                          marginBottom: "12px",
                        }}
                      >
                        <img
                          src={ell?.path}
                          width={"100%"}
                          height={"100%"}
                          style={{ borderRadius: "16px" }}
                        />
                      </Box>
                    )
                  )}
                </Box>
              </div>
            </div>
          </>
        )}
        <Row
          icon={accountNumber}
          title="Số tài khoản"
          value={
            row?.extraInfo?.beneficiaryBankAcountNumber ||
            row?.insuredInfo?.beneficiaryBankAcountNumber
          }
        />
        <Row
          icon={back}
          title="Ngân hàng"
          value={
            row?.extraInfo?.beneficiaryBankTitle ||
            row?.insuredInfo?.beneficiaryBankTitle ||
            ""
          }
        />
        <Row
          icon={bankBranch}
          title="Chi nhánh"
          value={
            row?.extraInfo?.beneficiaryBankBranchName ||
            row?.insuredInfo?.beneficiaryBankBranchName
          }
        />
      </div>
    </div>
  );
}

const Accordions = (props) => {
  const classes = useStyles();
  const { children, titleAccor } = props;

  return (
    <>
      <Accordion className={classes.accordionRoot}>
        <AccordionSummary
          expandIcon={<KeyboardArrowUpIcon style={{ color: "#284078" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span className="titleAccodion">{titleAccor}</span>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  );
};

const Row = (props) => {
  const { title, value, capitalize, icon } = props;
  return (
    <div className="rowDiv">
      <span className="titleSpan">
        <img
          src={icon}
          alt="img"
          style={{ marginRight: "4px" }}
          height={24}
          width={24}
        />
        {title}
      </span>
      <span
        className={capitalize ? "valueSpan capitalize" : "valueSpan"}
        title={value}
        style={{ textAlign: "end" }}
      >
        {value}
      </span>
    </div>
  );
};
