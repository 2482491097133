import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
  TableCell,
  TableContainer,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useRef } from "react";
import CommonTable from "../../../../library/layout/commonTable";
import { makeStyles } from "@mui/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddDialog(props) {
  const { dispatch, state } = props;
  const { open, scrolling, rows } = state;
  const scrollRef = useRef();
  const classes = useStyles();

  const onScroll = () => {
    if (scrollRef.current) {
      const scrollDiv = scrollRef.current;
      const lastItem = scrollDiv.querySelector(
        "#tableScroll .scroll-item:last-child"
      );
      const { offsetTop, offsetHeight } = lastItem;
      if (
        Math.abs(offsetTop - scrollDiv.scrollTop) <
        offsetHeight + scrollDiv.offsetHeight
      ) {
        dispatch("fetchData");
      }
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth={"md"}
      onClose={() => dispatch("onClose")}
      className={classes.dialog}
    >
      <DialogTitle>Chọn mã quyền lợi sản phẩm</DialogTitle>
      <DialogContent>
        <TableContainer
          id="tableScroll"
          ref={scrollRef}
          onScroll={debounce(onScroll, 800)}
          className="table-scroll"
          component={Paper}
        >
          <div className={classes.headerTable}>
            <div className="code">Mã quyền lợi</div>
            <div>Tên quyền lợi</div>
          </div>
          <CommonTable
            classes={classes}
            rows={rows || []}
            columns={columns}
            empty={"Không có thông tin. Vui lòng kiểm tra lại"}
            event={(type, data) =>
              (ele, ...rest) => {
                dispatch({ type, data, ele, rest });
              }}
          />
          {scrolling && (
            <div className="scrolling">
              <CircularProgress size={25} sx={{ mb: 2, color: "#0ABAFD" }} />
            </div>
          )}
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button className="button" onClick={() => dispatch("addPakages")}>
          Thêm mã
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const columns = [
  {
    renderCell: ({ item, event, rowIdx }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst code"
            : item.isSecond
            ? "isSecond code"
            : "code"
        }
      >
        <Checkbox
          checked={!!item.checked}
          className={`${item.isFirst ? "hidden-check" : ""}`}
          onChange={event("onCheck", { rowIdx })}
        />
        {item?.code_benefit || ""}
      </TableCell>
    ),
  },
  {
    renderCell: ({ rowIdx, item }) => (
      <TableCell
        className={
          item.isFirst
            ? "isFirst name-benefits"
            : item.isSecond
            ? "isSecond name-benefits"
            : "name-benefits"
        }
      >
        {item?.title || ""}
      </TableCell>
    ),
  },
];

const useStyles = makeStyles(() => ({
  dialog: {
    height: "100%",
    "& .scrolling": { textAlign: "center" },
    "& .table-scroll": {
      overflowY: "auto",
      height: "calc(80vh - 20px)",
      margin: "10px 0",
    },
    "& .button": {
      background: "#3961A0",
      borderRadius: 5,
      color: "#fff",
      marginBottom: 5,
      padding: "10px 20px",
    },
    "& .MuiPaper-root-MuiDialog-paper": {
      maxHeight: "80%",
    },
    "& .MuiDialogContent-root": {
      padding: "0 24px",
    },
  },
  table: {
    minWidth: "800px",
    "& tbody td:last-child": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& tbody td:first-child": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "& td": {
      textAlign: "left",
      color: "#4B4B4B",
    },

    "& td.code": {
      padding: "0 8px",
      fontWeight: 500,
      background: "#fff",
      width: "150px",
    },

    "& td.isFirst": {
      background: "#09BAFD",
      fontWeight: 700,
      paddingLeft: 25,
    },

    "& td.isSecond": {
      background: "#E3F3FF",
      fontWeight: 500,
    },

    "& td.name-benefits": {
      padding: "0 8px",
    },
    "& tbody .hidden-check": {
      display: "none",
    },
  },

  div: {
    paddingBottom: "15px",
  },

  header: {
    display: "none",
  },

  headerTable: {
    color: "#fff",
    fontSize: 15,
    fontWeight: 600,
    display: "flex",
    flexDirection: "row",
    background: "#3961A0",
    padding: "8px",
    position: "sticky",
    top: 0,
    zIndex: 3,
    "& .code": {
      width: "150px",
    },
  },

  cellRow: {
    borderTop: "none",
    borderBottom: "1.5px solid #D6DEEB",
    background: "white !important",
  },
}));
