import React, { useEffect, useState } from 'react';
import { Wrapper, ContentWrapper, Content } from './styles';
import { Col, Row, DatePicker } from 'antd';
import { Formik, Form, useField, useFormikContext } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { Select } from 'antd';

import {
  phoneValidate,
  formValidate,
  emailValidateNotRequire,
  _18yearsValidate
} from '../../../../utils/validation/input'
import { InputField } from '../../../../ui/fields';
import Button from '../../../../ui/buttons/Button';
import * as PATH from '../../../../configs/routesConfig';
import { getInfoCarAccident } from '../../../../apis/carAccidentApis'
import Loading from '../../../../ui/Loading';
import HeaderBar from '../../../../ui/HeaderBar';
import './CarAccident.css'

const { Option } = Select;

const listGender = [
  {label: 'Nam', value: 1},
  {label: 'Nữ', value: 2}
]

const validationSchema = yup.object().shape({
  name: formValidate,
  gender: formValidate,
  identity_card: formValidate,
  license_plates: formValidate,
  manufacture: formValidate,
  car_brand: formValidate,
  seats_number: formValidate,
  phone_number: phoneValidate,
  address: formValidate,
  birthday: _18yearsValidate,
  email: emailValidateNotRequire,
});

const DatePickerField = ({ ...props }) => {
  const { touched, errors } = props
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const dateFormat = 'DD/MM/YYYY';
  return (
    <>
      <DatePicker
        id='date-accident'
        // {...field}
        // {...props}
        // onFocus=
        defaultPickerValue={moment().add(-18, 'years')}
        disabledDate={(date) => date.isAfter(moment().add(-18, 'years'))}
        status={touched.birthday && errors.birthday ? 'error' : undefined}
        style={inputDateStyle}
        placeholder="Ngày sinh"
        value={field.value ? moment(field.value, dateFormat) : null}
        format={dateFormat}
        selected={(field.value && new Date(field.value)) || null}
        suffixIcon={null}
        onChange={(val, date) => {
          setFieldValue(field.name, date);
        }}
        fontSize = {17}
      />
    </>
  );
};

const InsuranceInfo = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [dataUser, setDataUser] = useState({})
  const [mainInfo, setMainInfo] = useState({})
  const [detailsData, setDatailsData] = useState({})
  const location = useLocation();
  let initialValues = {
    name: '',
    gender: '',
    identity_card: '',
    license_plates: '',
    manufacture: '',
    car_brand: '',
    // frame_number: '',
    // machines_number: '',
    seats_number: '',
    phone_number: '',
    address: '',
    email: '',
    birthday: '',
  }

  useEffect(() => {
    document.body.style.backgroundColor = '#F7F7F7'
    window.history.scrollRestoration = 'manual'
    window.scrollTo({top: 0, behavior: 'smooth'})
    if(location?.state?.data) {
      const data = localStorage.getItem('dataUser') || false
      const isCheckData = data && data !== 'undefined' && data !== 'null'
      if (isCheckData) {
        setDataInfo(JSON.parse(data)?.insuredInfo)
        setDataUser(JSON.parse(data))
        localStorage.removeItem('dataUser');
      } else getInfo(location?.state?.data)
    }
  }, [])

  const getInfo = (data) => {
    const params = {
      certNum: data?.certNum,
      productId: data?.productId,
      productIdParent: data?.productIdParent,
    }
    setIsLoading(true)
    getInfoCarAccident(params).then(async res => {
      if(res?.data?.result) {
        const data = res?.data?.result
        setDataUser(data)
        await setDataInfo(data?.insuredInfo)
        setIsLoading(false)
      }
    })
    .catch(err => {
      setIsLoading(false)
      console.error('err', err)
    })
  }

  const setDataInfo = async(data) => {
    initialValues.name = data?.insuredName || ''
    initialValues.gender = data?.insuredGender || ''
    initialValues.identity_card = data?.insuredPrivateId || ''
    initialValues.license_plates = data?.licenseNumber || ''
    initialValues.manufacture = data?.yearOfManufacture || ''
    initialValues.car_brand = data?.carTitle || ''
    initialValues.frame_number = data?.frameNumber || ''
    initialValues.machines_number = data?.machineNumber || ''
    initialValues.seats_number = data?.seats || ''
    initialValues.phone_number = data?.insuredPhone || ''
    initialValues.address = data?.insuredAddress || ''
    initialValues.email = data?.insuredEmail || ''
    initialValues.birthday = data?.insuredDob || ''
    const newMainInfo = {
      licensePlates: data?.licenseNumber || '',
      effectiveDate: data?.beginDate || '',
      expirationDate: data?.endDate || '',
    }
    setMainInfo(newMainInfo)
    setDatailsData(data)
  }

  const handleChangeGender = (e, setValues, values) => {
    setValues({
      ...values,
      gender: e
    })
  }

  const onSubmitForm = (values, setSubmitting) => {
    let info = dataUser
    let insuredInfo = info['insuredInfo'] || {}
    insuredInfo.insuredName = values?.name
    insuredInfo.insuredGender = values?.gender
    insuredInfo.insuredPrivateId = values?.identity_card
    insuredInfo.licenseNumber = values?.license_plates
    insuredInfo.yearOfManufacture = values?.manufacture
    insuredInfo.carTitle = values?.car_brand
    insuredInfo.frameNumber = values?.frame_number
    insuredInfo.machineNumber = values?.machines_number
    insuredInfo.seats = values?.seats_number
    insuredInfo.insuredPhone = values?.phone_number
    insuredInfo.insuredAddress = values?.address
    insuredInfo.insuredEmail = values?.email
    insuredInfo.insuredDob = values?.birthday
    
    setSubmitting(false);
    localStorage.setItem('dataUser', JSON.stringify(dataUser))
    history.push(
      PATH.CREATE_CLAIM_ACCIDENT_PATH + location?.search,
      {
        claimId: detailsData?.certNum,
        dataUser: info,
      }
    )
  }

	return (
		<Wrapper> 
			<ContentWrapper>
        <HeaderBar
          title='Tạo hồ sơ bồi thường'
          onPress={() => history.goBack()}
          background='#F2F2F2'
        />
				<Content style={{marginTop: 60}}>
          <Row justify='center'>
            {isLoading && <Loading/>}
            <Col md={12} lg={10} xl={10} xxl={10} span={24}>
              <Col style={headerInfo} span={24} id='car-accident'>
                <Row span={6} className='header-title'>
                  <div style={txtTitle}>
                    Thông tin hợp đồng bồi thường
                  </div>
                </Row>
                <Row span={6} className='item-info' style={borderBottom}>
                  <Col span={10}>
                    Biển số xe
                  </Col>
                  <Col span={14} style={itemRight}>
                    {mainInfo?.licensePlates}
                  </Col>
                </Row>
                <Row span={6} className='item-info' style={borderBottom}>
                  <Col span={10}>
                    Ngày hiệu lực
                  </Col>
                  <Col span={14} style={itemRight}>
                    {mainInfo?.effectiveDate ? moment(mainInfo?.effectiveDate).format('hh:mm:ss; DD/MM/YYYY') : ''}
                  </Col>
                </Row>
                <Row span={6} className='item-info'>
                  <Col span={10}>
                    Ngày hết hạn
                  </Col>
                  <Col span={14} style={itemRight}>
                    {mainInfo?.expirationDate ? moment(mainInfo?.expirationDate).format('hh:mm:ss; DD/MM/YYYY') : ''}
                  </Col>
                </Row>
              </Col>
              <div style={formInfo}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  // onSubmit={(values, { setSubmitting }) => {
                  //   setTimeout(() => {
                  //     alert(JSON.stringify(values, null, 2));
                  //     setSubmitting(false);
                  //   }, 400);
                  // }}
                  onSubmit={(values, { setSubmitting }) => onSubmitForm(values, setSubmitting)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setValues
                  }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <InputField
                          validateStatus={touched.name && errors.name ? 'error' : undefined}
                          help={touched.name && errors.name ? errors.name : ''}
                          name="name"
                          autoComplete="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Họ và tên"
                          size="large"
                          inputStyle={inputStyle}
                          title=' Họ tên người được bảo hiểm'
                          isRequire={true}
                          styleTitle={styleTitle}
                          disabled={detailsData.insuredName}
                        />
                        <Row>
                          <Col span={11}>
                            {detailsData.insuredDob ? (
                              <InputField
                                validateStatus={touched.birthday && errors.birthday ? 'error' : undefined}
                                help={touched.birthday && errors.birthday ? errors.birthday : ''}
                                name="birthday"
                                autoComplete="birthday"
                                value={moment(detailsData.birthday).format('DD/MM/YYYY')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Ngày sinh"
                                size="large"
                                inputStyle={inputStyle}
                                title='Ngày sinh'
                                styleTitle={styleTitle}
                                isRequire={true}
                                disabled
                              />
                            ) : (
                              <>
                                <div style={styleTitle}>
                                  Ngày sinh<span style={{color: 'red'}}>*</span>
                                </div>
                                <DatePickerField
                                  name="birthday"
                                  touched={touched}
                                  errors={errors}
                                />
                              </>)
                            }
                            
                          </Col>
                          <Col span={2}/>
                          <Col span={11} id='select_gender' style={{marginBottom: 20}}>
                            <div style={styleTitle}>
                              Giới tính<span style={{color: 'red'}}>*</span>
                            </div>
                            {detailsData?.insuredGender ? (
                              <Select
                                name="gender"
                                value={detailsData?.insuredGender === '1' ? "Nam" : 'Nữ'}
                                style={{ width: '100%', fontSize: 17 }}
                                placeholder="Giới tính"
                                disabled
                              />
                            ) : (
                            <Select
                              status={touched.gender && errors.gender ? 'error' : undefined}
                              help={touched.gender && errors.gender ? errors.gender : ''}
                              name="gender"
                              onChange={e => handleChangeGender(e, setValues, values)}
                              style={{ width: '100%', fontSize: 17}}
                              placeholder="Giới tính"
                              disabled={detailsData?.insuredGender}
                            >
                              {listGender?.map(item => {
                                return (<Option key={item.value}>
                                  {item.label}
                                </Option>)
                              })}
                            </Select>)}
                          </Col>
                          <InputField
                            validateStatus={touched.identity_card && errors.identity_card ? 'error' : undefined}
                            help={touched.identity_card && errors.identity_card ? errors.identity_card : ''}
                            name="identity_card"
                            autoComplete="identity_card"
                            value={values.identity_card}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="CMND, CCCD hoặc Passport"
                            size="large"
                            inputStyle={inputStyle}
                            title='CMND/CCCD/Hộ chiếu'
                            styleTitle={styleTitle}
                            isRequire={true}
                            disabled={detailsData?.insuredPrivateId}
                          />
                          <InputField
                            validateStatus={touched.license_plates && errors.license_plates ? 'error' : undefined}
                            help={touched.license_plates && errors.license_plates ? errors.identity_card : ''}
                            name="license_plates"
                            autoComplete="license_plates"
                            value={values.license_plates}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Biển số xe"
                            size="large"
                            inputStyle={inputStyle}
                            title='Biển số xe'
                            styleTitle={styleTitle}
                            isRequire={true}
                            disabled={detailsData.licenseNumber}
                          />
                          <InputField
                            validateStatus={touched.manufacture && errors.manufacture ? 'error' : undefined}
                            help={touched.manufacture && errors.manufacture ? errors.manufacture : ''}
                            name="manufacture"
                            autoComplete="manufacture"
                            value={values.manufacture}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Năm sản xuất"
                            size="large"
                            inputStyle={inputStyle}
                            title='Năm sản xuất'
                            styleTitle={styleTitle}
                            isRequire={true}
                            disabled={detailsData.yearOfManufacture}
                          />
                          <InputField
                            validateStatus={touched.car_brand && errors.car_brand ? 'error' : undefined}
                            help={touched.car_brand && errors.car_brand ? errors.car_brand : ''}
                            name="car_brand"
                            autoComplete="car_brand"
                            value={values.car_brand}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Hiệu xe"
                            size="large"
                            inputStyle={inputStyle}
                            title='Hiệu xe'
                            styleTitle={styleTitle}
                            isRequire={true}
                            disabled={detailsData.carTitle}
                          />
                          {/* <InputField
                            validateStatus={touched.frame_number && errors.frame_number ? 'error' : undefined}
                            help={touched.frame_number && errors.frame_number ? errors.frame_number : ''}
                            name="frame_number"
                            autoComplete="frame_number"
                            value={values.frame_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Số khung"
                            size="large"
                            inputStyle={inputStyle}
                            title='Số khung'
                            styleTitle={styleTitle}
                            disabled={detailsData.frameNumber}
                          />
                          <InputField
                            validateStatus={touched.machines_number && errors.machines_number ? 'error' : undefined}
                            help={touched.machines_number && errors.machines_number ? errors.machines_number : ''}
                            name="machines_number"
                            autoComplete="machines_number"
                            value={values.machines_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Số máy"
                            size="large"
                            inputStyle={inputStyle}
                            title='Số máy'
                            styleTitle={styleTitle}
                            disabled={detailsData.machineNumber}
                          /> */}
                          <InputField
                            validateStatus={touched.seats_number && errors.seats_number ? 'error' : undefined}
                            help={touched.seats_number && errors.seats_number ? errors.seats_number : ''}
                            name="seats_number"
                            autoComplete="seats_number"
                            value={values.seats_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Số lượng chỗ ngồi"
                            size="large"
                            inputStyle={inputStyle}
                            title='Số chỗ ngồi'
                            styleTitle={styleTitle}
                            isRequire={true}
                            disabled={detailsData.seats}
                          />
                          <InputField
                            validateStatus={touched.phone_number && errors.phone_number ? 'error' : undefined}
                            help={touched.phone_number && errors.phone_number ? errors.phone_number : ''}
                            name="phone_number"
                            autoComplete="phone_number"
                            value={values.phone_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Số điện thoại liên lạc"
                            size="large"
                            inputStyle={inputStyle}
                            title='Số điện thoại'
                            styleTitle={styleTitle}
                            isRequire={true}
                            disabled={detailsData.insuredPhone}
                          />
                          <InputField
                            validateStatus={touched.address && errors.address ? 'error' : undefined}
                            help={touched.address && errors.address ? errors.address : ''}
                            name="address"
                            autoComplete="address"
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Địa chỉ"
                            size="large"
                            inputStyle={inputStyle}
                            title='Địa chỉ'
                            styleTitle={styleTitle}
                            isRequire={true}
                            disabled={detailsData.insuredAddress}
                          />
                          <InputField
                            validateStatus={touched.email && errors.email ? 'error' : undefined}
                            help={touched.email && errors.email ? errors.email : ''}
                            name="email"
                            autoComplete="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Email"
                            size="large"
                            inputStyle={inputStyle}
                            title='Email'
                            styleTitle={styleTitle}
                            isRequire={true}
                            disabled={detailsData.insuredEmail}
                          />
                        </Row>

                        <div style={styleErrorText}>
                          {Object.keys(errors).length !== 0 && "Vui lòng nhập đầy đủ thông tin để tiếp tục."}
                        </div>
                      
                        <div style={{padding: '20px 10px 0 10px'}}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={isSubmitting}
                            disabled={Object.keys(errors).length !== 0}
                            border='14'
                          >
                            Tiếp tục
                          </Button>
                        </div>
                      </Form>
                    )}
                  }
                </Formik>
              </div>
            </Col>
          </Row>
				</Content>
			</ContentWrapper>
		</Wrapper>
	);
}

const headerInfo = {
  display: 'grid',
  backgroundColor: '#FFF',
  height: 186,
  borderRadius: 19,
}

const txtTitle = {
  fontSize: 17,
  color: '#FFF',
  fontWeight: '600'
}

const borderBottom = {
  borderBottom: "0.5px solid rgb(212, 212, 212, 0.5)"
}

const inputStyle = {
  borderRadius: 9,
  height: 53,
  fontSize: 17
}

const inputDateStyle = {
  width: '100%',
  borderRadius: 9,
  height: 53,
  fontSize: 17,
}

const inputDateStyleError = {
  width: '100%',
  borderRadius: 9,
  height: 53,
  fontSize: 17,
  border: "1px solid red"
}

const formInfo = {
  margin: '24px 0 12px 0'
}

const styleTitle = {
  paddingBottom: 14,
  color: '#000000',
  fontWeight: 'bold',
  fontSize: 17,
  opacity: 0.5
}

const itemRight = {
  textAlign: 'right',
  paddingRight: 12
}

const styleErrorText = {
  color: 'red',
  fontSize: 15,
  textAlign: 'center'
}

export default InsuranceInfo
