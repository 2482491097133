import React, { useEffect, useState } from "react";
import {
  Button,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import TableToExcel from "@linways/table-to-excel";

import { useStyles } from "../styles/useStyles";
import useReducer from "../../../../../library/hooks/useReducer";
import CommonTable from "../../../../../library/layout/commonTable";
import { columns } from "../components/columns";
import intransitRrducer from "../reducer/intransitReducer";
import TableExportExcel from "../components/TableExportExcel";
import TableExportExcelGC from "../components/TableExportExcelGC";

import CarSearch from "../components/CarSearch";
import DialogNote from "../components/DialogNote";
import exportIcon from "../assets/exportIcon.svg";
import { useRecoilState } from "recoil";
import { dataSearchList } from "../atomIntransit";
import { getParamUrlByKey } from "../../../../../utils/utils";
import { get } from "lodash";

const initialState = {
  rows: [],
  dataExport: [],
  row: {},
  showImg: {},
  dataSearch: {},
  errors: {},
  pageTable: {
    count: 0,
    page: 0,
    rowsPerPage: 10,
  },
  count: 0,
  totalRows: 0,
  approvalAmount: "",
  content: "",
  setPage: "compensation",
  loading: false,
  confirmDialog: false,
  openMaxLineDialog: false,
};

export default function CompensationReport(props) {
  const { form, embeded } = props;
  const token = getParamUrlByKey("token");
  token && localStorage.setItem("token", token);
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const roleType = user?.extra_info?.userType;
  const privilegesFromUser =
    get(user, "role_management_claim.privileges") || {};
  const history = useHistory();

  const [dataSearchIntransitList, setDataSearchIntransitList] =
    useRecoilState(dataSearchList);
  const [isFirstRender, setIsFirstRender] = useState(false);

  const [state, dispatch] = useReducer({
    initialState: initialState,
    reducer: intransitRrducer,
    name: "intransitReducer",
    roleType,
    form,
    history,
    dataSearchIntransitList,
    setDataSearchIntransitList,
    embeded,
    user,
  });

  const {
    loading,
    rows,
    pageTable,
    count,
    dataExport,
    dataSearch,
    openMaxLineDialog,
  } = state;

  useEffect(() => {
    setIsFirstRender(true);
    return () => {
      const fetchData = async () => {
        if (
          ![
            "/admin/int-detail-review",
            "/admin/int-detail-report",
            "/admin/embeded/int-detail-report",
            "/admin/embeded/int-detail-review",
          ].includes(window.location?.pathname)
        ) {
          setDataSearchIntransitList({});
        }
      };

      fetchData();
    };
  }, [window.location.pathname]);

  useEffect(() => {
    if (isFirstRender) {
      dispatch("initial");
    }
  }, [isFirstRender, user?.extra_info?.merchantId]);

  useEffect(async () => {
    if (count !== 0) {
      const table =
        roleType === "globalcare"
          ? document.getElementById("export-data-table-globalcare")
          : document.getElementById("export-data-table");
      TableToExcel.convert(table, {
        name: `bao-cao-boi-thuong.xlsx`,
        sheet: {
          name: "Sheet 1",
        },
      });
    }
  }, [count]);

  return (
    <div
      className={classes.container}
      style={{ marginTop: embeded ? "6px" : "55px" }}
    >
      <div className="head-title">
        {form === "review-claim"
          ? "Kiểm Tra Xử Lý Hồ Sơ"
          : "Báo Cáo Bồi Thường"}
      </div>
      <CarSearch {...{ state, dispatch, user }} />
      <div className={classes.boxTable}>
        <div className="head">
          <div className="head-title">KẾT QUẢ TRA CỨU</div>
          <div className="button-table">
            <div className={classes.selectPage}>
              <Select
                className="select-number"
                fullWidth
                size="small"
                value={pageTable?.rowsPerPage || 10}
                style={{ color: "#2A3790" }}
                onChange={(e) => {
                  dispatch({
                    type: "onPaging",
                    page: pageTable?.page,
                    rowsPerPage: e?.target?.value,
                  });
                }}
              >
                <MenuItem value={10}>Hiển thị 10</MenuItem>
                <MenuItem value={20}>Hiển thị 20</MenuItem>
                <MenuItem value={50}>Hiển thị 50</MenuItem>
              </Select>
            </div>
            {form === "report-claim" &&
              privilegesFromUser?.CLAIM_REPORT == 1 && (
                <div className="button">
                  <Button onClick={() => dispatch("onSubmitExport")}>
                    <img
                      src={exportIcon}
                      style={{ width: 25, marginRight: "6px" }}
                      alt="ic"
                    />
                    Xuất báo cáo
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
      {rows.length > 0 ? (
        <CommonTable
          columns={columns}
          rows={rows}
          data={{ form }}
          classes={classes}
          page={{
            ...pageTable,
            onChange: (event, page, rowsPerPage) => {
              dispatch({
                type: "onPaging",
                page,
                rowsPerPage,
              });
            },
          }}
          event={(type, data) =>
            (ele, ...rest) => {
              dispatch({ type, data, ele, rest });
            }}
        />
      ) : (
        <div style={{ marginTop: "20px", color: "red", fontSize: "18px" }}>
          Không có kết quả giống với từ khoá bạn tìm. Vui lòng kiểm tra lại
        </div>
      )}
      <DialogNote
        open={!!openMaxLineDialog}
        dispatch={dispatch}
        loading={loading}
      />
      <TableExportExcel
        data={dataExport}
        dataSearch={dataSearch}
        roleType={roleType}
      />
      <TableExportExcelGC
        data={dataExport}
        dataSearch={dataSearch}
        roleType={roleType}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
