export const initialStateProductManager = {
  listProducts: [],
  listOrganization: [],
  listMerchants: [],
  oldListOrganization: [],
  oldListMerchants: [],
  listProviders: [],
  listProviderParent: [],
  listProductParents: [],
  dataExportExcel: [],
  errors: [],
  // organization: {},
  loadingFilter: {},
  pageFilter: {},
  dataSearch: {},
  detailProduct: {},
  pageTable: {},
  snackbar: {},
  payloadCreate: {},
  payloadListAutocomplete: {},
  loadingAutocomplete: {},
  loading: false,
  count: 0,
};

export const initialStateProductDetail = {
  productPackages: [],
  benefits: [],
  errors: [],
  listDataBenefitAlready: [],
  productPackage: {},
  valueAutocomplete: {},
  details: {},
  payloadProductPackage: {},
  snackbar: {},
  dialogAddBenefit: {},
  popverEdit: {},
  openDialogWarning: false,
  loading: false,
  count: 0,
};

export const limit_fees = [
  {
    id: 1,
    title: "Theo ngày lương",
    type_code: "theo_ngay_luong",
  },
  {
    id: 2,
    title: "Theo tháng lương",
    type_code: "theo_thang_luong",
  },
];

export const code_rules = [
  {
    id: 1,
    title: "Giới hạn/ năm",
  },
  {
    id: 2,
    title: "Giới hạn/ ngày",
  },
  {
    id: 3,
    title: "Giới hạn/ lần",
  },
  {
    id: 4,
    title: "Trợ cấp/ ngày",
  },
  {
    id: 5,
    title: "Trợ cấp/ lần",
  },
  {
    id: 6,
    title: "Trợ cấp/ năm",
  },
  {
    id: 7,
    title: "Trợ cấp lương/ ngày",
  },
  {
    id: 8,
    title: "Trợ cấp lương/ tháng",
  },
];

export const type_of_indemnify = [
  {
    id: 1,
    title: "Tai nạn",
  },
  {
    id: 2,
    title: "Sức khoẻ",
  },
  {
    id: 3,
    title: "Tử vong",
  },
  {
    id: 4,
    title: "Thai sản",
  },
  {
    id: 5,
    title: "TTVV/TTTT",
  },
];

export const conver_type_of_indemnify = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, "");

  return str
    .toString()
    .replace(/[\]\[\s]/g, "")
    .toLowerCase()
    .split("/")
    .map((i) => {
      switch (i) {
        case "tainan":
          return 1;
        case "suckhoe":
          return 2;
        case "tuvong":
          return 3;
        case "thaisan":
          return 4;
        case "ttvv":
          return 5;
        default:
          return null;
      }
    })
    .filter((i) => i !== null);
};
