import { message } from "antd";
import _, { get, isString } from "lodash";
import {
  getGCNList,
  changeStatus,
  getListMerchant,
  getListMerchantFilter,
  getProductListFilter,
} from "../../../../apis/gcnApis";
import { MAXIMUN_LINE_EXPORT_EXCEL } from "../util";
import moment from "moment";

export default function gcnManagerReducer(props) {
  const { state, action, dispatch } = props;

  const cases = {
    initial: async () => {
      const { dataSearch } = state;
      const res = {};
      res.filter = {
        from: moment().subtract(30, "days").format(),
        to: moment().format(),
      };
      dispatch("onLoading");
      const params = {
        keyword: dataSearch,
        limit: 20,
        offset: 0,
        from: moment().subtract(30, "days").format(),
        to: moment().format(),
      };
      const resApi = await getGCNList(params);
      if (resApi?.data?.statusCode !== 200) {
        dispatch("getListFilter");
        message.error("Lỗi lấy danh sách", 2);
        return { loading: false };
      }
      if (resApi?.data?.statusCode === 200) {
        dispatch("getListFilter");
        res.list = _.get(resApi, "data.result.data") || [];
        res.total = _.get(resApi, "data.result.total") || 0;
        res.pageTable = {
          count: Math.ceil(res.total / 20),
          pageNumber: 1,
          pageSize: 20,
        };
        res.selectType = [{ name: "BH con người" }];
        res.loading = false;
      }

      return res;
    },

    onChangeSearch: () => ({ dataSearch: action.value }),

    getListFilter: async () => {
      const res = {};
      const [prodResponse, orgaResponse] = await Promise.all([
        getProductListFilter({
          enableClaim: 1,
          limit: 10,
          offset: 0,
        }),
        getListMerchantFilter({
          limit: 10,
          offset: 0,
        }),
      ]);

      if (prodResponse instanceof Error || orgaResponse instanceof Error) {
        message.error("Có lỗi xảy ra", 2);
        return { loading: false };
      }
      res.pageFilter = {
        product: {
          page: 1,
          total: _.get(prodResponse, "data.result.total"),
          limit: 10,
        },
        merchant: {
          page: 1,
          total: _.get(orgaResponse, "data.result.total"),
          limit: 10,
        },
      };
      res.products = [...(_.get(prodResponse, "data.result.data") || [])].slice(
        0,
        5
      );
      res.organizes = [
        ...(_.get(orgaResponse, "data.result.data") || []),
      ].slice(0, 5);

      res.oldProducts = _.cloneDeep(
        _.get(prodResponse, "data.result.data", [])
      );
      res.oldOrganizes = _.cloneDeep(
        _.get(orgaResponse, "data.result.data", [])
      );

      return res;
    },

    getMoveProduct: async () => {
      const { pageFilter, loadingFilter, oldProducts, products } = state;
      if ((oldProducts || []).length >= pageFilter?.product?.total) {
        return;
      }

      dispatch({ type: "onLoadFilter", name: "product" });
      const dataApi = await getProductListFilter({
        statusId: 1,
        limit: 10,
        offset: pageFilter?.product?.page * 10,
      });

      if (dataApi?.data?.statusCode !== 200) {
        return { loadingFilter: { ...loadingFilter, product: false } };
      }

      const res = {};
      res.oldProducts = [
        ...oldProducts,
        ..._.get(dataApi, "data.result.data", []),
      ];
      res.products = [...products, ..._.get(dataApi, "data.result.data", [])];
      pageFilter.product = {
        ...pageFilter.merchant,
        total: _.get(dataApi, "data.result.total"),
        page: pageFilter?.merchant?.page + 1,
      };

      return {
        ...res,
        loadingFilter: { ...loadingFilter, product: false },
        pageFilter,
      };
    },

    getMoveMerchant: async () => {
      const { pageFilter, loadingFilter, oldOrganizes, organizes } = state;
      if ((oldOrganizes || []).length >= pageFilter?.merchant?.total) {
        return;
      }

      dispatch({ type: "onLoadFilter", name: "merchant" });
      const dataApi = await getListMerchantFilter({
        limit: 10,
        offset: pageFilter?.merchant?.page * 10,
      });

      if (dataApi?.data?.statusCode !== 200) {
        return { loadingFilter: { ...loadingFilter, merchant: false } };
      }

      const res = {};
      res.oldOrganizes = [
        ...oldOrganizes,
        ..._.get(dataApi, "data.result.data", []),
      ];
      res.organizes = [...organizes, ..._.get(dataApi, "data.result.data", [])];
      pageFilter.merchant = {
        ...pageFilter.merchant,
        total: _.get(dataApi, "data.result.total"),
        page: pageFilter?.merchant?.page + 1,
      };

      return {
        ...res,
        loadingFilter: { ...loadingFilter, merchant: false },
        pageFilter,
      };
    },

    onChangeFilter: () => {
      const { filter } = state;
      const { name, value } = action;
      filter[name] = value;
      return { filter };
    },

    onCheck: () => {
      const { organizes, products, oldOrganizes, oldProducts } = state;
      const { index, value, name } = action;
      if (name === "organize") {
        organizes[index].checked = value;
        oldOrganizes[index].checked = value;
        return { organizes, oldOrganizes };
      }
      if (name === "product") {
        products[index].checked = value;
        oldProducts[index].checked = value;
        return { products, oldProducts };
      }
    },

    deleteFilterDate: () => {
      const { filter } = state;
      filter.from = null;
      filter.to = null;
      return { filter };
    },

    deleteFilter: () => {
      const { organizes, products, oldOrganizes, oldProducts } = state;
      const { name } = action;

      const res = {};
      if (name === "organize") {
        res.organizes = _.cloneDeep(
          organizes.map((i) => ({ ...i, checked: false }))
        );
        res.oldOrganizes = _.cloneDeep(
          oldOrganizes.map((i) => ({ ...i, checked: false }))
        );
      }
      if (name === "product") {
        res.products = _.cloneDeep(
          products.map((i) => ({ ...i, checked: false }))
        );
        res.oldProducts = _.cloneDeep(
          oldProducts.map((i) => ({ ...i, checked: false }))
        );
      }
      return res;
    },

    onFilter: async () => {
      const { name, value } = action;
      const { organizes, products, filter } = state;
      const res = {};

      const checkedOrg = organizes.filter((item) => item.checked === true);
      const orgIds = checkedOrg.map((i) => i.id);

      const checkedProd = products.filter((item) => item.checked === true);
      const productIds = checkedProd.map((i) => i.id);

      if (["from", "to"].includes(name)) {
        filter[name] = value;
      }

      const params = {
        merchantIds: !_.isEmpty(orgIds) ? orgIds : undefined,
        // orgIds: !_.isEmpty(orgIds) ? orgIds : undefined,
        productIds: !_.isEmpty(productIds) ? productIds : undefined,
        from: filter?.from || undefined,
        to: filter?.to || undefined,
        limit: 20,
        offset: 0,
      };
      dispatch("onLoading");
      const result = await getGCNList(params);
      if (result instanceof Error) {
        message.error("Lỗi lấy danh sách", 2);
        filter.open = false;
        return { filter, loading: false };
      }
      res.list = _.get(result, "data.result.data") || [];
      res.total = _.get(result, "data.result.total") || 0;
      res.pageTable = {
        count: Math.ceil(res.total / 20),
        pageNumber: 1,
        pageSize: 20,
      };
      res.dataSearch = "";
      res.loading = false;
      filter.open = false;

      return { ...res, filter };
    },

    openFilter: () => {
      const { filter } = state;
      return { filter: { ...filter, open: true } };
    },

    closeFilter: () => {
      const { filter } = state;
      return { filter: { ...filter, open: false } };
    },

    openPopverEditTableGCN: () => {
      const { data, ele } = action;

      return {
        popverEditTabelGCN: {
          open: true,
          ref: ele.currentTarget,
          item: data?.item,
        },
      };
    },

    closePopverEditTableGCN: () => ({ popverEditTabelGCN: { open: false } }),

    changeStatus: async () => {
      const { popverEditTabelGCN, pageTable, dataSearch, filter } = state;
      dispatch("onLoading");
      const dataApi = await changeStatus({
        id: popverEditTabelGCN?.item?.id,
        status: popverEditTabelGCN?.item?.status ? 0 : 1,
      });

      const params = {
        keyword: dataSearch,
        limit: pageTable.pageSize,
        offset: (pageTable?.pageNumber - 1) * pageTable.pageSize,
        ...filter,
      };
      dispatch("closePopverEditTableGCN");
      const resApi = await getGCNList(params);

      if (resApi instanceof Error) {
        return { loading: false };
      }
      const res = {};
      res.list = _.get(resApi, "data.result.data") || [];
      res.total = _.get(resApi, "data.result.total") || 0;
      res.pageTable = {
        count: Math.ceil(res.total / pageTable.pageSize),
        pageNumber: pageTable?.pageNumber,
        pageSize: pageTable.pageSize,
      };
      res.selectType = [{ name: "BH con người" }];
      res.loading = false;

      return { loading: false, ...res };
    },

    onLoading: () => ({ loading: true }),

    onLoadFilter: () => ({
      loadingFilter: { ...state?.loadingFilter, [action.name]: true },
    }),

    onPaging: async () => {
      const { filter, pageTable } = state;
      const { e, value, dataSearch = null } = action;
      const res = {};

      if (dataSearch || dataSearch === "") {
        res.dataSearch = dataSearch;
      }

      const p = value
        ? {
            limit: pageTable.pageSize,
            offset: (value - 1) * pageTable.pageSize,
          }
        : {
            limit: 20,
            offset: 0,
          };

      const params = {
        keyword: dataSearch,
        ...filter,
        ...p,
      };
      dispatch("onLoading");
      const result = await getGCNList(params);
      if (result instanceof Error) {
        message.error("Lỗi lấy danh sách", 2);
        return { loading: false };
      }
      res.list = _.get(result, "data.result.data") || [];
      res.total = _.get(result, "data.result.total") || 0;
      res.pageTable = {
        count: Math.ceil(res.total / pageTable.pageSize),
        pageNumber: value || 1,
        pageSize: pageTable.pageSize,
      };
      return { ...res, loading: false };
    },

    onSubmitExport: async () => {
      const { count, filter } = state;
      const res = {};
      dispatch("onLoading");

      const params = {
        ...filter,
        limit: +MAXIMUN_LINE_EXPORT_EXCEL,
        offset: 0,
        productPackageId: null,
      };

      const dataApi = await getGCNList(params);

      if (dataApi instanceof Error) {
        message.error("Lỗi lấy danh sách", 2);
        return { loading: false };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.count = +count + 1;
        res.dataExport = _.get(dataApi, "data.result.data") || [];
      }

      return { ...res, loading: false };
    },

    handleShrink: () => {
      const { name, show } = action;
      const { oldOrganizes, oldProducts } = state;

      const dataProduct = _.cloneDeep(oldProducts);
      const dataOrganizes = _.cloneDeep(oldOrganizes);

      if (name === "product") {
        const products = dataProduct.slice(0, 5);
        return {
          products,
        };
      }

      const organizes = dataOrganizes.slice(0, 5);
      return {
        organizes,
      };
    },

    handleShow: async () => {
      const { name } = action;
      const {
        pageFilter,
        oldOrganizes,
        oldProducts,
        organizes,
        products,
        loadingFilter,
      } = state;

      const res = {};
      if (name === "product") {
        if (oldProducts.length > products.length) {
          return { products: [...oldProducts] };
        }

        if (pageFilter?.product.page * 10 >= pageFilter?.product?.total) {
          return;
        }

        dispatch({ type: "onLoadFilter", name: "product" });
        const productApi = await getProductListFilter({
          enableClaim: 1,
          limit: 10,
          offset: pageFilter?.product.page * 10,
        });

        if (productApi?.data?.statusCode !== 200) {
          return {
            loading: false,
            snackbar: {
              open: true,
              type: "error",
              message: "Xin lỗi, đã xảy ra lỗi trong quá trình lấy dữ liệu",
            },
          };
        }

        res.products = [
          ...products,
          ...(get(productApi, "data.result.data") || []),
        ];
        res.oldProducts = [
          ...oldProducts,
          ...(get(productApi, "data.result.data") || []),
        ];
        res.pageFilter = {
          ...pageFilter,
          product: {
            ...pageFilter.product,
            page: pageFilter?.product?.page + 1,
            total: get(productApi, "data.result.total", 0),
          },
        };
      }

      if (name === "merchant") {
        if (oldOrganizes.length > organizes.length) {
          return { organizes: [...oldOrganizes] };
        }

        if (pageFilter?.merchant.page * 10 >= pageFilter?.merchant?.total) {
          return;
        }

        dispatch({ type: "onLoadFilter", name: "merchant" });
        const merchantApi = await getListMerchantFilter({
          limit: 10,
          offset: pageFilter?.merchant.page * 10,
        });

        if (merchantApi?.data?.statusCode !== 200) {
          return {
            loading: false,
            snackbar: {
              open: true,
              type: "error",
              message: "Xin lỗi, đã xảy ra lỗi trong quá trình lấy dữ liệu",
            },
          };
        }

        res.organizes = [
          ...organizes,
          ...(get(merchantApi, "data.result.data") || []),
        ];
        res.oldOrganizes = [
          ...oldOrganizes,
          ...(get(merchantApi, "data.result.data") || []),
        ];
        res.pageFilter = {
          ...pageFilter,
          merchant: {
            ...pageFilter.merchant,
            page: pageFilter?.merchant?.page + 1,
            total: get(merchantApi, "data.result.total", 0),
          },
        };
      }

      return { ...res, loadingFilter: { ...loadingFilter, [name]: false } };
    },
  };

  return isString(action.type) && cases[action.type];
}
