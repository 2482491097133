import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { People } from "@mui/icons-material";

const theme = createTheme();

const styleds = makeStyles(() => {
  return {
    rowStyle: {
      position: "sticky",
      display: "flex",
      width: "100%",
      top: 0,
      left: 0,
      justifyContent: "flex-start",
      alignItems: "center",
      zIndex: 11,
      background: "#284078",
    },

    divStyle: {
      width: "100%",
      display: "flex",
    },

    styleButtonBack: {
      color: "white",
      display: "flex",
      padding: "0 13px",
      // position: "absolute",
      left: 0,
      top: 0,
      alignItems: "center",
    },

    styleTitle: {
      color: "white",
      fontSize: 19,
      paddingRight: "16px",
      fontWeight: "700",
      alignItems: "center",
      lineHeight: "35px",
      textAlign: "center",
      width: "80%",
    },
  };
});

export default function HeaderBar(props) {
  const { title = "", onPress } = props;
  const classes = styleds();

  return (
    <Box className={classes.rowStyle}>
      <div className={classes.divStyle} style={{ margin: "55px 0 10px" }}>
        <div
          style={{ top: "44px" }}
          className={classes.styleButtonBack}
          onClick={onPress}
        >
          <ChevronLeftIcon style={{ fontSize: "35px" }} />
        </div>
        <div className={classes.styleTitle}>{title}</div>
      </div>
    </Box>
  );
}
