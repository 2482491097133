
import styled, { css } from 'styled-components';
import { Button } from 'antd';
import Color from '../../utils/color';

export default styled(Button)`
	border-radius: ${props => (props.border ? props.border + 'px' : '18px')};
	min-width: 150px;
	height: ${props => (props.height ? props.height + 'px' : '52px')};
	// text-transform: uppercase;
  width: 100%;
	font-size: ${props => (props.fontSize ? props.fontSize + 'px' : '17px')};
	font-weight: ${props => (props.fontWeight ? props.fontWeight : 'bold')};
	${props => {
		let cBorder = 'none',
			cBackgroundColor = Color.lightgray,
			cColor = Color.white;
		if (!props.disabled) {
			switch (props.type) {
				case 'primary':
					if (props.ghost) {
						cBorder = `solid 1px ${Color.yellow}`;
						cBackgroundColor = `${Color.white} !important`;
						cColor = Color.yellow;
					} else {
						cBackgroundColor = Color.yellow;
					}
					break;
				case 'secondary':
					if (props.ghost) {
						cBorder = `solid 1px ${Color.blue}`;
						cBackgroundColor = `${Color.white} !important`;
						cColor = Color.blue;
					} else {
						cBackgroundColor = Color.blue;
					}
					break;
				case 'danger':
					if (props.ghost) {
						cBorder = `solid 1px ${Color.red}`;
						cBackgroundColor = `${Color.white} !important`;
						cColor = Color.blue;
					} else {
						cBackgroundColor = Color.red;
					}
					break;
				default:
					return null;
			}
		}
		return css`
			&& {
				background-color: ${cBackgroundColor};
				color: ${cColor};
				border: ${cBorder};
				&:hover {
					border: ${cBorder};
					background-color: ${cBackgroundColor};
					color: ${cColor};
				}
			}
		`;
	}};
`;

