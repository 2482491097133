import { makeStyles } from "@mui/styles";

const styleDowDocx = makeStyles((theme) => {
  return {
    container: {
      marginTop: "40px",
      padding: "16px",

      "& .title": {
        corlo: "#4B4B4B",
        fontWeight: "700",
        fontSize: "24px",
        paddingLeft: "32px",
      },
    },

    box_button: {
      display: "flex",
      alignItems: "center",
      margin: "24px",

      "& .text-field-search": {
        marginLeft: "24px",
        minWidth: "350px",
      },
    },

    noData: {
      marginTop: "20px",
      marginLeft: "30px",
      color: "red",
      fontSize: "18px",
    },

    div: {
      padding: "0 8px",
    },

    table: {
      minWidth: "1520px",

      "& tbody td:last-child": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      "& tbody td:first-child": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },

      "& th": {
        fontWeight: "600",
        textAlign: "center",
        whiteSpace: "nowrap",
      },

      "& td": {
        textAlign: "center",
        padding: 0,
      },

      "& .stickey": {
        position: "sticky",
      },

      "& th.stt": {
        width: "45px",
      },

      "& th.full-name": {
        minWidth: "180px",
        textTransform: "capitalize",
      },

      "& th.view-records": {
        right: 0,
        background: "white",
      },

      "& td.full-name": {
        textTransform: "capitalize",
      },

      "& td.view-records": {
        right: 0,
        background: "white",
      },

      "& td.reviewer": {
        minWidth: "200px",
        paddingLeft: "8px",
        textAlign: "center",
        // display: "flex",
        // justifyContent: "space-between",
      },
    },

    headerRow: {
      borderTop: "none",
      borderBottom: "2px solid #686868",
      padding: "0 8px",
    },

    cellRow: {
      borderTop: "none",
      borderBottom: "1.5px solid #D6DEEB",
      background: "white !important",
    },

    //
  };
});

export default styleDowDocx;
