import React from "react";
import { makeStyles } from "@mui/styles";
import { Stack, Stepper, Step, StepLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import { ReactComponent as Tick_icon_steper } from "../../../../../assets/health/tick_icon_steper.svg";
import { ReactComponent as Tick_icon_steper_2 } from "../../../../../assets/health/tick_icon_steper_2.svg";
import { ReactComponent as Tick_icon_steper_3 } from "../../../../../assets/health/tick_icon_steper_3.svg";

export default function SteperHeader(props) {
  const { detail } = props;
  const classes = useStyled();

  const curenStep = () => {
    switch (detail.statusId) {
      case 1:
      case 2:
      case 3:
        return 0;
      case 4:
        return 1;
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return 2;

      default:
        break;
    }
  };

  const steps = [
    { icon: Tick_icon_steper, label: "Gửi yêu cầu bồi thường" },
    { icon: Tick_icon_steper_2, label: "Bổ sung chứng từ" },
    { icon: Tick_icon_steper_3, label: "Kết quả bồi thường" },
  ];

  return (
    <div style={{ marginBottom: "22px", marginTop: "16px" }}>
      <Stack sx={{ width: "100%" }} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={curenStep()}
          connector={<ColorlibConnector />}
        >
          {steps.map((item, index) => (
            <Step key={`step_icon_${index}`}>
              <StepLabel
                className={classes.stepLabel}
                StepIconComponent={(status) => {
                  return (
                    <item.icon
                      className={
                        status.icon == 3 &&
                        ([10].includes(detail.statusId) ||
                          (detail.amount == 0 &&
                            ![2, 3, 4].includes(detail.statusId)))
                          ? classes.iconStepError
                          : status.active || status.completed
                          ? classes.iconStepCompleted
                          : classes.iconStepDisabel
                      }
                    />
                  );
                }}
              >
                {item.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </div>
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderTop: "2px dotted #3961A0",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderTop: "2px dotted #3961A0",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTop: "2px dotted #686868",
  },
}));

const useStyled = makeStyles(() => ({
  iconStepDisabel: {
    "& path": {
      fill: "#A0A0A0",
    },
    "& rect": {
      stroke: "#A0A0A0",
    },
  },

  iconStepCompleted: {
    "& path": {
      fill: "#3961A0",
    },
    "& rect": {
      stroke: "#3961A0",
    },
  },

  iconStepError: {
    "& path": {
      fill: "#FF003D",
    },
    "& rect": {
      stroke: "#FF003D",
    },
  },

  stepLabel: {
    "& .MuiStepLabel-iconContainer": {
      fontSize: "13px",
      color: "white",
      width: 35,
      height: 35,
      fontWeight: "bold",
      borderRadius: "50%",
      border: "1px solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  //
}));
