export const initialStateAccountManager = {
  listUsers: [],
  listOrg: [],
  roleGroups: [],
  oldListOrg: [],
  oldRoleGroups: [],
  dataExport: [],
  selectUserType: [],
  pageTable: {},
  pageFilter: {},
  dataFill: {},
  expand: {},
  snackbar: {},
  loadingFilter: {},
  loading: false,
  count: 0,
};

export const initialStateAccountDetail = {
  listOrg: [],
  listProducts: [],
  roleGroupByOrg: [],
  productParents: [],
  listMerchants: [],
  listProviderBVKP: [],
  oldListProviderBVKP: [],
  errors: [],
  details: {},
  payloadAcount: {},
  roleGroup: {},
  snackbar: {},
  dialogNotifica: {},
  loadingAutocomplete: {},
  payloadListAutocomplete: {},
  loading: false,
};

export const userType = [
  { label: "GC System", value: "globalcare" },
  { label: "Provider", value: "provider" },
  // { label: "GC Merchant", value: "merchant_gc" },
  { label: "Merchant", value: "merchant" },
  { label: "TPAG", value: "tpag" },
  { label: "TPAS", value: "tpas" },
  { label: "TPA", value: "tpa" },
  { label: "BVPK", value: "bvpk" },
];

export const accountType = [
  { label: "Admin", id: 1 },
  { label: "User", id: 2 },
];

export const userTypes = [
  { label: "Tất cả", value: "all" },
  { label: "GC system", value: "globalcare" },
  { label: "Provider", value: "provider" },
  // { label: "GC Merchant", value: "merchant_gc" },
  { label: "Merchant", value: "merchant" },
  { label: "TPAG", value: "tpag" },
  { label: "TPAS", value: "tpas" },
  { label: "TPA", value: "tpa" },
  { label: "BVPK", value: "bvpk" },
];
