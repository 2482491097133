import { makeStyles } from "@mui/styles";

export const styledStep2 = makeStyles(() => ({
  container: {
    padding: "0 16px 16px",
    background: "white",

    "& .titleHead": {
      fontSize: "17px",
      color: "#284078",
      fontWeight: "600",
      padding: "12px 0",
    },

    "& .rowDiv": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: "500",

      "& .valueSpan": {
        maxWidth: "70%",
        textAlign: "end",
      },

      "& .capitalize": {
        textTransform: "capitalize",
      },
    },

    "& .bm": {
      paddingBottom: "8px",
    },
  },
}));
