import React from "react";
import moment from "moment";

// import Logo from "../../../../../assets/images/LOGO-GC.png";
import Logo from "../assets/fina_logo_v2.png";
import { styledNotify } from "../components/step/styledNotify";
import warring from "../../../../../assets/ahamove/waring.png";
import received from "../../../../../assets/ahamove/received.png";
import phone from "../../../../../assets/ahamove/Phone.svg";
import email from "../../../../../assets/ahamove/Email.svg";
import Certificate from "../components/step/DialogCertificate";

export default function Notify(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledNotify();

  const { inforOrder, destination, statusNotify, openCertificate } = state;
  const date = moment(inforOrder?.endDate).format("DD/MM/YYYY, HH:mm");

  return (
    <div
      style={{
        minHeight: isApp ? "calc(100vh - 165px)" : "calc(100vh - 125px)",
      }}
    >
      <div style={{ background: "white" }}>
        <div className={classes.boxLogo}>
          <div className="logo">
            <img src={Logo} alt="logo" width={150} height={48} />
          </div>
          {statusNotify === "success" ? (
            <div className="boxText">
              <span className="line_1">
                Fina đã nhận được khiếu nại của bạn
              </span>
              <span className="line_2">Chúng tôi sẽ phản hồi sớm nhất</span>
            </div>
          ) : (
            <div className="boxInfor">
              <span className="line1">Cổng hỗ trợ khách hàng từ Fina</span>
              <span className="line2">
                Mã đơn hàng {inforOrder?.partnerOrderCode}
              </span>
              <span className="line3">
                Hoàn thành dự kiến vào {date.replace(/,/g, "")}
              </span>
              <span
                onClick={() => dispatch("openLink")}
                style={{
                  color: "#3F76DF",
                  fontStyle: "italic",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Xem giấy chứng nhận bảo hiểm
              </span>
            </div>
          )}
        </div>
        <div
          style={{
            paddingBottom: "8px",
            paddingTop: statusNotify === "success" ? "60px" : "40px",
          }}
        >
          <img
            src={statusNotify === "success" ? received : warring}
            alt="warring"
            width={"100%"}
          />
        </div>

        {statusNotify !== "success" && (
          <div
            style={{
              width: "100%",
              padding: "0 16px",
              paddingBottom: "20px",
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            Khiếu nại chỉ có thể được tiếp nhận trong{" "}
            {/* {" " + destination?.data?.paymentInfo?.paymentId === 1
              ? "7 ngày"
              : "24h" + " "}{" "} */}
            72 giờ từ thời điểm hoàn thành đơn hàng
          </div>
        )}
      </div>
      <div className={classes.divFooter}>
        <div className="title">Mọi thắc mắc xin vui lòng liên hệ</div>
        <div className="infor">
          <div
            className="phone"
            onClick={() => {
              window.open("tel:0857498668", "_self");
            }}
          >
            <img src={phone} alt="phone" style={{ marginRight: "6px" }} />
            08 5749 8668
          </div>
          <div
            className="email"
            onClick={() => {
              window.location.assign("mailto:support@fina.com.vn");
            }}
          >
            <img src={email} alt="email" style={{ marginRight: "6px" }} />
            support@fina.com.vn
          </div>
        </div>
      </div>
      <Certificate
        open={openCertificate}
        link={inforOrder?.certLink}
        onClose={() => dispatch("closeCertificate")}
      />
    </div>
  );
}
