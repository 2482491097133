import { pdf } from "@react-pdf/renderer";
import { message } from "antd";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import _ from "lodash";
import { uploadImg } from "../../../../../apis/claimAhamoveApi";
import { deleteS3Document } from "../../../../../apis/claimApis";
import {
  getDetailIndemnify,
  getFileByUrl,
  updateDetailIndemnify,
  updateIndemnifyByMerchant,
} from "../../../../../apis/claimLoyaltyApis";
import ExportAcceptClaimPDF from "../components/ExportAcceptClaimPDF";
import History from "./../../../../../configs/history";

const getItemImg = ({ data, index, id }) => {
  switch (id) {
    case 200:
      return {
        name: `Hình ảnh hàng hoá trước khi giao ${index + 1}`,
        path: data.path,
      };
    case 202:
      return {
        name: `Hình ảnh hóa đơn ${index + 1}`,
        path: data.path,
      };
    case 208:
      return {
        name: `Hình ảnh CMND - CCCD ${index + 1}`,
        path: data.path,
      };
    default:
      return null;
  }
};

const getAction = (statusId) => {
  switch (statusId) {
    case 4:
      return "amend";
    case 5:
      return "approve";
    case 6:
      return "complete";
    case 7:
      return "reject";
    default:
      return "";
  }
};

export default function detailReducer({
  state,
  dispatch,
  action,
  roleType,
  id,
}) {
  const cases = {
    initial: async () => {
      dispatch("onLoading");
      const res = {};
      const dataApi = await getDetailIndemnify(id);
      if (dataApi?.data?.statusCode === 200) {
        const data = _.get(dataApi, "data.result") || {};

        res.row = data;
        res.initStatusId = _.get(dataApi, "data.result.statusId");
        res.statusId = _.get(dataApi, "data.result.statusId");
        res.updated = [6, 7].includes(res.statusId);
        res.showFileEvidence = {};

        const temp =
          (data?.elements || []).find((i) => i.type_id === 203) || {};
        res.content = temp?.text || "";
        res.approvalAmount = temp?.value || "";
        const files203 = temp?.extra_info?.documents || [];
        res.documentsAdditionalImg = files203.filter((i) => i.type !== "file");
        res.documentsAdditionalFile = files203.filter((i) => i.type === "file");

        const audio206 =
          data?.elements.find((item) => item.type_id === 206)?.extra_info
            ?.documents || [];
        res.evidenceAudio = audio206
          ?.filter((i) => i.type === "evidence_audio")
          .map((item) => ({ ...item, playAudio: true }));

        const audio =
          (data?.elements || []).find((i) => i.type_id === 205)?.extra_info
            ?.documents || [];
        res.documentAudio = audio?.map((i) => ({ ...i, playAudio: true }));

        const claimFile =
          (data?.elements || []).find((item) => item.type_id === 207)
            ?.extra_info?.documents || [];
        res.acceptSignFile = claimFile.filter(
          (i) => i.type === "accept_sign_doc"
        );
        res.updatedSignFile = !_.isEmpty(res.acceptSignFile);
      }
      return {
        ...res,
        loading: false,
        showImg: {},
      };
    },

    back: () => {
      History.goBack();
    },

    onchangeContent: () => {
      const { e } = action;
      return {
        content: e.target.value,
        errors: { ...state.errors, errorContent: !e.target.value },
      };
    },

    onchangeApprovalAmount: () => {
      const { e } = action;
      const value = e.target.value.replace(/\./g, "");
      return {
        approvalAmount: value,
        errors: {
          ...state.errors,
          approvalAmount: !value && value !== 0,
        },
      };
    },

    changeStatusId: () => {
      const { e } = action;
      const { errors, statusId } = state;

      const res = {};
      if (+e.target.value === 7) {
        res.approvalAmount = 0;
        res.errors = { ...errors, approvalAmount: false };
      }

      if (statusId === 7) {
        res.approvalAmount = null;
      }

      if (+e.target.value === 4) {
        res.errors = { ...errors, approvalAmount: false };
      }

      return { statusId: +e.target.value, ...res };
    },

    update: () => {
      const { content, approvalAmount, statusId } = state;
      if (
        !content ||
        (!approvalAmount && +statusId !== 4 && approvalAmount !== 0)
      ) {
        return {
          errors: {
            ...state.errors,
            errorContent: !content,
            approvalAmount:
              !approvalAmount && +statusId !== 4 && approvalAmount !== 0,
          },
        };
      }
      return { confirmDialog: true };
    },

    onSubmitUpdate: async () => {
      const {
        content,
        statusId,
        approvalAmount,
        row,
        documentsAdditionalImg,
        documentsAdditionalFile,
        documentAudio,
        acceptDoc,
      } = state;

      const newDocumentAudio = documentAudio.map(({ playAudio, ...item }) => ({
        ...item,
        currentTime: 0,
        progress: 0,
      }));

      dispatch("onOpenUpdating");

      const oldElement207 =
        row?.elements?.find((item) => item.type_id === 207) || {};
      const documentsOfOldElement207 = (
        oldElement207?.extra_info?.documents || []
      ).filter((i) => i.type !== "accept_doc");

      const listTodo = (oldElement207?.extra_info?.documents || [])
        .filter((i) => i.type === "accept_doc")
        .map((i) => i.path);

      // if (listTodo.length > 0 && statusId === 5) {
      //   dispatch({ type: "todoS3Path", value: listTodo });
      // }

      const approveDoc =
        statusId === 5
          ? [
              {
                id: oldElement207?.id,
                claimId: oldElement207?.claim_id,
                typeId: 207,
                typeCode: "file_compensation_claim_records",
                extraInfo: {
                  documents: [...documentsOfOldElement207, acceptDoc],
                },
              },
            ]
          : [];

      const params = {
        claimId: row.id,
        productIdParent: 34,
        elements: [
          {
            typeId: 203,
            typeCode: "note",
            extraInfo: {
              documents: [
                ...documentsAdditionalImg,
                ...documentsAdditionalFile,
              ],
            },
            text: content,
            value: approvalAmount || null,
          },
          {
            typeId: 205,
            typeCode: "file_voice",
            extraInfo: { documents: newDocumentAudio },
          },
          ...approveDoc,
        ],
      };
      const action = getAction(statusId);
      const dataApi = await updateDetailIndemnify(roleType, action, params);

      const res = {};
      if (dataApi?.data?.statusCode === 200) {
        res.confirmDialog = false;
        res.updated = true;
        res.snackbar = {
          open: true,
          message: "Cập nhật thành công",
          type: "success",
        };
        row.elements = dataApi?.data?.result?.elements;
        row.statusId = dataApi?.data?.result?.status_id;
        if (listTodo.length > 0 && statusId === 5) {
          dispatch({ type: "todoS3Path", value: listTodo });
        }
      }
      if (dataApi instanceof Error) {
        return {
          confirmDialog: false,
          openNotiUpdating: false,
          snackbar: {
            open: true,
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
            type: "error",
          },
        };
      }

      return { openNotiUpdating: false, ...res };
    },

    additionalFile: async () => {
      const { name, e } = action;
      const { documentsAdditionalFile, documentsAdditionalImg } = state;
      const file = e.target.files[0];
      const data = new FormData();

      if (!file) {
        return;
      }

      data.append("document", file);
      dispatch("onLoading");
      const api = await uploadImg(data);

      if (api instanceof Error) {
        return { loading: false };
      }

      const res = {};
      if (api?.data?.statusCode === 200) {
        const dataApi = _.get(api, "data.result[0]") || {};
        res.loading = false;
        if (name === "file") {
          res.documentsAdditionalFile = [
            ...documentsAdditionalFile,
            {
              path: dataApi?.path,
              size: dataApi?.size,
              originalname: dataApi?.originalname,
              type: name,
            },
          ];
        } else {
          res.documentsAdditionalImg = [
            ...documentsAdditionalImg,
            { path: dataApi?.path, size: dataApi?.size },
          ];
        }
      }

      return res;
    },

    deleteAdditionalImg: () => {
      const { indexImg, itemIMG } = action;
      const { documentsAdditionalImg } = state;
      if (itemIMG.path) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: itemIMG.path },
        });
      }

      documentsAdditionalImg.splice(indexImg, 1);
      return { documentsAdditionalImg };
    },

    downloadFile: async () => {
      const { path, name } = action;
      const dataApi = await getFileByUrl(path);
      saveAs(dataApi, `${name}`);
    },

    closeConfirm: () => ({ confirmDialog: false }),

    showDialog: () => {
      const { indexImg, listImg, name } = action;
      const { showImg } = state;

      showImg.listImg = listImg?.map((i) => i.path);
      showImg.path = listImg[indexImg].path;
      showImg.indexImg = indexImg;
      showImg.open = true;
      return { showImg };
    },

    changeIndexImg: () => {
      const { showImg } = state;
      const { name } = action;
      const res = {};
      if (name === "next") {
        const index =
          showImg?.indexImg === showImg?.listImg?.length - 1
            ? 0
            : showImg?.indexImg + 1;

        res.showImg = {
          ...showImg,
          indexImg: index,
          path: showImg?.listImg[index],
        };
      } else {
        const index =
          showImg?.indexImg === 0
            ? showImg?.listImg.length - 1
            : showImg?.indexImg - 1;

        res.showImg = {
          ...showImg,
          indexImg: index,
          path: showImg?.listImg[index],
        };
      }

      return res;
    },

    closeShowImg: () => ({
      showImg: { ...state.showImg, open: false },
    }),

    showEvidence: () => {
      const { showFileEvidence } = state;
      const { file } = action;
      showFileEvidence.open = true;
      showFileEvidence.link = file.path;
      return { showFileEvidence };
    },

    showEvidenceImg: () => {
      const { file } = action;
      const { showImg } = state;
      showImg.listImg = [file];
      showImg.path = file.path;
      showImg.indexImg = 0;
      showImg.open = true;
      return { showImg };
    },

    closeShowEvidence: () => ({ showFileEvidence: {} }),

    onLoading: () => ({ loading: true }),

    downLoadZip: async () => {
      const { row } = state;
      dispatch("onLoading");

      const elements = row.elements || [];
      const listPathAndNameImg = [];

      const listImgFileZip = [];
      const listClaimFileZip = [];

      elements.forEach((item) => {
        if (!_.isEmpty(item?.extra_info?.documents || [])) {
          (item?.extra_info?.documents || [])?.forEach((el, index) => {
            const itemArray = getItemImg({ data: el, index, id: item.type_id });
            !_.isEmpty(itemArray) && listPathAndNameImg.push(itemArray);
          });
        }
      });

      const listAddition203 =
        elements.find((item) => item.type_id === 203)?.extra_info?.documents ||
        [];

      (listAddition203.filter((i) => i.type !== "file") || []).forEach(
        (item, index) => {
          listPathAndNameImg.push({
            name: `Hình ảnh bổ sung ${index + 1}`,
            path: item.path,
          });
        }
      );

      const listEvidence206 =
        elements.find((item) => item.type_id === 206)?.extra_info?.documents ||
        [];

      (
        listEvidence206.filter((i) => i.type === "evidence_image") || []
      ).forEach((item, index) => {
        listPathAndNameImg.push({
          name: `Bằng chứng tìm tài xế ${index + 1}`,
          path: item.path,
        });
      });

      const driverContract209 =
        elements.find((item) => item.type_id === 209)?.extra_info?.documents ||
        [];
      (driverContract209.filter((i) => i.type !== "file") || []).forEach(
        (item, index) => {
          listPathAndNameImg.push({
            name: `Hợp đồng tài xế với Ahamove ${index + 1}`,
            path: item.path,
          });
        }
      );

      if (listPathAndNameImg.length > 0) {
        await Promise.all(
          listPathAndNameImg.map(async (i) => {
            const dataApi = await getFileByUrl(i.path);
            listImgFileZip.push({ name: i.name, file: dataApi });
          })
        );
      }

      const fileAddition203 = listAddition203.filter((i) => i.type === "file");
      const additionAudio205 =
        elements.find((item) => item.type_id === 205)?.extra_info?.documents ||
        [];

      const listFile206 = listEvidence206.filter(
        (i) => i.type !== "evidence_image"
      );
      const file207 =
        elements.find((item) => item.type_id === 207)?.extra_info?.documents ||
        [];
      const listFile207 = file207.filter((i) => i.type !== "accept_doc");

      const driverFile209 = driverContract209.filter((i) => i.type === "file");

      const files = [
        ...fileAddition203,
        ...additionAudio205,
        ...listFile206,
        ...listFile207,
        ...driverFile209,
      ];

      if (!_.isEmpty(files)) {
        await Promise.all(
          files.map(async (i) => {
            const dataApi = await getFileByUrl(i.path);
            listClaimFileZip.push({ name: i.originalname, file: dataApi });
          })
        );
      }

      const zip = new JSZip();

      listClaimFileZip.forEach((file) => {
        zip.file(file.name, file.file);
      });

      const img = zip.folder("Hình ảnh");
      listImgFileZip.forEach((blob, index) => {
        img.file(`${blob.name}.png`, blob.file, {
          base64: true,
        });
      });
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "Hồ sơ yêu cầu bồi thường bảo hiểm.zip");
      });

      return { loading: false };
    },

    closeSnackbar: () => ({ snackbar: { ...state.snackbar, open: false } }),

    loadingAudio: () => ({ loadAudio: true }),

    addAudio: async () => {
      const { e } = action;
      const { documentAudio } = state;
      if (e.target.files.length < 1) {
        return;
      }

      const file = e.target.files[0];
      const data = new FormData();
      data.append("document", file);

      dispatch("loadingAudio");
      const resultApi = await uploadImg(data);
      if (resultApi instanceof Error) {
        message.error(resultApi?.response?.data?.message || "Xảy ra lỗi", 3);

        return { loadAudio: false };
      }

      const res = {};
      const dataApi = _.get(resultApi, "data.result[0]") || {};

      res.documentAudio = [
        ...documentAudio,
        {
          ...dataApi,
          size: `${((dataApi?.size || 0) / 1024).toFixed()} KB`,
          playAudio: true,
        },
      ];
      return { ...res, loadAudio: false };
    },

    setPlayIcon: () => {
      const { documentAudio, evidenceAudio } = state;
      const { index, all, value, name } = action;

      if (all) {
        const evidenceArr = evidenceAudio.map((i) => ({
          ...i,
          playAudio: true,
        }));
        const documentArr = documentAudio.map((i) => ({
          ...i,
          playAudio: true,
          progress: 0,
          currentTime: 0,
        }));

        return { evidenceAudio: evidenceArr, documentAudio: documentArr };
      }

      if (name === "evidence") {
        evidenceAudio[index] = { ...evidenceAudio[index], playAudio: value };
        return { evidenceAudio };
      } else {
        documentAudio[index] = { ...documentAudio[index], playAudio: value };
        return { documentAudio };
      }
    },

    setAudioPlay: () => {
      const { documentAudio } = state;
      const { index, progress, currentTime, duration } = action;
      documentAudio[index] = {
        ...documentAudio[index],
        progress:
          progress == 0
            ? progress
            : progress || documentAudio[index]?.progress || 0,
        currentTime:
          currentTime == 0
            ? currentTime
            : currentTime || documentAudio[index]?.currentTime || 0,
        duration:
          duration == 0
            ? duration
            : duration || documentAudio[index]?.duration || 0,
      };

      return { documentAudio };
    },

    cantPlayAudio: () => {
      return {
        snackbar: {
          ...state.snackbar,
          open: true,
          type: "error",
          message:
            "Vui lòng download để nghe offline, trình duyệt chưa hỗ trợ file này.",
        },
      };
    },

    deleteAudio: () => {
      const { documentAudio } = state;
      const { index, url } = action;

      if (url) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: url },
        });
      }

      documentAudio.splice(index, 1);
      return { documentAudio };
    },

    dowloadAudio: async () => {
      const { documentAudio } = state;
      const item = documentAudio[action.index];
      const dataApi = await getFileByUrl(item.path);
      saveAs(dataApi, `${item?.originalname}`);
    },

    loadingFile: () => ({ loadFile: true }),

    addAcceptSignFile: async () => {
      const { e } = action;
      const { acceptSignFile } = state;
      const file = e.target.files[0];
      const data = new FormData();
      if (!file) {
        return;
      }
      data.append("document", file);

      dispatch("loadingFile");
      const api = await uploadImg(data);

      if (api instanceof Error) {
        return { loading: false };
      }

      const res = {};
      if (api?.data?.statusCode === 200) {
        const dataApi = _.get(api, "data.result[0]") || {};
        res.acceptSignFile = [
          ...acceptSignFile,
          {
            path: dataApi?.path,
            size: `${((dataApi?.size || 0) / 1024).toFixed()} KB`,
            type: "accept_sign_doc",
            originalname: dataApi?.originalname,
          },
        ];
      }

      return { loadFile: false, ...res };
    },

    deleteFile: () => {
      const { index, url, name } = action;
      const { acceptSignFile, documentsAdditionalFile } = state;
      if (url) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: url },
        });
      }
      name === "additionalFile"
        ? documentsAdditionalFile.splice(index, 1)
        : acceptSignFile.splice(index, 1);

      return { acceptSignFile, documentsAdditionalFile };
    },

    uploadAcceptForm: async () => {
      const blobPdf = await new pdf(ExportAcceptClaimPDF({ state })).toBlob();
      if (!blobPdf) {
        return;
      }

      const acceptFile = new File([blobPdf], `accept-claim-form.pdf`);
      const data = new FormData();
      data.append("document", acceptFile);

      dispatch("onOpenUpdating");
      const cdnPdfApi = await uploadImg(data);
      if (cdnPdfApi instanceof Error) {
        message.error("Error upload accept claim form");
        return { openNotiUpdating: false };
      }
      const dataRequest = _.get(cdnPdfApi, "data.result[0]") || {};
      const acceptDoc = {
        path: dataRequest?.path,
        size: dataRequest?.size,
        type: "accept_doc",
        originalname: "Giấy thế quyền đòi bồi thường.pdf",
      };
      return { acceptDoc };
    },

    onApprove: async () => {
      await dispatch("uploadAcceptForm");
      dispatch("onSubmitUpdate");
    },

    onOpenUpdating: () => ({ openNotiUpdating: true }),
    openConfirmDialog: () => ({ confirmDialog: true }),

    uploadAcceptSign: async () => {
      const { row, acceptSignFile } = state;

      const oldElement207 =
        row?.elements?.find((item) => item.type_id === 207) || {};

      const params = {
        id: row.id,
        insured_info: row.insuredInfo,
        extra_info: row.extraInfo,
        elements: [
          {
            id: oldElement207.id,
            claimId: oldElement207.claim_id,
            typeId: 207,
            typeCode: oldElement207.type_code,
            extraInfo: {
              user: oldElement207?.extra_info?.user,
              documents: [
                ...oldElement207?.extra_info?.documents,
                ...acceptSignFile,
              ],
            },
            text: oldElement207.text,
            value: oldElement207.value,
          },
        ],
      };

      dispatch("onOpenUpdating");
      const dataApi = await updateIndemnifyByMerchant(params);

      const res = {};
      if (dataApi?.data?.statusCode === 200) {
        res.confirmDialog = false;
        res.updatedSignFile = true;
        res.snackbar = {
          open: true,
          message: "Cập nhật yêu cầu bồi thường thành công.",
          type: "success",
        };
      }
      if (dataApi instanceof Error) {
        return {
          confirmDialog: false,
          openNotiUpdating: false,
          snackbar: {
            open: true,
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
            type: "error",
          },
        };
      }

      return { openNotiUpdating: false, ...res };
    },

    copyToClipboard: () => {
      const { row } = state;
      const textArea = document.createElement("textarea");
      textArea.value = row?.extraInfo?.codeDestination || "";

      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      message.success("Đã sao chép mã!", 3);
    },

    todoS3Path: () => {
      const { value } = action;

      if (Array.isArray(value)) {
        const arrayApi = value.map((i) =>
          deleteS3Document({
            action_code: "document_delete",
            extra_info: { url: i },
          })
        );

        Promise.all(arrayApi);
      }
    },
  };

  return action?.type && cases[action?.type];
}
