import React from 'react';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

const ItemDropDown = ({
  title = '',
  isShowData = false,
  children,
  onPress = () => {}
}) => {
  return (
    <div style={styleDropItem}>
      <div onClick={onPress} style={styleDropDown}>
        <div style={styleTitleDrop}>
          {title}
        </div>
        {!isShowData ? <DownOutlined style={styleDropIcon}/> :
          <RightOutlined style={styleDropIcon}/>
        }
      </div>
      {!isShowData ? children : null}
    </div>
  );
};


const styleDropDown = {
  display: 'flex',
  borderRadius: 11,
  height: 45,
  backgroundColor: '#E16B2F',
  alignItems: 'center',
  padding: 12
}

const styleDropItem = {
  background: '#FFF',
  borderRadius: 11
}

const styleDropIcon = {
  marginRight: 5,
  color: '#FFF',
}

const styleTitleDrop = {
  flex: 1,
  fontSize: 17,
  color: '#FFF',
  fontWeight: '600'
}

export default ItemDropDown
