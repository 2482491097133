import "../styles/account-manage.css";
import _ from "lodash";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useReducer from "../../../../../library/hooks/useReducer";
import { useStyles } from "../styles/useStyles";
import accountCreateReducer from "../useReducer/accountCreateReducer";
import {
  contants_status,
  infoUser,
  positionList,
  removeWhiteSpaceBeginning_End,
  roles,
} from "../utils/common";
import ManagePermission from "../components/manage-permission";
import SystemPermission from "../components/system-permission";
import ChangePassword from "../components/modals/change-password";
import { useEffect } from "react";
import AccountInfo from "../components/account-info";
import moment from "moment";
import { Select } from "antd";
import LoadingMui from "../../../../../ui/LoadingMui";

const { Option } = Select;

const initialState = {
  loading: false,
  errors: [],
  accountInfo: {},
  accountActivity: {},
  rolesSystem: {},
  organList: [],
  showPassword: false,
  account: {},
  merchant: {},
  provider: {},
  changePass: { open: false, value: "" },
  dataEdit: [],
  accountBtv: {},

  render: false,
  productClaimList: [],
  garageList: [],
};

const AccountCreate = ({ props }) => {
  const history = useHistory();
  const location = useLocation();
  const userId = location?.state?.userId || false;
  const roleEdit = location?.state?.roleEdit || false;
  const { isRoot, systemType } = infoUser();

  const classes = useStyles();
  const [state, dispatch] = useReducer({
    reducer: accountCreateReducer,
    name: "accountCreateReducer",
    initialState,
    history,
    userId,
    resetState: initialState,
  });

  useEffect(() => {
    dispatch("initial");
  }, []);

  const {
    productClaimList,
    accountActivity,
    organList,
    rolesSystem,
    showPassword,
    account,
    provider,
    merchant,
    changePass,
    errors,
    dataEdit,
    accountInfo,
    accountBtv,
    garageList,
    render,
    loading,
  } = state;

  const permissionNewVertiom = [
    {
      title: "Quyền quản lý Khách hàng",
      name: "merchant",
      checks: [
        {
          name: "customer",
          label: "Tạo và quản lý merchant",
          value: !!_.get(merchant, "customer.view_and_edit"),
        },
        {
          name: "merchant",
          label: "Tạo và quản lý khách hàng ",
          value: !!_.get(merchant, "merchant.view_and_edit"),
        },
        {
          name: "export",
          label: "Xuất báo cáo",
          value: !!_.get(merchant, "export"),
          not_view_and_edit: true,
        },
      ],
    },
    {
      title: "Quyền quản lý Sản phẩm và Nhà bảo hiểm",
      name: "provider",
      checks: [
        {
          name: "benefit",
          label: "Quản lý mã nghiệp vụ",
          value: !!_.get(provider, "benefit.view_and_edit"),
        },
        {
          name: "product_parent",
          label: "Quản lý sản phẩm gốc",
          value: !!_.get(provider, "product_parent.view_and_edit"),
        },
        {
          name: "product",
          label: "Quản lý sản phẩm NBH",
          value: !!_.get(provider, "product.view_and_edit"),
        },
      ],
    },
  ];

  const ROLE = accountActivity?.role || 0;
  const positions =
    !isRoot && accountActivity?.role !== 1
      ? positionList.filter((i) => i.id !== 2)
      : positionList.filter((i) => i.id == 1 || i.id == 2);

  return (
    render && (
      <Box component="main" sx={{ flexGrow: 1, pt: 6, mb: 5 }}>
        <Container>
          {userId ? (
            <>
              <div className={classes.titleName}>
                <span
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {dataEdit?.fullname || "..."}
                </span>{" "}
                - User đăng nhập: {dataEdit?.username || "..."}
              </div>
              <div
                style={{
                  color: "white",
                  fontSize: 16,
                  background: `${
                    contants_status[accountInfo?.status || 0].bgColor
                  }`,
                  padding: "3px 30px",
                  borderRadius: "36px",
                  whiteSpace: "nowrap",
                  width: "max-content",
                  marginBottom: "16px",
                }}
              >
                {contants_status[accountInfo?.status || 0].label}
              </div>
            </>
          ) : (
            <>
              <Typography sx={{ mb: 2 }} variant="h4">
                Tạo tài khoản
              </Typography>
              <Typography sx={{ my: 1.5 }}>
                Vui lòng nhập các trường thông tin để tạo tài khoản
              </Typography>
            </>
          )}
          <Grid container className={classes.gridContainer} spacing={2}>
            <Grid item xs={6}>
              <div className={classes.titleWrapper}>Thông tin tài khoản</div>
              <AccountInfo {...{ state, dispatch, userId }} />
            </Grid>
            <Grid item xs={6}>
              <div className={classes.titleWrapper}>
                Quản lý hoạt động tài khoản
              </div>
              <div className="bodyWrapper">
                <TextField
                  fullWidth
                  select
                  size="small"
                  label="Quyền truy cập hệ thống kênh quản lý"
                  value={accountActivity?.role || ""}
                  onChange={(e) =>
                    dispatch({ type: "onSelect", name: "role", e })
                  }
                  className="textField"
                  disabled={!!userId || !isRoot}
                >
                  {roles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {errors.includes("role") && (
                  <div className={classes.redTxt}>
                    Vui lòng nhập đầy đủ thông tin
                  </div>
                )}

                {ROLE === 1 && (
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label="Công ty bảo hiểm"
                    value={accountActivity?.organ || ""}
                    onChange={(e) =>
                      dispatch({ type: "onSelect", name: "organ", e })
                    }
                    className="textField"
                    disabled={!!userId || !isRoot}
                  >
                    {organList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {`${option?.title || ""}  ${
                          option?.code ? `(${option?.code})` : ""
                        }`}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {ROLE === 4 && (
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label="Bệnh viện phòng khám"
                    value={accountActivity?.organ || ""}
                    onChange={(e) =>
                      dispatch({ type: "onSelect", name: "organ", e })
                    }
                    className="textField"
                    disabled={!!userId || !isRoot}
                  >
                    {organList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {ROLE === 5 && (
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label="Tên đối tác"
                    value={accountActivity?.organ || ""}
                    onChange={(e) =>
                      dispatch({ type: "onSelect", name: "organ", e })
                    }
                    className="textField"
                    disabled={!!userId || (!isRoot && ROLE !== 1)}
                  >
                    {organList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {errors.includes("organ") && (
                  <div className={classes.redTxt}>
                    Vui lòng nhập đầy đủ thông tin
                  </div>
                )}
                {!isRoot && systemType !== "department_garage" && (
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label="Loại tài khoản"
                    value={accountActivity?.positionId || ""}
                    onChange={(e) =>
                      dispatch({ type: "onSelect", name: "positionId", e })
                    }
                    className="textField"
                    disabled={!!userId || (!isRoot && ROLE !== 1)}
                  >
                    {positions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {!isRoot && systemType === "department_garage" && (
                  <TextField
                    fullWidth
                    size="small"
                    label="Loại tài khoản"
                    value={"Nhân viên"}
                    className="textField"
                    disabled
                  ></TextField>
                )}
                {!isRoot &&
                  (accountActivity?.positionId === 2 ||
                    (ROLE === 1 && accountActivity?.positionId === 3)) && (
                    <div id="sl">
                      <Select
                        filterOption={(input, option) => {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(
                                removeWhiteSpaceBeginning_End(
                                  input.toLowerCase()
                                )
                              ) >= 0
                          );
                        }}
                        showSearch
                        allowClear={true}
                        placeholder="Tên Garage"
                        value={accountActivity?.garageId}
                        className={classes.select}
                        onChange={(e) =>
                          dispatch({ type: "onSelect", name: "garageId", e })
                        }
                        onSearch={(val) =>
                          dispatch({ type: "getListGarage", val })
                        }
                        disabled={accountActivity?.positionId === 3}
                      >
                        {garageList?.map((item) => (
                          <Option value={item?.id}>{item?.name}</Option>
                        ))}
                      </Select>
                    </div>
                  )}
                <TextField
                  fullWidth
                  size="small"
                  type={showPassword ? "text" : "password"}
                  label="Mật khẩu"
                  onChange={(e) =>
                    dispatch({ type: "onChange", name: "password", e })
                  }
                  value={accountActivity?.password || ""}
                  className="textField"
                  disabled={!!userId}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {!userId && (
                          <IconButton onClick={() => dispatch("showPassword")}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    autoComplete: "new-password",
                  }}
                />
                {errors.includes("password") && (
                  <div className={classes.redTxt}>
                    Vui lòng nhập đầy đủ thông tin
                  </div>
                )}
                {errors.includes("passwordLen") && (
                  <div className={classes.redTxt}>
                    Password phải có ít nhất 8 kí tự
                  </div>
                )}
                {userId && (
                  <a
                    className={classes.endText}
                    onClick={() => dispatch("openChangePass")}
                  >
                    Đổi mật khẩu
                  </a>
                )}
                <div className="rowText">
                  <Box className="cicle"></Box> &nbsp; Lần hoạt động gần nhất
                </div>
                <span className="number">
                  {userId
                    ? moment(dataEdit?.extra_info?.lastLogin).format(
                        "HH:mm:ss ; DD/MM/YYYY"
                      )
                    : "0"}
                </span>
                {userId && (
                  <div className={classes.divCenter}>
                    <Button
                      className={classes.button}
                      onClick={() => dispatch({ type: "blockUser", status: 0 })}
                      disabled={!accountInfo.status}
                    >
                      Khoá tài khoản
                    </Button>
                    <Button
                      className={classes.unBlockBtn}
                      onClick={() => dispatch({ type: "blockUser", status: 1 })}
                      disabled={!!accountInfo.status}
                    >
                      Mở khoá tài khoản
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
          {((ROLE === 1 &&
            accountActivity?.positionId !== 2 &&
            accountActivity?.positionId !== 3) ||
            (!isRoot && systemType === "globalcare") ||
            ROLE === 4) && (
            <Box className={classes.roleContainer}>
              <div className={classes.titleWrapper}>
                Quyền phân cấp hệ thống
              </div>
              <SystemPermission {...{ state, dispatch, isRoot, ROLE }} />
            </Box>
          )}
          {/* {ROLE === 2 && (
            <Box className={classes.roleContainer}>
              <div className={classes.titleWrapper}>Quyền phân cấp</div>

              <SystemPermission
                {...{ state, dispatch, isRoot, name: "new-vertion" }}
              />
            </Box>
          )} */}
          <Box className={classes.roleContainer}>
            <div className={classes.titleWrapper}>Quyền quản lý bồi thường</div>
            {productClaimList?.map((item) => {
              const check = !!rolesSystem[`${item.product_code}`]?.checked;
              const has =
                !isRoot &&
                !!accountBtv[`has${item.product_code}`] &&
                ROLE !== 2 &&
                ROLE !== 1;
              return (
                ((check && ROLE !== 4) ||
                  has ||
                  (isRoot && ROLE === 2) ||
                  (isRoot && ROLE === 5) ||
                  (item.product_code === "blvp" && ROLE === 4)) && (
                  <ManagePermission
                    {...{
                      systemName: `${item.product_code}Permission`,
                      state,
                      dispatch,
                      item,
                    }}
                  />
                )
              );
            })}
          </Box>
          <Box className={classes.roleContainer}>
            <div className={classes.titleWrapper}>Quyền quản lý tài khoản</div>
            <Grid container className={classes.roleWrapper}>
              <Grid item xs={4} className={classes.padding}>
                <FormControlLabel
                  id="check-box"
                  checked={!!account?.view_and_edit}
                  onChange={(e) =>
                    dispatch({
                      type: "onCheck",
                      name: "view_and_edit",
                      e,
                    })
                  }
                  control={<Checkbox />}
                  label="Tạo và quản lý tài khoản"
                />
              </Grid>
              <Grid item xs={4} className={classes.padding}>
                <FormControlLabel
                  id="check-box"
                  checked={!!account?.export}
                  onChange={(e) =>
                    dispatch({
                      type: "onCheck",
                      name: "export",
                      e,
                    })
                  }
                  control={<Checkbox />}
                  label="Xuất báo cáo"
                />
              </Grid>
              {[2, 4].includes(ROLE) && (
                <Grid item xs={4} className={classes.padding}>
                  <FormControlLabel
                    id="check-box"
                    checked={!!account?.product}
                    onChange={(e) =>
                      dispatch({
                        type: "onCheck",
                        name: "product",
                        e,
                      })
                    }
                    control={<Checkbox />}
                    label="Quản lý sản phẩm"
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          {ROLE == 2 &&
            permissionNewVertiom.map((permission, indexPM) => (
              <Box
                className={classes.roleContainer}
                key={`permission${indexPM}`}
              >
                <div className={classes.titleWrapper}>{permission?.title}</div>
                <Grid container className={classes.roleWrapper}>
                  {permission?.checks?.map((check, indexCheckd) => (
                    <Grid
                      item
                      xs={4}
                      className={classes.padding}
                      key={`checked${indexCheckd}`}
                    >
                      <FormControlLabel
                        id="check-box"
                        checked={!!check.value}
                        onChange={(e) =>
                          dispatch({
                            type: "onCheckPermission",
                            name: check.name,
                            permissionName: permission.name,
                            not_view_and_edit: check.not_view_and_edit,
                            e,
                          })
                        }
                        control={<Checkbox />}
                        label={check.label}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          <div className={classes.divCenter}>
            <Button
              className={classes.button}
              onClick={() => dispatch("validate")}
            >
              {!userId ? "Tạo tài khoản" : "Cập nhật"}
            </Button>
          </div>
        </Container>
        <ChangePassword dispatch={dispatch} data={changePass} errors={errors} />
        <LoadingMui loading={loading} />
      </Box>
    )
  );
};
export default AccountCreate;
