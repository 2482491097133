import React from "react";
import { makeStyles } from "@mui/styles";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";

import img from "../../../../../assets/health/img-icon.svg";
import dog_icon from "../../../../../assets/health/dog_icon.svg";
import Accordions from "./Accordions";
import moment from "moment";

export default function AccordionsSteper(props) {
  const classes = useMakeStyles();
  const { data, onClick, expanded, dispatch } = props;

  return (
    <div>
      <Accordions
        classes={classes}
        titleHeader={data?.title || ""}
        expanded={expanded}
        onClick={onClick}
      >
        <Stepper activeStep={0} orientation="vertical" connector={<div />}>
          {(data?.licese || []).map((item, index) => (
            <Step
              active={true}
              className={classes.step}
              key={`stepper_${index}`}
            >
              <StepLabel
                StepIconComponent={() => <img src={dog_icon} width={18} />}
              >
                <div className={classes.titile} key={`div_stepper_${index}`}>
                  <div className={classes.dateTitle}>
                    {moment(item?.created_date).format("DD/MM/YYYY")}
                  </div>
                  <div
                    className={classes.textTitle}
                    onClick={() =>
                      dispatch({
                        type: "openDaShowImg",
                        item: {
                          title: data.titile,
                          typeCode: data.typeCode,
                          typeId: data.typeId,
                          licese: [item],
                        },
                      })
                    }
                  >
                    <div className="title_text">{`Hình ảnh hồ sơ lần ${
                      index + 1
                    }`}</div>
                    <img src={img} />
                  </div>
                </div>
              </StepLabel>
              <StepContent>
                <div style={{ height: "16px" }}></div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Accordions>
    </div>
  );
}

const useMakeStyles = makeStyles(() => ({
  accordion: {
    boxShadow: "none !important",
    border: "none !important",
    borderRadius: "12px !important",
    marginTop: "12px",

    "&:before": {
      height: "0 !important",
    },
  },

  accordiontitle: {
    minHeight: "36px !important",
    padding: "12px 0 !important",

    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
    },

    "& .MuiAccordionSummary-expandIconWrapper": {
      "& .MuiSvgIcon-root": {
        color: "#3961A0 !important",
      },
    },
  },

  step: {
    "& .MuiStepContent-root.MuiStepContent-last": {
      display: "none",
    },

    "& .MuiStepContent-root": {
      borderLeft: "2px solid #3961A0",
      marginLeft: "8px",
    },
  },

  dateTitle: {
    width: "100%",
    color: "#686868",
    width: "90px",
    marginRight: "4px",
  },

  titile: {
    display: "flex",
  },

  textTitle: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    width: "calc(100% - 95px)",
    "& .title_text": {
      color: "#3961A0",
    },
  },
}));
