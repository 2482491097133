import { makeStyles } from "@mui/styles";

export const styledReportAccident = makeStyles(() => ({
  container: {
    background: "#F8F8F8",
    padding: "16px",
    "& .title-head": {
      color: "#686868",
    },
  },

  title: {
    fontSize: "18px",
    fontWeight: "700",
    color: "black",
    margin: "8px 0",
  },

  label: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#4B4B4B",
    margin: "12px 0",
  },

  capitalize: {
    "& input": {
      textTransform: "capitalize",
    },
  },

  inputDiv: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "18px",
    marginTop: "6px",
    padding: "7px 6px",
    display: "flex",
    background: "white",

    "& .input-date.MuiInputBase-root:before": {
      border: "none !important",
    },

    "& .input-date.MuiInputBase-root:after": {
      border: "none !important",
    },

    "& .input-date.MuiInputBase-input": {
      boxSizing: "border-box",
    },

    "& .input-time.MuiInputBase-root:before": {
      border: "none !important",
    },

    "& .input-time.MuiInputBase-root:after": {
      border: "none !important",
    },

    "& .input-time.MuiInputBase-input": {
      boxSizing: "border-box",
    },
  },

  textField: {
    "& .MuiInputBase-multiline": {
      padding: "0px !important",
    },
  },

  //
}));
