import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import _ from "lodash";
import { Row } from "antd";

const HeaderBar = ({
  title = "",
  onPress = () => {},
  background = "#FFF",
  children,
}) => {
  return (
    <Row
      style={{
        position: "fixed",
        height: 50,
        width: "100%",
        top: 0,
        left: 0,
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99,
        background,
      }}
    >
      <div style={styleButtonBack} onClick={onPress}>
        <LeftOutlined style={{ fontSize: 17 }} />
      </div>
      {!_.isEmpty(title) ? (
        <div style={{ ...styleTitle, fontSize: 17 }}>{title}</div>
      ) : (
        children
      )}
    </Row>
  );
};

const styleButtonBack = {
  padding: "8px 14px 5px 14px",
  position: "absolute",
  left: 0,
  // top: 10,
};

const styleTitle = {
  fontFamily: `"Google Sans",sans-serif`,
  fontSize: 17,
  fontWeight: "700",
  lineHeight: "22px",
  alignItems: "center",
  textAlign: "center",
};

export default HeaderBar;
