import { get } from "lodash";
import * as PATH from "../../../../configs/routesConfig";
import { getProducts } from "../../../../apis/productApis";
import { message } from "antd";

const productReducer = ({ initialState, state, action, dispatch, history }) => {
  const cases = {
    getList: async () => {
      const { data } = state;
      const { value } = action;
      const res = {};

      const p = {
        keyword: value || "",
        enableClaim: 1,
        limit: 20,
        offset: 0,
      };
      dispatch("onLoading");
      const result = await getProducts(p);
      if (result instanceof Error) {
        message.error("Lỗi lấy danh sách", 2);
        return { loading: false };
      }
      res.products = get(result, "data.result.data") || [];
      res.total = get(result, "data.result.total") || 0;
      res.page = {
        count: Math.ceil(res.total / 20),
        pageNumber: 1,
        pageSize: 20,
      };
      data.search = value || "";
      return { ...res, data, loading: false };
    },
    changePage: async () => {
      const { name, item } = action;
      name === "add" && history.push(`${PATH.AD_PRODUCT_DETAIL}`);
      name === "detail" &&
        history.push(`${PATH.AD_PRODUCT_DETAIL}?id=${item.id}`);
    },
    onLoading: () => ({ loading: true }),
    onPaging: async () => {
      const { data, page } = state;
      const { e, value } = action;
      const res = {};
      const p = {
        keyword: data?.search || "",
        enableClaim: 1,
        limit: page.pageSize,
        offset: (value - 1) * page.pageSize,
      };
      dispatch("onLoading");
      const result = await getProducts(p);
      if (result instanceof Error) {
        message.error("Lỗi lấy danh sách", 2);
        return { loading: false };
      }
      res.products = get(result, "data.result.data") || [];
      res.total = get(result, "data.result.total") || 0;
      res.page = {
        count: Math.ceil(res.total / page.pageSize),
        pageNumber: value,
        pageSize: page.pageSize,
      };
      return { ...res, data, loading: false };
    },
  };
  return cases[action?.type];
};
export default productReducer;
