import { Backdrop, CircularProgress } from "@mui/material";

export const Loading = ({ loading }) => {
  return (
    <Backdrop
      style={{
        zIndex: 999999,
        color: "#fff",
        position: "absolute",
        borderRadius: "5px",
      }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
