import { makeStyles } from "@mui/styles";

const styleRecords = makeStyles((theme) => {
  return {
    container: {
      marginTop: "40px",
      padding: "16px 0",

      "& .title": {
        corlo: "#4B4B4B",
        fontWeight: "700",
        fontSize: "15px",
        paddingLeft: "32px",
      },
    },

    containerSearch: {
      margin: "12px 0",
      padding: "0 8px",

      "& .header-title": {
        background: "#3961A0",
        color: "white",
        padding: "8px 0 8px 24px",
        fontWeight: "600",
        fontSize: "15px",
      },
    },

    cardSearch: {
      width: "100%",
      boxShadow: "0px 0px 5px rgba0, 0, 0, 0.25)",
      padding: "8px",
      background: "white",

      "& .img-search": {
        [theme?.breakpoints?.down("md")]: {
          display: "flex",
          justifyContent: "center",
        },
        [theme?.breakpoints?.up("md")]: {
          borderRight: "1px solid #4481EB",
        },
      },
    },

    gridItemContainer: {
      padding: "12px",
    },

    buttonSearch: {
      width: "100%",
      background: "#3961A0 !important",
      color: "white !important",
      padding: "12px 0",
      borderRadius: "16px",
      height: "48px",

      [theme?.breakpoints?.only("sm")]: {
        width: "calc(50% - 6px)",
        padding: "0 16px",
      },

      "&:hover": {
        background: "#3961A0 !important",
        color: "white !important",
      },
    },

    result: {
      width: "100%",
      padding: "0 16px",
      display: "flex",
      justifyContent: "space-between",
      margin: "12px 0",

      "& .select-view": {
        "& .MuiSelect-select": {
          paddingTop: "6px",
          paddingBottom: "6px",
        },
      },
    },

    noData: {
      marginTop: "20px",
      marginLeft: "30px",
      color: "red",
      fontSize: "18px",
    },

    div: {
      padding: "0 8px",
    },

    table: {
      minWidth: "1520px",

      "& tbody td:last-child": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      "& tbody td:first-child": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },

      "& th": {
        fontWeight: "600",
        textAlign: "center",
        whiteSpace: "nowrap",
      },

      "& td": {
        textAlign: "center",
        padding: 0,
      },

      "& .stickey": {
        position: "sticky",
      },

      "& th.stt": {
        width: "45px",
      },

      "& th.full-name": {
        minWidth: "180px",
        textTransform: "capitalize",
      },

      "& th.view-records": {
        right: 0,
        background: "white",
      },

      "& td.full-name": {
        textTransform: "capitalize",
      },

      "& td.view-records": {
        right: 0,
        background: "white",
      },

      "& td.reviewer": {
        minWidth: "200px",
        paddingLeft: "8px",
        textAlign: "center",
        // display: "flex",
        // justifyContent: "space-between",
      },
    },

    headerRow: {
      borderTop: "none",
      borderBottom: "2px solid #686868",
      padding: "0 8px",
    },

    cellRow: {
      borderTop: "none",
      borderBottom: "1.5px solid #D6DEEB",
      background: "white !important",
    },

    //
  };
});

export default styleRecords;
