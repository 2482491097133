import { IconButton, TableCell } from "@mui/material";
import _ from "lodash";
import moment from "moment";

// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { getStatusById, converDate } from "../../util";

export const columns = [
  {
    label: "Hồ sơ bồi thường",
    headerClass: "code",
    renderCell: ({ rowIdx, item, data, event }) => (
      <TableCell
        className={
          data?.currentFilePdf?.id_element == item.id ? "code choose" : "code"
        }
        onClick={() => data.handleClick({ item, name: "show_detail_pdf" })}
      >
        <>
          <span style={{ color: "#3961A0" }}>SHBT </span>
          <span style={{ color: "#3961A0", fontWeight: "600" }}>
            {data.name == "approval_history" ? data?.detail?.code : item?.code}
          </span>
        </>
      </TableCell>
    ),
  },
  {
    label: "Ngày xuất HSBT",
    // headerClass: "code",
    renderCell: ({ item, data }) => (
      <TableCell
        className={data?.currentFilePdf?.id_element == item.id ? "choose" : ""}
        onClick={
          data.name == "approval_history"
            ? () => data.handleClick({ item })
            : null
        }
      >
        {
          // data.name == "approval_history"
          moment(
            converDate(item?.created_date || item?.export_claim_date)
          ).format("HH:mm, DD/MM/YYYY")
        }
      </TableCell>
    ),
  },
  {
    label: "NS xử lý",
    // headerClass: "code",
    renderCell: ({ rowIdx, item, data }) => (
      <TableCell
        className={data?.currentFilePdf?.id_element == item.id ? "choose" : ""}
        onClick={
          data.name == "approval_history"
            ? () => data.handleClick({ item })
            : null
        }
      >
        {data?.name === "approval_history"
          ? _.get(item, "extra_info.user.userReview.fullname") || ""
          : item?.user_id_review || ""}
      </TableCell>
    ),
  },
  {
    label: "NS phê duyệt",
    // headerClass: "code",
    renderCell: ({ rowIdx, item, data }) => (
      <TableCell
        className={data?.currentFilePdf?.id_element == item.id ? "choose" : ""}
        onClick={
          data.name == "approval_history"
            ? () => data.handleClick({ item })
            : null
        }
      >
        {data?.name === "approval_history"
          ? _.get(data, "detail.userFullnameAssign") || ""
          : item?.user_id_assign || ""}
      </TableCell>
    ),
  },
  {
    label: "Trạng thái",
    headerClass: "status",
    renderCell: ({ item, data }) => {
      return (
        <TableCell
          className={
            data?.currentFilePdf?.id_element == item.id ? "choose" : ""
          }
          style={{
            color: getStatusById(item.status_id || item.status)?.color || "",
          }}
          onClick={
            data.name == "approval_history"
              ? () => data.handleClick({ item })
              : null
          }
        >
          {data?.name === "approval_history"
            ? item.status_id == 2
              ? ""
              : [3, 5, 7, 8, 9, 10].includes(+item.status_id) &&
                getStatusById(item.status_id).label
            : getStatusById(item.status_id).label}
        </TableCell>
      );
    },
  },
  {
    label: "Phí bồi thường",
    headerClass: "amount",
    renderCell: ({ item, data }) => (
      <TableCell
        className={data?.currentFilePdf?.id_element == item.id ? "choose" : ""}
        onClick={
          data.name == "approval_history"
            ? () => data.handleClick({ item })
            : null
        }
      >
        {`${item?.extra_info?.approve_info?.approve_amount || 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          "."
        ) + " VNĐ"}
      </TableCell>
    ),
  },
  // {
  //   label: " ",
  //   headerClass: "button_option",
  //   renderCell: ({ item, data, rowIdx }) => {
  //     const disableBtn =
  //       ![3, 4, 6, 7, 8, 9, 10].includes(item.status_id) &&
  //       [2, 8].includes(data?.detail?.statusId);

  //     return (
  //       <TableCell
  //         className={
  //           data?.currentFilePdf?.id_element == item.id ? "choose" : ""
  //           // data?.currentFilePdf?.id_element == item.id
  //           //   ? data.name == "approval_history"
  //           //     ? "button_option sticky choose"
  //           //     : "button_option choose"
  //           //   : data.name == "approval_history"
  //           //   ? "button_option sticky"
  //           //   : "button_option"
  //         }
  //       >
  //         {data.name == "approval_history" && data.level_id == 1 && (
  //           <IconButton
  //             style={{ padding: "4px" }}
  //             id="button_option"
  //             // onClick={data.handleClickP}
  //             onClick={(e) =>
  //               data.dispatch({ type: "openPopoverButton", e, item, rowIdx })
  //             }
  //             disabled={!disableBtn}
  //           >
  //             <MoreHorizIcon
  //               style={{
  //                 color: disableBtn ? "#000000" : "#CCCCCC	",
  //               }}
  //             />
  //           </IconButton>
  //         )}
  //       </TableCell>
  //     );
  //   },
  // },
];
