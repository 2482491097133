import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";

import { useStyles } from "../styles/useStyles";
import date from "../assets/toDate.svg";
import location from "../assets/location.svg";
import cod from "../assets/cod.svg";
import user from "../assets/user.svg";
import phone from "../assets/phone.svg";
import address from "../assets/address.svg";
import classify from "../assets/classify.svg";
import statusIcon from "../assets/status-icon.svg";
import oclock from "../assets/oclock.svg";
import action from "../assets/action.svg";
import back from "../assets/back.svg";
import license from "../assets/license.svg";
import accountNumber from "../assets/license.svg";
import email from "../assets/email.svg";
import money from "../assets/money.svg";
import object from "../assets/object.svg";
import risk from "../assets/risk.svg";

export default function CardInfor(props) {
  const classes = useStyles();
  const { row, dispatch } = props;
  return (
    <div className={classes.boxAccor}>
      <Accordions
        divTitleAccor={
          <div className="titleAccodion">
            Chuyến đi #
            <span className="code" onClick={() => dispatch("copyToClipboard")}>
              {row?.extraInfo?.codeDestination || ""}
            </span>
          </div>
        }
      >
        <Row
          icon={date}
          title="Ngày khởi tạo"
          value={moment(row?.extraInfo?.createdDate).format("DD/MM/YYYY")}
        />
        <Row
          icon={location}
          title="Thông tin điểm giao"
          value={row?.extraInfo?.address}
        />
        <Row
          icon={cod}
          title="Giá trị COD"
          value={
            `${row?.extraInfo?.codValue || 0}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              "."
            ) + "đ"
          }
        />
      </Accordions>
      <Accordions titleAccor={"Thông tin người gửi"}>
        <Row
          icon={user}
          title="Họ và tên"
          value={row?.extraInfo?.sender?.fullName || ""}
          capitalize
        />
        <Row
          icon={phone}
          title="Số điện thoại"
          value={row?.extraInfo?.sender?.phoneNumber || ""}
        />
        <Row
          icon={address}
          title="Địa chỉ"
          value={row?.extraInfo?.sender?.address || ""}
        />
      </Accordions>
      <Accordions titleAccor={"Thông tin người nhận"}>
        <Row
          icon={user}
          title="Họ và tên"
          value={row?.extraInfo?.receiver?.fullName || ""}
          capitalize
        />
        <Row
          icon={phone}
          title="Số điện thoại"
          value={row?.extraInfo?.receiver?.phoneNumber || ""}
        />
        <Row
          icon={address}
          title="Địa chỉ"
          value={row?.extraInfo?.receiver?.address || ""}
        />
      </Accordions>
      <Accordions titleAccor={"Thông tin tài xế"}>
        <Row
          icon={user}
          title="Họ và tên"
          value={row?.extraInfo?.driver?.fullName || ""}
          capitalize
        />
        <Row
          icon={phone}
          title="Số điện thoại"
          value={row?.extraInfo?.driver?.phoneNumber || ""}
        />
        <Row
          icon={license}
          title="Biển số xe"
          value={row?.extraInfo?.driver?.license || ""}
        />
      </Accordions>
      <div className={classes.boxLine}>
        <div className="lineTitle">Thông tin thanh toán</div>
        <Row
          icon={date}
          title="Loại thanh toán"
          value={
            row?.extraInfo?.paymentInfo?.paymentId === 1
              ? "Ứng thu hộ COD"
              : "Không ứng COD"
          }
        />
        <div className="lineTitle">Phân loại hang hoá</div>
        <Row
          icon={classify}
          title="Phân loại"
          value={row?.extraInfo?.productInfo?.typeNameOfProduct || ""}
        />
        <div className="lineTitle">Trạng thái</div>
        <Row
          icon={statusIcon}
          title="Trạng thái"
          value={row?.extraInfo?.statusId === 0 ? "Thất bại" : "Thành công"}
        />
      </div>
      <Accordions titleAccor={"Thông tin sự cố"}>
        <Row
          icon={date}
          title="Ngày xảy ra sự cố"
          value={moment(row?.extraInfo?.riskDate).format("DD/MM/YYYY")}
        />
        <Row
          icon={oclock}
          title="Giờ xảy ra sự cố"
          value={row?.extraInfo?.riskTime || ""}
        />
        <div style={{ fontSize: "14px" }}>
          <div
            style={{
              color: "#2A3790",
              whiteSpace: "nowrap",
            }}
          >
            <img
              src={action}
              alt="img"
              style={{ marginRight: "4px" }}
              height={24}
              width={24}
            />{" "}
            Mô tả diễn biến
          </div>
          <div>{row?.extraInfo?.describe || ""}</div>
        </div>
      </Accordions>
      <div className={classes.boxLine}>
        <div className="lineTitle">Khai báo giá trị hàng hoá</div>
        <Row
          icon={money}
          title="Giá trị hàng hoá"
          value={
            `${row?.extraInfo?.productAmount || 0}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              "."
            ) + "đ"
          }
        />
        <div className="lineTitle">Đối tượng bảo hiểm</div>
        <Row
          icon={object}
          title="Đối tượng"
          value={
            +row?.extraInfo?.insuranceObj === 1
              ? "Hàng hoá"
              : +row?.extraInfo?.insuranceObj === 2
              ? "Hàng hoá có hoá đơn"
              : +row?.extraInfo?.insuranceObj === 3
              ? "Hàng hoá không có hoá đơn"
              : +row?.extraInfo?.insuranceObj === 4
              ? "Tài liệu"
              : ""
          }
        />
        <div className="lineTitle">Rủi ro gặp phải</div>
        <Row
          icon={risk}
          title="Rủi ro"
          value={+row?.extraInfo?.typeOfAccident === 1 ? "Hư hỏng" : "Mất mát"}
        />
      </div>
      <Accordions titleAccor={"Thông tin người thụ hưởng"}>
        <Row
          icon={user}
          title="Họ và tên"
          value={row?.extraInfo?.beneficiaryName || ""}
          capitalize
        />
        <Row
          icon={phone}
          title="Số điện thoại"
          value={row?.extraInfo?.beneficiaryPhone || ""}
        />
        <Row
          icon={back}
          title="Ngân hàng"
          value={row?.extraInfo?.beneficiaryBank || ""}
        />
        <Row
          icon={accountNumber}
          title="Số tài khoản"
          value={row?.extraInfo?.beneficiaryBankAcountNumber || ""}
        />
        <Row
          icon={email}
          title="email"
          value={row?.extraInfo?.beneficiaryEmail || ""}
        />
      </Accordions>
    </div>
  );
}

const Accordions = (props) => {
  const classes = useStyles();
  const { children, titleAccor, divTitleAccor } = props;

  return (
    <>
      <Accordion className={classes.accordionRoot}>
        <AccordionSummary
          expandIcon={<KeyboardArrowUpIcon style={{ color: "#284078" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {divTitleAccor ? (
            divTitleAccor
          ) : (
            <span className="titleAccodion">{titleAccor}</span>
          )}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  );
};

const Row = (props) => {
  const { title, value, capitalize, icon } = props;

  return (
    <div className="rowDiv">
      <span className="titleSpan">
        <img
          src={icon}
          alt="img"
          style={{ marginRight: "4px" }}
          height={24}
          width={24}
        />
        {title}
      </span>
      <span
        className={capitalize ? "valueSpan capitalize" : "valueSpan"}
        title={value}
        style={{ textAlign: "end" }}
      >
        {value}
      </span>
    </div>
  );
};
